import { activateLogger, deactivateLogger } from 'core/api-logger/config/logger';

export function addLogger() {
    activateLogger();
}

export function removeLoggerOnVerification(newRouteId) {
    const applyFlowRoutes = ['apply-flow', 'job-preview.apply-flow'];

    if (!applyFlowRoutes.includes(newRouteId)) {
        deactivateLogger();
    }
}

export function removeLoggerOnApplyFlow(currentRouteId, newRouteId) {
    if (currentRouteId !== newRouteId) {
        deactivateLogger();
    }
}