function arrayBufferToBinaryString(arrayBuffer) {
    const bytes = new window.Uint8Array(arrayBuffer);
    let result = '';

    for (let i = 0; i < bytes.length; i++) {
        result += String.fromCharCode(bytes[i]);
    }

    return result;
}

function binaryStringToArrayBuffer(byteString) {
    const result = new window.Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        result[i] = byteString.charCodeAt(i);
    }

    return result;
}

export default function Base64FileReader() {
    const reader = new FileReader();

    this.encode = file =>
        new Promise((resolve) => {
            reader.onload = (event) => {
                const binaryString = arrayBufferToBinaryString(event.target.result);

                resolve(btoa(binaryString));
            };

            reader.readAsArrayBuffer(file);
        });

    this.decode = string =>
        new Promise((resolve) => {
            const arrayBuffer = binaryStringToArrayBuffer(atob(string));

            resolve(arrayBuffer);
        });

    return this;
}