import ko from 'knockout';
import keycodes from 'core/config/keycodes';

const FOCUSED_WITH_KEYBOARD_CLASS_NAME = 'focused-with-keyboard';
const { body } = document;

function addClass(event) {
    if (event.which === keycodes.TAB) {
        body.classList.add(FOCUSED_WITH_KEYBOARD_CLASS_NAME);
    }
}

function removeClass(event) {
    if (event && event.detail > 0) {
        body.classList.remove(FOCUSED_WITH_KEYBOARD_CLASS_NAME);
    }
}

/**
 * Please use only on <body> element!
 *
 * When applied, focus indicator on body's children is shown only while navigating via keyboard.
 * Is used to help people with vision issues notice focus indicator.
 *
 * @example
 *
 * <body data-bind="a11y.toggleFocusIndicator">
 *     --- content ---
 * </body>
 */

ko.bindingHandlers['a11y.toggleFocusIndicator'] = {
    init(element) {
        element.addEventListener('keydown', addClass);
        element.addEventListener('mousedown', removeClass);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('keydown', addClass);
            element.removeEventListener('mousedown', removeClass);
        });
    },
};
