import AiJobsSearchServiceAbstract from './aiJobsSearchAbstract';
import searchService from 'cx/module/search/service/search';
import { searchResultsAiJobs as searchResultsAiJobsModel } from '../model/searchJobResults';
import { SEARCH_AI_JOBS_LIMIT } from 'search/service/providers/search';

class SearchServiceSimilar extends AiJobsSearchServiceAbstract {

    limit = SEARCH_AI_JOBS_LIMIT;

    _performSearch(params, requisitionId, contentLocaleLang) {
        return searchService.searchSimilarJobs({
            params,
            similarJobId: requisitionId,
            limit: this.limit,
            contentLocaleLang,
        });
    }

}

export const searchSimilar = new SearchServiceSimilar({
    jobsModel: searchResultsAiJobsModel,
});