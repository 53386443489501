export const ELEMENT_TYPES = {
    TextArea: 'textarea',
    TextBox: 'textarea',
    RichTextArea: 'textarea',
    Checkbox: 'checkbox',
    SingleChoiceList: 'select',
    SingleChoiceRadio: 'radio',
    MultipleChoiceCheck: 'multiselect',
    DatePicker: 'date',
    Integer: 'integer',
    Decimal: 'decimal',
    MultipleChoiceList: 'multiselectlist',
    NoResponse: 'noresponse',
};

export const DEFAULT_ELEMENT_TYPE = 'text';

function getFormElementType(restType) {
    return ELEMENT_TYPES[restType] || DEFAULT_ELEMENT_TYPE;
}

function setBranchingProperties(formElementsDef) {
    formElementsDef.forEach((formElement) => {
        formElement.conditionedQuestions = formElementsDef
            .filter(({ conditioningQuestionId }) => conditioningQuestionId === formElement.name);
    });

    return formElementsDef.filter(({ conditioningQuestionId }) => !conditioningQuestionId);
}

function sortBranchingQuestions(formElementsDef) {
    return formElementsDef.reduce((previousElements, element) => ([
        ...previousElements,
        element,
        ...sortBranchingQuestions(element.conditionedQuestions),
    ]), []);
}

export default {
    mapFormElementsFromRest(restData) {
        const restFormElements = restData.items;
        const mappedElements = restFormElements.map(this.mapFormElementFromRest);

        const mappedElementsBranched = setBranchingProperties(mappedElements);

        return sortBranchingQuestions(mappedElementsBranched);
    },

    mapFormElementFromRest(restFormElement) {
        const formElement = {
            type: getFormElementType(restFormElement.uidisplayType),
            name: restFormElement.attributeName,
            label: restFormElement.prompt?.trim(),
            instructions: restFormElement.instructionsText,
            defaultValue: restFormElement.defaultValue,
            value: restFormElement.defaultValue,
            attributes: {
                readonly: restFormElement.readonlyFlag,
                required: restFormElement.requiredFlag,
            },
            validators: {},
            options: restFormElement.answers,
            isHidden: restFormElement.conditionDisplay === 'Y',
            conditioningQuestionId: restFormElement.conditionQuestionId,
            conditioningAnswerId: restFormElement.conditionAnswerId,
            conditionedQuestions: [],
            questionnaireId: restFormElement.questionnaireId,
            questionnaireVersionNumber: restFormElement.qstnrVersionNumber,
        };

        if (restFormElement.requiredFlag) {
            formElement.validators.required = {};
        }

        if (restFormElement.minimumLength) {
            formElement.validators.minLength = {
                min: restFormElement.minimumLength,
            };
        }

        if (restFormElement.maximumLength) {
            formElement.validators.maxLength = {
                max: restFormElement.maximumLength,
            };
        }

        if (restFormElement.minimumSelections) {
            formElement.validators.minSelection = {
                minSelection: restFormElement.minimumSelections,
            };
        }

        if (restFormElement.maximumSelections) {
            formElement.validators.maxSelection = {
                maxSelection: restFormElement.maximumSelections,
            };
        }

        if (restFormElement.minimumDate) {
            formElement.validators.minDate = {
                min: restFormElement.minimumDate,
                inclusive: true,
            };
        }

        if (restFormElement.maximumDate) {
            formElement.validators.maxDate = {
                max: restFormElement.maximumDate,
                inclusive: true,
            };
        }

        return formElement;
    },
};