import appConfig from 'app/model/config';
import storageService from 'core/storage/localStorage';

const SESSION_PERSISTENCE_KEY = 'sessionPersistence';


export default {
    isActive() {
        return Boolean(storageService.restore(SESSION_PERSISTENCE_KEY));
    },

    isEnabled() {
        return appConfig.getSettingByKey('ORA_IRC_KEEP_CANDIDATE_SIGNED_IN_ENABLED') === 'true';
    },

    activate() {
        storageService.store(SESSION_PERSISTENCE_KEY, 'true');
    },

    deactivate() {
        storageService.remove(SESSION_PERSISTENCE_KEY);
    },
};