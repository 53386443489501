import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';
import { session } from 'candidate-verification/service/user-session/session';
import tokenService from 'candidate-verification/service/token';
import { clearShortTokenData } from 'candidate-verification/service/shortTokenStorage';

export function triggerChallenge(token) {
    return tokenService.verifyTokenWithChallengeFlag(token)
        .then(response => handleChallengeFlag(Boolean(response?.challengeFlag)));
}

export async function handleChallengeFlag(challengeFlag) {
    if (challengeFlag && sessionPersistence.isActive()) {
        await session.signOut();
        sessionPersistence.deactivate();
    }

    if (!challengeFlag) {
        clearShortTokenData();
    }

    return challengeFlag;
}