import ko from 'knockout';
import router from 'app/model/router';


/**
 * Invoke transition function.
 *
 * @param {object} route - route configuration
 * @param {string} routeId - routeId
 * @param {object} element - element to pass to transition function
 */
function handleTransition(route, routeId, element) {
    const { transition } = route;

    if (route.id === routeId) {
        transition(element);
    }
}

/**
 * Every time when route is changed, binding look into route configuration for transition function, when
 * route id is equal to given binding param the transition function is invoke.
 *
 * @params {string} - route id
 *
 * @example
 * <div data-bind="routeTransition: 'route_name'"></div>
 *
 * In routing configuration file:
 *
 * router.configure({
 *     'route_name': {
 *         ...
 *         transition: transitionFunction
 *     }
 * });
 */
ko.bindingHandlers.routeTransition = {
    update(element, valueAccessor) {
        const routeId = valueAccessor();

        handleTransition(router.route(), routeId, element);
    },
};
