import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { CustomComponentTypeCode } from 'site-editor/module/content-editor/config/components';
import { notReachable } from 'app/types/utils';
import paramsMapper, { CustomContentParams } from './params';
import { SearchResultsCustomizationParams } from 'minimal/module/search/component/search-results/config/types';
import { FiltersCustomizationParams } from 'minimal/module/search/component/search-filters-panel/config/types';
import { SearchBarCustomizationParams } from 'minimal/module/search/component/search-bar/config/types';
import { mapParamsFromRest as mapRecommendedJobsWidgetParamsFromRest } from 'minimal/component/recommended-jobs-widget/service/mapParams';
import { mapParamsFromRest as mapCategoryTilesParamsFromRest } from 'minimal/component/category-tiles/service/mapParams';
import { mapParamsFromRest as mapSearchResultsParamsFromRest } from 'minimal/module/search/component/search-results/service/mapParams';
import { mapParamsFromRest as mapSearchFiltersParamsFromRest } from 'minimal/module/search/component/search-filters-panel/service/mapParams';
import { mapParamsFromRest as mapSearchBarParamsFromRest } from 'minimal/module/search/component/search-bar/service/mapParams';
import { mapParamsFromRest as mapJobDetailsTitleParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-title/service/mapParams';
import { mapParamsFromRest as mapCookieConsentModalTitleParamsFromRest } from 'cookie-consent/components/cookie-consent-modal-title/service/mapParams';
import { mapParamsFromRest as mapCookieConsentModalPolicyParamsFromRest } from 'cookie-consent/components/cookie-consent-modal-policy/service/mapParams';
import { mapParamsFromRest as mapCookieConsentModalMessageParamsFromRest } from 'cookie-consent/components/cookie-consent-modal-message/service/mapParams';
import { mapParamsFromRest as mapCookieConsentModalActionsParamsFromRest } from 'cookie-consent/components/cookie-consent-modal-actions/service/mapParams';
import { mapParamsFromRest as mapCookieConsentModalCategoriesParamsFromRest } from 'cookie-consent/components/cookie-consent-modal-categories/service/mapParams';
import { mapParamsFromRest as mapJobDetailsLocationParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-location/service/mapParams';
import { mapParamsFromRest as mapJobDetailsDescriptionParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-description/service/mapParams';
import { mapParamsFromRest as mapJobDetailsApplyButtonParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-apply-button/service/mapParams';
import { mapParamsFromRest as mapJobDetailsSkillsParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-skills/service/mapParams';
import { mapParamsFromRest as mapJobDetailsMediaParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-media/service/mapParams';
import { mapParamsFromRest as mapJobDetailsInfoParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-info/service/mapParams';
import { mapParamsFromRest as mapJobDetailsTagsParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-tags/service/mapParams';
import { mapParamsFromRest as mapJobDetailsSimilarJobsParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-similar-jobs/service/mapParams';
import { mapParamsFromRest } from 'minimal/component/cookie-preference-link/service/mapParams';
import { mapParamsFromRest as mapJobDetailsJobFitParamsFromRest } from 'minimal/module/job-details/component/job-details-content/component/job-details-job-fit/service/mapParams';

type RestComponent = Record<string, unknown> & {
    componentParams: RestParam[];
};

export const mapComponentParamsFromRest = (
    component: RestComponent
):
    | CustomContentParams
    | SearchResultsCustomizationParams
    | FiltersCustomizationParams
    | SearchBarCustomizationParams => {
    const typeParam = component.componentParams.find((param) => param.paramKey === 'type');

    if (!typeParam) {
        console.error(`Type of component wasn't identified on params mapping: ${JSON.stringify(component)}`);

        return paramsMapper.mapParamsFromRest(component.componentParams);
    }

    const componentType = typeParam.paramValue as CustomComponentTypeCode;

    switch (componentType) {
        case 'cc-rule':
        case 'cc-headline':
        case 'cc-paragraph':
        case 'cc-image':
        case 'cc-video':
        case 'cc-button':
        case 'cc-space':
        case 'cc-job-list':
        case 'cc-custom-html':
        case 'cc-talent-community-button':
        case 'cc-linkedin-connections-widget':
        case 'cc-minimal-search-categories':
        case 'cc-minimal-scroll-indicator':
        case 'cc-splash-mohegan-view-all-jobs':
        case 'cc-minimal-search-box-compact':
            // TODO: provide more specific mapping based on components params type
            return paramsMapper.mapParamsFromRest(component.componentParams);

        case 'cc-cookie-preference-link':
            return mapParamsFromRest(component.componentParams);

        case 'cc-cookie-consent-modal-title':
            return mapCookieConsentModalTitleParamsFromRest(component.componentParams);

        case 'cc-cookie-consent-modal-policy':
            return mapCookieConsentModalPolicyParamsFromRest(component.componentParams);

        case 'cc-cookie-consent-modal-message':
            return mapCookieConsentModalMessageParamsFromRest(component.componentParams);

        case 'cc-cookie-consent-modal-actions':
            return mapCookieConsentModalActionsParamsFromRest(component.componentParams);

        case 'cc-cookie-consent-modal-categories':
            return mapCookieConsentModalCategoriesParamsFromRest(component.componentParams);

        case 'cc-recommended-jobs-widget':
            return mapRecommendedJobsWidgetParamsFromRest(component.componentParams);

        case 'cc-category-tiles':
            return mapCategoryTilesParamsFromRest(component.componentParams);

        case 'cc-search-bar':
            return mapSearchBarParamsFromRest(component.componentParams);

        case 'cc-search-filters-panel':
            return mapSearchFiltersParamsFromRest(component.componentParams);

        case 'cc-search-results':
            return mapSearchResultsParamsFromRest(component.componentParams);

        case 'cc-job-details-title':
            return mapJobDetailsTitleParamsFromRest(component.componentParams);

        case 'cc-job-details-location':
            return mapJobDetailsLocationParamsFromRest(component.componentParams);

        case 'cc-job-details-apply-button':
            return mapJobDetailsApplyButtonParamsFromRest(component.componentParams);

        case 'cc-job-details-skills':
            return mapJobDetailsSkillsParamsFromRest(component.componentParams);

        case 'cc-job-details-media':
            return mapJobDetailsMediaParamsFromRest(component.componentParams);

        case 'cc-job-details-info':
            return mapJobDetailsInfoParamsFromRest(component.componentParams);

        case 'cc-job-details-tags':
            return mapJobDetailsTagsParamsFromRest(component.componentParams);

        case 'cc-job-details-similar-jobs':
            return mapJobDetailsSimilarJobsParamsFromRest(component.componentParams);

        case 'cc-job-details-job-fit':
            return mapJobDetailsJobFitParamsFromRest(component.componentParams);

        case 'cc-job-details-description':
        case 'cc-job-details-organization-description':
        case 'cc-job-details-responsibilities':
        case 'cc-job-details-qualifications':
        case 'cc-job-details-corporate-description':
            return mapJobDetailsDescriptionParamsFromRest(component.componentParams);

        default:
            return notReachable(componentType);
    }
};
