import { observable, unwrap } from 'knockout';
import a11yEvents from 'core/a11y/events';
import i18n from 'core/i18n/i18n';

const COMPONENT_MAP = {
    ora_image: 'media-image-slide',
    image: 'media-image-slide',
    ora_video: 'media-video-slide',
    video: 'media-video-slide',
    ora_map: 'media-map-slide',
    map: 'media-map-slide',
};

export default class MediaSliderViewModel {

    constructor({ className, isAdminMode, slides }) {
        this.className = observable(className);
        this.slides = unwrap(slides);
        this.activeIndex = observable(0);
        this.isAdminMode = isAdminMode;

        this.MAX_INDEX = this.slides.length - 1;
        this.isPrevButtonFocused = observable(false);
        this.isNextButtonFocused = observable(false);
    }

    next() {
        const newIndex = this.activeIndex() + 1;

        this.activeIndex(newIndex >= this.MAX_INDEX ? this.MAX_INDEX : newIndex);

        if (this.isLast()) {
            this.isPrevButtonFocused(true);
        }

        a11yEvents.status.dispatch(i18n('job-details.a11y.media-loaded'));
    }

    previous() {
        const newIndex = this.activeIndex() - 1;

        this.activeIndex(newIndex <= 0 ? 0 : newIndex);

        if (this.isFirst()) {
            this.isNextButtonFocused(true);
        }

        a11yEvents.status.dispatch(i18n('job-details.a11y.media-loaded'));
    }

    getComponentType(slide) {
        if (!slide?.mediaTypeCode()) {
            return COMPONENT_MAP[slide.type().toLowerCase()];
        }

        return COMPONENT_MAP[slide.mediaTypeCode().toLowerCase()];
    }

    isLast() {
        return this.activeIndex() >= this.MAX_INDEX;
    }

    isFirst() {
        return this.activeIndex() === 0;
    }

}
