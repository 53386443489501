import oj from 'ojs/ojcore';
import MinMaxDateValidator from './MinMaxDate';

export default class MaxDateValidator extends MinMaxDateValidator {

    constructor(...args) {
        super(...args);
        this._message = 'validators.max-date';
    }

    _validate(isoDate, options) {
        if (!isoDate) {
            return true;
        }

        try {
            const date = oj.IntlConverterUtils.isoToLocalDate(isoDate);
            const maxDate = oj.IntlConverterUtils.isoToLocalDate(options.max);

            return options.inclusive ? date <= maxDate : date < maxDate;
        } catch (err) {
            return false;
        }
    }

}