import ko from 'knockout';
import './binding/awliWidget';
import OAuthService from './service/oauth';
import MetadataService from './service/Metadata';
import profileImportService from './service/profileImport';
import ProfileImportConfig from './model/ProfileImportConfig';
import candidateModel from 'apply-flow/model/candidate';
import appEvents from 'app/config/events';
import profileImportEvents from './config/events';
import downloader from 'ce-common/module/downloader/service/downloader';
import referralService from 'candidate-verification/service/referral';
import router from 'app/model/router';
import Base64FileReader from 'core/base64-file-reader/Base64FileReader';
import { RESUME } from '../file-upload/config/attachmentCategories';
import fileUploadFormBuilder from '../file-upload/service/fileUploadFormBuilder';
import i18n from 'core/i18n/i18n';
import signals, {
    PROFILE_IMPORTS_FROM_INDEED,
    PROFILE_IMPORTS_FROM_LINKEDIN,
    PROFILE_IMPORTS_FROM_RESUME,
} from 'app/module/cx/config/userEvents';
import { profileImportTypes } from './config/importTypes';
import { isCWKAndCWKAddressUpdateDisabled } from 'apply-flow/service/cwkAddressCheck';
import { addressBackupInstance } from '../../mapper/addressBackup';

const profileImportSignals = {
    linkedin: PROFILE_IMPORTS_FROM_LINKEDIN,
    indeed: PROFILE_IMPORTS_FROM_INDEED,
};

export default class ProfileImportViewModel {

    constructor() {
        this.metadataService = new MetadataService();
        this.oauthService = new OAuthService();
        this.config = ko.observable(new ProfileImportConfig());

        this.selectedFile = ko.observable('').extend({ notify: 'always' });
        this.hasImportSucceded = ko.observable(null);
        this.isResumeParsingInProgress = ko.observable(false);

        this.isReferralResumeParsing = ko.observable(false);
        this.referral = ko.observable();
        this.referralResume = ko.observable();
        this.isResumeValid = ko.observable(true);
        this.profileImportFeedbackMessage = ko.observable('');
        this.profileImportSubscriptions = [];

        this.resumeUploadElement = this._getResumeFormElement();

        this.downloadReferralResume = this.downloadReferralResume.bind(this);
        this.importFromReferralResume = this.importFromReferralResume.bind(this);

        this._importHandler = profileData => this._importEventHandler(profileData);
        profileImportEvents.awliProfileImportStarted.add(this._importHandler);

        this._handleProfileImportEvents();

        this.metadataService.getProviderConfig()
            .then(this.config.bind(this))
            .then(this._initializeReferralResumeParsing.bind(this));
    }

    _handleProfileImportEvents() {
        this.profileImportSubscriptions.push(
            profileImportEvents.indeedProfileImportEnded.add((result) => {
                const { status, isReturningCandidate } = result;
                let message = '';

                if (status === 'success') {
                    message = i18n('apply-flow.profile-import.success-label');
                } else if (isReturningCandidate) {
                    message = i18n('apply-flow.profile-import.import-from-indeed.error-profile-import-block-present-returning-candidate');
                } else {
                    message = i18n('apply-flow.profile-import.import-from-indeed.error-profile-import-block-present-new-candidate');
                }

                this.isResumeParsingInProgress(false);
                this.profileImportFeedbackMessage(message);
                this.hasImportSucceded(status === 'success');
            }));

        this.profileImportSubscriptions.push(
            profileImportEvents.recommendedJobProfileImport.add(({ status, message }) => {
                switch (status) {
                    case 'success':
                        this.isResumeParsingInProgress(false);
                        this.profileImportFeedbackMessage(i18n('apply-flow.profile-import.success-label'));
                        this.hasImportSucceded(true);
                        break;

                    case 'error':
                        this.isResumeParsingInProgress(false);
                        this.profileImportFeedbackMessage(i18n(message || 'apply-flow.profile-import.error-label'));
                        this.hasImportSucceded(false);
                        break;

                    default:
                        this.isResumeParsingInProgress(true);
                        this.hasImportSucceded(null);
                }
            }));
    }

    _getResumeFormElement() {
        const resumeFormConfig = {
            name: 'resume-upload',
            label: 'apply-flow.section-personal-information.connect-with-cv-button',
            sizeLimit: 5,
            acceptedFileExtensions: '.txt, .rtf, .doc, .docx, .pdf, .odt, .htm, .html',
        };

        return fileUploadFormBuilder.createForm(resumeFormConfig)
            .getElement(resumeFormConfig.name);
    }

    _importEventHandler(profileData) {
        this._clearBeforeImport();
        this.isResumeParsingInProgress(true);

        profileImportService.importAwliProfile(profileData)
            .then(candidateRest => profileImportService.fillApplyFlow(candidateRest, profileImportTypes.linkedin))
            .catch(error => this._errorHandler(error));
    }

    importProfile(profileType) {
        if (profileImportSignals[profileType]) {
            signals[profileImportSignals[profileType]].dispatch();
        }

        this._clearBeforeImport();
        this.hasImportSucceded(null);

        const configParams = ko.toJS(this.config()[profileType]);

        this.oauthService.initialize(configParams);

        this.oauthService.socialLogin((oauthToken) => {
            if (!oauthToken) {
                // oauth failed; show error
                appEvents.error.dispatch();
                this.hasImportSucceded(false);
                this.profileImportFeedbackMessage(i18n('apply-flow.profile-import.error-label'));

                return;
            }

            if (oauthToken !== 'user_denied_access') {
                const params = {
                    bProfileType: configParams.code,
                };

                profileImportService.getProfile(params, oauthToken)
                    .then(candidateRest =>
                        profileImportService.fillApplyFlow(candidateRest, profileImportTypes[profileType]))
                    .catch(error => this._errorHandler(error));
            }
        });
    }

    downloadReferralResume() {
        downloader.download(Promise.resolve({
            fileName: this.referralResume().fileName,
            fileContents: this.referralResume().fileContents,
            mimeType: this.referralResume().mimeType,
        }));
    }

    importFromReferralResume() {
        this.isResumeParsingInProgress(true);

        profileImportService.parseReferrerAttachment(this.referralResume())
            .then(candidateRest => profileImportService.fillApplyFlow(candidateRest, profileImportTypes.resume))
            .catch(error => this._errorHandler(error));
    }

    importAgain() {
        this.selectedFile(null);
        this.hasImportSucceded(null);
    }

    onFileSelected(data, event) {
        signals[PROFILE_IMPORTS_FROM_RESUME].dispatch();

        const [file] = event.target.files;

        this._addFile(file)
            .catch(console.error);
    }

    _initializeReferralResumeParsing() {
        const referralId = referralService.getReferralId(router.routeParams().jobId);

        if (referralId) {
            referralService.get(referralId)
                .then(this.referral.bind(this))
                .then(referralService.getResume.bind(referralService, referralId))
                .then(this.referralResume.bind(this))
                .then(() => {
                    const hasReferralAttachment = Boolean(this.referralResume().sourceAttachmentId);
                    const isResumeParserEnabled = this.config().resumeParser.activeFlag();

                    this.isReferralResumeParsing(hasReferralAttachment && isResumeParserEnabled);
                })
                .catch(error => this._errorHandler(error));
        }
    }

    async _addFile(file) {
        if (!file) {
            return;
        }

        const isValid = await this.resumeUploadElement.validate(file);

        this.isResumeValid(isValid);

        if (!isValid) {
            return;
        }

        const reader = new Base64FileReader();

        this.isResumeParsingInProgress(true);

        const encodedFile = await reader.encode(file);

        this._readerOnLoad(encodedFile, file.name);
    }

    _clearBeforeImport() {
        if (isCWKAndCWKAddressUpdateDisabled(addressBackupInstance.get())) {
            return;
        }

        candidateModel.address.country(undefined);
    }

    _parseAttachment(attachmentData) {
        this._clearBeforeImport();

        profileImportService.parseAttachment(attachmentData)
            .then(candidateRest => profileImportService.fillApplyFlow(candidateRest, profileImportTypes.resume))
            .catch(error => this._errorHandler(error));
    }

    _readerOnLoad(encodedFile, fileName) {
        const attachmentData = {
            CategoryName: RESUME,
            DatatypeCode: 'document',
            Title: fileName,
            FileContents: encodedFile,
        };

        this._parseAttachment(attachmentData);
    }

    _errorHandler(error) {
        console.error(error);
        this.hasImportSucceded(false);
        this.isResumeParsingInProgress(false);
        this.profileImportFeedbackMessage(i18n('apply-flow.profile-import.error-label'));
    }

    dispose() {
        this.profileImportSubscriptions.forEach(subscription => subscription.detach());
    }

}
