import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    action: 'CREATE',
    flowVersionId: null,
    sectionId: null,
    contentTypeId: null,
    contentItemId: null,
    certificationId: null,
    certificationName: null,
    stateProvinceCode: null,
    countryCode: null,
    issuedBy: null,
    certificationNumber: null,
    issueDate: null,
    originalIssueYear: null,
    expirationDate: null,
    renewalDate: null,
    lastRenewalDate: null,
    renewalInprogressFlag: null,
    renewalRequiredFlag: null,
    actualCompletionDate: null,
    status: null,
    verifiedFlag: null,
    comments: null,
    educationalEstablishmentId: null,
    educationalEstablishment: null,
    completedTrainingUnits: null,
    restrictions: null,
    totalAmount: null,
    completedAmount: null,
    certificationUrl: null,
    title: null,
};

export default class CandidateLicensesAndCertificates extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'certificationId');
    }

}
