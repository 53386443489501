import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

export function hasJobAlertPreference(candidate) {
    const tcOptInEnabled = isTCOptInEnabled();
    const { hasEmptySitePreferences, subscribeToJobAlert } = candidate;

    return tcOptInEnabled && (hasEmptySitePreferences() || subscribeToJobAlert());
}

export function hasMarketingPreference(candidate) {
    const { preferences } = candidate;

    return Boolean(Object.values(preferences).length);
}
