import xhr from './xhr';
import fetch from './fetch';
import jsonp from './jsonp';

const ajax = (window.fetch ? fetch : xhr);

export default {
    getChannel(method) {
        return (method === 'JSONP' ? jsonp : ajax);
    },
};