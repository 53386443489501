import i18n from 'core/i18n/i18n';
import FormTileStrategy from 'apply-flow/module/tile-profile-items/model/FormTileStrategy';
import tileDateFormatter from 'apply-flow/module/tile-profile-items/service/tileDateFormatter';

const UNNAMED_LICENSE_LABEL = i18n('apply-flow.profile-item.unnamed-license');
const PRESENT_LABEL = i18n('apply-flow.profile-item.time-present');
const VALID_UNTIL_LABEL = i18n('apply-flow.profile-item.valid-until');

export default class LicenseFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return {
            name: 'contentItemId',
            certificationName: 'certificationName',
            title: 'title',
            issueDate: 'issueDate',
            expirationDate: 'expirationDate',
        };
    }

    buildTitle({ title, name, certificationName }) {
        return title || certificationName || name || UNNAMED_LICENSE_LABEL;
    }

    buildSubtitle(valueMap) {
        if (valueMap.issueDate) {
            if (valueMap.expirationDate) {
                return tileDateFormatter.formatDateRange(valueMap.issueDate, valueMap.expirationDate);
            }

            const issueDate = tileDateFormatter.formatDate(valueMap.issueDate);

            return `${issueDate} - ${PRESENT_LABEL}`;
        }

        if (valueMap.expirationDate) {
            const expirationDate = tileDateFormatter.formatDate(valueMap.expirationDate);

            return `${VALID_UNTIL_LABEL} ${expirationDate}`;
        }

        return null;
    }

}