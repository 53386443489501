import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { PureComputed } from 'knockout';
import { CookieConsentModalPolicyCustomizationParams, CookieConsentModalPolicyParams } from './config/types';
import { CookieConsentWidgetData } from '../../types';
import { getCustomStylesCookiePolicy } from './service/customStyles';
import { getCookieConsentPolicySelectors } from './config/selectors';

type Props = {
    pageData: PureComputed<CookieConsentWidgetData>;
    params?: CookieConsentModalPolicyParams;
    id?: string;
};

export class CookieConsentModalPolicyViewModel extends CustomComponentViewModel<CookieConsentModalPolicyParams> {
    pageData: PureComputed<CookieConsentWidgetData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof CookieConsentModalPolicyCustomizationParams): string {
        return getCustomStylesCookiePolicy(
            key,
            getCookieConsentPolicySelectors(`.${this.uniqueWrapperClass}`),
            this.customizationParams
        );
    }
}
