import SearchFiltersPanelHorizontalViewModel from '../search-filters-panel-horizontal/SearchFiltersPanelHorizontalViewModel';
import { FiltersParams } from '../search-filters-panel/config/types';
import { Facet } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { Observable, ObservableArray } from 'knockout';

type Props = {
    adminMode: boolean;
    areEventsEnabled: boolean;
    totalEventsCount: number;
    totalJobsCount: number;
    loading: boolean;
    totalCount: Observable<number>;
    filters: ObservableArray<Facet>;
    filtersOpened: Observable<boolean>;
    customizationParams: FiltersParams;
    uniqueWrapperClass: Observable<string>;
    defaultSearchContext: string;
    selectedSearchContext: Observable<string>;
};

export default class SearchFiltersPanelVerticalViewModel extends SearchFiltersPanelHorizontalViewModel {
    filtersOpened: Observable<boolean>;
    selectedSearchContext: Observable<string>;

    constructor({
        adminMode,
        areEventsEnabled,
        totalEventsCount,
        totalJobsCount,
        loading,
        totalCount,
        filters,
        filtersOpened,
        customizationParams,
        uniqueWrapperClass,
        defaultSearchContext,
        selectedSearchContext,
    }: Props) {
        super({
            adminMode,
            areEventsEnabled,
            totalEventsCount,
            totalJobsCount,
            loading,
            totalCount,
            filters,
            customizationParams,
            uniqueWrapperClass,
            defaultSearchContext,
            selectedSearchContext,
        });

        this.selectedSearchContext = selectedSearchContext;
        this.filtersOpened = filtersOpened;
        this.toggleFilters = this.toggleFilters.bind(this);
    }

    clearFilters(): void {
        this.filtersOpened(false);

        super.clearFilters();
    }

    toggleFilters(): void {
        this.filtersOpened(!this.filtersOpened());
    }

    onChangeSearchContext = (searchContext: string): void => {
        this.selectedSearchContext(searchContext);
    };
}
