import {
    ORA_ADDRESS,
    ORA_SENSITIVE_PERSONAL_INFO,
    ORA_AGREEMENTS,
    ORA_ESIGNATURE,
} from '../../enum/blockTypes';
import AddressModel from 'apply-flow/module/personal-information-address/model/Address';

export class BasicBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        this.blocks = new Map([
            [ORA_ADDRESS, this.removeAddressData],
            [ORA_SENSITIVE_PERSONAL_INFO, this.removeSensitiveInformationData],
            [ORA_AGREEMENTS, this.removeAgreementsData],
            [ORA_ESIGNATURE, this.removeEsignature],
        ]);

        return this.blocks;
    }

    getBlockIdentifier() {
        return undefined;
    }

    removeEsignature({ application }) {
        application.eSignature.fullName('');
    }

    removeAgreementsData({ candidate }) {
        candidate.subscribeToJobAlert = false;
        candidate.preferences.optIn = false;
    }

    removeAddressData({ candidate }) {
        candidate.address = new AddressModel();
    }

    removeSensitiveInformationData({ candidate }) {
        candidate.personalIdentifyingInformationItems = [];
        candidate.dateOfBirth = undefined;
    }

}
