export default {
    resolveAll(batchPromiseMap, batchResponseMap) {
        Object.keys(batchPromiseMap).forEach((key) => {
            const batchId = key;
            const singleBatchResponse = batchResponseMap[batchId];
            const batchPromiseResolve = batchPromiseMap[batchId].resolve;

            batchPromiseResolve(singleBatchResponse);
        });

        return Promise.resolve();
    },
};