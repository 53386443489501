import EqualsValidator from './Equals';

export default class PhoneEqualsValidator extends EqualsValidator {

    _validate(value, options) {
        const { source } = options;

        if (value) {
            const areCountryCodesEqual = value.countryCode === source.countryCode;
            const areNumbersEqual = `${value.areaCode}${value.number}` === `${source.areaCode}${source.number}`;

            return areCountryCodesEqual && areNumbersEqual;
        }

        return true;
    }

}