import httpMapper from 'core/http/mapper';

import {
    AttachmentedResumePayloadKeys,
    MappedResumeParserInputToRest,
    ResumeParserMetadata,
    RecommendedJobsMetadata,
    RecommendedJobsKeys,
} from '../config/types';
import { Dictionary } from 'app/types/utils';

export const mapResumeParserInputToRest = (
    attachmentData: Record<AttachmentedResumePayloadKeys, string>
): MappedResumeParserInputToRest => {
    return {
        profileType: 'rp',
        fileTitle: attachmentData.title,
        fileContents: attachmentData.fileContents,
        languages: [{ ProfileItemId: 1 }],
        licensesAndCertifications: [{ ProfileItemId: 2 }],
        educations: [{ ProfileItemId: 3 }],
        attachments: [{ Id: 4 }],
        workExperiences: [{ ProfileItemId: 5 }],
        workPreferences: [{ ProfileItemId: 6 }],
        skills: [{ ProfileItemId: 7 }],
    };
};

const RECOMMENDED_JOBS_METADATA: Dictionary<string> = {
    skills: 'skillData',
    workExperiences: 'workExperienceData',
    educations: 'educationData',
} as const;

export const flattenResumeParserResponseToRecommendedJobsMetadata = (
    resumeParserMetadataList: ResumeParserMetadata
): RecommendedJobsMetadata => {
    return Object.entries(RECOMMENDED_JOBS_METADATA).reduce(
        (acc, [keyName, mappedKeyName]) => {
            const mappedKeyValue = (
                resumeParserMetadataList[keyName as RecommendedJobsKeys].shift() as Dictionary<string>
            )[mappedKeyName];

            const parsedData = httpMapper.mapFromRest(JSON.parse(mappedKeyValue)) ?? [];

            return {
                ...acc,
                [keyName]: parsedData,
            };
        },
        { displayName: resumeParserMetadataList['displayName'] } as RecommendedJobsMetadata
    );
};
