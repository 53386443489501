import ko from 'knockout';
import ValidatableForm from 'core/form/ValidatableForm';
import formElementFactory from 'core/form/element/factory';

export default {
    createForm(baseConfig) {
        const config = ko.utils.extend({
            elements: [],
        }, baseConfig);

        config.elements = config.elements.map((element) => {
            element.validateImmediately = config.validateImmediately;

            return this.createFormElement(element);
        });

        return new ValidatableForm(config);
    },

    createFormElement(elementDef) {
        const formElement = formElementFactory.create(elementDef.type, elementDef.name);

        return this._initFormElement(formElement, elementDef);
    },

    _initFormElement(formElement, elementDef) {
        formElement.label(elementDef.label);
        formElement.instructions(elementDef.instructions);
        formElement.helpText(elementDef.helpText);
        formElement.defaultValue(elementDef.defaultValue);
        formElement.value(elementDef.value);
        formElement.isHidden(elementDef.isHidden);
        formElement.isLabelHidden(elementDef.isLabelHidden);
        formElement.attributes(elementDef.attributes);
        formElement.validators(elementDef.validators);
        formElement.validateImmediately(elementDef.validateImmediately);

        if (formElement.options) {
            formElement.options(elementDef.options);

            if (formElement.withEmptyOption && 'withEmptyOption' in elementDef) {
                formElement.withEmptyOption(elementDef.withEmptyOption);
            }
        }

        if (elementDef.checked) {
            formElement.checkedValue = elementDef.checked;
        }

        if (elementDef.unchecked) {
            formElement.uncheckedValue = elementDef.unchecked;
        }

        if (formElement.dictionary) {
            formElement.dictionary(elementDef.dictionary);
        }

        if (formElement.dependencyField) {
            formElement.dependencyField(elementDef.dependencyField);
        }

        if (formElement.optionsKeys && elementDef.optionsKeys) {
            formElement.optionsKeys(elementDef.optionsKeys);
        }

        return formElement;
    },
};