import { observable, Observable, ObservableArray, PureComputed, pureComputed } from 'knockout';
import { searchJobResults } from '../../model/searchJobResults';
import { getCustomStyles } from '../search-job-results/config/customStyles';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import { getTileComponents } from '../search-job-results/config/tileComponents';
import { getPaginationState, PaginationState } from '../search-pagination/service/pagination';
import router from 'app/model/router';
import { search as searchService } from 'minimal/module/search/service/search';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import {
    getJobsSelectedSortOption,
    getJobsSortingOptions,
} from '../search-sorting-selector/service/searchJobsSortOptionsService';
import SearchQueryBuilder, { buildSortOption } from 'app/module/cx/module/search/model/SearchQueryBuilder';
import {
    HeaderInformationItem,
    JobInfoDisplayedItem,
    JobTagsInformationItem,
    LayoutStyle,
    MainContentInformationItem,
    SearchResultsParams,
} from 'minimal/module/search/component/search-results/config/types';
import {
    noSearchResultsParams,
    RouterParams,
    RouterQuery,
    SEARCH_RESULTS_STRINGS,
    SortOption,
    JobInfoDisplay,
    JobInfoSeparator,
} from 'cx/module/search/config/types';
import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { isSpellCheckEnabled } from 'minimal/module/search/service/spellCheck';
import '../spell-check';
import { Job } from 'minimal/module/job-details/types';
import {
    getStoredQuery,
    saveSearchContextParamInStorage,
    clearSearchParams,
} from '../../service/storeSearchContextDetails';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';
import Page from 'site-editor/model/Page';
import { CustomRecommendedJobsWidgetParams } from 'minimal/component/recommended-jobs-widget/config/types';
import { getPageComponentByType } from 'custom-content/service/getPageComponents';
import recommendedJobsEvents from 'minimal/config/events';
import { SignalBinding } from 'signals';
import { areRecommendedJobsEnabledWithParams } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { getComponentClass } from 'minimal/module/search/component/search-job-results/config/getComponentClass';
import { NON_TOGGLE_DISPLAY_STYLE_LIST } from 'site-editor/module/search-editor/component/cc-search-results-editor/config/defaultParams';
import { DisplayStyle, ComponentClass } from 'cx/module/search/config/types';
import { isCustomPageRoute } from 'core/router/service/isCustomPageRoute';

type Props = {
    params: SearchResultsParams;
    uniqueWrapperClass: string;
    pageData: Page;
};

export default class SearchJobResultsViewModel {
    customizationParams: SearchResultsParams;
    tileComponent: string;
    talentCommunityTileComponent: string;
    loading: Observable<boolean> | null;
    initialized: Observable<boolean> | null;
    requisitions: ObservableArray<Job> | null;
    hasResults: PureComputed<boolean | null> | null;
    listClass: PureComputed<string>;
    componentClass: PureComputed<ComponentClass>;
    sortOptions: PureComputed<SortOption[]>;
    selectedSortOption: PureComputed<SortOption>;
    customCss: PureComputed<string>;
    paginationState: PureComputed<PaginationState>;
    isElasticSearchEnabled: boolean;
    keyword: Observable<string>;
    correctedKeyword: Observable<string>;
    useExactKeywordFlag: Observable<boolean>;
    suggestedKeyword: Observable<string>;
    isSpellCheckEnabled: boolean;
    headerInformation: HeaderInformationItem[];
    JobInfo: JobInfoDisplayedItem[];
    jobTagsInformation: JobTagsInformationItem[];
    mainContentInformation: MainContentInformationItem[];
    isImageVisible: boolean;
    resumeParsingSuccessful: Observable<boolean>;
    resumeReuploadEvent: Observable<boolean>;
    recommendedJobsParams: CustomRecommendedJobsWidgetParams | undefined;
    recommendedJobsEventsSubscription: SignalBinding<boolean>;
    recommendedJobsReuploadSubscription: SignalBinding<boolean>;
    areRecommendedJobsEnabled: PureComputed<boolean>;
    jobDisplayStyle: PureComputed<DisplayStyle>;
    jobInfoDisplay: PureComputed<JobInfoDisplay>;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
    layoutStyle: Observable<LayoutStyle>;
    isMapViewEnabled: Observable<boolean>;
    isMapAlreadyLoaded: Observable<boolean>;
    showStaticMap: Observable<boolean>;
    noSearchResultsParams: noSearchResultsParams;
    isCustomPage: boolean;

    constructor({ params, uniqueWrapperClass, pageData }: Props) {
        this.customizationParams = params || mapParamsConfigurationToObservable(emptySearchResultsParams);

        this.jobDisplayStyle = pureComputed(() => this.customizationParams.contentParams.jobDisplayStyle());

        this.jobInfoDisplay = pureComputed(() => this.customizationParams.contentParams.jobInfoDisplay());

        this.jobInfoSeparator = pureComputed(() => this.customizationParams.contentParams.jobInfoSeparator());

        this.layoutStyle = this.customizationParams.contentParams.layoutStyle;

        const { tile, talentCommunityTile } = getTileComponents(this.jobDisplayStyle(), 'active');

        this.isSpellCheckEnabled = isSpellCheckEnabled();
        this.keyword = searchJobResults.keyword;
        this.correctedKeyword = searchJobResults.correctedKeyword;
        this.useExactKeywordFlag = searchJobResults.useExactKeywordFlag;
        this.suggestedKeyword = searchJobResults.suggestedKeyword;

        this.tileComponent = tile;
        this.talentCommunityTileComponent = talentCommunityTile;
        this.loading = searchJobResults.loading;
        this.initialized = searchJobResults.initialized;
        this.requisitions = searchJobResults.results;

        this.isElasticSearchEnabled = mapTrueFalseStringToBoolean(
            appConfig.getSettingByKey('IRC_ELASTIC_SEARCH_ENABLED')
        );

        this.resumeParsingSuccessful = observable<boolean>(true);
        this.resumeReuploadEvent = observable<boolean>(false);

        this.areRecommendedJobsEnabled = pureComputed(
            () => areRecommendedJobsEnabled() && areRecommendedJobsEnabledWithParams()
        );

        this.recommendedJobsEventsSubscription = recommendedJobsEvents.resumeParsingSuccessful.add(
            (resumeParsingState: boolean) => this.resumeParsingSuccessful(resumeParsingState)
        );

        this.recommendedJobsReuploadSubscription = recommendedJobsEvents.resumeReuploadEvent.add(
            (reuploadEvent: boolean) => this.resumeReuploadEvent(reuploadEvent)
        );

        this.recommendedJobsParams = pageData
            ? getPageComponentByType(pageData.sections ?? [], 'cc-recommended-jobs-widget') ?? {}
            : undefined;

        this.hasResults = pureComputed(this._hasResults, this);
        this.listClass = pureComputed(this._getListClass, this);
        this.headerInformation = this.customizationParams.contentParams.headerInformationDisplayed();
        this.jobTagsInformation = this.customizationParams.contentParams.jobTagsInformationDisplayed();

        this.mainContentInformation =
            this.customizationParams.contentParams.mainContentInformationDisplayed();

        this.JobInfo = this.customizationParams.contentParams.jobInfoDisplayed();
        this.isImageVisible = this.mainContentInformation.includes('previewImage');
        this.componentClass = pureComputed(this.getSelectedComponentClass, this);
        this.isMapViewEnabled = observable<boolean>(false);
        this.isMapAlreadyLoaded = observable<boolean>(false);
        this.showStaticMap = observable<boolean>(false);
        this.isCustomPage = isCustomPageRoute();

        this.sortOptions = pureComputed(() => getJobsSortingOptions(router.routeParams()));

        const [firstSortOption]: SortOption[] = this.sortOptions();

        this.selectedSortOption = pureComputed(
            () => getJobsSelectedSortOption(searchJobResults.sortBy() as string) || firstSortOption
        );

        this.customCss = pureComputed(() => getCustomStyles(this.customizationParams, uniqueWrapperClass));

        this.paginationState = pureComputed(() =>
            getPaginationState({
                hasMore: searchJobResults.hasMore,
                loading: searchJobResults.appending,
                loadMore: () => this._loadMore.bind(this),
                isInfiniteScrollEnabled: this.customizationParams.contentParams.enableInfiniteScroll(),
                isMapView: this.isMapViewEnabled,
            })
        );

        this.noSearchResultsParams = {
            context: SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS,
            ctaRoute: SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_JOBS,
            dataQaAttr: 'viewAllJobsBtn',
            beforeRedirect: () => {
                clearSearchParams();

                return Promise.resolve(true);
            },
        };
    }

    dispose(): void {
        this.loading = null;
        this.initialized = null;
        this.requisitions = null;

        this.recommendedJobsEventsSubscription.detach();
        this.recommendedJobsReuploadSubscription.detach();
    }

    _getListClass(): string {
        return [this.customizationParams.commonParams.cssClass(), this.componentClass()]
            .filter((value) => Boolean(value))
            .join(' ');
    }

    _hasResults(): boolean | null {
        return (
            !!this.initialized && this.initialized() && !!this.requisitions && this.requisitions().length > 0
        );
    }

    _loadMore(): void {
        const { isSignedIn } = signedCandidate;
        let { query } = router.routeParams() as { query: RouterQuery };

        if (!query?.sortBy) {
            query = {
                ...query,
                sortBy: searchJobResults.sortBy(),
            };
        }

        searchService.loadMoreJobs(
            {
                limit: this.customizationParams.contentParams.numberOfJobsDisplayed(),
                ...query,
            },
            Boolean(isSignedIn())
        );
    }

    onChangeSortBy(sortOption: SortOption): void {
        searchJobResults.sortBy(buildSortOption(sortOption));

        const query = getStoredQuery(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS);
        const jobSearchQuery = new SearchQueryBuilder(query).withSortOption(sortOption).build();

        if (areEventsEnabled()) {
            saveSearchContextParamInStorage(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS, {
                query: jobSearchQuery,
            } as RouterParams);
        }

        router.go(
            'search',
            {
                query: jobSearchQuery,
            },
            { inherit: true }
        );
    }

    private getSelectedComponentClass(): ComponentClass {
        if (NON_TOGGLE_DISPLAY_STYLE_LIST.includes(this.jobDisplayStyle())) {
            this.isMapViewEnabled(false);
        }

        return getComponentClass(this.jobDisplayStyle());
    }
}
