import { WorkplaceType } from 'app/module/cx/config/constants/workplaceType';
import i18n from 'core/i18n/i18n';
import { Job } from 'minimal/module/job-details/types';
import { JobMetaTagKey } from 'site-editor/module/job-details-editor/component/cc-job-details-info/config/jobMetaTypes';
import { RequisitionFlexField } from 'minimal/module/job-details/types/index';

export type JobAttribute =
    | {
          name: string;
          value: string | string[] | null | undefined;
          id?: string;
          sequenceNumber?: number;
          type?: string;
          order?: string;
      }
    | undefined;

export type WorkplaceTypeAttribute = {
    workplaceType: WorkplaceType;
};

export type LocationJobAttribute = JobAttribute & WorkplaceTypeAttribute;

export type AttributesList = Partial<Record<JobMetaTagKey, JobAttribute | LocationJobAttribute>>;

const removeEmptyAttributes = (attributes: AttributesList): AttributesList => {
    Object.keys(attributes).forEach((key) => {
        const isEmpty = Array.isArray(attributes[key as keyof AttributesList]?.value)
            ? attributes[key as keyof AttributesList]?.value?.length
            : attributes[key as keyof AttributesList]?.value;

        if (!isEmpty) {
            delete attributes[key as keyof AttributesList];
        }
    });

    return attributes;
};

const getLocationLabel = (locationValue: string[], lang: string): string => {
    if (locationValue.length) {
        return i18n('job-details.locations-label', null, lang);
    }

    return i18n('job-details.location-label', null, lang);
};

const getLocationValue = ({
    workLocations,
    primaryLocation = null,
    secondaryLocations = [],
}: Job): string[] => {
    if (workLocations?.length) {
        return workLocations;
    }

    return primaryLocation ? [primaryLocation].concat(secondaryLocations || []) : [];
};

export const generateJobFlexFields = ({
    requisitionFlexFields = [],
}: {
    requisitionFlexFields: RequisitionFlexField[];
}): JobAttribute[] => {
    return requisitionFlexFields.filter(({ value }) => value || value.length);
};

export const generateDefaultJobAttributes = (job: Job): AttributesList => {
    const { lang } = job;

    const locationValue = getLocationValue(job);
    const locationLabel = getLocationLabel(locationValue, lang);

    const attributes = {
        id: {
            value: job.id,
            name: i18n('job-details.id-label', null, lang),
            order: '0',
        },
        category: {
            value: job.category,
            name: i18n('job-details.category-label', null, lang),
            order: '1',
        },
        locations: {
            id: 'locations',
            value: locationValue,
            name: locationLabel,
            order: '2',
            workplaceType: {
                name: job.workplaceType,
                code: job.workplaceTypeCode,
            },
        },
        postedDate: {
            value: job.postedDate,
            name: i18n('job-details.posting-date-label', null, lang),
            order: '3',
        },
        postedEndDate: {
            value: job.postedEndDate,
            name: i18n('job-details.closing-date-label', null, lang),
            order: '4',
        },
        studyLevel: {
            value: job.studyLevel,
            name: i18n('job-details.job-study-level-label', null, lang),
            order: '5',
        },
        jobSchedule: {
            value: job.jobSchedule,
            name: i18n('job-details.job-schedule-label', null, lang),
            order: '6',
        },
        jobShift: {
            value: job.jobShift,
            name: i18n('job-details.job-shift-label', null, lang),
            order: '7',
        },
    };

    return removeEmptyAttributes(attributes);
};

export const generateJobAttributes = (job: Job): AttributesList => {
    const { lang } = job;

    const attributes = {
        ...generateDefaultJobAttributes(job),
        otherRequisitionTitle: {
            value: job.otherRequisitionTitle,
            name: i18n('job-details.other-job-title-label', null, lang),
        },
        numberOfOpenings: {
            value: job.numberOfOpenings,
            name: i18n('job-details.number-of-openings-label', null, lang),
        },
        hiringManagerField: {
            value: job.hiringManager,
            name: i18n('job-details.hiring-manager-label', null, lang),
        },
        organization: {
            value: job.organization,
            name: i18n('job-details.organization-label', null, lang),
        },
        jobFunction: {
            value: job.jobFunction,
            name: i18n('job-details.job-function-label', null, lang),
        },
        workerType: {
            value: job.workerType,
            name: i18n('job-details.worker-type-label', null, lang),
        },
        contractType: {
            value: job.contractType,
            name: i18n('job-details.regular-temporary-label', null, lang),
        },
        jobLevel: {
            value: job.jobLevel,
            name: i18n('job-details.management-level-label', null, lang),
        },
        jobType: {
            value: job.jobType,
            name: i18n('job-details.job-type-label', null, lang),
        },
        domesticTravelRequiredField: {
            value: job.domesticTravelRequired,
            name: i18n('job-details.domestic-travel-required-yesno-label', null, lang),
        },
        internationalTravelRequiredField: {
            value: job.internationalTravelRequired,
            name: i18n('job-details.international-travel-required-yesno-label', null, lang),
        },
        workMonths: {
            value: job.workMonths,
            name: i18n('job-details.work-duration-months-label', null, lang),
        },
        workYears: {
            value: job.workYears,
            name: i18n('job-details.work-duration-years-label', null, lang),
        },
        workHours: {
            value: job.workHours,
            name: i18n('job-details.work-hours-label', null, lang),
        },
        workDays: {
            value: job.workDays,
            name: i18n('job-details.work-days-label', null, lang),
        },
        externalContactName: {
            value: job.externalContactName,
            name: i18n('job-details.external-contact-name-label', null, lang),
        },
        externalContactEmail: {
            value: job.externalContactEmail,
            name: i18n('job-details.external-contact-email-label', null, lang),
            type: 'email',
        },
        legalEmployer: {
            value: job.legalEmployer,
            name: i18n('job-details.legal-employer-label', null, lang),
        },
        businessUnit: {
            value: job.businessUnit,
            name: i18n('job-details.business-unit-label', null, lang),
        },
        department: {
            value: job.department,
            name: i18n('job-details.department-label', null, lang),
        },
        jobGrade: {
            value: job.jobGrade,
            name: i18n('job-details.grade-label', null, lang),
        },
    };

    return removeEmptyAttributes(attributes);
};
