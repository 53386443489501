// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import fakeJobDetailsConfig from '../config/fakeJobDetailsConfig.json';
import { getWorkLocations } from 'cx/module/site-editor/module/fake-job-details/config/workLocations';
import { Job } from 'minimal/module/job-details/types';
import mediaMapper from 'cx/module/job-details/mapper/media';

export const getStaticJob = (): Job => {
    fakeJobDetailsConfig.media = mediaMapper.mapFromRest(fakeJobDetailsConfig.media);

    return {
        ...fakeJobDetailsConfig,
        workLocations: getWorkLocations(),
    };
};
