import { Observable } from 'knockout';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import { RouterQuery } from 'app/module/cx/module/search/config/types';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import * as spellCheckService from 'minimal/module/search/service/spellCheck';

type Props = {
    keyword: Observable<string>;
    correctedKeyword: Observable<string>;
    useExactKeywordFlag: Observable<boolean>;
    suggestedKeyword: Observable<string>;
};

export class SpellCheckViewModel {
    keyword: Observable<string>;
    correctedKeyword: Observable<string>;
    useExactKeywordFlag: Observable<boolean>;
    suggestedKeyword: Observable<string>;
    urlForKeyword: string;
    urlForCorrectedKeyword: string;
    urlForSuggestedKeyword: string;

    constructor({ keyword, correctedKeyword, useExactKeywordFlag, suggestedKeyword }: Props) {
        this.keyword = keyword;
        this.correctedKeyword = correctedKeyword;
        this.useExactKeywordFlag = useExactKeywordFlag;
        this.suggestedKeyword = suggestedKeyword;
        this.urlForCorrectedKeyword = this.getUrl(this.correctedKeyword(), false);
        this.urlForKeyword = this.getUrl(this.keyword(), true);
        this.urlForSuggestedKeyword = this.getUrl(this.suggestedKeyword(), false);

        if (this.correctedKeyword()) {
            spellCheckService.storeCorrectedKeyword(this.correctedKeyword());
        } else {
            spellCheckService.removeCorrectedKeyword();
        }

        this.useCorrectedKeyword = this.useCorrectedKeyword.bind(this);
        this.useExactKeyword = this.useExactKeyword.bind(this);
        this.useSuggestedKeyword = this.useSuggestedKeyword.bind(this);
    }

    private prepareQuery(keyword: string, useExactKeywordFlag: boolean): SearchQueryBuilder {
        spellCheckService.removeCorrectedKeyword();

        const { query } = router.routeParams() as { query: RouterQuery };
        const queryBuilder = new SearchQueryBuilder(query).withKeyword(keyword);

        if (useExactKeywordFlag) {
            queryBuilder.withExactKeyword();
        } else {
            queryBuilder.withoutExactKeyword();
        }

        return queryBuilder;
    }

    private getUrl(keyword: string, useExactKeywordFlag: boolean): string {
        const queryBuilder = this.prepareQuery(keyword, useExactKeywordFlag);

        return absoluteRouter.interpolate(
            {},
            'search',
            { query: queryBuilder.build() },
            { inherit: false, encode: true }
        );
    }

    useCorrectedKeyword(): void {
        const queryBuilder = this.prepareQuery(this.correctedKeyword(), false);

        router.go('search', { query: queryBuilder.build() }, { inherit: false, encode: true });
    }

    useExactKeyword(): void {
        const queryBuilder = this.prepareQuery(this.keyword(), true);

        router.go('search', { query: queryBuilder.build() }, { inherit: false, encode: true });
    }

    useSuggestedKeyword(): void {
        const queryBuilder = this.prepareQuery(this.suggestedKeyword(), false);

        router.go('search', { query: queryBuilder.build() }, { inherit: false, encode: true });
    }
}
