import { observable, pureComputed } from 'knockout';
import sectionValidator from 'apply-flow/model/sectionValidator';
import applyFlowEvents from 'apply-flow/config/events';
import fixer from './service/fixer';
import i18n from 'app/module/core/i18n/i18n';

export default class ApplyFlowFixerViewModel {

    constructor({ isMultipage }) {
        this.isMultipage = isMultipage;

        this.isApplicationSubmited = observable(false);
        this.errorCount = sectionValidator.getSummaryErrorCount();

        this.isVisible = pureComputed(this._computeVisibility, this);

        this._onApplicationSubmitting = this._onApplicationSubmitting.bind(this);

        applyFlowEvents.submitHasErrors.add(this._onApplicationSubmitting);

        this.bodyClassName = 'fixer-visible';

        this.fixerText = observable('');
    }

    run() {
        fixer.run(this.isMultipage);
    }

    dispose() {
        applyFlowEvents.submitHasErrors.remove(this._onApplicationSubmitting);
    }

    _onApplicationSubmitting() {
        this.isApplicationSubmited(true);
        this.fixerText(`${i18n('apply-flow.validation.fixer-button-aria-label', { BUTTON_LABEL: i18n('apply-flow.validation.fixer-button-label', { NUMBER_OF_ERRORS: this.errorCount }) })}`);
    }

    _computeVisibility() {
        return this.isApplicationSubmited() && this.errorCount() > 0;
    }

}