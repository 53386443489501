type FacetsMap = {
    locationsEnabled: boolean;
    workLocationsEnabled: boolean;
    workplaceTypesEnabled: boolean;
    categoriesEnabled: boolean;
    organizationsEnabled: boolean;
    titlesEnabled: boolean;
    postingDatesEnabled: boolean;
    facetOrder: string;
};

type FacetsConfig = {
    LOCATIONS_FACET_ENABLED: string;
    WORK_LOCATIONS_FACET_ENABLED: string;
    WORKPLACE_TYPES_FACET_ENABLED: string;
    CATEGORIES_FACET_ENABLED: string;
    ORGANIZATIONS_FACET_ENABLED: string;
    TITLES_FACET_ENABLED: string;
    POSTING_DATES_FACET_ENABLED: string;
    FACET_ORDER: string;
};

export const mapFacetsToConfig = (facets: FacetsMap): FacetsConfig => {
    return {
        LOCATIONS_FACET_ENABLED: String(facets.locationsEnabled),
        WORK_LOCATIONS_FACET_ENABLED: String(facets.workLocationsEnabled),
        WORKPLACE_TYPES_FACET_ENABLED: String(facets.workplaceTypesEnabled),
        CATEGORIES_FACET_ENABLED: String(facets.categoriesEnabled),
        ORGANIZATIONS_FACET_ENABLED: String(facets.organizationsEnabled),
        TITLES_FACET_ENABLED: String(facets.titlesEnabled),
        POSTING_DATES_FACET_ENABLED: String(facets.postingDatesEnabled),
        FACET_ORDER: facets.facetOrder,
    };
};
