import ko from 'knockout';
import formBuilder from 'core/form/formBuilder';
import profileItemsFormBuilder from 'apply-flow/module/profile-items/service/profileItemsFormBuilder';
import TileForm from './TileForm';

export default {
    createForm(TileStrategy, configObj) {
        const config = ko.utils.extend({
            elements: [],
        }, configObj);

        config.elements = config.elements.map((element) => {
            element.validateImmediately = config.validateImmediately;

            return formBuilder.createFormElement(element);
        });

        const form = new TileForm(TileStrategy, config);

        return profileItemsFormBuilder.createForm(config, form);
    },
};