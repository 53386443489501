import ko from 'knockout';
import $ from 'jquery';

/**
 * Binding used to force browser focus to move to
 * the first focusable element within target (if any exist)
 * or to target itself (if none exist).
 *
 * @param {observable} isVisible - observable responsible for target visibility
 * @param {string} resetFocus - DOM selector to focus on when target losses visibility
 * @param {string} focusOn - element selector to focus on when target becomes visible
 * @param {string} focusCallback - custom focus function
 * @param {integer} delay - time in miliseconds after focus will be set - needed in cases where animation is fired
 * @param {integer} resetFocusdelay - time in miliseconds after target losses visibility - set focus to DOM selector
 *
 * @example
 *   <div id="focus-on-me-after-you-close"></div>
 *   <div data-bind="a11y.setInitialFocus: {isVisible: someObservable, resetFocus: '#focus-on-me-after-you-close'}"
 *   <div data-bind="a11y.setInitialFocus: {isVisible: someObservable, delay: 400}"
 */

const TABBABLE_SELECTOR = ':tabbable';

ko.bindingHandlers['a11y.setInitialFocus'] = {
    after: ['hideAfterAnimation'],
    init(element, accessor) {
        const { focusOn, delay, focusCallback, onInit, shouldRemoveTabIndexOnFocus, resetFocusDelay } =
            accessor();

        const $container = $(element);

        // wild hold refrence to element where focus should get back after
        // change of isVisible to false, unless resetFocus is set up (resetFocus has higher priority than initedBy).
        let initedBy;

        $container.attr('tabindex', '-1');

        function setVisibleFocus(elementToFocus) {
            elementToFocus.focus();
            removeTabIndex(elementToFocus);
        }

        function removeTabIndex(elementToFocus) {
            if (shouldRemoveTabIndexOnFocus && accessor().isVisible()) {
                elementToFocus.attr('tabIndex', '-1');
            }
        }

        function setFocus(isVisible) {
            let focusTarget;

            if (!initedBy || !$.contains(element, document.activeElement)) {
                initedBy = document.activeElement;
            }

            if (isVisible) {
                if (focusCallback) {
                    focusCallback($container);

                    return;
                }

                if (focusOn) {
                    focusTarget = $container.find(focusOn).first();
                } else {
                    const $tabbables = $container.find(TABBABLE_SELECTOR);

                    focusTarget = $tabbables.length ? $tabbables.first() : $container;
                }
            } else {
                // place focus back on resetFocus, or initedBy if resetFocus is not defined
                focusTarget = accessor().resetFocus ? $(accessor().resetFocus) : $(initedBy);
            }

            if (resetFocusDelay && !isVisible) {
                setTimeout(() => setVisibleFocus(focusTarget), resetFocusDelay);

                return;
            }

            setVisibleFocus(focusTarget);
        }

        function initFocus(state) {
            if (!delay) {
                setFocus(state);
            } else {
                setTimeout(() => {
                    setFocus(state);
                }, delay);
            }
        }

        if (accessor().isVisible() && onInit) {
            initFocus(accessor().isVisible());
        }

        // Set focus immidiately or after delay
        const subscription = accessor().isVisible.subscribe((state) => {
            initFocus(state);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
            setFocus(false);
        });
    },
};
