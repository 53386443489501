import { observable, observableArray, pureComputed } from 'knockout';
import router from 'app/model/router';
import searchService from 'cx/module/search/service/search';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import i18n from 'app/module/core/i18n/i18n';

const CATEGORIES_FACET_TYPE = 'CATEGORIES';
const POSTING_DATES_TYPE = 'POSTING_DATES';
const NEWEST_VALUE = '30';

const PRIMARY_CATEGORIES_COUNT = 5;

export default class SearchCategoriesViewModel {

    constructor() {
        this.loaded = observable(false);
        this.eventsLoaded = observable(false);
        this.expanded = observable(false);

        this.totalJobsCount = observable(0);
        this.newestJobsCount = observable();
        this.totalEventsCount = observable(0);
        this.areEventsEnabled = areEventsEnabled();

        this.primaryCategories = observableArray();
        this.secondaryCategories = observableArray();

        this.isPostingDatesFacetEnabled = observable(false);
        this.isCategoriesFacetEnabled = observable(false);

        this.queryBuilder = new SearchQueryBuilder();

        this.isExpandButtonVisible = pureComputed(this._computeExpandButtonVisibility, this);

        this.filterByCategory = this.filterByCategory.bind(this);


        searchService.searchFacets()
            .then(this._initSearchResultsModel.bind(this))
            .catch(e => console.error(e));

        if (this.areEventsEnabled) {
            searchService.searchEvents()
                .then(this._initEventsSearchResultsModel.bind(this))
                .catch(e => console.error(e));
        }

        this.allJobsTitle = observable('');
        this.filterNewestTitle = observable('');
        this.viewAllEventsTitle = observable('');
    }

    expand() {
        this.expanded(true);
    }

    _queryWithoutFilters() {
        const queryParams = router.routeParams().query;

        return new SearchQueryBuilder(queryParams).withoutKeyword().withoutLocation()
            .withoutFacets()
            .withoutHotJobs()
            .withoutSortOption();
    }

    viewAll() {
        router.go('search', { query: this._queryWithoutFilters().build() }, { inherit: false });
    }

    viewAllEvents() {
        router.go('search-events', { query: this._queryWithoutFilters().build() }, { inherit: false });
    }

    filterNewest() {
        const queryFilterNewest = this._queryWithoutFilters()
            .withFacet({ type: POSTING_DATES_TYPE, selectedIds: [NEWEST_VALUE] });

        router.go('search', {
            query: queryFilterNewest.build(),
        }, { inherit: false });
    }

    filterByCategory(category) {
        const queryFilterByCategory = this._queryWithoutFilters()
            .withFacet({ type: CATEGORIES_FACET_TYPE, selectedIds: [category.value] });

        router.go('search', {
            query: queryFilterByCategory.build(),
        }, { inherit: false });
    }

    _initSearchResultsModel({ totalJobsCount, facets }) {
        this.totalJobsCount(totalJobsCount);
        this.newestJobsCount(this._getNewestJobsCount(facets));

        const { primaryCategories, secondaryCategories } = this._getCategories(facets);

        this.primaryCategories(primaryCategories);
        this.secondaryCategories(secondaryCategories);

        this.allJobsTitle = `${i18n('search.all-jobs')} ${totalJobsCount}`;
        this.filterNewestTitle = `${i18n('search.new-jobs')} ${this._getNewestJobsCount(facets)}`;

        this.loaded(true);
    }

    _initEventsSearchResultsModel({ totalEventsCount }) {
        this.totalEventsCount(totalEventsCount);
        this.viewAllEventsTitle = `${i18n('search.all-events')} ${totalEventsCount}`;
        this.eventsLoaded(true);
    }

    _getNewestJobsCount(facets) {
        const [postingDates] = facets
            .filter(({ type }) => type === POSTING_DATES_TYPE);

        if (!postingDates) {
            return null;
        }

        const [newestPostingDate] = postingDates.items()
            .filter(({ value }) => value === NEWEST_VALUE);

        if (!newestPostingDate) {
            return null;
        }

        this.isPostingDatesFacetEnabled(true);

        return newestPostingDate.totalCount;
    }

    _getCategories(facets) {
        const [facet] = facets
            .filter(({ type }) => type === CATEGORIES_FACET_TYPE);

        if (!facet) {
            return { primaryCategories: [], secondaryCategories: [] };
        }

        this.isCategoriesFacetEnabled(true);

        const categories = facet.items()
            .sort((categoryA, categoryB) => categoryB.totalCount - categoryA.totalCount);

        return {
            primaryCategories: categories.slice(0, PRIMARY_CATEGORIES_COUNT),
            secondaryCategories: categories.slice(PRIMARY_CATEGORIES_COUNT),
        };
    }

    _computeExpandButtonVisibility() {
        return !this.expanded() && this.secondaryCategories().length;
    }

}
