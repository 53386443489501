import Interval from './Interval';
import TimelineLevel from './TimelineLevel';
import ColorDistributor from '../model/ColorDistributor';

class TimelineProjectionBuilder {

    constructor(intervals) {
        this.levels = [];
        this._entries = intervals;
        this._colorDistributor = ColorDistributor.getInstance();
    }

    push(...interval) {
        this._entries.push(...interval);
    }

    buildProjection(projectionName) {
        this._buildLevels();
        this._assignColors(projectionName);
        this._computeVisibleIntervals();

        return this._flattenEntries();
    }

    _buildLevels() {
        this.levels = [];

        let intervals = Array.prototype.slice.call(this._entries)
            .sort((i1, i2) => Interval.compareLength(i2, i1) || Interval.compareStart(i1, i2));

        while (intervals.length > 0) {
            const currentLevel = new TimelineLevel();

            intervals = intervals.filter(interval => !currentLevel.push(interval));
            this.levels.push(currentLevel);
        }

        return this.levels;
    }

    _assignColors(projectionName) {
        this._entries.forEach((entry) => {
            entry.color = this._colorDistributor.getColor(`${projectionName}-${entry.id}`);
        });
    }

    _computeVisibleIntervals() {
        const { levels } = this;

        for (let i = 0; i < levels.length; i++) {
            for (let j = i + 1; j < levels.length; j++) {
                levels[i].coverWith(levels[j]);
            }
        }
    }

    _flattenEntries() {
        return this.levels.reduce((entries, currentLevel, index) => {
            currentLevel.elements.forEach((item) => {
                item.level = index;
                entries.push(item);
            });

            return entries;
        }, []).sort(Interval.compareStart).reverse();
    }

}

export default TimelineProjectionBuilder;
