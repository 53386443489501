import router from 'app/model/router';
import { RouteParams, CustomWindow, Bot } from '../config/types';
import {
    ODA_CONTEXT,
    UPLOAD_RESUME_FLOW,
    SEARCH_JOBS_FLOW,
    SEARCH_JOBS_UTTERANCE,
    UPLOAD_RESUME_UTTERANCE,
    GREETING_UTTERANCE,
} from '../config/constant';

declare let window: CustomWindow;

export const openBot = (): void => {
    const { bots } = (window as CustomWindow) || {};
    const { query } = router.routeParams() as RouteParams;

    if (!bots || !query) {
        return;
    }

    const { context, flow, utterance, requisitionNumber, qrShortCode } = query;

    if (context?.toLowerCase() === ODA_CONTEXT) {
        bots.openChat();

        if (requisitionNumber) {
            updateUserProperty(bots, { requisitionNumber });
        }

        if (flow) {
            sendFlowSpecificHiddenMessageToODA(bots, flow);
        } else if (utterance) {
            bots.sendMessage(utterance, { hidden: true });
        } else if (qrShortCode) {
            sendFlowSpecificHiddenMessageToODA(bots, SEARCH_JOBS_FLOW);
        } else {
            bots.sendMessage(GREETING_UTTERANCE, { hidden: true });
        }
    }
};

const updateUserProperty = (bots: Bot, value: Record<string, string>): void => {
    bots.updateUser({
        profile: {
            properties: value,
        },
    });
};

const sendFlowSpecificHiddenMessageToODA = (bots: Bot, flow: string): void => {
    let message = GREETING_UTTERANCE;

    switch (flow.toLowerCase()) {
        case UPLOAD_RESUME_FLOW:
            message = UPLOAD_RESUME_UTTERANCE;
            break;
        case SEARCH_JOBS_FLOW:
            message = SEARCH_JOBS_UTTERANCE;
            break;
        default:
            console.error('Unexpected flow, but opening the chat anyway with greeting flow.');
            break;
    }

    bots.sendMessage(message, { hidden: true });
};
