import { session } from './session';
import { makePreparedRequest } from 'core/http/common';
import events from 'app/config/events';
import tokenService from 'candidate-verification/service/token';
import sessionPersistence from './sessionPersistence';
import { isApplyFlowRoute } from '../routes';

export const UNAUTHORIZED = 'unauthorized';

function shouldDestroySession() {
    return !isApplyFlowRoute();
}

export function unauthorizedHandler(requestConfig) {
    return session.extend()
        .then(() =>
            makePreparedRequest(requestConfig),
        )
        .catch(() => {
            if (shouldDestroySession()) {
                tokenService.destroy();
                sessionPersistence.deactivate();
            }

            events.unauthorizedError.dispatch();

            return Promise.reject(UNAUTHORIZED);
        });
}