import { components } from 'knockout';

import ViewModel from '../PersonalInformationBasicViewModel';
import template from '../personal-information-basic.html';
import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/personal-information-basic/service/Metadata';

components.register('personal-information-basic', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_NAME_CONTACT_INFO', {
    component: 'personal-information-basic',
    repository: metadataRepository,
});
