import { Observable, observable, PureComputed, pureComputed, Subscription } from 'knockout';
import i18n from 'core/i18n/i18n';
import { Job, JobDetailsViewData } from './types';
import router from 'app/model/router';
import cxSignals from 'cx/config/events';
import userEvents, { APPLY_FLOW_ENTERED } from 'cx/config/userEvents';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import userTracking from 'cx/service/userTracking';
import {
    getApplyFlowRouting,
    getIndeedApplyFlowRouting,
} from 'cx/module/candidate-verification/service/routes';
import absoluteRouter from 'app/model/absoluteRouter';
import { CUSTOM_JOB_DETAILS_PAGE } from 'cx/module/custom-content/enums/pageTypes';
import customContentService from 'cx/module/custom-content/service/customContent';
import { computeDisplayStyles } from './service/displayStyles';
import { isIndeedOptiExperience } from 'app/service/indeedExperience';
import {
    importIndeedProfile,
    ImportStatus,
} from 'cx/module/apply-flow/module/profile-import/service/optimizedIndeedProfileImport';
import profileImportService from 'cx/module/apply-flow/module/profile-import/service/profileImport';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import { ERROR_NO_PAGE_SET } from 'cx/module/custom-content/service/customContentHelper';

type JobDetailsRouteParams = { jobId: string; isExpired?: boolean };

type ApplyFlowRoutingParams = {
    sectionNumber?: string;
};

type ApplyFlowRouting = {
    route: string;
    params: ApplyFlowRoutingParams;
};

type State = { type: 'loading' } | { type: 'loaded' };

export class JobDetailsViewModel {
    state: Observable<State>;
    viewData: JobDetailsViewData;
    routeParamsSubscription: Subscription;
    jobId: Observable<string>;
    isExpired: Observable<boolean>;
    displayStyles: PureComputed<string>;
    isIndeedMode: Observable<boolean>;

    constructor() {
        const { jobId, isExpired } = router.routeParams() as JobDetailsRouteParams;

        this.jobId = observable(jobId);
        this.isExpired = observable(Boolean(isExpired));
        this.state = observable<State>({ type: 'loading' });
        this.close = this.close.bind(this);
        this.displayStyles = pureComputed(() => computeDisplayStyles(this.viewData.customPage()?.params));
        this.isIndeedMode = observable(isIndeedOptiExperience());

        this.viewData = {
            isApplyInProgress: observable<boolean>(false),
            isDetailsOpened: observable<boolean>(true),
            lang: observable('en'),
            viewType: 'modal',
            isPreviousRouteStored: true,
            goBackLabel: i18n('job-details.a11y.back-button-label'),
            customPage: observable(null),
            close: this.close.bind(this),
            apply: this.apply.bind(this),
            applyWithIndeed: this.applyWithIndeed.bind(this),
        };

        this.routeParamsSubscription = router.routeParams.subscribe(() => {
            const { jobId, isExpired } = router.routeParams() as JobDetailsRouteParams;

            this.jobId(jobId);
            this.isExpired(Boolean(isExpired));
        });

        this.loadCustomPage();

        cxSignals.contentLocaleLang.add((lang: string) => this.loadCustomPage(lang));
    }

    apply(job: Job): void {
        if (this.viewData.isApplyInProgress()) {
            return;
        }

        this.viewData.isApplyInProgress(true);
        cxSignals.toggleLoading.dispatch('apply-now-button');

        userEvents[APPLY_FLOW_ENTERED].dispatch({
            jobId: job.id,
        });

        siteLanguage.store();
        userTracking.trackJobApplicationStart(job.id, 'apply_now');

        getApplyFlowRouting(job.id, this.viewData.viewType === 'modal')
            .then((flowRouting) => {
                this.redirectOnApply(job, flowRouting);
            })
            .catch((error) => {
                console.warn(error);
                this.viewData.isApplyInProgress(false);
            });
    }

    async applyWithIndeed(job: Job): Promise<void> {
        if (this.viewData.isApplyInProgress()) {
            return;
        }

        this.viewData.isApplyInProgress(true);
        cxSignals.toggleLoading.dispatch('apply-now-with_indeed-button');

        userEvents[APPLY_FLOW_ENTERED].dispatch({
            jobId: job.id,
        });

        siteLanguage.store();
        userTracking.trackJobApplicationStart(job.id, 'apply_with_indeed');

        profileImportService.clearIndeedProfileFromCache();

        profileImportEvents.indeedProfileImportEnded.add((data: ImportStatus) => {
            if (data.status == 'success' || data.challengeRequired) {
                this.applyWithIndeedContinue(job, data.challengeRequired || false);
            } else {
                this.viewData.isApplyInProgress(false);
            }
        });

        await importIndeedProfile();
    }

    async applyWithIndeedContinue(job: Job, challengeRequired: boolean): Promise<void> {
        getIndeedApplyFlowRouting(job.id, this.viewData.viewType === 'modal', challengeRequired)
            .then((flowRouting) => {
                this.redirectOnApply(job, flowRouting);
            })
            .catch((error) => {
                console.error(error);
                this.viewData.isApplyInProgress(false);
            });
    }

    redirectOnApply(job: Job, flowRouting: ApplyFlowRouting): void {
        const { route, params } = flowRouting as ApplyFlowRouting;
        const siteLang = siteLanguage.get();

        if (siteLang !== job.lang) {
            const url = absoluteRouter.interpolate({ lang: job.lang }, route, params);

            return router.redirect(url);
        }

        router.go(route, params);
    }

    async loadCustomPage(lang?: string): Promise<void> {
        try {
            const page = await customContentService.getPageByType(CUSTOM_JOB_DETAILS_PAGE, lang);

            this.viewData.customPage(page);
        } catch (error) {
            this.handleError(error);
        }

        cxSignals.isSectionsLoaded.dispatch();

        this.state({ type: 'loaded' });
    }

    handleError(error: string | unknown): void {
        if (error !== ERROR_NO_PAGE_SET) {
            console.error(error);
        }

        this.setDefaultPage();
    }

    setDefaultPage(): void {
        const defaultPage = customContentService.getDefaultPage(CUSTOM_JOB_DETAILS_PAGE);

        this.viewData.customPage(defaultPage);
    }

    close(): void {
        this.viewData.isDetailsOpened(false);
    }

    dispose(): void {
        this.routeParamsSubscription.dispose();
    }
}
