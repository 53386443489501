import { storeShortTokenData, getShortToken } from './shortTokenStorage';
import tokenService from './token';
import router from 'app/model/router';
import unverifiedService from 'candidate-verification/service/unverified';
import postApplyService from 'cx/module/apply-flow-post/service/postApply';
import { isIndeedTrustAuthEnabled, isIndeedOptiExperience } from 'app/service/indeedExperience';
import storageService from 'core/storage/sessionStorage';
import profileImportService from 'apply-flow/module/profile-import/service/profileImport';

const SELF_SERVICE_PATH = 'candidate-self-service';

function findApplyFlowRoute() {
    const isJobPreview = (router.route().parent || {}).id === 'job-preview';

    return isJobPreview
        ? 'job-preview.apply-flow'
        : 'apply-flow';
}

export function canEnterReferralVerification(newRouteParams) {
    storeShortTokenData({ token: newRouteParams.token, jobId: newRouteParams.jobId });

    return Promise.reject({ redirectTo: 'job-details' });
}

export function canEnterEmailVerification() {
    const applyFlowRoute = findApplyFlowRoute();

    return tokenService.accessCodeExists()
        ? Promise.reject({
            redirectTo: applyFlowRoute,
            redirectParams: { sectionNumber: 1 },
        })
        : Promise.resolve();
}

export function redirectToApplyFlow(params) {
    const route = findApplyFlowRoute();

    return router.go(route, {
        sectionNumber: '1',
        sourceContext: params?.sourceContext,
    });
}

export async function getIndeedApplyFlowRouting(jobId, isJobPreview, challengeRequired) {
    const token = tokenService.get();

    if (!isIndeedOptiExperience()) {
        throw new Error('Apply with Indeed optimised experience disabled');
    }

    if (!token) {
        if (challengeRequired) {
            const route = isJobPreview ? 'job-preview.confirm-email' : 'job-details.confirm-email';

            const [profileData] = profileImportService.getIndeedProfileFromCache()?.items;

            const email = profileData?.email;

            storageService.store('unverifiedApplicationsVerificationData', {
                verificationMethod: 'email',
                email,
                phone: null,
            });

            return routeWithIndeedContext(route);
        }

        throw new Error('Incomplete token information');
    }

    if (tokenService.accessCodeExists()) {
        return goToApplyFlowWithIndeedContext(isJobPreview);
    }

    if (isIndeedTrustAuthEnabled()) {
        return goToApplyFlowWithIndeedContext(isJobPreview);
    }

    if (tokenService.getCandidateNumber()) {
        const route = 'job-details.confirm-email';

        return routeWithIndeedContext(route);
    }

    if (unverifiedService.hasAppliedToJob(jobId)
            && unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = 'job-details.confirm-application';

        return { route, params: {} };
    }

    if (unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = 'job-details.confirm-email';

        return routeWithIndeedContext(route);
    }

    return goToApplyFlowWithIndeedContext(isJobPreview);
}

function routeWithIndeedContext(route) {
    return {
        route,
        params: {
            sectionNumber: '1',
            sourceContext: 'indeedApply',
        },
    };
}

function goToApplyFlowWithIndeedContext(isJobPreview) {
    const route = isJobPreview ? 'job-preview.apply-flow' : 'apply-flow';

    return routeWithIndeedContext(route);
}

function calculatePreviewSource() {
    let sourceContext;

    if (router.route().id?.includes(SELF_SERVICE_PATH)) {
        sourceContext = SELF_SERVICE_PATH;
    }

    return sourceContext;
}

export async function getApplyFlowRouting(jobId, isJobPreview) {
    if (tokenService.accessCodeExists()) {
        const shortToken = getShortToken(jobId)?.token;

        const pinCodeSent = shortToken
            && await tokenService.verifyTokenWithChallengeFlag(shortToken)
                .then(response => response?.challengeFlag)
                .catch((error) => {
                    console.error(error);

                    return true;
                });

        storeShortTokenData({ token: shortToken, jobId, pinCodeSent });

        if (!pinCodeSent) {
            const route = isJobPreview ? 'job-preview.apply-flow' : 'apply-flow';

            return { route, params: { sectionNumber: '1', sourceContext: calculatePreviewSource() } };
        }
    }

    if (unverifiedService.hasAppliedToJob(jobId)
        && unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = 'job-details.confirm-application';

        return { route, params: { sourceContext: undefined } };
    }

    if (unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = isJobPreview ? 'job-preview.confirm-email' : 'job-details.confirm-email';

        return { route, params: { sourceContext: undefined } };
    }

    if (getShortToken(jobId)) {
        return { route: 'job-details.pin-verification', params: { sourceContext: undefined } };
    }

    const route = isJobPreview ? 'job-preview.email-verification' : 'job-details.email-verification';

    return { route, params: { sourceContext: undefined } };
}


export function isApplyFlowRoute() {
    const routeId = router.route().id;
    const isApplyFlow = routeId.indexOf('apply-flow') !== -1;
    const isPostApply = postApplyService.isPostApply();

    return isApplyFlow || isPostApply;
}