import FormValidator from 'core/form/validator/Validator';

const commonCode = /^\d{5}([-]?\d{4})?$/;
const canadianCode = /^(?:(?=[^dfioquwz])[a-z])[0-9](?:(?=[^dfioqu])[a-z])( ?[0-9](?:(?=[^dfioqu])[a-z])[0-9])?$/i;

export default class PostalFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.invalid-postal';
    }

    _validate(value) {
        if (value) {
            return commonCode.test(value.trim()) || canadianCode.test(value.trim());
        }

        return true;
    }

}