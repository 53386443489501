import locationMapper from '../mapper/recruitingLocation';
import http from 'core/http/http';
import httpCached from 'core/http/httpCached';
import siteLanguage from 'ce-common/service/language/siteLanguage';

export default {

    getLocationsByTerm(options) {
        if (options.term == null) {
            return Promise.resolve([]);
        }

        let httpMethod = httpCached;

        if (siteLanguage.restore() !== siteLanguage.get()) {
            siteLanguage.store();
            httpMethod = http;
        }

        return httpMethod
            .get(`/recruitingHierarchyLocations?onlyData=true&finder=findBySiteNumberAndWord;SiteNumber=${options.siteNumber},FilterAttributes=GeographyFlatName,SearchTerms=${options.term},StartsWithFlag=true&limit=1000000`)
            .then(locationMapper.mapLocations.bind(locationMapper));
    },
};