import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import interviewSchedulingService from '../../service/interviewScheduling';
import notificationsService from 'cx/service/notifications';

export default class InterviewCancelDialogViewModel {

    constructor({ isVisible, requestId }) {
        this.isVisible = isVisible;
        this.requestId = requestId;

        this.isCancelInProgress = observable(false);
    }

    closeDialog() {
        this.isVisible(false);
    }

    confirmCancel() {
        this.isCancelInProgress(true);

        return interviewSchedulingService.cancelInterview(this.requestId())
            .then(({ message }) => {
                if (message === 'CANCELLED') {
                    notificationsService.success(i18n('interview-scheduling.interview-detail.messages.cancel-success'));
                    router.go('candidate-self-service');
                } else {
                    notificationsService.error(i18n('interview-scheduling.interview-detail.errors.cancel-error'));
                }
            })
            .catch(() => {
                notificationsService.error(i18n('interview-scheduling.interview-detail.errors.cancel-error'));
            })
            .then(() => {
                this.isCancelInProgress(false);
                this.closeDialog();
            });
    }

    dispose() {
        this.isVisible = null;
        this.interviewId = null;
    }

}