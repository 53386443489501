import defaultMapper from 'apply-flow/module/profile-items/mapper/metadata';
import timelineEntryMetadata from '../mapper/timelineEntryMetadata';

export default {
    getMapper(contentTypeId) {
        if (contentTypeId === 106 || contentTypeId === 129) {
            return timelineEntryMetadata;
        }

        return defaultMapper;
    },
};