import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import flowService from 'apply-flow/service/flow';
import tokenService from 'candidate-verification/service/token';
import fadeInTransition from 'core/router/transition/fadeIn';
import notificationsService from 'cx/service/notifications';
import cxEvents from 'cx/config/events';
import challengeService from 'candidate-verification/service/challenge';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';
import {
    addLogger,
    removeLoggerOnApplyFlow,
} from 'apply-flow/config/logger';

router.configure({

    // Initial route, accessed from email
    'post-apply-base': {
        parent: 'cx',
        url: '/submission/{jobId}/{submissionId}/post-apply/{token}',
        view: { },
        canEnter(newRouteParams) {
            if (challengeService.isChallengeRequired()) {
                storeShortTokenData({ token: newRouteParams.token });

                return Promise.reject({ redirectTo: 'post-apply.pin-verification' });
            }

            return new Promise((resolve, reject) => {
                tokenService.verifyToken(newRouteParams.token)
                    .then(() => {
                        newRouteParams.sectionNumber = 1;

                        flowService.setSecondaryFlowData(flowService
                            .createFlowData(tokenService.get().flowId, tokenService.get().stepActionUsageId));

                        reject({ redirectTo: 'post-apply' });
                    })
                    .catch((error) => {
                        if (error === 'token-expired') {
                            notificationsService.error(i18n('general.error-token-expired.header'));
                        } else {
                            notificationsService.error();
                        }

                        console.error(error);
                        reject({ redirectTo: 'home-page' });
                    });
            });
        },
    },

    'post-apply': {
        parent: 'cx',
        url: '/submission/{jobId}/{submissionId}/post-apply-flow/section/{sectionNumber}',
        view: {
            layout: 'apply-flow-post',
        },
        title: i18n('apply-flow.page-title'),
        transition: fadeInTransition,
        canEnter() {
            return new Promise((resolve, reject) => {
                if (tokenService.isCandidateVerified()) {
                    return resolve();
                }

                return reject({
                    redirectTo: 'job-details',
                });
            });
        },
        enter() {
            cxEvents.loaded.dispatch();

            addLogger();
        },
        exit(params, newRouteId) {
            removeLoggerOnApplyFlow(this.id, newRouteId);
        },
    },


    'post-apply.pin-verification': {
        parent: 'cx',
        url: '/submission/{jobId}/{submissionId}/post-apply-pin-verification',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'apply-flow-post-pin-verification',
        },
    },

});