import router from 'app/model/router';
import mapper from 'apply-flow/module/regulatory/mapper/regulatoryResponses';
import candidateModel from 'apply-flow/model/candidate';

const baseConfig = {
    restEndpoint: '/recruitingCERegulatoryResponses',
    successMessage: 'IRC-Candidate Data Validation Successful (IRC--1)',
};

export default {
    getConfig() {
        const data = candidateModel.regulatoryResponses();

        if (!data.length) {
            return undefined;
        }

        const { jobId } = router.routeParams();
        const payload = mapper.mapDataToRest(data, jobId);

        return { ...baseConfig, payload };
    },
};