import deviceDetector from 'core/device-detector/deviceDetector';

const UNSUPPORTED = [{
    browser: 'Internet Explorer',
    version: 10,
}];

export default {
    isBrowserUnsupported() {
        const userBrowser = deviceDetector.browser;
        const userBrowserVersion = deviceDetector.version;

        return UNSUPPORTED.some((unsupported) => {
            if (unsupported.browser === userBrowser) {
                return unsupported.version ? (userBrowserVersion <= unsupported.version) : true;
            }

            return false;
        });
    },
};
