import { observable, pureComputed, utils } from 'knockout';
import router from 'app/model/router';
import cxEvents from 'cx/config/events';
import customContentService from './service/customContent';
import { search as searchService } from 'minimal/module/search/service/search';
import { getPageComponentByType } from 'custom-content/service/getPageComponents';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import i18n from 'core/i18n/i18n';
import { FACET_MAP } from './config/constants';
import signedCandidate from 'app/module/cx/module/candidate-verification/model/signedCandidate';
import historyService, { PREVIOUS_ROUTE } from 'ce-common/service/historyService';
import storageService from 'core/storage/sessionStorage';

export default class CustomContentViewModel {

    constructor() {
        this.page = observable();
        this.pageCode = pureComputed(() => router.routeParams().pageCode);
        this.isPreview = pureComputed(() => router.routeParams().query?.preview);
        this.isCustomPage = true;

        this._pageCodeSub = this.pageCode.subscribe((pageCode) => {
            this._loadPage(pageCode);
        });

        this._loadPage(this.pageCode());

        storageService.remove(PREVIOUS_ROUTE);
    }

    _getPage(pageCode) {
        if (!pageCode) {
            return Promise.reject();
        }

        if (this.isPreview()) {
            return customContentService.getDraft(pageCode);
        }

        return customContentService.get(pageCode);
    }

    _loadPage(pageCode) {
        return this._getPage(pageCode)
            .catch(err => console.error(err))
            .then((page) => {
                if (!page) {
                    return router.go('not-found');
                }

                this.page(page);

                this._loadSearchResultPage(page);

                historyService.store();

                return this._updatePageTitle();
            });
    }

    _loadSearchResultPage(page) {
        const searchResultParams = page
            ? getPageComponentByType(page.sections ?? [], 'cc-search-results') ?? {}
            : undefined;

        if (!searchResultParams || !Object.entries(searchResultParams).length) {
            return;
        }

        const { criteria, numberOfJobsDisplayed } = searchResultParams.params.contentParams;

        const queryParams = this._buildQueryParams(JSON.parse(criteria()));

        const searchParams = Object.assign({}, queryParams, { limit: numberOfJobsDisplayed() });

        searchService.searchJobs(searchParams, signedCandidate.isSignedIn());
    }

    _buildQueryParams(criteriaList) {
        const querybuilder = new SearchQueryBuilder();

        const keyword = this._getCriteriaByKey(criteriaList, 'keyword');
        const categories = this._getCriteriaByKey(criteriaList, 'categories');
        const location = this._getCriteriaByKey(criteriaList, 'location');
        const workLocations = this._getCriteriaByKey(criteriaList, 'workLocations');
        const titles = this._getCriteriaByKey(criteriaList, 'titles');
        const postingDate = this._getCriteriaByKey(criteriaList, 'postingDate');
        const organizations = this._getCriteriaByKey(criteriaList, 'organizations');
        const hotJob = this._getCriteriaByKey(criteriaList, 'hotJob');
        const workplaceType = this._getCriteriaByKey(criteriaList, 'workplaceType');

        if (keyword) {
            querybuilder.withKeyword(keyword.value);
        }

        if (location) {
            querybuilder.withLocation(location.value);
        }

        if (workLocations?.value) {
            const { locationId: selectedIds } = workLocations.value;

            querybuilder.withFacet({
                type: FACET_MAP[workLocations.key],
                selectedIds: [selectedIds],
            });
        }

        if (categories?.value) {
            querybuilder.withFacet({
                type: FACET_MAP[categories.key],
                selectedIds: categories.value,
            });
        }

        if (organizations?.value) {
            const { orgId: selectedOrgId } = organizations.value;

            querybuilder.withFacet({
                type: FACET_MAP[organizations.key],
                selectedIds: [selectedOrgId],
            });
        }

        if (titles) {
            querybuilder.withFacet({
                type: FACET_MAP[titles.key],
                selectedIds: titles.value,
            });
        }

        if (postingDate) {
            querybuilder.withFacet({
                type: FACET_MAP[postingDate.key],
                selectedIds: [postingDate.value],
            });
        }

        if (hotJob) {
            querybuilder.withHotJobs(hotJob.value);
        }

        if (workplaceType) {
            querybuilder.withFacet({
                type: FACET_MAP[workplaceType.key],
                selectedIds: workplaceType.value,
            });
        }

        querybuilder.withSortOption({
            code: 'POSTING_DATES',
            order: 'DESC',
            name: i18n('job-details.posting-date-label'),
        });

        return querybuilder.build();
    }

    _getCriteriaByKey(criteriaList, key) {
        return utils.arrayFirst(criteriaList, criteria => criteria.key === key);
    }

    _updatePageTitle() {
        cxEvents.pageTitleUpdate.dispatch(this.page().title);
    }

    dispose() {
        this._pageCodeSub.dispose();
        this.pageCode.dispose();
    }

}
