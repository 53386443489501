/* eslint no-bitwise: 0, no-mixed-operators: 0 */

const KEYSTRING = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

function _utf8Encode(input) {
    const string = input.replace(/\r\n/g, '\n');

    let output = '';

    for (let i = 0; i < string.length; i++) {
        const char = string.charCodeAt(i);

        if (char < 128) {
            output += String.fromCharCode(char);
        } else if ((char > 127) && (char < 2048)) {
            output += String.fromCharCode((char >> 6) | 192);
            output += String.fromCharCode((char & 63) | 128);
        } else {
            output += String.fromCharCode((char >> 12) | 224);
            output += String.fromCharCode(((char >> 6) & 63) | 128);
            output += String.fromCharCode((char & 63) | 128);
        }
    }

    return output;
}

function _utf8Decode(input) {
    let string = '';

    const chars = [0, 0, 0];

    let i = 0;

    while (i < input.length) {
        chars[0] = input.charCodeAt(i);

        if (chars[0] < 128) {
            string += String.fromCharCode(chars[0]);

            i++;
        } else if ((chars[0] > 191) && (chars[0] < 224)) {
            chars[1] = input.charCodeAt(i + 1);

            string += String.fromCharCode(((chars[0] & 31) << 6) | (chars[1] & 63));

            i += 2;
        } else {
            chars[1] = input.charCodeAt(i + 1);
            chars[2] = input.charCodeAt(i + 2);

            string += String.fromCharCode(((chars[0] & 15) << 12) | ((chars[1] & 63) << 6) | (chars[2] & 63));

            i += 3;
        }
    }

    return string;
}

export default {
    encode(input) {
        if (!input) {
            return null;
        }

        let output = '';

        const chars = [];
        const encodedChars = [];

        let i = 0;

        const string = _utf8Encode(input);

        while (i < string.length) {
            chars[0] = string.charCodeAt(i++);
            chars[1] = string.charCodeAt(i++);
            chars[2] = string.charCodeAt(i++);

            encodedChars[0] = chars[0] >> 2;
            encodedChars[1] = ((chars[0] & 3) << 4) | (chars[1] >> 4);
            encodedChars[2] = ((chars[1] & 15) << 2) | (chars[2] >> 6);
            encodedChars[3] = chars[2] & 63;

            if (isNaN(chars[1])) {
                encodedChars[2] = 64;
                encodedChars[3] = 64;
            } else if (isNaN(chars[2])) {
                encodedChars[3] = 64;
            }

            output += encodedChars.reduce((sum, encodedChar) => sum + KEYSTRING.charAt(encodedChar), '');
        }

        return output;
    },

    decode(input) {
        if (!input) {
            return null;
        }

        let output = '';

        const chars = [];
        const encodedChars = [];

        let i = 0;

        const string = input.replace(/[^A-Za-z0-9+/=]/g, '');

        while (i < string.length) {
            encodedChars[0] = KEYSTRING.indexOf(string.charAt(i++));
            encodedChars[1] = KEYSTRING.indexOf(string.charAt(i++));
            encodedChars[2] = KEYSTRING.indexOf(string.charAt(i++));
            encodedChars[3] = KEYSTRING.indexOf(string.charAt(i++));

            chars[0] = (encodedChars[0] << 2) | (encodedChars[1] >> 4);
            chars[1] = ((encodedChars[1] & 15) << 4) | (encodedChars[2] >> 2);
            chars[2] = ((encodedChars[2] & 3) << 6) | encodedChars[3];

            output += String.fromCharCode(chars[0]);

            if (encodedChars[2] !== 64) {
                output += String.fromCharCode(chars[1]);
            }

            if (encodedChars[3] !== 64) {
                output += String.fromCharCode(chars[2]);
            }
        }

        return _utf8Decode(output);
    },
};
