import { PureComputed, pureComputed } from 'knockout';
import { searchJobResults } from 'app/module/minimal/module/search/model/searchJobResults';
import router from 'app/model/router';
import { isDistanceOutOfRadius } from 'cx/module/search/service/distanceCalculation';
import { getDistanceText } from 'minimal/component/search-results-distance/service/distance';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { JobHeadingConfig } from '../../types';
import i18n from 'core/i18n/i18n';
import { DistanceUnit } from 'app/module/cx/module/search/config/types';
import { generateJobTags, JobTag, JobTagsOptions } from 'minimal/module/job-tags/config/jobTags';
import 'minimal/component/posting-locations';
import 'minimal/component/search-results-distance';
import { Job } from 'minimal/module/job-details/types';

type Props = {
    job: Job;
    jobHeadingConfig: PureComputed<JobHeadingConfig>;
};

const DISTANCE_UNITS_NAMES: Record<Exclude<DistanceUnit, null>, string> = {
    MI: i18n('location-bar.dropdown.radius-unit.mi'),
    KM: i18n('location-bar.dropdown.radius-unit.km'),
};

export default class JobDetailsSimilarJobsItemHeaderViewModel {
    job: Job;
    jobHeadingConfig: PureComputed<JobHeadingConfig>;
    distanceInfo: DistanceInfo;
    hasSubheader: PureComputed<boolean>;
    areJobTagsVisible: PureComputed<boolean>;
    jobTags: JobTag[];
    isJobTagVisible: PureComputed<boolean>;

    constructor({ job, jobHeadingConfig }: Props) {
        this.job = job;
        this.jobHeadingConfig = jobHeadingConfig;
        this.distanceInfo = this.getDistanceInfo(job.distance);

        this.isJobTagVisible = pureComputed(
            () =>
                !this.jobHeadingConfig().isAlreadyAppliedTagVisible &&
                (this.jobHeadingConfig().isHotJobVisible ||
                    this.jobHeadingConfig().isTrendingJobVisible ||
                    this.jobHeadingConfig().isBeFirstToApplyVisible)
        );

        // TODO: something is not correct with type casting here
        this.jobTags = generateJobTags(
            this.job,
            this.jobHeadingConfig as unknown as PureComputed<JobTagsOptions>
        );

        this.areJobTagsVisible = pureComputed(() => {
            return !!this.jobTags.find((tag) => tag.isVisible());
        });

        this.hasSubheader = pureComputed(() => {
            const {
                isLocationVisible,
                isDistanceVisible,
                isPostingDateVisible,
                isHotJobVisible,
                isTrendingJobVisible,
                isBeFirstToApplyVisible,
            } = jobHeadingConfig();

            return (
                isLocationVisible ||
                isDistanceVisible ||
                isPostingDateVisible ||
                isHotJobVisible ||
                isTrendingJobVisible ||
                isBeFirstToApplyVisible
            );
        });
    }

    hasSecondaryLocation(): boolean {
        return Boolean(this.job.secondaryLocations && this.job.secondaryLocations.length > 0);
    }

    getDistanceInfo(jobDistance?: string): DistanceInfo {
        const distanceUnit = searchJobResults.distanceUnit as DistanceUnit;
        const distanceUnitName = distanceUnit ? DISTANCE_UNITS_NAMES[distanceUnit] : '';

        const distanceStringKey = this.hasSecondaryLocation()
            ? 'search.job-item.distance-multiple-locations'
            : 'search.job-item.distance-single-location';

        const inputRadius = this.getInputRadius();
        const isOutOfRadius = Boolean(isDistanceOutOfRadius(inputRadius, jobDistance));
        const distanceText = getDistanceText({
            isDistanceOutOfRadius: isOutOfRadius,
            inputRadius,
            jobDistance,
        });

        return {
            distanceUnit: distanceUnitName,
            distanceStringKey,
            inputRadius,
            isDistanceOutOfRadius: isOutOfRadius,
            distanceText,
        };
    }

    getInputRadius(): string | undefined {
        const routeParams = router.routeParams() as {
            query?: {
                radius: string;
            };
        };

        return routeParams.query?.radius;
    }
}
