import { unwrap } from 'knockout';

function setFocus(selector) {
    const target = document.querySelector(selector);

    if (!target) {
        return;
    }

    target.focus();
}

export function setFocusWithDelay(focusOn, delay) {
    const selector = unwrap(focusOn);

    if (typeof delay === 'undefined') {
        setFocus(selector);
    } else {
        window.setTimeout(() => {
            setFocus(selector);
        }, delay);
    }
}