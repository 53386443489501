import ko from 'knockout';

export default class FormElement {

    constructor(name) {
        this.id = ko.pureComputed({
            read: this._getId,
            write: this._setId,
            owner: this,
        });

        this.name = ko.pureComputed({
            read: this._getName,
            write: this._setName,
            owner: this,
        });

        this.label = ko.observable();
        this.instructions = ko.observable();

        this.helpText = ko.observable();
        this.showLabelTooltip = ko.observable(true);
        this.focusNextSelector = ko.observable();
        this.containerId = ko.observable();

        this.defaultValue = ko.observable();
        this.valueUpdateType = ko.observable('change');

        this._valueObs = ko.observable(ko.observable());

        this.value = ko.pureComputed({
            read: this._getValue,
            write: this._setValue,
            owner: this,
        });

        this.isHidden = ko.observable(false);
        this.isLabelHidden = ko.observable(false);

        /**
         * @protected
         */
        this._attributes = ko.observable({
            id: name,
            name,
            disabled: false,
        });

        this.attributes = ko.pureComputed({
            read: this._getAttributes,
            write: this._setAttributes,
            owner: this,
        });

        this.isDisabled = ko.pureComputed({
            read: this._getIsDisabled,
            write: this._setIsDisabled,
            owner: this,
        });

        this.component = ko.observable('text-form-element');

        this.isDirty = ko.pureComputed(this._isDirty, this);
        this.isTouched = ko.observable(false).extend({ notify: 'always' });
    }

    registerModel(newValue) {
        if (ko.isObservable(newValue)) {
            const previousValue = this.value();

            if (!newValue() && previousValue) {
                newValue(previousValue);
            }

            this._valueObs(newValue);
        } else {
            this.value(newValue);
        }

        return this;
    }

    _getValue() {
        const observable = this._valueObs();

        return observable();
    }

    _setValue(value) {
        const observable = this._valueObs();

        return observable(value);
    }

    _getId() {
        return this._attributes().id;
    }

    _setId(id) {
        this.attributes('id', id);
    }

    _getName() {
        return this._attributes().name;
    }

    _setName(name) {
        this.attributes('name', name);
    }

    _getAttributes() {
        return this._attributes();
    }

    _setAttributes(attrName, attrValue) {
        if (typeof attrName === 'object') {
            Object.keys(attrName).forEach((name) => {
                this._setAttribute(name, attrName[name]);
            });
        } else {
            this._setAttribute(attrName, attrValue);
        }

        this._attributes.notifySubscribers();

        return this;
    }

    _setAttribute(attrName, attrValue) {
        this._attributes()[attrName] = attrValue;
    }

    _setIsDisabled(isDisabled) {
        this.attributes('disabled', isDisabled);
    }

    _getIsDisabled() {
        return this._attributes().disabled;
    }

    _isDirty() {
        return this.isTouched();
    }

    dispose() {
    }

}