export default {
    groupHiringApplications(applications) {
        applications.filter(application => application.isHiring()).forEach((hiringApplication) => {
            const pipelineApp = applications.filter(pipelineApplication =>
                pipelineApplication.submissionId() === hiringApplication.pipelineSubmissionId()).shift();

            pipelineApp.hiringJobApplications().push(hiringApplication);
        });

        // no longer need hiring applications, as they're linked to pipelines
        return applications.filter(application => !application.isHiring());
    },
};
