const COUNTRY_LOOKUP_CODE_KEY = 'countryCode';
const COUNTRY_MEANING_KEY = 'geographyName';
const STATE_LOOKUP_CODE_KEY = 'geographyName';
const STATE_MEANING_KEY = 'geographyDisplayName';

const LOOKUP_CODE_KEYS = [
    'code',
    'contentItemId',
    'currencyCode',
    'countryCode',
    'id',
    'locationId',
    'lookupCode',
    'levelId',
    'territoryCode',
    'jobFamilyId',
    'ratingLevelId',
    'establishmentId',
];

const MEANING_KEYS = [
    'geographyName',
    'locationName',
    'meaning',
    'name',
    'territoryShortName',
    'jobFamilyName',
    'ratingDescription',
];

function reduceItemPropertyKey(item, propertyKeys) {
    return propertyKeys.reduce((key, propertyKey) => {
        if (key) {
            return key;
        }

        return item[propertyKey]
            ? propertyKey
            : '';
    }, '');
}

function _mapFromRest(restCollectionItems, codeKeys, meaningKeys) {
    const extraInformationItems = restCollectionItems[0] && restCollectionItems[0].listOfValues;
    const items = extraInformationItems || restCollectionItems;

    const [firstItem] = items;
    const lookupCodeKey = reduceItemPropertyKey(firstItem, codeKeys);
    const meaningKey = reduceItemPropertyKey(firstItem, meaningKeys);

    Object.assign(firstItem, {
        lookupCodeKey,
        meaningKey,
    });

    return items.map(item => Object.assign(item, {
        lookupCode: item[lookupCodeKey],
        meaning: item[meaningKey],
    }));
}

function _isStatesDictionary(restDictionaryItems) {
    if (!restDictionaryItems.length) {
        return false;
    }

    const [{ geographyType }] = restDictionaryItems;

    return geographyType && geographyType !== 'COUNTRY';
}

function _computeLookupKeysForStates() {
    return LOOKUP_CODE_KEYS
        .map(key => (key === COUNTRY_LOOKUP_CODE_KEY ? STATE_LOOKUP_CODE_KEY : key));
}

function _computeMeaningKeysForStates() {
    return MEANING_KEYS
        .map(key => (key === COUNTRY_MEANING_KEY ? STATE_MEANING_KEY : key));
}

export default {
    mapDictionariesFromRest(restCollection) {
        const restDictionaries = restCollection.items;

        if (!restDictionaries.length) {
            return [];
        }

        if (_isStatesDictionary(restDictionaries)) {
            const statesLookupCodes = _computeLookupKeysForStates();
            const statesMeaningCodes = _computeMeaningKeysForStates();

            return _mapFromRest(restDictionaries, statesLookupCodes, statesMeaningCodes);
        }

        return _mapFromRest(restDictionaries, LOOKUP_CODE_KEYS, MEANING_KEYS);
    },
};
