import { Observable } from 'knockout';
import { SCROLL_LEFT, SPEED, DISTANCE, STEP } from '../config/scroll';

type Params = {
    element: HTMLDivElement;
    direction: string;
    scrollAmount: Observable<number>;
    scrollEndReached: Observable<boolean>;
    callback?: () => void;
};

export const sideScroll = (params: Params): void => {
    let distanceToGo = 0;
    const isRtl = document.dir == 'rtl';

    const { element, direction, scrollAmount, scrollEndReached, callback } = params;

    const slideTimer = setInterval(() => {
        if ((direction == SCROLL_LEFT && !isRtl) || (direction != SCROLL_LEFT && isRtl)) {
            element.scrollLeft -= STEP;
            scrollAmount(scrollAmount() - STEP);
        } else {
            element.scrollLeft += STEP;
            scrollAmount(scrollAmount() + STEP);
        }

        distanceToGo += STEP;

        if (distanceToGo >= DISTANCE) {
            window.clearInterval(slideTimer);

            const isScrollEndReached = Math.abs(scrollAmount()) >= element.scrollWidth - element.clientWidth;

            scrollEndReached(isScrollEndReached);

            if (callback) {
                callback();
            }
        }
    }, SPEED);
};
