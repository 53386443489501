import {
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { CookiePreferenceLinkParams } from './types';
import { getSearchResultSelectors } from './selectors';
import { emptyParams as defaultSearchResultsParams } from './emptyParams';
import { getParamsValueMergeFunction } from 'minimal/module/search/service/observableParams/getParamsValueMergeFunction';
import { mergeObservableParams } from 'minimal/module/search/service/observableParams/mergeObservableParams';

export const getCustomStyles = (params: CookiePreferenceLinkParams, uniqueWrapperClass: string): string => {
    const selectors = getSearchResultSelectors(`.${uniqueWrapperClass}`);

    const customizationKeys = Object.keys(params) as (keyof CookiePreferenceLinkParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'cookiePreferenceLinkTypography':
                return getTypographyCss({
                    selector: selectors.cookieConsentLink,
                    typography: params[key],
                });

            case 'cookiePreferenceLinkHoverTypography':
                const cookiePreferenceLinkHoverTypography = mergeObservableParams({
                    sourceParams: params.cookiePreferenceLinkTypography,
                    targetParams: params.cookiePreferenceLinkHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.cookiePreferenceLinkHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.cookieConsentLinkOnHover,
                    typography: cookiePreferenceLinkHoverTypography,
                });

            case 'cookiePreferenceLinkContainerStyling':
                return getStylingBoxCss({
                    selector: selectors.cookieConsentLinkContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'cookiePreferenceLinkContainerHoverStyling':
                const cookiePreferenceLinkContainerHoverStyling = mergeObservableParams({
                    sourceParams: params.cookiePreferenceLinkContainerStyling,
                    targetParams: params.cookiePreferenceLinkContainerHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.cookiePreferenceLinkContainerHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.cookieConsentLinkContainerOnHover,
                    stylingBox: cookiePreferenceLinkContainerHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return '';
        }
    });

    return customStyles.filter((style) => style?.trim().length).join(STYLES_SEPARATOR);
};
