import i18n from 'core/i18n/i18n';

export default class FormTileStrategy {

    constructor(form) {
        this.useForm(form);
    }

    get addButtonLabel() {
        return i18n('apply-flow.profile-item.add-item-button');
    }

    get _sources() {
        throw new Error('not implemented');
    }

    useForm(form) {
        this.form = form;
    }

    buildTitle(/* valueMap */) {
        throw new Error('not implemented');
    }

    buildSubtitle(/* valueMap */) {
    }

    resolveFormSources() {
        function readValue(valueObj) {
            if (!valueObj) {
                return undefined;
            }

            if (Object.hasOwnProperty.call(valueObj, 'meaning')) {
                return valueObj.meaning;
            }

            return valueObj;
        }

        function getElementValue(element) {
            if (!element) {
                return Promise.resolve();
            }

            return element.validate().then(() => {
                if (!element.isValid()) {
                    return Promise.resolve();
                }

                if (element.isHidden()) {
                    return Promise.resolve();
                }

                const value = element.value();

                if (element.getOption) {
                    return element.getOption(value)
                        .catch((err) => {
                            console.error(err);

                            return null;
                        });
                }

                return Promise.resolve(value);
            });
        }

        const sources = this._sources;
        const resultKeys = Object.keys(sources);

        const promises = resultKeys.map((key) => {
            const formElement = this.form.getElement(sources[key]);

            return getElementValue(formElement);
        });

        return Promise.all(promises)
            .then(resolvedValues =>
                resultKeys.reduce((obj, resultKey, i) => {
                    obj[resultKey] = readValue(resolvedValues[i]);

                    return obj;
                }, {}));
    }

}
