import { SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';
import Facet from './Facet';
import FlexFieldsFacet from './FlexFieldsFacet';

export default {
    getTypes(context) {
        switch (context) {
            default:
            case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS: return {
                LOCATIONS: Facet,
                WORK_LOCATIONS: Facet,
                WORKPLACE_TYPES: Facet,
                TITLES: Facet,
                CATEGORIES: Facet,
                ORGANIZATIONS: Facet,
                POSTING_DATES: Facet,
                FLEX_FIELDS: FlexFieldsFacet,
            };
            case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS: return {
                START_DATES: Facet,
                LOCATIONS: Facet,
                EVENT_CATEGORIES: Facet,
                EVENT_FORMATS: Facet,
            };
        }
    },

    typeToModel(facetData) {
        return this.getTypes(facetData.context)[facetData.type] || this.getTypes(facetData.context).FLEX_FIELDS;
    },

    create(facetData) {
        const FacetModel = this.typeToModel(facetData);
        const facetImpl = new FacetModel();

        facetImpl.init(facetData);

        return facetImpl;
    },
};
