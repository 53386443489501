import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';
import { session } from 'candidate-verification/service/user-session/session';
import router from 'app/model/router';

export default class SignoutMenuViewModel {
    isMenuOpen: Observable<boolean>;
    isKeepMeSignedInEnabled: boolean;
    isClickOutsideActive: PureComputed<boolean>;

    constructor() {
        this.isMenuOpen = observable<boolean>(false);
        this.isKeepMeSignedInEnabled = sessionPersistence.isEnabled();
        this.isClickOutsideActive = pureComputed(() => this.isMenuOpen());
    }

    toggleMenu(): void {
        this.isMenuOpen(!this.isMenuOpen());
    }

    closeMenu(): void {
        this.isMenuOpen(false);
    }

    signOut(): void {
        session.signOut().then(() => router.go('home-page'));
    }

    signOutAll(): void {
        session.signOutAll().then(() => router.go('home-page'));
    }

    onEditProfile(): void {
        this.isMenuOpen(false);
    }
}
