import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class CheckboxStringValueFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.attributes('type', 'checkbox');
        this.component('checkbox-string-value-form-element');
    }

}
