import { observable } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import interviewSchedulingService from '../../service/interviewScheduling';
import cxEvents from 'cx/config/events';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import applicationService from '../../../../../apply-flow/service/application';
import CandidateChallengeAbstractViewModel from '../../../../../candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import challengeService from 'candidate-verification/service/challenge';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';

export default class InterviewDetailViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.model = observable();
        this.submissionId = observable();
        this.loaded = observable(false);
        this.postScheduleDetails = observable();
        this.candidateMessage = observable();
        this.locationDetail = observable();
        this.isInterviewCancelVisible = observable(false);
        this.isPinRequired = observable();
        this.routerParamsSubscription = router.routeParams.subscribe(this._initialize, this);

        this._initialize();
    }

    onPinValid() {
        this.isPinRequired(false);
        this._initialize();
    }

    async _initialize() {
        if (challengeService.isChallengeRequired()) {
            await this._triggerChallenge()
                .then((challengeRequired) => {
                    this.isPinRequired(challengeRequired);
                })
                .catch((error) => {
                    this._handleError(error);

                    this.isPinRequired(challengeService.isChallengeRequired());
                });

            if (this.isPinRequired()) {
                cxEvents.loaded.dispatch();

                return;
            }
        }

        this._verifyToken()
            .then(() => this._verifySubmission())
            .then(() => this._load())
            .catch(error => this._handleError(error))
            .then(() => cxEvents.loaded.dispatch());
    }

    _verifySubmission() {
        if (!this.submissionId()) {
            const { submissionId } = router.routeParams();

            if (submissionId) {
                this.submissionId(submissionId);
            }
        }

        return applicationService.getSubmission(this.submissionId())
            .catch(() =>
                Promise.reject('inactive-submission'));
    }

    _load() {
        this.loaded(false);
        this.model(null);

        return interviewSchedulingService.getInterviewDetail(router.routeParams().id, this.submissionId())
            .then(model => this.model(model))
            .then(() => {
                const postScheduleDetails = interviewSchedulingService.getPostScheduleDetail(this.model().scheduleId())
                    .then(value => this.postScheduleDetails(value));

                if (this.model().autoCalendarSlot) {
                    return Promise.resolve(postScheduleDetails);
                }

                const candidateMessage = interviewSchedulingService.getCandidateNotes(this.model().interviewId)
                    .then(value => this.candidateMessage(value));

                const locationDetail = interviewSchedulingService.getLocationDetail(this.model().interviewId)
                    .then(value => this.model().locationDetail(value));

                return Promise.all([postScheduleDetails, candidateMessage, locationDetail]);
            })
            .then(() => this.loaded(true))
            .catch(this._handleError);
    }

    _verifyToken() {
        let token = null;

        if (tokenService.accessCodeExists() && this._submissionIdExists()) {
            token = Promise.resolve(tokenService.get());
        } else {
            token = tokenService.verifyToken(router.routeParams().token);
        }

        return token.then((t) => {
            if (t.submissionId) {
                this.submissionId(t.submissionId);
            }
        });
    }

    _handleError(error) {
        if (error === 'inactive-submission') {
            notificationsService.error(i18n('interview-scheduling.self-schedule.messages.inactive-submission'));

            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);

            return;
        }

        super._handleError(error);
    }

    openInterviewCancelDialog() {
        this.isInterviewCancelVisible(true);
    }

    reScheduleInterview() {
        router.go('interview-self-re-schedule-without-token', {
            id: this.model().scheduleId(),
            requestId: this.model().requestId,
            action: 'RESCHEDULE',
        });
    }

    _submissionIdExists() {
        return tokenService.get()?.submissionId || router.routeParams().submissionId;
    }

    dispose() {
        this.routerParamsSubscription.dispose();
    }

}