import ko from 'knockout';
import i18n from 'core/i18n/i18n';


const REQUIRED_LABEL = ` (${i18n('apply-flow.section-personal-information.required-label')})`;

/**
 * Sets element's specified attributes to localized values with additional '(required)' note for each of them
 *
 * @param {object} value - map of attribute's name => translation's key
 *
 * @example
 * <div data-bind="i18nRequired: { 'placeholder': 'translationKey' }"></div>
 */
ko.bindingHandlers.i18nRequired = {
    update(element, valueAccessor, allBindings) {
        const attributes = valueAccessor();

        ko.bindingHandlers.i18nAttr.update(element, valueAccessor, allBindings);

        if (element.getAttribute('required')) {
            Object.keys(attributes).forEach((attrName) => {
                const attrValue = element.getAttribute(attrName);

                if (attrValue) {
                    element.setAttribute(attrName, attrValue + REQUIRED_LABEL);
                }
            });
        }
    },
};