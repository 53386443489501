import ko from 'knockout';

function numberToPixels(number) {
    return `${number}px`;
}

function getViewportLeftOffset(element) {
    const rect = element.getBoundingClientRect();

    return rect.x;
}

function getViewportRightOffset(element) {
    const rect = element.getBoundingClientRect();

    return rect.right - (window.innerWidth || document.documentElement.clientWidth);
}

function adjustOutOfViewportLeft(tooltip, margin) {
    const leftEdgeOffset = getViewportLeftOffset(tooltip);

    if (leftEdgeOffset < 0) {
        if (document.dir === 'rtl') {
            tooltip.style.right = numberToPixels(leftEdgeOffset + margin);
        } else {
            tooltip.style.left = numberToPixels((margin - leftEdgeOffset) + tooltip.offsetLeft);
        }
    }
}

function adjustOutOfViewportRight(tooltip, margin) {
    const rightEdgeOffset = getViewportRightOffset(tooltip);

    if (rightEdgeOffset > 0) {
        if (document.dir === 'rtl') {
            // this is a half of the width of the tooltip button, the value is approximate,
            // because there might be a different element that triggers the tooltip
            // if this ever causes an issue, additional binding parameter should be considered
            const additionalRightOffset = 8;

            tooltip.style.right = numberToPixels(rightEdgeOffset + margin + additionalRightOffset);
        } else {
            tooltip.style.left = numberToPixels(tooltip.offsetLeft - rightEdgeOffset - margin);
        }
    }
}

/**
 * Computes tooltip position
 *
 * @example
 * <element data-bind="tooltipPosition">
 * </element>
 */
ko.bindingHandlers.tooltipPosition = {
    init(element) {
        adjustOutOfViewportLeft(element, 5);
        adjustOutOfViewportRight(element, 5);
    },
};