import i18n from 'core/i18n/i18n';
import {
    AdditionInformationItem,
    HeaderInformationItem,
    JobInfoDisplayedItem,
    JobTagsInformationItem,
    MainContentInformationItem,
} from 'minimal/module/search/component/search-results/config/types';
import {
    JobInfoDisplay,
    DisplayStyle,
    DisplayStyleWithoutToggle,
    JobInfoSeparator,
    JobInfoTagsConfiguration,
} from 'cx/module/search/config/types';

type OptionItem<Type extends string> = {
    value: Type;
    label: string;
    disabled?: boolean;
};

export const TOGGLE_DISPLAY_STYLE_LIST: string[] = ['toggleTileMap', 'toggleListMap'];
export const NON_TOGGLE_DISPLAY_STYLE_LIST: string[] = ['list', 'tile'];

export type JobDisplayStyleOption = OptionItem<DisplayStyle>;
export type AdditionInformationItemOption = OptionItem<AdditionInformationItem>;
export type JobInfoDisplayOptions = OptionItem<JobInfoDisplay>;
export type JobInfoSeparatorOptions = OptionItem<JobInfoSeparator>;
export type JobInfoTagConfigurationOptions = OptionItem<JobInfoTagsConfiguration>;
export type HeaderInformationItemOptions = OptionItem<HeaderInformationItem>;
export type JobTagsInformationOptions = OptionItem<JobTagsInformationItem>;
export type JobInfoDisplayedInformationOptions = OptionItem<JobInfoDisplayedItem>;
export type MainContentInformationOptions = OptionItem<MainContentInformationItem>;

const HEADER_INFORMATION_LABELS: Record<HeaderInformationItem, string> = {
    jobTitle: i18n('admin.site-editor.content-editor.job-title'),
    alreadyApplied: i18n('admin.site-editor.content-editor.already-applied'),
};

export const JOB_INFO_DISPLAYED_INFORMATION_LABELS: Record<JobInfoDisplayedItem, string> = {
    location: i18n('admin.site-editor.content-editor.additional-information-location'),
    postingDate: i18n('admin.site-editor.content-editor.additional-information-posting-date'),
    distance: i18n('admin.site-editor.content-editor.additional-information-preview-distance'),
    requisitionNumber: i18n(
        'admin.site-editor.content-editor.additional-information-preview-requisition-number'
    ),
    organization: i18n('admin.site-editor.content-editor.additional-information-preview-organization'),
    jobFamily: i18n('admin.site-editor.content-editor.additional-information-preview-job-category'),
    jobFunction: i18n('admin.site-editor.content-editor.additional-information-preview-job-function'),
    workerType: i18n('admin.site-editor.content-editor.additional-information-preview-worker-type'),
    contractType: i18n('admin.site-editor.content-editor.additional-information-preview-working-type'),
    managerLevel: i18n('admin.site-editor.content-editor.additional-information-preview-management-level'),
    jobSchedule: i18n('admin.site-editor.content-editor.additional-information-preview-job-schedule'),
    jobShift: i18n('admin.site-editor.content-editor.additional-information-preview-job-shift'),
    jobType: i18n('admin.site-editor.content-editor.additional-information-preview-job-type'),
    studyLevel: i18n('admin.site-editor.content-editor.additional-information-preview-education-level'),
    domesticTravelRequired: i18n(
        'admin.site-editor.content-editor.additional-information-preview-domestic-travel'
    ),
    internationalTravelRequired: i18n(
        'admin.site-editor.content-editor.additional-information-preview-international-travel'
    ),
    workDurationMonths: i18n(
        'admin.site-editor.content-editor.additional-information-preview-work-duration-months'
    ),
    workDurationYears: i18n(
        'admin.site-editor.content-editor.additional-information-preview-work-duration-years'
    ),
    workDays: i18n('admin.site-editor.content-editor.additional-information-preview-work-days'),
    workHours: i18n('admin.site-editor.content-editor.additional-information-preview-work-hours'),
    legalEmployer: i18n('admin.site-editor.content-editor.additional-information-preview-legal-employer'),
    businessUnit: i18n('admin.site-editor.content-editor.additional-information-preview-business-unit'),
    department: i18n('admin.site-editor.content-editor.additional-information-preview-department'),
    postingEndDate: i18n(
        'admin.site-editor.content-editor.additional-information-preview-posting-expiration-date'
    ),
    primaryWorkLocation: i18n(
        'admin.site-editor.content-editor.additional-information-preview-primary-work-location'
    ),
};

const JOB_TAGS_INFORMATION_LABELS: Record<JobTagsInformationItem, string> = {
    trendingJob: i18n('admin.site-editor.content-editor.trending-job'),
    beFirstToApply: i18n('admin.site-editor.content-editor.be-first-to-apply'),
    hotJob: i18n('admin.site-editor.content-editor.hot-job'),
};

const MAIN_CONTENT_INFORMATION_LABELS: Record<MainContentInformationItem, string> = {
    description: i18n('admin.site-editor.content-editor.additional-information-description'),
    previewImage: i18n('admin.site-editor.content-editor.additional-information-preview-image'),
    responsibilities: i18n('admin.site-editor.content-editor.additional-information-responsibilities'),
    qualifications: i18n('admin.site-editor.content-editor.additional-information-qualifications'),
};

const ADDITIONAL_INFORMATION_LABELS: Record<AdditionInformationItem, string> = {
    description: i18n('admin.site-editor.content-editor.additional-information-description'),
    distance: i18n('admin.site-editor.content-editor.additional-information-preview-distance'),
    location: i18n('admin.site-editor.content-editor.additional-information-location'),
    postingDate: i18n('admin.site-editor.content-editor.additional-information-posting-date'),
    previewImage: i18n('admin.site-editor.content-editor.additional-information-preview-image'),
    hotJob: i18n('admin.site-editor.content-editor.hot-job'),
    trendingJob: i18n('admin.site-editor.content-editor.trending-job'),
    beFirstToApply: i18n('admin.site-editor.content-editor.be-first-to-apply'),
    alreadyAppliedTag: i18n('admin.site-editor.content-editor.already-applied'),
};

export const JOB_INFO_DISPLAY_LABELS: Record<JobInfoDisplay, string> = {
    labelValue: i18n('admin.site-editor.content-editor.job-info-display-label-value'),
    iconValue: i18n('admin.site-editor.content-editor.job-info-display-icon-value'),
};

export const JOB_INFO_SEPARATOR_LABELS: Record<JobInfoSeparator, string> = {
    dot: i18n('admin.site-editor.content-editor.dot-separator'),
    line: i18n('admin.site-editor.content-editor.line-separator'),
    none: i18n('admin.site-editor.content-editor.no-separator'),
};

export const JOB_INFO_TAGS_CONFIGURATION_LABELS: Record<JobInfoTagsConfiguration, string> = {
    inHeader: i18n('admin.site-editor.content-editor.display-in-header'),
    inMainContent: i18n('admin.site-editor.content-editor.display-in-main-content'),
};

export const JOB_DISPLAY_STYLE_LABELS: Record<DisplayStyle, string> = {
    list: i18n('admin.site-editor.content-editor.job-display-style-list'),
    tile: i18n('admin.site-editor.content-editor.job-display-style-tile'),
    toggleTileMap: i18n('admin.site-editor.content-editor.job-display-style-tile-map'),
    toggleListMap: i18n('admin.site-editor.content-editor.job-display-style-list-map'),
};

const JOB_DISPLAY_STYLE_LABELS_NON_TOGGLE: Record<DisplayStyleWithoutToggle, string> = {
    list: i18n('admin.site-editor.content-editor.job-display-style-list'),
    tile: i18n('admin.site-editor.content-editor.job-display-style-tile'),
};

export const mapOptions = <Type extends string>(
    options: Record<Type, string>,
    isMapEnabled?: boolean
): { value: Type; label: string }[] => {
    return Object.entries(options).map(([value, label]) => {
        return {
            value: value as Type,
            label: label as string,
            disabled: TOGGLE_DISPLAY_STYLE_LIST.includes(value) && !isMapEnabled,
        };
    });
};

export const ADDITIONAL_INFORMATION_OPTIONS: AdditionInformationItemOption[] = mapOptions(
    ADDITIONAL_INFORMATION_LABELS
);

export const MAIN_CONTENT_INFORMATION_OPTIONS: MainContentInformationOptions[] = mapOptions(
    MAIN_CONTENT_INFORMATION_LABELS
);

export const HEADER_INFORMATION_OPTIONS: HeaderInformationItemOptions[] =
    mapOptions(HEADER_INFORMATION_LABELS);

export const JOB_TAGS_INFORMATION_OPTIONS: JobTagsInformationOptions[] =
    mapOptions(JOB_TAGS_INFORMATION_LABELS);

export const JOB_INFO_DISPLAYED_OPTIONS: JobInfoDisplayedInformationOptions[] = mapOptions(
    JOB_INFO_DISPLAYED_INFORMATION_LABELS
);
export const JOB_DISPLAY_STYLES_OPTIONS: JobDisplayStyleOption[] = mapOptions(JOB_DISPLAY_STYLE_LABELS);

export const JOB_DISPLAY_STYLES_WITH_OUT_TOGGLE_OPTIONS: JobDisplayStyleOption[] = mapOptions(
    JOB_DISPLAY_STYLE_LABELS_NON_TOGGLE
);
