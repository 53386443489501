import FormValidator from 'core/form/validator/Validator';

export default class PIIExpirationDateValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.section-personal-identifying-information.validation.nino-uk-expiration-date-should-not-exist';
    }

    _validate(value, options) {
        const countryCode = options.countryCode.value();
        const typePii = options.typePii.value();

        if (countryCode === 'GB' && typePii === 'NINO' && value) {
            return false;
        }

        return true;
    }

}