import { observable } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class TooltipFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.helpTextId = `${this.element.id()}-helpText`;
        this.isHelpTooltipVisible = observable(false);
        this.isReadonly = Boolean(this.attributes?.readonly);
        this.attributes['aria-label'] = `${this.element.label()} ${this.element.helpText() ? this.element.helpText() : ''}`;
    }

    showHelpTooltip() {
        this.isHelpTooltipVisible(true);
    }

    hideHelpTooltip() {
        this.isHelpTooltipVisible(false);
    }

}