import ko from 'knockout';
import { propertyEquals } from 'core/utils/functional/propertyEquals';

function getMeaningDegree(phrase) {
    const startPos = phrase.indexOf('(');

    if (startPos !== -1) {
        const endPos = phrase.indexOf(')');
        const strBraces = phrase.substring(startPos + 1, endPos);

        if (startPos > 0) {
            const str = phrase.substring(0, startPos);

            return [strBraces, str.trim()];
        }

        return [strBraces];
    }

    return [phrase];
}

function findLookupCodeDegree(phrase, dictionaryCollection) {
    let element = dictionaryCollection.find(propertyEquals('meaning', phrase));

    if (element) {
        return element?.lookupCode;
    }

    const meanings = getMeaningDegree(phrase);

    element = ko.utils.arrayFirst(dictionaryCollection, item =>
        meanings.some(meaning => item.meaning.includes(meaning)));

    return element?.lookupCode;
}

function getMeaning(phrase) {
    const startPos = phrase.indexOf('(');

    if (startPos !== -1) {
        const endPos = phrase.indexOf(')');

        return phrase.substring(startPos + 1, endPos);
    }

    return phrase;
}

function findLookupCode(phrase, dictionaryCollection) {
    const meaning = getMeaning(phrase);
    const element = dictionaryCollection.find(propertyEquals('meaning', meaning));

    return element?.lookupCode;
}

function findLanguageLookupCode(phrase, dictionaryCollection) {
    const element = dictionaryCollection.find(item => item.contentItemCode?.toUpperCase() === phrase.toUpperCase());

    if (element) {
        return element?.lookupCode;
    }

    return findExactMatchLookupCode(phrase, dictionaryCollection);
}

function findExactMatchLookupCode(phrase, dictionaryCollection) {
    const element = dictionaryCollection.find(propertyEquals('meaning', phrase));

    if (element) {
        return element?.lookupCode;
    }

    return findLookupCode(phrase, dictionaryCollection);
}

export default {
    findLookupCodeDegree,
    findLookupCode,
    findLanguageLookupCode,
    findExactMatchLookupCode,
};