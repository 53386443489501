import ko from 'knockout';
import emailFormBuilder from './service/emailFormBuilder';
import smsFormBuilder from './service/smsFormBuilder';
import { SMS, EMAIL } from '../../config/verificationMethods';
import appConfig from 'app/model/config';
import CandidateChallengeAbstractViewModel from '../challenge-layout/CandidateChallengeAbstractViewModel';
import honeyPotBuilder from 'candidate-verification/component/email-verification/service/honeyPotBuilder';
import { isCaptchaEnable, captchaAction } from 'ce-common/module/captcha/service/captchaService';
import { getLoginEmail } from '../../service/emailStorage';
import { getUserContactInfoFromStorage } from 'cx/service/uploadedResumeInfoProvider';

export default class CandidateVerificationAbstractViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.verificationMethods = { SMS, EMAIL };
        this.isSMSVerificationEnabled = appConfig.getSettingByKey('SMS_ENABLED_EXTERNAL_CANDIDATES') === 'true';

        this.activeForm = ko.observable();
        this.forms = ko.observable();

        this._createForms()
            .then(this._onFormsCreated.bind(this));

        this.legalDisclaimer = ko.observable(null);
        this.legalDisclaimerForm = ko.observable(null);
        this.honeyPot = ko.observable('');
        this.honeyPotForm = honeyPotBuilder.createForm(this.honeyPot);
        this.captchaToken = ko.observable('');
        this.isBotsProtectionEnabled = ko.observable(true);

        this.enableLegalDisclaimer = ko.pureComputed(() =>
            this.legalDisclaimer() !== null && this.legalDisclaimer().isEnabled,
        );
    }

    verify() {
        throw new Error('Method not implemented');
    }

    beforeCaptchaVerify() {
        if (isCaptchaEnable()) {
            captchaAction().execute();
        } else {
            this.verify();
        }
    }

    afterCaptchaVerify() {
        this.captchaToken(captchaAction().getResponse());

        if (this.captchaToken()) {
            this.verify();
        }
    }

    async _createForms() {
        const forms = {
            [EMAIL]: emailFormBuilder.createForm(this.candidate),
        };

        if (this.isSMSVerificationEnabled) {
            forms[SMS] = smsFormBuilder.createForm(this.candidate);
        }

        this.forms(forms);
    }

    setVerificationMethod(method) {
        this.activeForm(null); // force rerender of Form
        this.activeForm(this.forms()[method]);

        this.candidate.verificationMethod(method);
    }

    async _onFormsCreated() {
        const verificationMethod = await this._getVerificationMethod();

        this.setVerificationMethod(verificationMethod);

        const emailValue = getLoginEmail();

        if (emailValue) {
            this.candidate.email(emailValue);
        }
    }

    _enableLegalDisclaimerValidation() {
        if (!this.enableLegalDisclaimer()) {
            return;
        }

        this.legalDisclaimerForm()
            .enableImmediateValidation();
    }

    _isLegalDisclaimerEmptyOrValid() {
        return this.legalDisclaimerForm()
            ?.isValid() ?? true;
    }

    async _getVerificationMethod() {
        const userContactInfo = await getUserContactInfoFromStorage();

        if (!userContactInfo) {
            return this.candidate?.verificationMethod() ?? EMAIL;
        }

        const { email, phoneDetails } = userContactInfo;

        if (email) {
            this.candidate.email(email);

            return EMAIL;
        } else if (this.isSMSVerificationEnabled && phoneDetails) {
            this.candidate.phone(phoneDetails);

            return SMS;
        }

        return EMAIL;
    }

}
