import i18n from 'core/i18n/i18n';

export type JobMetaTag = {
    value: JobMetaTagKey;
    label: string;
};

export const FLEX_FIELDS_GROUP = 'flexFields';

export const JOB_META_TYPES: JobMetaTag[] = [
    {
        value: 'id',
        label: i18n('job-details.id-label'),
    },
    {
        value: 'category',
        label: i18n('job-details.category-label'),
    },
    {
        value: 'postedDate',
        label: i18n('job-details.posting-date-label'),
    },
    {
        value: 'locations',
        label: i18n('job-details.locations-label'),
    },
    {
        value: 'postedEndDate',
        label: i18n('job-details.closing-date-label'),
    },
    {
        value: 'studyLevel',
        label: i18n('job-details.job-study-level-label'),
    },
    {
        value: 'jobSchedule',
        label: i18n('job-details.job-schedule-label'),
    },
    {
        value: 'jobShift',
        label: i18n('job-details.job-shift-label'),
    },
    {
        value: 'flexFields',
        label: i18n('admin.site-editor.content-editor.job-info-flex-fields-label'),
    },
    {
        value: 'otherRequisitionTitle',
        label: i18n('job-details.other-job-title-label'),
    },
    {
        value: 'numberOfOpenings',
        label: i18n('job-details.number-of-openings-label'),
    },
    {
        value: 'hiringManagerField',
        label: i18n('job-details.hiring-manager-label'),
    },
    {
        value: 'organization',
        label: i18n('job-details.organization-label'),
    },
    {
        value: 'jobFunction',
        label: i18n('job-details.job-function-label'),
    },
    {
        value: 'workerType',
        label: i18n('job-details.worker-type-label'),
    },
    {
        value: 'contractType',
        label: i18n('job-details.regular-temporary-label'),
    },
    {
        value: 'jobLevel',
        label: i18n('job-details.management-level-label'),
    },
    {
        value: 'jobType',
        label: i18n('job-details.job-type-label'),
    },
    {
        value: 'domesticTravelRequiredField',
        label: i18n('job-details.domestic-travel-required-yesno-label'),
    },
    {
        value: 'internationalTravelRequiredField',
        label: i18n('job-details.international-travel-required-yesno-label'),
    },
    {
        value: 'workMonths',
        label: i18n('job-details.work-duration-months-label'),
    },
    {
        value: 'workYears',
        label: i18n('job-details.work-duration-years-label'),
    },
    {
        value: 'workHours',
        label: i18n('job-details.work-hours-label'),
    },
    {
        value: 'workDays',
        label: i18n('job-details.work-days-label'),
    },
    {
        value: 'externalContactName',
        label: i18n('job-details.external-contact-name-label'),
    },
    {
        value: 'externalContactEmail',
        label: i18n('job-details.external-contact-email-label'),
    },
    {
        value: 'legalEmployer',
        label: i18n('job-details.legal-employer-label'),
    },
    {
        value: 'businessUnit',
        label: i18n('job-details.business-unit-label'),
    },
    {
        value: 'department',
        label: i18n('job-details.department-label'),
    },
    {
        value: 'jobGrade',
        label: i18n('job-details.grade-label'),
    },
];

export const JOB_META_TYPES_DEFAULT: JobMetaTagKey[] = [
    'id',
    'category',
    'postedDate',
    'postedEndDate',
    'studyLevel',
    'jobSchedule',
    'jobShift',
    'locations',
    'flexFields',
];

export type JobMetaTagKey =
    | 'id'
    | 'category'
    | 'postedDate'
    | 'locations'
    | 'postedEndDate'
    | 'studyLevel'
    | 'jobSchedule'
    | 'jobShift'
    | 'recruitingOrganisation'
    | 'bonusAmount'
    | 'flexFields'
    | 'otherRequisitionTitle'
    | 'numberOfOpenings'
    | 'hiringManagerField'
    | 'organization'
    | 'jobFunction'
    | 'workerType'
    | 'contractType'
    | 'jobLevel'
    | 'jobType'
    | 'domesticTravelRequiredField'
    | 'internationalTravelRequiredField'
    | 'workMonths'
    | 'workYears'
    | 'workHours'
    | 'workDays'
    | 'externalContactName'
    | 'externalContactEmail'
    | 'legalEmployer'
    | 'businessUnit'
    | 'department'
    | 'jobGrade';
