import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { CookieConsentModalPolicyCustomizationParams, CookieConsentModalPolicyParams } from '../config/types';
import { CookieConsentPolicyCustomizationSelectorsKey } from '../config/selectors';

export const getCustomStylesCookiePolicy = (
    key: keyof CookieConsentModalPolicyCustomizationParams,
    selectors: Record<CookieConsentPolicyCustomizationSelectorsKey, string>,
    customizationParams: CookieConsentModalPolicyParams
): string => {
    switch (key) {
        case 'policyTypography':
            return getTypographyCss({
                selector: selectors.policyTypography,
                typography: customizationParams[key],
            });
        case 'overAllStyling':
            return getStylingBoxCss({
                selector: selectors.overAllStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        default:
            return '';
    }
};
