import { Observable, observable } from 'knockout';
import i18n from 'core/i18n/i18n';

export type DistanceInfo = {
    distanceStringKey: string;
    distanceUnit: string;
    distanceText: string;
    isDistanceOutOfRadius: boolean;
    inputRadius: string | undefined;
};

type Props = {
    distanceInfo: DistanceInfo;
    itemId: string;
    type: string;
};

export default class SearchResultsDistanceViewModel {
    distanceInfo: DistanceInfo;
    isTooltipVisible: Observable<boolean>;
    helpText: string;
    helpTextId: string;
    type: string;

    constructor({ distanceInfo, itemId, type = 'job' }: Props) {
        this.distanceInfo = distanceInfo;

        this.helpText = this._getHelpText(distanceInfo.inputRadius, distanceInfo.distanceUnit, type);
        this.helpTextId = `helpText-distanceOutOfRadius-${itemId}`;
        this.isTooltipVisible = observable<boolean>(false);
        this.type = type;
    }

    _getHelpText(inputRadius: string | undefined, distanceUnit: string, type: string): string {
        return i18n(`search.${type}-item.distance-out-of-radius`, {
            radius: inputRadius,
            unit: distanceUnit,
        });
    }

    showHelpTooltip(): void {
        this.isTooltipVisible(true);
    }

    hideHelpTooltip(): void {
        this.isTooltipVisible(false);
    }
}
