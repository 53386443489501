
export const FLOW_ID = 'flowId';
export const STEP_ACTION_USAGE_ID = 'stepActionUsageId';
export const SUBMISSION_ID = 'submissionId';
export const INTERVIEW_REQUEST_ID = 'interviewRequestId';
export const SCHEDULE_ID = 'scheduleId';
export const INTERVIEW_ID = 'interviewId';
export const REFERRAL_ID = 'referralId';
export const DRAFT_USER = 'draftUser';
export const MERGED_CANDIDATE = 'mergedCandidate';
export const ACTION = 'action';