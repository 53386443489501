import tokenService from 'candidate-verification/service/token';
import hasSubmissionCache from 'candidate-verification/service/hasSubmissionCache';
import applicationService from 'apply-flow/service/application';
import unverifiedService from 'candidate-verification/service/unverified';

export default {
    getUnconfirmedForJob(jobId) {
        return applicationService.getForJob(jobId)
            .then((submissions) => {
                if (submissions.length === 0) {
                    return Promise.reject();
                }

                return submissions.filter(submission => !submission.isConfirmed()).shift();
            });
    },

    hasJobSubmission(jobId) {
        const token = tokenService.get() || {};

        if (!token.candidateNumber && !unverifiedService.isKnownUnverifiedCandidate()) {
            return Promise.resolve(false);
        }

        if (hasSubmissionCache.exists(jobId)) {
            return Promise.resolve(hasSubmissionCache.get(jobId));
        }

        return applicationService.getForJob(jobId)
            .then(submissions => submissions.length > 0)
            .then((hasJobSubmission) => {
                hasSubmissionCache.put(jobId, hasJobSubmission);

                return hasJobSubmission;
            });
    },
};
