import { CUSTOM_JOB_DETAILS_PAGE } from 'cx/module/custom-content/enums/pageTypes';

const DEFAULT_PARAMS = {
    display: 'boxed',
    videoUrl: '',
};

export default class Page {

    constructor(data = {}) {
        this.title = data.title || '';
        this.sections = data.sections || [];
        this.params = Object.assign({}, DEFAULT_PARAMS, data.params);

        this._setDisplayParam(data.type);
    }

    _setDisplayParam(pageType) {
        if (pageType && pageType === CUSTOM_JOB_DETAILS_PAGE) {
            this.params.display = '';
        }
    }

}
