import httpLangCached from 'cx/service/http/httpLangCached';
import metadataMapper from '../mapper/metadata';

export default class Metadata {

    getProviderConfig() {
        return httpLangCached.get('/recruitingCEProfileImportConfigs?onlyData=true')
            .then(config => metadataMapper.mapFromRest(config));
    }

    isEmpty() {
        return this.getProviderConfig()
            .then((config) => {
                const isIndeedEnabled = config.indeed.activeFlag();
                const isLinkedInEnabled = config.linkedin.activeFlag();
                const isResumeParserEnabled = config.resumeParser.activeFlag();
                const isAwLIEnabled = config.awli.activeFlag();

                return !(isIndeedEnabled || isLinkedInEnabled || isResumeParserEnabled || isAwLIEnabled);
            });
    }

}