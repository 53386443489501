import { JobDetailsInfoCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { JOB_META_TYPES_DEFAULT } from 'site-editor/module/job-details-editor/component/cc-job-details-info/config/jobMetaTypes';

export const emptyParams: JobDetailsInfoCustomizationParams = {
    content: {
        isTitleHidden: false,
        jobMetaTypesOptions: JOB_META_TYPES_DEFAULT,
    },
    titleTypography: { ...EMPTY_TYPOGRAPHY },
    jobInfoTitleTypography: { ...EMPTY_TYPOGRAPHY },
    jobInfoDataTypography: { ...EMPTY_TYPOGRAPHY },
    container: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
