import http from 'core/http/http';
import mapping from 'knockout-mapping';
import profileItemMapper from 'apply-flow/module/profile-items/mapper/profileItem';
import synchronization from 'apply-flow/service/synchronization';
import profileItemValidation from 'apply-flow/service/backend-validation/profileItemValidation';
import profileItemFieldsFilter from './profileItemFieldsFilter';
import errorHandler from './errorHandler';
import { PROFILE_ITEM_SERVICE } from './profileItemServiceConfiguration';
import { useDraftData } from '../../../service/applicationDraftMonitor';
import { get as getAttributes } from './profileItemsAttributesRepository';

export default class ProfileItemService {

    constructor(contentTypeId, { flowVersionId, submissionId }) {
        this.contentTypeId = contentTypeId;
        this.restEndpoint = PROFILE_ITEM_SERVICE[contentTypeId].restEndpoint;

        this.flowVersionId = flowVersionId;
        this.submissionId = submissionId || null;
    }

    query(sectionId) {
        if (useDraftData()) {
            return Promise.resolve([]);
        }

        const attributes = getAttributes(sectionId);

        const params = {
            sectionId,
            flowVersionId: this.flowVersionId,
            expand: this._expandClobFields(sectionId),
        };

        const url = `${this.restEndpoint}?finder=findBySectionId;SectionId=:sectionId:,FlowVersionId=:flowVersionId:&onlyData=true&expand=:expand:`;


        return http.get(url, { params, retry: 2 })
            .then(item => profileItemMapper.mapProfileItemsFromRest(item, this.contentTypeId, attributes));
    }

    save(profileItem) {
        const attributes = getAttributes(profileItem.sectionId());
        const restProfileItem = this.mapToRestWithOperationalData(profileItem);

        const action = restProfileItem[attributes.primaryKey]
            ? this._update.bind(this)
            : this._create.bind(this);

        return action(restProfileItem)
            .then(() => synchronization.remove(profileItem, this.contentTypeId))
            .catch((restResponse) => {
                const error = errorHandler.createError(profileItem.sectionId(), restResponse);

                return Promise.reject(error);
            });
    }

    mapToRestWithOperationalData(profileItem) {
        const attributes = getAttributes(profileItem.sectionId());

        const restProfileItem = profileItemMapper.mapToRest(profileItem, attributes);

        restProfileItem.flowVersionId = this.flowVersionId;
        restProfileItem.submissionId = this.submissionId;

        return restProfileItem;
    }

    validate(profileItem) {
        const { contentTypeId } = profileItem;
        const restProfileItem = this.mapToRestWithOperationalData(profileItem);

        return profileItemValidation.validate(mapping.toJS(contentTypeId), restProfileItem);
    }

    _create(profileItem) {
        return http.post(`${this.restEndpoint}`, profileItem, {
            retry: 3,
        });
    }

    _update(profileItem) {
        const attributes = getAttributes(profileItem.sectionId);

        return http.patch(`${this.restEndpoint}/${profileItem[attributes.primaryKey]}`, profileItem, {
            statusCodes: {
                404: () => {
                    synchronization.remove(profileItem, this.contentTypeId);

                    return Promise.resolve();
                },
            },
        });
    }

    _expandClobFields(sectionId) {
        return getAttributes(sectionId)
            .clob
            .map(field => field.charAt(0).toUpperCase() + field.slice(1))
            .join(',');
    }

    remove(profileItem) {
        const attributes = getAttributes(profileItem.sectionId());
        const restProfileItem = profileItemMapper.mapToRest(profileItem, attributes);

        return http.delete(`${this.restEndpoint}/${restProfileItem[attributes.primaryKey]}`, {
            statusCodes: {
                404: () => Promise.resolve(),
                500: () => Promise.resolve(),
            },
        })
            .then(() => synchronization.remove(profileItem, this.contentTypeId));
    }

    storeAllByType(key, profileItems) {
        const removedItems = profileItems.getRemovedItems();

        [...removedItems, ...profileItems()]
            .forEach(item => synchronization.store(item, key));
    }

    async saveAllByType(profileItems) {
        const sanitizedItems = profileItemFieldsFilter
            .removeHiddenFields(profileItems());

        const removedItems = profileItems.getRemovedItems();

        await Promise
            .all(removedItems.map(profileItem => this.remove(profileItem)));

        await Promise
            .all(sanitizedItems.map(profileItem => this.save(profileItem)));

        profileItems.clearRemovedItems(profileItems);
    }

}
