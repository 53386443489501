type BlockProperties = {
    isRequired: boolean | boolean[] | undefined;
};

export const isBlockRequired = (blockProperties: BlockProperties | undefined): boolean => {
    const isRequired = blockProperties?.isRequired;

    if (Array.isArray(isRequired)) {
        const isRequiredArray = isRequired as boolean[];

        return isRequiredArray.every((element) => element);
    }

    return Boolean(isRequired);
};
