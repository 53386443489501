import $ from 'jquery';
import ko from 'knockout';
import 'jqueryui-amd/core';
import 'jqueryui-amd/widget';
import 'jqueryui-amd/widgets/mouse';
import 'jquery-ui-widgets/autocomplete';
import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { areEventsEnabled } from 'app/service/areEventsEnabled';

const isElasticSearchEnabled = mapTrueFalseStringToBoolean(appConfig.getSettingByKey('IRC_ELASTIC_SEARCH_ENABLED'));
const minimumChars = isElasticSearchEnabled ? 1 : 3;

const _defaultOptions = {
    delay: 500,
    minLength: minimumChars,
    position: {
        my: 'left top+2',
    },
    classes: {
        'ui-autocomplete': 'text-color-primary background-color-dropdown',
    },
};

function calculateLeftPosition($element, { calculateLeft }) {
    if (!calculateLeft) {
        return '0';
    }

    const paddingLeft = parseFloat($element.css('padding-left'));
    const left = paddingLeft - 10;

    return left > 0 ? `${left}px` : '0';
}

function disableTabKey(element) {
    element.keydown((event) => {
        const isTabKeyPressed = event.keyCode === 9;

        if (!isTabKeyPressed
            || !isAutocompleteOpened(element)) {
            return;
        }

        setTimeout(() => {
            element.focus();
        }, 100);

        event.stopPropagation();
    });
}

function isAutocompleteOpened(element) {
    return element
        .autocomplete('widget')
        .is(':visible');
}

function _escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/**
 * Wrapper binding used to wrap jQueryUI autocomplete plugin.
 * Autocomplete is triggered only if input value length is bigger than 2 signs.
 * Target must be bound with textInput binding.
 *
 *  @param {observable} model - variable to save the selected value;
 *  @param {Array} source - an array of options for autocomplete list;
 *  @param {string} appendTo - DOM selector to append autocomplete to;
 *  @param {function} select - handler to fire after autocomplete list item was selected
 *          @default
 *              sets value into model param;
 *  @params - any other options that are accepted by jQueryUI autocomplete widget;
 *
 *  @example
 *      <div id="append-to-me-please">
 *      <input data-bind="textInput: someObservable,
 *              autocomplete: {model: someObservable, source: mySuggester, appendTo: 'append-to-me-please',
 *                             select: mySelectionHandler, delay: 400}"/>
 */

ko.bindingHandlers.autocomplete = {
    init(element, valueAccessor, allBindings, shouldDisableTabKey) {
        const $element = $(element);
        const options = $.extend(true, {}, _defaultOptions, valueAccessor());
        const { source } = options;
        const inputValue = allBindings().textInput;
        const isExpanded = ko.observable(false);

        if (options.position.of === undefined) {
            options.position.of = options.appendTo;
        }

        if (shouldDisableTabKey) {
            disableTabKey($element);
        }

        options.create = () => {
            $element.autocomplete('widget').attr('id', options.id);
        };

        options.open = () => {
            $element.autocomplete('widget').css({
                width: '100%',
                left: calculateLeftPosition($element, options),
            });
        };

        options.close = () => {
            isExpanded(false);
            $element.attr('aria-activedescendant', null);
        };

        options.focus = (event) => {
            event.preventDefault();

            const focusedOption = $(event.currentTarget).find('.ui-state-active');
            const focusedOptionId = (focusedOption.length) ? focusedOption.attr('id') : null;

            $element.attr('aria-activedescendant', focusedOptionId);
        };

        options.source = (request, response) => {
            source(request.term).then((data) => {
                isExpanded(Boolean(data.length));
                response(data);
            });
        };

        if (!options.select) {
            options.select = (event, ui) => {
                options.model(ui.item);
            };
        }

        function _renderMenu(ul, items) {
            let currentCategory = '';

            items.forEach((item) => {
                if (areEventsEnabled() && item.category !== currentCategory) {
                    $('<li>')
                        .attr('aria-label', item.category)
                        .addClass('ui-category')
                        .append(item.category)
                        .appendTo(ul);

                    currentCategory = item.category;
                }

                this._renderItemData(ul, item);
            });
        }

        function _renderItem(ul, item) {
            const updatedTerm = _escapeRegExp(this.term);
            const keywordRegexp = new RegExp(updatedTerm, 'gi');
            const itemLabel = item.label.replace(keywordRegexp, '<span class="ui-autocomplete-mark">$&</span>');

            setTimeout(() => {
                $('.ui-category').removeClass('ui-menu-item');
            }, 0);

            return $('<li>')
                .attr('aria-label', `${item.value} ${item.category}`)
                .attr('data-value', item.value)
                .attr('data-qa', 'autocompleteResultItem')
                .attr('role', 'option')
                .attr('data-routing', item.routing)
                .addClass(item.cssClass ?? '')
                .append($('<div>').append(itemLabel))
                .appendTo(ul);
        }

        const autocompleteInstance = $element.autocomplete(options).data('ui-autocomplete');

        $(`#${$element.attr('data-control-id')}`).attr('role', 'listbox');

        autocompleteInstance._renderMenu = _renderMenu;
        autocompleteInstance._renderItem = _renderItem;

        function _setAriaExpandedAttr(expanded) {
            ko.bindingHandlers.boolAttr.update(element, () => ({
                'aria-expanded': expanded,
            }), allBindings);
        }

        _setAriaExpandedAttr(isExpanded());

        const isExpandedSubscription = isExpanded.subscribe(_setAriaExpandedAttr);

        const inputSubscription = inputValue.subscribe((value) => {
            if (value && value.length < options.minLength) {
                $element.autocomplete('close');
                $element.removeClass('ui-autocomplete-loading');
                $element.find('.ui-autocomplete-category').removeClass('ui-menu-item');
            }
        }, this);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            if ($element.autocomplete('instance')) {
                $element.autocomplete('destroy');
            }

            inputSubscription.dispose();
            isExpandedSubscription.dispose();
        });
    },
};
