import phoneNumberFilter from './phoneNumberFilter';

const PHONE_NUMBER_PATTERN = /^(\+1)?(\d{3})(\d{7})$/;
const ALL_EXCEPT_ALLOWED_SPECIAL_CHARS_PATTERN = /[ \-()]/g;

export default class PhoneNumberFormatterForUS {

    constructor(phoneNumber) {
        this._rawPhoneNumber = `+${phoneNumber.countryCode || ''}${phoneNumber.areaCode || ''}${phoneNumber.number || ''}`;
        this._phoneNumber = this._parsePhoneNumber(phoneNumber.legislationCode);
    }

    get countryCode() {
        return this._phoneNumber.countryCode;
    }

    get areaCode() {
        return this._phoneNumber.areaCode;
    }

    get number() {
        return this._phoneNumber.number;
    }

    get legislationCode() {
        return this._phoneNumber.legislationCode;
    }

    get _clearRawPhoneNumber() {
        return this._rawPhoneNumber.replace(ALL_EXCEPT_ALLOWED_SPECIAL_CHARS_PATTERN, '');
    }

    get _clearRawPhoneNumberWithoutCountryCode() {
        return this._clearRawPhoneNumber.replace('+1', '');
    }

    asFormattedString() {
        if (this.isValid()) {
            const countryCode = this.countryCode ? `+${this.countryCode}` : '';

            return `${countryCode} ${this.asFormattedStringWithoutCountryCode()}`.trim();
        }

        return this._rawPhoneNumber;
    }

    asFormattedStringWithLegislationCode() {
        return `${this.legislationCode}${this.asFormattedString()}`;
    }

    asFormattedStringWithoutCountryCode() {
        const value = phoneNumberFilter.filter(this.areaCode + this.number);

        if (value.length > 3 && value.length <= 7) {
            return `${value.substring(0, 3)} - ${value.substring(3)}`;
        }

        if (value.length > 7 && value.length <= 10) {
            return `(${value.substring(0, 3)}) ${value.substring(3, 6)}-${value.substring(6)}`;
        }

        return value;
    }

    isValid() {
        if (this._clearRawPhoneNumber) {
            return PHONE_NUMBER_PATTERN.test(this._clearRawPhoneNumber);
        }

        return true;
    }

    _parsePhoneNumber(legislationCode) {
        if (this._clearRawPhoneNumber && this.isValid()) {
            const parts = this._clearRawPhoneNumber.match(PHONE_NUMBER_PATTERN);

            if (parts) {
                return {
                    countryCode: '1',
                    areaCode: parts[2] || '',
                    number: parts[3] || '',
                    legislationCode: legislationCode || '',
                };
            }
        }

        return {
            countryCode: '1',
            areaCode: '',
            number: this._clearRawPhoneNumberWithoutCountryCode || '',
            legislationCode: legislationCode || '',
        };
    }

}