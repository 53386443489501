import { observable } from 'knockout';
import formBuilder from 'core/form/formBuilder';
import candidateModel from '../../model/candidate';
import QuestionnaireModel from './model/Questionnaire';
import QuestionModel from './model/Question';
import MetadataService from './service/Metadata';
import ApplyFlowBlockComponentViewModel from '../common/ApplyFlowBlockComponentViewModel';
import { getResponseType } from './service/getResponseType';
import { findQuestionnaireInModel } from './service/findQuestionnaireInModel';
import { fetchQuestionnairePrefillData } from './service/prefillQuestionnaireResponses';
import { isPrefillQuestionnaireEnabled } from 'app/service/prefillResponses';
import { MULTIPLE_CHOICE } from './enum/responseTypes';


export default class QuestionnaireViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        const { questionnaireId, questionnaireVersionNumber } = params.blockProperties;

        this.existingQuestionnaireModel = findQuestionnaireInModel(questionnaireId, params.code);
        this.noQuestions = observable();

        this.conditioningQuestions = [];
        this.conditioningSubscriptions = [];

        this.questionnaire = this.existingQuestionnaireModel || new QuestionnaireModel({
            blockCode: params.code,
            readWriteServiceUrl: params.readWriteServiceUrl,
            questionnaireId,
            questionnaireVersionNumber,
        });

        this.prefillResponses = null;

        const metadataService = new MetadataService(params.metadataServiceUrl);

        const urlParams = {
            questionnaireId,
            questionnaireVersionNumber,
        };

        metadataService.getFormElements(urlParams)
            .then(this._fetchPrefillData.bind(this))
            .then(this._setConditioningQuestions.bind(this))
            .then(this._createQuestions.bind(this))
            .then(this._saveQuestionnaireInModel.bind(this))
            .then(this._createForm.bind(this))
            .then(this._registerForm.bind(this))
            .then(this._supplementConditioningQuestions.bind(this))
            .then(this._computeConditionedQuestionsVisibilityOnInit.bind(this))
            .then(this._subscribeToConditioningQuestions.bind(this))
            .then(this._updateFromPrefillResponses.bind(this))
            .then(this._setBlockReady.bind(this))
            .catch(error => console.error(error));
    }

    _createQuestions(formElementsDef) {
        this.noQuestions(!formElementsDef.length);

        if (!this.noQuestions()) {
            const [{ questionnaireId, questionnaireVersionNumber }] = formElementsDef;

            this.questionnaire.questionnaireId = questionnaireId;
            this.questionnaire.questionnaireVersionNumber = questionnaireVersionNumber;
        }

        return formElementsDef
            .map(formElementDef => formBuilder.createFormElement(formElementDef))
            .map((formElement) => {
                const question = this._getQuestion(formElement.name())
                    || this._createQuestion(formElement.name(), formElement.isHidden());

                question.bindFormElementVisibility(formElement.isHidden);
                question.type = getResponseType(formElement.component());

                return formElement.registerModel(question.answer);
            });
    }

    _getQuestion(questionId) {
        return this.questionnaire.questions().find(candidateQuestion =>
            candidateQuestion.questionId() === questionId);
    }

    _createQuestion(questionId, isHidden) {
        const question = new QuestionModel({
            questionId,
            isHidden,
        });

        this.questionnaire.questions.push(question);

        return question;
    }

    _setConditioningQuestions(formElementsDef) {
        this.conditioningQuestions = formElementsDef.filter(({ conditionedQuestions }) => conditionedQuestions.length);

        return formElementsDef;
    }

    _supplementConditioningQuestions() {
        this.conditioningQuestions
            .forEach((question) => {
                question.conditionedQuestions.forEach((conditioned) => {
                    conditioned.element = this.form().getElement(conditioned.name);
                });

                question.questionModel = this._getQuestion(question.name);
            });
    }

    _computeConditionedQuestionsVisibilityOnInit() {
        this.conditioningQuestions
            .filter(({ questionModel: { answer, isHidden } }) => answer() && !isHidden())
            .forEach(({ conditionedQuestions, questionModel: { answer } }) =>
                this._setConditionedQuestionsVisibility(conditionedQuestions, answer()));
    }

    _subscribeToConditioningQuestions() {
        this.conditioningSubscriptions = this.conditioningQuestions.map(question =>
            question.questionModel.answer.subscribe((value) => {
                this._setConditionedQuestionsVisibility(question.conditionedQuestions, value);
                this._prefillConditionedQuestions(question.conditionedQuestions, question);
            }));
    }

    _setConditionedQuestionsVisibility(questions, value, isParentHidden = false) {
        questions.forEach(({ questionModel, element, conditionedQuestions, conditioningAnswerId }) => {
            const isSingleChoiceCorrect = value === conditioningAnswerId;
            const isMultipleChoiceCorrect = Array.isArray(value) && value.indexOf(conditioningAnswerId) !== -1;
            const isQuestionHidden = isParentHidden || (!isSingleChoiceCorrect && !isMultipleChoiceCorrect);

            element.isHidden(isQuestionHidden);

            if (conditionedQuestions.length) {
                this._setConditionedQuestionsVisibility(conditionedQuestions, questionModel.answer(), isQuestionHidden);
            }
        });
    }

    _saveQuestionnaireInModel(formElements) {
        if (!this.existingQuestionnaireModel) {
            candidateModel.questionnaires.push(this.questionnaire);
        }

        return formElements;
    }

    _createForm(formElements) {
        const form = formBuilder.createForm().elements(formElements);

        this.form(form);

        return form;
    }

    _fetchPrefillData(formElementsDef) {
        let questionnaireId;
        let questionnaireVersionNumber;

        if (formElementsDef.length) {
            [{ questionnaireId, questionnaireVersionNumber }] = formElementsDef;
        } else {
            questionnaireId = this.questionnaire.questionnaireId();
            questionnaireVersionNumber = this.questionnaire.questionnaireVersionNumber();
        }

        if (!this._isPrefillRequired()) {
            return Promise.resolve(formElementsDef);
        }

        return fetchQuestionnairePrefillData(questionnaireId, questionnaireVersionNumber)
            .then((mappedResponses) => {
                this.prefillResponses = mappedResponses;

                return formElementsDef;
            });
    }

    _updateFromPrefillResponses(questions = this.questionnaire.questions(), parentQuestion = null) {
        if (!this._isPrefillRequired()) {
            return;
        }

        // Prefill child question only if parent is answered
        if (parentQuestion) {
            const parentPrefillResponse = this.prefillResponses.find(prefillResponse =>
                prefillResponse.questionId === parentQuestion.questionId());

            if (!parentPrefillResponse) {
                return;
            }
        }

        questions.map((question) => {
            if (question.type === MULTIPLE_CHOICE) {
                if (question.answer()?.length) {
                    return question;
                }
            } else if (question.answer()) {
                return question;
            }

            if (!question.isHidden()) {
                const prefillValue = this.prefillResponses.find(prefillResponse =>
                    (prefillResponse.questionId === question.questionId()));

                if (prefillValue?.answer) {
                    question.answer(prefillValue.answer);
                }
            }

            return question;
        });
    }

    _isPrefillRequired() {
        const isQuestionnairePrefillEnabled = isPrefillQuestionnaireEnabled();

        if (isQuestionnairePrefillEnabled && !candidateModel.isNew()) {
            return true;
        }

        return false;
    }

    _prefillConditionedQuestions(conditionedQuestions, parentQuestion) {
        const questions = conditionedQuestions.map(question => this._getQuestion(question.name));

        this._updateFromPrefillResponses(questions, parentQuestion.questionModel);
    }

    dispose() {
        this.conditioningSubscriptions.forEach(subscription => subscription.dispose());
    }

}
