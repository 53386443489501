import { TemplateNumber } from './pageTypes';

export const COOKIE_CONSENT_WIDGET = 'COOKIE_CONSENT_WIDGET';
export const COOKIE_PREFERENCES_WIDGET = 'COOKIE_PREFERENCES_WIDGET';
export const COOKIE_POLICY_WIDGET = 'COOKIE_POLICY_WIDGET';

export type WidgetType =
    | typeof COOKIE_CONSENT_WIDGET
    | typeof COOKIE_POLICY_WIDGET
    | typeof COOKIE_PREFERENCES_WIDGET;

export const ALL_TYPES = 'ALL';

export const WIDGET_TYPES: WidgetType[] = [
    COOKIE_CONSENT_WIDGET,
    COOKIE_PREFERENCES_WIDGET,
    COOKIE_POLICY_WIDGET,
];

export const AVAILABLE_WIDGET_TYPES: Record<TemplateNumber, WidgetType[]> = {
    mohegan: [],
    minimal: [COOKIE_CONSENT_WIDGET, COOKIE_POLICY_WIDGET, COOKIE_PREFERENCES_WIDGET],
};

export const WIDGET_EDITOR_ROUTES: Record<WidgetType, string> = {
    [COOKIE_CONSENT_WIDGET]: 'site-editor-cookie-consent-widget-editor',
    [COOKIE_PREFERENCES_WIDGET]: 'site-editor-cookie-preferences-widget-editor',
    [COOKIE_POLICY_WIDGET]: 'site-editor-cookie-policy-widget-editor',
};

export const WIDGET_TYPE_NAMES: Record<WidgetType, string> = {
    [COOKIE_CONSENT_WIDGET]: 'admin.site-editor.pages.page-types.cookie-consent',
    [COOKIE_PREFERENCES_WIDGET]: 'admin.site-editor.pages.page-types.cookie-preference',
    [COOKIE_POLICY_WIDGET]: 'admin.site-editor.pages.page-types.cookie-policy',
};

export const WIDGET_TYPE_CLASS_NAMES: Record<WidgetType, string> = {
    [COOKIE_CONSENT_WIDGET]: 'cookie-consent',
    [COOKIE_PREFERENCES_WIDGET]: 'cookie-preferences',
    [COOKIE_POLICY_WIDGET]: 'cookie-policy',
};

export const WIDGET_CLASS_MODIFIERS: Record<WidgetType, string> = {
    [COOKIE_CONSENT_WIDGET]: 'cookie-consent',
    [COOKIE_PREFERENCES_WIDGET]: 'cookie-preferences',
    [COOKIE_POLICY_WIDGET]: 'cookie-policy',
};
