import dictionary from 'apply-flow/service/dictionary';
import TileForm from 'apply-flow/module/tile-profile-items/form/TileForm';
import LicenseFormTileStrategy from '../model/LicenseFormTileStrategy';
import { KnockoutSubscribableOnce } from 'app/types/subscribeOnce';
import { capitalize } from 'apply-flow/service/stringValidation';
import SearchFormElement from 'core/form/element/Search';

export default class LicenseForm extends TileForm {
    private licenseElement: SearchFormElement;
    private titleElement: SearchFormElement;

    constructor(...args: LicenseFormTileStrategy[]) {
        super(...args);

        this.licenseElement = this.getElement('contentItemId');
        this.titleElement = this.getElement('title');

        (this.model as unknown as KnockoutSubscribableOnce<boolean>).subscribeOnce(() => {
            this.matchLicenseValue();
        }, this);
    }

    private matchLicenseValue(): void {
        const licenseValue = this.licenseElement.value();
        const titleValue = this.titleElement.value();

        if (!licenseValue && titleValue) {
            const criteriaKey = capitalize(this.licenseElement.optionsKeys().label);

            dictionary
                .findOne(this.licenseElement.dictionary(), { [criteriaKey]: titleValue })
                .then((response?: Record<string, unknown>) => {
                    this.setLicenseValue(response);
                })
                .catch((error: string) => {
                    console.error(error);
                });
        }
    }

    private setLicenseValue(response?: Record<string, unknown>): void {
        const key = this.licenseElement.optionsKeys().value;

        if (response) {
            this.licenseElement.value(response[key]);
        }
    }
}
