import { pipe } from 'core/utils/functional/pipe';
import { not } from 'core/utils/functional/not';

function parseRadius(radius) {
    return radius
        ? Number.parseInt(radius, 10)
        : undefined;
}

function isAnyValueEmpty(...values) {
    return values.find(not(Boolean));
}

function isGreaterThan(radius) {
    return distance =>
        (isAnyValueEmpty(radius, distance)
            ? false
            : distance > radius);
}

export function isDistanceOutOfRadius(radiusStringValue, distance) {
    return pipe(
        parseRadius,
        isGreaterThan,
    )(radiusStringValue)(distance);
}