import FormValidator from 'core/form/validator/Validator';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';

export default class PhoneNumberFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.invalid-phone';
    }

    _validate(value) {
        if (value) {
            return phoneLocaleAwareFactory.createPhone(value).isValid();
        }

        return true;
    }

}