import ko from 'knockout';

/**
 // how to use parallax:
 //
 // in your view model add:
 // isParallaxEnabled - variable with value return true or false
 // backgroundSrc - image source
 //
 // in html:
 // <cc-parallax params="backgroundSrc: backgroundSrc, isParallaxEnabled: isParallaxEnabled"></cc-parallax>
 //
 */

const PARALLAX_SPEED = 0.35;
let elements = [];
let visibleContainerHeight = 0;
let windowHeight = window.innerHeight;

function scrollTopPosition() {
    return window.pageYOffset || document.documentElement.scrollTop;
}

function isElementVisible(element) {
    const containerTopPosition = element.getBoundingClientRect().top;
    const containerBottomPosition = element.getBoundingClientRect().bottom;

    if (windowHeight - containerTopPosition > 0) {
        visibleContainerHeight = (windowHeight - containerTopPosition);
    }

    return windowHeight > containerTopPosition && containerBottomPosition > 0;
}

function changeImagePosition() {
    elements.forEach(({ parentElement, imgElement }) => {
        if (isElementVisible(parentElement)) {
            const minValue = Math.min(
                PARALLAX_SPEED * scrollTopPosition(),
                visibleContainerHeight * PARALLAX_SPEED);

            imgElement.style.transform = `translate3d(0, ${-minValue}px, 0)`;
        }
    });
}

function resizeEvent() {
    windowHeight = window.innerHeight;
    changeImagePosition();
}

ko.bindingHandlers.parallax = {
    init(element, valueAccessor, allBindings, viewModel, bindingContext) {
        const [imgElement] = element.children;
        const { parentElement } = element.parentElement;
        const indexElement = bindingContext.$index();

        if (elements.length === 0) {
            window.addEventListener('scroll', changeImagePosition);
            window.addEventListener('resize', resizeEvent);
        }

        elements.push({
            parentElement,
            imgElement,
            indexElement,
        });

        changeImagePosition();

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            elements = elements.filter(elem => elem.indexElement !== indexElement);

            if (elements.length === 0) {
                window.removeEventListener('scroll', changeImagePosition);
                window.removeEventListener('resize', resizeEvent);
            }
        });
    },
};