import { pureComputed } from 'knockout';
import { acceptAll, cookieConfig, declineAll, hasUserAcceptedAnyCategory, hasUserDeclinedAllCategory, isCookieConsentVisible, isCookiePolicyDialogVisible, isCookiePreferencesVisible } from '../../service/cookieAgreement';
import screenInfo from '../../model/screenInfo';
import { COOKIE_CONSENT_NARROW_STYLE, COOKIE_CONSENT_WIDE_STYLE } from 'site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import tokenService from 'candidate-verification/service/token';


export default class CookieConsentViewModel {

    constructor() {
        this.isCookiePolicyDialogVisible = isCookiePolicyDialogVisible;
        this.config = cookieConfig;
        this.isCookieConsentVisible = isCookieConsentVisible;
        isCookieConsentVisible(this._getCookiesBarVisibility());
        this.isLargeUpScreen = pureComputed(() => screenInfo.isLargeUp());

        this.cookieConsentStyle = {
            wide: COOKIE_CONSENT_WIDE_STYLE,
            narrow: COOKIE_CONSENT_NARROW_STYLE,
        };
    }

    openCookiePreferencesModal() {
        isCookiePreferencesVisible(true);
    }

    openPolicyDialog() {
        this.isCookiePolicyDialogVisible(true);
    }

    acceptAll() {
        acceptAll();
        this.closeDialogs();
    }

    declineAll() {
        declineAll();
        this.closeDialogs();
    }

    closeDialogs() {
        tokenService.refreshDeviceIDCookie().finally(() => {
            this.isCookiePolicyDialogVisible(false);
            isCookieConsentVisible(false);
        });
    }

    _getCookiesBarVisibility() {
        return this.config.isCookieConsentEnabled()
            ? (!hasUserAcceptedAnyCategory() && !hasUserDeclinedAllCategory())
            : false;
    }

}
