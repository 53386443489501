import { Observable, observable } from 'knockout';
import themeEvents from 'cx/module/site-editor/config/events';
import { SignalBinding } from 'signals';
import { CustomHeaderModel } from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import { LogoImages } from 'minimal/component/app-header/config/types';
import appConfig from 'app/model/config';
import { calculateLogoSize } from 'cx/component/logo/service/logoSizeCalculator';

type Props = {
    isLogoHidden?: boolean;
    logoImages?: LogoImages;
    headerId?: Observable<number | null> | undefined;
};

export default class LogoViewModel {
    logoMobile: Observable<string | null>;
    logoDesktop: Observable<string | null>;
    isLogoHidden: Observable<boolean | undefined>;
    logoImageUrlSub: SignalBinding<string | null>;
    mobileLogoImageUrlSub: SignalBinding<string | null>;
    globalHeaderSettingsSubscription: SignalBinding<CustomHeaderModel>;
    headerId: number | null;

    constructor({ isLogoHidden, logoImages, headerId }: Props = {}) {
        this.headerId = headerId ? headerId() : null;

        // TODO change when backend available in CX_CONFIG
        this.logoMobile = observable(
            this.headerId
                ? logoImages?.mobileLogoImageUrl
                : logoImages?.mobileLogoImageUrl ?? appConfig.images?.mobileLogoImageUrl ?? null
        );

        this.logoDesktop = observable(
            this.headerId
                ? logoImages?.logoImageUrl
                : logoImages?.logoImageUrl ?? appConfig.images?.logoImageUrl ?? null
        );

        this.isLogoHidden = observable(this.headerId ? false : isLogoHidden);

        this.logoImageUrlSub = themeEvents.logoImageUrl.add(
            (
                imageUrl: string | null,
                dispatcher: string,
                headerId: number,
                globalHeaderSettings: CustomHeaderModel
            ) => {
                if (dispatcher === 'app-header') {
                    this.logoDesktop(imageUrl);
                    this.headerId = headerId;

                    this.isLogoHidden(this.headerId ? false : globalHeaderSettings.isLogoHidden);
                } else {
                    !this.headerId && this.logoDesktop(imageUrl);
                }
            }
        );

        this.mobileLogoImageUrlSub = themeEvents.mobileLogoImageUrl.add(
            (
                imageUrl: string | null,
                dispatcher: string,
                headerId: number,
                globalHeaderSettings: CustomHeaderModel
            ) => {
                if (dispatcher === 'app-header') {
                    this.logoMobile(imageUrl);
                    this.headerId = headerId;

                    this.isLogoHidden(this.headerId ? false : globalHeaderSettings.isLogoHidden);
                } else {
                    !this.headerId && this.logoMobile(imageUrl);
                }
            }
        );

        this.globalHeaderSettingsSubscription = themeEvents.customHeaderSettingsUpdated.add(
            (globalHeaderSettings: CustomHeaderModel) =>
                this.isLogoHidden(this.headerId ? false : globalHeaderSettings.isLogoHidden)
        );
    }

    adjustLogoSize(data: LogoViewModel, event: Event): void {
        const logoImgElement = event.target as HTMLImageElement;

        const calculatedLogoSize = calculateLogoSize(logoImgElement);

        logoImgElement.style.width = calculatedLogoSize.width ?? '';
        logoImgElement.style.height = calculatedLogoSize.height ?? '';
    }

    dispose(): void {
        this.logoImageUrlSub.detach();
        this.mobileLogoImageUrlSub.detach();
        this.globalHeaderSettingsSubscription.detach();
    }
}
