export const ORA_DQ_QUESTIONS = {
    blockCode: 'ORA_DQ_QUESTIONS',
    componentName: 'disqualification-questions',
};

export const ORA_QUESTIONNAIRE = {
    blockCode: 'ORA_QUESTIONNAIRE',
    componentName: 'static-questionnaire',
};

export const ORA_EVENT_REG_QUESTIONS = {
    blockCode: 'ORA_EVENT_REG_QUESTIONS',
    componentName: 'event-registration-questions',
};