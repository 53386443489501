import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import appConfig from 'app/model/config';
import { search as searchService } from 'minimal/module/search/service/search';

export const shouldShowDistance = (): boolean => {
    const isDistanceCalculationEnabled = mapTrueFalseStringToBoolean(
        appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED')
    );

    return isDistanceCalculationEnabled && searchService.isLocationSearch();
};
