import { CookieConsentModalCategoriesCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'app/module/admin/module/site-editor/module/content-editor/config';

export const emptyParams: CookieConsentModalCategoriesCustomizationParams = {
    categoriesTypography: { ...EMPTY_TYPOGRAPHY },
    alwaysActiveTypography: { ...EMPTY_TYPOGRAPHY },
    categoryDescriptionTypography: { ...EMPTY_TYPOGRAPHY },
    offTypography: { ...EMPTY_TYPOGRAPHY },
    onTypography: { ...EMPTY_TYPOGRAPHY },
    overallStyling: { ...EMPTY_BLOCK_STYLES },
    toggleOffStyling: { ...EMPTY_BLOCK_STYLES },
    toggleOnStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
