import { JobDetailsTagsCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { JOB_TAGS_DEFAULT } from 'site-editor/module/job-details-editor/component/cc-job-details-tags/config/jobTags';

export const emptyParams: JobDetailsTagsCustomizationParams = {
    content: {
        jobTagsOptions: JOB_TAGS_DEFAULT,
    },
    jobTagsTypography: { ...EMPTY_TYPOGRAPHY },
    jobTagsStyling: { ...EMPTY_BLOCK_STYLES },
    container: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
