export default class CustomJsController {

    constructor(customJsId) {
        this.customJsId = customJsId;
        this.currentScriptValue = '';
    }

    _removeElement() {
        const element = document.getElementById(this.customJsId);

        if (element && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    }

    _createElement(script) {
        const element = document.createElement('script');

        element.id = this.customJsId;

        const contents = document.createTextNode(script);

        element.appendChild(contents);

        document.head.appendChild(element);

        return element;
    }

    update(script, enabled) {
        this._removeElement();

        if (enabled && script && script !== this.currentScriptValue) {
            this._createElement(script);
            this.currentScriptValue = script;
        }
    }

}
