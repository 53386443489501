import { RestParam } from 'app/module/cx/module/custom-content/mapper/mapParam';
import { CategoryTileCustomizationParams, SubComponentParams } from '../config/types';
import { mapParamsFromRest } from './mapParams';

export const mapSubComponentParamsFromRest = (
    params: SubComponentParams
): CategoryTileCustomizationParams => {
    return params.map((item: { subComponentParams: RestParam[] }) => {
        const subComponentParams = mapParamsFromRest(item.subComponentParams);

        delete (subComponentParams as CategoryTileCustomizationParams)['content'];

        return subComponentParams;
    });
};
