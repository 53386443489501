import candidateModel from 'apply-flow/model/candidate';

export function findQuestionnaireInModel(questionnaireIdParam, blockCodeParam) {
    const questionnaires = candidateModel.questionnaires();

    const questionnaire = questionnaires.find(({ questionnaireId, blockCode }) =>
        (questionnaireIdParam
            ? String(questionnaireId()) === String(questionnaireIdParam)
            : blockCode() === blockCodeParam),
    );

    if (questionnaire) {
        questionnaire.questionnaireId(questionnaireIdParam);
    }

    return questionnaire;
}