import ko from 'knockout';
import mapping from 'knockout-mapping';
import BasicInformationModel from 'apply-flow/module/personal-information-basic/model/BasicInformation';
import AddressModel from 'apply-flow/module/personal-information-address/model/Address';
import FileAttachment from 'apply-flow/module/file-upload/model/FileAttachment';
import SiteLink from 'apply-flow/module/candidate-links/model/SiteLink';
import CandidateLanguage from './../module/profile-items/model/CandidateLanguage';
import CandidateWorkPreferences from './../module/profile-items/model/CandidateWorkPreferences';
import CandidateLicensesAndCertificates from './../module/profile-items/model/CandidateLicensesAndCertificates';
import CandidateExperience from './../module/profile-items/model/CandidateExperience';
import CandidateEducation from './../module/profile-items/model/CandidateEducation';
import CandidateSkill from './../module/profile-items/model/CandidateSkill';
import Questionnaire from './../module/questionnaire/model/Questionnaire';
import RegulatoryResponse from 'apply-flow/module/regulatory/model/RegulatoryResponse';
import PersonalIdentifyingInformation from '../module/personal-identifying-information/model/PersonalIdentifyingInformation';
import ExtraInformationContext from '../module/extra-information/model/Context';
import 'apply-flow/extender/stashRemoved';
import StashRemovedConfig from '../extender/StashRemovedConfig';


const PROFILE_ITEMS_MAP = {
    103: 'licensesAndCertificates',
    106: 'education',
    109: 'languages',
};

const mappingOptions = {
    attachments: {
        key(attachment) {
            return ko.unwrap(attachment.id);
        },

        create(options) {
            let value = options.data;

            if (value instanceof FileAttachment === false) {
                value = new FileAttachment(value);
            }

            return value;
        },
    },
    linkedSites: {
        key(link) {
            return ko.unwrap(link.id);
        },

        create(options) {
            let value = options.data;

            if (value instanceof SiteLink === false) {
                value = new SiteLink(value);
            }

            return value;
        },
    },

    education: {
        key(profileItem) {
            const id = ko.unwrap(profileItem.id);
            const contentTypeId = ko.unwrap(profileItem.contentTypeId);
            const educationId = ko.unwrap(profileItem.educationId);

            return `${contentTypeId}-${educationId}-${id}`;
        },


        create(options) {
            let value = options.data;

            if (value instanceof CandidateEducation === false) {
                value = new CandidateEducation(value);
            }

            return value;
        },
    },

    experience: {
        key(profileItem) {
            const id = ko.unwrap(profileItem.id);
            const contentTypeId = ko.unwrap(profileItem.contentTypeId);
            const previousEmploymentId = ko.unwrap(profileItem.previousEmploymentId);

            return `${contentTypeId}-${previousEmploymentId}-${id}`;
        },


        create(options) {
            let value = options.data;

            if (value instanceof CandidateExperience === false) {
                value = new CandidateExperience(value);
            }

            return value;
        },
    },

    languages: {
        key(profileItem) {
            const id = ko.unwrap(profileItem.id);
            const contentTypeId = ko.unwrap(profileItem.contentTypeId);
            const languageId = ko.unwrap(profileItem.languageId);

            return `${contentTypeId}-${languageId}-${id}`;
        },


        create(options) {
            let value = options.data;

            if (value instanceof CandidateLanguage === false) {
                value = new CandidateLanguage(value);
            }

            return value;
        },
    },

    licensesAndCertificates: {
        key(profileItem) {
            const id = ko.unwrap(profileItem.id);
            const contentTypeId = ko.unwrap(profileItem.contentTypeId);
            const certificationId = ko.unwrap(profileItem.certificationId);

            return `${contentTypeId}-${certificationId}-${id}`;
        },


        create(options) {
            let value = options.data;

            if (value instanceof CandidateLicensesAndCertificates === false) {
                value = new CandidateLicensesAndCertificates(value);
            }

            return value;
        },
    },

    workPreferences: {
        key(profileItem) {
            const id = ko.unwrap(profileItem.id);
            const contentTypeId = ko.unwrap(profileItem.contentTypeId);
            const workPreferenceId = ko.unwrap(profileItem.workPreferenceId);

            return `${contentTypeId}-${workPreferenceId}-${id}`;
        },


        create(options) {
            let value = options.data;

            if (value instanceof CandidateWorkPreferences === false) {
                value = new CandidateWorkPreferences(value);
            }

            return value;
        },
    },

    skills: {
        key(skill) {
            const id = ko.unwrap(skill.id);
            const skillId = ko.unwrap(skill.skillId);
            const contentTypeId = ko.unwrap(skill.contentTypeId);

            return `${contentTypeId}-${skillId}-${id}`;
        },

        create(options) {
            let value = options.data;

            if (value instanceof CandidateSkill === false) {
                value = new CandidateSkill(value);
            }

            return value;
        },
    },

    questionnaires: {
        key(questionnaire) {
            return ko.unwrap(questionnaire.questionnaireId);
        },

        create(options) {
            let value = options.data;

            if (value instanceof Questionnaire === false) {
                value = new Questionnaire(value);
            }

            return value;
        },
    },

    regulatoryResponses: {
        key(regulatoryResponse) {
            return ko.unwrap(regulatoryResponse.id);
        },

        create(options) {
            let value = options.data;

            if (value instanceof RegulatoryResponse === false) {
                value = new RegulatoryResponse(value);
            }

            return value;
        },
    },

    personalIdentifyingInformationItems: {
        create({ data }) {
            if (data instanceof PersonalIdentifyingInformation) {
                return data;
            }

            return new PersonalIdentifyingInformation(data);
        },
    },

    extraInformation: {
        key(context) {
            return ko.unwrap(context.name);
        },

        create({ data }) {
            if (data instanceof ExtraInformationContext) {
                return data;
            }

            return new ExtraInformationContext(data);
        },
    },
};

const rawProfile = {
    id: undefined,
    basicInformation: new BasicInformationModel(),
    address: new AddressModel(),
    dateOfBirth: undefined,
    isDateOfBirthInProfile: false,

    attachments: [],
    linkedSites: [],

    profileItems: [],
    personalIdentifyingInformationItems: [],
    education: [],
    experience: [],
    languages: [],
    licensesAndCertificates: [],
    workPreferences: [],
    extraInformation: [],
    skills: [],

    questionnaires: [],
    regulatoryResponses: [],

    preferences: {},

    subscribeToJobAlert: false,
    hasEmptySitePreferences: false,

    isNew() {
        return !this.id();
    },

    clear() {
        this.attachments.clearItems();
        this.linkedSites.clearItems();
        this.profileItems.clearItems();
        this.education.clearItems();
        this.experience.clearItems();
        this.languages.clearItems();
        this.licensesAndCertificates.clearItems();
        this.workPreferences.clearItems();
        this.questionnaires([]);
        this.regulatoryResponses([]);
        this.personalIdentifyingInformationItems.clearItems();
        this.extraInformation([]);
        this.skills.clearItems();
        this.dateOfBirth();
        this.hasEmptySitePreferences(false);
        this.subscribeToJobAlert(false);
    },

    clearAfterImport() {
        this.attachments([]);
        this.linkedSites([]);
        this.profileItems([]);
        this.education([]);
        this.experience([]);
        this.languages([]);
        this.licensesAndCertificates([]);
        this.workPreferences([]);
        this.skills([]);
    },

    filterProfileItems(contentTypeId) {
        const profileItems = this.profileItems()
            .filter(profileItem => profileItem.contentTypeId() === contentTypeId)
            .concat(this[PROFILE_ITEMS_MAP[contentTypeId]]());

        return profileItems
            .filter(profileItem => profileItem.contentItemId)
            .map(profileItem => profileItem.contentItemId());
    },
};

const profile = mapping.fromJS(rawProfile, mappingOptions);

profile.attachments.extend({ stashRemoved: new StashRemovedConfig('attachments') });
profile.linkedSites.extend({ stashRemoved: new StashRemovedConfig('linkedSites') });

profile.profileItems.extend({ stashRemoved: new StashRemovedConfig('profileItems', 'profileItemId') });

profile.personalIdentifyingInformationItems.extend({ stashRemoved: new StashRemovedConfig('personalIdentifyingInformationItems') });

profile.education.extend({ stashRemoved: new StashRemovedConfig('education', 'educationId') });
profile.experience.extend({ stashRemoved: new StashRemovedConfig('experience', 'previousEmploymentId') });
profile.languages.extend({ stashRemoved: new StashRemovedConfig('languages', 'languageId') });
profile.licensesAndCertificates.extend({ stashRemoved: new StashRemovedConfig('licensesAndCertificates', 'certificationId') });
profile.workPreferences.extend({ stashRemoved: new StashRemovedConfig('workPreferences', 'workPreferenceId') });
profile.skills.extend({ stashRemoved: new StashRemovedConfig('skills', 'skillId') });

export default profile;
