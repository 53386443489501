import { observable, pureComputed } from 'knockout';
import TileForm from 'apply-flow/module/tile-profile-items/form/TileForm';
import { LEGISLATION_CODE, NATIONAL_IDENTIFIER_TYPE, NATIONAL_IDENTIFIER_NUMBER } from '../config/personalIdentifyingInformationFieldMap';

export default class PiiForm extends TileForm {

    constructor(...args) {
        super(...args);

        const [, config] = args;

        this.showRemoveButton = observable(true);
        this.validationErrorMessage = observable();

        this.isReadOnly = config.readOnly;

        this.isValidAfterServiceCall = pureComputed(() => !this.validationErrorMessage(), this);
        this.isValid = pureComputed(this._isPiiFormValid, this);
        this.getErrorCount = pureComputed(this._getPiiErrorCount, this);
        this.totalErrors = pureComputed(this.getErrorCount, this);

        this.legislationCodeElement = this.getElement(LEGISLATION_CODE);
        this.nationalIdTypeElement = this.getElement(NATIONAL_IDENTIFIER_TYPE);
        this.nationalIdElement = this.getElement(NATIONAL_IDENTIFIER_NUMBER);

        this.tileNationalIdTitle = observable();
    }

    async buildTile() {
        const valueMap = await this.tileStrategy.resolveFormSources();

        const [title, subtitle] = await Promise.all([
            this.tileStrategy.buildTitle(valueMap),
            this.tileStrategy.buildSubtitle(valueMap),
        ]);

        this.tileTitle(title.nationalIdType);
        this.tileNationalIdTitle(title.nationalId);
        this.tileSubtitle(subtitle);
    }

    registerModel(model) {
        if (model.action() === 'CREATE') {
            this.nationalIdTypeElement.isDisabled(true);
            this.showRemoveButton(true);

            this._legislationCodeValueSubscription = this.legislationCodeElement.value.subscribe(
                this._legislationCodeValueChanged,
                this,
            );

            this._nationalIdTypeValueSubscription = this.nationalIdTypeElement.value.subscribe(
                this._nationalIdTypeValueChanged,
                this,
            );
        } else {
            this.legislationCodeElement.isDisabled(true);
            this.nationalIdTypeElement.isDisabled(true);
            this.showRemoveButton(false);
        }

        return super.registerModel(model);
    }

    _legislationCodeValueChanged() {
        this.nationalIdTypeElement.value(null);

        this.nationalIdTypeElement.isDisabled(!this.legislationCodeElement.value());
    }

    _nationalIdTypeValueChanged() {
        this.nationalIdElement.value(null);

        this.nationalIdTypeElement.validate().then((isValid) => {
            this.nationalIdElement.isDisabled(!this.nationalIdTypeElement.value() || !isValid);
        });
    }

    _isPiiFormValid() {
        const areElementsValid = this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .every(element => !element.isValid || (element.isValid && element.isValid()));

        return areElementsValid
            && this.isValidAfterServiceCall();
    }

    _getPiiErrorCount() {
        let invalidElements = this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .filter(element => !element.isValid());

        if (!this.isValidated()) {
            invalidElements = invalidElements.filter(element => element.isDirty());
        }

        return invalidElements.length +
            !this.isValidAfterServiceCall();
    }

    dispose() {
    }

}