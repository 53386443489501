import mapping from 'knockout-mapping';
import appConfig from 'app/model/config';
import { EMAIL, SMS } from '../config/verificationMethods';
import { VERIFICATION_MODES } from '../config/verificationSubmodules';
import {
    FLOW_ID, STEP_ACTION_USAGE_ID, SUBMISSION_ID, INTERVIEW_REQUEST_ID, SCHEDULE_ID, INTERVIEW_ID,
    REFERRAL_ID, DRAFT_USER, MERGED_CANDIDATE, ACTION,
} from 'candidate-verification/enum/consumerAttributes';
import { combinePhone } from 'candidate-verification/service/phoneConverter';

const offerStatusRegexp = /\((\w+)\)$/;

const CONSUMER_RESPONSE_TO_TOKEN_MAP = {
    FlowId: FLOW_ID,
    ApplyFlowId: FLOW_ID,
    StepActionUsageId: STEP_ACTION_USAGE_ID,
    SubmissionId: SUBMISSION_ID,
    InterviewRequestId: INTERVIEW_REQUEST_ID,
    ScheduleId: SCHEDULE_ID,
    ScheduleInterviewId: INTERVIEW_ID,
    ReferralId: REFERRAL_ID,
    DraftUser: DRAFT_USER,
    MergedCandidate: MERGED_CANDIDATE,
    Action: ACTION,
};

function mapConsumerResponse(token) {
    if (!token.consumerResponse) {
        return;
    }

    const offerStatusMatch = token.consumerResponse.match(offerStatusRegexp);

    if (offerStatusMatch) {
        token.offerStatus = offerStatusMatch.pop();
    }

    token.consumerResponse.split(',').forEach((keyValuePair) => {
        const [key, value] = keyValuePair.split('=');
        const tokenProperty = CONSUMER_RESPONSE_TO_TOKEN_MAP[key];

        if (value && value !== 'null' && tokenProperty) {
            token[tokenProperty] = value;
        }
    });
}

function updateVerificationChannel(payload, verificationMethod, email, phone) {
    if (verificationMethod === EMAIL) {
        payload.email = email;
    }

    if (verificationMethod === SMS) {
        payload.phone = combinePhone(phone);
        payload.phoneLegislationCode = phone.legislationCode;
    }
}

export default {

    mapCreateToRest(
        { jobId, candidate, sourceTrace = {}, submodule, lastName, dateOfBirth, captchaToken, honeyPot, indeedKey }) {
        const payload = this.mapCandidateToRest({ candidate, submodule });

        payload.requisitionNumber = jobId;

        if (lastName) {
            payload.lastName = lastName;
        }

        if (dateOfBirth) {
            // Extracting month and date from complete date to pass to service.
            payload.dateOfBirth = dateOfBirth.substring(5);
        }

        if (sourceTrace.sourceTrackingId) {
            payload.sourceTrackingId = sourceTrace.sourceTrackingId;
        }

        if (captchaToken) {
            payload.captchaToken = captchaToken;
        }

        if (honeyPot) {
            payload.control = honeyPot;
        }

        if (indeedKey) {
            payload.indeedKey = indeedKey;
        }

        return payload;
    },

    mapVerifyTokenToRest(token) {
        return {
            siteNumber: appConfig.siteNumber,
            URLShortCode: token,
        };
    },

    mapVerifyTokenWithChallengeToRest(token) {
        return {
            siteNumber: appConfig.siteNumber,
            URLShortCode: token,
            ChallengeFlag: true,
        };
    },

    mapCandidateToRest(formData) {
        const { candidate, submodule, captchaToken, honeyPot } = mapping.toJS(formData);

        const payload = {
            siteNumber: appConfig.siteNumber,
            mode: VERIFICATION_MODES[submodule],
            captchaToken,
            control: honeyPot,
        };

        updateVerificationChannel(payload, candidate.verificationMethod, candidate.email, candidate.phone);

        return payload;
    },

    mapVerificationCodeToRest(formData) {
        const data = mapping.toJS(formData);
        const { verificationMethod, code, email, phone, shortToken, persistAccess } = data;

        const payload = {
            shortCode: code,
        };

        if (shortToken) {
            payload.URLShortCode = shortToken;
        } else {
            payload.challengeFlag = true;
        }

        if (persistAccess) {
            payload.persistAccessFlag = true;
        }

        updateVerificationChannel(payload, verificationMethod, email, phone);

        return payload;
    },

    mapResendVerificationCodeToRest(formData) {
        const data = mapping.toJS(formData);
        const { verificationMethod, email, phone, jobId, shortToken, submodule, lastName, dateOfBirth } = data;

        const payload = {
            requisitionNumber: jobId,
            siteNumber: appConfig.siteNumber,
            mode: VERIFICATION_MODES[submodule],
        };

        updateVerificationChannel(payload, verificationMethod, email, phone);

        if (shortToken) {
            payload.URLShortCode = shortToken;
            payload.challengeFlag = true;
        }

        if (lastName) {
            payload.lastName = lastName;
        }

        if (dateOfBirth) {
            // Extracting month and date from complete date to pass to service.
            payload.dateOfBirth = dateOfBirth.substring(5);
        }

        payload.resendFlag = true;

        return payload;
    },

    mapClaimPhoneNumberToRest(formData) {
        const data = mapping.toJS(formData);
        const { candidate: { phone } } = data;

        return {
            siteNumber: appConfig.siteNumber,
            phone: combinePhone(phone),
            phoneLegislationCode: phone.legislationCode,
            mode: 'CLAIM_PHONE',
        };
    },

    mapClaimEmailToRest(formData) {
        const data = mapping.toJS(formData);
        const { candidate: { email } } = data;

        return {
            siteNumber: appConfig.siteNumber,
            email,
            mode: 'CLAIM_EMAIL',
        };
    },

    mapExtendAccessToRest(accessCode) {
        return {
            accessCode,
            mode: 'REFRESH_ACCESS',
        };
    },

    mapSignOutToRest(accessCode) {
        return {
            accessCode,
            mode: 'SIGN_OUT',
        };
    },

    mapSignOutAllToRest(accessCode) {
        return {
            accessCode,
            mode: 'SIGN_OUT_ALL',
        };
    },

    mapTokenFromRest(tokenResponse) {
        mapConsumerResponse(tokenResponse);
        delete tokenResponse.consumerResponse;
        delete tokenResponse.links;

        return tokenResponse;
    },
};
