import { observable, Observable, PureComputed, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import { DEFAULT_MODE } from 'search/module/location/module/mode-menu/service/mode';
import { changeSearchRoute } from '../../service/searchContextRouting';
import { SearchContext, searchContextMap, SearchRouteOnly } from 'app/module/cx/module/search/config/types';

type SearchContextData = {
    name: string;
    count: number;
    ariaLabel: string;
};

type Props = {
    counters: Record<SearchContext, number>;
    defaultSearchContext: Observable<SearchContext>;
    onChange: (searchContext: SearchContext) => void;
    adminMode: boolean;
    loading: Observable<boolean>;
};

export class SearchContextSwitcherViewModel {
    searchContexts: Record<SearchContext, SearchContextData>;
    selectedSearchContext: PureComputed<SearchContext>;
    onChange: (searchContext: SearchContext) => void;
    adminMode: boolean;
    loading: Observable<boolean>;
    defaultLocationMode: string;
    message: Observable<string>;
    isMsgDisplayedForDefaultMode: boolean;

    constructor({ counters, defaultSearchContext, adminMode, onChange, loading }: Props) {
        this.defaultLocationMode = DEFAULT_MODE;
        this.message = observable<string>('');
        this.isMsgDisplayedForDefaultMode = false;

        this.searchContexts = {
            jobs: {
                name: i18n('search.jobs'),
                count: counters.jobs,
                ariaLabel: i18n('search.results-for-jobs', { total: counters.jobs }),
            },
            events: {
                name: i18n('search.events'),
                count: counters.events,
                ariaLabel: i18n('search.results-for-events', { total: counters.events }),
            },
        };

        this.selectedSearchContext = pureComputed(
            () => searchContextMap[router.route().id as SearchRouteOnly] ?? defaultSearchContext()
        );

        this.adminMode = adminMode;
        this.onChange = onChange;
        this.loading = loading;
    }

    onClick(selectedSearchContext: SearchContext): void {
        if (this.selectedSearchContext() === selectedSearchContext) {
            return;
        }

        this.onChange(selectedSearchContext);

        if (this.adminMode) {
            return;
        }

        changeSearchRoute(selectedSearchContext);
    }
}
