import ko from 'knockout';
import mapping from 'knockout-mapping';
import contextLang from '../service/contextLang';
import i18n from '../i18n';

/**
 * Replaces node content with HTML containing translation, as defined in resources/nls/root/translations.js.
 *
 * @param {(object|string|ko.observable)} translationKey - key for translation or object containing key and tokens
 * @param {string} translationKey.key - translation key
 * @param {object} translationKey.tokens - object containing token name as key and token value
 *                  (string or observable) as value.
 *
 * @example
 * <h1 data-bind="i18n: 'admin.template-configuration.header-subtitle'"></div>
 *
 * @example
 * <div data-bind="i18n: {key: 'location-bar.facet.radius-text', tokens: {location: 'London, UK'}}"></div>
 */
ko.bindingHandlers.i18n = {
    update(element, valueAccessor, allBindings, viewModel, bindingContext) {
        const value = ko.unwrap(valueAccessor());
        const lang = contextLang.get(bindingContext);

        if (typeof value === 'object') {
            const tokens = mapping.toJS(value.tokens);

            if (Object.keys(tokens).length > 0) {
                element.innerHTML = i18n(value.key, tokens, lang);
            } else {
                element.textContent = i18n(value.key, null, lang);
            }
        } else {
            element.textContent = i18n(value, null, lang);
        }
    },
};
