import http from 'core/http/http';
import jobService from '../../job-details/service/job';
import applicationDraftMapper from '../mapper/applicationDraft';
import { applicationDraft } from './applicationDraftInstance';
import applyFlowEvents from '../config/events';
import userEvents, { APPLY_FLOW_DRAFT_CREATED } from 'app/module/cx/config/userEvents';
import { MISCELLANEOUS } from 'apply-flow/module/file-upload/config/attachmentCategories';
import mapping from 'knockout-mapping';
import { isCWKAndCWKAddressUpdateDisabled } from './cwkAddressCheck';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';


export const DRAFTS_URL = '/recruitingCEJobApplicationDrafts';

const DRAFT_EXISTS = 'ORA_DRAFT_EXISTS';
const DRAFT_URL = `${DRAFTS_URL}/:draftId:`;
const CONTENT_NOT_CHANGED = 'CONTENT_NOT_CHANGED';

function _addSavedContent(savedDraft, savedContent) {
    savedDraft.content = savedContent;

    return savedDraft;
}

function _isRifDraft({ submissionId, flowId, currentPhaseId }) {
    return Boolean(submissionId() && flowId() && currentPhaseId());
}

function _handleResponse({ items }) {
    const [result] = items;

    if (result) {
        return result;
    }

    console.error('missing draft');

    return Promise.reject('missing draft');
}

function _getDraft({ jobId, application }) {
    if (_isRifDraft(application)) {
        return _queryForRIF(application.flowId(), application.submissionId(), application.currentPhaseId())
            .then(_handleResponse);
    }

    return _query(jobId).then(_handleResponse);
}

function _create(draft) {
    const mappedDraft = applicationDraftMapper.mapToRest(draft);

    applyFlowEvents.beforeApplicationDraftSave.dispatch();

    return http.post(DRAFTS_URL, mappedDraft, {
        statusCodes: {
            400({ response }) {
                if (response === DRAFT_EXISTS) {
                    return _getDraft(draft);
                }

                return Promise.reject();
            },
        },
    })
        .then(savedDraft => _addSavedContent(savedDraft, mappedDraft.content));
}

function _triggerPixelTracking(draftResponse) {
    if (applicationDraft.id) {
        return draftResponse;
    }

    const { jobId } = draftResponse;
    const candidateId = draftResponse.candidateId || draftResponse.proposedCandidateId;

    if (candidateId) {
        userEvents[APPLY_FLOW_DRAFT_CREATED].dispatch({
            jobId,
            candidateId,
        });
    }

    return draftResponse;
}

function _update(draft) {
    const mappedDraft = applicationDraftMapper.mapToRest(draft);

    if (!applicationDraft.shouldUpdate(mappedDraft)) {
        return Promise.reject(CONTENT_NOT_CHANGED);
    }

    applyFlowEvents.beforeApplicationDraftSave.dispatch();

    return http.patch(DRAFT_URL, mappedDraft, { params: { draftId: draft.id } })
        .then(savedDraft => _addSavedContent(savedDraft, mappedDraft.content));
}

function _query(requisitionNumber) {
    const url = `${DRAFTS_URL}?finder=findByRequisitionNumberFinder;RequisitionNumber=:requisitionNumber:&onlyData=:onlyData:&expand=:expand:`;

    const params = {
        onlyData: true,
        expand: 'Content',
        requisitionNumber,
    };

    return http.get(url, { params });
}

function _queryForRIF(flowId, submissionId, currentPhaseId) {
    const url = `${DRAFTS_URL}?onlyData=:onlyData:&expand=:expand:&finder=findBySubmissionStepAndFlowId;` +
        'SubmissionId=:submissionId:,StepActionUsageId=:currentPhaseId:,FlowId=:flowId:';

    const params = {
        onlyData: true,
        expand: 'Content',
        submissionId,
        flowId,
        currentPhaseId,
    };

    return http.get(url, { params });
}

function setProposedCandidateId(draft) {
    if (!draft.id || draft.candidateId || draft.proposedCandidateId) {
        return Promise.resolve(draft);
    }

    const payload = {
        CustomAction: 'ORA-GENERATE-PROPOSED-CAND-NUMBER',
    };

    return http.patch(DRAFT_URL, payload, { params: { draftId: draft.id } })
        .catch(console.error)
        .then(() => draft);
}

function _createDraft(restDraft, jobId, application) {
    let draft = applicationDraftMapper.mapFromRest(restDraft);

    if (!draft) {
        draft = { jobId, application };
    }

    return setProposedCandidateId(draft);
}

function performUpdateDrafts(candidateData) {
    const draftsUpdate = applicationDraftMapper.mapDraftsUpdateToRest(candidateData);

    return http.post(DRAFTS_URL, draftsUpdate);
}

function _getMiscAttachments(attachments) {
    return attachments.filter(({ categoryId }) => categoryId === MISCELLANEOUS);
}

function _performRequestUpdateCommunicationChannel(data) {
    return http.post(DRAFTS_URL, data);
}

export { CONTENT_NOT_CHANGED };

export default {

    mergeCandidateAndApplicationDraftData(candidate, draftCandidate) {
        if (!draftCandidate) {
            return candidate;
        }

        const mergedCandidate = mapping.toJS(candidate);

        mergedCandidate.questionnaires = draftCandidate.questionnaires;
        mergedCandidate.regulatoryResponses = draftCandidate.regulatoryResponses;
        mergedCandidate.attachments.push(..._getMiscAttachments(draftCandidate.attachments));

        return mergedCandidate;
    },

    delete(draftId) {
        return http.delete(DRAFT_URL, { params: { draftId } });
    },

    getCandidateDrafts() {
        const params = {
            fields: 'RequisitionNumber,APPDraftId,SiteNumber,ModificationDate,SubmissionId',
            orderBy: 'ModificationDate:desc',
        };

        return http.get(`${DRAFTS_URL}?fields=:fields:&orderBy=:orderBy:&limit=500&onlyData=true`, { params })
            .then(this.filterDrafts)
            .catch(console.error);
    },

    draftExists() {
        return Boolean(applicationDraft);
    },

    getDraftId() {
        return applicationDraft?.id ?? '';
    },

    async loadForRIF(jobId, flowId, application) {
        try {
            const restDraft = await _queryForRIF(flowId, application.submissionId(), application.currentPhaseId());

            return _createDraft(restDraft, jobId, application);
        } catch (error) {
            console.error(error);

            return Promise.reject();
        }
    },

    async load(jobId, application) {
        try {
            const restDraft = await _query(jobId);

            return _createDraft(restDraft, jobId, application);
        } catch (error) {
            console.error(error);

            return Promise.reject();
        }
    },

    save() {
        const action = applicationDraft.id ? _update : _create;

        return action(applicationDraft)
            .then(applicationDraftMapper.mapSaveFromRest)
            .then(_triggerPixelTracking)
            .then(applicationDraft.update)
            .then(() => applyFlowEvents.afterApplicationDraftSave.dispatch())
            .then(() => this.updateDraftSource(applicationDraft.draftSource));
    },

    updateCandidateDrafts(candidate = null) {
        if (candidate) {
            return performUpdateDrafts({ candidate });
        }

        if (!this.draftExists()) {
            return Promise.resolve();
        }

        return performUpdateDrafts(applicationDraft);
    },

    updateCandidateModificationDate() {
        if (!this.draftExists()) {
            return;
        }

        const updateDate = new Date(applicationDraft.draftModificationDate);

        updateDate.setSeconds(updateDate.getSeconds() + 1);

        applicationDraft.update({ candidateModificationDate: updateDate });
    },

    updateLegalDisclaimerId(legalDisclaimerId) {
        if (this.draftExists()) {
            applicationDraft.update({ legalDisclaimerId });
        }
    },

    updateDraftSource(draftSource) {
        if (this.draftExists()) {
            applicationDraft.update({ draftSource });
        }
    },

    mergeDraftsAndJobsDetails(drafts) {
        if (!drafts) {
            return Promise.resolve([]);
        }

        const jobsIds = drafts.map(draft => draft.requisitionNumber);

        if (!jobsIds.length) {
            return [];
        }

        return jobService.getJobs(jobsIds)
            .then(jobs => applicationDraftMapper.mapDraftsListFromRest(drafts, jobs.items));
    },

    filterDrafts(drafts) {
        if (!drafts.items.length) {
            return Promise.resolve([]);
        }

        const filteredDrafts = drafts.items
            .filter(draft => draft.submissionId === null);

        return filteredDrafts;
    },

    calculateDraftCandidate({ isCandidateNewer, candidateModel, draftCandidate }) {
        if (isCandidateNewer) {
            try {
                return this.mergeCandidateAndApplicationDraftData(candidateModel, draftCandidate);
            } catch (error) {
                this._notifyError(error);

                return candidateModel;
            }
        }

        const candidateModelConverted = mapping.toJS(candidateModel);

        if (isCWKAndCWKAddressUpdateDisabled(candidateModelConverted?.address)) {
            draftCandidate.address = candidateModelConverted.address;
        }

        return draftCandidate;
    },

    _notifyError(e) {
        console.error(e);
    },

    updateCommunicationChannelEmail(email, draftId, proposedCandidateId) {
        const data = applicationDraftMapper
            .mapUpdateCommunicationChannelEmailToRest(email, draftId, proposedCandidateId);

        return _performRequestUpdateCommunicationChannel(data)
            .then(applicationDraft.candidate.basicInformation.verificationMethod(EMAIL));
    },

    updateCommunicationChannelPhone(phone, draftId, proposedCandidateId) {
        const data = applicationDraftMapper
            .mapUpdateCommunicationChannelPhoneToRest(phone, draftId, proposedCandidateId);

        return _performRequestUpdateCommunicationChannel(data)
            .then(applicationDraft.candidate.basicInformation.verificationMethod(SMS));
    },
};
