import { SearchResultsRequisitionFlexField } from 'minimal/module/search/component/search-results/config/types';
import { JobAttribute } from '../config/types';

export const mapDffJobInfoParamToJobAttribute = (
    dffJobInfoParam: string,
    requisitionDff: SearchResultsRequisitionFlexField
): JobAttribute => {
    return {
        key: dffJobInfoParam,
        value: requisitionDff.value,
        label: requisitionDff.prompt,
        translationKey: undefined,
        className: 'flexfields',
    };
};
