import ko, { observable, pureComputed } from 'knockout';
import PhoneFormElement from 'core/form/element/Phone';
import EqualsToValidator from 'core/form/validator/EqualsTo';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';
import CommunicationChannelEditor from './CommunicationChannelEditor';
import i18n from 'core/i18n/i18n';

export default class PhoneEditFormElement extends PhoneFormElement {

    constructor(...args) {
        super(...args);

        this.origin = null;
        this.requestVerification = null;
        this.requestRemoval = null;
        this.challenge = null;
        this.noPinVerification = false;
        this.cssMode = false;
        this.isChannelVerified = null;
        this.rawPhoneNumber = {};

        this.isVerificationInProgress = observable(false);
        this.errorCode = observable();

        this.component('phone-edit-form-element');

        const editor = new CommunicationChannelEditor(this);

        this.edit = editor.edit;
        this.cancel = editor.cancel;
        this.editMode = editor.editMode;
        this.throttlingMessage = editor.throttlingMessage;
        this.saveButtonDisabled = editor.saveButtonDisabled;
        this.accessibiliyVerificationMessage = pureComputed(() => i18n('candidate-self-service.communication-channel.phone-verification-message', { phone_number: this.getFormattedValue() }));
        this.editModeErrorOccured = observable(false);
        this.initiallyRequired = observable(false);
    }

    subscribeValidations() {
        this.value.subscribe(() => this.validate());
        this.isDirty.subscribe(() => this.validate());

        this.validateImmediately = ko.observable(false).extend({ notify: 'always' });
        this.validateImmediately.subscribe(() => this.validate());
    }

    getFormattedValue() {
        const phoneFormatter = phoneLocaleAwareFactory.createPhone(this.value());

        return phoneFormatter.asFormattedString();
    }

    isSaveButtonEnabled() {
        return this.isValid() || this.editModeErrorOccured();
    }

    setValue(phoneNumber) {
        const phoneObject = phoneLocaleAwareFactory.createPhone(phoneNumber);

        this.rawPhoneNumber = {
            countryCode: phoneObject.countryCode,
            number: `${phoneObject.areaCode}${phoneObject.number}`,
            legislationCode: phoneObject.legislationCode,
        };

        this.value({
            countryCode: phoneObject.countryCode,
            legislationCode: phoneObject.legislationCode,
            areaCode: phoneObject.areaCode,
            number: phoneObject.number,
        });
    }

    _validate(value) {
        this.editModeErrorOccured(false);

        return this._runSynchronouslyValidators(value)
            .catch(validator => ({
                isValid: false,
                validator,
            }))
            .then((result = {}) => {
                const { validator, isValid = true } = result;

                if (validator instanceof EqualsToValidator) {
                    this.editModeErrorOccured(!isValid);
                }

                this.isValid(isValid);
                this.getErrorMessage(validator ? validator.getErrorMessage() : null);

                return isValid;
            })
            .catch(console.error);
    }

}