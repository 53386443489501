import cxEvents from 'cx/config/events';
import unsupportedBrowser from 'cx/service/unsupported-browser/unsupportedBrowser';
import referrerService from './module/source-tracking/service/referrer';

export default class CxViewModel {

    constructor() {
        this.isBrowserUnsupported = unsupportedBrowser.isBrowserUnsupported();

        referrerService.store();

        cxEvents.loaded.dispatch();
    }

}