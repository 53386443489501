export const CLASS_ARROW_RIGHT_ACTIVE = 'app-header-horizontal-navbar__arrow--active';
export const CLASS_ARROW_RIGHT_DISABLED = 'app-header-horizontal-navbar__arrow--disabled';
export const CLASS_ARROW_LEFT_ACTIVE = 'app-header-horizontal-navbar__arrow--active';
export const CLASS_ARROW_LEFT_DISABLED = 'app-header-horizontal-navbar__arrow--disabled';
export const LINKS_BLOCK_ID = 'linksMenu';
export const LINKS_BLOCK = '.app-header-horizontal-navbar';
export const LEFT_ARROW_ID = 'headerLinksArrowLeft';
export const RIGHT_ARROW_ID = 'headerLinksArrowRight';
export const SUBMENU_BLOCK = '.app-header-horizontal-navbar__sublinks-wrapper';
export const LINKS = '#linksMenu li.app-header-horizontal-navbar__item';
export const ARROW_DOWN_SUBLINKS = '.button__arrow.button__arrow--down';
export const INLINE = 'inline';
export const NONE = 'none';
export const BLOCK_DISPLAY = 'block';
export const CLICK = 'click';
export const KEY_DOWN = 'keydown';
export const DATA_CLICK_ATTRIBUTE = 'data-click-listener';
