import FormValidator from 'core/form/validator/Validator';

export default class SchoolImportFailedValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.validation.school-import-dont-match-message';
    }

    _validate() {
        return false;
    }

}
