import ko from 'knockout';
import dictionary from 'apply-flow/service/dictionary';

export default class EducationFormViewModel {

    constructor(params) {
        this.form = params.form;

        this.institutions = ko.observableArray();
        this.levels = ko.observableArray();

        dictionary.get(dictionary.CODES.educationLevels).then((educationLevels) => {
            this.levels(educationLevels.map(level => ({
                levelId: `${level.levelId}`,
                name: level.name,
            })));
        });
    }

}