import router from 'app/model/router';
import { unsubscribe as campaignUnsubscribe, displayThankYou as campaignDisplayThankYou } from '../service/campaign';

router.configure({
    'campaign-thank-you': {
        parent: 'home-page',
        url: 'campaign/thank-you',
        canEnter() {
            campaignDisplayThankYou();

            return Promise.reject({
                redirectTo: 'home-page',
            });
        },
    },
    'campaign-unsubscribe': {
        parent: 'home-page',
        url: 'unsubscribe/{campaignCode}/token/{token}',
        canEnter(routeParams) {
            campaignUnsubscribe(routeParams);

            return Promise.reject({
                redirectTo: 'home-page',
            });
        },
    },
});