import { register } from 'ce-common/service/templateRepository';

import alreadyApplied from './apply-flow-summary-already-applied.html';
import pinCodeRequired from './apply-flow-summary-pin-code-required.html';
import success from './apply-flow-summary-success.html';
import candidateExists from './apply-flow-summary-candidate-exists.html';
import tokenExpired from './apply-flow-token-expired.html';

register('apply-flow-summary-already-applied', alreadyApplied);
register('apply-flow-summary-pin-code-required', pinCodeRequired);
register('apply-flow-summary-success', success);
register('apply-flow-summary-candidate-exists', candidateExists);
register('apply-flow-summary-token-expired-apply-flow', tokenExpired);