export const updateExpandedFacetTypes = (
    expandedFacetTypes: string[],
    facetType: string,
    isExpanded: boolean
): void => {
    if (isExpanded) {
        expandedFacetTypes.push(facetType);
    } else {
        const indexToRemove = expandedFacetTypes.indexOf(facetType);

        expandedFacetTypes.splice(indexToRemove, 1);
    }
};
