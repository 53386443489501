import AbstractStorage from './abstractStorage';

class SessionStorage extends AbstractStorage {

    _getStorageHandler() {
        return window.sessionStorage;
    }

}

const sessionStorage = new SessionStorage();

export default sessionStorage;