import absoluteRouter, { Contexts } from 'app/model/absoluteRouter';
import { SearchContext, SEARCH_RESULTS_STRINGS } from '../module/search/config/types';

export default {
    generate(id: string | number, lang: string, context: SearchContext): string {
        switch (context) {
            case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS:
                return absoluteRouter.interpolate(
                    { lang, context: Contexts.PUBLIC },
                    'event-details',
                    { eventId: id, query: { utm_medium: 'eventshare' } },
                    { inherit: false, encode: true, action: 'link' }
                );
            case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS:
            default:
                return absoluteRouter.interpolate(
                    { lang, context: Contexts.PUBLIC },
                    'job-details',
                    { jobId: id, query: { utm_medium: 'jobshare' } },
                    { inherit: false, encode: true, action: 'link' }
                );
        }
    },
};
