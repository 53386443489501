import http from 'core/http/http';
import appConfig from 'app/model/config';

export const CAPTCHA_SETTING_KEY = 'CAPTCHA';
export const LI_CONNECTIONS_SETTING_KEY = 'LI_CONNECTIONS';
export const SKILL_RECOMMENDATION_SETTING_KEY = 'SKILL_RECOMMENDATION';
export const CHATBOT_SETTING_KEY = 'chatbot';
export const INFINITY_TAG_SETTING_KEY = 'infinityTag';

const GLOBAL_SETTINGS_REST_ENDPOINT = '/globalSettings';

const globalSettingMap = [
    'ORA_IRC_AUTO_CONFIRM_CANDIDATE_ENABLED',
    'SMS_ENABLED_EXTERNAL_CANDIDATES',
    'IRC_ELASTIC_SEARCH_ENABLED',
    'ORA_IRC_KEEP_CANDIDATE_SIGNED_IN_ENABLED',
    'IRC_CE_REST_LOG_LEVEL',
    'ORA_IRC_CE_POST_RETRY_ENABLED',
    'ORA_IRC_TC_OPTIN_ENABLED',
    'ORA_IRC_CE_SAVE_DRAFT_INTRVL',
    'ORA_IRC_MISC_ATTACH_FILE_TYPES',
    'ORA_IRC_MISC_ATTACH_UNSUPPORTED_FILE_TYPES',
    'ORA_IRC_CE_USE_JERSEY_ENDPOINT_FOR_REQ_SEARCH',
    'ORA_IRC_CE_VTURL_ADD_TIMESTAMP_HEADER',
    'ORA_IRC_TC_ACTIVE_ATTRIBUTES',
    'ORA_IRC_CE_XSS_VALIDATOR_DISABLED',
    'ORA_IRC_CE_HTML_VALIDATOR_DISABLED',
    'ORA_IRC_CE_SEARCH_SPELL_CHECK_ENABLED',
    'ORA_IRC_GLOBAL_EVENTS_ENABLED',
    'ORA_IRC_PHONE_LEGISLATION_CODE_ENABLED',
    'IRC_CE_GJS_ENABLED',
    'ORA_IRC_JA_WITHDRAW_REAPPLY',
    'ORA_IRC_RESUME_PARSING_ENABLED',
    'ORA_IRC_JA_PREFILL_LEG_INFO',
    'ORA_IRC_INDEED_OPTI_EXP_ENABLED',
    'ORA_IRC_INDEED_TRUST_AUTH_ENABLED',
    'ORA_IRC_JA_PREFILL_QSTNR_INFO',
    'ORA_IRC_INDEED_REFERRER_DOMAINS',
    'ORA_IRC_ALLOW_CWK_EXT_CANDIDATE_ADDRESS_UPDATE',
    'ORA_IRC_SITE_DESIGN_LIBRARY_URL',
    'ORA_IRC_CE_JOB_FIT_GAI_ENABLED',
    'ORA_IRC_CE_ADMIN_GAI_ENABLED',
];

const httpConfig = {
    baseUrl: appConfig.ceSettingsUrl,
};

function _convertRestStringToBooleanString(value) {
    let returnedValue = value;

    if (value === 'Y' || value === 'N') {
        returnedValue = value === 'Y' ? 'true' : 'false';
    }

    return returnedValue;
}

function _addSettingsToAppConfig(data) {
    data.settings.forEach((setting) => {
        if (globalSettingMap.indexOf(setting.settingKey) === -1) {
            console.warn(`Unknown global setting - ${setting.settingKey}`);
        } else {
            appConfig.setSettingByKey(setting.settingKey,
                _convertRestStringToBooleanString(setting.settingValue));
        }
    });

    return data;
}

function _addLiConnectionsSettingsToAppConfig(data) {
    appConfig.setSettingByKey(LI_CONNECTIONS_SETTING_KEY, data.liConnectionConfig);

    return data;
}

function _addSkillRecommendationConfig(data) {
    appConfig.setSettingByKey(SKILL_RECOMMENDATION_SETTING_KEY,
        data.skillRecommendationConfig.recommendationEnabled);

    return data;
}

function _addChatbotSettingsToAppConfig(data) {
    appConfig.setSettingByKey(CHATBOT_SETTING_KEY, data.chatbotConfig);

    return data;
}

function _addCaptchaSettingsToAppConfig(data) {
    appConfig.setSettingByKey(CAPTCHA_SETTING_KEY, data.captcha);

    return data;
}

function _addInfinityTagSettingsAppConfig(data) {
    appConfig.setSettingByKey(INFINITY_TAG_SETTING_KEY, data.infinityTagConfig?.infinityTag);

    return data;
}

export default {
    loadGlobalSettings() {
        return http.get(GLOBAL_SETTINGS_REST_ENDPOINT, httpConfig)
            .then(_addSettingsToAppConfig)
            .then(_addLiConnectionsSettingsToAppConfig)
            .then(_addSkillRecommendationConfig)
            .then(_addChatbotSettingsToAppConfig)
            .then(_addInfinityTagSettingsAppConfig)
            .then(_addCaptchaSettingsToAppConfig)
            .catch(error => console.error(error));
    },
};
