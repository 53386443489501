import { Job, JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { JobDetailsTagsParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-tags/config/types';
import { JobTagsItem } from 'site-editor/module/job-details-editor/component/cc-job-details-tags/config/jobTags';
import { Observable, PureComputed, pureComputed } from 'knockout';
import { generateJobTags, JobTag, JobTagsOptions } from 'minimal/module/job-tags/config/jobTags';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsTagsParams;
    id?: string;
};

export class JobDetailsTagsViewModel extends CustomComponentViewModel<JobDetailsTagsParams> {
    pageData: PureComputed<JobDetailsPageData>;
    jobTagsOptions: Observable<JobTagsItem[]>;
    job: Job;
    jobTagsConfiguration: PureComputed<JobTagsOptions>;
    jobTags: JobTag[];
    areJobTagsVisible: PureComputed<boolean>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
        this.jobTagsOptions = this.customizationParams.content.jobTagsOptions;
        this.job = this.pageData().job;
        this.jobTagsConfiguration = pureComputed(() => this.getJobTagsConfiguration());

        this.jobTags = generateJobTags(this.job, this.jobTagsConfiguration);

        this.areJobTagsVisible = pureComputed(() => {
            return !!this.jobTags.find((tag) => tag.isVisible());
        });
    }

    getJobTagsConfiguration(): JobTagsOptions {
        return {
            isBeFirstToApplyVisible: this.hasJobTag('beFirstToApply'),
            isTrendingJobVisible: this.hasJobTag('trendingJob'),
            isHotJobVisible: this.hasJobTag('hotJob'),
        };
    }

    private hasJobTag(item: JobTagsItem): boolean {
        if (!this.jobTagsOptions()) {
            return true;
        }

        return this.jobTagsOptions().includes(item);
    }

    getCustomStyle(key: keyof JobDetailsTagsParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'jobTagsTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-tag`,
                    typography: this.customizationParams[key],
                });

            case 'jobTagsStyling':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: `.${this.uniqueWrapperClass} .job-tag__icon::before`,
                    selector: `.${this.uniqueWrapperClass} .job-tag`,
                    stylingBox: this.customizationParams[key],
                });

            case 'container':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-details__section`,
                    stylingBox: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
