import { searchJobResults } from '../../model/searchJobResults';
import router from 'app/model/router';
import { Observable } from 'knockout';

type Option = {
    code: string;
    order: string;
    text: string;
    value: string;
};

type Props = {
    selectedSortOption: Observable<Option>;
    option: Option;
};

export default class SearchSortingItemViewModel {
    option: Option;
    selectedSortOption: Observable<Option>;

    constructor({ selectedSortOption, option }: Props) {
        this.option = option;
        this.selectedSortOption = selectedSortOption;
    }

    setOption(sortOption: Option): void {
        this.selectedSortOption(sortOption);

        searchJobResults.queryBuilder.withSortOption(sortOption);

        router.go(
            'search',
            {
                query: searchJobResults.queryBuilder.build(),
            },
            { inherit: true }
        );
    }
}
