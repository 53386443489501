import { observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';

export default class FormValidator {

    constructor(options) {
        this._message = '';

        this._options = options || {};

        this._isDisabled = observable(false);

        this.isDisabled = pureComputed({
            read: this._getIsDisabled,
            write: this._setIsDisabled,
            owner: this,
        });
    }

    getErrorMessage() {
        const message = this._options.message || this._message;

        return i18n(message, this._options);
    }

    isValid(value) {
        if (this.isDisabled()) {
            return Promise.resolve(true);
        }

        const pairValidator = this._options.pairValidationElement
            ? this._options.pairValidationElement.getValidator(this._options.pairValidationValidator)
            : undefined;

        if (pairValidator) {
            pairValidator.isDisabled(true);
        }

        const result = this._validate(value, this._options);

        const resultPromise = result instanceof Promise
            ? result
            : Promise.resolve(result);

        return resultPromise.then((isValid) => {
            if (pairValidator) {
                this._options.pairValidationElement.validate()
                    .then(() => {
                        pairValidator.isDisabled(false);
                    });
            }

            return isValid;
        });
    }

    validate(value) {
        const result = this._validate(value, this._options);

        return result instanceof Promise ? result : Promise.resolve(result);
    }

    /**
     * @protected
     * @param {*} value - value which will be checked
     * @param {Object} options - validator options
     */
    _validate(value, options = {}) {
        throw new Error(`Method is not implemented, value: [${value}], options: [${options}]`);
    }

    /**
     * @protected
     */
    _getIsDisabled() {
        return this._isDisabled();
    }

    /**
     * @protected
     */
    _setIsDisabled(_isDisabled) {
        return this._isDisabled(_isDisabled);
    }

}