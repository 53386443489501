import ko from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import jobService from 'job-details/service/job';
import notificationsService from 'cx/service/notifications';

export default class ResendTokenViewModel {

    constructor() {
        const routeParams = router.routeParams();

        this.template = ko.observable();
        this.token = routeParams.token;

        this.jobId = routeParams.jobId;
        this.routeId = router.route().id;

        this.notMeMessage = i18n('referral.not-me.header');
        this.tokenExpiredMessage = i18n('general.error-token-expired.header');

        this.jobTitle = ko.observable();

        this.notInterestedMessage = ko.pureComputed(() =>
            i18n('referral.not-interested.description', { job_title: this.jobTitle() || '' }));
    }

    _handleNotAvailableJob() {
        if (this.routeId === 'existing-candidate-verification') {
            return Promise.reject('job-expired');
        }

        return Promise.resolve();
    }

    checkJobAvailability() {
        return jobService.getLightJob(this.jobId)
            .catch(this._handleNotAvailableJob.bind(this));
    }

    showErrorNotification(error) {
        if (error === 'token-invalid' || error === 'token-expired') {
            notificationsService.error(this.tokenExpiredMessage);
        } else if (error !== 'job-expired') {
            notificationsService.error();
            console.error(error);
        }
    }

}
