export default {
    header: {
        LINKS_LIMIT: 5,
        SUBLINKS_LIMIT: 6,
    },
    footer: {
        LINKS_LIMIT: 5,
        SUBLINKS_LIMIT: 3,
        MOBILE_LINKS_LIMIT: 5,
    },
};
