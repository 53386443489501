import { ATTACHMENT_MIME_TYPE_ERROR, ATTACHMENT_UPLOAD_ERROR } from '../config/attachmentErrorCodes';

function error400Handler(response) {
    if (response.includes(ATTACHMENT_MIME_TYPE_ERROR)) {
        return ATTACHMENT_MIME_TYPE_ERROR;
    }

    return response.includes(ATTACHMENT_UPLOAD_ERROR) ? ATTACHMENT_UPLOAD_ERROR : response;
}

export const HTTP_STATUS_CODES_CONFIG = {
    statusCodes: {
        400: ({ response }) => error400Handler(response),
    },
};