import {
    areEventsEnabledGlobally,
    areEventsEnabledLocally,
    areEventsEnabled,
} from 'app/service/areEventsEnabled';
import { eventsState } from 'minimal/module/candidate-self-service/service/EventsState';

export const isEventTabVisible = (): boolean => {
    const eventsCount: number = eventsState.eventsList().length;

    if (!areEventsEnabledGlobally()) {
        return false;
    }

    if (areEventsEnabled()) {
        return true;
    }

    if (areEventsEnabledGlobally() && !areEventsEnabledLocally()) {
        return eventsCount > 0;
    }

    return false;
};
