export default {
    mapCookiesToConfig(settings) {
        return {
            cookieMessageEnabled: settings.isCookieConsentEnabled,
            popUpMessage: settings.cookiePopupMessage,
            policyMessage: settings.cookiePolicy,
            acceptButtonLabel: settings.acceptButtonLabel,
            declineButtonEnabled: settings.isDeclineButtonEnabled,
            declineButtonLabel: settings.declineButtonLabel,
            cookiePreferencesEnabled: settings.isCookiePreferencesEnabled,
            customCookieEnabled: settings.isCustomCategoryActive,
            cookieLinkLabel: settings.cookieLinkText,
            preferencesButtonLabel: settings.cookiePreferenceButtonText,
            preferencesSaveButtonLabel: settings.cookiePreferenceSaveButtonText,
            strictCategoryMessage: settings.cookiePreferenceStrictlyNecessaryDescription,
            functionalCategoryMessage: settings.cookiePreferenceFunctionalDescription,
            analyticalCategoryMessage: settings.cookiePreferenceAnalyticalDescription,
            customCategoryButtonLabel: settings.cookiePreferenceCustomCategoryLabel,
            customCategoryDescription: settings.cookiePreferenceCustomCategoryDescription,
        };
    },

    mapCookiesFromConfig(restSettings) {
        return {
            isCookieConsentEnabled: restSettings.cookieMessageEnabled,
            cookiePopupMessage: restSettings.popUpMessage,
            cookiePolicy: restSettings.policyMessage,
            acceptButtonLabel: restSettings.acceptButtonLabel,
            isDeclineButtonEnabled: restSettings.declineButtonEnabled,
            declineButtonLabel: restSettings.declineButtonLabel,
            isCookiePreferencesEnabled: restSettings.cookiePreferencesEnabled,
            isCustomCategoryActive: restSettings.customCookieEnabled,
            cookieLinkText: restSettings.cookieLinkLabel,
            cookiePreferenceButtonText: restSettings.preferencesButtonLabel,
            cookiePreferenceSaveButtonText: restSettings.preferencesSaveButtonLabel,
            cookiePreferenceStrictlyNecessaryDescription: restSettings.strictCategoryMessage,
            cookiePreferenceFunctionalDescription: restSettings.functionalCategoryMessage,
            cookiePreferenceAnalyticalDescription: restSettings.analyticalCategoryMessage,
            cookiePreferenceCustomCategoryLabel: restSettings.customCategoryButtonLabel,
            cookiePreferenceCustomCategoryDescription: restSettings.customCategoryDescription,
        };
    },
};
