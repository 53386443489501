const metadata = {};

export default {
    registerBlock(blockCode, config) {
        metadata[blockCode] = config;

        return this;
    },

    getConfigurationForBlock(blockCode) {
        return metadata[blockCode];
    },

    isPresectionBlock(blockCode) {
        return metadata[blockCode] ? metadata[blockCode].isPresectionBlock : undefined;
    },

    getComponentForBlock(blockCode) {
        const blockConfig = metadata[blockCode];

        return blockConfig ? blockConfig.component : null;
    },

    getRepositoryForBlock(blockCode) {
        const blockConfig = metadata[blockCode];

        return blockConfig ? blockConfig.repository : null;
    },
};