import 'core/utils/subscribeOnce';
import TooltipFormElementViewModel from 'core/form/component/tooltip-text-form-element/TooltipFormElementViewModel';

export default class CheckboxFormElementViewModel extends TooltipFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.element.value.subscribeOnce(this.blurHandler.bind(this));

        this.checked = this.element.value;
        this.cssClass = this.attributes.cssClass;
    }

}