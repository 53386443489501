import appConfig from 'app/model/config';

export default {
    isMarketingOptedIn(sitePreferences) {
        return sitePreferences.optIn;
    },

    isJobAlertsOptedIn(candidateSitePreferences) {
        const sitePreferences = candidateSitePreferences.siteTCPreferences
            || [];

        const [sitePreference] = sitePreferences
            .filter(preference => preference.siteNumber === appConfig.siteNumber);

        return Boolean(sitePreference?.jobAlertsEnabled);
    },
};