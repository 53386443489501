import http from 'core/http/http';
import attachmentMapper from 'apply-flow/module/file-upload/mapper/fileAttachment';
import { HTTP_STATUS_CODES_CONFIG } from './attachmentStatusCodesConfig';
import httpBatch from 'core/http/httpBatch';

const QUERY_STRING = '?fields=APPDraftId,Id,CandidateNumber,Category,Type,Attachment,Title';
const DRAFTS_ATTACHMENT_URL = '/recruitingCEJobApplicationDrafts';

export default {
    queryOne(appDraftNumber, attachmentId, onlyMeta = false) {
        const params = onlyMeta ? QUERY_STRING : '';

        return http.get(`${DRAFTS_ATTACHMENT_URL}/${appDraftNumber}/child/attachments/${attachmentId}${params}`, {
            statusCodes: {
                404: () => null,
            },
        }).then(attachmentMapper.mapDraftFileAttachmentFromRest);
    },

    query(appDraftNumber, onlyMeta = false) {
        const params = onlyMeta ? QUERY_STRING : '';

        return http.get(`${DRAFTS_ATTACHMENT_URL}/${appDraftNumber}/child/attachments${params}`, {
            statusCodes: {
                404: () => null,
            },
        }).then(attachmentMapper.mapDraftFileAttachmentsFromRest.bind(attachmentMapper));
    },

    create(appDraftId, attachment, abortControllerSignal) {
        const body = attachmentMapper.mapDraftFileAttachmentToRest(attachment);

        const config = {
            ...HTTP_STATUS_CODES_CONFIG,
            signal: abortControllerSignal,
            timeout: 1200000,
        };

        return http.post(`${DRAFTS_ATTACHMENT_URL}/${appDraftId}/child/attachments`, body, config)
            .then(attachmentMapper.mapCreateDraftFileAttachmentFromRest.bind(attachmentMapper));
    },

    remove(appDraftNumber, attachment) {
        const attachmentId = attachment.id();

        return http.delete(`${DRAFTS_ATTACHMENT_URL}/${appDraftNumber}/child/attachments/${attachmentId}`, {
            statusCodes: {
                404() {
                    return Promise.resolve();
                },
            },
        });
    },

    removeAttachments(appDraftNumber, attachmentsList) {
        const batch = httpBatch.create();

        attachmentsList.map(({ id }) =>
            httpBatch.delete(batch, `${DRAFTS_ATTACHMENT_URL}/${appDraftNumber}/child/attachments/${id}`, id),
        );

        return batch.flush();
    },

    submitAllDraftAttachments(candidate, attachments) {
        const supportingDocuments = attachments.filter(({ isResume, isCoverLetter }) => isResume() || isCoverLetter());
        const body = attachmentMapper.mapDraftAttachmentsToSubmit(candidate, supportingDocuments);

        return http.post('/recruitingCECandidates', body, HTTP_STATUS_CODES_CONFIG);
    },

    copyAttachmentToDraft(appDraftId, attachment) {
        const body = attachmentMapper.mapCopyDraftAttachment(attachment);

        return http.post(`${DRAFTS_ATTACHMENT_URL}/${appDraftId}/child/attachments`, body, HTTP_STATUS_CODES_CONFIG)
            .then(attachmentMapper.mapDraftFileAttachmentFromRest);
    },
};