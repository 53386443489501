import { PureComputed, Subscription, pureComputed } from 'knockout';
import { DialogDisplaySettings } from 'minimal/component/minimal-dialog/MinimalDialogViewModel';
import { CookieConsentViewModel } from '../cookie-consent-modal/CookieConsentViewModel';
import { isCookiePolicyDialogVisible } from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_WIDGET_TYPE } from '../../types';
import { cookiePolicyWidgetData } from 'cx/service/loadCookieConsentPageData';
import i18n from 'core/i18n/i18n';
import { getSections } from 'app/module/admin/module/site-editor/module/pages/service/pageTypes';

export class CookiePolicyModalViewModal extends CookieConsentViewModel {
    displaySettings: PureComputed<DialogDisplaySettings>;
    classModifier: string;
    isCookiePolicyDialogVisible = isCookiePolicyDialogVisible;
    isCookiePolicyDialogVisibleSubscription: Subscription;

    constructor() {
        super({ widgetType: COOKIE_CONSENT_WIDGET_TYPE.COOKIE_POLICY_WIDGET });

        this.displaySettings = pureComputed(this.computeDisplaySettings, this);
        this.classModifier = `cookie-policy-modal`;

        this.pageData().modalTitle = i18n('cookie-consent.cookie-policy-title');

        this.isCookiePolicyDialogVisibleSubscription = this.isCookiePolicyDialogVisible.subscribe((value) => {
            if (value) {
                const cookiePolicyWidget = cookiePolicyWidgetData ?? {
                    sections: getSections(COOKIE_CONSENT_WIDGET_TYPE.COOKIE_POLICY_WIDGET),
                    pageParams: [],
                };

                this.loadCustomPage(cookiePolicyWidget);
            }
        });
    }

    computeDisplaySettings(): DialogDisplaySettings {
        const { overlayOpacity, overlayStyle, overlayWidth, overlayWidthUnit } =
            this.viewData.customPage()?.params || {};

        return {
            opacity: overlayOpacity ? `${overlayOpacity}` : null,
            alignment: overlayStyle || null,
            width: overlayWidth && overlayWidthUnit ? `${overlayWidth}${overlayWidthUnit}` : null,
        };
    }

    dispose(): void {
        this.isCookiePolicyDialogVisibleSubscription.dispose();
    }
}
