export default class TimelineTileBuilder {

    constructor(tileStrategy) {
        this._tileStrategy = tileStrategy;
    }

    buildTile(form) {
        this._tileStrategy.useForm(form);

        return this._tileStrategy.resolveFormSources()
            .then(valueMap => Promise.all([
                this._tileStrategy.buildTitle(valueMap),
                this._tileStrategy.buildSubtitle(valueMap),
                valueMap,
            ]))
            .then(([title, subtitle, value]) => ({
                id: form.model().id(),
                title,
                subtitle,
                startDate: value.startDate,
                endDate: value.endDate,
                isCurrent: value.isCurrent,
                valid: form.isValid(),
            }));
    }

}