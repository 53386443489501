import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import tokenService from 'candidate-verification/service/token';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';

router.configure({
    'interview-self-schedule': {
        url: '/my-profile/interview/self-schedule/{id}/{token}',
        title: i18n('candidate-self-service.page-title'),
        view: {
            layout: 'interview-scheduling',
            'interview-scheduling-content': 'self-schedule',
        },
        enter(routeParams) {
            storeShortTokenData({ token: routeParams.token });
        },
    },
    'interview-self-re-schedule': {
        url: '/my-profile/interview/self-schedule/{id}/{requestId}/{token}',
        title: i18n('candidate-self-service.page-title'),
        view: {
            layout: 'interview-scheduling',
            'interview-scheduling-content': 'self-schedule',
        },
        enter(routeParams) {
            storeShortTokenData({ token: routeParams.token });
        },
    },
    'interview-self-re-schedule-without-token': {
        url: '/my-profile/interview/self-schedule/{id}/{requestId}',
        title: i18n('candidate-self-service.page-title'),
        view: {
            layout: 'interview-scheduling',
            'interview-scheduling-content': 'self-schedule',
        },
        canEnter() {
            if (tokenService.isCandidateVerified()) {
                return Promise.resolve();
            }

            return Promise.reject({
                redirectTo: 'home-page',
            });
        },
    },
    'interview-detail': {
        url: '/my-profile/interview/detail/{id}',
        title: i18n('candidate-self-service.page-title'),
        view: {
            layout: 'interview-scheduling',
            'interview-scheduling-content': 'interview-detail',
        },
        canEnter() {
            if (tokenService.isCandidateVerified()) {
                return Promise.resolve();
            }

            return Promise.reject({
                redirectTo: 'home-page',
            });
        },
    },
    'interview-detail-with-token': {
        url: '/my-profile/interview/detail/{id}/{token}',
        title: i18n('candidate-self-service.page-title'),
        view: {
            layout: 'interview-scheduling',
            'interview-scheduling-content': 'interview-detail',
        },
        enter(routeParams) {
            storeShortTokenData({ token: routeParams.token });
        },
    },
});