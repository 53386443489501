import { observable, Observable } from 'knockout';
import { AdditionInformationItem, LayoutStyle } from '../../search-results/config/types';
import { SearchEventResultsContentObservableParams } from './types';
import { SearchResultsContentObservableParams } from '../../search-results/config/types';

const mapAdditionalInformationDisplayed = (
    contentParams: SearchResultsContentObservableParams
): AdditionInformationItem[] => {
    let additionalInformation: AdditionInformationItem[] = [];

    if (contentParams.headerInformationDisplayed().includes('alreadyApplied')) {
        additionalInformation.push('alreadyAppliedTag');
    }

    additionalInformation = [
        ...additionalInformation,
        ...(contentParams
            .jobInfoDisplayed()
            .filter(
                (param) => param === 'location' || param === 'postingDate' || param === 'distance'
            ) as AdditionInformationItem[]),
    ];

    additionalInformation = [
        ...additionalInformation,
        ...(contentParams
            .mainContentInformationDisplayed()
            .filter(
                (param) => param === 'description' || param === 'previewImage'
            ) as AdditionInformationItem[]),
    ];

    additionalInformation = [
        ...additionalInformation,
        ...(contentParams
            .jobTagsInformationDisplayed()
            .filter(
                (param) => param === 'hotJob' || param === 'trendingJob' || param === 'beFirstToApply'
            ) as AdditionInformationItem[]),
    ];

    return additionalInformation;
};

export const mapContentParamsToEventContentParams = (
    contentParams: SearchResultsContentObservableParams
): SearchEventResultsContentObservableParams => {
    const additionalInformationDisplayed = mapAdditionalInformationDisplayed(contentParams);

    return {
        additionalInformationDisplayed: observable(additionalInformationDisplayed),
        displayAfterJobs: contentParams.displayAfterJobs,
        enableInfiniteScroll: contentParams.enableInfiniteScroll,
        hideTalentCommunitySignup: contentParams.hideTalentCommunitySignup,
        jobDisplayStyle: contentParams.jobDisplayStyle,
        numberOfJobsDisplayed: contentParams.numberOfJobsDisplayed,
        width: contentParams.width,
        widthUnit: contentParams.widthUnit,
        headerInformationDisplayed: contentParams.headerInformationDisplayed,
        jobInfoDisplayed: contentParams.jobInfoDisplayed,
        jobTagsInformationDisplayed: contentParams.jobTagsInformationDisplayed,
        mainContentInformationDisplayed: contentParams.mainContentInformationDisplayed,
        jobInfoDisplay: contentParams.jobInfoDisplay,
        jobInfoSeparator: contentParams.jobInfoSeparator,
        jobInfoTagsConfiguration: contentParams.jobInfoTagsConfiguration,
        enableSaveIcon: contentParams.enableSaveIcon,
        enableShareIcon: contentParams.enableShareIcon,
        enableArrowIcon: contentParams.enableArrowIcon,
        enableApplyButton: contentParams.enableApplyButton,
        layoutStyle: observable('classic') as Observable<LayoutStyle>,
        originalLayoutStyle: observable('classic') as Observable<LayoutStyle>,
    };
};
