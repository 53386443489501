import formBuilder from 'core/form/formBuilder';
import AddressForm from 'apply-flow/module/personal-information-address/form/AddressForm';
import formElementFactory from 'apply-flow/module/personal-information-address/form/element/factory';
import CountryForm from './CountryForm';

const AUTOCOMPLETE = {
    country: 'country-name',
    addressLine1: 'address-line1',
    addressLine2: 'address-line2',
    postalCode: 'postal-code',
    city: 'address-level2',
    region2: 'address-level1',
};

export default Object.create(formBuilder, {
    createFormElement: {
        value(elementDef) {
            const formElement = formElementFactory.create(elementDef.type, elementDef.name);

            return this._initFormElement(formElement, elementDef);
        },
    },

    _initFormElement: {
        value(formElement, elementDef) {
            if (formElement.lovColumn) {
                formElement.geoHierarchyService = elementDef.geoHierarchyService;
                formElement.lovColumn(elementDef.lovColumn);
            }

            if (formElement.geographyType) {
                formElement.geographyType(elementDef.geographyType);
            }

            return formBuilder._initFormElement(formElement, elementDef);
        },
    },

    createAddressForm: {
        value(formConfig) {
            const config = formConfig;

            config.elements = config.elements.map((el) => {
                const element = el;

                element.geoHierarchyService = config.geoHierarchyService;
                element.attributes.autocomplete = AUTOCOMPLETE[element.name];

                return this.createFormElement(element);
            });

            return new AddressForm(config);
        },
    },

    createCountryForm: {
        value(formConfig) {
            const config = formConfig;

            config.elements = config.elements.map((el) => {
                const element = el;

                element.geoHierarchyService = config.geoHierarchyService;
                element.attributes.autocomplete = AUTOCOMPLETE[element.name];

                return this.createFormElement(element);
            });

            return new CountryForm(config);
        },
    },
});