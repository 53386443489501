import { Observable } from 'knockout';
import { Dictionary } from 'app/types/utils';
import appConfig from 'app/model/config';
import { CategoryTileModel } from 'site-editor/config/categoryTilesTypes';
import { TileId } from 'minimal/component/category-tiles/config/types';
import i18n from 'core/i18n/i18n';
import { STATIC_TILES_DATA } from 'minimal/component/category-tiles/config/staticCategoryTilesData';

type SelectSingleValue = string | number;

type SelectSingleOption = {
    label: string;
    value: SelectSingleValue;
};

type Props = {
    value: Observable<TileId>;
    config: Dictionary<string | string[]>;
};

export const GLOBAL_TILE_ID = '-1';

const allTilesOption = {
    value: GLOBAL_TILE_ID,
    label: i18n('facets.default-option-label'),
};

export class CcCategoryTilesListViewModel {
    value: Observable<TileId>;
    config: Dictionary<string | string[]>;

    tilesData: SelectSingleOption[];
    isDisabled: boolean;

    constructor(props: Props) {
        const { value, config } = props;

        this.value = value;
        this.config = config;
        this.isDisabled = false;

        this.tilesData = this.getAllTiles();

        this.value(GLOBAL_TILE_ID);
    }

    getAllTiles(): SelectSingleOption[] {
        this.isDisabled = !appConfig.links.jobCategory?.length;

        const availableCategoryTiles = appConfig.links.jobCategory?.length
            ? appConfig.links.jobCategory
            : STATIC_TILES_DATA;

        const tilesData = [
            allTilesOption,
            ...availableCategoryTiles.map((item: CategoryTileModel) => {
                return {
                    value: item.id || item.jobFamilyIds,
                    label: item.title,
                };
            }),
        ];

        return tilesData;
    }
}
