import mapper from 'core/http/batch/mapper';

export default {
    baseUrl: '/hcmRestApi/resources/latest',
    headers: {
        'Content-Type': 'application/vnd.oracle.adf.batch+json',
    },
    transformRequest(config) {
        config.body = mapper.mapToRest(config.body);

        return config;
    },
};