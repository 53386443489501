export class Marker {

    constructor({ coordinates, popupContent, markerObject, label = '' }) {
        this.coordinates = coordinates;
        this.markerObject = markerObject;
        this.label = label;
        this.popupContent = popupContent;
    }

    setMarkerObject(markerObject) {
        this.markerObject = markerObject;
    }

    validate() {
        return this.coordinates.validate();
    }

}