import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { JobDetailsMediaCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapCommonParams } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';

export const mapParamsFromRest = (params: RestParam[]): JobDetailsMediaCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return notReachable(paramKey);
            }
        },
    });
};
