import converterFactory from 'core/l10n/converter/factory';


export default {
    format(date) {
        return this.formatShort(date);
    },

    formatShort(date) {
        return converterFactory.createDateTimeConverter('short').format(date);
    },

    formatMedium(date) {
        return converterFactory.createDateTimeConverter('medium').format(date);
    },

    formatLong(date) {
        return converterFactory.createDateTimeConverter('long').format(date);
    },

    formatFull(date) {
        return converterFactory.createDateTimeConverter('full').format(date);
    },

    formatCustom(date, options) {
        return converterFactory.createDateTimeCustomConverter(options).format(date);
    },

    parse(formattedDate) {
        return this.parseShort(formattedDate);
    },

    parseShort(formattedDate) {
        return converterFactory.createDateTimeConverter('short').parse(formattedDate);
    },

    parseMedium(formattedDate) {
        return converterFactory.createDateTimeConverter('medium').parse(formattedDate);
    },

    parseLong(formattedDate) {
        return converterFactory.createDateTimeConverter('long').parse(formattedDate);
    },

    parseFull(formattedDate) {
        return converterFactory.createDateTimeConverter('full').parse(formattedDate);
    },
};