import { JobDetailsViewModel } from './JobDetailsViewModel';
import historyService from 'ce-common/service/historyService';
import cxEvents from 'cx/config/events';

export class JobDetailsFullscreenViewModel extends JobDetailsViewModel {
    constructor() {
        super();

        cxEvents.loading.dispatch();

        this.viewData = {
            ...this.viewData,
            viewType: 'fullscreen',
            isPreviousRouteStored: historyService.isPreviousRouteStored(),
        };
    }

    close(): void {
        historyService.moveBackOrFallbackTo('search');
    }
}
