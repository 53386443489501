import { SearchBarContentParams, SearchBarCustomizationParams } from './types';
import {
    EMPTY_BLOCK_STYLES,
    EMPTY_COMMON_PARAMS,
    EMPTY_TYPOGRAPHY,
} from 'site-editor/module/content-editor/config';

export const DEFAULT_SEARCH_BAR_CONTENT: SearchBarContentParams = {
    widthUnit: 'px',
    heightUnit: 'px',
    width: 800,
    height: 90,
    searchBarSticky: false,
};

export const emptySearchBarParams: SearchBarCustomizationParams = {
    searchBarContent: { ...DEFAULT_SEARCH_BAR_CONTENT },
    searchValuesTypography: { ...EMPTY_TYPOGRAPHY },
    searchValuesTypographySelected: { ...EMPTY_TYPOGRAPHY },
    headerTypography: { ...EMPTY_TYPOGRAPHY },
    dropdownValuesTypography: { ...EMPTY_TYPOGRAPHY },
    commonParams: { ...EMPTY_COMMON_PARAMS },
    searchButtonStyle: { ...EMPTY_BLOCK_STYLES },
    buttonHoverStyle: { ...EMPTY_BLOCK_STYLES },
    overallStyle: { ...EMPTY_BLOCK_STYLES },
    separators: { ...EMPTY_BLOCK_STYLES },
};

export const defaultSearchBarParams: SearchBarCustomizationParams = {
    ...emptySearchBarParams,
    searchValuesTypographySelected: {
        ...emptySearchBarParams.searchValuesTypographySelected,
        fontWeight: 'normal',
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
    buttonHoverStyle: {
        ...emptySearchBarParams.buttonHoverStyle,
        iconColorLight: 'var(--color-theme-1)',
        backgroundColor: 'transparent',
    },
};
