import router from 'app/model/router';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import applyFlowEvents from 'apply-flow/config/events';
import candidate from 'apply-flow/model/candidate';
import submitService from 'apply-flow/service/submit';
import submitChecklist from 'apply-flow/service/submit-checklist/submitChecklist';
import tokenService from 'candidate-verification/service/token';
import hasSubmissionCache from 'candidate-verification/service/hasSubmissionCache';
import unverifiedService from 'candidate-verification/service/unverified';
import * as VERIFICATION_METHODS from 'candidate-verification/config/verificationMethods';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import applicationService, { INVALID_ESIGNATURE, JOB_NOT_AVAILABLE, DUPLICATE_SUBMISSION } from 'apply-flow/service/application';
import CandidateChallengeAbstractViewModel from 'candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import { JOB_APPLICATION } from 'candidate-verification/config/verificationSubmodules';
import userEvents, { APPLY_FLOW_SUBMITTED, APPLY_FLOW_FINISHED, APPLY_FLOW_APPLICATION_CREATED } from 'cx/config/userEvents';
import { ATTACHMENT_MIME_TYPE_ERROR, ATTACHMENT_UPLOAD_ERROR } from '../../module/file-upload/config/attachmentErrorCodes';
import profileItemErrorHandler from 'apply-flow/module/profile-items/service/errorHandler';
import { applicationDraft } from 'apply-flow/service/applicationDraftInstance';
import apiLogger from 'core/api-logger/apiLogger';
import { getBaseLogMetadata } from 'core/api-logger/logMetadata';
import { APPLY_FLOW } from 'core/api-logger/config/module';
import jobService from 'job-details/service/job';
import postApplyService from '../../../apply-flow-post/service/postApply';
import scrollKeeper from 'minimal/service/scrollKeeper';
import appEvents from 'app/config/events';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import { profileImportTypes } from 'cx/module/apply-flow/module/profile-import/config/importTypes';
import { clearCandidate, processUnverifiedCandidate } from './service/candidateSummary';
import { confirmTCSignup } from './service/jobAlertsSummary';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';
import userTracking from 'cx/service/userTracking';
import { removeWithdrawnApplication } from 'ce-common/service/withdrawService';

const TEMPLATES = [
    'already-applied',
    'candidate-exists',
    'token-expired-apply-flow',
];

export default class ApplyFlowSummaryViewModel extends CandidateChallengeAbstractViewModel {

    constructor({ application, template }) {
        super();

        this.template = template;
        this.application = application;
        this.queryParams = router.routeParams().query;
        this.jobId = router.routeParams().jobId;
        this.job = null;
        this.userLanguage = siteLanguage.restore();
        this.isPartnerLinksEnabled = false;
        this.confirmApplicationErrorOccured = false;
        this.jobNotAvailableErrorOccured = false;
        this.awliLConversionWidgetLoaded = false;

        this.candidate.email = candidate.basicInformation.email;
        this.candidate.phone = candidate.basicInformation.phoneNumber;
        this.candidate.verificationMethod = candidate.basicInformation.verificationMethod;
        this.challenge.submodule = JOB_APPLICATION;
        this.verificationMethods = VERIFICATION_METHODS;

        this.candidateVerificationMethod = candidate.basicInformation.verificationMethod();

        this._submitHandler = () => this._saveCandidate();
        applyFlowEvents.submit.add(this._submitHandler);

        this._loadJob();

        this._unauthorizedSignal = appEvents.unauthorizedError.addOnce(() => {
            this._dumpLogsOnError();
        });

        this._applicationSubmittedSignal = userEvents[APPLY_FLOW_APPLICATION_CREATED].addOnce(({ submissionId }) => {
            this._trackJobSubmitSuccess(submissionId);
        });

        profileImportEvents.isAwliConversionLoaded.addOnce(() => {
            this.awliLConversionWidgetLoaded = true;
        });
    }

    _trackJobSubmitSuccess(submissionId) {
        userTracking.trackJobAppSubmitSuccess(this.jobId, submissionId);
    }

    _saveCandidate() {
        submitChecklist.reset();
        submitChecklist.show();

        userEvents[APPLY_FLOW_SUBMITTED].dispatch();

        return submitService.saveCandidateAndApplication(this.application(), this.jobId, this.submitProgress)
            .then(() => {
                userEvents[APPLY_FLOW_FINISHED].dispatch({
                    jobId: this.jobId,
                    candidateId: candidate.id(),
                });
            }).then(this._onCandidateSubmitted.bind(this))
            .then(() => {
                removeWithdrawnApplication(this.jobId);
            })
            .catch(this.manageApplicationCreationErrors.bind(this))
            .finally(submitChecklist.hide);
    }

    async _onCandidateSubmitted() {
        this._dumpLogs();

        cachedCandidateApplications.clear();

        if (!tokenService.isKnownCandidate()) {
            processUnverifiedCandidate(this.jobId);

            return this._showPinVerification();
        }

        return this._postProcessApplication();
    }

    _handleAwliTracking() {
        if (profileImportTypes.linkedin !== applicationDraft?.draftSource) {
            return;
        }

        if (!this.awliLConversionWidgetLoaded) {
            let maxWait = 0;
            const minWait = 500;

            const timer = setInterval(() => {
                maxWait += minWait;

                if (this.awliLConversionWidgetLoaded || maxWait > 5000) {
                    clearInterval(timer);
                }
            }, minWait);
        }
    }


    _showPinVerification() {
        applyFlowEvents.submitSucceed.dispatch();

        submitChecklist.hide();
        this._resetScroll();

        this.template('pin-code-required');

        return Promise.resolve();
    }

    async onPinValid() {
        return this._postProcessApplication();
    }

    async _postProcessApplication() {
        let application;

        try {
            const { getForJob, accept } = applicationService;

            const applicationPromise = postApplyService.isPostApply()
                ? getForJob(this.jobId)
                : accept(this.application);

            [application] = await Promise.all([
                applicationPromise,
                confirmTCSignup(),
            ]);

            return this._setPartnerLinksEnabled(application);
        } catch (error) {
            return this._onConfirmApplicationError(error);
        } finally {
            this._afterCandidateVerification();
        }
    }

    _setPartnerLinksEnabled(application) {
        this.isPartnerLinksEnabled = (application.hasAssessment()
            || application.hasTaxCredit()) && application.isActive();

        return application;
    }

    _afterCandidateVerification() {
        this._handleAwliTracking();
        hasSubmissionCache.remove(this.jobId);
        this.redirectToCSS();
    }

    _onConfirmApplicationError(error) {
        if (error === JOB_NOT_AVAILABLE) {
            this.jobNotAvailableErrorOccured = true;
        } else {
            this.confirmApplicationErrorOccured = true;
        }

        return this._logError(error);
    }

    async _logError(error) {
        console.error(error);
        await this._dumpLogs();
    }

    async manageApplicationCreationErrors(error) {
        clearCandidate();

        console.error(error);

        this._dumpLogsOnError();
        applyFlowEvents.refresh.dispatch();
        applyFlowEvents.restartDraftMonitor.dispatch();

        if (typeof error === 'string' && TEMPLATES.indexOf(error) > -1) {
            this.template(error);
            applyFlowEvents.submitFailed.dispatch();
        } else if (error === INVALID_ESIGNATURE) {
            applyFlowEvents.invalidESignature.dispatch();
        } else if (error === JOB_NOT_AVAILABLE) {
            notificationsService.error(i18n('apply-flow.job-no-longer-available'), 0);
        } else if (error === ATTACHMENT_UPLOAD_ERROR) {
            notificationsService.error(i18n('apply-flow.submit-errors.file-upload'), 0);
        } else if (error === ATTACHMENT_MIME_TYPE_ERROR) {
            notificationsService.error(i18n('validators.file-upload.mime-type-error'), 0);
        } else if (profileItemErrorHandler.isValidationError(error)) {
            profileItemErrorHandler.handleValidationError(error);
        } else if (error === DUPLICATE_SUBMISSION) {
            this.template('already-applied');
            applyFlowEvents.submitFailed.dispatch();
        } else {
            notificationsService.error(i18n('apply-flow.submit-errors.unhandled-error'), 0);
        }
    }

    redirectToCSS() {
        candidate.clear();
        unverifiedService.destroy();

        router.go('candidate-self-service', {}, { inherit: false })
            .then(this._notifyAfterRedirectToCSS.bind(this));

        applyFlowEvents.submitSucceed.dispatch();
    }

    _notifyAfterRedirectToCSS() {
        if (this.jobNotAvailableErrorOccured) {
            notificationsService.errorAfterLoaded(i18n('candidate-self-service.job-unavailable-confirmation-error'), 500);

            return;
        }

        if (this.confirmApplicationErrorOccured) {
            notificationsService.errorAfterLoaded(i18n('candidate-self-service.confirm-your-application', {
                JOB_TITLE: this.job.title,
            }), 500);

            return;
        }

        const THANK_YOU_MESSAGE = i18n('candidate-self-service.returning-candidate-confirm-message');
        const ADDITIONAL_INSTRUCTION_MESSAGE = i18n('apply-flow.thank-you-dialog.additional-instructions-text');

        notificationsService.successAfterLoaded(this.isPartnerLinksEnabled
            ? ADDITIONAL_INSTRUCTION_MESSAGE
            : THANK_YOU_MESSAGE);
    }

    expiredCandidateRedirection() {
        const route = 'job-details.confirm-email';

        unverifiedService.setUnverifiedVerificationData();
        tokenService.destroy();

        return router.go(route);
    }

    _dumpLogs() {
        apiLogger.dump(this._getLogMetadata());
    }

    _dumpLogsOnError() {
        apiLogger.dumpOnError(this._getLogMetadata());
    }

    _getLogMetadata() {
        return Object.assign(getBaseLogMetadata(), {
            module: APPLY_FLOW,
            requisitionNumber: this.job.id,
            draftApplicationId: applicationDraft.id,
        });
    }

    async _loadJob() {
        this.job = await jobService.getJob(this.jobId)
            .catch((error) => {
                console.error(error);

                return {};
            });
    }

    _resetScroll() {
        scrollKeeper.scrollTo(0);
    }

    dispose() {
        applyFlowEvents.submit.remove(this._submitHandler);

        if (this._unauthorizedSignal) {
            this._unauthorizedSignal.detach();
        }

        if (this._applicationSubmittedSignal) {
            this._applicationSubmittedSignal.detach();
        }
    }

}
