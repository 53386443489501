import { SimilarJobAdditionInformationItem } from 'minimal/module/search/component/search-results/config/types';
import { DimensionUnit } from 'minimal/module/search/service/customCss';
import { defaultContentParams } from '../config/emptyParams';
import { JobDetailsSimilarJobsContentParams } from '../config/types';
import { DisplayStyle } from 'cx/module/search/config/types';

export const mapContentParams = (
    stringifiedParams: string | undefined | null
): JobDetailsSimilarJobsContentParams => {
    if (!stringifiedParams) {
        return defaultContentParams;
    }

    const parsedParams = JSON.parse(stringifiedParams) as Record<string, string | boolean | undefined | null>;

    return {
        additionalInformationDisplayed:
            (parsedParams.additionalInformationDisplayed as unknown as SimilarJobAdditionInformationItem[]) ||
            defaultContentParams.additionalInformationDisplayed,

        headerInformationDisplayed: defaultContentParams.headerInformationDisplayed,
        jobInfoDisplayed: defaultContentParams.jobInfoDisplayed,
        jobTagsInformationDisplayed: defaultContentParams.jobTagsInformationDisplayed,
        mainContentInformationDisplayed: defaultContentParams.mainContentInformationDisplayed,

        hideMoreJobsButton:
            typeof parsedParams.hideMoreJobsButton === 'undefined' || parsedParams.hideMoreJobsButton === null
                ? defaultContentParams.hideMoreJobsButton
                : Boolean(parsedParams.hideMoreJobsButton),

        jobDisplayStyle:
            (parsedParams.jobDisplayStyle as DisplayStyle) || defaultContentParams.jobDisplayStyle,

        numberOfJobsDisplayed: parsedParams.numberOfJobsDisplayed
            ? parseInt(String(parsedParams.numberOfJobsDisplayed))
            : defaultContentParams.numberOfJobsDisplayed,

        width: parsedParams.width ? parseInt(String(parsedParams.width)) : defaultContentParams.width,

        widthUnit: (parsedParams.widthUnit as DimensionUnit) || defaultContentParams.widthUnit,

        jobInfoDisplay: defaultContentParams.jobInfoDisplay,
        jobInfoSeparator: defaultContentParams.jobInfoSeparator,
        jobInfoTagsConfiguration: defaultContentParams.jobInfoTagsConfiguration,
    };
};
