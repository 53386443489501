import router from 'app/model/router';
import i18n from 'core/i18n/i18n';

router.configure({
    'not-found': {
        parent: 'cx',
        title: i18n('general.error-404.page-not-found'),
        url: '/404',
        view: {
            main: 'error-404',
        },
    },
});
