import UnscheduledInterviewRequest from '../model/UnscheduledInterviewRequest';

export default {
    mapFromRest(value) {
        return new UnscheduledInterviewRequest({
            interviewRequestId: value.intrvwRequestsId,
            interviewId: value.interviewId,
            submissionId: value.submissionId,
            scheduleId: value.scheduleId,
            isSchedulable: value.isSchedulable === 'Y',
        });
    },
};