export const addLanguageSupportToMaplibregl = (maplibregl) => {
    const langFallbackDecorate = (style, cfg) => {
        const { layers } = style;
        const lf = cfg['layer-filter'];
        const { decorators } = cfg;
        const [, lfProp] = lf;
        const lfValues = lf.slice(2);

        for (let i = layers.length - 1; i >= 0; i--) {
            const layer = layers[i];

            if (
                !(
                    lf[0] === 'in'
                    && lfProp === 'layout.text-field'
                    && layer.layout
                    && layer.layout['text-field']
                    && lfValues.indexOf(layer.layout['text-field']) >= 0
                )
            ) {
                // eslint-disable-next-line no-continue
                continue;
            }

            for (let j = decorators.length - 1; j >= 0; j--) {
                const decorator = decorators[j];
                let postfix = decorator['layer-name-postfix'] || '';

                postfix = postfix.replace(/(^-+|-+$)/g, '');

                let newLayer;

                if (j > 0) {
                    newLayer = JSON.parse(JSON.stringify(layer));

                    layers.splice(i + 1, 0, newLayer);
                } else {
                    newLayer = layer;
                }

                newLayer.id += postfix ? `-${postfix}` : '';
                newLayer.layout['text-field'] = decorator['layout.text-field'];

                if (newLayer.layout['symbol-placement'] === 'line') {
                    newLayer.layout['text-field'] = newLayer.layout['text-field'].replace('\n', ' ');
                }

                const filterPart = decorator['filter-all-part'].concat();

                if (!newLayer.filter) {
                    newLayer.filter = filterPart;
                } else if (newLayer.filter[0] === 'all') {
                    newLayer.filter.push(filterPart);
                } else {
                    newLayer.filter = ['all', newLayer.filter, filterPart];
                }
            }
        }
    };

    let langaugeEnabled = true;

    let setStyleMutex = false;
    const origSetStyle = maplibregl.Map.prototype.setStyle;

    maplibregl.Map.prototype.setStyle = function setStyle(...args) {
        origSetStyle.apply(this, args);

        if (langaugeEnabled && !setStyleMutex) {
            if (this.styleUndecorated) {
                this.styleUndecorated = undefined;
            }

            this.once('styledata', () => {
                if (this.languageOptions) {
                    this.setLanguage(this.languageOptions.language, this.languageOptions.noAlt);
                }
            });
        }
    };

    maplibregl.Map.prototype.setLanguageEnabled = function setLanguageEnabled(enable) {
        langaugeEnabled = enable;
    };

    maplibregl.Map.prototype.setLanguage = function setLanguage(language, noAlt) {
        this.languageOptions = {
            language,
            noAlt,
        };

        if (!this.styleUndecorated) {
            try {
                this.styleUndecorated = this.getStyle();
            } catch (e) {
                // TODO - Styles call before maps loaded
                console.info('styles loading...');
            }
        }

        if (!this.styleUndecorated) {
            return;
        }

        const isNonLatin =
            [
                'ar',
                'hy',
                'be',
                'bg',
                'zh',
                'ka',
                'el',
                'he',
                'ja',
                'ja_kana',
                'kn',
                'kk',
                'ko',
                'mk',
                'ru',
                'sr',
                'th',
                'uk',
            ].includes(language);

        const style = JSON.parse(JSON.stringify(this.styleUndecorated));

        const langCfg = {
            'layer-filter': [
                'in',
                'layout.text-field',
                '{name}',
                '{name_de}',
                '{name_en}',
                '{name:latin}',
                '{name:latin} {name:nonlatin}',
                '{name:latin}\n{name:nonlatin}',
            ],
            decorators: [
                {
                    'layout.text-field': isNonLatin
                        ? `{name:nonlatin}${noAlt ? '' : '\n{name:latin}'}`
                        : `{name:latin}${noAlt ? '' : '\n{name:nonlatin}'}`,
                    'filter-all-part': ['!has', `name:${language}`],
                },
                {
                    'layer-name-postfix': language,
                    'layout.text-field': `{name:${language}}${
                        noAlt ? '' : `\n{name:${isNonLatin ? 'latin' : 'nonlatin'}}`
                    }`,
                    'filter-all-part': ['has', `name:${language}`],
                },
            ],
        };

        if (language === 'native') {
            langCfg.decorators = [
                {
                    'layout.text-field': '{name}',
                    'filter-all-part': ['all'],
                },
            ];
        }

        langFallbackDecorate(style, langCfg);

        setStyleMutex = true;
        this.setStyle(style);
        setStyleMutex = false;
    };

    maplibregl.Map.prototype.autodetectLanguage = function autodetectLanguage(lang, fallback) {
        this.setLanguage(lang || fallback || 'native');
    };
};
