export default {
    mapAssessmentLinkFromRest({ assessmentUrl }) {
        return assessmentUrl;
    },

    mapApplicationAssessmentsFromRest(assessmentData) {
        return {
            provisioningId: assessmentData.provisioningId,
            partnerName: assessmentData.partnerName,
        };
    },

};