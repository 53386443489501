import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';
import ValidatableElement from 'core/form/element/ValidatableElement';
import PhoneElement from 'core/form/element/Phone';
import ValidatableForm from 'core/form/ValidatableForm';
import appConfig from 'app/model/config';
import duplicateCheck from '../../../../candidate-verification/service/duplicateCheck';
import personalInformationFormBuilder from './personalInformationFormBuilder';
import EditPersonalInformationForm from './EditPersonalInformationForm';
import EmailEditFormElement from '../element/EmailEditFormElement';
import PhoneEditFormElement from '../element/PhoneEditFormElement';

const USE_FOR_COMMUNICATION_LABEL = i18n('candidate-self-service.communication-channel.use-for-communication');

export default Object.create(formBuilder, {
    createForm: {
        value(params) {
            const { universalElements, emailElement, phoneElement, candidate } = params;

            let form;

            const config = {
                elements: universalElements,
            };

            config.elements = config.elements.map((element) => {
                element.validateImmediately = config.validateImmediately;

                return formBuilder.createFormElement(element);
            });

            if (!emailElement || !phoneElement) {
                form = new ValidatableForm(config);

                form.bindComponent('name-form');

                personalInformationFormBuilder._addAutoCompleteAttribute(form.elements());

                return form;
            }

            this._composeContactFields({
                emailElement,
                phoneElement,
                candidate,
            })
                .forEach(field => config.elements.push(field));

            const {
                emailVerifiedFlag,
                emailPreferredFlag,
                phoneVerifiedFlag,
                phonePreferredFlag,
            } = candidate.basicInformation;

            form = new EditPersonalInformationForm(config, {
                candidateId: candidate.id(),
                emailVerifiedFlag,
                emailPreferredFlag,
                phoneVerifiedFlag,
                phonePreferredFlag,
            });

            form.bindComponent('name-form');

            personalInformationFormBuilder._addAutoCompleteAttribute(form.elements());

            return form;
        },
    },

    _composeContactFields: {
        value(contactParams) {
            const { emailElement, phoneElement, candidate } = contactParams;

            return [
                this._createEmailFormElement(candidate, emailElement),
                this._createEmailCommunicationChannelCheckbox(),
                this._createPhoneFormElement(candidate, phoneElement),
                this._createPhoneCommunicationChannelCheckbox(),
                this._createPhoneCommunicationChannelWarning('phoneFeesWarning'),
            ];
        },
    },

    _createEmailFormElement: {
        value(candidate, element) {
            const emailFormElement = new EmailEditFormElement('email');

            const { email, emailVerifiedFlag } = candidate.basicInformation;
            const candidateEmail = email();

            emailFormElement.origin = candidateEmail;
            emailFormElement.cssMode = true;

            emailFormElement
                .label(element.label)
                .validators(element.validators);

            if (element.attributes.required) {
                emailFormElement.initiallyRequired(true);
            }

            emailFormElement.addValidator('serviceCall', {
                serviceCall: duplicateCheck.checkEmail,
                exclude: value => duplicateCheck.compareEmail(value, candidateEmail),
            });

            emailFormElement.addValidator('equalsTo', {
                message: 'candidate-self-service.communication-channel.edit-mode-warning',
                source: emailFormElement.editMode,
                valueToCompare: false,
            });

            const errorValidatorMessage = this._isPhoneVerificationEnabled()
                ? 'validators.purge-email-error'
                : 'validators.purge-email-error-no-sms-communication';

            emailFormElement.addValidator('error', {
                message: errorValidatorMessage,
                error: 'IRC_CAND_NO_ALT_PREF_CHANNEL',
                source: emailFormElement.errorCode,
            });

            if (emailVerifiedFlag()) {
                emailFormElement.removeValidator(emailFormElement.getValidator('required'));
                emailFormElement.isDisabled(true);
            }

            return emailFormElement;
        },
    },

    _createPhoneFormElement: {
        value(candidate, element) {
            let phoneFormElement;

            const { basicInformation } = candidate;

            const { phone: { countryCode, areaCode, number, legislationCode }, phoneVerifiedFlag } = basicInformation;

            const candidatePhone = {
                countryCode: countryCode(),
                areaCode: areaCode(),
                number: number(),
                legislationCode: legislationCode(),
            };

            if (this._isPhoneVerificationEnabled()) {
                phoneFormElement = new PhoneEditFormElement('phoneNumber');
                phoneFormElement.registerModel(candidatePhone);
                phoneFormElement.cssMode = true;

                phoneFormElement.addValidator('error', {
                    message: 'validators.purge-phone-error',
                    error: 'IRC_CAND_NO_ALT_PREF_CHANNEL',
                    source: phoneFormElement.errorCode,
                });

                phoneFormElement.addValidator('equalsTo', {
                    message: 'candidate-self-service.communication-channel.edit-mode-warning',
                    source: phoneFormElement.editMode,
                    valueToCompare: false,
                });

                if (element.attributes.required) {
                    phoneFormElement.initiallyRequired(true);
                }
            } else {
                phoneFormElement = new PhoneElement('phoneNumber');
                phoneFormElement.isVerificationInProgress = observable(false);
            }

            phoneFormElement.origin = candidatePhone;

            phoneFormElement
                .label(element.label)
                .validators(element.validators)
                .attributes({ id: 'phoneNumber', autocomplete: 'none' });

            if (this._isPhoneVerificationEnabled() && phoneVerifiedFlag()) {
                phoneFormElement.removeValidator(phoneFormElement.getValidator('required'));
                phoneFormElement.isDisabled(true);
            }

            return phoneFormElement;
        },
    },

    _createEmailCommunicationChannelCheckbox: {
        value() {
            const element = formElementFactory.create('checkbox', 'emailPreferredFlag')
                .label(USE_FOR_COMMUNICATION_LABEL);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

    _createPhoneCommunicationChannelCheckbox: {
        value() {
            const element = formElementFactory.create('checkbox', 'phonePreferredFlag')
                .label(USE_FOR_COMMUNICATION_LABEL);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

    _createPhoneCommunicationChannelWarning: {
        value(name) {
            const warningMessageLabel = i18n('apply-flow.candidate-verification.phone-fees-info');

            const element = new ValidatableElement(name);

            element.component('communication-channel-warning');
            element.label(warningMessageLabel);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

    _isPhoneVerificationEnabled: {
        value() {
            return appConfig.getSettingByKey('SMS_ENABLED_EXTERNAL_CANDIDATES') === 'true';
        },
    },
});
