import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class ScaleNumberFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.scale';

        ko.utils.extend(this._options, {
            scaleValue: this._options.scale,
        });
    }

    _validate(value = '', options) {
        const [, decimalPart = ''] = String(value).split('.');

        return decimalPart.length <= options.scale;
    }

}