import { Observable, PureComputed, pureComputed } from 'knockout';
import { JobDetailsViewData } from 'minimal/module/job-details/types';

type Props = {
    viewData: JobDetailsViewData;
    jobId: Observable<string>;
    isExpired: Observable<boolean>;
};

type State = { type: 'expired' } | { type: 'ok' };

export class JobDetailsCheckerViewModel {
    state: PureComputed<State>;
    viewData: JobDetailsViewData;
    jobId: Observable<string>;

    constructor({ viewData, jobId, isExpired }: Props) {
        this.viewData = viewData;
        this.jobId = jobId;
        this.state = pureComputed<State>(() => (isExpired() ? { type: 'expired' } : { type: 'ok' }) as State);
    }
}
