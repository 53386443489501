import { observable } from 'knockout';
import { isBlockRequired } from 'apply-flow/component/apply-flow-block/service/isBlockRequired';
import i18n from 'core/i18n/i18n';

const blocksWithNoHeader = [
    'ORA_SUMMARY',
    'ORA_PROFILE_IMPORT',
    'ORA_JOB_ALERT_PREFERENCES',
];

export default class ApplyFlowBlockViewModel {

    constructor(params) {
        this.application = params.application;
        this.section = params.section;
        this.block = params.block;
        this.isActive = params.isActive;
        this.isSingleClickFlow = params.isSingleClickFlow;
        this.flow = params.flow;
        this.sectionDisabled = observable(false);
        this.isRequired = isBlockRequired(params.block.blockProperties);

        this.shouldRenderBlockHeader = () => blocksWithNoHeader.indexOf(this.block.code) === -1;
        this.requiredBlockAriaLabel = this.isRequired ? i18n('apply-flow.a11y.required-block-label') : '';
        this.blockAriaLabel = `${this.block.title || ''} ${this.requiredBlockAriaLabel} ${this.block.instructions || ''}`;
    }

}