import { bindingHandlers } from 'knockout';
import { MapController } from '../model/MapController';
import { getMapProvider } from '../service/providers/factory';

/**
 * Shows map inside an element.
 *
 *  @param {Array} markers - array of markers to display on a map
 *
 *  @example
 *      <div class="my-map" data-bind="map: { markers: job.markers }"></div>
 */

bindingHandlers.map = {
    init(element, valueAccessor) {
        const { markers } = valueAccessor();
        const mapProvider = getMapProvider();

        if (mapProvider && markers && markers.length) {
            const mapController = new MapController(mapProvider, element);

            mapController.showMap();
            mapController.addMarkers(markers);
            mapController.adjustBounds();
        }
    },
};