import FormValidator from 'core/form/validator/Validator';

export default class CustomJsValidator extends FormValidator {

    _validate(value, options) {
        options.runCustomJsValidation();

        return true;
    }

}