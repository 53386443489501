import { SiteList } from '../config/types';

type RestSite = {
    siteNumber: string;
    siteName: string;
    siteUrlname: string;
};

type RestSiteResponse = {
    items: RestSite[];
};

export default {
    mapSiteListFromRest(restCollection: RestSiteResponse): SiteList {
        return restCollection.items.reduce(
            (siteList, { siteName, siteNumber, siteUrlname }) => ({
                ...siteList,
                [siteNumber]: {
                    siteName,
                    siteUrlName: siteUrlname,
                },
            }),
            {}
        );
    },
};
