import l10n from 'core/l10n/l10n';
import offerLetterMapper from './offerLetter';
import attachments from './attachments';
import mediaMapper from 'job-details/mapper/media';

export default {

    mapCollectionFromRest(restData) {
        return restData.items.map(this.fromRest.bind(this))[0];
    },

    fromRest(restResponse) {
        if (restResponse.mediaLinks) {
            restResponse.media = mediaMapper.mapFromRest(restResponse.mediaLinks);
            delete restResponse.mediaLinks;
        }

        restResponse.offerLetter = offerLetterMapper.fromRest(restResponse.offerLetter);
        restResponse.attachments = attachments.fromRest(restResponse.attachments);

        restResponse.status = restResponse.displayMode;
        delete restResponse.displayMode;

        restResponse.candidateName = restResponse.candidateDisplayName;
        delete restResponse.candidateDisplayName;

        restResponse.acceptedOnBehalf = restResponse.acceptedOnBehalf === 'Y';

        if (restResponse.acceptedOnBehalfDate) {
            restResponse.acceptedOnBehalfDate = l10n.dateTime.formatLong(restResponse.acceptedOnBehalfDate);
        }

        if (restResponse.signatureDate) {
            restResponse.signatureDate = l10n.dateTime.formatLong(restResponse.signatureDate);
        }

        return restResponse;
    },
};
