import { observable, pureComputed } from 'knockout';
import { searchJobResults } from '../../model/searchJobResults';
import { mapParamsConfigurationToObservable } from '../../service/customCss';
import { getCustomStyles } from './service/customStyles';
import { getFiltersSelectors } from './config/selectors';
import { emptyFiltersParams } from './config/defaultParams';
import { isAnyPrimaryFacetEnabled } from './service/isAnyPrimaryFacetEnabled';
import { isAnyDffEnabled } from './service/isAnyDffEnabled';
import { searchEventResults } from '../../model/searchEventResults';
import { getSearchModelFromRoute } from '../../service/searchContextRouting';
import { areEventsEnabled } from 'app/service/areEventsEnabled';

export default class SearchFiltersPanelViewModel {

    constructor({ params, id = 'default' }) {
        this.adminMode = false;
        this.areEventsEnabled = areEventsEnabled();
        this.totalJobsCount = searchJobResults.totalCount;
        this.totalEventsCount = searchEventResults.totalCount;
        this.searchResultsModel = pureComputed(() => getSearchModelFromRoute());
        this.loading = pureComputed(() => this.searchResultsModel().loading);
        this.totalCount = pureComputed(() => this.searchResultsModel().totalCount);
        this.filters = pureComputed(() => this.searchResultsModel().filters);
        this.filtersOpened = observable(false);
        this.customizationParams = params || mapParamsConfigurationToObservable(emptyFiltersParams);
        this.isVerticalStyle = pureComputed(() => this.customizationParams.contentParams.filtersDisplayStyle() === 'vertical');
        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.selectors = getFiltersSelectors(`.${this.uniqueWrapperClass}`);
        this.customCss = pureComputed(() => getCustomStyles(this.customizationParams, this.selectors));
        this.defaultSearchContext = 'jobs';
        this.selectedSearchContext = observable(this.defaultSearchContext);

        this.filterPanelVisible = pureComputed(
            () => isAnyPrimaryFacetEnabled() || isAnyDffEnabled(this.filters()()) || areEventsEnabled,
        );
    }

}