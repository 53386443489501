import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import applicationService from '../../service/application';
import unverifiedService from '../../../candidate-verification/service/unverified';
import CandidateChallengeAbstractViewModel from '../../../candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import candidateService from '../../service/candidate';
import submissionService from '../../../candidate-verification/service/submission';
import { IN_PROGRESS, FILL_IN, ATTEMPS_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import { JOB_APPLICATION } from 'candidate-verification/config/verificationSubmodules';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';

export default class ApplyFlowConfirmApplicationViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.jobId = router.routeParams().jobId;
        this.token = tokenService.get();
        this.verificationStatus(IN_PROGRESS);
        this.challenge.submodule = JOB_APPLICATION;

        this._initialize();
    }

    _initialize() {
        candidateService.create(this.token || unverifiedService.getUnverifiedVerificationData())
            .then((candidate) => {
                this.candidate.email = candidate.basicInformation.email;
                this.candidate.phone = candidate.basicInformation.phoneNumber;
                this.candidate.verificationMethod = candidate.basicInformation.verificationMethod;

                return tokenService.resendCode({
                    verificationMethod: this.candidate.verificationMethod,
                    email: this.candidate.email,
                    phone: this.candidate.phone,
                    jobId: this.jobId,
                    submodule: this.challenge.submodule,
                });
            })
            .then(this.verificationStatus(FILL_IN))
            .catch(this._handleError.bind(this));
    }

    onPinValid() {
        return submissionService.getUnconfirmedForJob(this.jobId)
            .then(applicationService.accept)
            .then(cachedCandidateApplications.clear.bind(cachedCandidateApplications))
            .then(() => {
                unverifiedService.destroy();

                router.go('candidate-self-service')
                    .then(() => {
                        notificationsService.successAfterLoaded(this.isPartnerLinksEnabled
                            ? i18n('apply-flow.thank-you-dialog.additional-instructions-text')
                            : i18n('candidate-self-service.returning-candidate-confirm-message'), 500);
                    });
            });
    }

    _handleError(error) {
        if (error === ATTEMPS_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);

            return;
        }

        super._handleError(error);
    }

}
