import { toJS } from 'knockout';
import profileImportService from 'cx/module/apply-flow/module/profile-import/service/profileImport';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import OAuthService from 'cx/module/apply-flow/module/profile-import/service/oauth';
import MetadataService from 'cx/module/apply-flow/module/profile-import/service/Metadata';
import tokenVerification from 'cx/module/candidate-verification/component/email-verification/service/tokenVerification';
import tokenService from 'cx/module/candidate-verification/service/token';
import router from 'app/model/router';
import { JOB_APPLICATION_INDEED } from 'cx/module/candidate-verification/config/verificationSubmodules';
import LightCandidate from 'cx/module/candidate-verification/model/lightCandidate';
import flowService from 'apply-flow/service/flow';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import {
    INTERNAL_CANDIDATE,
    LAST_NAME_CHECK,
    DATE_OF_BIRTH_CHECK,
} from 'cx/module/candidate-verification/config/candidateVerificationStatus';
import {
    CHALLENGE_REQUIRED,
    ATTEMPS_LIMIT_REACHED,
    PIN_LIMIT_REACHED,
} from 'cx/module/candidate-verification/config/pinVerificationStatus';

const KNOWN_ERROR_TYPES = [
    'already-applied',
    'confirmed-submission',
    'pin-code-required',
    'removed-submission',
    'submission-accept',
    'token-verification',
    'uncofirmed-submission',
    LAST_NAME_CHECK,
    DATE_OF_BIRTH_CHECK,
];

type JobDetailsRouteParams = { jobId: string };

export const INDEED_IMPORT_SUCCESS = 'success';
export const INDEED_IMPORT_FAILED = 'failed';
export type ImportStatus = {
    status: string;
    returnCandidate?: boolean;
    challengeRequired?: boolean;
};

export const importIndeedProfile = async (profileType = 'indeed'): Promise<void> => {
    const oauthService = new OAuthService();

    try {
        const config = await new MetadataService().getProviderConfig();
        const configParams = toJS(config[profileType]);

        oauthService.initialize(configParams);
    } catch (error) {
        importFailed(error as string);

        return;
    }

    await oauthService.socialLogin(
        async (oauthToken: string, popupWindow: Window) => {
            if (!oauthToken) {
                // oauth failed; show error
                importFailed('oauthtoken error - unable to get oauthtoken!', popupWindow);

                return;
            }

            if (oauthToken !== 'user_denied_access') {
                const indeedOAuthToken = oauthToken;

                processOptimizedIndeedImport(indeedOAuthToken, popupWindow);
            } else {
                profileImportEvents.indeedProfileImportEnded.dispatch('cancelled');
                closePopupWindow(popupWindow);
            }
        },
        async (error: string, popupWindow: Window) => {
            profileImportEvents.indeedProfileImportEnded.dispatch(error);
            closePopupWindow(popupWindow);
        },
        false
    );
};

export const processOptimizedIndeedImport = async (
    indeedOAuthToken: string,
    popupWindow: Window
): Promise<void> => {
    const { jobId } = router.routeParams() as JobDetailsRouteParams;

    try {
        const response = await flowService.queryRawFlow(jobId);
        const [flowData] = response.items;
        const flowVersionId = flowData?.flowVersionId;

        profileImportService
            .getProfileFromIndeed({ bProfileType: 'in' }, indeedOAuthToken, flowVersionId)
            .then(async () => {
                try {
                    await createTokenForIndeed();

                    const result: ImportStatus = {
                        status: INDEED_IMPORT_SUCCESS,
                        returnCandidate: false,
                        challengeRequired: false,
                    };

                    profileImportEvents.indeedProfileImportEnded.dispatch(result);

                    setTimeout(() => {
                        closePopupWindow(popupWindow);
                    }, 750);
                } catch (error) {
                    handleTokenError(error as string, popupWindow);
                }
            })
            .catch((error: string) => {
                importFailed(error, popupWindow);
            });
    } catch (error) {
        importFailed(error as string, popupWindow);
    }
};

export const createTokenForIndeed = async (): Promise<void> => {
    const inProfile = profileImportService.getIndeedProfileFromCache();
    const [profileData] = inProfile.items;
    const { isSignedIn } = signedCandidate;

    if (!isSignedIn() && !tokenService.accessCodeExists()) {
        const email = profileData?.email;
        const indeedKey = profileData?.providerKey;

        if (email == null || indeedKey == null) {
            importFailed('Email not found');

            return;
        }

        const candidate = new LightCandidate();

        candidate.email(email);

        candidate.verificationMethod('email');

        const { jobId } = router.routeParams() as JobDetailsRouteParams;

        await tokenVerification.createTokenForIndeed({
            jobId: jobId,
            candidate: candidate,
            submodule: JOB_APPLICATION_INDEED,
            lastName: null,
            dateOfBirth: null,
            indeedKey: indeedKey,
        });
    }
};

export const importFailed = (error: string, popupWindow?: Window): void => {
    console.error(error);
    notificationsService.error(i18n('apply-flow.profile-import.error-label'), 0);
    profileImportEvents.indeedProfileImportEnded.dispatch('failed');
    closePopupWindow(popupWindow);
};

export const handleTokenError = (error: string, popupWindow: Window): void => {
    if (error === INTERNAL_CANDIDATE) {
        return;
    }

    if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED || error == CHALLENGE_REQUIRED) {
        const result: ImportStatus = {
            status: INDEED_IMPORT_SUCCESS,
            returnCandidate: true,
            challengeRequired: true,
        };

        profileImportEvents.indeedProfileImportEnded.dispatch(result);
        closePopupWindow(popupWindow);
    } else if (KNOWN_ERROR_TYPES.includes(error)) {
        profileImportEvents.indeedProfileImportEnded.dispatch(error);
        importFailed(error, popupWindow);
    } else {
        importFailed(error, popupWindow);
    }
};

export const closePopupWindow = (popupWindow?: Window): void => {
    popupWindow?.postMessage({ type: 'closewindow', data: 'closewindow' }, '*');

    const bc = new BroadcastChannel('indeed_channel');

    bc.postMessage({ type: 'closewindow', data: 'closewindow' });
    popupWindow?.close();
    bc.close();
};
