import { observable } from 'knockout';
import router from 'app/model/router';
import { isSearchByRecruitingLocationsModeEnabled } from 'cx/module/search/module/location/config/locationSearchConfig';

const MODES = {
    LOCATION: 'location',
    ZIPCODE: 'zipcode',
    RECRUITING_LOCATION: 'job-location',
    GEO_LOCATION: 'geolocation',
};

const DEFAULT_MODE = isSearchByRecruitingLocationsModeEnabled() ? MODES.RECRUITING_LOCATION : MODES.LOCATION;

class ModeService {

    constructor() {
        this.currentMode = observable(DEFAULT_MODE);
        this.onModeChange = () => {};
        this.setModeFromParams();

        this._routeParamsSub = router.routeParams.subscribe(this._onRouteParamsChange, this);
    }

    setMode(mode) {
        if (this.currentMode() === mode) {
            return;
        }

        this.onModeChange();
        this.currentMode(this._getModeByValue(mode));
    }

    setModeFromParams() {
        const { query } = router.routeParams();

        if (query && query.mode) {
            const mode = this._getModeByValue(query.mode);

            this.currentMode(mode || DEFAULT_MODE);
        }
    }

    _getModeByValue(value) {
        const key = Object.keys(MODES).find(objKey => MODES[objKey] === value);

        return MODES[key] || DEFAULT_MODE;
    }

    _onRouteParamsChange() {
        this.setModeFromParams();
    }

}

export { MODES, DEFAULT_MODE };
export default new ModeService();
