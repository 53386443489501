import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import pinFormBuilder from '../email-verification/service/pinFormBuilder';
import notificationsService from 'cx/service/notifications';
import tokenService from 'candidate-verification/service/token';
import userTracking from 'cx/service/userTracking';
import { isLastNameExists, getLastName, removeLastName } from 'cx/module/candidate-verification/service/lastNameStorage';
import { isDateOfBirthExists, getDateOfBirth, removeDateOfBirth } from 'cx/module/candidate-verification/service/dateOfBirthStorage';

import {
    FILL_IN,
    IN_PROGRESS,
    ATTEMPS_LIMIT_REACHED,
    WRONG_PIN,
    INVALID_PERSON_ID,
    PIN_LIMIT_REACHED,
} from 'candidate-verification/config/pinVerificationStatus';

import {
    WRONG_PIN_MESSAGE,
    RESEND_BLOCKED,
    fillInMessageOptions,
    resendMessageKey,
    INVALID_PERSON_MESSAGE,
} from './service/pinCodeMessages';

import challengeService from 'candidate-verification/service/challenge';
import { clearShortTokenData } from 'candidate-verification/service/shortTokenStorage';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';
import { JOB_NOT_AVAILABLE } from 'apply-flow/service/application';
import a11yEvents from 'core/a11y/events';

export default class PinCodeVerificationViewModel {

    constructor({ challenge }) {
        this.pinCode = observable();
        this.jobId = router.routeParams().jobId;

        this.verificationStatus = challenge.verificationStatus;
        this.candidate = challenge.candidate;
        this.submodule = challenge.submodule;
        this.mergedCandidate = challenge.mergedCandidate;

        this.onPinValid = challenge.actions.onPinValid;
        this.onAttempsLimitReached = challenge.actions.onAttempsLimitReached;

        this.keepSignedIn = observable(false);

        this.form = this._createForm();

        this.states = {
            FILL_IN,
            IN_PROGRESS,
            ATTEMPS_LIMIT_REACHED,
        };

        this.fillInMessageOptions = pureComputed(this.computeMessages, this);

        challengeService.clearChallengeFlag();

        if (this.verificationStatus() === this.states.FILL_IN) {
            a11yEvents.alert.dispatch(i18n(this.fillInMessageOptions().key, {
                email_address: this.fillInMessageOptions().tokens.email_address,
                phone_number: this.fillInMessageOptions().tokens.phone_number,
            }));
        }
    }

    computeMessages() {
        return fillInMessageOptions({
            candidate: this.candidate,
            submodule: this.submodule,
            mergedCandidate: this.mergedCandidate,
        });
    }

    resendPin() {
        this.verificationStatus(IN_PROGRESS);

        return this._requestResend()
            .then(() => {
                const RESEND_MESSAGE = i18n(resendMessageKey(this.candidate));

                notificationsService.success(RESEND_MESSAGE, 0);
                this.verificationStatus(FILL_IN);

                userTracking.trackResendToken(this.jobId, router.route().id);
            })
            .catch(this._handleResendError.bind(this));
    }

    verify() {
        this.form.enableImmediateValidation();

        this.form.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.verificationStatus(IN_PROGRESS);

                userTracking.trackVerifyToken(router.routeParams().jobId, router.route().id);

                this._presetSessionPersistence();

                tokenService.verifyCode({
                    code: this.pinCode(),
                    verificationMethod: this.candidate.verificationMethod,
                    phone: this.candidate.phone,
                    email: this.candidate.email,
                    jobId: this.jobId,
                    persistAccess: this.keepSignedIn,
                }).then((token) => {
                    if (!token.accessCode) {
                        return Promise.reject(WRONG_PIN);
                    }

                    removeLastName();
                    removeDateOfBirth();

                    return this.onPinValid();
                }).then(() => {
                    this.verificationStatus(FILL_IN);
                    clearShortTokenData();
                })
                    .catch(this._handleError.bind(this));
            });
    }

    isKeepSignedInEnabled() {
        return sessionPersistence.isEnabled() && !tokenService.isDraftCandidate();
    }

    _presetSessionPersistence() {
        if (this.keepSignedIn()) {
            sessionPersistence.activate();
        } else {
            sessionPersistence.deactivate();
        }
    }

    _handleError(error) {
        if (this.isKeepSignedInEnabled()) {
            sessionPersistence.deactivate();
        }

        if (error === ATTEMPS_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
        } else if (error === WRONG_PIN) {
            this.pinCode('');
            this.form = this._createForm();
            this.verificationStatus(FILL_IN);
            notificationsService.error(WRONG_PIN_MESSAGE, 0);
        } else if (error === JOB_NOT_AVAILABLE) {
            router.go('job-details');
        } else if (error === INVALID_PERSON_ID) {
            this.verificationStatus(FILL_IN);
            notificationsService.error(INVALID_PERSON_MESSAGE, 0);
        } else {
            this._handleDefaultError(error);
        }
    }

    _handleResendError(error) {
        if (error === PIN_LIMIT_REACHED) {
            this.verificationStatus(FILL_IN);
            notificationsService.error(RESEND_BLOCKED, 0);
        } else {
            this._handleDefaultError(error);
        }

        return Promise.reject(error);
    }

    _handleDefaultError(error) {
        this.verificationStatus(FILL_IN);
        notificationsService.error(undefined, 0);
        console.error(error);
    }

    _requestResend() {
        const verificationData = {
            verificationMethod: this.candidate.verificationMethod,
            email: this.candidate.email,
            phone: this.candidate.phone,
            jobId: this.jobId,
            submodule: this.submodule,
        };

        if (!isLastNameExists() && !isDateOfBirthExists()) {
            return tokenService.resendCode(verificationData);
        }

        const storedLastName = getLastName();
        const storedDateOfBirth = getDateOfBirth();

        return tokenService.resendCode({
            ...verificationData, lastName: storedLastName, dateOfBirth: storedDateOfBirth,
        });
    }

    _createForm() {
        return pinFormBuilder.createForm({
            pinCode: this.pinCode,
            keepSignedIn: this.isKeepSignedInEnabled()
                ? this.keepSignedIn
                : undefined,
        });
    }

}
