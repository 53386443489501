export const COLUMN_LAYOUTS = [
    {
        id: '1',
        count: 1,
        sizes: [],
    },
    {
        id: '2',
        count: 2,
        sizes: ['1-2', '1-2'],
    },
    {
        id: '3',
        count: 3,
        sizes: ['1-3', '1-3', '1-3'],
    },
    {
        id: '4',
        count: 4,
        sizes: [],
    },
    {
        id: '5',
        count: 5,
        sizes: [],
    },
    {
        id: '6',
        count: 6,
        sizes: [],
    },
    {
        id: '7',
        count: 2,
        sizes: ['1-3', '2-3'],
    },
    {
        id: '8',
        count: 2,
        sizes: ['2-3', '1-3'],
    },
    {
        id: '9',
        count: 3,
        sizes: ['1-4', '1-2', '1-4'],
    },
    {
        id: '10',
        count: 3,
        sizes: ['1-4', '1-4', '1-2'],
    },
    {
        id: '11',
        count: 3,
        sizes: ['1-2', '1-4', '1-4'],
    },
    {
        id: '12',
        count: 2,
        sizes: ['1-4', '3-4'],
    },
    {
        id: '13',
        count: 2,
        sizes: ['3-4', '1-4'],
    },
];
