import SearchFacetVerticalViewModel from './SearchFacetVerticalViewModel';
import { Observable } from 'knockout';
import { Filter } from 'cx/module/search/module/search-results/module/facet/config/types';

export default class SearchPostingDateFacetVerticalViewModel extends SearchFacetVerticalViewModel {
    toggleItem(selected: Observable<boolean>): void {
        this.facet.items().forEach((item: Filter) => {
            item.selected(selected === item.selected);
        });
    }
}
