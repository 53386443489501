import ko from 'knockout';
import TextTruncateViewModel from 'apply-flow/module/e-signature/component/text-truncate/TextTruncateViewModel';

export default class MinimalTextTruncateViewModel extends TextTruncateViewModel {

    constructor(...args) {
        super(...args);

        this.worthTruncate = ko.observable();
        this.isTruncated = ko.observable(true);

        this.isCollapsible = ko.pureComputed(() => this.worthTruncate() && this.isSectionActive());
        this.isCollapsed = ko.pureComputed(() => this.worthTruncate() && this.isTruncated());
    }

}

