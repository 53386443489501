import { components } from 'knockout';
import ViewModel from 'custom-content/component/job-list/JobListViewModel';
import template from 'custom-content/component/job-list/job-list.html';

components.register('cc-job-list', {
    viewModel: {
        createViewModel(params) {
            return new ViewModel(params, 'button');
        },
    },
    template,
});