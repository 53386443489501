export function sanitize(rawSettings) {
    const settings = Object.assign({}, rawSettings);

    delete settings.initUserHiddenMessage;

    Object.keys(settings).forEach((settingKey) => {
        const setting = settings[settingKey];

        if (typeof setting === 'object' && setting !== null) {
            settings[settingKey] = sanitize(setting);
        } else if (setting == null || setting === '') {
            delete settings[settingKey];
        }
    });

    return settings;
}