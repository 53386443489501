import dictionaryService from 'apply-flow/service/dictionary';
import { COUNTRY_LOV_COLUMN } from '../config/countryLovColumn';

function _getFormType(restFormElement) {
    const restDisplayType = restFormElement.uidisplaytype;

    if (restFormElement.lovColumn) {
        if (restDisplayType === 'SingleChoiceList') {
            return 'geoHierarchySearch';
        }

        return 'geoHierarchyAutosuggest';
    }

    return restDisplayType === 'SingleChoiceList' ? 'select' : 'text';
}

function _toCamelCase(str) {
    return str[0].toLowerCase() + str.substr(1);
}

function _sortByLovColumnThenByDisplaySequence(item1, item2) {
    if (item1.lovColumn && !item2.lovColumn) {
        return -1;
    }

    if (!item1.lovColumn && item2.lovColumn) {
        return 1;
    }

    if (item1.lovColumn === item2.lovColumn) {
        return item1.displaySequence - item2.displaySequence;
    }

    return item1.lovColumn > item2.lovColumn ? 1 : -1;
}

export default {
    mapFormElementsFromRest(restData) {
        const restFormElements = restData.addressFormatDetails;

        return Promise.all(
            restFormElements
                .filter(restFormElement => restFormElement.attributeName)
                .map(this.mapFormElementFromRest.bind(this)),
        ).then(formElements => formElements.sort(_sortByLovColumnThenByDisplaySequence));
    },

    mapFormElementFromRest(restFormElement) {
        const formElement = {
            type: _getFormType(restFormElement),
            name: _toCamelCase(restFormElement.attributeName),
            label: restFormElement.prompt,
            attributes: {
                required: restFormElement.requiredFlag,
            },
            validators: {},
            displaySequence: restFormElement.displaySequence,
            lovColumn: restFormElement.lovColumn,
            optionsKeys: {
                value: 'lookupCode',
                label: 'meaning',
            },
            geographyType: restFormElement.geographyType,
        };

        if (restFormElement.requiredFlag) {
            formElement.validators.required = {
                label: restFormElement.prompt,
            };
        }

        if (restFormElement.maxlength) {
            formElement.validators.maxLength = {
                max: restFormElement.maxlength,
            };
        }

        if (formElement.name === 'country') {
            formElement.lovColumn = COUNTRY_LOV_COLUMN;
            formElement.type = 'geoHierarchySelect';

            return dictionaryService.get(dictionaryService.CODES.countries)
                .then((lookupValues) => {
                    formElement.options = lookupValues;

                    return formElement;
                });
        }

        return Promise.resolve(formElement);
    },
};