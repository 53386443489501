import i18n from 'core/i18n/i18n';
import { pureComputed } from 'knockout';
import withdrawApplicationService from './withdrawApplication';

const SUBMISSION_STATE_REJECTED = 1004;
const SUBMISSION_STATE_WITHDRAWN = 1005;
const SUBMISSION_PHASE_HR = 16;
const SUBMISSION_STATUS_NOT_RETAINED_KEY = 'candidate-self-service.tile.status-not-retained';
const SUBMISSION_STATUS_UNDER_CONSIDERATION_KEY = 'candidate-self-service.tile.status-under-consideration';
const SUBMISSION_STATUS_OFFER_ACCEPTED_KEY = 'candidate-self-service.tile.status-offer-accepted';
const SUBMISSION_STATUS_UNCONFIRMED_KEY = 'candidate-self-service.tile.status-unconfirmed';

function isTerminalState(stateId) {
    return stateId === SUBMISSION_STATE_WITHDRAWN || stateId === SUBMISSION_STATE_REJECTED;
}

function getPipelineApplicationStatus(pipelineApplication) {
    if (isTerminalState(pipelineApplication.currentStateId())
        && pipelineApplication.hiringJobApplications().every(hiringApplication =>
            isTerminalState(hiringApplication.currentStateId()))) {
        pipelineApplication.status(i18n(SUBMISSION_STATUS_NOT_RETAINED_KEY));
    } else if (pipelineApplication.hiringJobApplications().some(hiringApplication =>
        hiringApplication.currentPhaseId() === SUBMISSION_PHASE_HR
        && !isTerminalState(hiringApplication.currentStateId()))) {
        pipelineApplication.status(i18n(SUBMISSION_STATUS_OFFER_ACCEPTED_KEY));
    } else if (pipelineApplication.isConfirmed() === false) {
        pipelineApplication.status(i18n(SUBMISSION_STATUS_UNCONFIRMED_KEY));
    } else {
        pipelineApplication.status(i18n(SUBMISSION_STATUS_UNDER_CONSIDERATION_KEY));
    }
}

function getPipelineApplicationState(application) {
    if (!application.isActive()) {
        application.isActive = pureComputed(() => (
            application.hiringJobApplications().some(hiringApplication => hiringApplication.isActive())),
        );
    }
}

function updatePipelineApplicationStatuses(application) {
    getPipelineApplicationState(application);
    getPipelineApplicationStatus(application);
}

export default {
    calculatePipelineApplicationsStatuses(applications) {
        applications
            .filter(application => application.isPipeline())
            .forEach(updatePipelineApplicationStatuses);

        return applications;
    },

    withdraw(application) {
        const withdrawHiringCalls = application.hiringJobApplications()
            .filter(hiringApplication => hiringApplication.isActive())
            .map(hiringApplication => withdrawApplicationService.withdraw(hiringApplication));

        let withdrawAction;

        if (application.isPipelineApplicationActive()) {
            withdrawAction = Promise.all([
                withdrawApplicationService.withdraw(application),
                ...withdrawHiringCalls,
            ]);
        } else {
            withdrawAction = Promise.all(withdrawHiringCalls);
        }

        return withdrawAction
            .then(() => updatePipelineApplicationStatuses(application));
    },
};
