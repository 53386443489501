import { CookieConsentStyle } from 'site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import Page from 'cx/module/custom-content/model/Page';
import { Observable, PureComputed } from 'knockout';

export type CookieConsentWidgetData = {
    isCookieConsentEnabled?: Observable<boolean>;
    isDeclineButtonEnabled?: Observable<boolean>;
    cookieConsentBoxStyle?: CookieConsentStyle;
    isCookiePreferencesEnabled?: Observable<boolean>;
    isCustomCategoryActive?: Observable<boolean>;
    acceptButtonLabel?: Observable<string>;
    declineButtonLabel?: Observable<string>;
    cookiePopupMessage?: Observable<string>;
    cookiePolicy?: Observable<string>;
    cookieLinkText?: Observable<string>;
    cookiePreferenceButtonText?: Observable<string>;
    cookiePreferenceSaveButtonText?: Observable<string>;
    cookiePreferenceStrictlyNecessaryDescription?: Observable<string>;
    cookiePreferenceFunctionalDescription?: Observable<string>;
    cookiePreferenceAnalyticalDescription?: Observable<string>;
    cookiePreferenceCustomCategoryLabel?: Observable<string>;
    cookiePreferenceCustomCategoryDescription?: Observable<string>;
    widgetType: COOKIE_CONSENT_WIDGET_TYPES;
    modalTitle?: string;
    modalStyle?: PureComputed<CookieConsentStyle | null>;
    categoriesSelectionModel?: {
        isFunctionalCookieEnabled: Observable<boolean>;
        isAnalyticalCookieEnabled: Observable<boolean>;
        isCustomCookieCategoryEnabled: Observable<boolean>;
    };
};

export const COOKIE_CONSENT_WIDGET_TYPE = {
    COOKIE_CONSENT_WIDGET: 'COOKIE_CONSENT_WIDGET',
    COOKIE_POLICY_WIDGET: 'COOKIE_POLICY_WIDGET',
    COOKIE_PREFERENCES_WIDGET: 'COOKIE_PREFERENCES_WIDGET',
} as const;

export type COOKIE_CONSENT_WIDGET_TYPES =
    | typeof COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET
    | typeof COOKIE_CONSENT_WIDGET_TYPE.COOKIE_POLICY_WIDGET
    | typeof COOKIE_CONSENT_WIDGET_TYPE.COOKIE_PREFERENCES_WIDGET;

export type CookieConsentWidgetViewData = {
    customPage: Observable<Page | null>;
};

export type CookieConsentMessage = undefined;

export enum CookieCategories {
    scrictlyNecessary = 'scrictly-necessary',
    functional = 'functional',
    analyticalAndPerformance = 'analytical',
    custom = 'custom',
}
