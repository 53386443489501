import { observable, pureComputed, unwrap } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';
import i18n from 'core/i18n/i18n';

export default class MultiSelectDropdownFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.value = pureComputed({
            read() {
                return this._value();
            },

            write(option) {
                const label = this.element.getLabelForValue(option);

                if (label) {
                    this.element.value().push({
                        value: option,
                        label,
                    });

                    this.element.value.notifySubscribers();
                }

                this._value([]);
            },

            owner: this,
        });

        this._value = observable([]);

        this.optionsKeys = observable({
            value: 'value',
            label: 'name',
        });

        this.isFilled = pureComputed(() => Boolean(this.value().length));

        this.isDisabled = pureComputed(() => {
            const isDisabled = this.element.isDisabled();
            const options = this.element.options();
            const hasOptions = Array.isArray(options) && options.length;
            const maxSelectionCount = this.element.value().length >= 3;
            const optionsAllSelected = this.element.value().length === options.length;

            return isDisabled || !hasOptions || maxSelectionCount || optionsAllSelected;
        });
    }

    blurHandler() {
        this.hasFocus(false);
        super.blurHandler();
    }

    getRemoveLocationTitle(label) {
        const removeTranslation = i18n('apply-flow.profile-item.remove-item-button');

        return `${removeTranslation} ${unwrap(label)}`;
    }

}
