import FormValidator from 'core/form/validator/Validator';

export default class OnlyUppercaseFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.only-uppercase';
    }

    _validate(value = '') {
        return value === value.toUpperCase();
    }

}