import mapping from 'knockout-mapping';

export default class Question {

    constructor(data) {
        const model = {
            questionId: undefined,
            answer: undefined,
            isHidden: false,
            type: undefined,
        };

        if (data) {
            Object.assign(model, data);
        }

        mapping.fromJS(model, {}, this);
    }

    bindFormElementVisibility(isHidden) {
        this.isHidden = isHidden;
    }

}