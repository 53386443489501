const typeToComponent = new Proxy(
    {
        StaticURL: 'meta-item-static-url',
        locations: 'meta-item-location',
        email: 'meta-item-email',
        __default: 'meta-item-text',
    },
    {
        get(obj, prop) {
            return prop in obj ? obj[prop] : obj.__default;
        },
    },
);

export default class JobMetaItemViewModel {

    constructor(params) {
        this.componentName = typeToComponent[params.type || params.id];
        this.componentData = params;
    }

}
