export default {
    mapPartnerDataFromRest({ requisitionNumber, taxCreditUrl }) {
        return {
            requisitionNumber,
            partnerUrl: taxCreditUrl,
        };
    },

    mapFromRest({ items: [{ taxCreditCompletedFlag, taxCreditConfiguredFlag }] }) {
        return {
            isPartnerCompleted: taxCreditCompletedFlag,
            partnerConfiguredFlag: taxCreditConfiguredFlag,
        };
    },

};