import { Observable, observable } from 'knockout';
import GeoHierarchyAutosuggest from 'apply-flow/module/personal-information-address/form/element/GeoHierarchyAutosuggest';
import GeoHierarchyQueryBuilder from 'apply-flow/module/personal-information-address/service/GeoHierarchyQueryBuilder';

export default class GeoHierarchySearch extends GeoHierarchyAutosuggest {
    geographyType: Observable<string | undefined>;

    constructor(...args: unknown[]) {
        super(...args);

        this.geographyType = observable();

        this.component('geo-hierarchy-search-form-element');
    }

    protected getQueryBuilder(): GeoHierarchyQueryBuilder {
        return new GeoHierarchyQueryBuilder(this.lovColumnIndex(), this.geographyType());
    }
}
