import profileItemMetadataMapper from 'apply-flow/module/profile-items/mapper/metadata';

export default Object.create(profileItemMetadataMapper, {
    getFormElementType: {
        value(restType) {
            if (restType === 'DATE') {
                return 'monthpicker';
            }

            return profileItemMetadataMapper.getFormElementType(restType);
        },
    },
});