import scrollKeeper from 'minimal/service/scrollKeeper';

const dialogs = [];
const DIALOG_OPENED_CLASS = 'body--dialog-opened';
const BODY = document.body;

export default {
    add(element) {
        dialogs.push(element);
        BODY.classList.add(DIALOG_OPENED_CLASS);
    },

    remove(closedDialog) {
        const index = dialogs.indexOf(closedDialog);

        if (index === -1) {
            return;
        }

        dialogs.splice(index, 1);

        if (!dialogs.length) {
            scrollKeeper.restore();
            BODY.classList.remove(DIALOG_OPENED_CLASS);
        }
    },
};

