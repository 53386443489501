import storageService from 'core/storage/sessionStorage';

const LAST_NAME_STORAGE = 'lastName';

export const saveLastName = (lastName: string): void => {
    storageService.store(LAST_NAME_STORAGE, lastName);
};

export const removeLastName = (): void => {
    storageService.remove(LAST_NAME_STORAGE);
};

export const isLastNameExists = (): boolean => {
    return storageService.exists(LAST_NAME_STORAGE);
};

export const getLastName = (): string => {
    return storageService.restore(LAST_NAME_STORAGE);
};
