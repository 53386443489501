export default {
    parse(response) {
        const batchParts = response.parts || [];

        return batchParts.reduce((rawResponse, batchPart) => {
            if (batchPart.payload && batchPart.payload.error) {
                return Promise.reject({
                    statusCode: batchPart.payload.status,
                    response: batchPart.payload.error,
                });
            }

            rawResponse[batchPart.id] = batchPart.payload;

            return rawResponse;
        }, {});
    },
};