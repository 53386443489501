import http from 'core/http/http';
import applicationMapper from '../mapper/application';
import applicationsModel from '../../candidate-self-service/model/applications';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';


export default {
    withdraw(application) {
        return http.post('/recruitingCEJobApplications', applicationMapper.mapWithdrawToRest(application), {
            statusCodes: {
                400({ response }) {
                    if (response === 'ORA_IRC_WITHDRAW_FAIL') {
                        return {
                            message: 'withdraw-application-fail',
                        };
                    }

                    return Promise.reject();
                },
            },
        })
            .then(applicationMapper.mapFromRest.bind(applicationMapper))
            .then((updatedApplication) => {
                applicationsModel.update(application, updatedApplication);
                cachedCandidateApplications.clear();
            });
    },
};
