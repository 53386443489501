import cxEvents from 'cx/config/events';
import router from 'app/model/router';
import CandidateSelfServiceViewModel from './CandidateSelfServiceViewModel';
import { Route } from 'core/router/config/types';
import { RouteParams } from 'minimal/module/candidate-self-service/config/types';
import { Token } from 'cx/module/candidate-verification/config/types';
import { ActionName, isSupportedAction, performAction } from 'candidate-self-service/service/cssActions';

export default class CandidateSelfServiceShortTokenViewModel extends CandidateSelfServiceViewModel {
    private token: Token | undefined;

    protected async initialize(): Promise<void> {
        const routeParams = router.routeParams() as RouteParams;

        this.token = routeParams.token;

        super.initialize();
    }

    protected initializeWithToken(): void {
        const route = router.route() as Route;
        const isAction = isSupportedAction(route.id);

        if (isAction) {
            performAction(route.id as ActionName, this.token)
                .catch(this._handleError.bind(this))
                .then(cxEvents.loaded.dispatch);
        } else {
            super.initializeWithToken();
        }
    }
}
