class ScrollKeeperService {
    offsetY: number;
    selector: string;

    constructor() {
        this.offsetY = 0;
        this.selector = '.maplibre-popup-content__job-list-container .search-marker';
    }

    store(): void {
        const isSingleLocation = (
            document.querySelector(
                '.maplibre-popup-content__job-list-container .search-marker__container'
            ) as HTMLElement
        )?.classList?.contains('search-marker-address--sticky');

        if (isSingleLocation !== undefined && !isSingleLocation) {
            this.selector = '.maplibre-popup-content__job-list-container';
        }

        this.offsetY = (document.querySelector(this.selector) as HTMLElement)?.scrollTop || 0;
    }

    restore(): void {
        const element = document.querySelector(this.selector) as HTMLElement;

        if (!element) {
            return;
        }

        requestAnimationFrame(() => {
            element.scrollTo(0, this.offsetY);
        });
    }
}

export const scrollKeeper = new ScrollKeeperService();
