import { components } from 'knockout';

import { CookieConsentModalCategoriesViewModel } from './CookieConsentModalCategoriesViewModel';
import template from './cookie-consent-modal-categories.html';

components.register('cookie-consent-modal-categories', {
    viewModel: CookieConsentModalCategoriesViewModel,
    template,
});

components.register('cc-cookie-consent-modal-categories', {
    viewModel: CookieConsentModalCategoriesViewModel,
    template,
});
