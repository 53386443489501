import { ORA_QUESTIONNAIRE } from '../../enum/blockTypes';

export class QuestionnaireBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        this.blocks = new Map([
            [ORA_QUESTIONNAIRE, this.remove],
        ]);

        return this.blocks;
    }

    remove(candidate, questionnaireIds) {
        candidate.questionnaires = candidate.questionnaires
            .filter(({ questionnaireId }) => questionnaireIds.includes(String(questionnaireId)));
    }

    getBlockIdentifier({ blockProperties }) {
        return [String(blockProperties.questionnaireId)];
    }

}