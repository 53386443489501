import router from 'app/model/router';
import i18n from 'core/i18n/i18n';

router.configure({
    'event-self-checkin': {
        parent: 'minimal',
        url: '/events/check-in/{eventId}',
        title: i18n('event-check-in.title'),
        view: {
            main: 'event-self-checkin',
            dialogs: null,
        },
    },
});
