import DateTimeConverterTimezoneDecorator from 'core/l10n/model/DateTimeConverterTimezoneDecorator';
import oj from 'ojs/ojcore';
import 'ojs/ojvalidation';


export default {
    createConverter(type, options, Decorator) {
        const converter = oj.Validation.converterFactory(type).createConverter(options);

        return Decorator ? new Decorator(converter) : converter;
    },

    createCurrencyConverter(currency) {
        return this.createConverter('number', {
            style: 'currency',
            currency,
        });
    },

    createDateConverter(dateFormat, pattern) {
        return this.createConverter('datetime', {
            formatType: 'date',
            dateFormat,
            pattern,
        });
    },

    createDateTimeConverter(dateTimeFormat) {
        return this.createConverter(
            'datetime', {
                formatType: 'datetime',
                dateFormat: dateTimeFormat,
                timeFormat: dateTimeFormat,
            },
            DateTimeConverterTimezoneDecorator,
        );
    },

    createDateTimeCustomConverter(options) {
        return this.createConverter(
            'datetime',
            options,
            DateTimeConverterTimezoneDecorator,
        );
    },

    createNumberConverter(decimalFormat) {
        return this.createConverter('number', {
            style: 'decimal',
            decimalFormat,
        });
    },

    createPercentConverter() {
        return this.createConverter('number', {
            style: 'percent',
        });
    },

    createTimeConverter(timeFormat) {
        return this.createConverter('datetime', {
            formatType: 'time',
            timeFormat,
        });
    },

    createWeekDayConverter(weekDayFormat) {
        return this.createConverter('datetime', {
            weekday: weekDayFormat,
        });
    },
};