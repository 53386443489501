import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import appConfig from 'app/model/config';
import {
    LOCATION_SEARCH_MODE_KEY,
    LOCATION_SEARCH_MODE_VALUES,
    ZIPCODE_SEARCH_ENABLED_KEY,
    GEOLOCATION_SEARCH_ENABLED_KEY,
    ZIPCODE_SEARCH_ENABLED_DEFAULT_VALUE,
    GEOLOCATION_SEARCH_ENABLED_DEFAULT_VALUE,
} from 'site-editor/module/general-editor/component/site-editor-location-search/config/locationSearchSettings';

const ELASTIC_SEARCH_ENABLED_KEY = 'IRC_ELASTIC_SEARCH_ENABLED';

export function isSearchByRecruitingLocationsModeEnabled() {
    return appConfig.getSettingByKey(LOCATION_SEARCH_MODE_KEY) === LOCATION_SEARCH_MODE_VALUES.RECRUITING;
}

export function isZipcodeSearchEnabled() {
    const isElasticSearchEnabled = mapTrueFalseStringToBoolean(appConfig.getSettingByKey(ELASTIC_SEARCH_ENABLED_KEY));
    const isZipcodeSearchEnabledSetting = appConfig.getSettingByKey(ZIPCODE_SEARCH_ENABLED_KEY);

    const isZipcodeSearchEnabledSettingMapped = isZipcodeSearchEnabledSetting
        ? mapTrueFalseStringToBoolean(isZipcodeSearchEnabledSetting)
        : ZIPCODE_SEARCH_ENABLED_DEFAULT_VALUE;

    return isElasticSearchEnabled && isZipcodeSearchEnabledSettingMapped;
}

export function isGeolocationSearchEnabled() {
    const isGeolocationSearchEnabledSetting = appConfig.getSettingByKey(GEOLOCATION_SEARCH_ENABLED_KEY);

    return isGeolocationSearchEnabledSetting
        ? mapTrueFalseStringToBoolean(isGeolocationSearchEnabledSetting)
        : GEOLOCATION_SEARCH_ENABLED_DEFAULT_VALUE;
}

export function showSearchModeMenu() {
    return isZipcodeSearchEnabled() || isGeolocationSearchEnabled();
}