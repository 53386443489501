import Signal from 'signals';

export const JOB_VIEWS = 'Job Views';
export const APPLY_FLOW_ENTERED = 'Begin Job Apply';
export const APPLY_FLOW_SUBMITTED = 'Submit Job Apply';
export const APPLY_FLOW_FINISHED = 'Finish Job Apply';
export const APPLY_FLOW_APPLICATION_CREATED = 'Create Job Application';
export const APPLY_FLOW_DRAFT_CREATED = 'Apply Landing Page';
export const USER_ACCEPTED_COOKIE_CONSENT_FUNCTIONAL = 'User accepted Cookie Consent Functional';
export const USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL = 'User accepted Cookie Consent Analytical';
export const USER_ACCEPTED_COOKIE_CONSENT_CUSTOM_CATEGORY = 'User accepted Cookie Consent Custom Category';
export const USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL = 'User declined Cookie Consent Functional';
export const USER_DECLINED_COOKIE_CONSENT_ANALYTICAL = 'User declined Cookie Consent Analytical';
export const USER_DECLINED_COOKIE_CONSENT_CUSTOM_CATEGORY = 'User declined Cookie Consent Custom Category';
export const PROFILE_IMPORTS_FROM_INDEED = 'Profile Imports from Indeed';
export const PROFILE_IMPORTS_FROM_LINKEDIN = 'Profile Imports from LinkedIn';
export const PROFILE_IMPORTS_FROM_RESUME = 'Profile Imports from Resume';

export default {
    [JOB_VIEWS]: new Signal(),
    [APPLY_FLOW_ENTERED]: new Signal(),
    [APPLY_FLOW_SUBMITTED]: new Signal(),
    [APPLY_FLOW_FINISHED]: new Signal(),
    [APPLY_FLOW_APPLICATION_CREATED]: new Signal(),
    [APPLY_FLOW_DRAFT_CREATED]: new Signal(),
    [USER_ACCEPTED_COOKIE_CONSENT_FUNCTIONAL]: new Signal(),
    [USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL]: new Signal(),
    [USER_ACCEPTED_COOKIE_CONSENT_CUSTOM_CATEGORY]: new Signal(),
    [USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL]: new Signal(),
    [USER_DECLINED_COOKIE_CONSENT_ANALYTICAL]: new Signal(),
    [USER_DECLINED_COOKIE_CONSENT_CUSTOM_CATEGORY]: new Signal(),
    [PROFILE_IMPORTS_FROM_INDEED]: new Signal(),
    [PROFILE_IMPORTS_FROM_LINKEDIN]: new Signal(),
    [PROFILE_IMPORTS_FROM_RESUME]: new Signal(),
};

