import {
    cookieConfig,
    isAnalyticalCookieAllowed,
    isCustomCategoryCookieAllowed,
    isFunctionalCookieAllowed,
} from 'cx/service/cookieAgreement';
import appConfig from './config';
import cookieStorage from 'core/cookie/storage';

type CustomWindow = {
    [key: string]: boolean;
};

declare let window: CustomWindow;

const COOKIE_CONSENT_ENABLED_NAME = `${appConfig.siteNumber}_cookieConsentEnabled`;
const COOKIE_SITE_NUMBER = 'ORA_CX_SITE_NUMBER';

export const configureCookieConsentGeneralSettings = (): void => {
    cookieStorage.set(COOKIE_CONSENT_ENABLED_NAME, cookieConfig?.isCookieConsentEnabled?.());
    cookieStorage.set(COOKIE_SITE_NUMBER, appConfig?.siteNumber);
};

export const configureCookieConsentGlobalVariableForCustomJS = (): void => {
    window.vFunctionalCookies = isFunctionalCookieAllowed();
    window.vAnalyticalPerformanceCookies = isAnalyticalCookieAllowed();

    if (cookieConfig?.isCustomCategoryActive?.()) {
        window.vCustomCookies = isCustomCategoryCookieAllowed();
    }
};

export const configureCookieConsentSettings = (): void => {
    configureCookieConsentGeneralSettings();
    configureCookieConsentGlobalVariableForCustomJS();
};
