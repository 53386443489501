import '../component/extra-information-tile-form/component';

import { components } from 'knockout';

import metadataConfig from 'cx/module/apply-flow/config/metadata';
import MetadataRepository from 'cx/module/apply-flow/module/extra-information/service/Metadata';

import ViewModel from 'apply-flow/module/extra-information/ExtraInformationViewModel';
import template from 'apply-flow/module/extra-information/extra-information.html';

components.register('extra-information', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_JA_EXTRA_INFO', {
    component: 'extra-information',
    repository: MetadataRepository,
    requireBlockProperties: true,
});
