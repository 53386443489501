const TEMPLATES = new Map();

export function register(key, template) {
    if (TEMPLATES.has(key)) {
        throw new Error(`Template named ${key} has been already added to repository`);
    }

    TEMPLATES.set(key, template);
}

export function get(key) {
    const template = TEMPLATES.get(key);

    if (!template) {
        throw new Error(`Template named ${key} do not exists in repository`);
    }

    return template;
}
