const ACRONYMS = ['CSS', 'HTML', 'URL', 'GPA'];

function _toCamelCase(str) {
    return str.replace(/[A-Z]+/g, (match, offset) => {
        if (offset === 0) {
            return match.toLowerCase();
        }

        return match[0] + match.substr(1).toLowerCase();
    });
}

/**
 * All acronyms must be written with capital letters, when used in object's property name
 *
 * @example
 * {
 *  FooterHTML: '',
 *  URL: '',
 *  CustomCSS: ''
 * }
 */
function _uppercaseAcronyms(str) {
    const regexp = new RegExp(`(${ACRONYMS.join('|')})$`, 'gi');

    return str.replace(regexp, match => match.toUpperCase());
}

function _toUpperCase(str) {
    return str[0].toUpperCase() + str.substr(1);
}

function _toUpperCaseWithAcronyms(str) {
    return _toUpperCase(_uppercaseAcronyms(str));
}

function _convertKeys(obj, keyConverter) {
    const initialValue = Array.isArray(obj) ? [] : {};

    return Object.keys(obj).reduce((resultObj, key) => {
        const transformedObj = resultObj;
        const value = obj[key];

        if (Array.isArray(value)) {
            transformedObj[key] = _convertKeys(value, keyConverter);
        } else {
            const convertedKey = keyConverter(key);

            if (value && typeof value === 'object') {
                transformedObj[convertedKey] = _convertKeys(value, keyConverter);
            } else {
                transformedObj[convertedKey] = value;
            }
        }

        return transformedObj;
    }, initialValue);
}

function _convertKeysToCamelCase(obj) {
    return _convertKeys(obj, _toCamelCase);
}

function _convertKeysToUpperCase(obj, upperCaseAcronyms) {
    const keyConverter = upperCaseAcronyms ? _toUpperCaseWithAcronyms : _toUpperCase;

    return _convertKeys(obj, keyConverter);
}

function _removeEmptyFields(obj) {
    const initialValue = Array.isArray(obj) ? [] : {};

    return Object.keys(obj).reduce((resultObj, key) => {
        const result = resultObj;
        const value = obj[key];

        if (value !== null && value !== '') {
            result[key] = value;
        }

        return result;
    }, initialValue);
}

export default {
    mapFromRest(response) {
        if (response && typeof response === 'object') {
            return _convertKeysToCamelCase(_removeEmptyFields(response));
        }

        return response;
    },

    mapToRest(body, upperCaseAcronyms = true) {
        if (body && typeof body === 'object') {
            return JSON.stringify(this.convertKeysToRestFormat(body, upperCaseAcronyms));
        }

        return body;
    },

    convertKeysToRestFormat: _convertKeysToUpperCase,

    toCamelCase: _toCamelCase,
};