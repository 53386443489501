import ko from 'knockout';

const NAME_MAX_LENGTH = 40;

function clamp(string, length) {
    if (string.length <= length) {
        return string;
    }

    return `${string.substring(0, Math.min(length, string.length))}...`;
}

export default class Facet {

    constructor() {
        this.items = ko.observableArray();
    }

    init(facet) {
        this.type = facet.type;
        this.name = facet.name;

        this.items(facet.items.map((item) => {
            const name = clamp(item.name, NAME_MAX_LENGTH);
            const lastWordIndex = name.lastIndexOf(' ');

            return {
                text: item.name,
                textParts: {
                    main: `${name.substring(0, lastWordIndex)} `,
                    last: name.substring(lastWordIndex),
                },
                value: item.id,
                totalCount: item.totalCount,
                selected: ko.observable(facet.selectedIds.indexOf(item.id) !== -1),
                default: item.default,
            };
        }).sort((left, right) => right.totalCount - left.totalCount));

        this.selectedItems = ko.pureComputed(() => this.items().filter(item => item.selected()));
        this.selectedIds = ko.pureComputed(() => this.selectedItems().map(selectedItem => selectedItem.value));
        this.context = facet.context;
    }

}