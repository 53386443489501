import http from 'core/http/http';
import appConfig from 'app/model/config';

export default {
    getEmployeeInfo() {
        const cxConfig = window.CX_CONFIG || {};

        if (cxConfig.employee) {
            return Promise.resolve(cxConfig.employee);
        }

        return http.get(`/CandExpSecured/EmployeeInfo${window.location.search}`, {
            baseUrl: `${appConfig.uiBaseUrl}`,
            credentials: 'same-origin',
        });
    },
};