class IntervalSequence {

    constructor(comparator, ...params) {
        this._comparator = comparator;
        this.elements = [...params];
        this._sort();
    }

    get(index) {
        return this.elements[index];
    }

    push(intervals) {
        if (Array.isArray(intervals)) {
            intervals.forEach(interval => this.push(interval));
        } else if (!intervals.isEmpty()) {
            this.elements.push(intervals);
        }

        this._sort();

        return true;
    }

    subtractInterval(subtractor) {
        const elements = this.elements.splice(0, this.elements.length);

        elements.reduce((sequence, currentInterval) => {
            const subtracted = currentInterval.subtract(subtractor);

            sequence.push(subtracted);

            return sequence;
        }, this);

        return this;
    }

    subtractIntervals(subtractors) {
        subtractors.elements.forEach((subtractor) => {
            this.subtractInterval(subtractor);
        }, this.elements);

        return this;
    }

    getFirstNotShorter(length) {
        return this.elements.filter(item => item.length() >= length).pop();
    }

    _sort() {
        this.elements.sort(this._comparator);
    }

}

export default IntervalSequence;