export type FiltersCustomizationSelectors = {
    root: string;
    container: string;
    icon: string;
    iconHover: string;
    selectedIconHover: string;
    header: string;
    headerLine: string;
    filters: string;
    filtersSelected: string;
    filtersHover: string;
    filtersCounter: string;
    clearFilters: string;
    miscSpacer: string;
    sectionLine: string;
    subHeaderLine: string;
    searchDisplay: string;
    searchValueDropdown: string;
    searchValueDropdownSelected: string;
    jobsEventsToggleDropdown: string;
    jobsEventsToggleDropdownHover: string;
    jobsEventsToggleDropdownSelected: string;
    jobsEventsToggleDropdownSelectedHover: string;
    jobsEventsToggleCountDropdownSelected: string;
    jobsEventsToggleCountDropdownSelectedHover: string;
    jobsEventsToggle: string;
    jobsEventsToggleContainer: string;
    jobsEventsToggleSelected: string;
    jobsEventsToggleSelectedHover: string;
    jobsEventsToggleSelectedActive: string;
    jobsEventsToggleStylesHover: string;
    jobsEventsToggleStylesActive: string;
    jobsEventsToggleCountContainer: string;
    jobsEventsToggleCountContainerHover: string;
    jobsEventsToggleCountSelectedContainerHover: string;
    jobsEventsToggleCountSelected: string;
    subHeader: string;
    subHeaderSelected: string;
    subHeaderSelectedHover: string;
    subHeaderHover: string;
    subHeaderText: string;
    subHeaderTextSelected: string;
    subHeaderTextSelectedHover: string;
    subHeaderTextHover: string;
    filtersDropdown: string;
    subHeaderContainer: string;
    searchIcon: string;
};

const CONTAINER_SELECTOR = '.search-jobs';
const MOBILE_CONTAINER_SELECTOR = '.search-filters-dialog__main';

const SUBHEADER_HORIZONTAL = `${CONTAINER_SELECTOR} .search-filters__pill`;
const SUBHEADER_VERTICAL = '.search-filters__header--vertical';

const SUBHEADER_HORIZONTAL_SELECTED = `${CONTAINER_SELECTOR} .search-filters__pill.search-filters__pill--contains-selected-items`;
const SUBHEADER_VERTICAL_SELECTED = '.search-filters__header--selected';

const FILTER_DROPDOWN_ITEM = `${CONTAINER_SELECTOR} .search-facet-dropdown__list-element .search-facet-dropdown__filter-button`;
const FILTER_DROPDOWN_ITEM_SELECTED = `${CONTAINER_SELECTOR} .search-facet-dropdown__list-element--selected .search-facet-dropdown__filter-button`;

const SUBHEADER_HORIZONTAL_TEXT = `${CONTAINER_SELECTOR} .search-filters__pill .search-filters__pill-title`;
const SUBHEADER_HORIZONTAL_TEXT_SELECTED = `${CONTAINER_SELECTOR} .search-filters__pill.search-filters__pill--contains-selected-items .search-filters__pill-title`;

const SUBHEADER_VERTICAL_TEXT = '.search-filters__header';
const SUBHEADER_VERTICAL_TEXT_SELECTED = `.search-filters__header--vertical .search-filters__header-content--selected`;
const JOBS_EVENTS_TOGGLE_BUTTON = '.search-context-switcher__container .search-context-button';

export const getFiltersSelectors = (wrapperSelector: string): FiltersCustomizationSelectors => {
    return {
        root: `${wrapperSelector} .search-jobs-root-container`,
        container: `
            ${wrapperSelector} ${CONTAINER_SELECTOR},
            ${wrapperSelector} .background-color-dialog.search-filters-dialog__main`,
        icon: `
            ${wrapperSelector} .search-filters__header::after,
            .search-facet-dropdown__list-search-icon,
            .search-filters__list .search-icon,
            ${wrapperSelector} .search-filters__pill-icon`,
        iconHover: `
            ${wrapperSelector} .search-filters__header:not(.search-filters__header--selected):hover::after,
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL}:hover .search-filters__pill-icon`,
        selectedIconHover: `
            ${wrapperSelector} .search-filters__header--selected:hover::after,
            ${wrapperSelector}  ${SUBHEADER_HORIZONTAL_SELECTED}:hover  .search-filters__pill-icon`,
        header: `${wrapperSelector} .search-jobs__filters-header`,
        headerLine: `${wrapperSelector} ${CONTAINER_SELECTOR} .search-jobs__filters-header::after`,
        filters: `
            ${wrapperSelector} .search-filters__list--vertical .search-filters__set-filter, 
            ${wrapperSelector} ${FILTER_DROPDOWN_ITEM}`,
        filtersSelected: `
            ${wrapperSelector} .search-filters__list--vertical .search-filters__filter--active .search-filters__set-filter,
            ${wrapperSelector} ${FILTER_DROPDOWN_ITEM_SELECTED},
            ${wrapperSelector} .search-filters__list--vertical .ui-widget-content .search-filters__filter--active`,
        filtersHover: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__list .ui-menu-item-wrapper:hover,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-filters__list .ui-menu-item-wrapper:hover,
            ${wrapperSelector} .search-filters__filter .search-filters__set-filter:hover, 
            ${wrapperSelector} .search-filters__filter--active .search-filters__set-filter:hover, 
            ${wrapperSelector} ${FILTER_DROPDOWN_ITEM}:hover,
            ${wrapperSelector} ${FILTER_DROPDOWN_ITEM_SELECTED}:hover`,
        filtersCounter: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__pill-counter, 
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-filters__pill-counter`,
        clearFilters: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-jobs__clear-filters, 
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-jobs__clear-filters,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-jobs__counter,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__counter,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-jobs__toggle-filters`,
        searchDisplay: `
            ${wrapperSelector} .search-facet-dropdown .search-facet-dropdown__list-input,
            ${wrapperSelector} .search-filter-vertical_autosuggest__list .search-filter-vertical_autosuggest__list-input`,
        searchValueDropdown: `
            ${wrapperSelector} .search-filter-vertical_autosuggest__list .search-filter-vertical_autosuggest__list-input,
            ${wrapperSelector} .search-filter-vertical_autosuggest__list .search-filter-vertical_autosuggest__list-input::placeholder,
            ${wrapperSelector} .search-facet-dropdown .search-facet-dropdown__list-input,
            ${wrapperSelector} .search-facet-dropdown .search-facet-dropdown__list-input::placeholder`,
        searchValueDropdownSelected: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__list .ui-autocomplete-mark,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-facet-dropdown__list-element--highlighted,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-filters__list .ui-autocomplete-mark,
            ${wrapperSelector} .search-filter-vertical_autosuggest__list .search-filter-vertical_autosuggest__list-input,
            ${wrapperSelector} .search-facet-dropdown .search-facet-dropdown__list-input`,
        miscSpacer: `${wrapperSelector} ${CONTAINER_SELECTOR} .search-jobs__misc-spacer`,
        sectionLine: `${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__close-section-line`,
        subHeaderLine: `${wrapperSelector} .search-filters__section .search-filters__header::before`,
        subHeader: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL},
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL},
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL}`,
        subHeaderHover: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL}:hover,
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL}:not(${SUBHEADER_VERTICAL_SELECTED}):hover,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL}:not(${SUBHEADER_VERTICAL_SELECTED}):hover`,
        subHeaderText: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL_TEXT}, 
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT}:not(.search-filters__header-content--selected),
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT}:not(.search-filters__header-content--selected)`,
        subHeaderTextHover: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL}:not(.search-filters__pill--contains-selected-items):hover .search-filters__pill-title,
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT}:not(${SUBHEADER_VERTICAL_SELECTED}):hover,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT}:hover`,
        subHeaderSelected: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL_SELECTED},
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_SELECTED},
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_SELECTED}`,
        subHeaderSelectedHover: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL_SELECTED}:hover,
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_SELECTED}:hover,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_SELECTED}:hover`,
        subHeaderTextSelected: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL_TEXT_SELECTED}, 
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT_SELECTED},
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL_TEXT_SELECTED}`,
        subHeaderTextSelectedHover: `
            ${wrapperSelector} ${SUBHEADER_HORIZONTAL_SELECTED}:hover .search-filters__pill-title , 
            ${wrapperSelector} ${CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL}:hover .search-filters__header-content--selected,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} ${SUBHEADER_VERTICAL}:hover .search-filters__header-content--selected`,
        filtersDropdown: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-facet-dropdown__list-container,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__list .ui-autocomplete,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-filters__list .ui-autocomplete`,
        subHeaderContainer: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-facet-dropdown,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__section-container,
            ${wrapperSelector} ${MOBILE_CONTAINER_SELECTOR} .search-filters__section-container`,
        searchIcon: `
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-filters__list .search-icon,
            ${wrapperSelector} ${CONTAINER_SELECTOR} .search-facet-dropdown__list-search-icon,
            ${wrapperSelector} .search-filter-vertical_autosuggest__list .search-icon`,

        jobsEventsToggleDropdown: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON} .search-context-button__title`,
        jobsEventsToggleDropdownHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}:not(.search-context-button--selected):hover .search-context-button__title`,
        jobsEventsToggleDropdownSelected: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected .search-context-button__title`,
        jobsEventsToggleDropdownSelectedHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected:hover .search-context-button__title`,
        jobsEventsToggleCountDropdownSelected: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected .search-context-button__pill-counter`,
        jobsEventsToggleCountDropdownSelectedHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected:hover .search-context-button__pill-counter`,

        jobsEventsToggle: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}`,
        jobsEventsToggleStylesHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}:hover`,
        jobsEventsToggleStylesActive: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}:active`,
        jobsEventsToggleContainer: `${wrapperSelector} .search-context-switcher__container`,
        jobsEventsToggleSelected: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected`,
        jobsEventsToggleSelectedHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected:hover`,
        jobsEventsToggleSelectedActive: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected:active`,
        jobsEventsToggleCountContainer: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}:not(.search-context-button--selected) .search-context-button__pill-counter`,
        jobsEventsToggleCountContainerHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}:not(.search-context-button--selected):hover .search-context-button__pill-counter`,
        jobsEventsToggleCountSelectedContainerHover: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected:hover .search-context-button__pill-counter`,
        jobsEventsToggleCountSelected: `${wrapperSelector} ${JOBS_EVENTS_TOGGLE_BUTTON}.search-context-button--selected .search-context-button__pill-counter`,
    };
};
