import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    skillId: null,
    action: 'CREATE',
    skills: null,
    dateAchieved: null,
    yearsOfExperience: null,
    projectName: null,
    speciality: null,
    comments: null,
    description: null,
    contentTypeId: null,
    flowVersionId: null,
    sectionId: null,
};

export default class CandidateSkill extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'skillId');
    }

}
