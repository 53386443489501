import { pureComputed, observable } from 'knockout';
import storeFactory, { START, STOP, REVEAL_CLOCK, BLOCK } from './store';
import { PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import { pinResendMessage } from '../../service/pinCodeMessages';
import candidateVerificationEvents from 'candidate-verification/config/events';
import i18n from 'core/i18n/i18n';

let timerInstance;
const RESEND_A11Y_MESSAGE_DURATION = 12000;

export default class ResendPinViewModel {

    constructor({ resendPin }) {
        this.resendPin = resendPin;
        this.store = storeFactory.createStore();
        this.state = this.store.state;
        this.resendMessage = pureComputed(this.computeMessage, this);
        this.isVisible = observable(true);
        this.isResendPinCodeAlertEnabled = observable(false);

        this.resendMessageWarning = () => i18n('apply-flow.candidate-verification.code-resend-counter',
            {
                RESEND_COUNTER: this.resendMessage().tokens.resend_counter,
            },
        );

        this.store.dispatch(START);

        this.clockRevealedSubscription = this.state.clockRevealed.subscribe(this.resendCodeA11yMessage.bind(this));
    }

    resendCodeA11yMessage() {
        if (!this.state.blocked() && this.state.clockRevealed()) {
            timerInstance = setInterval(() => {
                const resendPinTimerElement = document.querySelector('#resend-pin-timer');
                const minutes = Math.floor(this.state.secondsLeft() / 60);
                const seconds = this.state.secondsLeft() % 60;

                const timerTransaltionKey = (minutes > 0)
                    ? 'code-resend-counter-a11y-with-minutes'
                    : 'code-resend-counter-a11y-without-minutes';

                resendPinTimerElement.innerHTML = i18n(`apply-flow.candidate-verification.${timerTransaltionKey}`,
                    {
                        RESEND_MINUTES: minutes,
                        RESEND_SECONDS: seconds,
                    });
            }, RESEND_A11Y_MESSAGE_DURATION);
        } else if (!this.state.blocked() && timerInstance) {
            const resendPinTimerElement = document.querySelector('#resend-pin-timer');

            clearInterval(timerInstance);
            resendPinTimerElement.innerHTML = '';
            timerInstance = null;
            this.isResendPinCodeAlertEnabled(true);
            this._focusSendNewCode();

            setTimeout(() => this.isResendPinCodeAlertEnabled(false), RESEND_A11Y_MESSAGE_DURATION);
        }
    }

    computeMessage() {
        const minutes = Math.floor(this.state.secondsLeft() / 60);
        const seconds = this.state.secondsLeft() % 60;

        const clock = [minutes, seconds]
            .map(String)
            .map(part => part.padStart(2, '0'))
            .join(':');

        return pinResendMessage(clock);
    }

    async resend() {
        if (this.state.secondsLeft()) {
            this.store.commit(REVEAL_CLOCK);

            candidateVerificationEvents.pinResend.dispatch();

            return;
        }

        try {
            await this.resendPin();

            this.store.dispatch(START);
            this.store.commit(REVEAL_CLOCK);
            candidateVerificationEvents.pinResend.dispatch();
        } catch (error) {
            this._handleError(error);
        }
    }

    _handleError(error) {
        if (error === PIN_LIMIT_REACHED) {
            this.store.dispatch(BLOCK);
        }
    }

    _focusSendNewCode() {
        if (document.activeElement?.matches('#counter-to-resend')) {
            setTimeout(() => document.querySelector('.pin-code-form__action-button')?.focus());
        }
    }

    dispose() {
        this.store.dispatch(STOP);
        this.clockRevealedSubscription.dispose();

        if (timerInstance) {
            clearInterval(timerInstance);
        }
    }

}
