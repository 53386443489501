export const cookiePolicyTemplates = {
    cookiePolicy: {
        sections: [
            {
                elementOrder: 0,
                sectionParams: [
                    {
                        paramKey: 'type',
                        paramValue: 'cc-section',
                    },
                ],
                rows: [
                    {
                        elementOrder: 0,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-cookie-consent-modal-title',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 1,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-cookie-consent-modal-policy',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 2,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-cookie-consent-modal-actions',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
} as const;
