import storageService from 'core/storage/sessionStorage';
import { UTM_STORAGE_KEY, SOURCE_TRACKING_MAPPING_KEYS } from '../config/constants';

export const addUtmParams = (): Record<string, string> => {
    const sourceTrackingParams: Record<string, string> = storageService.restore(UTM_STORAGE_KEY) || {};

    return Object.entries(sourceTrackingParams).reduce((acc: Record<string, string>, [key, value]) => {
        if (key !== 'referrerHeader') {
            acc[SOURCE_TRACKING_MAPPING_KEYS[key]] = value;
        }

        return acc;
    }, {});
};

export const addUtmParamsToLink = (url: string): string => {
    const urlLink = new URL(url);
    const utmParams = addUtmParams();
    const searchParams = urlLink.searchParams;

    Object.entries(utmParams).forEach(([key, value]) => {
        searchParams.append(key, value);
    });

    return urlLink.href;
};

export const isInternalLink = (url: string): boolean => {
    const urlLink = new URL(url);

    return urlLink.hostname === window.location.hostname;
};
