import LightCandidate from 'app/module/cx/module/candidate-verification/model/lightCandidate';
import tokenService from 'cx/module/candidate-verification/service/token';
import { Token } from 'cx/module/candidate-verification/config/types';
import { CHALLENGE_REQUIRED } from 'cx/module/candidate-verification/config/pinVerificationStatus';
import { reclaimLastName } from './lastNameVerifier';

export const claimPhoneNumber = async ({ candidate }: { candidate: LightCandidate }): Promise<any> => {
    return tokenService.claimPhoneNumber({ candidate }).then((verificationToken: Token) => {
        if (verificationToken.challengeFlag) {
            reclaimLastName();

            return Promise.reject(CHALLENGE_REQUIRED);
        }

        return verificationToken;
    });
};
