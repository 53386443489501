import ko from 'knockout';

export default class LegalDisclaimer {

    constructor({ versionId, description }) {
        this.versionId = versionId;
        this.description = description;
        this.isEnabled = Boolean(this.versionId);
        this._isAccepted = ko.observable(false);
    }

    get isAccepted() {
        return this._isAccepted;
    }

    accept() {
        this._isAccepted(true);
    }

    decline() {
        this._isAccepted(false);
    }

}