import router from 'app/model/router';
import i18n from 'app/module/core/i18n/i18n';
import tokenService from 'candidate-verification/service/token';
import fadeInTransition from 'core/router/transition/fadeIn';
import { findRegisterFlowRoute } from './eventRegisterFlowRoutes';
import { hasRegisteredEvent } from '../service/eventRegistration';
import { RouterParams } from './types';

function canEnterRegisterFlow(routerParams: RouterParams, routeWhenNoAccesCode: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
        hasRegisteredEvent(routerParams.eventId)
            .then((isUserAlreadyRegisteredToThisEvent) => {
                if (isUserAlreadyRegisteredToThisEvent && !tokenService.isCandidateVerified()) {
                    // TODO Handle this condition by directing to appropriate screen as part of Unverified Event story in 23A
                    reject();
                } else if (isUserAlreadyRegisteredToThisEvent) {
                    reject({ redirectTo: 'event-details.existing-registration' });
                } else if (!tokenService.accessCodeExists()) {
                    reject({ redirectTo: routeWhenNoAccesCode });
                } else {
                    resolve(true);
                }
            })
            .catch(console.error);
    });
}

router.configure({
    'event-details.email-verification': {
        parent: 'event-details',
        url: '/register/email',
        title: i18n('event-register-flow.page-title'),
        view: {
            layout: 'event-register-flow-light',
            'event-register-flow-content': 'event-candidate-verify',
        },
        canEnter: canEnterEmailVerification,
    },
    'event-preview.email-verification': {
        parent: 'event-preview',
        url: '/register/email',
        title: i18n('event-register-flow.page-title'),
        view: {
            layout: 'event-register-flow-light',
            'event-register-flow-content': 'event-candidate-verify',
        },
        canEnter: canEnterEmailVerification,
    },
    'event-register-flow': {
        url: '/event/{eventId}/register/section/{sectionNumber}:?query:',
        view: {
            layout: 'event-register-flow',
        },
        title: i18n('event-register-flow.page-title'),
        transition: fadeInTransition,
        canEnter(routeParams: RouterParams) {
            return canEnterRegisterFlow(routeParams, 'event-details');
        },
    },
    'event-preview.register-flow': {
        parent: 'event-preview',
        url: '/register/section/{sectionNumber}:?query:',
        view: {
            layout: 'event-register-flow',
        },
        title: i18n('event-register-flow.page-title'),
        transition: fadeInTransition,
        canEnter(routeParams: RouterParams) {
            return canEnterRegisterFlow(routeParams, 'event-preview');
        },
    },
    'event-details.existing-registration': {
        parent: 'event-details',
        url: '/register/registration-exists',
        view: {
            layout: 'event-register-flow-light',
            'event-register-flow-content': 'existing-registration',
        },
    },
    'event-details.pin-verification': {
        parent: 'event-details',
        url: '/register/pin',
        view: {
            layout: 'event-register-flow-light',
            'event-register-flow-content': 'event-register-flow-pin-verification',
        },
    },
});

function canEnterEmailVerification() {
    const registerFlowRoute = findRegisterFlowRoute();

    return tokenService.accessCodeExists()
        ? Promise.reject({
              redirectTo: registerFlowRoute,
              redirectParams: { sectionNumber: 1 },
          })
        : Promise.resolve();
}
