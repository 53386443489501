import { Event } from '../config/types';
import { Observable, observable, ObservableArray, observableArray } from 'knockout';
import { getEvents } from './eventRegistration';
import { LOADED, PENDING, ERROR } from 'candidate-self-service/enum/loadingStatus';

export type LoadingStatus = typeof LOADED | typeof PENDING | typeof ERROR | null;

export class EventsState {
    eventsList: ObservableArray<Event>;
    loadingStatus: Observable<LoadingStatus>;

    constructor() {
        this.eventsList = observableArray<Event>([]);
        this.loadingStatus = observable<LoadingStatus>(null);
    }

    loadEvents(): Promise<void> {
        this.loadingStatus(PENDING);

        return getEvents()
            .then((events: Event[]) => {
                this.eventsList(events);
                this.loadingStatus(LOADED);
            })
            .catch((error) => {
                console.error(error);
                this.eventsList([]);
                this.loadingStatus(ERROR);
            });
    }
}

export const eventsState = new EventsState();
