import { Observable, PureComputed, pureComputed } from 'knockout';
import { HeaderInformationConfig, JobTagsConfig } from '../../types';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import {
    JobInfoDisplay,
    JobInfoSeparator,
    JobInfoTagsConfiguration,
} from 'app/module/cx/module/search/config/types';
import { JobTag } from 'minimal/module/job-tags/config/jobTags';
import '../search-result-item-job-info';
import 'minimal/component/posting-locations';
import 'minimal/component/search-results-distance';
import { Job } from 'minimal/module/job-details/types';
import '../search-result-item-apply-button';
import {
    JobInfoDisplayedItem,
    LayoutStyle,
    SearchResultsParams,
} from 'minimal/module/search/component/search-results/config/types';
import { getDistanceInfo } from 'minimal/module/search/component/search-result-item/service/getDistance';
import { SearchResultsRequisitionFlexField } from '../../../search-results/config/types';

type Props = {
    job: Job;
    customizationParams: SearchResultsParams;
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    jobInfoDisplayed: PureComputed<JobInfoDisplayedItem[]>;
    jobInfoDisplay: PureComputed<JobInfoDisplay>;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
    jobInfoTagsConfiguration: PureComputed<JobInfoTagsConfiguration>;
    jobTags: JobTag[];
    jobTagsConfig: PureComputed<JobTagsConfig>;
    shouldShowDistance: boolean;
    viewType: 'listView' | 'tileView';
    dffs: SearchResultsRequisitionFlexField[];
};

export default class SearchResultItemHeaderViewModel {
    job: Job;
    customizationParams: SearchResultsParams;
    distanceInfo: DistanceInfo;
    areJobTagsVisible: PureComputed<boolean>;
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    isAlreadyAppliedVisible: PureComputed<boolean>;
    isJobTitleVisible: PureComputed<boolean>;
    isApplyButtonVisible: boolean | undefined;
    enableApplyButton: PureComputed<boolean>;
    jobInfoDisplayed: PureComputed<JobInfoDisplayedItem[]>;
    jobInfoDisplay: PureComputed<JobInfoDisplay>;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
    jobInfoTagsConfiguration: PureComputed<JobInfoTagsConfiguration>;
    jobTags: JobTag[];
    jobTagsConfig: PureComputed<JobTagsConfig>;
    shouldShowDistance: boolean;
    viewType: 'listView' | 'tileView';
    layoutStyle: Observable<LayoutStyle>;
    dffs: SearchResultsRequisitionFlexField[];

    constructor({
        job,
        customizationParams,
        headerInformationConfig,
        jobInfoDisplayed,
        jobInfoDisplay,
        jobInfoSeparator,
        jobInfoTagsConfiguration,
        jobTags,
        jobTagsConfig,
        shouldShowDistance,
        viewType,
        dffs,
    }: Props) {
        this.job = job;
        this.customizationParams = customizationParams;
        this.headerInformationConfig = headerInformationConfig;
        this.jobTagsConfig = jobTagsConfig;
        this.jobInfoDisplayed = jobInfoDisplayed;
        this.jobInfoDisplay = jobInfoDisplay;
        this.jobInfoSeparator = jobInfoSeparator;
        this.jobInfoTagsConfiguration = jobInfoTagsConfiguration;
        this.distanceInfo = getDistanceInfo(job);
        this.shouldShowDistance = shouldShowDistance;
        this.isApplyButtonVisible = !(this.job.isAlreadyApplied || this.job.isConfirmed);
        this.viewType = viewType;
        this.layoutStyle = this.customizationParams.contentParams.layoutStyle;
        this.dffs = dffs;

        this.enableApplyButton = pureComputed(() =>
            this.customizationParams.contentParams.enableApplyButton()
        );

        this.isAlreadyAppliedVisible = pureComputed(
            () => this.headerInformationConfig().isAlreadyAppliedVisible
        );

        this.isJobTitleVisible = pureComputed(() => this.headerInformationConfig().isJobTitleVisible);

        this.jobTags = jobTags;

        this.areJobTagsVisible = pureComputed(() => {
            return !!this.jobTags.find((tag) => tag.isVisible());
        });
    }
}
