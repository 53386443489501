import { OracleMapProvider } from './OracleMapProvider';
import { MAP_PROVIDERS } from '../../config/maps';

const MAP_PROVIDER_INSTANCES = {
    [MAP_PROVIDERS.ORACLE]: {
        constructor: OracleMapProvider,
    },
};

export function getMapProvider() {
    const provider = MAP_PROVIDER_INSTANCES[MAP_PROVIDERS.ORACLE];

    if (!provider) {
        return null;
    }

    if (!provider.instance) {
        provider.instance = new provider.constructor();
    }

    return provider.instance;
}