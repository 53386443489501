import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import RegulatoryConstants from 'apply-flow/module/regulatory/config/RegulatoryConstants';
import MetadataRepository from 'apply-flow/module/regulatory/module/disability/service/DisabilityMetadata';
import './templates';
import ViewModel from '../DisabilityViewModel';
import template from '../templates/disability.html';


components.register(RegulatoryConstants.ORA_DISABILITY.componentName, { viewModel: ViewModel, template });

metadataConfig.registerBlock(RegulatoryConstants.ORA_DISABILITY.blockCode, {
    component: RegulatoryConstants.ORA_DISABILITY.componentName,
    repository: MetadataRepository,
});
