import { FiltersHeaderStylesObservableParams } from 'minimal/module/search/component/search-filters-panel/config/types';
import { getBackgroundColorCss, getDisplayCss, STYLES_SEPARATOR } from '../../../service/customCss';

export const getHeaderAreaCss = ({
    colorSelector,
    displaySelector,
    styling,
}: {
    colorSelector: string;
    displaySelector: string;
    styling: FiltersHeaderStylesObservableParams;
}): string => {
    const colorCss = getBackgroundColorCss({
        selector: colorSelector,
        value: styling.ruleColor(),
    });
    const displayCss = getDisplayCss({
        selector: displaySelector,
        value: styling.displayHorizontalRule(),
    });

    return [colorCss, displayCss].join(STYLES_SEPARATOR);
};
