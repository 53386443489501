import { PaginationState } from './service/pagination';
import { Observable } from 'knockout';

type Props = {
    state: Observable<PaginationState>;
};

export default class SearchPaginationViewModel {
    state: Observable<PaginationState>;

    constructor({ state }: Props) {
        this.state = state;
    }
}
