import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { FiltersCustomizationParams } from '../config/types';
import {
    isFiltersCustomizationParamsKey,
    isFiltersCustomizationParamsKeyDeprecated,
} from './typesVerification';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapContentParams } from './mapContentParams';
import { emptyFiltersParams } from 'minimal/module/search/component/search-filters-panel/config/defaultParams';
import { notReachable } from 'app/types/utils';

export const mapParamsFromRest = (params: RestParam[]): FiltersCustomizationParams => {
    return params.reduce<FiltersCustomizationParams>((customizationParams, { paramKey, paramValue }) => {
        if (paramKey === 'type') {
            return {
                ...customizationParams,
                type: paramValue,
            };
        }

        if (isFiltersCustomizationParamsKeyDeprecated(paramKey)) {
            return customizationParams;
        }

        if (!isFiltersCustomizationParamsKey(paramKey)) {
            return customizationParams;
        }

        switch (paramKey) {
            case 'contentParams':
                return {
                    ...customizationParams,
                    [paramKey]: mapContentParams(paramValue),
                };

            case 'commonParams':
                return {
                    ...customizationParams,
                    [paramKey]: mapCommonParams(paramValue),
                };

            case 'headerTypography':
            case 'subHeaderTypography':
            case 'subHeaderTypographyHover':
            case 'subHeaderTypographySelected':
            case 'subHeaderTypographySelectedHover':
            case 'filtersTypography':
            case 'filtersTypographySelected':
            case 'filtersTypographyHover':
            case 'filtersCounterTypography':
            case 'searchValuesDropdownTypography':
            case 'searchValuesDropdownTypographySelected':
            case 'jobsEventsToggleDropdownTypography':
            case 'jobsEventsToggleDropdownTypographyHover':
            case 'jobsEventsToggleDropdownTypographySelected':
            case 'jobsEventsToggleDropdownTypographySelectedHover':
            case 'jobsEventsToggleCountDropdownTypography':
            case 'jobsEventsToggleCountDropdownTypographyHover':
            case 'jobsEventsToggleCountDropdownTypographySelected':
            case 'jobsEventsToggleCountDropdownTypographySelectedHover':
            case 'clearFiltersTypography':
                return {
                    ...customizationParams,
                    [paramKey]: mapTypography(paramValue),
                };

            case 'container':
            case 'filterStyles':
            case 'searchDisplayStyles':
            case 'filterStylesOnHover':
            case 'subHeaderStylingBox':
            case 'subHeaderStylesSelected':
            case 'subHeaderStylesSelectedHover':
            case 'filterDropdownStyles':
            case 'filtersCounterStyles':
            case 'filterVerticalSeparator':
            case 'jobsEventsToggleStylingBox':
            case 'jobsEventsToggleStylesHover':
            case 'jobsEventsToggleSelectedStylingBox':
            case 'jobsEventsToggleStylesSelectedHover':
            case 'jobsEventsToggleCountStylingBox':
            case 'jobsEventsToggleCountSelectedStylingBox':
            case 'jobsEventsToggleCountStylesHover':
            case 'jobsEventsToggleCountStylesSelectedHover':
                return {
                    ...customizationParams,
                    [paramKey]: mapStylingBox(paramValue),
                };

            case 'headerStyles':
            case 'subHeaderStyles':
                return {
                    ...customizationParams,
                    [paramKey]: paramValue ? JSON.parse(paramValue) : null,
                };

            default:
                return notReachable(paramKey);
        }
    }, emptyFiltersParams);
};
