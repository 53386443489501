import PinCodeVerificationViewModel from './PinCodeVerificationViewModel';
import * as STATES from 'candidate-verification/config/pinVerificationStatus';
import preferredCommunicationsChannel from 'apply-flow/module/personal-information-basic/service/prefferedCommunicationsChannel';
import { SMS } from 'candidate-verification/config/verificationMethods';
import notificationsService from 'cx/service/notifications';
import { RESEND_BLOCKED } from './service/pinCodeMessages';

export default class InlinePinCodeVerificationViewModel extends PinCodeVerificationViewModel {

    constructor({ challenge, cancel, throttlingMessage }) {
        super({ challenge });

        this.states = {
            ...this.states,
            ...{
                PIN_LIMIT_REACHED: STATES.PIN_LIMIT_REACHED,
                CONTACT_UPDATED_RECENTLY: STATES.CONTACT_UPDATED_RECENTLY,
                CONTACT_UPDATE_LIMIT_REACHED: STATES.CONTACT_UPDATE_LIMIT_REACHED,
            },
        };

        this.cancel = () => {
            cancel();
            this.verificationStatus(STATES.FILL_IN);
        };

        this._setVerificationStatus(throttlingMessage());

        this.subscription = throttlingMessage.subscribe((newValue) => {
            this._setVerificationStatus(newValue);
        });
    }

    dispose() {
        this.subscription.dispose();
    }

    isKeepSignedInEnabled() {
        return false;
    }

    _presetSessionPersistence() {
        // this is empty since inline component should not modify session persistence
    }

    _setVerificationStatus(throttlingMessage) {
        if (throttlingMessage) {
            this.verificationStatus(throttlingMessage);
        } else {
            this.verificationStatus(STATES.FILL_IN);
        }
    }

    _requestResend() {
        if (this.candidate.verificationMethod() === SMS) {
            const { countryCode, areaCode, number } = this.candidate.phone();

            const phone = {
                mobilePhoneCountryCode: countryCode,
                mobilePhoneAreaCode: areaCode,
                mobilePhoneNumber: number,
            };

            return preferredCommunicationsChannel.requestPhoneVerification(this.candidate.id, phone);
        }

        return preferredCommunicationsChannel.requestEmailVerification(
            this.candidate.id, this.candidate.email());
    }

    _handleResendError(error) {
        if (error === STATES.CONTACT_UPDATE_LIMIT_REACHED) {
            this.verificationStatus(error);

            return Promise.resolve();
        }

        if (error === STATES.PIN_LIMIT_REACHED) {
            this.verificationStatus(STATES.FILL_IN);
            notificationsService.error(RESEND_BLOCKED, 0);

            return Promise.reject(error);
        }

        this._handleDefaultError();

        return Promise.reject(error);
    }

}