import mapping from 'knockout-mapping';
import flowService from '../../../service/flow';
import candidate from '../../../model/candidate';
import candidateDataMapper from '../mapper/candidateData';
import configMapper from '../mapper/config';

const VALIDATIONS_ARRAY_NAME = 'ORA_CE_VALIDATIONS';

function getValidationsArray() {
    return Array.isArray(window[VALIDATIONS_ARRAY_NAME])
        ? window[VALIDATIONS_ARRAY_NAME]
        : [];
}

function getValidator(code, id) {
    function findByBlockCodeAndId({ blockCode, identifier }) {
        return blockCode === code && identifier === id;
    }

    const { validator } = getValidationsArray().find(findByBlockCodeAndId) || {};

    return validator;
}

function checkValidator({ blockCode, identifier, validator }) {
    const blockCodeValid = typeof blockCode === 'string';

    const identifierValid = typeof identifier === 'string'
        || typeof identifier === 'number'
        || typeof identifier === 'undefined';

    const validatorValid = typeof validator === 'function';

    if (blockCodeValid
        && identifierValid
        && validatorValid) {
        return '';
    }

    return `Custom validator errors for block: ${blockCode}, identifier: ${identifier}.`;
}

export default {

    get(blockCode, blockIdentifier) {
        return getValidator(blockCode, blockIdentifier);
    },

    checkValidators() {
        getValidationsArray()
            .map(checkValidator)
            .filter(error => error)
            .forEach(error => console.error(error));
    },

    getCandidateData() {
        const candidateData = mapping.toJS(candidate);

        return candidateDataMapper.mapToValidation(candidateData);
    },

    getConfig() {
        const { requisitionFlexFields } = flowService.get();

        return configMapper.mapToValidation(requisitionFlexFields);
    },

};