import { hasMarginParams, mapMarginParamsFromRest, mapMarginParamsToRest } from './mapMarginParams';
import { hasPaddingParams, mapPaddingParamsFromRest, mapPaddingParamsToRest } from './mapPaddingParams';
import { castParamType, mapParamToRest, RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { mapCommonParams } from 'cx/module/custom-content/mapper/mapCommonParams';

export type CustomContentParams = Record<string, unknown>;

const filterParamsToRest = (paramKey: string) => {
    switch (paramKey) {
        case 'paddingTop':
        case 'paddingRight':
        case 'paddingBottom':
        case 'paddingLeft':
        case 'marginTop':
        case 'marginRight':
        case 'marginBottom':
        case 'marginLeft':
            return false;

        default:
            return true;
    }
};

const mapParamsFromRest = (params: RestParam[] = []): CustomContentParams => {
    return params.reduce((acc, param) => {
        const { paramKey, paramValue } = param;

        switch (paramKey) {
            case 'commonParams':
                return {
                    ...acc,
                    [paramKey]: mapCommonParams(paramValue),
                };

            case 'margin':
                const marginParams = paramValue ? mapMarginParamsFromRest(paramValue) : {};

                return {
                    ...acc,
                    ...marginParams,
                    [paramKey]: paramValue,
                };

            case 'padding':
                const paddingParams = paramValue ? mapPaddingParamsFromRest(paramValue) : {};

                return {
                    ...acc,
                    ...paddingParams,
                    [paramKey]: paramValue,
                };

            default:
                return {
                    ...acc,
                    [paramKey]: paramValue ? castParamType(paramValue) : null,
                };
        }
    }, {});
};

const mapParamsToRest = (params: CustomContentParams): RestParam[] => {
    if (hasMarginParams(params)) {
        params.margin = mapMarginParamsToRest(params);
    }

    if (hasPaddingParams(params)) {
        params.padding = mapPaddingParamsToRest(params);
    }

    return Object.keys(params)
        .filter(filterParamsToRest)
        .map((paramKey) => mapParamToRest(paramKey, params[paramKey]));
};

export default { mapParamsFromRest, mapParamsToRest };
