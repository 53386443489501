import { Observable, observable } from 'knockout';
import router from 'app/model/router';
import { RouterParams } from '../../../event-details/config/types';
import { getEventDetails } from 'minimal/module/event-details/service/eventService';
import { EVENT_DETAILS_FETCH_CONTEXT } from 'minimal/module/event-details/config/types';
import { confirmEventRegistration, getEventRegistrationDetails } from '../../service/eventRegistration';
import { Event, EventActionResponse } from '../../../candidate-self-service/config/types';
import { convertEventRegistrationDate } from '../../../candidate-self-service/service/eventDate';
import { EventRegisterModel } from '../../config/types';
import notificationsService from 'cx/service/notifications';
import i18n from 'app/module/core/i18n/i18n';
import appConfig from 'app/model/config';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';

export default class ExistingRegistrationViewModel {
    private readonly EVENT_NOT_ACCEPTING_REGISTRATION = 'IRC_EVENT_NOT_ACCEPTING_REGN';
    private readonly ERR_EVENT_CANCELLED = 'EVENT_CANCELLED';
    private template: Observable<string>;
    private eventTitle: Observable<string>;
    private eventNumber: string;
    private loaded: Observable<boolean>;
    private eventRegistrationDetails: Observable<Event | undefined>;
    private confirmEventRegistration: VoidFunction;
    private isConfirmInProgress: Observable<boolean>;
    private eventName = '';
    private registeredDate = '';

    constructor() {
        this.template = observable('');
        this.eventTitle = observable('');
        this.loaded = observable<boolean>(false);
        this.eventNumber = (router.routeParams() as RouterParams).eventId;
        this.loadEventDetails(this.eventNumber).finally(() => this.loaded(true));
        this.eventRegistrationDetails = observable();
        this.confirmEventRegistration = () => this.confirm();
        this.isConfirmInProgress = observable<boolean>(false);

        this.fetchEventRegistrationDetails().then(() => this.resolveTemplate());
    }

    private loadEventDetails(eventId: string): Promise<void> {
        return getEventDetails(eventId, EVENT_DETAILS_FETCH_CONTEXT.CHECK_IN_CONTEXT).then(
            (eventAttributes) => {
                this.eventTitle(eventAttributes?.title ?? '');
            }
        );
    }

    fetchEventRegistrationDetails(): Promise<void> {
        const eventId = (router.routeParams() as RouterParams).eventId;

        return getEventRegistrationDetails(eventId).then((registrationInformation: Event) => {
            if (!registrationInformation) {
                return Promise.reject();
            }

            this.eventRegistrationDetails(registrationInformation);
            this.eventName = registrationInformation.title;
            this.registeredDate = convertEventRegistrationDate(registrationInformation.registeredOnDate);
        });
    }

    resolveTemplate(): void {
        const isCandidateDisqualified = this.eventRegistrationDetails()?.isCandidateDisqualified;

        if (isCandidateDisqualified) {
            this.template('previously-disqualified');

            return;
        }

        const isEventConfirmed = this.eventRegistrationDetails()?.confirmedFlag;

        if (isEventConfirmed) {
            this.template('already-applied');
        } else {
            this.template('unconfirmed-registration');
        }
    }

    confirm(): Promise<void> {
        const params: EventRegisterModel | undefined = this.getEventActionParams();

        if (!params) {
            return Promise.reject('Unable to get event details');
        }

        this.isConfirmInProgress(true);

        return confirmEventRegistration(params)
            .then((response: EventActionResponse) => {
                cachedCandidateRegistrations.clear();

                const THANK_YOU_MESSAGE = response.isManualConfirmationRequired
                    ? i18n('candidate-self-service.events.interest-thank-you-message')
                    : i18n('candidate-self-service.events.confirm-success-message');

                router
                    .go('candidate-self-service.events', {}, { inherit: false })
                    .then(this.notifyAfterRedirectToCSS.bind(this, THANK_YOU_MESSAGE));
            })
            .catch((error: string) => this.handleError(error))
            .finally(() => this.isConfirmInProgress(false));
    }

    private notifyAfterRedirectToCSS(msg: string): void {
        notificationsService.successAfterLoaded(msg);
    }

    private getEventActionParams(): EventRegisterModel | undefined {
        const registeredEventInfo = this.eventRegistrationDetails();

        if (!registeredEventInfo) {
            return;
        }

        const params: EventRegisterModel = {
            candidateNumber: registeredEventInfo.candidateNumber,
            eventNumber: registeredEventInfo.eventNumber,
            siteNumber: appConfig.siteNumber,
            action: 'CONFIRM',
        };

        return params;
    }

    handleError(error: string): void {
        console.error(error);

        if (error === this.EVENT_NOT_ACCEPTING_REGISTRATION || error === this.ERR_EVENT_CANCELLED) {
            notificationsService.error(i18n('event-register-flow.submit-errors.event-reg-closed'), 0);
        } else {
            notificationsService.error(error);
        }
    }
}
