import ko from 'knockout';
import formBuilder from 'apply-flow/module/tile-profile-items/form/tileFormBuilder';
import LanguageFormTileStrategy from '../model/LanguageFormTileStrategy';
import candidateModel from 'apply-flow/model/candidate';
import i18n from 'core/i18n/i18n';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            this._setSearchTypeLanguageElement(config);

            const form = formBuilder.createForm(LanguageFormTileStrategy, config).bindComponent('language-form');

            form.legend(i18n('apply-flow.profile-item.add-language-button'));

            this._addUniqueValidator(form);

            return form;
        },
    },

    _setSearchTypeLanguageElement: {
        value(config) {
            const element = ko.utils.arrayFirst(config.elements, formElement => formElement.name === 'contentItemId');

            if (element) {
                element.type = 'search';

                element.optionsKeys = {
                    value: 'contentItemId',
                    label: 'name',
                };
            }
        },
    },

    _addUniqueValidator: {
        value(form) {
            const element = form.getElement('contentItemId');

            const languagesWithSameSection = ko.pureComputed(() => {
                const { sectionId } = ko.unwrap(form).model();

                return candidateModel.languages()
                    .filter(item => item.sectionId === sectionId);
            });

            if (element) {
                element.validators('unique', {
                    inArray: languagesWithSameSection,
                    label: ko.unwrap(element.label) || '',
                    propertyName: 'contentItemId',
                });
            }
        },
    },
});
