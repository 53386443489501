import appConfig from 'app/model/config';
import { TC_TILE_ID } from 'cx/module/search/mapper/talentCommunityTile';
import { TalentCommunityRecord } from 'minimal/module/search/component/search-result-item/types';
import { Job } from 'minimal/module/job-details/types';

const { uiBaseUrl, version } = appConfig;
const appVersion = version ? `${version}/` : '';

const getAssetUrl = (name: string) => `${uiBaseUrl}/CandExpStatic/${appVersion}${name}`;

const TALENT_COMMUNITY_ENTITY: TalentCommunityRecord = {
    id: TC_TILE_ID,
};

export const STATIC_DATA: Job[] = [
    {
        id: '1',
        requisitionId: 1,
        title: 'Front-end developer',
        primaryLocation: 'Krakow, Poland',
        secondaryLocations: ['One'],
        postedDate: '13.02.2019',
        postingEndDate: '13.09.2019',
        distance: '25',
        shortDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis convallis libero sem, quis lobortis lectus dapibus id. Pellentesque eu ex a elit ornare sodales. Nam eros orci, maximus non risus quis, ultrices vulputate lacus. Sed sed maximus ante. Phasellus ultrices quis eros ac dictum.',
        mediaThumbUrl: getAssetUrl('images/admin/mock/search-result-item-static-1.jpg'),
        isAlreadyApplied: true,
        isConfirmed: true,
        lang: 'en',
        workplaceType: 'Hybrid',
        workplaceTypeCode: 'ORA_HYBRID',
        businessUnit: 'Business unit',
        contractType: 'Temporary',
        domesticTravelRequired: 'Yes',
        geographyId: 1,
        internationalTravelRequired: 'Yes',
        jobFamily: 'ZFRCE_Management',
        workHours: '8am to 5pm',
        jobFunction: 'Supervisor',
        jobSchedule: 'Full Time',
        jobShift: 'Day',
        jobType: 'type',
        studyLevel: "Bachelor's Degree",
        department: 'Software Engineering',
        language: 'US',
        managerLevel: 'Chief operating officer',
        organization: 'Fusion Recruiting',
        primaryLocationCountry: 'US',
        publishedJobId: 300100578504202,
        radiusUnit: 'MI',
        relevancy: 6.933856964111328,
        workDurationMonths: '12',
        workDurationYears: '4',
        workerType: 'Development',
        workDays: 'Monday - Friday',
        legalEmployer: 'Oracle',
        primaryWorkLocation: 'San Francisco',
        responsibilities:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
        qualifications:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
    },
    {
        id: '2',
        requisitionId: 2,
        title: 'Front-end developer',
        primaryLocation: 'Krakow, Poland',
        postedDate: '13.02.2019',
        distance: '25',
        shortDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis convallis libero sem, quis lobortis lectus dapibus id. Pellentesque eu ex a elit ornare sodales. Nam eros orci, maximus non risus quis, ultrices vulputate lacus. Sed sed maximus ante. Phasellus ultrices quis eros ac dictum.',
        isActive: true,
        mediaThumbUrl: getAssetUrl('images/admin/mock/search-result-item-static-2.jpg'),
        hotJobFlag: true,
        trendingJob: false,
        beFirstToApply: false,
        lang: 'en',
        workplaceType: 'On-site',
        workplaceTypeCode: 'ORA_ON_SITE',
        businessUnit: 'Business',
        contractType: 'Temporary',
        domesticTravelRequired: 'No',
        geographyId: 1,
        internationalTravelRequired: 'No',
        jobFamily: 'ZFRCE',
        workHours: '8am to 5pm',
        jobFunction: 'Supervisor',
        jobSchedule: 'Part Time',
        jobShift: 'Day',
        jobType: 'type',
        postingEndDate: '13.09.2019',
        studyLevel: 'Degree',
        department: 'Software Engineering',
        language: 'US',
        managerLevel: 'Chief operating officer',
        organization: 'Fusion Recruiting',
        primaryLocationCountry: 'US',
        publishedJobId: 300100578504202,
        radiusUnit: 'MI',
        relevancy: 6.933856964111328,
        workDurationMonths: '12',
        workDurationYears: '4',
        workerType: 'Development',
        workDays: 'Monday - Friday',
        legalEmployer: 'Oracle',
        primaryWorkLocation: 'San Francisco',
        responsibilities:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
        qualifications:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
    },
    {
        id: '3',
        requisitionId: 3,
        title: 'Front-end developer',
        primaryLocation: 'Krakow, Poland',
        secondaryLocations: ['1', '2', '3'],
        postedDate: '13.02.2019',
        distance: '25',
        shortDescription:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis convallis libero sem, quis lobortis lectus dapibus id. Pellentesque eu ex a elit ornare sodales. Nam eros orci, maximus non risus quis, ultrices vulputate lacus. Sed sed maximus ante. Phasellus ultrices quis eros ac dictum.',
        mediaThumbUrl: getAssetUrl('images/admin/mock/search-result-item-static-3.jpg'),
        hotJobFlag: true,
        trendingJob: true,
        beFirstToApply: true,
        lang: 'en',
        workplaceType: 'Remote',
        workplaceTypeCode: 'ORA_REMOTE',
        businessUnit: 'Business unit',
        contractType: 'Temporary',
        domesticTravelRequired: 'Yes',
        geographyId: 1,
        internationalTravelRequired: 'Yes',
        jobFamily: 'ZFRCE_Management',
        workHours: '8am to 5pm',
        jobFunction: 'Supervisor',
        jobSchedule: 'Full Time',
        jobShift: 'Day',
        jobType: 'type',
        postingEndDate: '13.09.2024',
        studyLevel: "Bachelor's Degree",
        department: 'Software Engineering',
        language: 'US',
        managerLevel: 'Chief operating officer',
        organization: 'Fusion Recruiting',
        primaryLocationCountry: 'US',
        publishedJobId: 300100578504202,
        radiusUnit: 'MI',
        relevancy: 6.933856964111328,
        workDurationMonths: '12',
        workDurationYears: '4',
        workerType: 'Development',
        workDays: 'Monday - Friday',
        legalEmployer: 'Oracle',
        primaryWorkLocation: 'San Francisco',
        responsibilities:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
        qualifications:
            '<ul><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor, neque in porta pretium.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li><li>Lorem ipsum dolor sit amet, consectetur.</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tempor.</li></ul>',
    },
];

export const getStaticData = (withTalentCommunityTile: boolean): (Job | TalentCommunityRecord)[] => {
    if (withTalentCommunityTile) {
        return [...STATIC_DATA, TALENT_COMMUNITY_ENTITY];
    }

    return STATIC_DATA;
};
