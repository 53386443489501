import i18n from 'core/i18n/i18n';
import CandidateVerificationAbstractViewModel from 'candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import notificationsService from 'cx/service/notifications';
import router from 'app/model/router';
import { Observable, observable } from 'knockout';
import { getEventDetails } from 'minimal/module/event-details/service/eventService';
import { postCandidateEventCheckin } from 'minimal/module/event-checkin/service/eventCheckinService';
import absoluteRouter, { Contexts } from 'app/model/absoluteRouter';
import { combinePhone } from 'candidate-verification/service/phoneConverter';
import { areEventsEnabledLocally } from 'app/service/areEventsEnabled';
import { EventCheckinData } from 'minimal/module/event-checkin/config/types';
import { EVENT_DETAILS_FETCH_CONTEXT } from '../../event-details/config/types';
import cxEvents from 'cx/config/events';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';

export default class EventSelfCheckinViewModel extends CandidateVerificationAbstractViewModel {
    private eventId: string;
    private eventTitle: Observable<string | null>;
    private isLoading: Observable<boolean>;
    private promptUserToRegister: Observable<boolean>;
    private eventRegistrationLink: Observable<string | null>;
    private isEventEnabled: boolean;
    private showAllEventsLink: boolean;

    private ERR_ALREADY_CHECKED_IN = 'EVENT_ALREADY_CHECKIN';
    private ERR_EVENT_CANCELLED = 'EVENT_CANCELLED';
    private SUCCESSFULLY_CHECKED_IN_KEY = 'event-check-in.success-message';
    private ALREADY_CHECKED_IN_KEY = 'event-check-in.already-checked-in';

    constructor() {
        super();
        this.eventTitle = observable(null);
        this.eventRegistrationLink = observable(null);
        this.isLoading = observable<boolean>(false);
        this.promptUserToRegister = observable<boolean>(false);
        this.eventId = (router.routeParams() as { eventId: string }).eventId;
        this.isEventEnabled = areEventsEnabledLocally();
        this.showAllEventsLink = false;
        this.loadEvent(this.eventId).then(() => this.updatePageTitle());
        this.isBotsProtectionEnabled = observable<boolean>(false);
    }

    onCheckinClick(): void {
        const form = this.activeForm();

        form.enableImmediateValidation();

        form.validate().then((isFormValid: boolean) => {
            if (!isFormValid) {
                return;
            }

            this.triggerEventCheckin();
        });
    }

    private async triggerEventCheckin() {
        this.activeForm();
        this.isLoading(true);

        await this.postEventCheckin()
            .then(this.onSuccessfulEventCheckIn.bind(this))
            .catch(this.manageEventCheckInErrors.bind(this))
            .finally(() => {
                this.isLoading(false);
                this.resetForm();
            });
    }

    private onSuccessfulEventCheckIn(): void {
        notificationsService.success(
            i18n(this.SUCCESSFULLY_CHECKED_IN_KEY, {
                event_name_number: `${this.eventTitle()}(${this.eventId})`,
            }),
            0
        );
    }

    private manageEventCheckInErrors(errorResponse: string): void {
        if (errorResponse == this.ERR_ALREADY_CHECKED_IN) {
            notificationsService.error(i18n(this.ALREADY_CHECKED_IN_KEY), 0);

            return;
        }

        if (errorResponse == this.ERR_EVENT_CANCELLED) {
            // As per PMG this scenario would never happen ie., Event owner
            // would not CANCEL the event during the CHECK_IN. Hence used a generic message
            notificationsService.error(i18n('event-register-flow.submit-errors.event-reg-closed'), 0);

            return;
        }

        this.eventRegistrationLink(
            EventSelfCheckinViewModel.generateEventRegistrationLink(this.eventId, 'eng')
        );

        this.promptUserToRegister(true);
    }

    private resetForm(): void {
        // email or phone field to be cleared
        const formElement = this.activeForm().elements()[0];

        formElement.value('');
        this._createForms();
        this._onFormsCreated();
    }

    private async postEventCheckin(): Promise<string> {
        const verificationMethod = this.candidate.verificationMethod();

        const eventCheckinData: EventCheckinData = {
            emailId: verificationMethod === EMAIL ? this.candidate.email() : '',
            phoneNumber: verificationMethod === SMS ? combinePhone(this.candidate.phone()) : '',
            eventNumber: this.eventId,
        };

        return await postCandidateEventCheckin(eventCheckinData);
    }

    private async loadEvent(eventId: string) {
        try {
            this.isLoading(true);

            const eventAttributes = await getEventDetails(
                eventId,
                EVENT_DETAILS_FETCH_CONTEXT.CHECK_IN_CONTEXT
            );

            if (eventAttributes) {
                this.eventTitle(eventAttributes.title);
            }
        } catch (error: unknown) {
            console.error('Error while setting title in events check-in ' + error);
        }

        this.isLoading(false);
    }

    private static generateEventRegistrationLink(eventId: string, lang: string): string {
        return absoluteRouter.interpolate(
            { lang, context: Contexts.PUBLIC },
            'event-details',
            { eventId },
            { inherit: true, encode: true }
        );
    }

    private updatePageTitle(): void {
        cxEvents.pageTitleUpdate.dispatch(this.eventTitle());
    }
}
