import { observable, pureComputed } from 'knockout';
import ValidatableForm from 'core/form/ValidatableForm';
import i18n from 'core/i18n/i18n';

const TILE_ANIMATION_DURATION = 300;
const EDIT_LABEL = i18n('general.change-button');

export default class TileForm extends ValidatableForm {

    constructor(TileStrategy, ...args) {
        super(...args);

        this.navId = `tile-${Date.now()}`;
        this.tileTitle = observable();
        this.tileSubtitle = observable();
        this.tileStrategy = new TileStrategy(this);
        this.isActive = observable(false);
        this.isFocused = observable(false);
        this.totalErrors = pureComputed(() => this.getErrorMessages().length);

        this.isTileInvalid = pureComputed(() => {
            const isFormValid = this.isValid();
            const isFormActive = this.isActive();

            return !isFormValid && !isFormActive;
        });

        this.tileLabel = pureComputed(() => `${this.tileTitle()} ${EDIT_LABEL}`);
    }

    showForm() {
        this.isActive(true);

        setTimeout(() => {
            this.isFocused(true);
        }, TILE_ANIMATION_DURATION);
    }

    showTile() {
        this.isActive(false);
        this.isFocused(false);
    }

    buildTile() {
        return this.tileStrategy.resolveFormSources()
            .then(valueMap => Promise.all([
                this.tileStrategy.buildTitle(valueMap),
                this.tileStrategy.buildSubtitle(valueMap),
            ]))
            .then(([title, subtitle]) => {
                this.tileTitle(title);
                this.tileSubtitle(subtitle);
            });
    }

}