import http from 'core/http/http';
import appConfig from 'app/model/config';
import router from 'app/model/router';

function derivePayloadSourceTraceData(sourceTraceData) {
    const { tokenId } = sourceTraceData;
    const { id } = router.route();

    if (id === 'event-register-flow' && tokenId) {
        return sourceTraceData;
    }

    return {
        ...sourceTraceData,
        siteNumber: appConfig.siteNumber,
    };
}

export default {
    create(sourceTraceData) {
        const traceData = derivePayloadSourceTraceData(sourceTraceData);

        return http.post('/recruitingCESourceTrackings', traceData);
    },
};
