/* eslint-disable no-bitwise,no-mixed-operators */
function generateUUID() {
    const array = new Uint32Array(1);

    let d = new Date().getTime();

    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now();
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        crypto.getRandomValues(array);

        const r = (d + array[0]) % 16 | 0;

        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}


export default generateUUID;
