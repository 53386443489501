function mapExtraInformationRows(rows, name) {
    return rows.map(({ attributes }) => ({
        ...attributes,
        contextCode: name,
    }));
}

function mapExtraInformationItems(items) {
    return items.map(({ name, rows }) => mapExtraInformationRows(rows, name))
        .flat();
}

export default {

    mapToValidation(candidate) {
        const {
            address,
            education,
            experience,
            extraInformation,
            languages,
            licensesAndCertificates,
            skills,
            workPreferences,
            dateOfBirth,
            personalIdentifyingInformationItems,
        } = candidate;

        return {
            address,
            education,
            experience,
            extraInformation: mapExtraInformationItems(extraInformation),
            languages,
            licensesAndCertificates,
            skills,
            workPreferences,
            dateOfBirth,
            personalIdentifyingInformationItems,
        };
    },

};