import candidateModel from 'apply-flow/model/candidate';
import tokenService from 'candidate-verification/service/token';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';

export function getBaseLogMetadata() {
    const {
        accessCode,
        sourceTrackingId: ceUserTrackingId,
    } = tokenService.get();

    const { email, phoneNumber } = candidateModel.basicInformation;

    const formattedPhoneNumber = phoneLocaleAwareFactory.createPhone(phoneNumber()).asFormattedString();

    return {
        accessCode,
        ceUserTrackingId,
        email: email(),
        phoneNumber: formattedPhoneNumber,
    };
}