import i18n from 'core/i18n/i18n';
import http from 'core/http/http';

const CHECK_ACTION = 'CHECK_DUPLICATES';
const DUPLICATE_FOUND = 'ORA_IRC_CAND_EXISTS';


function _checkDuplication(value, message) {
    const payload = Object.assign({ action: CHECK_ACTION }, value);

    const statusCodes = {
        400({ response }) {
            if (response === DUPLICATE_FOUND) {
                return message;
            }

            return response;
        },
    };

    return http.post('/recruitingCECandidates', payload, {
        statusCodes,
    });
}

export default {
    checkEmail(email) {
        const duplicatedEmailMessage = i18n('validators.duplicated-email');

        return _checkDuplication({ email }, duplicatedEmailMessage);
    },

    compareEmail(newEmail, originalEmail) {
        return newEmail === originalEmail;
    },
};
