import { pureComputed } from 'knockout';
import notificationsService from 'cx/service/notifications';
import draftAttachmentService from '../../service/draftAttachmentService';
import { applicationDraft } from '../../../../service/applicationDraftInstance';
import { STATES, VALIDATION_MESSAGE_VISIBILITY_TIME, ACTIONS } from '../../config/attachmentUploadButton';
import AttachmentUploadButtonViewModel from './AttachmentUploadButtonViewModel';
import { download } from '../../service/download';

export default class SupportingDocumentsUploadButtonViewModel extends AttachmentUploadButtonViewModel {

    constructor(params) {
        super(params);

        this.model = pureComputed({
            read: () => this.finderFunction(this.attachments()),
            write: this._replaceAttachment,
            owner: this,
        });

        this.model.subscribe(this._changeStatusOnModelChange, this);

        this.defaultStatus = pureComputed(this._computeDefaultStatus, this);
        this.status(this.defaultStatus());
        this.isDocumentReplaced = false;
    }


    _computeDefaultStatus() {
        return this.model() ? STATES.FILLED : STATES.WAITING;
    }

    _showAttachmentChangedLabel() {
        this.attachmentChangedLabelVisible(true);
        setTimeout(() => this.attachmentChangedLabelVisible(false), VALIDATION_MESSAGE_VISIBILITY_TIME);
    }

    _changeStatusOnModelChange(value) {
        const newStatus = value ? STATES.FILLED : STATES.WAITING;

        this.status(newStatus);
    }

    finderFunction(attachments = []) {
        throw new Error(`Method is not implemented, attachments: [${attachments}]`);
    }

    getFileElementConfig() {
        return Object.assign(super.getFileElementConfig(), {
            sizeLimit: 5,
            acceptedFileExtensions: '.txt, .rtf, .doc, .docx, .pdf, .odt, .htm, .html',
            unsupportedFileExtensions: '',
        });
    }

    downloadAttachment() {
        if (this.pendingAction()) {
            return;
        }

        this.pendingAction(ACTIONS.DOWNLOAD);

        download(this.model())
            .catch(() => notificationsService.error())
            .then(() => this.pendingAction(null));
    }

    async _removeDraftAttachment(attachment) {
        this.pendingAction(ACTIONS.REMOVE);

        await draftAttachmentService.remove(applicationDraft.id, attachment)
            .catch(console.error);

        this.pendingAction(null);
    }

    async removeFile() {
        if (this.pendingAction()) {
            return;
        }

        if (applicationDraft && this.model().appDraftId()) {
            await this._removeDraftAttachment(this.model());
        }

        this._removeAttachmentFromModel();
        this._addFocusToFileButton();
    }

    _addFocusToFileButton() {
        this.isAddFileButtonFocused(true);
    }

    _removeAttachmentFromModel() {
        this.model(null);
    }

    async _removeOldFileBeforeAdding() {
        if (!this.model()) {
            return;
        }

        this.status(this.defaultStatus());

        if (applicationDraft) {
            await this._removeDraftAttachment(this.model());
        }

        this._removeAttachmentFromModel();
    }

    async _addFile(file) {
        await this._removeOldFileBeforeAdding();

        return super._addFile(file);
    }

    _addAttachmentToModel(attachment) {
        this.model(attachment);
    }

    _replaceAttachment(attachment) {
        const oldAttachment = this.finderFunction(this.attachments());

        if (oldAttachment) {
            this.attachments.remove(oldAttachment);

            if (!this.isRemoving()) {
                this.isDocumentReplaced = true;
            }
        }

        if (!attachment) {
            this.attachments.notifySubscribers();

            return;
        }

        this.attachments.push(attachment);

        if (this.isDocumentReplaced) {
            this._showAttachmentChangedLabel();
            this.isDocumentReplaced = false;
        }
    }

    _attachmentExists() {
        const attachment = this.finderFunction(this.attachments());

        return Boolean(attachment);
    }

}