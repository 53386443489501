import mapping from 'knockout-mapping';
import AddressModel from 'apply-flow/module/personal-information-address/model/Address';


export default {
    mapFromRest(restCandidate) {
        return new AddressModel({
            country: restCandidate.country,
            region1: restCandidate.region1,
            region2: restCandidate.region2,
            region3: restCandidate.region3,
            city: restCandidate.city,
            addressLine1: restCandidate.addressLine1,
            addressLine2: restCandidate.addressLine2,
            addressLine3: restCandidate.addressLine3,
            addressLine4: restCandidate.addressLine4,
            addressLine5: restCandidate.addressLine5,
            postalCode: restCandidate.postalCode,
            longPostalCode: restCandidate.longPostalCode,
            building: restCandidate.building,
            floorNumber: restCandidate.floorNumber,
            addlAddressAttribute1: restCandidate.addlAddressAttribute1,
            addlAddressAttribute2: restCandidate.addlAddressAttribute2,
            addlAddressAttribute3: restCandidate.addlAddressAttribute3,
            addlAddressAttribute4: restCandidate.addlAddressAttribute4,
            addlAddressAttribute5: restCandidate.addlAddressAttribute5,
        });
    },

    mapToRest(address) {
        return mapping.toJS(address);
    },
};