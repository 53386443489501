import base64 from 'core/utils/base64';

const JSON_UNDEFINED = 'draft-json-undefined';

function isObject(value) {
    return value !== null && typeof value === 'object';
}

function draftJSONReplacer(key, value) {
    return value === undefined ? JSON_UNDEFINED : value;
}

function draftJSONReviver(draft) {
    Object.keys(draft).forEach((key) => {
        if (draft[key] === JSON_UNDEFINED) {
            draft[key] = undefined;
        }

        if (isObject(draft[key])) {
            draftJSONReviver(draft[key]);
        }
    });

    return draft;
}

export function mapDraftContentToRest({ candidate, application, removedProfileItemsMap }) {
    candidate.attachments = candidate.attachments.map((attachment) => {
        delete attachment.fileContents;

        return attachment;
    });

    delete candidate.isDateOfBirthInProfile;

    const content = { candidate, application, removedProfileItemsMap };

    return base64.encode(JSON.stringify(content, draftJSONReplacer));
}

export function mapDraftContentFromRest({ content, candidateNumber }) {
    const draftContent = JSON.parse(base64.decode(content));

    if (candidateNumber) {
        draftContent.application.candidateNumber = candidateNumber;
        draftContent.candidate.id = candidateNumber;
    }

    return draftJSONReviver(draftContent);
}