import IntegerFormValidator from 'core/form/validator/Integer';

export default class PositiveIntegerFormValidator extends IntegerFormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.positive-number';
    }

    _validate(value) {
        if (!value) {
            return true;
        }

        return super._validate(value) && value > 0;
    }

}