import { jobsSortOptions } from '../model/jobsSorting';
import { RouterQuery, SortOption } from 'search/config/types';
import { isDistanceEnabled, isKeywordSearch, isLocationSearch } from 'search/service/searchTypes';

type SearchType = 'KEYWORD' | 'DISTANCE_NOT_CALCULATED' | 'DISTANCE_CALCULATED';

const defaultSearchType: Record<SearchType, string> = {
    KEYWORD: 'RELEVANCY',
    DISTANCE_NOT_CALCULATED: 'POSTING_DATES',
    DISTANCE_CALCULATED: 'DISTANCE',
};

/**
 * Method to get the search type based on the query params
 * @returns {string} Return value is one of the types in SearchTypes
 */
export const getSearchType = (query: RouterQuery): SearchType => {
    if (isKeywordSearch(query)) {
        return 'KEYWORD';
    }

    if (isDistanceEnabled() && isLocationSearch(query)) {
        const locationLevel = query?.locationLevel;

        if (locationLevel === 'state' || locationLevel === 'country') {
            return 'DISTANCE_NOT_CALCULATED';
        }

        return 'DISTANCE_CALCULATED';
    }

    return 'DISTANCE_NOT_CALCULATED';
};

/**
 * getDefaultSortOption is to derive the default sort option based on the search criteria
 * @returns {object} Sort Option to set
 */
export const getDefaultSortOption = (query: RouterQuery): SortOption | null => {
    const searchType = getSearchType(query);

    return jobsSortOptions.find(({ code }) => code === defaultSearchType[searchType]) ?? null;
};
