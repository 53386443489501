import oj from 'ojs/ojcore';

/**
 * FRCE-5407
 * When displaying a date to candidates, the full information about this date (date, time, time zone)
 * must be considered to convert it to the candidate's time zone.
 */
export default class DateTimeConverterTimezoneDecorator {

    constructor(dateTimeConverter) {
        this.dateTimeConverter = dateTimeConverter;
    }

    format(dateTime) {
        const date = new Date(dateTime);
        const localIso = oj.IntlConverterUtils.dateToLocalIso(date);

        return this.dateTimeConverter.format(localIso);
    }

    parse(formattedDateTime) {
        try {
            const localIso = this.dateTimeConverter.parse(formattedDateTime);
            const date = oj.IntlConverterUtils.isoToLocalDate(localIso);

            return date;
        } catch (e) {
            return null;
        }
    }

}
