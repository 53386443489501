import { observable } from 'knockout';
import scrollKeeper from './scrollKeeper';

const { body } = document;
const ACTIVE_CLASS = 'body--menu-opened';

export default {
    isVisible: observable(false),
    wrapperClassName: observable(''),

    open(wrapperClassName) {
        if (wrapperClassName) {
            this.wrapperClassName(wrapperClassName());
        }

        this.isVisible(true);
        scrollKeeper.store(false);
        body.classList.add(ACTIVE_CLASS);
    },

    close() {
        this.isVisible(false);
        scrollKeeper.restore();
        body.classList.remove(ACTIVE_CLASS);
    },
};
