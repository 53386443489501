import { PureComputed, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import { EventShape } from 'cx/module/search/config/types';

export type EventTag = {
    isVisible: PureComputed<boolean>;
    className: string;
    label: string;
};

export type EventTagsOptions = {
    isRegistrationClosedTagVisible: boolean;
};

export const generateEventTags = (
    event: EventShape,
    configuration: PureComputed<EventTagsOptions>
): EventTag[] => {
    const lang = event.lang;

    return [
        {
            isVisible: pureComputed(
                () => Boolean(event.registrationClosedFlag) && configuration().isRegistrationClosedTagVisible
            ),
            label: i18n('search.registration-closed', null, lang),
            className: 'registration-closed',
        },
    ];
};
