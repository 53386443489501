import { components } from 'knockout';
import '../cs-event-withdraw-dialog/component';

import '../cs-event-tile/';
import '../cs-no-data-component';

import ViewModel from './CsEventsViewModel';
import template from './cs-events.html';

components.register('cs-events', { viewModel: ViewModel, template });
