import tokenService from 'candidate-verification/service/token';
import challengeService from 'candidate-verification/service/challenge';
import { Token } from 'candidate-verification/config/types';
import { redirectToHomePage } from 'candidate-self-service/service/redirectToHomePage';

export type ActionName =
    | 'candidate-self-service-not-me'
    | 'candidate-self-service-unsubscribe-job-alerts'
    | 'candidate-self-service-doi-accept';

type Action = (token: Token | undefined) => Promise<void>;

export const isSupportedAction = (routeId: string): boolean => {
    return routeId in ACTIONS && !challengeService.isChallengeRequired();
};

export const performAction = (actionName: ActionName, token: Token | undefined): Promise<void> => {
    const action = ACTIONS[actionName];

    if (action) {
        return action(token);
    }

    return Promise.resolve();
};

const markAsNotMe = (token: Token | undefined): Promise<void> => {
    return tokenService.verifyToken(token).then(handleNotMeSuccess);
};

const unsubscribeJobAlerts = (token: Token | undefined): Promise<void> => {
    return tokenService.verifyToken(token).then(handleUnsubscribeJobAlertsSuccess);
};

const doiAcceptConfirm = (token: Token | undefined): Promise<void> => {
    return tokenService.verifyToken(token).then(handleDoiAcceptSuccess);
};

const handleNotMeSuccess = (): Promise<void> => {
    return redirectToHomePage({
        notificationKey: 'referral.not-me.header',
        destroyToken: true,
    });
};

const handleUnsubscribeJobAlertsSuccess = (): Promise<void> => {
    return redirectToHomePage({
        notificationKey: 'candidate-self-service.unsubscribe-job-alerts-message',
    });
};

const handleDoiAcceptSuccess = (): Promise<void> => {
    return redirectToHomePage({
        notificationKey: 'candidate-self-service.doi-accept-confirmed',
    });
};

const ACTIONS: Record<ActionName, Action> = {
    'candidate-self-service-not-me': markAsNotMe,
    'candidate-self-service-unsubscribe-job-alerts': unsubscribeJobAlerts,
    'candidate-self-service-doi-accept': doiAcceptConfirm,
};
