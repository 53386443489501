import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';
import applyFlowEvents from 'apply-flow/config/events';
import TalentCommunitySummaryViewModel from './TalentCommunitySummaryViewModel';
import profileItemErrorHandler from 'apply-flow/module/profile-items/service/errorHandler';
import { ATTACHMENT_MIME_TYPE_ERROR, ATTACHMENT_UPLOAD_ERROR } from 'apply-flow/module/file-upload/config/attachmentErrorCodes';
import { CANDIDATE_SELF_SERVICE } from 'core/api-logger/config/module';
import talentCommunityEvents from '../../config/events';

export default class TalentCommunityUpdateSummaryViewModel extends TalentCommunitySummaryViewModel {

    constructor({ flow }) {
        super({ flow });
        this.module = CANDIDATE_SELF_SERVICE;
    }

    _saveJobAlertPreference() {
        return null;
    }

    _submitSourceTracking() {
        return null;
    }

    _onSuccess() {
        this._dumpLogs();

        notificationsService.successAfterLoaded(i18n('talent-community.update-success-message'), 500);

        applyFlowEvents.submitSucceed.dispatch();
    }

    _onError(error) {
        this._dumpLogsOnError();

        this._clearCandidate();
        talentCommunityEvents.updateFailed.dispatch();
        applyFlowEvents.submitFailed.dispatch();

        if (profileItemErrorHandler.isValidationError(error)) {
            profileItemErrorHandler.handleValidationError(error);
        } else {
            let errorMessage;

            if (error === ATTACHMENT_UPLOAD_ERROR) {
                errorMessage = i18n('apply-flow.submit-errors.file-upload');
            } else if (error === ATTACHMENT_MIME_TYPE_ERROR) {
                errorMessage = i18n('validators.file-upload.mime-type-error');
            }

            notificationsService.error(errorMessage);

            throw new Error(error);
        }
    }

}