import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import MultiSelectDropdown from './element/MultiSelectDropdown';

export default Object.create(formBuilder, {

    createForm: {
        value(model, jobLocations) {
            const form = formBuilder.createForm();

            form.elements([
                this._createPreferredLocationsFormElement(model().preferredLocations, jobLocations),
            ]);

            return form;
        },
    },

    _createPreferredLocationsFormElement: {
        value(model, jobLocations) {
            const label = i18n('apply-flow.work-preferences.preferred-location-label');

            const formElement = new MultiSelectDropdown({
                options: jobLocations,
            });

            formElement.label(label);

            formElement.attributes({
                name: 'preferredLocations',
                id: 'preferred-locations',
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});
