import viewsStore from 'core/router/service/viewsStore';
import appConfig from 'app/model/config';

let currentChallenge;

export default {
    open(challenge) {
        currentChallenge = challenge;

        const view = viewsStore.get('main') || viewsStore.get('layout');

        view('challenge-layout');
    },

    getCurrentChallenge() {
        return currentChallenge;
    },

    isChallengeRequired() {
        return appConfig.shortUrl.challengeFlag === 'NOW';
    },

    clearChallengeFlag() {
        appConfig.shortUrl.challengeFlag = '';
    },
};
