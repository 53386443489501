import Route from 'core/router/Route';

const ICE_PARAM_DELIMETER = ';';

function interpolateSourceTrace(routeParams) {
    const queryParams = routeParams.query || {};

    return Object.keys(queryParams)
        .filter(paramName => belongsToSourceTrace(paramName))
        .map(paramName => interpolateIceParam(paramName, queryParams[paramName]))
        .join(ICE_PARAM_DELIMETER);
}

function belongsToSourceTrace(paramName) {
    return paramName.indexOf('utm_') === 0;
}

function interpolateIceParam(paramName, paramValue) {
    return `${paramName}=${paramValue}`;
}

export default class IceRoute extends Route {

    interpolate(routeParams) {
        const url = super.interpolate(routeParams);
        const sourceTrace = interpolateSourceTrace(routeParams);
        const urlIncludesObjKey = url.includes('objKey');

        if (sourceTrace && urlIncludesObjKey) {
            return `${url}${ICE_PARAM_DELIMETER}${sourceTrace}`;
        }

        return url;
    }

}