import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import suggestionsService from 'cx/module/search/module/search-box/service/suggestions';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import * as spellCheckService from 'minimal/module/search/service/spellCheck';
import i18n from 'app/module/core/i18n/i18n';
import router from 'app/model/router';
import SearchQueryBuilder from 'app/module/cx/module/search/model/SearchQueryBuilder';
import { RouterQuery } from 'app/module/cx/module/search/config/types';
import { updateSearchContextStorageDetails } from '../../service/storeSearchContextDetails';

type KeywordObject = { label: string; value: string; routing: string };

type Keyword = string | KeywordObject;

type FetchedSuggestion = {
    label: string;
};

type SuggestionListElement = {
    item: KeywordObject;
};

type Props = {
    value: Observable<string>;
    action: () => void;
};

export default class SearchBoxKeywordViewModel {
    value: Observable<string>;
    isWorking: Observable<boolean>;
    keyword: PureComputed<Keyword>;
    action: () => void;
    searchKeywordPlaceHolder: 'search.keyword-placeholder-with-events' | 'search.keyword-placeholder';
    searchKeywordAriaLabel: string;
    maxKeywordSuggestions: number;

    constructor({ value, action }: Props) {
        this.value = value;
        this.action = action;
        this.maxKeywordSuggestions = areEventsEnabled() ? 3 : 5;

        this.searchKeywordPlaceHolder = areEventsEnabled()
            ? 'search.keyword-placeholder-with-events'
            : 'search.keyword-placeholder';

        this.searchKeywordAriaLabel = areEventsEnabled()
            ? i18n('search.a11y.keyword-label-events')
            : i18n('search.keyword-label');

        this.isWorking = observable<boolean>(false);

        this.keyword = pureComputed<Keyword>({
            read: () => this.value(),
            write: (keyword: Keyword) => {
                if (typeof keyword === 'object') {
                    return this.value(keyword.label);
                }

                spellCheckService.removeSpellCheckFlag();

                return this.value(keyword);
            },
        });

        this.fetch = this.fetch.bind(this);
        this.onSelectSuggestion = this.onSelectSuggestion.bind(this);
    }

    fetch(term: string): Promise<FetchedSuggestion[]> {
        this.isWorking(true);

        const keywordSuggestionsRequests = [
            suggestionsService.getKeywordSuggestions(term, this.maxKeywordSuggestions),
        ];

        if (areEventsEnabled()) {
            keywordSuggestionsRequests.push(
                suggestionsService.getKeywordSuggestionsEvents(term, this.maxKeywordSuggestions)
            );
        }

        return Promise.all(keywordSuggestionsRequests).then(this.onFetchingCompleted.bind(this));
    }

    private onFetchingCompleted(values: FetchedSuggestion[]): FetchedSuggestion[] {
        this.isWorking(false);

        return values.flat();
    }

    onSelectSuggestion(event: Event, suggestion: SuggestionListElement): void {
        this.keyword(suggestion.item.label);
        spellCheckService.stopSpellCheckExecution();

        const routingContext = suggestion.item?.routing;

        if (routingContext && areEventsEnabled()) {
            const { query } = router.routeParams() as { query: RouterQuery };
            const queryBuilder = new SearchQueryBuilder(query).withKeyword(suggestion.item.label);

            updateSearchContextStorageDetails(queryBuilder.build());
            router.go(routingContext, { query: queryBuilder.build() }, { inherit: false });
        } else {
            this.action();
        }
    }
}
