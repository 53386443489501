import { Observable, observable, ObservableArray, PureComputed, pureComputed } from 'knockout';
import selectedFiltersService from '../../service/selectedFilters';
import router from 'app/model/router';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { FiltersParams } from 'app/module/minimal/module/search/component/search-filters-panel/config/types';
import { Facet } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { getSearchModelFromRoute } from '../../service/searchContextRouting';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import { RouterParams, searchContextMap, SearchRouteOnly } from 'cx/module/search/config/types';
import {
    getSearchContextParam,
    getStoredQuery,
    saveSearchContextParamInStorage,
} from '../../service/storeSearchContextDetails';

type Props = {
    adminMode: boolean;
    areEventsEnabled: boolean;
    totalJobsCount: number;
    totalEventsCount: number;
    loading: boolean;
    totalCount: Observable<number>;
    filters: ObservableArray<Facet>;
    customizationParams: FiltersParams;
    uniqueWrapperClass: Observable<string>;
    defaultSearchContext: string;
    selectedSearchContext: Observable<string>;
};

export default class SearchFiltersHorizontalPanelViewModel {
    adminMode: boolean;
    areEventsEnabled: boolean;
    totalJobsCount: number;
    totalEventsCount: number;
    loading: boolean;
    totalCount: Observable<number>;
    filters: ObservableArray<Facet>;
    popupFiltersOpened: Observable<boolean>;
    selectedFiltersCount: PureComputed<number>;
    selectedFilters: PureComputed<Facet[]>;
    customizationParams: FiltersParams;
    jobsCounterKey: PureComputed<
        'search.search-results-header.plain-single' | 'search.search-results-header.plain'
    >;

    uniqueWrapperClass: Observable<string>;
    defaultSearchContext: string;
    selectedSearchContext: Observable<string>;

    constructor({
        adminMode,
        areEventsEnabled,
        totalJobsCount,
        totalEventsCount,
        loading,
        totalCount,
        filters,
        customizationParams,
        uniqueWrapperClass,
        defaultSearchContext,
    }: Props) {
        this.adminMode = adminMode;
        this.areEventsEnabled = areEventsEnabled;
        this.totalJobsCount = totalJobsCount;
        this.totalEventsCount = totalEventsCount;
        this.loading = loading;
        this.totalCount = totalCount;
        this.filters = filters;
        this.customizationParams = customizationParams;
        this.uniqueWrapperClass = uniqueWrapperClass;

        this.popupFiltersOpened = observable<boolean>(false);
        this.selectedFiltersCount = pureComputed(() => this.getSelectedFilters().length);
        this.selectedFilters = pureComputed(this.getSelectedFilters, this);
        this.jobsCounterKey = pureComputed(this.computeJobsCounterKey, this);

        this.clearFilters = this.clearFilters.bind(this);
        this.toggleFiltersPopup = this.toggleFiltersPopup.bind(this);
        this.defaultSearchContext = defaultSearchContext;
        this.selectedSearchContext = observable(this.defaultSearchContext);
    }

    private getSelectedFilters() {
        return selectedFiltersService.get(getSearchModelFromRoute().filters());
    }

    private computeJobsCounterKey() {
        return this.totalCount() === 1
            ? 'search.search-results-header.plain-single'
            : 'search.search-results-header.plain';
    }

    clearFilters(): void {
        const routeId = router.route().id;

        const query = getStoredQuery(searchContextMap[routeId as SearchRouteOnly]);

        const queryWithoutFilters = new SearchQueryBuilder(query).withoutFacets().withoutHotJobs().build();

        if (areEventsEnabled()) {
            saveSearchContextParamInStorage(getSearchContextParam(routeId), {
                query: queryWithoutFilters,
            } as RouterParams);
        }

        router.go(routeId, { query: queryWithoutFilters }, { inherit: false });
    }

    toggleFiltersPopup(): void {
        this.popupFiltersOpened(!this.popupFiltersOpened());
    }

    onChangeSearchContext = (searchContext: string): void => {
        this.selectedSearchContext(searchContext);
    };
}
