import { observable, observableArray, pureComputed } from 'knockout';

import candidateModel from 'apply-flow/model/candidate';
import skillRecommendation from '../../service/skillRecommendation';
import jobDetails from '../../../../model/jobDetails.js';
import applyFlowEvents from '../../../../config/events';
import i18n from 'core/i18n/i18n';
import CandidateExperience from '../../../profile-items/model/CandidateExperience';
import CandidateSkill from '../../../profile-items/model/CandidateSkill';

export default class SkillRecommendationViewModel {

    constructor({ isActive, createTile, sectionId, isValidating }) {
        this.createTile = createTile;
        this.sectionId = sectionId;
        this.isActive = isActive;
        this.isValidating = isValidating;
        this._recommendationRefreshThreshold = -1;
        this.isRecommendationStale = true;
        this.recommendations = observableArray([]);
        this.isFetchingRecommendations = observable(false);
        this.componentLabel = i18n('apply-flow.profile-item.skill-recommendation');
        this.skillSelected = this.skillSelected.bind(this);

        this.isActive.subscribe(this._activeChangeDetected, this);

        jobDetails.title
            .subscribe(this._dependencyChangeDetected, this);

        this.profileItemChangeDetected = applyFlowEvents
            .profileItemChangeDetected
            .add(this._profileItemChangeHandler.bind(this));

        this.isLoading = pureComputed(() => this.isFetchingRecommendations() || this.isValidating());

        if (this._shouldRefreshRecommendations()) {
            this._refreshRecommendations();
        }
    }

    addSkillButtonTitle(skill) {
        const titlePrefix = i18n('apply-flow.profile-item.add-skill-button');

        return `${titlePrefix} ${skill}`;
    }

    skillSelected(skill) {
        if (!skill) {
            return;
        }

        this.createTile(skill)
            .then(() => {
                this.recommendations.remove(skill);
                this._checkRecommendationTheshold();
            });
    }

    focusHandler() {
        this.hasFocus(true);
    }

    _checkRecommendationTheshold() {
        if (this.recommendations().length <= this._recommendationRefreshThreshold) {
            this._refreshRecommendations();
        }
    }

    _shouldRefreshRecommendations() {
        return this.isRecommendationStale
              && this.isActive()
              && jobDetails.title();
    }

    _refreshRecommendations() {
        this.isFetchingRecommendations(true);

        this._fetchRecommendations()
            .then(skills => this._updateRecommendations(skills))
            .then(() => this._updateRefreshThreshold())
            .finally(() => this.isFetchingRecommendations(false));
    }

    _profileItemChangeHandler({ model }) {
        const isExperience = model() instanceof CandidateExperience;

        const isSkill = model() instanceof CandidateSkill
            && model().sectionId() === this.sectionId;

        if (isExperience || isSkill) {
            this._dependencyChangeDetected();
        }
    }

    _dependencyChangeDetected() {
        this.isRecommendationStale = true;

        if (this._shouldRefreshRecommendations()) {
            this._refreshRecommendations();
        }
    }

    _activeChangeDetected() {
        if (this._shouldRefreshRecommendations()) {
            this._refreshRecommendations();
        }
    }

    _fetchRecommendations() {
        const skills = candidateModel.skills()
            .filter(({ sectionId }) => sectionId() === this.sectionId);

        return skillRecommendation.getRecommendations({
            title: jobDetails.title(),
            experiences: candidateModel.experience(),
            skills,
        });
    }

    _updateRecommendations(skills) {
        this.recommendations(skills);

        this.isRecommendationStale = false;
    }

    _updateRefreshThreshold() {
        const RECOMMENDATION_THRESHOLD_FACTOR = 0.75;
        const recommendationsLimit = skillRecommendation.MAX_RECOMMENDATIONS;

        let newThreshold = -1;

        if (this.recommendations().length === recommendationsLimit) {
            newThreshold = Math.floor(recommendationsLimit * RECOMMENDATION_THRESHOLD_FACTOR);
        }

        this._recommendationRefreshThreshold = newThreshold;
    }

    dispose() {
        this.profileItemChangeDetected.detach();
    }

}
