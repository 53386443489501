import { getItemActiveSelector } from 'minimal/config/utils';

type RecommendedJobsWidgetField =
    | 'wrapper'
    | 'container'
    | 'containerOnHover'
    | 'callToAction'
    | 'callToActionOnHover'
    | 'browseButton'
    | 'browseButtonOnHover'
    | 'uploadingMessage'
    | 'errorMessage'
    | 'successMessage'
    | 'manage'
    | 'manageOnHover'
    | 'manageDropdownOnHover'
    | 'viewAllJobsLinkContainerOnHover'
    | 'icons'
    | 'iconsOnHover'
    | 'uploadingMessageContainer'
    | 'errorMessageContainer'
    | 'successMessageContainer'
    | 'manageDropdownContainer'
    | 'viewAllJobsLinkContainer'
    | 'viewAllJobsLabel'
    | 'viewAllJobsLabelOnHover'
    | 'privacyPolicyLink'
    | 'privacyPolicyLinkOnHover'
    | 'privacyPolicyModalHeader'
    | 'privacyPolicyModalText'
    | 'privacyPolicyModalCloseButton'
    | 'privacyPolicyModalCloseButtonHover'
    | 'privacyPolicyModal'
    | 'privacyPolicyModalCloseButtonStyling'
    | 'successMessageOnHover'
    | 'successMessageIcon';

type RecommendedJobsWidgetSelector = Record<RecommendedJobsWidgetField, string>;

export const getSearchResultSelectors = (wrapperSelector: string): RecommendedJobsWidgetSelector => {
    return {
        wrapper: `${wrapperSelector}.recommended-jobs-widget-wrapper`,
        container: `${wrapperSelector} .recommended-jobs-widget`,
        containerOnHover: getItemActiveSelector(`${wrapperSelector} .recommended-jobs-widget`, ``),
        icons: `${wrapperSelector} .recommended-jobs-widget__icon-cloud::before, ${wrapperSelector} .recommended-jobs-widget__status .minimal-spinner`,
        iconsOnHover: [
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget`,
                '.recommended-jobs-widget__icon-cloud::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget`,
                '.recommended-jobs-widget__status .minimal-spinner'
            ),
        ].join(','),
        callToAction: `.cc-row-editor__columns ${wrapperSelector} .recommended-jobs-widget .recommended-jobs-widget__text-upload, .cc-row__content ${wrapperSelector} .recommended-jobs-widget .recommended-jobs-widget__text-upload`,
        callToActionOnHover: getItemActiveSelector(
            `${wrapperSelector} .recommended-jobs-widget`,
            '.recommended-jobs-widget__text-upload'
        ),
        browseButton: `${wrapperSelector} .recommended-jobs-widget--large .recommended-jobs-widget__button.recommended-jobs-widget__button--upload, ${wrapperSelector} .recommended-jobs-widget--medium .recommended-jobs-widget__button.recommended-jobs-widget__button--upload, ${wrapperSelector} .recommended-jobs-widget--small .recommended-jobs-widget__button.recommended-jobs-widget__button--upload`,
        browseButtonOnHover: getItemActiveSelector(
            `${wrapperSelector} .recommended-jobs-widget`,
            '.recommended-jobs-widget__button--upload'
        ),
        uploadingMessage: `${wrapperSelector} .recommended-jobs-widget__info`,
        errorMessage: `${wrapperSelector} .recommended-jobs-widget__text-error`,
        successMessage: `${wrapperSelector} .recommended-jobs-widget-success-banner__text`,
        uploadingMessageContainer: `${wrapperSelector} .recommended-jobs-widget--uploading`,
        errorMessageContainer: `${wrapperSelector} .recommended-jobs-widget--error`,
        successMessageContainer: `${wrapperSelector} .recommended-jobs-widget-success-banner`,
        manage: `${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-section`,
        manageOnHover: getItemActiveSelector(
            `.recommended-jobs-widget-success-banner__manage-action-section`,
            '.recommended-jobs-widget-success-banner__manage-action-label'
        ),
        manageDropdownContainer: `${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-menu`,
        manageDropdownOnHover: getItemActiveSelector(
            `.recommended-jobs-widget-success-banner__manage-action-item`,
            ''
        ),
        viewAllJobsLinkContainer: `${wrapperSelector} .recommended-jobs-widget-success-banner__view-all-jobs-section`,
        viewAllJobsLinkContainerOnHover: [
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget-success-banner__view-all-jobs-section`,
                ''
            ),
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget-success-banner__view-all-jobs-section`,
                '.recommended-jobs-widget-success-banner__view-all-jobs-arrow-icon::before'
            ),
        ].join(','),
        viewAllJobsLabel: `${wrapperSelector} .recommended-jobs-widget-success-banner__view-all-jobs`,
        viewAllJobsLabelOnHover: getItemActiveSelector(
            `${wrapperSelector} .recommended-jobs-widget-success-banner__view-all-jobs-section`,
            '.recommended-jobs-widget-success-banner__view-all-jobs'
        ),
        privacyPolicyLink: `${wrapperSelector} .recommended-jobs-widget__privacy-statement`,
        privacyPolicyLinkOnHover: `${wrapperSelector} .recommended-jobs-widget__privacy-statement:hover`,
        privacyPolicyModal: `.recommended-jobs-privacy-policy__dialog .recommended-jobs-privacy-policy__dialog__main.recommended-jobs-privacy-policy__dialog__main-center`,
        privacyPolicyModalHeader: `.recommended-jobs-privacy-policy__header .heading`,
        privacyPolicyModalText: `.recommended-jobs-privacy-policy__dialog .recommended-jobs-privacy-policy__body`,
        privacyPolicyModalCloseButton: `.recommended-jobs-privacy-policy__dialog .recommended-jobs-privacy-policy__close-button`,
        privacyPolicyModalCloseButtonHover: `.recommended-jobs-privacy-policy__dialog .recommended-jobs-privacy-policy__close-button:hover`,
        privacyPolicyModalCloseButtonStyling: `.recommended-jobs-privacy-policy__dialog .recommended-jobs-privacy-policy__close-button::before`,
        successMessageOnHover: [
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-section`,
                '.recommended-jobs-widget-success-banner__manage-action-icon::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-section`,
                '.recommended-jobs-widget-success-banner__manage-action-down-arrow-icon::before'
            ),
        ].join(','),
        successMessageIcon: `${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-icon::before, ${wrapperSelector} .recommended-jobs-widget-success-banner__manage-action-down-arrow-icon::before`,
    };
};
