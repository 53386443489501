import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import '../form/component/multi-select-pill-form-element/component';

import ViewModel from '../JobAlertPreferencesViewModel';
import template from '../job-alert-preferences.html';

components.register('job-alert-preferences', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_JOB_ALERT_PREFERENCES', {
    component: 'job-alert-preferences',
});
