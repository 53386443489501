import { components } from 'knockout';
import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/inline-partner/service/Metadata';

import ViewModel from 'apply-flow/module/inline-partner/InlinePartnerViewModel';
import template from '../inline-partner.html';

components.register('inline-tax-credits', { viewModel: ViewModel, template });
components.register('inline-assessment', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_ASSESSMENT', {
    component: 'inline-assessment',
    repository: metadataRepository,
});

metadataConfig.registerBlock('ORA_TAXCREDITS', {
    component: 'inline-tax-credits',
    repository: metadataRepository,
});

