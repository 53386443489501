import * as genericDictionaryService from 'apply-flow/service/genericDictionary';
import dictionaryService from 'apply-flow/service/dictionary';

const REST_ELEMENT_TYPES = [
    'Email',
    'Phone',
];

const ELEMENT_TEXT_TYPE = 'text';


function _toCamelCase(str) {
    return str[0].toLowerCase() + str.substr(1);
}

function _sortByDisplaySequence(item1, item2) {
    return item1.displaySequence - item2.displaySequence;
}

function _mapDictionaryData(formElement) {
    // FIXME: Hack for title element will be removed once bug #30233760 is resolved
    let getDictionaryPromise;

    if (formElement.name === 'title') {
        getDictionaryPromise = dictionaryService.get(dictionaryService.CODES.nameTitles);
    } else {
        getDictionaryPromise = genericDictionaryService.get(formElement.dictionary, 'findByLookupType');
    }

    return getDictionaryPromise
        .then((lookupValues) => {
            if (lookupValues) {
                formElement.type = 'select';
                formElement.options = lookupValues;
            }

            return formElement;
        });
}

function _getFormElementType(attributeName) {
    return REST_ELEMENT_TYPES.includes(attributeName)
        ? _toCamelCase(attributeName)
        : ELEMENT_TEXT_TYPE;
}

function _createFormElement(restFormElement) {
    return {
        type: _getFormElementType(restFormElement.attributeName),
        name: _toCamelCase(restFormElement.attributeName),
        label: restFormElement.prompt,
        attributes: {
            required: restFormElement.requiredFlag,
        },
        validators: {},
        dictionary: restFormElement.dictionary,
    };
}

export default {
    mapFormElementsFromRest(restData) {
        const restFormElements = restData.items;

        return Promise.all(
            restFormElements
                .sort(_sortByDisplaySequence)
                .map(this.mapFormElementFromRest.bind(this)),
        );
    },

    mapFormElementFromRest(restFormElement) {
        const formElement = _createFormElement(restFormElement);

        if (restFormElement.requiredFlag) {
            formElement.validators.required = {
                label: restFormElement.prompt,
            };
        }

        if (restFormElement.length) {
            formElement.validators.maxLength = {
                max: restFormElement.length,
            };
        }

        if (formElement.dictionary != null) {
            return _mapDictionaryData(formElement);
        }

        return Promise.resolve(formElement);
    },
};