import { unwrap, pureComputed } from 'knockout';
import { getLocalTimezone } from 'core/utils/timezoneUtils';
import appConfig from 'app/model/config';

export default class ScheduleDateTimeViewModel {

    constructor(params) {
        this.scheduleDateTime = params.scheduleDateTime;
        this.date = pureComputed(() => this._formatDateFull(this.scheduleDateTime().startTime()));
        this.timeSlot = pureComputed(() => this._getTimeSlot());
    }

    _formatDateFull(date) {
        const d = unwrap(date);

        return d ? Intl.DateTimeFormat([appConfig.siteLang, 'en'], { dateStyle: 'full' }).format(new Date(d)) : null;
    }

    _getTimeSlot() {
        const startTime = this._formatTimeShort(this.scheduleDateTime().startTime());
        const endTime = this._formatTimeShort(this.scheduleDateTime().endTime());
        const timeZoneOffset = getLocalTimezone();
        let timeSlot = null;

        if (startTime && endTime) {
            timeSlot = startTime.concat(' - ').concat(endTime).concat(' ').concat(timeZoneOffset);
        }

        return timeSlot;
    }

    _formatTimeShort(date) {
        const d = unwrap(date);

        return d ? Intl.DateTimeFormat([appConfig.siteLang, 'en'], { timeStyle: 'short' }).format(new Date(d)) : null;
    }

}