import siteListService from '../../service/siteList';
import { observable, pureComputed } from 'knockout';
import jobAlertService from '../../../job-alerts/service/jobAlerts';
import appConfig from 'app/model/config';
import OptIn from '../../../apply-flow/model/OptIn';

export default class CsTalentCommunityViewModel {

    constructor({ candidateNumber, enableOptInCSS, isTCOptInEnabled }) {
        this.candidateNumber = candidateNumber;
        this.showSitesNames = observable();
        this.tcPreferences = observable();
        this.optIn = observable(false);
        this.isReady = observable(false);
        this.jobAlertPreferencesConfig = appConfig.getSettingByKey('ORA_IRC_TC_ACTIVE_ATTRIBUTES');

        this.enableOptInCSS = pureComputed(() => {
            if (enableOptInCSS()) {
                this.optIn(new OptIn({
                    isEnabled: enableOptInCSS(),
                    versionId: appConfig.optinDescriptionVersionId,
                    description: appConfig.optinDescriptionStr,
                }));
            }

            return enableOptInCSS();
        });

        this.isTCOptInEnabled = pureComputed(() => isTCOptInEnabled);

        if (this.candidateNumber()) {
            this._loadPreferences();
        } else {
            this.candidateNumber.subscribeOnce(this._loadPreferences.bind(this));
        }
    }

    _loadPreferences() {
        this.isReady(false);

        Promise.all([jobAlertService.getPreferences(this.candidateNumber()),
            siteListService.getSiteList()])
            .then(([jobAlerts, siteList]) => {
                this._addCurrentSitePreferences(jobAlerts);
                this.showSitesNames(jobAlerts.siteTCPreferences.length > 1);
                this.tcPreferences(this._mapSiteNames(jobAlerts.siteTCPreferences, siteList));
            })
            .then(() => {
                this.isReady(true);
            });
    }

    _addCurrentSitePreferences(preferences) {
        if (!preferences.siteTCPreferences) {
            preferences.siteTCPreferences = [];
            preferences.candidateId = this.candidateNumber();
            preferences.siteTCPreferences.push(this._addCurrentSitePreferencesData());
        } else if (!preferences.siteTCPreferences
            .filter(preference => preference.siteNumber === appConfig.siteNumber).length) {
            preferences.siteTCPreferences.push(this._addCurrentSitePreferencesData());
        }
    }

    _addCurrentSitePreferencesData() {
        return {
            jobAlertsEnabled: false,
            preferredLocations: [],
            preferredJobFamilies: [],
            preferredJobFunctions: [],
            siteName: appConfig.siteName,
            siteNumber: appConfig.siteNumber,
        };
    }

    _mapSiteNames(preferences, siteList) {
        preferences.forEach((site, index, arr) => {
            arr[index].siteName = siteList[site.siteNumber]?.siteName;
        });

        return preferences;
    }

}