import absoluteRouter from 'app/model/absoluteRouter';
import screenInfo from 'cx/model/screenInfo';
import i18n from 'core/i18n/i18n';
import { Job, LinkTemplate } from '../config/types';
import { groupByAddress } from '../config/utils';

const PROGRESS_BARS_LENGTH = {
    DESKTOP: 6,
    MOBILE: 5,
};

export const linkTemplateMobile = ({ number, title }: LinkTemplate): string =>
    `<li class="search-marker__link" data-job-id="${number}">
    <span class="search-marker__job text-color-primary">
        ${title}
    </span>
    <span class="search-marker__icon"></span>
    </li>`;

export const linkTemplateLarge = ({ number, url, title }: LinkTemplate): string =>
    `<li><a href="${url}" class="search-marker__link" data-job-id="${number}">
    <span class="search-marker__job text-color-primary">
        ${title}
    </span>
    <span class="search-marker__icon"></span>
    </a></li>`;

export const generatepopupContent = (jobs: Job[], hasMore: boolean, markerJobsCount: number): string => {
    if (!jobs?.length) {
        return generateProgressBarContent(markerJobsCount);
    }

    const groupByLocation = groupByAddress(jobs);

    const isSingleLocation = Object.entries(groupByLocation).length === 1;

    const gropupByLocationLinks = Object.entries(groupByLocation).reduce(
        (accumulator, [address, jobList]) => {
            const jobLinks = jobList.reduce((links, { number, title }) => {
                const url = absoluteRouter.interpolate({}, 'job-details', { jobId: number });

                const link = screenInfo.hasPointingDevice
                    ? linkTemplateLarge({ number, url, title })
                    : linkTemplateMobile({ number, title });

                return links + link;
            }, '');

            return `${accumulator}
                <div class="search-marker__address text-color-secondary">${address}</div>
                <ul class="search-marker">
                ${jobLinks}
                ${isSingleLocation && hasMore ? generateLoadMoreButtonContent() : ''}
                </ul>`;
        },
        ''
    );

    return `<div class="search-marker__container font-family-primary ${
        isSingleLocation ? 'search-marker-address--sticky' : ''
    }">${gropupByLocationLinks}</div>
            ${!isSingleLocation && hasMore ? generateLoadMoreButtonContent() : ''}`;
};

export const generateProgressBarContent = (progressbarCount: number): string => {
    const maxProgressbarCount = PROGRESS_BARS_LENGTH[screenInfo.isSmall() ? 'MOBILE' : 'DESKTOP'];
    const isMaxLimitReach = progressbarCount >= maxProgressbarCount;

    if (isMaxLimitReach) {
        progressbarCount = maxProgressbarCount;
    } else {
        progressbarCount += 1;
    }

    const content = [...Array(progressbarCount)]
        .map(
            (_, index) =>
                `<div class="progress-bar-skeleton_${index + 1} progress-bar-skeleton-animation ${
                    isMaxLimitReach && progressbarCount === index + 1 ? 'margin-bottom' : ''
                }"></div>`
        )
        .join('');

    return `<div class="progress-bar-wrapper">
        ${content}
        </div>`;
};

export const generateLoadMoreButtonContent = (): string => {
    return `
    <div class="search-pagination__container">
        <div class="search-pagination__loading">
            <span class="search-pagination__loading-label">${i18n('search.maps.loading-more-jobs')}</span>
            <div class="search-pagination__spinner" aria-label=${i18n(
                'search.maps.loading-more-jobs'
            )} role="alert" aria-busy="false">
                <div class="minimal-spinner text-color-secondary" aria-label=${i18n(
                    'search.maps.loading-more-jobs'
                )} role="alert" aria-busy="false">
                </div>
            </div>
        </div>
    </div>`;
};
