import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import i18n from 'core/i18n/i18n';
import { canEnterCSS } from 'candidate-self-service/config/canEnterCss';
import { cssActionsRoutes, cssTokenRoute } from 'candidate-self-service/config/commonRoutes';
import { storeShortTokenData } from 'app/module/cx/module/candidate-verification/service/shortTokenStorage';

router.configure({
    'candidate-self-service': {
        url: '/my-profile',
        view: {
            layout: 'candidate-self-service',
            dialogs: null,
        },
        title: i18n('candidate-self-service.page-title'),
        canEnter() {
            return canEnterCSS();
        },
    },

    'candidate-self-service.sign-in': {
        parent: 'minimal',
        url: '/my-profile/sign-in',
        view: {
            main: 'cs-sign-in',
            dialogs: null,
        },
        title: i18n('apply-flow.candidate-verification.manage-profile'),
        canEnter() {
            if (tokenService.isCandidateVerified()) {
                return Promise.reject({
                    redirectTo: 'candidate-self-service',
                });
            }

            return Promise.resolve();
        },
    },

    'candidate-self-service.events': {
        parent: 'candidate-self-service',
        url: '/events',
        canEnter() {
            return canEnterCSS();
        },
    },

    'candidate-self-service.info': {
        parent: 'candidate-self-service',
        url: '/info',
        canEnter() {
            return canEnterCSS();
        },
    },

    'candidate-self-service-with-token.events': {
        url: '/my-profile/{token}/events',
        view: {
            layout: 'candidate-self-service',
        },
        title: i18n('candidate-self-service.page-title'),

        enter(routeParams: any) {
            storeShortTokenData({ token: routeParams?.token });

            return Promise.resolve();
        },
    },

    'candidate-self-service.job-preview': {
        parent: 'candidate-self-service',
        url: '/preview/{jobId}',
        view: {
            dialogs: 'job-details-css',
        },
        canEnter() {
            return canEnterCSS();
        },
    },
    'candidate-self-service.event-preview': {
        parent: 'candidate-self-service.events',
        url: '/preview/{eventId}',
        view: {
            dialogs: 'event-details-css',
        },
        canEnter() {
            return canEnterCSS();
        },
    },

    'candidate-self-service.event-detail-fullscreen': {
        parent: 'minimal',
        url: '/css/events/{eventId}',
        view: {
            main: 'event-details-fullscreen',
            dialogs: null,
        },
    },

    // This is important that the token route is placed after events and info routes.
    // Otherwise these routes would be recognized as a token route
    ...cssTokenRoute,
    ...cssActionsRoutes,
});
