import { components } from 'knockout';

import { JobDetailsDescriptionViewModel } from '../job-details-description/JobDetailsDescriptionViewModel';
import template from './job-details-qualifications.html';

components.register('job-details-qualifications', {
    viewModel: JobDetailsDescriptionViewModel,
    template,
});

components.register('cc-job-details-qualifications', {
    viewModel: JobDetailsDescriptionViewModel,
    template,
});
