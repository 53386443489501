import { observable } from 'knockout';
import tokenService from './token';
import storageService from 'core/storage/localStorage';

type CandidateSessionExpire = {
    tokenId: number;
    accessCodeExpirationDate: string;
    uiSessionExpireTime: number;
};

class IdleTimer {
    SESSION_EXPIRE_TIME_OUT = 30 * 60 * 1000;
    SESSION_EXPIRE_DIALOG_THREASHOLD_TIME = 3 * 60 * 1000;
    SESSION_EXPIRE_KEY = 'ceSessionExpire';
    SERVER_SESSION_EXPIRE_TIME_OUT = 4 * 60 * 60 * 1000;
    leftOverTime = observable(this.SESSION_EXPIRE_TIME_OUT);
    private interval: NodeJS.Timeout | undefined;
    eventHandler = this.updateExpiredTime.bind(this);

    private getTokenId(): number {
        return tokenService.get()?.tokenId;
    }

    private getAccessCodeExpirationDate(): string {
        return tokenService.get()?.accessCodeExpirationDate;
    }

    private getDateNow(): number {
        return Date.now();
    }

    private calculateTimeDifference(dateString: string): number {
        return new Date().valueOf() - new Date(dateString).valueOf();
    }

    init(): void {
        this.startActivityTracker();
        this.startInterval();
        this.clearExpiredSessionsFromStore();
    }

    startInterval(): void {
        this.updateExpiredTime();

        this.interval = setInterval(() => {
            const candidateSessionExpire = this.getCandidateSessionExpireFromStorage(this.getTokenId());

            if (candidateSessionExpire) {
                this.leftOverTime(candidateSessionExpire?.uiSessionExpireTime - this.getDateNow());
            }
        }, 1000);
    }

    updateExpiredTime(): void {
        const token = this.getTokenId();

        if (!token) {
            return;
        }

        const candidatesSessionExpire = this.getSessionExpireFromStorage().filter(
            ({ tokenId }: CandidateSessionExpire) => tokenId !== token
        );

        candidatesSessionExpire.push({
            tokenId: token,
            accessCodeExpirationDate: this.getAccessCodeExpirationDate(),
            uiSessionExpireTime: this.getDateNow() + this.SESSION_EXPIRE_TIME_OUT,
        });

        storageService.store(this.SESSION_EXPIRE_KEY, candidatesSessionExpire);
    }

    startActivityTracker(): void {
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    stopActivityTracker(): void {
        window.removeEventListener('mousemove', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
        window.removeEventListener('keydown', this.eventHandler);
    }

    cleanUp(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }

        this.stopActivityTracker();
        this.clearCandidateSessionExpireFromStorage();
    }

    getSessionExpireFromStorage(): CandidateSessionExpire[] {
        const ceSessionExpire = storageService.restore(this.SESSION_EXPIRE_KEY);

        return ceSessionExpire ?? [];
    }

    getCandidateSessionExpireFromStorage(token: number): CandidateSessionExpire | undefined {
        const sessionExpireFromStorage = this.getSessionExpireFromStorage();

        return sessionExpireFromStorage?.find(({ tokenId }: CandidateSessionExpire) => tokenId === token);
    }

    clearExpiredSessionsFromStore(): void {
        const candidatesSessionExpire = this.getSessionExpireFromStorage().filter(
            ({ accessCodeExpirationDate }: CandidateSessionExpire) =>
                this.calculateTimeDifference(accessCodeExpirationDate) < this.SERVER_SESSION_EXPIRE_TIME_OUT
        );

        storageService.store(this.SESSION_EXPIRE_KEY, candidatesSessionExpire);
    }

    clearCandidateSessionExpireFromStorage(): void {
        const candidatesSessionExpire = this.getSessionExpireFromStorage().filter(
            ({ tokenId }: CandidateSessionExpire) => tokenId !== this.getTokenId()
        );

        storageService.store(this.SESSION_EXPIRE_KEY, candidatesSessionExpire);
    }
}

export default new IdleTimer();
