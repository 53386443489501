export default {
    empty: {
        sections: [
            {
                elementOrder: 0,
                sectionParams: [
                    {
                        paramKey: 'type',
                        paramValue: 'cc-section',
                    },
                ],
                rows: [
                    {
                        elementOrder: 0,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [{
                            elementOrder: 0,
                            components: [],
                            columnParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-column',
                            }],
                        }],
                    },
                ],
            },
        ],
    },
};
