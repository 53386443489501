import columnMapper from './column';
import itemSorter from '../service/itemSorter';
import paramsMapper from './params';

export default {
    mapRowsFromRest(rows, componentFactory) {
        if (!rows) {
            return [];
        }

        const sortedRows = itemSorter.sort(rows);

        return sortedRows.map(row => this.mapRowFromRest(row, componentFactory));
    },

    mapRowFromRest(row, componentFactory) {
        try {
            return componentFactory.create({
                elementNumber: row.elementNumber,
                params: paramsMapper.mapParamsFromRest([
                    ...row.rowParams,
                    { paramKey: 'type', paramValue: 'cc-row' },
                ]),
                order: row.elementOrder,
                columns: columnMapper.mapColumnsFromRest(row.columns, componentFactory),
            });
        } catch (err) {
            console.error(err);

            return componentFactory.createEmptyRow();
        }
    },
};