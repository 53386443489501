import { Observable, ObservableArray, PureComputed, pureComputed, observable } from 'knockout';
import { searchEventResults } from '../../model/searchEventResults';
import { mapParamsConfigurationToObservable } from '../../service/customCss';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { SearchResultsParams } from 'minimal/module/search/component/search-results/config/types';
import { getTileComponents } from '../search-event-results/config/tileComponents';
import { getCustomStyles } from '../search-event-results/config/customStyles';
import {
    noSearchResultsParams,
    RouterParams,
    RouterQuery,
    SEARCH_RESULTS_STRINGS,
    SortOption,
} from 'app/module/cx/module/search/config/types';
import ContentComponent from 'app/module/admin/module/site-editor/model/ContentComponent';
import { EventShape as Event } from 'cx/module/search/config/types';
import { getPaginationState, PaginationState } from '../search-pagination/service/pagination';
import { search as searchService } from 'minimal/module/search/service/search';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import router from 'app/model/router';
import {
    getEventsSelectedSortOption,
    getEventsSortingOptions,
} from 'minimal/module/search/component/search-sorting-selector/service/searchEventsSortOptionsService';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import SearchQueryBuilder, { buildSortOption } from 'search/model/SearchQueryBuilder';
import {
    getStoredQuery,
    saveSearchContextParamInStorage,
    clearSearchParams,
} from 'minimal/module/search/service/storeSearchContextDetails';
import { mapContentParamsToEventContentParams } from './migration/contentParams';

type ComponentClass = 'events-grid' | 'events-list';

const eventsTileView = ['toggleTileMap', 'tile'];

type Props = {
    contentComponent: ContentComponent;
    uniqueWrapperClass: string;
};

export default class SearchEventResultsViewModel {
    customizationParams: SearchResultsParams;
    tileComponent: string;
    talentCommunityTileComponent: string;
    loading: Observable<boolean> | null;
    initialized: Observable<boolean> | null;
    eventList: ObservableArray<Event> | null;
    hasResults: PureComputed<boolean | null> | null;
    listClass: PureComputed<string>;
    componentClass: PureComputed<ComponentClass>;
    sortOptions: PureComputed<SortOption[]>;
    selectedSortOption: PureComputed<SortOption>;
    customCss: PureComputed<string>;
    paginationState: PureComputed<PaginationState>;
    noSearchResultsParams: noSearchResultsParams;

    constructor({ contentComponent, uniqueWrapperClass }: Props) {
        if (contentComponent.params) {
            contentComponent.params.contentParams = mapContentParamsToEventContentParams(
                contentComponent.params.contentParams
            );
        }

        this.customizationParams =
            contentComponent.params || mapParamsConfigurationToObservable(emptySearchResultsParams);

        const { tile, talentCommunityTile } = getTileComponents(
            this.customizationParams.contentParams.jobDisplayStyle(),
            'active'
        );

        this.tileComponent = tile;

        this.talentCommunityTileComponent = talentCommunityTile;

        this.loading = searchEventResults.loading;

        this.initialized = searchEventResults.initialized;

        this.eventList = searchEventResults.results;

        this.hasResults = pureComputed(this.hasEventResults, this);

        this.listClass = pureComputed(this.getListClass, this);

        this.componentClass = pureComputed(() =>
            eventsTileView.includes(this.customizationParams.contentParams.jobDisplayStyle())
                ? 'events-grid'
                : 'events-list'
        );

        this.sortOptions = pureComputed(() => getEventsSortingOptions(router.routeParams()));

        const [firstSortOption]: SortOption[] = this.sortOptions();

        this.selectedSortOption = pureComputed(
            () => getEventsSelectedSortOption(searchEventResults.sortBy() as string) || firstSortOption
        );

        this.customCss = pureComputed(() => getCustomStyles(this.customizationParams, uniqueWrapperClass));

        this.paginationState = pureComputed(() =>
            getPaginationState({
                hasMore: searchEventResults.hasMore,
                loading: searchEventResults.appending,
                loadMore: () => this._loadMore.bind(this),
                isInfiniteScrollEnabled: this.customizationParams.contentParams.enableInfiniteScroll(),
                isMapView: observable<boolean>(false),
            })
        );

        this.noSearchResultsParams = {
            context: SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS,
            ctaRoute: SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS,
            dataQaAttr: 'viewAllEventsBtn',
            beforeRedirect: () => {
                clearSearchParams();

                return Promise.resolve(true);
            },
        };
    }

    _loadMore(): void {
        const { isSignedIn } = signedCandidate;
        const areEventEnabled = areEventsEnabled();

        let { query } = router.routeParams() as { query: RouterQuery };

        if (!query?.sortBy) {
            query = {
                ...query,
                sortBy: searchEventResults.sortBy(),
            };
        }

        searchService._loadMoreEvents(
            {
                limit: this.customizationParams.contentParams.numberOfJobsDisplayed(),
                ...query,
            },
            Boolean(isSignedIn()),
            areEventEnabled
        );
    }

    dispose(): void {
        this.loading = null;
        this.initialized = null;
        this.eventList = null;
    }

    private hasEventResults(): boolean | null {
        return !!this.initialized && this.initialized() && !!this.eventList && this.eventList().length > 0;
    }

    private getListClass(): string {
        return [this.customizationParams.commonParams.cssClass(), this.componentClass()]
            .filter((value) => Boolean(value))
            .join(' ');
    }

    onChangeSortBy(sortOption: SortOption): void {
        searchEventResults.sortBy(buildSortOption(sortOption));

        const query = getStoredQuery(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS);
        const eventsSearchQuery = new SearchQueryBuilder(query).withSortOption(sortOption).build();

        saveSearchContextParamInStorage(SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS, {
            query: eventsSearchQuery,
        } as RouterParams);

        router.go(
            'search-events',
            {
                query: eventsSearchQuery,
            },
            { inherit: true }
        );
    }
}
