import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import { URL_INVALID } from 'candidate-verification/config/candidateVerificationStatus';
import CandidateChallengeAbstractViewModel from 'candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import { isPinCodeSent } from 'candidate-verification/service/shortTokenStorage';
import referralService from 'candidate-verification/service/referral';
import * as STATES from 'candidate-verification/config/pinVerificationStatus';
import { handleChallengeFlag } from 'candidate-verification/service/triggerChallenge';
import { observable } from 'knockout';

export default class ApplyFlowPinVerificationViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.jobId = router.routeParams().jobId;

        this.verificationStatus(STATES.IN_PROGRESS);

        this.isReady = observable(false);

        if (!isPinCodeSent()) {
            this.isReady(true);

            this._triggerChallenge()
                .then(this._onTriggeredChallenge.bind(this))
                .catch(this._handleError.bind(this));
        } else {
            handleChallengeFlag(true)
                .then(() => this.verificationStatus(STATES.FILL_IN))
                .finally(() => this.isReady(true));
        }
    }

    async onPinValid() {
        const { referralId } = tokenService.get();

        if (referralId) {
            referralService.captureReferralId(tokenService.get());

            await referralService.updateReferralHitCount(referralId, this.jobId)
                .catch(this._handleError);
        }

        router.go('apply-flow', { sectionNumber: '1' });
    }

    _onTriggeredChallenge() {
        this.handleMergedCandiate();
        this.verificationStatus(STATES.FILL_IN);
    }

    _handleError(error) {
        if (error === URL_INVALID) {
            tokenService.destroy();
            router.go('job-details.email-verification');

            return;
        }

        if (error === STATES.ATTEMPS_LIMIT_REACHED || error === STATES.PIN_LIMIT_REACHED) {
            this.verificationStatus(STATES.ATTEMPS_LIMIT_REACHED);

            return;
        }

        super._handleError(error);
    }

}
