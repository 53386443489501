import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class MaxLengthFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.value-exceeds-length';

        ko.utils.extend(this._options, {
            max_value: this._options.max,
        });
    }

    _validate(value, options) {
        return value ? String(value).length <= options.max : true;
    }

}