import httpCached from 'core/http/httpCached';
import storageService from 'core/storage/sessionStorage';
import candidateVerificationEvents from 'candidate-verification/config/events';
import referralMapper from 'candidate-verification/mapper/referral';
import referrerService from '../../source-tracking/service/referrer';

const REFERRAL_KEY = 'referral';
const REFERRAL_ID_KEY = 'referralId';

export default {
    get(referralId) {
        return httpCached.get(`/recruitingCEReferrals/${referralId}`);
    },

    getResume(referralId) {
        return httpCached.get(`/recruitingCEReferrals/${referralId}/child/attachments`).then(referralMapper.mapResumeFromRest);
    },

    getReferralId(requisitionNumber) {
        const hitCountResponse = this.getHitCountResponse(requisitionNumber);
        const referralId = storageService.restore(REFERRAL_ID_KEY);

        if (referralId && hitCountResponse && hitCountResponse.referralId === referralId) {
            return referralId;
        }

        return undefined;
    },

    captureReferralId(verificationToken) {
        if (!verificationToken.referralId) {
            return Promise.reject('referral-removed');
        }

        storageService.store(REFERRAL_ID_KEY, verificationToken.referralId);

        return verificationToken;
    },

    restoreReferralId() {
        return storageService.restore(REFERRAL_ID_KEY);
    },

    updateReferralHitCount(token, requisitionNumber) {
        return this._updateHitCount({
            referralId: token,
            requisitionNumber,
            source: referrerService.get(),
        });
    },

    updateShareHitCount(token, requisitionNumber) {
        return this._updateHitCount({
            shareId: token,
            requisitionNumber,
            source: referrerService.get(),
        });
    },

    _updateHitCount(data) {
        return httpCached
            .post('/recruitingCEReferralHits', data, {
                statusCodes: {
                    500(err) {
                        return Promise.reject(err);
                    },
                },
                credentials: 'same-origin',
            })
            .then(referralMapper.mapUpdateHitCount.bind(referralMapper))
            .then((response) => {
                storageService.store(REFERRAL_KEY, response);
                candidateVerificationEvents.hitCounterUpdated.dispatch();

                return response;
            }).catch((err) => {
                console.error(err);
            });
    },

    getHitCountResponse(requisitionNumber) {
        const hitCountResponse = storageService.restore(REFERRAL_KEY);

        if (hitCountResponse && hitCountResponse.requisitionNumber === requisitionNumber) {
            return hitCountResponse;
        }

        return undefined;
    },

    destroyHitCountResponse(requisitionNumber) {
        const hitCountResponse = this.getHitCountResponse(requisitionNumber);

        if (hitCountResponse) {
            storageService.remove(REFERRAL_ID_KEY);
            storageService.remove(REFERRAL_KEY);
            candidateVerificationEvents.hitCounterUpdated.dispatch();
        }
    },
};