import http from 'core/http/http';
import scheduleMapper from '../mapper/interviewScheduleDetail';
import interviewMapper from '../mapper/interviewDetail';
import interviewActionMapper from '../mapper/interviewAction';

export default {
    getInterviewScheduleDetail({ requestId, scheduleId, submissionId, action }) {
        // Handles the legacy interview requests.
        if (!requestId) {
            return http.get(`/recruitingCEInterviewScheduleDetails?finder=findByScheduleId;ScheduleId=${scheduleId},SubmissionId=${submissionId}&expand=Interviews,ScheduleParticipantsProgrammatic&onlyData=true&limit=500`)
                .then(response => scheduleMapper.mapFromRest(response, action));
        }

        return http.get(`/recruitingCEInterviewScheduleDetails?finder=findByScheduleId;ScheduleId=${scheduleId},SubmissionId=${submissionId},InterviewRequestId=${requestId}&expand=Interviews,ScheduleParticipantsProgrammatic&onlyData=true&limit=500`)
            .then(response => scheduleMapper.mapFromRest(response, action));
    },
    getPreScheduleDetail(scheduleId) {
        if (!scheduleId) {
            return Promise.resolve(null);
        }

        return http.get(`/recruitingCEInterviewScheduleDetails/${scheduleId}/enclosure/PreScheduleDetails`)
            .catch(() => Promise.resolve());
    },
    getInterviewDetail(interviewId, submissionId, action) {
        const finderParams = {
            InterviewId: interviewId,
        };

        if (submissionId) {
            finderParams.SubmissionId = submissionId;
        }

        return http.get('/recruitingCEInterviewDetails?finder=findByInterviewId;:finderParams:&expand=ScheduleInterviewParticipants&onlyData=true', {
            params: { finderParams },
            paramsDelimiter: ',',
        }).then(response => interviewMapper.mapFromRestArray(response, action));
    },
    getPostScheduleDetail(scheduleId) {
        return http.get(`/recruitingCEInterviewScheduleDetails/${scheduleId}/enclosure/PostScheduleDetails`)
            .catch(() => Promise.resolve());
    },
    getCandidateNotes(interviewId) {
        return http.get(`/recruitingCEInterviewDetails/${interviewId}/enclosure/CandidateNotes`)
            .catch(() => Promise.resolve());
    },
    getLocationDetail(interviewId) {
        return http.get(`/recruitingCEInterviewDetails/${interviewId}/enclosure/LocationDetails`)
            .catch(() => Promise.resolve());
    },
    scheduleInterview(requestId, interviewId, submissionId) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapScheduleActionToRest(requestId, interviewId, submissionId))
            .then(interviewActionMapper.mapActionFromRest);
    },
    cancelInterview(requestId) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapCancelActionToRest(requestId))
            .then(interviewActionMapper.mapActionFromRest);
    },
    reScheduleInterview(requestId, interviewId) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapRescheduleActionToRest(requestId, interviewId))
            .then(interviewActionMapper.mapActionFromRest);
    },
    scheduleAutoSlotInterview({ requestId, scheduleId, submissionId, startDate, endDate }) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapAutoSlotScheduleActionToRest({
                requestId,
                scheduleId,
                submissionId,
                startDate,
                endDate,
            }))
            .then(interviewActionMapper.mapActionFromRest);
    },
    reScheduleAutoSlotInterview({ requestId, submissionId, startDate, endDate }) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapAutoSlotRescheduleActionToRest({
                requestId,
                submissionId,
                startDate,
                endDate,
            }))
            .then(interviewActionMapper.mapActionFromRest);
    },
    requestMoreSlots(requestId, comments) {
        return http.post('/recruitingCEInterviewActions',
            interviewActionMapper.mapRequestMoreSlotsToRest(requestId, comments))
            .then(interviewActionMapper.mapActionFromRest);
    },
};