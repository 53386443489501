import storageService from 'core/storage/sessionStorage';

const DATE_OF_BIRTH_STORAGE_KEY = 'dateOfBirth';

export const saveDateOfBirth = (dateOfBirth: Date): void => {
    storageService.store(DATE_OF_BIRTH_STORAGE_KEY, dateOfBirth);
};

export const removeDateOfBirth = (): void => {
    storageService.remove(DATE_OF_BIRTH_STORAGE_KEY);
};

export const isDateOfBirthExists = (): boolean => {
    return storageService.exists(DATE_OF_BIRTH_STORAGE_KEY);
};

export const getDateOfBirth = (): Date => {
    return storageService.restore(DATE_OF_BIRTH_STORAGE_KEY);
};
