import { notReachable } from 'app/types/utils';
import { SearchRoute, SearchResultContextComponent } from 'cx/module/search/config/types';

export const getSearchResultContextComponent = (routerID: SearchRoute): SearchResultContextComponent => {
    switch (routerID) {
        case 'search':
        case 'site-editor-search-editor':
        case 'site-editor-content-translations-lang':
        case 'job-preview':
        case 'job-formatting-preview':
        case 'fake-job-details':
        case 'site-editor-content':
        case 'cc-page':
            return 'search-job-results';

        case 'search-events':
        case 'event-preview':
            return 'search-event-results';

        default:
            return notReachable(routerID);
    }
};
