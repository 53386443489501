import {
    desktopOnly,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { notReachable } from 'app/types/utils';
import { SearchResultsParams } from 'minimal/module/search/component/search-results/config/types';
import { getSearchResultSelectors } from './selectors';
import { defaultSearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { getParamsValueMergeFunction } from 'minimal/module/search/service/observableParams/getParamsValueMergeFunction';
import { mergeObservableParams } from 'minimal/module/search/service/observableParams/mergeObservableParams';
import { getJobDisplayStyle } from 'minimal/module/search/component/search-job-results/config/getComponentClass';

export const getCustomStyles = (params: SearchResultsParams, uniqueWrapperClass: string): string => {
    const jobDisplayStyle = getJobDisplayStyle(params.contentParams.jobDisplayStyle());
    const selectors = getSearchResultSelectors(`.${uniqueWrapperClass}`)[jobDisplayStyle];

    const customizationKeys = Object.keys(params) as (keyof SearchResultsParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'contentParams':
                return desktopOnly(
                    getMaxWidthCss({
                        selector: selectors.container,
                        width: params[key].width(),
                        widthUnit: params[key].widthUnit(),
                    })
                );

            case 'headerTypography':
                return getTypographyCss({
                    selector: selectors.itemTitle,
                    typography: params[key],
                });

            case 'headerTypographyOnHover':
                const headerTypographyOnHover = mergeObservableParams({
                    sourceParams: params.headerTypography,
                    targetParams: params.headerTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.headerTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemTitleOnHover,
                    typography: headerTypographyOnHover,
                });

            case 'subHeaderTypography':
                return getTypographyCss({
                    selector: selectors.itemSubHeaderTitle,
                    typography: params[key],
                });

            case 'subHeaderTypographyOnHover':
                const subHeaderTypographyOnHover = mergeObservableParams({
                    sourceParams: params.subHeaderTypography,
                    targetParams: params.subHeaderTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.subHeaderTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemSubHeaderOnHover,
                    typography: subHeaderTypographyOnHover,
                });

            case 'jobInfoValueTypography':
                return getTypographyCss({
                    selector: selectors.jobInfoValue,
                    typography: params[key],
                });

            case 'jobInfoValueTypographyOnHover':
                const jobInfoValueTypographyOnHover = mergeObservableParams({
                    sourceParams: params.jobInfoValueTypography,
                    targetParams: params.jobInfoValueTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobInfoValueTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobInfoValueOnHover,
                    typography: jobInfoValueTypographyOnHover,
                });

            case 'jobInfoLabelTypography':
                return getTypographyCss({
                    selector: selectors.jobInfoLabel,
                    typography: params[key],
                });

            case 'jobInfoLabelTypographyOnHover':
                const jobInfoLabelTypographyOnHover = mergeObservableParams({
                    sourceParams: params.jobInfoLabelTypography,
                    targetParams: params.jobInfoLabelTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobInfoLabelTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobInfoLabelOnHover,
                    typography: jobInfoLabelTypographyOnHover,
                });

            case 'applyButton':
                return getTypographyCss({
                    selector: selectors.applyNowButtonContainer,
                    typography: params[key],
                });
            case 'applyButtonOnHover':
                const applyButtonOnHover = mergeObservableParams({
                    sourceParams: params.applyButton,
                    targetParams: params.applyButtonOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.applyButtonOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.applyNowButtonContainerOnHover,
                    typography: applyButtonOnHover,
                });

            case 'jobTagsTypography':
                return getTypographyCss({
                    selector: selectors.jobTagBox,
                    typography: params[key],
                });

            case 'jobTagsTypographyOnHover':
                const jobTagsTypographyOnHover = mergeObservableParams({
                    sourceParams: params.jobTagsTypography,
                    targetParams: params.jobTagsTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobTagsTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobTagBoxOnHover,
                    typography: jobTagsTypographyOnHover,
                });

            case 'alreadyAppliedJobTag':
                return getTypographyCss({
                    selector: selectors.alreadyAppliedTagBox,
                    typography: params[key],
                });

            case 'alreadyAppliedJobTagOnHover':
                const alreadyAppliedJobTagOnHover = mergeObservableParams({
                    sourceParams: params.alreadyAppliedJobTag,
                    targetParams: params.alreadyAppliedJobTagOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.alreadyAppliedJobTagOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.alreadyAppliedTagBoxOnHover,
                    typography: alreadyAppliedJobTagOnHover,
                });

            case 'descriptionTypography':
                return getTypographyCss({
                    selector: selectors.itemDescription,
                    typography: params[key],
                });

            case 'descriptionTypographyOnHover':
                const descriptionTypographyOnHover = mergeObservableParams({
                    sourceParams: params.descriptionTypography,
                    targetParams: params.descriptionTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.headerTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemDescriptionOnHover,
                    typography: descriptionTypographyOnHover,
                });
            case 'descriptionTitleTypography':
                return getTypographyCss({
                    selector: selectors.itemDescriptionTitle,
                    typography: params[key],
                });

            case 'descriptionTitleTypographyOnHover':
                const descriptionTitleTypographyOnHover = mergeObservableParams({
                    sourceParams: params.descriptionTitleTypography,
                    targetParams: params.descriptionTitleTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.descriptionTitleTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemDescriptionTitleOnHover,
                    typography: descriptionTitleTypographyOnHover,
                });

            case 'sortByTypography':
                return getTypographyCss({
                    selector: selectors.itemSortBy,
                    typography: params[key],
                });

            case 'sortOptionsTypography':
                return getTypographyCss({
                    selector: selectors.itemSortOptions,
                    typography: params[key],
                });

            case 'sortOptionsTypographyOnHover':
                const sortOptionsTypographyOnHover = mergeObservableParams({
                    sourceParams: params.sortOptionsTypography,
                    targetParams: params.sortOptionsTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.sortOptionsTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemSortOptionsOnHover,
                    typography: sortOptionsTypographyOnHover,
                });

            case 'selectedSortValueTypography':
                return getTypographyCss({
                    selector: selectors.itemSelectedSortValue,
                    typography: params[key],
                });

            case 'selectedSortValueTypographyOnHover':
                const selectedSortValueTypographyOnHover = mergeObservableParams({
                    sourceParams: params.selectedSortValueTypography,
                    targetParams: params.selectedSortValueTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.selectedSortValueTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemSelectedSortValueOnHover,
                    typography: selectedSortValueTypographyOnHover,
                });

            case 'selectedSortOptionTypography':
                return getTypographyCss({
                    selector: selectors.itemSelectedSortOption,
                    typography: params[key],
                });

            case 'container':
                return getStylingBoxCss({
                    selector: selectors.root,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'tileStyles':
                return getStylingBoxCss({
                    selector: selectors.item,
                    stylingBox: params[key],
                    lightIconSelector: selectors.itemIcon,
                    darkIconSelector: null,
                });

            case 'tileStylesOnHover':
                const tileStylesOnHover = mergeObservableParams({
                    sourceParams: params.tileStyles,
                    targetParams: params.tileStylesOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.tileStylesOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.itemOnHover,
                    stylingBox: tileStylesOnHover,
                    lightIconSelector: selectors.itemIconOnHover,
                    darkIconSelector: null,
                });

            case 'sortByStyling':
                return getStylingBoxCss({
                    selector: selectors.itemSortByDropdown,
                    stylingBox: params[key],
                    lightIconSelector: selectors.itemSortByIcon,
                    darkIconSelector: null,
                });

            case 'sortByHoverStyling':
                const sortByHoverStyling = mergeObservableParams({
                    sourceParams: params.sortByStyling,
                    targetParams: params.sortByHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.sortByHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.itemSortByIcon,
                    stylingBox: sortByHoverStyling,
                    lightIconSelector: selectors.itemSortByIconOnHover,
                    darkIconSelector: null,
                });

            case 'sortByDropdownStyling':
                return getStylingBoxCss({
                    selector: selectors.itemSortByDropdown,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'tileHeaderStyles':
                return selectors.tileHeader
                    ? getStylingBoxCss({
                          selector: selectors.tileHeader,
                          stylingBox: params[key],
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';

            case 'tileHeaderStylesOnHover':
                const tileHeaderStylesOnHover = mergeObservableParams({
                    sourceParams: params.tileHeaderStyles,
                    targetParams: params.tileHeaderStylesOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.tileHeaderStylesOnHover,
                    }),
                });

                return selectors.tileHeaderOnHover
                    ? getStylingBoxCss({
                          selector: selectors.tileHeaderOnHover,
                          stylingBox: tileHeaderStylesOnHover,
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';

            case 'jobIconsStyles':
                return getStylingBoxCss({
                    selector: selectors.jobTagBox,
                    stylingBox: params[key],
                    lightIconSelector: selectors.jobIconsStyles,
                    darkIconSelector: null,
                });

            case 'jobIconsStylesOnHover':
                const jobIconsStylesOnHover = mergeObservableParams({
                    sourceParams: params.jobIconsStyles,
                    targetParams: {
                        ...params.jobIconsStylesOnHover,
                        color: params.subHeaderTypographyOnHover.color,
                    },
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobIconsStylesOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.jobTagBoxOnHover,
                    stylingBox: jobIconsStylesOnHover,
                    lightIconSelector: selectors.jobIconsStylesOnHover,
                    darkIconSelector: null,
                });

            case 'alreadyAppliedTagBox':
                return getStylingBoxCss({
                    selector: selectors.alreadyAppliedTagBox,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'alreadyAppliedTagBoxOnHover':
                const alreadyAppliedTagBoxOnHover = mergeObservableParams({
                    sourceParams: params.alreadyAppliedTagBox,
                    targetParams: params.alreadyAppliedTagBoxOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.alreadyAppliedTagBoxOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.alreadyAppliedTagBoxOnHover,
                    stylingBox: alreadyAppliedTagBoxOnHover,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'defaultToggleIconStyles':
                return getStylingBoxCss({
                    selector: selectors.defaultToggleIconStyles,
                    stylingBox: params[key],
                    lightIconSelector: selectors.defaultToggleIconStyles,
                    darkIconSelector: null,
                });

            case 'defaultToggleIconStylesOnHover':
                return getStylingBoxCss({
                    selector: selectors.defaultToggleIconStylesOnHover,
                    stylingBox: params[key],
                    lightIconSelector: selectors.defaultToggleIconStylesOnHover,
                    darkIconSelector: null,
                });

            case 'selectedToggleIconStyles':
                return getStylingBoxCss({
                    selector: selectors.selectedToggleIconStyles,
                    stylingBox: params[key],
                    lightIconSelector: selectors.selectedToggleIconStyles,
                    darkIconSelector: null,
                });

            case 'selectedToggleIconStylesOnHover':
                return getStylingBoxCss({
                    selector: selectors.selectedToggleIconStylesOnHover,
                    stylingBox: params[key],
                    lightIconSelector: selectors.selectedToggleIconStylesOnHover,
                    darkIconSelector: null,
                });

            case 'jobInfoIconsStyles':
                return getStylingBoxCss({
                    selector: selectors.jobInfoIcon,
                    stylingBox: params[key],
                    lightIconSelector: selectors.jobInfoIcon,
                    darkIconSelector: null,
                });

            case 'jobInfoIconsStylesOnHover':
                return getStylingBoxCss({
                    selector: selectors.jobInfoIconOnHover,
                    stylingBox: params[key],
                    lightIconSelector: selectors.jobInfoIconOnHover,
                    darkIconSelector: null,
                });

            case 'applyButtonBox':
                return getStylingBoxCss({
                    selector: selectors.applyNowButton,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'applyButtonBoxContainer':
                return getStylingBoxCss({
                    selector: selectors.applyNowButtonContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'applyButtonBoxOnHover':
                const applyButtonBoxOnHover = mergeObservableParams({
                    sourceParams: params.applyButtonBox,
                    targetParams: {
                        ...params.applyButtonBoxOnHover,
                        color: params.applyButtonBoxOnHover.color,
                    },
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.applyButtonBoxOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.applyNowButtonOnHover,
                    stylingBox: applyButtonBoxOnHover,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    });

    return customStyles.filter((style) => style.trim().length).join(STYLES_SEPARATOR);
};
