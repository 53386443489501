import candidateModel from 'apply-flow/model/candidate';
import ApplyFlowBlockComponentViewModel from 'apply-flow/module/common/ApplyFlowBlockComponentViewModel';
import personalInformationFormProvider from './form/personalInformationFormProvider';
import MetadataService from './service/Metadata';
import cachedJob from 'cx/module/apply-flow/service/cachedJob';
import router from 'app/model/router';

const DEFAULT_FIELDS_STATE = {
    includeUniversalNameFields: true,
    includeEmailPhoneFields: true,
    editMode: false,
    isTCFlow: false,
};

export default class PersonalInformationNameViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        const { additionalData, metadataServiceUrl } = params;

        this.additionalData = Object.assign({}, DEFAULT_FIELDS_STATE, additionalData || {});

        this.additionalData.isTCFlow = params.flow.flowTypeCode === 'ORA_TALENT'
            && !this.additionalData.editMode;

        this._metadataService = new MetadataService(metadataServiceUrl);

        this._fetchFormElements()
            .then(this._composeFormConfig.bind(this))
            .then(this._createForm.bind(this))
            .then(this._registerForm.bind(this))
            .then(this._setBlockReady.bind(this))
            .catch(err => console.error(err));
    }

    _fetchFormElements() {
        return this._metadataService.getFormElements();
    }

    _composeFormConfig(elements) {
        return Promise.resolve({ elements });
    }

    async _createForm(formElements) {
        const { includeEmailPhoneFields, includeUniversalNameFields, editMode, isTCFlow } = this.additionalData;

        const formParams = {
            elements: formElements.elements,
            includeUniversalFields: includeUniversalNameFields,
            includeContactFields: includeEmailPhoneFields,
            candidate: candidateModel,
            editMode,
            isTCFlow,
        };

        const { jobId } = router.routeParams();

        if (jobId) {
            const jobDetails = await cachedJob.getJob(jobId)
                .catch(() => {});

            formParams.preferredTerritoryCode = jobDetails?.primaryLocationCountry;
        }

        const form = personalInformationFormProvider.getForm(formParams);

        form.registerModel(candidateModel.basicInformation);

        this.form(form);
    }

}
