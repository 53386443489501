import ko from 'knockout';
import i18n from 'core/i18n/i18n';

export default class LocationPickerViewModel {

    constructor({ element, collection }) {
        this.element = element;
        this.selectedLocations = ko.observableArray();

        this._createSelectedLocations(collection());

        this.collectionSub = collection.subscribe(updatedCollection =>
            this._createSelectedLocations(updatedCollection));

        this.valueSub = this.element.value.subscribe((value) => {
            if (value) {
                this._addLocation(value);
            }
        });

        this.isCollectionEmpty = ko.pureComputed(() =>
            this.selectedLocations()
                .filter(location => location.label())
                .length === 0,
        );
    }

    _isLocationNotSelected(locationCode) {
        const isAlreadyChosen = this.selectedLocations().some(location => location.locationCode() === locationCode);

        return isAlreadyChosen ? Promise.reject() : Promise.resolve();
    }

    _addLocation(locationCode) {
        if (!locationCode) {
            return;
        }

        this._isLocationNotSelected(locationCode)
            .then(() => Promise.all([
                this._resolveLocationToUpdate(),
                this.element.getLabel(locationCode),
            ])
                .then((result) => {
                    const [selectedLocation, label] = result;

                    selectedLocation.label(label);
                    selectedLocation.locationCode(locationCode);

                    this._togglePicker();
                    this.element.value(null);
                }));
    }

    _getEmptyLocation() {
        return ko.utils.arrayFirst(this.selectedLocations(),
            selectedOption => !selectedOption.locationCode());
    }

    _resolveLocationToUpdate() {
        const firstEmpty = this._getEmptyLocation();

        if (firstEmpty) {
            return Promise.resolve(firstEmpty);
        }

        return Promise.reject();
    }

    _createSelectedLocations(collection) {
        const locations = collection.map((item) => {
            const selectedLocation = this._createSelectedLocation(item);

            this.element.getLabel(selectedLocation.locationCode())
                .then(locationLabel => selectedLocation.label(locationLabel));

            return selectedLocation;
        });

        this.selectedLocations(locations);

        this._togglePicker();
    }

    _createSelectedLocation(value) {
        return {
            label: ko.observable(),
            locationCode: value,
        };
    }

    removeLocation(selectedLocation) {
        selectedLocation.label(null);
        selectedLocation.locationCode(null);

        this._togglePicker();
    }

    getRemoveLocationTitle({ label }) {
        const removeTranslation = i18n('apply-flow.profile-item.remove-item-button');

        return `${removeTranslation} ${label()}`;
    }

    _togglePicker() {
        const firstEmpty = this._getEmptyLocation();
        const isDisabled = Boolean(!firstEmpty);

        this.element.isDisabled(isDisabled);
    }

    dispose() {
        this.valueSub.dispose();
        this.collectionSub.dispose();
    }

}