import http from 'core/http/http';
import middleware from 'core/http/middleware';
import httpMapper from 'core/http/mapper';
import storageService from 'core/storage/sessionStorage';
import urlInterpolator from './urlInterpolator';

export default Object.assign({}, http, {
    get(url, config = {}) {
        const interpolatedUrl = urlInterpolator.interpolate(url, config.params, config.paramsDelimiter);

        if (storageService.exists(interpolatedUrl)) {
            const response = storageService.restore(interpolatedUrl);

            middleware.apply(JSON.parse(httpMapper.mapToRest(response)), { url, ...config });

            return Promise.resolve(response);
        }

        return http.get(url, config).then((response) => {
            storageService.store(interpolatedUrl, response);

            return response;
        });
    },
});