import { observable } from 'knockout';
import router from 'app/model/router';
import localeUnitOfLength from 'search/model/localeUnitOfLength';
import modeService, { MODES, DEFAULT_MODE } from 'search/module/location/module/mode-menu/service/mode';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { isZipcodeSearchEnabled, isGeolocationSearchEnabled, isSearchByRecruitingLocationsModeEnabled } from 'cx/module/search/module/location/config/locationSearchConfig';
import recruitingLocationService from 'app/module/cx/module/recruiting-location/service/recruitingLocation';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import { emptySearchBarParams } from 'minimal/module/search/component/search-bar/config/defaultParams';
import { updateSearchContextStorageDetails } from '../../service/storeSearchContextDetails';
import { searchRouteMap } from 'app/module/cx/module/search/config/types';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import * as spellCheckService from 'minimal/module/search/service/spellCheck';

const DEFAULT_RADIUS_VALUE = 0;
const DEFAULT_RADIUS_UNIT = localeUnitOfLength.getShort(navigator.language.toLowerCase()) || 'KM';

const LOCATION_INPUT_LABELS = {
    [MODES.LOCATION]: 'location-bar.dropdown.location-input-label',
    [MODES.ZIPCODE]: 'location-bar.zipcode-placeholder',
    [MODES.RECRUITING_LOCATION]: 'location-bar.dropdown.recruiting-location-placeholder',
    [MODES.GEO_LOCATION]: 'location-bar.button-placeholder',
};

// TODO: add inheritance from CustomComponentViewModel
export default class SearchBoxCompactViewModel {

    constructor({ params, isAdminMode = false, id = 'default' }) {
        this.customizationCompactParams = params;
        this.isCustomSearch = observable(false);
        this.isAdminMode = isAdminMode;
        this.searchKeywordLabel = areEventsEnabled() ? 'search.keyword-label-events' : 'search.keyword-label';
        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.searchTooltipLabel = areEventsEnabled() ? 'search.search-jobs-events-button' : 'search.search-button';
        this.locationInputLabel = observable(LOCATION_INPUT_LABELS[DEFAULT_MODE]);

        this.keyword = observable();
        this.location = observable();
        this.radiusValue = observable();
        this.radiusUnit = observable();
        this.zipcode = observable();
        this.country = observable();
        this.queryBuilder = observable(new SearchQueryBuilder(router.routeParams()?.query));
        this.customizationParams = params || mapParamsConfigurationToObservable(emptySearchBarParams);

        this.isZipcodeEnabled = isZipcodeSearchEnabled();
        this.isGeolocationEnabled = isGeolocationSearchEnabled();

        this.beforeSearch = observable(() => Promise.resolve());

        this.mode = modeService.currentMode;
        this.MODES = MODES;

        this.mode.subscribe(() => this.locationInputLabel(LOCATION_INPUT_LABELS[this.mode()]));

        modeService.onModeChange = () => {
            this._clearParams();
        };

        this.performSearch = this.performSearch.bind(this);
        this.fetchJobLocations = this.fetchJobLocations.bind(this);
        this._setParams();
        this._setDefaultCustomParams();

        if (spellCheckService.isSpellCheckEnabled()) {
            spellCheckService.removeCorrectedKeyword();
            spellCheckService.removeSpellCheckFlag();
        }

        this.routeParamsSubscription = router.routeParams.subscribe(() => {
            this.keywordFromQuery = this._getKeywordFromQuery(router.routeParams()?.query);

            if (this.keyword() !== this.keywordFromQuery) {
                this.keyword(this.keywordFromQuery);
            }

            const locationFromQuery = this._getLocationFromQuery(router.routeParams()?.query);

            if (JSON.stringify(this.location()) !== JSON.stringify(locationFromQuery)) {
                this.location(locationFromQuery);
            }

            const modeFromQuery = router.routeParams()?.query?.mode;

            if (!modeFromQuery) {
                modeService.currentMode(
                    isSearchByRecruitingLocationsModeEnabled()
                        ? MODES.RECRUITING_LOCATION
                        : MODES.LOCATION);
            }

            const radiusUnitFromQuery = router.routeParams()?.query?.radiusUnit;

            if (!radiusUnitFromQuery) {
                this.radiusUnit(DEFAULT_RADIUS_UNIT);
            }
        });
    }

    performSearch() {
        this.queryBuilder(new SearchQueryBuilder(router.routeParams()?.query));

        this.beforeSearch()()
            .then(() => {
                this._buildKeywordQuery();
                this._buildLocationQuery();
                this._buildZipcodeQuery();
                this._addModeToQuery();

                if (areEventsEnabled()) {
                    updateSearchContextStorageDetails(this.queryBuilder().build());
                }

                const searchRoute = router.route().id === searchRouteMap.events
                    ? searchRouteMap.events
                    : searchRouteMap.jobs;

                router.go(searchRoute, { query: this.queryBuilder().build() }, { inherit: false });
            })
            .catch((error) => {
                if (error instanceof Error) {
                    console.error(error);
                }
            });
    }


    _setParams() {
        const { query } = router.routeParams();

        this.keyword(this._getKeywordFromQuery(query));
        this.location(this._getLocationFromQuery(query));
        this.radiusValue(this._getRadiusValueFromQuery(query));
        this.radiusUnit(this._getRadiusUnitFromQuery(query));
        this.country(this._getCountryFromQuery(query));
        this.zipcode(this._getZipcodeFromQuery(query));
    }

    _setDefaultCustomParams() {
        this.isStickySearchBarEnabled = false;
        this.isReady = observable(false);
    }

    _clearParams() {
        this.location({});
        this.country(null);
        this.zipcode(null);
    }

    _buildKeywordQuery() {
        this.queryBuilder(this.queryBuilder().withKeyword(this.keyword()).withoutExactKeyword());
    }

    _buildLocationQuery() {
        if (this.mode() === MODES.ZIPCODE) {
            return;
        }

        const location = this.location();

        const isLocationSet = Boolean(Object.keys(location).length);

        if (!isLocationSet) {
            this.queryBuilder(this.queryBuilder().withoutLocation());
            this.queryBuilder(this.queryBuilder().withoutZipcode());
            this.queryBuilder(this.queryBuilder().withoutCountryCode());
            this.queryBuilder(this.queryBuilder().withoutZipcodeKeyword());

            return;
        }

        this.queryBuilder(this.queryBuilder().withLocation(this.location()));

        const { level, isGeolocation } = location;

        if (level === 'city' || isGeolocation) {
            this.queryBuilder(this.queryBuilder().withRadius(this.radiusValue()));
            this.queryBuilder(this.queryBuilder().withRadiusUnit(this.radiusUnit()));
        } else {
            this.queryBuilder(this.queryBuilder().withoutRadius());
        }

        this.queryBuilder(this.queryBuilder().withoutZipcode());
        this.queryBuilder(this.queryBuilder().withoutCountryCode());
        this.queryBuilder(this.queryBuilder().withoutZipcodeKeyword());
    }

    _buildZipcodeQuery() {
        if (this.mode() !== MODES.ZIPCODE) {
            return;
        }

        const zipcode = this.zipcode();
        const isZipcodeSet = zipcode && Boolean(Object.keys(zipcode).length);

        if (!isZipcodeSet) {
            this.queryBuilder(this.queryBuilder().withoutZipcode());
            this.queryBuilder(this.queryBuilder().withoutCountryCode());
            this.queryBuilder(this.queryBuilder().withoutZipcodeKeyword());
            this.queryBuilder(this.queryBuilder().withoutLocation());
            this.queryBuilder(this.queryBuilder().withoutRadius());

            return;
        }

        const { zipcodeKeyword } = zipcode;

        this.queryBuilder(this.queryBuilder().withLocation(zipcode));
        this.queryBuilder(this.queryBuilder().withRadius(this.radiusValue()));
        this.queryBuilder(this.queryBuilder().withRadiusUnit(this.radiusUnit()));
        this.queryBuilder(this.queryBuilder().withZipcodeKeyword(zipcodeKeyword));

        this.queryBuilder(this.queryBuilder().withoutZipcode());

        this.queryBuilder(this.queryBuilder().withCountryCode(this.country()));
    }

    _addModeToQuery() {
        this.queryBuilder(this.queryBuilder().withMode(this.mode()));
    }

    _getKeywordFromQuery(query) {
        if (!query || !query.keyword) {
            return '';
        }

        return query.keyword;
    }

    _getLocationFromQuery(query) {
        if (!query) {
            return {};
        }

        if (query.locationId) {
            const { location, locationId, locationLevel } = query;

            return { label: location, locationId, level: locationLevel };
        }

        if (query.latitude && query.longitude) {
            const { latitude, longitude, location } = query;

            return { latitude, longitude, label: location, isGeolocation: true };
        }

        return {};
    }

    _getRadiusValueFromQuery(query) {
        if (!query || !query.radius) {
            return DEFAULT_RADIUS_VALUE;
        }

        return parseInt(query.radius, 10) || DEFAULT_RADIUS_VALUE;
    }

    _getRadiusUnitFromQuery(query) {
        if (!query || !query.radiusUnit) {
            return DEFAULT_RADIUS_UNIT;
        }

        return query.radiusUnit;
    }

    _getCountryFromQuery(query) {
        if (query && query.workLocationCountryCode) {
            return query.workLocationCountryCode;
        }

        return null;
    }

    _getZipcodeFromQuery(query) {
        if (!query) {
            return null;
        }

        if (query.workLocationZipCode && query.workLocationCountryCode) {
            return query.workLocationZipCode;
        }

        if (query.zipcode) {
            return query.zipcode;
        }

        return null;
    }

    fetchJobLocations(payload) {
        return recruitingLocationService.getLocationsByTerm(payload);
    }

    dispose() {
        this.routeParamsSubscription.dispose();
    }

}