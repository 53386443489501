import {
    getSelectedSortOption,
    getSortingOptions,
} from 'minimal/module/search/component/search-sorting-selector/service/searchSortOptionsService';
import { SortOption } from 'search/config/types';
import { eventsSortOptions } from 'search/model/eventsSorting';

export const getEventsSortingOptions = ({ query }: Record<string, unknown>): SortOption[] => {
    return getSortingOptions(eventsSortOptions, { query });
};

export const getEventsSelectedSortOption = (sortBy: string): SortOption | null => {
    return getSelectedSortOption(eventsSortOptions, sortBy);
};
