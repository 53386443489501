import TileProfileItemsViewModel from './TileProfileItemsViewModel';
import { unwrap } from 'knockout';
import { PROFILE_ITEM_SERVICE } from 'apply-flow/module/profile-items/service/profileItemServiceConfiguration';

export default class UniqueTileProfileItemsViewModel extends TileProfileItemsViewModel {

    #primaryKey = this._getPrimaryKey();

    editProfileItem(profileItemForm) {
        this._disableAlreadyAssignedContentItemIds(profileItemForm);

        super.editProfileItem(profileItemForm);
    }

    _hideForm(profileItemForm) {
        this._markReAddedItemAsUpdated(profileItemForm);

        return super._hideForm(profileItemForm);
    }

    _getPrimaryKey() {
        return PROFILE_ITEM_SERVICE[this._contentTypeId].primaryKey;
    }

    _disableAlreadyAssignedContentItemIds(profileItemForm) {
        const disabledOptions = this._getOptionsToDisable(profileItemForm);

        const formElement = profileItemForm.getElement('contentItemId');

        formElement.optionsDisabled(disabledOptions);
    }

    _getOptionsToDisable(profileItemForm) {
        const contentItemId = this._getContentItemId(profileItemForm);

        const isCurrentContentItemId = item => item.contentItemId() === contentItemId;
        const hasNoContentItemId = item => !item.contentItemId();

        const isCurrentOrEmptyContentItemId = item =>
            isCurrentContentItemId(item)
            || hasNoContentItemId(item);

        return this.profileItems()
            .filter(item => !isCurrentOrEmptyContentItemId(item))
            .filter(this._hasSameSectionId.bind(this))
            .map(item => item.contentItemId());
    }

    _markReAddedItemAsUpdated(profileItemForm) {
        const contentItemId = this._getContentItemId(profileItemForm);
        const removedItem = this._findPersistentRemovedItem(contentItemId);

        if (!removedItem) {
            return;
        }

        const removedId = removedItem[this.#primaryKey]();
        const profileItem = this._getModel(profileItemForm);

        profileItem[this.#primaryKey](removedId);
        profileItem.action('UPDATE');
    }

    _getContentItemId(profileItemForm) {
        const profileItem = this._getModel(profileItemForm);

        return profileItem.contentItemId();
    }

    _findPersistentRemovedItem(contentItemId) {
        const profileItems = this._getCandidateProfileItems();

        const hasPrimaryKeySameAs = key => item => Boolean(item[key]());
        const hasContentItemIdSameAs = id => item => item.contentItemId() === id;

        return profileItems.getRemovedItems()
            .filter(hasContentItemIdSameAs(contentItemId))
            .filter(this._hasSameSectionId.bind(this))
            .find(hasPrimaryKeySameAs(this.#primaryKey));
    }

    _getModel(profileItemForm) {
        return unwrap(profileItemForm).model();
    }

}