import l10n from 'core/l10n/l10n';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';
import { getLocalTimezone } from 'core/utils/timezoneUtils';

const dateTimeOptions = {
    formatType: 'datetime',
    dateFormat: 'long',
    timeFormat: 'short',
};

const timeOptions = {
    formatType: 'time',
    timeFormat: 'short',
    timeZoneName: 'short',
};

export type EventDateRange = {
    startDate: string;
    endDate: string;
    timeZone: string;
};

export const convertEventDateRange = (startDate: string, endDate: string): EventDateRange => {
    const localTimeZone = getLocalTimezone();
    const convertedStartDate = l10n.dateTime.formatCustom(startDate, dateTimeOptions);
    const endTimeConvertOptions =
        l10n.date.formatShort(startDate) === l10n.date.formatShort(endDate) ? timeOptions : dateTimeOptions;

    return {
        startDate: convertedStartDate,
        endDate: l10n.dateTime.formatCustom(endDate, endTimeConvertOptions),
        timeZone: localTimeZone,
    };
};

export const convertEventRegistrationDate = (registeredOnDate: string): string => {
    const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

    return dateFormatter.formatDate(registeredOnDate);
};
