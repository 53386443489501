import mapping from 'knockout-mapping';


const configPrototype = {
    indeed: {
        code: 'in',
        activeFlag: false,
        clientId: undefined,
        oauthService: undefined,
    },
    linkedin: {
        code: 'li',
        activeFlag: false,
        clientId: undefined,
        oauthService: undefined,
    },
    resumeParser: {
        code: 'rp',
        activeFlag: false,
    },
    awli: {
        code: 'al',
        activeFlag: false,
        clientId: undefined,
        integrationContext: undefined,
    },
};

function ProfileImportConfig(data) {
    mapping.fromJS(configPrototype, {}, this);

    if (data) {
        mapping.fromJS(data, {}, this);
    }
}

export default ProfileImportConfig;