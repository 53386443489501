import { bindingHandlers, utils } from 'knockout';
import { setFocusWithDelay } from 'core/a11y/service/setFocusWithDelay';

/**
 * Binding used to force browser focus to move to
 * the provided element on target click event.
 *
 * @param {string} focusOn - element selector to focus on
 * @param {integer} delay - time in miliseconds after focus will be set - needed in cases where animation is fired
 *
 * @example
 *   <div id="focus-on-me-after-click"></div>
 *   <div data-bind="a11y.setInitialFocus: {focusOn: '#focus-on-me-after-click'}"
 *   <div data-bind="a11y.setInitialFocus: {focusOn: '#focus-on-me-after-click', delay: 400}"
 */

bindingHandlers['a11y.setFocusOnClick'] = {
    after: ['hideAfterAnimation'],
    init(element, accessor) {
        const { focusOn, delay } = accessor();

        function onClick() {
            setFocusWithDelay(focusOn, delay);
        }

        element.addEventListener('click', onClick);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', onClick);
        });
    },
};

