import AbstractMetaItemViewModel from 'cx/module/job-details/module/job-meta-item/component/abstract-meta-item/AbstractMetaItemViewModel';

type Props = {
    name: string;
    value: string;
};

const dummySpamProtector = '<span class="job-meta__spamprotection"></span>@';

export class EmailMetaItemViewModel extends AbstractMetaItemViewModel {
    spamProtectedEmail: string;

    constructor({ name, value }: Props) {
        super({ name, value });
        this.spamProtectedEmail = this.getSpamProtectedEmail(value);
    }

    getSpamProtectedEmail(email: string): string {
        return email.replace('@', dummySpamProtector);
    }
}
