import { observable, pureComputed } from 'knockout';
import notificationsService from 'cx/service/notifications';
import tokenService from 'candidate-verification/service/token';
import applyFlowEvents from 'apply-flow/config/events';
import candidateModel from 'apply-flow/model/candidate';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import candidateService from 'apply-flow/service/candidate';
import profileItemsDefaultBlockFinder from 'apply-flow/module/profile-items/service/defaultBlockFinder';
import profileItemServiceFactory from 'apply-flow/module/profile-items/service/profileItemServiceFactory';
import talentCommunityEvents from '../../config/events';
import talentCommunityFlowService from '../../service/talentCommunityFlow';
import talentCommunityMapper from '../../mapper/talentCommunity';
import { validationNotifications } from 'apply-flow/service/backend-validation/validationNotifications';
import i18n from 'core/i18n/i18n';
import { UNAUTHORIZED } from 'candidate-verification/service/user-session/unauthorized';
import appEvents from 'app/config/events';
import historyService from 'ce-common/service/historyService';
import {
    activateLogger,
    deactivateLogger,
} from 'core/api-logger/config/logger';

const COMBOBOX_SELECTOR = 'oj-combobox-one';

export default class TalentCommunityFlowViewModel {

    constructor() {
        this.flow = observable();
        this.isFlowFieldsVisible = observable(false);
        this.showExpandButton = observable(true);
        this.isSubscribeButtonVisible = observable(true);
        this.isValidationInProgress = observable(false);

        this.toggleFieldsButtonLabel = pureComputed(this._getToogleButtonLabel, this);

        profileItemServiceFactory.initialize();

        this._initialize();

        this.toogleFlowFields = this.toogleFlowFields.bind(this);
        this.openFlowFields = this.openFlowFields.bind(this);
        this._submit = this._submit.bind(this);
        this._submitSucceed = this._submitSucceed.bind(this);
        this._submitFailed = this._submitFailed.bind(this);
        this._profileImportSucceed = this._profileImportSucceed.bind(this);

        applyFlowEvents.submit.add(this._submit);
        applyFlowEvents.submitSucceed.add(this._submitSucceed);
        talentCommunityEvents.submitFailed.add(this._submitFailed);
        applyFlowEvents.profileImportSucceed.add(this._profileImportSucceed);

        talentCommunityEvents.toggleFields.add(this.openFlowFields);

        this.isFlowReady = observable(false);

        this._unauthorizedSignal = appEvents.unauthorizedError.add(() => {
            this._handleUnauthorized(UNAUTHORIZED);
        });

        talentCommunityEvents.updateFailed.add(this._showSubscribeButton);
    }

    toogleFlowFields() {
        this.isFlowFieldsVisible(!this.isFlowFieldsVisible());
    }

    openFlowFields() {
        this.isFlowFieldsVisible(true);
    }

    dragEnterHandler(context, event) {
        event.stopPropagation();
        event.preventDefault();

        applyFlowEvents.dragEnter.dispatch();
    }

    dispose() {
        deactivateLogger();
        this._unRegisterSignalHandlers();
        validationNotifications.clearNotifications();

        if (this.flow()) {
            this.flow().destroy();
        }

        talentCommunityEvents.updateFailed.remove(this._showSubscribeButton);
    }

    flowHasRemainingFields() {
        return this.flow().sections
            .some(hasAtLeastOneBlock);

        function hasAtLeastOneBlock(section) {
            return section.pages
                .some(page => Boolean(page.blocks.length));
        }
    }

    _submit() {
        this.isSubscribeButtonVisible(false);
    }

    _submitSucceed() {
        talentCommunityEvents.hide.dispatch();
    }

    _submitFailed() {
        this._initialize();
    }

    _profileImportSucceed() {
        this.isFlowFieldsVisible(true);
    }

    _getToogleButtonLabel() {
        const showLabel = 'talent-community.flow.show-all-fields';
        const hideLabel = 'talent-community.flow.hide-all-fields';

        return !this.isFlowFieldsVisible() ? showLabel : hideLabel;
    }

    _initialize() {
        if (this.flow()) {
            this.flow().destroy();
        }

        this.isSubscribeButtonVisible(true);

        return Promise.all([
            this._loadFlow(),
            this._loadCandidate(),
        ])
            .then(this._initializeSectionState.bind(this))
            .then(this._initializeSectionValidator.bind(this))
            .then(this._determineDefaultProfileItemsBlock.bind(this))
            .then(this._renderFlow.bind(this))
            .catch(this._onError.bind(this));
    }

    _loadCandidate() {
        const token = tokenService.get();

        if (!token) {
            return Promise.reject(UNAUTHORIZED);
        }

        return candidateService
            .loadForToken(candidateModel, token)
            .catch(() => {
                console.error('Unable to create candidate model.');
            });
    }

    _loadFlow() {
        activateLogger();

        return talentCommunityFlowService.query(talentCommunityMapper);
    }

    _renderFlow(flow) {
        return Promise.resolve(flow)
            .then(this.flow.bind(this));
    }

    _initializeSectionValidator(flow) {
        const sections = flow.sections.concat(
            flow.flowAgreementsSection,
            flow.flowPersonalInformationsSection,
            flow.flowJobAlertPreferencesSection,
        );

        sectionValidator.initialize(sections);

        return flow;
    }

    _initializeSectionState([flow]) {
        const sections = flow.sections.concat(
            flow.flowAgreementsSection,
            flow.flowPersonalInformationsSection,
            flow.flowJobAlertPreferencesSection,
        );

        sectionState.initialize(sections);

        this._setFlowReadySubscription();
        this._setShowMoreFieldsSubscription(flow);

        return flow;
    }

    _determineDefaultProfileItemsBlock(flow) {
        profileItemsDefaultBlockFinder.find(flow);

        return flow;
    }

    _onError(error) {
        if (error === UNAUTHORIZED) {
            this._handleUnauthorized();

            return;
        }

        notificationsService.error();

        console.error(error);

        throw new Error(error);
    }

    _handleUnauthorized() {
        historyService.moveBackOrFallbackTo();
        notificationsService.error(i18n('general.session-expired'));
    }

    _setFlowReadySubscription() {
        sectionState.isAllSectionsReady().subscribeOnce((state) => {
            this.isFlowReady(state);
        });
    }

    _setShowMoreFieldsSubscription(flow) {
        this.isFlowReady.subscribeOnce((state) => {
            if (state && talentCommunityFlowService.shouldShowMoreFieldsSectionBeExpanded(flow.sections)) {
                this.isFlowFieldsVisible(true);
                this.showExpandButton(false);
            }
        });
    }

    _unRegisterSignalHandlers() {
        if (this._unauthorizedSignal) {
            this._unauthorizedSignal.detach();
        }

        Object.keys(applyFlowEvents).forEach((signalName) => {
            applyFlowEvents[signalName].removeAll();
        });
    }

    setInitialFocus($container) {
        $container.find(COMBOBOX_SELECTOR)
            .first()
            .focus();
    }

     _showSubscribeButton = () => this.isSubscribeButtonVisible(true);

}
