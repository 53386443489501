import appConfig from 'app/model/config';
import LegacySearch from './providers/search';
import JerseySearch from './providers/jersey';

function isJerseyEnabled() {
    return appConfig.getSettingByKey('ORA_IRC_CE_USE_JERSEY_ENDPOINT_FOR_REQ_SEARCH') === 'true'
        && appConfig.getSettingByKey('IRC_ELASTIC_SEARCH_ENABLED') === 'true';
}

export function resolveProvider() {
    if (isJerseyEnabled()) {
        return new JerseySearch();
    }

    return new LegacySearch();
}

export default resolveProvider();

