import appEvents from 'app/config/events';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';

appEvents.error.add(() => {
    const message = `${i18n('general.error-message-header')} ${i18n('general.error-message-hint')}`;

    notificationsService.error(message, 0);
});

appEvents.cookiesDisabledError.add(() => {
    const message = i18n('general.cookies.disabled-message');

    notificationsService.error(message, 0);
});

