import ko from 'knockout';

export default class Section {

    constructor(configuration) {
        ko.utils.extend(this, configuration);
        this._beforeExitCallbacks = [];
        this._beforeValidationCallbacks = [];
        this._validationBlockCallbacks = [];
    }

    addBeforeExitCallback(callback) {
        this._beforeExitCallbacks.push(callback);
    }

    exit() {
        this._beforeExitCallbacks.forEach(callback => callback());
    }

    addBeforeValidationCallback(callback) {
        this._beforeValidationCallbacks.push(callback);
    }

    async beforeValidation() {
        return await Promise.all(
            this._beforeValidationCallbacks.map(async callback =>
                await callback()));
    }

    validationBlock() {
        this._validationBlockCallbacks.forEach(callback => callback());
    }

}