import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { PureComputed } from 'knockout';
import { CookieConsentModalTitleCustomizationParams, CookieConsentModalTitleParams } from './config/types';
import { CookieConsentWidgetData } from '../../types';
import { getCustomStylesCookieTitle } from './service/customStyles';
import { getCookieConsentTitleSelectors } from './config/selectors';

type Props = {
    pageData: PureComputed<CookieConsentWidgetData>;
    params?: CookieConsentModalTitleParams;
    id?: string;
};

export class CookieConsentModalTitleViewModel extends CustomComponentViewModel<CookieConsentModalTitleParams> {
    pageData: PureComputed<CookieConsentWidgetData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof CookieConsentModalTitleCustomizationParams): string {
        return getCustomStylesCookieTitle(
            key,
            getCookieConsentTitleSelectors(`.${this.uniqueWrapperClass}`),
            this.customizationParams
        );
    }
}
