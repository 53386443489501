import ko from 'knockout';

const SCROLLED_CLASS = 'body--scrolled-down';

ko.bindingHandlers.bodyScrolledDown = {
    init() {
        const BODY = document.body;

        function scrollHandler() {
            const offset = window.scrollY || window.pageYOffset;

            if (offset > 50) {
                BODY.classList.add(SCROLLED_CLASS);
            } else {
                BODY.classList.remove(SCROLLED_CLASS);
            }
        }

        document.addEventListener('scroll', scrollHandler, { passive: true });
    },
};
