export const CUSTOM_HEADERS_ADDITIONAL_SETTINGS = [
    'buttonsHoverStyling',
    'buttonsSelectedStyling',
    'buttonsStyling',
    'content',
    'emailTypography',
    'linkSelectedTypography',
    'linksHoverStyling',
    'linksHoverTypography',
    'linksSelectedStyling',
    'linksStyling',
    'linksTypography',
    'locationTypography',
    'menuStyling',
    'dropdownStyling',
    'nameTypography',
    'overallStyling',
    'overallStylingHover',
    'sublinksHoverStyling',
    'sublinksHoverTypography',
    'sublinksStyling',
    'sublinksTypography',
    'submenuTypography',
    'submenuHoverTypography',
];
