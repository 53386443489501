import i18n from 'core/i18n/i18n';
import { Observable } from 'knockout';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export const requestSlotsFormBuilder = Object.create(formBuilder, {
    createForm: {
        value(comments: Observable<string>) {
            const form = formBuilder.createForm();

            const formElements = [this._createCommentFormElement(comments)];

            form.elements(formElements);

            return form;
        },
    },

    _createCommentFormElement: {
        value(model: Observable<string>) {
            return formElementFactory
                .create('textarea', 'comments')
                .label(i18n('interview-scheduling.request-slots-confirm.input-comments'))
                .attributes('autocomplete', 'off')
                .attributes('autofocus', 'true')
                .attributes('maxlength', '5000')
                .validators('maxLength', { max: 5000 })
                .validators('required', {})
                .registerModel(model);
        },
    },
});
