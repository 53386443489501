function debouncePromise(func, wait) {
    let timeout;

    let all = [];

    return (...args) => new Promise((resolve) => {
        const later = (...laterArgs) => {
            timeout = null;

            const result = func.apply(this, laterArgs);

            all.forEach(callback => callback(result));

            all = [];
        };

        clearTimeout(timeout);

        all.push(resolve);

        timeout = setTimeout(() => {
            later(...args);
        }, wait);
    });
}

export default debouncePromise;