import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { JobDetailsSimilarJobsContentParams, JobDetailsSimilarJobsCustomizationParams } from './types';
import { EMPTY_BLOCK_STYLES, EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';

export const defaultContentParams: JobDetailsSimilarJobsContentParams = {
    jobDisplayStyle: 'list',
    jobInfoDisplay: 'labelValue',
    jobInfoTagsConfiguration: 'inHeader',
    jobInfoSeparator: 'dot',
    width: 100,
    widthUnit: '%',
    numberOfJobsDisplayed: 4,
    hideMoreJobsButton: false,
    additionalInformationDisplayed: [
        'description',
        'location',
        'postingDate',
        'distance',
        'hotJob',
        'trendingJob',
        'beFirstToApply',
    ],
    headerInformationDisplayed: [],
    jobInfoDisplayed: [],
    jobTagsInformationDisplayed: [],
    mainContentInformationDisplayed: [],
};

export const emptyParams: JobDetailsSimilarJobsCustomizationParams = {
    content: defaultContentParams,

    headerTypography: { ...EMPTY_TYPOGRAPHY },
    headerTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypography: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    descriptionTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    descriptionTypography: { ...EMPTY_TYPOGRAPHY },
    alreadyAppliedJobTag: { ...EMPTY_TYPOGRAPHY },
    jobTagsTypography: { ...EMPTY_TYPOGRAPHY },

    container: { ...EMPTY_BLOCK_STYLES },
    tileStyles: { ...EMPTY_BLOCK_STYLES },
    tileStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    tileHeaderStyles: { ...EMPTY_BLOCK_STYLES },
    tileHeaderStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    alreadyAppliedTagBox: { ...EMPTY_BLOCK_STYLES },
    alreadyAppliedTagBoxOnHover: { ...EMPTY_BLOCK_STYLES },
    jobIconsStyles: { ...EMPTY_BLOCK_STYLES },
    jobIconsStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    defaultToggleIconStyles: { ...EMPTY_BLOCK_STYLES },
    defaultToggleIconStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    selectedToggleIconStyles: { ...EMPTY_BLOCK_STYLES },
    selectedToggleIconStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
