import { components } from 'knockout';
import metadataConfig from 'cx/module/apply-flow/config/metadata';
import 'cx/module/apply-flow/module/file-upload/module/misc-attachments/component/misc-attachment-list-item/component';

import ViewModel from 'apply-flow/module/file-upload/module/misc-attachments/MiscAttachmentsViewModel';
import template from 'apply-flow/module/file-upload/module/misc-attachments/misc-attachments.html';

components.register('misc-attachments', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_MISC_DOCUMENTS', {
    component: 'misc-attachments',
});
