import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { JobDetailsMediaParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-media/config/types';
import { PureComputed } from 'knockout';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsMediaParams;
    id?: string;
    mode?: string;
};

export class JobDetailsMediaViewModel extends CustomComponentViewModel<JobDetailsMediaParams> {
    pageData: PureComputed<JobDetailsPageData>;
    isAdminMode?: boolean;

    constructor({ pageData, mode, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
        this.isAdminMode = mode === 'admin';
    }

    getCustomStyle(key: keyof JobDetailsMediaParams): string {
        switch (key) {
            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
