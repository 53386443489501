import { unwrap } from 'knockout';

export function propertyEquals(property, value) {
    if (typeof property !== 'string') {
        throw new TypeError('First argument must be a string');
    }

    return function checkEquality(object) {
        return unwrap(object[property]) === value;
    };
}
