import HttpBatch from './batch/HttpBatch';
import BatchPartFactory from './batch/partFactory';

export default {
    create() {
        return new HttpBatch(new BatchPartFactory());
    },

    post(batch, url, data, config) {
        return batch.append(url, data, 'POST', config);
    },

    patch(batch, url, data, config) {
        return batch.append(url, data, 'PATCH', config);
    },

    delete(batch, url, config) {
        return batch.append(url, null, 'DELETE', config);
    },
};