import { Observable, observable, PureComputed } from 'knockout';
import appConfig from 'app/model/config';
import googleJobPostingConverter from 'cx/module/job-details/service/googleJobPostingConverter';
import { JobDetailsPageData, JobDetailsViewData } from 'minimal/module/job-details/types';
import { JobDetailsApplyButtonMessage } from 'minimal/module/job-details/component/job-details-content/component/job-details-apply-button/JobDetailsApplyButtonViewModel';

export type JobDetailsContentMessage = JobDetailsApplyButtonMessage;

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    viewData: JobDetailsViewData;
    onMessage: (msg: JobDetailsContentMessage) => void;
};

export default class JobDetailsContentViewModel {
    isGJSEnabled: Observable<boolean>;
    googleJobSearchMarkup: Observable<string>;
    viewData: JobDetailsViewData;
    pageData: PureComputed<JobDetailsPageData>;
    onMessage: (msg: JobDetailsContentMessage) => void;
    overlayVisible: Observable<boolean>;
    classModifier: string;

    constructor({ pageData, viewData, onMessage }: Props) {
        this.pageData = pageData;
        this.viewData = viewData;
        this.onMessage = onMessage;
        this.classModifier = `job-details-${viewData.viewType}`;

        this.isGJSEnabled = observable(
            pageData().job.isConfidential ? false : appConfig.getSettingByKey('IRC_CE_GJS_ENABLED')
        );

        this.googleJobSearchMarkup = observable('');
        this.overlayVisible = this.viewData.isDetailsOpened;
    }

    generateGoogleJobSearchStr(): Promise<string> {
        if (!this.isGJSEnabled()) {
            return Promise.resolve('');
        }

        return googleJobPostingConverter.generateMarkup(this.pageData().job).then(this.googleJobSearchMarkup);
    }
}
