import { components } from 'knockout';

import { CookieConsentModalPolicyViewModel } from './CookieConsentModalPolicyViewModel';
import template from './cookie-consent-modal-policy.html';

components.register('cookie-consent-modal-policy', {
    viewModel: CookieConsentModalPolicyViewModel,
    template,
});

components.register('cc-cookie-consent-modal-policy', {
    viewModel: CookieConsentModalPolicyViewModel,
    template,
});
