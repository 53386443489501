import converterFactory from 'core/l10n/converter/factory';


export default {
    format(number) {
        return converterFactory.createNumberConverter('standard').format(number);
    },

    formatShort(number) {
        return converterFactory.createNumberConverter('short').format(number);
    },

    formatLong(number) {
        return converterFactory.createNumberConverter('long').format(number);
    },

    parse(formattedNumber) {
        return converterFactory.createNumberConverter('standard').parse(formattedNumber);
    },

    parseShort(formattedNumber) {
        return converterFactory.createNumberConverter('short').parse(formattedNumber);
    },

    parseLong(formattedNumber) {
        return converterFactory.createNumberConverter('long').parse(formattedNumber);
    },
};