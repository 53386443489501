import { observable, pureComputed } from 'knockout';
import TooltipFormElementViewModel from 'core/form/component/tooltip-text-form-element/TooltipFormElementViewModel';
import i18n from 'core/i18n/i18n';
import countryCodesService from 'cx/service/phone/phoneCountryCodes';
import { NON_UNIQUE_COUNTRY_CODES } from 'app/module/cx/module/apply-flow/module/personal-information-basic/config/nonUniqueCountryCodes';
import { isLegislationCodeEnabled } from 'app/module/cx/module/apply-flow/module/personal-information-basic/service/isLegislationCodeEnabled';
import { communicationChannelEvents } from 'app/module/cx/module/apply-flow/module/personal-information-basic/config/events';
import profileImportEvents from 'apply-flow/module/profile-import/config/events';

export default class PhoneFormElementViewModel extends TooltipFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.selectedPrefix = observable();
        this.prefixField = this._getPrefixFieldConfig();
        this.phoneField = this._getPhoneFieldConfig();
        this.prefixMatchedAmbiguously = observable(false);
        this.prefixCleared = observable(false);

        this._loadOptions().then((prefix) => {
            this._setInitialValue().then(() => {
                this._selectPrefixOnLoadOptions(prefix);
            });
        });

        this.subscription = this._createValueSubscription();

        this.cssEditSignal = communicationChannelEvents.editInProgress.add(() =>
            this._cssVerifiedPhoneEditButtonAction());

        this.profileImportSignal = profileImportEvents.isPhoneImported.add(
            this._onPhoneNumberImported.bind(this));
    }

    onChangeNumber() {
        this._setValueForElement();
        this.element.isTouched(true);
    }

    onValueAction(e) {
        if (!e.detail.value) {
            e.target.refresh();
        }
    }

    onChangePrefix(event) {
        // Fix for Edge (EdgeHTML) where onChangePrefix is fired before the actual value is changed
        // thus prefix is null when below code is run. Can be JET issue also.
        this.prefixField.value(event.detail.value);

        this.phoneField.value(this.formatNumber());

        if (this.phoneField.value()) {
            this.onChangeNumber();
        }
    }

    formatNumber() {
        return this.element.formatPhoneNumberWithoutCountryCode({
            countryCode: this.selectedPrefix()?.phoneCountryCode,
            number: this.phoneField.value(),
            legislationCode: this.selectedPrefix()?.legislationCode,
        });
    }

    dispose() {
        if (this.subscription) {
            this.subscription.dispose();
            this.subscription = null;
        }
    }

    _createValueSubscription() {
        return this.element.value.subscribe(this.onValueChanged.bind(this));
    }

    onValueChanged(value) {
        if (this.element.rawPhoneNumber) {
            if (this._isEqualToRawNumber(value)) {
                this._setValueForControl(value);
            }

            return;
        }

        this._setValueForControl(value);
    }

    async _setInitialValue() {
        const initialValue = this.element.value();

        if (initialValue) {
            await this._setValueForControl(initialValue);
        }
    }

    _setValueForElement() {
        const newValue = {
            countryCode: this.phoneField.value() ? this.selectedPrefix()?.phoneCountryCode : '',
            number: this.element.formatPhoneNumberWithoutCountryCode({
                countryCode: this.selectedPrefix()?.phoneCountryCode,
                number: this.phoneField.value(),
                legislationCode: this.selectedPrefix()?.legislationCode,
            }),
            legislationCode: this.phoneField.value() ? this.selectedPrefix()?.legislationCode : '',
        };

        this.element.setValue({
            countryCode: newValue.countryCode,
            number: newValue.number,
            legislationCode: newValue.legislationCode,
        });
    }


    async _setValueForControl({ countryCode, areaCode, number, legislationCode }) {
        if (isLegislationCodeEnabled()) {
            if (!legislationCode) {
                await this._processReturningCandidate(countryCode, areaCode, number);
            } else if (this._isPhoneValueFilled(countryCode, areaCode, number)) {
                await this._processNewCandidate(countryCode, legislationCode);
            }
        } else if (this._isPhoneValueFilled(countryCode, areaCode, number)) {
            await this._setPrefix(countryCode, legislationCode);
        }

        this.phoneField.value(this.element.formatPhoneNumberWithoutCountryCode({
            countryCode,
            number: `${areaCode}${number}`,
            legislationCode,
        }));
    }

    async _processReturningCandidate(countryCode, areaCode, number) {
        if (this.element.isDisabled()) {
            await this._setPrefix(countryCode);
        } else if (this._isPhoneValueFilled(countryCode, areaCode, number)) {
            await this._processEnabledFilledElement(countryCode);
        }
    }

    async _processNewCandidate(countryCode, legislationCode) {
        await this._setPrefix(countryCode, legislationCode);
    }

    _isPhoneValueFilled(countryCode, areaCode, number) {
        return countryCode && (areaCode || number);
    }

    _setEmptyPrefix() {
        this.selectedPrefix('');
        this.prefixCleared(true);
    }

    _cssVerifiedPhoneEditButtonAction() {
        if (this.prefixMatchedAmbiguously()) {
            this._setEmptyPrefix();
        }
    }

    async _processEnabledFilledElement(countryCode) {
        if (NON_UNIQUE_COUNTRY_CODES.includes(countryCode)) {
            this._setEmptyPrefix();
        } else {
            await this._setPrefix(countryCode);
        }
    }

    async _setPrefix(countryCode, legislationCode = null) {
        this.selectedPrefix({
            phoneCountryCode: countryCode,
            legislationCode: legislationCode || await this._noLegislationCodeFallback(countryCode),
        });
    }

    _computePhoneNumberPlaceholder() {
        if (this.selectedPrefix() === this.element.getNANPPrefix()) {
            return '(123) 123-1234';
        }

        return i18n('apply-flow.section-personal-information.phone-number-placeholder');
    }

    _getPrefixFieldConfig() {
        return {
            name: 'prefix',
            value: this.selectedPrefix,
            placeholder: i18n('apply-flow.section-personal-information.country-code-placeholder'),
            a11yLabel: `${this.element.label()} ${i18n('apply-flow.section-personal-information.country-code-placeholder')}`,
            options: observable(),
            dropdownId: `country-codes-dropdown${this.element.name()}`,
        };
    }

    _getPhoneFieldConfig() {
        return {
            name: 'phone',
            value: observable(),
            placeholder: pureComputed(this._computePhoneNumberPlaceholder, this),
            a11yLabel: this.element.label(),
        };
    }

    async _loadOptions() {
        return countryCodesService.getAll()
            .then((codes) => {
                this.prefixField.options(codes);

                return countryCodesService.getLocalCountryCode();
            });
    }

    async _selectPrefixOnLoadOptions(prefix) {
        if (this.selectedPrefix() || this.prefixCleared()) {
            return;
        }

        if (prefix) {
            this.selectedPrefix(prefix);

            return;
        }

        const { preferredTerritoryCode } = this.element.attributes();

        if (!preferredTerritoryCode) {
            return;
        }

        const codeFromTerritory = await countryCodesService
            .getPhoneCountryCodeByTerritoryCode(preferredTerritoryCode);

        this.selectedPrefix({
            phoneCountryCode: codeFromTerritory,
            legislationCode: preferredTerritoryCode,
        });
    }

    async _noLegislationCodeFallback(phoneCountryCode) {
        try {
            const legislationCodes = await countryCodesService.getAll();

            const matchingLegislationCodes = legislationCodes.filter(code =>
                code.value.phoneCountryCode === phoneCountryCode);

            if (matchingLegislationCodes.length > 0) {
                this.prefixMatchedAmbiguously(true);
            }

            return matchingLegislationCodes[0]?.value.legislationCode || '';
        } catch (error) {
            console.error(error);

            return '';
        }
    }

    _valueToRawPhoneNumber(value) {
        return {
            countryCode: value.countryCode,
            number: `${value.areaCode}${value.number}`,
            legislationCode: value.legislationCode,
        };
    }

    _isEqualToRawNumber(value) {
        const valueRawNumber = this._valueToRawPhoneNumber(value);

        return this.element.rawPhoneNumber.countryCode === valueRawNumber.countryCode
            && this.element.rawPhoneNumber.number === valueRawNumber.number
            && this.element.rawPhoneNumber.legislationCode === valueRawNumber.legislationCode;
    }

    _onPhoneNumberImported(phoneValue) {
        if (this.element.rawPhoneNumber) {
            this.element.rawPhoneNumber.countryCode = phoneValue.countryCode();

            this.element.rawPhoneNumber.number = phoneValue.areaCode()
                ? `${phoneValue.areaCode()}${phoneValue.number()}`
                : phoneValue.number();

            this.element.rawPhoneNumber.legislationCode = phoneValue.legislationCode();
        }
    }

}
