import { validationNotifications } from './validationNotifications';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';
import { PROFILE_ITEM_SERVICE } from 'apply-flow/module/profile-items/service/profileItemServiceConfiguration';

const CONFIG_MAP = JSON.parse(JSON.stringify(PROFILE_ITEM_SERVICE));

CONFIG_MAP[contentTypes.CERTIFICATION_ID].successMessage = 'IRC-Certification Validation Successful (IRC--1)';
CONFIG_MAP[contentTypes.EDUCATION_ID].successMessage = 'IRC-Education Validation Successful (IRC--1)';
CONFIG_MAP[contentTypes.LANGUAGE_ID].successMessage = 'IRC-Language Validation Successful (IRC--1)';
CONFIG_MAP[contentTypes.WORK_PREFERENCE_ID].successMessage = 'IRC-Workpref Validation Successful (IRC--1)';
CONFIG_MAP[contentTypes.EXPERIENCE_ID].successMessage = 'IRC-PreviousExp Validation Successful (IRC--1)';
CONFIG_MAP[contentTypes.SKILL_ID].successMessage = 'IRC-Skill Validation Successful (IRC--1)';

function isUpdateMode(contentTypeId, restProfileItem) {
    return Boolean(restProfileItem[CONFIG_MAP[contentTypeId].primaryKey]);
}

function getRestEndpoint(contentTypeId, restProfileItem) {
    const { restEndpoint: baseEndpoint } = CONFIG_MAP[contentTypeId];
    const itemId = restProfileItem[CONFIG_MAP[contentTypeId].primaryKey];

    return isUpdateMode(contentTypeId, restProfileItem) ? `${baseEndpoint}/${itemId}` : baseEndpoint;
}

export default {
    validate(contentTypeId, payload) {
        const config = this.getConfig(contentTypeId, payload);

        return validationNotifications.sendAndNotify(config);
    },

    getConfig(contentTypeId, payload) {
        const isUpdate = isUpdateMode(contentTypeId, payload);
        const restEndpoint = getRestEndpoint(contentTypeId, payload);
        const { successMessage } = CONFIG_MAP[contentTypeId];

        return { successMessage, isUpdate, payload, restEndpoint };
    },
};