/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Event } from '../../config/types';
import i18n from 'core/i18n/i18n';
import csEvents from 'cx/module/candidate-self-service/config/events';
// @ts-ignore
import { ojMenuEventMap } from 'ojs/ojmenu';

const eventActionLabelMap: Record<EventActionKey, string> = {
    withdraw: i18n('candidate-self-service.events.withdraw-registration-button-label'),
};

type Props = {
    menuId: string;
    event: Event;
};

type EventActionKey = 'withdraw';

type AriaLabels = Record<EventActionKey, string>;

export default class CsEventContextMenuViewModel {
    menuId: string;
    event: Event;
    ariaLabels: AriaLabels;

    constructor({ menuId, event }: Props) {
        this.menuId = menuId;
        this.event = event;

        this.ariaLabels = {
            withdraw: `${eventActionLabelMap['withdraw']} ${this.event.title}`,
        };
    }

    showWithdrawDialog(): void {
        csEvents.openEventWithdrawDialog.dispatch(this.event);
    }

    triggerMenuItemAction(event: ojMenuEventMap['ojMenuAction']): void {
        switch (event.detail.selectedValue) {
            case 'withdrawRegistration':
                this.showWithdrawDialog();
                break;
            default:
                break;
        }
    }
}
