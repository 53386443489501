export const CREATING_CANDIDATE = 'CREATING_CANDIDATE';
export const SAVING_CANDIDATE = 'SAVING_CANDIDATE';
export const SAVING_APPLICATION = 'SAVING_APPLICATION';
export const REGISTERING_EVENT = 'REGISTERING_EVENT';

export const labels = {
    [CREATING_CANDIDATE]: 'apply-flow.submit-progress.creating-candidate-info',
    [SAVING_CANDIDATE]: 'apply-flow.submit-progress.saving-candidate-info',
    [SAVING_APPLICATION]: 'apply-flow.submit-progress.saving-application',
    [REGISTERING_EVENT]: 'event-register-flow.submit-progress.registering-event',
};
