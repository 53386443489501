import 'cx/module/custom-content/binding/customStyles';
import '../component/button/component';
import '../component/talent-community-button/component';
import '../component/job-list/component';
import 'cx/module/custom-content/component/custom-html/component';
import 'cx/module/custom-content/component/headline/component';
import 'cx/module/custom-content/component/image/component';
import 'cx/module/custom-content/component/paragraph/component';
import 'cx/module/custom-content/component/rule/component';
import 'cx/module/custom-content/component/section/component';
import 'cx/module/custom-content/component/row/component';
import 'cx/module/custom-content/component/column/component';
import 'cx/module/custom-content/component/space/component';
import 'cx/module/custom-content/component/video/component';
import 'cx/module/custom-content/component/linkedin-connections-widget/component';
import 'cx/module/custom-content/component/parallax/component';
import 'cx/module/custom-content/component/page/component';
import 'cx/module/custom-content/component/back-to-homepage/component';
import '../../search/component/custom-search/component';
import './routing';

import { components } from 'knockout';

import ViewModel from 'custom-content/CustomContentViewModel';
import template from '../custom-content.html';

components.register('custom-content', { viewModel: ViewModel, template });
