import l10n from 'core/l10n/l10n';
import l10nConverterFactory from 'core/l10n/converter/factory';
import FormValidator from 'core/form/validator/Validator';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';

export default class MinMaxDateValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        const dateFormatter = this._getDateFormatter();

        this._options.maxDate = this._options.max ? dateFormatter(this._options.max) : null;
        this._options.minDate = this._options.min ? dateFormatter(this._options.min) : null;
    }

    _getDateFormatter() {
        const { lang, msgDateFormat } = this._options;

        if (lang) {
            const converter = new LocaleBasedDateTimeFormatter(lang);

            return converter.formatDate.bind(converter);
        }

        if (msgDateFormat) {
            const converter = l10nConverterFactory.createConverter('datetime', {
                pattern: msgDateFormat,
            });

            return converter.format.bind(converter);
        }

        return l10n.date.format.bind(l10n.date);
    }

}