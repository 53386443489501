import mapping from 'knockout-mapping';
import BasicInformationModel from 'apply-flow/module/personal-information-basic/model/BasicInformation';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';

function getVerificationMethodFromRest(restCandidate) {
    const { emailPreferredFlag, phonePreferredFlag } = restCandidate;

    if (emailPreferredFlag) {
        return EMAIL;
    }

    if (phonePreferredFlag) {
        return SMS;
    }

    return undefined;
}

function mapVerificationMethodToRest(basicInformation) {
    const { verificationMethod } = basicInformation;

    if (verificationMethod === EMAIL) {
        basicInformation.emailPreferredFlag = 'Y';
        basicInformation.phonePreferredFlag = 'N';
    }

    if (verificationMethod === SMS) {
        basicInformation.phonePreferredFlag = 'Y';
        basicInformation.emailPreferredFlag = 'N';
    }
}

export default {
    mapFromRest(restCandidate) {
        return new BasicInformationModel({
            title: restCandidate.title,
            firstName: restCandidate.firstName,
            middleNames: restCandidate.middleNames,
            lastName: restCandidate.lastName,
            suffix: restCandidate.suffix,
            phone: {
                type: 'mobile',
                countryCode: restCandidate.mobilePhoneCountryCode,
                legislationCode: restCandidate.mobilePhoneLegislationCode,
                areaCode: restCandidate.mobilePhoneAreaCode,
                number: restCandidate.mobilePhoneNumber,
            },
            email: restCandidate.email,
            emailPreferredFlag: restCandidate.emailPreferredFlag,
            phonePreferredFlag: restCandidate.phonePreferredFlag,
            emailVerifiedFlag: restCandidate.emailVerifiedFlag,
            phoneVerifiedFlag: restCandidate.phoneVerifiedFlag,
            isVerified: restCandidate.emailVerifiedFlag || restCandidate.phoneVerifiedFlag,
            preNameAdjunct: restCandidate.preNameAdjunct,
            knownAs: restCandidate.knownAs,
            previousLastName: restCandidate.previousLastName,
            honors: restCandidate.honors,
            militaryRank: restCandidate.militaryRank,
            namInformation1: restCandidate.namInformation1,
            namInformation2: restCandidate.namInformation2,
            namInformation3: restCandidate.namInformation3,
            namInformation4: restCandidate.namInformation4,
            namInformation5: restCandidate.namInformation5,
            namInformation6: restCandidate.namInformation6,
            namInformation7: restCandidate.namInformation7,
            namInformation8: restCandidate.namInformation8,
            namInformation9: restCandidate.namInformation9,
            namInformation10: restCandidate.namInformation10,
            namInformation11: restCandidate.namInformation11,
            namInformation12: restCandidate.namInformation12,
            namInformation13: restCandidate.namInformation13,
            namInformation14: restCandidate.namInformation14,
            namInformation15: restCandidate.namInformation15,
            namInformation16: restCandidate.namInformation16,
            namInformation17: restCandidate.namInformation17,
            namInformation18: restCandidate.namInformation18,
            namInformation19: restCandidate.namInformation19,
            namInformation20: restCandidate.namInformation20,
            namInformation21: restCandidate.namInformation21,
            namInformation22: restCandidate.namInformation22,
            namInformation23: restCandidate.namInformation23,
            namInformation24: restCandidate.namInformation24,
            namInformation25: restCandidate.namInformation25,
            namInformation26: restCandidate.namInformation26,
            namInformation27: restCandidate.namInformation27,
            namInformation28: restCandidate.namInformation28,
            namInformation29: restCandidate.namInformation29,
            namInformation30: restCandidate.namInformation30,
            displayName: restCandidate.displayName,
            listName: restCandidate.listName,
            exWorker: restCandidate.exWorkerFlag,
            cwkCandidate: restCandidate.cwkCandidateFlag,
            verificationMethod: getVerificationMethodFromRest(restCandidate),
        });
    },

    mapToRest(basicInformationModel, isNewCandidate) {
        const basicInformation = mapping.toJS(basicInformationModel);
        const preferredTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (isNewCandidate) {
            mapVerificationMethodToRest(basicInformation);
        }

        basicInformation.mobilePhoneCountryCode = basicInformation.phone.countryCode;
        basicInformation.mobilePhoneLegislationCode = basicInformation.phone.legislationCode;
        basicInformation.mobilePhoneAreaCode = basicInformation.phone.areaCode;
        basicInformation.mobilePhoneNumber = basicInformation.phone.number;

        delete basicInformation.phone;
        delete basicInformation.exWorker;
        delete basicInformation.cwkCandidate;
        delete basicInformation.verificationMethod;
        delete basicInformation.isVerified;
        delete basicInformation.displayName;

        return { ...basicInformation, preferredTimezone };
    },
};