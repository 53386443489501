export default {

    mapToRest(profileItem) {
        if (profileItem.educationalEstablishmentId) {
            profileItem.educationalEstablishment = null;
        }

        return profileItem;
    },

};