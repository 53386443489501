import { Observable, observable, ObservableArray, observableArray } from 'knockout';

import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { Facet, Filter } from 'cx/module/search/module/search-results/module/facet/config/types';

export class SearchResultsModel<Type> {
    queryBuilder: SearchQueryBuilder;
    loading: Observable<boolean>;
    results: ObservableArray<Type>;
    initialized: Observable<boolean>;
    totalCount: Observable<number>;
    filters: ObservableArray<Facet | Filter>;
    appending: Observable<boolean>;
    hasMore: Observable<boolean | null>;
    offset: Observable<number>;
    sortBy: Observable<string | undefined>;

    constructor() {
        this.queryBuilder = SearchQueryBuilder.create({});
        this.results = observableArray();
        this.loading = observable<boolean>(true);
        this.initialized = observable<boolean>(false);
        this.totalCount = observable(0);
        this.filters = observableArray();
        this.appending = observable<boolean>(false);
        this.hasMore = observable<boolean | null>(false);
        this.offset = observable(0);
        this.sortBy = observable();
    }
}
