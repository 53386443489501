import i18n from 'core/i18n/i18n';
import { EventRegistrationPhase } from './types';

export const registrationPhaseLabelMap: Record<EventRegistrationPhase, string> = {
    invited: i18n('candidate-self-service.events.registration-phase-invited'),
    registered: i18n('candidate-self-service.events.registration-phase-registered'),
    cancelled: i18n('candidate-self-service.events.registration-phase-cancelled'),
    withdrawn: i18n('candidate-self-service.events.registration-phase-withdrawn'),
    checkedIn: i18n('candidate-self-service.events.registration-phase-attended'),
    notAttended: i18n('candidate-self-service.events.registration-phase-not-attended'),
    unconfirmed: i18n('candidate-self-service.events.registration-phase-unconfirmed'),
    pending: i18n('candidate-self-service.events.registration-phase-pending'),
};
