import { components } from 'knockout';

import { JobDetailsTagsViewModel } from '../job-details-tags/JobDetailsTagsViewModel';
import template from './job-details-tags.html';
import 'minimal/module/job-tags';

components.register('job-details-tags', {
    viewModel: JobDetailsTagsViewModel,
    template,
});

components.register('cc-job-details-tags', {
    viewModel: JobDetailsTagsViewModel,
    template,
});
