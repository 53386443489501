import appConfig from 'app/model/config';
import { workLocationDisplayKey, workLocationDisplayValues } from '../config/workLocationsSettings';
import { Coordinates } from 'ce-common/module/maps/model/Coordinates';

export function getWorkLocationsDisplay() {
    return appConfig.getSettingByKey(workLocationDisplayKey) || workLocationDisplayValues.default;
}

function sortWorkLocations(workLocation1, workLocation2) {
    if (workLocation1.country < workLocation2.country) {
        return -1;
    }

    if (workLocation1.country > workLocation2.country) {
        return 1;
    }

    const workLocation1Region = workLocation1.region1 || workLocation1.region2;
    const workLocation2Region = workLocation2.region1 || workLocation2.region2;

    if (workLocation1Region < workLocation2Region) {
        return -1;
    }

    if (workLocation1Region > workLocation2Region) {
        return -1;
    }

    if (workLocation1.townOrCity < workLocation2.townOrCity) {
        return -1;
    }

    if (workLocation1.townOrCity > workLocation2.townOrCity) {
        return 1;
    }

    return 0;
}

export function mapRecruitingLocationToString({ name }) {
    return name;
}

export function mapWorkLocationToString(workLocation) {
    if (!workLocation) {
        return null;
    }

    switch (getWorkLocationsDisplay()) {
        case workLocationDisplayValues.full:
            return mapToFullWorkLocationString(workLocation);

        case workLocationDisplayValues.zipCode:
            return workLocation.postalCode;

        case workLocationDisplayValues.name:
            return workLocation.locationName || workLocation.name;

        default:
            return null;
    }
}

function mapToFullWorkLocationString(workLocation) {
    const address = [];
    const streetAndBuilding = mapStreetAndBuildingToString(workLocation);

    address.push(streetAndBuilding);
    address.push(workLocation.townOrCity);
    address.push(workLocation.region2 || workLocation.region3);
    address.push(workLocation.postalCode);
    address.push(workLocation.country);

    return address.filter(element => element)
        .join(', ');
}

function mapStreetAndBuildingToString(workLocation) {
    const streetAndBuilding = [];

    streetAndBuilding.push(workLocation.building);
    streetAndBuilding.push(workLocation.addressLine1);

    return streetAndBuilding.filter(element => element)
        .join(' ');
}

function mapPrimaryLocationFromRest(primaryLocation, primaryLocationCoordinates) {
    const [coordinates] = primaryLocationCoordinates && primaryLocationCoordinates.length
        ? primaryLocationCoordinates
        : [{}];

    return mapLocationFromRest(primaryLocation, coordinates);
}

function mapLocationFromRest(address, { latitude, longitude }) {
    return {
        address,
        coordinates: new Coordinates({
            latitude,
            longitude,
        }),
    };
}

export default {
    mapLocationToString(location) {
        return location.name;
    },

    mapLocationCountry(location) {
        return location.countryCode;
    },

    mapWorkLocationsFromRest(workLocation, otherWorkLocations) {
        return [...workLocation, ...otherWorkLocations.sort(sortWorkLocations)]
            .map(location => mapLocationFromRest(
                mapWorkLocationToString(location),
                location))
            .filter(({ address }) => address);
    },

    mapRecruitingLocationsFromRest({ primaryLocation, primaryLocationCoordinates, secondaryLocations }) {
        const mappedPrimaryLocation = mapPrimaryLocationFromRest(primaryLocation, primaryLocationCoordinates);

        const mappedSecondaryLocations = secondaryLocations.map(location =>
            mapLocationFromRest(mapRecruitingLocationToString(location), location));

        return [
            mappedPrimaryLocation,
            ...mappedSecondaryLocations,
        ];
    },
};