import { observable, Observable, pureComputed, PureComputed, Subscription } from 'knockout';
import suggestionsService from 'cx/module/search/module/search-box/service/suggestions';
import AbstractSearchBoxInputViewModel from './AbstractSearchBoxInputViewModel';
import { RadiusUnit } from './config/types';

const DEFAULT_RADIUS = 25;

type Props = {
    action: () => void;
    beforeSearch: Observable<() => Promise<void>>;
    radiusUnit: Observable<RadiusUnit>;
    radiusValue: Observable<number>;
    value: Observable<Location>;
};

type Location = {
    label: string;
    level: string;
    locationId: string;
};

type ResponseLocation = Location & {
    value: string;
};

export default class SearchBoxLocationViewModel extends AbstractSearchBoxInputViewModel {
    location: Observable<string | undefined>;
    isDisabled: Observable<boolean>;
    isWorking: Observable<boolean>;
    hasFocus: Observable<boolean>;
    noSuggestions: Observable<boolean>;
    showRadiusDisplay: Observable<boolean>;
    showNoResultsHint: PureComputed<boolean>;
    private locationSubscription: Subscription;
    private valueSubscription: Subscription;
    private focusSubscription: Subscription;

    constructor(params: Props) {
        super(params);

        this.location = observable<string>();
        this.isDisabled = observable<boolean>(false);
        this.isWorking = observable<boolean>(false);
        this.hasFocus = observable<boolean>(false);

        this.noSuggestions = observable<boolean>(false);

        this.showRadiusDisplay = observable<boolean>(false);
        this.showNoResultsHint = pureComputed<boolean>(this.showNoResultsHintAction, this);

        this.fetch = this.fetch.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onResponse = this.onResponse.bind(this);

        this.locationSubscription = this.location.subscribe(this.clearValue, this);
        this.valueSubscription = this.value.subscribe(this.onValueChanged, this);
        this.focusSubscription = this.hasFocus.subscribe(this.onFocusChanged, this);

        if (this.hasValue()) {
            this.setValue();
        }

        this.setRadiusDisplayVisibility();
        this.setInitialRadiusValue();
    }

    dispose(): void {
        this.locationSubscription.dispose();
        this.valueSubscription.dispose();
        this.focusSubscription.dispose();
    }

    onPressEnter(): void {
        this.action();
    }

    onSelect(event: Event, value: { item: ResponseLocation }): void {
        const {
            item,
            item: { label },
        } = value;

        this.value(item);

        if (label) {
            this.location(label);
            this.action();
        }
    }

    onResponse(event: Event, data: { content: ResponseLocation[] }): void {
        const results = data.content;

        if (results.length === 0) {
            this.noSuggestions(true);
            this.showRadiusDisplay(false);

            return;
        }

        if (results.length === 1) {
            const [item] = results;

            this.value(item);
        }

        this.checkExactMatch(results);

        this.noSuggestions(false);
    }

    fetch(term: string): Location[] {
        this.isWorking(true);

        return suggestionsService.getLocationSuggestions(term).then(this.onFetchingCompleted.bind(this));
    }

    private onFetchingCompleted(values: Location[]): Location[] {
        this.isWorking(false);

        return values;
    }

    private hasValue(): boolean {
        const value = this.value();

        return !value || Boolean(Object.keys(value).length);
    }

    private setValue(): void {
        const { label } = this.value();

        if (label) {
            this.location(label);
        }
    }

    private clearValue(location: string | undefined): void {
        if (!this.hasValue()) {
            this.noSuggestions(false);

            return;
        }

        const { label } = this.value();

        if (label && location !== label) {
            this.value({});
        }
    }

    private onValueChanged(): void {
        if (this.hasValue()) {
            this.setRadiusDisplayVisibility();

            return;
        }

        this.location('');
        this.setInitialRadiusValue();
        this.setRadiusDisplayVisibility();
    }

    private onFocusChanged(): void {
        this.setRadiusDisplayVisibility();

        const { label } = this.value();

        if (label && this.location() !== label) {
            this.showRadiusDisplay(false);
        }
    }

    private setRadiusDisplayVisibility(): void {
        const shouldDisplayRadius = this.hasValue() && this.isValueCityLevel();

        this.showRadiusDisplay(shouldDisplayRadius);
    }

    private setInitialRadiusValue(): void {
        if (!this.hasValue()) {
            this.radiusValue(DEFAULT_RADIUS);
        }
    }

    private isValueCityLevel(): boolean {
        const { level, isGeolocation } = this.value();

        return level === 'city' || isGeolocation;
    }

    private showNoResultsHintAction(): boolean {
        return Boolean(!this.hasValue() && this.location() && this.noSuggestions());
    }

    private checkExactMatch(results: ResponseLocation[]): void {
        const exactMatch = results.find(
            (suggestion: ResponseLocation) =>
                suggestion.label.toLowerCase() === this.location()?.toLowerCase()
        );

        if (exactMatch) {
            this.value(exactMatch);
        }
    }
}
