import router from 'app/model/router';

router.configure({
    downloader: {
        url: '/downloader',
        view: {
            layout: 'downloader',
        },
    },
});
