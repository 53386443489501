import { observable, observableArray, pureComputed, utils } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import searchService from 'cx/module/search/service/search';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import tokenService from 'cx/module/candidate-verification/service/token';
import applicationService from 'cx/module/apply-flow/service/application';
import { CUSTOM_CRITERIA, RELEVANT_TO_USER } from '../../enums/jobListTypes';
import { JOBS_TO_CANDIDATE_STUB, FACET_MAP } from '../../config/constants';
import historyService from 'ce-common/service/historyService';
import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';
import { getFusionCode } from 'custom-content/service/getFusionCode';

export default class JobListViewModel extends ElementViewModel {

    constructor({ params, mode, lang }, templateButtonClass = 'cc-job-list__view-more-button') {
        super({ params });

        this._querybuilder = new SearchQueryBuilder();

        this.isLoaded = observable();
        this.loadingMore = observable(false);
        this.queryParams = observable();
        this.jobs = observableArray();
        this.buttonClass = templateButtonClass;
        this.isAdminMode = mode === 'admin';
        this.offset = 0;
        this.lastAppliedRequisitionId = 0;
        this.hasMoreJobs = observable(false);

        this._criteriaSubscription = this.params.criteria.subscribe(this._onCriteriaChanged, this);
        this._limitSubscription = this.params.limit.subscribe(this._onCriteriaChanged, this);

        this._jobListTypeSubscription = this.params.jobListType
            ? this.params.jobListType.subscribe(this._onJobListTypeChanged, this)
            : null;

        this._onCriteriaChanged();

        const languageCode = lang ? lang() : getFusionCode();

        this.viewMoreLabel = pureComputed(() => {
            const paramKey = `viewMoreLabel_${languageCode}`;
            const langLabel = this.params[paramKey];
            const label = this.params.viewMoreLabel;

            return langLabel ? langLabel() : label();
        });
    }

    dispose() {
        this._criteriaSubscription.dispose();
        this._limitSubscription.dispose();

        if (this._jobListTypeSubscription) {
            this._jobListTypeSubscription.dispose();
        }
    }

    openJobPreview(jobId) {
        historyService.store();

        const url = this.getUrl(jobId);

        router.redirect(url);
    }

    getUrl(jobId) {
        return absoluteRouter.interpolate(
            { lang: siteLanguage.get() },
            'job-details',
            { jobId },
        );
    }

    _onCriteriaChanged() {
        this.isLoaded(false);

        if (this.params.jobListType && this.params.jobListType() === CUSTOM_CRITERIA) {
            this._onCustomCriteriaChanged();
        } else {
            this._onJobsToCandidateCriteriaChanged();
        }
    }

    _onCustomCriteriaChanged() {
        const criteria = JSON.parse(this.params.criteria());
        const limit = this.params.limit();

        this._clearQueryParams();
        this._buildQueryParams(criteria);

        const searchParams = Object.assign({}, this.queryParams(), { limit });

        searchService.searchJobs(searchParams)
            .then(this._loadJobs.bind(this))
            .catch(error => console.error(error));
    }

    _onJobsToCandidateCriteriaChanged() {
        if (this.isAdminMode) {
            this._loadJobsToCandidateStub();
        } else {
            this._loadJobsToCandidate();
        }
    }

    async _loadJobsToCandidate() {
        const candidateNumber = tokenService.getCandidateNumber();

        if (!candidateNumber) {
            this.isLoaded(true);

            return;
        }

        this.lastAppliedRequisitionId = await this._getLastAppliedRequisitionId();

        if (!this.lastAppliedRequisitionId) {
            this.isLoaded(true);

            return;
        }

        const query = {
            limit: this.params.limit(),
        };

        searchService.searchJobsToCandidate(query, this.lastAppliedRequisitionId)
            .then(this._loadJobs.bind(this))
            .catch(error => console.error(error));
    }

    async _getLastAppliedRequisitionId() {
        const applications = await applicationService.getAll();

        if (!applications?.length) {
            return undefined;
        }

        const appliedJobs = await applicationService.getAppliedJobs(applications);

        return applicationService.getLastPostedApplicationReqId(applications, appliedJobs);
    }

    _onJobListTypeChanged(jobListType) {
        if (jobListType === RELEVANT_TO_USER) {
            this._loadJobsToCandidateStub();
        } else {
            this._onCriteriaChanged();
        }
    }

    _loadJobs({ requisitionList, hasMore }) {
        this.jobs(requisitionList);
        this.hasMoreJobs(hasMore);
        this.isLoaded(true);
    }

    _clearQueryParams() {
        this._querybuilder.withoutKeyword();
        this._querybuilder.withoutFacets();
        this._querybuilder.withoutLocation();
        this._querybuilder.withoutHotJobs();
    }

    _buildQueryParams(criteriaList) {
        const keyword = this._getCriteriaByKey(criteriaList, 'keyword');
        const categories = this._getCriteriaByKey(criteriaList, 'categories');
        const location = this._getCriteriaByKey(criteriaList, 'location');
        const workLocations = this._getCriteriaByKey(criteriaList, 'workLocations');
        const titles = this._getCriteriaByKey(criteriaList, 'titles');
        const postingDate = this._getCriteriaByKey(criteriaList, 'postingDate');
        const organizations = this._getCriteriaByKey(criteriaList, 'organizations');
        const hotJob = this._getCriteriaByKey(criteriaList, 'hotJob');
        const workplaceType = this._getCriteriaByKey(criteriaList, 'workplaceType');

        if (keyword) {
            this._querybuilder.withKeyword(keyword.value);
        }

        if (location) {
            this._querybuilder.withLocation(location.value);
        }

        if (workLocations?.value) {
            const { locationId: selectedIds } = workLocations.value;

            this._querybuilder.withFacet({
                type: FACET_MAP[workLocations.key],
                selectedIds: [selectedIds],
            });
        }

        if (categories?.value) {
            this._querybuilder.withFacet({
                type: FACET_MAP[categories.key],
                selectedIds: categories.value,
            });
        }

        if (organizations?.value) {
            const { orgId: selectedOrgId } = organizations.value;

            this._querybuilder.withFacet({
                type: FACET_MAP[organizations.key],
                selectedIds: [selectedOrgId],
            });
        }

        if (titles) {
            this._querybuilder.withFacet({
                type: FACET_MAP[titles.key],
                selectedIds: titles.value,
            });
        }

        if (postingDate) {
            this._querybuilder.withFacet({
                type: FACET_MAP[postingDate.key],
                selectedIds: [postingDate.value],
            });
        }

        if (hotJob) {
            this._querybuilder.withHotJobs(hotJob.value);
        }

        if (workplaceType) {
            this._querybuilder.withFacet({
                type: FACET_MAP[workplaceType.key],
                selectedIds: workplaceType.value,
            });
        }

        this.queryParams(this._querybuilder.build());
    }

    _getCriteriaByKey(criteriaList, key) {
        return utils.arrayFirst(criteriaList, criteria => criteria.key === key);
    }

    _loadJobsToCandidateStub() {
        this._loadJobs({
            requisitionList: JOBS_TO_CANDIDATE_STUB.slice(0, this.params.limit()),
        });
    }

    async loadMoreJobs() {
        const query = {
            offset: ++this.offset,
            limit: this.params.limit(),
        };

        this.loadingMore(true);

        const { requisitionList, hasMore } =
            await searchService.searchJobsToCandidate(query, this.lastAppliedRequisitionId)
                .catch((error) => {
                    console.error(error);
                    this.loadingMore(false);
                });

        this.jobs.push(...requisitionList);
        this.hasMoreJobs(hasMore);
        this.loadingMore(false);
    }

}
