import GeoHierarchySelectFormElement from 'apply-flow/module/personal-information-address/form/element/GeoHierarchySelect';

export default class GeoHierarchyAutosuggestFormElement extends GeoHierarchySelectFormElement {
    constructor(...args: unknown[]) {
        super(...args);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.validators('html');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.validators('xss');

        this.component('geo-hierarchy-autosuggest-form-element');
    }
}
