import ko from 'knockout';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';

export default class ApplyFlowBlockComponentViewModel {

    constructor(params) {
        this.sectionNumber = params.section.number;
        this.code = params.code;
        this.section = params.section;
        this.blockId = params.blockId;
        this.form = ko.observable();
    }

    _registerForm() {
        sectionValidator.registerForm(this.form, this.sectionNumber);
    }

    _unregisterForm() {
        sectionValidator.unregisterForm(this.form, this.sectionNumber);
    }

    _setBlockReady() {
        sectionState.setBlockReady(this.sectionNumber, this.blockId);
    }

    _setBlockUnready() {
        sectionState.setBlockUnready(this.sectionNumber, this.blockId);
    }

}