import router from 'app/model/router';
import cxEvents from 'cx/config/events';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';

router.configure({
    offer: {
        url: '/offers/{token}:?query:',
        view: {
            layout: 'offer',
            'offer-main': 'offer-content',
        },
        enter(routeParams) {
            storeShortTokenData({ token: routeParams.token });
            cxEvents.loaded.dispatch();
        },
    },
    'offer-inactive': {
        parent: 'offer',
        url: '/offer-message/inactive',
        view: {
            'offer-main': 'offer-message',
        },
    },
    'offer-refused': {
        parent: 'offer',
        url: '/offer-message/refused',
        view: {
            'offer-main': 'offer-message',
        },
    },
    'e-signature': {
        parent: 'offer',
        url: '/e-signature',
        view: {
            'offer-main': 'e-signature',
        },
    },
    'offer-just-refused': {
        parent: 'offer',
        url: '/offer-message/refuse-acknowledgement',
        view: {
            'offer-main': 'offer-message',
        },
    },
    'offer-refusal': {
        parent: 'offer',
        url: '/refuse',
        view: {
            'offer-main': 'offer-refusal',
        },
    },
});