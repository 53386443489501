import themeEvents from '../config/events';
import StyleSheetController from '../model/StyleSheetController';
import CustomCssController from '../model/CustomCssController';
import CustomJsController from '../model/CustomJsController';
import CustomFontsController from '../model/CustomFontsController';

function ThemeService() {
    const styleSheetController = new StyleSheetController('cx-custom');
    const customCssController = new CustomCssController('custom-css');
    const customJsController = new CustomJsController('custom-js');
    const customFontsController = new CustomFontsController('custom-fonts');

    this.updateStyles = compiledCss => styleSheetController.applyCSS(compiledCss);
    this.updateCustomCss = styles => customCssController.update(styles);
    this.updateCustomFonts = fonts => customFontsController.update(fonts);

    this.updateCustomJs = (script, enabled) => customJsController.update(script, enabled);

    themeEvents.stylesUpdated.add(this.updateStyles);
    themeEvents.customCssUpdated.add(this.updateCustomCss);
    themeEvents.customJsUpdated.add(this.updateCustomJs);
    themeEvents.customFontsUpdated.add(this.updateCustomFonts);
}

export default new ThemeService();