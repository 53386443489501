import converterFactory from 'core/l10n/converter/factory';


export default {
    format(number) {
        return converterFactory.createPercentConverter().format(number);
    },

    parse(formattedNumber) {
        return converterFactory.createPercentConverter().parse(formattedNumber);
    },
};