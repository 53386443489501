import appConfig from 'app/model/config';

const MAX_DAY = 31;
const MAX_MONTHS = 12;
const MIN_YEAR = 1900;

const CACHE = {};
const LANG = appConfig.siteLang;

function _addLeadingZero(value) {
    return `0${value}`.slice(-2);
}

export function getDays() {
    if (CACHE.days) {
        return CACHE.days;
    }

    const days = [];
    const formatter = new Intl.DateTimeFormat(LANG, { day: 'numeric' });

    for (let i = 1; i <= MAX_DAY; i++) {
        const date = new Date(`2020/01/${_addLeadingZero(i)}`);

        days.push({
            value: _addLeadingZero(i),
            label: formatter.format(date),
        });
    }

    CACHE.days = days;

    return CACHE.days;
}

export function getMonths() {
    if (CACHE.months) {
        return CACHE.months;
    }

    const months = [];
    const formatter = new Intl.DateTimeFormat(LANG, { month: 'long' });

    for (let i = 0; i < MAX_MONTHS; i++) {
        const date = new Date(`2020/${_addLeadingZero(i + 1)}/01`);

        months.push({
            value: _addLeadingZero(i + 1),
            label: formatter.format(date),
        });
    }

    CACHE.months = months;

    return CACHE.months;
}

export function getYears() {
    const maxYear = (new Date()).getFullYear() + 10;

    if (CACHE.years) {
        return CACHE.years;
    }

    const years = [];
    const formatter = new Intl.DateTimeFormat(LANG, { year: 'numeric' });

    for (let i = maxYear; i >= MIN_YEAR; i--) {
        const date = new Date(`${i}/01/01`);

        years.push({
            value: String(i),
            label: formatter.format(date),
        });
    }

    CACHE.years = years;

    return CACHE.years;
}
