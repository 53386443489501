import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import applyFlowEvents from 'apply-flow/config/events';
import flowService from 'apply-flow/service/flow';
import notificationsService from 'cx/service/notifications';
import ApplyFlowSummaryViewModel from 'apply-flow/component/apply-flow-summary/ApplyFlowSummaryViewModel';

export default class ApplyFlowPostSummaryThankYouViewModel extends ApplyFlowSummaryViewModel {

    redirectToCSS() {
        applyFlowEvents.submitSucceed.dispatch();

        router.go('candidate-self-service')
            .then(() => {
                flowService.clearSecondaryFlowData();
                notificationsService.successAfterLoaded(i18n('post-apply.message.submit-success'), 500);
            });
    }

}
