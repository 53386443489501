import i18n from 'core/i18n/i18n';
import FormValidator from 'core/form/validator/Validator';

export default class FileExtensionFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.file-upload.invalid-file-type';
    }

    getErrorMessage() {
        return i18n(this._message, {
            allowed_extensions: this._options.allowed_extensions.join(', '),
        });
    }

    _validate(file, options) {
        if (!file) {
            return true;
        }

        const extensions = options.allowed_extensions.join('|')
            .replaceAll('.', '\\.');

        const expression = `(.+)(${extensions})$`;

        const allowedFilesPattern = new RegExp(expression, 'i');

        return allowedFilesPattern.test(file.name);
    }

}
