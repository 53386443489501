import { observable, observableArray } from 'knockout';
import { isMapEnabled } from 'ce-common/module/maps/service/isMapEnabled';
import { getMapProvider } from 'ce-common/module/maps/service/providers/factory';
import { search as searchService } from 'minimal/module/search/service/search';
import router from 'app/model/router';
import mapEvents from 'minimal/config/events';

const STATES = {
    NOT_REQUESTED: 'not_requested',
    LOADING: 'loading',
    LOADED: 'loaded',
    ERROR: 'error',
};

export default class SearchResultsMapViewModel {

    constructor({ toggleButtonClass }) {
        this.toggleButtonClass = toggleButtonClass;
        this.markersState = observable(STATES.NOT_REQUESTED);
        this.markers = observableArray([]);
        this.showAdditionalFilterMessage = observable(false);
        this.areMoreJobsThanMapSearchLimit = searchService.areMoreJobsThanMapSearchLimit();

        if (isMapEnabled()) {
            const { query } = router.routeParams();

            try {
                this._initMapProvider();
                this._getMarkers(query);
            } catch (e) {
                this.markersState = observable(STATES.ERROR);
                console.error(e);
            }
        }

        this.showAdditionalFilterMessageSubscription = mapEvents.showAdditionalFilterMessage.add(searchLimitFlag =>
            this.showAdditionalFilterMessage(searchLimitFlag),
        );
    }

    _initMapProvider() {
        const mapProvider = getMapProvider();

        if (!mapProvider) {
            throw new Error('No MapProvider');
        }

        mapProvider.initialize();
    }

    _getMarkers(query) {
        this.markersState(STATES.LOADING);

        searchService.getMarkers(query, false)
            .then((markers) => {
                this.markersState(STATES.LOADED);
                this.markers(markers);
            }).catch((error) => {
                console.error(error);
                this.markersState(STATES.ERROR);
            });
    }

    getMapAriaLabel() {
        const isListView = this.toggleButtonClass() === 'jobs-list';

        return isListView
            ? 'search.maps.map-view-click-action'
            : 'search.maps.map-view-click-action-to-tile';
    }

    dispose() {
        this.showAdditionalFilterMessageSubscription.detach();
    }

}
