import { BasicBlocksData } from './BasicBlocksData';
import { MultiBlocksData } from './MultiBlocksData';
import { QuestionnaireBlocksData } from './QuestionnaireBlocksData';
import { ExtraInformationBlocksData } from './ExtraInformationBlocksData';
import { ORA_TIMELINE } from '../../enum/blockTypes';
import { AttachmentBlocksData } from './AttachmentBlocksData';
import { RegulatoryBlocksData } from './RegulatoryBlocksData';

const BASIC_BLOCKS = 'BASIC_BLOCKS';
const MULTI_BLOCKS = 'MULTI_BLOCK';
const REGULATORY_BLOCKS = 'REGULATORY_BLOCKS';
const QUESTIONNAIRE_BLOCKS = 'QUESTIONNAIRE_BLOCKS';
const ATTACHMENTS_BLOCKS = 'ATTACHMENTS_BLOCKS';
const EXTRA_INFO_BLOCKS = 'EXTRA_INFO_BLOCKS';

const factories = new Map([
    [BASIC_BLOCKS, { constructor: BasicBlocksData }],
    [MULTI_BLOCKS, { constructor: MultiBlocksData }],
    [REGULATORY_BLOCKS, { constructor: RegulatoryBlocksData }],
    [QUESTIONNAIRE_BLOCKS, { constructor: QuestionnaireBlocksData }],
    [ATTACHMENTS_BLOCKS, { constructor: AttachmentBlocksData }],
    [EXTRA_INFO_BLOCKS, { constructor: ExtraInformationBlocksData }],
]);

function getBlockFactory(factoryName) {
    const factory = factories.get(factoryName);

    if (!factory) {
        throw Error(`missing factory ${factoryName}`);
    }

    if (!factory.instance) {
        factory.instance = new factory.constructor();
    }

    return factory.instance;
}

export default {

    getBasicBlocksReduction() {
        return getBlockFactory(BASIC_BLOCKS).getBlocksReduction();
    },

    getMultiBlocksReduction() {
        return getBlockFactory(MULTI_BLOCKS).getBlocksReduction();
    },

    getSectionIdentifier(block) {
        if (block.code === ORA_TIMELINE) {
            return getBlockFactory(MULTI_BLOCKS).getBlockIdentifier(block);
        }

        const blockFactory = [...factories.keys()]
            .find(factory => getBlockFactory(factory).getBlocksReduction().has(block.code));

        if (!blockFactory) {
            return null;
        }

        return getBlockFactory(blockFactory).getBlockIdentifier(block);
    },

    assignIdentifiersFromTimeline(block) {
        getBlockFactory(MULTI_BLOCKS).assignIdentifiersFromTimeline(block);
    },

    getRegulatoryBlockReduction() {
        return getBlockFactory(REGULATORY_BLOCKS).getBlocksReduction();
    },

    getAttachmentBlockReduction() {
        return getBlockFactory(ATTACHMENTS_BLOCKS).getBlocksReduction();
    },

    getQuestionnaireBlockReduction() {
        return getBlockFactory(QUESTIONNAIRE_BLOCKS).getBlocksReduction();
    },

    getExtraInformationBlockReduction() {
        return getBlockFactory(EXTRA_INFO_BLOCKS).getBlocksReduction();
    },
};