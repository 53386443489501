import { unwrap } from 'knockout';
import FormValidator from '../../../../../../../core/form/validator/Validator';

export default class UniqueKey extends FormValidator {

    constructor(options) {
        super(options);

        this._message = 'validators.value-should-be-unique';
    }

    _getMessageValue(value, valueLabel) {
        if (valueLabel && value) {
            return valueLabel();
        }

        return value;
    }

    _validate(value, options) {
        const { uniqueKey, elementValueLabel } = options;
        const newRow = unwrap(options.newRow);
        const existingRows = unwrap(options.existingRows);

        options.value = this._getMessageValue(value, elementValueLabel);

        if (!newRow || !existingRows) {
            return true;
        }

        const [rowExists] = existingRows
            .filter(({ attributes }) => attributes !== newRow)
            .filter(({ attributes }) => unwrap(attributes[uniqueKey]) === value);

        return !rowExists;
    }

}