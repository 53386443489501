import ko from 'knockout';
import router from 'app/model/router';

ko.bindingHandlers.currentRoute = {
    init(element) {
        const currentRoute = router.route;

        currentRoute.subscribe(({ id }) => {
            element.setAttribute('data-route', id);
        });
    },
};

