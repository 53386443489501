import jobService from 'job-details/service/job';


export function isCopyLinkVisible({ routeId, jobId }) {
    if (routeId === 'job-formatting-preview') {
        return Promise.resolve(true);
    }

    return jobService.isJobPosted(jobId);
}
