import { bindingHandlers, utils } from 'knockout';
import $ from 'jquery';

/**
 * Scroll page to given value or element.
 *
 * @params {string or number} target - scroll value or element selector
 * @params {number} offset - scroll offset
 * @params {function} onfinish - called when scroll is completed
 * @params {duration} duration - animation duration (default is 500ms)
 * @params {isActive} - observable return boolean value for scrollTo enable
 * @params {eventType} - string with event type - supported options: 'onLoad'
 *
 * @listens click
 *
 * <div class="location-bar" data-bind="scrollTo: { target: 0 }">
 */

const DEFAULT_OFFSET = 0;
const ANIMATION_DURATION = 500;

bindingHandlers.scrollTo = {
    init(element, valueAccessor) {
        const $html = $('body, html');
        const { offset, onfinish, duration, eventType, isActive } = valueAccessor();

        let { target } = valueAccessor();
        let animationDuration = parseInt(duration, 10);

        const scrollToElement = () => {
            const scrollPosition = (parseInt(target, 10) === target) ? target : target.offsetTop;

            $html.animate({ scrollTop: scrollPosition - offset || DEFAULT_OFFSET }, animationDuration, onfinish);
        };

        const scrollToOnClickEvent = (event) => {
            event.preventDefault();

            scrollToElement();
        };

        if (isActive !== undefined && !isActive()) {
            return;
        }

        animationDuration = isNaN(animationDuration) ? ANIMATION_DURATION : animationDuration;
        target = parseInt(target, 10) !== target ? document.querySelector(target) : target;

        if (eventType === 'onLoad') {
            setTimeout(() => {
                scrollToElement();
            }, 0);
        } else {
            element.addEventListener('click', scrollToOnClickEvent);
        }

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', scrollToOnClickEvent);
        });
    },
};
