const items = {};

export const COLLECTIONS = {
    LINKS: 'links',
    DELETED_LIKS: 'links.deleted',
    DOB: 'dob',
    PII: 'pii',
    EFF: 'eff',
};

export default {

    store(itemToStore, collection) {
        if (!items[collection]) {
            items[collection] = [];
        }

        items[collection].push(itemToStore);
    },

    remove(itemToRemove, collection) {
        const profileItems = items[collection];

        if (!profileItems) {
            return;
        }

        items[collection] = profileItems.filter(item => item !== itemToRemove);
    },

    unshiftAll(collection) {
        const itemsToUnshift = items[collection];

        if (!itemsToUnshift) {
            return [];
        }

        const requestItems = itemsToUnshift.map(item => item);

        itemsToUnshift
            .forEach(item => this.remove(item, collection));

        return requestItems;
    },

};
