import facetMapper from 'search/module/search-results/module/facet/mapper/facet';
import facetFactory from 'search/module/search-results/module/facet/model/facetFactory';
import locationMapper from 'job-details/mapper/location';
import appConfig from 'app/model/config';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import { searchEventsFinderParams, searchRequisitionsFinderParams, similarRequisitionsFinderParams } from './allowedFinderParams';
import { propertyEquals } from 'core/utils/functional/propertyEquals';
import { formatDateRange } from 'app/module/minimal/module/search/component/search-event-results/service/tileDateFormatter';
import { gatherRegistrationDetails } from 'app/module/minimal/module/event-details/mapper/eventMapperHelperService';
import { SEARCH_RESULTS_STRINGS } from '../config/types';

const DEFAULT_LIMIT = 25;

function getFacetsList(context) {
    return Object.keys(facetFactory.getTypes(context)).join(';');
}

function _escapeKeyword(params) {
    return `"${params.keyword.replace(/"/g, '\\"')}"`;
}

function _calculateOffset(params) {
    return params.limit * (params.offset || 0);
}

function _mapSearchResultFromRest(radiusUnit, restSearchResult, candidateReqList = []) {
    restSearchResult.lang = restSearchResult.contentLocale;
    restSearchResult.radiusUnit = radiusUnit;
    delete restSearchResult.contentLocale;

    const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

    restSearchResult.shortDescription = restSearchResult.shortDescriptionStr;
    restSearchResult.postedDate = dateFormatter.formatDate(restSearchResult.postedDate);

    restSearchResult.secondaryLocations =
        restSearchResult.secondaryLocations.map(locationMapper.mapLocationToString);

    restSearchResult.trendingJob = restSearchResult.trendingFlag || false;
    restSearchResult.beFirstToApply = restSearchResult.beFirstToApplyFlag || false;

    delete restSearchResult.trendingFlag;
    delete restSearchResult.beFirstToApplyFlag;

    const application = candidateReqList.find(propertyEquals('id', restSearchResult.id));

    restSearchResult.isAlreadyApplied = Boolean(application?.id);

    restSearchResult.isConfirmed = Boolean(application?.confirmedFlag);

    restSearchResult.responsibilities = restSearchResult.externalResponsibilitiesStr;
    restSearchResult.qualifications = restSearchResult.externalQualificationsStr;

    return restSearchResult;
}

function _mapSearchResultsFromRest({ items }, candidateReqList) {
    const [restSearchResults] = items;
    const endOffset = restSearchResults.offset + restSearchResults.limit;

    restSearchResults.hasMore = endOffset < restSearchResults.totalJobsCount;

    restSearchResults.facets = facetMapper.mapFacetsFromRest(restSearchResults);
    restSearchResults.distanceUnit = restSearchResults.radiusUnit;

    restSearchResults.requisitionList = (restSearchResults.requisitionList || [])
        .map(restSearchResult =>
            _mapSearchResultFromRest(restSearchResults.radiusUnit, restSearchResult, candidateReqList));

    return restSearchResults;
}

function _mapSimilarJobsFromRest(result, contentLocale) {
    const { items } = result;

    const [restSearchResults] = items;

    const addContentLocalToRequisitionList = restSearchResults.requisitionList.map(data => ({
        ...data,
        contentLocale,
    }));

    items[0].requisitionList = addContentLocalToRequisitionList;

    return result;
}

function _mapSearchRecommendedJobsResultsFromRest(restSearchResults) {
    const endOffset = restSearchResults.offset + restSearchResults.limit;

    restSearchResults.hasMore = endOffset < restSearchResults.totalJobsCount;

    restSearchResults.facets = [];

    const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

    restSearchResults.requisitionList = (restSearchResults.requisitionList || []).map(
        ({ shortDescriptionStr, postedDate, ...restResult }) => ({
            ...restResult,
            ...{ shortDescription: shortDescriptionStr, postedDate: dateFormatter.formatDate(postedDate) },
        }),
    );

    return restSearchResults;
}

function _mapSearchEventResultFromRest(restEventSearchResult, registeredEventList) {
    restEventSearchResult.eventDate = formatDateRange(
        restEventSearchResult.startDate,
        restEventSearchResult.endDate,
    );

    restEventSearchResult.id = restEventSearchResult.eventNumber;

    gatherRegistrationDetails(restEventSearchResult, registeredEventList, restEventSearchResult.eventNumber);

    delete restEventSearchResult.eventNumber;

    return restEventSearchResult;
}

function _mapSearchEventResultsFromRest({ items }, registeredEventList) {
    const [restSearchEventResults] = items;

    const endOffset = (restSearchEventResults.offsetAttr) +
        (restSearchEventResults.limitAttr || DEFAULT_LIMIT);

    restSearchEventResults.facets = facetMapper.mapEventFacetsFromRest(restSearchEventResults);

    restSearchEventResults.hasMore = endOffset < restSearchEventResults.totalEventsCount;
    restSearchEventResults.distanceUnit = restSearchEventResults.radiusUnitAttr;
    restSearchEventResults.distance = restSearchEventResults.radiusAttr;

    restSearchEventResults.eventList = (restSearchEventResults.eventList || [])
        .map(restSearchEventResult => _mapSearchEventResultFromRest(restSearchEventResult, registeredEventList));

    return restSearchEventResults;
}

function filterAllowedParams(allParams, allowedParams) {
    return Object.keys(allParams || {})
        .filter(param => allowedParams.includes(param))
        .reduce((filteredParams, key) => ({
            ...filteredParams,
            [key]: allParams[key],
        }), {});
}

export default {

    mapLocationParamsToRest(params) {
        delete params.locationLevel;

        const isGeolocation = params.latitude != null && params.longitude != null;

        if (params.locationId || isGeolocation) {
            delete params.location;
        }

        if (params.location) {
            params.location = params.location.replace(/,/g, ' >');
        }

        return params;
    },

    mapSearchParamsToRest(restParams, siteNumber, limit, facets, doEscapeKeyword = true) {
        const params = {
            siteNumber,
            facetsList: facets ?? getFacetsList(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS),
            limit,
            ...filterAllowedParams(restParams, searchRequisitionsFinderParams),
        };

        this.mapLocationParamsToRest(params);

        params.offset = _calculateOffset(params);

        if (params.keyword && doEscapeKeyword) {
            params.keyword = _escapeKeyword(params);
        }

        return params;
    },

    mapSearchRecommendedJobsParamsToRest({ skills, titles, offset }, siteNumber, limit) {
        const payload = { siteNumber, limit, skills, titles };

        payload.offset = _calculateOffset({ offset, limit });

        return payload;
    },

    mapSearchEventsParamsToRest(restParams, siteNumber, facets) {
        const params = {
            siteNumber,
            limit: restParams?.limit ?? DEFAULT_LIMIT,
            facetsList: facets ?? getFacetsList(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS),
            ...filterAllowedParams(restParams, searchEventsFinderParams),
        };

        params.offset = _calculateOffset(params);

        if (params.keyword) {
            params.keyword = _escapeKeyword(params);
        }

        return params;
    },

    mapSearchAIJobsParamsToRest(restParams, requisitionId, candidateNumber, siteNumber, limit) {
        const params = {
            requisitionId,
            siteNumber,
            candidateNumber,
            limit,
            ...filterAllowedParams(restParams, similarRequisitionsFinderParams),
        };

        params.offset = _calculateOffset(params);

        return params;
    },

    mapSearchResultFromRest: _mapSearchResultFromRest,

    mapSearchResultsFromRest: _mapSearchResultsFromRest,

    mapSearchEventResultsFromRest: _mapSearchEventResultsFromRest,

    mapSearchRecommendedJobsResultsFromRest: _mapSearchRecommendedJobsResultsFromRest,

    mapSimilarJobsFromRest: _mapSimilarJobsFromRest,
};