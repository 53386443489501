import ko, { observable, pureComputed } from 'knockout';
import TimelineProfileItemsViewModel from './TimelineProfileItemsViewModel';
import sectionValidator from 'apply-flow/model/sectionValidator';
import { isBlockRequired } from 'apply-flow/component/apply-flow-block/service/isBlockRequired';

export default class WorkAndEducationTimelineViewModel {

    constructor(params) {
        const { section, blockProperties } = params;

        this.section = section;
        this.blockProperties = blockProperties;

        const [experienceContentTypeId, educationContentTypeId] = blockProperties.contentTypeId;
        const [experienceSectionId, educationSectionId] = blockProperties.sectionId;

        const [experienceMetadataServiceUrl, educationMetadataServiceUrl] = params.metadataServiceUrl;

        this.educationViewModel = new TimelineProfileItemsViewModel(ko.utils.extend(Object.create(params), {
            blockProperties: {
                contentTypeId: educationContentTypeId,
                sectionId: educationSectionId,
            },
            metadataServiceUrl: educationMetadataServiceUrl,
        }));

        this.experienceViewModel = new TimelineProfileItemsViewModel(ko.utils.extend(Object.create(params), {
            blockProperties: {
                contentTypeId: experienceContentTypeId,
                sectionId: experienceSectionId,
            },
            metadataServiceUrl: experienceMetadataServiceUrl,
        }));

        this.isEducationValid = this.educationViewModel.isValid;
        this.isExperienceValid = this.experienceViewModel.isValid;

        this.hasEducationAndExperienceItemsAdded = ko.pureComputed(() => {
            const hasEducationItemAdded = this.isEducationValid();
            const hasExperienceItemAdded = this.isExperienceValid();

            return hasEducationItemAdded && hasExperienceItemAdded;
        });

        this.isBlockRequired = isBlockRequired(this.blockProperties);
        this.requiredValidationEnabled = observable(false);
        this.section.addBeforeValidationCallback(() => this._enableRequiredValidation());

        this.isRequiredValidation = pureComputed(this._computeRequiredValidation, this);
        sectionValidator.addValidation(this.section.number, this.isRequiredValidation);

        if (this.isBlockRequired) {
            sectionValidator.setHasRequiredElementExtraValidation(this.section.number, true);
        }

        this.onDialogClose = this.onDialogClose.bind(this);

        this.addButtonsInViewport = ko.observable();
        this.buttonsPinned = ko.pureComputed(() => !this.addButtonsInViewport());
    }

    _enableRequiredValidation() {
        this.requiredValidationEnabled(true);
    }

    _computeRequiredValidation() {
        const isRequiredValidationActive = this.isBlockRequired && this.requiredValidationEnabled();

        return isRequiredValidationActive
            ? this.hasEducationAndExperienceItemsAdded()
            : true;
    }

    onDialogClose() {
        this._enableRequiredValidation();
    }

    dispose() {
        this.hasEducationAndExperienceItemsAdded.dispose();
        this.educationViewModel.dispose();
        this.experienceViewModel.dispose();
    }

}