import flowService from 'apply-flow/service/flow';
import httpLangCached from 'cx/service/http/httpLangCached';
import metadataMapper from 'apply-flow/module/personal-information-basic/mapper/metadata';

export default class Metadata {

    constructor(serviceUrl) {
        this._serviceUrl = serviceUrl;
    }

    getFormElements() {
        const { flowVersionId } = flowService.get();

        const serviceUrl = this._serviceUrl
            .replace('<FLOW_VERSION_ID>', flowVersionId);

        return httpLangCached.get(serviceUrl)
            .then(metadataMapper.mapFormElementsFromRest.bind(metadataMapper));
    }

}