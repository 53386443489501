import Section from 'apply-flow/model/Section';
import flowPageMapper from 'apply-flow/mapper/flowPage';
import flowBlockMapper from 'apply-flow/mapper/flowBlock';
import metadataConfig from 'apply-flow/config/metadata';

function _isNotSummarySection(section) {
    const summaryBlock = section.pages
        .reduce((blocks, page) => blocks.concat(page.blocks), [])
        .filter(block => block.blockCode === 'ORA_SUMMARY');

    return summaryBlock.length === 0;
}

function _isNotEmptySection(section) {
    return section.pages.length;
}

function _removeSectionContainingPresectionOnly(section) {
    return !(section.pages.length === 1
           && section.pages[0].blocks.length === 1
           && metadataConfig.isPresectionBlock(section.pages[0].blocks[0].blockCode));
}

function _sortBySectionSequence(firstSection, secondSection) {
    return firstSection.sectionSequence > secondSection.sectionSequence;
}

function _addPostSectionBlocks(section, eSignature) {
    const lastPage = section.pages[section.pages.length - 1];

    lastPage.blocks.push(flowBlockMapper.createAgreementsBlock());

    if (eSignature.eSignatureEnabled) {
        lastPage.blocks.push(flowBlockMapper.createESignatureBlock(eSignature.eSignatureDescription));
    }

    return section;
}

function _createSection({ sectionName, pages }) {
    return new Section({
        title: sectionName,
        pages: flowPageMapper.mapFlowPagesFromRest(pages),
    });
}

function _reorderSection(section, index) {
    section.number = String(index + 1);

    return section;
}

export default {
    mapFlowSectionsFromRest(restSections, eSignature) {
        const sections = restSections
            .filter(_removeSectionContainingPresectionOnly)
            .filter(_isNotSummarySection)
            .sort(_sortBySectionSequence)
            .map((section, index, allSections) => {
                if (index === (allSections.length - 1)) {
                    return _addPostSectionBlocks(section, eSignature);
                }

                return section;
            });

        return sections
            .map(_createSection)
            .filter(_isNotEmptySection)
            .map(_reorderSection);
    },

    findSectionNumberByBlockCode(sections, blockCode) {
        let sectionNumber = 1;

        sections.forEach((section, id) => {
            section.pages.forEach((page) => {
                page.blocks.forEach((block) => {
                    if (block.code === blockCode) {
                        sectionNumber = id + 1;
                    }
                });
            });
        });

        return sectionNumber;
    },
};