import { AddressMarker } from 'ce-common/module/maps/model/AddressMarker';

function hasSameCoordinates(marker1, marker2) {
    const { coordinates: { latitude: latitude1, longitude: longitude1 } } = marker1;
    const { coordinates: { latitude: latitude2, longitude: longitude2 } } = marker2;

    return latitude1 === latitude2 && longitude1 === longitude2;
}

function removeSameCoordinates(markers, marker) {
    const coordinatesExist = markers.some(hasSameCoordinates.bind(null, marker));

    if (!coordinatesExist) {
        markers.push(marker);
    }

    return markers;
}

export default {
    mapMarkersFromLocations(workLocations, recruitingLocations, lang) {
        const locations = workLocations && workLocations.length ? workLocations : recruitingLocations;

        return locations.map(({ coordinates, address }) => new AddressMarker({
            coordinates,
            address,
            lang,
        }))
            .filter(marker => marker.validate())
            .reduce(removeSameCoordinates, []);
    },
};