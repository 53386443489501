import { CustomContentParams } from 'cx/module/custom-content/mapper/params';

type MarginParam = 'marginTop' | 'marginRight' | 'marginBottom' | 'marginLeft';
type MarginParams = Record<MarginParam, string | null>;
type CustomContentWithMarginParams = CustomContentParams & MarginParams;

export const mapMarginParamsFromRest = (margin: string): MarginParams => {
    const [marginTop, marginRight, marginBottom, marginLeft] = margin.split(' ');

    return {
        marginTop: marginTop === 'null' ? null : marginTop,
        marginRight: marginRight === 'null' ? null : marginRight,
        marginBottom: marginBottom === 'null' ? null : marginBottom,
        marginLeft: marginLeft === 'null' ? null : marginLeft,
    };
};

export const hasMarginParams = (params: CustomContentParams): params is CustomContentWithMarginParams => {
    return (
        params.marginTop !== undefined &&
        params.marginRight !== undefined &&
        params.marginBottom !== undefined &&
        params.marginLeft !== undefined
    );
};

export const mapMarginParamsToRest = (params: CustomContentWithMarginParams): string => {
    const { marginTop, marginRight, marginBottom, marginLeft } = params;

    return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
};
