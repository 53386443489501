import http from 'core/http/http';
import synchronization from 'apply-flow/service/synchronization';
import attachmentMapper from 'apply-flow/module/file-upload/mapper/fileAttachment';
import siteLinkMapper from 'apply-flow/module/candidate-links/mapper/siteLink';
import { HTTP_STATUS_CODES_CONFIG } from './attachmentStatusCodesConfig';

const QUERY_PARAM = '?fields=Id,Title,Category,Attachment,Type';

export default {
    query(candidateId, onlyMeta = false) {
        const params = onlyMeta ? QUERY_PARAM : '';

        return http.get(`/recruitingCECandidates/${candidateId}/child/attachments${params}`, {
            retry: 2,
            statusCodes: {
                404: () => null,
            },
        }).then(restCollection => ({
            fileAttachments: attachmentMapper.mapFileAttachmentsFromRest(restCollection),
            linkedSites: siteLinkMapper.mapSiteLinksFromRest(restCollection),
        }));
    },

    create(candidateId, attachment) {
        return http.post(`/recruitingCECandidates/${candidateId}/child/attachments/`, attachment, HTTP_STATUS_CODES_CONFIG);
    },

    update(candidateId, attachment) {
        return http.patch(`/recruitingCECandidates/${candidateId}/child/attachments/${attachment.id}`, attachment, HTTP_STATUS_CODES_CONFIG);
    },

    save(candidateId, attachment) {
        if (attachment.id) {
            return this.update(candidateId, attachment);
        }

        return this.create(candidateId, attachment);
    },

    remove(candidateId, attachmentId) {
        return http.delete(`/recruitingCECandidates/${candidateId}/child/attachments/${attachmentId}`, {
            statusCodes: {
                404: () => Promise.resolve(),
                500: () => Promise.resolve(),
            },
        });
    },

    saveAttachments(candidateId, attachments) {
        const promises = attachmentMapper.mapFileAttachmentsToRest(attachments)
            .map(attachment => this.save(candidateId, attachment));

        return Promise.all(promises)
            .then(items => attachmentMapper.mapFileAttachmentsFromRest({ items }));
    },

    removeAll(candidateId, attachments) {
        return Promise.all(
            attachments
                .filter(attachment => attachment.id())
                .map(attachment => this.remove(candidateId, attachment.id())),
        );
    },

    removeLinks(candidateId, links) {
        const remove = link => this.remove(candidateId, link.id())
            .then(() => synchronization.remove(link, 'links.deleted'));

        const promises = links
            .filter(({ id }) => id())
            .map(remove);

        return Promise.all(promises);
    },

    saveLinks(candidateId, links) {
        const save = link => this.create(candidateId, siteLinkMapper.mapSiteLinkToRest(link))
            .then((savedLink) => {
                synchronization.remove(link, 'links');

                return savedLink;
            });

        const promises = links
            .filter(({ url }) => url())
            .map(save);

        return Promise.all(promises)
            .then(items => siteLinkMapper.mapSiteLinksFromRest({ items }));
    },
};