import componentMapper from './component';
import itemSorter from '../service/itemSorter';
import paramsMapper from './params';

export default {
    mapColumnsFromRest(columns, componentFactory) {
        const sortedColumns = itemSorter.sort(columns);

        return sortedColumns.map(column => this.mapColumnFromRest(column, componentFactory));
    },

    mapColumnFromRest(column, componentFactory) {
        try {
            return componentFactory.create({
                elementNumber: column.elementNumber,
                params: paramsMapper.mapParamsFromRest([
                    ...column.columnParams,
                    { paramKey: 'type', paramValue: 'cc-column' },
                ]),
                order: column.elementOrder,
                components: componentMapper.mapComponentsFromRest(column.components, componentFactory),
            });
        } catch (err) {
            console.error(err);

            return componentFactory.createEmptyColumn();
        }
    },
};