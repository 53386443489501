import { Observable, observable, Subscription } from 'knockout';
import { convertMsToMinutesSeconds } from 'core/utils/millisecondsConverter';
import { session } from 'candidate-verification/service/user-session/session';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import idleTimerService from 'candidate-verification/service/idleTimer';

export class SessionExpireViewModel {
    isDialogVisible: Observable<boolean>;
    leftOverTimeSubscription: Subscription;
    counter: Observable<string>;
    showNotification: Observable<boolean>;

    constructor() {
        this.isDialogVisible = observable<boolean>(false);
        this.showNotification = observable<boolean>(false);

        this.counter = observable(
            convertMsToMinutesSeconds(idleTimerService.SESSION_EXPIRE_DIALOG_THREASHOLD_TIME)
        );

        const isSessionExistWithoutPersistance = tokenService.get() && !tokenService.get()?.persistAccessFlag;

        if (isSessionExistWithoutPersistance) {
            idleTimerService.init();
        } else {
            idleTimerService.cleanUp();
        }

        this.leftOverTimeSubscription = idleTimerService.leftOverTime.subscribe((leftOverTime) => {
            this.counter(convertMsToMinutesSeconds(leftOverTime));

            if (leftOverTime <= 0) {
                this.showNotification(true);
                this.signOut();
            } else if (leftOverTime <= idleTimerService.SESSION_EXPIRE_DIALOG_THREASHOLD_TIME) {
                this.isDialogVisible(true);
                idleTimerService.stopActivityTracker();
            }
        });
    }

    private redirectToSearch(): void {
        router.go('search');
    }

    signOut(): void {
        this.isDialogVisible(false);
        idleTimerService.cleanUp();

        session
            .signOut()
            .then(() => {
                this.redirectToSearch();

                if (this.showNotification()) {
                    notificationsService.error(i18n('general.session-expire.notification.message'), 0, false);
                    this.showNotification(false);
                }
            })
            .catch((error) => {
                console.error(error);
                notificationsService.error();
            });
    }

    continueWorking(): void {
        idleTimerService.init();
        this.isDialogVisible(false);
    }

    dispose(): void {
        this.leftOverTimeSubscription.dispose();
    }
}
