export const JOB_TAGS_DEFAULT: JobTagsItem[] = ['hotJob', 'trendingJob', 'beFirstToApply'];

import i18n from 'core/i18n/i18n';

export type JobTagsItem = 'hotJob' | 'trendingJob' | 'beFirstToApply';

type OptionItem<Type extends string> = {
    value: Type;
    label: string;
};

export type JobTagsItemOption = OptionItem<JobTagsItem>;

const JOB_TAGS_LABELS: Record<JobTagsItem, string> = {
    hotJob: i18n('admin.site-editor.content-editor.hot-job'),
    trendingJob: i18n('admin.site-editor.content-editor.trending-job'),
    beFirstToApply: i18n('admin.site-editor.content-editor.be-first-to-apply'),
};

const mapOptions = <Type extends string>(options: Record<Type, string>): { value: Type; label: string }[] => {
    return Object.entries(options).map(([value, label]) => {
        return {
            value: value as Type,
            label: label as string,
        };
    });
};

export const JOB_TAGS_OPTIONS: JobTagsItemOption[] = mapOptions(JOB_TAGS_LABELS);
