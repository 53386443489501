import RegulatoryViewModel from 'apply-flow/module/regulatory/RegulatoryViewModel';
import VeteranMetadataService from 'apply-flow/module/regulatory/module/veteran/service/VeteranMetadata';

export default class VeteranViewModel extends RegulatoryViewModel {

    constructor(params) {
        super(params, new VeteranMetadataService(params.metadataServiceUrl));
    }

}
