import DraftDataProcessor from '../service/draft-data/DraftDataProcessor';
import postApplyService from '../../apply-flow-post/service/postApply';
import applicationDraftService from '../service/applicationDraft';
import { storeInitialCommunicationChannelsValue, setInitialEmail, setInitialPhone } from './initialCommunicationChannels';
import applyFlowEvents from '../config/events';
import { EMAIL } from 'candidate-verification/config/verificationMethods';
import tokenService from 'candidate-verification/service/token';

export default class ApplicationDraft {

    constructor(draftData, candidateModel) {
        this.id = draftData.id;
        this.jobId = draftData.jobId;
        this.legalDisclaimerId = draftData.legalDisclaimerId;
        this.candidateModificationDate = draftData.candidateModificationDate;
        this.draftModificationDate = draftData.draftModificationDate;
        this.savedContent = draftData.savedContent;
        this.contentLanguage = draftData.contentLanguage;
        this.draftReducer = new DraftDataProcessor();
        this.proposedCandidateId = draftData.proposedCandidateId;
        this.draftSource = draftData.draftSource;
        this.candidateModel = candidateModel;
        this.forceDraftUpdate = false;

        this.application = draftData.application;

        const draftCandidate = postApplyService.isPostApply() && draftData.candidate
            ? this.draftReducer.reduceDraftDataByFlow(draftData).candidate
            : draftData.candidate;

        this.candidate = applicationDraftService
            .calculateDraftCandidate({
                isCandidateNewer: this.isCandidateDataNewer(),
                candidateModel,
                draftCandidate,
            });

        storeInitialCommunicationChannelsValue();

        this.draftCommunicationChannelChange = applyFlowEvents.afterCommunicationChannelChange
            .add(this.onDraftCommunicationChannelChanged.bind(this));

        this.removedProfileItemsMap = this.isCandidateDataNewer() ? {} : draftData.removedProfileItemsMap;
        this.update = this.update.bind(this);
    }

    addRemovedProfileItem(collectionName, removedItem) {
        if (this.removedProfileItemsMap[collectionName]) {
            this.removedProfileItemsMap[collectionName].push(removedItem);
        } else {
            this.removedProfileItemsMap[collectionName] = [removedItem];
        }
    }

    clearRemovedProfileItems(collectionName) {
        delete this.removedProfileItemsMap[collectionName];
    }

    isCandidateDataNewer() {
        if (!this.id) {
            return true;
        }

        return this.candidateModificationDate && this.candidateModificationDate > this.draftModificationDate;
    }

    shouldUpdate({ content, contentLanguage }) {
        if (this.forceDraftUpdate) {
            this.forceDraftUpdate = false;

            return true;
        }

        return this.savedContent !== content || this.contentLanguage !== contentLanguage;
    }

    update(data) {
        Object.assign(this, data);
    }

    async onDraftCommunicationChannelChanged(verificationMethod, value) {
        if (!this.candidateModel.isNew()) {
            this._updateInitialCandidateCommunicationChannels(verificationMethod, value);

            return;
        }

        const action = verificationMethod === EMAIL
            ? applicationDraftService.updateCommunicationChannelEmail
            : applicationDraftService.updateCommunicationChannelPhone;

        await action(value, this.id, this.proposedCandidateId);

        this._updateInitialCandidateCommunicationChannels(verificationMethod, value);

        tokenService.setCandidateCommunicationChannel(verificationMethod, value);
    }

    _updateInitialCandidateCommunicationChannels(verificationMethod, value) {
        if (verificationMethod === EMAIL) {
            setInitialEmail(value, Boolean(value));
        } else {
            setInitialPhone(value, Boolean(value));
        }

        this.forceDraftUpdate = true;
    }

}