import { observable } from 'knockout';
import router from 'app/model/router';
import appConfig from 'app/model/config';
import { search as searchService } from '../../service/search';
import { getDefaultSortOption } from 'app/module/cx/module/search/service/defaultSortBy';
import { buildSortOption } from 'app/module/cx/module/search/model/SearchQueryBuilder';
import { jobsSortOptions } from 'app/module/cx/module/search/model/jobsSorting';

export default class SearchSortingViewModel {

    constructor() {
        this.sortOptions = observable(this._getOptions());

        this.selectedSortOption = observable();

        this._routeParamsSub = router.routeParams.subscribe(({ query }) => {
            this.sortOptions(this._getOptions(query));
            this._setSortOptionFromRouting();
        }, this);

        this._setSortOptionFromRouting();
    }

    _resolveSortOption(sortBy) {
        const defaultSortByVal = sortBy || buildSortOption(getDefaultSortOption(router.routeParams()?.query)) || 'RELEVANCY';
        const [selectedSortOption] = this.sortOptions().filter(sortOption => defaultSortByVal === sortOption.value);

        this.selectedSortOption(selectedSortOption);
    }

    _getOptions(query) {
        const isDistanceEnabled = appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED') === 'true';

        return jobsSortOptions
            .map(({ name, code, order }) => ({
                text: name,
                value: code === 'RELEVANCY' ? code : `${code}_${order}`,
                code,
                order,
            }))
            .filter(({ code }) => (code === 'DISTANCE' ? isDistanceEnabled && searchService.isLocationSearch(query) : true));
    }

    _setSortOptionFromRouting() {
        const { sortBy } = router.routeParams().query || {};

        this._resolveSortOption(sortBy);
    }

    dispose() {
        this._routeParamsSub.dispose();
    }

}
