import profileImportConverterVerCan from './profileImportConverterVerCan';
import flowService from 'apply-flow/service/flow';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';
import dateTimeFormatter from 'apply-flow/module/profile-import/mapper/profileImportDateTimeFormatter';
import countryCodesService from 'cx/service/phone/phoneCountryCodes';

const EDUCATION_BLOCK = 'ORA_EDUCATION';
const LANGUAGE_BLOCK = 'ORA_LANGUAGE';
const EXPERIENCE_BLOCK = 'ORA_EXPERIENCE';
const CERTIFICATIONS_BLOCK = 'ORA_LICENSE_CERTIFICATES';
const TIMELINE_BLOCK = 'ORA_TIMELINE';
const ADDRESS_BLOCK = 'ORA_ADDRESS';
const PERSONAL_INFORMATION_BLOCK_CODE = 'ORA_NAME_CONTACT_INFO';
const SKILL_BLOCK = 'ORA_SKILL';
const ATTACHMENT_BLOCK = 'ORA_DOCUMENTS';
let isCountryCodeFromPhoneNumber = false;
let toSortCountry = false;

let blocks = [];

function _getBlocks() {
    return flowService.get().sections
        .reduce((pages, section) => pages.concat(section.pages), [])
        .reduce((blocksVal, page) => blocksVal.concat(page.blocks), []);
}

function getBlocks() {
    return blocks;
}

function setBlocks() {
    blocks = _getBlocks();
}

function containsBlock(blockCode) {
    return getBlocks().some(block => block.code === blockCode);
}

function getProficiency(proficiency) {
    const LOW = 8001;
    const MODERATE = 8002;
    const HIGH = 8003;

    if (proficiency === 'ELEMENTARY') {
        return LOW;
    } else if (proficiency === 'LIMITED_WORKING') {
        return MODERATE;
    } else if (proficiency === 'PROFESSIONAL_WORKING') {
        return HIGH;
    } else if (proficiency === 'FULL_PROFESSIONAL') {
        return HIGH;
    } else if (proficiency === 'NATIVE_OR_BILINGUAL') {
        return HIGH;
    }

    return null;
}

class WorkExperienceConverter {

    toRestModel(profileData) {
        if (containsBlock(EXPERIENCE_BLOCK) || containsBlock(TIMELINE_BLOCK)) {
            const workExperience = profileData.map((item, index) => {
                const rest = {};

                rest.contentTypeId = contentTypes.EXPERIENCE_ID;
                rest.profileItemId = index + 1;
                rest.employerName = item.companyName;
                rest.jobTitle = item.title;

                if (item.company) {
                    rest.businessType = item.company.type;
                }

                rest.achievements = item.summary;
                rest.currentJobFlag = item.isCurrent;

                if (item.startDate && item.startDate.year) {
                    rest.startDate = dateTimeFormatter.getStartDate(item.startDate);
                }

                if (item.endDate && item.endDate.year) {
                    rest.endDate = dateTimeFormatter.getEndDate(item.endDate);
                }

                return rest;
            });


            return Promise.resolve(workExperience);
        }

        return [];
    }

}

class LanguageConverter {

    toRestModel(profileData) {
        if (containsBlock(LANGUAGE_BLOCK)) {
            const language = profileData.map((item, index) => {
                const rest = {};

                rest.contentTypeId = contentTypes.LANGUAGE_ID;
                rest.contentItemId = item.name;
                rest.profileItemId = index + 1;
                rest.language = item.name;
                rest.nativeSpeakerFlag = (item.proficiency === 'NATIVE_OR_BILINGUAL');
                rest.ableToTranslateFlag = (((item.proficiency === 'NATIVE_OR_BILINGUAL') || (item.proficiency === 'FULL_PROFESSIONAL')) ? 'Y' : 'N');
                rest.ableToTeachFlag = (((item.proficiency === 'NATIVE_OR_BILINGUAL') || (item.proficiency === 'FULL_PROFESSIONAL')) ? 'Y' : 'N');
                rest.speakingLevelId = getProficiency(item.proficiency);
                rest.readingLevelId = getProficiency(item.proficiency);
                rest.writingLevelId = getProficiency(item.proficiency);

                return rest;
            });

            return Promise.resolve(language);
        }

        return [];
    }

}


class SkillsConverter {

    toRestModel(profileData) {
        if (containsBlock(SKILL_BLOCK)) {
            const skills = profileData.map((item, index) => {
                const rest = {};

                rest.profileItemId = index + 1;
                rest.skills = item.name;
                rest.dateAchieved = null;
                rest.yearsOfExperience = null;
                rest.projectName = null;
                rest.speciality = null;
                rest.comments = null;
                rest.description = item.name;
                rest.contentTypeId = contentTypes.SKILL_ID;

                return rest;
            });

            return Promise.resolve(skills);
        }

        return [];
    }

}


class LicenseAndCertConverter {

    toRestModel(profileData) {
        if (containsBlock(CERTIFICATIONS_BLOCK)) {
            const certification = profileData.map((item, index) => {
                const rest = {};

                rest.contentTypeId = contentTypes.CERTIFICATION_ID;
                rest.profileItemId = index + 1;
                rest.title = item.name;
                rest.certificationName = item.name;
                rest.comments = null;
                rest.issuedBy = item.authority;
                rest.certificationNumber = item.number;

                if (item.endDate && item.endDate.year) {
                    rest.expirationDate = dateTimeFormatter.getEndDate(item.endDate);
                }

                if (item.startDate && item.startDate.year) {
                    rest.issueDate = dateTimeFormatter.getStartDate(item.startDate);
                }

                return rest;
            });

            return Promise.resolve(certification);
        }

        return [];
    }

}

class EducationConverter {

    toRestModel(profileData) {
        if (containsBlock(EDUCATION_BLOCK) || containsBlock(TIMELINE_BLOCK)) {
            const education = profileData.map((item, index) => {
                const rest = {};

                rest.contentTypeId = contentTypes.EDUCATION_ID;
                rest.profileItemId = index + 1;
                rest.contentItemId = item.degree;
                rest.degreeName = item.degree;
                rest.major = item.fieldOfStudy;
                rest.educationalEstablishment = item.schoolName;
                rest.graduatedFlag = false;
                rest.educationalEstablishmentId = null;
                rest.stateProvinceCode = null;
                rest.countryCode = null;

                const sameStartAndEndYear = dateTimeFormatter.isSameYear(item.startDate, item.endDate);

                if (item.startDate && item.startDate.year) {
                    rest.startDate = sameStartAndEndYear
                        ? dateTimeFormatter.getStartDate(item.startDate)
                        : dateTimeFormatter.getSchoolYearStartDate(item.startDate);
                }

                if (item.endDate && item.endDate.year) {
                    rest.endDate = sameStartAndEndYear
                        ? dateTimeFormatter.getEndDate(item.endDate)
                        : dateTimeFormatter.getSchoolYearEndDate(item.endDate);

                    const presentYear = (new Date()).getFullYear();

                    rest.graduatedFlag = item.endDate.year <= presentYear;
                }

                return rest;
            });

            return Promise.resolve(education);
        }

        return [];
    }

}

class AttachmentConverter {

    toRestModel(profileData) {
        if (containsBlock(ATTACHMENT_BLOCK)) {
            const attachments = profileData.map((item, index) => ({
                id: index + 1,
                category: 'IRC_CANDIDATE_SOCIAL',
                type: 'URL',
                title: 'link',
                description: 'LinkedIn',
                attachment: item,
                fileContents: null,
            }));

            return Promise.resolve(attachments);
        }

        return [];
    }

}

class AwliProfileImportConverter {

    constructor() {
        this.educationConverter = new EducationConverter();
        this.languageConverter = new LanguageConverter();
        this.certifications = new LicenseAndCertConverter();
        this.workExperienceConverter = new WorkExperienceConverter();
        this.skillsConverter = new SkillsConverter();
        this.attachConverter = new AttachmentConverter();
    }

    async getCountryCode(awliProfileData) {
        const countryCodeFromPhoneNumber = (awliProfileData.phoneNumber?.startsWith('+')) ? await countryCodesService.extractCountryCode(awliProfileData.phoneNumber) : '';

        const territoryName = awliProfileData.location?.country?.toUpperCase();

        const countryCodeFromTerritory = await countryCodesService.getPhoneCountryCodeByTerritoryCode(territoryName);

        if (countryCodeFromPhoneNumber === countryCodeFromTerritory) {
            toSortCountry = true;
        }

        let phoneCountryCode;

        if (countryCodeFromPhoneNumber) {
            isCountryCodeFromPhoneNumber = true;
            phoneCountryCode = countryCodeFromPhoneNumber;
        } else {
            phoneCountryCode = countryCodeFromTerritory;
        }

        return phoneCountryCode;
    }

    async convertProfile(awliProfileData) {
        setBlocks();

        const restModel = {};

        const territoryName = awliProfileData.location?.country?.toUpperCase();

        awliProfileData.profileType = 'li';

        awliProfileData.phoneCountryCode = await this.getCountryCode(awliProfileData);

        restModel.profileType = 'li';

        if (containsBlock(PERSONAL_INFORMATION_BLOCK_CODE)) {
            restModel.firstName = awliProfileData.firstName;
            restModel.lastName = awliProfileData.lastName;
            restModel.title = '';
        }

        restModel.email = awliProfileData.emailAddress;

        if (isCountryCodeFromPhoneNumber) {
            if (territoryName) {
                const phoneCodeCheck = await countryCodesService.getPhoneCountryCodeByTerritoryCode(territoryName) ===
                awliProfileData.phoneCountryCode;

                if (phoneCodeCheck) {
                    restModel.mobilePhoneLegislationCode = territoryName;
                }
            } else {
                restModel.mobilePhoneLegislationCode =
                await countryCodesService
                    .getTerritoryCodeByPhoneCountryCode(toSortCountry, awliProfileData.phoneCountryCode);
            }
        } else {
            restModel.mobilePhoneLegislationCode = territoryName;
        }

        restModel.mobilePhoneCountryCode = awliProfileData.phoneCountryCode;
        restModel.mobilePhoneNumber = (awliProfileData.phoneNumber) ? countryCodesService.getPhoneNumber(awliProfileData) : '';

        if (containsBlock(ADDRESS_BLOCK)) {
            restModel.country = awliProfileData.location.country.toUpperCase();
        }

        delete awliProfileData.profileType;
        restModel.workPreferences = [];
        restModel.attachments = [];

        const promiseEducations = this.educationConverter.toRestModel(awliProfileData.educations || []);
        const promiseLanguages = this.languageConverter.toRestModel(awliProfileData.languages || []);
        const promiseCertifications = this.certifications.toRestModel(awliProfileData.certifications || []);
        const promiseWorkExperiences = this.workExperienceConverter.toRestModel(awliProfileData.positions || []);
        const promiseSkills = this.skillsConverter.toRestModel(awliProfileData.skills || []);
        const promiseAttachments = this.attachConverter.toRestModel([awliProfileData.publicProfileUrl] || []);

        return Promise.all([
            promiseEducations,
            promiseLanguages,
            promiseCertifications,
            promiseWorkExperiences,
            promiseSkills,
            promiseAttachments,
        ]).then(([educations, languages, licensesAndCertifications, workExperiences, skills, attachments]) => {
            restModel.educations = educations;
            restModel.languages = languages;
            restModel.skills = skills;
            restModel.licensesAndCertifications = licensesAndCertifications;
            restModel.workExperiences = workExperiences;
            restModel.attachments = attachments;

            const item = {
                items: [restModel],
            };

            return item;
        });
    }


    getRestData(awliProfileData) {
        const convertedProfile = this.convertProfile(awliProfileData);

        if (convertedProfile) {
            const profileConverter = profileImportConverterVerCan.getInstance();

            return Promise.resolve(convertedProfile)
                .then(restResponse => profileConverter.toLocalModel(restResponse));
        }

        return null;
    }

}

export default new AwliProfileImportConverter();
