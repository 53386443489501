import { pureComputed, observable, ObservableArray, PureComputed, Observable } from 'knockout';
import router from 'app/model/router';
import { orderFacets } from './service/orderFacets';
import { Facet } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { isAnyFilterInFacet } from '../search-filters-panel/service/isAnyFilterInFacet';
import { areRecommendedJobsEnabledWithParams } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';

type RouterQuery = {
    lastSelectedFacet?: string;
    mode?: string;
    selectedLocationsFacet?: string;
    selectedPostingDatesFacet?: string;
    selectedTitlesFacet?: string;
    selectedWorkplaceTypesFacet?: string;
    sortBy?: string;
    hotJobFlag?: boolean;
};

type Props = {
    totalCount: Observable<number>;
    defaultSearchContext: string;
    filters: ObservableArray<Facet>;
    adminMode: boolean;
    loading: Observable<boolean>;
    clear: () => void;
};

export default class SearchFiltersViewModel {
    totalCount: Observable<number>;
    filters: ObservableArray<Facet>;
    adminMode: boolean;
    filterPresentInUrl: PureComputed<boolean>;
    loading: Observable<boolean>;
    orderedFacets: PureComputed<Facet[]>;
    selectedSearchContext: Observable<string>;
    clearFilters: () => void;
    anyFilterInFacetAvailable: PureComputed<boolean>;
    areRecommendedJobsEnabled: PureComputed<boolean>;

    constructor({ totalCount, defaultSearchContext, filters, adminMode, loading, clear }: Props) {
        this.filters = filters;
        this.totalCount = totalCount;
        this.selectedSearchContext = observable(defaultSearchContext);
        this.adminMode = adminMode;
        this.loading = loading;
        this.clearFilters = clear;
        this.orderedFacets = pureComputed(() => orderFacets(this.filters()));

        this.filterPresentInUrl = pureComputed(() => {
            const { query } = router.routeParams() as { query: RouterQuery };

            return Boolean(query?.lastSelectedFacet || query?.hotJobFlag || false);
        });

        this.anyFilterInFacetAvailable = pureComputed(() => isAnyFilterInFacet(this.filters()));

        this.areRecommendedJobsEnabled = pureComputed(
            () => areRecommendedJobsEnabled() && areRecommendedJobsEnabledWithParams()
        );
    }

    onChangeSearchContext = (searchContext: string): void => {
        this.selectedSearchContext(searchContext);
    };
}
