import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import ValidatableFormElement from 'core/form/element/ValidatableElement';
import WorkLocationSelect from './WorkLocationSelect';
import UniqueWorkLocationsFormValidator from '../validator/UniqueWorkLocations';

const PREFERRED_PICKER_LABEL = i18n('apply-flow.work-preferences.preferred-location-label');
const EXCLUDED_PICKER_LABEL = i18n('apply-flow.work-preferences.excluded-location-label');

export default class WorkLocationsFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component('locations-form-element');

        this.preferredElement = null;
        this.preferredFields = ko.observableArray();

        this.preferredOptions = ko.pureComputed(() => {
            const fields = this.preferredFields();

            return fields.length ? fields[0].options : [];
        });

        this.preferredLabel = ko.observable(PREFERRED_PICKER_LABEL);

        this.excludedElement = null;
        this.excludedFields = ko.observableArray();

        this.excludedOptions = ko.pureComputed(() => {
            const fields = this.excludedFields();

            return fields.length ? fields[0].options : [];
        });

        this.excludedLabel = ko.observable(EXCLUDED_PICKER_LABEL);

        this.optionsDisabled = ko.pureComputed(() => {
            const { preferredLocations, excludedLocations } = this.value();

            return [...preferredLocations, ...excludedLocations];
        });
    }

    _resolveLabel(elements, defaultLabel) {
        if (elements.length) {
            const prompt = (elements[0].label || '').trim();

            return prompt || defaultLabel;
        }

        return defaultLabel;
    }

    _getLocations(collection) {
        return collection
            .map(location => location())
            .filter(location => Boolean(location));
    }

    _getValue() {
        this._initValue();

        return {
            preferredLocations: this._getLocations(this.preferredLocations()),
            excludedLocations: this._getLocations(this.excludedLocations()),
        };
    }

    _initValue() {
        if (!this.preferredLocations) {
            this.preferredLocations = ko.observableArray();
        }

        if (!this.excludedLocations) {
            this.excludedLocations = ko.observableArray();
        }
    }

    _setValidators() {
        const uniqueLocationsValidator = new UniqueWorkLocationsFormValidator({
            preferredLabel: this.preferredLabel(),
            excludedLabel: this.excludedLabel(),
        });

        this.addValidator(uniqueLocationsValidator);
    }

    _hasValidValue() {
        let isValid = super.isValid();

        if (this.preferredElement) {
            isValid = isValid && this.preferredElement.isValid();
        }

        if (this.excludedElement) {
            isValid = isValid && this.excludedElement.isValid();
        }

        return isValid;
    }

    _createLocationsArray(fields, formModel) {
        return fields
            .sort((field1, field2) => Boolean(field2.attributes.required) - Boolean(field1.attributes.required))
            .map(field => formModel[field.name]);
    }

    resolveOptionsDependency(formElements, formModel) {
        const preferredLocations = this._createLocationsArray(this.preferredFields(), formModel);

        this.preferredLocations(preferredLocations);

        this.preferredElement = new WorkLocationSelect({
            label: this.preferredLabel(),
            options: this.preferredOptions(),
            optionsDisabled: this.optionsDisabled,
            fields: this.preferredFields(),
            allLocations: this.preferredLocations(),
        });

        const excludedLocations = this._createLocationsArray(this.excludedFields(), formModel);

        this.excludedLocations(excludedLocations);

        this.excludedElement = new WorkLocationSelect({
            label: this.excludedLabel(),
            options: this.excludedOptions(),
            optionsDisabled: this.optionsDisabled,
            fields: this.excludedFields(),
            allLocations: this.excludedLocations(),
        });
    }

    dispose() {
        if (this.preferredElement) {
            this.preferredElement.dispose();
        }

        if (this.excludedElement) {
            this.excludedElement.dispose();
        }

        super.dispose();
    }

}