import botsEvent from 'minimal/config/events';
import { observable, Observable } from 'knockout';
import { Bot } from 'minimal/component/splash-page/config/types';
import { SignalBinding } from 'signals';

type ODAPopupData = {
    bodyText: string;
    isVisible: boolean;
    bots: Bot;
};

export class OdaSummaryViewModel {
    isDialogVisible: Observable<boolean>;
    body: Observable<string>;
    bots: Bot;
    botsEventSubscription: SignalBinding;
    editDisabled: Observable<boolean>;

    constructor() {
        this.isDialogVisible = observable<boolean>(false);
        this.bots = {} as Bot;
        this.body = observable<string>('');
        this.editDisabled = observable<boolean>(true);

        this.botsEventSubscription = botsEvent.showOdaPopup.add((odaPopupData: ODAPopupData) => {
            const { bodyText, isVisible, bots } = odaPopupData;

            this.body(bodyText);
            this.isDialogVisible(isVisible);
            this.bots = bots;
        });
    }

    action(message: string): void {
        let sendMessage = message === 'Accept' ? this.body.peek() : 'Decline';

        if (!sendMessage.length) {
            sendMessage = 'Empty Summary';
        }

        this.bots.sendMessage(sendMessage, {
            hidden: true,
        });

        const chatWrapperElement = document.querySelector('.oda-chat-wrapper') as HTMLDivElement;

        chatWrapperElement.style.pointerEvents = 'auto';
        this.isDialogVisible(false);
        this.bots.openChat();
    }

    dispose(): void {
        this.botsEventSubscription.detach();
    }
}
