import { RouterQuery, SortOption } from 'search/config/types';
import { eventsSortOptions } from 'search/model/eventsSorting';
import { isDistanceEnabled, isKeywordSearch, isLocationSearch } from 'search/service/searchTypes';

type SearchType = 'KEYWORD' | 'DISTANCE_NOT_CALCULATED' | 'DISTANCE_CALCULATED';

const KEYWORD = 'KEYWORD';
const DISTANCE_CALCULATED = 'DISTANCE_CALCULATED';
const DISTANCE_NOT_CALCULATED = 'DISTANCE_NOT_CALCULATED';

const defaultSearchType: Record<SearchType, string> = {
    [KEYWORD]: 'RELEVANCY',
    [DISTANCE_NOT_CALCULATED]: 'EVENT_DATE',
    [DISTANCE_CALCULATED]: 'DISTANCE',
};

/**
 * Method to get the search type based on the query params
 * @returns {string} Return value is one of the types in SearchTypes
 */
export const getSearchType = (query: RouterQuery): SearchType => {
    if (isKeywordSearch(query)) {
        return KEYWORD;
    }

    if (isDistanceEnabled() && isLocationSearch(query)) {
        const locationLevel = query?.locationLevel;

        if (locationLevel === 'state' || locationLevel === 'country') {
            return DISTANCE_NOT_CALCULATED;
        }

        return DISTANCE_CALCULATED;
    }

    return DISTANCE_NOT_CALCULATED;
};

export const getEventsDefaultSortOption = (query: RouterQuery): SortOption | null => {
    const searchType = getSearchType(query);

    return eventsSortOptions.find(({ code }) => code === defaultSearchType[searchType]) ?? null;
};
