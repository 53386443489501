import store from 'apply-flow/service/submit-checklist/store/store';
import i18n from 'core/i18n/i18n';

export default class ApplyFlowSubmissionProgressViewModel {

    constructor() {
        this.state = store.state;
        this.progress = store.getters.progress;
        this.ariaLabel = i18n('general.loading-screen');
    }

}
