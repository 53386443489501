import { Observable, observable, ObservableArray, PureComputed } from 'knockout';
import { SortOption } from 'search/config/types';
import i18n from 'core/i18n/i18n';

export type Props = {
    sortOptions: ObservableArray<SortOption>;
    selectedSortOption: PureComputed<SortOption>;
    onChangeSortBy: (sortOption: SortOption) => void;
};

export default class SearchSortingSelectorViewModel {
    sortOptions: ObservableArray<SortOption>;
    isSortByMenuVisible: Observable<boolean>;
    selectedSortOption: PureComputed<SortOption>;
    onChangeSortByCallback: (sortOption: SortOption) => void;
    ariaLabel: string;

    constructor({ sortOptions, selectedSortOption, onChangeSortBy }: Props) {
        this.sortOptions = sortOptions;
        this.isSortByMenuVisible = observable<boolean>(false);
        this.selectedSortOption = selectedSortOption;
        this.onChangeSortByCallback = onChangeSortBy;
        this.ariaLabel = i18n('search.sort-by-title') + ' ' + selectedSortOption().name;
        this.onChangeSortBy = this.onChangeSortBy.bind(this);
    }

    toggleSortMenu(): void {
        this.isSortByMenuVisible(!this.isSortByMenuVisible());
    }

    hideSortMenu(): void {
        this.isSortByMenuVisible(false);
    }

    onChangeSortBy(sortOption: SortOption): void {
        this.onChangeSortByCallback(sortOption);
        this.hideSortMenu();
    }

    isSortOptionSelected(sortOption: SortOption): boolean {
        return sortOption.code === this.selectedSortOption().code;
    }
}
