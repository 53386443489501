import converterFactory from 'core/l10n/converter/factory';


export default {
    format(date) {
        return this.formatShort(date);
    },

    formatNarrow(date) {
        return converterFactory.createWeekDayConverter('narrow').format(date);
    },

    formatShort(date) {
        return converterFactory.createWeekDayConverter('short').format(date);
    },

    formatLong(date) {
        return converterFactory.createWeekDayConverter('long').format(date);
    },

    parse(formattedDate) {
        return this.parseShort(formattedDate);
    },

    parseNarrow(formattedDate) {
        return converterFactory.createWeekDayConverter('narrow').parse(formattedDate);
    },

    parseShort(formattedDate) {
        return converterFactory.createWeekDayConverter('short').parse(formattedDate);
    },

    parseLong(formattedDate) {
        return converterFactory.createWeekDayConverter('long').parse(formattedDate);
    },
};