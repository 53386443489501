import { Observable, observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import interviewSchedulingEvents from '../../config/events';
import interviewSchedulingService from 'cx/module/candidate-self-service/module/interview-scheduling/service/interviewScheduling';
import notificationsService from 'cx/service/notifications';
import { requestSlotsFormBuilder } from '../../service/requestSlotsFormBuilder';
import ValidatableForm from 'app/module/core/form/ValidatableForm';
import { SignalBinding } from 'signals';
import InterviewAction from '../../model/InterviewAction';

const EMPTY_STRING = '';

type Props = {
    requestId: Observable<string>;
};

export default class RequestSlotsDialogViewModel {
    private isDialogVisible: Observable<boolean>;
    private isSlotsConfirmDialogProgress: Observable<boolean>;
    private requestId: Observable<string>;
    private comments: Observable<string>;
    private form: ValidatableForm;
    private _requestSlotsDialogShowSignal: SignalBinding;

    constructor({ requestId }: Props) {
        this.isDialogVisible = observable<boolean>(false);
        this.isSlotsConfirmDialogProgress = observable<boolean>(false);
        this.requestId = requestId;
        this.comments = observable(EMPTY_STRING);
        this.form = requestSlotsFormBuilder.createForm(this.comments);

        this._requestSlotsDialogShowSignal = interviewSchedulingEvents.showRequestSlotsDialog.add(() => {
            this.isDialogVisible(true);
            this.comments(EMPTY_STRING);
        });
    }

    closeDialog(): void {
        this.isDialogVisible(false);
    }

    confirm(): void {
        this.form.enableImmediateValidation();

        this.form.validate().then((isFormValid) => {
            if (!isFormValid) {
                return;
            }

            this.isSlotsConfirmDialogProgress(true);

            interviewSchedulingService
                .requestMoreSlots(this.requestId(), this.comments())
                .then((value: InterviewAction) => {
                    if (value.message === 'SUCCESS') {
                        notificationsService.success(
                            i18n('interview-scheduling.request-slots-confirm.messages.request-slots-success')
                        );

                        router.go('candidate-self-service');
                    } else if (value.message === 'ERROR') {
                        notificationsService.error(
                            i18n('interview-scheduling.request-slots-confirm.errors.try-again-later-error')
                        );

                        console.error(
                            i18n('interview-scheduling.request-slots-confirm.errors.try-again-later-error')
                        );
                    }
                })
                .catch((error: unknown) => {
                    notificationsService.error(
                        i18n('interview-scheduling.request-slots-confirm.errors.try-again-later-error')
                    );

                    console.error(error);
                })
                .then(() => {
                    this.isSlotsConfirmDialogProgress(false);
                    this.closeDialog();
                });
        });
    }

    dispose(): void {
        this._requestSlotsDialogShowSignal.detach();
    }
}
