import { desktopOnly, getStyle, STYLES_SEPARATOR, wrapBySelector, RTL } from '../../../service/customCss';

const DEFAULT_BACKGROUND_COLOR =
    'hsla(var(--color-secondary-hue), var(--color-secondary-saturation), var(--color-secondary-lightness), 0.5)';

export const getSeparatorStyle = ({
    separatorSelector,
    borderSelector,
    backgroundColor,
}: {
    separatorSelector: string;
    borderSelector: string;
    backgroundColor: string | null;
}): string => {
    const backgroundColorToApply = backgroundColor || DEFAULT_BACKGROUND_COLOR;
    const styles: string[] = [
        wrapBySelector(separatorSelector, getStyle('backgroundColor', backgroundColorToApply)),
        desktopOnly(
            [
                wrapBySelector(borderSelector, `border-inline-start: 1px solid ${backgroundColorToApply}`),
                wrapBySelector(
                    `${RTL} ${borderSelector}`,
                    `border-left: 0;
                    border-right: 1px solid ${backgroundColorToApply}`
                ),
            ].join(STYLES_SEPARATOR)
        ),
    ];

    return styles.join(STYLES_SEPARATOR);
};
