import { observableArray, pureComputed } from 'knockout';
import candidateModel from 'apply-flow/model/candidate';
import sectionState from 'apply-flow/model/sectionState';
import candidateAttachmentService from './service/candidateAttachmentService';
import ResumeUploadButtonViewModel from './component/attachment-upload-button/ResumeUploadButtonViewModel';
import CoverLetterUploadButtonViewModel from './component/attachment-upload-button/CoverLetterUploadButtonViewModel';
import applicationDraftService from 'apply-flow/service/applicationDraft';
import { useDraftData } from '../../service/applicationDraftMonitor';

export default class FileUploadViewModel {

    constructor({ section, code, blockId, isActive, blockProperties }) {
        const {
            resumeRequired,
            cvRequired,
            linksRequired,
        } = blockProperties;

        this.attachments = observableArray();

        this.resumeUploadButtonViewModel = new ResumeUploadButtonViewModel({ blockId: 'resume', section, required: resumeRequired });
        this.coverLetterUploadButtonViewModel = new CoverLetterUploadButtonViewModel({ blockId: 'cv', section, required: cvRequired });
        this.findResume = this.resumeUploadButtonViewModel.finderFunction;

        this.section = section;
        this.code = code;
        this.blockId = blockId;
        this.isActiveBlock = isActive;

        this.linksRequired = linksRequired;

        this.resumeAttachments = pureComputed(() =>
            this.attachments().filter(attachment => attachment.isResume()),
        );

        this.coverLetterAttachments = pureComputed(() =>
            this.attachments().filter(attachment => attachment.isCoverLetter()),
        );

        this._loadAttachments()
            .then(this._setUpAttachments.bind(this))
            .then(() => {
                sectionState.setBlockReady(this.section.number, this.blockId);
            });

        this.addResume = (data, event) => {
            this.resumeUploadButtonViewModel.onFileSelected(data, event);
        };

        this.addCoverLetter = (data, event) => {
            this.coverLetterUploadButtonViewModel.onFileSelected(data, event);
        };
    }

    _loadAttachments() {
        if (useDraftData()) {
            return Promise.resolve();
        }

        return this._getCandidateAttachments()
            .then(attachments => this._initializeAttachments(attachments));
    }

    _getCandidateAttachments() {
        const onlyMetadata = applicationDraftService.draftExists();

        return Promise.resolve(candidateModel.id())
            .then(candidateId => candidateId || Promise.reject())
            .then(candidateId => candidateAttachmentService.query(candidateId, onlyMetadata))
            .catch(() => ({
                fileAttachments: [],
                linkedSites: candidateModel.linkedSites(),
            }));
    }

    _initializeAttachments(attachments) {
        const profileAttachments = [...attachments.fileAttachments];
        const profileResumes = profileAttachments.filter(({ isResume }) => isResume());
        const profileCoverLetters = profileAttachments.filter(({ isCoverLetter }) => isCoverLetter());
        const hasOneResumeInProfile = profileResumes.length === 1;
        const hasOneCoverLetterInProfile = profileCoverLetters.length === 1;

        if (hasOneResumeInProfile) {
            const resume = profileResumes.pop();

            candidateModel.attachments.push(resume);
        }

        if (hasOneCoverLetterInProfile) {
            const cv = profileCoverLetters.pop();

            candidateModel.attachments.push(cv);
        }

        [...profileResumes, ...profileCoverLetters].forEach((attachment) => {
            candidateModel.attachments.markAsDeleted(attachment);
        });
    }

    _setUpAttachments() {
        const removedFromProfile = candidateModel.attachments.getRemovedItems()
            .filter(({ appDraftId }) => !appDraftId());

        this.attachments(removedFromProfile);
    }

}