import { COOKIE_CONSENT_WIDGET_TYPE, COOKIE_CONSENT_WIDGET_TYPES } from 'cookie-consent/types';
import { cookieConfig } from './cookieAgreement';
import customContentHelperService from 'cx/module/custom-content/service/customContentHelper';
import { PageRestData } from 'cookie-consent/types/restPageDataType';

export let cookieConsentWidgetData: PageRestData | undefined;
export let cookiePolicyWidgetData: PageRestData | undefined;
export let cookiePreferencesWidgetData: PageRestData | undefined;

export const loadWidgetData = async (
    widgetType: COOKIE_CONSENT_WIDGET_TYPES
): Promise<PageRestData | undefined> => {
    return await loadCustomPage(widgetType);
};

export const loadCookieConsentModal = async (): Promise<void> => {
    if (!cookieConsentWidgetData) {
        cookieConsentWidgetData = await loadWidgetData(COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET);
    }
};

export const loadCookiePolicyModal = async (): Promise<void> => {
    if (!cookiePolicyWidgetData) {
        cookiePolicyWidgetData = await loadWidgetData(COOKIE_CONSENT_WIDGET_TYPE.COOKIE_POLICY_WIDGET);
    }
};

export const loadCookiePreferencesModal = async (): Promise<void> => {
    if (!cookiePreferencesWidgetData) {
        cookiePreferencesWidgetData = await loadWidgetData(
            COOKIE_CONSENT_WIDGET_TYPE.COOKIE_PREFERENCES_WIDGET
        );
    }
};

export const loadCookieConsentModals = async (): Promise<void> => {
    await Promise.all([
        loadCookieConsentModal(),
        cookieConfig.isCookiePreferencesEnabled() ? loadCookiePreferencesModal() : loadCookiePolicyModal(),
    ]);
};

export const loadCustomPage = async (
    widgetType: COOKIE_CONSENT_WIDGET_TYPES
): Promise<PageRestData | undefined> => {
    try {
        const { pageCode } = customContentHelperService.getPageCodeByType(widgetType);

        return await customContentHelperService.getPageData(pageCode);
    } catch (error) {
        return Promise.resolve(undefined);
    }
};
