import { notReachable } from 'app/types/utils';
import { DisplayStyle } from 'cx/module/search/config/types';

type TileComponent = {
    tile: string;
    talentCommunityTile: string;
};

const TILE_COMPONENTS_ACTIVE: Record<DisplayStyle, TileComponent> = {
    list: {
        tile: 'search-event-result-list-item',
        talentCommunityTile: 'talent-community-tile',
    },
    tile: {
        tile: 'search-event-result-grid-item',
        talentCommunityTile: 'talent-community-tile',
    },
    toggleListMap: {
        tile: 'search-event-result-list-item',
        talentCommunityTile: 'talent-community-tile',
    },
    toggleTileMap: {
        tile: 'search-event-result-grid-item',
        talentCommunityTile: 'talent-community-tile',
    },
};

export const getTileComponents = (eventDisplayStyle: DisplayStyle, siteType: 'active'): TileComponent => {
    if (siteType === 'active') {
        return TILE_COMPONENTS_ACTIVE[eventDisplayStyle];
    }

    return notReachable(siteType);
};
