import { MarkerSearchResultsModel } from '../model/MarkerSearchResults';
import searchService from 'cx/module/search/service/search';
import { mapJobDetailsFromRest } from 'search/mapper/searchMarkers';
import router from 'app/model/router';
import { SearchJobResultDetailResponse, RouterParams } from '../config/types';
import { RouterQuery } from 'cx/module/search/config/types';

const SEARCH_LIMIT = 25;

const calculateStartOffset = (offset: number): number => {
    return SEARCH_LIMIT * (offset || 0);
};

export class LoadMoreSearchService {
    markerSearchResultsModel: MarkerSearchResultsModel;
    locationId: string[];

    constructor(markerSearchResults: MarkerSearchResultsModel, locationId: string[]) {
        this.markerSearchResultsModel = markerSearchResults;

        this.locationId = locationId;
    }

    handleSearchJobsResult({ result }: SearchJobResultDetailResponse): void {
        const requisitionDetailList = mapJobDetailsFromRest(result).filter((location) =>
            this.locationId.includes(location.locationId)
        );

        const endOffset = calculateStartOffset(this.markerSearchResultsModel.offset()) + SEARCH_LIMIT;

        const hasMore = endOffset < this.markerSearchResultsModel.totalCount();

        this.markerSearchResultsModel.offset(this.markerSearchResultsModel.offset() + 1);

        this.markerSearchResultsModel.results.push(...requisitionDetailList);
        this.markerSearchResultsModel.hasMore(hasMore);
        this.markerSearchResultsModel.appending(false);
    }

    handleSearchJobsError(error: string | undefined): void {
        console.error(error);
        this.markerSearchResultsModel.appending(false);
    }

    async performSearch(query: RouterQuery): Promise<SearchJobResultDetailResponse> {
        return searchService.markerJobDetails(query);
    }

    searchJobs(): Promise<SearchJobResultDetailResponse | void> {
        const { query } = router.routeParams() as RouterParams;

        this.markerSearchResultsModel.appending(true);

        const startOffset = calculateStartOffset(this.markerSearchResultsModel.offset());

        const requisitionIds = this.markerSearchResultsModel
            .requisitionIdList()
            .slice(startOffset, startOffset + SEARCH_LIMIT)
            .join(',');

        const updatedQuery = Object.assign({}, query, {
            offset: this.markerSearchResultsModel.offset(),
            limit: SEARCH_LIMIT,
            requisitionIds,
        });

        return this.performSearch(updatedQuery)
            .then(this.handleSearchJobsResult.bind(this))
            .catch(this.handleSearchJobsError.bind(this));
    }
}
