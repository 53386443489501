import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import MetadataRepository from '../../../service/Metadata';
import { ORA_QUESTIONNAIRE } from '../../../enum/questionnaireBlocks';

import ViewModel from '../../../QuestionnaireViewModel';
import template from '../../../questionnaire.html';

components.register(ORA_QUESTIONNAIRE.componentName, { viewModel: ViewModel, template });

metadataConfig.registerBlock(ORA_QUESTIONNAIRE.blockCode, {
    component: ORA_QUESTIONNAIRE.componentName,
    repository: MetadataRepository,
});
