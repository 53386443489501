import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { CategoryTilesCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';

export const mapParamsFromRest = (params: RestParam[]): CategoryTilesCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    const contentParams = paramValue ? JSON.parse(paramValue) : {};

                    return {
                        width: contentParams.width || emptyParams.content.width,
                        widthUnit: contentParams.widthUnit || emptyParams.content.widthUnit,
                        height: contentParams.height || emptyParams.content.height,
                        heightUnit: contentParams.heightUnit || emptyParams.content.heightUnit,
                        tileStyle: contentParams.tileStyle || emptyParams.content.tileStyle,
                        tileVerticalAlignment:
                            contentParams.tileVerticalAlignment || emptyParams.content.tileVerticalAlignment,
                        selectedSubComponent: '',
                    };

                case 'categoryTitleTypography':
                case 'categoryTitleHoverTypography':
                    return mapTypography(paramValue);

                case 'containerStyling':
                case 'overallStyling':
                case 'containerHoverStyling':
                case 'categoryTitleStyling':
                case 'categoryTitleHoverStyling':
                case 'categoryTileStyling':
                case 'categoryTileHoverStyling':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return null;
            }
        },
    });
};
