import { pureComputed, observable, utils, toJS } from 'knockout';
import MultiSelectPillFormElementValue from './model/MultiSelectPillFormElementValue';
import MultiSelectPillFormElementViewModel from './MultiSelectPillFormElementViewModel';

const DEFAULT_CONFIG = {
    markSelectedDisabled: false,
    possibleValueValue: 'value',
    possibleValueName: 'name',
};

export default class MultiSelectPillDictionaryFormElementViewModel extends MultiSelectPillFormElementViewModel {

    constructor({ model, filterRepository, config = null }) {
        super({ model, filterRepository });

        this._currentValue = observable([]);

        this._config = Object.assign({}, DEFAULT_CONFIG, config || {});
        this._dictionaryPossibleValues = [];

        this.currentValue = pureComputed({
            read() {
                return this._currentValue();
            },

            write(options) {
                const value = options || null;
                const valueKey = this._config.possibleValueValue;
                const [match] = this._dictionaryPossibleValues.filter(item => item[valueKey] === value);

                if (match) {
                    const [isDuplicate] = this.filters()
                        .filter(filter => this._valuesMatch(filter.value, match[valueKey]));

                    if (!isDuplicate) {
                        this.addFilter(new MultiSelectPillFormElementValue({
                            value: match[valueKey],
                            label: match[this._config.possibleValueName],
                        }));
                    }
                }

                this._currentValue([]);
            },

            owner: this,
        });

        this._fetchMissingLabels();
    }

    _valuesMatch(addedFiltersValue, selectedFilterValue) {
        if (typeof addedFiltersValue === 'object') {
            return JSON.stringify(toJS(addedFiltersValue)) === JSON.stringify(toJS(selectedFilterValue));
        }

        return addedFiltersValue() === selectedFilterValue;
    }

    _fetchFromRepository(repositoryMethod, fetchConfig) {
        return repositoryMethod(fetchConfig)
            .then((data) => {
                if (this._config.markSelectedDisabled) {
                    this.filters().forEach((filter) => {
                        const valueToMarkAsDisabled = utils.arrayFirst(data, value => value.id === filter.value());

                        if (valueToMarkAsDisabled) {
                            valueToMarkAsDisabled.disabled = true;
                        }
                    });
                }

                this._dictionaryPossibleValues = data;

                return data;
            })
            .catch(this._handleErrors);
    }

    fetchLabelForValue(/* value */) {
        throw new Error('Not implemented');
    }

    _fetchMissingLabels() {
        this.filters()
            .filter(filter => !filter.label())
            .forEach((filter) => {
                this.fetchLabelForValue(filter.value())
                    .then(({ name }) => filter.label(name))
                    .catch(this._handleErrors);
            });
    }

}
