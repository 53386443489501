import { Observable, observable, PureComputed, pureComputed, Subscription } from 'knockout';
import candidateSelfServiceEvents from 'cx/module/candidate-self-service/config/events';
import cxEvents from 'cx/config/events';
import draftApplicationsModel from 'cx/module/candidate-self-service/model/draftApplications';
import applicationsModel from 'cx/module/candidate-self-service/model/applications';
import siteListService from 'cx/module/candidate-self-service/service/siteList';
import applicationService from 'apply-flow/service/application';
import { setApplicationsSiteNames } from 'cx/module/candidate-self-service/service/applicationsSiteNames';
import { loadDrafts } from 'cx/module/candidate-self-service/service/loadDrafts';
import router from 'app/model/router';
import lightCandidateService from 'cx/module/candidate-self-service/service/lightCandidate';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import tokenService from 'cx/module/candidate-verification/service/token';
import scrollKeeper from 'minimal/service/scrollKeeper';
import CandidateChallengeAbstractViewModel from 'cx/module/candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import challengeService from 'cx/module/candidate-verification/service/challenge';
import {
    ATTEMPS_LIMIT_REACHED,
    PIN_LIMIT_REACHED,
} from 'cx/module/candidate-verification/config/pinVerificationStatus';
import { Application } from 'apply-flow/config/types';
import { Token } from 'cx/module/candidate-verification/config/types';
import { CandidatePreferences, RouteParams } from 'minimal/module/candidate-self-service/config/types';
import { CssTab } from './config/types';
import { areEventsEnabledGlobally, areEventsEnabledLocally } from 'app/service/areEventsEnabled';
import { isEventTabVisible } from 'minimal/module/candidate-self-service/service/eventTabVisibility';
import { LOADED } from 'candidate-self-service/enum/loadingStatus';
import { LoadingStatus, eventsState } from 'minimal/module/candidate-self-service/service/EventsState';
import { SiteList } from 'app/module/cx/module/candidate-self-service/config/types';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

const CSS_MAIN_ROUTE = 'candidate-self-service';
const CSS_EVENTS_ROUTE = 'candidate-self-service.events';
const CSS_INFO_ROUTE = 'candidate-self-service.info';
const CSS_EVENTS_ROUTE_TOKEN = 'candidate-self-service-with-token.events';

export default class CandidateSelfServiceViewModel extends CandidateChallengeAbstractViewModel {
    private candidateFullName: Observable<string | null>;
    private candidateEmail: Observable<string | null>;
    private candidateNumber: Observable<string | null>;
    private isCandidateCwk: Observable<boolean>;

    private applicationsLoaded: Observable<boolean>;
    private lastRequisitionId: Observable<number>;

    private isTCSectionReady: Observable<boolean>;

    private routeSubscription: Subscription;

    showJobsToCandidate: PureComputed<boolean>;
    isJobsToCandidateComponentReady: Observable<boolean>;
    showSkeletonForJobsToCandidate: PureComputed<boolean>;
    showSkeletonForTCSection: PureComputed<boolean>;

    enableOptInCSS: Observable<boolean>;
    isPinRequired: Observable<boolean>;
    showDeleteProfileLink: PureComputed<boolean>;

    shouldRenderEditProfile: PureComputed<boolean>;

    activeTab: Observable<CssTab>;
    eventTabVisibility: PureComputed<boolean>;
    tabLoadingStatus: PureComputed<LoadingStatus>;
    isTCOptInEnabled: boolean;

    constructor() {
        super();

        this.candidateFullName = observable(null);
        this.candidateEmail = observable(null);
        this.candidateNumber = observable(null);
        this.isCandidateCwk = observable<boolean>(false);

        this.applicationsLoaded = observable<boolean>(false);
        this.lastRequisitionId = observable(0);

        this.isTCSectionReady = observable<boolean>(false);

        this.showJobsToCandidate = pureComputed<boolean>(() => this.lastRequisitionId() > 0);
        this.isJobsToCandidateComponentReady = observable<boolean>(false);

        this.showSkeletonForJobsToCandidate = pureComputed<boolean>(
            this.shouldShowSkeletonForJobsToCandidate,
            this
        );

        this.showSkeletonForTCSection = pureComputed(this.shouldShowSkeletonForTCSection.bind(this));

        this.enableOptInCSS = observable<boolean>(false);
        this.isPinRequired = observable<boolean>(false);
        this.showDeleteProfileLink = pureComputed(() => !this.isCandidateCwk());

        this.shouldRenderEditProfile = pureComputed(() =>
            Boolean(this.candidateNumber() && !this.isPinRequired())
        );

        this.isTCOptInEnabled = isTCOptInEnabled();

        this.reloadUser();

        this.initialize = this.initialize.bind(this);

        candidateSelfServiceEvents.reloadUser.add(this.reloadUser.bind(this));

        this.activeTab = observable('applications' as CssTab);

        this.setActiveTab();

        if (areEventsEnabledGlobally() && eventsState.loadingStatus() === LOADED) {
            eventsState.loadEvents();
        }

        this.eventTabVisibility = pureComputed(() => isEventTabVisible());

        this.tabLoadingStatus = pureComputed(() => {
            if (areEventsEnabledGlobally() && !areEventsEnabledLocally()) {
                return eventsState.loadingStatus();
            }

            return LOADED;
        });

        this.routeSubscription = router.route.subscribe(() => this.setActiveTab());
    }

    reloadUser(): void {
        this.resetScroll();
        this.initialize();
    }

    onPinValid(): void {
        this.isPinRequired(false);
        this.initialize();
    }

    dispose(): void {
        this.routeSubscription.dispose();
    }

    protected async initialize(): Promise<void> {
        if (challengeService.isChallengeRequired()) {
            await this._triggerChallenge()
                .then(this.onTriggeredChallenge.bind(this))
                .catch((error: string) => {
                    this.handleError(error);

                    this.isPinRequired(challengeService.isChallengeRequired());
                });

            if (this.isPinRequired()) {
                cxEvents.loaded.dispatch();

                return;
            }
        }

        this.initializeWithToken();
    }

    private onTriggeredChallenge(challengeRequired: boolean): void {
        this.handleMergedCandiate();
        this.isPinRequired(challengeRequired);
    }

    protected initializeWithToken(): void {
        this.verifyToken()
            .then(this.onTokenVerified)
            .then(this.loadCandidateData.bind(this))
            .then(this.getPreferencesOptIn.bind(this))
            .catch(this.handleError.bind(this))
            .then(cxEvents.loaded.dispatch);
    }

    private verifyToken(): Promise<Token> {
        if (tokenService.accessCodeExists()) {
            return Promise.resolve(tokenService.get());
        }

        const routeParams = router.routeParams() as RouteParams;

        return tokenService.verifyToken(routeParams.token);
    }

    private onTokenVerified(token: Token): Token {
        cxEvents.loaded.dispatch();

        return token;
    }

    private async loadCandidateData(token: Token): Promise<void[]> {
        draftApplicationsModel.clear();
        applicationsModel.clear();

        const siteList = await siteListService.getSiteList();

        return Promise.all([
            this.loadApplications(siteList),
            loadDrafts(siteList),
            this.loadEvents(),
            this.loadCandidate(token),
        ]);
    }

    private loadApplications(siteList: SiteList): Promise<void> {
        return applicationService
            .getAll()
            .then((applications: Application[]) => setApplicationsSiteNames(applications, siteList))
            .then(this.setApplications.bind(this));
    }

    private async setApplications(applications: Application[]): Promise<void> {
        if (applications.length) {
            const appliedJobs = await applicationService.getAppliedJobs(applications);
            const lastPostedRequisitionId = applicationService.getLastPostedApplicationReqId(
                applications,
                appliedJobs
            );

            applicationService.setRequisitionDetails(applications, appliedJobs);

            this.lastRequisitionId(lastPostedRequisitionId);
        }

        applicationsModel.setLoadedData(applications);

        this.applicationsLoaded(true);
    }

    private loadCandidate(token: Token): Promise<void> {
        return lightCandidateService
            .get(token.candidateNumber)
            .then(({ displayName, email, candidateNumber, cwkCandidateFlag }) => {
                this.candidateFullName(displayName);
                this.candidateEmail(email);
                this.candidateNumber(candidateNumber);
                this.isCandidateCwk(cwkCandidateFlag);
            });
    }

    private getPreferencesOptIn(): void {
        return jobAlertService
            .getPreferences(this.candidateNumber())
            .then((response: CandidatePreferences) => {
                this.enableOptInCSS(response.optIn !== undefined);
            })
            .finally(() => {
                this.isTCSectionReady(true);
            });
    }

    private handleError(error: string): void {
        if (error === 'token-invalid' || error === 'token-expired') {
            router.go('home-page');

            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            this.isPinRequired(true);

            return;
        }

        super._handleError(error);
    }

    private shouldShowSkeletonForJobsToCandidate(): boolean {
        if (!this.applicationsLoaded()) {
            return true;
        }

        if (this.applicationsLoaded() && !this.showJobsToCandidate()) {
            return false;
        }

        return !this.isJobsToCandidateComponentReady();
    }

    private shouldShowSkeletonForTCSection(): boolean {
        const isTCSectionRendered = this.enableOptInCSS() || this.isTCOptInEnabled;

        return !this.isTCSectionReady() && !isTCSectionRendered;
    }

    private resetScroll(): void {
        scrollKeeper.scrollTo(0);
    }

    private setActiveTab() {
        if (router.isActive(CSS_EVENTS_ROUTE) || router.isActive(CSS_EVENTS_ROUTE_TOKEN)) {
            this.activeTab('events');

            return;
        }

        if (router.isActive(CSS_INFO_ROUTE)) {
            this.activeTab('info');

            return;
        }

        this.activeTab('applications');
    }

    onTabChanged(tab: CssTab): void {
        if (tab === 'events') {
            router.go(CSS_EVENTS_ROUTE);

            return;
        }

        if (tab === 'info') {
            router.go(CSS_INFO_ROUTE);

            return;
        }

        router.go(CSS_MAIN_ROUTE);
    }

    private loadEvents(): Promise<void> {
        if (!areEventsEnabledGlobally()) {
            return Promise.resolve();
        }

        return eventsState.loadEvents();
    }
}
