import { unwrap } from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class NotInExistingItemsValidator extends FormValidator {

    constructor(options) {
        super(options);

        if (options.message) {
            this._message = options.message;
        }
    }

    _validate(value, options) {
        const { fieldsToCheck } = options;
        const newItem = unwrap(options.newItem);
        const existingItems = unwrap(options.existingItems);

        if (!newItem || !existingItems) {
            return true;
        }

        const [itemExists] = existingItems
            .filter(item => item !== newItem)
            .filter(item =>
                fieldsToCheck
                    .map(key => unwrap(newItem[key]) === unwrap(item[key]))
                    .every(same => same),
            );

        return !itemExists;
    }

}