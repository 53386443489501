import { observable, observableArray, pureComputed } from 'knockout';
import sectionValidator from 'cx/module/apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';

const DEFAULT_ADD_BUTTON_LABEL = i18n('apply-flow.profile-item.add-item-button');

export default class ApplyFlowTileFormViewModel {

    constructor({ section, code, blockId, model, runCustomJsValidation }) {
        this.sectionNumber = section.number;
        this.code = code;
        this.blockId = blockId;
        this.model = model;
        this.runCustomJsValidation = runCustomJsValidation;

        this.addButtonLabel = DEFAULT_ADD_BUTTON_LABEL;

        this.formBuilder = formBuilder;
        this.formElements = [];

        this.activeForm = observable();
        this.forms = observableArray();

        this.hasActiveForm = pureComputed({
            read: () => Boolean(this.activeForm()),
            write: (state) => {
                if (!state) {
                    this.done(this.activeForm());
                }
            },
        });
    }

    removeForm(form) {
        this._removeForm(form);
        this._hideDialog();
        this.runCustomJsValidation();
    }

    done(form) {
        if (form.isEmpty()) {
            this._removeForm(form);
        }

        form.buildTile();

        this._hideDialog();
        this.runCustomJsValidation();
    }

    addNewForm(model, config) {
        if (!model) {
            throw new Error('You must provide form model!');
        }

        const form = this.insertForm(model, config);

        this.editForm(form());
    }

    insertForm(model, config = { elements: this.formElements }) {
        const form = observable(this.formBuilder.createForm(config));

        form().registerModel(model);
        form().buildTile();

        sectionValidator.registerForm(form, this.sectionNumber);
        this.forms.push(form);

        return form;
    }

    editForm(form) {
        this.activeForm(form);
    }

    setBlockReady() {
        sectionState.setBlockReady(this.sectionNumber, this.blockId);
    }

    _hideDialog() {
        this.activeForm(null);
    }

    _removeForm(form) {
        const [removedForm] = this.forms().filter(_form => _form() === form);

        sectionValidator.unregisterForm(removedForm, this.sectionNumber);

        this.model.remove(removedForm().model());
        this.forms.remove(removedForm);
    }

}