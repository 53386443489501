import { observableArray, observable } from 'knockout';
import { LOADED, PENDING } from '../enum/loadingStatus';

const applicationsModel = {
    applications: observableArray([]),
    loadingStatus: observable(PENDING),

    clear() {
        applicationsModel.applications([]);
        applicationsModel.loadingStatus(PENDING);
    },

    setLoadedData(applications) {
        applicationsModel.applications(applications);
        applicationsModel.loadingStatus(LOADED);
    },

    getByJobId(jobId) {
        function checkForPipeline(application) {
            return application.isPipeline()
                && application.hiringJobApplications().some(hiringApplication => hiringApplication.jobId() === jobId);
        }

        return this.applications()
            .filter(application => application.jobId() === jobId || checkForPipeline(application))
            .shift() || {};
    },

    remove(application) {
        this.applications.remove(application);
    },

    update(application, updatedApplication) {
        if (updatedApplication.isConfirmed() !== undefined) {
            application.isConfirmed(updatedApplication.isConfirmed());
        }

        application.isActive(updatedApplication.isActive());
        application.status(updatedApplication.status());
        application.currentStateId(updatedApplication.currentStateId());
        application.hasAssessment(updatedApplication.hasAssessment());
        application.hasTaxCredit(updatedApplication.hasTaxCredit());
        application.secondarySubmissions(updatedApplication.secondarySubmissions());
        application.assessmentLinks(updatedApplication.assessmentLinks());
        application.backgroundCheckLinks(updatedApplication.backgroundCheckLinks());
        application.isActionInProgress(false);
    },
};

export default applicationsModel;