import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(model) {
            const form = formBuilder.createForm();

            form.elements([this._createEmailFormElement(model.email)]);

            return form;
        },
    },

    _createEmailFormElement: {
        value(model) {
            const label = i18n('apply-flow.section-personal-information.primary-email-label');

            const formElement = formElementFactory.create('email', 'primary-email');

            formElement.addValidator('required', { label }, 'prepend');
            formElement.addValidator('maxLength', { max: 240 });
            formElement.label(label);

            formElement.attributes({
                id: 'primary-email',
                autocomplete: 'email',
                'aria-label': label,
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});
