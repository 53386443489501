import {
    RouterParams,
    RouterQuery,
    SearchContext,
    searchRouteMap,
    SearchRouteOnly,
    SEARCH_RESULTS_STRINGS,
    StorageParams,
} from 'cx/module/search/config/types';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import storageService from 'core/storage/sessionStorage';
import baseRouter from 'app/model/router';
import { areEventsEnabled } from 'app/service/areEventsEnabled';

export const saveSearchContextDetails = (searchContext: SearchContext, storageValue: RouterParams): void => {
    let storageKey: StorageParams = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;

    switch (searchContext) {
        case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS:
            storageKey = SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS;
            break;
        case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS:
            storageKey = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;
            break;
        default:
            break;
    }

    storageService.store(storageKey, storageValue);
};

export const getSearchContextDetails = (searchContext: SearchContext): RouterParams => {
    let retrievalKey: StorageParams = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;

    switch (searchContext) {
        case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS:
            retrievalKey = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;
            break;
        case SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS:
            retrievalKey = SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS;
            break;
        default:
            break;
    }

    return storageService.restore(retrievalKey);
};

export const saveSearchContextParamInStorage = (
    searchParam: StorageParams,
    storageValue: RouterParams
): void => {
    storageService.store(searchParam, storageValue);
};

export const updateSearchContextStorageDetails = (query: RouterQuery): void => {
    const jobsStoreQuery = mergeQueries(
        getSearchContextDetails(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS)?.query,
        query
    );
    const eventsStoreQuery = mergeQueries(
        getSearchContextDetails(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS)?.query,
        query
    );
    const jobsQuery = new SearchQueryBuilder(jobsStoreQuery).build();
    const eventsQuery = new SearchQueryBuilder(eventsStoreQuery).build();

    saveSearchContextParamInStorage(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS, {
        query: jobsQuery as RouterParams,
    });

    saveSearchContextParamInStorage(SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS, {
        query: eventsQuery as RouterParams,
    });
};

export const mergeQueries = (queryFromStorage: RouterQuery, queryFromRouter: RouterQuery): RouterQuery => {
    if (!queryFromStorage) {
        return { ...queryFromRouter };
    }

    if (JSON.stringify(queryFromStorage) === JSON.stringify(queryFromRouter)) return queryFromRouter;

    // Do not merge the facets and sort option as they are different for both contexts
    const routerQueryBuilder = new SearchQueryBuilder(queryFromRouter);

    queryFromRouter = routerQueryBuilder.withoutFacets().withoutSortOption().build();

    const storageQueryBuilder = new SearchQueryBuilder(queryFromStorage);

    storageQueryBuilder.clearSearchBoxCriteria();

    // Taking new keyword and location criteria into consideration from router
    return {
        ...storageQueryBuilder.build(),
        ...queryFromRouter,
    };
};

export const removeCurrentSearchContextStorageDetails = (): void => {
    const route = baseRouter.route().id;

    switch (route) {
        case searchRouteMap.events:
            storageService.remove(SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS);
            break;
        case searchRouteMap.jobs:
            storageService.remove(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS);
            break;
        default:
            break;
    }
};

export const getStoredQuery = (searchContext: SearchContext): RouterQuery => {
    const { query: routerQuery } = baseRouter.routeParams() as { query: RouterQuery };

    const storedQuery = getSearchContextDetails(searchContext) as RouterParams;

    return areEventsEnabled() && storedQuery ? storedQuery.query : routerQuery;
};

export const getSearchContextParam = (routeId: SearchRouteOnly): StorageParams => {
    let storageKey: StorageParams = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;

    switch (routeId) {
        case SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS:
            storageKey = SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS;
            break;
        case SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_JOBS:
            storageKey = SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS;
            break;
        default:
            break;
    }

    return storageKey;
};

export const clearSearchParams = (): void => {
    storageService.remove(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS);
    storageService.remove(SEARCH_RESULTS_STRINGS.STORAGE_EVENTS_PARAMS);
};
