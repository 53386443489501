import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';
import ESignatureValidator from '../module/e-signature/validator/ESignatureValidator';
import applyFlowEvents from 'apply-flow/config/events';

export default Object.create(formBuilder, {
    createForm: {
        value(model) {
            const form = formBuilder.createForm();

            form.elements(this._createNameFormElement(model));

            return form;
        },
    },

    _createNameFormElement: {

        value({ eSignature, candidate }) {
            const formElement = formElementFactory.create('text', 'fullName');

            const esignValidator = new ESignatureValidator({
                lastName: candidate.basicInformation.lastName,
            });

            const label = i18n('apply-flow.section-personal-information.fullname-label');

            formElement
                .registerModel(eSignature.fullName)
                .label(i18n('apply-flow.section-personal-information.fullname-label'))
                .attributes('autocomplete', 'off');

            formElement.addValidator('required', { label });
            formElement.addValidator('maxLength', { max: 240 });
            formElement.addValidator(esignValidator);

            this._invalidESignatureSignal = applyFlowEvents.invalidESignature.add(() => {
                formElement.isValidElement(false);
                formElement.getErrorMessage(i18n(esignValidator._message));
            });

            return formElement;
        },
    },

});