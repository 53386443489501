import { utils, bindingHandlers, Observable, observable } from 'knockout';
import { SCROLL_LEFT, SCROLL_RIGHT } from '../config/scroll';
import { sideScroll } from '../service/sideScroll';
import {
    CLASS_ARROW_RIGHT_ACTIVE,
    CLASS_ARROW_RIGHT_DISABLED,
    CLASS_ARROW_LEFT_ACTIVE,
    CLASS_ARROW_LEFT_DISABLED,
    LINKS_BLOCK_ID,
    LINKS_BLOCK,
    LEFT_ARROW_ID,
    RIGHT_ARROW_ID,
    SUBMENU_BLOCK,
    LINKS,
    ARROW_DOWN_SUBLINKS,
    INLINE,
    NONE,
    BLOCK_DISPLAY,
    CLICK,
    KEY_DOWN,
    DATA_CLICK_ATTRIBUTE,
} from './constants';

type dataType = {
    scrollAmount: Observable<number>;
    scrollElement: HTMLDivElement | null;
    isScrollable: Observable<boolean>;
    isSublinkOpened: Observable<boolean>;
    currActiveArrowDown: HTMLElement | null;
    scrollEndReached: Observable<boolean>;
    arrowLeft: HTMLDivElement | null;
    arrowRight: HTMLDivElement | null;
    sublinksBlock: HTMLCollectionOf<HTMLElement> | [];
    currentHoverOverOffset: number;
    isRtl: Observable<boolean>;
};

type KeyEvent = Event & {
    keyCode: number;
};

const data: dataType = {
    scrollElement: null,
    scrollAmount: observable(0),
    scrollEndReached: observable<boolean>(false),
    isScrollable: observable<boolean>(false),
    isSublinkOpened: observable<boolean>(false),
    currActiveArrowDown: null,
    arrowLeft: null,
    arrowRight: null,
    sublinksBlock: [],
    currentHoverOverOffset: 0,
    isRtl: observable<boolean>(false),
};

const forward = (): void => {
    if (data.scrollEndReached() && !data.isRtl()) {
        return;
    }

    sideScroll({
        element: <HTMLDivElement>data.scrollElement,
        direction: SCROLL_RIGHT,
        scrollAmount: data.scrollAmount,
        scrollEndReached: data.scrollEndReached,
        callback: () => {
            calculate();
            calcTabIndexes();
        },
    });
};

const forwardByEnter = (event: KeyEvent) => {
    if (event.keyCode == 13) {
        forward();
    }
};

const backward = (): void => {
    if (data.scrollAmount() <= 0 && !data.isRtl()) {
        return;
    }

    sideScroll({
        element: <HTMLDivElement>data.scrollElement,
        direction: SCROLL_LEFT,
        scrollAmount: data.scrollAmount,
        scrollEndReached: data.scrollEndReached,
        callback: () => {
            calculate();
            calcTabIndexes();
        },
    });
};

const backwardByEnter = (event: KeyEvent) => {
    if (event.keyCode == 13) {
        backward();
    }
};

const documentClickOutside = () => {
    if (!data.isSublinkOpened() && data.currActiveArrowDown) {
        return;
    }

    const sublinksMenus = document.querySelectorAll(SUBMENU_BLOCK) as NodeListOf<HTMLElement>;

    sublinksMenus.forEach((menu) => {
        menu.style.display = '';
        menu.parentElement?.removeAttribute('aria-expanded');
    });

    data.currActiveArrowDown = null;
    data.isSublinkOpened(false);
};

let menuDefaultTop: string | null = null;

const setSublinksPosition = (event: Event, el: Element) => {
    let eventElement = event.target as HTMLSpanElement;

    // if this event comes from keyboard, we have to position menu against LI element, not against arrow_down
    if (eventElement.classList.contains('button__arrow--down')) {
        eventElement = (eventElement.parentElement as HTMLSpanElement).children[0] as HTMLSpanElement;
    }

    const newOffset = Math.floor(eventElement.getBoundingClientRect().left / 10) * 10;

    if (Math.abs(data.currentHoverOverOffset - newOffset) > 15) {
        data.currentHoverOverOffset = newOffset;
    }

    const sublinksBlock = el.querySelector(SUBMENU_BLOCK);

    (sublinksBlock as HTMLElement).style.left =
        data.currentHoverOverOffset - 50 > 0 ? `${data.currentHoverOverOffset - 50}px` : '0px';

    if (!menuDefaultTop) {
        menuDefaultTop = window.getComputedStyle(sublinksBlock as HTMLElement).top;
    }

    (sublinksBlock as HTMLElement).style.top = computeSublinksIntendation(
        sublinksBlock as HTMLElement,
        el,
        menuDefaultTop
    );

    (sublinksBlock as HTMLElement).style.left = computeSublinksIntendation(
        sublinksBlock as HTMLElement,
        el,
        window.getComputedStyle(sublinksBlock as HTMLElement).left,
        true
    );

    return sublinksBlock;
};

const computeSublinksIntendation = (
    sublinksBlock: HTMLElement,
    el: Element,
    defaultValue: string,
    isLeftIntendation?: boolean
) => {
    let result = 0;

    if (!sublinksBlock || !el) {
        return defaultValue;
    }

    if (window.getComputedStyle(el).margin) {
        result += parseInt(window.getComputedStyle(el).margin);
    }

    if (window.getComputedStyle(el).padding) {
        result += parseInt(window.getComputedStyle(el).padding);
    }

    return parseInt(defaultValue) + (isLeftIntendation ? result * 3 : result) + 'px';
};

const getSublinksBlock = (context: HTMLElement) => {
    return context.querySelector(SUBMENU_BLOCK) as HTMLElement;
};

const showSublinksBlock = (sublinksBlock: HTMLElement) => {
    sublinksBlock.style.display = BLOCK_DISPLAY;
    sublinksBlock.setAttribute('aria-expanded', 'true');

    const currSublinkParent = sublinksBlock.parentElement as HTMLElement;
    const currSublinkParentText = (currSublinkParent.children[0] as HTMLElement).innerText;

    sublinksBlock.setAttribute('aria-controls', currSublinkParentText.replace(/ /g, '_'));
};

const calcOffsetForSublinks = () => {
    const liItems = document.querySelectorAll(LINKS);

    for (const el of liItems) {
        if (el.children.length <= 1) {
            continue;
        }

        const arrowDown = el.querySelector(ARROW_DOWN_SUBLINKS) as HTMLElement;

        if (!arrowDown.getAttribute(DATA_CLICK_ATTRIBUTE)) {
            arrowDown.addEventListener(CLICK, (event) => {
                event.stopPropagation();
                event.preventDefault();

                if (data.currActiveArrowDown && event.target != data.currActiveArrowDown) {
                    documentClickOutside();

                    return;
                }

                const sublinksBlock = setSublinksPosition(event, el) as HTMLElement;

                if (data.isSublinkOpened()) {
                    sublinksBlock.style.display = '';
                    sublinksBlock.removeAttribute('aria-expanded');
                    sublinksBlock.removeAttribute('aria-controls');

                    data.isSublinkOpened(false);
                    data.currActiveArrowDown = null;
                    el.removeAttribute('aria-expanded');

                    return false;
                }

                showSublinksBlock(sublinksBlock);
                el.setAttribute('aria-expanded', 'true');
                data.isSublinkOpened(true);
                data.currActiveArrowDown = arrowDown;
            });
        }

        arrowDown.setAttribute(DATA_CLICK_ATTRIBUTE, 'true');

        arrowDown.addEventListener(KEY_DOWN, (event) => {
            if ((event as KeyEvent).keyCode == 13) {
                const sublinksBlock = setSublinksPosition(event, el) as HTMLElement;

                showSublinksBlock(sublinksBlock);
            }

            if ((event as KeyEvent).keyCode == 27) {
                getSublinksBlock(el as HTMLElement).style.display = '';
                getSublinksBlock(el as HTMLElement).removeAttribute('aria-expanded');
                getSublinksBlock(el as HTMLElement).removeAttribute('aria-controls');
            }

            if ((event as KeyEvent).keyCode == 9) {
                const sublinksBlockUl = getSublinksBlock(el as HTMLElement).querySelector('ul');
                const howManyChildren = (sublinksBlockUl as HTMLElement).childElementCount;
                const sublinksBlockLi = sublinksBlockUl?.querySelectorAll('li');

                if (sublinksBlockLi) {
                    const lastSublink = sublinksBlockLi[howManyChildren - 1] as HTMLElement;

                    lastSublink.addEventListener(KEY_DOWN, (evt) => {
                        if ((evt as KeyEvent).keyCode == 9) {
                            getSublinksBlock(el as HTMLElement).style.display = '';
                        }
                    });
                }
            }
        });
    }
};

const calculate = (): void => {
    const isScrollable =
        (data.scrollElement && data.scrollElement.scrollWidth > data.scrollElement.clientWidth) ?? false;
    const isRtl = document.dir == 'rtl';

    data.isScrollable(isScrollable);
    data.isRtl(isRtl);

    if (data.arrowLeft) {
        data.arrowLeft.style.display = data.isScrollable() ? INLINE : NONE;

        if (data.scrollAmount() == 0 || (data.scrollAmount() < 0 && !data.isRtl())) {
            data.arrowLeft.classList.remove(CLASS_ARROW_LEFT_ACTIVE);
            data.arrowLeft.classList.add(CLASS_ARROW_LEFT_DISABLED);
            data.arrowLeft.removeEventListener(CLICK, backward);
            data.arrowLeft.removeEventListener(KEY_DOWN, backwardByEnter);
            data.arrowLeft.removeAttribute(DATA_CLICK_ATTRIBUTE);

            data.arrowLeft.setAttribute('tabindex', '-1');
        } else {
            data.arrowLeft.classList.remove(CLASS_ARROW_LEFT_DISABLED);
            data.arrowLeft.classList.add(CLASS_ARROW_LEFT_ACTIVE);

            if (!data.arrowLeft.getAttribute(DATA_CLICK_ATTRIBUTE)) {
                data.arrowLeft.addEventListener(CLICK, backward);
                data.arrowLeft.addEventListener(KEY_DOWN, backwardByEnter);
            }

            data.arrowLeft.setAttribute(DATA_CLICK_ATTRIBUTE, 'true');
            data.arrowLeft.setAttribute('tabindex', '0');
        }
    }

    if (data.arrowRight) {
        data.arrowRight.style.display = data.isScrollable() ? INLINE : NONE;

        if (data.scrollEndReached()) {
            data.arrowRight.classList.remove(CLASS_ARROW_RIGHT_ACTIVE);
            data.arrowRight.classList.add(CLASS_ARROW_RIGHT_DISABLED);
            data.arrowRight.removeEventListener(CLICK, forward);
            data.arrowRight.removeEventListener(KEY_DOWN, forwardByEnter);
            data.arrowRight.removeAttribute(DATA_CLICK_ATTRIBUTE);

            data.arrowRight.setAttribute('tabindex', '-1');
        } else {
            data.arrowRight.classList.remove(CLASS_ARROW_RIGHT_DISABLED);
            data.arrowRight.classList.add(CLASS_ARROW_RIGHT_ACTIVE);

            if (!data.arrowRight.getAttribute(DATA_CLICK_ATTRIBUTE)) {
                data.arrowRight.addEventListener(CLICK, forward);
                data.arrowRight.addEventListener(KEY_DOWN, forwardByEnter);
            }

            data.arrowRight.setAttribute(DATA_CLICK_ATTRIBUTE, 'true');
            data.arrowRight.setAttribute('tabindex', '0');
        }
    }

    calcOffsetForSublinks();
};

const calcTabIndexes = () => {
    if (!data.isScrollable()) {
        return;
    }

    const linksBlock = document.querySelector(LINKS_BLOCK) as HTMLElement;
    const links = document.querySelectorAll(LINKS);

    for (const linkBlock of links) {
        const link = linkBlock.querySelector('a.app-header-horizontal-navbar__text') as HTMLElement;
        const arrow = linkBlock.querySelector('span.button__arrow--down');

        if (linkBlock && !isVisible(linkBlock as HTMLElement, linksBlock as HTMLElement)) {
            link.setAttribute('tabindex', '-1');

            if (arrow) {
                arrow.setAttribute('tabindex', '-1');
            }
        } else {
            link.removeAttribute('tabindex');

            if (arrow) {
                arrow.removeAttribute('tabindex');
                arrow.setAttribute('tabindex', '0');
            }
        }
    }
};

const isVisible = (element: HTMLElement, block: HTMLElement) => {
    const blockPositioning = block.getBoundingClientRect();
    const elemPositioning = element.getBoundingClientRect();
    const buttonSize = 30;

    return (
        blockPositioning.right - buttonSize > elemPositioning.right &&
        blockPositioning.left + buttonSize < elemPositioning.left
    );
};

const calculationsRunner = () => {
    calculate();
    calcTabIndexes();
};

bindingHandlers.scrollable = {
    init() {
        (<HTMLDivElement>document.getElementById(LINKS_BLOCK_ID)).scrollLeft = 0;

        data.scrollElement = <HTMLDivElement>document.getElementById(LINKS_BLOCK_ID);
        data.arrowLeft = <HTMLDivElement>document.getElementById(LEFT_ARROW_ID);
        data.arrowRight = <HTMLDivElement>document.getElementById(RIGHT_ARROW_ID);

        new ResizeObserver(() => {
            calculate();
            calcTabIndexes();
        }).observe(document.querySelector(LINKS_BLOCK) as HTMLElement);

        document.addEventListener(CLICK, documentClickOutside);

        utils.domNodeDisposal.addDisposeCallback(data.scrollElement, () => {
            document.body.removeEventListener(CLICK, documentClickOutside, true);
            data.scrollAmount(0);
            (<HTMLDivElement>document.getElementById(LINKS_BLOCK_ID)).scrollLeft = 0;
        });
    },

    update() {
        setTimeout(() => {
            calculationsRunner();
        }, 0);
    },
};
