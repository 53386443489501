import tokenService from 'candidate-verification/service/token';
import router from 'app/model/router';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';

export type RedirectParams = {
    notificationKey: string;
    destroyToken?: boolean;
};

export const redirectToHomePage = ({ notificationKey, destroyToken }: RedirectParams): Promise<void> => {
    if (destroyToken) {
        tokenService.destroy();
    }

    return router
        .go('home-page')
        .then(() => notificationsService.successAfterLoaded(i18n(notificationKey), 500));
};
