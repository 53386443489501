import { CategoryTilesViewModel, Props } from '../../CategoryTilesViewModel';
import { CategoryTilesParams, CategoryTilesDisplayContent } from '../../config/types';
import { getCatgeoryListData, getTilesData } from '../../service/categoryTilesProvider';
import { Observable, observable } from 'knockout';
import { STATIC_TILES_DATA } from '../../config/staticCategoryTilesData';
import router from 'app/model/router';

export class CategoryTilesStaticViewModel extends CategoryTilesViewModel {
    categoryTilesParams: {
        id: string | undefined;
        params: CategoryTilesParams | undefined;
        adminMode: boolean;
    };

    tilesList: Observable<CategoryTilesDisplayContent[]>;

    constructor(props: Props) {
        super(props);
        this.tilesList = observable<CategoryTilesDisplayContent[]>([]);

        const { siteCode } = router.routeParams() as { siteCode: string };

        getCatgeoryListData(siteCode).then((data) => {
            const tiles = data.length ? getTilesData(data) : STATIC_TILES_DATA;

            this.tilesList(tiles);
        });

        this.categoryTilesParams = {
            id: props.id,
            params: props.params,
            adminMode: true,
        };
    }
}
