import { observable, observableArray, pureComputed } from 'knockout';

import SearchJobResultsViewModel from './SearchJobResultsViewModel';
import { getStaticData } from './config/staticData';
import { getTileComponents } from 'minimal/module/search/component/search-job-results/config/tileComponents';
import { noOperation } from 'core/utils/noOperation';
import { searchJobResults } from '../../model/searchJobResults';
import { jobsSortOptions } from 'cx/module/search/model/jobsSorting';
import store from 'site-editor/store/store';


export default class SearchJobResultsStaticViewModel extends SearchJobResultsViewModel {

    constructor(params) {
        super(params);

        // By default searchResults doesn't provide any unit, it's done to make a nice static preview
        searchJobResults.distanceUnit = 'MI';

        this.titleComponents = pureComputed(() =>
            getTileComponents(this.customizationParams.contentParams.jobDisplayStyle(), 'static'),
        );

        this.tileComponent = pureComputed(() => this.titleComponents().tile);

        this.sortOptions = observableArray(jobsSortOptions);

        const [firstSortOption] = this.sortOptions();

        this.selectedSortOption = observable(firstSortOption);

        this.talentCommunityTileComponent = pureComputed(() => this.titleComponents().talentCommunityTile);

        this.loading = observable(false);
        this.initialized = observable(true);
        this.requisitions = observableArray(getStaticData(store.site.tcSignUp.showAtSearchResults()));
        this.openJob = noOperation;
        this.getJobUrl = noOperation;
        this.mapImagePath = 'images/maps/oracle-map-static.png';
    }

    onChangeSortBy() {
        return false;
    }

}