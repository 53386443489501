import { observable, Observable } from 'knockout';
import { getCaptchaProviderData, isCaptchaEnable, ProviderConfigData } from './service/captchaService';
import { ApiCaptchaConfig } from './config/captcha';

type Props = {
    captchaCallback: () => void;
};

export default class CaptchaViewModel {
    public captchaData: ProviderConfigData & ApiCaptchaConfig;
    public isCaptchaEnable: Observable<boolean>;
    public captchaTemplate: Observable<string | undefined>;
    public captchaCallback: () => void;

    constructor({ captchaCallback }: Props) {
        this.captchaCallback = captchaCallback;

        this.captchaData = getCaptchaProviderData();
        this.isCaptchaEnable = observable(isCaptchaEnable());
        this.captchaTemplate = observable(this.captchaData.componentName);
    }
}
