export const ORA_MISC_DOCUMENTS = 'ORA_MISC_DOCUMENTS';
export const ORA_EXPERIENCE = 'ORA_EXPERIENCE';
export const ORA_EDUCATION = 'ORA_EDUCATION';
export const ORA_WORK_PREFERENCE = 'ORA_WORK_PREFERENCE';
export const ORA_TIMELINE = 'ORA_TIMELINE';
export const ORA_LANGUAGE = 'ORA_LANGUAGE';
export const ORA_LICENSE_CERTIFICATES = 'ORA_LICENSE_CERTIFICATES';
export const ORA_SKILL = 'ORA_SKILL';
export const ORA_NAME_CONTACT_INFO = 'ORA_NAME_CONTACT_INFO';
export const ORA_ADDRESS = 'ORA_ADDRESS';
export const ORA_SENSITIVE_PERSONAL_INFO = 'ORA_SENSITIVE_PERSONAL_INFO';
export const ORA_AGREEMENTS = 'ORA_AGREEMENTS';
export const ORA_ESIGNATURE = 'ORA_ESIGNATURE';
export const ORA_JA_EXTRA_INFO = 'ORA_JA_EXTRA_INFO';
export const ORA_QUESTIONNAIRE = 'ORA_QUESTIONNAIRE';
export const ORA_DIVERSITY = 'ORA_DIVERSITY';
export const ORA_VETERAN = 'ORA_VETERAN';
export const ORA_DISABILITY = 'ORA_DISABILITY';
export const ORA_DOCUMENTS = 'ORA_DOCUMENTS';