import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { CookieConsentModalTitleCustomizationParams, CookieConsentModalTitleParams } from '../config/types';
import { CookieConsentTitleCustomizationSelectorsKey } from '../config/selectors';

export const getCustomStylesCookieTitle = (
    key: keyof CookieConsentModalTitleCustomizationParams,
    selectors: Record<CookieConsentTitleCustomizationSelectorsKey, string>,
    customizationParams: CookieConsentModalTitleParams
): string => {
    switch (key) {
        case 'titleTypography':
            return getTypographyCss({
                selector: selectors.titleTypography,
                typography: customizationParams[key],
            });
        case 'overAllStyling':
            return getStylingBoxCss({
                selector: selectors.overAllStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        default:
            return '';
    }
};
