import ko from 'knockout';
import scrollKeeper from 'minimal/service/scrollKeeper';
import bodyDialogClassKeeper from 'minimal/service/bodyDialogClassKeeper';

/**
 * Controls ojDialog by responding to state of given observable.
 *
 * @param {observable} value - KO observable indicating if dialog should be open or not
 *
 * @example
 * <oj-dialog data-bind="isDialogVisible: { isVisible: isVisible, afterClose: afterClose }"></oj-dialog>
 */
ko.bindingHandlers.isDialogVisible = {
    init(element, accessor) {
        const { isVisible, afterClose } = accessor();

        function shouldOpenHandler(shouldOpen) {
            if (shouldOpen) {
                scrollKeeper.store();

                window.scrollTo(0, 0);
                bodyDialogClassKeeper.add(element);

                // In some cases , the dialog open method is throwing error
                // for the first time. However, it works for the second time.
                try {
                    element.open();
                } catch (error) {
                    element.open();
                }
            } else {
                element.close();
                bodyDialogClassKeeper.remove(element);
            }
        }

        const subscription = isVisible.subscribe(shouldOpenHandler);

        function onBeforeClose() {
            bodyDialogClassKeeper.remove(element);

            const app = document.querySelector('.app');

            if (app) {
                app.setAttribute('aria-hidden', 'false');
            }

            if (typeof afterClose === 'function') {
                afterClose();
            }
        }

        setTimeout(() => {
            element.addEventListener('ojBeforeClose', onBeforeClose);
            shouldOpenHandler(isVisible());
        }, 0);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
            element.removeEventListener('ojBeforeClose', onBeforeClose);
        });
    },
};

