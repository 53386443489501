import { DimensionUnit } from 'minimal/module/search/service/customCss';
import { cookieConsentStyles } from '../config/defaultValues';

export const COOKIE_CONSENT_WIDE_STYLE = 'wide';
export const COOKIE_CONSENT_NARROW_STYLE = 'narrow';

export type CookieConsentStyle = typeof COOKIE_CONSENT_NARROW_STYLE | typeof COOKIE_CONSENT_WIDE_STYLE;

export type CookieConsentWidgetOptions = {
    style: keyof typeof cookieConsentStyles | null;
    width: number;
    widthUnit: DimensionUnit;
    horizontalPadding: number;
    horizontalPaddingUnit: DimensionUnit;
    verticalPadding: number;
    verticalPaddingUnit: DimensionUnit;
    borderRadius: number;
    borderWidth: number;
    backgroundColor: string | null;
    borderColor: string | null;
};
