import { observable } from 'knockout';
import router from 'app/model/router';
import LightCandidate from 'candidate-verification/model/lightCandidate';
import { FILL_IN, ATTEMPS_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import ChallengeModel from 'candidate-verification/model/challenge';
import { getShortToken } from 'candidate-verification/service/shortTokenStorage';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import { UNAUTHORIZED } from 'candidate-verification/service/user-session/unauthorized';
import { triggerChallenge } from 'candidate-verification/service/triggerChallenge';

export default class CandidateChallengeAbstractViewModel {

    constructor() {
        this.candidate = new LightCandidate();
        this.verificationStatus = observable(FILL_IN);
        this.verificationStates = { FILL_IN, ATTEMPS_LIMIT_REACHED };
        this.mergedCandidate = observable(false);

        this.challenge = new ChallengeModel({
            verificationStatus: this.verificationStatus,
            candidate: this.candidate,
            actions: {
                onPinValid: this.onPinValid.bind(this),
                onAttempsLimitReached: this.onAttempsLimitReached.bind(this),
            },
            mergedCandidate: this.mergedCandidate,
        });
    }

    onPinValid() {
        throw new Error('Not implemented');
    }

    onAttempsLimitReached() {
        return router.go('search');
    }

    _triggerChallenge() {
        const shortTokenData = getShortToken();

        if (shortTokenData) {
            return triggerChallenge(shortTokenData.token);
        }

        return Promise.resolve(false);
    }

    _handleError(error) {
        router.go('home-page');

        let errorMessage;

        if (error === 'token-expired') {
            errorMessage = i18n('general.error-token-expired.header');
        } else if (error === ATTEMPS_LIMIT_REACHED) {
            errorMessage = i18n('apply-flow.candidate-verification.link-currently-not-active');
        } else if (error === UNAUTHORIZED) {
            errorMessage = i18n('general.session-expired');
        }

        notificationsService.error(errorMessage);
        console.error(error);
    }

    handleMergedCandiate() {
        if (getShortToken().mergedCandidate) {
            this.mergedCandidate(true);
        }
    }

}