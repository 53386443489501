import ko from 'knockout';

/**
 * Custom binding for dropdown of search filters
 * Calculates available space to the edge of the screen and adds class to open to left instead
 * of right when there's not enough space on LTR, and the other way around on RTL
 *
 * @param {ko.observable} when View model's observable indicating if dropdown is opened
 */

const DROPDOWN_ELEMENT_SELECTOR = '.search-facet-dropdown .search-filters__pill';
const DROPDOWN_LIST_CONTAINER_SELECTOR = '.search-facet-dropdown__list-container';
const OPEN_TO_LEFT_CLASS_NAME = 'search-facet-dropdown__list-container--open-to-left';

function isInViewport(pill: HTMLElement, dropdown: HTMLElement) {
    const { right, left } = pill.getBoundingClientRect();
    const viewportWidth = document.documentElement.clientWidth;

    return left + dropdown.clientWidth < viewportWidth && right - dropdown.clientWidth > 0;
}

ko.bindingHandlers.adjustDropdownPosition = {
    init(element, valueAccessor) {
        function onResize() {
            const isDropdownOpen = valueAccessor().when();

            if (isDropdownOpen) {
                adjustPosition();
            }
        }

        function adjustPosition() {
            const listContainerNode = element.querySelector(DROPDOWN_LIST_CONTAINER_SELECTOR);
            const pillNode = element.querySelector(DROPDOWN_ELEMENT_SELECTOR);

            if (isInViewport(pillNode, listContainerNode)) {
                listContainerNode.classList.remove(OPEN_TO_LEFT_CLASS_NAME);
            } else {
                listContainerNode.classList.add(OPEN_TO_LEFT_CLASS_NAME);
            }
        }

        window.addEventListener('resize', onResize);
        element.addEventListener('click', onResize);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            window.removeEventListener('resize', onResize);
            element.addEventListener('click', onResize);
        });
    },
};
