import { getItemActiveSelector, getItemActiveSelectorWithBefore } from 'app/module/minimal/config/utils';

export type CookieConsentActionsCustomizationSelectors = {
    overallStyling: string;
    acceptButtonStyling: string;
    acceptButtonHoverStyling: string;
    declineButtonStyling: string;
    declineButtonHoverStyling: string;
    savePreferencesButtonStyling: string;
    savePreferencesButtonHoverStyling: string;
    cookiePreferencesButtonStyling: string;
    cookiePreferencesButtonHoverStyling: string;
    cookiePreferencesLinkStyling: string;
    cookiePreferencesLinkHoverStyling: string;
    acceptButtonTypography: string;
    acceptButtonHoverTypography: string;
    declineButtonTypography: string;
    declineButtonHoverTypography: string;
    savePreferencesButtonTypography: string;
    savePreferencesButtonHoverTypography: string;
    cookiePreferencesButtonTypography: string;
    cookiePreferencesButtonHoverTypography: string;
    cookiePreferencesLinkTypography: string;
    cookiePreferencesLinkHoverTypography: string;
};

export const getCookieConsentActionsSelectors = (
    wrapperSelector: string
): CookieConsentActionsCustomizationSelectors => {
    const commonSelector = `.cookie-consent-actions__container.cookie-consent-widgets .app-dialog__buttons-bar .cookie-consent__actions-wrapper .cookie-consent__actions`;
    const commonSelectorPreferenceLink = `.cookie-consent-actions__container.cookie-consent-widgets .app-dialog__buttons-bar .cookie-consent__actions-wrapper`;

    return {
        overallStyling: `${wrapperSelector}.cookie-consent-actions__container`,
        acceptButtonStyling: `${wrapperSelector}${commonSelector} .accept, ${wrapperSelector}${commonSelector} .accept::before`,
        acceptButtonHoverStyling: getItemActiveSelectorWithBefore(
            `${wrapperSelector}${commonSelector} .accept`,
            ''
        ),
        declineButtonStyling: `${wrapperSelector}${commonSelector} .decline, ${wrapperSelector}${commonSelector} .decline::before`,
        declineButtonHoverStyling: getItemActiveSelectorWithBefore(
            `${wrapperSelector}${commonSelector} .decline`,
            ''
        ),
        savePreferencesButtonStyling: `${wrapperSelector}${commonSelector} .save-preferences, ${wrapperSelector}${commonSelector} .save-preferences::before`,
        savePreferencesButtonHoverStyling: getItemActiveSelectorWithBefore(
            `${wrapperSelector}${commonSelector} .save-preferences`,
            ''
        ),
        cookiePreferencesButtonStyling: `${wrapperSelector}${commonSelector} .cookie-preferences, ${wrapperSelector}${commonSelector} .cookie-preferences::before`,
        cookiePreferencesButtonHoverStyling: getItemActiveSelectorWithBefore(
            `${wrapperSelector}${commonSelector} .cookie-preferences`,
            ''
        ),
        cookiePreferencesLinkStyling: `${wrapperSelector}${commonSelectorPreferenceLink} .cookie-consent__preferences`,
        cookiePreferencesLinkHoverStyling: getItemActiveSelector(
            `${wrapperSelector}${commonSelectorPreferenceLink} .cookie-consent__preferences`,
            ''
        ),

        acceptButtonTypography: `${wrapperSelector}${commonSelector} .accept`,
        acceptButtonHoverTypography: getItemActiveSelector(`${wrapperSelector}${commonSelector} .accept`, ''),
        declineButtonTypography: `${wrapperSelector}${commonSelector} .decline`,
        declineButtonHoverTypography: getItemActiveSelector(
            `${wrapperSelector}${commonSelector} .decline`,
            ''
        ),
        savePreferencesButtonTypography: `${wrapperSelector}${commonSelector} .save-preferences`,
        savePreferencesButtonHoverTypography: getItemActiveSelector(
            `${wrapperSelector}${commonSelector} .save-preferences`,
            ''
        ),
        cookiePreferencesButtonTypography: `${wrapperSelector}${commonSelector} .cookie-preferences`,
        cookiePreferencesButtonHoverTypography: getItemActiveSelector(
            `${wrapperSelector}${commonSelector} .cookie-preferences`,
            ''
        ),
        cookiePreferencesLinkTypography: `${wrapperSelector}${commonSelectorPreferenceLink} .cookie-consent__preferences__link`,
        cookiePreferencesLinkHoverTypography: getItemActiveSelector(
            `${wrapperSelector}${commonSelectorPreferenceLink} .cookie-consent__preferences__link`,
            ''
        ),
    };
};
