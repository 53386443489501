function mapExtractedSkill(extractedSkill) {
    return {
        name: extractedSkill,
        score: 0.0,
    };
}

function mapSuggestedSkill({ name, score }) {
    return {
        name,
        score,
    };
}

function mapFromRest(restSkillData) {
    let {
        extractedSkills = [],
        suggestedSkills = [],
    } = restSkillData;

    extractedSkills = extractedSkills
        .map(mapExtractedSkill);

    suggestedSkills = suggestedSkills
        .map(mapSuggestedSkill);

    return [...extractedSkills, ...suggestedSkills];
}

function mapToRest({ title, experiences, skills }) {
    const experienceList = experiences
        .filter(({ jobTitle, achievements, responsibilities }) => jobTitle() || achievements() || responsibilities())
        .map(({ jobTitle, achievements, responsibilities }) => ({
            role: jobTitle() || '',
            achievements: achievements() || '',
            responsibilities: responsibilities() || '',
        }));

    const skillList = skills
        .map(skill => skill.skills());

    return {
        title,
        structured: {
            experience: experienceList,
        },
        existingSkills: skillList,
    };
}

function mapToSkill(recommendation) {
    return recommendation.name;
}

export default {
    mapFromRest,
    mapToRest,
    mapToSkill,
};