import { DimensionUnit } from 'minimal/module/search/service/customCss';
import { SearchBarContentParams } from '../config/types';
import { DEFAULT_SEARCH_BAR_CONTENT } from '../config/defaultParams';

export const mapContentParams = (stringifiedParams: string | undefined | null): SearchBarContentParams => {
    if (!stringifiedParams) {
        return DEFAULT_SEARCH_BAR_CONTENT;
    }

    const parsedParams = JSON.parse(stringifiedParams) as Record<string, string | boolean | undefined | null>;

    return {
        searchBarSticky:
            typeof parsedParams.searchBarSticky === 'undefined' || parsedParams.searchBarSticky === null
                ? DEFAULT_SEARCH_BAR_CONTENT.searchBarSticky
                : Boolean(parsedParams.searchBarSticky),

        width: parsedParams.width ? parseInt(String(parsedParams.width)) : DEFAULT_SEARCH_BAR_CONTENT.width,
        widthUnit: (parsedParams.widthUnit as DimensionUnit) || DEFAULT_SEARCH_BAR_CONTENT.widthUnit,
        heightUnit: (parsedParams.heightUnit as DimensionUnit) || DEFAULT_SEARCH_BAR_CONTENT.heightUnit,
        height: parsedParams.height
            ? parseInt(String(parsedParams.height))
            : DEFAULT_SEARCH_BAR_CONTENT.height,
    };
};
