import ProfileItemsViewModel from 'apply-flow/module/profile-items/ProfileItemsViewModel';

export default class WorkPreferencesViewModel extends ProfileItemsViewModel {

    _setProfileItems(newProfileItems) {
        super._setProfileItems(newProfileItems);

        if (!this._hasProfileItems()) {
            this.addProfileItem();
        }
    }

}