/* Rule disabled as interface is provided by KnockoutJS */
/* eslint-disable new-cap */

import ko from 'knockout';
import { get as getTemplate } from 'ce-common/service/templateRepository';

ko.webpackTemplateEngine = function webpackTemplateEngine() {
    this.allowTemplateRewriting = false;
};

ko.webpackTemplateEngine.prototype = new ko.templateEngine();
ko.webpackTemplateEngine.prototype.constructor = ko.nativeTemplateEngine;

ko.webpackTemplateEngine.prototype.makeTemplateSource =
    function makeTemplateSource(rawTemplateName, rawTemplateDocument) {
        const template = ko.unwrap(rawTemplateName);

        if (typeof template === 'string') {
            const templateDocument = rawTemplateDocument || document;
            const elem = templateDocument.getElementById(template);

            if (!elem) {
                return {
                    text: ko.observable(getTemplate(template)),
                };
            }

            return new ko.templateSources.domElement(elem);
        } else if ((template.nodeType === Node.ELEMENT_NODE) || (template.nodeType === Node.COMMENT_NODE)) {
            return new ko.templateSources.anonymousTemplate(template);
        }

        return null;
    };

ko.webpackTemplateEngine.prototype.renderTemplateSource =
    function renderTemplateSource(templateSource, bindingContext, options, templateDocument) {
        const templateNodes = templateSource.nodes ? templateSource.nodes() : null;

        if (templateNodes) {
            return [...templateNodes.cloneNode(true).childNodes];
        }

        return ko.utils.parseHtmlFragment(templateSource.text(), templateDocument);
    };

ko.setTemplateEngine(new ko.webpackTemplateEngine());
