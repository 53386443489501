import { Observable, observable } from 'knockout';

import { SearchResultsModel } from './searchResults';
import { Job } from 'minimal/module/job-details/types';

class SearchJobResultsModel extends SearchResultsModel<Job> {
    distanceUnit: string | null;
    keyword: Observable<string>;
    correctedKeyword: Observable<string>;
    useExactKeywordFlag: Observable<boolean>;
    suggestedKeyword: Observable<string>;

    constructor() {
        super();

        this.distanceUnit = null;
        this.keyword = observable('');
        this.correctedKeyword = observable('');
        this.useExactKeywordFlag = observable<boolean>(false);
        this.suggestedKeyword = observable('');
    }
}

export const searchJobResults = new SearchJobResultsModel();
export const searchResultsAiJobs = new SearchJobResultsModel();
