import {
    desktopOnly,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { RecommendedJobsWidgetParams } from './types';
import { getSearchResultSelectors } from './selectors';
import { emptyParams as defaultSearchResultsParams, defaultHoverParams } from './emptyParams';
import { getParamsValueMergeFunction } from 'minimal/module/search/service/observableParams/getParamsValueMergeFunction';
import { mergeObservableParams } from 'minimal/module/search/service/observableParams/mergeObservableParams';

export const getCustomStyles = (params: RecommendedJobsWidgetParams, uniqueWrapperClass: string): string => {
    const selectors = getSearchResultSelectors(`.${uniqueWrapperClass}`);

    const customizationKeys = Object.keys(params) as (keyof RecommendedJobsWidgetParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'content':
                const widthSelector = desktopOnly(
                    getMaxWidthCss({
                        selector: [selectors.container, selectors.successMessageContainer].join(','),
                        width: params[key].width(),
                        widthUnit: params[key].widthUnit(),
                    })
                );

                return widthSelector;

            case 'callToActionTypography':
                return getTypographyCss({
                    selector: selectors.callToAction,
                    typography: params[key],
                });

            case 'callToActionHoverTypography':
                const callToActionHoverTypography = mergeObservableParams({
                    sourceParams: params.callToActionTypography,
                    targetParams: params.callToActionHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.callToActionHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.callToActionOnHover,
                    typography: callToActionHoverTypography,
                });

            case 'browseButtonTypography':
                return getTypographyCss({
                    selector: selectors.browseButton,
                    typography: params[key],
                });

            case 'browseButtonHoverTypography':
                const browseButtonHoverTypography = mergeObservableParams({
                    sourceParams: params.browseButtonTypography,
                    targetParams: params.browseButtonHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.browseButtonHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.browseButtonOnHover,
                    typography: browseButtonHoverTypography,
                });

            case 'uploadingMessageTypography':
                return getTypographyCss({
                    selector: selectors.uploadingMessage,
                    typography: params[key],
                });

            case 'errorMessageTypography':
                return getTypographyCss({
                    selector: selectors.errorMessage,
                    typography: params[key],
                });

            case 'successMessageTypography':
                return getTypographyCss({
                    selector: selectors.successMessage,
                    typography: params[key],
                });

            case 'privacyPolicyLinkTypography':
                return getTypographyCss({
                    selector: selectors.privacyPolicyLink,
                    typography: params[key],
                });

            case 'privacyPolicyLinkHoverTypography':
                const privacyPolicyLinkHoverTypography = mergeObservableParams({
                    sourceParams: params.privacyPolicyLinkTypography,
                    targetParams: params.privacyPolicyLinkHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.privacyPolicyLinkHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.privacyPolicyLinkOnHover,
                    typography: privacyPolicyLinkHoverTypography,
                });

            case 'privacyPolicyModalHeaderTypography':
                return getTypographyCss({
                    selector: selectors.privacyPolicyModalHeader,
                    typography: params[key],
                });

            case 'privacyPolicyModalTextTypography':
                return getTypographyCss({
                    selector: selectors.privacyPolicyModalText,
                    typography: params[key],
                });

            case 'privacyPolicyModalCloseButtonTypography':
                return getTypographyCss({
                    selector: selectors.privacyPolicyModalCloseButton,
                    typography: params[key],
                });

            case 'privacyPolicyModalCloseButtonHoverTypography':
                const privacyPolicyModalCloseButtonHoverTypography = mergeObservableParams({
                    sourceParams: params.privacyPolicyModalCloseButtonTypography,
                    targetParams: params.privacyPolicyModalCloseButtonHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams:
                            defaultSearchResultsParams.privacyPolicyModalCloseButtonHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.privacyPolicyModalCloseButtonHover,
                    typography: privacyPolicyModalCloseButtonHoverTypography,
                });

            case 'manageTypography':
                return getTypographyCss({
                    selector: selectors.manage,
                    typography: params[key],
                });

            case 'manageDropdownTypography':
                return getTypographyCss({
                    selector: selectors.manageDropdownContainer,
                    typography: params[key],
                });

            case 'manageDropdownHoverTypography':
                const manageDropdownHoverTypography = mergeObservableParams({
                    sourceParams: params.manageDropdownTypography,
                    targetParams: params.manageDropdownHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.manageDropdownHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.manageDropdownOnHover,
                    typography: manageDropdownHoverTypography,
                });

            case 'manageHoverTypography':
                const manageHoverTypography = mergeObservableParams({
                    sourceParams: params.manageTypography,
                    targetParams: params.manageHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultHoverParams.manageHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.manageOnHover,
                    typography: manageHoverTypography,
                });

            case 'viewAllJobsLabelTypography':
                return getTypographyCss({
                    selector: selectors.viewAllJobsLabel,
                    typography: params[key],
                });

            case 'viewAllJobsLabelHoverTypography':
                const viewAllJobsLabelHoverTypography = mergeObservableParams({
                    sourceParams: params.viewAllJobsLabelTypography,
                    targetParams: params.viewAllJobsLabelHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultHoverParams.viewAllJobsLabelHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.viewAllJobsLabelOnHover,
                    typography: viewAllJobsLabelHoverTypography,
                });

            case 'containerStyling':
                return getStylingBoxCss({
                    selector: selectors.wrapper,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'overallStyling':
                return getStylingBoxCss({
                    selector: selectors.container,
                    stylingBox: params[key],
                    lightIconSelector: selectors.icons,
                    darkIconSelector: null,
                });

            case 'overallHoverStyling':
                const overallHoverStyling = mergeObservableParams({
                    sourceParams: params.overallStyling,
                    targetParams: params.overallHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.overallHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.containerOnHover,
                    stylingBox: overallHoverStyling,
                    lightIconSelector: selectors.iconsOnHover,
                    darkIconSelector: null,
                });

            case 'privacyPolicyModalStyling':
                return getStylingBoxCss({
                    selector: selectors.privacyPolicyModal,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'privacyPolicyModalCloseButtonStyling':
                return selectors.privacyPolicyModalCloseButton
                    ? getStylingBoxCss({
                          selector: selectors.privacyPolicyModalCloseButton,
                          stylingBox: params[key],
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';

            case 'privacyPolicyModalCloseButtonHoverStyling':
                const privacyPolicyModalCloseButtonHoverStyling = mergeObservableParams({
                    sourceParams: params.privacyPolicyModalCloseButtonStyling,
                    targetParams: params.privacyPolicyModalCloseButtonHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.privacyPolicyModalCloseButtonHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.privacyPolicyModalCloseButtonHover,
                    stylingBox: privacyPolicyModalCloseButtonHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'browseButtonStyling':
                return selectors.browseButton
                    ? getStylingBoxCss({
                          selector: selectors.browseButton,
                          stylingBox: params[key],
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';

            case 'browseButtonHoverStyling':
                const browseButtonHoverStyling = mergeObservableParams({
                    sourceParams: params.browseButtonStyling,
                    targetParams: params.browseButtonHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.browseButtonHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.browseButtonOnHover,
                    stylingBox: browseButtonHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'successMessageHoverStyling':
                const successMessageHoverStyling = mergeObservableParams({
                    sourceParams: params.successMessageStyling,
                    targetParams: params.successMessageHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultHoverParams.successMessageHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.successMessageOnHover,
                    stylingBox: successMessageHoverStyling,
                    lightIconSelector: selectors.successMessageOnHover,
                    darkIconSelector: null,
                });

            case 'uploadingMessageStyling':
                return getStylingBoxCss({
                    selector: selectors.uploadingMessageContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'errorMessageStyling':
                return getStylingBoxCss({
                    selector: selectors.errorMessageContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'successMessageStyling':
                return getStylingBoxCss({
                    selector: selectors.successMessageContainer,
                    stylingBox: params[key],
                    lightIconSelector: selectors.successMessageIcon,
                    darkIconSelector: null,
                });

            case 'manageDropdownStyling':
                return getStylingBoxCss({
                    selector: selectors.manageDropdownContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'viewAllJobsStyling':
                return getStylingBoxCss({
                    selector: selectors.viewAllJobsLinkContainer,
                    stylingBox: params[key],
                    lightIconSelector: selectors.viewAllJobsLinkContainer,
                    darkIconSelector: null,
                });

            case 'viewAllJobsHoverStyling':
                const viewAllJobsHoverStyling = mergeObservableParams({
                    sourceParams: params.viewAllJobsStyling,
                    targetParams: params.viewAllJobsHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultHoverParams.viewAllJobsHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.viewAllJobsLinkContainerOnHover,
                    stylingBox: viewAllJobsHoverStyling,
                    lightIconSelector: selectors.viewAllJobsLinkContainerOnHover,
                    darkIconSelector: null,
                });

            case 'callToActionDesktopText':
            case 'callToActionMobileText':
            case 'callToActionButtonText':
            case 'uploadingText':
            case 'successMessageText':
            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return '';
        }
    });

    return customStyles.filter((style) => style?.trim().length).join(STYLES_SEPARATOR);
};
