import router from 'app/model/router';

const defaultRoute = {
    abstract: true,
    view: {
        layout: 'minimal',
    },
};

router.configure({
    cx: defaultRoute, // for compatibility with some cx paths
    minimal: defaultRoute,
    'home-page': {
        parent: 'minimal',
        url: '/:?query:',
        isDefault: true,
        view: {
            main: 'splash-page',
            dialogs: null,
            'candidate-verification': null,
        },
    },
});
