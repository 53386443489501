import httpMapper from 'core/http/mapper';

export default {
    mapToRest(requestBody) {
        let body = requestBody;

        if (body && typeof body === 'object') {
            body.parts.forEach((bodyPart) => {
                const part = bodyPart;

                if (part.payload) {
                    part.payload = httpMapper.convertKeysToRestFormat(part.payload, true);
                } else {
                    delete part.payload;
                }
            });

            body = JSON.stringify(body);
        }

        return body;
    },
};