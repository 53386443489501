import { bindingHandlers, utils } from 'knockout';
import { render, h } from 'preact';
import { get as getComponent } from '../service/reactComponents';

bindingHandlers.react = {
    init(element) {
        utils.domNodeDisposal.addDisposeCallback(element, () => render(null, element));

        return { controlsDescendantBindings: true };
    },

    update(element, valueAccessor) {
        const { component: componentName, props } = valueAccessor();
        const component = getComponent(componentName);

        render(h(component, props), element);
    },
};
