import jobService from 'job-details/service/job';
import jobAlertsService from './jobAlerts';
import { JOB_APPLICATION, VERIFICATION_MODES } from 'candidate-verification/config/verificationSubmodules';
import { hasJobAlertPreference, hasMarketingPreference } from './preferenceFlags';
import { findJobFamilies } from './jobFamiliesFinder';

function saveMarketingPreference(candidate) {
    const { id: candidateId, preferences } = candidate;

    return jobAlertsService.saveCSSOptInEmailAlert({
        candidateId: candidateId(),
        optInEmailFlag: preferences.optIn(),
        flowContext: VERIFICATION_MODES[JOB_APPLICATION],
    });
}

function saveAllPreferences(candidate, job) {
    const { id: candidateId, subscribeToJobAlert, preferences } = candidate;

    return findJobFamilies(candidateId(), job)
        .then(jobFamiliesIds =>
            jobAlertsService.save(job, candidateId(), subscribeToJobAlert, preferences, jobFamiliesIds));
}

export default {
    async saveForJobApply(candidate, jobId) {
        const hasOnlyMarketingPreference = !hasJobAlertPreference(candidate) && hasMarketingPreference(candidate);

        if (hasOnlyMarketingPreference) {
            return saveMarketingPreference(candidate);
        }

        if (hasJobAlertPreference(candidate)) {
            const job = await jobService.getJob(jobId)
                .catch(console.error);

            if (!job) {
                return Promise.resolve();
            }

            return saveAllPreferences(candidate, job);
        }

        return Promise.resolve();
    },
};