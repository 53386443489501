import mapping from 'knockout-mapping';
import questionsMapper from '../../../mapper/questions';
import { STATIC } from 'apply-flow/module/questionnaire/enum/questionnaireTypes';

export default {
    mapDataToRest(questionnaire) {
        const rawQuestionnaire = mapping.toJS(questionnaire);

        return {
            type: STATIC,
            questionnaireId: rawQuestionnaire.questionnaireId,
            qstnrVersionNumber: rawQuestionnaire.questionnaireVersionNumber,
            questionResponses: questionsMapper.mapQuestionsToRest(rawQuestionnaire.questions),
        };
    },
};