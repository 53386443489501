import http from 'core/http/http';
import flowsMapper from 'apply-flow/mapper/notEmptyFlow';
import storageService from 'core/storage/sessionStorage';
import httpLangCached from 'cx/service/http/httpLangCached';

const FLOW_KEY = 'ce_flow';
const SECONDARY_FLOW_DATA_KEY = 'ce_secondary_flow_data';
const FLOW_URL = '/recruitingCEApplyFlows?finder=findByRequisitionNumber;RequisitionNumber=":requisitionNumber:"';
const FLOW_URL_WITH_SECTIONS = `${FLOW_URL}&expand=sections.pages.blocks,requisitionFlexFields&onlyData=true`;
const TALENT_COMMUNITY_FLOW_URL = '/recruitingCEApplyFlows?finder=findTalentCommunityApplyFlow;FlowTypeCode=ORA_TALENT&expand=sections.pages.blocks,requisitionFlexFields&onlyData=true';
const FLOW_URL_BY_EVENT_NUMBER = '/recruitingCEApplyFlows?finder=findByEventNumber;EventNumber=":eventNumber:"';
const FLOW_URL_BY_EVENT_NUMBER_WITH_SECTIONS = `${FLOW_URL_BY_EVENT_NUMBER}&expand=sections.pages.blocks&onlyData=true`;

function _queryFlow(requisitionNumber, flowUrl) {
    return http.get(flowUrl, {
        params: {
            requisitionNumber,
        },
    });
}

function _queryEventFlow(eventNumber, flowUrl) {
    return http.get(flowUrl, {
        params: {
            eventNumber,
        },
    });
}

export default {
    query(requisitionNumber) {
        return _queryFlow(requisitionNumber, FLOW_URL_WITH_SECTIONS)
            .then(flowsMapper.mapFlowFromRest.bind(flowsMapper))
            .then(this.set);
    },

    queryByEventNumber(eventNumber) {
        return _queryEventFlow(eventNumber, FLOW_URL_BY_EVENT_NUMBER_WITH_SECTIONS)
            .then(flowsMapper.mapFlowFromRest.bind(flowsMapper))
            .then(this.set);
    },

    queryTalentCommunityFlow() {
        return httpLangCached.get(TALENT_COMMUNITY_FLOW_URL);
    },

    querySecondary(submissionId) {
        const { flowId } = storageService.restore(SECONDARY_FLOW_DATA_KEY);
        const flowUrl = '/recruitingCESecondaryApplyFlows?finder=findBySubmission;SubmissionId=:submissionId:,FlowId=:flowId:&expand=sections.pages.blocks,requisitionFlexFields&onlyData=true';

        return http.get(flowUrl, {
            params: {
                submissionId, flowId,
            },
        })
            .then(flowsMapper.mapFlowFromRest.bind(flowsMapper))
            .then(this.validateFlowSections)
            .then(this.set);
    },

    queryRawFlow(requisitionNumber) {
        return _queryFlow(requisitionNumber, FLOW_URL);
    },

    queryRawEventFlow(eventNumber) {
        return _queryEventFlow(eventNumber, FLOW_URL_BY_EVENT_NUMBER);
    },

    validateFlowSections(flow) {
        if (flow && flow.sections.length > 0) {
            return flow;
        }

        return Promise.reject('empty-post-flow');
    },

    get() {
        return storageService.restore(FLOW_KEY);
    },

    set(flow) {
        storageService.store(FLOW_KEY, flow);

        storageService.verify(FLOW_KEY, flow);

        return flow;
    },

    setSecondaryFlowData(flowData) {
        storageService.store(SECONDARY_FLOW_DATA_KEY, flowData);

        storageService.verify(SECONDARY_FLOW_DATA_KEY, flowData);
    },

    clearSecondaryFlowData() {
        storageService.remove(SECONDARY_FLOW_DATA_KEY);
    },

    createFlowData(id, step) {
        return {
            flowId: id,
            stepActionUsageId: step,
        };
    },

    getSecondaryFlowData() {
        return storageService.restore(SECONDARY_FLOW_DATA_KEY);
    },
};
