import router from 'app/model/router';
import cxEvents from 'cx/config/events';

router.configure({
    'cc-page': {
        parent: 'minimal',
        url: '/pages/{pageCode}/:?query:',
        view: {
            main: 'custom-content',
        },
        enter() {
            cxEvents.loaded.dispatch();
        },
    },
});
