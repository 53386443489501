import router from 'app/model/router';
import flowService from 'apply-flow/service/flow';
import notificationsService from 'cx/service/notifications';
import ApplyFlowViewModel from 'apply-flow/ApplyFlowViewModel';
import i18n from 'core/i18n/i18n';
import applicationDraftService from '../apply-flow/service/applicationDraft';

export default class ApplyFlowPostViewModel extends ApplyFlowViewModel {

    constructor() {
        super();
        this.summaryComponentName = 'apply-flow-post-summary-thank-you';

        this.application().submissionId(router.routeParams().submissionId);
        this.application().currentPhaseId(flowService.getSecondaryFlowData().stepActionUsageId);
        this.application().flowId(flowService.getSecondaryFlowData().flowId);
        this.closeAction = this.postCloseAction.bind(this);
    }

    _createSourceTrace() {
        return null;
    }

    _loadFlow() {
        return flowService.querySecondary(router.routeParams().submissionId);
    }

    _loadApplicationDraft(flow) {
        return applicationDraftService
            .loadForRIF(this.jobId, flow.flowId, this.application())
            .then(draft => this._prepareDraft(draft, flow));
    }

    _handleError(error) {
        if (typeof error === 'string' && error === 'empty-post-flow') {
            router.go('candidate-self-service')
                .then(() => notificationsService.errorAfterLoaded(i18n('post-apply.message.all-data-provided'), 500));
        } else {
            console.error(error);
        }
    }

    postCloseAction() {
        router.go('candidate-self-service')
            .then(() => {
                flowService.clearSecondaryFlowData();
                notificationsService.errorAfterLoaded(i18n('post-apply.message.provide-additional-information'), 500);
            });
    }


}
