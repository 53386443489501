import ko from 'knockout';
import debounce from 'core/utils/debounce';

const setPropertyValue = (propertyName, propertyValue) => {
    const value = parseInt(propertyValue, 10) || 0;

    document.documentElement.style
        .setProperty(propertyName, `${value}px`);
};

const calculate = (locationElement) => {
    if (!locationElement) {
        console.warn('Element for calculateSearchInputWidth was not found');


        return;
    }

    const dropDownWidth = locationElement.offsetWidth;
    const dropDownLeftCoordinate = locationElement.getBoundingClientRect().left;

    setPropertyValue('--search-input-width', dropDownWidth);
    setPropertyValue('--search-input-left', dropDownLeftCoordinate);
};

/**
 * Applies to the combobox drop down width changes based on the element width.
 * @example
 * <div data-bind="calculateSearchInputWidth'"></div>
 */
ko.bindingHandlers.calculateSearchInputWidth = {
    init(element) {
        const locationElement = document.querySelector('#location-container');
        const debouncedCalculate = debounce(() => calculate(locationElement), 50);

        calculate(locationElement);
        window.addEventListener('resize', debouncedCalculate);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            window.removeEventListener('resize', debouncedCalculate);
        });
    },
};