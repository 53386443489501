import http from 'core/http/http';
import mapping from 'knockout-mapping';
import PIIMapper from '../mapper/personalIdentifyingInformation';
import storageService from 'core/storage/sessionStorage';
import synchronization, { COLLECTIONS } from 'apply-flow/service/synchronization';
import { useDraftData } from '../../../service/applicationDraftMonitor';
import piiItemsValidationResponseParser from './piiItemsValidationResponseParser';

const UPDATE_PII_ACTION = 'updatePII';
const STORAGE_KEY = 'personalIdentifyingInformation';

function _setCandidateParameters(payload, candidateNumber, basicInformation) {
    payload.candidateNumber = candidateNumber;
    payload.lastName = basicInformation.lastName();
    payload.email = basicInformation.email();
    payload.firstName = basicInformation.firstName();
    payload.previousLastName = basicInformation.previousLastName() || undefined;
    payload.middleNames = basicInformation.middleNames() || undefined;
    payload.action = UPDATE_PII_ACTION;
    payload.title = basicInformation.title() || undefined;
    payload.honors = basicInformation.honors() || undefined;
    payload.knownAs = basicInformation.knownAs() || undefined;
    payload.militaryRank = basicInformation.militaryRank() || undefined;
    payload.preNameAdjunct = basicInformation.preNameAdjunct() || undefined;
    payload.suffix = basicInformation.suffix() || undefined;
    payload.namInformation15 = basicInformation.namInformation15() || undefined;
    payload.namInformation16 = basicInformation.namInformation16() || undefined;
    payload.namInformation17 = basicInformation.namInformation17() || undefined;
    payload.namInformation18 = basicInformation.namInformation18() || undefined;
    payload.namInformation19 = basicInformation.namInformation19() || undefined;
    payload.namInformation20 = basicInformation.namInformation20() || undefined;
    payload.namInformation21 = basicInformation.namInformation21() || undefined;
    payload.namInformation22 = basicInformation.namInformation22() || undefined;
    payload.namInformation23 = basicInformation.namInformation23() || undefined;
    payload.namInformation24 = basicInformation.namInformation24() || undefined;
    payload.namInformation25 = basicInformation.namInformation25() || undefined;
    payload.namInformation26 = basicInformation.namInformation26() || undefined;
    payload.namInformation27 = basicInformation.namInformation27() || undefined;
    payload.namInformation28 = basicInformation.namInformation28() || undefined;
    payload.namInformation29 = basicInformation.namInformation29() || undefined;
    payload.namInformation30 = basicInformation.namInformation30() || undefined;
}

function isDateOfBirthSet(dateOfBirth) {
    return dateOfBirth && dateOfBirth();
}

function arePIIReadOnly(basicInformation) {
    return basicInformation.exWorker()
        || basicInformation.cwkCandidate();
}

async function loadPIIData(candidateId, basicInformation) {
    const candidateData = await http.get(`/recruitingCECandidates/${candidateId}?expand=CandidatePIIData&onlyData=true`);

    return {
        candidatePIIData: candidateData.CandidatePIIData,
        dateOfBirth: candidateData.dateOfBirth,
        readOnly: arePIIReadOnly(basicInformation),
    };
}

export default {
    save({ id, basicInformation, personalIdentifyingInformationItems, dateOfBirth }) {
        if (!personalIdentifyingInformationItems().length && !isDateOfBirthSet(dateOfBirth)) {
            return Promise.resolve(true);
        }

        const data = PIIMapper
            .mapPersonalIdentifyingInformationItemsToRest(personalIdentifyingInformationItems(), dateOfBirth());

        _setCandidateParameters(data, id(), basicInformation);
        synchronization.remove(personalIdentifyingInformationItems, COLLECTIONS.PII);
        synchronization.remove(dateOfBirth(), COLLECTIONS.DOB);

        return http.post('/recruitingCECandidates', data);
    },

    validate(candidate, basicInformation, piiElements) {
        const data = PIIMapper
            .mapPersonalIdentifyingInformationItemsToRest(piiElements, candidate.dateOfBirth());

        _setCandidateParameters(data, candidate.id(), basicInformation);

        return http.post('/recruitingCECandidates', data, {
            headers: {
                'Ora-Irc-Validation-Mode': 'Y',
            },
            statusCodes: {
                400({ response }) {
                    if (response.indexOf('IRC-PII Validation Successful (IRC--1') !== -1) {
                        return 'pii-valid';
                    }

                    return response;
                },
            },
        }).catch((error) => {
            if (error === 'pii-valid') {
                return Promise.resolve();
            }

            if (error === 'ORA_IRC_CAND_EXISTS') {
                return Promise.resolve();
            }

            return Promise.reject(piiItemsValidationResponseParser.getErrorObjectsWithPiiInfo(error));
        });
    },

    async query(candidateId, basicInformation) {
        if (!candidateId || useDraftData()) {
            return Promise.reject();
        }

        const piiData = await loadPIIData(candidateId, basicInformation);

        return PIIMapper.mapPersonalIdentifyingInformationItemsFromRest(piiData);
    },

    store(piiModel) {
        return new Promise((resolve) => {
            const rawPII = mapping.toJS(piiModel);

            storageService.store(STORAGE_KEY, rawPII);
            resolve(rawPII);
        });
    },
};