import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import {
    CookieConsentModalActionsCustomizationParams,
    CookieConsentModalActionsParams,
} from '../config/types';
import { CookieConsentActionsCustomizationSelectors } from '../config/selectors';

export const getCustomStylesCookie = (
    key: keyof CookieConsentModalActionsCustomizationParams,
    selectors: CookieConsentActionsCustomizationSelectors,
    customizationParams: CookieConsentModalActionsParams
): string => {
    switch (key) {
        case 'acceptButtonStyling':
            return getStylingBoxCss({
                selector: selectors.acceptButtonStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'declineButtonStyling':
            return getStylingBoxCss({
                selector: selectors.declineButtonStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'savePreferencesButtonStyling':
            return getStylingBoxCss({
                selector: selectors.savePreferencesButtonStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'acceptButtonHoverStyling':
            return getStylingBoxCss({
                selector: selectors.acceptButtonHoverStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'declineButtonHoverStyling':
            return getStylingBoxCss({
                selector: selectors.declineButtonHoverStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'savePreferencesButtonHoverStyling':
            return getStylingBoxCss({
                selector: selectors.savePreferencesButtonHoverStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'cookiePreferencesButtonStyling':
            return getStylingBoxCss({
                selector: selectors.cookiePreferencesButtonStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'cookiePreferencesButtonHoverStyling':
            return getStylingBoxCss({
                selector: selectors.cookiePreferencesButtonHoverStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'cookiePreferencesLinkStyling':
            return getStylingBoxCss({
                selector: selectors.cookiePreferencesLinkStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'cookiePreferencesLinkHoverStyling':
            return getStylingBoxCss({
                selector: selectors.cookiePreferencesLinkHoverStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'overallStyling':
            return getStylingBoxCss({
                selector: selectors.overallStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        case 'acceptButtonTypography':
            return getTypographyCss({
                selector: selectors.acceptButtonTypography,
                typography: customizationParams[key],
            });
        case 'declineButtonTypography':
            return getTypographyCss({
                selector: selectors.declineButtonTypography,
                typography: customizationParams[key],
            });
        case 'savePreferencesButtonTypography':
            return getTypographyCss({
                selector: selectors.savePreferencesButtonTypography,
                typography: customizationParams[key],
            });
        case 'acceptButtonHoverTypography':
            return getTypographyCss({
                selector: selectors.acceptButtonHoverTypography,
                typography: customizationParams[key],
            });
        case 'declineButtonHoverTypography':
            return getTypographyCss({
                selector: selectors.declineButtonHoverTypography,
                typography: customizationParams[key],
            });
        case 'savePreferencesButtonHoverTypography':
            return getTypographyCss({
                selector: selectors.savePreferencesButtonHoverTypography,
                typography: customizationParams[key],
            });
        case 'cookiePreferencesButtonHoverTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferencesButtonHoverTypography,
                typography: customizationParams[key],
            });
        case 'cookiePreferencesButtonTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferencesButtonTypography,
                typography: customizationParams[key],
            });
        case 'cookiePreferencesLinkHoverTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferencesLinkHoverTypography,
                typography: customizationParams[key],
            });
        case 'cookiePreferencesLinkTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferencesLinkTypography,
                typography: customizationParams[key],
            });
        default:
            return '';
    }
};
