import { observable } from 'knockout';

export default class FormElementLabelViewModel {

    constructor({ element, id, forId }) {
        this.element = element;
        this.id = id;
        this.forId = forId;
        this.helpTextId = `${element.attributes().id}-helpText`;

        this.isHelpTooltipVisible = observable(false);
    }

    showHelpTooltip() {
        this.isHelpTooltipVisible(true);
    }

    hideHelpTooltip() {
        this.isHelpTooltipVisible(false);
    }

}
