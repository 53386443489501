import { PureComputed, Subscription, observable, pureComputed } from 'knockout';
import { DialogDisplaySettings } from 'minimal/component/minimal-dialog/MinimalDialogViewModel';
import { CookieConsentViewModel } from '../cookie-consent-modal/CookieConsentViewModel';
import { isCookiePreferencesVisible } from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_WIDGET_TYPE } from '../../types';
import { cookiePreferencesWidgetData } from 'cx/service/loadCookieConsentPageData';
import i18n from 'core/i18n/i18n';
import { getSections } from 'app/module/admin/module/site-editor/module/pages/service/pageTypes';

export class CookiePreferencesModalViewModal extends CookieConsentViewModel {
    displaySettings: PureComputed<DialogDisplaySettings>;
    classModifier: string;
    isCookiePreferencesDialogVisibleSubscription: Subscription;
    isCookiePreferencesDialogVisible = isCookiePreferencesVisible;
    categoriesSelectionModel = {
        isFunctionalCookieEnabled: observable(false),
        isAnalyticalCookieEnabled: observable(false),
        isCustomCookieCategoryEnabled: observable(false),
    };

    constructor() {
        super({ widgetType: COOKIE_CONSENT_WIDGET_TYPE.COOKIE_PREFERENCES_WIDGET });

        this.pageData().categoriesSelectionModel = this.categoriesSelectionModel;
        this.displaySettings = pureComputed(this.computeDisplaySettings, this);
        this.classModifier = `cookie-preferences-modal`;

        this.pageData().modalTitle = i18n('cookie-consent.cookie-preference-title');

        this.isCookiePreferencesDialogVisibleSubscription = this.isCookiePreferencesDialogVisible.subscribe(
            (value) => {
                if (value) {
                    const cookiePreferencesWidget = cookiePreferencesWidgetData ?? {
                        sections: getSections(COOKIE_CONSENT_WIDGET_TYPE.COOKIE_PREFERENCES_WIDGET),
                        pageParams: [],
                    };

                    this.loadCustomPage(cookiePreferencesWidget);
                }
            }
        );
    }

    computeDisplaySettings(): DialogDisplaySettings {
        const { overlayOpacity, overlayStyle, overlayWidth, overlayWidthUnit } =
            this.viewData.customPage()?.params || {};

        return {
            opacity: overlayOpacity ? `${overlayOpacity}` : null,
            alignment: overlayStyle || null,
            width: overlayWidth && overlayWidthUnit ? `${overlayWidth}${overlayWidthUnit}` : null,
        };
    }

    dispose(): void {
        this.isCookiePreferencesDialogVisibleSubscription.dispose();
    }
}
