import { JobDetailsModalViewModel } from './JobDetailsModalViewModel';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';

export class JobDetailsCssViewModel extends JobDetailsModalViewModel {
    constructor() {
        super();

        this.viewData = {
            ...this.viewData,
            goBackLabel: i18n('job-details.a11y.back-to-my-profile-button-label'),
        };
    }

    redirectToMainPage(): void {
        router.go('candidate-self-service');
    }

    afterDialogClose(): void {
        const routeId = router.route().id;

        if (routeId === 'candidate-self-service.job-preview') {
            this.redirectToMainPage();
        }
    }
}
