import {
    getMaxHeightCss,
    getWidthCss,
    STYLES_SEPARATOR,
} from 'app/module/minimal/module/search/service/customCss';
import { ObservableParams } from 'minimal/module/search/service/observableParams';
import { AdditionalHeaderContentCustomizationParams } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/types';
import { HeaderSelector } from './selectors';

type Props = {
    selectors: HeaderSelector;
    content: ObservableParams<AdditionalHeaderContentCustomizationParams>;
};

export const getContentCss = ({ selectors, content }: Props): string => {
    const heightStyles = content.height()
        ? getMaxHeightCss({
              selector: selectors.wrapper,
              height: Number(content.height()),
              heightUnit: content.heightUnit(),
          })
        : '';

    const widthStyles = content.menuWidth()
        ? getWidthCss({
              selector: selectors.menu,
              width: Number(content.menuWidth()),
              widthUnit: content.menuWidthUnit(),
          })
        : '';

    const desktopLogoHeight = content.desktopLogoMaxHeight()
        ? getMaxHeightCss({
              selector: selectors.logoDesktop,
              height: Number(content.desktopLogoMaxHeight()),
              heightUnit: content.desktopLogoMaxHeightUnit(),
          })
        : '';

    const mobileLogoHeight = content.mobileLogoMaxHeight()
        ? getMaxHeightCss({
              selector: selectors.logoMobile,
              height: Number(content.mobileLogoMaxHeight()),
              heightUnit: content.mobileLogoMaxHeightUnit(),
          })
        : '';

    return [heightStyles, widthStyles, desktopLogoHeight, mobileLogoHeight].join(STYLES_SEPARATOR);
};
