import router from 'app/model/router';

router.configure({
    'fake-job-details': {
        parent: 'minimal',
        url: '/fake-job/{jobId}:?query:',
        view: {
            main: 'search',
            dialogs: 'fake-job-details',
        },
    },
});
