import { components } from 'knockout';

import { JobDetailsApplyButtonViewModel } from './JobDetailsApplyButtonViewModel';
import template from './job-details-apply-button.html';

components.register('job-details-apply-button', {
    viewModel: JobDetailsApplyButtonViewModel,
    template,
});

components.register('cc-job-details-apply-button', {
    viewModel: JobDetailsApplyButtonViewModel,
    template,
});
