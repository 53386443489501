import Component from './Component';

export default class ContentComponent extends Component {

    constructor(data) {
        super(data);

        this.content = data.content;
    }

}
