import AutosuggestFormElement from 'core/form/element/Autosuggest';
import CheckboxFormElement from 'core/form/element/Checkbox';
import CheckboxStringValueFormElement from 'core/form/element/CheckboxStringValue';
import ClobFormElement from 'core/form/element/Clob';
import DecimalFormElement from 'core/form/element/Decimal';
import EmailFormElement from 'core/form/element/Email';
import IntegerFormElement from 'core/form/element/Integer';
import MultiSelectFormElement from 'core/form/element/MultiSelect';
import PhoneFormElement from 'core/form/element/Phone';
import RadioFormElement from 'core/form/element/Radio';
import SearchFormElement from 'core/form/element/Search';
import SelectFormElement from 'core/form/element/Select';
import TextFormElement from 'core/form/element/Text';
import TextAreaFormElement from 'core/form/element/TextArea';
import UrlFormElement from 'core/form/element/Url';
import SiteLinkFormElement from 'core/form/element/SiteLink';
import DatepickerFormElement from 'core/form/element/Datepicker';
import DateMonthpickerElement from 'app/module/core/form/element/DateMonthpicker';
import MonthpickerElement from 'core/form/element/Monthpicker';
import MultiSelectListFormElement from 'core/form/element/MultiSelectList';
import NoResponseFormElement from 'core/form/element/NoResponse';
import PinFormElement from 'core/form/element/Pin';
import FileFormElement from 'core/form/element/File';
import { MultiSelectComboBoxFormElement } from './MultiSelectComboBox';

export default {
    create(type, name) {
        const FormElement = this.elements[type];

        return new FormElement(name);
    },

    elements: {
        autosuggest: AutosuggestFormElement,
        checkbox: CheckboxFormElement,
        checkboxStringValue: CheckboxStringValueFormElement,
        clob: ClobFormElement,
        date: DatepickerFormElement,
        dateMonthpicker: DateMonthpickerElement,
        decimal: DecimalFormElement,
        email: EmailFormElement,
        integer: IntegerFormElement,
        multiselect: MultiSelectFormElement,
        phone: PhoneFormElement,
        radio: RadioFormElement,
        select: SelectFormElement,
        search: SearchFormElement,
        text: TextFormElement,
        textarea: TextAreaFormElement,
        monthpicker: MonthpickerElement,
        url: UrlFormElement,
        siteLink: SiteLinkFormElement,
        multiselectlist: MultiSelectListFormElement,
        noresponse: NoResponseFormElement,
        pin: PinFormElement,
        file: FileFormElement,
        multiSelectComboBox: MultiSelectComboBoxFormElement,
    },
};
