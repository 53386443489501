import {
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { getHeaderSelectors } from './selectors';
import { getContentCss } from './contentCss';
import { AdditionalHeaderParams } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/types';

export const getCustomStyles = (settings: AdditionalHeaderParams, uniqueWrapperClass: string): string => {
    const selectors = getHeaderSelectors(`.${uniqueWrapperClass}`);

    const customizationKeys = Object.keys(settings) as (keyof AdditionalHeaderParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'content':
                return getContentCss({ selectors: selectors, content: settings.content });

            case 'linksTypography':
                return getTypographyCss({
                    selector: selectors.links,
                    typography: settings[key],
                });

            case 'linksHoverTypography':
                return getTypographyCss({
                    selector: selectors.linksHover,
                    typography: settings[key],
                });

            case 'linkSelectedTypography':
                return getTypographyCss({
                    selector: selectors.linksSelected,
                    typography: settings[key],
                });

            case 'sublinksTypography':
                return getTypographyCss({
                    selector: selectors.subLinks,
                    typography: settings[key],
                });

            case 'sublinksHoverTypography':
                return getTypographyCss({
                    selector: selectors.subLinksHover,
                    typography: settings[key],
                });

            case 'submenuTypography':
                return getTypographyCss({
                    selector: selectors.subMenu,
                    typography: settings[key],
                });

            case 'submenuHoverTypography':
                return getTypographyCss({
                    selector: selectors.subMenuHover,
                    typography: settings[key],
                });

            case 'emailTypography':
                return getTypographyCss({
                    selector: selectors.email,
                    typography: settings[key],
                });

            case 'locationTypography':
                return getTypographyCss({
                    selector: selectors.location,
                    typography: settings[key],
                });

            case 'nameTypography':
                return getTypographyCss({
                    selector: selectors.name,
                    typography: settings[key],
                });

            case 'overallStyling':
                return getStylingBoxCss({
                    selector: selectors.wrapper,
                    stylingBox: settings[key],
                    lightIconSelector: selectors.overallIcons,
                    ruleColorSelector: selectors.ruleColor,
                    darkIconSelector: null,
                });

            case 'overallStylingHover':
                return getStylingBoxCss({
                    selector: selectors.wrapper,
                    stylingBox: settings[key],
                    lightIconSelector: selectors.overallIconsHover,
                    darkIconSelector: null,
                });

            case 'linksStyling':
                return getStylingBoxCss({
                    selector: selectors.linksContainer,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'linksHoverStyling':
                return getStylingBoxCss({
                    selector: selectors.linksContainerHover,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'linksSelectedStyling':
                return getStylingBoxCss({
                    selector: selectors.linksContainerSelected,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'sublinksStyling':
                return getStylingBoxCss({
                    selector: selectors.subLinksContainer,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'sublinksHoverStyling':
                return getStylingBoxCss({
                    selector: selectors.subLinksContainerHover,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'buttonsStyling':
                return getStylingBoxCss({
                    selector: selectors.buttons,
                    stylingBox: settings[key],
                    lightIconSelector: selectors.icons,
                    darkIconSelector: null,
                });

            case 'buttonsHoverStyling':
                return getStylingBoxCss({
                    selector: null,
                    stylingBox: settings[key],
                    lightIconSelector: selectors.iconsHover,
                    darkIconSelector: null,
                });

            case 'buttonsSelectedStyling':
                return getStylingBoxCss({
                    selector: null,
                    stylingBox: settings[key],
                    lightIconSelector: selectors.iconsSelected,
                    darkIconSelector: null,
                });

            case 'menuStyling':
                return getStylingBoxCss({
                    selector: selectors.menu,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'dropdownStyling':
                return getStylingBoxCss({
                    selector: selectors.dropdown,
                    stylingBox: settings[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            default:
                return '';
        }
    });

    return customStyles.filter((style) => style?.trim().length).join(STYLES_SEPARATOR);
};
