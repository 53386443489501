export let applicationDraft;

export const applicationDraftInstance = {

    set(instance) {
        applicationDraft = instance;
    },

    clear() {
        applicationDraft = null;
    },
};