import ko from 'knockout';
import favouriteJobsService from 'minimal/service/favouriteJobs';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import a11yEvents from 'core/a11y/events';
import i18n from 'core/i18n/i18n';

export default class FavouriteJobsViewModel {

    constructor() {
        this.hasFavourites = favouriteJobsService.hasFavourites();
        this.favouriteJobs = favouriteJobsService.getFavourites();

        this.isFavouritesListOpened = ko.observable(false);
    }

    removeFavourite(favouriteJob) {
        favouriteJobsService.removeFavourite(favouriteJob);
        a11yEvents.status.dispatch(i18n('favourite.a11y.job-unpinned'));
    }

    toggleList() {
        this.isFavouritesListOpened(!this.isFavouritesListOpened());
    }

    closeList() {
        this.isFavouritesListOpened(false);
    }

    openJobPreview(favouriteJob) {
        router.go('job-preview', {
            jobId: favouriteJob.id,
        });
    }

    generateJobUrl(favouriteJob) {
        return absoluteRouter.interpolate({}, 'job-details', { jobId: favouriteJob.id });
    }

}
