import { components } from 'knockout';

import TalentCommunitySummaryViewModel from './TalentCommunitySummaryViewModel';
import TalentCommunityUpdateSummaryViewModel from './TalentCommunityUpdateSummaryViewModel';

import template from './talent-community-summary.html';

components.register('talent-community-summary', {
    viewModel: TalentCommunitySummaryViewModel,
    template,
});

components.register('talent-community-update-summary', {
    viewModel: TalentCommunityUpdateSummaryViewModel,
    template,
});
