import { FunctionComponent } from 'preact';
import i18n from 'core/i18n/i18n';
import { Job } from 'minimal/module/job-details/types';
import siteLanguage from 'app/module/ce-common/service/language/siteLanguage';
import userTracking from 'cx/service/userTracking';
import { getApplyFlowRouting } from 'candidate-verification/service/routes';
import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';
import userEvents, { APPLY_FLOW_ENTERED } from 'cx/config/userEvents';
import cxSignals from 'cx/config/events';

type Props = {
    job: Job;
    className: string;
};

type ApplyFlowRoutingParams = {
    sectionNumber?: string;
    jobId?: string;
    sourceContext?: string | undefined;
};

type ApplyFlowRouting = {
    route?: string;
    params: ApplyFlowRoutingParams;
};

export const SearchResultItemApplyButton: FunctionComponent<Props> = ({ job, className }) => {
    const redirectOnApply = (job: Job, flowRouting: ApplyFlowRouting) => {
        const { route, params } = flowRouting as ApplyFlowRouting;

        params.jobId = job.id;

        const url = absoluteRouter.interpolate({ lang: job.lang }, route, params);

        return router.redirect(url);
    };

    const apply = (e: Event, job: Job) => {
        cxSignals.toggleLoading.dispatch('apply-now-button');

        e.cancelBubble = true;
        e.preventDefault();

        userEvents[APPLY_FLOW_ENTERED].dispatch({
            jobId: job.id,
        });

        siteLanguage.store();
        userTracking.trackJobApplicationStart(job.id, 'apply_now');

        getApplyFlowRouting(job.id, true)
            .then((flowRouting: ApplyFlowRouting) => {
                redirectOnApply(job, flowRouting);
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    return (
        <button
            onClick={(e) => apply(e, job)}
            type="button"
            className={`button job-list-item__apply-now-button theme-color-1 ${className}`}
        >
            <span class="job-list-item__apply-now-button-frame"></span>
            <span className="button__label">{i18n('job-details.apply-button') ?? ''}</span>
        </button>
    );
};
