import { PureComputed, pureComputed, Observable } from 'knockout';
import { EventHeadingConfig, EventTagsConfig, HeaderInformationConfig } from '../../../types';
import { DistanceUnit, EventShape } from 'cx/module/search/config/types';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { searchEventResults } from 'app/module/minimal/module/search/model/searchEventResults';
import i18n from 'app/module/core/i18n/i18n';
import router from 'app/model/router';
import { isDistanceOutOfRadius } from 'app/module/cx/module/search/service/distanceCalculation';
import { getDistanceText } from 'minimal/component/search-results-distance/service/distance';
import { EventTag } from 'app/module/minimal/module/event-tags/config/eventTags';
import { LayoutStyle, SearchResultsParams } from '../../../../search-results/config/types';

type Props = {
    event: EventShape;
    eventHeadingConfig: PureComputed<EventHeadingConfig>;
    eventTagsConfig: PureComputed<EventTagsConfig>;
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    eventTags: EventTag[];
    customizationParams: SearchResultsParams;
};

const DISTANCE_UNITS_NAMES: Record<Exclude<DistanceUnit, null>, string> = {
    MI: i18n('location-bar.dropdown.radius-unit.mi'),
    KM: i18n('location-bar.dropdown.radius-unit.km'),
};

export default class SearchResultItemHeaderViewModel {
    event: EventShape;
    customizationParams: SearchResultsParams;
    eventHeadingConfig: PureComputed<EventHeadingConfig>;
    hasSubheader: PureComputed<boolean>;
    distanceInfo: DistanceInfo;
    eventTagsConfig: PureComputed<EventTagsConfig>;
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    isAlreadyRegisteredTagVisible: PureComputed<boolean>;
    eventTags: EventTag[];
    isRegistrationPhaseConfirmed: PureComputed<boolean>;
    layoutStyle: Observable<LayoutStyle>;

    constructor({
        event,
        customizationParams,
        eventHeadingConfig,
        eventTagsConfig,
        headerInformationConfig,
        eventTags,
    }: Props) {
        this.event = event;
        this.customizationParams = customizationParams;
        this.eventHeadingConfig = eventHeadingConfig;
        this.eventTagsConfig = eventTagsConfig;
        this.headerInformationConfig = headerInformationConfig;
        this.distanceInfo = this.getDistanceInfo(event.distance);
        this.layoutStyle = this.customizationParams.contentParams.layoutStyle;

        this.isAlreadyRegisteredTagVisible = pureComputed(
            () => this.headerInformationConfig().isAlreadyRegisteredTagVisible
        );

        this.hasSubheader = pureComputed(() => {
            const { isLocationVisible, isEventFormatVisible, isEventDateVisible } = eventHeadingConfig();

            return isLocationVisible || isEventFormatVisible || isEventDateVisible;
        });

        this.eventTags = eventTags;

        this.isRegistrationPhaseConfirmed = pureComputed(
            () => this.headerInformationConfig().isRegistrationPhaseConfirmed
        );
    }

    getDistanceInfo(jobDistance?: string): DistanceInfo {
        const distanceUnit = searchEventResults.distanceUnit as DistanceUnit;
        const distanceUnitName = distanceUnit ? DISTANCE_UNITS_NAMES[distanceUnit] : '';
        const distanceStringKey = 'search.job-item.distance-single-location';
        const inputRadius = this.getInputRadius();
        const isOutOfRadius = Boolean(isDistanceOutOfRadius(inputRadius, jobDistance));
        const distanceText = getDistanceText({
            isDistanceOutOfRadius: isOutOfRadius,
            inputRadius,
            jobDistance,
        });

        return {
            distanceUnit: distanceUnitName,
            distanceStringKey,
            inputRadius,
            isDistanceOutOfRadius: isOutOfRadius,
            distanceText,
        };
    }

    getInputRadius(): string | undefined {
        const routeParams = router.routeParams() as {
            query?: {
                radius: string;
            };
        };

        return routeParams.query?.radius;
    }
}
