import router from 'app/model/router';
import referralService from 'candidate-verification/service/referral';

export default class ShareVerificationViewModel {

    constructor() {
        this.updateHitCount();
    }

    updateHitCount() {
        const routeParams = router.routeParams();

        if (routeParams.shareId) {
            return referralService.updateShareHitCount(
                routeParams.shareId,
                routeParams.jobId,
            );
        }

        return undefined;
    }

}
