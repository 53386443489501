export default class InterviewAction {

    constructor(data = {}) {
        this.interviewRequestId = data.requestId;
        this.interviewId = data.interviewId;
        this.submissionId = data.submissionId;
        this.actionType = data.actionType;
        this.message = data.message;
        this.scheduleId = data.scheduleId;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.comments = data.comments;
    }

}