import router from 'app/model/router';
import {
    RouterParams,
    SearchContext,
    searchRouteMap,
    searchRouteAndPreviewMap,
    SEARCH_RESULTS_STRINGS,
} from 'app/module/cx/module/search/config/types';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { getSearchContextDetails, saveSearchContextDetails } from '../service/storeSearchContextDetails';
import { searchEventResults } from '../model/searchEventResults';
import { searchJobResults } from '../model/searchJobResults';

export const changeSearchRoute = (context: SearchContext): void => {
    const routeParams = router.routeParams() as RouterParams;

    saveSearchContextDetails(context, routeParams);

    const routeParamsFromStorage = getSearchContextDetails(context) as RouterParams;
    let queryFromStorage;
    let routeParamsQuery;

    if (context === SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS) {
        queryFromStorage = new SearchQueryBuilder(routeParamsFromStorage?.query).build();

        routeParamsQuery = new SearchQueryBuilder(routeParams?.query)
            .withoutFacets()
            .withoutSortOption()
            .build();
    } else {
        queryFromStorage = new SearchQueryBuilder(routeParamsFromStorage?.query).build();

        routeParamsQuery = new SearchQueryBuilder(routeParams?.query)
            .withoutFacets()
            .withoutSortOption()
            .build();
    }

    const isQueryFromStorageEmpty = Object.keys(queryFromStorage).length === 0;
    const query = isQueryFromStorageEmpty ? routeParamsQuery : queryFromStorage;

    router.go(searchRouteMap[context], { query }, { inherit: false });
};

export const isRouteChanged = (previousRouteId: string | null): boolean =>
    previousRouteId !== router.route().id;

export const getSearchModelFromRoute = (): typeof searchJobResults | typeof searchEventResults => {
    const route = router.route().id;

    switch (route) {
        case searchRouteAndPreviewMap.events:
        case searchRouteAndPreviewMap.eventPreview:
            return searchEventResults;
        case searchRouteAndPreviewMap.jobs:
        case searchRouteAndPreviewMap.jobPreview:
        default:
            return searchJobResults;
    }
};
