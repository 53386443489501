import i18n from 'core/i18n/i18n';
import { RecommendedJobsWidgetCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { USER_NAME_TOKEN } from './messages';

const RECOMMENDED_JOBS_DEFAULT_THEME_COLOR = 'var(--color-theme-1)';

export const emptyParams: RecommendedJobsWidgetCustomizationParams = {
    content: {
        width: 1000,
        widthUnit: 'px',
        buttonSize: 'large',
        enablePrivacyPolicy: false,
    },
    callToActionDesktopText: { text: i18n('recommended-jobs-widget.messages.call-to-action-desktop') },
    callToActionMobileText: { text: i18n('recommended-jobs-widget.messages.call-to-action-mobile') },
    callToActionButtonText: { text: i18n('recommended-jobs-widget.messages.call-to-action-button') },
    uploadingText: { text: i18n('recommended-jobs-widget.messages.uploading') },
    successMessageText: {
        text: i18n('recommended-jobs-widget.messages.success', { user_name: USER_NAME_TOKEN }),
    },
    privacyPolicyText: { text: '' },
    callToActionTypography: { ...EMPTY_TYPOGRAPHY },
    callToActionHoverTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyLinkTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyLinkHoverTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyModalHeaderTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyModalTextTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyModalCloseButtonTypography: { ...EMPTY_TYPOGRAPHY },
    privacyPolicyModalCloseButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    browseButtonTypography: { ...EMPTY_TYPOGRAPHY },
    browseButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    uploadingMessageTypography: { ...EMPTY_TYPOGRAPHY },
    errorMessageTypography: { ...EMPTY_TYPOGRAPHY },
    successMessageTypography: { ...EMPTY_TYPOGRAPHY },
    manageTypography: { ...EMPTY_TYPOGRAPHY },
    manageHoverTypography: { ...EMPTY_TYPOGRAPHY },
    manageDropdownTypography: { ...EMPTY_TYPOGRAPHY },
    viewAllJobsLabelTypography: { ...EMPTY_TYPOGRAPHY },
    manageDropdownHoverTypography: { ...EMPTY_TYPOGRAPHY },
    viewAllJobsLabelHoverTypography: { ...EMPTY_TYPOGRAPHY },
    containerStyling: { ...EMPTY_BLOCK_STYLES },
    overallStyling: { ...EMPTY_BLOCK_STYLES },
    overallHoverStyling: { ...EMPTY_BLOCK_STYLES },
    privacyPolicyModalStyling: { ...EMPTY_BLOCK_STYLES },
    privacyPolicyModalCloseButtonStyling: { ...EMPTY_BLOCK_STYLES },
    privacyPolicyModalCloseButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    browseButtonStyling: { ...EMPTY_BLOCK_STYLES },
    browseButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    successMessageHoverStyling: { ...EMPTY_BLOCK_STYLES },
    uploadingMessageStyling: { ...EMPTY_BLOCK_STYLES },
    errorMessageStyling: { ...EMPTY_BLOCK_STYLES },
    successMessageStyling: { ...EMPTY_BLOCK_STYLES },
    viewAllJobsStyling: { ...EMPTY_BLOCK_STYLES },
    viewAllJobsHoverStyling: { ...EMPTY_BLOCK_STYLES },
    manageDropdownStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};

export const defaultHoverParams: RecommendedJobsWidgetCustomizationParams = {
    ...emptyParams,
    manageHoverTypography: {
        ...emptyParams.manageHoverTypography,
        color: RECOMMENDED_JOBS_DEFAULT_THEME_COLOR,
    },
    viewAllJobsLabelHoverTypography: {
        ...emptyParams.viewAllJobsLabelHoverTypography,
        color: RECOMMENDED_JOBS_DEFAULT_THEME_COLOR,
    },
    viewAllJobsHoverStyling: {
        ...emptyParams.viewAllJobsHoverStyling,
        iconColorLight: RECOMMENDED_JOBS_DEFAULT_THEME_COLOR,
    },
    successMessageHoverStyling: {
        ...emptyParams.successMessageHoverStyling,
        iconColorLight: RECOMMENDED_JOBS_DEFAULT_THEME_COLOR,
    },
};
