import ko from 'knockout';
import dictionaryService from 'apply-flow/service/dictionary';

function _countriesDictionaryPromise() {
    return dictionaryService.get(dictionaryService.CODES.countries);
}

export default {
    build(location) {
        return _countriesDictionaryPromise().then((countries) => {
            let locationNameParts = [];

            const locationCountry = ko.utils.arrayFirst(countries,
                country => location.countryCode === country.territoryCode) || {};

            locationNameParts.push(location.addressLine1);
            locationNameParts.push(location.city);
            locationNameParts.push(location.state);
            locationNameParts.push(locationCountry.territoryShortName);

            locationNameParts = locationNameParts.filter(element => Boolean(element));

            return {
                name: locationNameParts.join(', '),
                latitude: location.latitude,
                longitude: location.longitude,
            };
        });
    },
};
