import { COUNTRY_LOV_COLUMN } from '../config/countryLovColumn';
import { GeographyFieldName } from '../config/types';

export default class GeoHierarchyQueryBuilder {
    conditions: Record<string, string>;
    finder: string;
    geographyType: string;

    constructor(level: number, geographyType = '') {
        this.conditions = {};
        this.geographyType = geographyType;
        this.finder = `findByElement${level}`;
    }

    maskFieldSpecialCharacters(value: string): string {
        const maskedFields = [{ pattern: /'/g, value: '%27' }];

        let processedValue = encodeURIComponent(value).replace(
            /[-_.!~*()]/g,
            (character) => `%${character.charCodeAt(0).toString(16)}`
        );

        maskedFields.forEach(({ pattern, value }) => {
            processedValue = processedValue.replace(pattern, value);
        });

        return processedValue;
    }

    where(fieldName: GeographyFieldName | undefined, fieldValue: string | null): this {
        if (fieldName && fieldValue) {
            this.conditions[fieldName] = `${fieldName}=${fieldValue}`;
        }

        return this;
    }

    whereLike(fieldName: GeographyFieldName | undefined, fieldValue: string | null): this {
        if (fieldName && fieldValue) {
            this.conditions[fieldName] = `Value=${this.maskFieldSpecialCharacters(fieldValue)}`;
        }

        return this;
    }

    getQuery(): string {
        const conditionsPart = (Object.keys(this.conditions) as GeographyFieldName[])
            .filter((conditionKey) => conditionKey !== COUNTRY_LOV_COLUMN)
            .map((conditionKey) => this.conditions[conditionKey]);

        if (this.geographyType) {
            conditionsPart.push(`GeographyType=${this.geographyType}`);
        }

        return `finder=${this.finder};${conditionsPart.join(',')}`;
    }
}
