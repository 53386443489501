import i18n from 'core/i18n/i18n';
import http from 'core/http/http';
import { toJS } from 'knockout';
import router from 'app/model/router';
import {
    mapFacetParamsToRest,
    mapFacetFiltersFromRest,
    mapEventFacetFiltersFromRest,
} from '../mapper/facets';
import { FacetFindParams, Filter, FacetSearchQuery } from '../config/types';
import { RouterQuery, SearchContext, SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';
import appConfig from 'app/model/config';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { Facet } from 'minimal/module/search/component/search-facet-dropdown-item/SearchFacetDropdownItemViewModel';

const JOBS_FACETS_BASE_URL =
    '/recruitingCEJobRequisitions?onlyData=true&expand=flexFieldsFacet.values&finder=findReqs;:findParams:';

const EVENTS_FACETS_BASE_URL = '/recruitingCEEvents?onlyData=true&finder=findEvents;:findParams:';

type FacetsHttpConfigParams = {
    findParams: FacetFindParams;
};

type FacetsHttpConfig = {
    params: FacetsHttpConfigParams;
    paramsDelimiter: string;
};

export const searchFacets = async (
    facetName: string,
    facetKeyword: string,
    selectedFiltersQuery: RouterQuery,
    facetContext: SearchContext
): Promise<Filter[]> => {
    const httpConfig = {
        params: {
            findParams: mapFacetParamsToRest(
                appConfig.siteNumber,
                facetName,
                facetKeyword,
                selectedFiltersQuery
            ),
        },
        paramsDelimiter: ',',
    } as FacetsHttpConfig;

    let result;

    const isEventFacetContext = facetContext === SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS;

    const baseURL = isEventFacetContext ? EVENTS_FACETS_BASE_URL : JOBS_FACETS_BASE_URL;

    try {
        result = await http.get(baseURL, httpConfig);
    } catch (error) {
        console.error(error);

        return [];
    }

    return isEventFacetContext
        ? mapEventFacetFiltersFromRest(result.items[0])
        : mapFacetFiltersFromRest(result.items[0]);
};

export const getFacetSearchQuery = (facet: Facet): FacetSearchQuery => {
    const routeParams = router.routeParams() as { query: RouterQuery };
    const { query } = routeParams;

    return new SearchQueryBuilder(query).withFacet(toJS(facet)).build() as FacetSearchQuery;
};

export const getFilterByLabel = (name: string, title?: string): string => {
    const facetName = title ?? i18n(`facets.${name.toLowerCase()}-title`);

    return i18n('facets.filter-by-label', {
        facet_name: facetName,
    });
};
