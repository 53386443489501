
export default {
    mapFromRest(skills) {
        if (!skills || !skills.length) {
            return '';
        }

        const skillsPerSectionMap = new Map();

        skills.forEach((skillDetail) => {
            const { sectionName, skill } = skillDetail;

            if (!skillsPerSectionMap.has(sectionName)) {
                skillsPerSectionMap.set(sectionName, []);
            }

            skillsPerSectionMap
                .get(sectionName)
                .push(skill);
        });

        const mappedSkills = [];

        skillsPerSectionMap.forEach((skill, section) => {
            const mappedSkill = {
                sectionName: section,
                skills: skill,
            };

            mappedSkills.push(mappedSkill);
        });

        return mappedSkills;
    },
};
