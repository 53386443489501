import Page from '../model/Page';
import sectionMapper from './section';
import paramsMapper from './params';

export default {
    mapPagesFromRest(restCollection) {
        return restCollection.items.map(page => this.mapPageFromRest(page));
    },

    mapPageFromRest(page) {
        return new Page({
            title: page.title,
            sections: sectionMapper.mapSectionsFromRest(page.sections),
            params: paramsMapper.mapParamsFromRest(page.pageParams),
            type: page.type,
        });
    },
};