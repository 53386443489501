import appConfig from 'app/model/config';

const regExps = [
    /(<|<;|%3C)body\b[^>]*(>|%3E)|(<|<;|%3C)\/body\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)head\b[^>]*(>|%3E)|(<|<;|%3C)\/head\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)html\b[^>]*(>|%3E)|(<|<;|%3C)\/html\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)link\b[^>]*(>|%3E)|(<|<;|%3C)\/link\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)style\b[^>]*(>|%3E)|(<|<;|%3C)\/style\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)object\b[^>]*(>|%3E)|(<|<;|%3C)\/object\b[^>]*(>|%3E)/i,
    /(<|<;|%3C)(javascript|script)\b[^>]*(>|%3E)|(<|<;|%3C)(\/javascript|\/script)\b[^>]*(>|%3E)/i,
    /(?:(<|<;|%3C)[a-zA-Z0-9_\-:][^>]*)\s(on[\w]+[\s]*=|FSCommand[\s]*|seekSegmentTime[\s]*=|href[\s]*=["']?\s*javascript)/i,
    /(:execScript|script:|script\s*&colon|behavior:url|addBehavior\(|execCommand\()/i,
    /(<|%3C)\?xml/i,
];

export default {
    validate(value) {
        const xssValidationDisabled = appConfig.getSettingByKey('ORA_IRC_CE_XSS_VALIDATOR_DISABLED') === 'true';

        if (xssValidationDisabled || !value) {
            return true;
        }

        const containsInvalidCode = regExps.some(regExp => regExp.test(value));

        return containsInvalidCode === false;
    },
};
