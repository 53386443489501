import { components } from 'knockout';

import ViewModel from 'custom-content/component/button/ButtonViewModel';
import template from 'custom-content/component/button/button.html';

components.register('cc-button', {
    viewModel: {
        createViewModel(params) {
            return new ViewModel(params, 'button');
        },
    },
    template,
});
