import mapping from 'knockout-mapping';
import { observableArray } from 'knockout';
import debouncePromise from 'core/utils/debouncePromise';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class MultiSelectDropdown extends ValidatableFormElement {

    constructor({ options }) {
        super();

        this.component('multi-select-dropdown-form-element');
        this.options = observableArray(options);

        this.fetchOptions = debouncePromise(this.fetchOptions.bind(this), 0);
    }

    fetchOptions() {
        return this._disableOptions(this.options());
    }

    _disableOptions(options) {
        const selectedValues = mapping.toJS(this.value())
            .map(({ value: { locationId } }) => locationId);

        options
            .forEach(option => (option.disabled = (selectedValues.indexOf(option.value.locationId) !== -1)));

        return options;
    }

    getLabelForValue(value) {
        const [selected] = this.options()
            .filter(option => option.value === value);

        if (selected) {
            return selected.name;
        }

        return '';
    }

    _removeOption(option) {
        const valueList = this.value();

        const [optionToRemove] = this.value()
            .filter(({ value }) => value === option.value);

        valueList.splice(valueList.indexOf(optionToRemove), 1);

        this.value(valueList);
    }

}
