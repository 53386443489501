const defaultConsoleError = console.error.bind(console);

export function extendConsoleError(callback) {
    console.error = (...args) => {
        defaultConsoleError.apply(console, args);
        callback(args);
    };
}

export function restoreDefaultConsoleError() {
    console.error = defaultConsoleError;
}
