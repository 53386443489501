import requestChannelFactory from 'core/http/request-channel/factory';
import exceptionRetryHandler, { MAX_RETRY } from 'core/http/exceptionRetryHandler';
import { sleep } from 'core/utils/sleep';

const _queue = {};

export default {
    create(config) {
        return (config.method === 'GET' ? this._queue(config) : this._standard(config));
    },

    _standard(config) {
        const channel = requestChannelFactory.getChannel(config.method);

        return channel.request(config)
            .catch((reason) => {
                if (config.retry) {
                    return this._retry(config);
                }

                if (exceptionRetryHandler.shouldRetry(reason)) {
                    if (isNaN(config.retry)) {
                        config.retry = MAX_RETRY;

                        return this._retry(config);
                    }
                }

                return Promise.reject(reason);
            });
    },

    async _retry(requestConfig) {
        const config = requestConfig;
        const retryCount = Number(config.retry);

        config.retry = retryCount - 1;

        if (isNaN(config.retry)) {
            throw new Error('HTTP Request: parameter "retry" isNaN');
        }

        await sleep(Math.pow(2 - retryCount, 2) * 1000);

        return this._standard(config);
    },

    _queue(config) {
        if (config.url in _queue === false) {
            _queue[config.url] = this._standard(config)
                .then((response) => {
                    delete _queue[config.url];

                    return response;
                })
                .catch((error) => {
                    delete _queue[config.url];

                    throw error;
                });
        }

        return _queue[config.url];
    },
};