import ko from 'knockout';
import formBuilder from 'apply-flow/module/profile-items/service/profileItemsFormBuilder';
import workPreferencesFormElementFactory from '../form/element/factory';
import workPreferencesFieldMap from '../config/workPreferencesFieldMap';

const LOCATIONS_FIELD = 'locations-selector';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            this._appendSearchLocationsField(config);
            this._removeLocationFields(config);

            const createForm = formBuilder.createForm.bind(this);
            const form = createForm(config).bindComponent('work-preferences');

            this._hidePreferredOrExcludedLocationWhenNoWillingToRelocate(form);

            this._makeCurrencyCodeAndFrequencyMandatoryWhenMinimumSalaryIsNotEmpty(form);
            this._makeCurrencyCodeAndFrequencyHiddenWhenNotRequired(form);
            this._setFocusOnMinimumSalary(form);

            this._hideTravelFrequencyWhenTravelingIsOff({
                form,
                travelFieldName: 'natTravelFlag',
                frequencyFieldName: 'natTravelFrequency',
            });

            this._hideTravelFrequencyWhenTravelingIsOff({
                form,
                travelFieldName: 'intlTravelFlag',
                frequencyFieldName: 'intlTravelFrequency',
            });

            this._requirePositiveIntegerFrom(form.getElement('relocateDuration'));

            return form;
        },
    },

    createFormElement: {
        value(elementDef) {
            const formElement = workPreferencesFormElementFactory.create(elementDef.type, elementDef.name);

            return this._initFormElement(formElement, elementDef);
        },
    },

    _initFormElement: {
        value(formElement, elementDef) {
            if (elementDef.preferredFields) {
                formElement.preferredFields(elementDef.preferredFields);
            }

            if (elementDef.excludedFields) {
                formElement.excludedFields(elementDef.excludedFields);
            }

            return formBuilder._initFormElement(formElement, elementDef);
        },
    },

    _appendSearchLocationsField: {
        value(config) {
            const alreadyAdded = config.elements.filter(element => element.name === LOCATIONS_FIELD).length > 0;

            if (alreadyAdded) {
                return;
            }

            const preferredLocation = workPreferencesFieldMap.getPreferredLocationFields();
            const excludedLocation = workPreferencesFieldMap.getExcludedLocationFields();

            const filterElements = (elements, names) =>
                elements.filter(element => names.indexOf(element.name) > -1 && !element.isHidden);

            const locationNames = preferredLocation.concat(excludedLocation);

            const locationElements = filterElements(config.elements, locationNames);

            const locationsSearchField = {
                name: LOCATIONS_FIELD,
                type: 'workLocations',
                isHidden: locationElements.every(element => element.isHidden),
                preferredFields: filterElements(locationElements, preferredLocation),
                excludedFields: filterElements(locationElements, excludedLocation),
            };

            const allLocationsField = config.elements
                .filter(element => element.name === 'allLocationsFlag')
                .shift();

            const allLocationsFieldIndex = config.elements.indexOf(allLocationsField) + 1;

            config.elements.splice(allLocationsFieldIndex, 0, locationsSearchField);
        },
    },

    _removeLocationFields: {
        value(config) {
            const preferredLocation = workPreferencesFieldMap.getPreferredLocationFields();
            const excludedLocation = workPreferencesFieldMap.getExcludedLocationFields();

            const locationNames = preferredLocation.concat(excludedLocation);

            config.elements = config.elements
                .filter(element => locationNames.indexOf(element.name) < 0);
        },
    },

    _makeCurrencyCodeAndFrequencyMandatoryWhenMinimumSalaryIsNotEmpty: {
        value(form) {
            const minimumSalary = form.getElement('minimumPay');
            const currencyCode = form.getElement('currencyCode');
            const frequency = form.getElement('payFrequency');

            if (minimumSalary) {
                minimumSalary.validators('positiveInteger');
                minimumSalary.valueUpdateType('input');

                const isMinimumSalaryNotEmpty = ko.pureComputed(() => Boolean(minimumSalary.value()));

                if (currencyCode && !currencyCode.isRequired()) {
                    if (isMinimumSalaryNotEmpty()) {
                        currencyCode.isRequired(true);
                    }

                    isMinimumSalaryNotEmpty.subscribe((isNotEmpty) => {
                        currencyCode.isRequired(isNotEmpty);

                        if (!isNotEmpty) {
                            currencyCode.value(null);
                        }
                    });
                }

                if (frequency && !frequency.isRequired()) {
                    if (isMinimumSalaryNotEmpty()) {
                        frequency.isRequired(true);
                    }

                    isMinimumSalaryNotEmpty.subscribe((isNotEmpty) => {
                        frequency.isRequired(isNotEmpty);

                        if (!isNotEmpty) {
                            frequency.value(null);
                        }
                    });
                }
            }
        },
    },

    _makeCurrencyCodeAndFrequencyHiddenWhenNotRequired: {
        value(form) {
            const minimumSalary = form.getElement('minimumPay');
            const currencyCode = form.getElement('currencyCode');
            const frequency = form.getElement('payFrequency');
            let isMinimumSalaryEmptyOrHidden;

            if (minimumSalary) {
                isMinimumSalaryEmptyOrHidden = ko.pureComputed(() =>
                    !minimumSalary.value() || minimumSalary.isHidden());
            }

            if (currencyCode) {
                let currencyCodeSubscription;

                if (isMinimumSalaryEmptyOrHidden && !currencyCode.isRequired()) {
                    currencyCode.isHidden(isMinimumSalaryEmptyOrHidden());

                    currencyCodeSubscription = isMinimumSalaryEmptyOrHidden.subscribe((isEmpty) => {
                        currencyCode.isHidden(isEmpty);
                    });
                } else {
                    currencyCode.isHidden(!currencyCode.isRequired());

                    currencyCodeSubscription = currencyCode.isRequired.subscribe((isRequired) => {
                        currencyCode.isHidden(!isRequired);
                    });
                }

                form.subscriptions.push(currencyCodeSubscription);
            }

            if (frequency) {
                let frequencySubscription;

                if (isMinimumSalaryEmptyOrHidden && !frequency.isRequired()) {
                    frequency.isHidden(isMinimumSalaryEmptyOrHidden());

                    frequencySubscription = isMinimumSalaryEmptyOrHidden.subscribe((isEmpty) => {
                        frequency.isHidden(isEmpty);
                    });
                } else {
                    frequency.isHidden(!frequency.isRequired());

                    frequencySubscription = frequency.isRequired.subscribe((isRequired) => {
                        frequency.isHidden(!isRequired);
                    });
                }

                form.subscriptions.push(frequencySubscription);
            }
        },
    },

    _setFocusOnMinimumSalary: {
        value(form) {
            const minimumSalary = form.getElement('minimumPay');
            const currencyCode = form.getElement('currencyCode');

            currencyCode.containerId('currencyCode');
            minimumSalary.focusNextSelector('#currencyCode oj-select-one');
        },
    },

    _hidePreferredOrExcludedLocationWhenNoWillingToRelocate: {
        value(form) {
            const willingToRelocate = form.getElement('relocateFlag');

            if (!willingToRelocate) {
                return;
            }

            const locations = form.getElement(LOCATIONS_FIELD);
            const relocateToAllLocations = form.getElement('allLocationsFlag');
            const isNotWillingToRelocate = () => !willingToRelocate.value() || willingToRelocate.value() === 'N';
            const hideLocations = ko.pureComputed(isNotWillingToRelocate);

            if (locations && !locations.isHidden()) {
                locations.isHidden(hideLocations());

                const hidePreferredLocationSub = hideLocations.subscribe(
                    locations.isHidden.bind(relocateToAllLocations));

                form.subscriptions.push(hidePreferredLocationSub);
            }

            if (relocateToAllLocations && !relocateToAllLocations.isHidden()) {
                relocateToAllLocations.isHidden(hideLocations());

                const hideAllLocationsSub = hideLocations.subscribe(
                    relocateToAllLocations.isHidden.bind(relocateToAllLocations));

                form.subscriptions.push(hideAllLocationsSub);
            }
        },
    },

    _hideTravelFrequencyWhenTravelingIsOff: {
        value(config) {
            const { form } = config;
            const travel = form.getElement(config.travelFieldName);
            const frequency = form.getElement(config.frequencyFieldName);

            const computeFrequencyVisibility = () => !travel.value() || travel.value() === 'N';

            if (travel && frequency && !frequency.isRequired() && !frequency.isHidden()) {
                const hideFrequency = ko.pureComputed(computeFrequencyVisibility);

                form.subscriptions.push(hideFrequency);

                frequency.isHidden(hideFrequency());

                const frequencySubscription = hideFrequency.subscribe(frequency.isHidden.bind(frequency));

                form.subscriptions.push(frequencySubscription);
            }
        },
    },

    _requirePositiveIntegerFrom: {
        value(formElement) {
            if (!formElement) {
                return;
            }

            formElement.addValidator('positiveInteger');
        },
    },
});
