import ko from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class PinFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component = ko.observable('pin-form-element');
    }

}

