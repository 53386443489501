import appConfig from 'app/model/config';
import appEvents from 'app/config/events';
import httpMapper from 'core/http/mapper';
import middleware from 'core/http/middleware';

const defaultsPerMethod = {
    GET: {},
    POST: {},
    PATCH: {},
    PUT: {},
    DELETE: {},
    JSONP: {},
};

export default {
    baseUrl: `${appConfig.apiBaseUrl || ''}/hcmRestApi/resources/latest`,
    isCrossDomain: false,
    statusCodes: {},

    headers: {
        'Content-Type': 'application/vnd.oracle.adf.resourceitem+json;charset=utf-8',
        'Accept-Language': appConfig.siteLang || appConfig.sessionLang,
        'Ora-Irc-Language': appConfig.siteLang || appConfig.sessionLang,
    },

    timeout: 100000,

    getMethodConfig(method) {
        return defaultsPerMethod[method];
    },

    addAuthorizationHeader(request) {
        return request;
    },

    addTimestampHeader(request) {
        return request;
    },

    addUserTrackingHeader(request) {
        return request;
    },

    addRestActionHeader(request) {
        return request;
    },

    defaultErrorHandler(error, config) {
        if (!config.suppressErrors) {
            appEvents.apiError.dispatch(error, config);
        }

        return Promise.reject(error);
    },

    transformRequest(request) {
        request.body = httpMapper.mapToRest(request.body);

        return request;
    },

    transformResponse(response) {
        return httpMapper.mapFromRest(response);
    },

    setStatusCodeHandler(code, handler) {
        this.statusCodes[code] = handler;
    },

    unsetStatusCodeHandler(code) {
        delete this.statusCodes[code];
    },

    addMiddleware(middlewareToAdd) {
        middleware.add(middlewareToAdd);
    },

    removeMiddleware(middlewareToRemove) {
        middleware.remove(middlewareToRemove);
    },

    hasMiddleware(middlewareToCheck) {
        return middleware.has(middlewareToCheck);
    },
};