import { PureComputed, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import { Job } from 'minimal/module/job-details/types';

export type JobTag = {
    isVisible: PureComputed<boolean>;
    className: string;
    label: string;
};

export type JobTagsOptions = {
    isHotJobVisible: boolean;
    isTrendingJobVisible: boolean;
    isBeFirstToApplyVisible: boolean;
};

export const generateJobTags = (job: Job, configuration: PureComputed<JobTagsOptions>): JobTag[] => {
    const lang = job.lang;

    return [
        {
            isVisible: pureComputed(() => {
                return (
                    Boolean(!job.isAlreadyApplied) &&
                    Boolean(job.hotJobFlag) &&
                    configuration().isHotJobVisible
                );
            }),
            label: i18n('search.hot-jobs', null, lang),
            className: 'hot-jobs',
        },
        {
            isVisible: pureComputed(() => {
                return (
                    Boolean(!job.isAlreadyApplied) &&
                    Boolean(job.trendingJob) &&
                    configuration().isTrendingJobVisible
                );
            }),
            label: i18n('search.trending-job', null, lang),
            className: 'trending-job',
        },
        {
            isVisible: pureComputed(() => {
                return (
                    Boolean(!job.isAlreadyApplied) &&
                    Boolean(job.beFirstToApply) &&
                    configuration().isBeFirstToApplyVisible
                );
            }),
            label: i18n('search.be-first-to-apply', null, lang),
            className: 'be-first-to-apply',
        },
    ];
};
