import ko from 'knockout';
import ValidatableForm from 'core/form/ValidatableForm';

export default class AddressForm extends ValidatableForm {

    constructor(...args) {
        super(...args);

        this._storeCountryElementReference(...args);

        this.componentName('address-form');

        this.geoHierarchyElements = ko.pureComputed(() =>
            this.elements().filter(formElement => formElement.lovColumn));

        this._correlateDependentElements();
    }

    _correlateDependentElements() {
        this.geoHierarchyElements().forEach((formElement) => {
            const parentFormElement = this._findParent(formElement);

            if (parentFormElement) {
                formElement.registerParent(parentFormElement);
            }
        });
    }

    _findParent(formElement) {
        let lovColumnIndex = formElement.lovColumnIndex();
        const filterByLovColumnIndex = element => element.lovColumnIndex() === lovColumnIndex;
        let parent = null;

        if (lovColumnIndex === 2) {
            parent = this.countryElement;
        }

        while (!parent && lovColumnIndex > 0) {
            lovColumnIndex -= 1;
            parent = ko.utils.arrayFirst(this.geoHierarchyElements(), filterByLovColumnIndex);
        }

        return parent;
    }

    _storeCountryElementReference({ countryElement }) {
        if (countryElement) {
            this.countryElement = countryElement;
        }
    }

    getGeoHierarchyRoot() {
        return this.geoHierarchyElements().filter(element => !element.parentElement()).pop();
    }

}