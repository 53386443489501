import candidate from 'apply-flow/model/candidate';
import jobAlertsService from 'cx/module/job-alerts/service/jobAlerts';
import { JOB_APPLY_CONFIRM } from 'cx/module/candidate-verification/config/verificationSubmodules';

export const confirmTCSignup = async (): Promise<void> => {
    const { id, subscribeToJobAlert, preferences } = candidate;

    if (subscribeToJobAlert() || Object.values(preferences).length) {
        await jobAlertsService.confirmTalentCommunity({
            candidateId: id(),
            flowContext: JOB_APPLY_CONFIRM,
        });
    }
};
