const loadedStylesMap = new Map();

const createStylePromise = (url: string): Promise<HTMLLinkElement> => {
    return new Promise(() => {
        const style = document.createElement('link');

        style.href = url;
        style.rel = 'stylesheet';

        document.head.appendChild(style);
    });
};

export const addStyleToHead = (url: string): Promise<HTMLLinkElement> => {
    if (loadedStylesMap.has(url)) {
        return loadedStylesMap.get(url);
    }

    const stylePromise = createStylePromise(url);

    loadedStylesMap.set(url, stylePromise);

    return stylePromise;
};
