import appConfig from 'app/model/config';
import referrerService from 'source-tracing/service/referrer';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import storageService from 'core/storage/sessionStorage';

const INDEED_OPTI_EXP_ENABLED = 'ORA_IRC_INDEED_OPTI_EXP_ENABLED';
const INDEED_TRUST_AUTH_ENABLED = 'ORA_IRC_INDEED_TRUST_AUTH_ENABLED';
const INDEED_REFERRER_DOMAINS = 'ORA_IRC_INDEED_REFERRER_DOMAINS';
const INDEED_AUTO_LAUNCH = 'indeed_auto_launch';

export const isIndeedOptimizedExperienceEnabled = (): boolean => {
    return appConfig.getSettingByKey(INDEED_OPTI_EXP_ENABLED) === 'true' || false;
};

export const isIndeedTrustAuthEnabled = (): boolean => {
    return appConfig.getSettingByKey(INDEED_TRUST_AUTH_ENABLED) === 'true' || false;
};

export const isIndeedOptiExperience = (): boolean => {
    const referrerDomainList = appConfig.getSettingByKey(INDEED_REFERRER_DOMAINS)?.split(',') ?? [];
    const referrer = referrerService.get();
    let isReferrerIndeedDomain = false;
    let referrerHost = '';

    try {
        referrerHost = new URL(referrer).hostname;

        const indeedDomain = (domain: string) => referrerHost?.endsWith(domain.trim());

        isReferrerIndeedDomain = referrerDomainList?.some(indeedDomain) ?? false;
    } catch (error) {
        return false;
    }

    return (
        isReferrerIndeedDomain &&
        mapTrueFalseStringToBoolean(appConfig.getSettingByKey(INDEED_OPTI_EXP_ENABLED))
    );
};

export const trustIndeedAuth = (): boolean => {
    return (
        isIndeedOptiExperience() &&
        mapTrueFalseStringToBoolean(appConfig.getSettingByKey(INDEED_TRUST_AUTH_ENABLED))
    );
};

export const isAutoLaunchIndeed = (): boolean => {
    return isIndeedOptiExperience() && !storageService.exists(INDEED_AUTO_LAUNCH);
};

export const setAutoLaunchedIndeedFlag = (): void => {
    if (!storageService.exists(INDEED_AUTO_LAUNCH)) {
        storageService.store(INDEED_AUTO_LAUNCH, true);
    }
};
