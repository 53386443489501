import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import RegulatoryConstants from 'apply-flow/module/regulatory/config/RegulatoryConstants';
import MetadataRepository from 'apply-flow/module/regulatory/module/diversity/service/DiversityMetadata';

import ViewModel from '../DiversityViewModel';
import template from '../templates/diversity.html';

import './templates';

components.register(RegulatoryConstants.ORA_DIVERSITY.componentName, { viewModel: ViewModel, template });

metadataConfig.registerBlock(RegulatoryConstants.ORA_DIVERSITY.blockCode, {
    component: RegulatoryConstants.ORA_DIVERSITY.componentName,
    repository: MetadataRepository,
});
