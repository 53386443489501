export function combinePhone(phone) {
    if (typeof phone === 'string') {
        return phone;
    }

    if (!phone || !phone.countryCode) {
        return '';
    }

    return `${phone.countryCode}${phone.areaCode}${phone.number}`;
}