import { observable, pureComputed } from 'knockout';
import geolocationService from 'cx/service/geolocation/geolocation';
import AbstractSearchBoxInputViewModel from '../search-box-location/AbstractSearchBoxInputViewModel';
import dictionaryService from 'apply-flow/service/dictionary';
import { MILES } from '../search-radius/config';
import i18n from 'app/module/core/i18n/i18n';

const DEFAULT_RADIUS_VALUE = 25;

class SearchBoxZipcodeViewModel extends AbstractSearchBoxInputViewModel {

    constructor(params) {
        super(params);

        this.country = params.country;

        this.zipcode = observable(this.value());
        this.isWorking = observable(false);
        this.showNoResultsHint = observable(false);
        this.showNoSelectedCountry = observable(false);
        this.hasFocus = observable(false);
        this.fullCountryName = observable('');
        this.isVisible = observable(false);

        this.radiusUnitLabel = pureComputed(() => {
            if (this.radiusUnit() === MILES) {
                return i18n('location-bar.dropdown.radius-unit.mi');
            }

            return i18n('location-bar.dropdown.radius-unit.km');
        });

        this.fullLocationLabel = pureComputed(() => `+${this.radiusValue()} ${this.radiusUnitLabel()}${this.fullCountryName()}`);

        this._focusSubscription = this.hasFocus.subscribe((value) => {
            if (value) {
                this.isVisible(value);
            }
        });

        this._fullCountryNameSubscription = this.country.subscribe((countryCode) => {
            this._setFullCountryName(countryCode);
        });

        this.beforeSearch(this._handleSubmit.bind(this));

        this._setFullCountryName(this.country());
        this._getGeolocationParams();
        this._setInitialRadiusParams();
    }

    onPressEnter() {
        this.action();
    }

    _setInitialRadiusParams() {
        if (!this.zipcode()) {
            this.radiusValue(DEFAULT_RADIUS_VALUE);
        }
    }

    _getGeolocationParams() {
        if (!this.zipcode()) {
            this.isWorking(true);

            geolocationService.query()
                .then(({ zipCode, countryCode }) => {
                    this.zipcode(zipCode);

                    if (!this.country()) {
                        this.country(countryCode);
                    }

                    this.action();
                    this.isWorking(false);
                    this.radiusValue(DEFAULT_RADIUS_VALUE);
                })
                .catch(() => {
                    this.isWorking(false);
                });
        }
    }

    _resolveZipcode() {
        const zipcode = this.zipcode();

        return geolocationService.getLocationByZipcode(zipcode, this.country())
            .then(coordinates => ({
                latitude: coordinates.latitude,
                longitude: coordinates.longitude,
                isGeolocation: true,
                zipcodeKeyword: zipcode,
            }));
    }

    _handleSubmit() {
        if (this.zipcode() && !this.country()) {
            this.showNoSelectedCountry(true);

            return Promise.reject('Country not set');
        }

        if (this.zipcode() && this.radiusValue() >= 0) {
            this.isWorking(true);

            return this._resolveZipcode()
                .then(this._onZipcodeResolveSuccess.bind(this))
                .catch(this._onZipcodeResolveFailure.bind(this));
        }

        this.value(this.zipcode());

        return Promise.resolve();
    }

    _onZipcodeResolveSuccess(geolocation) {
        this.isWorking(false);
        this.value(geolocation);
        this.showNoResultsHint(false);
    }

    _onZipcodeResolveFailure() {
        this.isWorking(false);
        this.showNoResultsHint(true);

        return Promise.reject();
    }

    dispose() {
        this.value('');
        this.beforeSearch(() => Promise.resolve());
        this._focusSubscription.dispose();
    }

    hide() {
        this.isVisible(false);
    }

    _getFullCountryName(countryCode) {
        return dictionaryService.findOne('countries', { TerritoryCode: countryCode })
            .catch((error) => {
                console.error(error);

                return Promise.resolve({});
            });
    }

    async _setFullCountryName(countryCode) {
        const { territoryShortName = '' } = countryCode
            ? await this._getFullCountryName(countryCode)
            : '';

        this.fullCountryName(territoryShortName ? `, ${territoryShortName}` : '');
    }

    openRadiusDropDown() {
        this.isVisible(!this.isVisible());
    }

}

export default SearchBoxZipcodeViewModel;
