import http from 'core/http/http';
import appConfig from 'app/model/config';
import { paths } from './config/paths';
import properties from './mapper/properties';
import { maskDraftContent } from './mapper/maskDraftContent';

const DUMP_URL = '/candExpError';

let logs = [];

function _getLogLevel() {
    return appConfig.getSettingByKey('IRC_CE_REST_LOG_LEVEL') || 'ERROR';
}

function _deepCopy(object) {
    return JSON.parse(JSON.stringify(object));
}

function _parseBody(body) {
    try {
        return JSON.parse(body);
    } catch {
        return body;
    }
}

function _dump(candidateData) {
    const {
        module,
        accessCode,
        draftApplicationId,
        ceUserTrackingId,
        requisitionNumber,
        email,
        phoneNumber,
    } = candidateData;

    const content = JSON.stringify(logs);

    const config = {
        baseUrl: appConfig.ceBaseUrl,
    };

    return http.post(DUMP_URL, {
        module,
        accessCode,
        draftApplicationId,
        ceUserTrackingId,
        requisitionNumber,
        email,
        phoneNumber,
        content,
    }, config)
        .then(() => { logs = []; })
        .catch(error => console.error(error));
}

export default {
    middleware(response, { url, method, headers, body, status }) {
        if (url.includes(DUMP_URL)) {
            return;
        }

        let maskedBody = {};
        let maskedResponse = {};

        if (body) {
            maskedBody = _parseBody(body);
        }

        if (response) {
            maskedResponse = _deepCopy(response);
        }

        paths.forEach((path) => {
            properties.mask(maskedBody, path);
            properties.mask(maskedResponse, path);
        });

        maskDraftContent({
            url,
            method,
            body: maskedBody,
            status,
        });

        logs.push({
            url,
            status: status || 200,
            method: method || 'GET',
            headers,
            body: maskedBody,
            response: maskedResponse,
        });
    },

    log({ data, type }) {
        logs.push({
            type,
            content: data,
        });
    },

    get() {
        return logs;
    },

    clear() {
        logs = [];
    },

    dump(candidateData) {
        if (_getLogLevel() === 'ALL') {
            return _dump(candidateData);
        }

        return Promise.resolve();
    },

    dumpOnError(candidateData) {
        if (_getLogLevel() === 'ALL' || _getLogLevel() === 'ERROR') {
            return _dump(candidateData);
        }

        return Promise.resolve();
    },
};
