import TalentCommunityButtonGeneralViewModel from 'cx/module/custom-content/component/talent-community-button/TalentCommunityButtonViewModel';
import router from 'app/model/router';
import historyService from 'ce-common/service/historyService';

export default class TalentCommunityButtonViewModel extends TalentCommunityButtonGeneralViewModel {

    openSignUpComponent() {
        historyService.store();
        router.go('talent-community');
    }

}