import http from 'core/http/http';
import { ELOCATION_APPID } from 'cx/config/constants/elocation';

export const VENDOR_NAME = 'oracle';

export const GEOCODE_URL_WITH_PARAM = `/elocation/lbs?json_request=`;

type locationResponse = {
    id: string;
    matchCount: number;
    matches: location[];
};

type location = {
    accuracy: number;
    country: string;
    edgeId: number;
    houseNumber: string;
    id: string;
    language: string;
    matchCode: number;
    matchVector: string;
    municipality: string;
    name: string;
    percent: number;
    postalCode: string;
    region: string;
    settlement: string;
    side: string;
    street: string;
    x: number;
    y: number;
};

const createJSONRequest = (requestParams: Record<string, unknown>) => {
    const config = {
        baseUrl: `//elocation.oracle.com/${ELOCATION_APPID}`,
        timeout: 5000,
    };

    const requestParamsString = JSON.stringify(requestParams);

    return http.get(`${GEOCODE_URL_WITH_PARAM}${requestParamsString}`, config);
};

export const findByCoordinates = (latitude: number, longitude: number): Promise<location[]> => {
    const requestParams = {
        geocode_request: {
            vendor: VENDOR_NAME,
            address_list: {
                input_location: [
                    {
                        longitude: longitude.toString(),
                        latitude: latitude.toString(),
                    },
                ],
            },
        },
    };

    return createJSONRequest(requestParams).then((response: locationResponse[]) => response[0].matches);
};

export const findByPostalCode = (countryCode: string, postalCode: string): Promise<location> => {
    const requestParams = {
        geocode_request: {
            vendor: VENDOR_NAME,
            address_list: {
                input_location: [
                    {
                        input_address: {
                            postal_code: postalCode,
                            country: countryCode,
                        },
                    },
                ],
            },
        },
    };

    return createJSONRequest(requestParams).then((response: locationResponse[]) =>
        response[0].matches.shift()
    );
};

export const findByCityState = (countryCode: string, city: string, state: string): Promise<location> => {
    const requestParams = {
        geocode_request: {
            vendor: VENDOR_NAME,
            address_list: {
                input_location: [
                    {
                        input_address: {
                            country: countryCode,
                            city,
                            region: state,
                        },
                    },
                ],
            },
        },
    };

    return createJSONRequest(requestParams).then((response: locationResponse[]) =>
        response[0].matches.shift()
    );
};
