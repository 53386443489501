import 'cx/module/candidate-self-service/module/interview-scheduling/config/routing';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/schedule-date-time/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/schedule-info/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/reschedule-info/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/schedule-group/config/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/self-schedule/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/interview-detail/config/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/scheduled-interview-action/component';
import 'cx/module/candidate-self-service/module/interview-scheduling/component/unscheduled-interview-action/component';
import '../component/interview-back-button/component';
import '../component/interview-cancel-dialog/component';
import '../component/self-schedule-confirm-dialog/component';
import '../component/request-slots-dialog/component';

import { components } from 'knockout';

import ViewModel from 'candidate-self-service/module/interview-scheduling/InterviewSchedulingViewModel';
import template from '../interview-scheduling.html';

components.register('interview-scheduling', { viewModel: ViewModel, template });
