import { observable } from 'knockout';

class JobDetails {

    constructor() {
        this.title = observable(null);
    }

    clear() {
        this.title(null);
    }

}

export default new JobDetails();