import http from 'core/http/http';
import errorHandler from 'candidate-verification/service/tokenErrorHandler';
import {
    flattenResumeParserResponseToRecommendedJobsMetadata,
    mapResumeParserInputToRest,
} from '../mapper/resumeParserRestMapper';
import {
    RecommendedJobsMetadata,
    AttachmentedResumePayloadKeys,
    VerificationTokenPayload,
    WorkExperienceKeys,
} from '../config/types';
import tokenService from 'candidate-verification/service/token';
import storageService from 'core/storage/sessionStorage';
import { ViewBy, RouterParams, RouterQuery } from '../config/types';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import { SEARCH_RESULTS_STRINGS } from 'app/module/cx/module/search/config/types';
import router from 'app/model/router';
import { isLocationSearch, isKeywordSearch } from 'app/module/cx/module/search/service/searchTypes';
import { encryptMessage, generateUniqueKey } from 'core/storage/encryption/encryption';
import {
    STORAGE_ENCKEY_DETAILS,
    STORAGE_RECOMMENDED_JOBS_METADATA,
    getResumeInfoFromStorage,
    setResumeFileInfo,
} from 'cx/service/uploadedResumeInfoProvider';

export const DATA_TYPE_CODE = 'datatypeCode';
export const DEFAULT_CLASS_ID = 'default';
export const RECOMMENDED_JOB_RESUME = 'recommended_job_resume';

const CREATE_VERIFICATION_TOKENS_URL = '/recruitingCEVerificationTokens';
const CANDIDATE_RESUME_PARSER_URL = '/recruitingCECandidateResumeParsers';

type AttachmentData = {
    categoryName: string;
    datatypeCode: string;
    title: string;
    fileContents: string;
};

type RecommendedJobsData =
    | {
          skills: string[];
          titles: string[];
          displayName: string;
          workExperiences: Record<WorkExperienceKeys, string>[];
          educations: Record<'degreeName' | 'educationalEstablishment' | 'startDate' | 'endDate', string>[];
      }
    | undefined;

export const createVerificationToken = async (): Promise<Record<string, string | unknown>> => {
    const accessCode: string = tokenService.accessCodeExists();

    if (accessCode) {
        return { accessCode };
    }

    const verificationTokenPayload: VerificationTokenPayload = {
        mode: 'JOB_APPLY_RECOMMENDED',
        email: `RECOMMENDED_JOB_DEMO_DEFAULT_${Date.now()}@adcdat05.us.oracle.com`,
    };

    return http.post(CREATE_VERIFICATION_TOKENS_URL, verificationTokenPayload, {
        statusCodes: {
            400({ response }: Record<string, unknown>) {
                const { mode } = verificationTokenPayload;

                return errorHandler.handleBadRequestOnCreateToken(response, mode);
            },
            500() {
                return Promise.reject();
            },
        },
    });
};

export const fetchAttachedResumeMetadata = (
    attachmentedResumePayload: Record<AttachmentedResumePayloadKeys, string>,
    accesscode: string
): Promise<RecommendedJobsMetadata> => {
    const headers = { 'Ora-Irc-Access-Code': accesscode };

    return http.post(CANDIDATE_RESUME_PARSER_URL, mapResumeParserInputToRest(attachmentedResumePayload), {
        headers,
    });
};

export const getRecommendedJobsMetaDataFromStorage = async (): Promise<RecommendedJobsData> => {
    const uploadedResumeMetadata = await getResumeInfoFromStorage();

    if (!uploadedResumeMetadata) {
        return undefined;
    }

    const { skills, workExperiences, displayName, educations } =
        flattenResumeParserResponseToRecommendedJobsMetadata(uploadedResumeMetadata);

    const skillsList = skills.map(({ skills }) => skills);

    const currentJobTitle =
        workExperiences.find(({ currentJobFlag }) => currentJobFlag === 'Y')?.jobTitle ??
        workExperiences[0]?.jobTitle;

    return {
        skills: skillsList,
        titles: currentJobTitle ? [currentJobTitle] : [],
        displayName: displayName,
        workExperiences,
        educations,
    };
};

export const saveRecommendedJobsMetadataInStorage = async (
    recommendedJobsMetadata: RecommendedJobsMetadata,
    attachmentData: AttachmentData
): Promise<void> => {
    const fileAttachmentData = {
        CategoryName: attachmentData.categoryName,
        Title: attachmentData.title,
        FileContents: attachmentData.fileContents,
    };

    const uniqueKey = await generateUniqueKey();

    const { cipherText, iv } = await encryptMessage(JSON.stringify(recommendedJobsMetadata), uniqueKey);

    storageService.store(STORAGE_RECOMMENDED_JOBS_METADATA, cipherText);
    storageService.store(STORAGE_ENCKEY_DETAILS, btoa(JSON.stringify({ k: uniqueKey, i: iv })));
    setResumeFileInfo(fileAttachmentData);
};

export const redirectToSearchBasedOnViewBy = (viewBy: ViewBy, userName?: string): void => {
    let query: { viewBy?: ViewBy; selectedFile?: string } = { viewBy };

    if (viewBy === 'RECOMMENDED_JOBS') {
        query['selectedFile'] = userName;
    } else {
        query = {};
    }

    if (areEventsEnabled()) {
        storageService.store(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS, { query: {} });
    }

    router.go('search', { query }, { inherit: false });
};

export const areRecommendedJobsViewEnabled = (query: RouterQuery): boolean => {
    return query?.viewBy === 'RECOMMENDED_JOBS';
};

export const areRecommendedJobsEnabledWithParams = (): boolean => {
    const { query } = router.routeParams() as RouterParams;

    const isKeywordOrLocationBasedSearch = query ? isKeywordSearch(query) || isLocationSearch(query) : false;

    return areRecommendedJobsViewEnabled(query) && !isKeywordOrLocationBasedSearch;
};
