import storage from 'core/storage/sessionStorage';
import { mapHeaderModeToRest, mapFooterModeToRest, mapThemeSettingsToConfig } from '../mapper/theme';

const THEME_LOCAL_STORAGE_KEY = 'cx-theme-config';

const themeSnapshot = {
    store(theme) {
        const parsedThemeConfig = {
            headerMode: mapHeaderModeToRest(theme.customHeader),
            footerMode: mapFooterModeToRest(theme.templateFooter, theme.customFooter),

            template: {
                welcomeText: theme.welcomeText,
            },

            theme: {
                brand: theme.brand,
                cssStyles: theme.cssStyles,
            },

            themeSettings: mapThemeSettingsToConfig(theme.themeSettings),
        };

        storage.store(THEME_LOCAL_STORAGE_KEY, parsedThemeConfig);
    },

    restore() {
        return storage.restore(THEME_LOCAL_STORAGE_KEY);
    },

    remove() {
        storage.remove(THEME_LOCAL_STORAGE_KEY);
    },
};

export default themeSnapshot;
