import candidate from 'apply-flow/model/candidate';
import mapping from 'knockout-mapping';

export default class OptIn {

    constructor({ versionId, description, isEnabled }) {
        this.versionId = versionId;
        this.description = description || '';
        this.isEnabled = isEnabled;
    }

    hasDescription() {
        return this.description.length !== 0;
    }

    get isAccepted() {
        return candidate.preferences.optIn;
    }

    accept() {
        candidate.preferences.optIn(true);
    }

    decline() {
        candidate.preferences.optIn(false);
    }

    set(preferences) {
        if (preferences) {
            mapping.fromJS({ preferences }, candidate);
        }
    }

}