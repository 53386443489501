import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';

export default class SpaceViewModel extends ElementViewModel {

    constructor({ params }) {
        super({ params });

        this.height = pureComputed(this._computeHeight, this);
    }

    _computeHeight() {
        return `${this.params.height()}px`;
    }

}
