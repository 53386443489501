const BASE_URL_ATTR = 'data-base-url';

const { location } = window;
const url = location.origin + location.pathname;

function getVersion() {
    const dataBaseElement = document.querySelector(`[${BASE_URL_ATTR}]`);

    if (!dataBaseElement) {
        console.warn(`BASE_URL_ATTR "${BASE_URL_ATTR}" not found`);

        return 'athoc';
    }

    const dataBaseUrl = dataBaseElement.getAttribute(BASE_URL_ATTR);

    if (!dataBaseUrl) {
        return 'athoc';
    }

    return dataBaseUrl.substring(3);
}

function getPromiseErrorMessage(errorEvent) {
    return errorEvent.reason;
}

function getWindowErrorMessage(errorEvent) {
    if (errorEvent.error && errorEvent.error.stack) {
        return `${errorEvent.message}\n${errorEvent.error.stack}`;
    }

    const fileName = errorEvent.filename.split('/').pop();

    return `${errorEvent.message} (${fileName} at line ${errorEvent.lineno})`;
}

function getApiErrorMessage(errorEvent) {
    return errorEvent.response;
}

const version = getVersion();

const ERROR_MAP = {
    promiseError: getPromiseErrorMessage,
    error: getWindowErrorMessage,
    apiError: getApiErrorMessage,
};

export default {
    createError(errorType, errorEvent, config) {
        const errorMessage = ERROR_MAP[errorType](errorEvent);

        const errorObject = {
            url,
            hash: location.hash,
            stack: errorMessage,
            version,
        };

        if (config) {
            errorObject.rest = {
                url: config.url,
                status: errorEvent.statusCode,
                payload: config.body,
                headers: config.headers,
            };
        }

        return errorObject;
    },
};