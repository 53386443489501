import sourceTraceService from 'source-tracing/service/sourceTrace';
import referralService from 'candidate-verification/service/referral';
import tokenService from 'candidate-verification/service/token';
import { CHALLENGE_REQUIRED } from 'candidate-verification/config/pinVerificationStatus';
import { LAST_NAME, DATE_OF_BIRTH } from 'app/module/cx/module/candidate-verification/config/claimVerificationChecks';
import { DATE_OF_BIRTH_CHECK, LAST_NAME_CHECK, INVALID_COOKIE, SIGNIN_LOCKED } from 'candidate-verification/config/candidateVerificationStatus';
import { redirectToApplyFlow } from 'candidate-verification/service/routes';

function _createSourceTrace(jobId) {
    const referralHitCountResponse = referralService.getHitCountResponse(jobId) || {};

    const sourceTraceData = {
        sourceLevel: sourceTraceService.LEVEL.JOB_APPLICATION,
        requisitionNumber: jobId,
        referralId: referralHitCountResponse.referralId,
        shareId: referralHitCountResponse.shareId,
    };

    return sourceTraceService.create(sourceTraceData);
}

export default {

    get claimedLastName() {
        return this._claimedLastName;
    },

    get claimedDateOfBirth() {
        return this._claimedDateOfBirth;
    },

    createToken(data) {
        this._claimedLastName = null;
        this._claimedDateOfBirth = null;

        return _createSourceTrace(data.jobId)
            .then(sourceTrace => tokenService.create({ ...data, sourceTrace }))
            .then((verificationToken) => {
                if (!verificationToken.cookieMatchedFlag) {
                    if (verificationToken.nextField === DATE_OF_BIRTH) {
                        return Promise.reject({ errorCode: DATE_OF_BIRTH_CHECK, params: verificationToken });
                    }

                    if (!verificationToken.challengeFlag || verificationToken.nextField === LAST_NAME) {
                        return Promise.reject({ errorCode: LAST_NAME_CHECK, params: verificationToken });
                    }
                }

                if (verificationToken.challengeFlag) {
                    return Promise.reject({ errorCode: CHALLENGE_REQUIRED });
                }

                if (!verificationToken.accessCode) {
                    return Promise.reject({ errorCode: 'token-verification' });
                }

                return redirectToApplyFlow();
            });
    },

    createTokenForIndeed(data) {
        this._claimedLastName = null;
        this._claimedDateOfBirth = null;

        return _createSourceTrace(data.jobId)
            .then(sourceTrace => tokenService.create({ ...data, sourceTrace }))
            .then((verificationToken) => {
                if (!verificationToken.cookieMatchedFlag) {
                    if (verificationToken.nextField === DATE_OF_BIRTH) {
                        return Promise.reject(DATE_OF_BIRTH_CHECK);
                    }

                    if (!verificationToken.challengeFlag || verificationToken.nextField === LAST_NAME) {
                        return Promise.reject(LAST_NAME_CHECK);
                    }
                }

                if (verificationToken.challengeFlag) {
                    return Promise.reject(CHALLENGE_REQUIRED);
                }

                if (!verificationToken.accessCode) {
                    return Promise.reject('token-verification');
                }

                return Promise.resolve('success');
            });
    },

    verifyLastName(data) {
        this._claimedLastName = null;

        return this._verify('lastname', data);
    },

    verifyDateOfBirth(data) {
        this._claimedDateOfBirth = null;

        return this._verify('dob', data);
    },

    _verify(usingFor = 'lastname', data) {
        return tokenService.create(data)
            .then((verificationToken) => {
                if (verificationToken.challengeFlag) {
                    return Promise.reject(CHALLENGE_REQUIRED);
                }

                return redirectToApplyFlow();
            })
            .catch((error) => {
                if (error === INVALID_COOKIE || error === SIGNIN_LOCKED) {
                    if (usingFor === 'lastname') {
                        this._lastName = data.lastName;
                    }

                    if (usingFor === 'dob') {
                        this._dateOfBirth = data.dateOfBirth;
                    }
                }

                return Promise.reject(error);
            });
    },

    claimPhoneNumber({ candidate }) {
        return tokenService
            .claimPhoneNumber({ candidate })
            .then((verificationToken) => {
                if (verificationToken.challengeFlag) {
                    this._claimedLastName = this._lastName;

                    return Promise.reject(CHALLENGE_REQUIRED);
                }

                return verificationToken;
            });
    },

    claimEmail({ candidate }) {
        return tokenService
            .claimEmail({ candidate })
            .then((verificationToken) => {
                if (verificationToken.challengeFlag) {
                    if (this._lastName) {
                        this._claimedLastName = this._lastName;
                    }

                    if (this._dateOfBirth) {
                        this._claimedDateOfBirth = this._dateOfBirth;
                    }

                    return Promise.reject(CHALLENGE_REQUIRED);
                }

                return verificationToken;
            });
    },

    resendToken(data) {
        return _createSourceTrace(data.jobId)
            .then(sourceTrace => tokenService.create({ ...data, sourceTrace }));
    },
};