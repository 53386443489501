import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class NotIncludesFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.not-includes';

        ko.utils.extend(this._options, {
            forbidden_string: this._options.substring,
        });
    }

    _validate(value, options) {
        if (!value) {
            return true;
        }

        return !value
            .toLowerCase()
            .includes(options.substring.toLowerCase());
    }

}

