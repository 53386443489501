import ko from 'knockout';
import mapping from 'knockout-mapping';

const basicInformationPrototype = {
    title: undefined,
    firstName: undefined,
    middleNames: undefined,
    lastName: undefined,
    suffix: undefined,
    phone: {
        type: 'mobile',
        countryCode: undefined,
        legislationCode: undefined,
        areaCode: undefined,
        number: undefined,
    },
    email: undefined,
    emailPreferredFlag: undefined,
    phonePreferredFlag: undefined,
    emailVerifiedFlag: undefined,
    phoneVerifiedFlag: undefined,
    isVerified: undefined,
    verificationMethod: undefined,
    preNameAdjunct: undefined,
    knownAs: undefined,
    previousLastName: undefined,
    honors: undefined,
    militaryRank: undefined,
    namInformation1: undefined,
    namInformation2: undefined,
    namInformation3: undefined,
    namInformation4: undefined,
    namInformation5: undefined,
    namInformation6: undefined,
    namInformation7: undefined,
    namInformation8: undefined,
    namInformation9: undefined,
    namInformation10: undefined,
    namInformation11: undefined,
    namInformation12: undefined,
    namInformation13: undefined,
    namInformation14: undefined,
    namInformation15: undefined,
    namInformation16: undefined,
    namInformation17: undefined,
    namInformation18: undefined,
    namInformation19: undefined,
    namInformation20: undefined,
    namInformation21: undefined,
    namInformation22: undefined,
    namInformation23: undefined,
    namInformation24: undefined,
    namInformation25: undefined,
    namInformation26: undefined,
    namInformation27: undefined,
    namInformation28: undefined,
    namInformation29: undefined,
    namInformation30: undefined,
    displayName: undefined,
    listName: undefined,
    exWorker: undefined,
    cwkCandidate: undefined,
};

export default class BasicInformation {

    constructor(data) {
        mapping.fromJS(basicInformationPrototype, {}, this);

        if (data) {
            mapping.fromJS(data, {}, this);
        }

        this.phoneNumber = ko.pureComputed({
            read: this._getPhoneNumber,
            write: this._setPhoneNumber,
            owner: this.phone,
        });
    }

    _getPhoneNumber() {
        return {
            countryCode: this.countryCode() || '',
            legislationCode: this.legislationCode() || '',
            areaCode: this.areaCode() || '',
            number: this.number() || '',
        };
    }

    _setPhoneNumber(phoneNumber) {
        this.countryCode(phoneNumber.countryCode || '');
        this.legislationCode(phoneNumber.legislationCode || '');
        this.areaCode(phoneNumber.areaCode || '');
        this.number(phoneNumber.number || '');

        return this;
    }

}