import ko from 'knockout';
import router from 'app/model/router';
import downloader from 'ce-common/module/downloader/service/downloader';
import i18n from 'core/i18n/i18n';
import tokenService from 'candidate-verification/service/token';
import offerService from 'offer/service/offer';

export default class OfferContentViewModel {

    constructor(offerModel) {
        this.offer = ko.observable(offerModel);

        this.offerTitle = ko.pureComputed(() => {
            if (router.route().id === 'offer') {
                if (router.routeParams().freshlyAccepted) {
                    return i18n('offer.header.congratulations-text');
                } else if (!this.offer().isOfferAccepted()) {
                    return i18n('offer.header.welcome-text');
                } else if (this.offer().isOfferAccepted()) {
                    return i18n('offer.accepted-job-offer-label');
                }
            }

            return '';
        });

        this.isPreview = ko.pureComputed(() => {
            if (router.routeParams().query) {
                return router.routeParams().query.preview || false;
            }

            return false;
        });

        this.isOfferLetterDownloadInProgress = ko.observable(false);
    }

    downloadContent() {
        const fileName = `${i18n('offer.job-offer-label')}-${this.offer().offerName()}.pdf`;

        this._download(fileName);
    }

    _download(fileName) {
        const mimeType = 'application/pdf';
        const fileContents = this.offer().offerLetter.pdf();

        if (fileContents) {
            downloader.download(Promise.resolve({ fileName, fileContents, mimeType }));

            return;
        }

        this.isOfferLetterDownloadInProgress(true);

        const offerDataContentPromise = offerService.getOfferDataContent({
            offerId: router.routeParams().query.offerId,
            format: 'pdf',
        })
            .then(({ offerLetter: { pdf } }) => (
                {
                    fileName,
                    fileContents: pdf,
                    mimeType,
                }
            ))
            .catch(err => console.error(err))
            .finally(() => this.isOfferLetterDownloadInProgress(false));

        downloader.download(offerDataContentPromise);
    }

    downloadAttachment(attachment) {
        const data = {
            url: attachment.url(),
            fileName: attachment.name(),
            mimeType: attachment.mimeType(),
        };

        const httpConfig = {
            baseUrl: '',
            headers: {
                'Ora-Irc-Access-Code': tokenService.get().accessCode,
            },
        };

        downloader.download(Promise.resolve(data), httpConfig);
    }


}