import cookieStorage from 'core/cookie/storage';
import tokenService from './token';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';

export const VERIFICATION_TOKEN_COOKIE = 'verificationToken';

export function applyToken(token) {
    cookieStorage.remove(VERIFICATION_TOKEN_COOKIE);
    tokenService.applyVerificationToken(token);
}

export function getStoredToken() {
    if (sessionPersistence.isActive()) {
        return tokenService.get();
    }

    return JSON.parse(cookieStorage.get(VERIFICATION_TOKEN_COOKIE));
}

export function storeTokenInCookie() {
    const token = JSON.stringify(tokenService.get());

    return cookieStorage.set(VERIFICATION_TOKEN_COOKIE, token, { hours: 4 });
}