import { observableArray, pureComputed } from 'knockout';
import storageService from 'core/storage/sessionStorage';
import jobService from 'cx/module/job-details/service/job';
import userTracking from 'cx/service/userTracking';
import favouriteEvents from 'favourite/config/events';

const FAVOURITE_IDS_STORAGE_KEY = 'favourite-jobs-ids';

const favouriteJobs = observableArray();

function setFavouriteJobsSubscriber() {
    favouriteJobs.subscribe(jobs => storageService.store(FAVOURITE_IDS_STORAGE_KEY, jobs.map(job => job.id)));
}

function loadFavouriteJobs(ids) {
    jobService.getJobs(ids)
        .then(({ items }) => favouriteJobs(items))
        .then(setFavouriteJobsSubscriber);
}

loadFavouriteJobs(storageService.restore(FAVOURITE_IDS_STORAGE_KEY) || []);
favouriteEvents.favouriteJobsSet.add(loadFavouriteJobs);

const favouriteJobsService = {

    getFavourites() {
        return favouriteJobs;
    },

    hasFavourites() {
        return pureComputed(() => favouriteJobs().length);
    },

    addFavourite(job) {
        favouriteJobs.unshift(job);
        userTracking.trackAddRequisitionToFavourites(job);
    },

    removeFavourite(job) {
        favouriteJobs.remove(favouriteJob => favouriteJob.id === job.id);
        userTracking.trackRemoveRequisitionToFavourites(job);
    },

    toggleFavourite(job) {
        const isFavourite = favouriteJobsService.isFavourite(job);

        if (!isFavourite()) {
            favouriteJobsService.addFavourite(job);
        } else {
            favouriteJobsService.removeFavourite(job);
        }
    },

    isFavourite({ id }) {
        return pureComputed(() => favouriteJobs().some(favouriteJob =>
            favouriteJob.id.toLowerCase() === id.toLowerCase(),
        ));
    },

};

export default favouriteJobsService;
