import SupportingDocumentsUploadButtonViewModel from './SupportingDocumentsUploadButtonViewModel';
import { COVER_LETTER } from '../../config/attachmentCategories';

export default class CoverLetterUploadButtonViewModel extends SupportingDocumentsUploadButtonViewModel {

    constructor(params) {
        super(params);

        this.categoryId = COVER_LETTER;
        this.dropdownLabel = 'apply-flow.section-more-about-you.drop-cover-letter-here';
        this.attachmentChangedLabel = 'apply-flow.section-more-about-you.cover-letter-changed-label';
    }

    finderFunction(attachments) {
        return attachments.find(({ isCoverLetter }) => isCoverLetter());
    }

    getButtonLabel() {
        return 'apply-flow.section-more-about-you.upload-cover-letter-label';
    }

}