import educationFormBuilder from 'apply-flow/module/degrees/form/educationFormBuilder';
import experienceFormBuilder from 'apply-flow/module/previous-employment/form/experienceFormBuilder';
import workPreferencesFormBuilder from 'apply-flow/module/work-preferences/service/workPreferencesFormBuilder';
import licensesAndCertificatesFormBuilder from 'apply-flow/module/licenses-and-certificates/service/licensesAndCertificatesFormBuilder';
import languagesFormBuilder from 'apply-flow/module/languages/service/languagesFormBuilder';
import skillFormBuilder from 'apply-flow/module/skill/service/skillFormBuilder';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';

const FORM_BUILDERS = {
    [contentTypes.CERTIFICATION_ID]: licensesAndCertificatesFormBuilder,
    [contentTypes.EDUCATION_ID]: educationFormBuilder,
    [contentTypes.LANGUAGE_ID]: languagesFormBuilder,
    [contentTypes.WORK_PREFERENCE_ID]: workPreferencesFormBuilder,
    [contentTypes.EXPERIENCE_ID]: experienceFormBuilder,
    [contentTypes.SKILL_ID]: skillFormBuilder,
};

export default {
    getFormBuilder(contentTypeId) {
        return FORM_BUILDERS[contentTypeId];
    },
};