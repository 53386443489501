import { PureComputed, pureComputed, Observable } from 'knockout';
import appConfig from 'app/model/config';
import pageLinksProvider from 'app/service/pageLinksProvider';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { CustomHeaderModel } from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import { LogoImages } from '../../config/types';

type Props = {
    globalHeaderSettings: CustomHeaderModel;
    logoImages: LogoImages;
    headerId: Observable<number | null>;
    uniqueWrapperClass: string;
};

export class AppHeaderHamburgerViewModel {
    isLogoHidden: boolean | undefined;
    isProfileButtonHidden: boolean | undefined;
    profileText: string | null | undefined;
    isAppMenuNotEmpty: PureComputed<boolean>;
    logoImages: LogoImages;
    headerId: Observable<number | null>;
    uniqueWrapperClass: string;

    constructor({
        globalHeaderSettings: { isLogoHidden, isProfileButtonHidden, profileText },
        logoImages,
        headerId,
        uniqueWrapperClass,
    }: Props) {
        this.isLogoHidden = isLogoHidden;
        this.isProfileButtonHidden = isProfileButtonHidden;
        this.profileText = profileText;
        this.logoImages = logoImages;
        this.headerId = headerId;
        this.uniqueWrapperClass = uniqueWrapperClass;

        this.isAppMenuNotEmpty = pureComputed(this.computeAppMenuNotEmpty, this);
    }

    private computeAppMenuNotEmpty(): boolean {
        const areLinksProvided = Boolean(pageLinksProvider.getHeaderPageLinks().length);
        const areLanguagesProvided = appConfig.languages.length > 1;

        return areLinksProvided || areLanguagesProvided || !signedCandidate.isSignedIn();
    }
}
