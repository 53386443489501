import { Address } from '../model/Address';

export let addressBackup: Address;

export const addressBackupInstance = {
    set(instance: Address): void {
        addressBackup = instance;
    },

    get(): Address {
        return addressBackup;
    },
};
