import deviceDetector from 'core/device-detector/deviceDetector';

export default {
    canCopyProgramatically() {
        return document.queryCommandSupported('copy');
    },

    canCopyByShortcut() {
        return !deviceDetector.isIOS();
    },
};