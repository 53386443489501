import { SearchContext } from 'cx/module/search/config/types';
import { Observable, PureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';

type Props = {
    name: string;
    count: number;
    isSelected: boolean;
    buttonAriaLabel: string;
    onClick: () => void;
    selectedSearchContext: PureComputed<SearchContext>;
    loading: Observable<boolean>;
};

export default class SearchContextButtonViewModel {
    name: string;
    count: number;
    isSelected: boolean;
    onClick: () => void;
    buttonAriaLabel: string;
    selectedSearchContext: PureComputed<SearchContext>;
    loading: Observable<boolean>;

    constructor({
        name,
        count,
        isSelected,
        buttonAriaLabel,
        onClick,
        selectedSearchContext,
        loading,
    }: Props) {
        this.name = name;
        this.count = count;
        this.isSelected = isSelected;
        this.buttonAriaLabel = buttonAriaLabel;
        this.onClick = onClick;
        this.selectedSearchContext = selectedSearchContext;
        this.loading = loading;
    }

    getSelectedContextValue(): string {
        return this.selectedSearchContext() === 'jobs'
            ? i18n('search.results-for-jobs', { TOTAL: this.count })
            : i18n('search.results-for-events', { TOTAL: this.count });
    }
}
