import ko from 'knockout';

function elementsLivingTime(target, livingTime) {
    const initialElements = target.removeAll();
    const targetPush = target.push.bind(target);

    target.push = (element) => {
        targetPush(element);
        setTimeout(() => target.shift(), livingTime);
    };

    initialElements.forEach(element => target.push(element));

    return target;
}

ko.extenders.elementsLivingTime = elementsLivingTime;