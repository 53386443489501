import {
    desktopOnly,
    getStyle,
    mobileOnly,
    STYLES_SEPARATOR,
    wrapBySelector,
    RTL,
    LTR,
} from '../../../service/customCss';
import { SearchBarCustomizationSelectors } from '../config/selectors';

export const getOverallStyle = ({
    selectors,
    backgroundColor,
    borderColor,
    borderWidth,
    borderRadius,
}: {
    selectors: SearchBarCustomizationSelectors;
    backgroundColor: string | null;
    borderColor: string | null;
    borderWidth: string | null;
    borderRadius: string | null;
}): string => {
    if (!backgroundColor) {
        backgroundColor = '';
    }

    if (!borderWidth) {
        borderWidth = '1';
    }

    const styles: string[] = [
        mobileOnly(
            [
                wrapBySelector(
                    `${selectors.fieldset}, ${selectors.dropdownMenu}`,
                    `background-color: ${backgroundColor};`
                ),
                wrapBySelector(
                    `${selectors.fieldset}, ${selectors.searchButtonStyle}, ${selectors.buttonBackground}`,
                    `border-radius: ${borderRadius}px;`
                ),
                wrapBySelector(
                    `${selectors.buttonBorder}, ${selectors.inputBorderMobile}`,
                    `border: ${borderWidth}px solid ${borderColor};
                    border-radius: ${borderRadius}px;`
                ),
            ].join(STYLES_SEPARATOR)
        ),
        desktopOnly(
            [
                wrapBySelector(
                    `${selectors.inputBackgroundDesktop}, ${selectors.dropdownMenu}`,
                    getStyle('backgroundColor', backgroundColor)
                ),
                wrapBySelector(
                    `${LTR} ${selectors.inputBackgroundDesktop}`,
                    `border: ${borderWidth}px solid ${borderColor};
                    border-right: 0;
                    border-radius: ${borderRadius}px 0 0 ${borderRadius}px;`
                ),
                wrapBySelector(
                    selectors.buttonBorder,
                    `border: ${borderWidth}px solid ${borderColor};
                    border-radius: 0 ${borderRadius}px ${borderRadius}px 0;`
                ),
                wrapBySelector(
                    `${selectors.searchButtonStyle}, ${selectors.buttonBackground}`,
                    `border-radius: 0 ${borderRadius}px ${borderRadius}px 0;`
                ),
                wrapBySelector(
                    `${RTL} ${selectors.inputBackgroundDesktop}`,
                    `border: ${borderWidth}px solid ${borderColor};
                    border-left: 0;
                    border-radius: 0 ${borderRadius}px ${borderRadius}px 0;`
                ),
                wrapBySelector(
                    `${RTL} ${selectors.buttonBorder}`,
                    `border: ${borderWidth}px solid ${borderColor};
                    border-right: 0;
                    border-radius: ${borderRadius}px 0 0 ${borderRadius}px;`
                ),
                wrapBySelector(
                    `${RTL} ${selectors.searchButtonStyle}, ${RTL} ${selectors.buttonBackground}`,
                    `border-radius: ${borderRadius}px 0 0 ${borderRadius}px;`
                ),
            ].join(STYLES_SEPARATOR)
        ),
    ];

    return styles.join(STYLES_SEPARATOR);
};
