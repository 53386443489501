function obfuscateString(value) {
    if (!value) {
        return value;
    }

    if (value.length <= 3) {
        return '*'.repeat(value.length);
    }

    const parts = [
        value.slice(0, 1),
        '*'.repeat(value.length - 2),
        value.slice(-1),
    ];

    return parts.join('');
}

export function obfuscate(value) {
    if (typeof value === 'string') {
        return obfuscateString(value);
    }

    return value;
}
