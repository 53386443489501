import { observable, observableArray, pureComputed } from 'knockout';
import { DONE, IN_PROGRESS, PENDING } from '../enum/taskStatus';

export class Stage {

    constructor({ name, label }) {
        this.name = observable(name);
        this.label = observable(label);
        this.steps = observableArray([]);

        this.status = pureComputed(() => this._getStatus());
    }

    addStep(step) {
        this.steps.push(step);
    }

    _getStatus() {
        const steps = this.steps();

        if (!steps.length) {
            return PENDING;
        }

        const hasAllDone = steps.every(step => step.status() === DONE);

        if (hasAllDone) {
            return DONE;
        }

        const hasStepInProgress = steps.some(step => step.status() === IN_PROGRESS);

        if (hasStepInProgress) {
            return IN_PROGRESS;
        }

        return PENDING;
    }

}
