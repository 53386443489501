import i18n from 'app/module/core/i18n/i18n';
import { ChildPropertiesCustomHeaders } from './types';
import base64 from 'core/utils/base64';

export const GLOBAL_HEADER_SETTINGS_KEY = 'ORA_GLOBAL';
export const ADDITIONAL_HEADER_SETTINGS_KEY = 'ORA_ADDITIONAL';

export const DEFAULT_GLOBAL_HEADER_SETTINGS = {
    headerType: 'hamburger',
    isLogoHidden: 'false',
    isEmployeeButtonHidden: 'true',
    employeeText: base64.encode(i18n('general.employee-login-button')),
    isProfileButtonHidden: 'false',
    profileText: '',
    type: GLOBAL_HEADER_SETTINGS_KEY,
    name: i18n('admin.global-header-editor.title'),
} as const;

export const CUSTOM_HEADERS_SETTING_PROPERTIES: ChildPropertiesCustomHeaders[] = [
    'isLogoHidden',
    'isEmployeeButtonHidden',
    'isProfileButtonHidden',
    'headerType',
];

export const EXCLUDE_ROUTES_LIST = [
    'job-preview',
    'job-formatting-preview',
    'job-template-preview',
    'event-preview',
    'fake-job-details',
    'search-events',
];

export const CUSTOM_PAGE_ROUTER_ID = ['home-page', 'search', 'job-details', 'cc-page'];
