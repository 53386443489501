import http from 'core/http/http';
import eventMapper from '../mapper/eventMapper';
import { EventData, EVENT_VISIBILITY, Settings } from 'minimal/module/event-details/config/types';
import appConfig from 'app/model/config';
import baseRouter from 'app/model/router';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';
import { areEventsEnabled } from 'app/service/areEventsEnabled';

export async function getEventDetails(eventId: string, context: string): Promise<EventData | null> {
    const response = await getEventDetailsFromApi(eventId, context, { expand: 'all' }).catch(
        (error: Error) => {
            console.error('Error getting event data from server ' + error);

            return Promise.reject(error);
        }
    );
    const [eventData] = response.items;
    const isSignedIn = signedCandidate.isSignedIn();
    const areEventEnabled = areEventsEnabled();

    const registeredEventList = isSignedIn && areEventEnabled ? await cachedCandidateRegistrations.get() : [];

    return eventMapper.mapEventFromRest(eventData, registeredEventList);
}

async function getEventDetailsFromApi(
    eventId: string,
    context: string,
    settings: Settings
): Promise<{ items: EventData[] }> {
    const endpoint = '/recruitingCEEventDetails';

    const queryElements = ['onlyData=true', 'finder=ById;:findParams:'];

    if (settings?.expand) {
        queryElements.unshift('expand=all');
    }

    const query = queryElements.join('&');
    const url = [endpoint, query].join('?');

    return http.get(url, createHttpConfig(eventId, context));
}

export async function getEventDetailsPreview(eventId: string, context: string): Promise<EventData | null> {
    const response = await getEventDetailsPreviewFromApi(eventId, context).catch((error: Error) => {
        console.error('Error getting event preview data from server ' + error);

        return Promise.reject(error);
    });
    const [eventData] = response.items;
    const isSignedIn = signedCandidate.isSignedIn();

    const registeredEventList = isSignedIn ? await cachedCandidateRegistrations.get() : [];

    return eventMapper.mapEventFromRest(eventData, registeredEventList);
}

async function getEventDetailsPreviewFromApi(
    eventId: string,
    context: string
): Promise<{ items: EventData[] }> {
    const url = '/recruitingCEEventDetailsPreviews?expand=all&onlyData=true&finder=ById;:findParams:';

    return http.get(url, createHttpConfig(eventId, context));
}

function createHttpConfig(eventId: string, context: string) {
    return {
        credentials: 'same-origin',

        statusCodes: {
            401() {
                return Promise.reject();
            },
            404() {
                return Promise.reject('event-not-found');
            },
        },
        params: {
            findParams: {
                Id: `${eventId}`,
                siteNumber: appConfig.siteNumber,
                apiContext: context,
            },
        },
        paramsDelimiter: ',',
    };
}

export const isEventAdminPreview = (): boolean => {
    return baseRouter.route().id === 'admin-event-preview';
};

export const isPublicEvent = (event: EventData | null): boolean => {
    return event?.eventVisibilityCode === EVENT_VISIBILITY.ORA_PUBLIC;
};

export const isEventOpenForRegistration = (event: EventData | null): boolean => {
    return event?.canRegisterFlag === true;
};
