import { Observable } from 'knockout';

export type PaginationState =
    | {
          type: 'loading';
      }
    | {
          type: 'no_more_items';
      }
    | {
          type: 'has_more_items';
          isInfiniteScrollEnabled: boolean;
          loadMore: () => void;
      }
    | true;

type Props = {
    hasMore: Observable<boolean | null>;
    loading: Observable<boolean>;
    loadMore: () => void;
    isInfiniteScrollEnabled: boolean;
    isMapView: Observable<boolean>;
};

export const getPaginationState = ({
    hasMore,
    loading,
    loadMore,
    isInfiniteScrollEnabled,
    isMapView,
}: Props): PaginationState => {
    if (!hasMore()) {
        return { type: 'no_more_items' };
    }

    if (loading()) {
        return { type: 'loading' };
    }

    if (isMapView()) {
        return true;
    }

    return {
        type: 'has_more_items',
        isInfiniteScrollEnabled,
        loadMore,
    };
};
