import contentTypes from '../enum/contentTypes';

const MINIMUM_PAY_FIELD = 'minimumPay';
const CURRENCY_CODE_FIELD = 'currencyCode';
const PAYMENT_FREQUENCY_FIELD = 'payFrequency';

const hiddenFields = {};

function _removeDuplicates(fieldName, index, fieldNames) {
    return fieldNames.indexOf(fieldName) === index;
}

function _applyHiddingExceptions(contentTypeId, fieldNamesToHide) {
    switch (contentTypeId) {
        case (contentTypes.WORK_PREFERENCE_ID):
            return _applyWorkPreferencesHiddingExceptions(fieldNamesToHide);
        default:
            return fieldNamesToHide;
    }
}

function _applyWorkPreferencesHiddingExceptions(fieldNamesToHide) {
    if (fieldNamesToHide.includes(MINIMUM_PAY_FIELD)) {
        return fieldNamesToHide;
    }

    return fieldNamesToHide
        .filter(name => ![CURRENCY_CODE_FIELD, PAYMENT_FREQUENCY_FIELD].includes(name));
}

export default {
    set(sectionId, contentTypeId, profileItemFields) {
        const fieldsToHide = profileItemFields.filter(field => field.isHidden && !field.keyFlag);

        const fieldNamesToHide = fieldsToHide
            .map(({ name }) => name)
            .filter(_removeDuplicates);

        hiddenFields[sectionId] = _applyHiddingExceptions(contentTypeId, fieldNamesToHide);
    },

    get(sectionId) {
        return hiddenFields[sectionId] || [];
    },

};