import i18n from 'core/i18n/i18n';
import FormTileStrategy from 'apply-flow/module/tile-profile-items/model/FormTileStrategy';
import tileDateFormatter from 'apply-flow/module/tile-profile-items/service/tileDateFormatter';
import { DATE_SHORT_FORMAT } from 'app/module/cx/service/dateTime/dateTimeFormats';

const UNNAMED_EXPERIENCE_LABEL = i18n('apply-flow.profile-item.unnamed-experience');
const PRESENT_LABEL = i18n('apply-flow.profile-item.time-present');

export default class ExperienceFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return {
            employerName: 'employerName',
            endDate: 'endDate',
            endingPosition: 'jobTitle',
            startDate: 'startDate',
            isCurrent: 'currentJobFlag',
        };
    }

    buildTitle(valueMap) {
        return valueMap.endingPosition || UNNAMED_EXPERIENCE_LABEL;
    }

    buildSubtitle(valueMap) {
        let subtitle = valueMap.employerName ? `${valueMap.employerName}` : '';

        if (valueMap.startDate) {
            subtitle = subtitle ? `${subtitle}\n` : '';

            if (valueMap.isCurrent === 'Y') {
                const startDate = tileDateFormatter.formatDate(valueMap.startDate, DATE_SHORT_FORMAT);

                return `${subtitle}${startDate} - ${PRESENT_LABEL}`;
            }

            const dateRange = tileDateFormatter.formatDateRange(
                valueMap.startDate, valueMap.endDate, DATE_SHORT_FORMAT);

            return `${subtitle}${dateRange}`;
        }

        if (valueMap.endDate) {
            const endDate = tileDateFormatter.formatDate(valueMap.endDate, DATE_SHORT_FORMAT);

            subtitle = subtitle ? `${subtitle}\n${endDate}` : `${endDate}`;
        }

        return subtitle;
    }

}