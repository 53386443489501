import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';
import profileImportService from 'cx/module/apply-flow/module/profile-import/service/profileImport';
import profileImportConverterVerCan from 'app/module/cx/module/apply-flow/module/profile-import/mapper/profileImportConverterVerCan';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import { profileImportTypes } from 'app/module/cx/module/apply-flow/module/profile-import/config/importTypes';

type Props = {
    performImportFromIndeed: Observable<boolean>;
    profileOrDraftExists: Observable<boolean>;
    profileLastUpdateDate: Observable<number>;
    profileImportBlockExists: Observable<boolean>;
};

export default class IndeedImportHandlerViewModel {
    performImportFromIndeed: Observable<boolean>;
    profileOrDraftExists: Observable<boolean>;
    profileSelectionOverlayVisible: Observable<boolean>;
    profileLastUpdateDate: PureComputed<string>;
    dateFormatter: LocaleBasedDateTimeFormatter;
    profileImportBlockExists: Observable<boolean>;

    constructor({
        performImportFromIndeed,
        profileOrDraftExists,
        profileLastUpdateDate,
        profileImportBlockExists,
    }: Props) {
        this.profileOrDraftExists = profileOrDraftExists;
        this.profileImportBlockExists = profileImportBlockExists;
        this.performImportFromIndeed = performImportFromIndeed;

        this.profileSelectionOverlayVisible = observable<boolean>(false);
        this.dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

        this.profileLastUpdateDate = pureComputed(() =>
            this.dateFormatter.formatDate(profileLastUpdateDate())
        );

        this.performImportFromIndeed.subscribe((importReady) => {
            if (importReady) {
                this.decideImportPath();
            }
        });
    }

    decideImportPath(): void {
        if (this.profileOrDraftExists()) {
            this.profileSelectionOverlayVisible(true);
        } else {
            this.fillApplyFlowWithIndeedData();
        }
    }

    async fillApplyFlowWithIndeedData(): Promise<void> {
        try {
            const profileConverter = profileImportConverterVerCan.getInstance();
            const convertedIndeedToApplyFlowData = await profileConverter.toLocalModel(
                profileImportService.getIndeedProfileFromCache()
            );

            await profileImportService.fillApplyFlow(
                convertedIndeedToApplyFlowData,
                profileImportTypes.indeed
            );

            this.showAfterImportNotification('success');
        } catch (error) {
            console.error(error);
            this.showAfterImportNotification('error');
        }
    }

    private showAfterImportNotification(status: 'success' | 'error') {
        const isReturningCandidate = this.profileOrDraftExists();

        if (this.profileImportBlockExists()) {
            profileImportEvents.indeedProfileImportEnded.dispatch({
                status,
                isReturningCandidate,
            });
        } else {
            let toastMessage = '';

            if (status === 'success') {
                toastMessage = i18n('apply-flow.profile-import.import-from-indeed.success-toast');
            } else {
                toastMessage = isReturningCandidate
                    ? i18n(
                          'apply-flow.profile-import.import-from-indeed.error-no-profile-import-block-returning-candidate'
                      )
                    : i18n(
                          'apply-flow.profile-import.import-from-indeed.error-no-profile-import-block-new-candidate'
                      );
            }

            notificationsService[status](toastMessage, 0);
        }
    }
}
