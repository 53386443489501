import appConfig from 'app/model/config';
import httpCached from 'core/http/httpCached';

function parseLocation(location) {
    if (!location) {
        return {};
    }

    const address = {
        '@type': 'PostalAddress',
        addressLocality: location.city,
        addressRegion: location.state,
        addressCountry: location.country,
    };

    return {
        '@type': 'Place',
        address,
    };
}

function fetchLocationDetails(locationId) {
    return httpCached.get(`/recruitingCESearchAutoSuggestions?expand=all&onlyData=true&finder=findByLocId;string=${locationId}`)
        .then(({ items }) => parseLocation(items.shift()));
}


function getISOFormat(date) {
    return date ? new Date(date).toISOString() : undefined;
}

function convertJobToMarkup(job) {
    return fetchLocationDetails(job.geographyId).then(location => ({
        '@context': 'https://schema.org',
        '@type': 'JobPosting',
        title: job.title,
        datePosted: getISOFormat(job.datePosted),
        validThrough: getISOFormat(job.validThrough),
        employmentType: job.employmentType,
        educationRequirements: job.educationRequirements,
        url: `${window.location.href}?utm_medium=search+engine&utm_source=google`,
        description: job.description,
        hiringOrganization: {
            '@type': 'Organization',
            name: appConfig?.seoConfiguration?.name,
            sameAs: appConfig.vanityBaseUrl ? appConfig.vanityBaseUrl : appConfig.ceBaseUrl,
            logo: appConfig?.seoConfiguration?.logo,
        },
        identifier: {
            '@type': 'PropertyValue',
            name: appConfig.siteName,
            value: job.id,
        },
        jobLocation: location,
    }));
}

export default {
    generateMarkup(job) {
        const tmpDiv = document.createElement('div');
        const tmpScript = document.createElement('script');

        tmpScript.type = 'application/ld+json';
        tmpDiv.appendChild(tmpScript);

        return convertJobToMarkup(job)
            .then((data) => {
                tmpScript.text = JSON.stringify(data);

                return tmpDiv.innerHTML;
            });
    },

    convertJobToMarkup,
};
