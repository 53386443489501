import timelineEvents from '../../config/events';
import i18n from 'core/i18n/i18n';
import l10n from 'core/l10n/l10n';

const HEIGHT_UNIT = 10;

function calculateSize(factor) {
    const height = HEIGHT_UNIT * factor;

    return `${height}px`;
}

export default class BeautifulTimelineItemViewModel {

    constructor({ item, type }) {
        this.item = item;
        this.type = type;
        this.offset = calculateSize(this.item.offset);
        this.height = calculateSize(this.item.height);
        this.contentOffset = calculateSize(this.item.contentOffset);
        this.colorCss = `beautiful-timeline-item--color-${this.item.color}`;
        this.startDate = l10n.date.formatMonthYear(this.item.startDate) || i18n('apply-flow.a11y.empty-date');
        this.endDate = l10n.date.formatMonthYear(this.item.endDate) || i18n('apply-flow.a11y.empty-date');

        this.dateRange = i18n('apply-flow.a11y.date-range', {
            startDate: this.startDate,
            endDate: this.endDate,
        });

        this.itemCss = {
            [this._resolveCss()]: true,
            'beautiful-timeline-item--faded': item.isFaded,
        };
    }

    _resolveCss() {
        const css = [
            `beautiful-timeline-item--level${this.item.level}`,
        ];

        if (!this.item.valid) {
            css.push('beautiful-timeline-item--invalid');
        }

        if (this.item.contentStyle) {
            css.push(`beautiful-timeline-item--${this.item.contentStyle}`);
        }

        return css.join(' ');
    }

    onclick() {
        timelineEvents.editTimelineItem.dispatch(this.item.id);
    }

    onMouseover() {
        timelineEvents.mouseoverItem.dispatch(this.item, this.type);
    }

    onMouseleave() {
        timelineEvents.mouseleaveItem.dispatch(this.type);
    }

}
