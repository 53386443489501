import { Observable, observable } from 'knockout';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import csEvents from 'cx/module/candidate-self-service/config/events';
import { Event, EventActionResponse } from '../../config/types';
import { confirmEventAction } from '../../service/eventRegistration';
import { mapConfirmEventParamsToRest } from '../../mapper/eventRegistration';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';

type Props = {
    event: Event;
};

export default class EventConfirmViewModel {
    private readonly ERR_EVENT_CANCELLED = 'EVENT_CANCELLED';
    isConfirmInProgress: Observable<boolean>;
    event: Event | null;

    constructor({ event }: Props) {
        this.isConfirmInProgress = observable<boolean>(false);
        this.event = event;
    }

    async confirm(): Promise<void> {
        this.isConfirmInProgress(true);

        if (this.event) {
            await confirmEventAction(mapConfirmEventParamsToRest(this.event))
                .then((response: EventActionResponse) => {
                    cachedCandidateRegistrations.clear();
                    csEvents.reloadEvents.dispatch();

                    const SUCCESS_MESSAGE = response.isManualConfirmationRequired
                        ? i18n('candidate-self-service.events.interest-thank-you-message')
                        : i18n('candidate-self-service.events.confirm-success-message');

                    notificationsService.success(SUCCESS_MESSAGE);
                })
                .catch(this.handleError.bind(this));

            this.isConfirmInProgress(false);
        }
    }

    handleError(error: string): void {
        console.error(error);

        if (error === this.ERR_EVENT_CANCELLED) {
            notificationsService.error(i18n('event-register-flow.submit-errors.event-reg-closed'), 0);
        } else {
            notificationsService.error(i18n('candidate-self-service.events.confirm-fail-message'));
        }
    }
}
