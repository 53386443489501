export const FILL_IN = 'fill-progress';
export const IN_PROGRESS = 'in-progress';
export const ATTEMPS_LIMIT_REACHED = 'ORA_URL_LOCKED';
export const PIN_LIMIT_REACHED = 'ORA_IRC_TOO_MANY_REQUESTS';
export const WRONG_PIN = 'ORA_INVALID_SHORT_CODE';
export const PHONE_BLOCKED = 'ORA_IRC_CANNOT_USE_PHONE';
export const CHALLENGE_REQUIRED = 'pin-code-required';
export const INVALID_PERSON_ID = 'ORA_INVALID_PERSON_ID';
export const CONTACT_UPDATE_LIMIT_REACHED = 'IRC_CONTACT_UPDATE_REQUEST_LIM';
export const CONTACT_UPDATED_RECENTLY = 'IRC_CONTACT_UPDATED_RECENTLY';
export const TOO_MANY_SIGNIN = 'ORA_TOO_MANY_SIGNIN';