import ko from 'knockout';
import referralService from 'candidate-verification/service/referral';
import downloader from 'ce-common/module/downloader/service/downloader';
import router from 'app/model/router';
import FileAttachment from 'apply-flow/module/file-upload/model/FileAttachment';
import draftAttachmentService from '../../service/draftAttachmentService';
import notificationsService from 'cx/service/notifications';
import { applicationDraft } from '../../../../service/applicationDraftInstance';
import applyFlowEvents from 'apply-flow/config/events';

export default class ReferralResumeBlockViewModel {

    constructor({ resumeModel }) {
        this.candidateResume = resumeModel;
        this.referral = ko.observable({});
        this.referralResume = ko.observable({});
        this.isDraftReady = ko.observable(false);

        this.referralId = referralService.getReferralId(router.routeParams().jobId);

        applyFlowEvents.afterApplicationDraftSave.addOnce(() => this.isDraftReady(true));

        this.isVisible = ko.pureComputed(() => {
            if (!this.referralId || !this.referralResume().fileContents || !this.isDraftReady()) {
                return false;
            }

            const isReferralResumeAttached = this.candidateResume() && this.candidateResume().isReferral();

            return !this.candidateResume() || !isReferralResumeAttached;
        });

        if (this.referralId) {
            this._loadReferral();
        }

        this.download = () => this._download();
    }

    _loadReferral() {
        referralService.get(this.referralId)
            .then(this.referral.bind(this))
            .then(referralService.getResume.bind(referralService, this.referralId))
            .then(this.referralResume.bind(this))
            .catch(error => console.error(error));
    }

    _download() {
        const { fileName, fileContents, mimeType } = this.referralResume();

        downloader.download(Promise.resolve({ fileName, fileContents, mimeType }));
    }

    attach() {
        if (!applicationDraft) {
            this.candidateResume(new FileAttachment(this.referralResume()));

            return;
        }

        draftAttachmentService.copyAttachmentToDraft(applicationDraft.id, this.referralResume())
            .then(this._replaceAttachment.bind(this))
            .catch((error) => {
                console.error(error);
                notificationsService.error();
            });
    }

    _replaceAttachment(newAttachment) {
        const existingAttachment = this.candidateResume();

        newAttachment.isReferral(true);
        this.candidateResume(newAttachment);

        if (applicationDraft && existingAttachment) {
            draftAttachmentService.remove(applicationDraft.id, existingAttachment);
        }
    }

}