import personalIdentifyingInformation from './personalIdentifyingInformation';
import candidateModel from 'apply-flow/model/candidate';
import { GENERAL_ERROR } from '../config/piiItemsValidationErrorTypes';

function getValidatableItems(piiItems) {
    const isNotReadOnly = item => !item.readOnly();
    const hasCountry = item => Boolean(item.country());
    const hasNIDType = item => Boolean(item.nationalIdtype());
    const hasNIDNumber = item => Boolean(item.nationalId());

    return piiItems
        .filter(isNotReadOnly)
        .filter(hasCountry)
        .filter(hasNIDType)
        .filter(hasNIDNumber);
}

function clearValidationErrors(forms) {
    forms.forEach(clearErrorAttributesOnForm);
}

function setValidationErrors(errorsWithPiiInfo, forms) {
    clearValidationErrors(forms);

    errorsWithPiiInfo.forEach((error) => {
        const matchedForms = findMatchingForms(error, forms);
        const isOneFormMatched = matchedForms.length === 1;
        const isMultipleFormsMatched = matchedForms.length > 1;

        if (isOneFormMatched) {
            const [matchedForm] = matchedForms;

            setErrorAttributesOnForm(matchedForm, error);
        } else if (isMultipleFormsMatched) {
            setErrorAttributesOnFormsExceptFirst(matchedForms, error);
        }
    });
}

function findMatchingForms(error, forms) {
    const hasItemIdSameAs = itemId => form => form().model().itemId() === itemId;
    const hasCountrySameAs = country => form => form().model().country() === country;
    const hasNIDTypeSameAs = type => form => form().model().nationalIdtype() === type;
    const hasNIDNumberSameAs = number => form => form().model().nationalId() === number;
    const hasIssueDateSameAs = issueDate => form => form().model().issueDate() === issueDate;
    const hasExpirationDateSameAs = expirationDate => form => form().model().expirationDate() === expirationDate;
    const hasPlaceOfIssueSameAs = placeOfIssue => form => form().model().placeOfIssue() === placeOfIssue;

    const {
        itemId,
        nationalIdCountry,
        nationalIdType,
        nationalIdNumber,
        issueDate,
        expirationDate,
        placeOfIssue,
    } = error.piiItem;

    return forms
        .filter(hasItemIdSameAs(itemId))
        .filter(hasCountrySameAs(nationalIdCountry))
        .filter(hasNIDTypeSameAs(nationalIdType))
        .filter(hasNIDNumberSameAs(nationalIdNumber))
        .filter(hasIssueDateSameAs(issueDate))
        .filter(hasExpirationDateSameAs(expirationDate))
        .filter(hasPlaceOfIssueSameAs(placeOfIssue));
}

function setErrorAttributesOnFormsExceptFirst(formsMatchedErrors, error) {
    formsMatchedErrors
        .slice(1)
        .forEach(form => setErrorAttributesOnForm(form, error));
}

function setErrorAttributesOnForm(form, error) {
    form().validationErrorMessage(error.errorMessage);
}

function clearErrorAttributesOnForm(form) {
    form().validationErrorMessage(null);
}

function logGeneralErrors(errorsWithPiiInfo) {
    const isGeneralError = error => error.errorType === GENERAL_ERROR;
    const hasGeneralErrors = errorsWithPiiInfo.some(isGeneralError);

    if (hasGeneralErrors) {
        const generalErrorMessage = errorsWithPiiInfo
            .filter(isGeneralError)
            .map(error => error.errorMessage)
            .join('\n');

        console.error(generalErrorMessage);
    }
}

export default {
    validateAllItems(piiItems, forms) {
        const validatablePiiItems = getValidatableItems(piiItems);

        if (validatablePiiItems.length === 0) {
            return Promise.resolve();
        }

        return personalIdentifyingInformation.validate(candidateModel, candidateModel.basicInformation,
            validatablePiiItems)
            .then(() => {
                clearValidationErrors(forms);
            })
            .catch((errorsWithPiiInfo) => {
                setValidationErrors(errorsWithPiiInfo, forms);
                logGeneralErrors(errorsWithPiiInfo);
            });
    },
};