import ko from 'knockout';
import googleJobPostingConverter from '../service/googleJobPostingConverter';

ko.bindingHandlers.generateGoogleJobSearchTags = {
    init(element, valueAccessor) {
        const job = valueAccessor();

        const script = document.createElement('script');

        script.type = 'application/ld+json';

        googleJobPostingConverter.convertJobToMarkup(job)
            .then((data) => {
                script.text = JSON.stringify(data);
                ko.virtualElements.prepend(element, script);
            });
    },
};

ko.virtualElements.allowedBindings.generateGoogleJobSearchTags = true;
