import { observable, pureComputed } from 'knockout';
import notificationsService from 'cx/service/notifications';
import candidateModel from 'apply-flow/model/candidate';
import draftAttachmentService from '../../../../service/draftAttachmentService';
import { applicationDraft } from '../../../../../../service/applicationDraftInstance';
import { download } from '../../../../service/download';
import i18n from 'core/i18n/i18n';

const ACTIONS = {
    DOWNLOAD: 'DOWNLOAD',
    REMOVE: 'REMOVE',
};

export default class MiscAttachmentListItemViewModel {

    constructor({ attachment }) {
        this.attachment = attachment;
        this.ACTIONS = ACTIONS;
        this.pendingAction = observable();
        this.isRemoving = pureComputed(() => this.pendingAction() === ACTIONS.REMOVE);
        this.buttonTitle = `${attachment.fileName()} ${i18n('general.remove-button')}`;
    }

    downloadAttachment() {
        if (this.pendingAction()) {
            return;
        }

        this.pendingAction(ACTIONS.DOWNLOAD);

        download(this.attachment)
            .catch(() => notificationsService.error())
            .then(() => this.pendingAction(false));
    }

    removeAttachment(context, event) {
        event.stopPropagation();

        if (this.pendingAction()) {
            return;
        }

        this.pendingAction(ACTIONS.REMOVE);

        draftAttachmentService.remove(applicationDraft.id, this.attachment)
            .then(() => candidateModel.attachments.remove(this.attachment))
            .catch(() => notificationsService.error())
            .finally(() => this.pendingAction(false));
    }

}