import ko from 'knockout';
import geolocationService from 'cx/service/geolocation/geolocation';
import geolocationQueryBuilder from '../service/geolocationQueryBuilder';


const STATUS = {
    ABORT: 'abort',
    INPROGRESS: 'inprogress',
    ERROR: 'error',
    SUCCESS: 'success',
};

export default {
    isEnable: ko.observable(true),

    queryStatus: ko.observable(),

    query() {
        this.queryStatus(STATUS.INPROGRESS);

        const geoLocationPromise = geolocationService.query();

        const abortPromise = new Promise((resolve, reject) => {
            const subscription = this.queryStatus.subscribe((status) => {
                if (status === STATUS.ABORT) {
                    reject();
                    subscription.dispose();
                }
            });
        });

        return Promise.race([geoLocationPromise, abortPromise])
            .then(geolocationQueryBuilder.build)
            .then((geolocation) => {
                this.queryStatus(STATUS.SUCCESS);

                return geolocation;
            })
            .catch((error) => {
                if (this.queryStatus() !== STATUS.ABORT) {
                    this.isEnable(false);
                    this.queryStatus(STATUS.ERROR);
                }

                return Promise.reject(error);
            });
    },

    abort() {
        this.queryStatus(STATUS.ABORT);
    },
};