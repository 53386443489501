import { observable, pureComputed } from 'knockout';
import partnersLinks from './service/partnersLinks';
import flowService from 'apply-flow/service/flow';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import MetadataService from './service/Metadata';
import applicationDraftService from 'apply-flow/service/applicationDraft';
import sectionState from 'apply-flow/model/sectionState';
import { applicationDraft } from 'apply-flow/service/applicationDraftInstance';
import { storeTokenInCookie } from 'candidate-verification/service/tokenCookie';
import notificationsService from 'cx/service/notifications';
import sectionValidator from 'apply-flow/model/sectionValidator';
import { PARTNER_TYPE_SETTINGS } from './config/configPartner';
import { PARTNER_BLOCK_CODE } from 'cx/module/candidate-verification/component/inline-partner-handler/config/partner';
import userTracking from 'cx/service/userTracking';

export default class InlinePartnerViewModel {

    constructor({ section, metadataServiceUrl, blockId, code }) {
        this.jobId = router.routeParams().jobId;
        this.isCompleted = observable(false);
        this.flowVersionId = flowService.get().flowVersionId;
        this.blockCode = code;
        this.partnerConfig = PARTNER_TYPE_SETTINGS[this.blockCode] || {};
        this.metadataService = new MetadataService(metadataServiceUrl);
        this.sectionNumber = section.number;
        this.blockId = blockId;
        this.showValidation = observable(false);
        this.isButtonDisabled = observable(true);
        this.takePartnerProgress = observable(false);
        this.selectorName = router.routeParams().element;
        this.isActive = observable(Boolean(this.selectorName));
        this.shouldSetFocus = observable(false);
        this.translationsKeyPartner = observable(this.partnerConfig.translationType);

        this.partnerConfigParameters = {
            requisitionNumber: this.jobId,
            flowVersionId: this.flowVersionId,
            draftId: applicationDraft.id,
            blockCode: this.blockCode,
        };

        this.isValid = pureComputed(() => (this.showValidation() ? this.isCompleted() : true));

        sectionValidator.addValidation(this.sectionNumber, this.isValid);
        section.addBeforeValidationCallback(() => this.enableValidation());

        this.initialize();
    }

    initialize() {
        this.getPartnerConfig()
            .catch((err) => {
                console.error(err);
                notificationsService.error();
            })
            .then(() => {
                sectionState.setBlockReady(this.sectionNumber, this.blockId);
                this.shouldSetFocus(this.isActive());

                if (this.isCompleted() && this.isActive()) {
                    accessibilityEvents.addAlert.dispatch(
                        i18n(`apply-flow.partners.${this.translationsKeyPartner()}-completed`),
                    );

                    this.trackPartnerSuccess();
                }
            });
    }

    trackPartnerSuccess() {
        if (this.blockCode === PARTNER_BLOCK_CODE.assessment) {
            userTracking.trackJobAppAssessmentSuccess(this.jobId, null, router.route().id);
        }

        if (this.blockCode === PARTNER_BLOCK_CODE['tax-credits']) {
            userTracking.trackJobAppTaxCreditSuccess(this.jobId, null, router.route().id);
        }
    }

    trackPartnerStarted() {
        if (this.blockCode === PARTNER_BLOCK_CODE.assessment) {
            userTracking.trackJobAppAssessmentStart(this.jobId, null, router.route().id);
        }

        if (this.blockCode === PARTNER_BLOCK_CODE['tax-credits']) {
            userTracking.trackJobAppTaxCreditStart(this.jobId, null, router.route().id);
        }
    }

    enableValidation() {
        this.showValidation(true);
    }

    getPartnerConfig() {
        if (!applicationDraft || !applicationDraft.id) {
            this.isButtonDisabled(false);

            return Promise.resolve();
        }

        return this.metadataService.getPartnerConfig(this.partnerConfigParameters)
            .then((response) => {
                this.isCompleted(response.isPartnerCompleted);
                this.isButtonDisabled(false);
            })
            .catch(console.error);
    }

    redirectToPartnerURL() {
        this.isButtonDisabled(true);
        this.takePartnerProgress(true);

        applicationDraftService.save()
            .catch(() => Promise.resolve())
            .then(() => {
                storeTokenInCookie();

                const { getPartnerLink } = this.partnerConfig;

                this.partnerConfigParameters.draftId = applicationDraft.id;

                this.trackPartnerStarted();

                return partnersLinks[getPartnerLink](this.partnerConfigParameters);
            })
            .then((response) => {
                if (!response.partnerUrl) {
                    return Promise.reject('missing url');
                }

                return router.redirect(response.partnerUrl);
            })
            .catch((err) => {
                console.error(err);
                notificationsService.error();
                this.takePartnerProgress(false);
            });
    }

}