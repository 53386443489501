import i18n from 'core/i18n/i18n';

export type TranslationModuleKeys = 'ojSelectOne' | 'ojSelectMany' | 'ojColorSpectrum' | 'ojInputNumber';

const ojTranslationsService = () => {
    return {
        ojSelectOne: {
            noMatchesFound: i18n('ojtranslations.select-one.no-matches-found'),
            searchField: i18n('ojtranslations.select-one.search-field'),
        },
        ojSelectMany: {
            noMoreResults: i18n('search.no-results-header'),
            noMatchesFound: i18n('ojtranslations.select-one.no-matches-found'),
        },
        ojColorSpectrum: {
            labelHue: i18n('ojtranslations.color-spectrum.label-hue'),
            labelOpacity: i18n('ojtranslations.color-spectrum.label-opacity'),
        },
        ojInputNumber: (min?: number, max?: number, converter?: string) => {
            return {
                numberRange: {
                    hint: {
                        inRange: `${i18n('ojtranslations.input-number.in-range', {
                            MIN_VALUE: min,
                            MAX_VALUE: max,
                        })}${converter}.`,
                        min: i18n('ojtranslations.input-number.min-value', {
                            MIN_VALUE: min,
                        }),
                    },
                },
                tooltipIncrement: i18n('ojtranslations.input-number.tooltip-increment'),
                tooltipDecrement: i18n('ojtranslations.input-number.tooltip-decrement'),
            };
        },
    };
};

export const ojTranslations = ojTranslationsService();
