import { pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import AbstractModeMenuViewModel from 'search/module/location/module/mode-menu/AbstractModeMenuViewModel';
import { showSearchModeMenu } from 'cx/module/search/module/location/config/locationSearchConfig';

class ModeMenuViewModel extends AbstractModeMenuViewModel {

    constructor() {
        super();

        this.toggleButtonText = pureComputed(() => i18n(`search.mode-menu.near-${this.mode()}`));
        this.showSearchModeMenu = showSearchModeMenu();
    }

}

export default ModeMenuViewModel;
