import mapping from 'knockout-mapping';

function ESignature() {
    const rawESignature = {
        fullName: '',
    };

    mapping.fromJS(rawESignature, {}, this);
}

export default ESignature;