import ko from 'knockout';
import mapping from 'knockout-mapping';

export default class Offer {

    constructor(data) {
        this.media = ko.observableArray();

        mapping.fromJS(data, {}, this);

        this.isOfferAcceptedByCandidate = ko.pureComputed(() => this.status() === 'JOBOFFER_ACCEPTED');

        this.isOfferAcceptedOnBehalf = ko.pureComputed(() => this.status() === 'JOBOFFER_ACCEPTED_ON_BEHALF');

        this.isOfferAccepted = ko.pureComputed(() =>
            this.isOfferAcceptedByCandidate() || this.isOfferAcceptedOnBehalf());

        if (data.offerDeclineReasons) {
            this.offerDeclineReasons = mapping.toJS(data.offerDeclineReasons);
        }
    }

}