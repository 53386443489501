import { bindingHandlers, utils } from 'knockout';

/**
 * Select whole text value of location search input if it contains geolocation. If search searchGeolocation is passed,
 * it queries users geolocation.
 *
 * @params {observable} location - observable with object describing current location in search input.
 * @params {function} searchGeolocation - function for querying users geolocation.
 *
 * @listens focus
 */

bindingHandlers.locationSearchFocus = {
    init(element, valueAccessor) {
        const { location, searchGeolocation } = valueAccessor();

        const onFocus = () => {
            if (searchGeolocation && !element.value) {
                searchGeolocation();
            } else if (location().isGeolocation) {
                element.setSelectionRange(0, element.value.length);
            }
        };

        element.addEventListener('focus', onFocus);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('focus', onFocus);
        });
    },
};
