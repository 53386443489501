export const FONT_SIZES = ['10', '12', '14', '16', '20', '24', '36', '48', '64'] as const;

export const LINE_HEIGHTS = ['1', '1.2', '1.5', '2'] as const;

export const FONT_WEIGHTS = {
    BOLD: '700',
    NORMAL: '400',
} as const;

export const FONT_STYLES = {
    NORMAL: null,
    ITALIC: 'italic',
} as const;

export type TextAlign = 'left' | 'center' | 'right' | 'justify';
