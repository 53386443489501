import ko from 'knockout';
import FormValidator from './Validator';

const YEAR_FORMAT = 'YYYY';

export default class YearFormatValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        ko.utils.extend(this._options, {
            year_format: YEAR_FORMAT,
        });

        this._message = 'validators.invalid-year-format';
    }

    _validate(date) {
        if (!date) {
            return true;
        }

        const [stringYear] = date.split('-');

        if (!stringYear) {
            return true;
        }

        if (isNaN(stringYear) || (stringYear >= 0 && stringYear <= 999) || stringYear > 9999) {
            return false;
        }

        return true;
    }

}