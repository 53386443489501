import { getItemActiveSelector, getItemSelector } from 'minimal/config/utils';

type SearchResultField =
    | 'root'
    | 'container'
    | 'item'
    | 'itemOnHover'
    | 'itemTitle'
    | 'itemTitleOnHover'
    | 'itemSubHeaderTitle'
    | 'itemSubHeaderOnHover'
    | 'itemDescriptionTitle'
    | 'itemDescriptionTitleOnHover'
    | 'itemDescription'
    | 'itemDescriptionOnHover'
    | 'itemSortBy'
    | 'itemSortOptions'
    | 'itemSortOptionsOnHover'
    | 'itemSelectedSortValue'
    | 'itemSelectedSortValueOnHover'
    | 'itemSelectedSortOption'
    | 'itemIcon'
    | 'itemIconOnHover'
    | 'itemSortByIcon'
    | 'itemSortByIconOnHover'
    | 'itemSortByDropdown'
    | 'tileHeader'
    | 'tileHeaderOnHover'
    | 'jobTagBox'
    | 'jobTagBoxOnHover'
    | 'alreadyAppliedTagBox'
    | 'alreadyAppliedTagBoxOnHover'
    | 'jobIconsStyles'
    | 'jobIconsStylesOnHover'
    | 'defaultToggleIconStyles'
    | 'defaultToggleIconStylesOnHover'
    | 'selectedToggleIconStyles'
    | 'selectedToggleIconStylesOnHover'
    | 'jobInfoLabel'
    | 'jobInfoLabelOnHover'
    | 'jobInfoValue'
    | 'jobInfoValueOnHover'
    | 'jobInfoIcon'
    | 'jobInfoIconOnHover'
    | 'applyNowButton'
    | 'applyNowButtonOnHover'
    | 'applyNowButtonContainer'
    | 'applyNowButtonContainerOnHover';

type SearchResultGeneralSelector = Record<SearchResultField, string>;

type SearchResultSelectors = {
    list: Omit<SearchResultGeneralSelector, 'tileHeader' | 'tileHeaderOnHover'> & {
        tileHeader: null;
        tileHeaderOnHover: null;
    };
    tile: Omit<SearchResultGeneralSelector, 'itemIcon' | 'itemIconOnHover'> & {
        itemIcon: string;
        itemIconOnHover: string;
    };
};

export const getSearchResultSelectors = (wrapperSelector: string): SearchResultSelectors => {
    return {
        list: {
            root: `${wrapperSelector} .jobs-list, ${wrapperSelector} .search-job-results__map-container`,
            container: `${wrapperSelector} .jobs-list__list, ${wrapperSelector} .jobs-list__header, ${wrapperSelector} .search-job-results__map-container`,
            item: `${wrapperSelector} .job-list-item`,
            itemOnHover: getItemActiveSelector(`${wrapperSelector} .job-list-item`, ``),
            itemTitle: `${wrapperSelector} .job-tile__title`,
            itemTitleOnHover: getItemActiveSelector(`${wrapperSelector} .job-list-item`, `.job-tile__title`),
            itemSubHeaderTitle: `${wrapperSelector} .job-tile__subheader`,
            jobInfoLabel: `${wrapperSelector} .job-list-item__job-info-label`,
            jobInfoLabelOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-list-item__job-info-label, .job-tile__header--job-info-tags .job-list-item__job-info-label`
            ),
            jobInfoValue: `${wrapperSelector} .job-list-item__job-info-value-container`,
            jobInfoValueOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-list-item__job-info-value-container, .job-tile__header--job-info-tags .job-list-item__job-info-value-container`
            ),
            jobInfoIcon: `${wrapperSelector} .job-list-item__job-info-icon`,
            jobInfoIconOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-list-item__job-info-icon`
            ),
            itemSubHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-tile__subheader`
            ),
            itemDescriptionTitle: `${wrapperSelector} .job-list-item__text-item-header`,
            itemDescriptionTitleOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                ` .job-list-item__text-item-header`
            ),
            itemDescription: `${wrapperSelector} .job-list-item__description, ${wrapperSelector} .job-list-item__text-item`,
            itemDescriptionOnHover: `
                ${getItemActiveSelector(`${wrapperSelector} .job-list-item`, `.job-list-item__description`)},
                ${getItemActiveSelector(`${wrapperSelector} .job-list-item`, `.job-list-item__text-item`)}`,
            itemSortBy: `${wrapperSelector} .search-results-sorting__label`,
            itemSortOptions: `${wrapperSelector} .search-results-sorting__selector-item--button`,
            itemSortOptionsOnHover: `
                ${getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector-item--button`,
                    ''
                )}, 
                ${getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
                    ''
                )}`,
            itemSelectedSortValue: `${wrapperSelector} .search-results-sorting__selector`,
            itemSelectedSortValueOnHover: getItemActiveSelector(
                `${wrapperSelector} .search-results-sorting__selector`,
                ''
            ),
            itemSelectedSortOption: `${wrapperSelector} .search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
            itemIcon: `
                ${wrapperSelector} .job-list-item__show-icon::before, 
                ${wrapperSelector} .favourite-star::before, 
                ${wrapperSelector} .copy-link__icon::before`,
            itemIconOnHover: `
                ${getItemActiveSelector(
                    `${wrapperSelector} .job-list-item`,
                    `.job-list-item__show-icon::before`
                )}, 
                ${getItemActiveSelector(`${wrapperSelector} .job-list-item`, `.favourite-star::before`)}, 
                ${getItemActiveSelector(`${wrapperSelector} .job-list-item`, `.copy-link__icon::before`)}`,
            applyNowButton: `${wrapperSelector} .job-list-item__apply-now-button .job-list-item__apply-now-button-frame`,
            applyNowButtonOnHover: `${getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-list-item__apply-now-button-frame`
            )}`,
            applyNowButtonContainer: `${wrapperSelector} .job-list-item__apply-now-button`,
            applyNowButtonContainerOnHover: `${getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                `.job-list-item__apply-now-button`
            )}`,
            itemSortByIcon: `${wrapperSelector} .search-results-sorting__selector-icon, ${wrapperSelector} .search-results-sorting__selector-mobile-icon`,
            itemSortByIconOnHover: [
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-icon'
                ),
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-mobile-icon'
                ),
            ].join(', '),
            itemSortByDropdown: `${wrapperSelector} .search-results-sorting__selector-menu`,
            tileHeader: null,
            tileHeaderOnHover: null,
            jobIconsStyles: `${wrapperSelector} .job-tag__icon::before`,
            jobIconsStylesOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                '.job-tag__icon::before'
            ),
            jobTagBox: `${wrapperSelector} .job-tag`,
            jobTagBoxOnHover: getItemActiveSelector(`${wrapperSelector} .job-list-item`, '.job-tag'),
            alreadyAppliedTagBox: `${wrapperSelector} .job-status-tag`,
            alreadyAppliedTagBoxOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                '.job-status-tag'
            ),

            defaultToggleIconStyles: [
                `${wrapperSelector} .search-job-results__jobs-list-icon-container`,
                `${wrapperSelector} .search-job-results__jobs-grid-icon-container`,
                `${wrapperSelector} .search-job-results__toggle-map-icon-container`,
            ].join(','),
            defaultToggleIconStylesOnHover: [
                getItemSelector(`${wrapperSelector} .search-job-results__jobs-list-icon-container`, ''),
                getItemSelector(`${wrapperSelector} .search-job-results__jobs-grid-icon-container`, ''),
                getItemSelector(`${wrapperSelector} .search-job-results__toggle-map-icon-container`, ''),
            ].join(','),
            selectedToggleIconStyles: [
                `${wrapperSelector} .search-job-results__jobs-list-icon--active-container`,
                `${wrapperSelector} .search-job-results__jobs-grid-icon--active-container`,
                `${wrapperSelector} .search-job-results__toggle-map-icon--active-container`,
            ].join(','),
            selectedToggleIconStylesOnHover: [
                getItemSelector(
                    `${wrapperSelector} .search-job-results__jobs-list-icon--active-container`,
                    ''
                ),
                getItemSelector(
                    `${wrapperSelector} .search-job-results__jobs-grid-icon--active-container`,
                    ''
                ),
                getItemSelector(
                    `${wrapperSelector} .search-job-results__toggle-map-icon--active-container`,
                    ''
                ),
            ].join(','),
        },
        tile: {
            root: `${wrapperSelector} .jobs-grid, ${wrapperSelector} .search-job-results__map-container`,
            container: `${wrapperSelector} .jobs-grid__list, ${wrapperSelector} .jobs-list__header, ${wrapperSelector} .search-job-results__map-container`,
            item: `${wrapperSelector} .job-grid-item`,
            itemOnHover: getItemActiveSelector(`${wrapperSelector} .job-grid-item`, ``),
            itemTitle: `${wrapperSelector} .job-tile__title`,
            itemTitleOnHover: getItemActiveSelector(`${wrapperSelector} .job-grid-item`, `.job-tile__title`),
            jobInfoLabel: `${wrapperSelector} .job-list-item__job-info-label`,
            jobInfoLabelOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-list-item__job-info-label, .job-tile__header--job-info-tags .job-list-item__job-info-label`
            ),
            jobInfoValue: `${wrapperSelector} .job-list-item__job-info-value-container`,
            jobInfoValueOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-list-item__job-info-value-container, .job-tile__header--job-info-tags .job-list-item__job-info-value-container`
            ),
            jobInfoIcon: `${wrapperSelector} .job-list-item__job-info-icon`,
            jobInfoIconOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-list-item__job-info-icon`
            ),
            itemSubHeaderTitle: `${wrapperSelector} .job-tile__subheader`,
            itemSubHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-tile__subheader`
            ),
            itemDescriptionTitle: `${wrapperSelector} .job-grid-item__text-item-header`,
            itemDescriptionTitleOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-grid-item__text-item-header`
            ),
            itemDescription: `${wrapperSelector} .job-grid-item__description`,
            itemDescriptionOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-grid-item__description`
            ),
            itemSortBy: `${wrapperSelector} .search-results-sorting__label`,
            itemSortOptions: `${wrapperSelector} .search-results-sorting__selector-item--button`,
            itemSortOptionsOnHover: `
                ${getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector-item--button`,
                    ''
                )}, 
                ${getItemActiveSelector(
                    `${wrapperSelector} ..search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
                    ''
                )}`,
            itemSelectedSortValue: `${wrapperSelector} .search-results-sorting__selector`,
            itemSelectedSortValueOnHover: getItemActiveSelector(
                `${wrapperSelector} .search-results-sorting__selector`,
                ''
            ),
            itemSelectedSortOption: `${wrapperSelector} .search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
            itemIcon: `
                ${wrapperSelector} .favourite-star::before, 
                ${wrapperSelector} .copy-link__icon::before`,
            itemIconOnHover: `
                ${getItemActiveSelector(`${wrapperSelector} .job-grid-item`, `.favourite-star::before`)}, 
                ${getItemActiveSelector(`${wrapperSelector} .job-grid-item`, `.copy-link__icon::before`)}`,
            itemSortByIcon: `${wrapperSelector} .search-results-sorting__selector-icon, ${wrapperSelector} .search-results-sorting__selector-mobile-icon`,
            itemSortByIconOnHover: [
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-icon'
                ),
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-mobile-icon'
                ),
            ].join(', '),
            itemSortByDropdown: `${wrapperSelector} .search-results-sorting__selector-menu`,
            tileHeader: `${wrapperSelector} .job-grid-item__header`,
            tileHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-grid-item__header`
            ),
            jobIconsStyles: `${wrapperSelector} .job-tag__icon::before`,
            jobIconsStylesOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                '.job-tag__icon::before'
            ),
            jobTagBox: `${wrapperSelector} .job-tag`,
            jobTagBoxOnHover: getItemActiveSelector(`${wrapperSelector} .job-grid-item`, '.job-tag'),
            alreadyAppliedTagBox: `${wrapperSelector} .job-status-tag`,
            alreadyAppliedTagBoxOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                '.job-status-tag'
            ),
            defaultToggleIconStyles: [
                `${wrapperSelector} .search-job-results__jobs-list-icon-container`,
                `${wrapperSelector} .search-job-results__jobs-grid-icon-container`,
                `${wrapperSelector} .search-job-results__toggle-map-icon-container`,
            ].join(','),
            defaultToggleIconStylesOnHover: [
                getItemSelector(`${wrapperSelector} .search-job-results__jobs-list-icon-container`, ''),
                getItemSelector(`${wrapperSelector} .search-job-results__jobs-grid-icon-container`, ''),
                getItemSelector(`${wrapperSelector} .search-job-results__toggle-map-icon-container`, ''),
            ].join(','),
            selectedToggleIconStyles: [
                `${wrapperSelector} .search-job-results__jobs-list-icon--active-container`,
                `${wrapperSelector} .search-job-results__jobs-grid-icon--active-container`,
                `${wrapperSelector} .search-job-results__toggle-map-icon--active-container`,
            ].join(','),
            selectedToggleIconStylesOnHover: [
                getItemSelector(
                    `${wrapperSelector} .search-job-results__jobs-list-icon--active-container`,
                    ''
                ),
                getItemSelector(
                    `${wrapperSelector} .search-job-results__jobs-grid-icon--active-container`,
                    ''
                ),
                getItemSelector(
                    `${wrapperSelector} .search-job-results__toggle-map-icon--active-container`,
                    ''
                ),
            ].join(','),
            applyNowButton: `${wrapperSelector} .job-list-item__apply-now-button .job-list-item__apply-now-button-frame`,
            applyNowButtonContainer: `${wrapperSelector} .job-list-item__apply-now-button`,
            applyNowButtonContainerOnHover: `${getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-list-item__apply-now-button`
            )},
            ${getItemActiveSelector(
                `${wrapperSelector} .search-results.job-grid-item`,
                `.job-tile__header--job-info-tags .job-list-item__apply-now-button`
            )}`,
            applyNowButtonOnHover: `${getItemActiveSelector(
                `${wrapperSelector} .job-grid-item`,
                `.job-list-item__apply-now-button-frame`
            )},
            ${getItemActiveSelector(
                `${wrapperSelector} .search-results.job-grid-item`,
                `.job-tile__header--job-info-tags .job-list-item__apply-now-button-frame`
            )}`,
        },
    };
};
