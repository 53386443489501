import AiJobsSearchAbstractViewModel from '../search-ai-jobs/AiJobsSearchAbstractViewModel';
import { searchJobsToCandidate as searchService } from '../../service/jobsToCandidateSearch';
import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';

export default class SearchJobsToCandidateViewModel extends AiJobsSearchAbstractViewModel {

    performSearch() {
        searchService.searchJobs({}, this.requisitionId)
            .catch(this._handleError.bind(this))
            .finally(this.onJobsLoaded.bind(this));
    }

    getMoreJobs() {
        return searchService.loadMoreJobs({}, this.requisitionId);
    }

    getHeaderLabel() {
        return 'search.search-jobs-to-candidate.suggested-jobs-header';
    }

    _handleError(error) {
        super._handleError(error);

        notificationsService.error(i18n('candidate-self-service.jobs-to-candidates-loading-error'));
    }

}
