import { mapYesNoToBoolean } from '../../../../../../core/utils/stringToBoolean';

const validatorsOptionsGenerator = {
    isRequired(validatorValue, formElement) {
        if (!mapYesNoToBoolean(validatorValue)) {
            return {};
        }

        const options = {};

        if (formElement.type === 'checkboxStringValue') {
            options.checkbox = { checked: formElement.checked };
        }

        return {
            required: options,
        };
    },

    isUpperCaseOnly(validatorValue) {
        if (!mapYesNoToBoolean(validatorValue)) {
            return {};
        }

        return {
            onlyUppercase: {},
        };
    },

    maximumLength(validatorValue) {
        return {
            maxLength: {
                max: validatorValue,
            },
        };
    },

    maximumValue(validatorValue, { type }) {
        if (type === 'integer' || type === 'decimal') {
            return {
                lessThan: {
                    max: Number(validatorValue),
                    inclusive: true,
                },
            };
        }

        if (type === 'date' || type === 'datetime') {
            return {
                maxDate: {
                    max: validatorValue,
                    inclusive: true,
                },
            };
        }

        return {};
    },

    minimumValue(validatorValue, { type }) {
        if (type === 'integer' || type === 'decimal') {
            return {
                greaterThan: {
                    min: Number(validatorValue),
                    inclusive: true,
                },
            };
        }

        if (type === 'date' || type === 'datetime') {
            return {
                minDate: {
                    min: validatorValue,
                    inclusive: true,
                },
            };
        }

        return {};
    },

    precision(validatorValue) {
        return {
            precisionNumber: {
                precision: Number(validatorValue),
            },
        };
    },

    scale(validatorValue) {
        return {
            scaleNumber: {
                scale: Number(validatorValue),
            },
        };
    },

    zeroFill(validatorValue) {
        return {
            zeroFill: {
                number_of_characters: validatorValue,
            },
        };
    },
};

export function createValidatorsOptions(restElement, formElement) {
    const supportedValidators = Object.keys(validatorsOptionsGenerator);

    return supportedValidators.reduce((validatorsOptions, validatorName) => {
        const validatorValue = restElement[validatorName];

        if (validatorValue) {
            const optionsGenerator = validatorsOptionsGenerator[validatorName];
            const options = optionsGenerator(validatorValue, formElement);

            Object.assign(validatorsOptions, options);
        }

        return validatorsOptions;
    }, {});
}