import ko from 'knockout';

/**
 * Sets boolean values to an HTML attributes.
 *
 * @param {Object} Map with attribute-value pairs
 *
 * @example
 *  <div data-bind="boolAttr: {'aria-busy': isLoading}"></div>
 *
 */

ko.bindingHandlers.boolAttr = {
    update(element, accessor) {
        const attributes = ko.utils.unwrapObservable(accessor()) || {};

        ko.utils.objectForEach(attributes, (attrName, attrValue) => {
            const value = ko.utils.unwrapObservable(attrValue);

            element.setAttribute(attrName, value ? 'true' : 'false');
        });
    },
};