import mapping from 'knockout-mapping';
import appConfig from 'app/model/config';
import { IntlConverterUtils } from 'ojs/ojcore';
import Application from 'apply-flow/model/Application';
import { mapDraftContentFromRest, mapDraftContentToRest } from './applicationDraftContent';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import { getInitialEmail, getInitialPhone } from '../model/initialCommunicationChannels';

const UPDATE_COMMUNICATION_CHANNEL_ACTION = 'ORA_UPDATE_COMMUNICATION_CHANNEL';

function mapRemovedItemsFromRest(removedItemsMap) {
    return Object.keys(removedItemsMap).reduce((mappedItems, itemName) => {
        const removedItems = removedItemsMap[itemName];

        mappedItems[itemName] = removedItems.map(item => mapping.fromJS(item));

        return mappedItems;
    }, {});
}

export default {
    mapToRest(draftModel) {
        const draft = mapping.toJS(draftModel);

        const { initialEmail, initialEmailVerified } = getInitialEmail();
        const { initialPhone, initialPhoneVerified } = getInitialPhone();
        const { phone } = draft.candidate.basicInformation;

        if (initialEmailVerified) {
            draft.candidate.basicInformation.email = initialEmail;
        }

        if (initialPhoneVerified) {
            phone.areaCode = initialPhone.areaCode;
            phone.countryCode = initialPhone.countryCode;
            phone.legislationCode = initialPhone.legislationCode;
            phone.number = initialPhone.number;
        }

        const { email, verificationMethod } = draft.candidate.basicInformation;

        return {
            siteNumber: appConfig.siteNumber,
            contentLanguage: appConfig.siteLang,
            candidateNumber: draft.candidate.id,
            requisitionNumber: draft.jobId,
            legalDescriptionVersionId: draft.legalDisclaimerId,
            content: mapDraftContentToRest(draft),
            emailAddress: email,
            countryCode: phone.countryCode,
            areaCode: phone.areaCode,
            phoneNumber: phone.number,
            preferredChannel: verificationMethod,
            submissionId: draft.application.submissionId,
            flowId: draft.application.flowId,
            stepActionUsageId: draft.application.currentPhaseId,
            draftSource: draft.draftSource ?? undefined,
        };
    },

    mapFromRest(restCollection) {
        const [restDraft] = restCollection.items;

        if (!restDraft) {
            return null;
        }

        const { candidate, application, removedProfileItemsMap } = mapDraftContentFromRest(restDraft);

        application.submissionId = restDraft.submissionId;
        application.flowId = restDraft.flowId;
        application.currentPhaseId = restDraft.stepActionUsageId;

        const draft = {
            id: restDraft.appdraftId,
            jobId: restDraft.requisitionNumber,
            legalDisclaimerId: restDraft.legalDescriptionVersionId,
            candidate,
            candidateId: restDraft.candidateNumber,
            contentLanguage: restDraft.contentLanguage,
            application: new Application(application),
            draftModificationDate: IntlConverterUtils.isoToLocalDate(restDraft.modificationDate),
            savedContent: restDraft.content,
            proposedCandidateId: restDraft.proposedCandidateNumber,
            removedProfileItemsMap: mapRemovedItemsFromRest(removedProfileItemsMap),
            draftSource: restDraft.draftSource ?? undefined,
        };

        if (restDraft.candLastModifiedDate) {
            draft.candidateModificationDate = IntlConverterUtils.isoToLocalDate(restDraft.candLastModifiedDate);
        }

        return draft;
    },

    mapSaveFromRest(draft) {
        return {
            id: draft.appdraftId,
            draftModificationDate: IntlConverterUtils.isoToLocalDate(draft.modificationDate),
            savedContent: draft.content,
            contentLanguage: draft.contentLanguage,
            proposedCandidateId: draft.proposedCandidateNumber,
            candidateId: draft.candidateNumber,
            jobId: draft.requisitionNumber,
            candidateModificationDate: draft.candLastModifiedDate
                ? IntlConverterUtils.isoToLocalDate(draft.candLastModifiedDate)
                : undefined,
        };
    },

    mapDraftsListFromRest(drafts, jobs) {
        const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

        return drafts.map((draft) => {
            const draftJob = jobs.find(({ id }) => id === draft.requisitionNumber);

            if (!draftJob) {
                return null;
            }

            return {
                id: draft.appdraftId,
                jobId: draft.requisitionNumber,
                modificationDateOrigianl: draft.modificationDate,
                modificationDate: dateFormatter.formatDate(draft.modificationDate),
                siteNumber: draft.siteNumber,
                siteName: draft.siteName,
                siteUrlName: draft.siteUrlName,
                jobTitle: draftJob.title,
                jobLocation: draftJob.primaryLocation,
                jobSecondaryLocations: draftJob.secondaryLocations?.map(item => item.name),
                workplaceType: draftJob.workplaceType,
                workplaceTypeCode: draftJob.workplaceTypeCode,
            };
        }).filter(draft => draft);
    },

    mapDraftsUpdateToRest({ candidate, proposedCandidateId }) {
        const { email, phone } = mapping.toJS(candidate.basicInformation);

        return {
            candidateNumber: candidate.id() || proposedCandidateId,
            customAction: 'ORA_UPDATE',
            emailAddress: email,
            countryCode: phone.countryCode,
            areaCode: phone.areaCode,
            phoneNumber: phone.number,
        };
    },

    mapUpdateCommunicationChannelEmailToRest(email, draftId, proposedCandidateId) {
        return {
            customAction: UPDATE_COMMUNICATION_CHANNEL_ACTION,
            phoneNumber: null,
            areaCode: null,
            countryCode: null,
            email,
            draftId,
            proposedCandidateNumber: proposedCandidateId,
        };
    },

    mapUpdateCommunicationChannelPhoneToRest(phone, draftId, proposedCandidateId) {
        return {
            customAction: UPDATE_COMMUNICATION_CHANNEL_ACTION,
            phoneNumber: phone.number,
            areaCode: phone.areaCode,
            countryCode: phone.countryCode,
            email: null,
            draftId,
            proposedCandidateNumber: proposedCandidateId,
        };
    },
};