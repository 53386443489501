import router from 'app/model/router';
import jobService from 'job-details/service/job';
import ApplyFlowBlockComponentViewModel from 'apply-flow/module/common/ApplyFlowBlockComponentViewModel';
import preferredLocationsFormBuilder from './form/preferredLocationsFormBuilder';

export default class PreferredLocationsViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        const { jobId } = router.routeParams();

        this.application = params.application;

        jobService.getJobPreferredLocations(jobId)
            .then(this._createForm.bind(this))
            .then(this._registerForm.bind(this))
            .catch(err => console.error(err))
            .then(this._setBlockReady.bind(this));
    }

    _createForm(data) {
        const form = preferredLocationsFormBuilder.createForm(
            this.application, data,
        );

        this.form(form);
    }

}