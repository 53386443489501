import AiJobsSearchServiceAbstract from './aiJobsSearchAbstract';
import searchService from 'cx/module/search/service/search';
import { searchResultsAiJobs as searchResultsAiJobsModel } from '../model/searchJobResults';

class SearchServiceJobsToCandidate extends AiJobsSearchServiceAbstract {

    _performSearch(query, requisitionId) {
        return searchService.searchJobsToCandidate(query, requisitionId);
    }

    _handleSearchError(error) {
        super._handleSearchError(error);

        return Promise.reject(error);
    }

}

export const searchJobsToCandidate = new SearchServiceJobsToCandidate({ jobsModel: searchResultsAiJobsModel });
