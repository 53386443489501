import { Typography } from 'minimal/module/search/service/customCss';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';

export const mapTypography = (stringifiedTypography: string | undefined | null): Typography => {
    if (!stringifiedTypography) {
        return EMPTY_TYPOGRAPHY;
    }

    const parsedTypography = JSON.parse(stringifiedTypography) as Record<string, string | undefined | null>;

    return {
        color: parsedTypography.color || null,
        fontFamily: parsedTypography.fontFamily || null,
        fontSize: parsedTypography.fontSize || null,
        fontStyle: parsedTypography.fontStyle || null,
        fontWeight: parsedTypography.fontWeight || null,
        textAlign: parsedTypography.textAlign || null,
        lineHeight: parsedTypography.lineHeight || null,
    };
};
