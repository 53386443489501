import { components } from 'knockout';

import '../job-details-header';
import '../job-details-page';
import '../job-expired';

import { JobDetailsLoaderViewModel } from './JobDetailsLoaderViewModel';
import template from './job-details-loader.html';

components.register('job-details-loader', {
    viewModel: JobDetailsLoaderViewModel,
    template,
});
