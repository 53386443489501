import http from 'core/http/http';
import mapper from '../mapper/preferredCommunicationsChannel';
import tokenService from 'candidate-verification/service/token';

function _performRequest(payload) {
    return http.post('/recruitingCECandidates', payload, {
        statusCodes: {
            400({ response }) {
                return response;
            },
        },
    });
}

function _performRequestNewCandidate(payload) {
    return http.post('/recruitingCEVerificationTokens', payload, {
        statusCodes: {
            400({ response }) {
                return response;
            },
        },
    });
}

export default {
    makeEmailPreferred(id, emailPreferred) {
        const payload = mapper.mapEmailPreferredToRest(id, emailPreferred);

        return _performRequest(payload);
    },

    makePhonePreferred(id, phonePreferred) {
        const payload = mapper.mapPhonePreferredToRest(id, phonePreferred);

        return _performRequest(payload);
    },

    requestPhoneVerification(id, newPhone) {
        const payload = mapper.mapPhoneVerificationToRest(id, newPhone);

        return _performRequest(payload);
    },

    requestEmailVerification(id, email) {
        const payload = mapper.mapEmailVerificationToRest(id, email);

        return _performRequest(payload);
    },

    requestEmailRemoval(id) {
        const payload = mapper.mapEmailRemovalToRest(id);

        return _performRequest(payload);
    },

    requestPhoneRemoval(id) {
        const payload = mapper.mapPhoneRemovalToRest(id);

        return _performRequest(payload);
    },

    changeNewCandidateEmailChannel(email) {
        const { accessCode } = tokenService.get();

        const payload = mapper.mapNewCandidateEmailPreferredToRest(accessCode, email);

        return _performRequestNewCandidate(payload);
    },

    changeNewCandidatePhoneChannel(phone) {
        const { accessCode } = tokenService.get();

        const payload = mapper.mapNewCandidatePhonePreferredToRest(accessCode, phone);

        return _performRequestNewCandidate(payload);
    },
};
