import appConfig from 'app/model/config';
import offerMapper from '../mapper/offer';
import router from 'app/model/router';
import http from 'core/http/http';
import tokenService from 'candidate-verification/service/token';

export const OFFER_STATUS = {
    REFUSED: 'JOBOFFER_REFUSED',
    REJECTED: 'JOBOFFER_REJECTED',
    UNAVAILABLE: 'JOBOFFER_UNAVAILABLE',
};

export default {
    get(accessCode) {
        const config = {
            headers: {
                'Ora-Irc-Access-Code': accessCode,
            },
        };

        return http.get('/recruitingCEOfferDetails?expand=all', config)
            .then(offerMapper.mapCollectionFromRest.bind(offerMapper));
    },

    getOfferDataContent({ offerId, format }) {
        return http.get(`/CandExpSecured/previewOfferDetails/${offerId}?format=:format:`, {
            baseUrl: `${appConfig.uiBaseUrl}`,
            params: {
                format,
            },
            timeout: 600000,
            statusCodes: {
                400() {
                    return Promise.reject();
                },
            },
            credentials: 'same-origin',
        }).then(offerMapper.fromRest);
    },

    _respond(accessCode, payload) {
        return http.post('/CandidateExperience/OfferResponsesAuth', payload, {
            baseUrl: `${appConfig.uiBaseUrl}`,
            statusCodes: {
                400({ response }) {
                    if (response === 'ORA_IRC_ESIGN_INVALID') {
                        return Promise.reject('invalid-esign');
                    }

                    return Promise.reject();
                },
                404() {
                    router.go('offer-inactive');
                },
            },
            headers: {
                'Ora-Irc-Access-Code': accessCode,
            },
        });
    },

    accept(accessCode, name) {
        return this._respond(accessCode, {
            accessCode,
            action: 'ACCEPTED',
            signature: name,
        });
    },

    refuse(accessCode, reasonId, comment) {
        return this._respond(accessCode, {
            accessCode,
            action: 'REFUSED',
            reasonId,
            comment,
        });
    },

    calculateOffer() {
        return this.get(tokenService.get().accessCode);
    },

    _isTokenFromRouteValid(token) {
        return Boolean(token);
    },
};