import Router from 'core/router/Router';
import appConfig from 'app/model/config';

export default class VanityRouter extends Router {

    constructor(name) {
        super(name);
        this.name = name;
    }

    _getUrl() {
        const [, ceUrl] = window.location.href.split('#');

        return ceUrl
            ? ceUrl.replace(this._getLangSiteUrlPattern(), '')
            : '';
    }

    _setUrl(url) {
        if (url.includes('//')) {
            window.location.assign(url);
        } else {
            window.history.pushState({}, '', this._newUrl(url));
        }
    }

    _newUrl(url) {
        const siteUrl = `#${this._getLangSiteUrlPattern()}`;

        return siteUrl.concat(url);
    }

    _getLangSiteUrlPattern() {
        const { siteLang, siteNumber, siteURLName: siteUrlName } = appConfig;
        const isSiteUrlName = siteUrlName && document.baseURI.includes(`/sites/${siteUrlName}`);
        const siteUrlKey = isSiteUrlName ? siteUrlName : siteNumber;

        return `${siteLang}/sites/${siteUrlKey}`;
    }

    redirect(url) {
        window.location.href = url;

        if (!url.startsWith(appConfig.vanityBaseUrl)) {
            return;
        }

        window.location.reload(true);
    }

}