import router from 'app/model/router';
import httpLangCached from 'cx/service/http/httpLangCached';
import metadataMapper from 'apply-flow/module/regulatory/mapper/metadata';
import urlEncoder from 'app/module/core/router/urlEncoder';

export default class AbstractRegulatoryMetadata {

    constructor(serviceUrl) {
        this._serviceUrl = serviceUrl.replace('<BLOCK_CODE>', this.getBlockCode());
    }

    getBlockCode() {
        throw new Error('not implemented');
    }

    getServiceConfig() {
        return {};
    }

    _callService(requisitionNumber) {
        const url = this._serviceUrl.replace('<REQ_NUM>', `%22${urlEncoder.encodeQueryParams(requisitionNumber)}%22`);

        return httpLangCached.get(url, Object.assign({
            statusCodes: {
                404() {
                    return Promise.resolve({
                        items: [],
                    });
                },
            },
        }, this.getServiceConfig()),
        ).then(metadataMapper.reformatFormElementsFromRest.bind(metadataMapper))
            .then(metadataMapper.mapFormElementsFromRest.bind(metadataMapper));
    }

    getFormElements(requisitionNumber) {
        return this._callService(requisitionNumber)
            .then(formElements => formElements.shift());
    }

    isEmpty() {
        const requisitionNumber = router.routeParams().jobId;

        return this._callService(requisitionNumber)
            .then(formElements => !formElements.length);
    }

}