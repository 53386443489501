import appConfig from 'app/model/config';
import { PrimaryFacetName } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { mapFacetNameToType } from '../../search-filters-horizontal/service/mapFacetNameToType';
import { DEFAULT_FACETS_SETTINGS } from 'app/module/admin/module/site-editor/mapper/facets';

export const isAnyPrimaryFacetEnabled = (): boolean => {
    return (DEFAULT_FACETS_SETTINGS.FACET_ORDER.split(',') as PrimaryFacetName[]).some(
        (facetName: PrimaryFacetName) => {
            const mappedName = mapFacetNameToType(facetName);

            return appConfig.getSettingByKey(`${mappedName}_FACET_ENABLED`) === 'true';
        }
    );
};
