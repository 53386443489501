import Signal from 'signals';

export default {
    brandUpdated: new Signal(),
    stylesUpdated: new Signal(),
    welcomeTextUpdated: new Signal(),
    customHeaderUpdated: new Signal(),
    customHeaderHtmlUpdated: new Signal(),
    customFooterUpdated: new Signal(),
    templateFooterUpdated: new Signal(),
    customCssUpdated: new Signal(),
    customJsUpdated: new Signal(),
    customFontsUpdated: new Signal(),
    themeSettingsUpdated: new Signal(),
    logoImageUrl: new Signal(),
    mobileLogoImageUrl: new Signal(),
    frontImageUrl: new Signal(),
    customHeaderSettingsUpdated: new Signal(),
};
