import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import employeeConfig from 'app/model/employeeConfig';

router.configure({
    'event-preview': {
        parent: 'search-events',
        url: '/preview/{eventId}',
        title: i18n('search.events-page-title'),

        view: {
            main: 'search',
            dialogs: 'event-details',
        },
    },
    'event-details': {
        parent: 'minimal',
        url: '/events/{eventId}:?query:',
        title: i18n('search.events-page-title'),
        view: {
            main: 'event-details-fullscreen',
            dialogs: null,
        },
    },
    'admin-event-preview': {
        parent: 'minimal',
        url: 'admin/events/preview/{eventId}',
        title: i18n('search.events-page-title'),

        view: {
            main: 'event-details',
            dialogs: null,
        },

        canEnter() {
            return employeeConfig.init();
        },
        enter() {
            document.body.classList.add('admin-job-preview');
        },
    },
    'event-details-with-token': {
        parent: 'minimal',
        url: '/events/{eventId}/{token}:?query:',
        title: i18n('search.events-page-title'),
        view: {
            main: 'event-details-fullscreen',
            dialogs: null,
        },
    },
});
