import { Observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import { RecommendedJobsWidgetViewModel, Props } from '../../RecommendedJobsWidgetViewModel';
import { RecommendedJobsWidgetParams } from '../../config/types';
import { shouldDisplaySuccessMessageSettings } from 'app/service/areRecommendedJobsEnabled';

export class RecommendedJobsWidgetStaticViewModel extends RecommendedJobsWidgetViewModel {
    recommendedJobsParams: {
        id: string | undefined;
        params: RecommendedJobsWidgetParams | undefined;
        adminMode: boolean;
        lang?: Observable<string>;
    };

    shouldDisplaySuccessMessage: boolean;

    constructor(props: Props) {
        super(props);

        this.shouldDisplaySuccessMessage = shouldDisplaySuccessMessageSettings(props.pageType);

        this.recommendedJobsParams = {
            id: props.id,
            params: props.params,
            adminMode: true,
            lang: props.lang,
        };

        this.fileName('Resume.doc');

        this.errorMessage(
            i18n('validators.file-upload.invalid-file-type', {
                allowed_extensions: '.doc, .docx, .pdf, .txt',
            })
        );
    }

    addFile(): Promise<void> {
        return Promise.resolve();
    }

    handleDrop(): void {
        return;
    }
}
