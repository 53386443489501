import http from 'core/http/http';
import { mapExtraInformationToRest, mapExtraInformationFromRest } from '../mapper/extraInformation';
import synchronization, { COLLECTIONS } from 'apply-flow/service/synchronization';

function saveContext(savePromise, contextData) {
    return savePromise.then(() => http.post('/recruitingCECandidates', contextData));
}

export function getExtraInformation(candidateId, blockContextName) {
    const restUrl = `/recruitingCECandidates/${candidateId}/child/candidateExtraInformation?finder=findByContextName;ContextName=${blockContextName}&expand=dataRows.attributes&onlyData=true`;

    return http.get(restUrl)
        .then(candidateExtraInformation => mapExtraInformationFromRest(candidateExtraInformation));
}

export function saveExtraInformation(candidate, extraInformationModel) {
    if (!extraInformationModel.length) {
        return Promise.resolve();
    }

    synchronization.remove(extraInformationModel, COLLECTIONS.EFF);

    const saveData = mapExtraInformationToRest(candidate.id(), extraInformationModel);

    return saveData.reduce(saveContext, Promise.resolve());
}