import { ObservableArray, Observable } from 'knockout';

export type WorkplaceTypeSettingModel = {
    isWorkplaceTypeOnsite: boolean;
    isWorkplaceTypeRemote: boolean;
    isWorkplaceTypeHybrid: boolean;
};

export type WorkplaceTypeKeys =
    | 'WORKPLACE_TYPE_ONSITE_ENABLED'
    | 'WORKPLACE_TYPE_REMOTE_ENABLED'
    | 'WORKPLACE_TYPE_HYBRID_ENABLED';

export type WorkplaceTypeEnabledSettings = Record<WorkplaceTypeKeys, boolean>;

export type WorkplaceTypeSiteModel = Record<keyof WorkplaceTypeSettingModel, Observable<boolean>>;

export type WorkplaceTypeSiteShape = {
    workplaceType: WorkplaceTypeSiteModel;
};

export const workplaceTypeSettingsMap: Record<WorkplaceTypeKeys, keyof WorkplaceTypeSettingModel> = {
    WORKPLACE_TYPE_ONSITE_ENABLED: 'isWorkplaceTypeOnsite',
    WORKPLACE_TYPE_REMOTE_ENABLED: 'isWorkplaceTypeRemote',
    WORKPLACE_TYPE_HYBRID_ENABLED: 'isWorkplaceTypeHybrid',
} as const;

export type DffContext = {
    code: string;
    name: string;
};

export type DffSegment = {
    name: string;
    definition: DffSegmentDefinition;
};

export type DffSegmentDefinition = {
    code: string;
    columnName: string;
    attributeName: string;
    contextCode: string;
};

export type DffSegmentOption = {
    value: DffSegmentDefinitionOption;
    label: Observable<string>;
};

export type DffSegmentDefinitionOption = {
    code: Observable<string>;
    columnName: Observable<string>;
    attributeName: Observable<string>;
    contextCode: Observable<string>;
};

export type DffSegmentValue = {
    code: string;
    name: string;
};

export type DffContextResultFromRest = {
    applicationId: number;
    descriptiveFlexfieldCode: string;
    contextCode: string;
    name: string;
    language: string;
    enabledFlag: boolean | null;
};

export type DffSegmentResultFromRest = {
    applicationId: number;
    segmentCode: string;
    segmentName: string;
    columnName: string;
    contextCode: string;
    lovFieldFlag: boolean;
    segmentAttributeName: string;
};

export type DffSegmentValueResultFromRest = {
    applicationId: number;
    code: string;
    description: string;
    contextCode: string;
    segmentAttributeName: string;
};

export type DffFilterResultFromRest = {
    dimensionKey: string;
    dimensionTypeCode: string;
    dffid: string;
    contextCode: string;
    segmentCode: string;
    segmentName: string;
    segmentAttributeName: string;
    columnName: string;
};

export type DffFiltersModel = ObservableArray<DffFiltersModelEntry>;

export type DffFiltersModelEntry = {
    filterDefinition: DffFilterDefinition;
    selectedFilters: ObservableArray<DffFilter>;
};

export type DffFilterStoreActionModel = {
    filterDefinition: DffFilterDefinition;
    filter: DffFilter;
};

export type DffFilterDefinition = {
    contextCode: Observable<string>;
    segmentCode: Observable<string>;
    segmentName: Observable<string>;
    columnName: Observable<string>;
    attributeName: Observable<string>;
};

export type DffFilterToRest = {
    dimensionTypeCode: string;
    dFFId: string;
    segmentCode: string;
    columnName: string;
    contextCode: string;
};

export type DffFilter = {
    id: Observable<string>;
    value: Observable<string>;
    label: Observable<string>;
};
