export type RestParam = { paramKey: string; paramValue: string | null | undefined };

export const mapParamToRest = (paramKey: string, paramValue: unknown): RestParam => {
    if (paramValue === null || paramValue === undefined) {
        return { paramKey, paramValue: null };
    }

    const mappedParamValue: string =
        typeof paramValue === 'object' ? JSON.stringify(paramValue) : String(paramValue);

    return { paramKey, paramValue: mappedParamValue };
};

export const castParamType = (param: string | null): string | boolean | null => {
    if (param === 'false' || param === 'true') {
        return param === 'true';
    }

    return param;
};
