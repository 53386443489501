import FormValidator from 'core/form/validator/Validator';

export default class AtLeastOneSelected extends FormValidator {

    constructor(options) {
        super(options);

        this._message = options.message;
    }

    _validate(value, { preferenceValues }) {
        const selected = preferenceValues.reduce((concatenatedValue, valueToConcat) =>
            concatenatedValue.concat(valueToConcat()),
        '');

        return Boolean(selected.length);
    }

}