import mapping from 'knockout-mapping';


const addressPrototype = {
    country: undefined,
    region1: undefined,
    region2: undefined,
    region3: undefined,
    city: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    addressLine3: undefined,
    addressLine4: undefined,
    addressLine5: undefined,
    postalCode: undefined,
    longPostalCode: undefined,
    building: undefined,
    floorNumber: undefined,
    addlAddressAttribute1: undefined,
    addlAddressAttribute2: undefined,
    addlAddressAttribute3: undefined,
    addlAddressAttribute4: undefined,
    addlAddressAttribute5: undefined,
};

function Address(data) {
    mapping.fromJS(addressPrototype, {}, this);

    if (data) {
        mapping.fromJS(data, {}, this);
    }
}

export default Address;