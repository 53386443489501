import {
    getSelectedSortOption,
    getSortingOptions,
} from 'minimal/module/search/component/search-sorting-selector/service/searchSortOptionsService';
import { jobsSortOptions } from 'search/model/jobsSorting';
import { SortOption } from 'search/config/types';

export const getJobsSortingOptions = ({ query }: Record<string, unknown>): SortOption[] => {
    return getSortingOptions(jobsSortOptions, { query });
};

export const getJobsSelectedSortOption = (sortBy: string): SortOption | null => {
    return getSelectedSortOption(jobsSortOptions, sortBy);
};
