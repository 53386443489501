import { observable } from 'knockout';
import router from 'app/model/router';
import CsConfirmDialogViewModel from 'cx/module/candidate-self-service/component/cs-confirm-dialog/CsConfirmDialogViewModel';
import { FILL_IN } from 'candidate-verification/config/pinVerificationStatus';
import challengeService from 'candidate-verification/service/challenge';
import historyService from 'app/module/ce-common/service/historyService';
import userTracking from 'app/module/cx/service/userTracking';

export default class CsSignInViewModel extends CsConfirmDialogViewModel {

    constructor() {
        const isVisible = observable(true);

        super({ isVisible });
    }

    _showPinChallenge() {
        challengeService.open(this.challenge);
    }

    onPinValid() {
        router.go('candidate-self-service');
    }

    onAttempsLimitReached() {
        this.verificationStatus(FILL_IN);

        return router.go('home-page');
    }

    _closeDialog() {
        router.go('home-page');
    }

    back() {
        userTracking.trackJobAppEntryCancel(router.routeParams().jobId, router.route().id);

        historyService.moveBackOrFallbackTo('home-page');
    }

}
