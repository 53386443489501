import { Job } from '../config/types';

export const groupByAddress = (array: Job[]): Record<string, Job[]> => {
    return array.reduce((result: { [key: string]: Job[] }, currentValue) => {
        (result[currentValue['address']] = result[currentValue['address']] || []).push(currentValue);

        return result;
    }, {} as Record<string, Job[]>);
};

export const lowercaseKeys = (record: Record<string, unknown>): Record<string, unknown> => {
    return Object.keys(record).reduce((accumulator, key) => {
        const lowercaseKey = key[0].toLowerCase() + key.slice(1);

        accumulator[lowercaseKey] = record[key];

        return accumulator;
    }, {} as Record<string, unknown>);
};
