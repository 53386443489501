const locale = {
    'ar-ly': {
        unitOfLength: 'miles',
        unitOfLengthShort: 'MI',
    },
    'en-bz': {
        unitOfLength: 'miles',
        unitOfLengthShort: 'MI',
    },
    'en-gb': {
        unitOfLength: 'miles',
        unitOfLengthShort: 'MI',
    },
    'en-us': {
        unitOfLength: 'miles',
        unitOfLengthShort: 'MI',
    },
    'es-do': {
        unitOfLength: 'miles',
        unitOfLengthShort: 'MI',
    },
};

export default {
    locale,

    get(languageCode) {
        return locale[languageCode];
    },

    getShort(languageCode) {
        const unit = this.get(languageCode);

        return unit && unit.unitOfLengthShort;
    },
};