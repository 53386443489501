import 'offer/config/routing';
import 'minimal/module/offer/component/offer-message/component';
import 'offer/component/offer-content/config/module';
import 'minimal/module/offer/component/e-signature/component';
import 'minimal/module/offer/component/offer-refusal/component';

import { components } from 'knockout';

import ViewModel from 'offer/OfferViewModel';
import template from '../offer.html';

components.register('offer', { viewModel: ViewModel, template });
