import { FunctionComponent } from 'preact';
import { useRef } from 'preact/hooks';
import { useKnockoutBindings } from 'core/react/hooks/useKnockoutBindings';
import { Job } from 'minimal/module/job-details/types';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { PureComputed } from 'knockout';
import { JobInfoSeparator } from 'search/config/types';

type Props = {
    job: Job;
    distanceInfo: DistanceInfo;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
};

export const SearchResultItemJobInfoDistance: FunctionComponent<Props> = ({
    job,
    distanceInfo,
    jobInfoSeparator,
}) => {
    const { id: itemId } = job;

    const distanceReference = useRef<HTMLDivElement | null>(null);

    useKnockoutBindings(distanceReference, {
        distanceInfo,
        itemId,
    });

    return (
        <div className={`job-list-item__job-info-value-container`} ref={distanceReference}>
            <div class="job-list-item__job-info-value">
                <search-results-distance params="distanceInfo: distanceInfo, itemId: itemId"></search-results-distance>
                <div className={`icon-separator-${jobInfoSeparator()}`}></div>
            </div>
        </div>
    );
};
