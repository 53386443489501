import { Observable, PureComputed, pureComputed } from 'knockout';
import { JobDetailsPageData, JobDetailsViewData } from 'minimal/module/job-details/types';
import { notReachable } from 'app/types/utils';
import { JobDetailsContentMessage } from 'minimal/module/job-details/component/job-details-content/JobDetailsContentViewModel';
import { JobDetailsHeaderDisplaySettings } from 'minimal/module/job-details/component/job-details-header-content/JobDetailsHeaderContentViewModel';
import { defaultPageOptions } from 'site-editor/module/job-details-editor/component/job-details-page-options-dialog/config/defaultValues';
import { setAutoLaunchedIndeedFlag } from 'app/service/indeedExperience';
import cxEvents from 'cx/config/events';

export type JobDetailsPageMessage = JobDetailsContentMessage;

type Props = {
    viewData: JobDetailsViewData;
    pageData: PureComputed<JobDetailsPageData>;
};

export class JobDetailsPageViewModel {
    viewData: JobDetailsViewData;
    pageData: PureComputed<JobDetailsPageData>;
    jobId: string;
    lang: Observable<string>;
    headerDisplaySettings: PureComputed<JobDetailsHeaderDisplaySettings>;

    constructor({ viewData, pageData }: Props) {
        this.viewData = viewData;
        this.pageData = pageData;
        this.jobId = this.pageData().job.id;
        this.lang = this.viewData.lang;
        this.headerDisplaySettings = pureComputed(this.computeHeaderDisplaySettings, this);

        this.onMessage = this.onMessage.bind(this);

        if (viewData.viewType === 'fullscreen') {
            cxEvents.loaded.dispatch();

            cxEvents.isHeaderLoaded.dispatch();
        }
    }

    onMessage(message: JobDetailsPageMessage): void {
        switch (message.type) {
            case 'onApplyButtonClicked':
                this.viewData.apply(this.pageData().job);
                break;
            case 'onApplyIndeedButtonClicked':
                this.viewData.applyWithIndeed(this.pageData().job);
                break;
            case 'onApplyIndeedAutoLaunch':
                this.viewData.applyWithIndeed(this.pageData().job);
                setAutoLaunchedIndeedFlag();
                break;
            default:
                return notReachable(message.type);
        }
    }

    computeHeaderDisplaySettings(): JobDetailsHeaderDisplaySettings {
        const customPageParams = this.viewData.customPage()?.params || defaultPageOptions;
        const { iconHiddenFavorite, iconHiddenShare } = customPageParams;

        return {
            iconHiddenFavorite,
            iconHiddenShare,
        };
    }
}
