import ko from 'knockout';
import dictionaryService from 'apply-flow/service/dictionary';
import candidateMapper from 'apply-flow/mapper/candidate';
import matcher from './common';
import flowService from '../../../service/flow';
import metadataConfig from 'apply-flow/config/metadata';
import defaultBlockFinder from 'apply-flow/module/profile-items/service/defaultBlockFinder';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';
import candidateModel from 'apply-flow/model/candidate';
import countryCodesService from 'cx/service/phone/phoneCountryCodes';

function getFlattenedBlocks() {
    return flowService.get().sections
        .reduce((pages, section) => pages.concat(section.pages), [])
        .reduce((blocksVal, page) => blocksVal.concat(page.blocks), []);
}

function getBlock(blockCode) {
    const [block] = getFlattenedBlocks().filter(({ code }) => code === blockCode);

    return block;
}

function getDefaultBlock(blockCode) {
    const [defaultBlock] = getFlattenedBlocks()
        .filter(({ code, blockProperties: { preferredFlag } }) => code === blockCode && preferredFlag);

    return defaultBlock;
}

function getMetadataRepository(block, contentTypeId, sectionId) {
    if (!block || !sectionId) {
        return undefined;
    }

    const MetadataRepository = metadataConfig.getRepositoryForBlock(block.code);
    const metadataServiceUrl = block.code === 'ORA_TIMELINE' ? block.metadataServiceUrl[1] : block.metadataServiceUrl;

    return new MetadataRepository(metadataServiceUrl, contentTypeId, sectionId);
}

function getDictionary(element) {
    if (!element || !element.dictionary) {
        return Promise.resolve();
    }

    return dictionaryService
        .get(element.dictionary)
        .catch((error) => {
            console.warn('Couldn\'t get dictionary data', error);

            return Promise.resolve();
        });
}

function getNameTitlesDictionary() {
    return getDictionary({ dictionary: dictionaryService.CODES.nameTitles });
}

function getFieldDictionaryData(metadataRepository, fieldName) {
    if (!metadataRepository) {
        return Promise.resolve();
    }

    return metadataRepository.getFormElements()
        .then(elements => elements.filter(({ name }) => name === fieldName).shift())
        .then(element => getDictionary(element));
}

function convertItemTextFlagFields(item) {
    for (let i = 1; i <= 5; i++) {
        const key = `itemText30${i}Flag`;

        if (item[key]) {
            item[key] = item[key] === true ? 'Y' : 'N';
        }
    }
}

export function getRatingLevelIds(promiseRatingLevel) {
    return promiseRatingLevel.then(ratingLevels =>
        ratingLevels?.map(ratingLevel => ratingLevel.ratingLevelId));
}

class EducationConverter {

    constructor() {
        const block = getBlock('ORA_TIMELINE') || getDefaultBlock('ORA_EDUCATION');

        this.defaultSectionId = defaultBlockFinder.get(contentTypes.EDUCATION_ID);

        const metadataRepository = getMetadataRepository(block, contentTypes.EDUCATION_ID, this.defaultSectionId);

        this.promiseDegrees = getFieldDictionaryData(metadataRepository, 'contentItemId');
        this.promiseInstitutions = getFieldDictionaryData(metadataRepository, 'educationalEstablishmentId');
    }

    toLocalModel(restEducations) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        return Promise.all([
            this.promiseDegrees, this.promiseInstitutions,
        ]).then(([educationLevels, educationInstitutions]) =>
            restEducations.map((restItem) => {
                const item = ko.utils.extend({}, restItem);

                if (item.contentItemId && educationLevels) {
                    item.contentItemId = matcher.findLookupCodeDegree(item.contentItemId, educationLevels);
                }

                if (item.educationalEstablishment && educationInstitutions) {
                    item.educationalEstablishmentId =
                        matcher.findExactMatchLookupCode(item.educationalEstablishment, educationInstitutions);
                }

                if (!item.countryCode) {
                    item.stateProvinceCode = null;
                }

                item.sectionId = this.defaultSectionId;
                convertItemTextFlagFields(item);

                return item;
            }),
        );
    }

}

class WorkExperienceConverter {

    constructor() {
        this.defaultSectionId = defaultBlockFinder.get(contentTypes.EXPERIENCE_ID);
    }

    toLocalModel(restWorkExperiences) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        const workExperiences = restWorkExperiences.map((restItem) => {
            const item = ko.utils.extend({}, restItem);

            convertItemTextFlagFields(item);

            item.sectionId = this.defaultSectionId;

            return item;
        });

        return Promise.resolve(workExperiences);
    }

}

class LanguageConverter {

    constructor() {
        const block = getDefaultBlock('ORA_LANGUAGE');

        this.defaultSectionId = defaultBlockFinder.get(contentTypes.LANGUAGE_ID);

        const metadataRepository = getMetadataRepository(block, contentTypes.LANGUAGE_ID, this.defaultSectionId);

        this.promiseLanguage = getFieldDictionaryData(metadataRepository, 'contentItemId');
        this.promiseReadingLevelIds = getRatingLevelIds(getFieldDictionaryData(metadataRepository, 'readingLevelId'));
        this.promiseWritingLevelIds = getRatingLevelIds(getFieldDictionaryData(metadataRepository, 'writingLevelId'));
        this.promiseSpeakingLevelIds = getRatingLevelIds(getFieldDictionaryData(metadataRepository, 'speakingLevelId'));
    }

    toLocalModel(restLanguages) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        return this.promiseLanguage.then(languages =>
            restLanguages.map((restItem) => {
                const item = ko.utils.extend({}, restItem);

                if (item.contentItemId && languages) {
                    item.contentItemId = matcher.findLanguageLookupCode(item.contentItemId, languages);
                }

                item.sectionId = this.defaultSectionId;

                this.promiseReadingLevelIds.then((readingLevelIds) => {
                    if (!readingLevelIds.includes(Number(item.readingLevelId))) {
                        item.readingLevelId = null;
                    } else {
                        item.readingLevelId = Number(item.readingLevelId);
                    }
                });

                this.promiseWritingLevelIds.then((writingLevelIds) => {
                    if (!writingLevelIds.includes(Number(item.writingLevelId))) {
                        item.writingLevelId = null;
                    } else {
                        item.writingLevelId = Number(item.writingLevelId);
                    }
                });

                this.promiseSpeakingLevelIds.then((speakingLevelIds) => {
                    if (!speakingLevelIds.includes(Number(item.speakingLevelId))) {
                        item.speakingLevelId = null;
                    } else {
                        item.speakingLevelId = Number(item.speakingLevelId);
                    }
                });

                convertItemTextFlagFields(item);

                return item;
            }),
        );
    }

}

class BasicConverter {

    constructor() {
        this.nameTitlesPromise = getNameTitlesDictionary();
    }

    getTitle(restTitle) {
        return this.nameTitlesPromise.then(nameTitles =>
            (nameTitles && restTitle ? matcher.findLookupCode(restTitle, nameTitles) : restTitle));
    }

}

class LicenseAndCertConverter {

    constructor() {
        const block = getDefaultBlock('ORA_LICENSE_CERTIFICATES');

        this.defaultSectionId = defaultBlockFinder.get(contentTypes.CERTIFICATION_ID);

        const metadataRepository = getMetadataRepository(block, contentTypes.CERTIFICATION_ID, this.defaultSectionId);

        this.licensesPromise = getFieldDictionaryData(metadataRepository, 'contentItemId');
    }

    toLocalModel(restLicensesAndCerts) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        return this.licensesPromise.then(licenses =>
            restLicensesAndCerts.map((restItem) => {
                const item = ko.utils.extend({}, restItem);

                if (item.title && licenses) {
                    item.contentItemId = matcher.findExactMatchLookupCode(item.title, licenses);
                }

                item.sectionId = this.defaultSectionId;
                convertItemTextFlagFields(item);

                return item;
            }),
        );
    }

}

class SkillConverter {

    constructor() {
        this.defaultSectionId = defaultBlockFinder.get(contentTypes.SKILL_ID);
    }

    toLocalModel(restSkills) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        const mappedSkills = restSkills.map((restItem) => {
            const item = Object.assign({}, restItem);

            item.sectionId = this.defaultSectionId;

            return item;
        });

        return Promise.resolve(mappedSkills);
    }

}

class WorkPreferenceConverter {

    constructor() {
        this.defaultSectionId = defaultBlockFinder.get(contentTypes.WORK_PREFERENCE_ID);
    }

    toLocalModel(restWorkPreferences) {
        if (!this.defaultSectionId) {
            return Promise.resolve([]);
        }

        const mappedWorkPreferences = restWorkPreferences.map(restItem => Object.assign(
            {},
            restItem,
            { sectionId: this.defaultSectionId },
        ));

        return Promise.resolve(mappedWorkPreferences);
    }

}

class ProfileImportConverterVerCan {

    constructor() {
        this.educationConverter = new EducationConverter();
        this.languageConverter = new LanguageConverter();
        this.licenseAndCertConverter = new LicenseAndCertConverter();
        this.workExperienceConverter = new WorkExperienceConverter();
        this.skillConverter = new SkillConverter();
        this.workPreferenceConverter = new WorkPreferenceConverter();
        this.basicConverter = new BasicConverter();
    }

    async toLocalModel(restResponse) {
        const [restProfile] = restResponse.items;

        const promiseEducations = this.educationConverter.toLocalModel(restProfile.educations || []);
        const promiseLanguages = this.languageConverter.toLocalModel(restProfile.languages || []);

        const promiseLicensesAndCertifications = this.licenseAndCertConverter.toLocalModel(
            restProfile.licensesAndCertifications || []);

        const promiseWorkExperiences = this.workExperienceConverter.toLocalModel(restProfile.workExperiences || []);
        const promiseSkills = this.skillConverter.toLocalModel(restProfile.skills || []);
        const promiseWorkPreferences = this.workPreferenceConverter.toLocalModel(restProfile.workPreferences || []);
        const promiseTitle = this.basicConverter.getTitle(restProfile.title || '');
        let toSortCountry = false;
        const phoneCountryCode = (restProfile.profileType !== 'rp' && restProfile.mobilePhoneNumber?.startsWith('+')) ? await countryCodesService.extractCountryCode(restProfile.mobilePhoneNumber) : restProfile.mobilePhoneCountryCode;

        if (!phoneCountryCode && (restProfile.profileType === 'rp' && restProfile.mobilePhoneNumber?.startsWith('+'))) {
            restProfile.mobilePhoneCountryCode =
            await countryCodesService.extractCountryCode(restProfile.mobilePhoneNumber);
        }

        const territoryName = restProfile.country;

        if (territoryName) {
            const phoneCountryCodeCheck = await countryCodesService
                .getPhoneCountryCodeByTerritoryCode(territoryName) === phoneCountryCode;

            const territoryCodeCheck = territoryName === await countryCodesService.getTerritoryCode(phoneCountryCode);

            if (phoneCountryCodeCheck || territoryCodeCheck) {
                toSortCountry = true;
            }
        }


        let countryCodeFromTerritory =
        await countryCodesService.getPhoneCountryCodeByTerritoryCode(restProfile.mobilePhoneLegislationCode);

        if (!countryCodeFromTerritory && restProfile.profileType === 'in') {
            countryCodeFromTerritory = await countryCodesService.getPhoneCountryCodeByTerritoryCode(territoryName);
        }

        if (!restProfile.mobilePhoneLegislationCode) {
            if (restProfile.profileType === 'in') {
                restProfile.mobilePhoneCountryCode = (!phoneCountryCode) ? countryCodeFromTerritory : phoneCountryCode;
            }

            if (toSortCountry && restProfile.mobilePhoneCountryCode === '1') {
                restProfile.mobilePhoneLegislationCode = territoryName;
            } else if (territoryName) {
                const phoneCodeCheck = await countryCodesService
                    .getPhoneCountryCodeByTerritoryCode(territoryName) === restProfile.mobilePhoneCountryCode;

                if (phoneCodeCheck) {
                    restProfile.mobilePhoneLegislationCode = territoryName;
                }
            }

            if (!restProfile.mobilePhoneLegislationCode) {
                restProfile.mobilePhoneLegislationCode =
            await countryCodesService.getTerritoryCodeByPhoneCountryCode(toSortCountry,
                restProfile.mobilePhoneCountryCode);

                if (!restProfile.mobilePhoneLegislationCode) {
                    restProfile.mobilePhoneLegislationCode = territoryName;
                }
            }
        }

        return Promise.all([
            promiseEducations,
            promiseLanguages,
            promiseLicensesAndCertifications,
            promiseWorkExperiences,
            promiseSkills,
            promiseWorkPreferences,
            promiseTitle,
        ]).then(([educations, languages, licensesAndCertifications, workExperiences, skills, workPreferences,
            title]) => {
            delete restProfile.profileId;
            delete restProfile.providerToken;
            delete restProfile.attachmentId;
            restProfile.title = title;
            restProfile.educations = educations;
            restProfile.languages = languages;
            restProfile.licensesAndCertifications = licensesAndCertifications;
            restProfile.workExperiences = workExperiences;
            restProfile.skills = skills;
            restProfile.workPreferences = workPreferences;
            restProfile.cwkCandidateFlag = candidateModel?.basicInformation?.cwkCandidate();

            if (restProfile.profileType === 'in') {
                restProfile.mobilePhoneNumber = countryCodesService.getPhoneNumber(restProfile);
            }

            if (!restProfile.mobilePhoneNumber) {
                restProfile.mobilePhoneCountryCode = '';
            }

            delete restProfile.profileType;

            return candidateMapper.mapCandidateFromImport(restProfile);
        }).catch((error) => {
            console.error('Couldn\'t convert imported profile');

            return Promise.reject(error);
        });
    }

    toLocalAttachment(restAttachment) {
        return {
            id: restAttachment.attachedDocumentId,
            type: 'document',
            attachment: restAttachment.Title,
            category: restAttachment.CategoryName,
            fileContents: restAttachment.FileContents,
        };
    }

    toLocalReferralAttachment(restAttachment) {
        return {
            sourceAttachmentId: restAttachment.sourceAttachmentId,
            type: 'document',
            attachment: restAttachment.fileName,
            category: restAttachment.categoryId,
            fileContents: restAttachment.fileContents,
            isReferral: true,
        };
    }

}

let instance;

export default {
    getInstance() {
        if (!instance) {
            instance = new ProfileImportConverterVerCan();

            return instance;
        }

        return instance;
    },
};
