import i18n from 'core/i18n/i18n';
import extraInformationTileFormBuilder from 'cx/module/apply-flow/module/extra-information/form/extraInformationTileFormBuilder';
import ApplyFlowTileFromViewModel from '../../../../component/apply-flow-tile-form/ApplyFlowTileFormViewModel';

export default class ExtraInformationTileFormViewModel extends ApplyFlowTileFromViewModel {

    constructor(params) {
        super(params);

        this.addButtonLabel = `${i18n('apply-flow.profile-item.add-item-button')} ${params.title}`;
        this.formBuilder = extraInformationTileFormBuilder;
        this.formElements = this.model.formElements;

        this.formConfig = {
            elements: this.formElements,
            existingRows: this.model.rows,
        };

        this._createFormsFromModel();
    }

    addNewForm() {
        const model = this.model.addRow();

        super.addNewForm(model.attributes, this.formConfig);
    }

    _createFormsFromModel() {
        this.model.rows().forEach(row => this.insertForm(row.attributes, this.formConfig));
        this.setBlockReady();
    }

}