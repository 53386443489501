import { components } from 'knockout';
import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/profile-items/service/Metadata';

import 'apply-flow/module/skill/component/skill-recommendation/component';

import ViewModel from 'apply-flow/module/skill/SkillViewModel';
import template from 'apply-flow/module/skill/skill.html';
import tileTemplate from '../../tile-profile-items/tile-profile-items.html';

components.register('skill', { viewModel: ViewModel, template });
components.register('skill-items-tile', { template: tileTemplate });

metadataConfig.registerBlock('ORA_SKILL', {
    component: 'skill',
    repository: metadataRepository,
    requireBlockProperties: true,
});