import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/profile-items/service/Metadata';
import '../component/education-form/component';
import '../extender/isoStringToDate';

metadataConfig.registerBlock('ORA_EDUCATION', {
    component: 'tile-profile-items',
    repository: metadataRepository,
    requireBlockProperties: true,
});

