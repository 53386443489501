import { observable } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import interviewSchedulingService from '../../service/interviewScheduling';
import cxEvents from 'cx/config/events';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import applicationService from '../../../../../apply-flow/service/application';
import CandidateChallengeAbstractViewModel from '../../../../../candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import challengeService from 'candidate-verification/service/challenge';
import interviewSchedulingEvents from 'candidate-self-service/module/interview-scheduling/config/events';

export default class SelfScheduleViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.model = observable();
        this.submissionId = observable();
        this.requestId = observable();
        this.loaded = observable(false);
        this.emptySchedule = observable(false);
        this.emptyRealTimeSlots = observable(false);
        this.preScheduleDetails = observable();
        this.isPinRequired = observable();
        this.action = null;
        this.showRequestSlotsDialog = observable(false);

        this._initialize();
    }

    onPinValid() {
        this.isPinRequired(false);
        this._initialize();
    }

    async _initialize() {
        if (challengeService.isChallengeRequired()) {
            await this._triggerChallenge()
                .then((challengeRequired) => {
                    this.isPinRequired(challengeRequired);
                })
                .catch((error) => {
                    this._handleError(error);

                    this.isPinRequired(challengeService.isChallengeRequired());
                });

            if (this.isPinRequired()) {
                cxEvents.loaded.dispatch();

                return;
            }
        }

        this._verifyToken()
            .then(() => this._verifySubmission())
            .then(() => this._load())
            .catch(error => this._handleError(error))
            .then(() => cxEvents.loaded.dispatch());
    }

    _verifySubmission() {
        if (!this.submissionId()) {
            const { submissionId } = router.routeParams();

            if (submissionId) {
                this.submissionId(submissionId);
            }
        }

        return applicationService.getSubmission(this.submissionId())
            .catch(() =>
                Promise.reject('inactive-submission'));
    }

    _load() {
        this.loaded(false);
        this.model(null);

        const { requestId, id, submissionId, action } = router.routeParams();

        if (requestId) {
            this.requestId(requestId);
        }

        if (submissionId) {
            this.submissionId(submissionId);
        }

        if (action) {
            this.action = action;
        }

        return interviewSchedulingService.getInterviewScheduleDetail({
            requestId: this.requestId(),
            scheduleId: id,
            submissionId: this.submissionId(),
            action: this.action,
        })
            .then(model => this.model(model))
            .then(() => interviewSchedulingService.getPreScheduleDetail(this.model().scheduleId))
            .then((value) => {
                this.preScheduleDetails(value);
                this.emptySchedule(this.model() && this.model().interviewGroups().length === 0);
                this.emptyRealTimeSlots(this.model()?.autoCalendarSlot === 'Y' && !this.model()?.interviewGroups().length);
            })
            .catch(() => this.emptySchedule(true))
            .then(() => this.loaded(true));
    }

    _verifyToken() {
        let token = null;

        if (tokenService.accessCodeExists() && this._submissionIdExists()) {
            token = Promise.resolve(tokenService.get());
        } else {
            token = tokenService.verifyToken(router.routeParams().token);
        }

        return token.then((t) => {
            if (t.submissionId) {
                this.submissionId(t.submissionId);
            }

            if (t.interviewRequestId) {
                this.requestId(t.interviewRequestId);
            }

            if (t.action) {
                this.action = t.action;
            }
        });
    }

    _handleError(error) {
        if (error === 'inactive-submission') {
            notificationsService.error(i18n('interview-scheduling.self-schedule.messages.inactive-submission'));
        } else {
            super._handleError(error);
        }
    }

    _submissionIdExists() {
        return tokenService.get()?.submissionId || router.routeParams().submissionId;
    }

    openRequestSlotsDialog() {
        interviewSchedulingEvents.showRequestSlotsDialog.dispatch();
    }

}
