import contentTypes from '../enum/contentTypes';

export const PROFILE_ITEM_SERVICE = {
    [contentTypes.CERTIFICATION_ID]: {
        restEndpoint: '/recruitingCECandidateCertificationItems',
        primaryKey: 'certificationId',
    },
    [contentTypes.EDUCATION_ID]: {
        restEndpoint: '/recruitingCECandidateEducationItems',
        primaryKey: 'educationId',
    },
    [contentTypes.LANGUAGE_ID]: {
        restEndpoint: '/recruitingCECandidateLanguageItems',
        primaryKey: 'languageId',
    },
    [contentTypes.WORK_PREFERENCE_ID]: {
        restEndpoint: '/recruitingCECandidateWorkPreferences',
        primaryKey: 'workPreferenceId',
    },
    [contentTypes.EXPERIENCE_ID]: {
        restEndpoint: '/recruitingCECandidatePreviousExpItems',
        primaryKey: 'previousEmploymentId',
    },
    [contentTypes.SKILL_ID]: {
        restEndpoint: '/recruitingCECandidateSkillItems',
        primaryKey: 'skillId',
    },
};