import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import i18n from 'app/module/core/i18n/i18n';
import { searchRouteMap } from 'app/module/cx/module/search/config/types';

const SUGGESTION_LEVEL = {
    1: 'country',
    2: 'state',
    3: 'city',
};

export default {
    mapLocationParamToRest(location) {
        return (location || '').replace(/,/g, ' >');
    },

    mapLocationSuggestionsFromRest(restSuggestions) {
        const isElasticSearchEnabled = mapTrueFalseStringToBoolean(appConfig.getSettingByKey('IRC_ELASTIC_SEARCH_ENABLED'));

        if (!isElasticSearchEnabled) {
            restSuggestions.items = restSuggestions.items.sort((x, y) => x.name.toUpperCase()
                .localeCompare(y.name.toUpperCase()));
        }

        return this._mapSuggestionsFromRest(restSuggestions, this._mapLocationFromRest);
    },

    mapJobsKeywordSuggestionsFromRest(restSuggestions) {
        return this._mapSuggestionsFromRest(restSuggestions, this._mapJobsKeywordFromRest);
    },

    mapEventsKeywordSuggestionsFromRest(restSuggestions) {
        return this._mapSuggestionsFromRest(restSuggestions, this._mapEventsKeywordFromRest);
    },

    _mapSuggestionsFromRest(restSuggestions, mapper) {
        return restSuggestions.items.map(mapper);
    },

    _mapEventsKeywordFromRest(keywordSuggestion) {
        return {
            label: keywordSuggestion.name,
            category: i18n('search.events'),
            routing: searchRouteMap.events,
        };
    },

    _mapJobsKeywordFromRest(keywordSuggestion) {
        return {
            label: keywordSuggestion.name,
            category: i18n('search.jobs'),
            routing: searchRouteMap.jobs,
        };
    },

    _mapLocationFromRest(locationSuggestion) {
        return {
            label: locationSuggestion.name.replace(/ > /g, ', '),
            level: SUGGESTION_LEVEL[locationSuggestion.level],
            locationId: locationSuggestion.id,
        };
    },
};