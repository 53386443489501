import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    action: 'CREATE',
    flowVersionId: null,
    sectionId: null,
    contentTypeId: null,
    contentItemId: null,
    workPreferenceId: null,
    natTravelFlag: null,
    natTravelFrequency: null,
    natTravelRequiredFlag: null,
    intlTravelFlag: null,
    intlTravelFrequency: null,
    intlTravelRequiredFlag: null,
    relocateFlag: null,
    allLocationsFlag: null,
    preferredLocationId1: null,
    preferredLocationId2: null,
    preferredLocationId3: null,
    preferredLocationId4: null,
    excludedLocationId1: null,
    excludedLocationId2: null,
    excludedLocationId3: null,
    excludedLocationId4: null,
    relocateDuration: null,
    relocationReason: null,
    comments: null,
    tempAssignFlag: null,
    partAssignFlag: null,
    flexibleWorkFlag: null,
    minimumPay: null,
    currencyCode: null,
    payFrequency: null,
    payRange: null,
    fullTimeEquivalent: null,
    workDays: null,
    workHours: null,
    workMonths: null,
    workYears: null,
};

export default class CandidateWorkPreferences extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'workPreferenceId');
    }

}
