import {
    STYLES_SEPARATOR,
    getStylingBoxCss,
    getTypographyCss,
} from 'minimal/module/search/service/customCss';
import { getCookiecategorySelectors } from './selectors';
import { CookieConsentModalCategoriesParams } from './types';

export const getCustomStyles = (params: CookieConsentModalCategoriesParams, wrapperClass: string): string => {
    const cookieCategorySelectors = getCookiecategorySelectors(wrapperClass);

    const customStyles = Object.keys(params).map((key) => {
        switch (key) {
            case 'categoriesTypography':
                return getTypographyCss({
                    selector: cookieCategorySelectors.categoryTitle,
                    typography: params[key],
                });
            case 'alwaysActiveTypography':
                return getTypographyCss({
                    selector: cookieCategorySelectors.alwaysActiveText,
                    typography: params[key],
                });
            case 'categoryDescriptionTypography':
                return getTypographyCss({
                    selector: cookieCategorySelectors.categoryDescription,
                    typography: params[key],
                });
            case 'offTypography':
                return getTypographyCss({
                    selector: cookieCategorySelectors.offButtonText,
                    typography: params[key],
                });
            case 'onTypography':
                return getTypographyCss({
                    selector: cookieCategorySelectors.onButtonText,
                    typography: params[key],
                });
            case 'overallStyling':
                return getStylingBoxCss({
                    selector: cookieCategorySelectors.categoriesContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'toggleOffStyling':
                return getStylingBoxCss({
                    selector: cookieCategorySelectors.toggleOffButton,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'toggleOnStyling':
                return getStylingBoxCss({
                    selector: cookieCategorySelectors.toggleOnButton,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });
            case 'commonParams':
                return '';

            default:
                return '';
        }
    });

    return customStyles.filter((style) => style?.trim().length).join(STYLES_SEPARATOR);
};
