import { components } from 'knockout';

import './search-event-result-item-header';

import ViewModel from './SearchEventResultItemViewModel';
import templateListItem from './search-event-result-list-item.html';
import templateGridItem from './search-event-result-grid-item.html';

components.register('search-event-result-list-item', {
    viewModel: ViewModel,
    template: templateListItem,
});

components.register('search-event-result-grid-item', {
    viewModel: ViewModel,
    template: templateGridItem,
});
