import Signal from 'signals';

export default {
    show: new Signal(),
    hide: new Signal(),
    toggleFields: new Signal(),
    userConfirmed: new Signal(),
    challengeRequired: new Signal(),
    submitFailed: new Signal(),
    updateFailed: new Signal(),
};
