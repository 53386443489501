import dateOfBirthFormBuilder from './service/dateOfBirthFormBuilder';
import piiFormBuilder from './service/piiFormBuilder';
import TileProfileItemsViewModel from '../tile-profile-items/TileProfileItemsViewModel';
import ko from 'knockout';
import sectionState from 'apply-flow/model/sectionState';
import synchronization, { COLLECTIONS } from 'apply-flow/service/synchronization';
import PersonalIdentifyingInformationModel from './model/PersonalIdentifyingInformation';
import candidateModel from 'apply-flow/model/candidate';
import personalIdentifyingInformation from './service/personalIdentifyingInformation';
import { DATE_OF_BIRTH } from './config/personalIdentifyingInformationFieldMap';
import MetadataService from './service/Metadata';
import sectionValidator from 'apply-flow/model/sectionValidator';
import geolocationService from '../../../../service/geolocation/geolocation';
import piiItemsValidator from './service/piiItemsValidation';
import applyFlowEvents from '../../config/events';
import router from 'app/model/router';
import cachedJob from 'cx/module/apply-flow/service/cachedJob';

export default class PersonalIdentifyingInformationViewModel extends TileProfileItemsViewModel {

    constructor({ section, code, blockId, metadataServiceUrl, blockProperties }) {
        super({ section, code, blockId, metadataServiceUrl });
        this.blockProperties = blockProperties;
        this._processBlockProperties();

        this.profileItems = this._getCandidateProfileItems();
        this.dateOfBirthFormElement = undefined;
        this.form = ko.observable();

        this.hideNewFormsDuringValidation = ko.observable(false);
        this.hiddenNIDForm = undefined;
        this.isEditMode = ko.pureComputed(() => this._isEditMode());

        this.isValid = ko.pureComputed(() => {
            if (this.blockProperties.isNIDRequired) {
                return this.forms().length >= 1 && !this.hideNewFormsDuringValidation();
            }

            return true;
        });

        this.showForm = ko.pureComputed(() => {
            if (this.blockProperties.isNIDRequired) {
                return !this.hideNewFormsDuringValidation();
            }

            return true;
        });

        this._submitFailureEventHandler = applyFlowEvents.refresh.add(this._getPiiData.bind(this));

        section.addBeforeValidationCallback(() => this._addProfileItemOnValidation());
        section.addBeforeValidationCallback(() => this._addBeforeSubmitServiceCallValidation());

        this._setGeolocationPreferredCountry()
            .catch(() => this._setJobPrefferedCountry());

        this._initCustomJsValidation();
    }

    _hideAddButton() {
        return !this.blockProperties.isNIDVisible;
    }

    _hasSameSectionId() {
        return true;
    }

    _processBlockProperties() {
        this.blockProperties.isNIDRequired = (this.blockProperties.isNIDRequired === 'true');
        this.blockProperties.isDOBRequired = (this.blockProperties.isDOBRequired === 'true');
        this.blockProperties.isNIDVisible = (this.blockProperties.isNIDVisible === 'true');
        this.blockProperties.isDOBVisible = (this.blockProperties.isDOBVisible === 'true');
    }

    _getPiiData() {
        const cachedPiiData = synchronization.unshiftAll(COLLECTIONS.PII);
        const [cachedDob] = synchronization.unshiftAll(COLLECTIONS.DOB);

        if (cachedPiiData.length) {
            candidateModel.personalIdentifyingInformationItems(cachedPiiData);
        }

        if (cachedDob && cachedDob.length) {
            candidateModel.dateOfBirth(cachedDob);
        }
    }

    _isEditMode() {
        const [activeForm] = this.forms().filter(form => form().isActive());

        return activeForm;
    }

    _addProfileItemOnValidation() {
        if (this.blockProperties.isNIDRequired && this.forms().length < 1) {
            this.hideNewFormsDuringValidation(true);

            const profileItem = this._createItem();

            this._pushProfileItem(profileItem).then((form) => {
                this.hiddenNIDForm = form;
            })
                .catch(console.error);
        }
    }

    async _addBeforeSubmitServiceCallValidation() {
        return await piiItemsValidator.validateAllItems(this.profileItems(), this.forms());
    }

    _setGeolocationPreferredCountry() {
        return geolocationService.query()
            .then(({ countryCode }) => (this.preferredCountry = countryCode));
    }

    async _setJobPrefferedCountry() {
        const { jobId } = router.routeParams();
        const isInJobContext = Boolean(jobId);

        if (!isInJobContext) {
            return;
        }

        const jobDetails = await cachedJob.getJob(jobId)
            .catch(() => {});

        const preferredCountry = jobDetails?.primaryLocationCountry;

        if (!preferredCountry) {
            return;
        }

        this.preferredCountry = preferredCountry;
    }

    _getButtonLabel() {
        return 'apply-flow.section-personal-identifying-information.add-personal-identifying-information-button';
    }

    _getMetadata(metadataServiceUrl) {
        this.metadataService = new MetadataService(metadataServiceUrl);
        this._contentTypeId = 'pii';
    }

    _getCandidateProfileItems() {
        return candidateModel.personalIdentifyingInformationItems;
    }

    _fetchFormElements() {
        return this.metadataService.getFormElements()
            .then(formElementsDef => this._processFormElements(formElementsDef));
    }

    _processDOBForm(dobElement) {
        if (!dobElement) {
            this._handleFormElementError(`missing form definition ${DATE_OF_BIRTH}`);

            return;
        }

        const dateOfBirthForm = dateOfBirthFormBuilder.createForm({
            formElementDef: dobElement,
            blockProperties: this.blockProperties,
        });

        this.dateOfBirthFormElement = dateOfBirthForm.getElement(DATE_OF_BIRTH);
        this.customJsValidation.addToForm(dateOfBirthForm);
        this.form(dateOfBirthForm);
        sectionValidator.registerForm(this.form, this.sectionNumber);
    }

    _processFormElements(formElementsDef) {
        if (this.blockProperties.isDOBVisible) {
            const dobElement = formElementsDef.find(element => element.name === DATE_OF_BIRTH);

            this._processDOBForm(dobElement);
        }

        if (this.blockProperties.isNIDVisible) {
            const nidElements = formElementsDef.filter(element => element.name !== DATE_OF_BIRTH);

            if (!nidElements.length) {
                this._handleFormElementError('missing form definition for NID');
            }

            return nidElements;
        }

        return [];
    }

    _handleFormElementError(errorMessage) {
        console.error(errorMessage);
    }

    _fetchProfileItems() {
        return Promise.resolve(candidateModel.id())
            .then(candidateId => candidateId || Promise.reject())
            .then(() => this.readWriteService.query(candidateModel.id(), candidateModel.basicInformation))
            .catch(() => ({}));
    }

    _loadForms() {
        return this._getFormData()
            .then(() => {
                if (this._shouldBuildTilesOnInit) {
                    this._buildTiles();
                }

                this._formsSub = this.forms.subscribe(() => this._buildTiles(), this, 'arrayChange');
            })
            .catch(console.error)
            .then(() => sectionState.setBlockReady(this.sectionNumber, this.blockId));
    }

    _getFormData() {
        return this._fetchFormElements()
            .then((formElements) => {
                if (!this.blockProperties.isNIDVisible) {
                    return;
                }

                this._formElements(formElements);
                this.readWriteService = this._getReadWriteService(formElements);

                this._fetchProfileItems()
                    .then(this._setProfileItems.bind(this));
            });
    }

    _setProfileItems({ candidatePIIData = [] }) {
        this.profileItems.push(...candidatePIIData);
        this.profileItems().forEach(profileItem => this._insertSingleForm(profileItem));

        piiItemsValidator.validateAllItems(this.profileItems(), this.forms());
    }

    addProfileItem() {
        if (this.hideNewFormsDuringValidation()) {
            this.editProfileItem(this.hiddenNIDForm);

            return true;
        }

        const profileItem = this._createItem();

        this._isNew = true;

        return this._pushProfileItem(profileItem)
            .then((form) => {
                this.editProfileItem(form);
            })
            .catch(console.error);
    }


    doneProfileItem(profileItemForm) {
        this.resetNIDForm();
        super.doneProfileItem(profileItemForm);
    }

    shouldFormBeValidated(profileItemForm) {
        return this.changeDetector.hasChanged()
            || profileItemForm.getErrorCount() > 0;
    }

    removeProfileItem(profileItemForm) {
        this.resetNIDForm();
        super.removeProfileItem(profileItemForm);
    }

    resetNIDForm() {
        if (this.hideNewFormsDuringValidation()) {
            this.hideNewFormsDuringValidation(false);
            this.hiddenNIDForm = undefined;
        }
    }

    _createItem() {
        return new PersonalIdentifyingInformationModel({ country: this.preferredCountry });
    }

    _createSingleForm(profileItem) {
        const formBuilder = this._getFormBuilder();

        const form = formBuilder
            .createForm({
                elements: this._formElements(),
                dateOfBirth: this.dateOfBirthFormElement,
                contentTypeId: this._contentTypeId,
                existingItems: this.profileItems,
                isNew: profileItem.isNew(),
                readOnly: profileItem.readOnly(),
                isDOBVisible: this.blockProperties.isDOBVisible,
            })
            .resolveElementsDependencies()
            .registerModel(profileItem);

        this._disableContentItemId(form);

        return ko.observable(form);
    }

    _getFormBuilder() {
        return piiFormBuilder;
    }


    _getReadWriteService() {
        return personalIdentifyingInformation;
    }

    _executeValidation(profileItem) {
        const activeForm = this._getFormByItemId(profileItem.id());

        activeForm().isValidationInProgress(true);

        return piiItemsValidator.validateAllItems(this.profileItems(), this.forms())
            .then(() => {
                activeForm().isValidationInProgress(false);

                if (activeForm().validationErrorMessage()) {
                    return Promise.reject();
                }

                return Promise.resolve();
            });
    }

    _computeRequiredValidation() {
        return true;
    }

    dispose() {
        super.dispose();
        this._submitFailureEventHandler.detach();
    }

}