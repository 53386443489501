export const LINK_WITH_UTM = 'utmLink';
export const LINK_WITHOUT_UTM = 'link';
export const ROUTING = 'routing';
export const UTM_STORAGE_KEY = 'source-tracking-utm';
export const SOURCE_TRACKING_MAPPING_KEYS: Record<string, string> = {
    source: 'utm_source',
    sourceMedium: 'utm_medium',
    campaignCode: 'utm_campaign',
    campaignPostId: 'utm_post_id',
    sourceInternalInfo: 'utm_internal_info',
    referrerHeader: 'referrerHeader',
};
