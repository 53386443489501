import { components } from 'knockout';

import './component';
import 'cx/module/job-details/binding/generateGoogleJobSearchTags';
import { JobDetailsModalViewModel } from './JobDetailsModalViewModel';
import { JobDetailsFullscreenViewModel } from './JobDetailsFullscreenViewModel';
import { JobDetailsCssViewModel } from './JobDetailsCssViewModel';
import { JobDetailsFakeViewModel } from 'minimal/module/job-details/JobDetailsFakeViewModel';
import jobDetailsTemplate from './job-details-modal.html';
import jobDetailsFakeTemplate from './job-details-modal-fake.html';
import jobDetailsFullscreenTemplate from './job-details-fullscreen.html';
import jobDetailsWrapperTemplate from './job-details-wrapper.html';

components.register('job-details', {
    viewModel: JobDetailsModalViewModel,
    template: jobDetailsTemplate,
});

components.register('job-details-fullscreen', {
    viewModel: JobDetailsFullscreenViewModel,
    template: jobDetailsFullscreenTemplate,
});

components.register('job-details-css', {
    viewModel: JobDetailsCssViewModel,
    template: jobDetailsTemplate,
});

components.register('fake-job-details', {
    viewModel: JobDetailsFakeViewModel,
    template: jobDetailsFakeTemplate,
});

components.register('job-details-wrapper', {
    template: jobDetailsWrapperTemplate,
});
