import { observable } from 'knockout';

export default class OfferRefusalReason {

    constructor() {
        this.comment = observable();
        this.reasonId = observable();
    }

}
