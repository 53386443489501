import { GENERAL_ERROR, ITEM_ERROR } from '../config/piiItemsValidationErrorTypes';
import { stripOutErrorCodes } from 'core/utils/stripOutErrorCodes';

function splitMultilineErrorResponse(multilineErrors) {
    return multilineErrors
        .split(/\n/)
        .filter(Boolean);
}

function mapErrorResponseToObjectWithPiiInfo(errorResponse) {
    const jsonErrorString = getJsonStringFromErrorResponse(errorResponse);
    let jsonError;

    try {
        jsonError = JSON.parse(jsonErrorString);
    } catch {
        return {
            errorType: GENERAL_ERROR,
            errorMessage: errorResponse,
            piiItem: {
                itemId: null,
                nationalIdNumber: null,
                nationalIdType: null,
                nationalIdCountry: null,
                issueDate: null,
                expirationDate: null,
                placeOfIssue: null,
            },
        };
    }

    return {
        errorType: ITEM_ERROR,
        errorMessage: stripOutErrorCodes(jsonError.ErrorMessage),
        piiItem: {
            itemId: jsonError.Data.Id,
            nationalIdNumber: jsonError.Data.NationalIdentifierNumber,
            nationalIdType: jsonError.Data.NationalIdentifierType,
            nationalIdCountry: jsonError.Data.LegislationCode,
            issueDate: jsonError.Data.IssueDate,
            expirationDate: jsonError.Data.ExpirationDate,
            placeOfIssue: jsonError.Data.PlaceOfIssue,
        },
    };
}

function getJsonStringFromErrorResponse(errorResponse) {
    return errorResponse.substring(errorResponse.indexOf('{'), errorResponse.lastIndexOf('}') + 1);
}

export default {
    getErrorObjectsWithPiiInfo(multilineErrorResponse) {
        const errorResponses = splitMultilineErrorResponse(multilineErrorResponse);

        return errorResponses.map(errorResponse => mapErrorResponseToObjectWithPiiInfo(errorResponse));
    },
};
