import ApplyFlowPaginationViewModel from 'cx/module/apply-flow/component/apply-flow-pagination/ApplyFlowPaginationViewModel';
import router from 'app/model/router';

const INVALID_APPLICATION_MESSAGE = 'event-register-flow.validation.invalid-application';

export default class EventRegisterPaginationViewModel extends ApplyFlowPaginationViewModel {
    _getApplyFlowRoute(): string {
        // overriden for event registration flow scenario
        const route = router.route();

        if (route.parent && route.parent.id === 'event-preview') {
            return 'event-preview.register-flow';
        }

        return 'event-register-flow';
    }

    getValidationMessage(): string {
        return INVALID_APPLICATION_MESSAGE;
    }
}
