import candidate from 'apply-flow/model/candidate';
import unverifiedService from 'cx/module/candidate-verification/service/unverified';
import { processRemainingUnverifiedApplications } from './applicationSummary';

export const clearCandidate = (): void => {
    const tempQuestionnaires = candidate.questionnaires();
    const tempRegulatoryResponses = candidate.regulatoryResponses();
    const tempAttachments = candidate.attachments();

    candidate.clear();

    candidate.regulatoryResponses(tempRegulatoryResponses);
    candidate.questionnaires(tempQuestionnaires);
    candidate.attachments(tempAttachments);
};

export const processUnverifiedCandidate = (jobId: string): void => {
    unverifiedService.setUnverifiedCandidateId(candidate.id());
    unverifiedService.addUnverifiedCandidateApplication(jobId);

    processRemainingUnverifiedApplications();
};
