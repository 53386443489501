import { observableArray } from 'knockout';
import SingleRow from './SingleRow';

export default class Context {

    constructor({ name, link, formElements, rows = [], removedRows = [] }) {
        this.name = name;
        this.link = link;
        this.formElements = formElements;

        this.rows = observableArray();
        this.removedRows = removedRows;

        this._fillWithCandidateData(rows);
    }

    get firstRow() {
        return this.rows()[0];
    }

    addRow(rowId, rowLink, rowData) {
        const row = new SingleRow(this.formElements, rowId, rowLink, rowData);

        this.rows.push(row);

        return row;
    }

    isEmpty() {
        return !this.rows().length;
    }

    remove(rowAttributes) {
        const [removedRow] = this.rows().filter(row => row.attributes === rowAttributes);

        this.rows.remove(removedRow);

        if (removedRow.id) {
            this.removedRows.push(removedRow);
        }

        return removedRow;
    }

    updateIdsAndLinks({ link, rows }) {
        this.link = link;
        this.rows().forEach((row, index) => row.updateIdAndLink(rows[index]));
    }

    _fillWithCandidateData(rows) {
        rows.forEach(row => this.addRow(row.id, row.link, row.attributes));
    }

}