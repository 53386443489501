import Interval from './Interval';
import IntervalSequence from './IntervalSequence';

class TimelineLevel extends IntervalSequence {

    push(entry) {
        if (!(entry instanceof Interval)) {
            throw new Error('It is not a Interval element');
        }

        if (!this.overlaps(entry)) {
            return super.push(entry);
        }

        return false;
    }

    coverWith(otherLevel) {
        this.elements.forEach((entry) => {
            entry.coverWith(otherLevel);
        });
    }

    overlaps(entry) {
        return this.elements.some(element => element.overlaps(entry));
    }

}

export default TimelineLevel;