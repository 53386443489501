export default {
    mapBackgroundCheckLinkFromRest({ backgroundCheckUrl }) {
        return backgroundCheckUrl;
    },

    mapApplicationBGCFromRest(bgCheckData) {
        return {
            provisioningId: bgCheckData.provisioningId,
            partnerName: bgCheckData.partnerName,
        };
    },

};