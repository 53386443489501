import ko from 'knockout';

/**
 * Creates new text node each time the given value has changed.
 * At the same time removes previous text nodes, so that Screen Reader always reads value of latest.
 *
 * @param {observable} value - KO observable which holds the message for Screen Reader
 *
 * @example
 * <div role="status" aria-live="polite" aria-relevant="additions" data-bind="accessibleMessage: status"></div>
 */
ko.bindingHandlers.accessibleMessage = {
    update(bindingElement, value) {
        const text = ko.unwrap(value());
        const element = bindingElement;

        if (text) {
            const textNode = document.createTextNode(text);
            const pNode = document.createElement('p');

            pNode.appendChild(textNode);

            element.innerHTML = '';
            element.appendChild(pNode);
        }
    },
};
