import { components } from 'knockout';

import { EventTagsViewModel } from 'minimal/module/event-tags/EventTagsViewModel';
import template from './event-tags.html';
import './component';

components.register('event-tags', {
    viewModel: EventTagsViewModel,
    template,
});
