import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import referralService from 'candidate-verification/service/referral';
import internalUserEvents from 'ce-common/module/internal-user/config/events';
import themeEvents from 'cx/module/site-editor/config/events';
import i18n from 'core/i18n/i18n';


export default class InternalUserLoginButtonViewModel {

    constructor({ isEmployeeButtonHidden, employeeText } = {}) {
        this.userLoginButtonText = observable(employeeText);
        this.hideUserLoginIcon = observable(isEmployeeButtonHidden);
        this.iceRouteName = observable();
        this.iceRouteParams = observable();
        this.iceUrl = pureComputed(() => this._getIceUrl());

        this.userLoginButtonAriaLabel = pureComputed(
            () => this.userLoginButtonText() || i18n('general.employee-login-button'),
        );

        this._requisitionNumber = pureComputed(() => router.routeParams().jobId);

        this._updateIceRoute();
        this._subscription = this._requisitionNumber.subscribe(this._updateIceRoute.bind(this));
        this._signal = internalUserEvents.refreshIceRouteParams.add(this._updateIceRoute.bind(this));

        this._globalHeaderSettingsSubscription = themeEvents.customHeaderSettingsUpdated.add((globalHeaderSettings) => {
            this.userLoginButtonText(globalHeaderSettings.employeeText);

            this.hideUserLoginIcon(globalHeaderSettings.isEmployeeButtonHidden);
        });
    }

    _updateIceRoute() {
        const requisitionNumber = this._requisitionNumber();
        const hitCountResponse = referralService.getHitCountResponse(requisitionNumber) || {};

        this._updateIceRouteName(requisitionNumber, hitCountResponse);
        this._updateIceRouteParams(hitCountResponse);
    }

    _updateIceRouteName(requisitionNumber, hitCountResponse) {
        let iceRouteName = 'ice-search';

        if (requisitionNumber) {
            if (hitCountResponse.referralId) {
                iceRouteName = 'ice-referred-job-details';
            } else if (hitCountResponse.shareId) {
                iceRouteName = 'ice-shared-job-details';
            } else {
                iceRouteName = 'ice-job-details';
            }
        }

        this.iceRouteName(iceRouteName);
    }

    _updateIceRouteParams(hitCountResponse) {
        this.iceRouteParams({
            referralId: hitCountResponse.referralId,
            shareId: hitCountResponse.shareId,
        });
    }

    _getIceUrl() {
        return router.interpolate(this.iceRouteName(), this.iceRouteParams());
    }

    dispose() {
        this._subscription.dispose();
        this._signal.detach();
        this._globalHeaderSettingsSubscription.detach();
    }

}