import mapping from 'knockout-mapping';
import Base64FileReader from 'core/base64-file-reader/Base64FileReader';
import FileAttachment from 'apply-flow/module/file-upload/model/FileAttachment';

export default {
    create(preuploadFileAttachment, file) {
        const fileAttachment = mapping.toJS(preuploadFileAttachment);
        const base64FileReader = new Base64FileReader();

        return base64FileReader.encode(file)
            .then((base64data) => {
                fileAttachment.fileContents = base64data;

                return new FileAttachment(fileAttachment);
            });
    },
};