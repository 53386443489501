import { Observable, observable, PureComputed, pureComputed, Subscription } from 'knockout';
import router from 'app/model/router';
import FlowIterator from 'app/module/cx/module/apply-flow/model/FlowIterator';
import siteLanguage from 'app/module/ce-common/service/language/siteLanguage';
import { getEventDetails } from '../../../event-details/service/eventService';
import { EventRegisterFlowModel, EventRegisterFormSection, RouterParams } from '../../config/types';
import { EVENT_DETAILS_FETCH_CONTEXT } from '../../../event-details/config/types';
import cxEvents from 'cx/config/events';

export default class EventRegisterFlowHeaderViewModel {
    private flow: EventRegisterFlowModel;
    private iterator: () => FlowIterator;
    private isFlowDataAvailable: PureComputed<boolean>;
    private isSingleClick: boolean;
    private summaryTemplate: Observable<string>;
    private closeAction: VoidFunction;
    private userLanguage: string;
    private sections: EventRegisterFormSection[];
    private eventTitle: Observable<string>;
    private currentPageSub: Subscription;

    constructor({
        flow,
        redirectOnJobError = true,
        closeAction,
        summaryTemplate,
    }: {
        flow: Observable<EventRegisterFlowModel>;
        redirectOnJobError: boolean;
        closeAction: VoidFunction;
        summaryTemplate: Observable<string>;
    }) {
        this.flow = flow();
        this.sections = this.flow.sections;
        this.userLanguage = siteLanguage.restore();
        this.iterator = this.flow.iterator.bind(this.flow);
        this.isSingleClick = this.flow.isSingleClick;
        this.summaryTemplate = summaryTemplate;
        this.closeAction = closeAction || this.exitRegisterFlow.bind(this);
        this.eventTitle = observable('');
        this.loadEventDetails(redirectOnJobError).then(() => this.updatePageTitle());
        this.isFlowDataAvailable = pureComputed(() => (this.flow ? true : false));
        this.currentPageSub = this.iterator().currentSection.subscribe(() => this.updatePageTitle());
    }

    private loadEventDetails(redirectOnJobError: boolean): Promise<void> {
        const { eventId } = router.routeParams() as RouterParams;

        return getEventDetails(eventId, EVENT_DETAILS_FETCH_CONTEXT.CHECK_IN_CONTEXT)
            .then((eventAttributes) => {
                this.eventTitle(eventAttributes ? eventAttributes.title : '');
            })
            .catch(() => this.handleGetJobFailure(redirectOnJobError));
    }

    private updatePageTitle() {
        const title = this.eventTitle() || '';
        const currentSection = this.iterator().currentSection() || {};

        let pageTitle = `${title}`;

        if (!this.isSingleClick && currentSection.title) {
            pageTitle = `${currentSection.title} - ${pageTitle}`;
        }

        cxEvents.pageTitleUpdate.dispatch(pageTitle);
    }

    private handleGetJobFailure(redirectOnJobError: boolean): void {
        if (redirectOnJobError) {
            router.go('event-details');
        }
    }

    private exitRegisterFlow(): void {
        const { eventId } = router.routeParams() as RouterParams;
        const route = router.route();

        if (route.parent?.id === 'event-preview') {
            router.go('event-preview', {
                eventId: eventId,
            });
        } else {
            router.go('event-details', { eventId: eventId });
        }
    }

    dispose(): void {
        this.currentPageSub.dispose();
    }
}
