import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import storageService from 'core/storage/sessionStorage';
import { Dictionary } from 'app/types/utils';

const GLOBAL_SPELL_CHECK_FLAG_KEY = 'ORA_IRC_CE_SEARCH_SPELL_CHECK_ENABLED';

export const EXECUTE_SPELL_CHECK_STORE_KEY = 'executeSpellCheckFlag';
export const EXECUTE_SPELL_CHECK_FLAG_FALSE = 'false';
export const SPELL_CHECK_CORRECTED_KEYWORD_KEY = 'correctedKeyword';

export const isSpellCheckEnabled = (): boolean =>
    mapTrueFalseStringToBoolean(appConfig.getSettingByKey(GLOBAL_SPELL_CHECK_FLAG_KEY));

export const addSpellCheckToParams = (
    params: Dictionary<string>,
    isMapSearch = false
): Dictionary<string> => {
    if (isSpellCheckEnabled()) {
        const executeSpellCheckFlag = restoreSpellCheckFlag();
        const correctedKeyword = restoreCorrectedKeyword();
        const hasCorrectedKeyword = correctedKeyword && params?.keyword !== correctedKeyword;
        const withoutUseExactKeyword = params?.useExactKeywordFlag !== 'true';
        const withSortOrFacet = params?.sortBy || params?.lastSelectedFacet || isMapSearch;

        if (executeSpellCheckFlag) {
            params.executeSpellCheckFlag = executeSpellCheckFlag;
        } else if ((hasCorrectedKeyword && withoutUseExactKeyword && withSortOrFacet) || isMapSearch) {
            params.executeSpellCheckFlag = EXECUTE_SPELL_CHECK_FLAG_FALSE;
            params.correctedKeyword = correctedKeyword;
        }
    }

    return params;
};

export const restoreSpellCheckFlag = (): string => {
    return isSpellCheckEnabled() && storageService.restore(EXECUTE_SPELL_CHECK_STORE_KEY);
};

export const removeSpellCheckFlag = (): void => {
    storageService.remove(EXECUTE_SPELL_CHECK_STORE_KEY);
};

export const stopSpellCheckExecution = (): void => {
    storageService.store(EXECUTE_SPELL_CHECK_STORE_KEY, EXECUTE_SPELL_CHECK_FLAG_FALSE);
};

export const storeCorrectedKeyword = (keyword: string): void => {
    storageService.store(SPELL_CHECK_CORRECTED_KEYWORD_KEY, keyword);
};

export const restoreCorrectedKeyword = (): string => {
    return isSpellCheckEnabled() && storageService.restore(SPELL_CHECK_CORRECTED_KEYWORD_KEY);
};

export const removeCorrectedKeyword = (): void => {
    storageService.remove(SPELL_CHECK_CORRECTED_KEYWORD_KEY);
};
