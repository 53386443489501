import ko from 'knockout';
import DictionaryElement from 'core/form/element/DictionaryElement';

export default class SearchFormElement extends DictionaryElement {

    constructor(...args) {
        super(...args);

        this.component('search-form-element');

        this.valueLabel = ko.pureComputed(this._getValueLabel, this);
    }

    _getValueLabel() {
        const fieldValue = this.value();
        const { value, label } = this.optionsKeys();

        const currentOption = ko.utils.arrayFirst(this.currentOptions(), option => option[value] === fieldValue);

        return currentOption ? currentOption[label] : null;
    }

}