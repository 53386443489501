import { observable, pureComputed } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';
import i18n from 'core/i18n/i18n';

export default class MultiSelectPillFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.value = observable(null);

        this.onValueChanged = this.onValueChanged.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);
        this.genericRemoveLabel = i18n('general.remove-button');
        this.showNoResultsHint = pureComputed(this._showNoResultsHint, this);
    }

    onValueChanged(event) {
        const { value: itemId, label: itemLabel } = event.detail.value;

        const options = this.element.options();

        const item = options
            .find(({ id, label }) => id === itemId && label === itemLabel);

        if (!item) {
            return;
        }

        this.element.value().push(item);
        this.element.value.notifySubscribers();

        this.hasFocus(true);
    }

    resetHandler() {
        this.value(null);
    }

    blurHandler() {
        this.hasFocus(false);

        super.blurHandler();
    }

    _showNoResultsHint() {
        return this.element.rawValue() && this.element.noSuggestions();
    }

}
