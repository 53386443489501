import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';
import { Observable } from 'knockout';

export default Object.create(formBuilder, {
    createForm: {
        value(model: Observable<Date>) {
            const form = formBuilder.createForm();

            form.elements([this._dateOfBirthFormElement(model)]);

            return form;
        },
    },

    _dateOfBirthFormElement: {
        value(model: Observable<Date>) {
            const label = i18n('apply-flow.section-personal-information.date-of-birth-label');
            const dateOfBirth = 'date-of-birth';
            const formElement = formElementFactory.create('dateMonthpicker', dateOfBirth);

            formElement.addValidator('required', { label });
            formElement.label(label);

            formElement.attributes({
                id: dateOfBirth,
                'aria-label': label,
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});
