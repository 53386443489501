import httpLangCached from 'cx/service/http/httpLangCached';
import geoHierarchyMapper from 'apply-flow/module/personal-information-address/mapper/geoHierarchy';

export default class GeoHierarchy {

    constructor(config) {
        const baseUrl = config.serviceUrl.replace('<COUNTRY_CODE>', config.countryCode);

        this._serviceUrl = `${baseUrl}/child/geoHierarchies`;
    }

    findByQuery(query) {
        return httpLangCached
            .get(`${this._serviceUrl}?${query}&limit=500&onlyData=true`)
            .then(geoHierarchyMapper.mapGeoHierarchiesFromRest.bind(geoHierarchyMapper));
    }

}