import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import ElementViewModel from '../element/ElementViewModel';
import customContentService from '../../service/customContent';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import { addHttpsPrefix } from '../../service/addHttpsPrefix';
import { getFusionCode } from 'custom-content/service/getFusionCode';

function paramToString(param) {
    return Array.isArray(param) ? param[0] : param;
}

export default class ButtonViewModel extends ElementViewModel {

    constructor({ params, content, mode, lang }, templateButtonClass = 'cx-button') {
        super({ params, content });

        this.buttonRootClass = pureComputed(() => `cc-button__wrapper--align-${this.params.align()}`);
        this.isExternal = pureComputed(() => this.params.destination() === 'external');
        this.isAdminMode = observable(mode === 'admin');
        this.customPageURL = observable(null);

        this.buttonClass = pureComputed(() => (
            this.params.buttonType() === 'button' ? `${templateButtonClass} ${templateButtonClass}--${this.params.buttonStyle()}` : ''),
        );

        this.targetAttr = pureComputed(() => (
            this.params.openInNewTab() ? '_blank' : null
        ));

        this._getCustomPageURL().then((url) => {
            this.customPageURL(url);
        });

        const languageCode = lang ? lang() : getFusionCode();

        this.externalURL = pureComputed(() => {
            const paramKey = `externalURL_${languageCode}`;
            const langUrl = this.params[paramKey];
            const url = langUrl ? langUrl() : this.params.externalURL();

            if (url === null || url.startsWith('#')) {
                return url;
            }

            return addHttpsPrefix(url);
        });
    }

    goToCcPage() {
        if (this.params.openInNewTab()) {
            return true;
        }

        return router.redirect(this.customPageURL());
    }

    _getCustomPageURL() {
        const destination = paramToString(this.params.destination());

        // TODO: RemoveHash: Validate
        return customContentService.getTitle(destination)
            .then(title => absoluteRouter.interpolate({ lang: siteLanguage.get() }, 'cc-page', {
                pageCode: destination,
                pageTitle: title,
            }));
    }

    buttonClickAction() {
        if (this.externalURL() === null || !this.externalURL().startsWith('#')) {
            return true;
        }

        const linkElement = document.getElementById(this.externalURL().substring(1));

        if (linkElement !== null) {
            linkElement.scrollIntoView({
                behavior: 'smooth',
                // this option is required by: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
                passive: true,
            });
        }

        return false;
    }

}
