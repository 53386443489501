import { observable, observableArray, pureComputed } from 'knockout';
import appConfig from 'app/model/config';
import debouncePromise from 'core/utils/debouncePromise';
import i18n from 'core/i18n/i18n';
import { hasLocation, isLocationWithCityLevel } from 'minimal/module/search/service/location';

const DEFAULT_RADIUS = 25;

/**
 * Handling "no suggestions popup" should work like in search-box-location component.
 * There are a couple of differences caused by the use of different input components.
 *
 * The popup should be displayed when user types something in the input and we can't
 * find any suggested locations.
 * The popup should be hidden when user leaves the input (on blur).
 *
 * Oj-combobox-one does not handle focus/blur events so we are handling that
 * on a wrapper DIV.
 * Oj-combobox-one changes value (and triggers change events) when user
 * leaves the input, that is why we are checking rawValue instead
 * (which is updated on each keystroke).
 * Finally we are checking if there are suggestions based on the API response.
 */

export default class SearchRecruitingLocationViewModel {

    constructor({ location, action, radiusValue, radiusUnit, fetch }) {
        this.value = observable(null);
        this.valueOption = observable(null);
        this.rawValue = observable(null);
        this.location = location;
        this.action = action;
        this.radiusValue = radiusValue;
        this.radiusUnit = radiusUnit;
        this.options = observableArray();
        this.siteNumber = appConfig.siteNumber;
        this.showRadiusDisplay = observable(false);
        this.noSuggestions = observable(false);

        this.placeholder = i18n('location-bar.dropdown.recruiting-location-placeholder');

        this.showNoResultsHint = pureComputed(this._showNoResultsHint, this).extend({
            notify: 'always',
        });

        this.fetch = fetch;
        this.fetchDebounced = debouncePromise(this.fetch.bind(this), 500);
        this.fetchOptions = this.fetchOptions.bind(this);
        this.onValueOptionChanged = this.onValueOptionChanged.bind(this);

        if (hasLocation(location())) {
            this._setValue(location());
            this.showRadiusDisplay(isLocationWithCityLevel(location()));

            return;
        }

        this.locationSubscription = this.location.subscribe((_location) => {
            if (!Object.keys(_location).length) {
                this.value(null);
                this.radiusValue(DEFAULT_RADIUS);
            }
        }, this);

        this.radiusValue(DEFAULT_RADIUS);
    }

    onBlur = () => {
        this.noSuggestions(false);
    }

    fetchOptions(context) {
        this.noSuggestions(false);

        const fn = context.term ? this.fetchDebounced : this.fetch;

        const payload = {
            siteNumber: this.siteNumber,
            term: context.term,
        };

        return fn(payload)
            // Return empty suggestions array on error
            .catch(() => [])
            .then(this._saveOptions.bind(this));
    }

    _saveOptions(options = []) {
        const mappedOptions = options.map(option => ({
            ...option,
            locationUID: `${option.id}@@${option.name.toLowerCase()}`,
        }));

        this.options(mappedOptions);

        this.noSuggestions(!mappedOptions.length);

        return mappedOptions;
    }

    onValueOptionChanged(event) {
        const { label: selectedLabel } = event.detail.value;
        let { value: selectedValue } = event.detail.value;

        if (!selectedLabel && !selectedValue) {
            this.location({});
            this.showRadiusDisplay(false);
            this.action();

            return;
        }

        const options = this.options();

        [selectedValue] = selectedValue.split('@@');

        const location = options
            .find(({ id, label }) =>
                (id === selectedValue
                    || selectedValue.toLowerCase() === selectedLabel.toLowerCase())
                && label.toLowerCase() === selectedLabel.toLowerCase());

        if (location) {
            this.location(location);
            this.showRadiusDisplay(isLocationWithCityLevel(location));

            this.action();
        }
    }

    _showNoResultsHint() {
        return Boolean(this.rawValue()) && this.noSuggestions();
    }

    _setValue(location) {
        const { locationId } = location;

        location.id = locationId;
        location.locationId = parseInt(locationId, 10);
        this.valueOption({ value: location.id, label: location.label });
        this.options().push(location);
    }

}