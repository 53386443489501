export const CERTIFICATION = 103;
export const EDUCATION = 106;
export const LANGUAGE = 109;
export const WORK_PREFERENCE = 117;
export const EXPERIENCE = 129;
export const SKILL = 134;

export default {
    CERTIFICATION_ID: 103,
    EDUCATION_ID: 106,
    LANGUAGE_ID: 109,
    WORK_PREFERENCE_ID: 117,
    EXPERIENCE_ID: 129,
    SKILL_ID: 134,
};