import config from 'core/http/config';
import {
    REQUEST_EMAIL_VERIFICATION,
    REQUEST_PHONE_VERIFICATION,
} from 'apply-flow/module/personal-information-basic/mapper/preferredCommunicationsChannel';

export const REST_ACTION_HEADER = 'Ora-Irc-Rest-Action';

type Request = {
    url: string;
    body?: string;
};

type TokenPayload = {
    Phone?: string;
    Email?: string;
    Mode?: string;
    ShortCode?: number;
};

type CandidatePayload = {
    Action?: string;
};

type Payload = TokenPayload | CandidatePayload;

type RestActionValueFunction =
    | ((payload: TokenPayload) => string | null)
    | ((payload: CandidatePayload) => string | null);

const getTokenRestActionValue = (tokenPayload: TokenPayload): string | null => {
    if (!tokenPayload.Mode) {
        return null;
    }

    if (tokenPayload.Phone) {
        return `${tokenPayload.Mode}:PHONE`;
    }

    if (tokenPayload.Email) {
        return `${tokenPayload.Mode}:EMAIL`;
    }

    return null;
};

const getCandidateRestActionValue = (candidatePayload: CandidatePayload): string | null => {
    if (
        candidatePayload.Action === REQUEST_PHONE_VERIFICATION ||
        candidatePayload.Action === REQUEST_EMAIL_VERIFICATION
    ) {
        return candidatePayload.Action;
    }

    return null;
};

const getDraftsRestActionValue = (): string => {
    return 'CE_JA_DRAFT';
};

const getResumeParserRestActionValue = (): string => {
    return 'CE_RESUME_PARSE';
};

const ENDPOINTS_TO_REST_ACTION_VALUES_MAP = new Map<string, RestActionValueFunction>([
    ['/recruitingCEVerificationTokens', getTokenRestActionValue],
    ['/recruitingCECandidates', getCandidateRestActionValue],
    ['/recruitingCEJobApplicationDrafts', getDraftsRestActionValue],
    ['/recruitingCECandidateResumeParsers', getResumeParserRestActionValue],
]);

export const getRestActionHeaderValue = (request: Request): string | null => {
    const url = `/${request.url.replace(config.baseUrl, '').split('?')[0].split('/')[1]}`;

    // Add action header for GET Request of following endpoints
    if (
        url !== '/recruitingCEJobApplicationDrafts' &&
        url !== '/recruitingCECandidateResumeParsers' &&
        !request.body
    ) {
        return null;
    }

    const restActionValueFunction = ENDPOINTS_TO_REST_ACTION_VALUES_MAP.get(url);

    if (restActionValueFunction) {
        const payload = request.body ? JSON.parse(request.body) : ('' as Payload);

        return restActionValueFunction(payload);
    }

    return null;
};
