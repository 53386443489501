import i18n from 'core/i18n/i18n';
import { Job } from 'minimal/module/job-details/types';
import { JOB_INFO_DISPLAYED_INFORMATION_LABELS } from 'site-editor/module/search-editor/component/cc-search-results-editor/config/defaultParams';
import { AttributesList } from '../config/types';

const removeEmptyAttributes = (attributes: AttributesList): AttributesList => {
    Object.keys(attributes).forEach((key) => {
        const isEmpty = Array.isArray(attributes[key as keyof AttributesList]?.value)
            ? attributes[key as keyof AttributesList]?.value?.length
            : attributes[key as keyof AttributesList]?.value;

        if (!isEmpty) {
            delete attributes[key as keyof AttributesList];
        }
    });

    return attributes;
};

const getLocationLabel = (locationValue: string[]): string => {
    if (locationValue.length) {
        return i18n('job-details.locations-label');
    }

    return i18n('job-details.location-label');
};

const getLocationValue = ({
    workLocations,
    primaryLocation = null,
    secondaryLocations = [],
}: Job): string[] => {
    if (workLocations?.length) {
        return workLocations;
    }

    return primaryLocation ? [primaryLocation].concat(secondaryLocations || []) : [];
};

export const generateJobInfo = (job: Job): AttributesList => {
    const locationValue = getLocationValue(job);
    const locationLabel = getLocationLabel(locationValue);

    const attributes = {
        location: {
            key: 'location',
            value: locationValue,
            translationKey: locationLabel,
            label: undefined,
            className: 'locations',
        },
        postingDate: {
            key: 'postingDate',
            value: job.postedDate,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['postingDate'],
            label: undefined,
            className: 'posting-date',
        },
        distance: {
            key: 'distance',
            value: job.distance,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['distance'],
            label: undefined,
            className: 'distance',
        },
        requisitionNumber: {
            key: 'requisitionNumber',
            value: job.id,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['requisitionNumber'],
            label: undefined,
            className: 'requisition-number',
        },
        organization: {
            key: 'organization',
            value: job.organization,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['organization'],
            label: undefined,
            className: 'organization',
        },
        jobFamily: {
            key: 'jobFamily',
            value: job.jobFamily,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['jobFamily'],
            label: undefined,
            className: 'job-category',
        },
        jobFunction: {
            key: 'jobFunction',
            value: job.jobFunction,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['jobFunction'],
            label: undefined,
            className: 'job-function',
        },
        workerType: {
            key: 'workerType',
            value: job.workerType,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['workerType'],
            label: undefined,
            className: 'worker-type',
        },
        contractType: {
            key: 'contractType',
            value: job.contractType,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['contractType'],
            label: undefined,
            className: 'working-type',
        },
        managerLevel: {
            key: 'managerLevel',
            value: job.managerLevel,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['managerLevel'],
            label: undefined,
            className: 'management-level',
        },
        jobSchedule: {
            key: 'jobSchedule',
            value: job.jobSchedule,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['jobSchedule'],
            label: undefined,
            className: 'job-schedule',
        },
        jobShift: {
            key: 'jobShift',
            value: job.jobShift,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['jobShift'],
            label: undefined,
            className: 'job-shift',
        },
        jobType: {
            key: 'jobType',
            value: job.jobType,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['jobType'],
            label: undefined,
            className: 'job-type',
        },
        studyLevel: {
            key: 'studyLevel',
            value: job.studyLevel,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['studyLevel'],
            label: undefined,
            className: 'education-level',
        },
        domesticTravelRequired: {
            key: 'domesticTravel',
            value: job.domesticTravelRequired,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['domesticTravelRequired'],
            label: undefined,
            className: 'domestic-travel',
        },
        internationalTravelRequired: {
            key: 'internationalTravel',
            value: job.internationalTravelRequired,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['internationalTravelRequired'],
            label: undefined,
            className: 'international-travel',
        },
        workDurationMonths: {
            key: 'workDurationMonths',
            value: job.workDurationMonths,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['workDurationMonths'],
            label: undefined,
            className: 'work-duration-months',
        },
        workDurationYears: {
            key: 'workDurationYears',
            value: job.workDurationYears,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['workDurationYears'],
            label: undefined,
            className: 'work-duration-years',
        },
        workDays: {
            key: 'workDays',
            value: job.workDays,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['workDays'],
            label: undefined,
            className: 'work-days',
        },
        workHours: {
            key: 'workHours',
            value: job.workHours,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['workHours'],
            label: undefined,
            className: 'work-hours',
        },
        legalEmployer: {
            key: 'legalEmployer',
            value: job.legalEmployer,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['legalEmployer'],
            label: undefined,
            className: 'legal-employer',
        },
        businessUnit: {
            key: 'businessUnit',
            value: job.businessUnit,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['businessUnit'],
            label: undefined,
            className: 'business-unit',
        },
        department: {
            key: 'department',
            value: job.department,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['department'],
            label: undefined,
            className: 'department',
        },
        postingEndDate: {
            key: 'postingEndDate',
            value: job.postingEndDate,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['postingEndDate'],
            label: undefined,
            className: 'posting-expiration-date',
        },
        primaryWorkLocation: {
            key: 'primaryWorkLocation',
            value: job.primaryLocation,
            translationKey: JOB_INFO_DISPLAYED_INFORMATION_LABELS['primaryWorkLocation'],
            label: undefined,
            className: 'primary-work-location',
        },
    };

    return removeEmptyAttributes(attributes);
};
