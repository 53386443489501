import Signal from 'signals';


export default {
    loaded: new Signal(),
    loading: new Signal(),
    pageTitleUpdate: new Signal(),
    cxFooterInViewportChanged: new Signal(),
    toggleLoading: new Signal(),
    isHeaderLoaded: new Signal(),
    contentLocaleLang: new Signal(),
    isSectionsLoaded: new Signal(),
};