import ko from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';
import dictionaryService from 'apply-flow/service/dictionary';
import stringSanitizer from 'ce-common/service/stringSanitizer';

const DEFAULT_SEARCH_LIMIT = 40;

export default class DictionaryElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.dictionary = ko.observable();

        this.options = ko.observableArray();
        this.optionsDisabled = ko.observableArray();

        this.optionsKeys = ko.observable({
            value: 'lookupCode',
            label: 'meaning',
        });

        this.currentOptions = ko.observableArray();
    }

    getOption(value) {
        if (!value) {
            return Promise.resolve();
        }

        const capitalizedKey = DictionaryElement._capitalize(this.optionsKeys().value);

        const criteriaObj = {
            [capitalizedKey]: value,
        };

        return dictionaryService.findOne(this.dictionary(), criteriaObj)
            .then(this._saveOptions.bind(this));
    }

    getOptions(query) {
        const sanitizedQuery = stringSanitizer.sanitize(query);

        return Promise.resolve()
            .then(() => {
                if (sanitizedQuery) {
                    return this._filterOptions(sanitizedQuery);
                }

                return this.options();
            })
            .then(this._disableOptions.bind(this))
            .then(this._saveOptions.bind(this));
    }

    static _capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    _filterOptions(query) {
        const capitalizedKey = DictionaryElement._capitalize(this.optionsKeys().label);

        const criteriaObj = {
            [capitalizedKey]: query,
        };

        return dictionaryService.findLike(this.dictionary(), criteriaObj, {
            limit: DEFAULT_SEARCH_LIMIT,
        });
    }

    _disableOptions(options) {
        const disabledOptions = ko.unwrap(this.optionsDisabled);
        const value = this.value();

        options.forEach((option) => {
            if (option.lookupCode === value) {
                option.disabled = false;
            } else {
                option.disabled = (disabledOptions.indexOf(option[this.optionsKeys().value]) !== -1);
            }
        });

        return options;
    }

    _saveOptions(options) {
        this.currentOptions(Array.isArray(options) ? options : [options]);

        return options;
    }

}