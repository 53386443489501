import { bindingHandlers, utils } from 'knockout';

/**
 *
 * Register an event to trigger it only once.
 *
 * @params {object} - a Javascript object in which property names correspond to
 * event names, and the values correspond to the function that you want to bind to the event.
 *
 * @example
 * <div data-bind="eventOnce: { input: onInputOnceHandler, click: onClickOnceHandler }"></div>
 */

bindingHandlers.eventOnce = {
    init(element, valueAccessor) {
        const eventBindings = valueAccessor();

        Object.keys(eventBindings).forEach((eventName) => {
            const eventHandler = eventBindings[eventName];

            const onEvent = (event) => {
                eventHandler(event);
                element.removeEventListener(eventName, onEvent);
            };

            element.addEventListener(eventName, onEvent);

            utils.domNodeDisposal.addDisposeCallback(element, () => {
                element.removeEventListener(eventName, onEvent);
            });
        });
    },
};