import { makeRequest } from './common';

export default {
    get(url, config) {
        return makeRequest(url, null, 'GET', config);
    },

    post(url, data, config) {
        return makeRequest(url, data, 'POST', config);
    },

    patch(url, data, config) {
        return makeRequest(url, data, 'PATCH', config);
    },

    put(url, data, config) {
        return makeRequest(url, data, 'PUT', config);
    },

    delete(url, config) {
        return makeRequest(url, null, 'DELETE', config);
    },

    jsonp(url, data, config) {
        return makeRequest(url, data, 'JSONP', config);
    },
};