import { Observable } from 'knockout';
import FormValidator from 'core/form/validator/Validator';

type Options = {
    message?: string;
    source: Observable<boolean>;
    valueToCompare: boolean;
};

export default class EqualsToValidator extends FormValidator {
    constructor(options: Options) {
        super(options);

        this._message = 'error-message-header';
    }

    _validate(value: string, options: Options): boolean {
        return options.source() === options.valueToCompare;
    }
}
