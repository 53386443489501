import VanityAbsoluteRouter from './absoluteRouter/VanityAbsoluteRouter';
import AbsoluteRouter from './absoluteRouter/AbsoluteRouter';
import { Contexts } from './absoluteRouter/types';

export { AbsoluteRouter, Contexts };

const BaseAbsoluteRouter = (window as unknown as { VanityUrlEnabled: boolean }).VanityUrlEnabled
    ? VanityAbsoluteRouter
    : AbsoluteRouter;

export default new BaseAbsoluteRouter(document.baseURI);
