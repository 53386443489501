import { components } from 'knockout';

import { JobDetailsMediaViewModel } from '../job-details-media/JobDetailsMediaViewModel';
import template from './job-details-media.html';
import 'ce-common/component/media-slider/component';

components.register('job-details-media', {
    viewModel: JobDetailsMediaViewModel,
    template,
});

components.register('cc-job-details-media', {
    viewModel: JobDetailsMediaViewModel,
    template,
});
