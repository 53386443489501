import candidate from 'apply-flow/model/candidate';
import candidateService from 'apply-flow/service/candidate';
import apiLogger from 'core/api-logger/apiLogger';
import { CANDIDATE_SNAPSHOT } from 'core/api-logger/config/entryType';
import * as sensitiveCandidateDataObfuscator from 'apply-flow/service/sensitiveCandidateDataObfuscator';

export function logCandidateSnapshot() {
    apiLogger.log({
        type: CANDIDATE_SNAPSHOT,
        data: sensitiveCandidateDataObfuscator.obfuscate(
            candidateService.getSnapshot(candidate),
        ),
    });
}
