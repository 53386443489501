import { bindingHandlers } from 'knockout';
import { addScriptToHead } from 'core/utils/addScriptToHead';

declare global {
    interface Window {
        onCaptchaLoad: () => void;
    }
}

const renderCaptcha = (element: string | HTMLInputElement, callback: () => void, siteKey: string) => {
    const captchaConfig = {
        sitekey: siteKey,
        callback: callback,
    };

    hcaptcha.render(element, captchaConfig);
};

bindingHandlers.invisibleHCaptcha = {
    init(element, valueAccessor) {
        const { callback, captchaData } = valueAccessor();
        const { siteKey, apiUrl } = captchaData;
        const captchaElement = element.querySelector('div');

        addScriptToHead(apiUrl);

        function onload() {
            renderCaptcha(captchaElement, callback, siteKey);
        }

        window.onCaptchaLoad = onload;
    },

    update(element, valueAccessor) {
        if (typeof hcaptcha === 'undefined') {
            // To early call for update should be skipped
            return;
        }

        const { callback, captchaData } = valueAccessor();
        const { siteKey } = captchaData;

        const captchaElement = element.querySelector('div');

        if (hcaptcha) {
            hcaptcha.remove();
        }

        renderCaptcha(captchaElement, callback, siteKey);
    },
};
