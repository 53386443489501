import { observable } from 'knockout';
import appConfig from 'app/model/config';
import themeEvents from 'cx/module/site-editor/config/events';

export default class CustomFooterViewModel {

    constructor() {
        const { footerMode } = appConfig;

        this.customFooterEnable = observable(footerMode === 2 || footerMode === 4);
        this.customFooterContent = observable(false);

        this._themeEditorEvent = themeEvents.customFooterUpdated.add((enabled, content) => {
            this.customFooterEnable(enabled);
            this.customFooterContent(content);
        });

        if (appConfig.theme) {
            this.customFooterContent(appConfig.theme.brand.footerHtml);
        }

        this.templateExists = Boolean(document.getElementById('custom-footer'));
    }

    dispose() {
        this._themeEditorEvent.detach();
    }

}
