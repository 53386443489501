import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import appConfig from 'app/model/config';
import cxEvents from 'cx/config/events';
import notificationsService from 'cx/service/notifications';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import { profileImportTypes } from 'cx/module/apply-flow/module/profile-import/config/importTypes';
import profileImportService from 'cx/module/apply-flow/module/profile-import/service/profileImport';
import {
    getUserProfileImportSelection,
    ProfileImportSelection,
    setUserProfileImportSelection,
    setIsProfileImportPopUpClicked,
    getIsProfileImportPopUpClicked,
} from 'cx/service/uploadedResumeInfoProvider';
import applyFlowEvents from 'apply-flow/config/events';
import { SignalBinding } from 'signals';

type Props = {
    isProfileExists: Observable<boolean>;
    isDraftExists: Observable<boolean>;
    profileLastUpdateDate: Observable<number>;
    profileImportBlockExists: Observable<boolean>;
    supportingDocumentsBlockExists: Observable<boolean>;
};

type ProfileImportStatus = 'success' | 'error' | 'loading';

export class RecommendedJobsResumeImportHandlerViewModel {
    isProfileExists: Observable<boolean>;
    isDraftExists: Observable<boolean>;
    showConfirmationDialog: Observable<boolean>;
    profileLastUpdateDate: PureComputed<string>;
    dateFormatter: LocaleBasedDateTimeFormatter;
    profileImportBlockExists: Observable<boolean>;
    supportingDocumentsBlockExists: Observable<boolean>;
    profileImportSubscription: SignalBinding<() => void>;
    userProfileImportSelection: ProfileImportSelection | undefined;
    popupMessageLabel: string;
    profileButtonLabel: string;

    constructor({
        isDraftExists,
        isProfileExists,
        profileLastUpdateDate,
        profileImportBlockExists,
        supportingDocumentsBlockExists,
    }: Props) {
        this.isProfileExists = isProfileExists;
        this.isDraftExists = isDraftExists;
        this.profileImportBlockExists = profileImportBlockExists;
        this.supportingDocumentsBlockExists = supportingDocumentsBlockExists;
        this.dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);
        this.popupMessageLabel = 'apply-flow.recommended-jobs-profile-selection-overlay.profile-data-content';
        this.profileButtonLabel = 'apply-flow.profile-selection-overlay.use-existing-profile-button-label';

        this.showConfirmationDialog = observable<boolean>(false);

        this.profileLastUpdateDate = pureComputed(() =>
            this.dateFormatter.formatDate(profileLastUpdateDate())
        );

        this.importData();

        this.profileImportSubscription = this.handleProfileImportEvents();
    }

    private importData(): void {
        const userProfileSelection = getUserProfileImportSelection();

        if (this.isProfileExists()) {
            if (!this.isDraftExists()) {
                if (!userProfileSelection) {
                    this.showConfirmationDialog(true);

                    return;
                } else if (userProfileSelection === 'resume_data') {
                    this.fillApplyFlowWithUploadedResumeData();
                }
            } else if (!getIsProfileImportPopUpClicked()) {
                this.popupMessageLabel =
                    'apply-flow.recommended-jobs-profile-selection-overlay.draft-data-content';

                this.profileButtonLabel =
                    'apply-flow.recommended-jobs-profile-selection-overlay.import-draft-button-label';

                this.showConfirmationDialog(true);
            }
        } else if (!this.isDraftExists()) {
            this.fillApplyFlowWithUploadedResumeData();
        }
    }

    useMyProfile(): void {
        this.showConfirmationDialog(false);
        this.userProfileImportSelection = 'profile_data';
        setIsProfileImportPopUpClicked(true);
    }

    useMyResume(): void {
        this.showConfirmationDialog(false);
        this.userProfileImportSelection = 'resume_data';
        setIsProfileImportPopUpClicked(true);
        this.fillApplyFlowWithUploadedResumeData();
    }

    private async fillApplyFlowWithUploadedResumeData(): Promise<void> {
        try {
            this.handleProfileImportStatusChange('loading');

            const profileData = await profileImportService.getProfileFromRecommendedJobsResume(
                this.supportingDocumentsBlockExists()
            );

            if (!profileData) {
                this.handleProfileImportStatusChange('error');

                return;
            }

            await profileImportService.fillApplyFlow(profileData, profileImportTypes.resume);

            this.handleProfileImportStatusChange('success');
        } catch (error) {
            this.handleProfileImportStatusChange('error');
        }
    }

    private handleProfileImportStatusChange(status: ProfileImportStatus): void {
        status === 'loading' ? this.showLoadingSpinner() : this.hideLoadingSpinner();

        if (this.profileImportBlockExists()) {
            const message =
                status === 'error'
                    ? !this.isProfileExists()
                        ? 'apply-flow.profile-import.import-from-resume.new-candidate-profile-import-failure'
                        : 'apply-flow.profile-import.import-from-indeed.error-profile-import-block-present-returning-candidate'
                    : '';

            profileImportEvents.recommendedJobProfileImport.dispatch({ status, message });

            return;
        }

        if (status === 'loading') {
            return;
        }

        const message =
            status === 'success'
                ? 'apply-flow.profile-import.import-from-resume.success-toast'
                : this.isProfileExists()
                ? 'apply-flow.profile-import.import-from-resume.existing-candidate-failure-toast'
                : 'apply-flow.profile-import.import-from-resume.new-candidate-failure-toast';

        notificationsService[status](i18n(message), 0);
    }

    private showLoadingSpinner(): void {
        cxEvents.loading.dispatch();
    }

    private hideLoadingSpinner(): void {
        cxEvents.loaded.dispatch();
    }

    private handleProfileImportEvents(): SignalBinding<() => void> {
        return applyFlowEvents.submit.add(() => {
            setUserProfileImportSelection('profile_data');
        });
    }

    dispose(): void {
        this.profileImportSubscription.detach();
    }
}
