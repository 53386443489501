import { bindingHandlers } from 'knockout';

const REGEX = /([a-z-]*): ([a-z(),.0-9#'\s]*)(\s+![a-z]*)?\s?; \/\* ([a-zA-Z]*) \*\//gm;
const REGEX_SELECTOR_CSS = /([^.|#])([a-zA-Z-_:#. ]*)(,| {|{)/gm;
let initialStyleTemplate = '';

/**
 * Apply custom styles to element based on pased values
 *
 * @example
 * <style data-bind="applyStyles: $data">
 *     .class {
 *         property: value !modifier; \/* valueKey *\/
 *     }
 * </style>
 */
bindingHandlers.applyStyles = {
    init(element, valueAccessor) {
        const { uniqueWrapperSelector } = valueAccessor();

        initialStyleTemplate = element.textContent
            .replace(REGEX_SELECTOR_CSS, match => `.${uniqueWrapperSelector} ${match}`);
    },

    update(element, valueAccessor) {
        const { values } = valueAccessor();

        function replaceValues(...args) {
            const [, property, , modifier, valueKey] = args;
            const value = values[valueKey]();

            if (!value) {
                return '';
            }

            return `${property}: ${value}${modifier || ''}; /* ${valueKey} */`.trim();
        }

        const styleTemplate = initialStyleTemplate
            .replace(REGEX, replaceValues);

        element.textContent = styleTemplate;
    },
};