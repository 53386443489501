import { bindingHandlers } from 'knockout';

bindingHandlers.preventEnter = {
    init(element) {
        element.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
            }
        });
    },
};
