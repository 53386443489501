import { observable, observableArray, pureComputed } from 'knockout';
import linksConfig from 'core/config/links';
import appConfig from 'app/model/config';
import themeEvents from 'cx/module/site-editor/config/events';
import pageLinksProvider from 'app/service/pageLinksProvider';
import { showCookiePolicyOrPreferenceDialog } from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_LINK } from '../app-menu/cookie-consent-header-identifier';

export default class AppFooterViewModel {

    constructor() {
        const { footerMode } = appConfig;

        this.footerEnable = observable(footerMode === 1 || footerMode === 4);
        this.footerLinks = observableArray(pageLinksProvider.getFooterPageLinks());
        this.footerMobileLinks = pureComputed(this._computeMobileLinks, this);

        this._themeEditorEvent = themeEvents.templateFooterUpdated.add((footerEnable) => {
            this.footerEnable(footerEnable);
        });
    }

    dispose() {
        this._themeEditorEvent.detach();
    }

    showCookieConsentPreferences() {
        showCookiePolicyOrPreferenceDialog();
    }

    _computeMobileLinks() {
        const mobileLinks = [];

        const serchForMobileLinks = (link) => {
            if (link.visibleOnMobile) {
                mobileLinks.push(link);
            }

            if (link.sublinks) {
                link.sublinks.forEach(serchForMobileLinks);
            }
        };

        this.footerLinks().forEach(serchForMobileLinks);

        return mobileLinks.slice(0, linksConfig.footer.MOBILE_LINKS_LIMIT);
    }

    getCookieConsentIdentifier() {
        return COOKIE_CONSENT_LINK;
    }

}
