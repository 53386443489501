import mapping from 'knockout-mapping';

const AMP = '&&';
const PIPE = '||';
const NULL_VALUE = '<NULL>';

function _processValue(value, appendNull) {
    if (value && Array.isArray(value)) {
        return value.join(',');
    }

    if (value && typeof value === 'string') {
        return value.replace(PIPE, '').replace(AMP, '');
    }

    if (appendNull === true) {
        return NULL_VALUE;
    }

    return '';
}

export default {
    mapDataToRest(regulatoryResponses, jobId, appendNull = false) {
        return {
            requisitionNumber: jobId,
            regulatoryResponses: this.mapRegulatoryResponsesToRest(regulatoryResponses, appendNull),
        };
    },

    mapRegulatoryResponsesToRest(regulatoryResponses, appendNull) {
        const response = this.mapRegulatoryResponseToRest(regulatoryResponses, appendNull);

        return JSON.stringify(response);
    },

    mapRegulatoryResponseToRest(regulatoryResponses, appendNull) {
        const regulatoryResponsesList = [];

        regulatoryResponses.forEach((regulatoryResponse) => {
            const rawRegulatoryResponse = mapping.toJS(regulatoryResponse);
            const [legislation, fieldType, attributeName, columnName] = rawRegulatoryResponse.id.split('-');
            const flexColumnAttribute = fieldType === 'STANDARD' ? '' : columnName;

            if (regulatoryResponsesList.some(responseObject => responseObject.LegislationCode === legislation)) {
                regulatoryResponsesList.forEach((response) => {
                    if (response.LegislationCode === legislation) {
                        const processedResponse = _processValue(rawRegulatoryResponse.value, appendNull);

                        if (processedResponse !== '') {
                            const regulatoryResponseString =
                                [fieldType, attributeName, processedResponse, flexColumnAttribute]
                                    .join(PIPE);

                            response.Response = [response.Response, regulatoryResponseString].join(AMP);
                        }
                    }
                });
            } else {
                const processedResponse = _processValue(rawRegulatoryResponse.value, appendNull);

                if (processedResponse !== '') {
                    regulatoryResponsesList.push({
                        LegislationCode: legislation,
                        Response: [fieldType, attributeName, processedResponse, flexColumnAttribute].join(PIPE),
                    });
                }
            }
        });

        return regulatoryResponsesList;
    },
};
