import {
    noHoverOnly,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
    getBorderColorCss,
    getBackgroundColorCss,
    StylingBox,
    ObservableParams,
} from 'minimal/module/search/service/customCss';
import { notReachable } from 'app/types/utils';
import { FiltersParams } from '../config/types';
import { getHeaderAreaCss } from './headerAreaCss';
import { FiltersCustomizationSelectors } from '../config/selectors';
import { defaultFiltersParams } from '../config/defaultParams';
import {
    getParamsValueMergeFunction,
    mergeObservableParams,
} from 'minimal/module/search/service/observableParams';

export const getCustomStyles = (params: FiltersParams, selectors: FiltersCustomizationSelectors): string => {
    const customizationKeys = Object.keys(params) as (keyof FiltersParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'contentParams':
                return getMaxWidthCss({
                    selector: selectors.container,
                    width: params[key].width(),
                    widthUnit: params[key].widthUnit(),
                });

            case 'headerTypography':
                return getTypographyCss({
                    selector: selectors.header,
                    typography: params[key],
                });

            case 'filtersTypography':
                return getTypographyCss({
                    selector: selectors.filters,
                    typography: params[key],
                });

            case 'filtersTypographySelected':
                const filtersTypographySelected = mergeObservableParams({
                    sourceParams: params.filtersTypography,
                    targetParams: params.filtersTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.filtersTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.filtersSelected,
                    typography: filtersTypographySelected,
                });

            case 'filtersTypographyHover':
                const filtersTypographyHover = mergeObservableParams({
                    sourceParams: params.filtersTypography,
                    targetParams: params.filtersTypographyHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.filtersTypographyHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.filtersHover,
                    typography: filtersTypographyHover,
                });

            case 'clearFiltersTypography':
                return getTypographyCss({
                    selector: selectors.clearFilters,
                    typography: params[key],
                });

            case 'searchValuesDropdownTypography':
                return getTypographyCss({
                    selector: selectors.searchValueDropdown,
                    typography: params[key],
                });

            case 'searchValuesDropdownTypographySelected':
                const selectedDropdownTypography = mergeObservableParams({
                    sourceParams: params.searchValuesDropdownTypography,
                    targetParams: params.searchValuesDropdownTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.searchValuesDropdownTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.searchValueDropdownSelected,
                    typography: selectedDropdownTypography,
                });

            case 'jobsEventsToggleDropdownTypography':
                return getTypographyCss({
                    selector: selectors.jobsEventsToggleDropdown,
                    typography: params[key],
                });

            case 'jobsEventsToggleDropdownTypographyHover':
                return getTypographyCss({
                    selector: selectors.jobsEventsToggleDropdownHover,
                    typography: params[key],
                });

            case 'jobsEventsToggleDropdownTypographySelected':
                const jobsEventsToggleDropdownTypographySelected = mergeObservableParams({
                    sourceParams: params.jobsEventsToggleDropdownTypography,
                    targetParams: params.jobsEventsToggleDropdownTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.jobsEventsToggleDropdownTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobsEventsToggleDropdownSelected,
                    typography: jobsEventsToggleDropdownTypographySelected,
                });

            case 'jobsEventsToggleDropdownTypographySelectedHover':
                const jobsEventsToggleDropdownTypographySelectedHover = mergeObservableParams({
                    sourceParams: params.jobsEventsToggleDropdownTypography,
                    targetParams: params.jobsEventsToggleDropdownTypographySelectedHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.jobsEventsToggleDropdownTypographySelectedHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobsEventsToggleDropdownSelectedHover,
                    typography: jobsEventsToggleDropdownTypographySelectedHover,
                });

            case 'jobsEventsToggleCountDropdownTypography':
                return getTypographyCss({
                    selector: selectors.jobsEventsToggleCountContainer,
                    typography: params[key],
                });

            case 'jobsEventsToggleCountDropdownTypographyHover':
                return getTypographyCss({
                    selector: selectors.jobsEventsToggleCountContainerHover,
                    typography: params[key],
                });

            case 'jobsEventsToggleCountDropdownTypographySelected':
                const jobsEventsToggleCountDropdownTypographySelected = mergeObservableParams({
                    sourceParams: params.jobsEventsToggleCountDropdownTypography,
                    targetParams: params.jobsEventsToggleCountDropdownTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.jobsEventsToggleCountDropdownTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobsEventsToggleCountDropdownSelected,
                    typography: jobsEventsToggleCountDropdownTypographySelected,
                });

            case 'jobsEventsToggleCountDropdownTypographySelectedHover':
                const jobsEventsToggleCountDropdownTypographySelectedHover = mergeObservableParams({
                    sourceParams: params.jobsEventsToggleCountDropdownTypography,
                    targetParams: params.jobsEventsToggleCountDropdownTypographySelectedHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams:
                            defaultFiltersParams.jobsEventsToggleCountDropdownTypographySelectedHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.jobsEventsToggleCountDropdownSelectedHover,
                    typography: jobsEventsToggleCountDropdownTypographySelectedHover,
                });

            case 'container':
                return getStylingBoxCss({
                    selector: selectors.root,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'filterStyles':
                return getStylingBoxCss({
                    selector: selectors.container,
                    stylingBox: params[key],
                    lightIconSelector: `${selectors.icon}`,
                    darkIconSelector: null,
                });

            case 'filterStylesOnHover':
                const filterStylesOnHover = mergeObservableParams({
                    sourceParams: params.subHeaderStylingBox,
                    targetParams: params.filterStylesOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.filterStylesOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.subHeaderHover,
                    stylingBox: filterStylesOnHover,
                    lightIconSelector: selectors.iconHover,
                    darkIconSelector: null,
                });

            case 'headerStyles':
                if (params.contentParams.filtersDisplayStyle() === 'horizontal') {
                    return '';
                }

                return getHeaderAreaCss({
                    colorSelector: selectors.headerLine,
                    displaySelector: selectors.headerLine,
                    styling: params[key],
                });

            case 'subHeaderStyles':
                return getHeaderAreaCss({
                    colorSelector: `${selectors.subHeaderLine}, ${selectors.sectionLine}`,
                    displaySelector: selectors.subHeaderLine,
                    styling: params[key],
                });

            case 'searchDisplayStyles':
                return getStylingBoxCss({
                    selector: selectors.searchDisplay,
                    stylingBox: params[key],
                    lightIconSelector: `${selectors.searchIcon}`,
                    darkIconSelector: null,
                });

            case 'jobsEventsToggleStylingBox':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggle,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                    customSelectors: { margin: selectors.jobsEventsToggleContainer },
                });

            case 'jobsEventsToggleStylesHover':
                return getJobsEventsToggleStylesHoverStyling(selectors, params, key);

            case 'jobsEventsToggleSelectedStylingBox':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggleSelected,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                    customSelectors: { margin: selectors.jobsEventsToggleSelected },
                });

            case 'jobsEventsToggleStylesSelectedHover':
                return getSelectedToggleStyling(selectors, params, key);

            case 'jobsEventsToggleCountStylingBox':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggleCountContainer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                    customSelectors: { margin: selectors.jobsEventsToggleContainer },
                });

            case 'jobsEventsToggleCountStylesHover':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggleCountContainerHover,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                    customSelectors: { margin: selectors.jobsEventsToggleCountContainerHover },
                });

            case 'jobsEventsToggleCountSelectedStylingBox':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggleCountSelected,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'jobsEventsToggleCountStylesSelectedHover':
                return getStylingBoxCss({
                    selector: selectors.jobsEventsToggleCountSelectedContainerHover,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'subHeaderStylingBox':
                return getStylingBoxCss({
                    selector: selectors.subHeader,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                    customSelectors: { margin: selectors.subHeaderContainer },
                });

            case 'subHeaderStylesSelected':
                return getStylingBoxCss({
                    selector: selectors.subHeaderSelected,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'subHeaderStylesSelectedHover':
                const subHeaderStylesSelectedHover = mergeObservableParams({
                    sourceParams: params.subHeaderStylingBox,
                    targetParams: params.subHeaderStylesSelectedHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.subHeaderStylesSelectedHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.subHeaderSelectedHover,
                    stylingBox: subHeaderStylesSelectedHover,
                    lightIconSelector: selectors.selectedIconHover,
                    darkIconSelector: null,
                });

            case 'subHeaderTypography':
                return getTypographyCss({
                    selector: selectors.subHeaderText,
                    typography: params[key],
                });

            case 'subHeaderTypographySelected':
                const subHeaderTypographySelected = mergeObservableParams({
                    sourceParams: params.subHeaderTypography,
                    targetParams: params.subHeaderTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.subHeaderTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.subHeaderTextSelected,
                    typography: subHeaderTypographySelected,
                });

            case 'subHeaderTypographyHover':
                const subHeaderTypographyHover = mergeObservableParams({
                    sourceParams: params.subHeaderTypography,
                    targetParams: params.subHeaderTypographyHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.subHeaderTypographyHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.subHeaderTextHover,
                    typography: subHeaderTypographyHover,
                });

            case 'subHeaderTypographySelectedHover':
                const subHeaderTypographySelectedHover = mergeObservableParams({
                    sourceParams: params.subHeaderTypography,
                    targetParams: params.subHeaderTypographySelectedHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultFiltersParams.subHeaderTypographySelectedHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.subHeaderTextSelectedHover,
                    typography: subHeaderTypographySelectedHover,
                });

            case 'filterDropdownStyles':
                return getStylingBoxCss({
                    selector: selectors.filtersDropdown,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'filtersCounterTypography':
                return getTypographyCss({
                    selector: selectors.filtersCounter,
                    typography: params[key],
                });

            case 'filtersCounterStyles':
                return getStylingBoxCss({
                    selector: selectors.filtersCounter,
                    stylingBox: params[key],
                    lightIconSelector: `${selectors.icon}`,
                    darkIconSelector: null,
                });

            case 'filterVerticalSeparator':
                return getStylingBoxCss({
                    selector: selectors.miscSpacer,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    });

    return customStyles.join(STYLES_SEPARATOR);
};

const getJobsEventsToggleStylesHoverStyling = (
    selectors: FiltersCustomizationSelectors,
    params: FiltersParams,
    key: keyof FiltersParams
): string => {
    const param = params[key] as ObservableParams<StylingBox>;

    const hoverCss = getStylingBoxCss({
        selector: selectors.jobsEventsToggleStylesHover,
        stylingBox: param,
        lightIconSelector: null,
        darkIconSelector: null,
    });

    // default border
    const borderColorCss = getBorderColorCss({
        selector: selectors.jobsEventsToggleStylesHover,
        color: 'hsla(var(--color-secondary-hue), var(--color-secondary-saturation), var(--color-secondary-lightness), 0.3)',
    });

    // default background
    const backgroundColorCss = getBackgroundColorCss({
        selector: selectors.jobsEventsToggleStylesHover,
        value: 'hsla(var(--color-theme-1-hue), var(--color-theme-1-saturation), var(--color-theme-1-lightness), 0.9)',
    });

    const hoverBorderColorCss = getBorderColorCss({
        selector: selectors.jobsEventsToggleStylesActive,
        color: param.borderColor(),
    });

    const hoverBackgroundColorCss = getBackgroundColorCss({
        selector: selectors.jobsEventsToggleStylesActive,
        value: param.backgroundColor(),
    });

    const additionalHoverCss = noHoverOnly([backgroundColorCss, borderColorCss].join(STYLES_SEPARATOR));

    const additionalActiveCss = noHoverOnly(
        [hoverBackgroundColorCss, hoverBorderColorCss].join(STYLES_SEPARATOR)
    );

    return [hoverCss, additionalHoverCss, additionalActiveCss].join(STYLES_SEPARATOR);
};

const getSelectedToggleStyling = (
    selectors: FiltersCustomizationSelectors,
    params: FiltersParams,
    key: keyof FiltersParams
): string => {
    const param = params[key] as ObservableParams<StylingBox>;

    const selectedHoverStyling = getStylingBoxCss({
        selector: selectors.jobsEventsToggleSelectedHover,
        stylingBox: param,
        lightIconSelector: null,
        darkIconSelector: null,
    });

    const borderColorCss = getBorderColorCss({
        selector: selectors.jobsEventsToggleSelectedHover,
        color: params['jobsEventsToggleSelectedStylingBox'].borderColor(),
    });

    const backgroundColorCss = getBackgroundColorCss({
        selector: selectors.jobsEventsToggleSelectedHover,
        value: params['jobsEventsToggleSelectedStylingBox'].backgroundColor(),
    });

    const hoverBorderColorCss = getBorderColorCss({
        selector: selectors.jobsEventsToggleSelectedActive,
        color: param.borderColor(),
    });

    const hoverBackgroundColorCss = getBackgroundColorCss({
        selector: selectors.jobsEventsToggleSelectedActive,
        value: param.backgroundColor(),
    });

    const additionalHoverCss = noHoverOnly([backgroundColorCss, borderColorCss].join(STYLES_SEPARATOR));

    const additionalActiveCss = noHoverOnly(
        [hoverBackgroundColorCss, hoverBorderColorCss].join(STYLES_SEPARATOR)
    );

    return [selectedHoverStyling, additionalHoverCss, additionalActiveCss].join(STYLES_SEPARATOR);
};
