import candidateModel from 'apply-flow/model/candidate';
import draftAttachmentService from '../../service/draftAttachmentService';
import notificationsService from 'cx/service/notifications';
import { applicationDraft } from '../../../../service/applicationDraftInstance';
import { download as downloadService } from '../../service/download';

export default class AttachmentPreuploadedViewModel {

    constructor({ attachments, finder }) {
        this.attachments = attachments;
        this.finder = finder;
        this.use = this._use.bind(this);
    }

    _use(preuploadedAttachment) {
        if (!applicationDraft) {
            this._replaceAttachment(preuploadedAttachment);

            return;
        }

        draftAttachmentService.copyAttachmentToDraft(applicationDraft.id, preuploadedAttachment)
            .then(this._replaceAttachment.bind(this))
            .catch((error) => {
                console.error(error);
                notificationsService.error();
            });
    }

    _replaceAttachment(newAttachment) {
        const existingAttachment = candidateModel.attachments().find(attachment => attachment[this.finder]());

        candidateModel.attachments.remove(existingAttachment);
        candidateModel.attachments.push(newAttachment);

        if (applicationDraft && existingAttachment) {
            draftAttachmentService.remove(applicationDraft.id, existingAttachment);
        }
    }

    download(attachment) {
        downloadService(attachment);
    }

}