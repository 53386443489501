import { observable, pureComputed, when } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import a11yEvents from 'core/a11y/events';
import tokenService from 'candidate-verification/service/token';
import sourceTraceService from 'source-tracing/service/sourceTrace';
import referralService from 'candidate-verification/service/referral';
import applyFlowEvents from 'apply-flow/config/events';
import flowService from 'apply-flow/service/flow';
import candidateModel from 'apply-flow/model/candidate';
import Application from 'apply-flow/model/Application';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import candidateService from 'apply-flow/service/candidate';
import notificationsService from 'cx/service/notifications';
import profileItemsDefaultBlockFinder from 'apply-flow/module/profile-items/service/defaultBlockFinder';
import profileItemServiceFactory from 'apply-flow/module/profile-items/service/profileItemServiceFactory';
import cxEvents from 'cx/config/events';
import applicationDraftService from './service/applicationDraft';
import { ApplicationDraftMonitor } from './service/applicationDraftMonitor';
import { applicationDraft } from 'apply-flow/service/applicationDraftInstance';
import { get, clear } from 'candidate-verification/service/legalDisclaimerAcceptanceStorage';
import appEvents from 'app/config/events';
import customJsValidationService from './module/custom-js-validation/service/customJsValidation';
import userTracking from '../../service/userTracking';
import profileImport from './module/profile-import/service/profileImport';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';
import { profileImportTypes } from 'cx/module/apply-flow/module/profile-import/config/importTypes';
import { hasUserResumeInfoInStorage } from 'cx/service//uploadedResumeInfoProvider';

export default class ApplyFlowViewModel {

    constructor() {
        this.flow = observable();
        this.legalDisclaimer = observable();
        this.application = observable(new Application());
        this.applicationDraftMonitor = new ApplicationDraftMonitor();
        this.isFlowVisible = observable(true);
        this.isNavigationVisible = observable(false);
        this.legalDisclaimerAcceptanceRequired = observable(false);
        this.isValidationInProgress = observable(false);
        this.summaryComponentName = 'apply-flow-summary';
        this.summaryTemplate = observable().extend({ notify: 'always' });
        this.jobId = router.routeParams().jobId;
        this.closeAction = this._closeAction.bind(this);
        this.acceptAgreementCallback = this._acceptAgreement.bind(this);
        this.isProfileImportLoading = observable(false);

        this.profileOrDraftExists = observable(false);
        this.profileImportBlockExists = observable(false);
        this.draftLastUpdateUnixDate = observable(0);
        this.profileLastUpdateUnixDate = observable(0);
        this.isProfileExists = observable(false);
        this.isDraftExists = observable(false);
        this.hasSupportingDocumentsBlock = observable(false);

        this.lastUpdateISOStringDate = pureComputed(() =>
            new Date(Math.max(this.draftLastUpdateUnixDate(), this.profileLastUpdateUnixDate())).toISOString());

        const { sourceContext } = router.routeParams();

        this.importFromIndeedOnApplyFlowLoad = observable(sourceContext === 'indeedApply');
        this.performImportFromIndeed = observable(false);
        this.importFromRecommendedJobsResumeOnApplyFlowLoad = hasUserResumeInfoInStorage();
        this.performImportFromResume = observable(false);

        this._profileImportLoadingSignal = applyFlowEvents.profileImportLoading.add(() => {
            this.isProfileImportLoading(true);
        });

        this._profileImportSucceedSignal = applyFlowEvents.profileImportSucceed.add(() => {
            this.isProfileImportLoading(false);
        });

        cxEvents.loading.dispatch();

        this.flowIterator = pureComputed(() => {
            const flow = this.flow();

            return flow ? flow.iterator() : undefined;
        });

        this.isSingleClickFlow = pureComputed(() => {
            const iterator = this.flowIterator();

            return iterator ? iterator.isSingleClick() : false;
        });

        this.isSingleClickNavVisible = pureComputed(() => {
            const isFlowVisible = this.isFlowVisible();
            const isSingleClickFlow = this.isSingleClickFlow();

            return isFlowVisible && isSingleClickFlow;
        });

        profileItemServiceFactory.initialize();
        customJsValidationService.checkValidators();

        this._loadCandidate()
            .then(this._loadFlow.bind(this))
            .then(this._determineDefaultProfileItemsBlock.bind(this))
            .then(this._initializeSectionState.bind(this))
            .then(this._profileImportBlockExists.bind(this))
            .then(this._doesSupportingDocumentsBlockExists.bind(this))
            .then(this._initializeSectionValidator.bind(this))
            .then(this._loadApplicationDraft.bind(this))
            .then(this._initializeLegalDisclaimer.bind(this))
            .then(this._renderFlow.bind(this))
            .then(this._validateSectionsAssessmentBack.bind(this))
            .then(this._userTrackingJobApplyFlow.bind(this))
            .catch(error => this._handleError(error));

        this._createSourceTrace();

        this._unauthorizedSignal = appEvents.unauthorizedError.add(() => {
            this.summaryTemplate('token-expired-apply-flow');
            this._submitFailed();
        });

        applyFlowEvents.submitSucceed.add(() => this._submitSucceed());
        applyFlowEvents.submitFailed.add(() => this._submitFailed());
        applyFlowEvents.navigationOpen.add(this.isNavigationVisible);
        applyFlowEvents.submit.add(this.applicationDraftMonitor.stop);
        applyFlowEvents.restartDraftMonitor.add(this.startApplicationDraftMonitor, this);
    }

    async startApplicationDraftMonitor() {
        if (applicationDraftService.draftExists()) {
            const isAllSectionsReady = sectionState.isAllSectionsReady();

            when(() => isAllSectionsReady(), async () => {
                await this.applicationDraftMonitor.start();
                cxEvents.loaded.dispatch();
                this.profileLastUpdateUnixDate(applicationDraft.candidateModificationDate?.valueOf() || 0);

                if (this.importFromIndeedOnApplyFlowLoad()) {
                    this.performImportFromIndeed(true);
                } else if (this.importFromRecommendedJobsResumeOnApplyFlowLoad) {
                    this.performImportFromResume(true);
                }
            });
        }
    }

    _userTrackingJobApplyFlow() {
        userTracking.trackJobApplyIn(router.routeParams().jobId,
            router.routeParams().sectionNumber,
            this.flowIterator().lastSectionNumber);
    }

    _handleError(error) {
        console.error(error);

        notificationsService.error();
    }

    _createSourceTrace() {
        if (sourceTraceService.shouldTraceSource(this.jobId)) {
            const referralHitCountResponse = referralService.getHitCountResponse(this.jobId) || {};

            const sourceTraceData = {
                sourceLevel: sourceTraceService.LEVEL.JOB_APPLICATION,
                requisitionNumber: this.jobId,
                referralId: referralHitCountResponse.referralId,
                shareId: referralHitCountResponse.shareId,
                tokenId: tokenService.get().tokenId,
            };

            return sourceTraceService.create(sourceTraceData);
        }

        return null;
    }

    _validateSectionsAssessmentBack() {
        const { assessmentSection, element } = router.routeParams();
        const isAllSectionsReady = sectionState.isAllSectionsReady();

        if (!element) {
            return;
        }

        isAllSectionsReady.subscribeOnce(() => {
            for (let i = 1; i < assessmentSection; i++) {
                sectionState.setSectionVisited(i);
                sectionValidator.validateSection(i);
            }
        });
    }

    _submitSucceed() {
        this.isFlowVisible(false);
    }

    _submitFailed() {
        this.isFlowVisible(false);
        this.applicationDraftMonitor.stop();
    }

    _closeAction() {
        this.applicationDraftMonitor.stop();
        this.applicationDraftMonitor.clearDraft();

        profileImport.clearIndeedProfileFromCache();

        const route = router.route();

        if (route.parent && route.parent.id === 'job-preview') {
            router.go('job-preview', {
                jobId: this.jobId,
            });
        } else {
            router.go('job-details', { jobId: this.jobId });
        }
    }

    async _loadCandidate() {
        const token = tokenService.get();

        try {
            const candidate = await candidateService.loadForToken(candidateModel, token);

            if (candidate.id()) {
                this.profileOrDraftExists(true);
                this.isProfileExists(true);
            }
        } catch (error) {
            console.error('Unable to create candidate model.');
        }
    }

    _loadFlow() {
        return flowService.query(this.jobId);
    }

    _determineDefaultProfileItemsBlock(flow) {
        profileItemsDefaultBlockFinder.find(flow);

        return flow;
    }

    _loadApplicationDraft(flow) {
        return applicationDraftService.load(this.jobId, this.application())
            .then((draft) => {
                if (draft.draftModificationDate) {
                    this.profileOrDraftExists(true);
                    this.isDraftExists(true);
                    this.draftLastUpdateUnixDate(draft.draftModificationDate.valueOf());
                }

                return this._prepareDraft(draft, flow);
            });
    }

    _prepareDraft(draft, flow) {
        this.applicationDraftMonitor.setDraft(draft, candidateModel);
        this.application(applicationDraft.application);

        if (profileImportTypes.linkedin === applicationDraft.draftSource) {
            profileImportEvents.awliClicked.dispatch();
        }

        return candidateService.loadFromDraft(candidateModel, applicationDraft)
            .then(() => {
                applicationDraft.update({ candidate: candidateModel });

                return flow;
            })
            .catch(() => flow);
    }

    _renderFlow(flow) {
        return Promise.resolve(flow)
            .then(this.flow.bind(this))
            .then(this._notifySubscribers.bind(this))
            .then(this._subscribeForPageChange.bind(this))
            .then(() => {
                if (!this.importFromIndeedOnApplyFlowLoad() && !this.importFromRecommendedJobsResumeOnApplyFlowLoad) {
                    cxEvents.loaded.dispatch();
                }
            });
    }

    _subscribeForPageChange() {
        this._currentPageSub = this.flowIterator().currentSection.subscribe(() => {
            this._notifySubscribers();
        }, this);
    }

    _notifySubscribers() {
        const iterator = this.flowIterator();
        const currentSection = iterator.currentSection();

        a11yEvents.status.dispatch(
            i18n('apply-flow.a11y.section-loaded', {
                sectionname: currentSection.title,
            }),
        );
    }

    _initializeSectionValidator(flow) {
        sectionValidator.initialize(flow.sections);

        return flow;
    }

    _initializeSectionState(flow) {
        sectionState.initialize(flow.sections);

        return flow;
    }

    async _initializeLegalDisclaimer(flow) {
        if (this._shouldCandidateAcceptLegalDisclaimer(flow.legalDisclaimer)) {
            this.legalDisclaimer(flow.legalDisclaimer);
            this.legalDisclaimerAcceptanceRequired(true);
            this.isFlowVisible(false);
            cxEvents.loaded.dispatch();
        } else {
            await this.startApplicationDraftMonitor();
        }

        return flow;
    }

    _isLegalDisclaimerOutdated(flowVersionId) {
        return !applicationDraft || applicationDraft.legalDisclaimerId !== flowVersionId;
    }

    _shouldCandidateAcceptLegalDisclaimer({ isEnabled, versionId }) {
        const isAlreadyAccepted = this._getLegalDisclaimerAcceptanceFromStorage();
        const isOutdated = this._isLegalDisclaimerOutdated(versionId);

        if (isAlreadyAccepted) {
            applicationDraftService.updateLegalDisclaimerId(versionId);
        }

        return isEnabled && isOutdated && !isAlreadyAccepted;
    }

    dragEnterHandler(context, event) {
        event.stopPropagation();
        event.preventDefault();
        applyFlowEvents.dragEnter.dispatch();
    }

    dispose() {
        this.applicationDraftMonitor.stop();
        this.applicationDraftMonitor.clearDraft();

        if (this._currentPageSub) {
            this._currentPageSub.dispose();
        }

        this._unRegisterSignalHandlers();

        if (this.flow()) {
            this.flow().destroy();
        }
    }

    _unRegisterSignalHandlers() {
        if (this._unauthorizedSignal) {
            this._unauthorizedSignal.detach();
        }

        if (this._profileImportLoadingSignal) {
            this._profileImportLoadingSignal.detach();
        }

        if (this._profileImportSucceedSignal) {
            this._profileImportSucceedSignal.detach();
        }

        Object.keys(applyFlowEvents).forEach((signalName) => {
            applyFlowEvents[signalName].removeAll();
        });
    }

    _acceptAgreement() {
        if (this.importFromIndeedOnApplyFlowLoad() || this.importFromRecommendedJobsResumeOnApplyFlowLoad) {
            cxEvents.loading.dispatch();
        }

        this.legalDisclaimerAcceptanceRequired(false);
        applicationDraftService.updateLegalDisclaimerId(this.legalDisclaimer().versionId);
        this.startApplicationDraftMonitor();
        this.isFlowVisible(true);
    }

    _getLegalDisclaimerAcceptanceFromStorage() {
        const candidateHasAcceptedLegalDisclaimer = get(this.jobId);

        clear();

        return candidateHasAcceptedLegalDisclaimer;
    }

    _profileImportBlockExists(flow) {
        const { preSection } = flow;

        preSection.pages.forEach((page) => {
            page.blocks.forEach((block) => {
                if (block.code === 'ORA_PROFILE_IMPORT') {
                    this.profileImportBlockExists(true);
                }
            });
        });

        return flow;
    }

    _doesSupportingDocumentsBlockExists(flow) {
        const { sections } = flow;
        let allBlocks = [];

        sections.forEach((section) => {
            section.pages.forEach((page) => {
                allBlocks = allBlocks.concat(page.blocks.map(block => block.code));
            });
        });

        this.hasSupportingDocumentsBlock(allBlocks.indexOf('ORA_DOCUMENTS') > -1);

        return flow;
    }

}
