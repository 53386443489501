import ValidatableFormElement from 'core/form/element/ValidatableElement';
import { observable } from 'knockout';

export default class CheckboxFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.attributes('type', 'checkbox');
        this.component('checkbox-form-element');
        this.isSlider = observable();
    }

}