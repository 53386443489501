import { register } from 'ce-common/service/templateRepository';

import alreadyApplied from './already-applied.html';
import pinCodeRequired from './pin-code-required.html';
import candidateExists from './candidate-exists.html';
import tokenExpired from './token-expired.html';
import unconfirmedRegistration from './unconfirmed-registration.html';
import confirmedRegistration from './confirmed-registration.html';
import previouslyDisqualified from './disqualified.html';

register('event-register-flow-summary-already-applied', alreadyApplied);
register('event-register-flow-summary-pin-code-required', pinCodeRequired);
register('event-register-flow-summary-candidate-exists', candidateExists);
register('event-register-flow-summary-token-expired-apply-flow', tokenExpired);
register('event-register-flow-summary-unconfirmed-registration', unconfirmedRegistration);
register('event-register-flow-summary-confirmed-registration', confirmedRegistration);
register('event-register-flow-summary-previously-disqualified', previouslyDisqualified);