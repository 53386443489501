import MultiSelectPillDictionaryFormElementViewModel from 'core/form/component/multiselect-pill-form-element/MultiSelectPillDictionaryFormElementViewModel';
import { observable, observableArray, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';

export default class CsDropdownMultiselectPillPreferenceViewModel
    extends MultiSelectPillDictionaryFormElementViewModel {

    constructor({ siteNumber, model, fetchAvailablePreferencesFunction, placeholder }) {
        super({ model, filterRepository: null, config: { markSelectedDisabled: true, possibleValueValue: 'id' } });

        this.availablePreferences = observableArray([]);

        this._fetchFromRepository(fetchAvailablePreferencesFunction, siteNumber)
            .then(preferences => this.availablePreferences(preferences));

        this.placeholder = i18n(placeholder);

        this.rawValue = observable(null);

        this.showNoResultsHint = pureComputed(this._showNoResultsHint, this);
    }

    addFilter(filter) {
        this._changePreferenceState(filter.value(), true);

        super.addFilter(filter);
    }

    removeFilter(filter) {
        this._changePreferenceState(filter.value(), false);

        super.removeFilter(filter);
    }

    _changePreferenceState(preferenceId, disabled) {
        const preferenceToChange = this.availablePreferences().find(preference => preference.id === preferenceId);

        this.availablePreferences.replace(preferenceToChange, Object.assign({}, preferenceToChange, { disabled }));
    }

    _showNoResultsHint() {
        if (!this.rawValue()) {
            return false;
        }

        return !this.availablePreferences()
            .find(({ name }) => name.toLowerCase().includes(this.rawValue()));
    }

}