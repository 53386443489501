import { unwrap } from 'knockout';
import mapping from 'knockout-mapping';
import QuestionModel from './Question';

const mappingOptions = {
    questions: {
        key({ questionId }) {
            return unwrap(questionId);
        },
        create({ data }) {
            if (data instanceof QuestionModel) {
                return data;
            }

            return new QuestionModel(data);
        },
    },
};

export default class Questionnaire {

    constructor(data = {}) {
        const model = {
            blockCode: data.blockCode,
            readWriteServiceUrl: undefined,
            questionnaireId: undefined,
            questionnaireVersionNumber: undefined,
            questions: [],
        };

        if (data) {
            Object.assign(model, data);
        }

        mapping.fromJS(model, mappingOptions, this);
    }

}