import { COLUMN_LAYOUTS } from 'cx/module/custom-content/config/columnLayouts';

function getLayoutSizes(layoutId) {
    const hasSameIdAs = id => item => item.id === id;

    const matchedLayout = COLUMN_LAYOUTS
        .find(hasSameIdAs(layoutId));

    const { sizes } = matchedLayout;

    return sizes;
}

export function setColumnSizes(layoutId, columns) {
    const layoutSizes = getLayoutSizes(layoutId);

    columns.forEach((column, index) => {
        const columnSize = layoutSizes.length
            ? layoutSizes[index]
            : 'default';

        column.size(columnSize);
        column.order(index);
    });
}