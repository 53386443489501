import ko, { observable, pureComputed } from 'knockout';
import ProfileItemsViewModel from '../profile-items/ProfileItemsViewModel';
import fixer from 'cx/module/apply-flow/component/apply-flow-fixer/service/fixer';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';
import FormChangeDetector from 'core/form/service/formChangeDetector';
import applyFlowEvents from '../../config/events';
import sectionState from 'apply-flow/model/sectionState';
import { setFocusWithDelay } from 'core/a11y/service/setFocusWithDelay';
import sectionValidator from 'apply-flow/model/sectionValidator';
import { isBlockRequired } from 'apply-flow/component/apply-flow-block/service/isBlockRequired';


const ADD_BUTTON_MAP = {
    [contentTypes.CERTIFICATION_ID]: 'apply-flow.profile-item.add-license-button',
    [contentTypes.EDUCATION_ID]: 'apply-flow.section-experience.education-button',
    [contentTypes.LANGUAGE_ID]: 'apply-flow.profile-item.add-language-button',
    [contentTypes.EXPERIENCE_ID]: 'apply-flow.section-experience.experience-button',
    [contentTypes.SKILL_ID]: 'apply-flow.profile-item.add-skill-button',
};

export default class TileProfileItemsViewModel extends ProfileItemsViewModel {

    constructor(...args) {
        super(...args);

        this.isReady = ko.pureComputed(() => {
            const [{
                blockId,
                section: {
                    number: sectionNumber,
                } = {},
            } = {}] = args;

            return ko.unwrap(sectionState.isBlockReady(sectionNumber, blockId)) ?? true;
        });

        this.blockToScroll = null;
        this.addButtonId = `profileItemsAddButton-${Date.now()}`;
        this.addButtonLabel = this._getButtonLabel();
        this.showRemoveButton = ko.observable(true);
        this._shouldBuildTilesOnInit = true;

        this.canAddProfileItem = ko.pureComputed(() => this._canAddProfileItem());

        this.activeForm = ko.pureComputed(() => {
            const [activeForm] = this.forms().filter(form => form().isActive());

            return activeForm ? activeForm() : undefined;
        });

        this.hasActiveForm = ko.pureComputed({
            read: () => Boolean(this.activeForm()),
            write: (state) => {
                if (!state) {
                    this.doneProfileItem(this.activeForm());
                }
            },
        });

        this.section.addBeforeExitCallback(this._collapseTiles.bind(this));

        this.focusCallback = fixer.focusInvalid;

        this.qaAttributeAdd = `add${this._contentTypeId}`;
        this.qaAttributeItem = `profileItem${this._contentTypeId}`;

        this.isBlockRequired = isBlockRequired(this.blockProperties);
        this.requiredValidationEnabled = observable(false);
        this.section.addBeforeValidationCallback(() => this._enableRequiredValidation());

        this.isRequiredValidation = pureComputed(this._computeRequiredValidation, this);
        sectionValidator.addValidation(this.section.number, this.isRequiredValidation);

        if (this.isBlockRequired) {
            sectionValidator.setHasRequiredElementExtraValidation(this.section.number, true);
        }

        this.onDialogClose = this.onDialogClose.bind(this);
    }

    _getButtonLabel() {
        return ADD_BUTTON_MAP[this._contentTypeId];
    }

    _loadForms() {
        return super._loadForms()
            .then(() => {
                if (this._shouldBuildTilesOnInit) {
                    this._buildTiles();
                }

                this._formsSub = this.forms.subscribe(() => this._buildTiles(), this, 'arrayChange');
            });
    }

    _canAddProfileItem() {
        const emptyForm = ko.utils.arrayFirst(this.forms(), form => form().isEmpty() && form().isActive());

        return !emptyForm;
    }

    _buildTiles() {
        this.forms().forEach(form => this._showTile(form()));
    }

    _showTile(profileItemForm) {
        profileItemForm.buildTile();
        profileItemForm.showTile();
    }

    _hideForm(profileItemForm) {
        return this.validateProfileItemForm(profileItemForm)
            .then(() => this._showTile(profileItemForm));
    }

    _collapseTiles() {
        this.forms()
            .filter(form => form().isActive())
            .forEach(form => this.doneProfileItem(form()));
    }

    _findContainerElement() {
        const element = document.querySelector('.app-dialog__content');

        return element || document.querySelector('article[aria-expanded=true] form-builder');
    }

    addProfileItem() {
        return this._addProfileItem(true);
    }

    addAndInitializeProfileItem(edit, profileItemInitializer) {
        return this._addProfileItem(edit, profileItemInitializer);
    }

    _addProfileItem(edit = true, profileItemInitializer) {
        this._isNew = true;

        return super.addProfileItem()
            .then((form) => {
                if (typeof profileItemInitializer === 'function') {
                    this._handleProfileItemInitializer({ form, edit, profileItemInitializer });

                    return;
                }

                if (edit) {
                    this.editProfileItem(form);
                }
            })
            .catch(console.error);
    }

    _handleProfileItemInitializer({ form, edit, profileItemInitializer }) {
        profileItemInitializer(form)
            .then(() => {
                this._isNew = null;

                if (edit) {
                    this.editProfileItem(form);
                }
            })
            .catch(() => {
                this.editProfileItem(form);
            });
    }

    editProfileItem(profileItemForm) {
        this._collapseTiles();

        this.changeDetector = new FormChangeDetector(profileItemForm);
        profileItemForm.showForm();
    }

    doneProfileItem(profileItemForm) {
        if (!this.shouldFormBeValidated(profileItemForm)) {
            if (this._isNew) {
                this.removeProfileItem(profileItemForm);
            } else {
                profileItemForm.isActive(false);
                this.scrollToSelectedSubblock();
            }

            this._isNew = null;

            return;
        }

        this._hideForm(profileItemForm)
            .then(() => {
                this._isNew = null;
                this.scrollToSelectedSubblock(true);
            });

        applyFlowEvents.profileItemChangeDetected.dispatch(profileItemForm);
    }

    shouldFormBeValidated(profileItemForm) {
        return profileItemForm
            && this.changeDetector.hasChanged();
    }

    scrollToSelectedSubblock(shouldFocusOnButton = false) {
        this.blockToScroll = this.blockToScroll ? this.blockToScroll : this.blockId;

        // To do: check if bug 34067795 persists after upgrading JIT, since setTimout is not
        // the best solution
        setTimeout(() => {
            document.getElementsByClassName(this.blockToScroll)[0].scrollIntoView({
                behavior: 'smooth',
                // this option is required by:
                // https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
                passive: true,
            });

            if (shouldFocusOnButton) {
                setFocusWithDelay(`#${this.addButtonId}`);
            }
        }, 1000);
    }

    removeProfileItem(profileItemForm) {
        this._isNew = null;
        profileItemForm.isActive(false);
        this.blockToScroll = super.removeProfileItem(profileItemForm);
        applyFlowEvents.profileItemChangeDetected.dispatch(profileItemForm);

        this.scrollToSelectedSubblock(true);
    }

    _enableRequiredValidation() {
        this.requiredValidationEnabled(true);
    }

    _computeRequiredValidation() {
        const isRequiredValidationActive = this.isBlockRequired && this.requiredValidationEnabled();

        return isRequiredValidationActive
            ? this.forms().length > 0
            : true;
    }

    onDialogClose() {
        this._enableRequiredValidation();
    }

    dispose() {
        super.dispose();

        if (this._formsSub) {
            this._formsSub.dispose();
        }
    }

}
