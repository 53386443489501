import mediaMapper from './media';
import locationMapper from './location';
import l10n from 'core/l10n/l10n';
import i18n from 'core/i18n/i18n';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import { mapJobFlexFieldsFromRest } from './flexField';
import mapMapper from './map';
import skillMapper from './skill';
import { propertyEquals } from 'core/utils/functional/propertyEquals';

function mapNumberOfOpenings(numberOfOpenings) {
    return (numberOfOpenings === 'Y') ? i18n('job-details.unlimited-openings') : numberOfOpenings;
}

function _mapLightJobFromRest(restJob) {
    restJob.lang = restJob.contentLocale;
    delete restJob.contentLocale;

    const dateFormatter = new LocaleBasedDateTimeFormatter(restJob.lang);

    restJob.postedDate = dateFormatter.formatDateTime(restJob.externalPostedStartDate);
    restJob.postedEndDate = dateFormatter.formatDateTime(restJob.externalPostedEndDate);
    restJob.openingDate = dateFormatter.formatDateTime(restJob.externalOpeningDate);
    restJob.closingDate = dateFormatter.formatDateTime(restJob.externalClosingDate);
    restJob.datePosted = restJob.externalPostedStartDate;
    restJob.validThrough = restJob.externalPostedEndDate;
    restJob.description = restJob.externalDescriptionStr || '';
    restJob.shortDescription = restJob.shortDescriptionStr || '';
    restJob.organizationDescription = restJob.organizationDescriptionStr;
    restJob.corporateDescription = restJob.corporateDescriptionStr;
    restJob.employmentType = restJob.jobSchedule;
    restJob.educationRequirements = restJob.studyLevel;
    restJob.qualifications = restJob.externalQualificationsStr || restJob.externalQualStr || '';
    restJob.responsibilities = restJob.externalResponsibilitiesStr || restJob.externalRespStr || '';

    restJob.isConfidential = restJob.applyWhenNotPostedFlag;
    restJob.trendingJob = restJob.trendingFlag || false;
    restJob.beFirstToApply = restJob.beFirstToApplyFlag || false;

    delete restJob.applyWhenNotPostedFlag;
    delete restJob.trendingFlag;
    delete restJob.beFirstToApplyFlag;
    delete restJob.organizationDescriptionStr;
    delete restJob.corporateDescriptionStr;
    delete restJob.externalDescriptionStr;
    delete restJob.shortDescriptionStr;

    restJob.contact = {
        name: restJob.externalContactName,
        email: restJob.externalContactEmail,
    };

    if (restJob.externalBonusAmount && restJob.externalBonusCurrencyCode) {
        restJob.bonusAmount = l10n.currency.format(
            restJob.externalBonusAmount,
            restJob.externalBonusCurrencyCode,
        );
    }

    restJob.numberOfOpenings = mapNumberOfOpenings(restJob.numberOfOpenings);

    return restJob;
}

export default {
    mapJobFromRest(restJob, candidateReqList = []) {
        let mappedJob = restJob;

        mappedJob = _mapLightJobFromRest(mappedJob);

        const dateFormatter = new LocaleBasedDateTimeFormatter(mappedJob.lang);

        const mappedWorkLocations =
            locationMapper.mapWorkLocationsFromRest(mappedJob.workLocation, mappedJob.otherWorkLocations);

        const mappedRecruitingLocations = locationMapper.mapRecruitingLocationsFromRest(mappedJob);

        const markers = mapMapper.mapMarkersFromLocations(
            mappedWorkLocations,
            mappedRecruitingLocations,
            mappedJob.lang,
        );

        mappedJob.secondaryLocations = mappedJob.secondaryLocations.map(locationMapper.mapLocationToString);
        mappedJob.workLocations = mappedWorkLocations.map(({ address }) => address);
        mappedJob.media = mediaMapper.mapFromRest(mappedJob.media, markers);

        mappedJob.requisitionFlexFields = mapJobFlexFieldsFromRest(mappedJob.requisitionFlexFields, dateFormatter);
        mappedJob.skills = skillMapper.mapFromRest(mappedJob.skills);

        const application = candidateReqList.find(propertyEquals('id', restJob.id));

        restJob.isAlreadyApplied = Boolean(application?.id);
        restJob.isConfirmed = Boolean(application?.confirmedFlag);

        delete mappedJob.workLocation;
        delete mappedJob.otherWorkLocations;

        return mappedJob;
    },
    mapLightJobFromRest: _mapLightJobFromRest,
};

export const mapJobFitFromRest = (response) => {
    const {
        skillsScore,
        relevantExperienceScore,
        domainKnowledgeScore,
        roleSeniorityScore,
        overallScore,
        summary,
    } = response;

    return {
        skillsScore: skillsScore ? parseInt(skillsScore, 10) : 0,
        relevantExperienceScore: relevantExperienceScore ? parseInt(relevantExperienceScore, 10) : 0,
        domainKnowledgeScore: domainKnowledgeScore ? parseInt(domainKnowledgeScore, 10) : 0,
        roleSeniorityScore: roleSeniorityScore ? parseInt(roleSeniorityScore, 10) : 0,
        overallScore: overallScore ? parseInt(overallScore, 10) : 0,
        summary,
    };
};
