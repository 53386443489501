export const VALIDATION_MESSAGE_VISIBILITY_TIME = 5000;

export const SAVED_MESSAGE_VISIBILITY_TIME = 3000;

export const MISC_FILES_LIMIT = 10;

export const STATES = {
    WAITING: 'WAITING',
    DRAGOVER: 'DRAGOVER',
    UPLOADING: 'UPLOADING',
    SAVED: 'SAVED',
    FILLED: 'FILLED',
};

export const ACTIONS = {
    DOWNLOAD: 'DOWNLOAD',
    REMOVE: 'REMOVE',
};

export const CONTAINER_CLASSES = {
    WAITING: 'attachment-upload-button--waiting',
    DRAGOVER: 'attachment-upload-button--dragover',
    UPLOADING: 'attachment-upload-button--uploading',
    SAVED: 'attachment-upload-button--saved',
    FILLED: 'attachment-upload-button--filled',
};