import { components } from 'knockout';

import CheckboxFormElementViewModel from './CheckboxFormElementViewModel';
import CheckboxStringValueFormElementViewModel from './CheckboxStringValueFormElementViewModel';
import template from './checkbox-form-element.html';

components.register('checkbox-form-element', {
    viewModel: CheckboxFormElementViewModel,
    template,
});

components.register('checkbox-string-value-form-element', {
    viewModel: CheckboxStringValueFormElementViewModel,
    template,
});
