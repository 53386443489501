import { observableArray, observable, pureComputed } from 'knockout';
import SearchFiltersPanelViewModel from './SearchFiltersPanelViewModel';
import facetFactory from 'search/module/search-results/module/facet/model/facetFactory';
import { filtersData } from './config/staticSearchFiltersData';

export default class SearchFilterResultStaticViewModel extends SearchFiltersPanelViewModel {

    constructor(config) {
        super(config);

        this.loading = observable(false);
        this.selectedFiltersCount = observable(true);
        this.filtersOpened = observable(true);
        this.adminMode = true;
        this.filters = observableArray();
        this.totalCount = observable(5);
        this.filterPanelVisible = pureComputed(() => true);

        filtersData.forEach((filter) => {
            this.filters.push(facetFactory.create(filter));
        });
    }

}
