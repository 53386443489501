import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { CookiePreferenceLinkCustomizationParams, Message } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';
import { LANGUAGE_KEY_SEPARATOR } from '../config/messages';

const mapMessage = (paramKey: string, paramValue: string | null | undefined): Message =>
    paramValue ? JSON.parse(paramValue) : emptyParams[paramKey];

export const mapParamsFromRest = (params: RestParam[]): CookiePreferenceLinkCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'cookiePreferenceLinkText':
                    return mapMessage(paramKey, paramValue);

                case 'cookiePreferenceLinkTypography':
                case 'cookiePreferenceLinkHoverTypography':
                    return mapTypography(paramValue);

                case 'cookiePreferenceLinkContainerStyling':
                case 'cookiePreferenceLinkContainerHoverStyling':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    if (paramKey.includes(LANGUAGE_KEY_SEPARATOR)) {
                        return mapMessage(paramKey, paramValue);
                    }

                    return null;
            }
        },
    });
};
