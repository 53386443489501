export default {
    interpolate(url, params, delimiter) {
        return Object.keys(params || {})
            .map(paramKey => ({
                key: paramKey,
                value: this.encode(params[paramKey], delimiter),
            }))
            .reduce((interpolatedUrl, param) => interpolatedUrl.replace(`:${param.key}:`, param.value), url);
    },

    encode(param, delimiter) {
        switch (typeof param) {
            case 'string':
                return this._encodeString(param);
            case 'object':
                return this._encodeObject(param, delimiter);
            default:
                return param;
        }
    },

    _encodeString(param) {
        return encodeURIComponent(param)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/~/g, '%7E');
    },

    _encodeObject(paramObj, delimiter) {
        return Object.keys(paramObj)
            .filter(key => paramObj[key])
            .map(key => `${key}=${this._encodeString(paramObj[key])}`)
            .join(delimiter || '&');
    },
};