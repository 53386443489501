import { Observable } from 'knockout';
import { LayoutStyle } from 'minimal/module/search/component/search-results/config/types';

type Params = {
    className: string;
    label: string;
    layoutStyle: Observable<LayoutStyle>;
};

export class TagViewModel {
    className: string;
    label: string;
    layoutStyle: Observable<LayoutStyle>;

    constructor({ className, label, layoutStyle }: Params) {
        this.className = className;
        this.label = label;
        this.layoutStyle = layoutStyle;
    }
}
