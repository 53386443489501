export const sortDescendingByDate = (dateString1, dateString2) => {
    const submissionDate1 = new Date(dateString1);
    const submissionDate2 = new Date(dateString2);

    if (submissionDate1 > submissionDate2) {
        return -1;
    }

    if (submissionDate1 < submissionDate2) {
        return 1;
    }

    return 0;
};
