import mapping from 'knockout-mapping';
import base64 from 'core/utils/base64';
import modelResolver from 'apply-flow/module/profile-items/service/modelResolver';
import degreeMapper from 'apply-flow/module/degrees/mapper/degrees';

const PROFILE_ITEM_MAPPERS = {
    106: degreeMapper,
};

function removeEmptyDateFields(profileItemModel) {
    const profileItem = profileItemModel;

    Object.keys(profileItem)
        .filter(key => key.indexOf('Date') > -1)
        .forEach((key) => {
            if (!profileItem[key]) {
                delete profileItem[key];
            }
        });

    return profileItem;
}

function mapItemTextFieldsToRest(profileItemModel) {
    const profileItem = profileItemModel;

    Object.keys(profileItem)
        .filter(key => key.indexOf('itemText') > -1 && key.indexOf('Flag') === -1)
        .forEach((key) => {
            if (profileItem[key]) {
                profileItem[key] = profileItem[key].toString();
            }
        });

    return profileItem;
}

function mapClobFields(profileItem, clobFields, mapMethod) {
    Object.keys(profileItem)
        .filter(key => clobFields.indexOf(key) > -1)
        .filter(key => profileItem[key] !== undefined)
        .forEach((key) => {
            profileItem[key] = mapMethod(profileItem[key]);
        });

    return profileItem;
}

function removeUnusedFields(profileItem, dataFields) {
    Object.keys(profileItem).forEach((key) => {
        if (dataFields.indexOf(key) === -1) {
            delete profileItem[key];
        }
    });

    return profileItem;
}

function removeEmptyPrimaryKey(profileItem, primaryKey) {
    if (!profileItem[primaryKey]) {
        delete profileItem[primaryKey];
    }

    return profileItem;
}

function convertFlagsToBoolean(profileItem) {
    Object.keys(profileItem)
        .filter(key => key.indexOf('Flag') > -1)
        .filter(key => typeof profileItem[key] === 'string')
        .forEach(key => (profileItem[key] = profileItem[key] === 'Y'));

    return profileItem;
}

function convertFlagsToString(profileItem) {
    Object.keys(profileItem)
        .filter(key => key.indexOf('Flag') > -1)
        .filter(key => typeof profileItem[key] === 'boolean')
        .forEach(key => (profileItem[key] = profileItem[key] ? 'Y' : 'N'));

    return profileItem;
}

function trimStringFields(profileItem) {
    Object.keys(profileItem)
        .filter(key => typeof profileItem[key] === 'string')
        .forEach(key => (profileItem[key] = profileItem[key].trim()));

    return profileItem;
}

export default {
    mapProfileItemsFromRest(restResponse, contentTypeId, attributes) {
        const restProfileItems = restResponse.items || [];

        return restProfileItems.map(item => this.mapProfileItemFromRest(item, contentTypeId, attributes));
    },

    mapProfileItemsFromImport(restProfileItems = []) {
        return restProfileItems.map(this.mapProfileItemFromImport.bind(this));
    },

    mapProfileItemFromImport(restProfileItemData) {
        let restProfileItem = restProfileItemData;

        delete restProfileItem.profileId;
        restProfileItem.profileItemId = null;

        restProfileItem = convertFlagsToString(restProfileItem);

        const Model = modelResolver.getModel(restProfileItem.contentTypeId);

        return new Model(restProfileItem);
    },

    mapProfileItemFromRest(restProfileItemData, contentTypeId, attributes) {
        let restProfileItem = restProfileItemData;

        restProfileItem.action = 'UPDATE';

        restProfileItem = mapClobFields(restProfileItem, attributes.clob, base64.decode);
        restProfileItem = convertFlagsToString(restProfileItem);

        const Model = modelResolver.getModel(contentTypeId);

        return new Model(Object.assign(restProfileItem, { contentTypeId }));
    },

    mapToRest(profileItemModel, attributes) {
        let profileItem = profileItemModel;

        if (profileItem.action() === 'CREATE') {
            profileItem[attributes.primaryKey](null);
        }

        profileItem.contentTypeId(parseInt(profileItem.contentTypeId(), 10));

        profileItem = mapping.toJS(profileItem, {
            ignore: ['id', 'action', 'isNew'],
        });

        profileItem = mapItemTextFieldsToRest(profileItem);
        profileItem = trimStringFields(profileItem);

        const mapper = PROFILE_ITEM_MAPPERS[profileItem.contentTypeId];

        if (mapper) {
            profileItem = mapper.mapToRest(profileItem);
        }

        profileItem = removeUnusedFields(profileItem, attributes.all);
        profileItem = removeEmptyPrimaryKey(profileItem, attributes.primaryKey);
        profileItem = mapClobFields(profileItem, attributes.clob, base64.encode);
        profileItem = convertFlagsToBoolean(profileItem);

        return removeEmptyDateFields(profileItem);
    },
};
