import { WorkplaceType } from 'cx/config/constants/workplaceType';
import { getWorkplaceTypeName } from 'app/service/workplaceType';
import AbstractMetaItemViewModel from 'cx/module/job-details/module/job-meta-item/component/abstract-meta-item/AbstractMetaItemViewModel';

type Props = {
    name: string;
    value: string;
    workplaceType: WorkplaceType;
};

const minimumRowHeight = 24;

export class LocationMetaItemViewModel extends AbstractMetaItemViewModel {
    workplaceTypeName: string | null;

    constructor({ name, value, workplaceType }: Props) {
        super({ name, value });
        this.workplaceTypeName = getWorkplaceTypeName(workplaceType);
    }

    hasLocationsAcrossMultipleRows(): boolean {
        const locationsListElement: HTMLElement | null = document.querySelector('.job-meta__locations');

        if (!locationsListElement || !this.workplaceTypeName) {
            return false;
        }

        return locationsListElement.offsetHeight > minimumRowHeight;
    }
}
