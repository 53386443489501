import httpLangCached from 'cx/service/http/httpLangCached';
import flowMapper from 'apply-flow/mapper/notEmptyFlow';
import flowService from 'apply-flow/service/flow';
import sectionValidator from 'apply-flow/model/sectionValidator';

export default {
    query(talentCommunityMapper) {
        const finder = 'findTalentCommunityApplyFlow;FlowTypeCode=ORA_TALENT';
        const flowUrl = `/recruitingCEApplyFlows?finder=${finder}&expand=sections.pages.blocks,requisitionFlexFields&onlyData=true`;

        return httpLangCached.get(flowUrl)
            .then(flowMapper.mapFlowFromRest.bind(flowMapper))
            .then((flow) => {
                if (talentCommunityMapper) {
                    return talentCommunityMapper.mapFlowFromRest(flow);
                }

                return flow;
            })
            .then(flowService.set);
    },

    shouldShowMoreFieldsSectionBeExpanded(sections) {
        return sections
            .some(section => sectionValidator.hasRequiredElement(section.number));
    },
};