import { observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import TooltipFormElementViewModel from 'core/form/component/tooltip-text-form-element/TooltipFormElementViewModel';
import DateMonthpicker from '../../element/DateMonthpicker';

const INCOMPLETE_ISO_DATE = '';

export default class DatepickerFormElementViewModel extends TooltipFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.selectedDay = observable();
        this.selectedMonth = observable();
        this.selectedYear = observable();

        this.dayField = {
            name: 'day',
            placeholder: i18n('general.date-picker.day'),
            layoutCode: 'dd',
            value: this.selectedDay,
            defaultValue: null,
            options: this.element.generateDays(),
            isTouched: observable(false),
            a11yLabel: this.element.label(),
            type: 'select',
            display: observable(),
        };

        this.monthField = {
            name: 'month',
            placeholder: i18n('general.date-picker.month'),
            layoutCode: 'mm',
            value: this.selectedMonth,
            defaultValue: null,
            options: this.element.generateMonths(),
            isTouched: observable(false),
            a11yLabel: this.element.label(),
            type: 'select',
            display: observable(),
        };

        this.yearField = {
            name: 'year',
            placeholder: i18n('general.date-picker.year'),
            layoutCode: 'yy',
            value: this.selectedYear,
            defaultValue: new Date().getFullYear(),
            options: this.element.generateYears(),
            isTouched: observable(false),
            a11yLabel: `${this.element.label()} ${i18n('general.date-picker.year')}`,
            type: 'text',
            display: observable(),
        };

        this.fields = this._generateLayout();

        const initialValue = this.element.value();

        if (initialValue) {
            this._setDate(initialValue);
        }

        this.blurHandler = this.blurHandler.bind(this);

        this._valueSubscribe = this.element.value.subscribe(this._setDate, this);

        if (this.element instanceof DateMonthpicker) {
            this.dayField.display = 'small';
            this.showDobWarningMessage = pureComputed(this._computeShowWarningMessage, this);
            this.showWarningMessage = false;
        } else {
            this.showWarningMessage = pureComputed(this._computeShowWarningMessage, this);
            this.showDobWarningMessage = false;
        }
    }

    onChange() {
        const isoDate = this._generateIsoDate(this._getSelectedDay(), this.selectedMonth(), this._getSelectedYear());

        this.element.value(isoDate);
    }

    onValueAction(e) {
        if (!e.detail.value) {
            e.target.refresh();
        }
    }

    blurHandler(field) {
        field.isTouched(true);

        if (this._isTouched()) {
            this.element.isTouched(true);
        }
    }

    pickerAttributes(field) {
        const classes = [
            'input-row__listbox background-color-dropdown',
            'datepicker-row__listbox',
        ];

        classes.push(`datepicker-row__listbox--${field.name}`);

        return {
            class: classes.join(' '),
        };
    }


    _getSelectedDay() {
        let day = this.selectedDay();

        if (this.element.fixedDay === 'first') {
            day = this.dayField.options[0].value;
        } else if (this.element.fixedDay === 'last') {
            day = new Date(this.selectedYear(), this.selectedMonth(), 0).getDate();
        }

        return day;
    }

    _getSelectedYear() {
        let year = this.selectedYear();

        if (this.element instanceof DateMonthpicker) {
            year = '1600';
        }

        return year;
    }

    _isTouched() {
        return [this.dayField, this.monthField, this.yearField].every(element => element.isTouched());
    }

    _setDate(isoDate) {
        if (isoDate === INCOMPLETE_ISO_DATE) {
            return;
        }

        if (!isoDate) {
            this.selectedDay(null);
            this.selectedMonth(null);
            this.selectedYear(null);

            return;
        }

        const [year, month, day] = isoDate.split('-');

        this.selectedDay(day);
        this.selectedMonth(month);
        this.selectedYear(year);
    }

    _createComputedValue(fieldValue) {
        return pureComputed({
            read() {
                const value = fieldValue();

                return value ? [value] : [];
            },
            write(options) {
                const value = options ? options[0] : null;

                fieldValue(value);
            },

            owner: this,
        });
    }

    _generateLayout() {
        const layout = this.element.layout.split('-');

        [this.dayField, this.monthField, this.yearField].forEach((field) => {
            const index = layout.indexOf(field.layoutCode);

            if (index > -1) {
                layout[index] = field;
            } else {
                field.isTouched(true);
            }
        });

        return layout;
    }

    _generateIsoDate(day, month, year) {
        if (!this._isDateComplete(day, month, year)) {
            return INCOMPLETE_ISO_DATE;
        }

        return [year, month, day]
            .join('-');
    }

    _isDateEmpty(day, month, year) {
        if (this.element.fixedDay) {
            return !month && !year;
        }

        return !day && !month && !year;
    }

    _isDateComplete(day, month, year) {
        if (this.element.fixedDay) {
            return month && year;
        }

        return day && month && year;
    }

    _computeShowWarningMessage() {
        if (this.isInvalid()) {
            return false;
        }

        const day = this.dayField.value();
        const month = this.monthField.value();
        const year = this.yearField.value();

        if (this._isDateEmpty(day, month, year)) {
            return false;
        }

        if (this._isDateComplete(day, month, year)) {
            return false;
        }

        return true;
    }

    dispose() {
        this._valueSubscribe.dispose();
    }

}