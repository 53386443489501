import LocalSession from './localSession';
import GlobalSession from './globalSession';
import sessionPersistence from './sessionPersistence';
import tokenService from './../token';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';
import { cachedCandidateRegistrations } from '../../../apply-flow/service/cachedCandidateRegistrations';

export default class Session {

    constructor({ localSession, globalSession }) {
        this._localSession = localSession;
        this._globalSession = globalSession;
    }

    get _instance() {
        if (sessionPersistence.isEnabled()) {
            return this._globalSession;
        }

        return this._localSession;
    }

    extend() {
        if (!sessionPersistence.isEnabled()) {
            sessionPersistence.deactivate();
        }

        return this._instance.extend();
    }

    async signOut() {
        await tokenService.signOut()
            .catch(console.error);

        this.cleanup();
    }

    async signOutAll() {
        await tokenService.signOutAll()
            .catch(console.error);

        this.cleanup();
    }

    cleanup() {
        cachedCandidateApplications.clear();
        cachedCandidateRegistrations.clear();
        this._instance.cleanup();
    }

}

export const session = new Session({
    localSession: new LocalSession(),
    globalSession: new GlobalSession(),
});
