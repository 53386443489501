import base64 from 'core/utils/base64';
import { getSplashBrandingContent } from './splashBrandingContent';

const BRANDING_TEXT = 'JOIN OUR TEAM';

export default {
    mohegan: {
        sections:
            [{
                elementOrder: 0,
                sectionParams: [{
                    paramKey: 'type',
                    paramValue: 'cc-section',
                }],
                rows: [{
                    elementOrder: 0,
                    rowParams: [{
                        paramKey: 'type',
                        paramValue: 'cc-row',
                    }],
                    columns: [{
                        elementOrder: 0,
                        columnParams: [{
                            paramKey: 'type',
                            paramValue: 'cc-column',
                        }],
                        components: [{
                            elementOrder: 0,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-space',
                            }, {
                                paramKey: 'height',
                                paramValue: 250,
                            }],
                        }, {
                            elementOrder: 1,
                            componentContent: base64.encode(getSplashBrandingContent(BRANDING_TEXT, 'mohegan')),
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-paragraph',
                            }],
                        }, {
                            elementOrder: 2,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-space',
                            }, {
                                paramKey: 'height',
                                paramValue: 50,
                            }],
                        }],
                    }],
                }, {
                    elementOrder: 1,
                    rowParams: [{
                        paramKey: 'type',
                        paramValue: 'cc-row',
                    }],
                    columns: [{
                        columnParams: [{
                            paramKey: 'type',
                            paramValue: 'cc-column',
                        }],
                        elementOrder: 0,
                        components: [{
                            elementOrder: 0,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-splash-mohegan-view-all-jobs',
                            }],
                        }, {
                            elementOrder: 1,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-space',
                            }, {
                                paramKey: 'height',
                                paramValue: 100,
                            }],
                        }],
                    }],
                }],
            }],
    },
    minimal: {
        sections:
            [{
                elementOrder: 0,
                sectionParams: [{
                    paramKey: 'type',
                    paramValue: 'cc-section',
                }],
                rows: [{
                    elementOrder: 0,
                    rowParams: [{
                        paramKey: 'type',
                        paramValue: 'cc-row',
                    }],
                    columns: [{
                        elementOrder: 0,
                        columnParams: [{
                            paramKey: 'type',
                            paramValue: 'cc-column',
                        }],
                        components: [{
                            componentContent: base64.encode(getSplashBrandingContent(BRANDING_TEXT, 'minimal')),
                            elementOrder: 0,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-paragraph',
                            }],
                        }],
                    }],
                }, {
                    elementOrder: 1,
                    rowParams: [{
                        paramKey: 'type',
                        paramValue: 'cc-row',
                    }],
                    columns: [{
                        elementOrder: 0,
                        columnParams: [{
                            paramKey: 'type',
                            paramValue: 'cc-column',
                        }],
                        components: [{
                            elementOrder: 0,
                            componentParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-minimal-search-box-compact',
                            }],
                        }],
                    }],
                }, {
                    elementOrder: 2,
                    rowParams: [{
                        paramKey: 'type',
                        paramValue: 'cc-row',
                    }],
                    columns: [{
                        columnParams: [{
                            paramKey: 'type',
                            paramValue: 'cc-column',
                        }],
                        elementOrder: 0,
                        components: [
                            {
                                elementOrder: 0,
                                componentParams: [{
                                    paramKey: 'type',
                                    paramValue: 'cc-minimal-search-categories',
                                }],
                            },
                            {
                                elementOrder: 1,
                                componentParams: [{
                                    paramKey: 'type',
                                    paramValue: 'cc-recommended-jobs-widget',
                                }],
                            },
                            {
                                elementOrder: 2,
                                componentParams: [{
                                    paramKey: 'type',
                                    paramValue: 'cc-category-tiles',
                                }],
                            },
                        ],
                    }],
                }],
            }],
    },
};
