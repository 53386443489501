import mapping from 'knockout-mapping';
import FormValidator from 'core/form/validator/Validator';

const DELIMITER_PATTERN = /\s+/;

export default class ESignatureValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.e-signature.validation.description';
    }

    setInvalid() {
        this._isValid = false;
    }

    _validate(eSign, options) {
        const { lastName } = mapping.toJS(options);

        if (!lastName || !eSign) {
            return true;
        }

        const lastNameSegments = lastName.toLowerCase().split(DELIMITER_PATTERN);
        const eSignSegments = eSign.toLowerCase().split(DELIMITER_PATTERN);

        return eSignSegments.some(eSignSegment => lastNameSegments.includes(eSignSegment));
    }

}