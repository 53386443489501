import appConfig from 'app/model/config';

const JA_PREFILL_LEG_INFO = 'ORA_IRC_JA_PREFILL_LEG_INFO';
const JA_PREFILL_QUESTIONNAIRE = 'ORA_IRC_JA_PREFILL_QSTNR_INFO';

export const isPrefillLegInfoEnabled = (): boolean => {
    return appConfig.getSettingByKey(JA_PREFILL_LEG_INFO) === 'true' || false;
};

export const isPrefillQuestionnaireEnabled = (): boolean => {
    return appConfig.getSettingByKey(JA_PREFILL_QUESTIONNAIRE) === 'true' || false;
};
