import { observable, pureComputed } from 'knockout';
import MultiSelectPillDictionaryFormElementViewModel from 'core/form/component/multiselect-pill-form-element/MultiSelectPillDictionaryFormElementViewModel';
import i18n from 'core/i18n/i18n';

export default class CsSearchMultiselectPillPreferenceViewModel extends MultiSelectPillDictionaryFormElementViewModel {

    constructor({ siteNumber, model, fetchAvailablePreferencesFunction, placeholder }) {
        super({ model, filterRepository: null, config: { markSelectedDisabled: true } });

        this._siteNumber = siteNumber;
        this._fetchAvailablePreferencesFunction = fetchAvailablePreferencesFunction;

        this.fetchAvailablePreferences = this.fetchAvailablePreferences.bind(this);
        this.resetHandler = this.resetHandler.bind(this);

        this.placeholder = i18n(placeholder);
        this.rawValue = observable(null);
        this.noSuggestions = observable(false);
        this.showNoResultsHint = pureComputed(this._showNoResultsHint, this);
    }

    async fetchAvailablePreferences(context) {
        this.noSuggestions(false);

        const availableOptions = await this._fetchFromRepository(this._fetchAvailablePreferencesFunction, {
            siteNumber: this._siteNumber,
            term: context.term,
        });

        this.noSuggestions(!availableOptions.length);

        return availableOptions;
    }

    resetHandler() {
        this.rawValue('');
    }

    _showNoResultsHint() {
        return this.rawValue() && this.noSuggestions();
    }

}