import { ObservableArray } from 'knockout';
import FormElementViewModel from '../form-element/FormElementViewModel';

type Option = {
    value: string;
    label: string;
};

type ValueOptions = {
    detail: {
        previousValue: Option[] | null;
        value: Option[];
    };
};

export class MultiSelectComboBoxFormElementViewModel extends FormElementViewModel {
    value: ObservableArray<string>;
    isCaseSensitive: boolean;

    constructor(params: { element: unknown }) {
        super(params);
        this.value = this.element.value;
        this.isCaseSensitive = this.element.isCaseSensitive;

        this.onValueChanged = this.onValueChanged.bind(this);
        this.onValueOptionChanged = this.onValueOptionChanged.bind(this);
        this.resetHandler = this.resetHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
    }

    onValueChanged(): void {
        this.hasFocus(true);
    }

    onValueOptionChanged(options: ValueOptions): void {
        this.filterValuesBasedOnCase(options);
        this.hasFocus(true);
    }

    resetHandler(): void {
        this.value([]);
    }

    blurHandler(): void {
        this.hasFocus(false);
        super.blurHandler();
    }

    filterValuesBasedOnCase(options: ValueOptions): void {
        const [previousOptionsList, currentOptionsList] = [
            options.detail.previousValue,
            options.detail.value,
        ];

        if (!this.isCaseSensitive || !previousOptionsList || !currentOptionsList) {
            return;
        }

        if (currentOptionsList.length > previousOptionsList.length) {
            const newInputValue = currentOptionsList.at(-1);
            const mappedPreviousValuesList = previousOptionsList.map((option: Option) =>
                option.value.toLowerCase()
            );

            if (newInputValue && mappedPreviousValuesList.indexOf(newInputValue.value.toLowerCase()) > -1) {
                setTimeout(() =>
                    this.setUpdatedValue(previousOptionsList.map((option: Option) => option.value))
                );
            }
        }
    }

    setUpdatedValue(valueOptions: string[]): void {
        this.value(valueOptions);
    }
}
