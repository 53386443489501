import { observable } from 'knockout';
import { FILL_IN } from 'candidate-verification/config/pinVerificationStatus';

class ChallengeModel {

    constructor({ candidate, verificationStatus, submodule, actions, mergedCandidate = false }) {
        this.verificationStatus = verificationStatus || observable(FILL_IN);

        this.submodule = submodule;
        this.candidate = candidate;
        this.actions = actions;
        this.mergedCandidate = mergedCandidate;
    }

    set actions(actions) {
        if (typeof actions.onPinValid !== 'function') {
            throw new Error('onPinValid callback action was not passed');
        }

        if (typeof actions.onAttempsLimitReached !== 'function') {
            throw new Error('onAttempsLimitReached callback action was not passed');
        }

        this._actions = actions;
    }

    get actions() {
        return this._actions;
    }

}

export default ChallengeModel;

