import draftAttachmentService from 'apply-flow/module/file-upload/service/draftAttachmentService';
import { ORA_MISC_DOCUMENTS, ORA_DOCUMENTS } from '../../enum/blockTypes';

const MISC = 'MISC';

export class AttachmentBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        this.blocks = new Map([
            [ORA_MISC_DOCUMENTS, this.removeMiscAttachments],
            [ORA_DOCUMENTS, this.removeAttachments],
        ]);

        return this.blocks;
    }

    getBlockIdentifier(block) {
        return [block.blockId];
    }

    removeAttachments({ candidate, id }) {
        candidate.linkedSites = [];

        const attachments = candidate.attachments.filter(({ categoryId }) => categoryId !== MISC);

        candidate.attachments = candidate.attachments.filter(({ categoryId }) => categoryId === MISC);

        if (attachments.length) {
            draftAttachmentService.removeAttachments(id, attachments);
        }
    }

    removeMiscAttachments({ candidate, id }, blockIds) {
        const miscAttachments = candidate.attachments
            .filter(({ categoryId, blockId }) => categoryId === MISC && !blockIds.includes(blockId));

        candidate.attachments = candidate.attachments
            .filter(({ categoryId, blockId }) => categoryId !== MISC
                || (categoryId === MISC && blockIds.includes(blockId)));

        if (miscAttachments.length) {
            draftAttachmentService.removeAttachments(id, miscAttachments);
        }
    }

}
