import ko from 'knockout';
import flowMapper from './flow';
import metadataConfig from 'apply-flow/config/metadata';

function _filterOutEmptySections(flow) {
    const { flowVersionId } = flow;

    return Promise.all(
        flow.sections.map(section => _filterOutEmptyPages(section, flowVersionId)),
    )
        .then(sections => sections.filter(section => section.pages.length))
        .then(sections => ko.utils.extend(flow, { sections }));
}

function _filterOutEmptyPages(section, flowVersionId) {
    return Promise.all(
        section.pages.map(page => _filterOutEmptyBlocks(page, flowVersionId)),
    )
        .then(pages => pages.filter(page => page.blocks.length))
        .then(pages => ko.utils.extend(section, { pages }));
}

function _filterOutEmptyBlocks(page, flowVersionId) {
    return Promise.all(
        page.blocks.map(block => _IsBlockEmpty(block, flowVersionId)),
    )
        .then(isEmptyCollection =>
            page.blocks.map((block, index) => {
                block.isEmpty = isEmptyCollection[index];

                return block;
            }),
        )
        .then(blocks => blocks.filter(block => !block.isEmpty))
        .then(blocks => ko.utils.extend(page, { blocks }));
}

function _IsBlockEmpty(block, flowVersionId) {
    const MetadataRepository = metadataConfig.getRepositoryForBlock(block.blockCode);

    if (MetadataRepository) {
        const metadataRepository = new MetadataRepository(`/${block.metadataService}`);

        if (metadataRepository.isEmpty) {
            return metadataRepository.isEmpty(block, flowVersionId);
        }
    }

    return Promise.resolve(false);
}

export default Object.create(flowMapper, {
    mapFlowFromRest: {
        value({ items }) {
            if (!items.length) {
                return null;
            }

            const [flowConfiguration] = items;

            return _filterOutEmptySections(flowConfiguration)
                .then(flow => flowMapper.mapFlowFromRest({ items: [flow] }));
        },
    },
});