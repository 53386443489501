import http from 'core/http/http';
import appConfig from 'app/model/config';
import router from 'app/model/router';
import httpLangCached from 'cx/service/http/httpLangCached';
import pageMapper from '../mapper/page';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import { getSections } from 'site-editor/module/pages/service/pageTypes';
import headerEvents from 'minimal/config/events';
import cxEvents from 'cx/config/events';
import customContentHelperService, { ERROR_NO_PAGE_SET, pageUrl } from './customContentHelper';

const PAGE_ID_TITLE_MAPPING = {
    SPLASH_PAGE: 'home-page',
    SEARCH_PAGE: 'search',
    JOB_DETAILS: 'job-detail',
};

const createAndAppendMetaTagToHead = (name, content) => {
    const meta = document.createElement('meta');

    meta.name = name;
    meta.content = content;
    document.getElementsByTagName('head')[0].prepend(meta);
};

let pageData = {};

export default {
    get(pageCode, lang) {
        if (!pageCode) {
            return Promise.reject(ERROR_NO_PAGE_SET);
        }

        return customContentHelperService.getPageData(pageCode, lang)
            .then(page => this.setCustomHeader(page))
            .then(page => pageMapper.mapPageFromRest(page));
    },

    setCustomHeader(page) {
        pageData = page;

        this.setPageMeta(PAGE_ID_TITLE_MAPPING[pageData.type]);

        if (page?.headerId) {
            headerEvents.headerUpdated.dispatch(page.headerId, page.type);
        }

        cxEvents.isHeaderLoaded.dispatch();

        return page;
    },

    setPageMeta(pageId) {
        if (router.route().id === 'job-preview') {
            return;
        }

        if (PAGE_ID_TITLE_MAPPING[pageData.type] !== pageId) {
            pageData = {};
        }

        let { seoDescription, seoKeywords } = pageData;
        const metaDescriptionHTMLElement = document.querySelector('meta[name="description"]');
        const metaKeywordHTMLElement = document.querySelector('meta[name="keywords"]');
        const seoConfig = appConfig?.seoConfiguration;

        seoDescription = seoDescription?.trim() || seoConfig?.seoDescription;
        seoKeywords = seoKeywords ?? seoConfig?.seoKeywords;

        if (metaDescriptionHTMLElement && seoDescription) {
            metaDescriptionHTMLElement.setAttribute('content', seoDescription);
        } else if (!metaDescriptionHTMLElement && seoDescription) {
            createAndAppendMetaTagToHead('description', seoDescription);
        } else if (metaDescriptionHTMLElement && !seoDescription) {
            metaDescriptionHTMLElement.remove();
        }

        if (metaKeywordHTMLElement && seoKeywords) {
            metaKeywordHTMLElement.setAttribute('content', seoKeywords);
        } else if (!metaKeywordHTMLElement && seoKeywords) {
            createAndAppendMetaTagToHead('keywords', seoKeywords);
        } else if (metaKeywordHTMLElement && !seoKeywords) {
            metaKeywordHTMLElement.remove();
        }
    },

    getDraft(pageCode) {
        return http.get(pageUrl, {
            baseUrl: appConfig.ceBaseSecuredUrl,
            params: {
                siteCode: appConfig.siteNumber,
                lang: siteLanguage.get(),
                onlyData: true,
                pageCode,
                statusCode: 'ORA_DRAFT',
            },
        })
            .then(page => pageMapper.mapPageFromRest(page));
    },

    getTitle(pageCode) {
        const siteCode = appConfig.siteNumber || router.routeParams().siteCode;

        return httpLangCached
            .get(`/recruitingCESites/${siteCode}/child/pages?q=StatusCode=ORA_ACTIVE&fields=Title,PageCode&onlyData=true`)
            .then((pagesCollection) => {
                const match = pagesCollection.items
                    .filter(page => pageCode === page.pageCode)
                    .pop();

                return match ? match.title : '-';
            });
    },

    getPageByType(pageType, lang) {
        const { pageCode } = customContentHelperService.getPageCodeByType(pageType);

        return this.get(pageCode, lang);
    },

    getDefaultPage(pageType) {
        const sections = getSections(pageType, appConfig.template.templateNumber);

        cxEvents.isHeaderLoaded.dispatch();

        return pageMapper.mapPageFromRest({
            sections,
            pageParams: [],
        });
    },
};