import tokenService from 'candidate-verification/service/token';

export function canEnterCSS(): Promise<void> {
    if (tokenService.isCandidateVerified()) {
        return Promise.resolve();
    }

    return Promise.reject({
        redirectTo: 'home-page',
    });
}
