import ko from 'knockout';
import DictionaryElement from 'core/form/element/DictionaryElement';

export default class AutosuggestFormElement extends DictionaryElement {

    constructor(...args) {
        super(...args);

        this.validators('html');
        this.validators('xss');

        this.component('autosuggest-form-element');

        this.valueKey = ko.pureComputed(this._getValueKey, this);
    }

    getOption(value) {
        return super.getOption(value)
            .then(response => response || this.value());
    }

    _stringifyValue(option) {
        const { value } = this.optionsKeys();

        if (option) {
            option[value] = String(option[value]);
        }

        return option;
    }

    _stringifyValues(options) {
        return options.map(this._stringifyValue.bind(this));
    }

    _saveOptions(options) {
        const optionsAsStr = this._stringifyValue(options);

        return super._saveOptions(optionsAsStr);
    }

    _getValueKey() {
        const value = this.value();
        const { key, label } = this.optionsKeys();

        let currentOption = this.currentOptions().find(option => option[label] === value);

        if (!currentOption) {
            currentOption = this.options().find(option => option[label] === value);
        }

        return currentOption ? currentOption[key] : null;
    }

}