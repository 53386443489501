export default class ExtraInformationTileStrategy {

    constructor({ elements }) {
        this.elements = elements;
    }

    buildTitle(uniqueElement) {
        const element = uniqueElement || this.elements()[0];
        const value = element.value() || '';

        if (element.valueLabel && value) {
            return element.valueLabel();
        }

        return value;
    }

}