import router from 'app/model/router';
import { Facet } from 'cx/module/search/module/search-results/module/facet/config/types';

type RouterParams = {
    expandedFacetTypes: string[] | undefined;
};

export const getExpandedFacetTypes = (facets: Facet[], allExpanded: boolean): string[] => {
    const expandedFacetTypesFromRouting = getExpandedFacetTypesFromRouting();

    return expandedFacetTypesFromRouting ?? getInitialExpandedFacetTypes(facets, allExpanded);
};

const getExpandedFacetTypesFromRouting = (): string[] | undefined => {
    const routeParams = router.routeParams() as RouterParams;
    const { expandedFacetTypes } = routeParams;

    if (expandedFacetTypes === undefined) {
        return expandedFacetTypes;
    }

    return Object.values(expandedFacetTypes);
};

const getInitialExpandedFacetTypes = (facets: Facet[], allExpanded: boolean): string[] => {
    return facets.filter((facet) => allExpanded || hasSelectedItems(facet)).map((facet) => facet.type);
};

const hasSelectedItems = (facet: Facet): boolean => {
    return facet.selectedItems().some((item) => !item.default);
};
