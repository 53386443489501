import { pureComputed } from 'knockout';
import { SearchResultItemViewModel, SearchResultItemViewModelProps } from './SearchResultItemViewModel';
import appConfig from 'app/model/config';
import { noOperation } from 'core/utils/noOperation';
import store from 'site-editor/store/store';
import { mapDffsToRequisitionFlexFields } from './mapper/dffs';
import { SearchResultsRequisitionFlexField } from '../search-results/config/types';

export default class SearchResultItemStaticViewModel extends SearchResultItemViewModel {
    constructor(params: SearchResultItemViewModelProps) {
        super(params);
        this.isFavourite = pureComputed(() => false);
        this.getJobUrl = noOperation;
        this.openJob = noOperation;
        this.shouldShowDistance = appConfig.isDistanceCalculationEnabled;
        this.dffs = this.getDffs();
    }

    toggleFavourite(): void {
        this.isFavourite(!this.isFavourite());
    }

    protected getDffs(): SearchResultsRequisitionFlexField[] {
        return mapDffsToRequisitionFlexFields(store.searchResultDffs);
    }
}
