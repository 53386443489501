import http from 'core/http/http';
import { LightCandidate } from 'minimal/module/candidate-self-service/config/types';

const deleteFailResponseRegexp = /ORA_IRC_SUBS_DELETE_FAIL\[(.*?)\]$/;

export type DeleteError = {
    message: string;
    jobId: string;
};

export default {
    get(candidateNumber: string): Promise<LightCandidate> {
        return http.get(`/recruitingCECandidates/${candidateNumber}?onlyData=true`);
    },

    delete(candidateNumber: string): Promise<void> {
        return http.delete(`/recruitingCECandidates/${candidateNumber}`, {
            statusCodes: {
                400({ response }: { response: string }) {
                    const deleteFailMatch = response.match(deleteFailResponseRegexp);

                    if (deleteFailMatch) {
                        return {
                            message: 'delete-profile-fail',
                            jobId: deleteFailMatch[1],
                        } as DeleteError;
                    }

                    return Promise.reject();
                },
            },
        });
    },
};
