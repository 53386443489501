import 'minimal/module/apply-flow/module/agreements/component/agreement-dialog/component';
import 'cx/module/apply-flow/module/agreements/component/legal-disclaimer/component';
import 'cx/module/apply-flow/module/agreements/component/opt-in/component';
import 'cx/module/apply-flow/module/agreements/component/job-alerts-opt-in/component';

import { components } from 'knockout';
import metadataConfig from 'cx/module/apply-flow/config/metadata';

import ViewModel from 'apply-flow/module/agreements/AgreementsViewModel';
import template from 'apply-flow/module/agreements/agreements.html';

components.register('agreements', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_AGREEMENTS', {
    component: 'agreements',
});
