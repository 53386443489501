import FormValidator from 'core/form/validator/Validator';

export default class ZeroFillValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.add-leading-zeros';
    }

    _validate(value, options) {
        if (!value) {
            return true;
        }

        return String(value).length === Number(options.number_of_characters);
    }

}