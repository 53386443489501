export default class StyleSheetController {

    constructor(styleSheetTitle) {
        this.title = styleSheetTitle;

        this.styleSheet = this._getStylesheetElement();
    }

    applyCSS(compiledCSS) {
        this.styleSheet.innerText = compiledCSS;
    }

    _getStylesheetElement() {
        const styleSheet = document.querySelector(`[title="${this.title}"]`);
        const previewStyleSheet = document.createElement('style');

        styleSheet.parentNode.insertBefore(previewStyleSheet, styleSheet.nextSibling);

        return previewStyleSheet;
    }

}