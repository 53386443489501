import 'apply-flow/module/profile-import/config/routing';
import 'apply-flow/module/profile-import/component/awli-confirm/component';

// TODO: it seems the following module is not used anywhere (!)
import 'apply-flow/module/profile-import/mapper/linkedInProfileConverter';

import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import MetadataRepository from 'apply-flow/module/profile-import/service/Metadata';

import ViewModel from 'apply-flow/module/profile-import/ProfileImportViewModel';
import template from '../profile-import.html';

components.register('profile-import', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_PROFILE_IMPORT', {
    component: 'profile-import',
    repository: MetadataRepository,
    isPresectionBlock: true,
});
