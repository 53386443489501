import { observable } from 'knockout';
import { DONE, IN_PROGRESS, PENDING, UNKNOWN } from '../enum/taskStatus';

export class Step {

    constructor({ name }) {
        this.name = observable(name);
        this.status = observable(PENDING);
    }

    setStatus(status) {
        const statuses = new Set([
            PENDING,
            IN_PROGRESS,
            DONE,
        ]);

        if (!statuses.has(status)) {
            this.status(UNKNOWN);

            return;
        }

        this.status(status);
    }

}

