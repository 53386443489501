import { components } from 'knockout';

import './component';

import { CategoryTilesViewModel } from './CategoryTilesViewModel';
import categoryTilesTemplate from './category-tiles.html';

components.register('cc-category-tiles', {
    viewModel: CategoryTilesViewModel,
    template: categoryTilesTemplate,
});
