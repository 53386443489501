import { Observable, observable, toJS } from 'knockout';
import SearchFacetViewModel from '../search-facet/SearchFacetViewModel';
import { Props } from '../search-facet/SearchFacetViewModel';
import { Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import {
    searchFacets,
    getFilterByLabel,
} from 'cx/module/search/module/search-results/module/facet/service/facets';
import router from 'app/model/router';
import { updateExpandedFacetTypes } from '../search-filters-vertical/service/updateExpandedFacetTypes';
import { addClassToVerticalFilters } from '../search-filters-vertical/service/addClassToVerticalFilters';
import { noOperation } from 'core/utils/noOperation';
import { RouterParams, RouterQuery, searchContextMap, SearchRouteOnly } from 'cx/module/search/config/types';
import {
    getSearchContextParam,
    getStoredQuery,
    saveSearchContextParamInStorage,
} from '../../service/storeSearchContextDetails';
import SearchQueryBuilder from 'app/module/cx/module/search/model/SearchQueryBuilder';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import i18n from 'app/module/core/i18n/i18n';

let instance = 0;

type VerticalFilter = Filter & {
    cssClass?: string;
};

export default class SearchFacetVerticalViewModel extends SearchFacetViewModel {
    isOpen: Observable<boolean>;
    filterByLabel: string;
    keyword: Observable<string>;
    instance: number;
    showSearchFilters: boolean | undefined;
    isLoading: Observable<boolean>;

    constructor({ ...args }: Props) {
        super({ ...args });

        this.isOpen = observable(this.expandedFacetTypes.includes(this.facet.type));
        this.filterByLabel = getFilterByLabel(this.facet.name, this.facet.title);
        this.keyword = observable('');
        this.isLoading = observable<boolean>(false);
        this.action = this.adminMode ? noOperation : this.action.bind(this);
        this.showSearchFilters = this.facet.items().length >= 10 || this.adminMode;
        this.title = this.facet.title || i18n(`facets.${this.facet.name?.toLowerCase()}-title`);

        this.fetchFacetFilters = this.fetchFacetFilters.bind(this);
        this.setOption = this.setOption.bind(this);

        this.instance = ++instance;
    }

    toggleOpen(): void {
        this.isOpen(!this.isOpen());

        updateExpandedFacetTypes(this.expandedFacetTypes, this.facet.type, this.isOpen());
    }

    toggleFilter(item: Filter): void {
        item.selected(!item.selected());
    }

    setOption(item: Filter): void {
        const facetFilter = this.facet.items().find((facetItem: Filter) => facetItem.value === item.value);

        facetFilter ? this.toggleFilter(facetFilter) : this.facet.items.push(item);

        this.toggleFilter(item);

        const routeId = router.route().id;

        const query = getStoredQuery(searchContextMap[routeId as SearchRouteOnly]);

        const searchQuery = new SearchQueryBuilder(query).withFacet(toJS(this.facet)).build();

        if (areEventsEnabled()) {
            saveSearchContextParamInStorage(getSearchContextParam(routeId), {
                query: searchQuery,
            } as RouterParams);
        }

        router.go(routeId, { query: searchQuery }, { inherit: false });
    }

    action(event: Event, { item }: { item: Filter }): void {
        this.setOption(item);
    }

    async fetchFacetFilters(): Promise<VerticalFilter[]> {
        this.isLoading(true);

        const { query } = router.routeParams() as { query: RouterQuery };
        const selectedFiltersQuery = query || {};

        const filters = await searchFacets(
            this.facet.type,
            this.keyword(),
            selectedFiltersQuery,
            this.facet.context
        );

        const selectedFilters = this.facet.items().filter((facetFilter: Filter) => facetFilter.selected());

        this.isLoading(false);

        return addClassToVerticalFilters(filters, selectedFilters);
    }
}
