import dictionaryService, { MAX_DICTIONARY_ITEMS } from 'apply-flow/service/dictionary';

const STATE_PROVINCE_ELEMENT_NAME = 'stateProvinceCode';
const paramPlaceholderRegex = /<\w+>/i;

function _fetchDictionary(url) {
    return dictionaryService.getByUrl(url);
}

function _shouldDictionaryBeFetched(url, formElement) {
    return Boolean(url) && formElement.name !== STATE_PROVINCE_ELEMENT_NAME;
}

function _toCamelCase(str) {
    return str[0].toLowerCase() + str.substr(1);
}

function _setOtherFieldDependency(formElement) {
    const formElementDependency = formElement.dictionary.match(paramPlaceholderRegex);

    if (!formElementDependency) {
        return;
    }

    const [placeholder] = formElementDependency;
    const dependencyName = placeholder.slice(1, placeholder.length - 1);

    formElement.dependencyField = {
        attributeName: dependencyName,
        name: _toCamelCase(dependencyName),
        placeholder,
    };
}

function _resolveDictionaryUrl(formElement, restFormElements) {
    let url = formElement.dictionary;

    if (formElement.dependencyField) {
        const dependencyField = restFormElements
            .filter(restFormElement => restFormElement.attributeName === formElement.dependencyField.attributeName)
            .pop() || { defaultValue: '' };

        if (dependencyField.defaultValue == null) {
            return null;
        }

        url = url.replace(formElement.dependencyField.placeholder, dependencyField.defaultValue);
    }

    return url;
}

function _setSearchParameters(formElement, lookupValues) {
    const hasMoreItems = lookupValues.length >= MAX_DICTIONARY_ITEMS;
    // FIXME: remove when backend filtering in ExtraInformation will be available
    const isExtraInformation = formElement.dictionary.includes('recruitingCEExtraInformationDictionary');
    const shouldBeSearch = hasMoreItems && !isExtraInformation;

    if (shouldBeSearch) {
        const [{ lookupCodeKey, meaningKey }] = lookupValues;

        formElement.type = 'search';

        formElement.optionsKeys = {
            value: lookupCodeKey,
            label: meaningKey,
        };
    }
}

function _logError(error) {
    if (error) {
        console.error(error);
    }
}

export default {
    mapFormElementFromRest(restFormElementData, formElementData, restFormElements) {
        const restFormElement = restFormElementData || {};
        const formElement = formElementData || {};

        if (!restFormElement.dictionary) {
            return Promise.resolve(formElement);
        }

        formElement.dictionary = restFormElement.dictionary;
        formElement.options = [];
        _setOtherFieldDependency(formElement);

        const url = _resolveDictionaryUrl(formElement, restFormElements);

        if (!_shouldDictionaryBeFetched(url, formElement)) {
            return Promise.resolve(formElement);
        }

        return _fetchDictionary(url, formElement)
            .then((lookupValues) => {
                formElement.options = lookupValues;
                _setSearchParameters(formElement, lookupValues);

                return formElement;
            })
            .catch((error) => {
                _logError(error);

                return formElement;
            });
    },
};