import {
    CustomHeaderModel,
    CustomHeaderRestModel,
    HeaderType,
} from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import {
    mapCustomHeaderFromRest,
    defaultCustomHeaderConfig,
} from 'site-editor/module/theme-editor/mapper/customHeaders';
import appConfig from 'app/model/config';
import { HeaderStyle, LogoImages } from '../config/types';
import { getCustomStyles } from '../config/customStyles';
import { GLOBAL_HEADER_SETTINGS_KEY } from 'site-editor/module/theme-editor/module/global-header-editor/config/constants';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { AdditionalHeaderContentCustomizationParams } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/types';

export const DEFAULT_CSS = '';
export const DEFAULT_IMAGES = appConfig?.images;

export const defaultHeaderStyle: HeaderStyle = {
    images: DEFAULT_IMAGES,
    styles: DEFAULT_CSS,
};

const getHeaderById = (pageHeaderId: number): CustomHeaderModel | null => {
    const customHeader = appConfig.customHeaders.find(
        ({ headerId }: CustomHeaderRestModel) => headerId === pageHeaderId
    );

    return customHeader ? mapCustomHeaderFromRest(customHeader) : null;
};

const getHeaderImages = (
    { desktopLogoUrl, mobileLogoUrl }: AdditionalHeaderContentCustomizationParams,
    type: HeaderType
): LogoImages => {
    if (type === GLOBAL_HEADER_SETTINGS_KEY) {
        return {
            ...DEFAULT_IMAGES,
        };
    }

    return { logoImageUrl: desktopLogoUrl, mobileLogoImageUrl: mobileLogoUrl };
};

export const mapGlobalHeaderFromAppConfig = (): CustomHeaderModel => {
    const globalHeader =
        appConfig.customHeaders.find(
            ({ type }: CustomHeaderRestModel) => type === GLOBAL_HEADER_SETTINGS_KEY
        ) ?? defaultCustomHeaderConfig;

    return mapCustomHeaderFromRest(globalHeader);
};

export const getHeaderStyle = (pageHeaderId: number | null, uniqueWrapperClass: string): HeaderStyle => {
    if (!pageHeaderId) {
        return defaultHeaderStyle;
    }

    const pageHeader = getHeaderById(pageHeaderId);

    if (!pageHeader?.settings) {
        return defaultHeaderStyle;
    }

    return {
        styles: getCustomStyles(mapParamsConfigurationToObservable(pageHeader.settings), uniqueWrapperClass),
        images: getHeaderImages(pageHeader.settings.content, pageHeader.type),
    };
};
