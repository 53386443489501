import TimelineEntry from './TimelineEntry';

class TimelineItemsMapper {

    constructor(startYear) {
        this._startYear = new Date(`${startYear}-01-01`);
    }

    toTimelineEntry(item) {
        const { startDate, endDate } = this._getProjectionDates(item);
        const start = TimelineItemsMapper.monthDifference(this._startYear, startDate) + 11;
        const end = TimelineItemsMapper.monthDifference(this._startYear, endDate) + 11;

        return new TimelineEntry(start, end, item);
    }

    toTimelineEntries(items) {
        return items.map(this.toTimelineEntry.bind(this));
    }

    _getProjectionDates(item) {
        const currentDate = new Date();

        let startDate = item.startDate ? new Date(item.startDate) : undefined;
        let endDate = item.endDate ? new Date(item.endDate) : undefined;

        if (!endDate && startDate) {
            endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDay());
        }

        if (!endDate || item.isCurrent === 'Y' || endDate > currentDate) {
            endDate = currentDate;
        }

        startDate = startDate || new Date(endDate.getFullYear() - 1, endDate.getMonth(), endDate.getDay());

        return { startDate, endDate };
    }

    static monthDifference(d1, d2) {
        let months = (d2.getFullYear() - d1.getFullYear()) * 12;

        months += d2.getMonth() - d1.getMonth();

        return months;
    }

}

export default TimelineItemsMapper;