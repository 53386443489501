import AfterDateValidator from 'core/form/validator/AfterDate';
import BeforeDateValidator from 'core/form/validator/BeforeDate';
import BetweenFormValidator from 'core/form/validator/Between';
import CheckedFormValidator from 'core/form/validator/Checked';
import DateFormValidator from 'core/form/validator/Date';
import DecimalFormValidator from 'core/form/validator/Decimal';
import EqualsFormValidator from 'core/form/validator/Equals';
import PhoneEqualsFormValidator from 'core/form/validator/PhoneEquals';
import EmailFormValidator from 'core/form/validator/Email';
import FileExtensionFormValidator from 'core/form/validator/FileExtension';
import { UnsupportedFileExtensionFormValidator } from 'core/form/validator/UnsupportedFileExtension';
import FileSizeFormValidator from 'core/form/validator/FileSize';
import GreaterThanFormValidator from 'core/form/validator/GreaterThan';
import IntegerFormValidator from 'core/form/validator/Integer';
import LessThanFormValidator from 'core/form/validator/LessThan';
import MaxLengthFormValidator from 'core/form/validator/MaxLength';
import MinDateFormValidator from 'core/form/validator/MinDate';
import MaxDateFormValidator from 'core/form/validator/MaxDate';
import MinLengthFormValidator from 'core/form/validator/MinLength';
import OnlyUppercaseValidator from 'core/form/validator/OnlyUppercase';
import PhoneCountryCodeFormValidator from 'core/form/validator/PhoneCountryCode';
import PhoneNumberFormValidator from 'core/form/validator/PhoneNumber';
import PositiveIntegerFormValidator from 'core/form/validator/PositiveInteger';
import PostalFormValidator from 'core/form/validator/Postal';
import RequiredFormValidator from 'core/form/validator/Required';
import SameYearFormValidator from 'core/form/validator/SameYear';
import UrlFormValidator from 'core/form/validator/Url';
import StartDatePickerValidator from 'core/form/validator/StartDatePicker';
import { NotInArrayValidator } from 'core/form/validator/NotInArray';
import MinSelectionFormValidator from 'core/form/validator/MinSelection';
import MaxSelectionFormValidator from 'core/form/validator/MaxSelection';
import ServiceCallFormValidator from 'core/form/validator/ServiceCall';
import NotInExistingItemsValidator from 'core/form/validator/NotInExistingItems';
import PrecisionNumberFormValidator from 'core/form/validator/PrecisionNumber';
import ScaleNumberFormValidator from 'core/form/validator/ScaleNumber';
import ZeroFillValidator from 'core/form/validator/ZeroFill';
import NotIncludesValidator from 'core/form/validator/NotIncludes';
import XssValidator from 'core/form/validator/XssValidator';
import HtmlValidator from 'core/form/validator/HtmlValidator';
import CustomJsValidator from 'core/form/validator/CustomJs';
import UniqueFormValidator from 'core/form/validator/Unique';
import YearFormatValidator from 'core/form/validator/YearFormatValidator';
import { CharsetValidator } from 'app/module/core/form/validator/CharsetValidator';
import ErrorValidator from 'core/form/validator/Error';
import EqualsToValidator from 'core/form/validator/EqualsTo';

export default {
    create(type, options) {
        const Validator = this.validators[type];

        return new Validator(options);
    },

    validators: {
        afterDate: AfterDateValidator,
        beforeDate: BeforeDateValidator,
        between: BetweenFormValidator,
        checked: CheckedFormValidator,
        date: DateFormValidator,
        decimal: DecimalFormValidator,
        email: EmailFormValidator,
        equals: EqualsFormValidator,
        phoneEquals: PhoneEqualsFormValidator,
        fileExtension: FileExtensionFormValidator,
        unsupportedFileExtension: UnsupportedFileExtensionFormValidator,
        fileSize: FileSizeFormValidator,
        greaterThan: GreaterThanFormValidator,
        integer: IntegerFormValidator,
        lessThan: LessThanFormValidator,
        maxLength: MaxLengthFormValidator,
        minDate: MinDateFormValidator,
        maxDate: MaxDateFormValidator,
        minLength: MinLengthFormValidator,
        notInArray: NotInArrayValidator,
        notInExistingItems: NotInExistingItemsValidator,
        onlyUppercase: OnlyUppercaseValidator,
        phoneCountryCode: PhoneCountryCodeFormValidator,
        phoneNumber: PhoneNumberFormValidator,
        positiveInteger: PositiveIntegerFormValidator,
        postal: PostalFormValidator,
        required: RequiredFormValidator,
        sameYear: SameYearFormValidator,
        url: UrlFormValidator,
        startDatePicker: StartDatePickerValidator,
        minSelection: MinSelectionFormValidator,
        maxSelection: MaxSelectionFormValidator,
        serviceCall: ServiceCallFormValidator,
        precisionNumber: PrecisionNumberFormValidator,
        scaleNumber: ScaleNumberFormValidator,
        zeroFill: ZeroFillValidator,
        notIncludes: NotIncludesValidator,
        xss: XssValidator,
        html: HtmlValidator,
        customJs: CustomJsValidator,
        unique: UniqueFormValidator,
        yearFormat: YearFormatValidator,
        charset: CharsetValidator,
        error: ErrorValidator,
        equalsTo: EqualsToValidator,
    },
};