import { bindingHandlers } from 'knockout';

/**
 * Check which elements of data-scroll-spy has top position (minus offset) less than actual scroll position
 * then update passed observable with data-scroll-spy-id of this element.
 *
 * @params {observable} observer - observable to update
 * @params {type} string - define elements of current scroll spy instance
 * @params {offset} number - offset
 *
 * @listens scroll
 *
 * <div data-scroll-spy="{scroll-spy-type}" data-scroll-spy-id="{scroll-spy-id}" />
 *
 * <div data-bind="scrollSpy: { observer: observer, type: '{scroll-spy-type}', offset: {scroll-offest} }" />
 */

const DEFAULT_OFFSET = 0;

const SCROLL_SPY_SELECTOR = '[data-scroll-spy={type}]';
const SCROLL_SPY_ID = 'data-scroll-spy-id';

function calculateBreakpoints(elementsList) {
    return elementsList.map(element => element.offsetTop);
}

function findInterval(breakpoints, value) {
    let currentIndex = breakpoints
        .filter(breakpoint => breakpoint <= value)
        .map((breakpoint, index) => index)
        .pop();

    if (!currentIndex) {
        currentIndex = 0;
    }

    return currentIndex;
}

function getScrollPosition() {
    return (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
}

bindingHandlers.scrollSpy = {
    init(element, valueAccessor) {
        const { observer, type, offset } = valueAccessor();

        const nodes = document.querySelectorAll(SCROLL_SPY_SELECTOR.replace('{type}', type));
        const targets = [].slice.call(nodes);

        let ticking = false;

        const scrollHandler = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const scrollPosition = getScrollPosition();
                    const breakpoints = calculateBreakpoints(targets);

                    const index = findInterval(breakpoints, scrollPosition + offset || DEFAULT_OFFSET);

                    observer(targets[index].getAttribute(SCROLL_SPY_ID));

                    ticking = false;
                });
            }

            ticking = true;
        };

        window.addEventListener('scroll', scrollHandler);
    },
};
