import '../component/application-withdraw-dialog/component';
import '../component/application-draft-delete-dialog/component';
import '../component/cs-sign-in/component';
import '../component/cs-application-tile/component';
import '../component/cs-application-draft-tile/component';
import '../component/cs-applications/';
import '../component/cs-applications-confirm-all/component';
import 'cx/module/candidate-self-service/component/cs-job-alert/component';
import 'cx/module/candidate-self-service/component/cs-talent-community/component';
import 'cx/module/candidate-self-service/component/cs-application-context-menu/component';
import 'cx/module/candidate-self-service/component/cs-application-actions/component';
import 'cx/module/candidate-self-service/component/cs-dropdown-multiselect-pill-preference/component';
import 'cx/module/candidate-self-service/component/cs-search-multiselect-pill-preference/component';
import '../component/cs-delete-profile/component';
import '../component/cs-update-profile/component';
import '../component/signout-menu/';
import '../component/cs-navigation-bar/';
import '../component/cs-events/';
import './routing';
import 'minimal/module/search/config/routing';
import '../component/cs-event-confirm';

import { components } from 'knockout';
import { createViewModel } from '../service/createViewModel';

import template from '../candidate-self-service.html';

import cssShortTokenViewModel from 'minimal/module/candidate-self-service/CandidateSelfServiceShortTokenViewModel';
import cssActionTemplate from '../candidate-self-service-action.html';

components.register('candidate-self-service', { viewModel: { createViewModel }, template });

components.register('candidate-self-service-action', {
    viewModel: cssShortTokenViewModel,
    template: cssActionTemplate,
});
