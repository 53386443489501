function reduceConfig(accumulator, { columnName, value }) {
    accumulator[columnName] = value;

    return accumulator;
}

export default {
    mapToValidation(config = []) {
        return config.reduce(reduceConfig, {});
    },
};