import { emptyFiltersParams } from 'minimal/module/search/component/search-filters-panel/config/defaultParams';
import { EMPTY_BLOCK_STYLES, EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';
import {
    FiltersCustomizationParamsDeprecated,
    FiltersCustomizationParamsKey,
    FiltersCustomizationParamsKeyDeprecated,
} from '../config/types';

export const isFiltersCustomizationParamsKey = (
    paramKey: string
): paramKey is FiltersCustomizationParamsKey => {
    const filterKeys = Object.keys(emptyFiltersParams) as FiltersCustomizationParamsKey[];

    return filterKeys.includes(paramKey as FiltersCustomizationParamsKey);
};

export const isFiltersCustomizationParamsKeyDeprecated = (
    paramKey: string
): paramKey is FiltersCustomizationParamsKeyDeprecated => {
    const emptyParams: FiltersCustomizationParamsDeprecated = {
        filterPillsTypography: EMPTY_TYPOGRAPHY,
        filterPillStyles: EMPTY_BLOCK_STYLES,
    };
    const filterKeys = Object.keys(emptyParams);

    return filterKeys.includes(paramKey as FiltersCustomizationParamsKeyDeprecated);
};
