import 'candidate-verification/component/email-verification/component/email-verification-form/component';

import { components } from 'knockout';

import ViewModel from 'candidate-verification/component/email-verification/EmailVerificationViewModel';
import template from 'candidate-verification/component/email-verification/email-verification.html';
import footerTemplate from './email-verification-footer.html';

components.register('email-verification', {
    viewModel: ViewModel,
    template,
});

components.register('email-verification-footer', {
    template: footerTemplate,
});
