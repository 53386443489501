const positions = [];
const VIEWPORT_SELECTOR = '.app__viewport';

export default {
    scrollTo(pageY = 0, pageX = 0) {
        requestAnimationFrame(() => {
            window.scrollTo(pageX, pageY);
        });
    },

    store(isExtraOffsetRequired = true) {
        const offset = window.scrollY || window.pageYOffset;

        positions.push(offset);

        // TODO: Temporary fix to fix the background issue when opening the hamburger menu
        // Need to come up with a proper solution.
        if (isExtraOffsetRequired) {
            document.querySelector(VIEWPORT_SELECTOR).style.top = `-${offset}px`;
        }
    },

    restore() {
        const restored = positions.pop();
        const viewportDomElement = document.querySelector(VIEWPORT_SELECTOR);

        if (!restored) {
            return;
        }

        if (viewportDomElement) {
            viewportDomElement.style.top = '';
        }

        this.scrollTo(restored);
    },
};
