import { pureComputed } from 'knockout';
import VideoViewModel from 'cx/module/custom-content/component/video/VideoViewModel';
import { youtubeRegex } from 'cx/module/custom-content/component/video/service/videoFormat';

export default class YoutubeVideoViewModel extends VideoViewModel {

    constructor({ params }) {
        super({ params });

        this.src = pureComputed(this._getYoutubeSrc, this);
    }

    _getYoutubeSrc() {
        const [, id] = this.params.src().match(youtubeRegex);
        const autoplay = this.params.autoplay() ? 1 : 0;
        const muted = autoplay ? 1 : 0;

        return `https://www.youtube.com/embed/${id}?autoplay=${autoplay}&mute=${muted}`;
    }

}