import flowService from 'apply-flow/service/flow';
import legalDisclaimerMapper from 'apply-flow/mapper/legalDisclaimer';

export default {
    queryLegalDisclaimer(requisitionNumber) {
        return flowService.queryRawFlow(requisitionNumber)
            .then(legalDisclaimerMapper.mapLegalDisclaimerFromRest);
    },

    async queryTCLegalDisclaimer() {
        const talentCommunityFlow = await flowService.queryTalentCommunityFlow();

        return legalDisclaimerMapper.mapLegalDisclaimerFromRest(talentCommunityFlow);
    },

    queryEventLegalDisclaimer(eventId) {
        return flowService.queryRawEventFlow(eventId)
            .then(legalDisclaimerMapper.mapLegalDisclaimerFromRest);
    },
};