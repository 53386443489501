import { addScriptToHead } from 'core/utils/addScriptToHead';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';


export default {
    load(apiKey, mode) {
        const content = `
             api_key: ${apiKey}
             extensions: AwliWidget@https://www.linkedin.com/talentwidgets/extensions/apply-with-linkedin-widget-v3
            `;

        const src = 'https://platform.linkedin.com/xdoor/scripts/in.js';

        addScriptToHead(src, content, true)
            .then(() => {
                if (mode === 'CONVERSION') {
                    profileImportEvents.isAwliConversionLoaded.dispatch();
                }
            })
            .catch(() => {
                console.error('Unable to load script');
            });
    },
};