import flowService from 'apply-flow/service/flow';

function matchesSectionId(sectionId, targets) {
    return [targets]
        .flat()
        .includes(sectionId);
}

export function findBlockTitle(sectionId) {
    const flow = flowService.get();

    if (!flow) {
        return '';
    }

    const allBlocks = flow.sections.map(({ pages }) =>
        pages.map(({ blocks }) => blocks))
        .flat(2);

    const matchingBlock = allBlocks.find(({ blockProperties = {} }) => {
        const { sectionId: sectionIdProperty } = blockProperties;

        return matchesSectionId(sectionId, sectionIdProperty);
    });

    return matchingBlock ? matchingBlock.title : '';
}