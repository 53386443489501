import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class MultiSelectFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        // knockout:checked binding requires an Array for multiselect
        if (!Array.isArray(this.element.value())) {
            this.element.value([]);
        }

        this.element.value(this.filterNonexistentValues());
    }

    filterNonexistentValues() {
        return this.element.value()
            .filter(value => this.element.options()
                .find(({ lookupCode }) => lookupCode === value));
    }

}