import storageService from 'core/storage/sessionStorage';

const CACHE_KEY = 'siteLanguage-has-changed';

export default {
    hasChanged() {
        return Boolean(storageService.restore(CACHE_KEY));
    },

    registerChange() {
        storageService.store(CACHE_KEY, 'true');
    },

    dismiss() {
        storageService.remove(CACHE_KEY);
    },
};
