import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { CookiePreferenceLinkCustomizationParams } from './types';
import { EMPTY_BLOCK_STYLES, EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';

export const emptyParams: CookiePreferenceLinkCustomizationParams = {
    cookiePreferenceLinkText: { text: '' },
    cookiePreferenceLinkTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferenceLinkHoverTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferenceLinkContainerStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferenceLinkContainerHoverStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
