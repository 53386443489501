import mapping from 'knockout-mapping';

const jobAlertPreferences = {
    preferredLocations: [],
    preferredJobFamilies: [],
    preferredJobFunctions: [],

    getLocationIds() {
        return this.preferredLocations()
            .map(({ id }) => id);
    },

    getJobFamilyIds() {
        return this.preferredJobFamilies()
            .map(({ id }) => id);
    },

    clear() {
        this.preferredLocations([]);
        this.preferredJobFamilies([]);
        this.preferredJobFunctions([]);
    },

};

const jobAlertPreferencesModel = mapping.fromJS(jobAlertPreferences);

export default jobAlertPreferencesModel;
