import ko from 'knockout';
import Interval from './Interval';
import IntervalSequence from './IntervalSequence';

const ONE_LINE_MIN_SPACE = 3;
const TWO_LINES_MIN_SPACE = 6;

class TimelineEntry extends Interval {

    constructor(start, end, data) {
        super(start, end);
        this._resolveEndDate();

        this.offset = this.start;
        this.height = this.end - this.start;
        this.color = '';
        this.isFaded = ko.observable(false);

        ko.utils.extend(this, data);

        this._visibleIntervals = new IntervalSequence(Interval.compareStart, new Interval(this.start, this.end));
        this._contentSpot = null;
    }

    get contentOffset() {
        const visibleSpot = this._getContentSpot();

        return visibleSpot ? this.end - visibleSpot.end : 0;
    }

    get contentStyle() {
        const visibleSpot = this._getContentSpot();

        return visibleSpot && visibleSpot.length() < TWO_LINES_MIN_SPACE ? 'single-line' : null;
    }

    coverWith(entry) {
        this._contentSpot = null;
        this._visibleIntervals.subtractIntervals(entry);
    }

    _resolveEndDate() {
        if (this.length() < ONE_LINE_MIN_SPACE) {
            this.end = this.start + ONE_LINE_MIN_SPACE;
        }
    }

    _getContentSpot() {
        if (this._contentSpot === null) {
            this._contentSpot = this._visibleIntervals.getFirstNotShorter(TWO_LINES_MIN_SPACE)
                || this._visibleIntervals.getFirstNotShorter(ONE_LINE_MIN_SPACE);
        }

        return this._contentSpot;
    }

}

export default TimelineEntry;