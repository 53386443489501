import { observable, observableArray, pureComputed } from 'knockout';
import mapping from 'knockout-mapping';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import { JOB_CATEGORY_VALUE, JOB_FUNCTION_VALUE } from 'cx/module/job-alerts/enums/additionalAttributes';
import recruitingLocationService from 'cx/module/recruiting-location/service/recruitingLocation';
import notificationsService from '../../../../service/notifications';
import MultiSelectPillFormElementValue from 'core/form/component/multiselect-pill-form-element/model/MultiSelectPillFormElementValue';
import i18n from 'core/i18n/i18n';
import candidate from 'apply-flow/model/candidate';
import { get } from 'apply-flow/service/genericDictionary';

export default class CsJobAlertViewModel {

    constructor({ jobAlert, candidateNumber, showSiteName, jobAlertPreferencesConfig }) {
        this.siteName = jobAlert.siteName;
        this.showSiteName = showSiteName;
        this.jobAlert = jobAlert;
        this.candidateNumber = candidateNumber;
        this.jobAlertCheckboxId = `job-alert-checkbox-${jobAlert.siteNumber}`;
        this.jobAlertPreferencesConfig = jobAlertPreferencesConfig;
        this.validationDataErrorMessage = jobAlertService.getValidationMessage(this.jobAlertPreferencesConfig);

        this.isActive = observable(false);
        this.jobFunctions = observableArray();

        this.preferredLocations = observableArray();
        this.fetchAvailableLocationsFunction = recruitingLocationService.getLocationsByTerm;
        this.fetchAvailableJobFunctions = this._fetchAvailableJobFunctions.bind(this);
        this.preferredJobFamilies = observableArray();
        this.preferredJobFunctions = observableArray();
        this.fetchAvailableJobFamiliesFunction = jobAlertService.getJobFamilies;
        this.isSiteAvailable = jobAlert.siteName !== undefined;
        this.jobAlertsEnabled = observable(this.jobAlert.jobAlertsEnabled && this.isSiteAvailable);

        this.showJobCategories = this._showJobCategories.bind(this);
        this.showJobFunctions = this._showJobFunctions.bind(this);

        this.isLocationsReady = observable(false);
        this.isFamiliesReady = observable(false);
        this.isJobFunctionsReady = observable(false);

        this.jobFunctionsLoaded = pureComputed(this._computeJobFunctionsLoaded, this);

        jobAlertService.getLocationsByIds(jobAlert.preferredLocations)
            .then((locations) => {
                this.preferredLocations(locations.map(location =>
                    new MultiSelectPillFormElementValue({
                        value: location.geographyId.toString(),
                        label: location.geographyFlatName,
                    }),
                ));
            })
            .catch((error) => {
                if (error) {
                    console.error(error);

                    notificationsService.error(i18n('candidate-self-service.tc-locations-loading-error'));
                }
            })
            .finally(() => {
                this.isLocationsReady(true);
            });

        jobAlertService.getJobFamiliesByIds(jobAlert.preferredJobFamilies)
            .then((jobFamilies) => {
                this.preferredJobFamilies(jobFamilies.map(jobFamily =>
                    new MultiSelectPillFormElementValue({
                        value: jobFamily.jobFamilyId.toString(),
                        label: jobFamily.jobFamilyName,
                    }),
                ));
            })
            .catch((error) => {
                if (error) {
                    console.error(error);

                    notificationsService.error(i18n('candidate-self-service.tc-categories-loading-error'));
                }
            })
            .finally(() => {
                this.isFamiliesReady(true);
            });

        this._loadJobFunctions(jobAlert);

        this.savePreferences = this.savePreferences.bind(this);
    }

    editJobAlert() {
        this.isActive(true);
    }

    savePreferences() {
        const preferredLocations = mapping.toJS(this.preferredLocations).map(location => Number(location.value));
        const preferredJobFamilies = mapping.toJS(this.preferredJobFamilies).map(jobFamily => Number(jobFamily.value));

        const preferredJobFunctions = mapping.toJS(this.preferredJobFunctions)
            .map(jobFunction => jobFunction.value);

        const payload = Object.assign({}, this.jobAlert, {
            jobAlertsEnabled: this.jobAlertsEnabled(),
            preferredLocations,
            preferredJobFamilies,
            preferredJobFunctions,
        });

        candidate.subscribeToJobAlert(this.jobAlertsEnabled());

        if (this._isValid(preferredLocations, preferredJobFamilies, preferredJobFunctions)) {
            jobAlertService.saveTCJobAlert(payload, this.candidateNumber());
            this.isActive(false);
        }
    }

    async _loadJobFunctions({ preferredJobFunctions }) {
        let jobFunctions = await this._getJobFunctions('findByLookupTypeAndSite');

        this.jobFunctions(jobFunctions);

        const jobFunctionsLookupCodes = jobFunctions.map(jobFunction => jobFunction.lookupCode);

        const areJobFunctionsWithoutLabels = preferredJobFunctions.filter(
            preferredJobFunction => !jobFunctionsLookupCodes.includes(preferredJobFunction)).length > 0;

        if (areJobFunctionsWithoutLabels) {
            jobFunctions = await this._getJobFunctions('findByLookupType');
        }

        const jobFunctionPills = jobFunctions
            .filter(jobFunction => preferredJobFunctions.includes(jobFunction.lookupCode))
            .map(jobFunctionPill => new MultiSelectPillFormElementValue({
                value: jobFunctionPill.lookupCode,
                label: jobFunctionPill.meaning,
            }));

        this.preferredJobFunctions(jobFunctionPills);
        this.isJobFunctionsReady(true);
    }

    _fetchAvailableJobFunctions() {
        return Promise.resolve(this.jobFunctions().map(jobFunction => ({
            id: jobFunction.lookupCode,
            name: jobFunction.meaning,
        })));
    }

    _isValid(preferredLocations, preferredJobFamilies, preferredJobFunctions) {
        const isValid =
            Boolean(preferredLocations.length + preferredJobFamilies.length + preferredJobFunctions.length);

        if (!isValid) {
            notificationsService.error(i18n(this.validationDataErrorMessage), 0);
        }

        return isValid;
    }

    enableJobAlerts() {
        this.jobAlertsEnabled(!this.jobAlertsEnabled());
    }

    _showJobCategories() {
        return this.jobAlertPreferencesConfig.includes(JOB_CATEGORY_VALUE);
    }

    _showJobFunctions() {
        return this.jobAlertPreferencesConfig.includes(JOB_FUNCTION_VALUE);
    }

    _getJobFunctions(lookupFinder) {
        return get('JOB_FUNCTION_CODE', lookupFinder, this.jobAlert.siteNumber);
    }

    _computeJobFunctionsLoaded() {
        return this.showJobFunctions() && this.isJobFunctionsReady();
    }

}