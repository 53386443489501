import { toJS, observable, Observable } from 'knockout';
import router from 'app/model/router';
import i18n from 'app/module/core/i18n/i18n';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { Facet, Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { RouterParams, searchContextMap, SearchRouteOnly } from 'cx/module/search/config/types';
import {
    getSearchContextParam,
    getStoredQuery,
    saveSearchContextParamInStorage,
} from '../../service/storeSearchContextDetails';
import { areEventsEnabled } from 'app/service/areEventsEnabled';

export type Props = {
    facet: Facet;
    item: Filter;
    expandedFacetTypes: string[];
};

let recentlyTriggeredItem = '';

export default class SearchFacetItemViewModel {
    item: Filter;
    facet: Facet;
    expandedFacetTypes: string[];
    hasFocus: Observable<boolean>;
    ariaLabel?: string;

    constructor({ facet, item, expandedFacetTypes }: Props) {
        this.item = item;
        this.facet = facet;
        this.expandedFacetTypes = expandedFacetTypes;
        this.hasFocus = observable(recentlyTriggeredItem === item.text);

        this.ariaLabel = `${i18n('search.filter-label', { filtername: this.item.text })} ${
            this.item.totalCount
        }`;
    }

    toggleItem(item: Filter): void {
        item.selected(!item.selected());
    }

    setOption(item: Filter): void {
        this.toggleItem(item);

        recentlyTriggeredItem = item.text;

        const routeId = router.route().id;

        const query = getStoredQuery(searchContextMap[routeId as SearchRouteOnly]);

        const searchQuery = new SearchQueryBuilder(query).withFacet(toJS(this.facet)).build();

        if (areEventsEnabled()) {
            saveSearchContextParamInStorage(getSearchContextParam(routeId), {
                query: searchQuery,
            } as RouterParams);
        }

        router.go(
            routeId,
            { query: searchQuery, expandedFacetTypes: this.expandedFacetTypes },
            { inherit: false }
        );
    }
}
