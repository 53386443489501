import { register } from 'ce-common/service/templateRepository';

import uploadButton from './recommended-jobs-widget-upload-button.html';
import uploadStatus from './recommended-jobs-widget-upload-status.html';
import metaDataDeleteModal from './recommended-jobs-widget-meta-data-delete-modal.html';
import privacyPolicyModal from './recommended-jobs-widget-privacy-policy-modal.html';
import manageActionDropdown from './recommended-jobs-widget-manage-dropdown.html';

register('recommended-jobs-widget-upload-button', uploadButton);
register('recommended-jobs-widget-upload-status', uploadStatus);
register('recommended-jobs-widget-meta-data-delete-modal', metaDataDeleteModal);
register('recommended-jobs-widget-privacy-policy-modal', privacyPolicyModal);
register('recommended-jobs-widget-manage-dropdown', manageActionDropdown);
