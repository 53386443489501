import { observable } from 'knockout';

export default class InterviewDetail {

    constructor(data = {}) {
        this.interviewId = data.interviewId;
        this.autoCalendarSlot = data.autoCalendarSlot;
        this.scheduleId = observable(data.scheduleId);
        this.requestId = data.requestId;
        this.submissionId = observable(data.submissionId);
        this.isScheduled = observable(data.isScheduled);
        this.address = data.address;
        this.phoneFormatted = data.phoneFormatted;
        this.phonePasscode = data.phonePasscode;
        this.webConferenceUrl = data.webConferenceUrl;
        this.jobTitle = observable(data.jobTitle);
        this.startTimeUTC = observable(data.startTimeUTC);
        this.startTime = observable(data.startTime);
        this.endTimeUTC = observable(data.endTimeUTC);
        this.endTime = observable(data.endTime);
        this.candidateMessage = observable(data.candidateNotes);
        this.interviewerNames = data.interviewerNames;
        this.reScheduleLimit = observable(data.reScheduleLimit);
        this.canSchedule = observable(data.canSchedule);
        this.canReSchedule = observable(data.canReSchedule);
        this.lockDuration = observable(data.lockDuration);
        this.canCancel = observable(data.canCancel);
        this.locationDetail = observable(data.locationDetail);
        this.action = data.action;
    }

}