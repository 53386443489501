import http from 'core/http/http';
import appConfig from 'app/model/config';
import siteLanguage from 'ce-common/service/language/siteLanguage';

export const pageUrl = '/:lang:/sites/:siteCode:/page/:pageCode:?statusCode=:statusCode:&onlyData=:onlyData:';

export const ERROR_NO_PAGE_SET = 'ERROR_NO_PAGE_SET';

export default {
    getPageData(pageCode, lang) {
        if (!pageCode) {
            return Promise.reject(ERROR_NO_PAGE_SET);
        }

        return http.get(pageUrl, {
            baseUrl: appConfig.ceBaseUrl,
            params: {
                siteCode: appConfig.siteNumber,
                lang: lang || siteLanguage.get(),
                onlyData: true,
                pageCode,
                statusCode: 'ORA_ACTIVE',
            },
        });
    },

    getPageCodeByType(pageType) {
        return appConfig.templates[pageType] ?? {};
    },
};