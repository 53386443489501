import RegulatoryViewModel from 'apply-flow/module/regulatory/RegulatoryViewModel';
import DisabilityMetadataService from 'apply-flow/module/regulatory/module/disability/service/DisabilityMetadata';

export default class DisabilityViewModel extends RegulatoryViewModel {

    constructor(params) {
        super(params, new DisabilityMetadataService(params.metadataServiceUrl));
    }

}
