import appConfig from 'app/model/config';
import talentCommunityEvents from 'cx/module/talent-community/config/events';
import absoluteRouter from 'app/model/absoluteRouter';

export default class TalentCommunityTileViewModel {

    constructor({ className } = {}) {
        this.className = className ? `talent-community-tile--${className}` : '';

        this.labels = {
            header: appConfig.talentCommunitySignUp.title,
            description: appConfig.talentCommunitySignUp.description,
            button: appConfig.talentCommunitySignUp.buttonLabel,
        };

        this.url = absoluteRouter.interpolate({}, 'talent-community');
    }

    openSignUpComponent() {
        talentCommunityEvents.show.dispatch();
    }

}
