import { components } from 'knockout';
import './templates';
import eventDetailViewModel from './EventDetailsViewModel';
import eventPreviewTemplate from './event-details.html';
import eventDetailsFullscreenTemplate from './event-details-fullscreen.html';
import eventDetailsFullscreenViewModel from './EventDetailsFullscreenViewModel';
import eventDetailsCssViewModel from './EventDetailsCssViewModel';

components.register('event-details', { viewModel: eventDetailViewModel, template: eventPreviewTemplate });

components.register('event-details-fullscreen', {
    viewModel: eventDetailsFullscreenViewModel,
    template: eventDetailsFullscreenTemplate,
});

components.register('event-details-css', {
    viewModel: eventDetailsCssViewModel,
    template: eventPreviewTemplate,
});
