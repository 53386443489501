import ValidatableFormElement from 'core/form/element/ValidatableElement';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';

export default class PhoneFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component('phone-form-element');

        this.showLabelTooltip(false);

        this.attributes('type', 'tel');

        this.validators('phoneNumber', {
            fieldname: () => this.label(),
        });
    }

    formatPhoneNumberWithoutCountryCode(phoneNumber) {
        return phoneLocaleAwareFactory.formatPhoneNumberWithoutCountryCode(phoneNumber);
    }

    getNANPPrefix() {
        return phoneLocaleAwareFactory.NANP_CALL_PREFIX;
    }

    setValue(phoneNumber) {
        const phoneObject = phoneLocaleAwareFactory.createPhone(phoneNumber);

        this.value({
            countryCode: phoneObject.countryCode,
            legislationCode: phoneObject.legislationCode,
            areaCode: phoneObject.areaCode,
            number: phoneObject.number,
        });
    }

    isEmpty() {
        return this._isValueEmpty();
    }

    _isValueEmpty() {
        const { countryCode, areaCode, number } = this.value() || {};

        return !(countryCode || areaCode || number);
    }

}
