import Signal from 'signals';

export default {
    openFormDialog: new Signal(),
    closeFormDialog: new Signal(),
    renderTimeline: new Signal(),
    editTimelineItem: new Signal(),
    mouseoverItem: new Signal(),
    mouseleaveItem: new Signal(),
};
