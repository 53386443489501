import { ApplicationsListState } from 'candidate-self-service/component/cs-applications/service/ApplicationsListState';
import { observable, PureComputed, pureComputed, Subscription } from 'knockout';
import { focusNthEntity } from 'candidate-self-service/component/cs-applications/service/applicationsListHelpers';
import { eventsState } from '../../service/EventsState';
import { PENDING, LOADED } from 'candidate-self-service/enum/loadingStatus';
import { isNoDataLoaded } from '../../service/isNoDataLoaded';
import csEvents from 'cx/module/candidate-self-service/config/events';

export default class CsEventsViewModel {
    activeEvents: ApplicationsListState;
    inactiveEvents: ApplicationsListState;
    showNoDataComponent: PureComputed<boolean>;
    statusSubscription: Subscription;

    constructor() {
        this.activeEvents = new ApplicationsListState({
            entities: observable([]),
            loadingStatus: eventsState.loadingStatus,
            titleKey: 'candidate-self-service.events.active-registrations',
            name: 'active',
        });

        this.inactiveEvents = new ApplicationsListState({
            entities: observable([]),
            loadingStatus: eventsState.loadingStatus,
            titleKey: 'candidate-self-service.events.inactive-registrations',
            name: 'inactive',
        });

        this.showNoDataComponent = pureComputed(() =>
            isNoDataLoaded([this.activeEvents, this.inactiveEvents])
        );

        this.statusSubscription = eventsState.loadingStatus.subscribe((status) => {
            if (status === LOADED) {
                this.setEntities();
            }
        });

        csEvents.reloadEvents.add(this.reloadEvents.bind(this));
    }

    dispose(): void {
        this.statusSubscription.dispose();
    }

    handleShowMore(container: ApplicationsListState): void {
        const displayedEventsCount = container.paginatedEntities().length;

        container.showMore();

        const parentSelector = `#events-list-${container.name}`;
        const itemSelector = '.cs-job-info__title';

        focusNthEntity({ parentSelector, itemSelector, index: displayedEventsCount });
    }

    reloadEvents(): void {
        this.clearLoadingStatuses();

        eventsState.loadEvents();
    }

    private clearLoadingStatuses() {
        this.activeEvents.loadingStatus(PENDING);
        this.inactiveEvents.loadingStatus(PENDING);
    }

    private setEntities() {
        this.activeEvents.entities(
            eventsState.eventsList().filter((event) => event.registrationStatus === 'active')
        );

        this.inactiveEvents.entities(
            eventsState.eventsList().filter((event) => event.registrationStatus === 'inactive')
        );
    }
}
