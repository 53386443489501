import postMessageService from 'core/post-message/postMessageService';
import appConfig from 'app/model/config';
import themeEvents from '../config/events';
import themeSnapshot from './themeSnapshot';

function updateTheme(theme) {
    themeEvents.welcomeTextUpdated.dispatch(theme.welcomeText);

    themeEvents.customHeaderUpdated.dispatch(theme.customHeader, theme.brand.headerHtml);
    themeEvents.customFooterUpdated.dispatch(theme.customFooter, theme.brand.footerHtml);
    themeEvents.templateFooterUpdated.dispatch(theme.templateFooter);

    themeEvents.stylesUpdated.dispatch(theme.generatedCustomCSS, theme.brand);
    themeEvents.customCssUpdated.dispatch(theme.brand.customCss);
    themeEvents.customJsUpdated.dispatch(theme.brand.customJs, theme.customJsEnabled);
    themeEvents.customFontsUpdated.dispatch(theme.customFonts);

    themeEvents.themeSettingsUpdated.dispatch(theme.themeSettings);
    themeEvents.logoImageUrl.dispatch(theme.brand.logoImageUrl, 'admin-theme');
    themeEvents.mobileLogoImageUrl.dispatch(theme.brand.mobileLogoImageUrl, 'admin-theme');
    themeEvents.frontImageUrl.dispatch(theme.brand.frontImageUrl);
    themeEvents.customHeaderSettingsUpdated.dispatch(theme.globalHeaderSettings, theme.brand);
}

postMessageService.add('store-theme', (config) => {
    themeSnapshot.store(config);

    appConfig.setTheme(themeSnapshot.restore());
});

postMessageService.add('restore-theme', () => {
    const config = themeSnapshot.restore();

    appConfig.setTheme(config);
});

postMessageService.add('clear-stored-theme', () => {
    themeSnapshot.remove();
});

postMessageService.add('update-theme', updateTheme);
