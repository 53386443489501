import { Observable } from 'knockout';
import {
    AdditionInformationItem,
    HeaderInformationItem,
    JobInfoDisplayedItem,
    JobTagsInformationItem,
    LayoutStyle,
    MainContentInformationItem,
    SearchResultsContentParams,
} from 'minimal/module/search/component/search-results/config/types';
import { defaultContentParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { DimensionUnit } from 'minimal/module/search/service/customCss';
import { NON_TOGGLE_DISPLAY_STYLE_LIST } from 'site-editor/module/search-editor/component/cc-search-results-editor/config/defaultParams';
import {
    DisplayStyle,
    JobInfoDisplay,
    JobInfoSeparator,
    JobInfoTagsConfiguration,
} from 'cx/module/search/config/types';
import { isMapEnabled } from 'ce-common/module/maps/service/isMapEnabled';
import { isAdminRoute } from 'app/module/core/router/service/isAdminRoute';
import store from 'site-editor/store/store';
import { mapClassicContentParamsToNewContentParams } from '../migration/contentParams';
import { SearchResultsClassicContentParams } from '../migration/types';

export const mapContentParams = (
    stringifiedParams: string | undefined | null
): SearchResultsContentParams => {
    if (!stringifiedParams) {
        return defaultContentParams;
    }

    let parsedParams = JSON.parse(stringifiedParams) as Record<string, string | boolean | undefined | null>;

    if (parsedParams.additionalInformationDisplayed) {
        parsedParams = mapClassicContentParamsToNewContentParams(
            parsedParams as unknown as SearchResultsClassicContentParams
        ) as unknown as Record<string, string | boolean | undefined | null>;
    }

    return {
        headerInformationDisplayed:
            (parsedParams.headerInformationDisplayed as unknown as HeaderInformationItem[]) ||
            defaultContentParams.headerInformationDisplayed,
        jobInfoDisplayed:
            (parsedParams.jobInfoDisplayed as unknown as JobInfoDisplayedItem[]) ||
            defaultContentParams.jobInfoDisplayed,
        jobTagsInformationDisplayed:
            (parsedParams.jobTagsInformationDisplayed as unknown as JobTagsInformationItem[]) ||
            defaultContentParams.jobTagsInformationDisplayed,
        mainContentInformationDisplayed:
            (parsedParams.mainContentInformationDisplayed as unknown as MainContentInformationItem[]) ||
            defaultContentParams.mainContentInformationDisplayed,

        additionalInformationDisplayed:
            (parsedParams.additionalInformationDisplayed as unknown as AdditionInformationItem[]) ||
            undefined,

        displayAfterJobs: parsedParams.displayAfterJobs
            ? parseInt(String(parsedParams.displayAfterJobs))
            : defaultContentParams.displayAfterJobs,

        enableInfiniteScroll:
            typeof parsedParams.enableInfiniteScroll === 'undefined' ||
            parsedParams.enableInfiniteScroll === null
                ? defaultContentParams.enableInfiniteScroll
                : Boolean(parsedParams.enableInfiniteScroll),

        criteria: (parsedParams.criteria as string) || defaultContentParams.criteria,

        hideTalentCommunitySignup:
            typeof parsedParams.hideTalentCommunitySignup === 'undefined' ||
            parsedParams.hideTalentCommunitySignup === null
                ? defaultContentParams.hideTalentCommunitySignup
                : Boolean(parsedParams.hideTalentCommunitySignup),
        jobDisplayStyle: getJobDisplay(parsedParams),

        numberOfJobsDisplayed: parsedParams.numberOfJobsDisplayed
            ? parseInt(String(parsedParams.numberOfJobsDisplayed))
            : defaultContentParams.numberOfJobsDisplayed,

        width: parsedParams.width ? parseInt(String(parsedParams.width)) : defaultContentParams.width,

        widthUnit: (parsedParams.widthUnit as DimensionUnit) || defaultContentParams.widthUnit,

        jobInfoDisplay:
            (parsedParams.jobInfoDisplay as unknown as JobInfoDisplay) || defaultContentParams.jobInfoDisplay,

        jobInfoSeparator:
            (parsedParams.jobInfoSeparator as unknown as JobInfoSeparator) ||
            defaultContentParams.jobInfoSeparator,

        jobInfoTagsConfiguration:
            (parsedParams.jobInfoTagsConfiguration as JobInfoTagsConfiguration) ||
            defaultContentParams.jobInfoTagsConfiguration,

        enableSaveIcon:
            typeof parsedParams.enableSaveIcon === 'undefined' || parsedParams.enableSaveIcon === null
                ? defaultContentParams.enableSaveIcon
                : Boolean(parsedParams.enableSaveIcon),

        enableShareIcon:
            typeof parsedParams.enableShareIcon === 'undefined' || parsedParams.enableShareIcon === null
                ? defaultContentParams.enableShareIcon
                : Boolean(parsedParams.enableShareIcon),

        enableArrowIcon:
            typeof parsedParams.enableArrowIcon === 'undefined' || parsedParams.enableArrowIcon === null
                ? defaultContentParams.enableArrowIcon
                : Boolean(parsedParams.enableArrowIcon),

        enableApplyButton:
            typeof parsedParams.enableApplyButton === 'undefined' || parsedParams.enableApplyButton === null
                ? defaultContentParams.enableApplyButton
                : Boolean(parsedParams.enableApplyButton),

        layoutStyle: (parsedParams.layoutStyle as LayoutStyle) || defaultContentParams.layoutStyle,

        originalLayoutStyle:
            (parsedParams.originalLayoutStyle as LayoutStyle) || defaultContentParams.originalLayoutStyle,
    };
};

const getJobDisplay = (parsedParams: Record<string, string | boolean | null | undefined>): DisplayStyle => {
    const site = store.site as unknown as {
        isMapsEnabled: Observable<boolean>;
    };

    const isMapsEnabled = isAdminRoute() ? site?.isMapsEnabled() : isMapEnabled();

    if (parsedParams?.jobDisplayStyle as DisplayStyle) {
        return isMapsEnabled
            ? (parsedParams?.jobDisplayStyle as DisplayStyle)
            : getDefaultDisplayoption(parsedParams?.jobDisplayStyle as DisplayStyle);
    }

    return defaultContentParams.jobDisplayStyle;
};

const getDefaultDisplayoption = (jobDisplayStyle: DisplayStyle): DisplayStyle =>
    NON_TOGGLE_DISPLAY_STYLE_LIST.includes(jobDisplayStyle)
        ? jobDisplayStyle
        : jobDisplayStyle === 'toggleListMap'
        ? 'list'
        : 'tile';
