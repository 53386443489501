const GEOGRAPHY_LEVEL = {
    1: 'country',
    2: 'state',
    3: 'city',
};

export default {
    mapLocations(restCollection) {
        if (!restCollection.items || !restCollection.items.length) {
            return [];
        }

        return restCollection.items.map(this.mapSingleLocation);
    },

    mapSingleLocation(location) {
        return {
            id: location.geographyId.toString(),
            geographyId: location.geographyId,
            name: location.geographyFlatName,
            geographyFlatName: location.geographyFlatName,
            level: GEOGRAPHY_LEVEL[location.geographyLevel],
            locationId: location.geographyId,
        };
    },
};