import ValidatableFormElement from 'core/form/element/ValidatableElement';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';
import { getMonths, getYears, getDays } from '../service/dateLocaleNames';
import i18n from '../../i18n/i18n';

export default class CalendarFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component('datepicker-form-element');
        this.layout = (new LocaleBasedDateTimeFormatter(appConfig.siteLang)).getDatePickerFormat('dd-mm-yy');

        this.fixedDay = null;

        this.addValidator('date', null, 'prepend');
        this.addValidator('yearFormat', { fieldname: () => i18n('general.date-picker.year') }, 'prepend');
    }

    generateDays() {
        return getDays();
    }

    generateMonths() {
        return getMonths();
    }

    generateYears() {
        return getYears();
    }

}
