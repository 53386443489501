import formBuilder from 'core/form/formBuilder';
import dictionaryService from 'apply-flow/service/dictionary';

const COUNTRY_FIELD_NAME = 'countryCode';
const STATE_PROVINCE_FIELD_NAME = 'stateProvinceCode';

function _findStateProvinceByCountryCode(countryCode) {
    return dictionaryService.findWithFinder({
        code: dictionaryService.CODES.profileStateProvinces,
        criteria: {
            CountryCode: countryCode,
        },
    });
}

export default Object.create(formBuilder, {
    createForm: {
        value(config, alreadyCreatedForm) {
            let form = alreadyCreatedForm;

            if (!form) {
                const createForm = formBuilder.createForm.bind(this);

                form = createForm(config);
            }

            this.narrowDownListOfStatesBasedOnCountry(form);

            return form;
        },
    },

    narrowDownListOfStatesBasedOnCountry: {
        value(form) {
            const country = form.getElement(COUNTRY_FIELD_NAME);
            const stateProvince = form.getElement(STATE_PROVINCE_FIELD_NAME);
            const isStateRequired = stateProvince && stateProvince.isRequired();

            const _setStatesFromCountryCode = (countryCode) => {
                stateProvince.value(null);
                stateProvince.isRequired(false);
                stateProvince.options([]);

                if (countryCode) {
                    _findStateProvinceByCountryCode(countryCode)
                        .then((options) => {
                            stateProvince.options(options);
                            stateProvince.isRequired(country.value() && isStateRequired && options.length);
                        });
                }
            };

            if (country && stateProvince) {
                const isStateInitiallyHidden = stateProvince.isHidden();

                if (!stateProvince.isHidden()) {
                    stateProvince.isHidden(!country.value());
                    stateProvince.isRequired(false);
                }

                const countrySubscription = country.value.subscribe(_setStatesFromCountryCode);

                const stateProvinceSubscription = stateProvince.options.subscribe((options) => {
                    if (!isStateInitiallyHidden) {
                        stateProvince.isHidden(!options.length);
                    }
                });

                if (country.value()) {
                    _setStatesFromCountryCode(country.value());
                }

                form.subscriptions.push(countrySubscription, stateProvinceSubscription);
            }
        },
    },

    _hasMajorField: {
        value(config) {
            if (config.contentTypeId === 106) {
                return config.elements.filter(element => element.name() === 'major').length;
            }

            return false;
        },
    },
});