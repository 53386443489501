import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/profile-items/service/Metadata';

import '../form/component/location-picker/component';
import '../form/component/locations-form-element/component';

import ViewModel from '../WorkPreferencesViewModel';
import template from '../../profile-items/profile-items.html';

components.register('work-preferences', { viewModel: ViewModel, template });


metadataConfig.registerBlock('ORA_WORK_PREFERENCE', {
    component: 'work-preferences',
    repository: metadataRepository,
    requireBlockProperties: true,
});
