import { bindingHandlers } from 'knockout';

/**
 * Add target attribute of all links to _blank
 *
 *  @example
 *     <p data-bind="targetBlank">
 *          <a href="https://www.oracle.com">Link</a>
 *     </p>
 */

bindingHandlers.targetBlank = {
    init(element: HTMLElement) {
        const links = element.querySelectorAll('a');

        Array.from(links).forEach((link: HTMLElement) => link.setAttribute('target', '_blank'));
    },
};
