import appConfig from 'app/model/config';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

export const TC_TILE_ID = 'CE_TALENT_COMMUNITY_TILE';

export const isTalentCommunityTileEnable = () => {
    if (!isTCOptInEnabled()) {
        return false;
    }

    const { showAtSearchResults } = appConfig.talentCommunitySignUp;

    return Boolean(showAtSearchResults);
};


export const insertTileToResults = (results, position) => {
    if (!isTalentCommunityTileEnable() || !results.length) {
        return results;
    }

    results.splice(position, 0, { id: TC_TILE_ID });

    return results;
};
