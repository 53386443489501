import appConfig from 'app/model/config';
import store from 'admin/module/site-editor/store/store';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { Observable } from 'knockout';
import { PageType, SEARCH_PAGE } from 'app/module/admin/module/site-editor/enum/pageTypes';

const DEFAULT_LOCAL_VALUE = 'true';

type SiteShape = {
    areRecommendedJobsEnabled: Observable<boolean>;
};

const GLOBAL_RECOMMENDED_JOBS_FLAG_KEY = 'ORA_IRC_RESUME_PARSING_ENABLED';

const LOCAL_RECOMMENDED_JOBS_FLAG_KEY = 'RECOMMENDED_JOBS_ENABLED';

const ELASTIC_SEARCH_ENABLED_KEY = 'IRC_ELASTIC_SEARCH_ENABLED';

export const areRecommendedJobsEnabledGlobally = (): boolean =>
    mapTrueFalseStringToBoolean(appConfig.getSettingByKey(GLOBAL_RECOMMENDED_JOBS_FLAG_KEY)) &&
    mapTrueFalseStringToBoolean(appConfig.getSettingByKey(ELASTIC_SEARCH_ENABLED_KEY));

export const areRecommendedJobsEnabledLocally = (): boolean => {
    if (isAdminRoute()) {
        const site = store.site as SiteShape | null;

        return Boolean(site?.areRecommendedJobsEnabled());
    }

    const settingValue = appConfig.getSettingByKey(LOCAL_RECOMMENDED_JOBS_FLAG_KEY);

    return mapTrueFalseStringToBoolean(settingValue ?? DEFAULT_LOCAL_VALUE);
};

export const areRecommendedJobsEnabled = (): boolean =>
    areRecommendedJobsEnabledGlobally() && areRecommendedJobsEnabledLocally();

export const shouldDisplaySuccessMessageSettings = (pageType: PageType | undefined): boolean =>
    pageType === SEARCH_PAGE;
