import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import MetadataRepository from 'apply-flow/module/questionnaire/service/Metadata';

import ViewModel from 'apply-flow/module/questionnaire/QuestionnaireViewModel';
import template from 'apply-flow/module/questionnaire/questionnaire.html';
import { ORA_EVENT_REG_QUESTIONS } from 'app/module/cx/module/apply-flow/module/questionnaire/enum/questionnaireBlocks';

components.register('event-registration-questions', { viewModel: ViewModel, template });

metadataConfig.registerBlock(ORA_EVENT_REG_QUESTIONS.blockCode, {
    component: ORA_EVENT_REG_QUESTIONS.componentName,
    repository: MetadataRepository,
});
