import { pureComputed } from 'knockout';
import candidateModel from 'apply-flow/model/candidate';
import sectionState from 'apply-flow/model/sectionState';

export default class MiscAttachmentsViewModel {

    constructor({ section, blockId, isActive, blockProperties: { required } }) {
        this.section = section;
        this.isActiveBlock = isActive;
        this.blockId = blockId;
        this.required = required;

        this.attachments = pureComputed(this.filterMiscellaneousAttachments, this);

        sectionState.setBlockReady(this.section.number, this.blockId);
    }

    filterMiscellaneousAttachments() {
        return candidateModel.attachments()
            .filter(({ blockId }) => blockId() === Number(this.blockId))
            .reverse();
    }

    dispose() {
        this.attachments.dispose();
    }

}