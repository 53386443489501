import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

const UNSUPPORTED_FILE_EXTENSION_VALIDATOR = 'unsupportedFileExtension';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            const form = formBuilder.createForm();

            const formElements = [
                this._createFileElement(config),
            ];

            form.elements(formElements);

            return form;
        },
    },

    _createFileElement: {
        value(config) {
            const fileElement = formElementFactory.create('file', config.name);

            fileElement.label(i18n(config.label));
            fileElement.onFileSelected = config.onFileSelected;
            fileElement.hasFocus = config.hasFocus;
            fileElement.labelClass = config.labelClass;

            fileElement.attributes({
                accept: config.acceptedFileExtensions,
                title: fileElement.label(),
                'aria-required': config.isRequired,
            });

            if (config.acceptedFileExtensions) {
                fileElement.addValidator('fileExtension', {
                    allowed_extensions: config.acceptedFileExtensions.split(', '),
                });
            }

            fileElement.addValidator('fileSize', { fileSizeLimit: config.sizeLimit });

            if (config.unsupportedFileExtensions) {
                fileElement.addValidator(UNSUPPORTED_FILE_EXTENSION_VALIDATOR, {
                    unsupported_extensions: config.unsupportedFileExtensions.split(', '),
                });
            }

            return fileElement;
        },
    },
});
