import ValidationNotifications from './validationNotifications';
import { UNAUTHORIZED } from 'candidate-verification/service/user-session/unauthorized';

export default class MultiValidationNotifications extends ValidationNotifications {

    async sendAndNotify(configs) {
        let allErrorMessages = '';

        await Promise.all(configs.map(async (config) => {
            const error = await this._handleRequest(config);

            allErrorMessages = this._createMultilineMessage(allErrorMessages, error);
        }));

        return allErrorMessages ? this._handleError(allErrorMessages) : this._handleSuccess();
    }

    _handleRequest(config) {
        const { payload, ...requestConfig } = config;

        return this._sendRequest(payload, requestConfig)
            .catch(error =>
                (!this._isValid(error) ? error : ''));
    }

    _createMultilineMessage(targetMessage, message) {
        return [targetMessage, message]
            .filter(Boolean)
            .join('\n');
    }

    _isUnauthorizedMessage(multilineMessage) {
        const allMessages = multilineMessage.split('\n');

        return allMessages.every(message => message === UNAUTHORIZED);
    }

}

export const multiValidationNotifications = new MultiValidationNotifications();