import { Observable } from 'knockout';
import FormValidator from 'core/form/validator/Validator';

type Options = {
    message?: string;
    error: string;
    source: Observable<string>;
};

export default class ErrorValidator extends FormValidator {
    constructor(options: Options) {
        super(options);

        this._message = 'error-message-header';
    }

    _validate(value: string, options: Options): boolean {
        value = options.source();
        options.source('');

        if (value) {
            return !value.includes(options.error);
        }

        return true;
    }
}
