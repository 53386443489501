import ko from 'knockout';
import { updateTabIndex } from 'core/a11y/service/updateTabIndex';

/**
 * Binding is used to add or remove focus on hyperlinks/buttons present in every
 * description elements.
 *
 * @param {observable} isTruncated - observable responsible to determine to add or remove focus on hyperlinks/buttons.
 *
 * @example
 * <div class="text-truncate__content basic-formatter"
 *     data-bind="html: text, hasScrollbar: { resultTo: worthTruncate, isActive: isSectionActive },
 *     a11y.setTabIndex: { isTruncated: isCollapsed }">
 * </div>
 */

const TABBABLE_ELEMENTS_SELECTOR = 'a[href], button';


ko.bindingHandlers['a11y.setTabIndex'] = {
    init(element, accessor) {
        const { isTruncated } = accessor();
        const tabbables = element.querySelectorAll(TABBABLE_ELEMENTS_SELECTOR);

        updateTabIndex(isTruncated(), tabbables);

        const subscription = isTruncated.subscribe((state) => {
            updateTabIndex(state, tabbables);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
        });
    },
};
