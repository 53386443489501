import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import profileItemsFormBuilder from 'apply-flow/module/profile-items/service/profileItemsFormBuilder';
import EducationForm from 'apply-flow/module/degrees/form/EducationForm';
import TileForm from 'apply-flow/module/tile-profile-items/form/TileForm';
import EducationFormTileStrategy from '../model/EducationFormTileStrategy';
import GraduatedValidator from 'apply-flow/module/degrees/form/validator/Graduated';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            const graduatedField = this._getElementFromConfig(config, 'graduatedFlag');
            const schoolElement = this._getElementFromConfig(config, 'educationalEstablishmentId');
            let schoolNameElement = this._getElementFromConfig(config, 'educationalEstablishment');

            if (graduatedField) {
                graduatedField.type = 'checkboxStringValue';
            }

            if (schoolElement) {
                schoolElement.type = 'search';

                schoolElement.optionsKeys = {
                    value: 'contentItemId',
                    label: 'name',
                };

                if (schoolNameElement && !schoolElement.isHidden) {
                    schoolNameElement = this._extendSchoolNameField(schoolNameElement, schoolElement);
                }
            }

            this._setUrlTypeForEducationUrlElement(config);

            config.elements = config.elements.map((element) => {
                element.validateImmediately = config.validateImmediately;

                return formBuilder.createFormElement(element);
            });

            let form;

            if (schoolElement && !schoolElement.isHidden && schoolNameElement) {
                form = new EducationForm(EducationFormTileStrategy, config);
            } else {
                form = new TileForm(EducationFormTileStrategy, config);
            }

            form.legend(i18n('apply-flow.section-experience.add-education-title'));

            form.bindComponent('education-form');

            this._addYearAcquiredValidators(form);
            this._addStartDateAndEndDateMonthPicker(form);
            this._graduatedHasToBeCheckedIfYearAcquiredIsInPast(form);
            this._gradingHasToBeInSpecificRange(form);

            return profileItemsFormBuilder.createForm(config, form);
        },
    },

    _getElementFromConfig: {
        value(config, elementName) {
            return config.elements
                .filter(element => element.name === elementName)
                .shift();
        },
    },

    _extendSchoolNameField: {
        value(schoolNameElement, schoolElement) {
            schoolNameElement.type = 'autosuggest';
            schoolNameElement.label = schoolElement.label;

            schoolNameElement.dictionary = schoolElement.dictionary;
            schoolNameElement.options = schoolElement.options;

            schoolNameElement.optionsKeys = {
                label: schoolElement.optionsKeys.label,
                value: schoolElement.optionsKeys.label,
                key: schoolElement.optionsKeys.value,
            };

            return schoolNameElement;
        },
    },

    _addYearAcquiredValidators: {
        value(form) {
            const yearAcquired = form.getElement('yearAcquired');
            const dateAcquired = form.getElement('dateAcquired');

            if (yearAcquired) {
                yearAcquired.validators({
                    minDate: {
                        min: '1900-01-01',
                        inclusive: true,
                        msgDateFormat: 'yyyy',
                    },
                });

                if (dateAcquired) {
                    dateAcquired.fixedDay = 'first';

                    dateAcquired.validators({
                        sameYear: {
                            with: yearAcquired,
                            withLabel: yearAcquired.label(),
                            pairValidationElement: yearAcquired,
                            pairValidationValidator: 'sameYear',
                        },
                    });

                    yearAcquired.validators({
                        sameYear: {
                            with: dateAcquired,
                            withLabel: dateAcquired.label(),
                            pairValidationElement: dateAcquired,
                            pairValidationValidator: 'sameYear',
                        },
                    });
                }
            }
        },
    },

    _addStartDateAndEndDateMonthPicker: {
        value(form) {
            const startDate = form.getElement('startDate');

            const projectedCompletionDateField = form.getElement('projectedCompletionDate');
            const endDateField = form.getElement('endDate');

            if (endDateField && projectedCompletionDateField) {
                console.error('Both end date and projected comp date are present');
            }

            const endDate = endDateField || projectedCompletionDateField;

            if (startDate) {
                startDate.fixedDay = 'first';

                startDate.validators({
                    startDatePicker: true,
                    minDate: {
                        min: '1900-01-01',
                        inclusive: true,
                        msgDateFormat: 'MM/yyyy',
                    },
                });

                if (endDate) {
                    endDate.fixedDay = 'last';

                    endDate.validators({
                        afterDate: {
                            minDateFormElement: startDate,
                            pairValidationElement: startDate,
                            pairValidationValidator: 'beforeDate',
                        },
                        minDate: {
                            min: '1900-01-01',
                            inclusive: true,
                            msgDateFormat: 'MM/yyyy',
                        },
                    });

                    startDate.validators({
                        beforeDate: {
                            maxDateFormElement: endDate,
                            pairValidationElement: endDate,
                            pairValidationValidator: 'afterDate',
                        },
                    });
                }
            }

            if (projectedCompletionDateField) {
                projectedCompletionDateField.fixedDay = 'last';
            }
        },
    },

    _graduatedHasToBeCheckedIfYearAcquiredIsInPast: {
        value(form) {
            const graduated = form.getElement('graduatedFlag');
            let dateAcquired = form.getElement('dateAcquired');
            let yearAcquired = form.getElement('yearAcquired');

            if (graduated) {
                if (dateAcquired) {
                    dateAcquired = Object.create(dateAcquired, {
                        value: {
                            value: dateAcquired.value.extend({
                                isoStringToDate: true,
                            }),
                        },
                    });

                    graduated.addValidator(
                        new GraduatedValidator({
                            dateAcquired,
                        }),
                    );
                }

                if (yearAcquired) {
                    yearAcquired = Object.create(yearAcquired, {
                        value: {
                            value: yearAcquired.value.extend({
                                toIsoDate: true,
                                isoStringToDate: true,
                            }),
                        },
                    });

                    graduated.addValidator(
                        new GraduatedValidator({
                            dateAcquired: yearAcquired,
                        }),
                    );
                }

                const currentDate = new window.Date();

                const graduatedValueBasedOnDateAcquired = ko.pureComputed(() => {
                    const dateAcquiredValue = dateAcquired && dateAcquired.value();
                    const yearAcquiredValue = yearAcquired && yearAcquired.value();

                    if (!dateAcquiredValue && !yearAcquiredValue) {
                        return graduated.defaultValue();
                    }

                    const isDateAcquiredInFuture = (dateAcquiredValue > currentDate);
                    const isYearAcquiredInFuture = (yearAcquiredValue > currentDate);

                    return !(isDateAcquiredInFuture || isYearAcquiredInFuture) ? 'Y' : 'N';
                }).extend({
                    notify: 'always',
                });

                graduated.value(graduatedValueBasedOnDateAcquired());
                graduatedValueBasedOnDateAcquired.subscribe(graduated.value.bind(graduated));
            }
        },
    },

    _gradingHasToBeInSpecificRange: {
        value(form) {
            const grading = form.getElement('gpa');

            if (grading) {
                grading.validators({
                    between: {
                        min: 1,
                        max: 100,
                        inclusive: true,
                    },
                    scaleNumber: {
                        scale: 2,
                    },
                });
            }
        },
    },


    _setUrlTypeForEducationUrlElement: {
        value(config) {
            const educationUrl = this._getElementFromConfig(config, 'educationUrl');

            if (educationUrl) {
                educationUrl.type = 'url';
            }
        },
    },
});
