import AbsoluteRouter from './AbsoluteRouter';
import VanityRouter from 'core/router/VanityRouter';
import appConfig from '../config';
import { SitePathParams } from './types';

export type CustomWindow = {
    VanityUrlEnabled: boolean;
    VanityUrlUseReverseProxy: boolean;
};

declare let window: CustomWindow;

const siteURL = '{lang}/sites/{siteUrlKey}';

const baseRouter = new VanityRouter('base');

baseRouter.configure({
    site: {
        url: window.VanityUrlEnabled && window.VanityUrlUseReverseProxy ? `/${siteURL}` : siteURL,
    },
});

export default class VanityAbsoluteRouter extends AbsoluteRouter {
    protected getSitePath({ lang, siteCode, siteUrlName }: SitePathParams): string {
        const siteUrlKey = this.getSiteUrlKey(siteUrlName, siteCode);

        return this.getVanityBaseUrl() + baseRouter.interpolate('site', { lang, siteUrlKey });
    }

    private getVanityBaseUrl(): string {
        const anchor = document.createElement('a');

        anchor.href = appConfig.vanityBaseUrl;

        return anchor.href.replace(/\/$/, '');
    }
}
