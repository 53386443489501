import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(model, options = {}) {
            const form = formBuilder.createForm();

            form.elements([this._createPhoneFormElement(model.phone, options)]);

            return form;
        },
    },

    _createPhoneFormElement: {
        value(model, { preferredTerritoryCode }) {
            const label = i18n('apply-flow.section-personal-information.phone-number-label');

            const formElement = formElementFactory.create('phone', 'phone-number');

            formElement.addValidator('required', { label });
            formElement.addValidator('maxLength', { max: 120 });
            formElement.label(label);

            if (preferredTerritoryCode) {
                formElement.attributes({
                    preferredTerritoryCode,
                });
            }

            formElement.attributes({
                id: 'phone-number',
                autocomplete: 'none',
                'aria-label': label,
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});

