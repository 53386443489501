const MASK = '***';

function _isWritable(property) {
    return typeof property === 'string' || typeof property === 'number';
}

function _isIterable(property) {
    return Array.isArray(property);
}

function _isNestable(property) {
    return property && typeof property === 'object' && !Array.isArray(property);
}

function _recursiveMask(data, path) {
    const [currentStep] = path;

    if (_isIterable(data)) {
        data.forEach((entry) => {
            _recursiveMask(entry, path);
        });

        return;
    }

    const onCurrentStep = (key) => {
        if (key.toLowerCase() !== currentStep.toLowerCase()) {
            return;
        }

        if (_isNestable(data[key])) {
            _recursiveMask(data[key], path.slice(1));
        }

        if (_isIterable(data[key])) {
            data[key].forEach((entry) => {
                _recursiveMask(entry, path.slice(1));
            });
        }

        if (_isWritable(data[key])) {
            data[key] = MASK;
        }
    };

    Object.keys(data)
        .forEach(onCurrentStep);
}

export default {

    mask(data, path) {
        const pathParts = path.split('.');

        _recursiveMask(data, pathParts);

        return data;
    },

};