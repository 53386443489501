import { showCookiePolicyOrPreferenceDialog } from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_LINK } from 'minimal/component/app-menu/cookie-consent-header-identifier';
import pageLinksProvider from 'app/service/pageLinksProvider';
import { HeaderLinksType } from 'minimal/component/app-header/config/types';

export class AppHeaderHorizontalNavbarViewModel {
    links: HeaderLinksType;

    constructor() {
        this.links = pageLinksProvider.getHeaderPageLinks();
    }

    showCookieConsentPreferences(): void {
        showCookiePolicyOrPreferenceDialog();
    }

    getCookieConsentIdentifier(): string {
        return COOKIE_CONSENT_LINK;
    }
}
