import ko from 'knockout';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';

export default class ApplyFlowAccessibilityMessagesViewModel {

    constructor() {
        this.alertMessage = ko.observable();
        this.messages = ko.observableArray();

        accessibilityEvents.addAlert.add((alert) => {
            this.alertMessage(alert);
        });

        accessibilityEvents.addMessage.add((item) => {
            this.messages.push(item);
        });

        accessibilityEvents.removeMessage.add(this._removeFirst.bind(this));
    }

    _removeFirst(item) {
        let itemRemoved = false;

        this.messages.remove((message) => {
            const removeItem = (message === item) && !itemRemoved;

            if (removeItem) {
                itemRemoved = true;
            }

            return removeItem;
        });
    }

    dispose() {
        accessibilityEvents.addMessage.removeAll();
        accessibilityEvents.removeMessage.removeAll();
    }

}