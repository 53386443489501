import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import EventDetailsViewModel from './EventDetailsViewModel';

export default class EventDetailsCssViewModel extends EventDetailsViewModel {
    constructor() {
        super();
        this.goBackLabel = i18n('job-details.a11y.back-to-my-profile-button-label');
    }

    redirectToMainPage(): void {
        router.go('candidate-self-service.events');
    }

    afterDialogClose(): void {
        const routeId = router.route().id;

        if (routeId === 'candidate-self-service.event-preview') {
            this.redirectToMainPage();
        }
    }
}
