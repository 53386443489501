/* global maplibregl */

import { AbstractMapProvider } from './AbstractMapProvider';
import {
    ORACLE_API_URL,
    ORACLE_VECTOR_MAP_STYLES,
    ORACLE_MAP_CSS_URL,
    PROVIDER_STATUSES,
    ICON_CLUSTER,
    ICON_MARKER,
    CUSTOM_ATTRIBUTION_LIST,
    RTL_LANG_SUPPORT_PLUGIN_URL,
} from '../../config/maps';
import { addScriptToHead } from 'core/utils/addScriptToHead';
import { addStyleToHead } from 'core/utils/addStyleToHead';
import { copyToClipboard } from 'minimal/config/utils';
import { GEO_OPTIONS } from 'cx/service/geolocation/geolocation';
import appConfig from 'app/model/config';
import { addLanguageSupportToMaplibregl } from '../lanuageSupportMapLibre';
import screenInfo from 'cx/model/screenInfo';
import { generatepopupContent } from '../generatePopupContent';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import mapEvents from 'minimal/config/events';
import { MarkerSearchResultsModel } from '../../model/MarkerSearchResults';
import { LoadMoreSearchService } from '../../service/loadMoreMarkerResults';
import { scrollKeeper } from '../ScrollKeeper';
import debouncePromise from 'core/utils/debouncePromise';

const DATA_SOURCE_ID = 'requisitions';
const CLUSTER_LAYER_ID = 'clusters';
const MARKER_LAYER_ID = 'markers';
const DEFAULT_LANGUAGE = 'en';

const SEARCH_RESULT_MAP_CONTAINER = 'search-results-map';
const JOB_DETAIL_MAP_CONTAINER = 'job-details-map';
const COPYRIGHT_PIPE_SYMBOL = ' | ';
const SEARCH_PAGINATION_CONTAINER = '.search-pagination__container';

const NAVIGATION_POSITION_CLASS = {
    TOP_RIGHT: 'top-right',
    TOP_LEFT: 'top-left',
};

const ATTRIBUTION_POSITION_CLASS = {
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_LEFT: 'bottom-left',
};

export class OracleMapProvider extends AbstractMapProvider {

    constructor() {
        super();

        this.scriptUrl = ORACLE_API_URL;

        this.popup = null;

        this.searchMapJobResults = null;

        this.loadMoreSearchResult = null;

        this.markerCoordinates = null;

        this.searchJobsDebounced = debouncePromise(this._loadMoreJobs.bind(this), 10);
    }

    async initialize(callback) {
        if (callback && this.status === PROVIDER_STATUSES.READY) {
            callback();
        } else if (callback) {
            this.initCallbacks.push(callback);
        }

        if (this.status !== PROVIDER_STATUSES.NOT_INITIALIZED) {
            return;
        }

        this.status = PROVIDER_STATUSES.INITIALIZING;

        await addScriptToHead(this.scriptUrl);

        addLanguageSupportToMaplibregl(maplibregl);

        this._onScriptLoaded();

        addStyleToHead(ORACLE_MAP_CSS_URL);
    }

    _handleClusterClick(map, event) {
        const [feature] = map.queryRenderedFeatures(event.point, {
            layers: [CLUSTER_LAYER_ID],
        });

        const clusterId = feature.properties.cluster_id;

        map.getSource(DATA_SOURCE_ID).getClusterExpansionZoom(clusterId, (error, zoomLevel) => {
            if (error) {
                return;
            }

            map.easeTo({
                center: feature.geometry.coordinates,
                zoom: zoomLevel,
            });
        });
    }

    async _handleMarkerClick(map, event) {
        const [feature] = event.features;

        const coordinates = feature.geometry.coordinates.slice();

        const isSearchResultPage = router.route().id === 'search';

        if (!isSearchResultPage) {
            this._showJobDetailPopup(map, coordinates, feature.properties.content);

            return;
        }

        const isSameMarkerClicked = JSON.stringify(coordinates) === JSON.stringify(this.markerCoordinates);

        if (this.popup?.isOpen() && isSameMarkerClicked) {
            this.popup.remove();

            return;
        }

        this.searchMapJobResults = new MarkerSearchResultsModel();

        const { requisitionIds, locationId } = feature.properties;

        this.loadMoreSearchResult = new LoadMoreSearchService(this.searchMapJobResults, JSON.parse(locationId));

        const requisitionIdList = JSON.parse(requisitionIds);

        this.searchMapJobResults.requisitionIdList(requisitionIdList);

        this.searchMapJobResults.totalCount(requisitionIdList.length);

        const domContent = this._createDOMContent(requisitionIdList.length);

        while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const popMaxWidth = screenInfo.isSmall() ? '200px' : '300px';

        this.popup = new maplibregl.Popup({
            offset: { bottom: [0, -10], 'bottom-left': [0, -10], 'bottom-right': [0, -10] },
        })
            .setMaxWidth(popMaxWidth)
            .setLngLat(coordinates)
            .setDOMContent(domContent)
            .addTo(map);

        this.markerCoordinates = coordinates;

        if (isSearchResultPage) {
            this._startObservingLoadMoreButton('.progress-bar-wrapper');
        }

        this._handlePopupToggle(this.popup);
    }


    async _loadMoreJobs() {
        await this.loadMoreSearchResult.searchJobs();

        const domContent = this._createDOMContent(this.searchMapJobResults.totalCount());

        scrollKeeper.store();

        this.popup.setDOMContent(domContent);

        this._handlePopupToggle(this.popup, true);

        scrollKeeper.restore();

        if (this.searchMapJobResults.hasMore()) {
            this._startObservingLoadMoreButton(SEARCH_PAGINATION_CONTAINER);
        }
    }

    _startObservingLoadMoreButton(selector) {
        const element = document.querySelector(selector);

        if (!element) {
            return;
        }

        const intersectionObserver = new IntersectionObserver((entries) => {
            if (entries[0].intersectionRatio <= 0) {
                return;
            }

            if (!this.searchMapJobResults.appending()) {
                this.searchJobsDebounced();
            }
        });

        intersectionObserver.observe(document.querySelector(selector));
    }


    _handlePopupToggle(popup, isSamePopupInstance = false) {
        const selector = router.route().id === 'search' ? SEARCH_RESULT_MAP_CONTAINER : JOB_DETAIL_MAP_CONTAINER;

        if (!isSamePopupInstance) {
            popup.on('close', () => {
                const focusElement = document.querySelector(`#${selector}`);

                focusElement.focus();
            });
        }

        const listenerElement = document.querySelector(`#${selector} .maplibregl-popup-close-button`);

        if (listenerElement) {
            listenerElement.textContent = '';
            listenerElement.setAttribute('title', i18n('apply-flow.a11y.close'));
            listenerElement.setAttribute('aria-label', i18n('apply-flow.a11y.close'));
            listenerElement.classList.add('focused-tooltip');
        }
    }

    _handleMapOnload(map) {
        map.addSource(DATA_SOURCE_ID, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            cluster: true,
            clusterMaxZoom: 13,
            clusterRadius: 50,
        });

        map.loadImage(`data:image/png;base64,${ICON_CLUSTER}`, (error, image) => {
            if (error) {
                throw error;
            }

            if (!map.hasImage('icon_cluster')) {
                map.addImage('icon_cluster', image);
            }

            map.addLayer({
                id: CLUSTER_LAYER_ID,
                type: 'symbol',
                source: DATA_SOURCE_ID,
                filter: ['has', 'cluster'],
                layout: {
                    'icon-image': 'icon_cluster',
                    'icon-size': 1,
                    'text-font': ['Open Sans Bold', 'Noto Sans Bold'],
                    'text-field': ['get', 'point_count_abbreviated'],
                    'text-size': 12,
                    'text-anchor': 'center',
                    'text-offset': [0, -0.2],
                    'icon-padding': 2,
                },
                paint: {
                    'text-color': '#FFFFFF',
                },
            });
        });

        map.loadImage(`data:image/png;base64,${ICON_MARKER}`, (error, image) => {
            if (error) {
                throw error;
            }

            if (!map.hasImage('icon_marker')) {
                map.addImage('icon_marker', image);
            }

            map.addLayer({
                id: MARKER_LAYER_ID,
                type: 'symbol',
                source: DATA_SOURCE_ID,
                filter: ['has', 'label'],
                layout: {
                    'icon-image': 'icon_marker',
                    'icon-size': 1,
                    'text-field': ['get', 'label'],
                    'text-font': ['Open Sans Bold', 'Noto Sans Bold'],
                    'text-size': [
                        'case',
                        ['<', ['get', 'label'], 1000],
                        12,
                        9,
                    ],
                    'text-anchor': 'center',
                    'text-offset': [0, -0.5],
                },
                paint: {
                    'text-color': '#FFFFFF',
                },
            });
        });

        map.on('click', CLUSTER_LAYER_ID, this._handleClusterClick.bind(this, map));

        map.on('click', MARKER_LAYER_ID, this._handleMarkerClick.bind(this, map));

        map.on('mouseenter', CLUSTER_LAYER_ID, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseenter', MARKER_LAYER_ID, () => {
            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', CLUSTER_LAYER_ID, () => {
            map.getCanvas().style.cursor = '';
        });

        map.on('mouseleave', MARKER_LAYER_ID, () => {
            map.getCanvas().style.cursor = '';
        });

        map.getCanvas().setAttribute('tabindex', '-1');

        this._createCandidateLocationMarker(map);

        mapEvents.showAdditionalFilterMessage.dispatch(true);
    }

    createMap({ element, center, zoom }) {
        const map = new maplibregl.Map({
            container: element.id,
            style: ORACLE_VECTOR_MAP_STYLES,
            center,
            zoom,
            keyboard: true,
            attributionControl: false,
            preserveDrawingBuffer: true,
            dragRotate: false,
            scrollZoom: true,
            cooperativeGestures: {
                macHelpText: i18n('search.maps.scroll-zoom-message-desktop-mac'),
                windowsHelpText: i18n('search.maps.scroll-zoom-message-desktop-window'),
                mobileHelpText: i18n('search.maps.scroll-zoom-message-mobile'),
            },
            transformRequest: (url, resourceType) => {
                if (
                    resourceType === 'Tile'
                    && (url.startsWith('https://elocation.oracle.com/mapviewer/pvt')
                        || url.startsWith('https://elocation-stage.oracle.com/mapviewer/pvt')
                        || url.startsWith('https://maps.oracle.com/mapviewer/pvt'))
                ) {
                    return {
                        url,
                        headers: {
                            'x-oracle-pvtile': 'OracleSpatial',
                        },
                        credentials: 'include',
                    };
                }

                return {
                    url,
                };
            },
        });

        if (document.dir === 'rtl' && maplibregl.getRTLTextPluginStatus() !== 'loaded') {
            maplibregl.setRTLTextPlugin(RTL_LANG_SUPPORT_PLUGIN_URL, null, true);
        }

        this._addControlsToMap(map);

        map.on('load', this._handleMapOnload.bind(this, map));

        map.on('render', () => this._handleMapOnRender());

        map.autodetectLanguage(appConfig.siteLang, DEFAULT_LANGUAGE);

        this._resetPopupInstance();

        return map;
    }

    _addControlsToMap(map) {
        let navigationPositionClass = NAVIGATION_POSITION_CLASS.TOP_RIGHT;
        let attributionPositionClass = ATTRIBUTION_POSITION_CLASS.BOTTOM_RIGHT;

        const documentDirection =
            router.route().id === 'search' ? document.dir : document.querySelector('.job-details')?.dir;

        if (documentDirection === 'rtl') {
            navigationPositionClass = NAVIGATION_POSITION_CLASS.TOP_LEFT;
            attributionPositionClass = ATTRIBUTION_POSITION_CLASS.BOTTOM_LEFT;
        }

        map.addControl(
            new maplibregl.NavigationControl({ showCompass: false }),
            navigationPositionClass,
        );

        map.addControl(new maplibregl.AttributionControl({
            customAttribution: this._createCustomAttribution(),
        }), attributionPositionClass);
    }

    _createCustomAttribution() {
        const contentLocaleLang = document.querySelector('.job-details')?.lang;

        const customAttributionElementList = CUSTOM_ATTRIBUTION_LIST.map(
            ({ content, className, href, isTranslationRequired }) =>
                `<a class="${className}" target="_blank" href="${href}">${isTranslationRequired ? i18n(content, null, contentLocaleLang) : content}</a>`,
        ).join('');

        return `<span class="oracle-mapinfo-copyright-container">${customAttributionElementList}</span>`;
    }

    _handleMapOnRender() {
        const copyrightElement = document.querySelector('.maplibregl-ctrl-attrib-inner');
        const closeButtonElement = document.querySelector('.maplibregl-popup-close-button');
        const areJobDetailsDirectionLTR = document.querySelector('.job-details')?.dir === 'ltr';
        const zoomInElement = document.querySelector('.mapboxgl-ctrl-zoom-in');
        const zoomOutElement = document.querySelector('.mapboxgl-ctrl-zoom-out');

        if (closeButtonElement && areJobDetailsDirectionLTR) {
            closeButtonElement.classList.add('close-button-direction--ltr');
        }

        if (copyrightElement && copyrightElement.innerHTML.includes(COPYRIGHT_PIPE_SYMBOL)) {
            copyrightElement.innerHTML = copyrightElement.innerHTML.replace(COPYRIGHT_PIPE_SYMBOL, '');
        }

        if (zoomInElement) {
            zoomInElement.setAttribute('title', i18n('search.maps.zoom-in'));
            zoomInElement.setAttribute('aria-label', i18n('search.maps.zoom-in'));
            zoomInElement.classList.add('focused-tooltip');
        }

        if (zoomOutElement) {
            zoomOutElement.setAttribute('title', i18n('search.maps.zoom-out'));
            zoomOutElement.setAttribute('aria-label', i18n('search.maps.zoom-out'));
            zoomOutElement.classList.add('focused-tooltip');
        }
    }

    isProviderReady() {
        return typeof maplibregl !== 'undefined' && typeof maplibregl.maps !== 'undefined';
    }

    createPosition({ coordinates: { latitude, longitude } }) {
        return [longitude, latitude];
    }

    createMarker({ coordinates, content, label, requisitionIds, locationId }) {
        return {
            position: this.createPosition({ coordinates }),
            popupContent: content,
            label,
            requisitionIds,
            locationId,
        };
    }

    createPopup() {
        return new maplibregl.Popup();
    }

    _onPopupClick(event) {
        const {
            dataset: { jobId },
        } = event.target;

        event.preventDefault();

        if (jobId) {
            this._goToJobPreview(jobId);
        } else {
            const elementTextToCopy = document.querySelector('.address-marker__address');

            copyToClipboard(elementTextToCopy);
        }
    }

    _generateDOMontent(content) {
        const div = document.createElement('div');

        div.innerHTML = content;
        div.className = 'maplibre-popup-content__job-list-container';
        div.onclick = this._onPopupClick.bind(this);

        return div;
    }

    _createDOMContent(markerJobsCount) {
        const domContent = generatepopupContent(
            this.searchMapJobResults?.results(),
            this.searchMapJobResults?.hasMore(),
            markerJobsCount,
        );

        return this._generateDOMontent(domContent);
    }

    updateSourceData({ map, markers }) {
        const features = markers.map(marker => ({
            type: 'Feature',
            properties: {
                content: marker.popupContent,
                label: !marker.label ? 1 : parseInt(marker.label, 10),
                requisitionIds: marker?.requisitionIds ?? [],
                locationId: marker.locationId,
            },
            geometry: {
                type: 'Point',
                coordinates: marker.position,
            },
        }));

        map.getSource(DATA_SOURCE_ID).setData({
            type: 'FeatureCollection',
            features,
        });
    }

    createClusterLayer({ map, markers }) {
        this.updateClusterLayer({ map, markers });

        return 'cluster';
    }

    updateClusterLayer({ map, markers }) {
        if (map.loaded()) {
            this.updateSourceData({ map, markers });
        } else {
            map.on('load', () => this.updateSourceData({ map, markers }));
        }
    }

    setZoomLevel({ map, zoom }) {
        map.setZoom(zoom);
    }

    setCenter({ map, coordinates }) {
        map.setCenter(this.createPosition({ coordinates }));
    }

    adjustBoundsForMultipleCoordinates({ map, coordinates }) {
        const bounds = new maplibregl.LngLatBounds();

        coordinates
            .map(coordinatesItem => this.createPosition({ coordinates: coordinatesItem }))
            .forEach(position => bounds.extend(position));

        map.fitBounds(bounds, {
            padding: { top: 25, bottom: 25, left: 25, right: 25 },
        });
    }

    clearMarkers({ map }) {
        this.updateSourceData({ map, markers: [] });
    }

    _resetPopupInstance() {
        if (router.route().id !== 'search') {
            return;
        }

        if (!this.popup) {
            return;
        }

        this.popup.remove();
    }

    _showJobDetailPopup(map, coordinates, content) {
        const domContent = this._generateDOMontent(content);

        const popMaxWidth = screenInfo.isSmall() ? '200px' : '300px';

        const popup = new maplibregl.Popup({
            offset: { bottom: [0, -10], 'bottom-left': [0, -10], 'bottom-right': [0, -10] },
            focusAfterOpen: false,
        })
            .setMaxWidth(popMaxWidth)
            .setLngLat(coordinates)
            .setDOMContent(domContent)
            .addTo(map);

        this._handlePopupToggle(popup);
    }

    showPopup({ map, marker, content }) {
        const isSearchResultPage = router.route().id === 'search';

        if (!isSearchResultPage) {
            this._showJobDetailPopup(map, marker.position, content);

            return;
        }

        const coordinates = marker.position.map(Number);
        const [lng, lat] = coordinates;

        const event = {
            features: [{
                properties: {
                    requisitionIds: JSON.stringify(marker?.requisitionIds),
                    locationId: JSON.stringify(marker?.locationId),
                },
                geometry: {
                    coordinates,
                },
            }],
            lngLat: {
                lat,
                lng,
            },
        };

        this._resetPopupInstance();

        this._handleMarkerClick(map, event);
    }

    _createCandidateLocationMarker(map) {
        const getCurrentPositionPromise = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, GEO_OPTIONS);
        });

        getCurrentPositionPromise
            .then((position) => {
                const { latitude, longitude } = position.coords;

                const candidateLocationIcon = document.createElement('div');

                candidateLocationIcon.className = 'candidate-location-marker';

                new maplibregl.Marker(candidateLocationIcon, {
                    anchor: 'bottom',
                }).setLngLat([longitude, latitude]).addTo(map);
            })
            .catch((error) => {
                throw error;
            });
    }

}