export default class CustomFontsController {

    constructor(fontsCssId) {
        this.fontsCss = document.getElementById(fontsCssId) || this._createElement(fontsCssId);
    }

    _createElement(fontsCssId) {
        const element = document.createElement('style');

        element.id = fontsCssId;

        document.head.appendChild(element);

        return element;
    }

    _buildFiles(files) {
        const sources = files
            .filter(file => file && file.url)
            .map(file => `url('${file.url}') format('${file.type}')`);

        return sources.join(', ');
    }

    _buildRules(font) {
        return font.variants
            .sort(variant => (variant.style === 'italic' ? -1 : 1))
            .reduce((rules, variant) => {
                const [woff2] = variant.files.filter(file => file.type === 'woff2');
                const [woff] = variant.files.filter(file => file.type === 'woff');
                const files = this._buildFiles([woff2, woff]);

                return `${rules}
                @font-face {
                    font-family: '${font.name}';
                    font-style: ${variant.style};
                    font-weight: ${variant.weight};
                    src: ${files};
                    font-display: swap;
                }`;
            }, '');
    }

    update(fonts) {
        this.fontsCss.innerText = fonts.reduce((rules, font) => rules + this._buildRules(font), '');
    }

}
