import candidateValidation from './candidateValidation';
import profileItemValidation from './profileItemValidation';
import { multiValidationNotifications } from './multiValidationNotifications';
import regulatoryResponsesValidation from './regulatoryResponsesValidation';
import profileItemServiceFactory from 'apply-flow/module/profile-items/service/profileItemServiceFactory';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';

function getWorkPreferencesConfig() {
    const profileItemsModel = profileItemServiceFactory
        .getCompositeService()
        .getMappedModel();

    const [workPreferences] = profileItemsModel[contentTypes.WORK_PREFERENCE_ID];

    if (!workPreferences) {
        return undefined;
    }

    return profileItemValidation.getConfig(contentTypes.WORK_PREFERENCE_ID, workPreferences);
}

export default {
    validate() {
        const configs = [
            candidateValidation.getConfig(),
            getWorkPreferencesConfig(),
            regulatoryResponsesValidation.getConfig(),
        ]
            .filter(Boolean);

        return multiValidationNotifications.sendAndNotify(configs);
    },
};