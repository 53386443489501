import { DisplayStyle } from 'cx/module/search/config/types';
import { notReachable } from 'app/types/utils';

type TileComponents = {
    tile: string;
    talentCommunityTile: string;
};

const TILE_COMPONENTS_STATIC: Record<DisplayStyle, TileComponents> = {
    list: {
        tile: 'search-result-list-item-static',
        talentCommunityTile: 'talent-community-tile-static',
    },
    tile: {
        tile: 'search-result-grid-item-static',
        talentCommunityTile: 'talent-community-tile-static',
    },
    toggleListMap: {
        tile: 'search-result-list-item-static',
        talentCommunityTile: 'talent-community-tile-static',
    },
    toggleTileMap: {
        tile: 'search-result-grid-item-static',
        talentCommunityTile: 'talent-community-tile-static',
    },
};

const TILE_COMPONENTS_ACTIVE: Record<DisplayStyle, TileComponents> = {
    list: {
        tile: 'search-result-list-item',
        talentCommunityTile: 'talent-community-tile',
    },
    tile: {
        tile: 'search-result-grid-item',
        talentCommunityTile: 'talent-community-tile',
    },
    toggleListMap: {
        tile: 'search-result-list-item',
        talentCommunityTile: 'talent-community-tile',
    },
    toggleTileMap: {
        tile: 'search-result-grid-item',
        talentCommunityTile: 'talent-community-tile',
    },
};

export const getTileComponents = (
    jobDisplayStyle: DisplayStyle,
    type: 'active' | 'static'
): TileComponents => {
    switch (type) {
        case 'active':
            return TILE_COMPONENTS_ACTIVE[jobDisplayStyle];

        case 'static':
            return TILE_COMPONENTS_STATIC[jobDisplayStyle];

        default:
            return notReachable(type);
    }
};
