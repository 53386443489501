export const WORKPLACE_TYPES = {
    ONSITE: 'ORA_ON_SITE',
    HYBRID: 'ORA_HYBRID',
    REMOTE: 'ORA_REMOTE',
} as const;

export type WorkplaceTypeCode =
    | typeof WORKPLACE_TYPES.ONSITE
    | typeof WORKPLACE_TYPES.REMOTE
    | typeof WORKPLACE_TYPES.HYBRID
    | null;

export type WorkplaceType = {
    name: string | null;
    code: WorkplaceTypeCode;
};
