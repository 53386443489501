function getOrder(item) {
    return parseInt(item.elementOrder, 10);
}

function sortByOrder(itemA, itemB) {
    const orderA = getOrder(itemA);
    const orderB = getOrder(itemB);

    return orderA - orderB;
}

export default {
    sort(items) {
        return items.sort(sortByOrder);
    },
};
