import applicationDraftService from 'apply-flow/service/applicationDraft';
import { setDraftApplicationsSiteProps } from 'cx/module/candidate-self-service/service/applicationsSiteNames';
import { sortDescendingByDate } from 'apply-flow/service/sortDescendingByDate';
import draftApplicationsModel from 'cx/module/candidate-self-service/model/draftApplications';
import { ApplicationDraft, ApplicationDraftRest } from 'apply-flow/config/types';
import { SiteList } from '../config/types';

export const loadDrafts = (siteList: SiteList): Promise<void> => {
    return applicationDraftService
        .getCandidateDrafts()
        .then((restDrafts: ApplicationDraftRest[]) => setDraftApplicationsSiteProps(restDrafts, siteList))
        .then(applicationDraftService.mergeDraftsAndJobsDetails)
        .then((drafts: ApplicationDraft[]) =>
            drafts.sort((a, b) =>
                sortDescendingByDate(a.modificationDateOrigianl, b.modificationDateOrigianl)
            )
        )
        .then((drafts: ApplicationDraft[]) => draftApplicationsModel.setLoadedData(drafts));
};
