const escapeSpecialCharacters = (text: string): string => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const highlightKeywordText = (filterText: string, searchKeyword: string): string => {
    if (!searchKeyword) {
        return filterText;
    }

    return filterText.replace(new RegExp(escapeSpecialCharacters(searchKeyword), 'ig'), function (match) {
        return `<span class="search-facet-dropdown__list-element--highlighted">${match}</span>`;
    });
};
