import { DffContext } from 'site-editor/module/search-editor/component/cc-search-results-editor/config/types';
import { SearchResultsRequisitionFlexField } from 'minimal/module/search/component/search-results/config/types';
import i18n from 'core/i18n/i18n';

export const mapDffsToRequisitionFlexFields = (dffs: DffContext[]): SearchResultsRequisitionFlexField[] => {
    return dffs.flatMap((dff) =>
        dff.segments.map((dffSegment) => {
            return {
                contextCode: dff.code,
                prompt: dffSegment.name,
                segmentCode: dffSegment.code,
                value: i18n('general.some-value'),
            };
        })
    );
};
