import ko from 'knockout';
import absoluteRouter from 'app/model/absoluteRouter';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import postMessageService from 'core/post-message/postMessageService';
import notificationsService from 'cx/service/notifications';

const defaults = { responseType: 'arraybuffer' };

export default {
    download(fileInfoPromise, httpConfig) {
        const config = ko.utils.extend(httpConfig || {}, defaults);

        const downloader = window.open(`${absoluteRouter.interpolate({ lang: siteLanguage.get() }, 'downloader')}`);

        async function onDownloaderLoad() {
            const fileInfo = await fileInfoPromise
                .catch(() => {
                    downloader.close();
                    notificationsService.error();
                });

            postMessageService.dispatch('download', {
                fileInfo,
                httpConfig: config,
            }, downloader);

            postMessageService.remove('ready', onDownloaderLoad);
        }

        postMessageService.add('ready', onDownloaderLoad);
    },
};
