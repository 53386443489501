import { Facet } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { isAnyFilterInFacet } from '../search-filters-panel/service/isAnyFilterInFacet';
import { Observable, ObservableArray, Subscription } from 'knockout';

type Props = {
    hide: () => void;
    clear: () => void;
    filters: ObservableArray<Facet>;
    adminMode: boolean;
    popupFiltersOpened: Observable<boolean>;
    uniqueWrapperClass: Observable<string>;
};

export default class SearchFiltersMobileViewModel {
    hide: () => void;
    clear: () => void;
    filters: ObservableArray<Facet>;
    adminMode: boolean;
    popupFiltersOpened: Observable<boolean>;
    uniqueWrapperClass: Observable<string>;
    filtersSubscription: Subscription;

    constructor({ hide, clear, filters, adminMode, popupFiltersOpened, uniqueWrapperClass }: Props) {
        this.hide = hide;
        this.clear = clear;
        this.filters = filters;
        this.adminMode = adminMode;
        this.popupFiltersOpened = popupFiltersOpened;
        this.uniqueWrapperClass = uniqueWrapperClass;

        this.filtersSubscription = filters.subscribe((newFilters: Facet[]) => {
            if (!isAnyFilterInFacet(newFilters) && this.popupFiltersOpened()) {
                this.hide();
            }
        });
    }

    dispose(): void {
        this.filtersSubscription.dispose();
    }
}
