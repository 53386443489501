// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapping from 'knockout-mapping';
import {
    CategoryTilesCustomizationParams,
    CategoryTileCustomizationParams,
    ParamValues,
    SubComponentsEntity,
} from 'minimal/component/category-tiles/config/types';
import { TileConfig } from '../config/types';
import { GLOBAL_TILE_ID } from '../component/cc-category-tiles-list/CcCategoryTilesListViewModel';
import { ComponentParams } from '../../abstract-editor';
import { copyParamsObject } from '../../../service/copyParams';
import { mapParamsFromRest } from 'minimal/component/category-tiles/service/mapParams';
import sessionStorage from 'core/storage/sessionStorage';

export const CATEGORY_TILE_PARAMS = 'category-tile-params';

type Props = {
    categoryTilesConfig: TileConfig;
    params: CategoryTileCustomizationParams;
    paramsWithoutGlobal: CategoryTileCustomizationParams;
    selectedTileId: string;
    isContentUpdated: boolean;
};

export const isCategoryTileParamsUpdated = ({
    categoryTilesConfig,
    params,
    paramsWithoutGlobal,
    selectedTileId,
    isContentUpdated,
}: Props): boolean => {
    const { selectedSubComponent, type, ...selectedTileConfig } = categoryTilesConfig[selectedTileId] ?? {};
    const {
        selectedSubComponent: globalSelectedSubComponent,
        type: globalConfigType,
        ...globalTileConfig
    } = categoryTilesConfig[GLOBAL_TILE_ID];
    const {
        selectedSubComponent: paramsSelectedSubComponent,
        type: paramsConfigType,
        ...paramsConfig
    } = params;
    const {
        selectedSubComponent: extractedSelectedSubComponent,
        type: extractedConfigType,
        ...extractedParamsConfig
    } = paramsWithoutGlobal;

    const isSelectedTileConfigExists = Object.keys(selectedTileConfig).length > 0;

    const isSelectedTileConfigUpdated =
        isSelectedTileConfigExists &&
        JSON.stringify(selectedTileConfig) !== JSON.stringify(extractedParamsConfig);
    const isContentConfigUpdated = isContentUpdated;
    const isSelectedTileConfigUpdatedOverGlobal =
        !isSelectedTileConfigExists && JSON.stringify(globalTileConfig) !== JSON.stringify(paramsConfig);

    return isContentConfigUpdated || isSelectedTileConfigUpdated || isSelectedTileConfigUpdatedOverGlobal;
};

export const mergeConfig = (
    configA: Record<string, ParamValues>,
    configB: Record<string, ParamValues>
): Record<string, ParamValues> => {
    if (JSON.stringify(configA) === JSON.stringify(configB)) return configA;

    const finalConfig = { ...configA };

    Object.keys(configA).forEach((key: any) => {
        const configAVal = configA[key];
        const configBVal = configB[key];

        if (configAVal === null) {
            finalConfig[key] = configBVal;

            return;
        }

        if (!configBVal) {
            return;
        }

        if (typeof configAVal === 'object' && key !== '__ko_mapping__') {
            const mergedConfig = mergeConfig(
                configAVal as Record<string, ParamValues>,
                configBVal as Record<string, ParamValues>
            );

            finalConfig[key] = { ...mergedConfig } as unknown as ParamValues;

            return;
        }

        if (configBVal !== configAVal) {
            finalConfig[key] = configBVal;
        }
    });

    return finalConfig;
};

export const updateParams = (
    targetParams: ComponentParams<CategoryTilesCustomizationParams>,
    sourceObj: CategoryTilesCustomizationParams
): void => {
    const sourceParams = mapping.fromJS(sourceObj);

    Object.keys(targetParams).forEach((key: keyof CategoryTilesCustomizationParams) => {
        if (typeof sourceParams[key] === 'object' && key !== '__ko_mapping__') {
            copyParamsObject(sourceParams[key], targetParams[key]);
        }
    });
};

export const extractTileSpecificConfig = (
    globalConfig: Record<string, ParamValues>,
    tileParams: Record<string, ParamValues>
): Record<string, ParamValues> => {
    const extractedConfig = { ...tileParams };

    Object.keys(tileParams).forEach((key: keyof CategoryTilesCustomizationParams) => {
        if (tileParams[key] && typeof tileParams[key] === 'object' && key !== '__ko_mapping__') {
            extractedConfig[key] = extractTileSpecificConfig(
                globalConfig[key] as Record<string, ParamValues>,
                tileParams[key] as Record<string, ParamValues>
            ) as unknown as ParamValues;

            return;
        }

        if (tileParams[key] && tileParams[key] === globalConfig[key]) {
            extractedConfig[key] = null;
        }
    });

    return extractedConfig;
};

export const removeContentFromParams = (
    params: CategoryTilesCustomizationParams
): CategoryTileCustomizationParams => {
    const { content, ...paramsWithoutContent } = { ...params };

    return paramsWithoutContent;
};

export const setCategoryTileParamsToStorage = (val: SubComponentsEntity[]): void => {
    sessionStorage.store(CATEGORY_TILE_PARAMS, val);
};

export const fetchCategoryTileParamsFromStorage = (): CategoryTilesCustomizationParams[] => {
    const categoryTilesFromStorage = sessionStorage.restore(CATEGORY_TILE_PARAMS);

    return categoryTilesFromStorage?.map((item: SubComponentsEntity) =>
        mapParamsFromRest(item.subComponentParams)
    );
};

export const removeCategoryTileParamsFromStorage = (): void => {
    sessionStorage.remove(CATEGORY_TILE_PARAMS);
};
