import { CookieConsentModalActionsCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_BLOCK_STYLES } from 'app/module/admin/module/site-editor/module/content-editor/config';
import { BUTTON_STYLE } from 'app/module/admin/module/site-editor/module/cookie-consent-editor/config/types';

export const emptyParams: CookieConsentModalActionsCustomizationParams = {
    content: {
        buttonSize: BUTTON_STYLE.LARGE,
    },
    overallStyling: { ...EMPTY_BLOCK_STYLES },
    acceptButtonStyling: { ...EMPTY_BLOCK_STYLES },
    acceptButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    declineButtonStyling: { ...EMPTY_BLOCK_STYLES },
    declineButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    savePreferencesButtonStyling: { ...EMPTY_BLOCK_STYLES },
    savePreferencesButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferencesButtonStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferencesButtonHoverStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferencesLinkStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferencesLinkHoverStyling: { ...EMPTY_BLOCK_STYLES },
    acceptButtonTypography: { ...EMPTY_TYPOGRAPHY },
    acceptButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    declineButtonTypography: { ...EMPTY_TYPOGRAPHY },
    declineButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    savePreferencesButtonTypography: { ...EMPTY_TYPOGRAPHY },
    savePreferencesButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferencesButtonTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferencesButtonHoverTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferencesLinkTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferencesLinkHoverTypography: { ...EMPTY_TYPOGRAPHY },
};
