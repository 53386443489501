import http from 'core/http/http';
import skillRecommendationMapper from '../mapper/skillRecommendation';
import appConfig from 'app/model/config';
import { SKILL_RECOMMENDATION_SETTING_KEY } from 'app/service/globalSettings';

const SKILL_RECOMMENDATIONS_REST_ENDPOINT = '/recruitingSkillRecommendations';

const SUPPORTED_LANGUAGES = ['en'];

const MAX_RECOMMENDATIONS = 10;

const httpConfig = {
    baseUrl: appConfig.ceBaseUrl,
    transformRequest(request) {
        request.body = JSON.stringify(request.body);

        return request;
    },
};

function fetchSkillRecommendations(data) {
    return http.post(SKILL_RECOMMENDATIONS_REST_ENDPOINT, data, httpConfig);
}

function sortRecommendations(recommendations) {
    return recommendations.sort((a, b) => {
        if (a.score === b.score) {
            const x = a.name.toLowerCase();
            const y = b.name.toLowerCase();

            if (x > y) {
                return 1;
            } else if (x < y) {
                return -1;
            }

            return 0;
        }

        return b.score - a.score;
    });
}

function getRecommendations({ title, experiences, skills }) {
    const data = skillRecommendationMapper
        .mapToRest({ title, experiences, skills });

    return fetchSkillRecommendations(data)
        .then((restData) => {
            let recommendations = skillRecommendationMapper.mapFromRest(restData);

            recommendations = sortRecommendations(recommendations).slice(0, MAX_RECOMMENDATIONS);

            return recommendations.map(skillRecommendationMapper.mapToSkill);
        });
}

function isSkillRecommendationEnabled() {
    if (!SUPPORTED_LANGUAGES.includes(appConfig.siteLang)) {
        return false;
    }

    const flag = appConfig
        .getSettingByKey(SKILL_RECOMMENDATION_SETTING_KEY);

    return (flag)
        ? (flag === 'Y')
        : false;
}

export default {
    getRecommendations,
    isSkillRecommendationEnabled,
    MAX_RECOMMENDATIONS,
};