import { SINGLE_CHOICE, MULTIPLE_CHOICE, TEXT, NONE } from '../enum/responseTypes';

const responseTypesMap = {
    'textarea-form-element': TEXT,
    'checkbox-form-element': SINGLE_CHOICE,
    'select-form-element': SINGLE_CHOICE,
    'radio-form-element': SINGLE_CHOICE,
    'multiselect-form-element': MULTIPLE_CHOICE,
    'multiselectlist-form-element': MULTIPLE_CHOICE,
    'date-form-element': TEXT,
    'integer-form-element': TEXT,
    'decimal-form-element': TEXT,
    'noresponse-form-element': NONE,
};

export function getResponseType(formElementType) {
    return responseTypesMap[formElementType] || TEXT;
}