import oj from 'ojs/ojcore';
import 'ojs/ojknockout';

const POINTER_MEDIA_QUERY = '(pointer: fine)';

export default {
    isSmall: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.SM_ONLY)),

    isMedium: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.MD_ONLY)),

    isLarge: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.LG_ONLY)),

    isSmallUp: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.SM_UP)),

    isMediumUp: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.MD_UP)),

    isLargeUp: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.LG_UP)),

    isMediumDown: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.MD_DOWN)),

    isLargeDown: oj.ResponsiveKnockoutUtils.createMediaQueryObservable(
        oj.ResponsiveUtils.getFrameworkQuery(oj.ResponsiveUtils.FRAMEWORK_QUERY_KEY.LG_DOWN)),

    hasPointingDevice: window.matchMedia?.(POINTER_MEDIA_QUERY).matches,
};
