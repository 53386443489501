import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import tokenService from '../../../../../candidate-verification/service/token';
import { useDraftData } from '../../../../service/applicationDraftMonitor';
import candidate from 'apply-flow/model/candidate';
import jobAlertsService from '../../../../../job-alerts/service/jobAlerts';
import { VERIFICATION_MODES, CSS_ENTRY } from 'candidate-verification/config/verificationSubmodules';

export default class OptInViewModel {

    constructor({ optIn, candidateNumber, savePreferencesFlag }) {
        this.optIn = optIn();
        this.isDialogVisible = observable(false);
        this.dialogHeaderText = i18n('apply-flow.opt-in.dialog-header');
        this.preferencesLoaded = observable();
        this.candidateNumber = candidateNumber;
        this.savePreferencesFlag = savePreferencesFlag;
        this.isAcceptedSubscription = null;

        this._loadPreferences()
            .then(this._setPreferences.bind(this))
            .catch(console.error);
    }

    openDialog() {
        this.isDialogVisible(true);
    }

    toggleAccepted(isAccepted) {
        const { optIn } = this;

        if (isAccepted) {
            optIn.accept();
        } else {
            optIn.decline();
        }
    }

    _saveCandidatePreferences() {
        if (this.savePreferencesFlag) {
            jobAlertsService.saveCSSOptInEmailAlert({
                candidateId: this.candidateNumber(),
                optInEmailFlag: candidate.preferences.optIn(),
                flowContext: VERIFICATION_MODES[CSS_ENTRY],
            });
        }
    }

    _loadPreferences() {
        if (useDraftData()) {
            return Promise.resolve();
        }

        const token = tokenService.get();

        if (token.candidateNumber) {
            return jobAlertsService.getPreferences(token.candidateNumber);
        }

        return this._createOptInPreferences();
    }

    _createOptInPreferences() {
        return Promise.resolve({ optIn: false });
    }

    _setPreferences(preferences) {
        if (preferences) {
            this._setOptIn(preferences);
        }

        const { isAccepted } = this.optIn;

        this.isAcceptedSubscription = isAccepted?.subscribe((value) => {
            this.toggleAccepted(value);
            this._saveCandidatePreferences();
        });

        this.preferencesLoaded(true);
    }

    _setOptIn(preferences) {
        if (!preferences.optIn) {
            preferences.optIn = false;
        }

        this.optIn.set(preferences);
    }

    dispose() {
        if (this.isAcceptedSubscription) {
            this.isAcceptedSubscription.dispose();
            this.isAcceptedSubscription = null;
        }
    }

}