import { ObservableArray, Observable, observable, observableArray } from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export class MultiSelectComboBoxFormElement extends ValidatableFormElement {
    options: ObservableArray<any>;
    maximumResultCount: Observable<number>;
    placeholder: Observable<string>;
    classes: Observable<string>;
    isCaseSensitive: boolean;
    constructor(name: string) {
        super(name);

        this.options = observableArray();
        this.maximumResultCount = observable(0);
        this.placeholder = observable('');
        this.classes = observable('');
        this.isCaseSensitive = true;
        this.component('multiselect-combobox-form-element');
    }
}
