import ApplyFlowNavigationMultiPageViewModel from 'apply-flow/component/apply-flow-navigation-multipage/ApplyFlowNavigationMultiPageViewModel';
import { EventRegisterFormSection } from '../../config/types';
import FlowIterator from 'app/module/cx/module/apply-flow/model/FlowIterator';

export default class EventRegisterFlowMultiPageNavigationViewModel extends ApplyFlowNavigationMultiPageViewModel {
    constructor({ sections, iterator }: { sections: EventRegisterFormSection[]; iterator: FlowIterator }) {
        super({ sections, iterator });
    }

    getRoute(): string {
        return 'event-register-flow';
    }
}
