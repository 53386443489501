import http from 'core/http/http';
import {
    RegulatoryResponse,
    MappedRegulatoryResponses,
} from 'apply-flow/module/regulatory/config/regulatoryResponseTypes';
import { mapDataFromRest } from 'apply-flow/module/regulatory/mapper/prefillRegulatoryResponses';

export const getCandidateProfileRegulatoryResponse = async (
    payload: RegulatoryResponse,
    jobId: string
): Promise<MappedRegulatoryResponses> => {
    return postCandidateProfileRegulatoryResponseApi(payload)
        .then((response) => mapDataFromRest(response, jobId))
        .catch((error) => {
            console.error('Fetching candidate profile prefill data failed.', error);

            return Promise.resolve({
                requisitionNumber: jobId,
                prefillResponses: [],
            });
        });
};

const postCandidateProfileRegulatoryResponseApi = async (
    payload: RegulatoryResponse
): Promise<RegulatoryResponse> => {
    return http.post('/recruitingCERegulatoryResponses', payload, {
        headers: {
            'Ora-Irc-Validation-Mode': 'P',
        },
    });
};
