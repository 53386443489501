import base64 from 'core/utils/base64';
import i18n from 'core/i18n/i18n';

export default {
    jobDetails: {
        sections: [
            {
                elementOrder: 0,
                sectionParams: [
                    {
                        paramKey: 'type',
                        paramValue: 'cc-section',
                    },
                ],
                rows: [
                    {
                        elementOrder: 0,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-title',
                                            },
                                        ],
                                    },
                                    {
                                        elementOrder: 1,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-location',
                                            },
                                        ],
                                    },
                                    {
                                        elementOrder: 2,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-tags',
                                            },
                                        ],
                                    },
                                    {
                                        elementOrder: 3,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-rule',
                                            },
                                            {
                                                paramKey: 'align',
                                                paramValue: 'center',
                                            },
                                            {
                                                paramKey: 'cssClass',
                                                paramValue: 'cc-element--rule-job-details',
                                            },
                                        ],
                                    },
                                    {
                                        elementOrder: 4,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-job-fit',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 1,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-description',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 2,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 1,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-responsibilities',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 3,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 2,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-qualifications',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 4,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 3,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-skills',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 5,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-corporate-description',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 6,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 1,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-organization-description',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 7,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-apply-button',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 8,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-info',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 9,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-media',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        elementOrder: 10,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [
                            {
                                elementOrder: 0,
                                columnParams: [
                                    {
                                        paramKey: 'type',
                                        paramValue: 'cc-column',
                                    },
                                ],
                                components: [
                                    {
                                        elementOrder: 0,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-paragraph',
                                            },
                                        ],
                                        componentContent: base64.encode(
                                            `<p class="job-details__similar-jobs-header" style="text-align: center">${i18n(
                                                'search.search-similar.header'
                                            )}</p>`
                                        ),
                                    },
                                    {
                                        elementOrder: 1,
                                        componentParams: [
                                            {
                                                paramKey: 'type',
                                                paramValue: 'cc-job-details-similar-jobs',
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
};
