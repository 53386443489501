import http from 'core/http/http';
import appConfig from 'app/model/config';
import jobMapper, { mapJobFitFromRest } from 'job-details/mapper/job';
import preferredLocationsMapper from 'job-details/mapper/preferredLocations';
import searchService from 'cx/module/search/service/search';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';


function createHttpConfig(jobId, settings = {}) {
    return {
        credentials: 'same-origin',

        cache: settings.reloadCache
            ? 'reload'
            : 'default',

        statusCodes: {
            401() {
                return Promise.reject();
            },
            404() {
                return Promise.reject('job-expired');
            },
        },
        params: {
            findParams: {
                Id: `"${jobId}"`,
                siteNumber: appConfig.siteNumber,
            },
        },
        paramsDelimiter: ',',
    };
}

function getJobDetails(jobId, settings) {
    const endpoint = '/recruitingCEJobRequisitionDetails';

    const queryElements = [
        'onlyData=true',
        'finder=ById;:findParams:',
    ];

    if (settings?.expand) {
        queryElements.unshift('expand=all');
    }

    const query = queryElements.join('&');
    const url = [endpoint, query].join('?');

    return http.get(url, createHttpConfig(jobId, {
        reloadCache: settings?.reloadCache,
    }));
}


export default {
    async getJob(jobId, isSignedIn = false) {
        const candidateReqList = isSignedIn ? await cachedCandidateApplications.get() : [];

        return getJobDetails(jobId, {
            expand: true,
        })
            .then(({ items }) => {
                const [job] = items;

                return job || Promise.reject('job-expired');
            })
            .then(job => jobMapper.mapJobFromRest(job, candidateReqList));
    },

    getLightJob(jobId) {
        return getJobDetails(jobId, {
            reloadCache: true,
        })
            .then(({ items }) => {
                const [job] = items;

                return job || Promise.reject('job-expired');
            })
            .then(jobMapper.mapLightJobFromRest);
    },

    getJobs(jobsIds) {
        if (!jobsIds.length) {
            return Promise.resolve({
                items: [],
            });
        }

        const params = {
            id: `Id=${jobsIds.join(' or ')}`,
            fields: 'Id,Title,PrimaryLocation,RequisitionId,WorkplaceType,WorkplaceTypeCode;secondaryLocations:Name',
            limit: 500,
        };

        return http.get('/recruitingCEJobRequisitionDetails?expand=secondaryLocations&onlyData=true&finder=ById;:id:&fields=:fields:&limit=:limit:', { params });
    },

    getJobPreview(jobId) {
        const url = '/recruitingCEJobRequisitionDetailsPreviews?expand=all&onlyData=true&finder=ById;:findParams:';

        return http.get(url, createHttpConfig(jobId))
            .then(({ items }) => jobMapper.mapJobFromRest(items.shift()));
    },

    getJobTemplatePreview(jobId) {
        const url = '/recruitingCERequisitionTemplateDetailsPreviews?expand=all&onlyData=true&finder=ById;:findParams:';

        return http.get(url, createHttpConfig(jobId))
            .then(({ items }) => jobMapper.mapJobFromRest(items.shift()));
    },

    getJobPreferredLocations(jobId) {
        return getJobDetails(jobId, {
            expand: true,
        })
            .then(({ items }) => preferredLocationsMapper.mapLocationsFromRest(items.shift()));
    },

    isJobPosted(jobId) {
        const query = { keyword: `"${jobId}"` };

        return searchService.searchJobs(query)
            .then(result => result.requisitionList.length > 0)
            .catch((error) => {
                console.error(`isJobPosted check failed: ${error}`);

                return false;
            });
    },
};


export const getJobFitScores = (requisitionNumber, candidateNumber, candidateProfile) => {
    if (candidateNumber || candidateProfile) {
        const url = '/recruitingCEJobMatchScoreGenerativeAIResponses';

        const payload = {
            siteNumber: appConfig.siteNumber,
            requisitionNumber,
            candidateNumber,
            candidateProfile,
        };

        return http.post(url, payload).then(mapJobFitFromRest);
    }

    return Promise.resolve({}).then(mapJobFitFromRest);
};
