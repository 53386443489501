import { components } from 'knockout';

import '../job-details-header';
import '../job-expired';
import '../job-details-loader';

import { JobDetailsCheckerViewModel } from './JobDetailsCheckerViewModel';
import template from './job-details-checker.html';

components.register('job-details-checker', {
    viewModel: JobDetailsCheckerViewModel,
    template,
});
