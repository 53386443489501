import TalentCommunityTileGeneralViewModel from 'cx/module/search/module/search-results/component/talent-community-tile/TalentCommunityTileViewModel';
import router from 'app/model/router';
import historyService from 'ce-common/service/historyService';

export default class TalentCommunityTileViewModel extends TalentCommunityTileGeneralViewModel {
    openSignUpComponent(): void {
        historyService.store();
        router.go('talent-community');
    }
}
