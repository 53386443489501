function debounce(func, wait, immediate) {
    let timeout;

    return (...args) => {
        const later = (...laterArgs) => {
            timeout = null;

            if (!immediate) {
                func.apply(this, laterArgs);
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(() => {
            later(...args);
        }, wait);

        if (callNow) {
            func.apply(this, args);
        }
    };
}

export default debounce;