import dictionary from 'cx/module/apply-flow/service/dictionary';
import geolocationService from 'cx/service/geolocation/geolocation';
import { propertyEquals } from 'core/utils/functional/propertyEquals';

const sortByTerritoryShortName = (country1, country2) => {
    if (country1.territoryShortName < country2.territoryShortName) {
        return -1;
    }

    if (country1.territoryShortName > country2.territoryShortName) {
        return 1;
    }

    return 0;
};

export default {
    LEADING_CHARS_REGEX: /^\+/,

    async getAll() {
        try {
            const allPhoneCountryCodes = await dictionary.get(dictionary.CODES.phoneCountryCodes);

            return allPhoneCountryCodes
                .filter(code => code.phoneCountryCode)
                .sort((codeA, codeB) => codeA.territoryShortName.localeCompare(codeB.territoryShortName))
                .map(code => ({
                    value: {
                        phoneCountryCode: code.phoneCountryCode,
                        legislationCode: code.territoryCode,
                    },
                    label: `+${code.phoneCountryCode} (${code.territoryShortName || ''})`,
                }));
        } catch (error) {
            console.error(error);

            return [];
        }
    },

    async getLocalCountryCode() {
        const [countryCodes, location] = await Promise.all([
            dictionary.get(dictionary.CODES.phoneCountryCodes),
            geolocationService.query(),
        ])
            .catch((error) => {
                console.error(error);

                return [];
            });

        if (!countryCodes || !location) {
            return null;
        }

        const matchingCode = countryCodes
            .find(({ territoryCode }) => territoryCode === location.countryCode);

        return {
            phoneCountryCode: matchingCode?.phoneCountryCode ?? null,
            legislationCode: matchingCode?.territoryCode ?? null,
        };
    },

    extractCountryCode(phoneNumber) {
        const startsWithCountryCode = countryCode =>
            phoneNumber.replace(this.LEADING_CHARS_REGEX, '').indexOf(countryCode) === 0;

        return dictionary.get(dictionary.CODES.phoneCountryCodes)
            .then((codes) => {
                const [matchingCode = ''] = codes
                    .map(code => code.phoneCountryCode)
                    .filter(code => startsWithCountryCode(code));

                return matchingCode;
            })
            .catch((error) => {
                console.error(error);

                return '';
            });
    },

    extractPhoneNumber(phoneNumber, phoneCountryCode) {
        if (phoneNumber && phoneCountryCode) {
            if (phoneNumber?.startsWith('+')) {
                return phoneNumber?.substring(phoneCountryCode?.length + 1, phoneNumber?.length);
            }

            return phoneNumber;
        }

        return '';
    },

    getPhoneNumber(restProfile = {}) {
        const { profileType, phoneNumber, phoneCountryCode, mobilePhoneNumber, mobilePhoneCountryCode } = restProfile;

        if (profileType === 'li') {
            return this.extractPhoneNumber(phoneNumber?.replace(/\s/g, ''), phoneCountryCode);
        } else if (profileType === 'in') {
            return this.extractPhoneNumber(mobilePhoneNumber?.replace(/\s/g, ''), mobilePhoneCountryCode);
        }

        return mobilePhoneNumber;
    },

    async getPhoneCountryCodeByTerritoryCode(territoryCode) {
        const countries = await dictionary.get(dictionary.CODES.phoneCountryCodes);

        return countries.find(propertyEquals('territoryCode', territoryCode))?.phoneCountryCode;
    },

    async getTerritoryCode(phoneCountryCode) {
        if (!phoneCountryCode) {
            return '';
        }

        const countries = await dictionary.get(dictionary.CODES.phoneCountryCodes);

        return countries.find(propertyEquals('phoneCountryCode', phoneCountryCode))?.territoryCode;
    },

    async getTerritoryCodeByPhoneCountryCode(toSortCountry, phoneCountryCode) {
        if (!phoneCountryCode) {
            return '';
        }

        if (phoneCountryCode === '1' && !toSortCountry) {
            return 'US';
        }

        if (phoneCountryCode === '44' && !toSortCountry) {
            return 'GB';
        }

        const countries = await dictionary.get(dictionary.CODES.phoneCountryCodes);

        if (!toSortCountry) {
            countries.sort(sortByTerritoryShortName);
        }

        return countries.find(propertyEquals('phoneCountryCode', phoneCountryCode))?.territoryCode;
    },

};
