import debouncePromise from 'core/utils/debouncePromise';
import {
    Observable,
    observable,
    ObservableArray,
    observableArray,
    PureComputed,
    pureComputed,
    Subscription,
} from 'knockout';
import router from 'app/model/router';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { toJS } from 'knockout';
import { Facet, Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import {
    searchFacets,
    getFilterByLabel,
} from 'cx/module/search/module/search-results/module/facet/service/facets';
import { highlightKeywordText } from 'cx/module/search/module/search-results/module/facet/service/highlightKeywordText';
import { LocationLevel } from 'app/module/cx/module/search/config/types';

type Props = {
    filters: ObservableArray<Filter>;
    facet: Facet;
    adminMode: boolean;
};

type RouterQuery = {
    lastSelectedFacet?: string;
    mode?: string;
    selectedLocationsFacet?: string;
    selectedPostingDatesFacet?: string;
    selectedTitlesFacet?: string;
    sortBy?: string;
    selectedWorkplaceTypesFacet?: string;
    locationLevel?: LocationLevel;
};

type searchFacetsDebouncedProps = [facetInfo: Facet, keyword: string];

export default class SearchFacetDropdownViewModel {
    isDropdownOpen: Observable<boolean>;
    filters: ObservableArray<Filter>;
    searchFilters: ObservableArray<Filter>;
    filtersToDisplay: PureComputed<Filter[]>;
    facet: Facet;
    adminMode: boolean;
    filterByLabel: string;
    searchValue: Observable<string>;
    searchInProgress: Observable<boolean>;
    showSearchFilters: boolean;
    searchMode: PureComputed<boolean>;
    searchFacetsDebounced: (...args: searchFacetsDebouncedProps) => Promise<Filter[]>;
    isSearchInputFocused: Observable<boolean>;
    title: string;

    private searchValueSubscription: Subscription;

    constructor({ filters, facet, adminMode }: Props) {
        this.isDropdownOpen = observable<boolean>(false);
        this.filters = filters;
        this.facet = facet;
        this.searchFilters = observableArray();
        this.adminMode = adminMode;
        this.searchValue = observable('');
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.filterByLabel = getFilterByLabel(facet.name, facet.title);
        this.searchInProgress = observable<boolean>(false);
        this.showSearchFilters = filters().length >= 10 || adminMode;
        this.isSearchInputFocused = observable<boolean>(false);
        this.title = this.facet.title || this.facet.name;

        this.searchFacetsDebounced = debouncePromise(this.loadFacets.bind(this), 500);

        this.searchValueSubscription = this.searchValue.subscribe(this.onSearchValueChange.bind(this));

        this.searchMode = pureComputed(() => !this.isEmptyOrWhiteSpace(this.searchValue()));

        this.filtersToDisplay = pureComputed((): Filter[] => {
            return this.searchMode() ? this.searchFilters() : this.filters();
        });
    }

    getFilterCount(): number {
        return this.filtersToDisplay().length;
    }

    announceSearchResults(): boolean {
        return (
            (!this.searchInProgress() || !this.searchMode()) &&
            this.showSearchFilters &&
            this.isDropdownOpen()
        );
    }

    toggleDropdown(): void {
        this.isDropdownOpen() ? this.closeDropdown() : this.openDropdown();
    }

    openDropdown(): void {
        this.isDropdownOpen(true);
    }

    closeDropdown(): void {
        this.isDropdownOpen(false);
    }

    toggleItem(item: Filter): void {
        item.selected(!item.selected());
    }

    setOption(item: Filter): void {
        this.toggleItem(item);

        const query = this.getRouteParams();

        const searchQuery = new SearchQueryBuilder(query).withFacet(toJS(this.facet)).build();

        router.go('search', { query: searchQuery }, { inherit: false });
    }

    async onSearchValueChange(newValue: string): Promise<void> {
        if (!this.isEmptyOrWhiteSpace(newValue)) {
            this.searchInProgress(true);
            this.isSearchInputFocused(true);

            const searchFilters = await this.searchFacetsDebounced(this.facet, newValue);

            this.searchFilters(searchFilters);
            this.searchInProgress(false);
        } else {
            setTimeout(() => {
                this.isSearchInputFocused(true);
            }, 50);
        }
    }

    async loadFacets(facet: Facet, keyword: string): Promise<Filter[]> {
        const selectedFiltersQuery = this.getRouteParams() || {};

        const searchFilters = await searchFacets(facet.type, keyword, selectedFiltersQuery, facet.context);

        const selectedFilters = this.filters().filter((facetFilter) => facetFilter.selected());

        searchFilters.forEach((filter) => {
            filter.label = highlightKeywordText(filter.text, keyword);

            if (selectedFilters.some((selectedFilter) => selectedFilter.value === filter.value)) {
                filter.selected(true);
            }
        });

        return searchFilters;
    }

    isEmptyOrWhiteSpace(value: string): boolean {
        return value === null || value.match(/^ *$/) !== null;
    }

    dispose(): void {
        this.searchValueSubscription.dispose();
    }

    private getRouteParams(): RouterQuery {
        const { query } = router.routeParams() as { query: RouterQuery };

        return query;
    }
}
