import { bindingHandlers } from 'knockout';

/**
 * Dynamic change source of the video element
 *
 * @param {String} src source of the video
 *
 * @example
 * <video>
 *     <source type="video/mp4" data-bind="videoSrc: videoSrc">
 * </video>
 */
bindingHandlers.videoSrc = {
    update(element, valueAccessor) {
        const src = valueAccessor();

        if (!src()) {
            element.removeAttribute('src');
        } else {
            element.src = src();
        }

        element.parentNode.load();
    },
};