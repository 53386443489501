import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class PrecisionNumberFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.precision';

        ko.utils.extend(this._options, {
            precisionValue: this._options.precision,
        });
    }

    _validate(value = '', options) {
        const number = String(value).replace('.', '')
            .replace('-', '');

        return number.length <= options.precision;
    }

}