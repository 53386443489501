import { components } from 'knockout';

import { CookieConsentModalTitleViewModel } from './CookieConsentModalTitleViewModel';
import template from './cookie-consent-modal-title.html';

components.register('cookie-consent-modal-title', {
    viewModel: CookieConsentModalTitleViewModel,
    template,
});

components.register('cc-cookie-consent-modal-title', {
    viewModel: CookieConsentModalTitleViewModel,
    template,
});
