let _callbackCounter = 0;

function _getCallbackName({ success, failure, timeout }) {
    const callbackName = `__jsonCallbacks__${_callbackCounter++}`;

    window[callbackName] = (() => {
        const timeoutId = setTimeout(() => {
            failure({
                statusCode: 400,
            });
        }, timeout);

        return (data) => {
            clearTimeout(timeoutId);
            success(data);
        };
    })();

    return callbackName;
}

function _encodeData(data) {
    const params = Object.keys(data).reduce((result, key) => {
        let value = data[key];

        if (value && typeof value === 'object') {
            value = _encodeData(value);
        }

        result.push(`${key}=${value}`);

        return result;
    }, []);

    return params.join('&');
}

function _createUrl(config) {
    let url = `${config.url}?callback=${_getCallbackName(config)}`;

    if (config.body) {
        url += `&${_encodeData(config.body)}`;
    }

    return url;
}

function _createRequest(config) {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = _createUrl(config);
    script.async = true;

    let timeoutId = null;

    function _callback(event) {
        clearTimeout(timeoutId);

        script.removeEventListener('load', _callback);
        script.removeEventListener('error', _callback);

        document.body.removeChild(script);

        if (event.type === 'error') {
            config.failure({
                statusCode: 404,
            });
        }
    }

    timeoutId = setTimeout(() => {
        _callback({
            type: 'error',
        });
    }, config.timeout);

    script.addEventListener('load', _callback);
    script.addEventListener('error', _callback);

    document.body.appendChild(script);
}

export default {
    request(requestConfig) {
        return new Promise((resolve, reject) => {
            const config = requestConfig;

            config.success = resolve;
            config.failure = reject;

            _createRequest(config);
        });
    },
};