const PREFERRED_LOCATION_NAMES = ['preferredLocationId1', 'preferredLocationId2', 'preferredLocationId3', 'preferredLocationId4'];

const EXCLUDED_LOCATION_NAMES = ['excludedLocationId1', 'excludedLocationId2', 'excludedLocationId3', 'excludedLocationId4'];

export default {
    getPreferredLocationFields() {
        return PREFERRED_LOCATION_NAMES;
    },

    getExcludedLocationFields() {
        return EXCLUDED_LOCATION_NAMES;
    },
};
