import http from 'core/http/http';
import router from 'app/model/router';
import notificationsService from 'cx/service/notifications';
import { PARTNER_TYPE_SETTINGS } from '../config/configPartner';
import urlEncoder from 'app/module/core/router/urlEncoder';

export default class Metadata {

    constructor(serviceUrl) {
        this._serviceUrl = serviceUrl;
    }

    getPartnerConfig({ requisitionNumber, flowVersionId, draftId, blockCode }) {
        const serviceUrl = this._serviceUrl
            .replace('<FLOW_VERSION_ID>', flowVersionId)
            .replace('<APP_DRAFT_ID>', draftId)
            .replace('<REQ_NUM>', `%22${urlEncoder.encodeQueryParams(requisitionNumber)}%22`);

        const { partnerMapper } = PARTNER_TYPE_SETTINGS[blockCode];

        return http.get(serviceUrl)
            .then(partnerMapper);
    }

    isEmpty({ blockCode }, flowVersionId) {
        const requisitionNumber = router.routeParams().jobId;

        const partnerConfigParameters = {
            requisitionNumber,
            flowVersionId,
            draftId: null,
            blockCode,
        };

        return this.getPartnerConfig(partnerConfigParameters)
            .then(response => Promise.resolve(!response.partnerConfiguredFlag))
            .catch((error) => {
                console.error(error);
                notificationsService.error();
            });
    }

}