import appConfig from 'app/model/config';
import i18n from 'core/i18n/i18n';
import BotSDK from 'bots';
import * as botsConfigSanitizer from 'app/mapper/botsConfigSanitizer';
import botsEventHandler from 'app/service/botsEventHandler';
import storageService from 'core/storage/sessionStorage';
import geolocation from 'app/module/cx/service/geolocation/geolocation';
import { getLocalTimezone } from 'core/utils/timezoneUtils';
import appEvents from 'app/config/events';
import { CHATBOT_SETTING_KEY } from 'app/service/globalSettings';
import { ODA_CONTEXT } from '../module/minimal/component/splash-page/config/constant';
import { openBot } from 'app/module/minimal/component/splash-page/service/openBot';
import { parseBotPositionAttribute, shouldInitializeBot, closeBot, getDefaultChatBotPosition } from './botUtils';
import { isSearchByRecruitingLocationsModeEnabled } from 'cx/module/search/module/location/config/locationSearchConfig';

const BOTS_WINDOW_KEY = 'bots';
const BOTS_STORAGE_KEY = 'ceBot';

const userProfileProperties = {
    siteNumber: appConfig.siteNumber,
    faEndpoint: appConfig.apiBaseUrl,
    deepLink: appConfig.vanityBaseUrl,
    siteLanguage: appConfig.siteLang,
    isSearchByRecruitingLocationsModeEnabled: isSearchByRecruitingLocationsModeEnabled(),
};

const shareMenuItems = [
    {
        type: 'txt rtf doc docx pdf odt htm html',
        maxSize: 5120,
    },
];

const DELETAGE_CONFIG = {
    beforeSend: message => botsEventHandler.beforeSendHandler(message, window[BOTS_WINDOW_KEY], userProfileProperties),
    beforeDisplay: message => botsEventHandler.beforeDisplay(message, window[BOTS_WINDOW_KEY]),
};

const ON_WIDGET_OPENED = 'widget:opened';

function createChatWidgetSettings() {
    const defaults = {
        URI: appConfig.getSettingByKey(CHATBOT_SETTING_KEY)?.uri,
        speechLocale: BotSDK.SPEECH_LOCALE.EN_US,
        theme: BotSDK.THEME.REDWOOD_DARK,
        i18n: {
            en: {
                chatTitle: i18n('chatbot.messenger.header', { company_name: appConfig.siteName }),
                chatSubtitle: i18n('chatbot.messenger.welcome-message'),
                inputPlaceholder: i18n('chatbot.messenger.send.placeholder'),
            },
        },
        linkHandler: {
            onclick: (event) => {
                botsEventHandler.buttonClickHandler(event);
            },
        },
        initUserProfile: {
            profile: {
                timezoneOffset: (new Date()).getTimezoneOffset() * 60 * 1000,
                timezoneOffsetStr: getLocalTimezone(),
                languageTag: null,
                locale: null,
            },
        },
    };

    return {
        welcomeMessage: appConfig.getSettingByKey(CHATBOT_SETTING_KEY)?.initUserHiddenMessage,
        botsConfig: botsConfigSanitizer.sanitize(Object.assign({},
            defaults,
            appConfig.getSettingByKey(CHATBOT_SETTING_KEY))),
    };
}

const setDefaultChatbotWidgetStrings = (botsConfig) => {
    botsConfig.i18n.en = {
        ...botsConfig.i18n.en,
        ...{
            endConversationConfirmMessage: i18n('chatbot.widget.end-conversation-confirm-message'),
            endConversationDescription: i18n('chatbot.widget.end-conversation-description-message'),
            showOptions: i18n('chatbot.widget.show-options-hint'),
            endConversation: i18n('chatbot.widget.end-conversation-hint'),
            upload: i18n('chatbot.widget.upload-file-hint'),
            speak: i18n('chatbot.widget.speak-button-hint'),
            yesText: i18n('general.yes'),
            noText: i18n('general.no'),
        },
    };
};

async function init() {
    if (!shouldInitializeBot(window.location.href)) {
        return;
    }

    const chatbotConfig = appConfig.getSettingByKey(CHATBOT_SETTING_KEY);
    let isFirstConnection = true;

    if (chatbotConfig
        && appConfig.getSettingByKey('CHATBOT_ENABLED') === 'Y'
        && _hasChatbotUrl()
        && chatbotConfig?.channelId) {
        storageService.remove(BOTS_STORAGE_KEY);

        setTimeout(async () => {
            const { botsConfig, welcomeMessage } = createChatWidgetSettings();

            if (!botsConfig.position) {
                botsConfig.position = getDefaultChatBotPosition();
            }

            if (typeof botsConfig.position === 'string') {
                botsConfig.position = parseBotPositionAttribute(botsConfig.position);
            }

            if (!botsConfig.globalActionsLayout) {
                botsConfig.globalActionsLayout = 'horizontal';
            }

            if (!botsConfig.cardActionsLayout) {
                botsConfig.cardActionsLayout = 'horizontal';
            }

            if (botsConfig.enableAttachment) {
                shareMenuItems[0].label = i18n('chatbot.widget.attachment-button-label');
                botsConfig.shareMenuItems = shareMenuItems;
                userProfileProperties.enableAttachment = chatbotConfig.enableAttachment;
            }

            setDefaultChatbotWidgetStrings(botsConfig);

            const bots = new BotSDK(botsConfig);// Initiate library with configuration

            bots.ORCAdapter = botsEventHandler.ORCAdapter;
            bots.setDelegate(DELETAGE_CONFIG);
            document.body.setAttribute('data-chatbot-ready', true);

            if (window.location.href.includes(`?context=${ODA_CONTEXT}`)) {
                await _initializeBot(bots, '', true);
            } else if (botsConfig.openChatOnLoad) {
                await _initializeBot(bots, welcomeMessage, false);
            } else {
                bots.on(ON_WIDGET_OPENED, async () => {
                    if (isFirstConnection) {
                        await _initializeBot(bots, welcomeMessage, false);
                        isFirstConnection = false;
                    }
                });
            }
        });
    }
}

function isQRCodeActive() {
    if (window.location.href.includes('&qrShortCode=')) {
        return true;
    }

    return false;
}

async function _initializeBot(bots, welcomeMessage, hasUrlOdaContext) {
    try {
        await bots.connect();

        if (!hasUrlOdaContext || isQRCodeActive()) {
            askLocation(bots, welcomeMessage);
        }

        window[BOTS_WINDOW_KEY] = bots;

        appEvents.botCloseSignal.add(() => closeBot());

        if (hasUrlOdaContext) {
            openBot();
        } else {
            appEvents.botInitializedSignal.dispatch();
        }
    } catch (error) {
        console.error('Bot initialization failed');
        console.error(error);
    }
}

function _hasChatbotUrl() {
    const { uri, chatServerURI } = appConfig.getSettingByKey(CHATBOT_SETTING_KEY);

    return uri || chatServerURI;
}

function reload() {
    if (window[BOTS_WINDOW_KEY]) {
        window[BOTS_WINDOW_KEY].destroy();
    }

    init();
}

function askLocation(bots, welcomeMessage) {
    const ceBotInSession = storageService.restore(BOTS_STORAGE_KEY);

    if (!ceBotInSession || !ceBotInSession.greeted) {
        geolocation.query()
            .then((result) => {
                userProfileProperties.userLocation = JSON.stringify(result);
            })
            .finally(() => {
                if (!isQRCodeActive()) {
                    updateUserAndSendMessage(bots, userProfileProperties, welcomeMessage);
                }
            });
    }
}

function updateUserAndSendMessage(bots, userProperties, welcomeMessage) {
    bots.updateUser({
        profile: {
            properties: userProperties,
        },
    });

    if (welcomeMessage) {
        bots.sendMessage(welcomeMessage, {
            hidden: true,
        });
    }

    storageService.store(BOTS_STORAGE_KEY, {
        greeted: true,
    });
}

export default {
    init,
    reload,
};