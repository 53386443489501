/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyBindings, observable } from 'knockout';
import { useLayoutEffect, useMemo } from 'preact/hooks';
import { RefObject } from 'preact';

export const useKnockoutBindings = (
    elementRef: RefObject<HTMLElement>,
    viewModel: Record<string, unknown> = {}
): void => {
    const viewModelObservable = useMemo(() => observable<any>(), []);

    useLayoutEffect(() => viewModelObservable(viewModel), [viewModel]);

    useLayoutEffect(() => {
        const element = elementRef.current;

        if (!element) {
            throw new Error('No element for knockout bindings application.');
        }

        applyBindings(viewModelObservable, element);
    }, [elementRef]);
};
