import http from 'core/http/http';
import assessmentMapper from '../mapper/assessment';
import taxCreditMapper from '../mapper/taxCredit';
import inlinePartnerMapper from '../mapper/inlinePartner';

export default {
    getInlineAssessmentPartnerURL(params) {
        const payload = inlinePartnerMapper.mapInlineToRest(params);

        return http.post('/recruitingCandidateAssessments', payload)
            .then(assessmentMapper.mapPartnerDataFromRest);
    },

    getInlineTaxCreditPartnerURL(params) {
        const payload = inlinePartnerMapper.mapInlineToRest(params);

        return http.post('/recruitingCETaxCredits', payload)
            .then(taxCreditMapper.mapPartnerDataFromRest);
    },
};

