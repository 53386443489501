import i18n from 'core/i18n/i18n';
import { RouteConfigEntry } from 'core/router/config/types';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';

export const cssActionsRoutes: Record<string, RouteConfigEntry> = {
    'candidate-self-service-unsubscribe-job-alerts': {
        url: '/my-profile/{token}/job-alerts/unsubscribe',
        view: {
            layout: 'candidate-self-service-action',
        },
        title: i18n('candidate-self-service.page-title'),
    },

    'candidate-self-service-doi-accept': {
        url: '/my-profile/{token}/doi/accept',
        view: {
            layout: 'candidate-self-service-action',
        },
        title: i18n('candidate-self-service.page-title'),
    },

    'candidate-self-service-not-me': {
        url: '/my-profile/{token}/talent-community/not-me',
        view: {
            layout: 'candidate-self-service-action',
        },
        title: i18n('candidate-self-service.page-title'),
    },
};

export const cssTokenRoute: Record<string, RouteConfigEntry> = {
    'candidate-self-service-with-token': {
        url: '/my-profile/{token}',
        view: {
            layout: 'candidate-self-service',
        },
        title: i18n('candidate-self-service.page-title'),

        enter(routeParams) {
            storeShortTokenData({ token: routeParams?.token });

            return Promise.resolve();
        },
    },
};
