import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';
import { EMAIL_REGEX } from '../config/regex';

const EMAIL_FORMAT = 'example@email.com';

export default class EmailFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        ko.utils.extend(this._options, {
            email_format: EMAIL_FORMAT,
        });

        this._message = 'validators.invalid-email';
    }

    _validate(value) {
        if (value) {
            if (Array.isArray(value)) {
                return value.every(EMAIL_REGEX.test.bind(EMAIL_REGEX));
            }

            return EMAIL_REGEX.test(value);
        }

        return true;
    }

}
