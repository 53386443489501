import FormValidator from 'core/form/validator/Validator';

export default class RequiredWorkLocationsFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.validation.required-locations';
    }

    _countLocations(locations) {
        return locations
            .map(location => location())
            .filter(location => Boolean(location))
            .length;
    }

    _validate(value, options) {
        return this._countLocations(options.allLocations) >= options.min;
    }

}
