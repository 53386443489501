import http from 'core/http/http';
import assessmentMapper from 'apply-flow/mapper/assessment';
import taxCreditMapper from 'apply-flow/mapper/taxCredit';
import backgroundCheckMapper from 'apply-flow/mapper/backgroundCheck';

export default {
    getAssessmentURL(requisitionNumber, provisioningId) {
        return http.post('/recruitingCandidateAssessments', { requisitionNumber, provisioningId })
            .then(assessmentMapper.mapAssessmentLinkFromRest)
            .catch((err) => {
                console.error(err);

                return Promise.reject('Assessment link error');
            });
    },

    getTaxCreditURL(requisitionNumber) {
        return http.post('/recruitingCETaxCredits', { requisitionNumber })
            .then(taxCreditMapper.mapTaxCreditLinkFromRest)
            .catch((err) => {
                console.error(err);

                return Promise.reject('Tax credit link error');
            });
    },

    getBackgroundCheckURL(requisitionNumber, provisioningId) {
        return http.post('/recruitingCEBackgroundChecks', { requisitionNumber, provisioningId })
            .then(backgroundCheckMapper.mapBackgroundCheckLinkFromRest)
            .catch((err) => {
                console.error(err);

                return Promise.reject('BGC link error');
            });
    },
};

