import tokenService from 'cx/module/candidate-verification/service/token';
import { CHALLENGE_REQUIRED } from 'cx/module/candidate-verification/config/pinVerificationStatus';
import {
    INVALID_COOKIE,
    SIGNIN_LOCKED,
} from 'cx/module/candidate-verification/config/candidateVerificationStatus';
import { redirectToRegisterFlow } from '../../../config/eventRegisterFlowRoutes';
import { Token } from 'cx/module/candidate-verification/config/types';
import { CandidateVerificationRequest } from '../../../config/types';
import {
    getSourceTrace,
    removeSourceTrace,
} from 'app/module/cx/module/candidate-verification/service/sourceTraceStorage';

let claimedDateOfBirth: Date | undefined;
let cachedDateOfBirth: Date | undefined;

export const verifyDateOfBirth = async (
    candidateVerificationRequest: CandidateVerificationRequest
): Promise<void> => {
    claimedDateOfBirth = undefined;

    const { eventNumber, candidate, submodule, dateOfBirth, captchaToken, honeyPot } =
        candidateVerificationRequest;

    const sourceTrace = getSourceTrace();

    return tokenService
        .create({ eventNumber, candidate, submodule, sourceTrace, dateOfBirth, captchaToken, honeyPot })
        .then((verificationToken: Token) => {
            if (verificationToken.challengeFlag) {
                removeSourceTrace();

                return Promise.reject(CHALLENGE_REQUIRED);
            }

            return redirectToRegisterFlow();
        })
        .catch((error: string) => {
            if (error === INVALID_COOKIE || error === SIGNIN_LOCKED) {
                cachedDateOfBirth = dateOfBirth;
            }

            return Promise.reject(error);
        });
};

export const getClaimedDateOfBirth = (): Date | undefined => {
    return claimedDateOfBirth;
};

export const setClaimedDateOfBirth = (dateOfBirth: Date): void => {
    claimedDateOfBirth = dateOfBirth;
};

export const reclaimDateOfBirth = (): void => {
    claimedDateOfBirth = cachedDateOfBirth;
};
