import { components } from 'knockout';

import SearchFacetVerticalViewModel from './SearchFacetVerticalViewModel';
import SearchPostingDateFacetVerticalViewModel from './SearchPostingDateFacetVerticalViewModel';
import template from './search-facet-vertical.html';

components.register('search-categories-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-organizations-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-locations-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-postingDates-facet-vertical', {
    viewModel: SearchPostingDateFacetVerticalViewModel,
    template,
});

components.register('search-titles-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-workLocations-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-flexFields-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-workplaceTypes-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

// Event Facet Component Registration

components.register('search-eventCategories-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-startDates-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});

components.register('search-eventFormats-facet-vertical', {
    viewModel: SearchFacetVerticalViewModel,
    template,
});
