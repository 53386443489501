import ko from 'knockout';
import { areRecommendedJobsEnabledWithParams } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';

function _underscoreToUpperCase(str) {
    return str.toLowerCase().replace(/_(\w)/g, (match, submatch) => submatch.toUpperCase());
}

function _getFacetParamKey(facetType) {
    const facetName = _underscoreToUpperCase(facetType);

    return `selected${facetName[0].toUpperCase()}${facetName.slice(1)}Facet`;
}

function _getFacetParamValue(facetSelectedIds) {
    if (facetSelectedIds && facetSelectedIds.length) {
        return facetSelectedIds.join(';');
    }

    return null;
}

function _isSrcParam(paramKey) {
    return paramKey === 'src';
}

export const buildSortOption = (sortOptions) => {
    if (!sortOptions) {
        return null;
    }

    if (sortOptions.code === 'RELEVANCY') {
        return sortOptions.code;
    }

    return `${sortOptions.code}_${sortOptions.order || 'DESC'}`;
};

/**
 * Create new SearchQueryBuilder with initial params
 * @constructor
 * @param {object} params
 */
export default class SearchQueryBuilder {

    constructor(params) {
        this._params = ko.utils.extend({}, params || {});
    }

    /**
     * Return object with search query params
     * @returns {object}
     */
    build() {
        const relevantParams = getRelevantParams(this._params);

        return Object.keys(relevantParams)
            .filter(paramKey => !_isSrcParam(paramKey))
            .sort()
            .reduce((queryParamsObj, paramKey) => {
                const queryParams = queryParamsObj;
                const paramValue = relevantParams[paramKey];

                if (paramValue) {
                    queryParams[paramKey] = paramValue;
                }

                return queryParams;
            }, {});
    }

    /**
     * Set keyword
     * @param {string} keyword
     * @returns {SearchQueryBuilder}
     */
    withKeyword(keyword) {
        this._params.keyword = keyword;

        return this;
    }

    /**
     * Remove keyword
     * @returns {SearchQueryBuilder}
     */
    withoutKeyword() {
        delete this._params.keyword;

        return this;
    }

    /**
     * Set Exact keyword
     * @returns {SearchQueryBuilder}
     */
    withExactKeyword() {
        this._params.useExactKeywordFlag = true;

        return this;
    }

    /**
     * Remove Exact keyword
     * @returns {SearchQueryBuilder}
     */
    withoutExactKeyword() {
        delete this._params.useExactKeywordFlag;

        return this;
    }

    /**
     * Set location
     * @param {object} location
     * @returns {SearchQueryBuilder}
     */
    withLocation(location) {
        if (location) {
            this._params.locationId = location.locationId;
            this._params.location = location.label;
            this._params.locationLevel = location.level;

            if (location.isGeolocation) {
                this._params.latitude = location.latitude;
                this._params.longitude = location.longitude;
            } else {
                this._params.latitude = null;
                this._params.longitude = null;
            }
        } else {
            this._params.locationId = null;
            this._params.location = null;
            this._params.locationLevel = null;
            this._params.latitude = null;
            this._params.longitude = null;
        }

        return this;
    }

    /**
     * Remove location
     * @returns {SearchQueryBuilder}
     */
    withoutLocation() {
        delete this._params.locationId;
        delete this._params.location;
        delete this._params.locationLevel;
        delete this._params.latitude;
        delete this._params.longitude;

        return this;
    }

    /**
     * Set radius
     * @param {number} radius
     * @returns {SearchQueryBuilder}
     */
    withRadius(radius) {
        this._params.radius = String(radius);

        return this;
    }

    /**
     * Remove location
     * @returns {SearchQueryBuilder}
     */
    withoutRadius() {
        delete this._params.radius;
        delete this._params.radiusUnit;

        return this;
    }

    /**
     * Set radius unit
     * @param {string} radiusUnit
     * @returns {SearchQueryBuilder}
     */
    withRadiusUnit(radiusUnit) {
        this._params.radiusUnit = radiusUnit;

        return this;
    }

    withZipcode(zipcode) {
        this._params.workLocationZipCode = zipcode;

        return this;
    }

    withoutZipcode() {
        delete this._params.workLocationZipCode;

        return this;
    }

    withZipcodeKeyword(zipcode) {
        this._params.zipcode = zipcode;

        return this;
    }

    withoutZipcodeKeyword() {
        delete this._params.zipcode;

        return this;
    }

    withCountryCode(countryCode) {
        this._params.workLocationCountryCode = countryCode;

        return this;
    }

    withoutCountryCode() {
        delete this._params.workLocationCountryCode;

        return this;
    }

    withMode(mode) {
        this._params.mode = mode;

        return this;
    }

    /**
     * Set offset
     * @param {number} offset
     * @returns {SearchQueryBuilder}
     */
    withOffset(offset) {
        this._params.offset = offset;

        return this;
    }

    withoutOffset() {
        this._params.offset = null;

        return this;
    }

    /**
     * Set limit
     * @param {number} limit
     * @returns {SearchQueryBuilder}
     */
    withLimit(limit) {
        this._params.limit = limit;

        return this;
    }

    /**
     * Set sort option
     * @param {string} sortOption
     * @param {string} [sortOrder=SearchQueryBuilder.SORT_ORDER.DESC]
     * @returns {SearchQueryBuilder}
     */
    withSortOption(sortOptions) {
        this._params.sortBy = buildSortOption(sortOptions);

        return this;
    }

    /**
     * Resets sort option
     * @returns {SearchQueryBuilder}
     */
    withoutSortOption() {
        this._params.sortBy = null;

        return this;
    }

    /**
     * setDefaultSortOption will add the default sortBy attribute to the query builder
     */
    setDefaultSortOption(defaultSortOption) {
        if (this._params.sortBy) {
            return;
        }

        if (defaultSortOption) {
            this.withSortOption(defaultSortOption);
        }
    }

    /**
     * Add facet
     * @param {object} facet
     * @returns {SearchQueryBuilder}
     */
    withFacet(facet) {
        const _getFacetTypes = () => Object.keys(SearchQueryBuilder.FACET_TYPES)
            .map(facetCode => SearchQueryBuilder.FACET_TYPES[facetCode]);

        const _findLastSelectedFacet = () => {
            const facetTypes = _getFacetTypes();

            return ko.utils.arrayFirst(facetTypes, (facetType) => {
                const paramKey = _getFacetParamKey(facetType);

                return this._params[paramKey];
            });
        };

        const paramKey = facet.paramKey || _getFacetParamKey(facet.type);

        const paramValue = facet.paramValueBuilder
            ? facet.paramValueBuilder(this._params[paramKey], facet.type, facet.selectedIds)
            : _getFacetParamValue(facet.selectedIds);

        this._params[paramKey] = paramValue;
        this._params.lastSelectedFacet = paramValue ? facet.type : _findLastSelectedFacet();

        return this;
    }

    /**
     * Clears all facets.
     * @returns {SearchQueryBuilder}
     */
    withoutFacets() {
        delete this._params.lastSelectedFacet;

        Object.keys(this._params).forEach((paramName) => {
            if (paramName.indexOf('selected') >= 0 && paramName.indexOf('Facet') > 0) {
                delete this._params[paramName];
            }
        });

        return this;
    }

    withHotJobs(hotJobsEnabled) {
        this._params.hotJobFlag = hotJobsEnabled;

        return this;
    }

    withoutHotJobs() {
        delete this._params.hotJobFlag;

        return this;
    }

    clearSearchBoxCriteria() {
        this
            .withoutKeyword()
            .withoutLocation()
            .withoutCountryCode()
            .withoutRadius()
            .withoutZipcode()
            .withoutZipcodeKeyword();
    }

    get lastSelectedFacetValue() {
        return this.lastSelectedFacet && this._params[_getFacetParamKey(this.lastSelectedFacet)];
    }

    get lastSelectedFacet() {
        return this._params.lastSelectedFacet;
    }

    get hotJobFlag() {
        return this._params.hotJobFlag;
    }

    get sortBy() {
        return this._params.sortBy;
    }

    /**
     * Return new SearchQueryBuilder initialized with passed parameters
     *
     * @param {object} params
     * @returns {SearchQueryBuilder}
     */
    static create(params) {
        return new SearchQueryBuilder(params);
    }

}

/**
 * Location levels
 * @readonly
 * @enum {{STATE: string, CITY: string, COUNTRY: string}}
 */
SearchQueryBuilder.LOCATION_LEVEL = {
    STATE: 'state',
    CITY: 'city',
    COUNTRY: 'country',
};

/**
 * Radius units
 * @readonly
 * @enum {string}
 */
SearchQueryBuilder.RADIUS_UNIT = {
    KM: 'KM',
    MI: 'MI',
};

/**
 * Sort options
 * @enumdef SORT_OPTIONS
 * @enum {{TITLES: string, POSTING_DATES: string, RELEVANCY: string}}
 */
SearchQueryBuilder.SORT_OPTIONS = {
    TITLES: 'TITLES',
    POSTING_DATES: 'POSTING_DATES',
    RELEVANCY: 'RELEVANCY',
};

/**
 * Order options
 * @readonly
 * @enum {string}
 */
SearchQueryBuilder.SORT_ORDER = {
    ASC: 'ASC',
    DESC: 'DESC',
};

/**
 * Facet types
 * @readonly
 * @enum {string}
 */
SearchQueryBuilder.FACET_TYPES = {
    TITLES: 'TITLES',
    CATEGORIES: 'CATEGORIES',
    LOCATIONS: 'LOCATIONS',
    ORGANIZATIONS: 'ORGANIZATIONS',
    POSTING_DATES: 'POSTINGDATES',
    WORKPLACE_TYPES: 'WORKPLACE_TYPES',
    EVENT_CATEGORIES: 'EVENT_CATEGORIES',
    START_DATES: 'START_DATES',
    EVENT_FORMATS: 'EVENT_FORMATS',
};

function isRadiusPossible(params) {
    const isGeolocation = params.latitude != null && params.longitude != null;

    if (isGeolocation) {
        return true;
    }

    return params.location && params.locationLevel === SearchQueryBuilder.LOCATION_LEVEL.CITY;
}


function getRelevantParams(params) {
    const relevantParams = ko.utils.extend({}, params);

    if (!isRadiusPossible(relevantParams)) {
        delete relevantParams.radius;
        delete relevantParams.radiusUnit;
    }

    if (areRecommendedJobsEnabledWithParams()) {
        delete relevantParams.selectedFile;
        delete relevantParams.viewBy;
    }

    return relevantParams;
}
