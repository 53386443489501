type EncryptedData = {
    cipherText: string;
    iv: string;
};

const ALGORITHM = 'AES-GCM';

const algorithmIdentifier = {
    name: ALGORITHM,
    length: 256,
};

const getStringFromArrayBuffer = (buffer: ArrayBuffer): string => {
    const typedArray = new Uint8Array(buffer);

    return JSON.stringify(Array.from(typedArray));
};

const getUint8ArrayFromString = (key: string): Uint8Array => new Uint8Array(JSON.parse(key));

const importSecretKey = async (key: string): Promise<CryptoKey> => {
    const parsedArray = JSON.parse(key);

    const secretKey = await crypto.subtle.importKey(
        'raw',
        new Uint8Array(parsedArray),
        algorithmIdentifier,
        true,
        ['encrypt', 'decrypt']
    );

    return secretKey;
};

export const generateUniqueKey = async (): Promise<string> => {
    const key = await crypto.subtle.generateKey(algorithmIdentifier, true, ['encrypt', 'decrypt']);
    const exported = await crypto.subtle.exportKey('raw', key);

    return getStringFromArrayBuffer(exported);
};

export const encryptMessage = async (message: string, key: string): Promise<EncryptedData> => {
    const encodedText = new TextEncoder().encode(message);

    const iv = crypto.getRandomValues(new Uint8Array(12));
    const secretKey = await importSecretKey(key);

    const cipherText = await crypto.subtle.encrypt(
        {
            name: ALGORITHM,
            iv,
        },
        secretKey,
        encodedText
    );

    return {
        cipherText: getStringFromArrayBuffer(cipherText),
        iv: getStringFromArrayBuffer(iv),
    };
};

export const decryptMessage = async (encryptedText: string, key: string, iv: string): Promise<string> => {
    const cipherText = getUint8ArrayFromString(encryptedText);
    const initVector = getUint8ArrayFromString(iv);
    const secretKey = await importSecretKey(key);

    const decrypted = await crypto.subtle.decrypt(
        {
            name: ALGORITHM,
            iv: initVector,
        },
        secretKey,
        cipherText
    );

    return new TextDecoder().decode(decrypted);
};
