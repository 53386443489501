import ko from 'knockout';
import mapping from 'knockout-mapping';

import profileItemsInstance from './../service/profileItemsInstance';

export default class AbstractProfileItem {

    constructor(profileItemPrototype, data) {
        if (!data?.id) {
            profileItemPrototype.id = profileItemsInstance.next();
        }

        mapping.fromJS(profileItemPrototype, {}, this);

        if (data) {
            mapping.fromJS(data, {}, this);
        }

        profileItemPrototype.id = null;
    }

    isNew() {
        return this.action() === 'CREATE';
    }

    clear(profileItemPrototype, idKey) {
        const proto = Object.create(profileItemPrototype);

        ['id', 'action', 'contentTypeId', idKey].forEach((property) => {
            proto[property] = ko.unwrap(this[property]);
        });

        mapping.fromJS(proto, this);
    }

    fill(itemData) {
        mapping.fromJS(itemData, this);
    }

    getSnapshot() {
        return mapping.toJS(this);
    }

}
