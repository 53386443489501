import { JobTag } from './config/jobTags';
import { Observable } from 'knockout';
import { LayoutStyle } from 'minimal/module/search/component/search-results/config/types';

type Params = {
    jobTags: JobTag[];
    layoutStyle: Observable<LayoutStyle>;
};

export class JobTagsViewModel {
    jobTags: JobTag[];
    layoutStyle: Observable<LayoutStyle>;

    constructor({ jobTags, layoutStyle }: Params) {
        this.jobTags = jobTags;
        this.layoutStyle = layoutStyle;
    }
}
