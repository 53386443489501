import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import appConfig from 'app/model/config';
import { RouterQuery } from 'search/config/types';

/**
 * Returns if keyword is provided as search criteria
 * @returns {boolean}
 */
export const isKeywordSearch = (query: RouterQuery): boolean => {
    return Boolean(query.keyword);
};

/**
 * Returns if filter facets are selected
 * @returns {boolean}
 */
export const isFilterSearch = (query: RouterQuery): boolean => {
    return Boolean(query.lastSelectedFacet);
};

/**
 * Returns if location - my location, location or zipcode is provided in search criteria
 * @returns {boolean}
 */
export const isLocationSearch = (query: RouterQuery): boolean => {
    const { location, zipcode, latitude } = query;

    return Boolean(location || zipcode || latitude);
};

/**
 * Returns if distance is enabled in the application
 * @returns {boolean}
 */
export const isDistanceEnabled = (): boolean => {
    return mapTrueFalseStringToBoolean(appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED'));
};
