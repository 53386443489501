import store from 'site-editor/store/store';
import { Observable } from 'knockout';

// Unfortunately, TalentCommunityTileStaticViewModel can't inherit TalentCommunityTileGeneralViewModel,
// because it contains this.url = absoluteRouter.interpolate(...). It can't be used inside editor,
// because appConfig.siteLang is not defined and it throws an error

// TODO: add inheritance from TalentCommunityTileViewModel, once appConfig.siteLang problem is solved in the editor
export default class TalentCommunityTileStaticViewModel {
    url: string;
    className: string;
    labels: {
        header: string;
        description: string;
        button: string;
    };

    constructor({ className }: { className: string }) {
        this.url = '';
        this.className = `talent-community-tile--${className}`;

        const site = store.site as unknown as {
            tcSignUp: {
                title: Observable<string>;
                description: Observable<string>;
                buttonLabel: Observable<string>;
            };
        };

        this.labels = {
            header: site.tcSignUp.title(),
            description: site.tcSignUp.description(),
            button: site.tcSignUp.buttonLabel(),
        };
    }

    openSignUpComponent(): void {
        return undefined;
    }
}
