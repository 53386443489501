import { RestParam } from 'cx/module/custom-content/mapper/mapParam';

type TYPOGRAPHY_FIELDS_TYPES =
    | 'fontFamily'
    | 'fontSize'
    | 'fontWeight'
    | 'fontStyle'
    | 'color'
    | 'textAlign'
    | 'lineHeight';

type TYPOGRAPHY_FIELDS = {
    fontFamily?: string;
    fontSize?: string;
    fontWeight?: string;
    fontStyle?: string;
    color?: string;
    textAlign?: string;
    lineHeight?: string;
};

type ELEMENTS_FOR_MAP = {
    oldParam: string;
    newParam: string;
    values: TYPOGRAPHY_FIELDS_TYPES[];
}[];

const elementsForMapping: ELEMENTS_FOR_MAP = [
    {
        oldParam: 'subHeaderTypography',
        newParam: 'jobInfoLabelTypography',
        values: ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'color', 'textAlign', 'lineHeight'],
    },
    {
        oldParam: 'subHeaderTypographyOnHover',
        newParam: 'jobInfoLabelTypographyOnHover',
        values: ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'color', 'textAlign', 'lineHeight'],
    },
    {
        oldParam: 'subHeaderTypography',
        newParam: 'jobInfoValueTypography',
        values: ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'color', 'textAlign', 'lineHeight'],
    },
    {
        oldParam: 'subHeaderTypographyOnHover',
        newParam: 'jobInfoValueTypographyOnHover',
        values: ['fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'color', 'textAlign', 'lineHeight'],
    },
    {
        oldParam: 'subHeaderTypography',
        newParam: 'jobTagsTypography',
        values: ['color', 'fontFamily'],
    },
    {
        oldParam: 'subHeaderTypographyOnHover',
        newParam: 'jobTagsTypographyOnHover',
        values: ['color'],
    },
    {
        oldParam: 'headerTypography',
        newParam: 'alreadyAppliedJobTag',
        values: ['color'],
    },
    {
        oldParam: 'headerTypographyOnHover',
        newParam: 'alreadyAppliedJobTagOnHover',
        values: ['color'],
    },
];

export const mapClassicCustomizationParamsToNewCustomizationParams = (params: RestParam[]): void => {
    elementsForMapping.map((element) => {
        const findParam = params.find((param) => param.paramKey == element.newParam);
        const findOldParams = params.find(
            (param: RestParam) => param.paramKey == element.oldParam
        )?.paramValue;
        const mappedCustomizationOldParams = findOldParams ? JSON.parse(findOldParams) : undefined;

        if (!findParam) {
            const paramValue: TYPOGRAPHY_FIELDS = {};

            element.values.map((field) => {
                if (mappedCustomizationOldParams && mappedCustomizationOldParams[field]) {
                    paramValue[field] = mappedCustomizationOldParams[field];
                }
            });

            params.push({ paramKey: element.newParam, paramValue: JSON.stringify(paramValue) });
        } else {
            params.map((param: RestParam) => {
                if (param.paramKey == element.newParam) {
                    const parsedParams = param.paramValue ? JSON.parse(param.paramValue) : undefined;

                    element.values.map((field: TYPOGRAPHY_FIELDS_TYPES) => {
                        if (
                            !parsedParams[field] &&
                            mappedCustomizationOldParams &&
                            mappedCustomizationOldParams[field]
                        ) {
                            parsedParams[field] = mappedCustomizationOldParams[field];
                        }
                    });

                    param.paramValue = JSON.stringify(parsedParams);
                }
            });
        }
    });
};
