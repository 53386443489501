import router from 'app/model/router';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { search as searchService } from 'minimal/module/search/service/search';
import { getPageComponentByType } from 'cx/module/custom-content/service/getPageComponents';
import userTracking from 'cx/service/userTracking';
import Page from 'app/module/cx/module/custom-content/model/Page';
import Route from 'app/module/core/router/Route';
import { SearchRoute, SEARCH_RESULTS_STRINGS } from 'app/module/cx/module/search/config/types';
import signedCandidate from 'app/module/cx/module/candidate-verification/model/signedCandidate';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import {
    getSearchContextDetails,
    mergeQueries,
    removeCurrentSearchContextStorageDetails,
} from '../../service/storeSearchContextDetails';
import { Subscription } from 'knockout';
import * as spellCheckService from 'minimal/module/search/service/spellCheck';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';
import { getDefaultSortOption } from 'search/service/defaultSortBy';
import { getEventsDefaultSortOption } from 'search/service/eventsDefaultSortBy';
import { areRecommendedJobsViewEnabled } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { RouterQuery as RecommendedJobsRouterQuery } from 'minimal/component/recommended-jobs-widget/config/types';

type RouterQuery = Record<string, unknown>;
type RouterParams = {
    query: RouterQuery;
};

type Props = {
    page: Page;
};

export default class CustomSearchViewModel {
    page: Page;
    latestQuery: RouterQuery | null;
    previousRouteId: SearchRoute | null;
    routeSub: Subscription;
    beforeRouteParamsChangedSub: Subscription;
    routeParamsSub: Subscription;
    recordsLimit: number | null;
    talentCommunityTilePosition: number | null;
    isSearchSpecificToContext: boolean;
    searchPageHeading: string;

    constructor({ page }: Props) {
        this.page = page;
        this.latestQuery = null;
        this.previousRouteId = null;
        this.recordsLimit = null;
        this.talentCommunityTilePosition = null;
        this.routeSub = router.route.subscribe(this.beforeRouteChanged, this, 'beforeChange');
        this.searchPageHeading = router._pageTitle._resolvePageTitle();

        this.beforeRouteParamsChangedSub = router.routeParams.subscribe(
            this.beforeRouteParamsChanged,
            this,
            'beforeChange'
        );

        this.routeParamsSub = router.routeParams.subscribe(this.onRouteParamsChanged, this);
        this.isSearchSpecificToContext = false;

        removeCurrentSearchContextStorageDetails();

        this.initializeCustomSearch();

        this.performSearch();
    }

    private beforeRouteParamsChanged(latestRouteParams: RouterParams): void {
        this.latestQuery = latestRouteParams.query;
    }

    private beforeRouteChanged(latestRoute: Route): void {
        this.previousRouteId = latestRoute.id;
    }

    onRouteParamsChanged(newRouteParams: Record<string, any>): void {
        const latestQuery = JSON.stringify(this.latestQuery);
        const newQuery = JSON.stringify(newRouteParams.query);
        const isSearchKeywordChanged = this.latestQuery?.keyword !== newRouteParams?.query?.keyword;

        if (spellCheckService.isSpellCheckEnabled() && isSearchKeywordChanged) {
            spellCheckService.removeCorrectedKeyword();
        }

        if (latestQuery !== newQuery) {
            this.isSearchSpecificToContext = this.checkIsSearchSpecificToContext(
                this.latestQuery,
                newRouteParams.query as RouterQuery
            );

            this.performSearch();
        }
    }

    checkIsSearchSpecificToContext(oldQuery: RouterQuery | null, newQuery: RouterQuery): boolean {
        //TODO - will be removed after getting support from new recommended Jobs API
        if (areRecommendedJobsViewEnabled(oldQuery as RecommendedJobsRouterQuery)) {
            return true;
        }

        const oldQueryBuilder = new SearchQueryBuilder(oldQuery);
        const newQueryBuilder = new SearchQueryBuilder(newQuery);

        const isFilterCriteriaUpdated =
            oldQueryBuilder.lastSelectedFacet !== newQueryBuilder.lastSelectedFacet ||
            oldQueryBuilder.lastSelectedFacetValue !== newQueryBuilder.lastSelectedFacetValue;

        const isSortCriteriaUpdated = oldQueryBuilder.sortBy !== newQueryBuilder.sortBy;

        return isFilterCriteriaUpdated || isSortCriteriaUpdated;
    }

    initializeCustomSearch(): void {
        const searchResultsComponent = getPageComponentByType(this.page.sections, 'cc-search-results');

        this.recordsLimit =
            parseInt(searchResultsComponent?.params.contentParams.numberOfJobsDisplayed(), 10) || null;

        this.talentCommunityTilePosition =
            parseInt(searchResultsComponent?.params.contentParams.displayAfterJobs(), 10) || null;

        searchService.setTalentCommunityTilePosition(this.talentCommunityTilePosition);
    }

    getQueryBuilder(query: RouterQuery): SearchQueryBuilder {
        return new SearchQueryBuilder({
            ...query,
            limit: this.recordsLimit,
        });
    }

    performSearch(): void {
        let { query } = router.routeParams() as RouterParams;

        if (this.isSearchSpecificToContext) {
            switch (router.route().id) {
                case SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS:
                    this.searchEvents(query);
                    break;
                default:
                    this.searchJobs(query);
                    break;
            }

            return;
        }

        this.searchJobs(query);

        if (
            areRecommendedJobsEnabled() &&
            areRecommendedJobsViewEnabled(query as RecommendedJobsRouterQuery)
        ) {
            query = getSearchContextDetails(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS)?.query;
        }

        areEventsEnabled() && this.searchEvents(query);
    }

    searchEvents(query: RouterQuery): void {
        const eventsQuery = mergeQueries(
            getSearchContextDetails(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS)?.query,
            router.route().id === SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS
                ? query
                : this.removeSearchSpecificQueryParams(query)
        );

        const eventsQueryBuilder = this.getQueryBuilder(eventsQuery);
        const { isSignedIn } = signedCandidate;
        const areEventEnabled = areEventsEnabled();

        eventsQueryBuilder.setDefaultSortOption(getEventsDefaultSortOption(eventsQuery));

        searchService.searchEvents(eventsQueryBuilder.build(), Boolean(isSignedIn()), areEventEnabled);
    }

    searchJobs(query: RouterQuery): void {
        const jobsQuery = mergeQueries(
            getSearchContextDetails(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS)?.query,
            router.route().id === SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_JOBS
                ? query
                : this.removeSearchSpecificQueryParams(query)
        );
        const jobsQueryBuilder = this.getQueryBuilder(jobsQuery);
        const { isSignedIn } = signedCandidate;

        jobsQueryBuilder.setDefaultSortOption(getDefaultSortOption(jobsQuery));

        searchService.searchJobs(jobsQueryBuilder.build(), Boolean(isSignedIn())).then(() => {
            userTracking.trackRequisitionSearch(jobsQuery);
        });
    }

    removeSearchSpecificQueryParams(query: RouterQuery): RouterQuery {
        const routerQueryBuilder = new SearchQueryBuilder(query);

        return routerQueryBuilder.withoutFacets().withoutSortOption().build() as RouterQuery;
    }

    dispose(): void {
        this.beforeRouteParamsChangedSub.dispose();
        this.routeParamsSub.dispose();
        this.routeSub.dispose();
    }
}
