import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import employeeConfig from 'app/model/employeeConfig';
import { areRecommendedJobsViewEnabled } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';
import { RouterParams } from 'minimal/component/recommended-jobs-widget/config/types';
import { hasUserResumeInfoInStorage } from 'cx/service/uploadedResumeInfoProvider';

const jobDetailsView = {
    main: 'search',
    dialogs: 'job-details',
} as const;

router.configure({
    search: {
        parent: 'minimal',
        url: '/requisitions:?query:',
        title: i18n('search.page-title'),
        view: {
            main: 'search',
            dialogs: null,
        },
        canEnter(newRouteParams: RouterParams) {
            const isInRecommendedJobsView = areRecommendedJobsViewEnabled(newRouteParams?.query);

            const isRecommendedJobsViewAllowed = areRecommendedJobsEnabled() && hasUserResumeInfoInStorage();

            return isInRecommendedJobsView && !isRecommendedJobsViewAllowed
                ? Promise.reject({ redirectTo: 'search', redirectParams: { query: {} } })
                : Promise.resolve();
        },
    },
    'search-events': {
        parent: 'minimal',
        url: '/events:?query:',
        title: i18n('search.events-page-title'),
        view: {
            main: 'search',
            dialogs: null,
        },
    },
    'job-details': {
        parent: 'minimal',
        url: '/job/{jobId}:?query:',
        view: {
            main: 'job-details-fullscreen',
            dialogs: null,
        },
    },
    'job-preview': {
        parent: 'search',
        url: '/preview/{jobId}',
        title: i18n('search.page-title'),
        view: jobDetailsView,
        isNotNewScreen: true,
    },
    'job-full-view': {
        parent: 'search',
        url: '/job/{jobId}',
        view: {
            main: 'job-details-fullscreen',
            dialogs: null,
        },
    },
    'job-formatting-preview': {
        parent: 'minimal',
        url: '/admin/job-preview/{jobId}',
        view: jobDetailsView,

        canEnter() {
            return employeeConfig.init();
        },

        enter() {
            document.body.classList.add('admin-job-preview');
        },
    },
    'job-template-preview': {
        parent: 'minimal',
        url: '/admin/job-template-preview/{jobId}',
        view: jobDetailsView,

        canEnter() {
            return employeeConfig.init();
        },

        enter() {
            document.body.classList.add('admin-job-preview');
        },
    },
});
