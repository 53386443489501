import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { SearchResultsCustomizationParams, SearchResultsCustomizationParamsKey } from '../config/types';
import { emptySearchResultsParams } from '../config/defaultStyles';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapContentParams } from './mapContentParams';
import { mapClassicCustomizationParamsToNewCustomizationParams } from '../migration/customizationParams';

// TODO: reuse mapCustomComponentParamsFromRest
export const mapParamsFromRest = (params: RestParam[]): SearchResultsCustomizationParams => {
    if (!params.find((param) => param.paramKey === 'jobInfoLabelTypography')) {
        mapClassicCustomizationParamsToNewCustomizationParams(params);
    }

    return params.reduce<SearchResultsCustomizationParams>(
        (searchResultsParams, { paramKey, paramValue }) => {
            const searchResultParamKey: SearchResultsCustomizationParamsKey | 'type' = paramKey as
                | SearchResultsCustomizationParamsKey
                | 'type';

            switch (searchResultParamKey) {
                case 'contentParams':
                    return {
                        ...searchResultsParams,
                        [paramKey]: mapContentParams(paramValue),
                    };

                case 'commonParams':
                    return {
                        ...searchResultsParams,
                        [paramKey]: mapCommonParams(paramValue),
                    };
                case 'headerTypography':
                case 'headerTypographyOnHover':
                case 'jobInfoValueTypography':
                case 'jobInfoValueTypographyOnHover':
                case 'jobInfoLabelTypography':
                case 'jobInfoLabelTypographyOnHover':
                case 'subHeaderTypography':
                case 'subHeaderTypographyOnHover':
                case 'descriptionTypography':
                case 'descriptionTypographyOnHover':
                case 'descriptionTitleTypography':
                case 'descriptionTitleTypographyOnHover':
                case 'sortByTypography':
                case 'sortOptionsTypography':
                case 'sortOptionsTypographyOnHover':
                case 'selectedSortValueTypography':
                case 'selectedSortValueTypographyOnHover':
                case 'selectedSortOptionTypography':
                case 'jobTagsTypography':
                case 'jobTagsTypographyOnHover':
                case 'alreadyAppliedJobTag':
                case 'alreadyAppliedJobTagOnHover':
                case 'applyButton':
                case 'applyButtonOnHover':
                    return {
                        ...searchResultsParams,
                        [paramKey]: mapTypography(paramValue),
                    };

                case 'container':
                case 'tileStyles':
                case 'tileStylesOnHover':
                case 'sortByStyling':
                case 'sortByHoverStyling':
                case 'sortByDropdownStyling':
                case 'tileHeaderStyles':
                case 'tileHeaderStylesOnHover':
                case 'jobIconsStyles':
                case 'jobIconsStylesOnHover':
                case 'alreadyAppliedTagBox':
                case 'defaultToggleIconStyles':
                case 'defaultToggleIconStylesOnHover':
                case 'selectedToggleIconStyles':
                case 'selectedToggleIconStylesOnHover':
                case 'alreadyAppliedTagBoxOnHover':
                case 'jobInfoIconsStyles':
                case 'jobInfoIconsStylesOnHover':
                case 'applyButtonBox':
                case 'applyButtonBoxContainer':
                case 'applyButtonBoxOnHover':
                    return {
                        ...searchResultsParams,
                        [paramKey]: mapStylingBox(paramValue),
                    };

                case 'type':
                    return {
                        ...searchResultsParams,
                        [paramKey]: paramValue,
                    };

                default:
                    return notReachable(searchResultParamKey);
            }
        },
        { ...emptySearchResultsParams }
    );
};
