import http from 'core/http/http';
import applicationMapper from 'apply-flow/mapper/application';
import referralService from 'candidate-verification/service/referral';
import flowService from 'apply-flow/service/flow';
import hiringApplicationsService from './hiringApplications';
import pipelineApplicationsService from './pipelineApplications';
import statusService from './status';
import unverifiedService from 'candidate-verification/service/unverified';
import { ATTACHMENT_UPLOAD_ERROR } from '../module/file-upload/config/attachmentErrorCodes';
import { applicationDraft } from './applicationDraftInstance';
import jobService from '../../job-details/service/job';
import userEvents, { APPLY_FLOW_APPLICATION_CREATED } from 'cx/config/userEvents';
import { httpGetAllItems } from 'cx/service/http/httpGetAllItems';
import { isApplyFlowRoute } from 'candidate-verification/service/routes';
import { sortDescendingByDate } from 'apply-flow/service/sortDescendingByDate';

export const JOB_NOT_AVAILABLE = 'ORA_JOB_NOT_AVAILABLE';
export const INVALID_ESIGNATURE = 'ORA_IRC_ESIGN_INVALID';
export const DUPLICATE_SUBMISSION = 'ORA_IRC_DUPLICATE_SUBMISSION';

const JOB_BY_REQUISITION_NUMBER_URL = '/recruitingCEJobApplications?finder=findByRequisitionNumber;RequisitionNumber=:requisitionNumber:';

const KNOWN_ERROR_CODES = [
    JOB_NOT_AVAILABLE,
    INVALID_ESIGNATURE,
    ATTACHMENT_UPLOAD_ERROR,
    DUPLICATE_SUBMISSION,
];

export default {
    create(application, jobId, candidateModel) {
        const referralHitCountResponse = referralService.getHitCountResponse(jobId);
        const flow = flowService.get();
        const isTCFlow = flow.flowTypeCode === 'ORA_TALENT';

        if (isApplyFlowRoute() && isTCFlow) {
            throw new Error('Flow data corrupt');
        }

        if (referralHitCountResponse) {
            application.referralId(referralHitCountResponse.referralId);
            application.shareId(referralHitCountResponse.shareId);
        }

        application.jobId(jobId);
        application.candidateNumber(candidateModel.id());

        application.flowVersionId(flow.flowVersionId);
        application.legalDescriptionVersionId(flow.legalDescriptionVersionId);
        application.esignDescriptionVersionId(flow.esignDescriptionVersionId);

        return application;
    },

    getAll() {
        return httpGetAllItems(http.get, '/recruitingCEJobApplications?expand=:expand:&limit=:limit:&offset=:offset:&orderBy=:orderBy:&onlyData=true', {
            params: {
                expand: [
                    'secondarySubmissions',
                    'ScheduleInterviews',
                    'UnscheduledInterviewRequests',
                    'BackgroundCheckLinks',
                    'assessmentLinks',
                ].join(','),
                orderBy: 'SubmissionDate:desc',
                limit: 499,
                offset: 0,
            },
        })
            .then(applicationMapper.mapCollectionFromRest.bind(applicationMapper))
            .then(applications => hiringApplicationsService.groupHiringApplications(applications))
            .then(applications => pipelineApplicationsService.calculatePipelineApplicationsStatuses(applications))
            .then(applications => statusService.fillEmptyStatus(applications))
            .then(applications => applications.sort((a, b) =>
                sortDescendingByDate(a.submissionDateOriginal(), b.submissionDateOriginal())));
    },

    getFullJob(jobId) {
        return http.get(`${JOB_BY_REQUISITION_NUMBER_URL}&expand=:expand:&onlyData=true&limit=500`, {
            params: {
                requisitionNumber: jobId,
                expand: [
                    'secondarySubmissions',
                    'ScheduleInterviews',
                    'UnscheduledInterviewRequests',
                ].join(','),
            },
        }).then(applicationMapper.mapCollectionFromRest.bind(applicationMapper)).then(appList => appList[0]);
    },

    getForJob(jobId) {
        return http.get(`${JOB_BY_REQUISITION_NUMBER_URL}&onlyData=true&limit=500`, {
            params: {
                requisitionNumber: jobId,
            },
        }).then(applicationMapper.mapCollectionFromRest.bind(applicationMapper));
    },

    getSubmission(submissionId) {
        return http.get('/recruitingCEJobApplications?finder=findBySubmissionId;SubmissionId=:submissionId:&onlyData=true&limit=500', {
            params: {
                submissionId,
            },
        }).then(applicationMapper.mapSubmission.bind(applicationMapper));
    },

    _save(application) {
        return http
            .post('/recruitingCEJobApplications', application, {
                statusCodes: {
                    400({ response }) {
                        const knownErrorCode = KNOWN_ERROR_CODES.find(errorCode => response.includes(errorCode));

                        return Promise.reject(knownErrorCode);
                    },
                },
            })
            .then((applicationResponse) => {
                userEvents[APPLY_FLOW_APPLICATION_CREATED].dispatch({
                    submissionId: applicationResponse.submissionId,
                });

                return applicationResponse;
            })
            .then(applicationMapper.mapApplicationAssessmentAndRemainingSubmissionsFromRest)
            .then((response) => {
                referralService.destroyHitCountResponse(application.requisitionNumber);
                unverifiedService.setRemainingUnverifiedApplications(response.remainingSubmissions);

                return response;
            });
    },

    savePrimary(applicationModel, candidate) {
        const application = applicationMapper.mapCreateToRest({
            applicationModel,
            applicationDraftId: applicationDraft.id,
            attachments: candidate.attachments(),
            questionnaires: candidate.questionnaires(),
            regulatoryResponses: candidate.regulatoryResponses(),
        });

        return this._save(application);
    },

    saveSecondary(applicationModel, candidate) {
        const application = applicationMapper.mapSecondaryToRest({
            applicationModel,
            applicationDraftId: applicationDraft.id,
            attachments: candidate.attachments(),
            questionnaires: candidate.questionnaires(),
            regulatoryResponses: candidate.regulatoryResponses(),
        });

        return this._save(application);
    },

    accept(application) {
        return http
            .post('/recruitingCEJobApplications', applicationMapper.mapAcceptToRest(application), {
                statusCodes: {
                    400({ response }) {
                        return Promise.reject(response);
                    },
                },
            })
            .then(applicationMapper.mapFromRest.bind(applicationMapper));
    },

    acceptAndCheckAssessment(application) {
        return http.post('/recruitingCEJobApplications', applicationMapper.mapAcceptToRest(application))
            .then(applicationMapper.mapApplicationAssessmentFromRest);
    },

    remove(application) {
        return http.post('/recruitingCEJobApplications', applicationMapper.mapRejectToRest(application));
    },

    setRequisitionDetails(applications, jobs) {
        for (const job of jobs) {
            const applicationData = applications?.find(application => job.id === application.jobId());
            const secondaryLocations = job.secondaryLocations.map(item => item.name);

            if (applicationData) {
                applicationData.jobSecondaryLocations(secondaryLocations);
                applicationData.workplaceType(job.workplaceType);
                applicationData.workplaceTypeCode(job.workplaceTypeCode);
            }
        }
    },

    getSecondaryLocations(applications, jobId) {
        const applicationData = applications.find(application => application.jobId() === jobId);

        return applicationData?.jobSecondaryLocations();
    },

    async getAppliedJobs(applications) {
        const appliedJobIds = this.getApplicationsJobsIds(applications);

        const jobs = await jobService.getJobs(appliedJobIds)
            .catch(console.error);

        return jobs.items;
    },

    getLastPostedApplicationReqId(applications, appliedJobs) {
        let lastApplicationRequisitionId = 0;

        applications.find(({ jobId }) => appliedJobs.find(({ id, requisitionId }) => {
            if (id === jobId()) {
                lastApplicationRequisitionId = requisitionId;

                return true;
            }

            return false;
        }));

        return lastApplicationRequisitionId;
    },

    getApplicationsJobsIds(applications) {
        return applications.map(({ jobId }) => jobId());
    },

    queryCandidateApplications() {
        return http.get('/recruitingCEJobApplications?onlyData=true&fields=:fields:&orderBy=:orderBy:&limit=499&finder=findConfirmedEligibleApplications;',
            {
                params: {
                    fields: 'RequisitionNumber,ConfirmedFlag',
                    orderBy: 'SubmissionDate:desc',
                },
            })
            .then(applicationMapper.mapSubmissionsFromRest)
            .catch((error) => {
                console.error(error);

                return Promise.reject([]);
            });
    },
    queryCandidateEventsRegistrations() {
        return http.get('/recruitingCEEventRegistrations?onlyData=true&fields=:fields:;',
            {
                params: {
                    fields: 'EventNumber,ConfirmedFlag,RegistrationPhase,Disqualification',
                },
            })
            .then(applicationMapper.mapEventRegistrationsFromRest)
            .catch((error) => {
                console.error(error);

                return Promise.reject([]);
            });
    },
};