import { SearchService } from './search';

export default class AiJobsSearchServiceAbstract extends SearchService {

    searchJobs(query, requisitionId, contentLocaleLang) {
        return this._searchJobs(query, requisitionId, contentLocaleLang);
    }

    loadMoreJobs(query, requisitionId) {
        return this._loadMoreJobs(query, requisitionId);
    }

    _insertAdditionalRequisitions(requisitionList) {
        return requisitionList;
    }

}