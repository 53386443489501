import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            const form = formBuilder.createForm(config);

            form.elements(this._createNameFormElement());

            return form;
        },
    },

    _createNameFormElement: {
        value() {
            return formElementFactory.create('text', 'fullName')
                .label(i18n('offer.e-signature.name-label'))
                .attributes('autocomplete', 'off')
                .validators('required', {})
                .validators('maxLength', {
                    max: 240,
                });
        },
    },
});