import ko from 'knockout';
import jobService from 'job-details/service/job';
import applicationService from 'apply-flow/service/application';
import submissionService from 'candidate-verification/service/submission';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';

export default class UnconfirmedApplicationViewModel {

    constructor() {
        this.application = ko.observable();
        this.jobInfo = ko.observable();
    }

    find(jobId) {
        return submissionService.getUnconfirmedForJob(jobId)
            .then(application => this.application(application))
            .then(() => this._fetchUnconfirmedApplicationDetails());
    }

    _fetchUnconfirmedApplicationDetails() {
        const unconfirmedApplication = this.application();

        if (!unconfirmedApplication) {
            return null;
        }

        return jobService.getJob(unconfirmedApplication.jobId())
            .then((job) => {
                const jobInfo = [job.title, job.primaryLocation].join(', ');

                this.jobInfo(jobInfo);
            });
    }

    confirm() {
        return applicationService.accept(this.application())
            .then(cachedCandidateApplications.clear.bind(cachedCandidateApplications));
    }

    remove() {
        return applicationService.remove(this.application());
    }

}