import { observableArray, pureComputed } from 'knockout';
import router from 'app/model/router';

const SECTION_START_NUMBER = 1;

export default class FlowIterator {

    constructor(sections) {
        this.sections = observableArray(sections);

        this.currentSectionNumber = pureComputed(() => String(router.routeParams().sectionNumber || 1));
        this.currentSection = pureComputed(() => sections[this.currentSectionNumber() - 1]);

        this.isFirstSection = pureComputed(() => this.currentSectionNumber() <= SECTION_START_NUMBER);

        this.lastSectionNumber = sections.length;
        this.isLastSection = pureComputed(() => this.currentSectionNumber() >= this.lastSectionNumber);
        this.skipToContainer = pureComputed(() => `[data-section-number="${this.currentSectionNumber()}"]`);

        this.nextStepSectionNumber = pureComputed(() => {
            if (!this.isLastSection()) {
                return parseInt(this.currentSectionNumber(), 10) + 1;
            }

            return this.currentSectionNumber();
        });

        this.previousStepSectionNumber = pureComputed(() => {
            if (!this.isFirstSection()) {
                return parseInt(this.currentSectionNumber(), 10) - 1;
            }

            return this.currentSectionNumber();
        });
    }

    isSingleClick() {
        return false;
    }

    runBeforeExit() {
        this.currentSection().exit();
    }

    async runBeforeValidation() {
        let sectionNumber = SECTION_START_NUMBER;

        const sectionsBeforeValidations = [];

        while (sectionNumber <= parseInt(this.currentSectionNumber(), 10)) {
            const section = this.sections()[sectionNumber - 1];

            sectionsBeforeValidations.push(section.beforeValidation.bind(section));

            sectionNumber++;
        }

        return await Promise.all(
            sectionsBeforeValidations.map(async beforeValidation =>
                await beforeValidation()));
    }

    runBlockValidation() {
        this.currentSection().validationBlock();
    }

}