import { ObservableParams } from 'app/module/minimal/module/search/service/customCss';
import { SiteList } from '../config/types';

export const setApplicationsSiteNames = <
    T extends ObservableParams<{ siteName: string; siteNumber: string }>
>(
    applications: T[],
    siteList: SiteList
): T[] =>
    applications.map((application) => {
        application.siteName(siteList[application.siteNumber()]?.siteName);

        return application;
    });

export const setDraftApplicationsSiteProps = <T extends { siteNumber: string }>(
    drafts: T[],
    siteList: SiteList
): T[] =>
    drafts.map((draft) => ({
        ...draft,
        siteName: siteList[draft.siteNumber]?.siteName,
        siteUrlName: siteList[draft.siteNumber]?.siteUrlName,
    }));
