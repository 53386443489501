import i18n from 'core/i18n/i18n';

export const KILOMETERS = 'KM';
export const MILES = 'MI';

export const RADIUS_UNITS = {
    [KILOMETERS]: i18n('location-bar.dropdown.radius-unit.switch-to-miles'),
    [MILES]: i18n('location-bar.dropdown.radius-unit.switch-to-kilometers'),
};

export const translations = {
    [KILOMETERS]: i18n('location-bar.dropdown.radius-unit.km'),
    [MILES]: i18n('location-bar.dropdown.radius-unit.mi'),
};

export const RADIUS_UNITS_FULL_NAMES = {
    [KILOMETERS]: i18n('location-bar.dropdown.radius-unit.kilometers'),
    [MILES]: i18n('location-bar.dropdown.radius-unit.miles'),
};

export const RADIUS_OPTIONS = {
    [KILOMETERS]: [
        { label: `0 ${translations[KILOMETERS]}`, value: 0 },
        { label: `5 ${translations[KILOMETERS]}`, value: 5 },
        { label: `10 ${translations[KILOMETERS]}`, value: 10 },
        { label: `25 ${translations[KILOMETERS]}`, value: 25 },
        { label: `50 ${translations[KILOMETERS]}`, value: 50 },
    ],
    [MILES]: [
        { label: `0 ${translations[MILES]}`, value: 0 },
        { label: `5 ${translations[MILES]}`, value: 5 },
        { label: `10 ${translations[MILES]}`, value: 10 },
        { label: `25 ${translations[MILES]}`, value: 25 },
        { label: `50 ${translations[MILES]}`, value: 50 },
    ],
};