import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import ResendTokenViewModel from 'candidate-verification/component/token-verification/ResendTokenViewModel';
import notificationsService from 'cx/service/notifications';
import cxEvents from 'cx/config/events';
import i18n from 'core/i18n/i18n';
import { storeShortTokenData } from '../../service/shortTokenStorage';
import { redirectToApplyFlow } from 'candidate-verification/service/routes';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';

const DELETE_DRAFT_MESSAGE = i18n('apply-flow.drafts.deleted-message');

export default class TokenVerificationViewModel extends ResendTokenViewModel {

    constructor() {
        super();

        this.checkJobAvailability()
            .then(this._performOperation.bind(this))
            .catch(this._handleError)
            .then(() => cxEvents.loaded.dispatch());
    }

    async _checkSubmission() {
        let pinCodeSent;

        try {
            pinCodeSent = await tokenService.verifyTokenWithChallengeFlag(this.token)
                .then(response => response?.challengeFlag);

            if (!pinCodeSent) {
                redirectToApplyFlow();

                return;
            }
        } catch (error) {
            if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
                pinCodeSent = false;
            } else {
                pinCodeSent = true;
                console.error(error);
            }
        }

        storeShortTokenData({ token: this.token, jobId: this.jobId, pinCodeSent });
        router.go('job-details.pin-verification');
    }

    _deleteDraft() {
        return tokenService.verifyToken(this.token)
            .then(() => notificationsService.success(DELETE_DRAFT_MESSAGE));
    }

    _handleError(error) {
        switch (error) {
            case 'job-expired':
                router.go('job-details');
                break;
            case 'token-expired':
                notificationsService.error(i18n('general.error-token-expired.header'));
                break;
            default:
                notificationsService.error();
                console.error(error);
        }
    }

    _markAsNotMe() {
        return tokenService.verifyToken(this.token)
            .then(() => notificationsService.success(this.notMeMessage));
    }

    _performOperation() {
        switch (this.routeId) {
            case 'submission-not-me':
                return this._markAsNotMe();
            case 'delete-draft':
                return this._deleteDraft();
            case 'existing-candidate-verification':
                return this._checkSubmission();
            default:
                return Promise.resolve();
        }
    }

}