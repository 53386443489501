function parseHeaders(headersString) {
    return headersString.split('\n').reduce((headersObj, headerString) => {
        const index = headerString.indexOf(':');
        const key = headerString.substr(0, index).toLowerCase().trim();
        const value = headerString.substr(index + 1).trim();

        const headers = headersObj;

        headers[key] = value;

        return headers;
    }, {});
}

function createRequest(config) {
    const xhr = new XMLHttpRequest();

    xhr.open(config.method, config.url, true);
    xhr.responseType = config.responseType || '';

    Object.keys(config.headers).forEach((header) => {
        xhr.setRequestHeader(header, config.headers[header]);
    });

    xhr.onload = () => {
        const { status } = xhr;
        let response = ('response' in xhr ? xhr.response : xhr.responseText);

        if ((status >= 200 && status < 300) || status === 304) {
            const headers = parseHeaders(xhr.getAllResponseHeaders());

            if (headers['content-length'] !== '0'
                && headers['content-type']
                && headers['content-type'].match(/^application\/.*json/)
            ) {
                response = JSON.parse(response);
            }

            config.success(response);
        } else {
            config.failure({
                statusCode: xhr.status,
                response,
            });
        }
    };

    xhr.onerror = xhr.onabort = () => {
        config.failure({
            statusCode: xhr.status,
        });
    };

    xhr.send(config.body);

    setTimeout(() => {
        xhr.abort();
    }, config.timeout);
}


export default {
    request(requestConfig) {
        return new Promise((resolve, reject) => {
            const config = requestConfig;

            config.success = resolve;
            config.failure = reject;

            createRequest(config);
        });
    },
};