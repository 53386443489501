import ko from 'knockout';
import router from 'app/model/router';
import UnconfirmedApplicationViewModel from 'apply-flow/UnconfirmedApplicationViewModel';
import unverifiedService from 'candidate-verification/service/unverified';
import signedCandidate from 'candidate-verification/model/signedCandidate';

export default class ExistingSubmissionViewModel {

    constructor() {
        const isSignedIn = signedCandidate.isSignedIn();
        const { sourceContext } = router.routeParams();

        this.routeContext = sourceContext;

        if (isSignedIn) {
            unverifiedService.destroy();
        }

        this.template = ko.observable();
        this.unconfirmedApplication = new UnconfirmedApplicationViewModel();

        this.isConfirmSubmissionInProgress = ko.observable(false);
        this.confirmSubmission = () => this._confirm();
        this.removeSubmission = () => this._remove();
        this.resolveRoute = () => this._resolveRoute();

        this._checkSubmissions()
            .then(() => this._resolveTemplate());
    }

    _confirm() {
        this.isConfirmSubmissionInProgress(true);

        this.unconfirmedApplication.confirm()
            .then(() => this.template('confirmed-submission'))
            .catch(() => this._handleError())
            .then(() => this.isConfirmSubmissionInProgress(false));
    }

    _remove() {
        this.unconfirmedApplication.remove()
            .then(() => this.template('removed-submission'))
            .catch(() => this._handleError());
    }

    _checkSubmissions() {
        const routeParams = router.routeParams();

        return this.unconfirmedApplication.find(routeParams.jobId);
    }

    _resolveTemplate() {
        if (this.unconfirmedApplication.application() && !unverifiedService.isKnownUnverifiedCandidate()) {
            this.template('unconfirmed-submission');
        } else {
            this.template('already-applied');
        }
    }

    _resolveRoute() {
        return this.routeContext || 'search';
    }

    _handleError() {
        this.template(null);
    }

}