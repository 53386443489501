import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import {
    CookieConsentModalMessageCustomizationParams,
    CookieConsentModalMessageParams,
} from '../config/types';
import { CookieConsentMessageCustomizationSelectorsKey } from '../config/selectors';

export const getCustomStylesCookieMessage = (
    key: keyof CookieConsentModalMessageCustomizationParams,
    selectors: Record<CookieConsentMessageCustomizationSelectorsKey, string>,
    customizationParams: CookieConsentModalMessageParams
): string => {
    switch (key) {
        case 'cookiePreferenceLinkHoverTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferenceLinkHoverTypography,
                typography: customizationParams[key],
            });
        case 'cookiePreferenceLinkTypography':
            return getTypographyCss({
                selector: selectors.cookiePreferenceLinkTypography,
                typography: customizationParams[key],
            });
        case 'cookieConsentMessageTypography':
            return getTypographyCss({
                selector: selectors.cookieConsentMessageTypography,
                typography: customizationParams[key],
            });
        case 'overAllStyling':
            return getStylingBoxCss({
                selector: selectors.overAllStyling,
                stylingBox: customizationParams[key],
                lightIconSelector: null,
                darkIconSelector: null,
            });
        default:
            return '';
    }
};
