export function mapHeaderModeToRest(customHeader) {
    if (!customHeader) {
        return 1;
    }

    return 4;
}

export function mapFooterModeToRest(templateFooter, customFooter) {
    if (!templateFooter && !customFooter) {
        return 0;
    } else if (templateFooter && !customFooter) {
        return 1;
    } else if (!templateFooter && customFooter) {
        return 2;
    }

    return 4;
}

export function mapThemeSettingsToConfig(settings) {
    return {
        PARALLAX_ENABLED: settings.parallaxEnabled ? 'true' : 'false',
    };
}