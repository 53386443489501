import ko from 'knockout';

ko.bindingHandlers.switchFilterLayout = {
    init(element, valueAccessor) {
        const { popupFiltersOpened } = valueAccessor();

        const SCREEN_MEDIUM_RANGE = 768;

        const resizeHandler = (): void => {
            if (popupFiltersOpened() && window.innerWidth >= SCREEN_MEDIUM_RANGE) {
                popupFiltersOpened(false);
            }
        };

        window.addEventListener('resize', resizeHandler);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            window.removeEventListener('resize', resizeHandler);
        });
    },
};
