import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import dictionaryService from 'apply-flow/service/dictionary';
import geolocation from 'cx/service/geolocation/geolocation';
import { ojTranslations } from 'cx/service/ojTranslations';

class SearchCountriesViewModel {

    constructor({ country }) {
        this.country = country;
        this.options = observable([]);
        this.isDisabled = observable(true);

        this.placeholder = i18n('location-bar.country-placeholder');
        this.value = country;
        this.ojTranslations = ojTranslations;

        this.getOptions()
            .then((options) => {
                this.options(options);
            })
            .then(country() ? Promise.reject : geolocation.query)
            .then(({ countryCode }) => {
                this.country(countryCode);
                this.isDisabled(false);
            })
            .catch(() => {
                this.isDisabled(false);
            });
    }

    getOptions() {
        return dictionaryService.get(dictionaryService.CODES.countries)
            .then(countries => countries.map(country => ({
                label: country.territoryShortName, value: country.territoryCode,
            })));
    }

}

export default SearchCountriesViewModel;
