import { unwrap } from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class UniqueFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.values-should-differ';
    }

    _validate(val, options) {
        const value = unwrap(val);

        if (!value) {
            return true;
        }

        return this._validateByProperty(value, options);
    }

    _validateByProperty(value, options) {
        const { propertyName } = options;

        const inArray = unwrap(options.inArray);
        const allPropertyValues = inArray.map(item => unwrap(item[propertyName]));

        return this._isUniqueInArray(value, allPropertyValues);
    }

    _isUniqueInArray(value, array) {
        return array.indexOf(value) === array.lastIndexOf(value);
    }

}
