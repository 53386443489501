import appConfig from 'app/model/config';
import { LI_CONNECTIONS_SETTING_KEY } from 'app/service/globalSettings';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';

export default class LinkedInConnectionsWidgetViewModel extends ElementViewModel {

    constructor({ params, mode }) {
        super({ params });

        this.linkedInConnectionsEnabled = appConfig.getSettingByKey(LI_CONNECTIONS_SETTING_KEY).isEnabled;

        this.isAdminMode = mode === 'admin';
    }

}
