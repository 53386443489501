import { CommonParams } from 'minimal/module/search/service/customCss';

export const EMPTY_COMMON_PARAMS: CommonParams = {
    cssClass: null,
    cssId: null,
    additionalOptionsUnit: 'px',
    margin: null,
    marginTop: null,
    marginBottom: null,
    marginLeft: null,
    marginRight: null,
    padding: null,
    paddingTop: null,
    paddingBottom: null,
    paddingLeft: null,
    paddingRight: null,
};
