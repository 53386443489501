import { components } from 'knockout';

import '../component/text/component';
import '../component/location/component';
import '../component/static-url/component';
import '../component/email';

import ViewModel from 'job-details/module/job-meta-item/JobMetaItemViewModel';
import template from '../job-meta-item.html';

components.register('job-meta-item', { viewModel: ViewModel, template });
