import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import { Subscription } from 'knockout';
import { SignalBinding } from 'signals';
import screenInfo from 'cx/model/screenInfo';
import {
    CustomHeaderModel,
    HeaderOption,
} from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import themeEvents from 'cx/module/site-editor/config/events';
import { mapGlobalHeaderFromAppConfig, getHeaderStyle } from './service/mapCustomHeaders';
import { LogoImages } from './config/types';
import headerEvents from 'minimal/config/events';
import router from 'app/model/router';
import Route from 'app/module/core/router/Route';
import {
    EXCLUDE_ROUTES_LIST,
    CUSTOM_PAGE_ROUTER_ID,
} from 'site-editor/module/theme-editor/module/global-header-editor/config/constants';
import customContentService from 'cx/module/custom-content/service/customContent';
import cxEvents from 'cx/config/events';
import { SEARCH_PAGE } from 'admin/module/site-editor/enum/pageTypes';

const HEADER_OPTION_HORIZONTAL = 'horizontal';

export const DEFAULT_CLASS_ID = 'default';

export class AppHeaderViewModel {
    isHorizontalMenu: PureComputed<boolean>;
    globalHeaderSettingsSubscription: SignalBinding<CustomHeaderModel>;
    globalHeaderSettings: CustomHeaderModel;
    selectedHeaderOption: Observable<HeaderOption | undefined>;
    logoImages: LogoImages;
    customCss: Observable<string>;
    uniqueWrapperClass: PureComputed<string>;
    headerEventsSubscription: SignalBinding<number>;
    pageLoadSubscription: SignalBinding<undefined>;
    routerSubscription: Subscription;
    headerId: Observable<number | null>;
    isHeaderLoaded: Observable<boolean>;
    isEventRoute: boolean;

    constructor() {
        this.headerId = observable(null);
        this.globalHeaderSettings = mapGlobalHeaderFromAppConfig();
        this.selectedHeaderOption = observable(this.globalHeaderSettings.headerType);

        this.isHorizontalMenu = pureComputed(
            () => screenInfo.isLargeUp() && this.selectedHeaderOption() === HEADER_OPTION_HORIZONTAL
        );

        this.uniqueWrapperClass = pureComputed(
            () => `component-styling-wrapper-${this.headerId() ?? DEFAULT_CLASS_ID}`
        );

        const { images, styles } = getHeaderStyle(this.headerId(), this.uniqueWrapperClass());

        this.customCss = observable(styles);
        this.logoImages = images;

        let pageId = router.route().id;

        this.isHeaderLoaded = observable<boolean>(Boolean(!CUSTOM_PAGE_ROUTER_ID.includes(pageId)));

        if (EXCLUDE_ROUTES_LIST.includes(pageId)) {
            pageId = 'search';
        }

        this.isEventRoute = false;

        this.routerSubscription = router.route.subscribe((route: Route) => {
            if (!EXCLUDE_ROUTES_LIST.includes(route.id) && pageId !== route.id && !this.isEventRoute) {
                this.isHeaderLoaded(false);

                customContentService.setPageMeta(route.id);

                headerEvents.headerUpdated.dispatch(null, null);

                pageId = route.id;
            }

            if (EXCLUDE_ROUTES_LIST.includes(route.id)) {
                pageId = 'search';
            }

            this.isEventRoute = pageId === 'home-page' && EXCLUDE_ROUTES_LIST.includes(route.id);
        });

        this.headerEventsSubscription = headerEvents.headerUpdated.add(this.handleHeaderUpdate, this);

        this.globalHeaderSettingsSubscription = themeEvents.customHeaderSettingsUpdated.add(
            this.handleThemeSettingsUpdate.bind(this)
        );

        this.pageLoadSubscription = cxEvents.isHeaderLoaded.add(() => this.isHeaderLoaded(true));
    }

    handleHeaderUpdate(updatedHeaderId: number | null, pageType: string | null): void {
        const headerNotChanged = this.headerId() === updatedHeaderId;
        const isExcludeRoute = EXCLUDE_ROUTES_LIST.includes(router.route().id) && pageType !== SEARCH_PAGE;
        const isCustomPage = CUSTOM_PAGE_ROUTER_ID.includes(router.route().id);

        if ((isCustomPage || isExcludeRoute) && (headerNotChanged || isExcludeRoute)) {
            return;
        }

        this.headerId(updatedHeaderId);

        const { images, styles } = getHeaderStyle(this.headerId(), this.uniqueWrapperClass());

        this.customCss(styles);
        this.logoImages = images;

        if (!isCustomPage) {
            this.isHeaderLoaded(true);
        }

        themeEvents.logoImageUrl.dispatch(
            this.logoImages.logoImageUrl,
            'app-header',
            this.headerId(),
            this.globalHeaderSettings
        );

        themeEvents.mobileLogoImageUrl.dispatch(
            this.logoImages.mobileLogoImageUrl,
            'app-header',
            this.headerId(),
            this.globalHeaderSettings
        );
    }

    handleThemeSettingsUpdate(globalHeaderSettings: CustomHeaderModel, logoImages: LogoImages): void {
        this.globalHeaderSettings = globalHeaderSettings;

        if (this.headerId()) {
            const { images } = getHeaderStyle(this.headerId(), this.uniqueWrapperClass());

            this.logoImages = images;
        } else {
            this.logoImages = logoImages;
        }

        this.selectedHeaderOption(this.globalHeaderSettings.headerType);
    }

    dispose(): void {
        this.globalHeaderSettingsSubscription.detach();

        this.headerEventsSubscription.detach();

        this.routerSubscription.dispose();

        this.pageLoadSubscription.detach();
    }
}
