let defaultBlocks = {};

function getDefaultBlocks({ blockProperties }) {
    const { sectionId, contentTypeId, preferredFlag } = blockProperties;

    if (contentTypeId && Array.isArray(contentTypeId)) {
        const [firstSectionId, secondSectionId] = sectionId;
        const [firstContentTypeId, secondContentTypeId] = contentTypeId;
        const [firstPreferredFlag, secondPreferredFlag] = preferredFlag;

        if (firstPreferredFlag) {
            defaultBlocks[firstContentTypeId] = firstSectionId;
        }

        if (secondPreferredFlag) {
            defaultBlocks[secondContentTypeId] = secondSectionId;
        }

        return;
    }

    if (preferredFlag) {
        defaultBlocks[contentTypeId] = sectionId;
    }
}

function getFlattenBlocks(flow) {
    return flow.sections
        .reduce((pages, section) => pages.concat(section.pages), [])
        .reduce((blocks, page) => blocks.concat(page.blocks), [])
        .filter(({ blockProperties }) => blockProperties.sectionId);
}

export default {
    find(flow) {
        defaultBlocks = {};

        const blocks = getFlattenBlocks(flow);

        blocks.forEach(getDefaultBlocks);
    },

    get(contentTypeId) {
        return defaultBlocks[contentTypeId];
    },
};
