import unverifiedService from 'cx/module/candidate-verification/service/unverified';
import tokenService from 'cx/module/candidate-verification/service/token';

export const processRemainingUnverifiedApplications = (): void => {
    if (unverifiedService.hasRemainingUnverifiedApplications()) {
        return;
    }

    unverifiedService.clear();
    unverifiedService.setHasExceededUnverifiedApplicationsLimit(true);

    tokenService.destroy();
};
