import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class MaxSelectionFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);
        this._message = 'validators.max-selection';

        ko.utils.extend(this._options, {
            maxSelection: this._options.maxSelection,
        });
    }

    _validate(value, options) {
        if (value) {
            return options.maxSelection >= value.length;
        }

        return true;
    }

}