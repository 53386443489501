import challengeService from '../../service/challenge';
import { ATTEMPS_LIMIT_REACHED } from '../../config/pinVerificationStatus';

export default class ChallengeLayoutViewModel {

    constructor() {
        this.challenge = challengeService.getCurrentChallenge();
        this.verificationStates = { ATTEMPS_LIMIT_REACHED };
    }

}
