import storageService from 'core/storage/sessionStorage';
import { decryptMessage } from 'core/storage/encryption/encryption';
import { SkillKeys, WorkExperienceKeys } from 'minimal/component/recommended-jobs-widget/config/types';
import countryCodesService from 'cx/service/phone/phoneCountryCodes';

type FileAttachmentData = {
    CategoryName: string;
    Title: string;
    FileContents: string;
    datatypeCode?: string;
};

export type ResumeInfo = {
    email: string;
    firstName: string;
    lastName: string;
    displayName: string;
    city: string;
    country: string;
    mobilePhoneCountryCode: string;
    mobilePhoneNumber: string;
    mobilePhoneLegislationCode: string;
    mobilePhoneAreaCode: string;
    workExperiences: Record<WorkExperienceKeys, string | number | null>[];
    skills: Record<SkillKeys, string | number | null>[];
    fileAttachmentData: FileAttachmentData | undefined;
};

type ContactInformation = {
    email: string;
    phoneDetails: PhoneDetails | undefined;
};

type PhoneDetails = {
    type: string;
    countryCode: string;
    legislationCode: string;
    areaCode: string;
    number: string;
};

export type ProfileImportSelection = 'profile_data' | 'resume_data';

export const STORAGE_RECOMMENDED_JOBS_METADATA = 'storage_recommended_jobs_metadata';

export const STORAGE_ENCKEY_DETAILS = 'enc_details';

export const STORAGE_PROFILE_IMPORT_SELECTION = 'profile_import_selection';

export const STORAGE_PROFILE_IMPORT_POPUP_CLICKED = 'interacted_with_popup';

let resumeFileInformation: FileAttachmentData | undefined = undefined;

export const hasUserResumeInfoInStorage = (): boolean =>
    storageService.exists(STORAGE_RECOMMENDED_JOBS_METADATA) && storageService.exists(STORAGE_ENCKEY_DETAILS);

export const getResumeInfoFromStorage = async (
    isFileContentRequired = false
): Promise<ResumeInfo | undefined> => {
    if (!hasUserResumeInfoInStorage()) {
        return undefined;
    }

    const keyDetails = storageService.restore(STORAGE_ENCKEY_DETAILS);
    const cipherText = storageService.restore(STORAGE_RECOMMENDED_JOBS_METADATA);

    const { k, i } = JSON.parse(atob(keyDetails));

    const decryptedInfo = await decryptMessage(cipherText, k, i);

    const resumeInfo: ResumeInfo = JSON.parse(decryptedInfo);

    if (isFileContentRequired) {
        resumeInfo.fileAttachmentData = resumeFileInformation;
    }

    return resumeInfo;
};

const getUserPhoneDetails = async (resumeInfo: ResumeInfo): Promise<PhoneDetails | undefined> => {
    let { mobilePhoneCountryCode, mobilePhoneLegislationCode } = resumeInfo;

    if (!resumeInfo.mobilePhoneNumber) {
        return undefined;
    }

    if (!mobilePhoneCountryCode) {
        mobilePhoneCountryCode = await countryCodesService.getPhoneCountryCodeByTerritoryCode(
            resumeInfo.country
        );
    }

    if (!mobilePhoneLegislationCode) {
        mobilePhoneLegislationCode = await countryCodesService.getTerritoryCodeByPhoneCountryCode(
            false,
            mobilePhoneCountryCode
        );
    }

    const phoneDetails = {
        type: 'mobile',
        countryCode: mobilePhoneCountryCode,
        legislationCode: mobilePhoneLegislationCode,
        areaCode: resumeInfo.mobilePhoneAreaCode,
        number: resumeInfo.mobilePhoneNumber,
    };

    return phoneDetails;
};

export const getUserContactInfoFromStorage = async (): Promise<ContactInformation | undefined> => {
    const uploadedResumeMetadata = await getResumeInfoFromStorage();

    if (!uploadedResumeMetadata) {
        return undefined;
    }

    const phoneDetails = await getUserPhoneDetails(uploadedResumeMetadata);

    return {
        email: uploadedResumeMetadata.email,
        phoneDetails,
    };
};

export const setIsProfileImportPopUpClicked = (isPopupClicked: boolean): void => {
    storageService.store(STORAGE_PROFILE_IMPORT_POPUP_CLICKED, isPopupClicked);
};

export const getIsProfileImportPopUpClicked = (): boolean => {
    const isPopupClicked = storageService.exists(STORAGE_PROFILE_IMPORT_POPUP_CLICKED);

    if (!isPopupClicked) {
        return false;
    }

    return storageService.restore(STORAGE_PROFILE_IMPORT_POPUP_CLICKED);
};

export const setUserProfileImportSelection = (profileImportSelection: ProfileImportSelection): void => {
    storageService.store(STORAGE_PROFILE_IMPORT_SELECTION, profileImportSelection);
};

export const getUserProfileImportSelection = (): ProfileImportSelection | undefined => {
    const isUserProfileImportSelectionExists = storageService.exists(STORAGE_PROFILE_IMPORT_SELECTION);

    if (!isUserProfileImportSelectionExists) {
        return undefined;
    }

    return storageService.restore(STORAGE_PROFILE_IMPORT_SELECTION);
};

export const setResumeFileInfo = (fileInfo: FileAttachmentData | undefined): void => {
    resumeFileInformation = fileInfo;
};

export const deleteResumeInfoFromStorage = (): void => {
    storageService.remove(STORAGE_RECOMMENDED_JOBS_METADATA);
    storageService.remove(STORAGE_ENCKEY_DETAILS);
    storageService.remove(STORAGE_PROFILE_IMPORT_SELECTION);
    storageService.remove(STORAGE_PROFILE_IMPORT_POPUP_CLICKED);
    setResumeFileInfo(undefined);
};

export const clearProfileImportSelection = (): void => {
    storageService.remove(STORAGE_PROFILE_IMPORT_SELECTION);
    storageService.remove(STORAGE_PROFILE_IMPORT_POPUP_CLICKED);
};
