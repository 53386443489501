import appConfig from 'app/model/config';

export const MAX_RETRY = 3;

const RETRY_ERROR_CODES = [
    'IRC_ROW_INCONSISTENT_EXCEPTION',
];

function isRetryEnabled() {
    return appConfig.getSettingByKey('ORA_IRC_CE_POST_RETRY_ENABLED') === 'true';
}

function isRetryableError({ response }) {
    if (!response) {
        return false;
    }

    return RETRY_ERROR_CODES.includes(response);
}

export default {
    shouldRetry(serverError) {
        return isRetryEnabled() && isRetryableError(serverError);
    },
};