import httpConfig from 'core/http/config';

const ERROR_CODES = {
    ORA_IRC_CAPTCHA_FAIL: 441,
    ORA_IRC_DUPLICATE_SUBMISSION: 409,
    ORA_IRC_INTERNAL_CANDIDATE: 442,
    ORA_IRC_CAND_EXISTS: 401,
    IRC_JOBOFR_NO: 404,
    IRC_JOBOFR_UNAVAIL: 404,
    IRC_ESIG_ERR: 403,
    ORA_IRC_ESIGN_INVALID: 403,
    JOBOFFER_REFUSED: 410,
};

function isServerError(error) {
    return error.statusCode === 500 || error.statusCode === 404;
}

function mapServerMsgToStatusCode(serverMsg) {
    let statusCode = null;

    Object.keys(ERROR_CODES).some((errorCode) => {
        if (serverMsg.indexOf(errorCode) > -1) {
            statusCode = ERROR_CODES[errorCode];

            return true;
        }

        return false;
    });

    return statusCode;
}

function convertServerError(serverError) {
    const error = serverError;
    const statusCode = error.response ? mapServerMsgToStatusCode(error.response) : null;

    if (statusCode) {
        error.statusCode = statusCode;
    }

    return error;
}

export default {
    manage(serverError, config) {
        let error = serverError;

        if (isServerError(error)) {
            error = convertServerError(error);
        }

        const code = error.statusCode;

        if (config.statusCodes && code in config.statusCodes) {
            const configErrorHandler = config.statusCodes[code];
            let result = configErrorHandler.call(null, error, config);

            if (!(result instanceof Promise)) {
                result = Promise.reject(result);
            }

            return result;
        }

        return httpConfig.defaultErrorHandler.call(null, error, config);
    },
};