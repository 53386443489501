import { CategoryTilesDisplayContent } from './types';
import i18n from 'core/i18n/i18n';

const tileTitle = i18n('category-tiles.admin.title-label');

export const STATIC_TILES_DATA: CategoryTilesDisplayContent[] = [
    {
        order: 0,
        jobFamilyIds: '2',
        title: tileTitle,
        url: '',
        tileImageUrl: '',
    },
    {
        jobFamilyIds: '3',
        order: 1,
        title: tileTitle,
        url: '',
        tileImageUrl: '',
    },
    {
        order: 2,
        title: tileTitle,
        url: '',
        tileImageUrl: '',
        jobFamilyIds: '4',
    },
    {
        order: 3,
        title: tileTitle,
        url: '',
        tileImageUrl: '',
        jobFamilyIds: '5',
    },
];
