import { observable } from 'knockout';
import cxConfig from 'app/model/config';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import i18n from 'core/i18n/i18n';
import { ojTranslations } from '../../service/ojTranslations';

const isThemePreview = Boolean(window.frameElement && window.frameElement.id === 'preview-frame');

export default class LanguageSelectViewModel {

    constructor({ headerOption }) {
        this.headerOption = headerOption;
        this.activeLang = observable(cxConfig.siteLang);
        this.options = cxConfig.languages;
        this.isDisabled = isThemePreview;
        this.ariaLabel = i18n('general.change-site-language');
        this.langSelectorAriaLabel = `${i18n('general.change-site-language')} ${this.getSelectedLanguageLabel()}`;
        this.ojTranslations = ojTranslations;
        this.isOpened = observable(false);

        this.optionsKeys = {
            value: 'code',
            label: 'label',
        };

        this.styleDropdownClass = this.getStyleDropdownClass();
    }

    changeLanguage(event) {
        if (event.detail?.selectedValue) {
            siteLanguage.set(event.detail?.selectedValue);
        } else {
            event.target.parentNode.classList.remove('language-select--opened');
            siteLanguage.set(event.detail.value);
        }
    }

    onClickHandler() {
        this.isOpened(!this.isOpened());
    }

    getStyleDropdownClass() {
        return `input-row__listbox language-select__listbox background-color-dropdown ${this.headerOption ?? ''}`;
    }

    getSelectedLanguageLabel() {
        return this.options.find(language => language.code === this.activeLang())?.label;
    }

}
