import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';
import linkGenerator from 'cx/service/linkGenerator';
import { CopyLinkProps } from './types';
import { unwrap } from 'knockout';
import generateUUID from 'core/utils/generateUUID';

export default class CopyLinkViewModel {
    private id: string | number;
    private lang: string;
    private hasInitialFocus: boolean;
    private link: string;
    private EVENTS = 'events';
    private htmlId: string;

    constructor(params: CopyLinkProps, lang: string) {
        this.id = unwrap(params.id);
        this.lang = params.context === this.EVENTS ? params.language : lang;
        this.hasInitialFocus = params.hasInitialFocus;
        this.htmlId = `copy-link-${generateUUID()}`;

        this.link = linkGenerator.generate(this.id, this.lang, params.context);
        this.afterCopy = this.afterCopy.bind(this);
    }

    afterCopy(): void {
        notificationsService.success(i18n('job-details.link-copied-label', null, this.lang), 0);
    }
}
