import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import a11yEvents from 'core/a11y/events';
import geolocationService from 'cx/service/geolocation/geolocation';
import candidateModel from 'apply-flow/model/candidate';
import GeoHierarchyService from 'apply-flow/module/personal-information-address/service/GeoHierarchy';
import MetadataService from 'apply-flow/module/personal-information-address/service/Metadata';
import CustomJsValidationBlockComponentViewModel from '../custom-js-validation/CustomJsValidationBlockComponentViewModel';
import { clearUnusedAddressFields } from './service/clearUnusedFields';
import GeoHierarchySearch from './form/element/GeoHierarchySearch';
import router from 'app/model/router';
import jobService from 'cx/module/job-details/service/job';

export default class PersonalInformationAddressViewModel extends CustomJsValidationBlockComponentViewModel {

    constructor(params) {
        super(params);

        this.formName = i18n('apply-flow.a11y.address-form-label');

        this._addressModel = candidateModel.address;
        this._metadataService = null;
        this._metadataServiceUrl = params.metadataServiceUrl;

        this.countryFormConfig = ko.observable();
        this.addressFormConfig = ko.observable();
        this.sectionDisabled = params.sectionDisabled;
        this.isCountrySelected = ko.pureComputed(() => Boolean(this._addressModel.country()));

        this._metadataService = new MetadataService(params.metadataServiceUrl);

        this._createForm()
            .then(this._subscribeToAddressChange.bind(this))
            .then(this._fetchGeoCountryCode.bind(this))
            .then(this._setPrefferedCountry.bind(this));

        this._initCustomJsValidation();
    }

    _subscribeToAddressChange() {
        this._countrySubscriber = this._addressModel.country.subscribe(() => {
            this._resetLovConnectedFields();
            this._createForm();
            this._notifyA11y();
        });
    }

    _fetchGeoCountryCode() {
        return geolocationService.query()
            .then((location) => {
                if (!this._addressModel.country() && location) {
                    this._addressModel.country(location.countryCode);
                }
            })
            .catch(console.error);
    }

    async _createForm() {
        this._setBlockUnready();

        await clearUnusedAddressFields(this._metadataService);

        return this._metadataService.getFormElements(this._addressModel.country())
            .then(this._composeFormConfigs.bind(this))
            .then(this._setBlockReady.bind(this))
            .catch(error => console.error(error));
    }

    async _composeFormConfigs(elements) {
        const geoHierarchyService = new GeoHierarchyService({
            serviceUrl: this._metadataServiceUrl,
            countryCode: this._addressModel.country(),
        });

        const [countryElement, ...addressElements] = elements;

        this.countryFormConfig({
            elements: [countryElement],
            geoHierarchyService,
        });

        this.addressFormConfig({
            elements: addressElements,
            geoHierarchyService,
        });
    }

    _notifyA11y() {
        a11yEvents.status.dispatch(i18n('apply-flow.a11y.form-loaded', {
            formName: this.formName,
        }));
    }

    _resetLovConnectedFields() {
        this.addressFormConfig().elements.forEach((element) => {
            if (element instanceof GeoHierarchySearch) {
                this._addressModel[element.name()]('');
            }
        });
    }

    async _setPrefferedCountry() {
        const { jobId } = router.routeParams();

        if (!jobId) {
            return;
        }

        const { country } = this._addressModel;

        if (country()) {
            return;
        }

        const jobDetails = await jobService.getLightJob(jobId)
            .catch(() => {});

        const preferredCountry = jobDetails?.primaryLocationCountry;

        if (!preferredCountry) {
            return;
        }

        country(preferredCountry);
    }

    dispose() {
        if (this._countrySubscriber) {
            this._countrySubscriber.dispose();
        }
    }

}