import { ATTEMPS_LIMIT_REACHED, WRONG_PIN, PHONE_BLOCKED, PIN_LIMIT_REACHED, TOO_MANY_SIGNIN } from '../config/pinVerificationStatus';
import {
    DUPLICATE_SUBMISSION, INTERNAL_CANDIDATE, URL_INVALID,
    INVALID_COOKIE, SIGNIN_LOCKED, NO_PROFILE, INVALID_PERSON_ID, INVALID_INPUT,
}
    from '../config/candidateVerificationStatus';
import router from 'app/model/router';
import { VERIFICATION_MODES, JOB_APPLICATION, TC_CONFIRM, TC_CANDIDATE } from '../config/verificationSubmodules';

function handleBadRequest(response, mode) {
    switch (response) {
        case DUPLICATE_SUBMISSION:
            return Promise.reject('already-applied');
        case INTERNAL_CANDIDATE:
            handleInternalCandidateError(mode);

            return Promise.reject(response);
        case ATTEMPS_LIMIT_REACHED:
        case PIN_LIMIT_REACHED:
        case PHONE_BLOCKED:
        case WRONG_PIN:
        case URL_INVALID:
        case INVALID_COOKIE:
        case SIGNIN_LOCKED:
        case NO_PROFILE:
        case INVALID_PERSON_ID:
        case TOO_MANY_SIGNIN:
            return Promise.reject(response);
        case INVALID_INPUT:
            return Promise.reject('token-expired');
        default:
            return Promise.reject('token-invalid');
    }
}

function handleBadRequestOnCreateToken(response, mode) {
    const responseCached = response;

    return handleBadRequest(response, mode)
        .catch((message) => {
            if (message === 'token-invalid' && responseCached) {
                return Promise.reject(responseCached);
            }

            return Promise.reject(message);
        });
}

function handleInternalCandidateError(mode) {
    let route;

    switch (mode) {
        case VERIFICATION_MODES[JOB_APPLICATION]:
            route = 'ice-job-details';
            break;
        case VERIFICATION_MODES[TC_CONFIRM]:
        case VERIFICATION_MODES[TC_CANDIDATE]:
            route = 'ice-job-alerts';
            break;
        default:
            route = 'ice-search';
            break;
    }

    router.go(route);
}


export default {
    handleBadRequest,
    handleBadRequestOnCreateToken,
};