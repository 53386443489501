import {
    PrimaryFacetName,
    PrimaryFacetType,
} from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { notReachable } from 'app/types/utils';

export const mapFacetNameToType = (searchFacet: PrimaryFacetName): PrimaryFacetType => {
    switch (searchFacet) {
        case 'locationsFacet': {
            return 'LOCATIONS';
        }

        case 'postingDatesFacet': {
            return 'POSTING_DATES';
        }

        case 'categoriesFacet': {
            return 'CATEGORIES';
        }

        case 'organizationsFacet': {
            return 'ORGANIZATIONS';
        }

        case 'workLocationsFacet': {
            return 'WORK_LOCATIONS';
        }

        case 'titlesFacet': {
            return 'TITLES';
        }

        case 'workplaceTypesFacet': {
            return 'WORKPLACE_TYPES';
        }

        default: {
            return notReachable(searchFacet);
        }
    }
};
