import ko from 'knockout';
import appConfig from 'app/model/config';

function resolveDirForLang(lang) {
    const [langInfo] = appConfig.languages.filter(language => language.code === lang);

    return langInfo ? langInfo.direction : 'ltr';
}

/**
 * Adds given lang value to the bindingContext so i18n can resolve translations in different language.
 *
 * @param {(ko.observable)} lang - lang value to push in context
 *
 * @example
 * <div data-bind="i18nLang: lang"></div>
 */
ko.bindingHandlers.i18nLang = {
    init(element, valueAccessor, allBindings, viewModel, bindingContext) {
        const value = ko.unwrap(valueAccessor());
        const direction = resolveDirForLang(value);

        element.setAttribute('dir', direction);

        const innerBindingContext = bindingContext.extend({
            $lang: value,
        });

        ko.applyBindingsToDescendants(innerBindingContext, element);

        return { controlsDescendantBindings: true };
    },
};