export const JOBS_TO_CANDIDATE_STUB = [
    {
        id: 'Recommended-Job-1',
        shortDescription: 'Recommended Job 1 Description',
        title: 'Recommended Job 1',
    },
    {
        id: 'Recommended-Job-2',
        shortDescription: 'Recommended Job 2 Description',
        title: 'Recommended Job 2',
    },
    {
        id: 'Recommended-Job-3',
        shortDescription: 'Recommended Job 3 Description',
        title: 'Recommended Job 3',
    },
    {
        id: 'Recommended-Job-4',
        shortDescription: 'Recommended Job 4 Description',
        title: 'Recommended Job 4',
    },
    {
        id: 'Recommended-Job-5',
        shortDescription: 'Recommended Job 5 Description',
        title: 'Recommended Job 5',
    },
    {
        id: 'Recommended-Job-6',
        shortDescription: 'Recommended Job 6 Description',
        title: 'Recommended Job 6',
    },
    {
        id: 'Recommended-Job-7',
        shortDescription: 'Recommended Job 7 Description',
        title: 'Recommended Job 7',
    },
    {
        id: 'Recommended-Job-8',
        shortDescription: 'Recommended Job 8 Description',
        title: 'Recommended Job 8',
    },
    {
        id: 'Recommended-Job-9',
        shortDescription: 'Recommended Job 9 Description',
        title: 'Recommended Job 9',
    },
    {
        id: 'Recommended-Job-10',
        shortDescription: 'Recommended Job 10 Description',
        title: 'Recommended Job 10',
    },
];

export const FACET_MAP = {
    categories: 'CATEGORIES',
    titles: 'TITLES',
    organizations: 'ORGANIZATIONS',
    postingDate: 'POSTING_DATES',
    workLocations: 'WORK_LOCATIONS',
    workplaceType: 'WORKPLACE_TYPES',
};
