import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import tileFormBuilder from 'apply-flow/module/tile-profile-items/form/tileFormBuilder';
import ExperienceFormTileStrategy from '../model/ExperienceFormTileStrategy';
import dictionaryService from 'apply-flow/service/dictionary';

export default Object.create(tileFormBuilder, {
    createForm: {
        value(config) {
            this._setSearchTypeForJobFamilyElement(config);
            this._convertCurrentJobToCheckbox(config);

            this._setUrlTypeForCompanyUrlElement(config);

            const form = tileFormBuilder.createForm(ExperienceFormTileStrategy, config);

            form.bindComponent('experience-form');
            form.legend(i18n('apply-flow.section-experience.add-experience-title'));

            this._addStartDateAndEndDateMonthPicker(form);
            this._disableEndDateWhenCurrentJobIsChecked(form);

            this._setFilterOptionsForJobFamilyElement(form.getElement('jobFamilyId'));

            return form;
        },
    },

    _setSearchTypeForJobFamilyElement: {
        value(config) {
            const element = ko.utils.arrayFirst(config.elements, formElement => formElement.name === 'jobFamilyId');

            if (element) {
                element.type = 'search';

                element.optionsKeys = {
                    value: 'jobFamilyId',
                    label: 'jobFamilyName',
                };
            }
        },
    },

    _setUrlTypeForCompanyUrlElement: {
        value(config) {
            const companyUrl = ko.utils.arrayFirst(config.elements, element => element.name === 'companyUrl');

            if (companyUrl) {
                companyUrl.type = 'url';
            }
        },
    },

    _convertCurrentJobToCheckbox: {
        value(config) {
            const element = ko.utils.arrayFirst(config.elements, formElement => formElement.name === 'currentJobFlag');

            if (element) {
                element.type = 'checkboxStringValue';
            }
        },
    },

    _addStartDateAndEndDateMonthPicker: {
        value(form) {
            const startDate = form.getElement('startDate');
            const endDate = form.getElement('endDate');

            if (startDate) {
                startDate.fixedDay = 'first';

                startDate.validators({
                    startDatePicker: true,
                    minDate: {
                        min: '1900-01-01',
                        inclusive: true,
                        msgDateFormat: 'MM/yyyy',
                    },
                });

                if (endDate) {
                    endDate.fixedDay = 'last';

                    endDate.validators({
                        afterDate: {
                            minDateFormElement: startDate,
                            pairValidationElement: startDate,
                            pairValidationValidator: 'beforeDate',
                        },
                        minDate: {
                            min: '1900-01-01',
                            inclusive: true,
                            msgDateFormat: 'MM/yyyy',
                        },
                    });

                    startDate.validators({
                        beforeDate: {
                            maxDateFormElement: endDate,
                            pairValidationElement: endDate,
                            pairValidationValidator: 'afterDate',
                        },
                    });
                }
            }
        },
    },

    _disableEndDateWhenCurrentJobIsChecked: {
        value(form) {
            const endDate = form.getElement('endDate');
            const currentJob = form.getElement('currentJobFlag');

            if (endDate && currentJob) {
                const adjustEndDate = (isCurrentFieldValue) => {
                    const isCurrent = isCurrentFieldValue === 'Y';

                    endDate.isDisabled(isCurrent);

                    if (endDate.attributes().required) {
                        endDate.getValidator('required').isDisabled(isCurrent);
                    }

                    if (isCurrent) {
                        endDate.value(null);
                    }
                };

                adjustEndDate(currentJob.value());
                currentJob.value.subscribe(adjustEndDate);
            }
        },
    },

    _setFilterOptionsForJobFamilyElement: {
        value(element) {
            element._filterOptions = query =>
                dictionaryService.findWithFinder({
                    code: element.dictionary(),
                    criteria: {
                        JobFamilyName: query,
                    },
                    finder: 'findByJobFamilyName',
                });
        },
    },
});