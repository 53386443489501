import { observable } from 'knockout';
import candidateSelfServiceEvents from 'cx/module/candidate-self-service/config/events';
import talentCommunityFlowService from 'cx/module/talent-community/service/talentCommunityFlow';
import talentCommunityEvents from 'cx/module/talent-community/config/events';
import i18n from 'core/i18n/i18n';
import tokenService from 'candidate-verification/service/token';

export default class CsUpdateProfileViewModel {

    constructor() {
        this.isUpdateDialogVisible = observable(false);
        this.isTalentCommunityFlowConfigured = observable(false);
        this.modalTitle = observable(i18n('candidate-self-service.edit-personal-information-button'));

        talentCommunityEvents.hide.add(this.hideUpdateDialog);

        this._checkTalentCommunityFlow();
    }

    _checkTalentCommunityFlow() {
        talentCommunityFlowService
            .query()
            .then(flow => this.isTalentCommunityFlowConfigured(Boolean(flow)));
    }

    onDialogClose = () => {
        if (tokenService.isKnownCandidate()) {
            candidateSelfServiceEvents.reloadUser.dispatch();
        }
    }

    showUpdateDialog = () => this.isUpdateDialogVisible(true);

    hideUpdateDialog = () => this.isUpdateDialogVisible(false);

    dispose() {
        talentCommunityEvents.hide.remove(this.hideUpdateDialog);
    }

}

