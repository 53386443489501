import http from 'core/http/http';
import {
    mapWithdrawEventParamsToRest,
    mapConfirmEventParamsToRest,
    filterOutDisqualifiedEventRegistrations,
    RestEvent,
    mapConfirmEventResponse,
} from '../mapper/eventRegistration';
import { Event, EventActionResponse, EventActionRestParams } from '../config/types';
import { mapEventsFromRest } from '../mapper/eventRegistration';
import siteListService from 'candidate-self-service/service/siteList';
import { setEventsSiteNames } from 'minimal/module/candidate-self-service/service/setEventsSiteNames';
import { sortEventsByAction } from 'minimal/module/candidate-self-service/service/sortEventsByAction';

const EVENTS_BASE_URL = '/recruitingCEEventRegistrations';

export const withdrawEvent = async (event: Event): Promise<void> => {
    return http.post(EVENTS_BASE_URL, mapWithdrawEventParamsToRest(event), {
        statusCodes: {
            400({ response }: { response: string }) {
                if (response === 'ORA_IRC_EVENT_WITHDRAW_FAIL') {
                    return {
                        message: 'withdraw-event-fail',
                    };
                }

                return Promise.reject();
            },
        },
    });
};

export const confirmEvent = async (event: Event): Promise<void> => {
    return http.post(EVENTS_BASE_URL, mapConfirmEventParamsToRest(event));
};

export const confirmEventAction = async (
    actionParams: EventActionRestParams
): Promise<EventActionResponse> => {
    return http
        .post(EVENTS_BASE_URL, actionParams, {
            statusCodes: {
                400({ response }: { response: string }) {
                    return Promise.reject(response);
                },
            },
        })
        .then((restEvent: RestEvent) => {
            return mapConfirmEventResponse(restEvent);
        });
};

export const getEvents = async (): Promise<Event[]> => {
    const result = await http.get(EVENTS_BASE_URL).catch(console.error);

    if (!result?.items?.length) {
        return Promise.resolve([]);
    }

    const events = mapEventsFromRest(filterOutDisqualifiedEventRegistrations(result.items));

    const siteList = await siteListService.getSiteList();

    setEventsSiteNames(events, siteList);
    sortEventsByAction(events);

    return events;
};
