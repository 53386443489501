import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class EqualsValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.equals';

        ko.utils.extend(this._options, {
            field_name: this._options.label,
        });
    }

    _validate(value, options) {
        if (value) {
            return options.source.toUpperCase().localeCompare(value.toUpperCase()) === 0;
        }

        return true;
    }

}