import router from 'app/model/router';
import tokenService from 'candidate-verification/service/token';
import ApplyFlowPinVerificationViewModel from 'cx/module/apply-flow/component/apply-flow-pin-verification/ApplyFlowPinVerificationViewModel';
import { URL_INVALID } from 'candidate-verification/config/candidateVerificationStatus';

export default class EventRegisterPinVerificationViewModel extends ApplyFlowPinVerificationViewModel {
    async onPinValid(): Promise<void> {
        // overridden to redirect to event specific route
        router.go('event-register-flow', { sectionNumber: '1' });
    }

    _handleError(error: string): void {
        // overridden to redirect to event specific route
        if (error === URL_INVALID) {
            tokenService.destroy();
            router.go('event-details.email-verification');

            return;
        }

        super._handleError(error);
    }
}
