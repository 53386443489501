import applicationsModel from '../../model/applications';
import applicationService, { JOB_NOT_AVAILABLE } from 'apply-flow/service/application';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import flowService from 'apply-flow/service/flow';
import partnersLinksService from 'apply-flow/service/partnersLinks';
import candidateTCSignup from '../../service/candidateTCSignup';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';
import userTracking from 'app/module/cx/service/userTracking';

export default class CsApplicationActionsViewModel {

    constructor({ application }) {
        this.application = application;
        this.confirm = this.confirm.bind(this);
        this.takeAssessment = this.takeAssessment.bind(this);
        this.takeTaxCredit = this.takeTaxCredit.bind(this);
        this.takeBackgroundCheck = this.takeBackgroundCheck.bind(this);
        this.redirectToPostApplyFlow = this.redirectToPostApplyFlow.bind(this);
        this.getSecondaryFlows = this.getSecondaryFlows.bind(this);
        this.getAssessments = this.getAssessments.bind(this);
        this.getBackgroundCheck = this.getBackgroundCheck.bind(this);
    }

    confirm() {
        this.application.isActionInProgress(true);

        applicationService.accept(this.application)
            .then((restApplication) => {
                applicationsModel.update(this.application, restApplication);
                cachedCandidateApplications.clear();

                notificationsService.success((this.application.hasAssessment()
                    || this.application.hasTaxCredit()) && this.application.isActive()
                    ? i18n('apply-flow.thank-you-dialog.additional-instructions-text')
                    : i18n('candidate-self-service.confirm-success-message'));

                return restApplication.jobId();
            })
            .then(jobId => applicationService.getFullJob(jobId))
            .then((application) => {
                applicationsModel.update(this.application, application);

                return candidateTCSignup.confirmTalentCommunity(application.candidateNumber());
            })
            .catch((err) => {
                this.application.isActionInProgress(false);

                if (err === JOB_NOT_AVAILABLE) {
                    notificationsService.error(i18n('candidate-self-service.job-unavailable-confirmation-error'));

                    return;
                }

                notificationsService.error();

                console.error(err);
            });
    }

    redirectToAction(urlResolve) {
        this.application.isActionInProgress(true);

        const windowReference = window.open('', '_blank');

        urlResolve.then((url) => {
            if (!url) {
                windowReference.close();

                throw Error('Missing partner link');
            }

            windowReference.location = url;
            this.application.isActionInProgress(false);
        })
            .catch((err) => {
                this.application.isActionInProgress(true);
                notificationsService.error(i18n('candidate-self-service.partner-link-error'));
                console.error(err);
                windowReference.close();
            });
    }

    takeTaxCredit() {
        userTracking.trackJobAppTaxCreditStart(this.application.jobId(),
            this.application.submissionId(), router.route().id);

        this.redirectToAction(partnersLinksService.getTaxCreditURL(this.application.jobId()));
    }

    takeAssessment(assessment) {
        userTracking.trackJobAppAssessmentStart(this.application.jobId(),
            this.application.submissionId(), router.route().id);

        this.redirectToAction(
            partnersLinksService.getAssessmentURL(this.application.jobId(), assessment.provisioningId()));
    }

    takeBackgroundCheck(bgCheck) {
        this.redirectToAction(
            partnersLinksService.getBackgroundCheckURL(this.application.jobId(), bgCheck.provisioningId()));
    }

    redirectToPostApplyFlow(submission) {
        flowService.setSecondaryFlowData(flowService
            .createFlowData(submission.secondaryFlowId(), submission.stepActionUsageId()));

        return router.go('post-apply', {
            sectionNumber: '1',
            submissionId: submission.submissionId(),
            jobId: submission.jobId(),
        });
    }

    getAssessments() {
        const assessments = [];

        if (this.application.assessmentLinks().length) {
            assessments.push(...this.application.assessmentLinks());
        }

        return assessments;
    }

    getBackgroundCheck() {
        const backgroundChecks = [];

        if (this.application.backgroundCheckLinks().length) {
            backgroundChecks.push(...this.application.backgroundCheckLinks());
        }

        return backgroundChecks;
    }

    getSecondaryFlows() {
        const postFlows = [];

        if (this.application.secondarySubmissions().length) {
            postFlows.push(...this._getSecondarySubmissions(this.application));
        }

        if (this.application.hiringJobApplications().length) {
            this.application.hiringJobApplications()
                .filter(childApplication => childApplication.secondarySubmissions().length)
                .forEach(application => postFlows.push(...this._getSecondarySubmissions(application)));
        }

        return postFlows;
    }

    _getSecondarySubmissions(application) {
        return application.secondarySubmissions();
    }

}