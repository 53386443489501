import FormElementViewModel from '../form-element/FormElementViewModel';

export default class FileFormElementViewModel extends FormElementViewModel {

    constructor(params) {
        super(params);

        this.labelClass = this.element.labelClass || '';
        this.isFocused = this.element.hasFocus || this.hasFocus;
        this.value = this.element.value;

        this.focusSubscription = this.isFocused.subscribe(this.onFocusChanged.bind(this));
    }

    onFocusChanged(isFocus) {
        const focusElement = document.querySelector(`#${this.element.id()}`);

        if (isFocus && focusElement) {
            focusElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    dispose() {
        this.focusSubscription.dispose();
    }

}
