import SearchFacetDropdownItemViewModel, { Props } from './SearchFacetDropdownItemViewModel';
import { noOperation } from 'core/utils/noOperation';

export default class SearchFacetDropdownItemStaticViewModel extends SearchFacetDropdownItemViewModel {
    constructor(props: Props) {
        super(props);

        this.setOption = noOperation;
    }
}
