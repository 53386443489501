import ScheduledInterview from '../model/ScheduledInterview';

export default {
    mapFromRest(value) {
        return new ScheduledInterview({
            interviewRequestId: value.intrvwRequestsId,
            interviewId: value.scheduleInterviewId,
            submissionId: value.submissionId,
            scheduleId: value.scheduleId,
            isScheduled: value.isScheduled,
            isReschedulable: value.isReschedulable === 'Y',
        });
    },
};