import ko from 'knockout';

export default class FormBuilderViewModel {

    constructor(params) {
        this.form = ko.unwrap(params.form);
    }

    dispose() {
        this.form.dispose();
    }

}