import pako from 'pako';
import Base64FileReader from 'core/base64-file-reader/Base64FileReader';

export const decodeCompressedString = async (base64String: string): Promise<Record<string, unknown>[]> => {
    if (!base64String) {
        return Promise.resolve([]);
    }

    const reader = new Base64FileReader();

    const decodedArrayBuffer = await reader.decode(base64String);

    const originalString = pako.inflate(decodedArrayBuffer, { to: 'string' });

    return JSON.parse(originalString);
};
