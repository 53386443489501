import { ObservableParams } from './paramsToObservable';
import { cloneObservableParams } from './cloneObservableParams';

export const mergeObservableParams = <Param>({
    sourceParams,
    targetParams,
    mergeParamsValue,
}: {
    sourceParams: ObservableParams<Param>;
    targetParams: ObservableParams<Param>;
    mergeParamsValue: (props: {
        paramKey: keyof Param;
        baseValue: Param[keyof Param];
        modifierValue: Param[keyof Param];
    }) => Param[keyof Param];
}): ObservableParams<Param> => {
    const mergedParams = cloneObservableParams(targetParams);

    (Object.keys(sourceParams) as (keyof Param)[]).forEach((paramKey) => {
        const baseValue = sourceParams[paramKey]();
        const modifierValue = targetParams[paramKey]();

        mergedParams[paramKey](mergeParamsValue({ paramKey, baseValue, modifierValue }));
    });

    return mergedParams;
};
