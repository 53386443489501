export const ABORTED_ERROR = 'ABORTED';

export function createAbortController() {
    const abortController = new window.AbortController();

    abortController.abort = abortController.abort.bind(abortController);

    return abortController;
}

export function isAborted(error) {
    return error instanceof DOMException && error.code === 20;
}
