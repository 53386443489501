import { observable } from 'knockout';
import csEvents from '../../config/events';
import draftApplicationsModel from '../../model/draftApplications';
import applicationDraftService from '../../../apply-flow/service/applicationDraft';

export default class ApplicationDraftDeleteDialogViewModel {

    constructor() {
        this.draft = null;

        this.isVisible = observable();
        this.isDeleteInProgress = observable();

        this._openDialogSignal = csEvents.openDeleteDraftDialog.add(this._showDialog.bind(this));
    }

    deleteDraft() {
        this.isDeleteInProgress(true);

        applicationDraftService.delete(this.draft.id)
            .then(() => draftApplicationsModel.remove(this.draft))
            .catch(error => console.error(error))
            .then(() => this.hideDialog());
    }

    hideDialog() {
        this.draft = null;
        this.isVisible(false);
        this.isDeleteInProgress(false);
    }

    _showDialog(draft) {
        this.draft = draft;
        this.isVisible(true);
    }

    dispose() {
        this._openDialogSignal.detach();
    }

}