import { observable, observableArray, pureComputed } from 'knockout';
import screenInfo from 'cx/model/screenInfo';
import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';
import sectionValidator from '../../../../model/sectionValidator';
import candidateModel from '../../../../model/candidate';
import PreuploadFileAttachment from 'apply-flow/module/file-upload/model/PreuploadFileAttachment';
import { applicationDraft } from '../../../../service/applicationDraftInstance';
import attachmentFactory from 'apply-flow/module/file-upload/service/attachmentFactory';
import applicationDraftService from '../../../../service/applicationDraft';
import draftAttachmentService from '../../service/draftAttachmentService';
import {
    VALIDATION_MESSAGE_VISIBILITY_TIME,
    SAVED_MESSAGE_VISIBILITY_TIME,
    STATES,
    ACTIONS,
    CONTAINER_CLASSES,
    MISC_FILES_LIMIT,
} from '../../config/attachmentUploadButton';
import fileUploadFormBuilder from '../../service/fileUploadFormBuilder';
import { MISCELLANEOUS } from '../../config/attachmentCategories';
import { ATTACHMENT_UPLOAD_ERROR, ATTACHMENT_CANCELLED, ORA_INVALID_FILE_TYPE, ORA_UNSUPPORTED_FILE_TYPE, ATTACHMENT_MIME_TYPE_ERROR } from '../../config/attachmentErrorCodes';
import appConfig from 'app/model/config';
import { getMiscAttachmentNotAllowedFileTypes } from 'apply-flow/module/file-upload/service/attachmentUnsupportedFileTypeService';

export default class AttachmentUploadButtonViewModel {

    constructor({ blockId, section, required }) {
        this.categoryId = MISCELLANEOUS;
        this.dropdownLabel = 'apply-flow.section-more-about-you.drop-your-attachment-here';
        this.attachments = candidateModel.attachments;
        this.isResumeButton = observable(false);
        this.blockId = blockId;
        this.isRequired = required;
        this.STATES = STATES;
        this.defaultStatus = observable(STATES.WAITING);
        this.status = observable(this.defaultStatus());
        this.validationErrors = observableArray().extend({ elementsLivingTime: VALIDATION_MESSAGE_VISIBILITY_TIME });
        this.containerClass = pureComputed(() => CONTAINER_CLASSES[this.status()]);
        this.isDragAndDropActive = screenInfo.isLargeUp;
        this.isAddFileButtonFocused = observable(false);
        this.ACTIONS = ACTIONS;
        this.pendingAction = observable();
        this.isRemoving = pureComputed(() => this.pendingAction() === ACTIONS.REMOVE);
        this._attachmentBeingUploaded = null;
        this.miscAttachmentAllowedTypes = appConfig.getSettingByKey('ORA_IRC_MISC_ATTACH_FILE_TYPES');

        this.miscAttachmentNotAllowedTypes = getMiscAttachmentNotAllowedFileTypes();
        this.fileElement = this.createFileFormElement();

        this.uploadingValidationEnabled = observable(false);
        section.addBeforeValidationCallback(() => this._enableUploadingValidation());

        this.isUploadingValidation = pureComputed(() => this._computeUploadingValidation());
        sectionValidator.addValidation(section.number, this.isUploadingValidation);

        this.requiredValidationEnabled = observable(false);
        section.addBeforeValidationCallback(() => this._enableRequiredValidation());

        this.isRequiredValidation = pureComputed(() => this._computeRequiredValidation());
        sectionValidator.addValidation(section.number, this.isRequiredValidation);

        if (this.isRequired) {
            sectionValidator.setHasRequiredElementExtraValidation(section.number, true);
        }

        this.attachmentChangedLabelVisible = observable(false);
    }

    handleDragOver(data, event) {
        event.preventDefault();
    }

    cancelUpload() {
        if (!this._attachmentBeingUploaded) {
            return;
        }

        this._attachmentBeingUploaded.isCancelled(true);
        this._attachmentBeingUploaded = null;

        this.status(this.defaultStatus());
        this._disableUploadingValidation();
    }

    createFileFormElement() {
        const fileElementConfig = this.getFileElementConfig();
        const form = fileUploadFormBuilder.createForm(fileElementConfig);

        return form.getElement(fileElementConfig.name);
    }

    getButtonLabel() {
        return 'apply-flow.section-more-about-you.upload-attachment';
    }

    getFileElementConfig() {
        return {
            name: 'attachment-upload',
            label: this.getButtonLabel(),
            onFileSelected: this.onFileSelected.bind(this),
            sizeLimit: 50,
            labelClass: pureComputed(() => {
                if (this.isDragAndDropActive()) {
                    return 'attachment-upload-button__label theme-color-1';
                }

                const classes = ['attachment-upload-button-mobile__label'];

                if (this.isRequired) {
                    classes.push('attachment-upload-button-mobile__label--required');
                }

                return classes.join(' ');
            }),
            hasFocus: observable(false),
            isRequired: this.isRequired,
            acceptedFileExtensions: this.miscAttachmentAllowedTypes,
            unsupportedFileExtensions: this.miscAttachmentNotAllowedTypes,
        };
    }

    handleDragEnter() {
        if (this.status() !== STATES.UPLOADING
            && !this.pendingAction()) {
            this.status(STATES.DRAGOVER);
        }
    }

    handleDragLeave() {
        if (this.status() !== STATES.UPLOADING) {
            this.status(this.defaultStatus());
        }
    }

    handleDrop(event) {
        if (this.pendingAction()) {
            return;
        }

        if (this.status() === STATES.UPLOADING) {
            this.cancelUpload();
        }

        event.stopPropagation();
        event.preventDefault();

        const { files } = event.dataTransfer;

        event.dataTransfer.dropEffect = 'copy';

        if (files && files.length > 0) {
            this.onFileSelected(files, event);
        }
    }

    _countBlockAttachments() {
        return this.attachments()
            .filter(attachment => this.blockId === attachment.blockId())
            .length;
    }

    async onFileSelected(data, event) {
        this._disableUploadingValidation();

        if (this.categoryId === MISCELLANEOUS
            && this._countBlockAttachments() >= MISC_FILES_LIMIT) {
            this.validationErrors.push(i18n('apply-flow.validation.file-upload-limit-error', { filesLimit: MISC_FILES_LIMIT }));

            return;
        }

        const [file] = event.target.files || event.dataTransfer.files;
        const isValid = await this.fileElement.validate(file);

        if (!isValid) {
            this.fileElement.value('');

            this.validationErrors.push(this.fileElement.getErrorMessage());
            this.status(this.defaultStatus());

            return;
        }

        file.categoryId = this.categoryId;
        file.blockId = this.blockId;

        this._addFile(file)
            .then(this._addAttachmentToModel.bind(this))
            .then(this._afterSaveAttachment.bind(this))
            .catch(this._handleError.bind(this));
    }

    async _saveDraftAttachment(attachment) {
        this._attachmentBeingUploaded = attachment;

        const draftAttachment = await draftAttachmentService.create(applicationDraft.id, attachment);

        this._attachmentBeingUploaded = null;

        if (attachment.isCancelled()) {
            draftAttachmentService.remove(applicationDraft.id, draftAttachment)
                .catch(console.error);

            return Promise.reject(ATTACHMENT_CANCELLED);
        }

        return draftAttachment;
    }

    async _addFile(file) {
        const preuploadFileAttachment = new PreuploadFileAttachment(file);
        const attachment = await attachmentFactory.create(preuploadFileAttachment, file);

        if (!applicationDraft) {
            return attachment;
        }

        this.status(STATES.UPLOADING);

        const draftAttachment = await this._saveDraftAttachment(attachment);

        return draftAttachment;
    }

    _addAttachmentToModel(attachment) {
        attachment.blockId(this.blockId);
        this.attachments.push(attachment);

        return applicationDraftService.save();
    }

    _afterSaveAttachment() {
        this.status(STATES.SAVED);

        setTimeout(() => {
            this.status(this.defaultStatus());
            this.fileElement.hasFocus(true);
        }, SAVED_MESSAGE_VISIBILITY_TIME);
    }

    _computeUploadingValidation() {
        const isValidationActive = this.uploadingValidationEnabled();
        const isValid = this.status() !== STATES.UPLOADING;

        return isValidationActive
            ? isValid
            : true;
    }

    _enableUploadingValidation() {
        return this.uploadingValidationEnabled(true);
    }

    _disableUploadingValidation() {
        return this.uploadingValidationEnabled(false);
    }

    _enableRequiredValidation() {
        return this.requiredValidationEnabled(true);
    }

    _computeRequiredValidation() {
        const isValidationActive = this.isRequired
            && this.requiredValidationEnabled()
            && this.status() !== STATES.UPLOADING;

        return isValidationActive
            ? this._attachmentExists()
            : true;
    }

    _attachmentExists() {
        const miscAttachments = this.attachments()
            .filter(({ blockId }) => blockId() === Number(this.blockId));

        return Boolean(miscAttachments.length);
    }

    _handleError(error) {
        if (error === ATTACHMENT_CANCELLED) {
            return;
        }

        this.status(this.defaultStatus());

        if (error === ORA_INVALID_FILE_TYPE) {
            this.validationErrors.push(i18n('validators.file-upload.invalid-file-type'));

            return;
        }

        if (error === ORA_UNSUPPORTED_FILE_TYPE) {
            this.validationErrors.push(i18n('validators.file-upload.unsupported-file-type'));

            return;
        }

        if (error === ATTACHMENT_MIME_TYPE_ERROR) {
            this.validationErrors.push(i18n('validators.file-upload.mime-type-error'));

            return;
        }

        if (error === ATTACHMENT_UPLOAD_ERROR) {
            this.validationErrors.push(i18n('apply-flow.validation.file-upload-errors'));

            return;
        }

        console.error(error);
        notificationsService.error();
    }

}