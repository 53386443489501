import { observable, pureComputed } from 'knockout';
import sectionValidator from 'apply-flow/model/sectionValidator';
import customJsValidationService from '../service/customJsValidation';

export class CustomJsValidation {

    constructor() {
        this.validator = null;
        this.message = observable('');
        this.valid = observable(true);

        this.showMessage = pureComputed(this.shouldShowMessage, this);
        this.run = this.run.bind(this);
    }

    init({ section, blockCode, identifier }) {
        this.validator = customJsValidationService.get(blockCode, identifier);

        if (this.validator) {
            sectionValidator.addValidation(section.number, this.valid);

            section.addBeforeValidationCallback(this.run);
        }
    }

    run() {
        if (!this.validator) {
            return;
        }

        const data = customJsValidationService.getCandidateData();
        const config = customJsValidationService.getConfig();

        try {
            const { valid = true, message = '' } = this.validator(data, config);

            this.valid(valid);
            this.message(message);
        } catch (error) {
            console.error(error);

            this.valid(true);
            this.message('');
        }
    }

    shouldShowMessage() {
        return Boolean(!this.valid() && this.message());
    }

    addToForm(form) {
        if (!this.validator) {
            return;
        }

        form.registerCustomJsValidation(this.run);
    }

}