import mapping from 'knockout-mapping';
import { pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';

export default class MultiSelectPillFormElementValue {

    constructor(data) {
        const model = {
            id: undefined,
            value: undefined,
            label: undefined,
        };

        this.removePillTitle = pureComputed(() => [i18n('general.remove-button'), this.label()].join(' '));

        if (data) {
            Object.assign(model, data);
        }

        mapping.fromJS(model, {}, this);
    }

}
