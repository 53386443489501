import http from 'core/http/http';
import suggestionMapper from 'cx/module/search/module/search-box/mapper/suggestion';

export default {

    getKeywordSuggestionsEvents(keyword, limit) {
        return http.get('/recruitingCEEventSearchAutoSuggestions?expand=all&onlyData=true&finder=findByKey;string=:keyword:&limit=:limit:', {
            params: {
                keyword,
                limit: limit || 20,
            },
        }).then(suggestionMapper.mapEventsKeywordSuggestionsFromRest.bind(suggestionMapper)).catch((error) => {
            console.error(error);

            return [];
        });
    },

    getKeywordSuggestions(keyword, limit) {
        return http.get('/recruitingCESearchAutoSuggestions?expand=all&onlyData=true&finder=findByKey;string=:keyword:&limit=:limit:', {
            params: {
                keyword,
                limit: limit || 20,
            },
        }).then(suggestionMapper.mapJobsKeywordSuggestionsFromRest.bind(suggestionMapper));
    },

    getLocationSuggestions(location, limit) {
        return http.get('/recruitingCESearchAutoSuggestions?expand=all&onlyData=true&finder=findByLoc;string=:location:&limit=:limit:', {
            params: {
                location: suggestionMapper.mapLocationParamToRest(location),
                limit: limit || 20,
            },
        }).then(suggestionMapper.mapLocationSuggestionsFromRest.bind(suggestionMapper));
    },

    getLocationById(locationId) {
        return http.get('/recruitingCESearchAutoSuggestions?expand=all&onlyData=true&finder=findByLocId;string=:locationId:', {
            params: { locationId },
        }).then(suggestionMapper.mapLocationSuggestionsFromRest.bind(suggestionMapper))
            .then(results => results.pop());
    },
};
