import storageService from 'core/storage/sessionStorage';

const TOKEN_KEY = 'legalDisclaimerAcceptance';

export function store(requisitionNumber) {
    storageService.store(TOKEN_KEY, requisitionNumber);
}

export function get(requisitionNumber) {
    const acceptedForRequisiton = storageService.restore(TOKEN_KEY) || {};

    return acceptedForRequisiton === requisitionNumber;
}

export function clear() {
    storageService.remove(TOKEN_KEY);
}