import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import DictionaryElementViewModel from 'core/form/component/form-element/DictionaryFormElementViewModel';

const AUTOSUGGEST_PLACEHOLDER = i18n('core.form.autosuggest.placeholder');

export default class AutosuggestFormElementViewModel extends DictionaryElementViewModel {

    constructor(...args) {
        super(...args);

        this.isEmpty = ko.pureComputed(() => {
            const options = this.element.options();

            return !(options && options.length);
        });

        this.defaultPlaceholder = AUTOSUGGEST_PLACEHOLDER;

        this.placeholder = ko.pureComputed(() => {
            const isEmpty = this.isEmpty();

            if (isEmpty) {
                // must be at least 1 whitespace to work
                return ' ';
            }

            return this.defaultPlaceholder;
        });
    }

}

