import ko from 'knockout';
import UrlFormElement from 'core/form/element/Url';

export default class SiteLinkFormElement extends UrlFormElement {

    constructor(...args) {
        super(...args);

        this.component('sitelink-form-element');
        this.isRemovable = ko.observable(true);
        this.siteLink = null;
    }

    registerModel(newValue) {
        this.siteLink = newValue;
        super.registerModel(newValue.url);
    }

}