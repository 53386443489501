const _values = {};

export default {
    exists(jobId) {
        return _values[jobId] !== undefined;
    },

    put(jobId, value) {
        _values[jobId] = value;
    },

    get(jobId) {
        return _values[jobId];
    },

    remove(jobId) {
        delete _values[jobId];
    },
};
