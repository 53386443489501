import {
    FiltersContentParams,
    FiltersCustomizationParams,
    FiltersHeaderStylesParams,
} from 'minimal/module/search/component/search-filters-panel/config/types';
import {
    EMPTY_BLOCK_STYLES,
    EMPTY_COMMON_PARAMS,
    EMPTY_TYPOGRAPHY,
} from 'site-editor/module/content-editor/config';
import { FONT_WEIGHTS } from 'site-editor/module/content-editor/config/standardFonts';

const EMPTY_HEADER_STYLES: FiltersHeaderStylesParams = {
    ruleColor: null,
    displayHorizontalRule: false,
};

const EMPTY_SUBHEADER_STYLES: FiltersHeaderStylesParams = {
    ruleColor: null,
    displayHorizontalRule: false,
};

export const defaultContentParams: FiltersContentParams = {
    filtersDisplayStyle: 'horizontal',
    width: 100,
    widthUnit: '%',
};

export const emptyFiltersParams: FiltersCustomizationParams = {
    contentParams: { ...defaultContentParams },

    headerTypography: { ...EMPTY_TYPOGRAPHY, fontWeight: FONT_WEIGHTS.BOLD },
    subHeaderTypography: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypographyHover: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypographySelected: { ...EMPTY_TYPOGRAPHY, fontWeight: FONT_WEIGHTS.BOLD },
    subHeaderTypographySelectedHover: { ...EMPTY_TYPOGRAPHY, fontWeight: FONT_WEIGHTS.BOLD },
    filtersTypography: { ...EMPTY_TYPOGRAPHY },
    filtersTypographySelected: { ...EMPTY_TYPOGRAPHY, fontWeight: FONT_WEIGHTS.BOLD },
    filtersTypographyHover: { ...EMPTY_TYPOGRAPHY },
    filtersCounterTypography: { ...EMPTY_TYPOGRAPHY },
    clearFiltersTypography: { ...EMPTY_TYPOGRAPHY },
    searchValuesDropdownTypography: { ...EMPTY_TYPOGRAPHY },
    searchValuesDropdownTypographySelected: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleDropdownTypography: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleDropdownTypographyHover: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleDropdownTypographySelected: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleDropdownTypographySelectedHover: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleCountDropdownTypography: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleCountDropdownTypographyHover: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleCountDropdownTypographySelected: { ...EMPTY_TYPOGRAPHY },
    jobsEventsToggleCountDropdownTypographySelectedHover: { ...EMPTY_TYPOGRAPHY },

    container: { ...EMPTY_BLOCK_STYLES },
    filterStyles: { ...EMPTY_BLOCK_STYLES },
    headerStyles: { ...EMPTY_HEADER_STYLES, displayHorizontalRule: true },
    subHeaderStyles: { ...EMPTY_SUBHEADER_STYLES, displayHorizontalRule: true },
    subHeaderStylingBox: { ...EMPTY_BLOCK_STYLES },
    subHeaderStylesSelected: { ...EMPTY_BLOCK_STYLES },
    subHeaderStylesSelectedHover: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleStylingBox: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleStylesHover: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleSelectedStylingBox: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleStylesSelectedHover: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleCountStylingBox: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleCountSelectedStylingBox: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleCountStylesHover: { ...EMPTY_BLOCK_STYLES },
    jobsEventsToggleCountStylesSelectedHover: { ...EMPTY_BLOCK_STYLES },
    filterStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    filterDropdownStyles: { ...EMPTY_BLOCK_STYLES },
    filtersCounterStyles: { ...EMPTY_BLOCK_STYLES },
    filterVerticalSeparator: { ...EMPTY_BLOCK_STYLES },
    searchDisplayStyles: { ...EMPTY_BLOCK_STYLES },

    commonParams: { ...EMPTY_COMMON_PARAMS },
};

export const defaultFiltersParams: FiltersCustomizationParams = {
    ...emptyFiltersParams,
    subHeaderTypographySelected: {
        ...emptyFiltersParams.subHeaderTypographySelected,
        fontWeight: FONT_WEIGHTS.BOLD,
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
    subHeaderTypographySelectedHover: {
        ...emptyFiltersParams.subHeaderTypographySelectedHover,
        fontWeight: FONT_WEIGHTS.BOLD,
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
    subHeaderTypographyHover: {
        ...emptyFiltersParams.subHeaderTypographyHover,
        fontWeight: FONT_WEIGHTS.NORMAL,
        fontStyle: 'normal',
        color: 'var(--color-secondary)',
    },
    filtersTypographySelected: {
        ...emptyFiltersParams.filtersTypographySelected,
        fontWeight: FONT_WEIGHTS.BOLD,
        fontStyle: 'normal',
        color: '#212121',
    },
    filtersTypographyHover: {
        ...emptyFiltersParams.filtersTypographyHover,
        fontWeight: FONT_WEIGHTS.NORMAL,
        fontStyle: 'normal',
        color: 'var(--color-theme-1)',
    },
    filterStylesOnHover: {
        ...emptyFiltersParams.filterStylesOnHover,
        backgroundColor: '#fff',
        borderColor: 'transparent',
        iconColorLight: 'var(--color-theme-1)',
    },
    subHeaderStylesSelectedHover: {
        ...emptyFiltersParams.subHeaderStylesSelectedHover,
        backgroundColor: '#fff',
        borderColor: 'transparent',
        iconColorLight: 'var(--color-theme-1)',
    },
    searchValuesDropdownTypographySelected: {
        ...emptyFiltersParams.searchValuesDropdownTypographySelected,
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
    jobsEventsToggleDropdownTypographySelected: {
        ...emptyFiltersParams.jobsEventsToggleDropdownTypographySelected,
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
    jobsEventsToggleCountDropdownTypographySelected: {
        ...emptyFiltersParams.jobsEventsToggleDropdownTypographySelected,
        fontWeight: 'bold',
        fontStyle: 'normal',
        color: 'var(--color-primary)',
    },
};
