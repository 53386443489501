import appConfig from 'app/model/config';
import IceRoute from '../model/IceRoute';
import router from 'app/model/router';

const iceLinks = appConfig.links.ice;

function _camelCaseToHyphens(str) {
    return str.replace(/([A-Z])/g, match => `-${match.toLowerCase()}`);
}

function _getRouteId(str) {
    return `ice-${_camelCaseToHyphens(str)}`;
}

if (iceLinks && typeof iceLinks === 'object') {
    Object.keys(iceLinks).forEach((key) => {
        const routeId = _getRouteId(key);
        const url = (iceLinks[key] || '').trim();

        if (url) {
            const iceRoute = new IceRoute(routeId, {
                url: decodeURIComponent(url),
            });

            router.addRoute(iceRoute);
        }
    });
}