import appConfig from 'app/model/config';
import menuService from '../../service/menu';
import pageLinksProvider from 'app/service/pageLinksProvider';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import themeEvents from 'cx/module/site-editor/config/events';
import { observable } from 'knockout';
import { mapGlobalHeaderFromAppConfig } from 'minimal/component/app-header/service/mapCustomHeaders';
import { showCookiePolicyOrPreferenceDialog } from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_LINK } from './cookie-consent-header-identifier';

const MENU_LINK_SELECTOR = '.app-menu__item';
const LANGUAGE_SELECT_SELECTOR = '.language-select';
const MISCELLANEOUS_LINK_SELECTOR = '.app-menu__miscellaneous-item';
const CLOSE_BUTTON_SELECTOR = '.app-menu__close';
const MENU_CONTAINER_SELECTOR = '.app-menu__content';

export default class AppMenuViewModel {

    isSignedIn = signedCandidate.isSignedIn;

    constructor() {
        this.isVisible = menuService.isVisible;
        this.wrapperClassName = menuService.wrapperClassName;
        this.links = pageLinksProvider.getHeaderPageLinks();
        this.showLanguageSelector = appConfig.languages.length > 1;
        this.globalHeaderSettings = mapGlobalHeaderFromAppConfig();

        this.employeeText = observable(this.globalHeaderSettings.employeeText);

        this._globalHeaderSettingsSubscription = themeEvents.customHeaderSettingsUpdated.add(
            (globalHeaderSettings) => {
                this.globalHeaderSettings = globalHeaderSettings;

                this.employeeText(globalHeaderSettings.employeeText);
            },
        );
    }

    showCookieConsentPreferences() {
        showCookiePolicyOrPreferenceDialog();
    }

    close() {
        menuService.close();
    }

    onLinkClicked(data, event) {
        if (event.target.tagName === 'A') {
            this.close();
        }

        return true;
    }

    dispose() {
        this.isVisible = null;

        this._globalHeaderSettingsSubscription.detach();
    }

    decideSectionToSkipTo() {
        const menuLink = document.querySelector(MENU_LINK_SELECTOR);
        const languageSelector = document.querySelector(LANGUAGE_SELECT_SELECTOR);
        const miscellaneousLink = document.querySelector(MISCELLANEOUS_LINK_SELECTOR);

        if (menuLink) {
            return MENU_CONTAINER_SELECTOR;
        }

        if (languageSelector) {
            return LANGUAGE_SELECT_SELECTOR;
        }

        if (miscellaneousLink) {
            return MENU_CONTAINER_SELECTOR;
        }

        return CLOSE_BUTTON_SELECTOR;
    }

    getCookieConsentIdentifier() {
        return COOKIE_CONSENT_LINK;
    }

}
