import { observable, observableArray, pureComputed } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';
import keyCodes from 'core/config/keycodes';
import i18n from 'core/i18n/i18n';

const SEGMENTS_NUMBER = 6;
const FIRST_SEGMENT_INDEX = 0;

export default class PinFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);
        this.segments = observableArray(this._createSegments(SEGMENTS_NUMBER));
        this.editedSegmentIndex = observable(FIRST_SEGMENT_INDEX);
        this.ariaLabel = i18n('apply-flow.candidate-verification.verification-code');
        this.combinedPin = pureComputed(() => this.segments().map(segment => segment.value()).join(''));
        this.isVisible = observable(true);

        this.segmentsSubs = this.segments().map(segment =>
            segment.value.subscribe(this._onSegmentValueChanged.bind(this)));
    }

    trimToDigit({ value }) {
        const firstChar = value().charAt(0);
        const newValue = /[0-9]/.test(firstChar) ? firstChar : undefined;

        value(newValue);

        if (value()) {
            this._focusNextSegment();
        }
    }

    setEditedSegmentIndex(index, segment, event) {
        event.target.select();

        this.editedSegmentIndex(index);
    }

    handleKeydown({ value }, event) {
        const key = event.keyCode || event.charCode;
        const segmentEmpty = !value();

        if (key === keyCodes.BACKSPACE || key === keyCodes.DELETE) {
            event.preventDefault();
            value(null);

            if (segmentEmpty) {
                this._focusPreviousSegment();
            }
        }

        return true;
    }

    blurHandler(segment) {
        if ([...this.segments()].pop() === segment) {
            super.blurHandler();
        }
    }

    focusInHandler() {
        this.hasFocus(true);
    }

    focusOutHandler() {
        this.hasFocus(false);
    }

    pasteHandler(value, { originalEvent }) {
        const clipboardData = originalEvent.clipboardData
            .getData('text')
            .trim();

        if (!clipboardData || !clipboardData.match(/[0-9]*/g)) {
            return;
        }

        const pinArray = clipboardData.split('');

        if (pinArray.length !== SEGMENTS_NUMBER) {
            return;
        }

        pinArray.forEach((char, index) => {
            const segments = this.segments();

            segments[index].value(char);
        });
    }

    _focusNextSegment() {
        if (this.editedSegmentIndex() < SEGMENTS_NUMBER - 1) {
            this.editedSegmentIndex(this.editedSegmentIndex() + 1);
        }
    }

    _focusPreviousSegment() {
        if (this.editedSegmentIndex() > 0) {
            this.editedSegmentIndex(this.editedSegmentIndex() - 1);
        }
    }

    _onSegmentValueChanged() {
        this.element.value(this.combinedPin().length === SEGMENTS_NUMBER ? this.combinedPin() : '');
    }

    _onModelValueChange() {
        this.segments().forEach((segment, index) => segment.value(this.element.value().charAt(index)));
    }

    _createSegments(length) {
        const segments = [...new Array(length)].map(() => ({ value: observable('') }));

        if (this.element.value()) {
            segments.forEach((segment, index) => segment.value(this.element.value().charAt(index)));
        }

        return segments;
    }

    dispose() {
        this.segmentsSubs.forEach(subscription => subscription.dispose());
    }

}
