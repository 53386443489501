import ko from 'knockout';

function subscribeOnce(handler, owner, eventName) {
    const subscription = this.subscribe((value) => {
        subscription.dispose();
        handler(value);
    }, owner, eventName);
}

ko.subscribable.fn.subscribeOnce = subscribeOnce;
