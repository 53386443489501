export class Coordinates {

    constructor({ latitude, longitude }) {
        this.latitude = latitude;
        this.longitude = longitude;
    }

    validate() {
        return Boolean(this.latitude && this.longitude);
    }

}