import jobService from 'job-details/service/job';

class CachedJob {

    #jobDetails = null;

    #getJobRequest = null;

    async _createGetJobRequest(jobId) {
        try {
            this.#jobDetails = await jobService.getJob(jobId);
        } finally {
            this.#getJobRequest = null;
        }

        return this.#jobDetails;
    }

    async getJob(jobId) {
        if (this.#jobDetails) {
            return this.#jobDetails;
        }

        this.#getJobRequest = this.#getJobRequest || this._createGetJobRequest(jobId);

        return this.#getJobRequest;
    }

    clear() {
        this.#jobDetails = null;
        this.#getJobRequest = null;
    }

}

export default new CachedJob();