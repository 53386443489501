import ko from 'knockout';
import router from 'app/model/router';
import viewsStore from '../service/viewsStore';

/**
 * Create virtual element for component.
 *
 * @param {object} parentNode - parent dom node
 * @param {string} componentName - component name
 */
function _appendVirtualNode(parentNode, componentName) {
    const virtualNodeStart = document.createComment(`ko [ ${componentName} ]`);
    const virtualNodeEnd = document.createComment('/ko');

    ko.virtualElements.prepend(parentNode, virtualNodeStart);
    ko.virtualElements.insertAfter(parentNode, virtualNodeEnd, virtualNodeStart);

    return virtualNodeStart;
}

/**
 * Render component in created virtual element.
 *
 * @param {object} element - parent dom node
 * @param {string} componentName - component name
 * @param {object} componentParams - component params
 */
function _renderComponent(element, componentName, componentParams) {
    const virtualNode = _appendVirtualNode(element, componentName);

    ko.applyBindingsToNode(virtualNode, {
        component: {
            name: componentName,
            params: componentParams,
        },
    });
}

/**
 * Dispose callback
 *
 * @callback sortableCallback
 * @param {string} viewName - view name
 */
function _addDisposeCallback(element, viewName) {
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        viewsStore.remove(viewName);
    });
}

ko.virtualElements.allowedBindings.view = true;

/**
 * Every time when route is changed, binding look into routeView configuration to know which component
 * should be render in element.
 *
 * @params {string} - view id
 *
 * @example
 * <div data-bind="view: 'view_id', params: optional_params"></div>
 *
 * In routing configuration file:
 *
 * router.configure({
 *     'route_name': {
 *         parent: 'parent_route_name',
 *         url: '/foo',
 *         view: {
 *             'view_id': 'component_name'
 *         }
 *     }
 * });
 */
ko.bindingHandlers.view = {
    /**
     * Subscribe for every change of view value, and render component
     */
    init(element, valueAccessor, allBindings) {
        const viewName = valueAccessor();
        const componentParams = allBindings().params;

        const view = viewsStore.create(viewName);

        const subscription = view.subscribe((componentName) => {
            ko.virtualElements.emptyNode(element);

            if (ko.components.isRegistered(componentName)) {
                _renderComponent(element, componentName, componentParams);

                _addDisposeCallback(element, viewName);
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            subscription.dispose();
        });
    },

    /**
     * Every time when route is changed, we look into routeView configuration,
     * to see whether value of 'viewName' should be updated
     */
    update(element, valueAccessor) {
        const viewName = valueAccessor();
        const currentRoute = router.route();
        const routeViewConfig = currentRoute.view();
        const view = viewsStore.create(viewName);

        if (viewName in routeViewConfig) {
            const newComponentName = routeViewConfig[viewName];

            view(newComponentName);
        }
    },
};
