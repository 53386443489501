import appConfig from 'app/model/config';
import { mapTrueFalseStringToBoolean } from 'app/module/core/utils/stringToBoolean';
import candidateModel from 'apply-flow/model/candidate';
import { Address } from '../model/Address';

const CWK_ALLOW_CONTACT_UPDATE = 'ORA_IRC_ALLOW_CWK_EXT_CANDIDATE_ADDRESS_UPDATE';

export const isCWKCandidate = (): boolean => {
    return candidateModel?.basicInformation?.cwkCandidate();
};

export const checkAddressAlreadyAvailable = (address: Address): boolean => {
    if (!address) return false;

    return Object.entries(address).some((element) => {
        if (element[0] !== 'country' && element[1]?.trim()) {
            return true;
        }

        return false;
    });
};

export const getCWKAddressProfileOption = (): boolean => {
    return mapTrueFalseStringToBoolean(appConfig.getSettingByKey(CWK_ALLOW_CONTACT_UPDATE));
};

export const isCWKAndCWKAddressUpdateDisabled = (address: Address): boolean => {
    return isCWKCandidate() && checkAddressAlreadyAvailable(address) && !getCWKAddressProfileOption();
};
