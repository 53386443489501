import store, {
    ADD_STEP,
    ADD_STAGE,
    UPDATE_STEP_STATUS,
    RESET,
    SHOW_DIALOG,
    HIDE_DIALOG,
} from './store/store';

import { DONE, IN_PROGRESS } from './enum/taskStatus';

export default {

    reset() {
        store.commit(RESET);
    },

    show() {
        store.commit(SHOW_DIALOG);
    },

    hide() {
        store.commit(HIDE_DIALOG);
    },

    addStage({ name, label }) {
        store.commit(ADD_STAGE, {
            name,
            label,
        });
    },

    addStep({ stage, step }, execute, ...params) {
        store.commit(ADD_STEP, {
            stageName: stage,
            stepName: step,
        });

        return async function inner() {
            store.commit(UPDATE_STEP_STATUS, {
                stageName: stage,
                stepName: step,
                status: IN_PROGRESS,
            });

            const response = await execute(...params);

            store.commit(UPDATE_STEP_STATUS, {
                stageName: stage,
                stepName: step,
                status: DONE,
            });

            return response;
        };
    },
};
