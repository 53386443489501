import router from 'app/model/router';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';
import i18n from 'core/i18n/i18n';

router.configure({
    'talent-community': {
        parent: 'minimal',
        url: '/tc-join:?query:',
        title: i18n('talent-community.email-verification-header'),
        view: {
            main: 'talent-community',
            dialogs: null,
        },
        canEnter() {
            return isTCOptInEnabled()
                ? Promise.resolve()
                : Promise.reject({ redirectTo: 'home-page' });
        },
    },
});
