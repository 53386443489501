import httpConfig from 'core/http/config';
import apiLogger from 'core/api-logger/apiLogger';
import { extendConsoleError, restoreDefaultConsoleError } from 'core/api-logger/consoleExtension';
import { logConsoleError } from 'core/api-logger/logConsoleError';

export function deactivateLogger() {
    apiLogger.clear();
    httpConfig.removeMiddleware(apiLogger.middleware);
    restoreDefaultConsoleError();
}

export function activateLogger() {
    if (!httpConfig.hasMiddleware(apiLogger.middleware)) {
        httpConfig.addMiddleware(apiLogger.middleware);
        extendConsoleError(logConsoleError);
    }
}