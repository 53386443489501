import PhoneNumberFormatter from './PhoneNumberFormatter';
import PhoneNumberFormatterForUS from './PhoneNumberFormatterForUS';


export default {
    /**
    * The international call prefix in all countries belonging to the North American Numbering Plan
    */
    NANP_CALL_PREFIX: '1',

    createPhone(phoneNumber) {
        if (phoneNumber.countryCode === this.NANP_CALL_PREFIX) {
            return new PhoneNumberFormatterForUS(phoneNumber);
        }

        return new PhoneNumberFormatter(phoneNumber);
    },

    formatPhoneNumberWithoutCountryCode(phoneNumber) {
        const phone = this.createPhone(phoneNumber);

        return phone.asFormattedStringWithoutCountryCode();
    },
};