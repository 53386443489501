import './binding/copyToClipboard';

import { components, contextFor } from 'knockout';

import ViewModel from './CopyLinkViewModel';
import template from './copy-link.html';
import { ComponentInfo, CopyLinkProps } from './types';

components.register('copy-link', {
    viewModel: {
        createViewModel(params: CopyLinkProps, componentInfo: ComponentInfo) {
            const context = contextFor(componentInfo.element);

            return new ViewModel(params, context.$lang);
        },
    },
    template,
});
