import { observable } from 'knockout';
import tokenService from 'candidate-verification/service/token';
import unverifiedService from 'candidate-verification/service/unverified';
import router from 'app/model/router';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import CandidateVerificationAbstractViewModel
    from 'candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import cxEvents from 'cx/config/events';
import notificationsService from 'cx/service/notifications';
import { UNVERIFIED_APPLICATIONS_LIMIT, JOB_APPLICATION } from 'candidate-verification/config/verificationSubmodules';
import { redirectToApplyFlow } from 'candidate-verification/service/routes';
import { INTERNAL_CANDIDATE } from 'candidate-verification/config/candidateVerificationStatus';

export default class ConfirmEmailViewModel extends CandidateVerificationAbstractViewModel {

    constructor() {
        super();

        this.queryParams = router.routeParams().query;
        this.userLanguage = siteLanguage.restore();
        this.isLoading = observable(true);

        this.challenge.submodule = unverifiedService.hasExceededUnverifiedApplicationsLimit()
            ? UNVERIFIED_APPLICATIONS_LIMIT
            : JOB_APPLICATION;

        const { email, phone, verificationMethod } = unverifiedService.getUnverifiedVerificationData();

        this.candidate.email(email);
        this.candidate.phone(phone);
        this.candidate.verificationMethod(verificationMethod);

        const { sourceContext } = router.routeParams();

        if (!sourceContext || sourceContext !== 'indeedApply') {
            this._initialize();
        } else {
            this.isLoading(false);
        }
    }

    onPinValid() {
        unverifiedService.destroy();

        redirectToApplyFlow(router.routeParams())
            .then(this._resetView.bind(this));
    }

    _createToken() {
        return tokenService.create({
            jobId: router.routeParams().jobId,
            candidate: this.candidate,
            submodule: this.challenge.submodule,
        });
    }

    _initialize() {
        this.isLoading(true);

        return this._createToken()
            .then(() => {
                this.isLoading(false);
                cxEvents.loaded.dispatch();
            })
            .catch(this._showError.bind(this));
    }

    _showError(error) {
        if (error === INTERNAL_CANDIDATE) {
            return;
        }

        if (error === this.verificationStates.ATTEMPS_LIMIT_REACHED) {
            this.verificationStatus(this.verificationStates.ATTEMPS_LIMIT_REACHED);
            this.isLoading(false);

            return;
        }

        this.isLoading(false);

        notificationsService.error();

        throw new Error(error);
    }

    _resetView() {
        this.isLoading(false);
    }

}