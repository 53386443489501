import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import UserMenuModel from './model/UserMenu';
import { session } from 'candidate-verification/service/user-session/session';
import notificationsService from 'cx/service/notifications';
import tokenService from 'candidate-verification/service/token';
import i18n from 'core/i18n/i18n';
import historyService from 'app/module/ce-common/service/historyService';
import absoluteRouter from 'app/model/absoluteRouter';
import { search as searchService } from 'minimal/module/search/service/search';
import userTracking from 'cx/service/userTracking';
import SearchQueryBuilder from 'search/model/SearchQueryBuilder';
import themeEvents from 'cx/module/site-editor/config/events';
import { getDefaultSortOption } from 'search/service/defaultSortBy';
import { getEventsDefaultSortOption } from 'search/service/eventsDefaultSortBy';
import { areEventsEnabled } from '../../../../service/areEventsEnabled';
import signedCandidate from '../../module/candidate-verification/model/signedCandidate';
import { SEARCH_RESULTS_STRINGS } from '../../module/search/config/types';

const CSS_ROUTE_ID = 'candidate-self-service.sign-in';

export default class UserMenuViewModel {

    constructor({ isProfileButtonHidden, profileText } = {}) {
        this.isProfileButtonHidden = observable(isProfileButtonHidden);
        this.userMenuButtonText = observable(profileText);

        this.userMenuButtonAriaLabel = pureComputed(
            () => this.userMenuButtonText() || i18n('candidate-self-service.header-button-title'),
        );

        this._globalHeaderSettingsSubscription = themeEvents.customHeaderSettingsUpdated.add((globalHeaderSettings) => {
            this.isProfileButtonHidden(globalHeaderSettings.isProfileButtonHidden);

            this.userMenuButtonText(globalHeaderSettings.profileText);
        });
    }

    isOpened = observable(false);

    model = new UserMenuModel();

    showUserData = pureComputed(this._shouldShowUserData.bind(this));

    url = absoluteRouter.interpolate({}, CSS_ROUTE_ID);

    toggleOpen() {
        this.isOpened(!this.isOpened());
    }

    menuClicked() {
        if (this.showUserData()) {
            this.toggleOpen();

            return;
        }

        if (router.route().id !== CSS_ROUTE_ID) {
            historyService.store();
        }

        router.go(CSS_ROUTE_ID);
    }

    dispose() {
        this.model.dispose();

        this._globalHeaderSettingsSubscription.detach();
    }

    signOut() {
        this.isOpened(false);

        session.signOut()
            .then(() => this.model.isSignedIn(false))
            .then(this._triggerSearch)
            .catch((error) => {
                console.error(error);
                notificationsService.error();
            });
    }

    _triggerSearch() {
        const { query } = router.routeParams();
        const queryBuilder = new SearchQueryBuilder({ ...query, limit: 25 });

        if (router.route().id === SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS) {
            const { isSignedIn } = signedCandidate;
            const areEventEnabled = areEventsEnabled();

            const eventsDefaultSortOption = query ? getEventsDefaultSortOption(query) : null;

            queryBuilder.setDefaultSortOption(eventsDefaultSortOption);

            searchService.searchEvents(queryBuilder.build(), Boolean(isSignedIn()), areEventEnabled);
        } else {
            const defaultSortOption = query ? getDefaultSortOption(query) : null;

            queryBuilder.setDefaultSortOption(defaultSortOption);

            searchService.searchJobs(queryBuilder.build()).then(() => {
                if (query) {
                    userTracking.trackRequisitionSearch(query);
                }
            });
        }
    }

    goToCss() {
        if (tokenService.isCandidateVerified()) {
            router.go('candidate-self-service');

            return;
        }

        this.model.isSignedIn(false);
        this.isOpened(false);
        notificationsService.error(i18n('general.session-expired'));
    }

    _shouldShowUserData() {
        return this.model.isSignedIn() && this.model.header();
    }

    closeMenu() {
        this.isOpened(false);
    }

}
