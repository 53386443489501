import http from 'core/http/http';
import errorFactory from 'core/error-tracker-plugin/errorFactory';
import pluginConfig from 'core/error-tracker-plugin/config';
import appEvents from 'app/config/events';

function logError(errorType, errorEvent, config) {
    const errorObject = errorFactory.createError(errorType, errorEvent, config);

    return http.post('/api/logs', errorObject, pluginConfig.http)
        .then((errorModel) => {
            console.warn(`Bug logged: ${pluginConfig.http.baseUrl}/log.html?${errorModel._id}`);
        })
        .catch(err => console.error(err));
}

export default {
    init() {
        const hostName = window.location.origin;

        if (hostName in pluginConfig.allowedHosts) {
            window.addEventListener('error', logError.bind(null, 'error'));
            appEvents.apiError.add(logError.bind(null, 'apiError'));
        }
    },
};