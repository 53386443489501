import './component/media-image-slide/component';
import './component/media-video-slide/component';
import './component/media-map-slide/component';

import { components } from 'knockout';

import ViewModel from './MediaSliderViewModel';
import template from './media-slider.html';

components.register('media-slider', { viewModel: ViewModel, template });
