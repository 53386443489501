import ko from 'knockout';
import ValidatableForm from 'core/form/ValidatableForm';

export default class LegalDisclaimerForm extends ValidatableForm {

    constructor(isAccepted) {
        super();
        this.componentName('legal-disclaimer');

        this.isAccepted = isAccepted;

        this.validateImmediately = ko.observable(false);
        this.isAccepted.subscribeOnce(this.validateImmediately.bind(this, true));
    }

    enableImmediateValidation() {
        this.validateImmediately(true);
    }

    _isValid() {
        const validateImmediately = this.validateImmediately();
        const isAccepted = this.isAccepted();

        return !validateImmediately || isAccepted;
    }

    _getErrorCount() {
        return this._isValid() ? 0 : 1;
    }

}