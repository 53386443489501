import { pureComputed, observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';
import applicationsModel from '../../model/applications';
import candidateTCSignup from '../../service/candidateTCSignup';
import applicationsConfirmService from '../../service/applicationsConfirm';

export default class CsApplicationsConfirmAllViewModel {

    constructor() {
        this.isConfirmAllInProgress = observable(false);
        this.applications = applicationsModel.applications;

        this.unconfirmedApplications = pureComputed(() =>
            this.applications().filter(application => !application.isConfirmed()));
    }

    confirmAll() {
        this.isConfirmAllInProgress(true);

        const applications = this.unconfirmedApplications();

        return applicationsConfirmService.confirmAllApplications(applications)
            .then(this._confirmTCSignup.bind(this))
            .then(() => notificationsService.success(i18n('candidate-self-service.confirmed-all-success-message')))
            .catch((error) => {
                notificationsService.error();

                throw new Error(error);
            })
            .then(() => this.isConfirmAllInProgress(false));
    }

    _confirmTCSignup() {
        const [application] = this.applications();

        return candidateTCSignup.confirmTalentCommunity(application.candidateNumber());
    }

}
