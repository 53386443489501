import { Observable } from 'knockout';
import { LANGUAGE_KEY_SEPARATOR } from '../config/messages';
import { CookiePreferenceLinkParams } from '../config/types';

type MessageObservable = Record<'text', Observable<string>>;

export const getLanguageKey = (key: string, lang: string): string => {
    return `${key}${LANGUAGE_KEY_SEPARATOR}${lang}`;
};

export const getMessages = (
    config: CookiePreferenceLinkParams,
    language: string
): Record<string, Observable<string>> => {
    const keys = Object.keys(config);
    const messageKeys = keys.filter((key) => key.includes('Text'));

    return messageKeys.reduce((messages, key) => {
        const langKey = getLanguageKey(key, language);

        return {
            ...messages,
            [key]:
                (config[langKey] as unknown as MessageObservable)?.text ||
                (config[key] as unknown as MessageObservable)?.text,
        };
    }, {});
};
