const valueFormatters = new Proxy(
    {
        Date: formatDate,
        DateTime: formatDateTime,
    },
    {
        get(obj, prop) {
            return prop in obj ? obj[prop] : param => param;
        },
    },
);

function formatDate(dateFormatter, date) {
    return dateFormatter.formatDate(date);
}

function formatDateTime(dateFormatter, dateTime) {
    return dateFormatter.formatDateTime(dateTime);
}

function mapJobFlexFieldFromRest(flexField) {
    return {
        name: flexField.prompt,
        type: flexField.controlType,
        value: valueFormatters[flexField.controlType](flexField.value),
        sequenceNumber: flexField.sequenceNumber,
    };
}

function sortBySequenceNumber(element1, element2) {
    return Number(element1.sequenceNumber) - Number(element2.sequenceNumber);
}

export function mapJobFlexFieldsFromRest(flexFields, dateFormatter) {
    valueFormatters.Date = formatDate.bind(null, dateFormatter);
    valueFormatters.DateTime = formatDateTime.bind(null, dateFormatter);

    return flexFields.sort(sortBySequenceNumber)
        .map(flexField => mapJobFlexFieldFromRest(flexField, dateFormatter));
}
