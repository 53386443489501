import formElementFactory from 'core/form/element/factory';
import GeoHierarchyAutosuggest from 'apply-flow/module/personal-information-address/form/element/GeoHierarchyAutosuggest';
import GeoHierarchySelect from 'apply-flow/module/personal-information-address/form/element/GeoHierarchySelect';
import GeoHierarchySearch from 'apply-flow/module/personal-information-address/form/element/GeoHierarchySearch';


const GEO_ELEMENTS = {
    geoHierarchyAutosuggest: GeoHierarchyAutosuggest,
    geoHierarchySelect: GeoHierarchySelect,
    geoHierarchySearch: GeoHierarchySearch,
};

export default Object.create(formElementFactory, {
    create: {
        value(type, name) {
            if (type in GEO_ELEMENTS) {
                const FormElement = GEO_ELEMENTS[type];

                return new FormElement(name);
            }

            return formElementFactory.create(type, name);
        },
    },
});