import { observable, pureComputed } from 'knockout';

class SectionState {

    constructor(sections) {
        this.sections = {};

        sections.forEach((section) => {
            this.sections[section.number] = this._createSectionState(section);
        });

        this.ready = pureComputed(this._isAllSectionsReady, this);
    }

    _isSectionReady(sectionNumber) {
        return Object.keys(this.sections[sectionNumber].blocks)
            .every(key => this.sections[sectionNumber].blocks[key]());
    }

    _isAllSectionsReady() {
        return Object.keys(this.sections)
            .every(key => this.sections[key].ready());
    }

    _createSectionState(section) {
        return {
            blocks: this._getBlocksForSection(section),
            ready: pureComputed(this._isSectionReady.bind(this, section.number), this),
            visited: observable(false),
        };
    }

    _getBlocksForSection(section) {
        return section.pages
            .reduce((blocks, page) => blocks.concat(page.blocks), [])
            .reduce((blocks, block) => {
                blocks[block.blockId] = observable(false);

                return blocks;
            }, {});
    }

}

export default {
    initialize(sections) {
        this.sectionState = new SectionState(sections);
    },

    getStateOf(sectionNumber) {
        return this.sectionState.sections[sectionNumber];
    },

    setBlockReady(sectionNumber, blockId) {
        this.getStateOf(sectionNumber).blocks[blockId](true);
    },

    setBlockUnready(sectionNumber, blockId) {
        this.getStateOf(sectionNumber).blocks[blockId](false);
    },

    isSectionReady(sectionNumber) {
        return this.getStateOf(sectionNumber).ready;
    },

    isBlockReady(sectionNumber, blockId) {
        return this.getStateOf(sectionNumber).blocks[blockId];
    },

    isAllSectionsReady() {
        return this.sectionState.ready;
    },

    setSectionVisited(sectionNumber) {
        this.getStateOf(sectionNumber).visited(true);
    },

    isSectionVisited(sectionNumber) {
        return this.getStateOf(sectionNumber).visited;
    },
};
