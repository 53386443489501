import { observable } from 'knockout';

const AUTO_DISMISS_DELAY = 6000;

export default class SearchBoxPopupViewModel {

    constructor(
        { message, focus = observable(true), value, autoDismiss, restoreValue = false, showDismissIcon = true }) {
        this.message = message;
        this.showDismissIcon = showDismissIcon;
        this.autoDismiss = autoDismiss;
        this.showDismissIconObs = observable(this.showDismissIcon);
        this.restoreValue = Boolean(restoreValue);
        this.value = value;

        this.visible = observable(false);

        this._dismissTimeout = null;

        this._focusSubscription = focus.subscribe(() => this._showMessage(focus, value), this);
        this._valueSubscription = value.subscribe(() => this._showMessage(focus, value), this);
    }

    dismissMessage() {
        this._clearDismissTimeout();
        this.visible(false);

        if (this.restoreValue) {
            this.value(false);
        }
    }

    dispose() {
        this._focusSubscription.dispose();
        this._valueSubscription.dispose();
    }

    _showMessage(focus, value) {
        const isVisible = focus() && value();

        if (isVisible) {
            this._setDismissTimeout();
        }

        this.visible(isVisible);
    }

    _setDismissTimeout() {
        if (!this.autoDismiss) {
            return;
        }

        this._dismissTimeout = setTimeout(() => this.dismissMessage(), AUTO_DISMISS_DELAY);
    }

    _clearDismissTimeout() {
        if (this._dismissTimeout) {
            clearTimeout(this._dismissTimeout);
            this._dismissTimeout = null;
        }
    }

}
