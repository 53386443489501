import { findByCoordinates, findByPostalCode } from './geosearch';

export const GEO_OPTIONS = {
    // cache = 12h
    maximumAge: 12 * 60 * 60 * 1000,
    timeout: 10000,
    enableHighAccuracy: true,
};

const GEOLOCATION_PERMISSION_GRANTED_TIMEOUT = 10000;

let geolocationRequest;

function isEmptyResponse(response) {
    return Object.keys(response).length === 0;
}

function mapResponse(latitude, longitude, location) {
    const addressLine1 = [location.houseNumber, location.street]
        .filter(element => element)
        .join(' ');

    return {
        countryCode: location.country,
        state: location.region,
        addressLine1,
        zipCode: location.postalCode,
        city: location.municipality,
        settlement: location.settlement,
        latitude,
        longitude,
    };
}

function createGeolocationRequest() {
    const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(reject, GEOLOCATION_PERMISSION_GRANTED_TIMEOUT);
    });

    const getCurrentPositionPromise = new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, GEO_OPTIONS);
    });

    let latitude;
    let longitude;

    return Promise.race([getCurrentPositionPromise, timeoutPromise])
        .then((position) => {
            ({ latitude, longitude } = position.coords);

            return findByCoordinates(latitude, longitude);
        })
        .then(([location]) => {
            if (isEmptyResponse(location)) {
                return Promise.reject();
            }

            geolocationRequest = null;

            return mapResponse(latitude, longitude, location);
        })
        .catch((error) => {
            geolocationRequest = null;

            throw error;
        });
}

export default {
    query() {
        geolocationRequest = geolocationRequest || createGeolocationRequest();

        return geolocationRequest;
    },

    getLocationByZipcode(zipcode, countryCode) {
        return findByPostalCode(countryCode, zipcode)
            .then((location) => {
                if (isEmptyResponse(location) || !location.x || !location.y) {
                    return Promise.reject();
                }

                return {
                    latitude: location.y,
                    longitude: location.x,
                };
            });
    },
};