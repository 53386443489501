import { getStyle } from './getStyle';
import { DimensionUnit } from './commonParams';
import { wrapBySelector } from './wrapBySelector';

export const getMaxWidthCss = ({
    selector,
    width,
    widthUnit,
}: {
    selector: string;
    width: number | 'none';
    widthUnit: DimensionUnit;
}): string => {
    if (width === 'none') {
        return wrapBySelector(selector, getStyle('maxWidth', width));
    }

    if (width < 0) {
        return '';
    }

    return wrapBySelector(selector, getStyle('maxWidth', `${width}${widthUnit}`));
};

export const getWidthCss = ({
    selector,
    width,
    widthUnit,
}: {
    selector: string;
    width: number | 'none';
    widthUnit: DimensionUnit;
}): string => {
    if (width === 'none') {
        return wrapBySelector(selector, getStyle('maxWidth', width));
    }

    if (width < 0) {
        return '';
    }

    return wrapBySelector(selector, getStyle('width', `${width}${widthUnit}`));
};

export const getMaxHeightCss = ({
    selector,
    height,
    heightUnit,
}: {
    selector: string;
    height: number;
    heightUnit: DimensionUnit;
}): string => {
    if (height < 0) {
        return '';
    }

    return wrapBySelector(selector, getStyle('maxHeight', `${height}${heightUnit}`));
};

export const getHeightCss = ({
    selector,
    height,
    heightUnit,
}: {
    selector: string;
    height: number;
    heightUnit: DimensionUnit;
}): string => {
    if (height < 0) {
        return '';
    }

    return wrapBySelector(selector, getStyle('height', `${height}${heightUnit}`));
};

export const getColorCss = ({
    selector,
    color,
}: {
    selector: string;
    color: string | null | undefined;
}): string => {
    return color ? wrapBySelector(selector, getStyle('color', color)) : '';
};

export const getBackgroundColorCss = ({
    selector,
    value,
}: {
    selector: string;
    value: string | null;
}): string => {
    if (!value) {
        return '';
    }

    return wrapBySelector(selector, getStyle('backgroundColor', value));
};

export const getDisplayCss = ({ selector, value }: { selector: string; value: boolean }): string => {
    const displayValue = value ? 'block' : 'none';

    return wrapBySelector(selector, getStyle('display', displayValue));
};

export const getHorizontalPaddingCss = ({
    selector,
    value,
    unit,
}: {
    selector: string;
    value: number | undefined;
    unit: DimensionUnit;
}): string => {
    if (!value) {
        return '';
    }

    return wrapBySelector(selector, `padding-left: ${value}${unit}; padding-right: ${value}${unit};`);
};

export const getVerticalPaddingCss = ({
    selector,
    value,
    unit,
}: {
    selector: string;
    value: number | undefined;
    unit: DimensionUnit;
}): string => {
    if (!value) {
        return '';
    }

    return wrapBySelector(selector, `padding-top: ${value}${unit}; padding-bottom: ${value}${unit};`);
};

export const getHorizontalMediaMarginCss = ({
    selector,
    value,
    unit,
}: {
    selector: string;
    value: number | undefined;
    unit: DimensionUnit;
}): string => {
    if (!value) {
        value = 0;
    }

    if (unit === '%') {
        return wrapBySelector(
            selector,
            `margin-left: calc(((100vw - 100px) * -${value}) * 0.01); margin-right: calc((100vw - 100px) * -${value} * 0.01);`
        );
    }

    return wrapBySelector(selector, `margin-left: -${value}${unit}; margin-right: -${value}${unit};`);
};

export const getBorderColorCss = ({
    selector,
    color,
}: {
    selector: string;
    color: string | null;
}): string => {
    if (!color) {
        return '';
    }

    return wrapBySelector(selector, getStyle('borderColor', color));
};

export const getBorderRadiusCss = ({
    selector,
    value,
}: {
    selector: string;
    value: number | undefined;
}): string => {
    if (!value) {
        return '';
    }

    return wrapBySelector(selector, getStyle('borderRadius', value.toString()));
};

export const getBorderWidthCss = ({
    selector,
    value,
}: {
    selector: string;
    value: number | undefined;
}): string => {
    if (!value) {
        return '';
    }

    return wrapBySelector(selector, getStyle('borderWidth', value.toString()));
};
