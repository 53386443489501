import { observableArray } from 'knockout';
import l10n from 'core/l10n/l10n';

export default class ScheduleRowViewModel {

    constructor(params) {
        this.scheduleRow = params.scheduleRow;
        this.submissionId = params.submissionId;
        this.groups = this._splitGroups();
    }

    formatDateLong(date) {
        return date ? l10n.date.formatLong(date) : null;
    }

    formatWeekDayLong(date) {
        return date ? l10n.weekDay.formatLong(date) : null;
    }

    _splitGroups() {
        const { interviews } = this.scheduleRow;
        const groups = observableArray();
        const GROUP_SIZE = 4;
        const GROUP_COUNT = Math.ceil(interviews.length / GROUP_SIZE);

        for (let i = 0; i < GROUP_COUNT; i++) {
            const group = observableArray(interviews.slice(GROUP_SIZE * i, GROUP_SIZE * (i + 1)));

            groups.push(group);
        }

        return groups;
    }

}