import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import legalDisclaimerService from 'apply-flow/service/legalDisclaimer';
import LegalDisclaimerForm from 'apply-flow/module/agreements/component/legal-disclaimer/form/LegalDisclaimerForm';
import CandidateVerificationAbstractViewModel from 'candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import { CHALLENGE_REQUIRED, ATTEMPS_LIMIT_REACHED, PHONE_BLOCKED, PIN_LIMIT_REACHED, TOO_MANY_SIGNIN } from '../../config/pinVerificationStatus';
import notificationsService from 'cx/service/notifications';
import { store } from 'candidate-verification/service/legalDisclaimerAcceptanceStorage';
import { JOB_APPLICATION, CLAIM_PHONE_NUMBER, CLAIM_EMAIL } from '../../config/verificationSubmodules';
import lastNameFormBuilder from './service/lastNameFormBuilder';
import dateOfBirthFormBuilder from './service/dateOfBirthFormBuilder';

import { INTERNAL_CANDIDATE, INVALID_COOKIE, LAST_NAME_CHECK, DATE_OF_BIRTH_CHECK, SIGNIN_LOCKED } from '../../config/candidateVerificationStatus';
import { redirectToApplyFlow } from 'candidate-verification/service/routes';
import tokenVerification from './service/tokenVerification';
import emailFormBuilder from './service/emailFormBuilder';
import smsFormBuilder from './service/smsFormBuilder';
import { SMS, EMAIL } from '../../config/verificationMethods';
import jobService from 'cx/module/job-details/service/job';
import userTracking from 'app/module/cx/service/userTracking';

const PHONE_BLOCKED_MESSAGE = i18n('apply-flow.candidate-verification.cant-use-this-phone');
const RETRY_LIMIT_CLAIM_MESSAGE = i18n('apply-flow.candidate-verification.retry-limit-claim-error');

const KNOWN_ERROR_TYPES = ['already-applied', 'confirmed-submission', 'pin-code-required', 'removed-submission', 'submission-accept',
    'token-verification', 'uncofirmed-submission', LAST_NAME_CHECK, DATE_OF_BIRTH_CHECK];

export default class EmailVerificationViewModel extends CandidateVerificationAbstractViewModel {

    constructor() {
        super();

        this.isLoading = ko.observable(false);
        this.requestErrorType = ko.observable(null);
        this.lastNameNotMatched = ko.observable(false);
        this.dateOfBirthNotMatched = ko.observable(false);
        this.jobId = router.routeParams().jobId;
        this.challenge.submodule = JOB_APPLICATION;

        this._loadLegalDisclaimer();

        this.lastName = ko.observable();
        this.lastNameForm = lastNameFormBuilder.createForm(this.lastName);
        this.showLastNameMismatchMessage = ko.observable(false);
        this.showClaimMessage = true;
        this.isLastNameCheck = ko.observable(false);

        this.dateOfBirth = ko.observable();
        this.dateOfBirthForm = dateOfBirthFormBuilder.createForm(this.dateOfBirth);
        this.showDateOfBirthMismatchMessage = ko.observable(false);
        this.isDateOfBirthCheck = ko.observable(false);
    }

    verify() {
        this._storeLegalDisclaimerAcceptance();
        this.createToken();
    }

    async _createForms() {
        const forms = {
            [EMAIL]: emailFormBuilder.createForm(this.candidate),
        };

        if (this.isSMSVerificationEnabled) {
            const { jobId } = router.routeParams();

            let preferredTerritoryCode;

            if (jobId) {
                const jobDetails = await jobService.getLightJob(jobId)
                    .catch((error) => {
                        if (error === 'job-expired') {
                            router.go('job-details', {
                                isExpired: true,
                            });
                        }
                    });

                preferredTerritoryCode = jobDetails?.primaryLocationCountry;
            }

            forms[SMS] = smsFormBuilder.createForm(this.candidate, {
                preferredTerritoryCode,
            });
        }

        this.forms(forms);
    }

    next() {
        userTracking.trackJobAppEntryNext(router.routeParams().jobId, router.route().id);

        const form = this.activeForm();

        form.enableImmediateValidation();
        this._enableLegalDisclaimerValidation();

        form.validate()
            .then((isFormValid) => {
                if (!isFormValid || !this._isLegalDisclaimerEmptyOrValid()) {
                    return;
                }

                this.beforeCaptchaVerify();
            });
    }

    onPinValid() {
        redirectToApplyFlow()
            .then(this._resetView.bind(this));
    }

    onAttempsLimitReached() {
        return router.go('job-details', { jobId: this.jobId });
    }

    createToken() {
        this.isLoading(true);
        this.lastNameNotMatched(false);
        this.dateOfBirthNotMatched(false);

        return tokenVerification.createToken({
            jobId: this.jobId,
            candidate: this.candidate,
            submodule: this.challenge.submodule,
            lastName: this.lastName(),
            dateOfBirth: this.dateOfBirth(),
            captchaToken: this.captchaToken(),
            honeyPot: this.honeyPot(),
        })
            .then(this._resetView.bind(this))
            .catch(this._handleTokenError.bind(this));
    }

    verifyLastName() {
        this.lastNameForm.enableImmediateValidation();

        this.lastNameForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);

                tokenVerification.verifyLastName({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    lastName: this.lastName(),
                    captchaToken: this.captchaToken(),
                })
                    .then(this._resetView.bind(this))
                    .catch(this._handleTokenError.bind(this));
            });
    }

    verifyDateOfBirth() {
        this.dateOfBirthForm.enableImmediateValidation();

        this.dateOfBirthForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);

                tokenVerification.verifyDateOfBirth({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    dateOfBirth: this.dateOfBirth(),
                    captchaToken: this.captchaToken(),
                })
                    .then(this._resetView.bind(this))
                    .catch(this._handleTokenError.bind(this));
            });
    }

    claimPhoneNumber() {
        this.isLoading(true);
        this.challenge.submodule = CLAIM_PHONE_NUMBER;

        return tokenVerification.claimPhoneNumber({ candidate: this.candidate })
            .then(this._resetView.bind(this))
            .catch(this._handleTokenError.bind(this));
    }

    claimEmail() {
        this.isLoading(true);
        this.challenge.submodule = CLAIM_EMAIL;

        return tokenVerification.claimEmail({ candidate: this.candidate })
            .then(this._resetView.bind(this))
            .catch(this._handleTokenError.bind(this));
    }

    redirectBack() {
        userTracking.trackJobAppEntryCancel(router.routeParams().jobId, router.route().id);

        const parentRoute = router.route().parent;

        router.go(parentRoute.id);
    }

    redirectBackToEmailVerification() {
        this.lastNameNotMatched(false);
        this.dateOfBirthNotMatched(false);
        this.showLastNameMismatchMessage(false);
        this.showDateOfBirthMismatchMessage(false);
        this.lastName(undefined);
        this.dateOfBirth(null);
        this.setVerificationMethod(this.candidate.verificationMethod());
        this.isLastNameCheck(false);
        this.isDateOfBirthCheck(false);
        this._resetView();
    }

    resendToken() {
        this.isLoading(true);

        return tokenVerification.resendToken({
            jobId: this.jobId,
            candidate: this.candidate,
            submodule: this.challenge.submodule,
        })
            .then(() => this.isLoading(false))
            .then(() => this.requestErrorType('token-resend-thank-you'))
            .catch(this._handleTokenError.bind(this));
    }

    dispose() {
        notificationsService.dismissAll();
    }

    _handleTokenError(errorObject) {
        this.isLoading(false);

        const error = errorObject?.errorCode ? errorObject?.errorCode : errorObject;

        if (error === INTERNAL_CANDIDATE) {
            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED || error === TOO_MANY_SIGNIN) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            this.requestErrorType(CHALLENGE_REQUIRED);
        } else if (error === SIGNIN_LOCKED) {
            notificationsService.error(RETRY_LIMIT_CLAIM_MESSAGE, 0);

            if (this.dateOfBirth()) {
                this.showDateOfBirthMismatchMessage(true);
                this.dateOfBirthForm.elements()[0].isDisabled(true);
            } else {
                this.showLastNameMismatchMessage(true);
                this.lastNameForm.elements()[0].isDisabled(true);
            }
        } else if (error === PHONE_BLOCKED) {
            notificationsService.error(PHONE_BLOCKED_MESSAGE, 0);
        } else if (error === INVALID_COOKIE) {
            if (this.dateOfBirth()) {
                this.showDateOfBirthMismatchMessage(true);
            } else {
                this.showLastNameMismatchMessage(true);
            }
        } else if (error === DATE_OF_BIRTH_CHECK) {
            this.dateOfBirthNotMatched(true);
            this.requestErrorType(error);
            this.isDateOfBirthCheck(true);
            this.captchaToken(errorObject?.params?.captchaToken || null);
        } else if (error === LAST_NAME_CHECK) {
            this.lastNameNotMatched(true);
            this.requestErrorType(error);
            this.isLastNameCheck(true);
            this.captchaToken(errorObject?.params?.captchaToken || null);
        } else if (KNOWN_ERROR_TYPES.includes(error)) {
            this.requestErrorType(error);
        } else if (typeof error === 'string') {
            notificationsService.error(error, 0);
        } else {
            notificationsService.error();
            console.error(error);
        }
    }

    _loadLegalDisclaimer() {
        this.isLoading(true);

        return legalDisclaimerService.queryLegalDisclaimer(this.jobId)
            .then((legalDisclaimer) => {
                this.legalDisclaimer(legalDisclaimer);
                this.legalDisclaimerForm(new LegalDisclaimerForm(this.legalDisclaimer().isAccepted));
                this.isLoading(false);
            })
            .catch(error => console.error(error));
    }

    _storeLegalDisclaimerAcceptance() {
        if (this.enableLegalDisclaimer()) {
            store(this.jobId);
        }
    }

    _resetView() {
        this.isLoading(false);
        this.requestErrorType(null);
    }

}