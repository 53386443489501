import ko from 'knockout';
import oj from 'ojs/ojcore';

function isoStringToDate(target) {
    return ko.pureComputed({
        read() {
            const isoString = target();

            try {
                if (isoString) {
                    return oj.IntlConverterUtils.isoToLocalDate(isoString);
                }

                return isoString;
            } catch (error) {
                return null;
            }
        },

        write(value) {
            target(value);
        },
    });
}

ko.extenders.isoStringToDate = isoStringToDate;