import { bindingHandlers, utils } from 'knockout';

const OBSERVER_CONFIG = {
    attributes: true,
    attributeOldValue: true,
    attributeFilter: ['class'],
    characterData: false,
};

bindingHandlers.classRemovedObserver = {
    init(element, valueAccessor) {
        const { className, handler } = valueAccessor();

        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                const oldValueHasClass = mutation.oldValue.includes(className);
                const newValueHasClass = element.className.includes(className);

                if (oldValueHasClass && !newValueHasClass) {
                    handler();
                }
            });
        });

        mutationObserver.observe(element, OBSERVER_CONFIG);

        utils.domNodeDisposal.addDisposeCallback(element, () => mutationObserver.disconnect());
    },
};