import {
    AdditionInformationItem,
    SearchResultsContentParams,
    HeaderInformationItem,
    JobInfoDisplayedItem,
    JobTagsInformationItem,
    MainContentInformationItem,
} from '../../search-results/config/types';
import { SearchResultsClassicContentParams } from './types';
import { defaultContentParams } from '../config/defaultStyles';

type SearchResultsNewSectionsDisplayed = {
    headerInformationDisplayed: HeaderInformationItem[];
    jobInfoDisplayed: JobInfoDisplayedItem[];
    jobTagsInformationDisplayed: JobTagsInformationItem[];
    mainContentInformationDisplayed: MainContentInformationItem[];
};

const mapAdditionalInformationDisplayedToNewSections = (
    additionalInformationDisplayed: AdditionInformationItem[]
): SearchResultsNewSectionsDisplayed => {
    const headerInformationDisplayed = ['jobTitle'] as HeaderInformationItem[];

    if (additionalInformationDisplayed.includes('alreadyAppliedTag')) {
        headerInformationDisplayed.push('alreadyApplied');
    }

    const jobInfoDisplayed = additionalInformationDisplayed.filter(
        (param) => param === 'location' || param === 'postingDate' || param === 'distance'
    ) as JobInfoDisplayedItem[];

    const jobTagsInformationDisplayed = additionalInformationDisplayed.filter(
        (param) => param === 'hotJob' || param === 'trendingJob' || param === 'beFirstToApply'
    ) as JobTagsInformationItem[];

    const mainContentInformationDisplayed = additionalInformationDisplayed.filter(
        (param) => param === 'description' || param === 'previewImage'
    ) as MainContentInformationItem[];

    return {
        headerInformationDisplayed,
        jobInfoDisplayed,
        jobTagsInformationDisplayed,
        mainContentInformationDisplayed,
    };
};

export const mapClassicContentParamsToNewContentParams = (
    contentParams: SearchResultsClassicContentParams
): SearchResultsContentParams => {
    const mappedNewSectionsDisplayed = mapAdditionalInformationDisplayedToNewSections(
        contentParams.additionalInformationDisplayed
    );

    const mappedNewContentParams = defaultContentParams;

    mappedNewContentParams.displayAfterJobs = contentParams.displayAfterJobs;
    mappedNewContentParams.enableInfiniteScroll = contentParams.enableInfiniteScroll;
    mappedNewContentParams.hideTalentCommunitySignup = contentParams.hideTalentCommunitySignup;
    mappedNewContentParams.jobDisplayStyle = contentParams.jobDisplayStyle;
    mappedNewContentParams.numberOfJobsDisplayed = contentParams.numberOfJobsDisplayed;
    mappedNewContentParams.width = contentParams.width;
    mappedNewContentParams.widthUnit = contentParams.widthUnit;
    mappedNewContentParams.headerInformationDisplayed = mappedNewSectionsDisplayed.headerInformationDisplayed;
    mappedNewContentParams.jobInfoDisplayed = mappedNewSectionsDisplayed.jobInfoDisplayed;
    mappedNewContentParams.layoutStyle = 'classic';
    mappedNewContentParams.originalLayoutStyle = 'classic';

    mappedNewContentParams.jobTagsInformationDisplayed =
        mappedNewSectionsDisplayed.jobTagsInformationDisplayed;

    mappedNewContentParams.mainContentInformationDisplayed =
        mappedNewSectionsDisplayed.mainContentInformationDisplayed;

    return mappedNewContentParams;
};
