import router from 'app/model/router';
import tokenService from 'app/module/cx/module/candidate-verification/service/token';
import { URL_REGEX } from 'core/form/config/regex';
import appConfig from 'app/model/config';
import botsEvent from 'minimal/config/events';

const PS_DELIMITER = '|||';
const ODA_WEBVIEW_URL_REGEX = /connectors\/v.*\/webviews/;

function _handleJobButtonClick(event) {
    const href = event.target?.href ?? event.currentTarget?.href;

    if (href.includes('apply')) {
        window.open(href, '_blank');
    } else {
        const jobId = decodeURIComponent(href.split('/').pop());

        router.go('job-details', { jobId });
    }
}

function _handleWebviewClick(event) {
    const href = event.target?.href ?? event.currentTarget?.href;

    window.open(href, '_blank');
}

function buttonClickHandler(event) {
    const href = event.target?.href ?? event.currentTarget?.href;

    if (!href) {
        return;
    }

    const urlObject = createURLObject(href);

    if (!urlObject) {
        return;
    }

    const isCareerSiteLink = _isCareerSiteLink(urlObject);

    const url = window.VanityUrlEnabled ? urlObject.hash : urlObject.pathname;

    if (isCareerSiteLink && url.includes('job')) {
        event.preventDefault();

        _handleJobButtonClick(event);
    } else if (isCareerSiteLink && url.includes('interview')) {
        event.preventDefault();

        const submissionId = urlObject.searchParams.get('submissionId');
        const interviewId = urlObject.pathname.split('/').pop();

        if (submissionId && interviewId && tokenService.isCandidateVerified()) {
            router.go('interview-detail', { id: interviewId, submissionId });
        } else {
            router.go('candidate-self-service.sign-in');
        }
    } else if (isCareerSiteLink && url.includes('my-profile/sign-in')) {
        event.preventDefault();

        router.go('candidate-self-service.sign-in');
    } else if (isCareerSiteLink && url.includes('tc-join')) {
        event.preventDefault();

        router.go('talent-community');
    } else if (ODA_WEBVIEW_URL_REGEX.test(url)) {
        event.preventDefault();

        _handleWebviewClick(event);
    }
}

function _isCareerSiteLink(urlObject) {
    const { hostname, href } = urlObject;
    const baseUrlObject = createURLObject(window.VanityUrlEnabled ? appConfig.vanityBaseUrl : appConfig.ceBaseUrl);

    return window.VanityUrlEnabled ? href.includes(baseUrlObject?.href) : hostname === baseUrlObject?.hostname;
}

function _addTokenToUserProps(userProfileProperties) {
    const { accessCode, candidateNumber } = tokenService.get() ?? {};

    Object.assign(userProfileProperties, {
        accessCode,
        candidateNumber,
    });
}

function _addQRShortCodeToUserProps(userProfileProperties) {
    const { query } = router.routeParams() ?? {};
    const { qrShortCode } = query ?? {};

    Object.assign(userProfileProperties, {
        qrShortCode,
    });
}

function beforeSendHandler(message, bots, userProfileProperties) {
    _addTokenToUserProps(userProfileProperties);
    _addQRShortCodeToUserProps(userProfileProperties);

    bots.updateUser({
        profile: {
            properties: userProfileProperties,
        },
    });

    return message;
}

const _generateSelectOptions = ({ rootObjectId, selectType, responseList, responseIdList }) => {
    const options = [];

    responseList.forEach((response, index) => {
        options.push(`<label for="${rootObjectId}${responseIdList[index]}">`);
        options.push(` <input odaUIType="${selectType}" id="${rootObjectId}${responseIdList[index]}"`);

        if (selectType === 'SingleSelect') {
            options.push(` name="${rootObjectId}"`);
        }

        options.push(` odaId="${responseIdList[index]}" type="${selectType === 'SingleSelect' ? 'radio' : 'checkbox'}"/> ${response}`);
        options.push('</label><br>');
    });

    return options;
};

const _geneateButtonContent = ({ rootObjectId, selectType, variable, actionLabel }) => {
    const domContent = [];

    domContent.push('<br><div class="oda-chat-message-actions" style="align-items: flex-start;">');
    domContent.push(`<button type="button" title="${actionLabel}" class="oda-chat-action-postback" `);
    domContent.push('onclick="bots.sendMessage(bots.ORCAdapter.createSelectedOptionsMessage(');

    domContent
        .push(`{ rootObjectId: '${rootObjectId}', selectType: '${selectType}', variable: '${variable}' }), {'hidden': true});">${actionLabel}`);

    domContent.push('</button></div>');

    return domContent.join('');
};

const _generateMessageForSelect = ({
    selectType,
    rootObjectId,
    label,
    responseList,
    responseIdList,
    variable,
    actionLabel,
}) => {
    let domContent = [];

    domContent.push(`<div id="oda${rootObjectId}" style="line-height: 1.5">`);
    domContent.push(`<div>${label}</div><br>`);
    domContent = domContent.concat(_generateSelectOptions({ rootObjectId, selectType, responseList, responseIdList }));
    domContent.push(_geneateButtonContent({ rootObjectId, selectType, variable, actionLabel }));
    domContent.push('</div>');

    return domContent.join('');
};

const _obtainSelectOptions = (rootObjectId, selectType) => {
    const selections = Array.from(document.querySelectorAll(`#oda${rootObjectId} [odaUIType='${selectType}']`).values())
        .filter(item => item.checked);

    const selectionsId = selections.reduce((response, item) => `${response} ${item.getAttribute('odaId')}`, '').trim();

    return selectionsId;
};

const _createSelectedOptionsMessage = ({ rootObjectId, selectType, variable }) => {
    const selectedOptions = _obtainSelectOptions(rootObjectId, selectType);

    const message = {
        messagePayload: {
            postback: {
                variables: {},
                action: 'submitSelections',
            },
            text: 'Submit',
            type: 'postback',
        },
    };

    message.messagePayload.postback.variables[variable] = selectedOptions;

    return message;
};

const _handleSelect = (message, uiComponent) => {
    const { responses, responseIds, variable, actionLabel } = uiComponent.properties;

    const responseList = responses.split(PS_DELIMITER);
    const responseIdList = responseIds.split(PS_DELIMITER);

    const rootObjectId = Date.now();

    message.messagePayload.text = _generateMessageForSelect({
        selectType: uiComponent.type,
        rootObjectId,
        label: message.messagePayload.text,
        responseList,
        responseIdList,
        variable,
        actionLabel,
    });
};

const _urlRedirect = (url) => {
    _handleJobButtonClick({
        target: {
            href: url,
        },
    });
};

const beforeDisplay = (message, bots) => {
    const { action, candidateWorkSummary, uiComponent } = message?.messagePayload?.channelExtensions || {};


    if (!uiComponent) {
        if (candidateWorkSummary) {
            botsEvent.showOdaPopup.dispatch({
                bodyText: candidateWorkSummary,
                isVisible: true,
                bots,
            });

            const chatWrapperElement = document.querySelector('.oda-chat-wrapper');

            if (chatWrapperElement) {
                chatWrapperElement.style.pointerEvents = 'none';
            }

            if (bots && bots.isChatOpened()) {
                bots.closeChat();
            }

            return null;
        }

        if (action === 'redirect') {
            const { eaApplyUrlSchema, eaApplyUrlNoSchema } = message?.messagePayload?.channelExtensions || {};

            if (eaApplyUrlSchema && eaApplyUrlNoSchema) {
                _urlRedirect(`${eaApplyUrlSchema}${eaApplyUrlNoSchema}`);
            }

            return null;
        }

        return message;
    }

    if (uiComponent.type === 'MultiSelect' || uiComponent.type === 'SingleSelect') {
        _handleSelect(message, uiComponent);
    }

    return message;
};

const ORCAdapter = {
    createSelectedOptionsMessage:
        ({ rootObjectId, selectType, variable }) =>
            _createSelectedOptionsMessage({ rootObjectId, selectType, variable }),
    urlRedirect: (schema, domainAndPath) => _urlRedirect(schema + domainAndPath),
};

export default {
    buttonClickHandler,
    beforeSendHandler,
    beforeDisplay,
    ORCAdapter,
};

function createURLObject(url) {
    if (!URL_REGEX.test(url)) {
        return null;
    }

    return new URL(url);
}