import { pureComputed } from 'knockout';

import applicationsModel from '../../model/applications';
import draftApplicationsModel from '../../model/draftApplications';
import { isActionRequiredForApplication, sortApplicationsByAction, focusNthEntity } from './service/applicationsListHelpers';
import { ERROR, LOADED, PENDING } from 'cx/module/candidate-self-service/enum/loadingStatus';
import { ApplicationsListState } from 'cx/module/candidate-self-service/component/cs-applications/service/ApplicationsListState';

const SECTION_NAMES = {
    ACTIVE: 'active',
    RESOLVED: 'resolved',
    DRAFTS: 'drafts',
};

const APPLICATIONS_CONTAINER_SELECTOR_PREFIX = '.cs-applications__container-';
const JOB_TITLE_SELECTOR = '.cs-job-info__title';

export default class CsApplicationsViewModel {

    constructor() {
        const activeApplicationsState = new ApplicationsListState({
            entities: pureComputed(() => applicationsModel.applications()
                .filter(application => application.isActive())
                .sort(sortApplicationsByAction)),
            loadingStatus: applicationsModel.loadingStatus,
            titleKey: 'candidate-self-service.active-applications',
            name: SECTION_NAMES.ACTIVE,
        });

        const resolvedApplicationsState = new ApplicationsListState({
            entities: pureComputed(() => applicationsModel.applications()
                .filter(application => !application.isActive())),
            loadingStatus: applicationsModel.loadingStatus,
            titleKey: 'candidate-self-service.resolved-applications',
            name: SECTION_NAMES.RESOLVED,
        });

        const draftsState = new ApplicationsListState({
            entities: pureComputed(() => draftApplicationsModel.drafts()),
            loadingStatus: draftApplicationsModel.loadingStatus,
            titleKey: 'candidate-self-service.draft-applications',
            name: SECTION_NAMES.DRAFTS,
        });

        this.stateArray = [activeApplicationsState, draftsState, resolvedApplicationsState];

        this.loadingStatusSubscription = activeApplicationsState.loadingStatus.subscribe(newStatus =>
            this._updateStateLimitWithActionRequiredApplicationsCount(newStatus, activeApplicationsState));
    }


    _updateStateLimitWithActionRequiredApplicationsCount(loadingStatus, state) {
        switch (loadingStatus) {
            case LOADED: {
                const actionRequiredApplicationsCount = state.entities()
                    .filter(isActionRequiredForApplication).length;

                if (actionRequiredApplicationsCount > state.limit()) {
                    state.limit(actionRequiredApplicationsCount);
                }
                break;
            }

            case PENDING:
            case ERROR:
                break;

            default:
                console.error(`Undefined loading status was handled: ${loadingStatus}`);
        }
    }

    handleShowMore(container) {
        const displayedApplicationsCount = container.paginatedEntities().length;
        const parentSelector = `${APPLICATIONS_CONTAINER_SELECTOR_PREFIX}${container.name}`;

        container.showMore();

        focusNthEntity({ parentSelector, itemSelector: JOB_TITLE_SELECTOR, index: displayedApplicationsCount });
    }


    dispose() {
        this.loadingStatusSubscription.dispose();
    }

}
