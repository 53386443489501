import { observableArray, observable } from 'knockout';
import { LOADED, PENDING } from '../enum/loadingStatus';

const draftApplicationsModel = {
    drafts: observableArray([]),
    loadingStatus: observable(PENDING),

    clear() {
        draftApplicationsModel.drafts([]);
        draftApplicationsModel.loadingStatus(PENDING);
    },

    setLoadedData(drafts) {
        draftApplicationsModel.drafts(drafts);
        draftApplicationsModel.loadingStatus(LOADED);
    },

    remove(draft) {
        draftApplicationsModel.drafts.remove(draft);
    },
};

export default draftApplicationsModel;