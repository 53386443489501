/**
 * Service is used to build and read query param values in pipes schema
 * Query string value format example (two pipe groups): AttributeChar1|Value1;Value2||AttributeChar2|Value1
 * Single pipe group: AttributeChar1|Value1;Value2
 * Pipe key: AttributeChar1
 * Pipe values: Value1;Value2
 * */

const GROUP_SEPARATOR = '||';
const GROUP_KEY_SEPARATOR = '|';
const GROUP_VALUE_SEPARATOR = ';';

function getGroupKey(group) {
    const [pipeKey] = group.split(GROUP_KEY_SEPARATOR);

    return pipeKey;
}

export function buildQueryValue(queryValue, pipeKey, pipeValues) {
    const newPipeGroup = `${pipeKey}|${pipeValues.join(GROUP_VALUE_SEPARATOR)}`;

    if (!queryValue) {
        return newPipeGroup;
    }

    const groups = queryValue.split(GROUP_SEPARATOR);
    const groupsKeys = groups.map(getGroupKey);

    if (!groupsKeys.includes(pipeKey)) {
        return `${queryValue}${GROUP_SEPARATOR}${newPipeGroup}`;
    }

    const setNewPipeGroup = (group) => {
        const [groupKey] = group.split(GROUP_KEY_SEPARATOR);

        if (groupKey === pipeKey) {
            if (!pipeValues.length) {
                return null;
            }

            return newPipeGroup;
        }

        return group;
    };

    return groups.map(setNewPipeGroup)
        .filter(group => group)
        .join(GROUP_SEPARATOR);
}

export function getKeyValues(queryValue, pipeKey) {
    if (!queryValue) {
        return [];
    }

    const groups = queryValue.split(GROUP_SEPARATOR);
    const foundGroup = groups.find(group => getGroupKey(group) === pipeKey);

    if (!foundGroup) {
        return [];
    }

    const [, values] = foundGroup.split(GROUP_KEY_SEPARATOR);

    return values
        ? values.split(GROUP_VALUE_SEPARATOR)
        : [];
}