import FormValidator from 'core/form/validator/Validator';

export default class IntegerFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.integer';
    }

    _validate(value) {
        if (!value) {
            return true;
        }

        const containsSeparator = String(value).indexOf('.') > -1;

        return value % 1 === 0 && !containsSeparator;
    }

}