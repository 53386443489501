import { Observable } from 'knockout';

type Props = {
    helpTextId: string;
    className: string;
    isVisible: Observable<boolean>;
    helpText: string;
};

class TooltipViewModel {
    helpTextId: string;
    className: string;
    isVisible: Observable<boolean>;
    helpText: string;
    nodes: { [key: string]: NodeListOf<ChildNode> };

    constructor({ helpTextId, className, isVisible, helpText }: Props) {
        this.helpTextId = helpTextId;
        this.className = className;
        this.isVisible = isVisible;
        this.helpText = helpText;
        this.nodes = {};
    }
}

type ComponentInfo = {
    templateNodes: HTMLElement[];
};

export default {
    createViewModel(params: Props, componentInfo: ComponentInfo): TooltipViewModel {
        const viewModel: TooltipViewModel = new TooltipViewModel(params);

        componentInfo.templateNodes
            .filter((node) => node.tagName)
            .forEach((node) => {
                const nodeName: string = node.tagName.toLowerCase();

                viewModel.nodes[nodeName] = node.childNodes;
            });

        return viewModel;
    },
};
