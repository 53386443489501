type LogoSize = {
    width?: string;
    height?: string;
};

export const calculateLogoSize = (logoImgElement: HTMLImageElement): LogoSize => {
    let imgWidth = logoImgElement.naturalWidth;
    let imgHeight = logoImgElement.naturalHeight;

    const maxWidth = getComputedStyle(logoImgElement).maxWidth;
    const maxHeight = getComputedStyle(logoImgElement).maxHeight;

    const maxWidthWithoutUnit = parseInt(maxWidth, 10);
    const maxHeightWithoutUnit = parseInt(maxHeight, 10);

    if (imgWidth === 0) {
        // Workaround for Firefox as it returns naturalWidth = 0 for dimensionless SVG images
        // (render an invisible image by setting fixed width and get its size)
        if (logoImgElement.parentElement) {
            const { renderedWidth, renderedHeight } = getRenderedLogoImgSize(
                logoImgElement,
                logoImgElement.parentElement,
                maxWidthWithoutUnit
            );

            imgWidth = renderedWidth;
            imgHeight = renderedHeight;
        }
    }

    const widthRatio = maxWidthWithoutUnit / imgWidth;
    const heightRatio = maxHeightWithoutUnit / imgHeight;

    return widthRatio < heightRatio ? { width: maxWidth } : { height: maxHeight };
};

const getRenderedLogoImgSize = (
    logoImgElement: HTMLImageElement,
    logoImgParentElement: HTMLElement,
    maxWidthWithoutUnit: number
): { renderedWidth: number; renderedHeight: number } => {
    const previousParentElementDisplayAttribute = logoImgParentElement.style.display;
    const previousParentElementPositionAttribute = logoImgParentElement.style.position;
    const previousParentElementVisibilityAttribute = logoImgParentElement.style.visibility;
    const previousImgElementWidth = logoImgElement.width;

    logoImgParentElement.style.display = 'block';
    logoImgParentElement.style.position = 'fixed';
    logoImgParentElement.style.visibility = 'hidden';

    logoImgElement.width = maxWidthWithoutUnit;

    const renderedWidth = logoImgElement.width;
    const renderedHeight = logoImgElement.height;

    logoImgParentElement.style.display = previousParentElementDisplayAttribute;
    logoImgParentElement.style.position = previousParentElementPositionAttribute;
    logoImgParentElement.style.visibility = previousParentElementVisibilityAttribute;
    logoImgElement.width = previousImgElementWidth;

    if (logoImgElement.width === 0) {
        logoImgElement.removeAttribute('width');
    }

    return { renderedWidth, renderedHeight };
};
