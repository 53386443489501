import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class EmailFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.attributes('type', 'email');

        this.validators('email', {
            fieldname: () => this.label(),
        });
    }

}