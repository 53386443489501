import contextLang from '../service/contextLang';
import i18n from '../i18n';
import ko from 'knockout';

/**
 * Sets element's specified attributes to localized values
 *
 * @param {object} value - map of attribute's name => translation's key
 *
 * @example
 * <div data-bind="i18nAttr: { 'placeholder': 'translationKey' }"></div>
 */
ko.bindingHandlers.i18nAttr = {
    update(element, valueAccessor, allBindings, viewModel, bindingContext) {
        const attributes = ko.utils.unwrapObservable(valueAccessor()) || {};
        const lang = contextLang.get(bindingContext);

        ko.utils.objectForEach(attributes, (attributeName, key) => {
            const translationKey = ko.utils.unwrapObservable(key);

            if (!translationKey) {
                element.removeAttribute(attributeName);

                return;
            }

            const translation = (typeof translationKey === 'object')
                ? i18n(translationKey.key, translationKey.tokens, lang)
                : i18n(translationKey, null, lang);

            element.setAttribute(attributeName, translation);
        });
    },
};
