import { components } from 'knockout';

import ResendPinViewModel from './ResendPinViewModel';
import template from './resend-pin.html';

components.register('resend-pin', {
    viewModel: ResendPinViewModel,
    template,
});

