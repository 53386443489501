import { bindingHandlers, utils } from 'knockout';
import { setFocusWithDelay } from 'core/a11y/service/setFocusWithDelay';
import keyCodes from 'core/config/keycodes';

/**
 * Binding used to force browser focus to move to
 * the provided element on target tab key event.
 *
 * @param {string} focusOn - element selector to focus on
 * @param {integer} delay - time in miliseconds after focus will be set - needed in cases where animation is fired
 *
 * @example
 *   <div id="focus-on-me-after-tab"></div>
 *   <div data-bind="a11y.setFocusOnTab: {focusOn: '#focus-on-me-after-tab'}"
 *   <div data-bind="a11y.setFocusOnTab: {focusOn: '#focus-on-me-after-tab', delay: 400}"
 */
bindingHandlers['a11y.setFocusOnTab'] = {
    init(element, accessor) {
        const { focusOn, delay, when } = accessor();

        if (!focusOn) {
            return;
        }

        function onKeyDown(event) {
            const wasTabPressed = event.which === keyCodes.TAB;
            const wasShiftPressed = event.shiftKey;

            if (wasTabPressed && !wasShiftPressed && when) {
                setFocusWithDelay(focusOn, delay);
            }
        }

        element.addEventListener('keydown', onKeyDown);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('keydown', onKeyDown);
        });
    },
};