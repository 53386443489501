import router from 'app/model/router';
import { observable } from 'knockout';

export default class UnscheduledInterviewActionViewModel {

    constructor(params) {
        this.interviewRequest = params.interviewRequest;
        this.canSchedule = observable(false);

        if (this.interviewRequest) {
            this.canSchedule(this.interviewRequest.isSchedulable());
        }
    }

    scheduleInterview() {
        router.go('interview-self-schedule', {
            id: this.interviewRequest.scheduleId(),
            requestId: this.interviewRequest.interviewRequestId(),
            submissionId: this.interviewRequest.submissionId(),
            action: 'SCHEDULE',
        });
    }

}