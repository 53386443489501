import dictionary from 'apply-flow/service/dictionary';
import TileForm from 'apply-flow/module/tile-profile-items/form/TileForm';
import SchoolImportFailedValidator from './validator/SchoolImportFailedValidator';
import timelineEvents from 'apply-flow/module/work-and-education-timeline/config/events';
import { capitalize } from 'apply-flow/service/stringValidation';

class SchoolValueStrategy {

    constructor(schoolElement, schoolNameElement) {
        this.schoolElement = schoolElement;
        this.schoolNameElement = schoolNameElement;
    }

}

class AutosuggestStrategy extends SchoolValueStrategy {

    constructor(...params) {
        super(...params);

        this.schoolElement.valueLabel.subscribeOnce(valueLabel => this.schoolNameElement.value(valueLabel));
    }

    setupValueSubscription() {
        return this.schoolNameElement.value.subscribe(this._setValue, this);
    }

    _setValue() {
        const schoolNameValueKey = this.schoolNameElement.valueKey();
        const schoolValue = schoolNameValueKey ? parseInt(schoolNameValueKey, 10) : null;

        this.schoolElement.value(schoolValue);
    }

}

class DropdownStrategy extends SchoolValueStrategy {

    setupValueSubscription() {
        return this.schoolElement.value.subscribe(this._setValue, this);
    }

    _setValue() {
        if (!this.schoolNameElement.isRequired()) {
            return;
        }

        this.schoolNameElement.value(this.schoolElement.valueLabel());

        const schoolValueLabelSubscription = this.schoolElement.valueLabel.subscribe((valueLabel) => {
            this.schoolNameElement.value(valueLabel);

            schoolValueLabelSubscription.dispose();
        }, this);
    }

}

const factory = {
    getSchoolStrategy(isSchoolFieldRequired) {
        return isSchoolFieldRequired ? DropdownStrategy : AutosuggestStrategy;
    },
};

export default class EducationForm extends TileForm {

    constructor(...args) {
        super(...args);

        this.schoolElement = this.getElement('educationalEstablishmentId');
        this.schoolNameElement = this.getElement('educationalEstablishment');

        this._combineSchoolFields();

        const Strategy = factory.getSchoolStrategy(this.schoolElement.isRequired());

        const schoolValueStrategy = new Strategy(this.schoolElement, this.schoolNameElement);

        this._modelSubscription = this.model.subscribe(() => {
            this._matchSchoolValue();

            this._valueSubscription = schoolValueStrategy.setupValueSubscription();
        }, this);
    }

    _matchSchoolValue() {
        const schoolValue = this.schoolElement.value();
        const schoolNameValue = this.schoolNameElement.value();

        if (schoolNameValue && !schoolValue) {
            const criteriaKey = capitalize(this.schoolElement.optionsKeys().label);

            dictionary.findOne(this.schoolElement.dictionary(), { [criteriaKey]: schoolNameValue })
                .then((response) => {
                    this._setSchoolValue(response);
                    this.buildTile();
                    timelineEvents.renderTimeline.dispatch();
                });
        }

        if (schoolValue && !schoolNameValue) {
            const criteriaKey = capitalize(this.schoolElement.optionsKeys().value);

            dictionary.findOne(this.schoolElement.dictionary(), { [criteriaKey]: schoolValue })
                .then((response) => {
                    this._setSchoolNameValue(response);
                    this.buildTile();
                    timelineEvents.renderTimeline.dispatch();
                });
        }

        this._modelSubscription.dispose();
    }

    _setSchoolValue(response) {
        const key = this.schoolElement.optionsKeys().value;

        if (response) {
            this.schoolElement.value(response[key]);
        } else {
            this._addSchoolElementImportFailedValidator();
        }
    }

    _setSchoolNameValue(response) {
        if (!response) {
            return;
        }

        const key = this.schoolNameElement.optionsKeys().label;

        this.schoolNameElement.value(response[key]);
    }

    _addSchoolElementImportFailedValidator() {
        if (!this.schoolElement.isRequired()) {
            return;
        }

        const importFailedValidator = new SchoolImportFailedValidator({
            label: this.schoolElement.label(),
        });

        this.schoolElement.addValidator(importFailedValidator, null, 'prepend');

        this._validateSchoolElement();

        this._schoolElementValueSubscription = this.schoolElement.value.subscribe(() => {
            this.schoolElement.removeValidator(importFailedValidator);

            this._validateSchoolElement();

            this._schoolElementValueSubscription.dispose();
        }, this);
    }

    _validateSchoolElement() {
        this.schoolElement.validateImmediately(true);
        this.schoolElement.validate();
    }

    _combineSchoolFields() {
        const schoolRequired = this.schoolElement.isRequired();

        this.schoolElement.isHidden(!schoolRequired);
        this.schoolNameElement.isHidden(schoolRequired);
    }

}