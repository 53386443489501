import EventDetailsViewModel from './EventDetailsViewModel';
import historyService from 'ce-common/service/historyService';
import router from 'app/model/router';
import { hasRegisteredEvent } from '../../event-register-flow/service/eventRegistration';
import { storeShortTokenData } from 'app/module/cx/module/candidate-verification/service/shortTokenStorage';
import { RouterParams } from '../config/types';

export default class EventDetailsFullscreenViewModel extends EventDetailsViewModel {
    constructor() {
        super();
        this.isPreviousRouteStored = historyService.isPreviousRouteStored();
        this.viewType = 'fullscreen';
    }

    async loadEvent(): Promise<void> {
        // overridden to handle short token scenario
        this.handleShortToken();
        super.loadEvent();
    }

    private handleShortToken(): void {
        const { eventId, token } = router.routeParams() as RouterParams;

        if (!token) {
            return;
        }

        hasRegisteredEvent(eventId)
            .then((isUserAlreadyRegisteredToThisEvent) => {
                if (isUserAlreadyRegisteredToThisEvent) {
                    return;
                }

                storeShortTokenData({ token: token, eventId: eventId });
            })
            .catch(console.error);
    }

    close(): void {
        historyService.moveBackOrFallbackTo('search-events');
    }
}
