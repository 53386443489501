import downloader from 'ce-common/module/downloader/service/downloader';
import draftAttachmentService from './draftAttachmentService';
import { applicationDraft } from '../../../service/applicationDraftInstance';

function getAttachmentFromDraft(attachmentId) {
    return draftAttachmentService.queryOne(applicationDraft.id, attachmentId);
}

export async function download(attachment) {
    const { id, fileContents } = attachment;
    const isDraftDataNeeded = applicationDraft && !fileContents();
    const attachmentPromise = isDraftDataNeeded ? getAttachmentFromDraft(id()) : Promise.resolve(attachment);

    downloader.download(attachmentPromise.then((attachmentWithContents) => {
        if (!attachmentWithContents.fileContents()) {
            return Promise.reject(new Error('No file contents found'));
        }

        return {
            fileName: attachmentWithContents.fileName(),
            fileContents: attachmentWithContents.fileContents(),
            mimeType: attachmentWithContents.getMimeType(),
        };
    }));
}