import i18n from 'core/i18n/i18n';
import applyFlowEvents from 'apply-flow/config/events';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import fixer from 'apply-flow/component/apply-flow-fixer/service/fixer';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import talentCommunityEvents from '../../config/events';
import compositeValidation from 'apply-flow/service/backend-validation/compositeValidation';

const INVALID_APPLICATION_MESSAGE = 'apply-flow.validation.invalid-application';

export default class TalentCommunitySubscribeViewModel {

    constructor({ flow, label, isVisible, isValidationInProgress }) {
        this.flowIterator = flow().iterator;
        this.label = label;

        this.isVisible = isVisible;
        this.isEnabled = sectionState.isAllSectionsReady();

        this.isValidationInProgress = isValidationInProgress;
    }

    validateAndSubmit() {
        this._runBeforeValidation();

        this.isValidationInProgress(true);

        return sectionValidator.validateAllSections()
            .then(this._onValidationCompleted.bind(this));
    }

    _onValidationCompleted(isValid) {
        if (isValid) {
            this._runBeforeExit();

            compositeValidation.validate()
                .then(() => {
                    this.isVisible(false);
                    applyFlowEvents.submit.dispatch();
                })
                .catch(() => this.isVisible(true))
                .finally(() => this.isValidationInProgress(false));

            return;
        }

        this.isVisible(true);

        this.isValidationInProgress(false);

        talentCommunityEvents.toggleFields.dispatch();

        fixer.run(false, 200);

        accessibilityEvents.addAlert.dispatch(i18n(INVALID_APPLICATION_MESSAGE));
    }

    _runBeforeExit() {
        this.flowIterator().runBeforeExit();
    }

    _runBeforeValidation() {
        this.flowIterator().runBeforeValidation();
    }

}
