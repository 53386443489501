import TileForm from '../../tile-profile-items/form/TileForm';

export default class ExtraInformationTileForm extends TileForm {

    constructor(uniqueElement, ...args) {
        super(...args);

        this.uniqueElement = uniqueElement;
    }

    buildTile() {
        this.tileTitle(this.tileStrategy.buildTitle(this.uniqueElement));
    }

}