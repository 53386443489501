import { observable } from 'knockout';
import sectionValidator from 'apply-flow/model/sectionValidator';
import applyFlowEvents from 'apply-flow/config/events';

export default class ApplyFlowSinglePageNavigationViewModel {

    constructor({ sections }) {
        this.sections = sections;
        this.sectionValidator = sectionValidator;

        this.isOpen = observable(false);
        this.isVisible = observable(true);
        this.activeSection = observable(1);

        this._isOpenSubscription = this.isOpen.subscribe(applyFlowEvents.navigationOpen.dispatch);

        this._submitSucceedSignal = applyFlowEvents.submitSucceed.add(() => this.isVisible(false));

        this.closeNavigation = this.closeNavigation.bind(this);
    }

    isActive(sectionNumber) {
        const activeSection = this.activeSection() || 1;

        return activeSection === sectionNumber;
    }

    isFocusable() {
        return this.sections ? 0 : -1;
    }

    toggleNavigation() {
        this.isOpen(!this.isOpen());
    }

    closeNavigation() {
        this.isOpen(false);
    }

    dispose() {
        this._isOpenSubscription.dispose();
        this._submitSucceedSignal.detach();
    }

}
