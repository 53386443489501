import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    action: 'CREATE',
    flowVersionId: null,
    sectionId: null,
    contentTypeId: null,
    contentItemId: null,
    educationId: null,
    degreeName: null,
    major: null,
    educationalEstablishmentId: null,
    educationalEstablishment: null,
    startDate: null,
    endDate: null,
    stateProvinceCode: null,
    countryCode: null,
    city: null,
    averageGrade: null,
    yearAcquired: null,
    gpa: null,
    graduatedFlag: null,
    trmnlDegDiscpFlag: null,
    dateAcquired: null,
    educator: null,
    minor: null,
    projectedCompletionDate: null,
    fee: null,
    completedAmount: null,
    totalAmount: null,
    comments: null,
    reimbursements: null,
    completedTraningUnits: null,
    status: null,
    feeCurrency: null,
    tuitionMethod: null,
    eduLevelCompletedFlag: null,
    awardingBody: null,
    title: null,
    highestEduLevel: null,
    areaOfStudy: null,
    department: null,
    duration: null,
    durationUnits: null,
    academicStanding: null,
    partTimeFlag: null,
    description: null,
    activitySummary: null,
    educationUrl: null,
    educationLevel: null,
};

export default class CandidateEducation extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'educationId');
    }

}
