import { bindingHandlers, utils } from 'knockout';

const DIALOG_EVENTS = {
    open: 'ojOpen',
    beforeClose: 'ojBeforeClose',
};

const SELECTORS = {
    chatBotMain: '.oda-chat-wrapper',
    chatBotConversation: '.oda-chat-conversation',
    chatBotConversationPane: '.oda-chat-conversation-pane',
    submitChecklistStep: '.checklist__step',
    body: 'body',
};

const getChatBotElement = (): HTMLElement | null => document.querySelector(SELECTORS.chatBotMain);

const scrollChatBot = (): void => {
    const conversationContainer: HTMLElement | null = document.querySelector(SELECTORS.chatBotConversation);
    const conversationPane: HTMLElement | null = document.querySelector(SELECTORS.chatBotConversationPane);

    if (!conversationContainer || !conversationPane) {
        return;
    }

    conversationContainer.scrollTop = conversationPane.clientHeight;
};

const moveChatBot = (parentElement: HTMLElement | null): void => {
    const chatBotElement = getChatBotElement();
    const isInParentAlready = Boolean(parentElement && chatBotElement?.parentElement === parentElement);
    const isSubmitInProgress = Boolean(document.querySelector(SELECTORS.submitChecklistStep));

    if (isInParentAlready || isSubmitInProgress || !chatBotElement || !parentElement) {
        return;
    }

    parentElement.appendChild(chatBotElement);
    scrollChatBot();
};

/**
 * Moves chatbot into Jet Dialog on open, moves back to body on close
 * Without it chatbot does not function properly over Jet Dialogs
 *
 * @example
 * <oj-dialog data-bind="moveChatbot"></oj-dialog>
 */

bindingHandlers.moveChatBot = {
    init(element: HTMLElement, accessor: () => { shouldMoveChatbot: boolean }): void {
        const { shouldMoveChatbot } = accessor();

        if (!shouldMoveChatbot) return;

        const bodyElement = document.querySelector(SELECTORS.body) as HTMLElement;

        const onDialogOpen = () => {
            const chatBotElement = getChatBotElement();
            const isAlreadyInDialog = chatBotElement?.parentElement?.classList.contains('app-dialog');

            if (isAlreadyInDialog) {
                return;
            }

            moveChatBot(element);
        };

        const onDialogClose = () => {
            const chatBotElement = getChatBotElement();
            const notInCurrentElement = element !== chatBotElement?.parentElement;

            if (notInCurrentElement) {
                return;
            }

            moveChatBot(bodyElement);
        };

        setTimeout(() => {
            element.addEventListener(DIALOG_EVENTS.open, onDialogOpen);
            element.addEventListener(DIALOG_EVENTS.beforeClose, onDialogClose);
        }, 0);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            moveChatBot(bodyElement);
            element.removeEventListener(DIALOG_EVENTS.open, onDialogOpen);
            element.removeEventListener(DIALOG_EVENTS.beforeClose, onDialogClose);
        });
    },
};
