import { bindingHandlers, utils } from 'knockout';

/**
 * Copies text to clipboard from indicated input field
 *
 * @param {string} inputSelector - css selector for target input field
* @param {function} afterCallback - function executed after value was copied
 *
 * @example
 * <input type="text" readonly class="selector" data-bind="value: value">
 * <button data-bind="copyToClipboard: { inputSelector: '.selector' }"></button>
 */
bindingHandlers.copyToClipboard = {
    init(element, accessor) {
        const { inputSelector, afterCallback } = accessor();
        const target = document.querySelector(inputSelector);

        function copy(e) {
            e.stopPropagation();

            target.focus();
            target.setSelectionRange(0, target.value.length);

            document.execCommand('copy');

            target.setSelectionRange(0, 0);
            element.focus();

            afterCallback();
        }

        element.addEventListener('click', copy);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', copy);
        });
    },
};
