import { observable, pureComputed } from 'knockout';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED, CONTACT_UPDATE_LIMIT_REACHED, CONTACT_UPDATED_RECENTLY } from 'candidate-verification/config/pinVerificationStatus';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import { NON_UNIQUE_COUNTRY_CODES } from '../config/nonUniqueCountryCodes';
import { isLegislationCodeEnabled } from '../service/isLegislationCodeEnabled';
import { communicationChannelEvents } from '../config/events';
import notificationsService from 'cx/service/notifications';

const errorCodes = [
    ATTEMPS_LIMIT_REACHED,
    PIN_LIMIT_REACHED,
    CONTACT_UPDATED_RECENTLY,
    CONTACT_UPDATE_LIMIT_REACHED,
];

export default class CommunicationChannelEditor {

    constructor(formElement) {
        this.formElement = formElement;

        this.edit = this.edit.bind(this);
        this.cancel = this.cancel.bind(this);
        this.throttlingMessage = observable();
        this.saveActionInProgress = observable(false);
        this.editMode = observable(false);
        this.saveButtonDisabled = pureComputed(this._computeSaveButtonDisabled, this);
    }

    edit() {
        this.formElement.editMode(false);

        if (this.formElement.isDisabled()) {
            this._saveInitialState();
            this.formElement.isDisabled(false);
            this.editMode(true);

            if (isLegislationCodeEnabled() && typeof this.initialState.value === 'object'
                && NON_UNIQUE_COUNTRY_CODES.includes(this.initialState.value.countryCode)) {
                communicationChannelEvents.editInProgress.dispatch();
            }

            return;
        }

        if (this.initialState && this._isEqualToInitialValue(this.formElement.value())) {
            this.cancel();

            return;
        }

        if (!this.initialState) {
            this._saveInitialState();
        }


        this.saveActionInProgress(true);

        this.formElement.validate()
            .then(this._onInputValidated.bind(this))
            .finally(() => this.saveActionInProgress(false));
    }

    cancel() {
        this.formElement.isVerificationInProgress(false);

        if (this.initialState) {
            this.formElement.isDisabled(this.initialState.isDisabled);
            this.formElement.editMode(!this.initialState.isDisabled);
            this.formElement.setValue(this.initialState.value);
            this.initialState = null;
        }

        this.formElement.validateImmediately(true);
    }

    _computeSaveButtonDisabled() {
        return this.saveActionInProgress() || !this.formElement.isSaveButtonEnabled();
    }

    async _onInputValidated(isValid) {
        if (!isValid) {
            this.formElement.editMode(true);

            return;
        }

        const action = this.formElement.isEmpty()
            ? this._requestRemoval.bind(this)
            : this._requestValidation.bind(this);

        await action()
            .then(this.editMode(false))
            .catch(this._onActionError.bind(this));
    }

    async _requestValidation() {
        return this.formElement.requestVerification(this.formElement.value())
            .then(() => {
                if (!this.formElement.noPinVerification) {
                    this.throttlingMessage('');
                    this.formElement.isVerificationInProgress(true);

                    accessibilityEvents.addAlert.dispatch(this.formElement.accessibiliyVerificationMessage());
                }
            });
    }

    async _requestRemoval() {
        return await this.formElement.requestRemoval()
            .then(() => {
                if (this.formElement.initiallyRequired()) {
                    this._addRequiredValidator();
                }
            });
    }

    _onActionError(error) {
        if (error === 'IRC_CAND_NO_ALT_PREF_CHANNEL') {
            this.formElement.errorCode(error);
            this.formElement.validateImmediately(true);
            this.editMode(true);
        } else if (errorCodes.includes(error)) {
            this.formElement.isVerificationInProgress(true);
            this.throttlingMessage(error);
        } else {
            notificationsService.error();
            console.error(error);
        }
    }

    _addRequiredValidator() {
        this.formElement.addValidator('required', { label: this.formElement.label() }, 'prepend');
        this.formElement.validateImmediately(true);
    }

    _removeRequiredValidator() {
        const requiredValidator = this.formElement.getValidator('required');

        if (requiredValidator) {
            this.formElement.removeValidator(this.formElement.getValidator('required'));
            this.formElement.validateImmediately(false);
        }
    }

    _saveInitialState() {
        this.initialState = {
            isDisabled: this.formElement.isDisabled(),
            value: this.formElement.value(),
        };
    }

    _isEqualToInitialValue(value) {
        if (typeof this.initialState.value === 'object') {
            return JSON.stringify(value) === JSON.stringify(this.initialState.value);
        }

        return value === this.initialState.value;
    }

}