import { Observable, observable } from 'knockout';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import csEvents from 'cx/module/candidate-self-service/config/events';
import { Event } from '../../config/types';
import { withdrawEvent } from '../../service/eventRegistration';
import { registrationPhaseLabelMap } from '../../config/registrationPhaseLabelMap';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';

type RestError = {
    message: string;
};

export default class EventWithdrawDialogViewModel {
    isWithdrawDialogVisible: Observable<boolean>;
    isWithdrawInProgress: Observable<boolean>;
    event: Observable<Event | null>;

    constructor() {
        this.isWithdrawDialogVisible = observable<boolean>(false);
        this.isWithdrawInProgress = observable<boolean>(false);
        this.event = observable(null);

        csEvents.openEventWithdrawDialog.add((event: Event) => this.showWithdrawDialog(event));
    }

    hideWithdrawDialog(): void {
        this.isWithdrawDialogVisible(false);
    }

    showWithdrawDialog(event: Event): void {
        this.event(event);
        this.isWithdrawDialogVisible(true);
    }

    async withdrawApplication(): Promise<void> {
        this.isWithdrawInProgress(true);

        const event = this.event();

        if (event) {
            try {
                await withdrawEvent(event);
                cachedCandidateRegistrations.clear();
                csEvents.reloadEvents.dispatch();
                notificationsService.success(i18n('candidate-self-service.events.withdraw-success-message'));
            } catch (error) {
                this.onWithdrawActionError(error as RestError);
            }
        }

        this.hideWithdrawDialog();
        this.isWithdrawInProgress(false);
    }

    private onWithdrawActionError(error: RestError): void {
        if (typeof error === 'object' && error.message === 'withdraw-event-fail') {
            return notificationsService.error(this.getWithdrawFailMessage());
        }

        return notificationsService.error();
    }

    private getWithdrawFailMessage(): string {
        const event = this.event();

        if (event) {
            return i18n('candidate-self-service.events.withdraw-fail-message', {
                registrationState: registrationPhaseLabelMap[event.registrationPhase || 'registered'],
            });
        }

        return i18n('general.error-message-header');
    }
}
