import ko from 'knockout';
import oj from 'ojs/ojcore';
import FormValidator from 'core/form/validator/Validator';

export default class AfterDateValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.after-date';

        ko.utils.extend(this._options, {
            maxDateLabel: this._options.minDateFormElement.label(),
        });
    }

    _validate(value, options) {
        const minDateValue = options.minDateFormElement.value();

        return options.minDateFormElement.validate().then((minDateIsValid) => {
            if (value && minDateValue && minDateIsValid) {
                try {
                    const date = oj.IntlConverterUtils.isoToLocalDate(value);
                    const minDate = oj.IntlConverterUtils.isoToLocalDate(minDateValue);

                    return (options.inclusive ? date >= minDate : date > minDate);
                } catch (err) {
                    return false;
                }
            }

            return true;
        });
    }

}