import router from 'app/model/router';
import i18n from 'core/i18n/i18n';

export default class OfferErrorViewModel {

    constructor() {
        this.isOfferJustRefused = router.route().id === 'offer-just-refused';

        switch (router.route().id) {
            case 'offer-refused':
                this.description = i18n('offer.errors.offer-refused');
                break;
            case 'offer-just-refused':
                this.description = i18n('offer.errors.offer-just-refused');
                break;
            default:
                this.description = i18n('offer.errors.offer-inactive');
                break;
        }
    }

}
