import {
    desktopOnly,
    getMaxHeightCss,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { SearchBarParams } from '../config/types';
import { notReachable } from 'app/types/utils';
import { getOverallStyle } from '../service/overallStyles';
import { getSeparatorStyle } from '../service/separatorStyles';
import { SearchBarCustomizationSelectors } from '../config/selectors';
import {
    getParamsValueMergeFunction,
    mergeObservableParams,
} from 'minimal/module/search/service/observableParams';
import { defaultSearchBarParams } from 'minimal/module/search/component/search-bar/config/defaultParams';

export const getCustomStyles = (
    params: SearchBarParams,
    selectors: SearchBarCustomizationSelectors
): string => {
    const customizationKeys = Object.keys(params) as (keyof SearchBarParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'headerTypography':
                return getTypographyCss({
                    selector: selectors.headerTypography,
                    typography: params[key],
                });

            case 'searchValuesTypography':
                return getTypographyCss({
                    selector: selectors.searchValueTypography,
                    typography: params[key],
                });

            case 'searchValuesTypographySelected':
                const searchValuesTypographySelected = mergeObservableParams({
                    sourceParams: params.searchValuesTypography,
                    targetParams: params.searchValuesTypographySelected,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchBarParams.searchValuesTypographySelected,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.searchValuePlaceholderTypography,
                    typography: searchValuesTypographySelected,
                });

            case 'dropdownValuesTypography':
                return getTypographyCss({
                    selector: selectors.dropdownValuesTypography,
                    typography: params[key],
                });

            case 'searchBarContent':
                const widthSelector = getMaxWidthCss({
                    selector: selectors.commonParams,
                    width: params[key].width(),
                    widthUnit: params[key].widthUnit(),
                });

                const heightSelector = desktopOnly(
                    getMaxHeightCss({
                        selector: selectors.commonParams,
                        height: params[key].height(),
                        heightUnit: params[key].heightUnit(),
                    })
                );

                return [widthSelector, heightSelector].join(STYLES_SEPARATOR);

            case 'searchButtonStyle':
                return getStylingBoxCss({
                    selector: selectors.searchButtonStyle,
                    stylingBox: params[key],
                    lightIconSelector: selectors.lightIconSelector,
                    darkIconSelector: null,
                });

            case 'buttonHoverStyle':
                const buttonHoverStyle = mergeObservableParams({
                    sourceParams: params.searchButtonStyle,
                    targetParams: params.buttonHoverStyle,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchBarParams.buttonHoverStyle,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.buttonHoverStyle,
                    stylingBox: buttonHoverStyle,
                    darkIconSelector: null,
                    lightIconSelector: `${selectors.lightIconSelectorHover}, ${selectors.buttonHoverStyle}`,
                });

            case 'separators':
                return getSeparatorStyle({
                    separatorSelector: selectors.separators,
                    borderSelector: selectors.searchButtonStyle,
                    backgroundColor: params[key].borderColor(),
                });

            case 'overallStyle':
                return getOverallStyle({
                    selectors,
                    backgroundColor: params[key].backgroundColor(),
                    borderWidth: params[key].borderWidth(),
                    borderRadius: params[key].borderRadius(),
                    borderColor: params[key].borderColor(),
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    });

    return customStyles.join(STYLES_SEPARATOR);
};
