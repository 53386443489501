import FormValidator from 'core/form/validator/Validator';

export default class LessThanFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.less-than';

        if (this._options.inclusive) {
            this._message = 'validators.max-value';
        }

        this._options.max_value = this._options.max;
    }

    _validate(value, options) {
        if (value) {
            return (options.inclusive ? value <= options.max : value < options.max);
        }

        return true;
    }

}