import { Observable } from 'knockout';
import profileImport from 'apply-flow/module/profile-import/service/profileImport';

type Props = {
    isProfileSelectionOpen: Observable<boolean>;
    profileLastUpdateDate: Observable<string>;
    importFromIndeed: () => void;
};

export class ProfileSelectionOverlayViewModel {
    isProfileSelectionOpen: Observable<boolean>;
    profileLastUpdateDate: Observable<string>;
    importFromIndeed: () => void;

    constructor({ isProfileSelectionOpen, profileLastUpdateDate, importFromIndeed }: Props) {
        this.isProfileSelectionOpen = isProfileSelectionOpen;
        this.profileLastUpdateDate = profileLastUpdateDate;
        this.importFromIndeed = importFromIndeed;
    }

    useMyProfile(): void {
        this.isProfileSelectionOpen(false);
        profileImport.clearIndeedProfileFromCache();
    }

    useIndeedData(): void {
        this.importFromIndeed();
        this.isProfileSelectionOpen(false);
    }
}
