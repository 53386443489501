import appConfig from 'app/model/config';

export const getMiscAttachmentNotAllowedFileTypes = (): string | null => {
    const allowedTypes = appConfig.getSettingByKey('ORA_IRC_MISC_ATTACH_FILE_TYPES');
    const unsupportedTypes = appConfig.getSettingByKey('ORA_IRC_MISC_ATTACH_UNSUPPORTED_FILE_TYPES');

    if (allowedTypes && unsupportedTypes) {
        const whiteList = allowedTypes.split(', ');
        const blackList = unsupportedTypes.split(', ');
        const finalBlackList = blackList.filter((val: string) => !whiteList.includes(val));

        return finalBlackList.join(', ');
    }

    return unsupportedTypes;
};
