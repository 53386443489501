import { components } from 'knockout';

import './components/map/component';

import ViewModel from './SearchJobResultsViewModel';
import StaticViewModel from './SearchJobResultsStaticViewModel';
import template from './search-job-results.html';
import ContentComponent from 'cx/module/custom-content/model/ContentComponent';
import Page from 'app/module/admin/module/site-editor/model/Page';

type SiteInfo = {
    mode: string | undefined;
    pageData: Page;
};

type Props = {
    contentComponent: ContentComponent & SiteInfo;
    uniqueWrapperClass: string;
};

const isAdminMode = (mode: string | undefined) => mode === 'admin';

components.register('search-job-results', {
    viewModel: {
        createViewModel({ contentComponent, uniqueWrapperClass }: Props) {
            const { mode, params, pageData } = contentComponent;
            const rest = { params, uniqueWrapperClass, pageData };

            if (isAdminMode(mode)) {
                return new StaticViewModel(rest);
            }

            return new ViewModel(rest);
        },
    },
    template,
});
