import { observable } from 'knockout';
import { JobDetailsPageData } from '../types';
import { getStaticJob } from 'minimal/module/job-details/service/getStaticJob';
import { noOperation } from 'core/utils/noOperation';
import { STATIC_DATA } from 'minimal/module/search/component/search-job-results/config/staticData';
import { SimilarJobsState, JobFitState } from 'minimal/module/job-details/types';

export const FAKE_JOB_DETAILS_DATA: JobDetailsPageData = {
    job: getStaticJob(),
    similarJobsState: observable<SimilarJobsState>({
        type: 'loaded',
        hasJobs: true,
        hasMoreResults: observable(true),
        requisitions: observable(STATIC_DATA),
        loadMore: noOperation,
        loadMoreInProgress: observable(false),
    }),
    jobFitState: observable<JobFitState>({
        type: 'loaded',
        skillsScore: 4,
        relevantExperienceScore: 4,
        domainKnowledgeScore: 4,
        roleSeniorityScore: 4,
        overallScore: 4,
        summary:
            'You are an ideal fit for this job based on your impressive skills and extensive job history. With a diverse range of skills acquired over several years, you possess a unique blend of technical expertise and problem-solving capabilities that perfectly align with the job requirements.',
    }),
};
