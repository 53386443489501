import router from 'app/model/router';
import CandidateSelfServiceViewModel from 'minimal/module/candidate-self-service/CandidateSelfServiceViewModel';
import CandidateSelfServiceShortTokenViewModel from 'minimal/module/candidate-self-service/CandidateSelfServiceShortTokenViewModel';
import { RouteParams } from 'minimal/module/candidate-self-service/config/types';

export const createViewModel = (): CandidateSelfServiceViewModel => {
    const routeParams = router.routeParams() as RouteParams;

    if (routeParams.token) {
        return new CandidateSelfServiceShortTokenViewModel();
    }

    return new CandidateSelfServiceViewModel();
};
