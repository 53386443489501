import appConfig from 'app/model/config';
import { SEARCH_RESULTS_STRINGS } from 'app/module/cx/module/search/config/types';
import {
    Facet,
    PrimaryFacetName,
} from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { mapFacetNameToType } from './mapFacetNameToType';

export const orderFacets = (filters: Facet[]): Facet[] => {
    const allFilters = filters;

    if (allFilters.length > 0 && allFilters[0].context === SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS) {
        return filters;
    }

    const orderedFiltersEnabledOnly = (appConfig.getSettingByKey('FACET_ORDER')?.split(',') || [])
        .map((filterType: PrimaryFacetName) => {
            return allFilters.find((filter: Facet) => filter.type === mapFacetNameToType(filterType));
        })
        .filter((filter: Facet) => filter !== undefined);

    const restFilters = allFilters.filter((filter: Facet) => !orderedFiltersEnabledOnly.includes(filter));

    return [...orderedFiltersEnabledOnly, ...restFilters];
};
