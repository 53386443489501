import { components } from 'knockout';
import 'ojs/ojpopup';
import '../search-box-keyword';
import '../search-box-location';
import '../search-box-popup/component';

import ViewModel from './SearchBoxCompactViewModel';
import template from './search-box-compact.html';
import customizationTemplate from './search-box-compact-customization.html';

components.register('search-box-compact', { viewModel: ViewModel, template });
components.register('search-box-compact-customization', { template: customizationTemplate });
components.register('cc-minimal-search-box-compact', { viewModel: ViewModel, template });