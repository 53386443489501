import { ParamsDelimiter } from 'app/module/core/http/config/types';
import http from 'core/http/http';
import { LookupItem, LookupType, LookupFinder } from '../config/types';
import dictionaryMapper from '../mapper/dictionary';

type FindByLookupTypeParams = {
    LookupType: LookupType;
};

type FindLookupTypeAndSiteParams = {
    LookupType: LookupType;
    SiteNumber: string;
};

export type LookupHttpConfig = {
    params: {
        finder: LookupFinder;
        findParams: FindByLookupTypeParams | FindLookupTypeAndSiteParams;
    };
    paramsDelimiter: ParamsDelimiter;
};

const getFindParams = (
    lookupType: LookupType,
    lookupFinder: LookupFinder,
    siteNumber?: string
): FindByLookupTypeParams | FindLookupTypeAndSiteParams => {
    return lookupFinder === 'findByLookupType'
        ? {
              LookupType: lookupType,
          }
        : {
              LookupType: lookupType,
              SiteNumber: siteNumber,
          };
};

export const get = async (
    lookupType: LookupType,
    lookupFinder: LookupFinder,
    siteNumber?: string
): Promise<LookupItem[]> => {
    const httpConfig: LookupHttpConfig = {
        params: {
            finder: lookupFinder,
            findParams: getFindParams(lookupType, lookupFinder, siteNumber),
        },
        paramsDelimiter: ',',
    };

    const result = await http.get(
        `/recruitingCEDictionaries/1/lov/genericHcmLookups?finder=:finder:;:findParams:&onlyData=true&limit=1000000`,
        httpConfig
    );

    return dictionaryMapper.mapDictionariesFromRest(result);
};
