import { ObservableArray, PureComputed, pureComputed, toJS } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import { searchJobResults } from '../../model/searchJobResults';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { Facet, Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';

type RouterQuery = {
    lastSelectedFacet?: string;
    mode?: string;
    selectedLocationsFacet?: string;
    selectedPostingDatesFacet?: string;
    selectedTitlesFacet?: string;
    sortBy?: string;
};

type Props = {
    selectedFilters: PureComputed<Filter[]>;
};

export default class SearchBreadcrumbsViewModel {
    filters: ObservableArray<Filter | Facet>;
    selectedFilters: PureComputed<Filter[]>;
    hasSelectedFilters: PureComputed<boolean>;

    constructor({ selectedFilters }: Props) {
        this.filters = searchJobResults.filters;

        this.selectedFilters = pureComputed(selectedFilters, this);
        this.hasSelectedFilters = pureComputed(this.checkHasSelectedFilters, this);

        this.unselect = this.unselect.bind(this);
    }

    unselect(item: Filter): void {
        item.selected(false);

        const { query } = router.routeParams() as { query: RouterQuery };
        const queryBuilder = new SearchQueryBuilder(query).withoutFacets();

        const filtersWithSelectedItems = toJS(this.filters()).filter((filter) => {
            if ('selectedIds' in filter) {
                return filter.selectedIds.length > 0 && filter.selectedIds[0] !== undefined;
            }

            return false;
        });

        if (filtersWithSelectedItems.length > 0) {
            for (const filter of filtersWithSelectedItems) {
                queryBuilder.withFacet(filter);
            }
        } else {
            queryBuilder.withoutFacets();
        }

        router.go('search', { query: queryBuilder.build() }, { inherit: false });
    }

    getLabel({ text }: Filter): string {
        return i18n('search.remove-filter-link-label', {
            filtername: text,
        });
    }

    private checkHasSelectedFilters() {
        const selectedFilters = this.selectedFilters();

        return Boolean(selectedFilters.length);
    }
}
