import appConfig from 'app/model/config';
import { workLocationDisplayKey, workLocationDisplayValues } from '../../../../job-details/config/workLocationsSettings';

const WORK_LOCATIONS = {
    [workLocationDisplayValues.full]: [
        '500 Oracle Parkway, Redwood Shores, CA, 94065 United States',
        '315 Park Avenue South, New York, NY, 10010 United States',
    ],
    [workLocationDisplayValues.zipCode]: [
        '94065',
        '10010',
    ],
    [workLocationDisplayValues.name]: [
        'Redwood Shores',
        'New York',
    ],
    [workLocationDisplayValues.none]: [],
};

function getWorkLocations() {
    const workLocationSetting = appConfig.getSettingByKey(workLocationDisplayKey);

    return WORK_LOCATIONS[workLocationSetting];
}

export { getWorkLocations };