import { Observable, observable, Subscription } from 'knockout';
import router from 'app/model/router';
import scrollKeeper from 'minimal/service/scrollKeeper';
import jobService, { getJobFitScores } from 'cx/module/job-details/service/job';
import {
    Job,
    JobDetailsPageData,
    JobDetailsViewData,
    SimilarJobsState,
    JobFitState,
    JobFitScoreResponse,
} from 'minimal/module/job-details/types';
import { notReachable } from 'app/types/utils';
import userTracking from 'cx/service/userTracking';
import cxEvents from 'cx/config/events';
import userEvents, { JOB_VIEWS } from 'cx/config/userEvents';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { searchSimilar } from 'minimal/module/search/service/similarSearch';
import { getPageComponentByType } from 'custom-content/service/getPageComponents';
import { SEARCH_AI_JOBS_LIMIT } from 'search/service/providers/search';
import { getVerificationTokenDetails, prepareCandidateProfile } from '../../service/jobFit';
import { areJobFitEnabled } from 'app/service/areJobFitEnabled';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import appConfig from 'app/model/config';
import { CUSTOM_JOB_DETAILS_PAGE } from 'cx/module/custom-content/enums/pageTypes';

type Props = {
    viewData: JobDetailsViewData;
    jobId: Observable<string>;
};

type State =
    | {
          type: 'loading';
      }
    | {
          type: 'error';
          error: string;
      }
    | {
          type: 'loaded';
          data: JobDetailsPageData;
      };

export class JobDetailsLoaderViewModel {
    state: Observable<State>;
    viewData: JobDetailsViewData;
    stateSubscription: Subscription;
    jobIdSubscription: Subscription;
    reload: () => void;

    constructor({ viewData, jobId }: Props) {
        this.viewData = viewData;

        this.state = observable<State>({
            type: 'loading',
        });

        this.reload = () => this.state({ type: 'loading' });

        this.jobIdSubscription = jobId.subscribe(() => {
            this.reload();
        });

        this.stateSubscription = this.state.subscribe((newState) => {
            this.onStateChange(newState, jobId());
        });

        this.reload();
    }

    dispose(): void {
        this.stateSubscription.dispose();
        this.jobIdSubscription.dispose();
    }

    loadJob(jobId: string, routeId: string): Promise<Job> {
        const { isSignedIn } = signedCandidate;

        switch (routeId) {
            case 'job-formatting-preview':
                return jobService.getJobPreview(jobId);

            case 'job-template-preview':
                return jobService.getJobTemplatePreview(jobId);

            default:
                return jobService.getJob(jobId, Boolean(isSignedIn()));
        }
    }

    private async loadJobFitComponent(
        state: Record<'jobFitState', Observable<JobFitState>>,
        jobId: string
    ): Promise<void> {
        if (!areJobFitEnabled()) {
            return;
        }

        const jobFitComponent = getPageComponentByType(
            this.viewData.customPage()?.sections,
            'cc-job-details-job-fit'
        );

        if (!jobFitComponent) {
            return;
        }

        const verificationToken = getVerificationTokenDetails();

        const { candidateNumber } = verificationToken;

        const candidateProfile = await prepareCandidateProfile();

        getJobFitScores(jobId, candidateNumber, candidateProfile)
            .then((response: JobFitScoreResponse) => {
                state.jobFitState({
                    type: 'loaded',
                    ...response,
                });
            })
            .catch(() => {
                state.jobFitState({
                    type: 'error',
                });
            });
    }

    onStateChange(newState: State, jobId: string): void {
        switch (newState.type) {
            case 'loading':
                const routeId = router.route().id;

                this.loadJob(jobId, routeId)
                    .then(this.onLoadingDone.bind(this))
                    .catch(this.onLoadingError.bind(this));

                break;

            case 'loaded':
                scrollKeeper.scrollTo(0);
                userTracking.trackJobView(newState.data.job.id);
                userEvents[JOB_VIEWS].dispatch();
                cxEvents.pageTitleUpdate.dispatch(newState.data.job.title);

                const requisitionId = newState.data.job.requisitionId;
                const similarJobsComponent = getPageComponentByType(
                    this.viewData.customPage()?.sections,
                    'cc-job-details-similar-jobs'
                );

                this.loadJobFitComponent(newState.data, jobId);

                if (similarJobsComponent) {
                    searchSimilar.limit =
                        similarJobsComponent.params.content.numberOfJobsDisplayed() || SEARCH_AI_JOBS_LIMIT;

                    searchSimilar
                        .searchJobs('', requisitionId, newState.data.job.lang)
                        .then(() => {
                            newState.data.similarJobsState({
                                type: 'loaded',
                                hasJobs: Boolean(searchSimilar.searchJobResultsModel.results().length),
                                requisitions: searchSimilar.searchJobResultsModel.results,
                                loadMore: () => searchSimilar.loadMoreJobs('', requisitionId),
                                hasMoreResults: searchSimilar.searchJobResultsModel.hasMore,
                                loadMoreInProgress: searchSimilar.searchJobResultsModel.appending,
                            });
                        })
                        .catch(() => {
                            newState.data.similarJobsState({
                                type: 'error',
                            });
                        });
                }

                break;

            case 'error':
                cxEvents.loaded.dispatch();
                break;

            default:
                return notReachable(newState);
        }
    }

    async onLoadingDone(job: Job): Promise<void> {
        this.viewData.lang(job.lang);

        const pageData: JobDetailsPageData = {
            job,
            similarJobsState: observable<SimilarJobsState>({ type: 'loading' }),
            jobFitState: observable<JobFitState>({ type: 'loading' }),
        };

        const stateLoaded = {
            type: 'loaded',
            data: pageData,
        } as State;

        if (!appConfig.templates[CUSTOM_JOB_DETAILS_PAGE] || job.lang === siteLanguage.get()) {
            this.state(stateLoaded);
        } else {
            cxEvents.contentLocaleLang.dispatch(job.lang);

            cxEvents.isSectionsLoaded.add(() => this.state(stateLoaded));
        }
    }

    onLoadingError(error: unknown): void {
        console.error(error);

        this.state({
            type: 'error',
            error: 'Something went wrong',
        });
    }
}
