import ValidatableFormElement from './ValidatableElement';

export default class File extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component('file-form-element');
    }

}