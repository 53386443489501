import ko from 'knockout';

/**
 * Used mainly for closing an element that does't have an overlay layer (e.g. context popup/menu)
 * when user press escape key.
 *
 * @param {Function} action View model's click handler to be invoked once escape is pressed.
 * @param {ko.observable} when View model's observable indicating if popup/menu is currently opened
 * @param {string} html Element selector of element which if present in view, should not trigger close action
 *
 * @example
 * class VM {
 *   constructor() {
 *      this.isOpened = ko.observable();
 *   }
 *
 *   escapeKeyPressHandler() {
 *      //handle escape keypress event
 *   }
 *
 * }
 *
 * <div class="[context menu class]" data-bind="escape:
 * { action: escapeKeyPressHandler, when: isOpened, excludeElement: '.element' }">
 *     [content]
 * </div>
 */
ko.bindingHandlers.escape = {
    init(element, valueAccessor, allBindings, viewModel) {
        const { action, when, excludeElement } = valueAccessor();
        const escapeKeyPressAction = action.bind(viewModel);

        function isExcludedTargetInView() {
            const excludeTargets = excludeElement ? document.querySelectorAll(excludeElement) : null;

            if ((excludeTargets?.length ?? 0) > 0) {
                return true;
            }

            return false;
        }

        function onEscape(event: KeyboardEvent) {
            if (event.key === 'Escape' && when()) {
                if (isExcludedTargetInView()) {
                    return;
                }

                escapeKeyPressAction();
            }
        }

        document.body.addEventListener('keydown', onEscape, true);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            document.body.removeEventListener('keydown', onEscape, true);
        });
    },
};
