import { observable, pureComputed } from 'knockout';
import { ojTranslations } from 'cx/service/ojTranslations';

let instanceCount = 0;

export default class FormElementViewModel {

    constructor(params) {
        this.element = params.element;
        this.ojTranslations = ojTranslations;

        const uniqueId = `${this.element.name()}-${instanceCount++}`;

        this.labelId = `${uniqueId}-label`;

        this.attributes = Object.assign(this.element.attributes(), {
            id: uniqueId,
            required: this.element.isRequired(),
        });

        if (this.element.component() === 'text-form-element') {
            delete this.attributes.required;
            this.attributes['aria-required'] = this.element.isRequired();
        }

        this.hasFocus = observable(false);
        this.isFocused = pureComputed(() => this.hasFocus());

        this.isInvalid = pureComputed(this._isInvalid, this);
        this.isAriaInvalid = pureComputed(() => `${this.isInvalid()}`);
        this.isFilled = pureComputed(() => Boolean(this.element.value()));

        this.isEmptyDisabled = pureComputed(() => {
            const isDisabled = this.element.isDisabled();

            return isDisabled && !this.isFilled();
        });

        this.blurHandler = this.blurHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);
    }

    blurHandler() {
        this.element.isTouched(true);
    }

    focusHandler() {
        this.hasFocus(true);
    }

    _isInvalid() {
        return this.element.isValidElement() === false;
    }

}
