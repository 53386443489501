export default class CustomCssController {

    constructor(customCssId) {
        this.customCss = document.getElementById(customCssId) || this._createElement(customCssId);
    }

    _createElement(customCssId) {
        const element = document.createElement('style');

        element.id = customCssId;

        document.head.appendChild(element);

        return element;
    }

    update(styles) {
        this.customCss.innerText = styles;
    }

}
