export default class SkipNavigationLinkViewModel {

    constructor({ skipTo }) {
        this.skipTo = skipTo || 'main';

        this.setMainElementFocusable();
    }

    setMainElementFocusable() {
        const mainElement = document.querySelector('main');

        if (mainElement) {
            mainElement.setAttribute('tabindex', '-1');
        }
    }

}