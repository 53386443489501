import postMessageService from 'core/post-message/postMessageService';
import appConfig from 'app/model/config';
import cookiesMapper from 'cx/component/cookie-consent/mapper/cookies';
import chatbotMapper from '../mapper/chatbot';
import botsConfig from 'app/service/botsConfig';
import { mapFacetsToConfig } from '../mapper/facets';

postMessageService.add('store-site', (config) => {
    config.cookies = cookiesMapper.mapCookiesToConfig(config.cookies);
    config.chatbot = chatbotMapper.mapChatbotToConfig(config.chatbot);
    config.facets = mapFacetsToConfig(config.facets);

    appConfig.setSite(config);
    botsConfig.reload();
});
