import { bindingHandlers, unwrap } from 'knockout';
import i18n from 'core/i18n/i18n';

const ATTRIBUTES_TO_SET = [
    'playsinline',
];

function createMessageElement() {
    const message = document.createElement('span');

    message.textContent = i18n('general.unsupported-browser.title');

    return message;
}

function applyAttributes(element, attributes) {
    Object.keys(attributes).forEach((key) => {
        const value = unwrap(attributes[key]);

        element[key] = value;

        if (key === 'autoplay' && value === true) {
            element.muted = true;
            element.autoplay = true;
        }

        if (ATTRIBUTES_TO_SET.indexOf(key) !== -1) {
            const attributeValue = value === true ? '' : value;

            element.setAttribute(key, attributeValue);
        }
    });

    return element;
}

function createVideoElement(params) {
    const video = document.createElement('video');

    if (params.unsupportedMessage) {
        video.appendChild(createMessageElement());
    }

    return applyAttributes(video, params.attributes);
}

/**
 * Dynamic appending of video element.
 * Created because of problems on Firefox and Safari
 * with creating video elements by KnockoutJS.
 *
 * @param {Object} attributes object with html attributes for video
 * @param {Boolean} unsupportedMessage whether to apply unsupported message
 *
 * @example
 * <div data-bind="videoCreator: { unsupportedMessage: true,
 *     attributes: { src: videoUrl, controls: true, className: 'video-background' }}">
 */
bindingHandlers.videoCreator = {
    update(element, valueAccessor) {
        const params = valueAccessor();
        const oldVideo = element.querySelector('video');

        if (oldVideo) {
            applyAttributes(oldVideo, params.attributes);
        } else {
            const newVideo = createVideoElement(params);

            element.appendChild(newVideo);
        }
    },
};
