import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class DecimalFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.validators('decimal');
    }

}