import AbstractProfileItem from '../../profile-items/model/AbstractProfileItem';

const personalIdentifyingInformationPrototype = {
    id: null,
    itemId: null,
    action: 'CREATE',
    issueDate: null,
    expirationDate: null,
    placeOfIssue: null,
    country: null,
    nationalIdtype: null,
    nationalId: null,
    readOnly: false,
};

export default class PersonalIdentifyingInformation extends AbstractProfileItem {

    constructor(data) {
        super(personalIdentifyingInformationPrototype, data);
    }

}