import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import notificationsService from 'cx/service/notifications';
import applicationsModel from '../../model/applications';
import lightCandidateService, { DeleteError } from '../../service/lightCandidate';
import { session } from 'candidate-verification/service/user-session/session';
import { eventsState } from 'minimal/module/candidate-self-service/service/EventsState';
import { getCandidateSitesList } from './service/getCandidateSitesList';
import { Application } from 'apply-flow/config/types';

type Props = {
    candidateNumber: Observable<string>;
};

export default class CsDeleteProfileViewModel {
    candidateNumber: Observable<string>;
    isDeleteProfileDialogVisible: Observable<boolean>;
    isDeleteProfileInProgress: Observable<boolean>;
    candidateHasEvents: PureComputed<boolean>;
    candidateSites: PureComputed<string[]>;

    constructor({ candidateNumber }: Props) {
        this.candidateNumber = candidateNumber;

        this.isDeleteProfileDialogVisible = observable<boolean>(false);
        this.isDeleteProfileInProgress = observable<boolean>(false);

        this.candidateHasEvents = pureComputed(() => {
            return eventsState.eventsList().length > 0;
        });

        this.candidateSites = pureComputed(() => {
            return getCandidateSitesList();
        });
    }

    deleteProfile(): Promise<void> {
        this.isDeleteProfileInProgress(true);

        return lightCandidateService
            .delete(this.candidateNumber())
            .then(this.handleDeleteProfileSuccess)
            .catch((error) => this.handleError(error))
            .then(() => {
                this.isDeleteProfileInProgress(false);
                this.hideDeleteProfileDialog();
            });
    }

    private handleError(error: DeleteError): void {
        if (typeof error === 'object' && error.message === 'delete-profile-fail') {
            const { status } = applicationsModel.getByJobId(error.jobId) as Application;

            return notificationsService.error(
                i18n('candidate-self-service.delete-fail-message', { public_status: status })
            );
        }

        console.error(error);

        return notificationsService.error();
    }

    private handleDeleteProfileSuccess() {
        session.cleanup();
        router.go('home-page');

        notificationsService.successAfterLoaded(
            i18n('candidate-self-service.delete-profile-success-message'),
            500
        );
    }

    hideDeleteProfileDialog(): void {
        this.isDeleteProfileDialogVisible(false);
    }

    showDeleteProfileDialog(): void {
        this.isDeleteProfileDialogVisible(true);
    }
}
