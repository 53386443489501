import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';
import { Observable } from 'knockout';

export default Object.create(formBuilder, {
    createForm: {
        value(model: Observable<string>) {
            const form = formBuilder.createForm();

            form.elements([this._createHoneyPotFormElement(model)]);

            return form;
        },
    },

    _createHoneyPotFormElement: {
        value(model: Observable<string>) {
            const formElement = formElementFactory.create('text', 'honey-pot');
            const label = 'honeypot';

            formElement.label('honeypot');

            formElement.attributes({
                cssClass: 'input-row--invisible',
                'aria-label': label,
                'aria-hidden': true,
                tabindex: '-1',
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});
