import facetFactory from 'search/module/search-results/module/facet/model/facetFactory';
import appConfig from 'app/model/config';
import { getKeyValues } from '../../../../../service/pipeQueryString';
import { SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';

const DEFAULT_FACET_SETTINGS = {
    LOCATIONS: true,
    WORK_LOCATIONS: false,
    WORKPLACE_TYPES: false,
    TITLES: true,
    CATEGORIES: true,
    ORGANIZATIONS: false,
    POSTING_DATES: true,
};

const FACETS_SETTINGS = _setFacetsSettings();

function _underscoreToUpperCase(str) {
    return str.toLowerCase().replace(/_(\w)/g, (match, submatch) => submatch.toUpperCase());
}

function _mapFacetItemFromRest({ id, name, totalCount }) {
    return {
        id: `${id}`,
        name: name ? name.replace(/\s*>/g, ',') : '',
        totalCount,
    };
}

function _mapFlexFieldFacetsFromRest({ flexFieldsFacet, selectedFlexFieldsFacets }, context) {
    return (flexFieldsFacet || []).map((flexFacet) => {
        const facetName = flexFacet.label;
        const facetType = flexFacet.name;

        const items = flexFacet.values?.map(_mapFacetItemFromRest) ?? [];

        return facetFactory.create({
            type: facetType,
            name: 'flexFields',
            title: facetName,
            items,
            selectedIds: getKeyValues(selectedFlexFieldsFacets, facetType),
            context,
        });
    })
        .filter(Boolean);
}

function _getItemsFieldName(facetName) {
    return `${facetName}Facet`;
}

function _getSelectedIdsFieldName(facetName) {
    return `selected${facetName[0].toUpperCase()}${facetName.slice(1)}Facet`;
}

function _removeUnavailableSelectedIds(selectedIds, availableItemsIds) {
    return (selectedIds ? selectedIds.split(';') : [])
        .filter(selectedId => availableItemsIds.indexOf(selectedId) !== -1);
}

function _setFacetsSettings() {
    const isFacetEnabled = facetSetting => facetSetting === 'true';

    return Object.keys(facetFactory.getTypes(SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS))
        .reduce((accumulator, facetType) => {
            const facetSetting = appConfig.getSettingByKey(`${facetType}_FACET_ENABLED`);

            return Object.assign(accumulator, {
                [facetType]: !facetSetting ? DEFAULT_FACET_SETTINGS[facetType] : isFacetEnabled(facetSetting),
            });
        }, {});
}

function _filterDisabledFacets(facetType) {
    return FACETS_SETTINGS[facetType];
}

function _mapFacetFromRest(restSearchResultsData, facetType, context) {
    const restSearchResults = restSearchResultsData;
    const facetName = _underscoreToUpperCase(facetType);

    const itemsFieldName = _getItemsFieldName(facetName);

    const items = (restSearchResults[itemsFieldName] || [])
        .map(_mapFacetItemFromRest);

    const itemsIds = items.map(item => item.id);

    const selectedIdsFieldName = _getSelectedIdsFieldName(facetName);
    const selectedIds = _removeUnavailableSelectedIds(restSearchResults[selectedIdsFieldName], itemsIds);

    const result = facetFactory.create({
        type: facetType,
        name: facetName,
        items,
        selectedIds,
        context,
    });

    delete restSearchResults[itemsFieldName];
    delete restSearchResults[selectedIdsFieldName];

    return result;
}

function _convertFacetNameFromRest(restSearchResultsData, facetType) {
    const facetName = _underscoreToUpperCase(facetType);
    const newSelectedFieldName = _getSelectedIdsFieldName(facetName);
    const oldSelectedFieldName = `${newSelectedFieldName}Attr`;

    restSearchResultsData[newSelectedFieldName] = restSearchResultsData[oldSelectedFieldName];
}

export default {
    mapFacetsFromRest(restSearchResults) {
        const facets = restSearchResults.facets.split(';')
            .filter(facetType => _filterDisabledFacets(facetType))
            .map(facetType => _mapFacetFromRest(restSearchResults, facetType,
                SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS));

        facets.push(..._mapFlexFieldFacetsFromRest(restSearchResults,
            SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS));

        return facets;
    },

    mapEventFacetsFromRest(restSearchEventResults) {
        if (!restSearchEventResults.facets) {
            return [];
        }

        const facetTypes = restSearchEventResults.facets.split(';');

        facetTypes.forEach(facetType => _convertFacetNameFromRest(restSearchEventResults, facetType));

        const facets = facetTypes.map(facetType => _mapFacetFromRest(restSearchEventResults, facetType,
            SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS));

        return facets;
    },
};
