import { bindingHandlers, utils } from 'knockout';
import debounce from 'core/utils/debounce';
import screenInfo from 'cx/model/screenInfo';


/**
 * Implements sticky-like behavior for elements for which
 * CSS sticky position won't work due to container dimensions
 *
 * @params {boolean} isEnabled - indicates if binding should be enabled
 * @params {string} className - class name with fixed positioning
 * @params {ko.observable} isReady - indicates if control is fully loaded
 *
 * @example
 * <div data-bind="sticky: { enabled: true, isReady: isReady, className: 'search-box-compact--sticky'}">
 */
bindingHandlers.sticky = {
    init(element, valueAccessor) {
        const { isEnabled, className, isReady } = valueAccessor();

        if (!isEnabled) {
            return;
        }

        const baseLeft = element.style.left;
        const baseWidth = element.style.width;

        let computedTop;
        let computedLeft;
        let computedWidth;
        let computedHeight;

        const placeholder = document.createElement('div');

        const setupPlaceHolderElement = () => {
            placeholder.style.background = 'transparent';
            placeholder.style.height = `${computedHeight}px`;
            placeholder.style.width = `${computedWidth}px`;
        };

        const isSticky = () => element.classList.contains(className);

        const setSticky = () => {
            if (screenInfo.isSmall() || isSticky()) {
                return;
            }

            element.parentNode.prepend(placeholder);

            element.classList.add(className);
            element.style.width = `${computedWidth}px`;
            element.style.left = `${computedLeft}px`;
        };

        const unsetSticky = () => {
            if (!isSticky()) {
                return;
            }

            element.parentNode.removeChild(placeholder);

            element.classList.remove(className);
            element.style.width = baseWidth;
            element.style.left = baseLeft;
        };

        const setComputedProperties = () => {
            let boundingRect = element.getBoundingClientRect();

            computedHeight = boundingRect.height;

            if (!isSticky()) {
                computedTop = boundingRect.top + window.scrollY;
                computedLeft = boundingRect.left;
                computedWidth = boundingRect.width;

                return;
            }

            unsetSticky();

            boundingRect = element.getBoundingClientRect();
            computedLeft = boundingRect.left;
            computedWidth = boundingRect.width;

            setSticky();
        };

        const resizeHandler = debounce(setComputedProperties, 50);

        const scrollHandler = () => {
            if (window.scrollY >= computedTop) {
                setSticky();
            } else {
                unsetSticky();
            }
        };

        const isReadyHandler = () => {
            setComputedProperties();
            setupPlaceHolderElement();
        };

        isReady.subscribeOnce(isReadyHandler);

        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('resize', resizeHandler);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            document.removeEventListener('scroll', scrollHandler);
            document.removeEventListener('resize', resizeHandler);
        });
    },
};