import ko from 'knockout';

/**
 * Used mainly for closing an element that does't have an overlay layer (e.g. context menu)
 * when user clicks outside of it.
 * Any click on an element inside the bound element is not disturbed.
 * For handling clicks for elements that have overlay layer, use clickOverlay binding.
 *
 * @param {Function} action View model's click handler to be invoked once bound element is clicked.
 * @param {ko.observable} when View model's observable indicating if menu is currently opened
 * @param {string} html element that does not close opened element when clicked
 *
 * @example
 * class VM {
 *   constructor() {
 *      this.isOpened = ko.observable();
 *   }
 *
 *   clickHandler() {
 *      //handle click event
 *   }
 *
 * }
 *
 * <div class="[context menu class]" data-bind="clickOutside:
 * { action: clickHandler, when: isOpened, excludeElement: '.element' }">
 *     [content]
 * </div>
 */
ko.bindingHandlers.clickOutside = {
    init(element, valueAccessor, allBindings, viewModel) {
        const { action, when, excludeElement } = valueAccessor();
        const clickAction = action.bind(viewModel);
        let excludeTargets;

        function isExcludeTarget() {
            let close = false;

            excludeTargets = excludeElement ? document.querySelectorAll(excludeElement) : null;

            if (!excludeTargets) {
                return false;
            }

            excludeTargets.forEach((excludeTarget) => {
                if (excludeTarget.contains(event.target)) {
                    close = true;
                }
            });

            return close;
        }

        function onClick(event) {
            if (!element.contains(event.target)
                && when()) {
                if (isExcludeTarget()) {
                    return;
                }

                clickAction();
            }
        }

        document.body.addEventListener('click', onClick, true);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            document.body.removeEventListener('click', onClick, true);
        });
    },
};