import { bindingHandlers, utils } from 'knockout';

const OBSERVER_CONFIG = {
    childList: true,
    subtree: true,
    characterData: true,
};

function _getPrefix(text) {
    const [prefix] = text.split(' ');

    return prefix;
}

bindingHandlers.prefixObserver = {
    init(element) {
        const mutationObserver = new MutationObserver((mutations) => {
            mutations.forEach(({ target }) => {
                if (target.tagName !== 'SPAN'
                    || !target.classList.contains('oj-select-chosen')) {
                    return;
                }

                if (!target.innerText.startsWith('+')) {
                    return;
                }

                const prefixOnly = _getPrefix(target.innerText);

                // Prevent mutations loop
                if (target.innerText !== prefixOnly) {
                    target.innerText = prefixOnly;
                }
            });
        });

        mutationObserver.observe(element, OBSERVER_CONFIG);

        utils.domNodeDisposal.addDisposeCallback(element, () => mutationObserver.disconnect());
    },
};

