import i18n from 'core/i18n/i18n';
import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import Base64FileReader from 'core/base64-file-reader/Base64FileReader';
import { RESUME } from 'apply-flow/module/file-upload/config/attachmentCategories';
import ValidatableFormElement from 'core/form/element/ValidatableElement';
import fileUploadFormBuilder from 'apply-flow/module/file-upload/service/fileUploadFormBuilder';
import { STATES } from 'apply-flow/module/file-upload/config/attachmentUploadButton';
import deviceDetector from 'core/device-detector/deviceDetector';
import {
    redirectToSearchBasedOnViewBy,
    createVerificationToken,
    fetchAttachedResumeMetadata,
    saveRecommendedJobsMetadataInStorage,
    DEFAULT_CLASS_ID,
    DATA_TYPE_CODE,
    RECOMMENDED_JOB_RESUME,
} from './service/recommendedJobsService';
import { RecommendedJobsWidgetParams } from './config/types';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { emptyParams } from './config/emptyParams';
import { ResumeParserMetadata } from './config/types';
import { getCustomStyles } from './config/customStyles';
import { getMessages } from './service/messages';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';
import recommendedJobsEvents from '../../config/events';
import router from 'app/model/router';
import { SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';
import { PageType } from 'app/module/admin/module/site-editor/enum/pageTypes';
import { ButtonSize } from './config/types';
import { SignalBinding } from 'signals';
import { hasUserResumeInfoInStorage } from 'cx/service/uploadedResumeInfoProvider';

export type Props = {
    params?: RecommendedJobsWidgetParams;
    id?: string;
    mode?: string;
    lang?: Observable<string>;
    pageType?: PageType;
};

const dispatchResumeParsingSuccessEvent = (state: boolean): void => {
    recommendedJobsEvents.resumeParsingSuccessful.dispatch(state);
};

export class RecommendedJobsWidgetViewModel {
    resumeUploadElement: ValidatableFormElement;
    isResumeValid: Observable<boolean>;
    fileName: Observable<string>;
    isResumeParsingInProgress: Observable<boolean>;
    defaultStatus: Observable<string>;
    status: Observable<string>;
    pendingAction: Observable;
    isDragAndDropActive: PureComputed<boolean>;
    callToActionText: PureComputed<string>;
    messages: Record<string, Observable<string>>;
    isAdmin: boolean;
    selectedFile: Observable<string | null>;
    errorMessage: Observable<string>;
    customCss: PureComputed<string>;
    customizationParams: RecommendedJobsWidgetParams;
    uniqueWrapperClass: string;
    languageCode: string;
    areRecommendedJobsEnabled: boolean;
    widgetClasses: PureComputed<string>;
    isEventRouteEnabled: PureComputed<boolean>;
    isPrivacyPolicyEnabled: Observable<boolean>;
    uploadButtonClass: PureComputed<string>;
    showPrivacyPolicyModal: Observable<boolean>;
    isReuploadAction: Observable<boolean>;
    recommendedJobsProgressSubscription: SignalBinding<boolean> | undefined;
    isReuploadInProgress: Observable<boolean>;
    isResumeParsedSuccessfully: Observable<boolean>;
    deleteResumeModalTitle: string;

    constructor({ id, params, mode, lang }: Props) {
        this.resumeUploadElement = this.getResumeFormElement();
        this.isResumeValid = observable<boolean>(true);
        this.isResumeParsingInProgress = observable<boolean>(false);
        this.fileName = observable('');
        this.defaultStatus = observable(STATES.WAITING);
        this.status = observable(this.defaultStatus());
        this.pendingAction = observable();
        this.isDragAndDropActive = pureComputed(() => Boolean(!deviceDetector.isMobile()));
        this.selectedFile = observable<string | null>('').extend({ notify: 'always' });
        this.errorMessage = observable('');
        this.uniqueWrapperClass = `component-styling-wrapper-${id ?? DEFAULT_CLASS_ID}`;
        this.customizationParams = params || mapParamsConfigurationToObservable(emptyParams);
        this.widgetClasses = pureComputed(this.computeWidgetClasses, this);
        this.showPrivacyPolicyModal = observable<boolean>(false);
        this.isReuploadAction = observable<boolean>(false);
        this.isReuploadInProgress = observable<boolean>(false);
        this.deleteResumeModalTitle = i18n('recommended-jobs-widget.messages.delete-resume');

        this.isResumeParsedSuccessfully = observable<boolean>(hasUserResumeInfoInStorage());

        this.customCss = pureComputed(() =>
            getCustomStyles(this.customizationParams, this.uniqueWrapperClass)
        );

        this.isAdmin = mode === 'admin';
        this.languageCode = lang ? lang() : siteLanguage.getFusionCode();
        this.messages = getMessages(this.customizationParams, this.languageCode);

        this.callToActionText = pureComputed(() =>
            deviceDetector.isMobile()
                ? this.messages.callToActionMobileText()
                : this.messages.callToActionDesktopText()
        );

        this.areRecommendedJobsEnabled = areRecommendedJobsEnabled();

        this.isPrivacyPolicyEnabled = this.customizationParams.content.enablePrivacyPolicy;

        this.uploadButtonClass = pureComputed(() => this.getButtonClassFromButtonSize());

        this.isEventRouteEnabled = pureComputed(
            () => router.route().id === SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_EVENTS
        );

        this.recommendedJobsProgressSubscription = recommendedJobsEvents.isReuploadProgressStatus.add(
            (resumeProgressState: boolean) => this.isReuploadInProgress(resumeProgressState)
        );
    }

    private computeWidgetClasses(): string {
        let modifier;

        switch (true) {
            case this.isResumeParsingInProgress():
                modifier = 'uploading';
                break;
            case this.status() === STATES.DRAGOVER:
                modifier = 'active';
                break;
            case Boolean(this.errorMessage()):
                modifier = 'error';
                break;
            default:
                modifier = 'call-to-action';
        }

        const modifierClass = `recommended-jobs-widget--${modifier}`;

        return [
            this.uploadButtonClass(),
            modifierClass,
            this.customizationParams.commonParams.cssClass(),
        ].join(' ');
    }

    private getResumeFormElement(): ValidatableFormElement {
        const resumeFormConfig = {
            name: 'resume-upload',
            label: 'apply-flow.section-personal-information.upload-resume-button',
            sizeLimit: 5,
            acceptedFileExtensions: '.doc, .docx, .pdf, .txt',
        };

        return fileUploadFormBuilder.createForm(resumeFormConfig).getElement(resumeFormConfig.name);
    }

    onFileSelected(
        _: RecommendedJobsWidgetViewModel | FileList,
        event: Event | DragEvent,
        isReuploadAction: boolean
    ): void {
        this.isReuploadAction(isReuploadAction);

        if (isReuploadAction) {
            recommendedJobsEvents.resumeReuploadEvent.dispatch(true);
        }

        const [file] =
            ((event.target as HTMLInputElement).files as FileList) ||
            (((event as DragEvent).dataTransfer as DataTransfer).files as FileList);

        this.addFile(file).catch(console.error);
    }

    protected async addFile(file: File): Promise<void> {
        if (!file) {
            return;
        }

        this.errorMessage('');

        this.isResumeParsingInProgress(false);
        recommendedJobsEvents.isReuploadProgressStatus.dispatch(false);

        const isValid = await this.resumeUploadElement.validate(file);

        this.isResumeValid(isValid);

        if (!isValid) {
            this.errorMessage(this.resumeUploadElement.getErrorMessage());

            if (!this.isResumeParsedSuccessfully()) {
                dispatchResumeParsingSuccessEvent(false);
            }

            this.status(this.defaultStatus());

            return;
        }

        const reader = new Base64FileReader();

        this.fileName(file.name);

        this.isResumeParsingInProgress(true);
        recommendedJobsEvents.isReuploadProgressStatus.dispatch(true);
        this.status(STATES.UPLOADING);

        const encodedFile = await reader.encode(file);

        this.parseAttachment(encodedFile, file.name);
    }

    private async getAccessCodeToValidate(): Promise<string | void> {
        return createVerificationToken()
            .then((verificationToken) => verificationToken?.accessCode as string)
            .catch(this.handleRecommendedJobsError.bind(this));
    }

    private async parseAttachment(encodedFile: string, fileName: string): Promise<void> {
        const accessCode = await this.getAccessCodeToValidate();

        const attachmentData = {
            categoryName: RESUME,
            datatypeCode: DATA_TYPE_CODE,
            title: fileName,
            fileContents: encodedFile,
        };

        const parsedResumeMetadata = await fetchAttachedResumeMetadata(
            attachmentData,
            accessCode as string
        ).catch(this.handleRecommendedJobsError.bind(this));

        !!parsedResumeMetadata &&
            (await saveRecommendedJobsMetadataInStorage(parsedResumeMetadata, attachmentData));

        const userName = (parsedResumeMetadata as ResumeParserMetadata).displayName ?? RECOMMENDED_JOB_RESUME;

        this.selectedFile(null);

        this.isResumeParsingInProgress(false);
        recommendedJobsEvents.isReuploadProgressStatus.dispatch(false);
        this.status(this.defaultStatus());

        redirectToSearchBasedOnViewBy('RECOMMENDED_JOBS', `${userName}_${Date.now()}`);

        dispatchResumeParsingSuccessEvent(true);
        this.isResumeParsedSuccessfully(true);
    }

    private handleRecommendedJobsError(error: string | undefined): void {
        this.isResumeParsingInProgress(false);
        recommendedJobsEvents.isReuploadProgressStatus.dispatch(false);

        this.errorMessage(i18n('recommended-jobs-widget.file-upload-failure'));

        if (!this.isResumeParsedSuccessfully()) {
            dispatchResumeParsingSuccessEvent(false);
        }

        this.selectedFile(null);

        console.error(error);

        throw new Error(error);
    }

    private getButtonClassFromButtonSize(): string {
        let sizeClass: ButtonSize = 'large';

        const buttonSize = this.customizationParams.content.buttonSize();

        if (buttonSize === 'medium') {
            sizeClass = 'medium';
        } else if (buttonSize === 'small') {
            sizeClass = 'small';
        }

        return `recommended-jobs-widget--${sizeClass}`;
    }

    handleDragOver(_: FileList, event: Event): void {
        event.preventDefault();
    }

    handleDragEnter(): void {
        if (this.status() !== STATES.UPLOADING && !this.pendingAction()) {
            this.status(STATES.DRAGOVER);
        }
    }

    handleDragLeave(): void {
        if (this.status() !== STATES.UPLOADING) {
            this.status(this.defaultStatus());
        }
    }

    handleDrop(event: DragEvent): void {
        if (this.pendingAction()) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        const { files } = (event as DragEvent).dataTransfer as DataTransfer;

        if (event?.dataTransfer?.dropEffect) {
            event.dataTransfer.dropEffect = 'copy';
        }

        if (files?.length) {
            this.onFileSelected(files, event, false);
        }
    }

    togglePrivacyPolicyModal(): void {
        this.showPrivacyPolicyModal(!this.showPrivacyPolicyModal());
    }

    dispose(): void {
        this.recommendedJobsProgressSubscription?.detach();
    }
}
