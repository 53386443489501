import ko from 'knockout';
import CheckboxFormElementViewModel from './CheckboxFormElementViewModel';

export default class CheckboxStringValueFormElementViewModel extends CheckboxFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.setDefaultValues();

        if (!this.element.value()) {
            this.element.value(this.element.defaultValue() || this.element.uncheckedValue);
        }

        this.checked = ko.pureComputed({
            read() {
                return this.element.value() === this.element.checkedValue;
            },
            write(value) {
                this.element.value(value ? this.element.checkedValue : this.element.uncheckedValue);
            },
        }, this);
    }

    setDefaultValues() {
        this.element.uncheckedValue = this.element.uncheckedValue || 'N';
        this.element.checkedValue = this.element.checkedValue || 'Y';
    }

}
