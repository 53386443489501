import LocaleBasedDateTimeFormatter from 'app/module/cx/service/dateTime/LocaleBasedDateTimeFormatter';
import {
    AlreadyRegisteredEvents,
    DATE_TIME_FORMAT,
    EventData,
} from 'minimal/module/event-details/config/types';

import appConfig from 'app/model/config';
import { gatherRegistrationDetails } from './eventMapperHelperService';

export default {
    mapEventFromRest(eventData: EventData, registeredEventList: AlreadyRegisteredEvents[]): EventData | null {
        if (!eventData) {
            return null;
        }

        eventData.lang = eventData.contentLocale;

        const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);
        const mappedEventData = { ...eventData };

        mappedEventData.isVirtual = eventData.eventFormat === 'ORA_VIRTUAL';
        mappedEventData.startDate = dateFormatter.formatDateTime(eventData.startDate, DATE_TIME_FORMAT);
        mappedEventData.endDate = dateFormatter.formatDateTime(eventData.endDate, DATE_TIME_FORMAT);

        mappedEventData.registrationEndDate = eventData.registrationEndDate
            ? dateFormatter.formatDateTime(eventData.registrationEndDate, DATE_TIME_FORMAT)
            : null;

        gatherRegistrationDetails(mappedEventData, registeredEventList, mappedEventData.eventNumber);

        return mappedEventData;
    },
};
