import {
    DEFAULT_ZOOM_LEVEL,
    DEFAULT_CENTER_COORDINATES,
    SINGLE_MARKER_ZOOM_LEVEL,
} from '../config/maps';

export class MapController {

    constructor(mapProvider, element) {
        if (!mapProvider || !element) {
            throw new Error('Missing params for Map Controller');
        }

        this.element = element;
        this.mapProvider = mapProvider;
        this.map = undefined;
        this.cluster = undefined;
        this.markers = new Map();
        this.initialCenter = DEFAULT_CENTER_COORDINATES;
        this.initialZoomLevel = DEFAULT_ZOOM_LEVEL;
    }

    showMap() {
        this.mapProvider.initialize(this._initMap.bind(this));
    }

    addMarkers(markers = []) {
        markers.forEach(this._addMarker.bind(this));

        this._addMarkersToCluster();
    }

    adjustBounds() {
        if (!this.map || !this.markers.size) {
            return false;
        }

        if (this.markers.size === 1) {
            return this._adjustBoundsForSingleMarker();
        }

        return this._adjustBoundsForMultipleMarkers();
    }

    clearMap() {
        if (this.map && this.markers.size) {
            this.mapProvider.clearMarkers({ map: this.map, markers: [...this.markers.values()] });
        }

        this.markers.clear();
    }

    _initMap() {
        if (this.map) {
            return;
        }

        const centerPosition = this.mapProvider.createPosition({ coordinates: this.initialCenter });

        const mapParams = {
            element: this.element,
            center: centerPosition,
            zoom: this.initialZoomLevel,
        };

        this.map = this.mapProvider.createMap(mapParams);

        this._addMissingMarkers();
        this.adjustBounds();
    }

    _addMissingMarkers() {
        [...this.markers.values()]
            .filter(({ markerObject }) => !markerObject)
            .forEach(this._addMarker.bind(this));

        this._addMarkersToCluster();
    }

    _addMarker(marker) {
        const key = JSON.stringify(marker.coordinates);

        if (!this.map) {
            this.markers.set(key, marker);

            return;
        }

        const markerObject = this.mapProvider.createMarker({
            coordinates: marker.coordinates,
            label: marker.label,
            requisitionIds: marker.jobs,
            content: marker.popupContent,
            locationId: marker.locationId,
        });

        marker.setMarkerObject(markerObject);
        this.markers.set(key, marker);
    }

    _addMarkersToCluster() {
        if (!this.map) {
            return;
        }

        const markerObjects = [...this.markers.values()]
            .map(({ markerObject }) => markerObject);

        if (!this.cluster) {
            this.cluster = this.mapProvider.createClusterLayer({ map: this.map, markers: markerObjects });

            return;
        }

        this.mapProvider.updateClusterLayer({ map: this.map, cluster: this.cluster, markers: markerObjects });
    }

    _adjustBoundsForSingleMarker() {
        const [marker] = [...this.markers.values()];

        this.mapProvider.setCenter({ map: this.map, coordinates: marker.coordinates });
        this.mapProvider.setZoomLevel({ map: this.map, zoom: SINGLE_MARKER_ZOOM_LEVEL });

        setTimeout(() => {
            this.mapProvider.showPopup({
                map: this.map,
                marker: marker.markerObject,
                content: marker?.popupContent,
            });
        }, 0);
    }

    _adjustBoundsForMultipleMarkers() {
        const markerCoordinates = [...this.markers.values()]
            .map(({ coordinates }) => coordinates);

        return this.mapProvider.adjustBoundsForMultipleCoordinates({ map: this.map, coordinates: markerCoordinates });
    }

}