import tokenService from 'candidate-verification/service/token';
import userTracking from 'cx/service/userTracking';
import sourceTrace from 'cx/module/source-tracking/service/sourceTrace';
import profileImport from '../../../apply-flow/module/profile-import/service/profileImport';
import { clearProfileImportSelection } from 'cx/service/uploadedResumeInfoProvider';

export default class LocalSession {

    extend() {
        return Promise.reject();
    }

    cleanup() {
        tokenService.destroy();
        sourceTrace.removeParentSourceTrackingId();
        userTracking.clearUserTrackingCookie();
        userTracking.clearUserTrackingCookieFunctional();
        profileImport.clearIndeedProfileFromCache();
        clearProfileImportSelection();
    }

}