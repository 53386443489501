import ValidatableForm from '../../../../../../core/form/ValidatableForm';

export default class CountryForm extends ValidatableForm {

    constructor(...args) {
        super(...args);

        this.componentName('country-form');
    }

    get countryElement() {
        return this.elements()[0];
    }

}