import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class MaskedFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.onInputOnceHandler = this._onInputOnceHandler.bind(this);
    }

    _onInputOnceHandler() {
        this.element.value('');
    }

}
