import httpConfig from 'core/http/config';
import batchConfig from './config';
import batchResponseParser from './responseParser';
import batchPromiseResolver from './promiseResolver';
import { makeRequest } from '../common';
import urlInterpolator from '../urlInterpolator';

function _isAccessCodePresent(headers) {
    return Object.keys(headers).indexOf('Ora-Irc-Access-Code') !== -1;
}

export default class HttpBatch {

    constructor(batchPartFactory) {
        this.batchParts = [];
        this.batchPromiseMap = {};
        this.currentBatchConfig = Object.assign({}, batchConfig);
        this.batchPartFactory = batchPartFactory;

        if (_isAccessCodePresent(httpConfig.headers)) {
            Object.assign(this.currentBatchConfig.headers, {
                'Ora-Irc-Batch': 'true',
            });
        }
    }

    flush() {
        if (!this.batchParts.length) {
            return Promise.resolve();
        }

        return makeRequest('', { parts: this.batchParts }, 'POST', this.currentBatchConfig)
            .then(batchResponseParser.parse.bind(batchResponseParser))
            .then(batchPromiseResolver.resolveAll.bind(batchPromiseResolver, this.batchPromiseMap))
            .catch(error => Promise.reject(error));
    }

    append(url, data, method, config = {}) {
        const currentBatchHeaders = this.currentBatchConfig.headers;

        Object.assign(this.currentBatchConfig, config);
        Object.assign(currentBatchHeaders, config.headers);
        this.currentBatchConfig.headers = Object.assign(currentBatchHeaders, batchConfig.headers);

        const interpolatedUrl = urlInterpolator.interpolate(url, config.params, config.paramsDelimiter);
        const batchPart = this.batchPartFactory.createPart(interpolatedUrl, data, method);

        this.batchParts.push(batchPart);

        return new Promise((resolve) => {
            this.batchPromiseMap[batchPart.id] = { resolve };
        });
    }

}