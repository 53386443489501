import { observable, pureComputed } from 'knockout';
import AutosuggestFormElementViewModel from
    'core/form/component/autosuggest-form-element/AutosuggestFormElementViewModel';
import debouncePromise from 'core/utils/debouncePromise';

export const USE_THROTTLE_THRESHOLD = 5;

export default class GeoHierarchySearchFormElementMoheganViewModel extends AutosuggestFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.isDisabled = pureComputed(this._isDisabled, this);
        this.placeholder = observable('');

        this.getOptions = this.getOptions.bind(this);

        this.debouncedGetOptions = debouncePromise(this.element.getOptions.bind(this.element), 500);
    }

    async getOptions(context) {
        const value = this.element.value();

        if ('term' in context) {
            return await (Boolean(!context.term) && Boolean(value))
                ? this.element.getOptionsWithValue(context.term, value)
                : this.element.getOptions(context.term);
        } else if (value && !this.contains(value)) {
            return await this.element.getOptions(value);
        }

        return this.element.options();
    }

    _isDisabled() {
        return this.element.isDisabled();
    }

}