import candidateMapper from 'apply-flow/mapper/candidate';
import preferredCommunicationsChannelMapper from 'apply-flow/module/personal-information-basic/mapper/preferredCommunicationsChannel';
import candidateModel from 'apply-flow/model/candidate';
import { validationNotifications } from './validationNotifications';

const baseConfig = {
    restEndpoint: '/recruitingCECandidates',
    successMessage: 'IRC-Candidate Data Validation Successful (IRC--1)',
};

export default {
    validateEmail(candidateNumber, email) {
        const payload = preferredCommunicationsChannelMapper.mapEmailVerificationToRest(candidateNumber, email);

        return validationNotifications.sendAndNotify({ ...baseConfig, payload });
    },

    validatePhone(candidateNumber, phone) {
        const payload = preferredCommunicationsChannelMapper.mapPhoneVerificationToRest(candidateNumber, phone);

        return validationNotifications.sendAndNotify({ ...baseConfig, payload });
    },

    validate() {
        return validationNotifications.sendAndNotify(this.getConfig());
    },

    getConfig() {
        const payload = candidateMapper.mapCandidateToRest(candidateModel);

        return {
            ...baseConfig,
            payload,
            ignoredErrors: ['ORA_IRC_CAND_EXISTS'],
        };
    },
};
