import formElementFactory from 'core/form/element/factory';
import WorkLocationsFormElement from './WorkLocations';

const WORK_PREFERENCES_ELEMENTS = {
    workLocations: WorkLocationsFormElement,
};

export default Object.create(formElementFactory, {
    create: {
        value(type, name) {
            if (type in WORK_PREFERENCES_ELEMENTS) {
                const FormElement = WORK_PREFERENCES_ELEMENTS[type];

                return new FormElement(name);
            }

            return formElementFactory.create(type, name);
        },
    },
});