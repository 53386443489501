import {
    Event,
    EventActionRestParams,
    EventStatus,
    EventFormat,
    EventRegistrationPhase,
    RegistrationStatus,
    EventActionResponse,
} from '../config/types';
import { notReachable } from 'app/types/utils';
import tokenService from 'candidate-verification/service/token';
import appConfig from 'app/model/config';
import { QuestionnaireResponse } from 'app/module/cx/module/apply-flow/module/questionnaire/config/types';

type OraEventStatus = 'ORA_POSTED' | 'ORA_UNPOSTED' | 'ORA_COMPLETED' | 'ORA_CANCELLED' | 'ORA_OPEN_REG';

type OraEventFormat = 'ORA_VIRTUAL' | 'ORA_IN_PERSON';

export type OraRegistrationPhase =
    | 'INVITED'
    | 'REGISTERED'
    | 'CANCELLED'
    | 'WITHDRAWN'
    | 'ATTENDED'
    | 'NOT_ATTENDED'
    | 'PENDING';

type OraStatus = 'ORA_ACTIVE' | 'ORA_INACTIVE';

export type EventQuestionnaireResponse = QuestionnaireResponse & {
    disqualification: 'Y' | 'N';
};

export type RestEvent = {
    eventNumber: string;
    siteNumber: string;
    title: string;
    startDate: string;
    endDate: string;
    eventStatus: OraEventStatus;
    eventFormat: OraEventFormat;
    eventLocation: string;
    meetingUrl: string | null;
    registeredOnDate: string;
    registrationPhase: OraRegistrationPhase | null; // TODO remove null once REST endpoint is fixed on DIT-B
    status: OraStatus;
    confirmedFlag: boolean;
    RecruitingEventQuestionnaireResponses?: EventQuestionnaireResponse[];
    disqualification: 'Y' | 'N';
    manualConfirmationRequiredFlag?: boolean;
};

const mapEventStatusFromRest = (eventStatus: OraEventStatus): EventStatus => {
    switch (eventStatus) {
        case 'ORA_POSTED':
            return 'posted';
        case 'ORA_UNPOSTED':
            return 'unposted';
        case 'ORA_COMPLETED':
            return 'completed';
        case 'ORA_CANCELLED':
            return 'cancelled';
        case 'ORA_OPEN_REG':
            return 'privateOpenForRegistration';
        default:
            return notReachable(eventStatus);
    }
};

const mapEventFormatFromRest = (eventFormat: OraEventFormat): EventFormat => {
    switch (eventFormat) {
        case 'ORA_VIRTUAL':
            return 'virtual';
        case 'ORA_IN_PERSON':
            return 'inPerson';
        default:
            return notReachable(eventFormat);
    }
};

const mapRegistrationPhaseFromRest = (
    eventFormat: OraRegistrationPhase | null,
    confirmedFlag: boolean
): EventRegistrationPhase | null => {
    if (!eventFormat) {
        return !confirmedFlag ? 'unconfirmed' : null;
    }

    switch (eventFormat) {
        case 'INVITED':
            return 'invited';
        case 'REGISTERED':
            return 'registered';
        case 'CANCELLED':
            return 'cancelled';
        case 'WITHDRAWN':
            return 'withdrawn';
        case 'ATTENDED':
            return 'checkedIn';
        case 'NOT_ATTENDED':
            return 'notAttended';
        case 'PENDING':
            return 'pending';
        default:
            return notReachable(eventFormat);
    }
};

const mapStatusFromRest = (status: OraStatus): RegistrationStatus => {
    switch (status) {
        case 'ORA_ACTIVE':
            return 'active';
        case 'ORA_INACTIVE':
            return 'inactive';
        default:
            return notReachable(status);
    }
};

const mapEventFromRest = (event: RestEvent): Event => {
    return {
        candidateNumber: tokenService.getCandidateNumber(),
        eventNumber: event.eventNumber,
        siteNumber: event.siteNumber,
        siteName: null,
        title: event.title,
        startDate: event.startDate,
        endDate: event.endDate,
        eventStatus: mapEventStatusFromRest(event.eventStatus),
        eventFormat: mapEventFormatFromRest(event.eventFormat),
        eventLocation: event.eventLocation,
        meetingURL: event.meetingUrl,
        registeredOnDate: event.registeredOnDate,
        registrationPhase: mapRegistrationPhaseFromRest(event.registrationPhase, event.confirmedFlag),
        registrationStatus: mapStatusFromRest(event.status),
        confirmedFlag: event.confirmedFlag ?? false,
        isCandidateDisqualified: event.disqualification === 'Y',
    };
};

export const filterOutDisqualifiedEventRegistrations = (events: RestEvent[]): RestEvent[] => {
    if (!events?.length) {
        return [];
    }

    return events.filter((value: RestEvent) => {
        return value.disqualification !== 'Y';
    });
};

export const mapEventsFromRest = (events: RestEvent[]): Event[] => {
    return events.map((event) => mapEventFromRest(event));
};

export const mapWithdrawEventParamsToRest = (event: Event): EventActionRestParams => {
    return {
        candidateNumber: event.candidateNumber,
        eventNumber: event.eventNumber,
        siteNumber: appConfig.siteNumber,
        action: 'WITHDRAW',
    };
};

export const mapConfirmEventParamsToRest = (event: Event): EventActionRestParams => {
    return {
        candidateNumber: event.candidateNumber,
        eventNumber: event.eventNumber,
        siteNumber: appConfig.siteNumber,
        action: 'CONFIRM',
    };
};

export const mapConfirmEventResponse = (event: RestEvent): EventActionResponse => {
    return {
        candidateNumber: tokenService.getCandidateNumber(),
        eventNumber: event.eventNumber,
        siteNumber: appConfig.siteNumber,
        action: 'CONFIRM',
        isManualConfirmationRequired: event.manualConfirmationRequiredFlag == true,
    };
};
