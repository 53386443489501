import { isDistanceEnabled } from 'app/module/cx/module/search/service/searchTypes';
import { search as searchService } from '../../../service/search';
import { SortOption } from 'search/config/types';

export const getSortingOptions = (
    sortOptions: SortOption[],
    { query }: Record<string, unknown>
): SortOption[] => {
    return sortOptions.filter(({ code }) => {
        if (code === 'DISTANCE') {
            return isDistanceEnabled() && searchService.isLocationSearch(query);
        }

        return true;
    });
};

export const getSelectedSortOption = (sortOptions: SortOption[], sortBy: string): SortOption | null => {
    if (!sortBy) {
        return null;
    }

    const sortCodeOrderDivPos: number = sortBy.lastIndexOf('_');
    const defaultSortCode = sortCodeOrderDivPos > 0 ? sortBy.slice(0, sortCodeOrderDivPos) : sortBy;
    const defaultSortOrder = sortCodeOrderDivPos > 0 ? sortBy.slice(sortCodeOrderDivPos + 1) : null;

    return (
        sortOptions.find(
            ({ code, order }) => code === sortBy || (code === defaultSortCode && order === defaultSortOrder)
        ) ?? null
    );
};
