import { observable } from 'knockout';
import { ObservableParams } from './paramsToObservable';

export const cloneObservableParams = <Param>(params: ObservableParams<Param>): ObservableParams<Param> => {
    const clone: ObservableParams<Param> = { ...params };

    (Object.keys(params) as (keyof Param)[]).forEach((paramKey) => {
        const value = params[paramKey]();

        clone[paramKey] = observable(value);
    });

    return clone;
};
