import TimelineProjectionBuilder from '../model/TimelineProjectionBuilder';
import TimelineItemsMapper from '../model/TimelineItemsMapper';

class TimelineProjector {

    createProjection(name, startYear, items) {
        const timelineItemsMapper = new TimelineItemsMapper(startYear);
        const entries = timelineItemsMapper.toTimelineEntries(items);
        const timelineProjectionBuilder = new TimelineProjectionBuilder(entries);

        return timelineProjectionBuilder.buildProjection(name);
    }

}

export default new TimelineProjector();