import i18n from 'app/module/core/i18n/i18n';
import { Observable } from 'knockout';

const jobDetailsButtonClass = 'button apply-with-indeed-button';
const profileImportBlockButtonClass = `${jobDetailsButtonClass}__profile-import-block`;
const buttonTranslationKey = 'apply-flow.section-personal-information.apply-with-indeed-button';
const indeedLogoHTML = '<i class="icon-indeed apply-with-indeed-button__icon"></i>';

type Props = {
    onClick: () => void;
    isEnabled: Observable<boolean>;
    mode: string;
};

export class JobDetailsApplyWithIndeedButtonViewModel {
    onClick: () => void;
    isEnabled: Observable<boolean>;
    className: string;
    indeedLogoHTML: string;
    ariaLabel: string;
    translationKey: string;

    constructor({ onClick, isEnabled, mode }: Props) {
        this.onClick = onClick;
        this.isEnabled = isEnabled;

        this.className =
            mode === 'profileImportBlock' ? profileImportBlockButtonClass : jobDetailsButtonClass;

        this.indeedLogoHTML = indeedLogoHTML;
        this.translationKey = buttonTranslationKey;

        this.ariaLabel = i18n(buttonTranslationKey, {
            indeed_logo: 'Indeed',
        });
    }
}
