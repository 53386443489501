import { ABORTED_ERROR, isAborted } from '../abortFetch';

function abortableFetch(request) {
    return window.fetch(request)
        .catch((error) => {
            const reason = isAborted(error) ? ABORTED_ERROR : error;

            return Promise.reject(reason);
        });
}

function timeoutFetch(request, config) {
    const timeoutPromise = createTimeoutPromise(config.timeout);
    const requestPromise = window.fetch(request);

    return Promise.race([timeoutPromise, requestPromise]);
}

function isJSON(headers) {
    return headers.get('Content-Length') !== '0'
        && headers.get('Content-Type')
        && headers.get('Content-Type').match(/^application\/.*json/);
}

function createTimeoutPromise(timeout) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                status: 0,
                text() {
                    return Promise.resolve('Connection timeout');
                },
            });
        }, timeout);
    });
}

async function createRequest(requestConfig) {
    const config = requestConfig;

    if (!config.body) {
        delete config.body;
    }

    const request = new Request(config.url, config);
    const requestMethod = config.signal ? abortableFetch : timeoutFetch;

    const response = await requestMethod(request, config);

    config.status = response.status;

    if (!(response instanceof Response) || !response.ok) {
        return Promise.reject({
            statusCode: response.status,
            response: await response.text(),
        });
    }

    if (isJSON(response.headers)) {
        return response.json();
    }

    return config.responseType === 'arraybuffer' ? response.arrayBuffer() : response.text();
}

export default {
    request(config) {
        return createRequest(config);
    },
};