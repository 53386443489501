import i18n from 'core/i18n/i18n';

const AGREEMENT_BLOCK_CODE = 'ORA_AGREEMENTS';
const PERSONAL_INFORMATION_BLOCK_CODE = 'ORA_NAME_CONTACT_INFO';

function _createJobAlertPreferencesBlock() {
    return {
        code: 'ORA_JOB_ALERT_PREFERENCES',
        component: 'job-alert-preferences',
        title: i18n('talent-community.job-alert-preferences-block-title'),
    };
}

function _reduceBlocks(sections) {
    return sections
        .reduce((pages, section) => pages.concat(section.pages), [])
        .reduce((blocks, page) => blocks.concat(page.blocks), []);
}

function _getAgreementBlock(sections) {
    const [agreementBlock] = _reduceBlocks(sections)
        .filter(block => block.code === AGREEMENT_BLOCK_CODE);

    return agreementBlock;
}

function _getPersonalInformationBlock(sections) {
    const [personalInformationBlock] = _reduceBlocks(sections)
        .filter(block => block.code === PERSONAL_INFORMATION_BLOCK_CODE);

    return personalInformationBlock;
}

function _removeAgreementBlock(block) {
    return block.code !== AGREEMENT_BLOCK_CODE;
}

function _removePersonalInformationBlock(block) {
    return block.code !== PERSONAL_INFORMATION_BLOCK_CODE;
}

function _mapBlocks(blocks) {
    return blocks
        .filter(_removeAgreementBlock)
        .filter(_removePersonalInformationBlock);
}

function _mapPages(pages) {
    return pages.map((page) => {
        page.blocks = _mapBlocks(page.blocks);

        return page;
    });
}

function _mapSections(sections) {
    return sections.map((section) => {
        section.pages = _mapPages(section.pages);

        return section;
    });
}

export default {
    mapFlowFromRest(flow) {
        flow.flowJobAlertPreferencesSection = {
            number: 10,
            pages: [{
                blocks: _createJobAlertPreferencesBlock(),
            }],
        };

        flow.flowPersonalInformationsSection = {
            number: 20,
            pages: [{
                blocks: _getPersonalInformationBlock(flow.sections),
            }],
        };

        flow.flowAgreementsSection = {
            number: 30,
            pages: [{
                blocks: _getAgreementBlock(flow.sections),
            }],
        };

        flow.sections = _mapSections(flow.sections);

        flow.isSingleClick = true;

        return flow;
    },
};