import router from 'app/model/router';
import tokenService from 'cx/module/candidate-verification/service/token';
import {
    storeShortTokenData,
    getShortTokenForEvent,
} from 'cx/module/candidate-verification/service/shortTokenStorage';

type Route = {
    route: string;
    params: RouterParams;
};

type RouterParams = {
    sectionNumber?: string;
};

export const redirectToRegisterFlow = (): void => {
    const route = findRegisterFlowRoute();

    router.go(route, {
        sectionNumber: '1',
    });
};

export const findRegisterFlowRoute = (): string => {
    const isEventPreview = (router.route().parent || {}).id === 'event-preview';

    return isEventPreview ? 'event-preview.register-flow' : 'event-register-flow';
};

export const getEventRegisterFlowRouting = async (
    eventId: string,
    isEventPreview: boolean
): Promise<Route> => {
    if (tokenService.accessCodeExists()) {
        const shortToken = getShortTokenForEvent(eventId)?.token;

        const pinCodeSent = await verifyWithShortToken(shortToken);

        storeShortTokenData({ token: shortToken, eventId: eventId, pinCodeSent });

        if (!pinCodeSent) {
            const route = isEventPreview ? 'event-preview.register-flow' : 'event-register-flow';

            return { route, params: { sectionNumber: '1' } };
        }
    }

    // TODO uncomment below lines and modify for Unverified scenaio as part of next 23A epic
    /* if (unverifiedService.hasAppliedToJob(jobId)
        && unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = 'job-details.confirm-application';

        return { route, params: {} };
    }

    if (unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
        const route = isEventPreview ? 'job-preview.confirm-email' : 'job-details.confirm-email';

        return { route, params: {} };
    } */

    if (getShortTokenForEvent(eventId)) {
        return { route: 'event-details.pin-verification', params: {} };
    }

    const route = isEventPreview ? 'event-preview.email-verification' : 'event-details.email-verification';

    return { route, params: {} };
};

const verifyWithShortToken = async (shortToken: string): Promise<boolean> => {
    return (
        shortToken &&
        (await tokenService
            .verifyTokenWithChallengeFlag(shortToken)
            .then((response: { challengeFlag: boolean }) => response?.challengeFlag)
            .catch((error: string) => {
                console.error(error);

                return true;
            }))
    );
};
