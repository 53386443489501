import formBuilder from 'core/form/formBuilder';
import PiiFormTileStrategy from '../model/PiiFormTileStrategy';
import PiiForm from '../form/PiiForm';
import profileItemsFormBuilder from 'apply-flow/module/profile-items/service/profileItemsFormBuilder';
import * as PII_FIELD_MAP from '../config/personalIdentifyingInformationFieldMap';
import PiiExpirationDateValidator from '../validator/PiiExpirationDateValidator';
import MaskedFormElement from '../element/MaskedFormElement';
import PiiExpirationDateMaxLimitValidator from '../validator/PiiExpirationDateMaxLimitValidator';
import i18n from 'core/i18n/i18n';

export default Object.create(formBuilder, {

    createForm: {
        value(config) {
            config.elements = config.elements.map((element) => {
                element.validateImmediately = config.validateImmediately;

                const formElement = element.name === PII_FIELD_MAP.NATIONAL_IDENTIFIER_NUMBER
                    ? this._createNationalIdElement(element, config.isNew)
                    : formBuilder.createFormElement(element);

                if (config.readOnly) {
                    formElement.attributes('disabled', true);
                }

                return formElement;
            });

            const form = profileItemsFormBuilder.createForm(config, new PiiForm(PiiFormTileStrategy, config))
                .bindComponent('pii-form');

            if (!config.readOnly) {
                this._addPiiDataValidation(form, config);
            }

            form.legend(i18n('apply-flow.section-personal-identifying-information.add-personal-identifying-information-button'));

            return form;
        },

    },

    _addPiiDataValidation: {
        value(form, config) {
            const legislationCode = form.getElement(PII_FIELD_MAP.LEGISLATION_CODE);
            const nationalIdType = form.getElement(PII_FIELD_MAP.NATIONAL_IDENTIFIER_TYPE);
            const nationalId = form.getElement(PII_FIELD_MAP.NATIONAL_IDENTIFIER_NUMBER);
            const issueDate = form.getElement(PII_FIELD_MAP.ISSUE_DATE);
            const expirationDate = form.getElement(PII_FIELD_MAP.EXPIRATION_DATE);

            legislationCode.addValidator('required', { label: legislationCode.label });

            nationalIdType.addValidator('required', { label: nationalIdType.label });

            nationalId.addValidator('required', { label: nationalId.label });

            if (config.isDOBVisible) {
                issueDate.validators({
                    afterDate: {
                        minDateFormElement: config.dateOfBirth,
                        inclusive: true,
                    },
                    beforeDate: {
                        maxDateFormElement: expirationDate,
                        pairValidationElement: expirationDate,
                        pairValidationValidator: 'afterDate',
                    },
                });
            }

            expirationDate.validators({
                afterDate: {
                    minDateFormElement: issueDate,
                    pairValidationElement: issueDate,
                    pairValidationValidator: 'beforeDate',
                },
            });

            expirationDate.addValidator(new PiiExpirationDateValidator({
                countryCode: legislationCode,
                typePii: nationalIdType,
            }));

            expirationDate.addValidator(new PiiExpirationDateMaxLimitValidator({
                issueDateElement: issueDate,
                expiryDateElement: expirationDate,
            }));
        },
    },

    _createNationalIdElement: {
        value(element, isNew) {
            return isNew
                ? formBuilder.createFormElement(element)
                : this._createMaskedFormElement(element);
        },
    },

    _createMaskedFormElement: {
        value(element) {
            const maskedFormElement = new MaskedFormElement(element.name);

            maskedFormElement.label(element.label);
            maskedFormElement.validators(element.validators);

            return maskedFormElement;
        },
    },
});