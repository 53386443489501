import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { JobDetailsSimilarJobsCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';
import { mapContentParams } from './mapContentParams';

export const mapParamsFromRest = (params: RestParam[]): JobDetailsSimilarJobsCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    return mapContentParams(paramValue);

                case 'headerTypography':
                case 'headerTypographyOnHover':
                case 'subHeaderTypography':
                case 'subHeaderTypographyOnHover':
                case 'descriptionTypographyOnHover':
                case 'descriptionTypography':
                case 'alreadyAppliedJobTag':
                case 'jobTagsTypography':
                    return mapTypography(paramValue);

                case 'container':
                case 'tileStyles':
                case 'tileStylesOnHover':
                case 'tileHeaderStyles':
                case 'tileHeaderStylesOnHover':
                case 'alreadyAppliedTagBox':
                case 'alreadyAppliedTagBoxOnHover':
                case 'jobIconsStyles':
                case 'jobIconsStylesOnHover':
                case 'defaultToggleIconStyles':
                case 'defaultToggleIconStylesOnHover':
                case 'selectedToggleIconStyles':
                case 'selectedToggleIconStylesOnHover':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return notReachable(paramKey);
            }
        },
    });
};
