import router from 'app/model/router';
import { getStoredToken, applyToken } from 'candidate-verification/service/tokenCookie';
import lightCandidateService from 'candidate-self-service/service/lightCandidate';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';
import { UNAUTHORIZED } from 'candidate-verification/service/user-session/unauthorized';
import challengeService from 'candidate-verification/service/challenge';
import flowService from 'apply-flow/service/flow';
import flowSectionMapper from 'apply-flow/mapper/flowSection';
import { PARTNER_BLOCK_CODE } from './config/partner';

export default class InlinePartnerHandlerViewModel {

    constructor() {
        const routeParams = router.routeParams();

        this.token = routeParams.token;
        this.jobId = routeParams.jobId;
        this.partner = routeParams.partner;
        this.blockCode = PARTNER_BLOCK_CODE[this.partner];

        const storedToken = getStoredToken();

        if (storedToken) {
            this._validateAccessCode(storedToken)
                .then(this._goToFlow.bind(this))
                .catch(this._handleError);
        } else {
            this._applyLinkToken();
        }
    }

    async _goToFlow() {
        try {
            const { sections, isSingleClick } = await flowService.query(this.jobId);
            const sectionNumber = flowSectionMapper.findSectionNumberByBlockCode(sections, this.blockCode);

            challengeService.clearChallengeFlag();

            router.go('apply-flow', {
                sectionNumber: isSingleClick ? '1' : sectionNumber,
                element: `.apply-flow-block--inline-${this.partner}`,
                assessmentSection: sectionNumber,
            });
        } catch (err) {
            console.error(err);
        }
    }

    _applyLinkToken() {
        storeShortTokenData({ token: this.token, jobId: this.jobId });
        router.go('job-details.pin-verification');
    }

    _validateAccessCode(token) {
        applyToken(token);

        return lightCandidateService.get(token.candidateNumber || null)
            .catch((error) => {
                if (error === UNAUTHORIZED) {
                    this._applyLinkToken();

                    return Promise.reject();
                }

                return Promise.resolve();
            });
    }

    _handleError(error) {
        console.error(error);
    }

}