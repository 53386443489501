import appConfig from 'app/model/config';
import { VERIFICATION_MODES, JOB_APPLICATION, CSS_ENTRY } from 'candidate-verification/config/verificationSubmodules';

function mapSinglePreferenceFromRest(preference) {
    return {
        siteNumber: preference.siteNumber,
        jobAlertsEnabled: preference.optInJobAlertFlag,
        preferredLocations: mapLocationsFromRest(preference),
        preferredJobFamilies: mapJobFamiliesFromRest(preference),
        preferredJobFunctions: mapJobFunctionsFromRest(preference),
    };
}

function mapSinglePreferenceToRest(preference) {
    return {
        flowVersionId: preference.flowVersionId || null,
        legalDescriptionVersionId: preference.legalDescriptionVersionId || null,
        siteNumber: preference.siteNumber,
        optInJobAlertFlag: preference.jobAlertsEnabled,
        preferredLocations: mapLocationsToRest(preference),
        preferredJobFamilies: mapJobFamiliesToRest(preference),
        preferredJobFunctions: mapJobFunctionsToRest(preference),
    };
}

function mapJobFamiliesFromRest({ preferredJobFamilies }) {
    return preferredJobFamilies.filter(({ jobFamilyId }) => jobFamilyId !== null)
        .map(({ jobFamilyId }) => jobFamilyId);
}

function mapJobFamiliesToRest({ preferredJobFamilies }) {
    if (!preferredJobFamilies.length) {
        return [{ jobFamilyId: null }];
    }

    return preferredJobFamilies.map(jobFamilyId => ({ jobFamilyId }));
}

function mapJobFunctionsFromRest({ preferredJobFunctions }) {
    return preferredJobFunctions.filter(({ jobFunctionCode }) => jobFunctionCode !== null)
        .map(({ jobFunctionCode }) => jobFunctionCode);
}

function mapJobFunctionsToRest({ preferredJobFunctions }) {
    if (!preferredJobFunctions.length) {
        return [{ jobFunctionCode: null }];
    }

    return preferredJobFunctions.map(jobFunctionCode => ({ jobFunctionCode }));
}

function mapLocationsFromRest({ preferredLocations }) {
    return preferredLocations.filter(({ geographyId }) => geographyId !== null)
        .map(({ geographyId }) => geographyId);
}

function mapLocationsToRest({ preferredLocations }) {
    if (!preferredLocations.length) {
        return [{ geographyId: null }];
    }

    return preferredLocations.map(geographyId => ({ geographyId }));
}

export default {
    mapPreferencesFromRest(restCollection) {
        if (restCollection.siteTCPreferences
            && !restCollection.siteTCPreferences.length
            && restCollection.optInMarketingEmailFlag) {
            return {
                optIn: restCollection.optInMarketingEmailFlag,
            };
        }

        const siteTCPreferences = restCollection.siteTCPreferences.map(mapSinglePreferenceFromRest);

        const { candidateNumber, optInMarketingEmailFlag } = restCollection;

        return {
            candidateId: candidateNumber,
            optIn: optInMarketingEmailFlag,
            siteTCPreferences,
        };
    },

    mapCreateToRest(job, candidateId, preferences, jobFamiliesIds) {
        const jobFamilies = jobFamiliesIds.map(jobFamilyId => this.mapJobFamilyToRest(jobFamilyId));

        const preferredJobFunctions = job.jobFunctionCode
            ? [{ jobFunctionCode: job.jobFunctionCode }]
            : [];

        return {
            candidateNumber: candidateId,
            context: VERIFICATION_MODES[JOB_APPLICATION],
            optInMarketingEmailFlag: preferences.marketingEmailFlag,
            siteTCPreferences: [{
                siteNumber: appConfig.siteNumber,
                flowVersionId: null,
                legalDescriptionVersionId: null,
                optInJobAlertFlag: preferences.jobAlertFlag,
                preferredLocations: [{ geographyId: job.geographyId }],
                preferredJobFamilies: jobFamilies,
                preferredJobFunctions,
            }],
        };
    },

    mapJobFamilyToRest(jobFamilyId) {
        return { jobFamilyId };
    },

    mapSaveJobAlertToRest(candidateId, jobAlert) {
        return {
            candidateNumber: candidateId,
            context: jobAlert.flowContext,
            optInMarketingEmailFlag: jobAlert.optInMarketingEmailFlag,
            siteTCPreferences: [mapSinglePreferenceToRest(jobAlert)],
        };
    },

    mapSaveCSSOptInEmailAlertToRest({ candidateId, flowContext, optInEmailFlag }) {
        return {
            candidateNumber: candidateId,
            context: flowContext,
            optInMarketingEmailFlag: optInEmailFlag,
        };
    },

    mapSaveTCJobAlertToRest(jobAlert, candidateId) {
        return {
            candidateNumber: candidateId,
            context: VERIFICATION_MODES[CSS_ENTRY],
            siteTCPreferences: [mapSinglePreferenceToRest(jobAlert)],
        };
    },

    mapAcceptToRest(tcPreferences) {
        return {
            candidateNumber: tcPreferences.candidateId,
            context: tcPreferences.flowContext,
            siteTCPreferences: [],
        };
    },

    mapRejectToRest(tcPreferences) {
        return {
            candidateNumber: tcPreferences.candidateId,
            action: 'DELETE',
            siteTCPreferences: [],
        };
    },
};
