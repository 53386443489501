import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import applyFlowEvents from 'apply-flow/config/events';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import postApplyService from '../../../apply-flow-post/service/postApply';
import userTracking from 'cx/service/userTracking';
import { validationNotifications } from 'apply-flow/service/backend-validation/validationNotifications';
import compositeValidation from 'apply-flow/service/backend-validation/compositeValidation';

const INVALID_APPLICATION_MESSAGE = 'apply-flow.validation.invalid-application';

export default class ApplyFlowPaginationViewModel {

    constructor({ flow, isValidationInProgress }) {
        this.isSingleClick = flow().isSingleClick;
        this.flowIterator = flow().iterator;

        this.route = postApplyService.isPostApply() ? 'post-apply' : this._getApplyFlowRoute();

        this.validationMessage = observable(false);

        this.isFirstSection = pureComputed(() => {
            const flowIterator = this.flowIterator();

            return flowIterator.isFirstSection();
        });

        this.isLastSection = pureComputed(() => {
            const flowIterator = this.flowIterator();

            return flowIterator.isLastSection();
        });

        this._currentSectionSubscription = this.flowIterator().currentSectionNumber.subscribe(() => {
            this.validationMessage(false);
        });

        this._invalidESignatureSignal = applyFlowEvents.invalidESignature.add(() => {
            this._createValidationSubscription(sectionValidator.isAllSectionsValid());

            this.validationMessage(this.getValidationMessage());
        });

        this.isValidationInProgress = isValidationInProgress;

        this.submit = this.submit.bind(this);
    }

    _getApplyFlowRoute() {
        const route = router.route();
        let routeId = '';

        if (route.parent && route.parent.id === 'job-preview') {
            routeId = 'job-preview.apply-flow';
        } else {
            routeId = 'apply-flow';
        }

        return routeId;
    }

    _runBeforeExit() {
        this.flowIterator().runBeforeExit();
    }

    async _runBeforeValidation() {
        await this.flowIterator().runBeforeValidation();
    }

    back() {
        router.go(this.route, { sectionNumber: this.flowIterator().previousStepSectionNumber() });
    }

    next() {
        const currentSectionNumber = parseInt(this.flowIterator().currentSectionNumber(), 10);

        userTracking.trackJobApplyIn(router.routeParams().jobId, 1 + currentSectionNumber,
            this.flowIterator().lastSectionNumber);

        this._runBeforeValidation();
        this.flowIterator().runBlockValidation();

        sectionValidator.validateSection(currentSectionNumber).then(() => {
            this._runBeforeExit();

            router.go(this.route, { sectionNumber: this.flowIterator().nextStepSectionNumber() });
        });
    }

    isNextEnabled() {
        return sectionState.isSectionReady(this.flowIterator().currentSectionNumber());
    }

    async submit() {
        this.isValidationInProgress(true);

        await this._runBeforeValidation();

        this.flowIterator().runBlockValidation();

        userTracking.trackJobApplicationSubmit(router.routeParams().jobId);

        return sectionValidator.validateAllSections()
            .then((validSections) => {
                if (validSections) {
                    this.validationMessage(false);
                    this._runBeforeExit();

                    this.isValidationInProgress(true);

                    compositeValidation.validate()
                        .then(() => {
                            applyFlowEvents.submit.dispatch();
                        })
                        .catch(error => this._handleValidationError(error))
                        .finally(() => this.isValidationInProgress(false));
                } else {
                    const isAllSectionValid = sectionValidator.isAllSectionsValid();

                    this._createValidationSubscription(isAllSectionValid);

                    this.validationMessage(this.getValidationMessage());

                    accessibilityEvents.addAlert.dispatch(i18n(this.getValidationMessage()));
                    this.isValidationInProgress(false);
                    applyFlowEvents.submitHasErrors.dispatch();
                }
            });
    }

    _handleValidationError(error) {
        if (error === 'job-expired') {
            router.go('job-details');
        } else {
            console.error(error);
        }
    }

    isSubmitEnabled() {
        return !this.isValidationInProgress() && sectionState.isAllSectionsReady();
    }

    dispose() {
        validationNotifications.clearNotifications();
        this._currentSectionSubscription.dispose();
        this._invalidESignatureSignal.detach();
    }

    _createValidationSubscription(validObservable) {
        const validSubscription = validObservable.subscribe((isValid) => {
            if (isValid) {
                this.validationMessage(false);

                validSubscription.dispose();
            }
        });
    }

    getValidationMessage() {
        return INVALID_APPLICATION_MESSAGE;
    }

}
