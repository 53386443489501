import i18n from 'core/i18n/i18n';
import { CssTab } from '../../config/types';
import { SearchRoute, searchRouteMap } from 'app/module/cx/module/search/config/types';

const HEADER_TEXT_APPLICATIONS = i18n('candidate-self-service.no-data-component.applications-tab-header');
const SUBHEADER_TEXT_APPLICATIONS = i18n(
    'candidate-self-service.no-data-component.applications-tab-subheader'
);
const BUTTON_TEXT_APPLICATIONS = i18n('search.view-all-jobs');

const HEADER_TEXT_EVENTS = i18n('candidate-self-service.no-data-component.events-tab-header');
const SUBHEADER_TEXT_EVENTS = i18n('candidate-self-service.no-data-component.events-tab-subheader');
const BUTTON_TEXT_EVENTS = i18n('search.view-all-events');

type Props = {
    context: Exclude<CssTab, 'info'>;
};

export class CsNoDataComponentViewModel {
    headerText = '';
    subheaderText = '';
    buttonText = '';
    buttonRoute: SearchRoute = searchRouteMap.jobs;

    constructor({ context }: Props) {
        this.setComponentData(context);
    }

    private setComponentData(context: CssTab): void {
        switch (context) {
            case 'applications': {
                this.headerText = HEADER_TEXT_APPLICATIONS;
                this.subheaderText = SUBHEADER_TEXT_APPLICATIONS;
                this.buttonText = BUTTON_TEXT_APPLICATIONS;
                this.buttonRoute = searchRouteMap.jobs;
                break;
            }

            case 'events': {
                this.headerText = HEADER_TEXT_EVENTS;
                this.subheaderText = SUBHEADER_TEXT_EVENTS;
                this.buttonText = BUTTON_TEXT_EVENTS;
                this.buttonRoute = searchRouteMap.events;
                break;
            }

            default: {
                break;
            }
        }
    }
}
