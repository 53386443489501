import { Facet } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import SearchFiltersHorizontalViewModel from '../search-filters-horizontal/SearchFiltersHorizontalViewModel';
import { getExpandedFacetTypes } from '../search-filters-vertical/service/getExpandedFacetTypes';
import { Observable, ObservableArray, Subscription } from 'knockout';

type Props = {
    totalCount: Observable<number>;
    filters: ObservableArray<Facet>;
    adminMode: boolean;
    defaultSearchContext: string;
    loading: Observable<boolean>;
    popupFiltersOpened: boolean;
    allExpanded: boolean;
    clear: () => void;
};

export default class SearchFiltersVerticalViewModel extends SearchFiltersHorizontalViewModel {
    popupFiltersOpened: boolean;
    expandedFacetTypes: string[];
    filtersSubscription: Subscription;

    constructor({
        totalCount,
        filters,
        adminMode,
        defaultSearchContext,
        loading,
        popupFiltersOpened,
        allExpanded,
        clear,
    }: Props) {
        super({
            totalCount,
            filters,
            adminMode,
            defaultSearchContext,
            loading,
            clear,
        });

        this.popupFiltersOpened = popupFiltersOpened;
        this.expandedFacetTypes = [];

        this.filtersSubscription = filters.subscribe((filters) => {
            this.expandedFacetTypes = getExpandedFacetTypes(filters, allExpanded);
        });
    }

    dispose(): void {
        this.filtersSubscription.dispose();
    }
}
