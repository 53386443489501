import 'ojs/ojselectsingle';
import 'ojs/ojhighlighttext';
import 'ojs/ojlistitemlayout';
import 'ojs/ojrestdataprovider';
import 'ojs/ojdeferreddataprovider';

import { components } from 'knockout';

import ViewModel from './CxSelectSingleViewModel';
import template from './cx-select-single.html';

components.register('cx-select-single', { viewModel: ViewModel, template });
