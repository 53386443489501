import dqQuestionsMapper from '../module/disqualification-questions/mapper/questionnaire';
import staticQuestionnaireMapper from '../module/static-questionnaire/mapper/questionnaire';
import { ORA_DQ_QUESTIONS, ORA_QUESTIONNAIRE, ORA_EVENT_REG_QUESTIONS } from '../enum/questionnaireBlocks';
import eventQuestionsMapper from '../module/event-registration-questions/mapper/questionnaire';

const MAPPERS = {
    [ORA_DQ_QUESTIONS.blockCode]: dqQuestionsMapper,
    [ORA_QUESTIONNAIRE.blockCode]: staticQuestionnaireMapper,
    [ORA_EVENT_REG_QUESTIONS.blockCode]: eventQuestionsMapper,
};

function containsResponses({ questionResponses }) {
    const parsedResponses = JSON.parse(questionResponses);

    return Boolean(parsedResponses.length);
}

function mapQuestionnaire(questionnaire) {
    const mapper = MAPPERS[questionnaire.blockCode()];

    return mapper.mapDataToRest(questionnaire);
}

export default {

    mapAllQuestionnairesToRest(questionnaires) {
        return questionnaires
            .filter(({ questions }) => questions().length)
            .reduce((allQuestionnaires, questionnaire) => {
                const mappedQuestionnaire = mapQuestionnaire(questionnaire);

                if (containsResponses(mappedQuestionnaire)) {
                    allQuestionnaires.push(mappedQuestionnaire);
                }

                return allQuestionnaires;
            },
            []);
    },
};