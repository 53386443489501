import flowService from 'apply-flow/service/flow';
import router from 'app/model/router';
import { PROFILE_ITEM_SERVICE } from './profileItemServiceConfiguration';
import ProfileItemCompositeService from './profileItemCompositeService';
import ProfileItemService from './profileItemService';

class ProfileItemServiceFactory {

    constructor() {
        this.initialize();
    }

    getCompositeService() {
        return this.compositeService;
    }

    initialize() {
        this.compositeService = new ProfileItemCompositeService();
    }

    create(type, formElements) {
        const sectionId = this._getSectionId(formElements);
        const attributes = this._getAttributes(type, formElements);
        const { flowVersionId } = flowService.get();
        const { submissionId } = router.routeParams();

        const profileItemService = new ProfileItemService(type, {
            flowVersionId,
            submissionId,
        });

        this.compositeService.registerService({
            type,
            service: profileItemService,
            attributes,
            sectionId,
        });

        return profileItemService;
    }

    _getSectionId(formElements) {
        const profileItemFormElement = formElements
            .filter(element => Boolean(element.sectionId))
            .shift()
            || {};

        return profileItemFormElement.sectionId || null;
    }

    _getAttributes(type, formElements) {
        const { primaryKey } = PROFILE_ITEM_SERVICE[type];

        return {
            primaryKey,
            all: this._getDataAttributes(formElements).concat(primaryKey),
            clob: this._getClobAttributes(formElements),
        };
    }

    _getDataAttributes(formElements) {
        return formElements
            .map(element => element.name)
            .concat('sectionId', 'flowVersionId');
    }

    _getClobAttributes(formElements) {
        return formElements
            .filter(element => element.datatype === 'CLOB')
            .map(element => element.name);
    }

}

export default new ProfileItemServiceFactory();