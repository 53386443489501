import { components } from 'knockout';

import { JobDetailsSimilarJobsItemViewModel as ViewModel } from './JobDetailsSimilarJobsItemViewModel';
import StaticViewModel from './JobDetailsSimilarJobsItemStaticViewModel';
import templateListItem from './job-details-similar-jobs-list-item.html';
import templateGridItem from './job-details-similar-jobs-grid-item.html';

components.register('job-details-similar-jobs-list-item', {
    viewModel: ViewModel,
    template: templateListItem,
});

components.register('job-details-similar-jobs-grid-item', {
    viewModel: ViewModel,
    template: templateGridItem,
});

components.register('job-details-similar-jobs-list-item-static', {
    viewModel: StaticViewModel,
    template: templateListItem,
});

components.register('job-details-similar-jobs-grid-item-static', {
    viewModel: StaticViewModel,
    template: templateGridItem,
});
