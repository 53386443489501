import router from 'app/model/router';
import storageService from 'core/storage/sessionStorage';

export const PREVIOUS_ROUTE = 'previousRoute';

export default {
    store() {
        const route = {
            id: router.route().id,
            params: router.routeParams(),
        };

        const previousRoutes = storageService.restore(PREVIOUS_ROUTE);

        const routes = previousRoutes
            ? [...previousRoutes, route]
            : [route];

        storageService.store(PREVIOUS_ROUTE, routes);
    },

    moveBackOrFallbackTo(...defaultRoute) {
        const previousRoutes = storageService.restore(PREVIOUS_ROUTE);

        if (!previousRoutes) {
            return defaultRoute.length
                ? router.go(...defaultRoute)
                : router.go('home-page');
        }

        const previousRoute = previousRoutes.pop();

        if (previousRoutes.length) {
            storageService.store(PREVIOUS_ROUTE, previousRoutes);
        } else {
            storageService.remove(PREVIOUS_ROUTE);
        }

        return router.go(previousRoute.id, previousRoute.params);
    },

    isPreviousRouteStored() {
        return Boolean(storageService.restore(PREVIOUS_ROUTE));
    },
};

