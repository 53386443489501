export default class FormChangeDetector {

    constructor(form) {
        this.form = form;

        this.initialState = this.form.elements()
            .map(element => ({
                value: element.value(),
                name: element.name(),
            }));
    }

    hasChanged() {
        const currentElements = this.form.elements();

        return currentElements.map((currentElement) => {
            const initialElement = this.initialState
                .find(elelement => elelement.name === currentElement.name());

            return initialElement
                ? this._areEqual(initialElement, currentElement)
                : false;
        })
            .includes(false);
    }


    _areEqual(initialElement, currentElement) {
        if (initialElement.value === currentElement.value()) {
            return true;
        }

        if (this._isCheckbox(currentElement)) {
            return this._areUncheckedElementsEqual(initialElement, currentElement);
        }

        return false;
    }

    _areUncheckedElementsEqual(initialElement, currentElement) {
        return currentElement.value() === currentElement.uncheckedValue
                && initialElement.value === null;
    }

    _isCheckbox(element) {
        return Boolean(element.uncheckedValue);
    }

}
