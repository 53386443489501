import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { JobDetailsInfoParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-info/config/types';
import {
    generateJobAttributes,
    generateJobFlexFields,
    JobAttribute,
    AttributesList,
} from 'cx/module/job-details/service/jobAttributes';
import { Observable, PureComputed, pureComputed } from 'knockout';
import {
    JobMetaTagKey,
    FLEX_FIELDS_GROUP,
} from 'site-editor/module/job-details-editor/component/cc-job-details-info/config/jobMetaTypes';
import { RequisitionFlexField } from 'minimal/module/job-details/types/index';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsInfoParams;
    id?: string;
};

export class JobDetailsInfoViewModel extends CustomComponentViewModel<JobDetailsInfoParams> {
    pageData: PureComputed<JobDetailsPageData>;
    jobMetaTypesOptions: Observable<JobMetaTagKey[]>;
    jobDetailsInfoItems: PureComputed<JobAttribute[]>;
    attributesList: AttributesList;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
        this.jobMetaTypesOptions = this.customizationParams.content.jobMetaTypesOptions;

        this.attributesList = generateJobAttributes(this.pageData().job);

        this.jobDetailsInfoItems = pureComputed(() => {
            return this.jobMetaTypesOptions().reduce((filteredItems, item) => {
                if (!item) {
                    return filteredItems;
                }

                if (this.isFlexFieldGroup(item)) {
                    return filteredItems.concat(
                        generateJobFlexFields(
                            this.pageData().job as { requisitionFlexFields: RequisitionFlexField[] }
                        )
                    );
                }

                if (this.isStandardFieldWithValue(item)) {
                    return filteredItems.concat(this.attributesList[item]);
                }

                return filteredItems;
            }, [] as JobAttribute[]);
        });
    }

    isFlexFieldGroup(item: JobMetaTagKey): boolean {
        return item === FLEX_FIELDS_GROUP;
    }

    isStandardFieldWithValue(item: JobMetaTagKey): boolean {
        return item !== FLEX_FIELDS_GROUP && Object.prototype.hasOwnProperty.call(this.attributesList, item);
    }

    getCustomStyle(key: keyof JobDetailsInfoParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'titleTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-details__description-header`,
                    typography: this.customizationParams[key],
                });

            case 'jobInfoTitleTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-meta__title`,
                    typography: this.customizationParams[key],
                });

            case 'jobInfoDataTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-meta__subitem`,
                    typography: this.customizationParams[key],
                });

            case 'container':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: `.${this.uniqueWrapperClass} .job-details__section .job-meta__pin-icon`,
                    selector: `.${this.uniqueWrapperClass} .job-details__section`,
                    stylingBox: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
