import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import httpConfig from 'core/http/config';
import fadeInTransition from 'core/router/transition/fadeIn';
import searchEvents from 'search/config/events';
import cxEvents from 'cx/config/events';
import tokenService from 'candidate-verification/service/token';
import submissionService from 'candidate-verification/service/submission';
import unverifiedService from 'candidate-verification/service/unverified';
import jobService from 'job-details/service/job';
import urlEncoder from 'core/router/urlEncoder';

import {
    addLogger,
    removeLoggerOnApplyFlow,
} from './logger';


function canEnterApplyFlow({ jobId }, routeWhenNoAccesCode, referralRoute) {
    const decodedJobId = urlEncoder.decode(jobId);

    return new Promise((resolve, reject) => {
        shouldCheckJobPresence(referralRoute)
            .then((shouldCheckJob) => {
                if (!shouldCheckJob) {
                    return true;
                }

                return isJobStillAvailable(decodedJobId);
            })
            .then(() => submissionService.hasJobSubmission(decodedJobId))
            .then((hasSubmission) => {
                if (hasSubmission && !tokenService.isCandidateVerified()) {
                    reject({ redirectTo: 'job-details.confirm-application' });
                } else if (hasSubmission) {
                    reject({ redirectTo: 'job-details.existing-submission' });
                } else if (unverifiedService.hasAppliedToJob(decodedJobId)
                    && unverifiedService.hasExceededUnverifiedApplicationsLimit()) {
                    reject({ redirectTo: 'job-details.confirm-application' });
                } else if (!tokenService.accessCodeExists()) {
                    reject({ redirectTo: routeWhenNoAccesCode });
                } else {
                    resolve();
                }
            })
            .catch((error) => {
                if (error === 'job-expired') {
                    reject({
                        redirectTo: 'job-preview',
                        redirectParams: { jobId },
                    });
                }
            });
    });
}

async function isJobStillAvailable(jobId) {
    await jobService.getLightJob(jobId);
}

async function shouldCheckJobPresence(referralRoute) {
    if (referralRoute === 'apply-flow') {
        return false;
    }

    return true;
}

router.configure({
    'apply-flow': {
        url: '/job/{jobId}/apply/section/{sectionNumber}:?query:',
        view: {
            layout: 'apply-flow',
        },
        title: i18n('apply-flow.page-title'),
        transition: fadeInTransition,
        canEnter(routeParams, referralRoute) {
            return canEnterApplyFlow(routeParams, 'job-details', referralRoute);
        },
        enter() {
            httpConfig.getMethodConfig('GET').retry = 3;

            searchEvents.jobDetails.isClosing.dispatch(true);
            cxEvents.loaded.dispatch();

            addLogger();
        },
        exit(params, newRouteId) {
            removeLoggerOnApplyFlow(this.id, newRouteId);

            delete httpConfig.getMethodConfig('GET').retry;
        },
    },
    'job-preview.apply-flow': {
        parent: 'job-preview',
        url: '/apply/section/{sectionNumber}',
        view: {
            layout: 'apply-flow',
        },
        transition: fadeInTransition,
        canEnter(routeParams, referralRoute) {
            return canEnterApplyFlow(routeParams, 'job-preview', referralRoute);
        },
        enter() {
            httpConfig.getMethodConfig('GET').retry = 3;

            cxEvents.loaded.dispatch();

            addLogger();
        },
        exit(params, newRouteId) {
            removeLoggerOnApplyFlow(this.id, newRouteId);

            delete httpConfig.getMethodConfig('GET').retry;
        },
    },
    'job-details.existing-submission': {
        parent: 'job-details',
        url: '/apply/submission-exists',
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'existing-submission',
        },
    },
    'apply-flow.thank-you-assessment': {
        parent: 'job-details',
        url: '/apply/{token}/thank-you-assessment',
        view: {
            layout: 'apply-flow-light',
            'apply-flow-content': 'thank-you-assessment',
        },
    },
    'job-preview.confirm-email': {
        parent: 'job-preview',
        url: '/apply/confirm-email',
        view: {
            layout: 'candidate-verification-layout',
            'candidate-verification-content': 'confirm-email',
        },
    },
});