import personalInformationFormBuilder from './personalInformationFormBuilder';
import editPersonalInformationFormBuilder from './editPersonalInformationFormBuilder';

const EMAIL = 'email';
const PHONE = 'phone';

export default {
    getForm(params) {
        const {
            elements,
            includeUniversalFields,
            includeContactFields,
            candidate,
            editMode,
            preferredTerritoryCode,
            isTCFlow,
        } = params;

        let universalElements = [];
        let emailElement = null;
        let phoneElement = null;

        if (includeUniversalFields) {
            universalElements = elements.filter(element => ![EMAIL, PHONE].includes(element.name));
        }

        if (includeContactFields) {
            emailElement = elements
                .find(({ name }) => name === EMAIL);

            phoneElement = elements
                .find(({ name }) => name === PHONE);
        }

        const formParams = {
            universalElements,
            emailElement,
            phoneElement,
            candidate,
            preferredTerritoryCode,
            isTCFlow,
        };

        const builder = editMode
            ? editPersonalInformationFormBuilder
            : personalInformationFormBuilder;

        return builder.createForm(formParams);
    },
};