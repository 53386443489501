import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class UniqueWorkLocationsFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.validation.unique-locations';

        ko.utils.extend(this._options, {
            preferred_label: this._options.preferredLabel,
            excluded_label: this._options.excludedLabel,
        });
    }

    _validate(valueObs) {
        const value = ko.unwrap(valueObs);

        const locations = [].concat(value.preferredLocations, value.excludedLocations);
        const distinctLocations = ko.utils.arrayGetDistinctValues(locations);

        return distinctLocations.length === locations.length;
    }

}
