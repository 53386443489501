import httpLangCached from '../../../../../service/http/httpLangCached';
import { mapContextFromRest } from '../mapper/metadata';
import flowBlockMapper from '../../../mapper/flowBlock';

export default class Metadata {

    constructor(serviceUrl, blockProperties = {}) {
        this._serviceUrl = serviceUrl.replace('<CONTEXT_CODE>', blockProperties.context);
    }

    getContext() {
        return httpLangCached.get(this._serviceUrl)
            .then(mapContextFromRest);
    }

    isEmpty(restBlock) {
        const { context } = flowBlockMapper.getBlockProperties(restBlock);

        return Promise.resolve(!context);
    }

}