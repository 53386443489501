import { observable } from 'knockout';
import applyFlowEvents from '../../config/events';

const MESSAGE_MINIMAL_TIME = 2000;
const ANIMATION_PROGRESS_TIME = 500;

export default class ApplyFlowDraftSavingIndicatorViewModel {

    constructor() {
        this.messageProgress = observable(false);
        this.messageProgressVisible = observable(false);
        this.messageDone = observable(false);
        this.messageDoneVisible = observable(false);
        this.startTime = null;

        this.beforeApplicationDraftSaveSignal = applyFlowEvents.beforeApplicationDraftSave.add(this.show.bind(this));
        this.afterApplicationDraftSaveSignal = applyFlowEvents.afterApplicationDraftSave.add(this.hide.bind(this));
    }

    show() {
        this._showProgress();
    }

    hide() {
        this._hideProgress();
    }

    _showProgress() {
        this.startTime = Date.now();
        this.messageProgress(true);

        setTimeout(() => {
            this.messageProgressVisible(true);
        }, 50);
    }

    _hideProgress() {
        return new Promise((resolve) => {
            const timeDiff = Date.now() - this.startTime;

            if (timeDiff < MESSAGE_MINIMAL_TIME) {
                setTimeout(() => {
                    this._hideProgress().then(resolve);
                }, MESSAGE_MINIMAL_TIME - timeDiff);
            } else {
                this.messageProgressVisible(false);

                setTimeout(() => {
                    this.messageProgress(false);
                    resolve();
                }, ANIMATION_PROGRESS_TIME);
            }
        });
    }

    dispose() {
        this.beforeApplicationDraftSaveSignal.detach();
        this.afterApplicationDraftSaveSignal.detach();
    }

}
