export const parseDistance = (distance?: string): string => {
    return Number.parseFloat(distance || '0')
        .toFixed(1)
        .toLocaleString();
};

type GetDistanceTextProps =
    | {
          isDistanceOutOfRadius: true;
          inputRadius: string | undefined;
      }
    | {
          isDistanceOutOfRadius: false;
          jobDistance: string | undefined;
      };

export const getDistanceText = (props: GetDistanceTextProps): string => {
    return props.isDistanceOutOfRadius
        ? `+${parseDistance(props.inputRadius)}`
        : parseDistance(props.jobDistance);
};
