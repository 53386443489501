import http from 'core/http/http';
import searchMapper from 'search/mapper/search';
import { mapJobsFromRest } from 'search/mapper/searchMarkers';
import appConfig from 'app/model/config';
import tokenService from 'candidate-verification/service/token';
import * as spellCheckService from 'minimal/module/search/service/spellCheck';
import httpMapper from 'core/http/mapper';
import { decodeCompressedString } from 'ce-common/module/maps/service/inflateBase64';
import { getWorkLocationsDisplay } from 'cx/module/job-details/mapper/location';

const SEARCH_LIMIT = 24;

const SEARCH_RECOMMENDED_JOBS_LIMIT = 10;

export const SEARCH_AI_JOBS_LIMIT = 4;

export const SEARCH_MAP_JOBS_LIMIT = 10000;

export default class Search {

    jobsBaseUrl = '/recruitingCEJobRequisitions?onlyData=true';

    mapsBaseUrl = '/recruitingCEJobRequisitions/action';

    eventsBaseUrl = '/recruitingCEEvents?onlyData=true';

    recommendedJobsBaseUrl = '/recruitingCEJobSearchRecommendations';

    paramsDelimiter = ',';

    urlSuffix = ';:findParams:';

    searchEvents(params, registeredEventList = []) {
        const url = `${this.eventsBaseUrl}&finder=findEvents${this.urlSuffix}`;

        const httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchEventsParamsToRest(params, appConfig.siteNumber),
            },
        };

        return http.get(url, httpConfig)
            .then(results => searchMapper.mapSearchEventResultsFromRest(results, registeredEventList));
    }

    searchJobsAndFacets(params, candidateReqList = []) {
        const url = `${this.jobsBaseUrl}&expand=requisitionList.secondaryLocations,flexFieldsFacet.values,requisitionList.requisitionFlexFields&finder=findReqs${this.urlSuffix}`;

        const parameters = spellCheckService.addSpellCheckToParams(params);

        const httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchParamsToRest(parameters, appConfig.siteNumber, SEARCH_LIMIT),
            },
        };

        return http.get(url, httpConfig)
            .then(results => searchMapper.mapSearchResultsFromRest(results, candidateReqList));
    }

    searchRecommendedJobsAndFacets(payload) {
        const httpConfig = {
            baseUrl: appConfig.ceBaseUrl,
            transformRequest(request) {
                request.body = JSON.stringify(request.body);

                return request;
            },
        };

        return http
            .post(
                this.recommendedJobsBaseUrl,
                searchMapper.mapSearchRecommendedJobsParamsToRest(
                    payload,
                    appConfig.siteNumber,
                    payload?.limit ?? SEARCH_RECOMMENDED_JOBS_LIMIT,
                ),
                httpConfig,
            )
            .then((result) => {
                const parseResult = typeof result === 'string' ? JSON.parse(result) : result;

                const mappedFromRest = httpMapper.mapFromRest(parseResult);

                return searchMapper.mapSearchRecommendedJobsResultsFromRest(mappedFromRest);
            });
    }

    searchJobs(params) {
        const url = `${this.jobsBaseUrl}&expand=requisitionList.secondaryLocations&finder=findReqs${this.urlSuffix}`;

        const httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchParamsToRest(params, appConfig.siteNumber, SEARCH_LIMIT, 'NONE'),
            },
        };

        return http.get(url, httpConfig)
            .then(searchMapper.mapSearchResultsFromRest);
    }

    markerJobDetails(params) {
        const httpConfig = {
            transformRequest(request) {
                request.body = JSON.stringify(request.body);

                return request;
            },
            headers: {
                'Content-Type': 'application/vnd.oracle.adf.action+json',
            },
        };

        const url = `${this.mapsBaseUrl}/getRequisitionDetailsForMap`;

        const { limit, offset, requisitionIds } = params;

        const payload = {
            searchContext: [{
                limit,
                offset,
                requisitionIds,
                siteNumber: appConfig.siteNumber,
                workLocationsDisplay: getWorkLocationsDisplay(),
            }],
        };

        return http.post(url, payload, httpConfig);
    }

    searchMarkersChunk(params) {
        const httpConfig = {
            transformRequest(request) {
                request.body = JSON.stringify(request.body);

                return request;
            },
            headers: {
                'Content-Type': 'application/vnd.oracle.adf.action+json',
            },
        };

        if (params?.selectedFlexFieldsFacets) {
            params.selectedFlexFieldsFacets = JSON.parse(params.selectedFlexFieldsFacets);
        }

        params.workLocationsDisplay = getWorkLocationsDisplay();

        const url = `${this.mapsBaseUrl}/getRequisitionsLocationCoordinates`;

        const parameters = spellCheckService.addSpellCheckToParams(params, true);

        const payload = {
            searchContext: [searchMapper.mapSearchParamsToRest(parameters, appConfig.siteNumber, SEARCH_MAP_JOBS_LIMIT, 'NONE', false)],
        };

        return http.post(url, payload, httpConfig)
            .then(({ result }) => {
                const { reqLocationCoordinatesCompressed } = result;

                const [base64StringObject] = reqLocationCoordinatesCompressed;

                return decodeCompressedString(base64StringObject.reqLocationCoordinatesCompressed);
            }).then(mapJobsFromRest);
    }

    searchMarkers(params, totalCount) {
        const getPromises = [];
        const chunksCount = totalCount / SEARCH_MAP_JOBS_LIMIT;
        let offset = 0;

        while (offset < chunksCount) {
            const chunkParams = Object.assign({}, params);

            chunkParams.limit = SEARCH_MAP_JOBS_LIMIT;
            chunkParams.offset = offset;
            getPromises.push(this.searchMarkersChunk(chunkParams));

            offset += 1;
        }

        return Promise.all(getPromises)
            .then(chunks => chunks.flat());
    }

    searchSimilarJobs({ params, similarJobId, limit = SEARCH_AI_JOBS_LIMIT, contentLocaleLang }) {
        const url = `${this.jobsBaseUrl}&expand=requisitionList.secondaryLocations&finder=findSimilarRequisitions${this.urlSuffix}`;
        const candidateNumber = this._getCandidateNumber();

        let httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchAIJobsParamsToRest(params, similarJobId, candidateNumber,
                    appConfig.siteNumber, limit),
            },
        };

        if (contentLocaleLang) {
            httpConfig = {
                ...httpConfig,
                headers: {
                    'Accept-Language': contentLocaleLang,
                    'Ora-Irc-Language': contentLocaleLang,
                },
            };

            return http
                .get(url, httpConfig)
                .then(result => searchMapper.mapSimilarJobsFromRest(result, contentLocaleLang))
                .then(searchMapper.mapSearchResultsFromRest);
        }

        return http.get(url, httpConfig)
            .then(searchMapper.mapSearchResultsFromRest);
    }

    searchJobsToCandidate(params, lastAppliedJobId) {
        const candidateNumber = this._getCandidateNumber();

        const url = `${this.jobsBaseUrl}&expand=requisitionList.secondaryLocations&finder=findSimilarRequisitionsByCandidate${this.urlSuffix}`;

        if (!params.limit) {
            params.limit = SEARCH_AI_JOBS_LIMIT;
        }

        const httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchAIJobsParamsToRest(
                    params,
                    lastAppliedJobId,
                    candidateNumber,
                    appConfig.siteNumber,
                    params.limit,
                ),
            },
        };

        return http.get(url, httpConfig)
            .then(searchMapper.mapSearchResultsFromRest);
    }

    searchFacets(params) {
        const url = `${this.jobsBaseUrl}&expand=flexFieldsFacet.values&finder=findReqs${this.urlSuffix}`;

        const httpConfig = {
            ...this._getDefaultHttpConfig(),
            params: {
                findParams: searchMapper.mapSearchParamsToRest(params, appConfig.siteNumber, SEARCH_LIMIT),
            },
        };

        return http.get(url, httpConfig)
            .then(searchMapper.mapSearchResultsFromRest);
    }

    _getCandidateNumber() {
        return tokenService.getCandidateNumber();
    }

    _getDefaultHttpConfig() {
        return {
            paramsDelimiter: this.paramsDelimiter,
        };
    }

}
