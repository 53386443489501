import { components } from 'knockout';

import template from './attachment-upload-button.html';
import ViewModel from 'cx/module/apply-flow/module/file-upload/component/attachment-upload-button/AttachmentUploadButtonViewModel';

components.register('attachment-upload-button', {
    viewModel: ViewModel,
    template,
});

components.register('resume-upload-button', {
    viewModel: {
        createViewModel({ model }) {
            return model;
        },
    },
    template,
});

components.register('cover-letter-upload-button', {
    viewModel: {
        createViewModel({ model }) {
            return model;
        },
    },
    template,
});
