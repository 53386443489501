import { observableArray, pureComputed } from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class RadioFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.attributes('type', 'radio');
        this.component('radio-form-element');
        this.options = observableArray();
        this.valueLabel = pureComputed(this._getValueLabel, this);
    }

    _getValueLabel() {
        const [selectedOption = {}] = this.options().filter(option => option.lookupCode === this.value());

        return selectedOption.meaning || '';
    }

}