import { pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import applyFlowEvents from 'apply-flow/config/events';
import synchronization from 'apply-flow/service/synchronization';
import candidate from 'apply-flow/model/candidate';
import linksFormBuilder from './service/linksFormBuilder';
import ApplyFlowBlockComponentViewModel from 'apply-flow/module/common/ApplyFlowBlockComponentViewModel';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import candidateAttachmentService from 'apply-flow/module/file-upload/service/candidateAttachmentService';
import SiteLink from './model/SiteLink';

const LINKS_MAX = 3;
const LINK_LABEL = i18n('apply-flow.section-more-about-you.site-link-url-label');
const LINK_NAME = 'siteLink';

export default class CandidateLinksViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        this.linksRequired = params.linksRequired;

        this.linkedSites = candidate.linkedSites;

        this.isLimitReached = pureComputed(() => this.linkedSites().length === LINKS_MAX);

        this._linkedSitesSubscription = this.linkedSites.subscribe(this._renderForm.bind(this));
        this._submitFailureEventHandler = applyFlowEvents.refresh.add(this._getCandidateSiteLinks.bind(this));

        this._ensureHavingLinkAfterProfileImport = applyFlowEvents.profileImportSucceed
            .add(this._addEmptyLinkIfNoLinks.bind(this));

        this._getCandidateSiteLinks()
            .then(this._renderForm.bind(this))
            .then(this._addAccessibilityMessages.bind(this));

        this.lastLinkInputSelector = '.candidate-links__link:last-of-type input';
    }

    addLink() {
        this.linkedSites.push(new SiteLink());

        accessibilityEvents.addMessage.dispatch(LINK_LABEL);
    }

    removeLink(siteLink) {
        this.linkedSites.remove(siteLink);

        setTimeout(() => {
            const [firstLink] = document.querySelectorAll('[id^=siteLink-1]');

            firstLink.focus();
        }, 0);

        accessibilityEvents.removeMessage.dispatch(LINK_LABEL);
    }

    dispose() {
        this._linkedSitesSubscription.dispose();
        this._submitFailureEventHandler.detach();
        this._ensureHavingLinkAfterProfileImport.detach();
    }

    async _getCandidateSiteLinks() {
        const cachedLinks = synchronization.unshiftAll('links');
        const cachedDeletedLinks = synchronization.unshiftAll('links.deleted');

        if (cachedLinks.length) {
            candidate.linkedSites(cachedLinks);
        }

        if (cachedDeletedLinks.length) {
            candidate.linkedSites.pushRemovedItems(cachedDeletedLinks);
        }

        if (candidate.id()) {
            await candidateAttachmentService.query(candidate.id(), true)
                .catch(error => console.error(error))
                .then((response) => {
                    if (response?.linkedSites) {
                        this._initializeSiteLinks(response);
                    }
                });
        }

        this._addEmptyLinkIfNoLinks();
    }

    _addEmptyLinkIfNoLinks() {
        const hasLinkedSites = Boolean(this.linkedSites().length);

        if (!hasLinkedSites) {
            this.linkedSites.push(new SiteLink());
        }
    }

    _initializeSiteLinks({ linkedSites }) {
        linkedSites.forEach((linkedSite) => {
            const findLink = ({ id }) => id() === linkedSite.id();

            const isAdded = candidate.linkedSites()
                .find(findLink);

            const isDeleted = candidate.linkedSites.getRemovedItems()
                .find(findLink);

            if (!isAdded && !isDeleted) {
                candidate.linkedSites.push(linkedSite);
            }
        });
    }

    _renderForm() {
        const form = this._createForm();

        this._unregisterForm();

        this.form(null);
        this.form(form);

        this._registerForm();
    }

    _createForm() {
        return linksFormBuilder.createForm(this.linkedSites(), {
            label: LINK_LABEL,
            name: LINK_NAME,
            required: this.linksRequired,
        });
    }

    _addAccessibilityMessages() {
        const restLinkedSites = this.linkedSites().slice(1);

        restLinkedSites.forEach(() => accessibilityEvents.addMessage.dispatch(LINK_LABEL));
    }

}
