import { observable, unwrap } from 'knockout';
import l10n from 'core/l10n/l10n';
import interviewSchedulingEvents from '../../../../config/events';
import interviewSchedulingService from '../../../../service/interviewScheduling';

export default class ScheduleRowButtonViewModel {

    constructor(params) {
        this.scheduleRowButton = params.scheduleRowButton;
        this.submissionId = params.submissionId;
        this.time = this._formatTimeShort(this.scheduleRowButton.startTime());
        this.model = observable();
    }

    _formatTimeShort(date) {
        const d = unwrap(date);

        return d ? l10n.time.formatShort(d) : null;
    }

    openScheduleConfirmDialog() {
        this._loadBasedOnO365()
            .then(model => this.model(model))
            .then(() =>
                interviewSchedulingService.getLocationDetail(this.model().interviewId)
                    .then(value => this.model().locationDetail(value)),
            )
            .then(() => {
                interviewSchedulingEvents.showConfirmDialog.dispatch({
                    interviewModel: this.model(),
                });
            });
    }

    _loadBasedOnO365() {
        if (this.scheduleRowButton.autoCalendarSlot) {
            return Promise.resolve(this.scheduleRowButton);
        }

        return interviewSchedulingService.getInterviewDetail(this.scheduleRowButton.interviewId,
            this.submissionId(), this.scheduleRowButton.action);
    }

}