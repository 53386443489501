export default class AbstractStorage {

    exists(key) {
        return Boolean(this._getStorageHandler().getItem(key));
    }

    store(key, data) {
        try {
            this._getStorageHandler().setItem(key, JSON.stringify(data));
        } catch (error) {
            console.error(error);
        }

        return this;
    }

    restore(key) {
        const storage = this._getStorageHandler();

        return JSON.parse(storage.getItem(key));
    }

    remove(key) {
        this._getStorageHandler().removeItem(key);

        return this;
    }

    verify(key, data) {
        if (JSON.stringify(this.restore(key)) !== JSON.stringify(data)) {
            return this._handleStoreError();
        }

        return true;
    }

    _handleStoreError(key) {
        this.remove(key);

        throw new Error('Storage store verification error');
    }

    _getStorageHandler() {
        throw new TypeError('This method should be overriden');
    }

}