import i18n from 'core/i18n/i18n';
import FormTileStrategy from '../../tile-profile-items/model/FormTileStrategy';
import { LEGISLATION_CODE, NATIONAL_IDENTIFIER_TYPE, NATIONAL_IDENTIFIER_NUMBER } from '../config/personalIdentifyingInformationFieldMap';

const UNNAMED_NATIONAL_IDENTIFIER_LABEL = i18n('apply-flow.section-personal-identifying-information.unnamed-national-identifier');

export default class PiiFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return {
            legislationCode: LEGISLATION_CODE,
            nationalIdentifierType: NATIONAL_IDENTIFIER_TYPE,
            nationalIdentifierNumber: NATIONAL_IDENTIFIER_NUMBER,
        };
    }

    buildTitle(valueMap) {
        const nationalId = valueMap.nationalIdentifierNumber
            || '';

        const nationalIdType = valueMap.nationalIdentifierType
            || UNNAMED_NATIONAL_IDENTIFIER_LABEL;

        const maskedNationalId = nationalId.replace(/.(?=.{2})/g, '*');

        return {
            nationalId: maskedNationalId,
            nationalIdType,
        };
    }

    buildSubtitle(valueMap) {
        return valueMap.legislationCode || '';
    }

}