import mapping from 'knockout-mapping';

export default function SiteLink(data = {}) {
    mapping.fromJS({
        id: undefined,
        url: undefined,
        description: undefined,
        type: undefined,
        action: undefined,
    }, {}, this);

    mapping.fromJS(data, {}, this);
}
