import ko from 'knockout';
import FlowIterator from 'apply-flow/model/FlowIterator';
import SingleClickFlowIterator from 'apply-flow/model/SingleClickFlowIterator';

export default class Flow {

    constructor(configuration) {
        ko.utils.extend(this, configuration);
    }

    iterator() {
        if (Flow._iterator === undefined) {
            Flow._iterator = this._createFlowIterator();
        }

        return Flow._iterator;
    }

    containsBlock(blockCode) {
        const blocks = this._getBlocks();

        return blocks.some(block => block.code === blockCode);
    }

    _createFlowIterator() {
        if (this.isSingleClick) {
            return new SingleClickFlowIterator(this.sections);
        }

        return new FlowIterator(this.sections);
    }

    _getBlocks() {
        return this.sections
            .reduce((pages, section) => pages.concat(section.pages), [])
            .reduce((blocks, page) => blocks.concat(page.blocks), []);
    }

    destroy() {
        Flow._iterator = undefined;
    }

}

Flow._iterator = undefined;
