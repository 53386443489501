import appConfig from 'app/model/config';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import tokenService from 'candidate-verification/service/token';
import applyFlowEvents from 'apply-flow/config/events';
import candidateModel from 'apply-flow/model/candidate';
import { VERIFICATION_MODES, TC_CANDIDATE, TC_CONFIRM } from 'candidate-verification/config/verificationSubmodules';
import jobAlertPreferencesModel from 'apply-flow/module/job-alert-preferences/model/jobAlertPreferencesModel';
import submitService from 'apply-flow/service/submit';
import submitChecklist from 'apply-flow/service/submit-checklist/submitChecklist';
import checklistStore from 'apply-flow/service/submit-checklist/store/store';
import talentCommunityEvents from '../../config/events';
import ChallengeModel from 'candidate-verification/model/challenge';
import { ATTACHMENT_MIME_TYPE_ERROR, ATTACHMENT_UPLOAD_ERROR } from 'apply-flow/module/file-upload/config/attachmentErrorCodes';
import applicationDraftService from '../../../apply-flow/service/applicationDraft';
import apiLogger from 'core/api-logger/apiLogger';
import { getBaseLogMetadata } from 'core/api-logger/logMetadata';
import { TALENT_COMMUNITY } from 'core/api-logger/config/module';
import { handleAllSettled } from 'core/utils/handleAllSettled';

export default class TalentCommunitySummaryViewModel {

    constructor({ flow }) {
        this.flow = flow();
        this.progressVisible = checklistStore.state.showDialog;
        this.module = TALENT_COMMUNITY;
        this.subscribeToJobAlert = false;

        this._saveCandidate = this._saveCandidate.bind(this);

        applyFlowEvents.submit.add(this._saveCandidate);
    }

    _saveCandidate() {
        submitChecklist.reset();
        submitChecklist.show();
        this.subscribeToJobAlert = candidateModel.subscribeToJobAlert();

        return submitService.saveCandidate()
            .then(this._onCandidateSave.bind(this))
            .then(this._clearCandidate.bind(this))
            .then(this._onSuccess.bind(this))
            .catch(this._onError.bind(this))
            .finally(submitChecklist.hide);
    }

    async _onCandidateSave() {
        if (this.subscribeToJobAlert) {
            candidateModel.subscribeToJobAlert(true);
        }

        await this._saveJobAlertPreference();

        return Promise.allSettled([
            applicationDraftService.updateCandidateDrafts(candidateModel),
            submitService.updateCandidateLastModificationDate(candidateModel),
        ])
            .then(handleAllSettled);
    }

    _saveJobAlertPreference() {
        const { id, preferences } = candidateModel;

        return jobAlertService.saveJobAlert(id(),
            this._createJobAlertPreferencesPayload(preferences));
    }

    _createJobAlertPreferencesPayload(preferences) {
        const { flowId, legalDescriptionVersionId } = this.flow;

        return {
            siteNumber: appConfig.siteNumber,
            flowContext: VERIFICATION_MODES[TC_CANDIDATE],
            optInMarketingEmailFlag: preferences.optIn ? preferences.optIn() : undefined,
            flowVersionId: flowId,
            legalDescriptionVersionId,
            jobAlertsEnabled: candidateModel.subscribeToJobAlert(),
            preferredLocations: jobAlertPreferencesModel.getLocationIds(),
            preferredJobFamilies: jobAlertPreferencesModel.getJobFamilyIds(),
            preferredJobFunctions: jobAlertPreferencesModel.preferredJobFunctions(),
        };
    }

    _clearCandidate() {
        jobAlertPreferencesModel.clear();
        candidateModel.clear();
    }

    _confirmTalentCommunityProfile() {
        jobAlertService.confirmTalentCommunity({
            candidateId: candidateModel.id(),
            flowContext: VERIFICATION_MODES[TC_CONFIRM],
        })
            .catch(console.error);

        talentCommunityEvents.userConfirmed.dispatch();
    }

    _onSuccess() {
        this._dumpLogs();

        const isCandidateConfirmed = tokenService.isKnownCandidate();

        if (isCandidateConfirmed) {
            this._confirmTalentCommunityProfile();

            return;
        }

        const challenge = new ChallengeModel({
            candidate: {
                verificationMethod: candidateModel.basicInformation.verificationMethod,
                phone: candidateModel.basicInformation.phone,
                email: candidateModel.basicInformation.email,
            },
            actions: {
                onPinValid: this._onPinValid.bind(this),
                onAttempsLimitReached: this._onAttempsLimitReached.bind(this),
            },
        });

        tokenService.destroy();
        talentCommunityEvents.challengeRequired.dispatch(challenge);
    }

    _onPinValid() {
        this._confirmTalentCommunityProfile();
    }

    _onAttempsLimitReached() {
        talentCommunityEvents.hide.dispatch();
    }

    _onError(error) {
        this._dumpLogsOnError();

        this._clearCandidate();

        talentCommunityEvents.submitFailed.dispatch(error);

        if (error === 'candidate-exists') {
            return;
        }

        let errorMessage;

        if (error === ATTACHMENT_UPLOAD_ERROR) {
            errorMessage = i18n('apply-flow.submit-errors.file-upload');
        } else if (error === ATTACHMENT_MIME_TYPE_ERROR) {
            errorMessage = i18n('validators.file-upload.mime-type-error');
        }

        notificationsService.error(errorMessage, 0);

        throw new Error(error);
    }

    _dumpLogs() {
        apiLogger.dump(this._getLogMetadata());
    }

    _dumpLogsOnError() {
        apiLogger.dumpOnError(this._getLogMetadata());
    }

    _getLogMetadata() {
        return Object.assign(getBaseLogMetadata(), {
            module: this.module,
        });
    }

}
