import $ from 'jquery';
import router from 'app/model/router';
import postApplyService from '../../../../apply-flow-post/service/postApply';
import { isApplyFlowRoute } from 'candidate-verification/service/routes';

const TABBABLE_SELECTOR = ':tabbable';

const INVALID_SECTION_SELECTOR = '[data-section-invalid="true"]';
const FIRST_INVALID_SELECTOR = '.input-row--invalid, [data-profile-item-invalid="true"]';

function centerToElement(element) {
    const offset = (window.outerHeight / 2) - element.height();
    const { top } = element.offset();

    window.scrollTo(0, top - offset);
}

function goToFirstInvalidSection() {
    const invalidSection = $(INVALID_SECTION_SELECTOR).first().attr('data-section-number');
    const currentSection = router.routeParams().sectionNumber;

    if (invalidSection !== currentSection) {
        let url = postApplyService.isPostApply() ? 'post-apply' : 'apply-flow';

        if (!isApplyFlowRoute()) {
            url = 'event-register-flow';
        }

        router.go(url, { sectionNumber: invalidSection });
    }
}

function getElementToFocus(container = window.body) {
    const firstInvalid = $(FIRST_INVALID_SELECTOR, container).first();

    if (!firstInvalid.length) {
        return $(TABBABLE_SELECTOR, container).first();
    }

    return firstInvalid;
}

function setFocusOn(element) {
    centerToElement(element);

    if (element.is(TABBABLE_SELECTOR) && element.is('button')) {
        element.focus();
    } else {
        element.find(TABBABLE_SELECTOR).first().focus();
    }
}

function checkForAccordion() {
    const collapsibleElements = document.querySelectorAll('.oj-collapsible');

    if (collapsibleElements.length > 0) {
        collapsibleElements.forEach((collapsible) => {
            const invalidElements = $(collapsible).find(FIRST_INVALID_SELECTOR);

            if (invalidElements.length > 0) {
                const accordion = document.getElementById(collapsible.closest('.oj-accordion').getAttribute('id'));

                if (collapsible) {
                    accordion.set('expanded', [{ id: collapsible.getAttribute('id') }]);
                }
            }
        });
    }
}

export default {

    run(multipage, delay = 0) {
        if (multipage) {
            goToFirstInvalidSection();
        }

        const firstInvalid = getElementToFocus();

        if (!firstInvalid.length) {
            return;
        }

        if (firstInvalid.attr('data-profile-item-tile')) {
            centerToElement(firstInvalid);

            firstInvalid.click();

            return;
        }

        checkForAccordion(firstInvalid);

        setTimeout(() => setFocusOn(firstInvalid), delay);
    },

    focusInvalid(container, delay = 0) {
        const parent = container.target || container;

        const focusOn = getElementToFocus(parent);

        if (!focusOn.length) {
            return;
        }

        setTimeout(() => setFocusOn(focusOn), delay);
    },

};
