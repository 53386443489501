import httpMapper from 'core/http/mapper';
import formElementMapper from 'apply-flow/mapper/metadata';
import { convertFusionDateToBaseFormat } from 'core/utils/fusionDateConverter';

const TYPES_MAP = {
    BOOLEAN: 'checkbox',
    DATE: 'date',
    NUMBER: 'integer',
    DECIMAL: 'decimal',
    TEXTAREA: 'textarea',
    CLOB: 'clob',
};

function sortByDisplaySequence(item1, item2) {
    return item1.displaySequence - item2.displaySequence;
}

function convertDefaultValue(restFormElement) {
    const { type, defaultValue } = restFormElement;

    if (defaultValue) {
        if (type === 'NUMBER') {
            return Number(defaultValue);
        } else if (type === 'DATE') {
            return convertFusionDateToBaseFormat(defaultValue);
        }
    }

    return defaultValue;
}

export default {
    getFormElementType(restType) {
        return TYPES_MAP[restType] || 'text';
    },

    mapFormElementsFromRest(restData) {
        const restFormElements = restData.items.sort(sortByDisplaySequence);

        return Promise.all(
            restFormElements.map(restFormElement =>
                this.mapFormElementFromRest(restFormElement, restFormElements)),
        );
    },

    mapFormElementFromRest(restFormElementData, restFormElements) {
        const restFormElement = restFormElementData;

        const { type: datatype } = restFormElement;

        restFormElement.type = restFormElement.maximumLength > 240 ? 'TEXTAREA' : restFormElement.type;
        restFormElement.defaultValue = convertDefaultValue(restFormElement);

        const formElement = {
            type: this.getFormElementType(restFormElement.type),
            datatype,
            name: httpMapper.toCamelCase(restFormElement.attributeName),
            label: restFormElement.prompt,
            defaultValue: restFormElement.defaultValue,
            value: restFormElement.defaultValue,
            isHidden: restFormElement.uihiddenFlag,
            keyFlag: restFormElement.keyFlag,
            attributes: {
                readonly: restFormElement.readonlyFlag,
                required: restFormElement.requiredFlag,
            },
            validators: {},
            options: restFormElement.answers,
            sectionId: restFormElement.sectionId,
        };

        if (restFormElement.dictionary) {
            formElement.withEmptyOption = formElement.type !== 'checkbox';
            formElement.type = 'select';
        }

        if (restFormElement.requiredFlag) {
            formElement.validators.required = {
                label: restFormElement.prompt,
            };
        }

        if (restFormElement.maximumLength) {
            formElement.validators.maxLength = {
                max: restFormElement.maximumLength,
            };
        }

        return formElementMapper.mapFormElementFromRest(restFormElement, formElement, restFormElements);
    },
};