import {
    desktopOnly,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { notReachable } from 'app/types/utils';
import { SearchResultsParams } from 'minimal/module/search/component/search-results/config/types';
import { getSearchEventResultSelectors } from './selectors';
import { defaultSearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { getParamsValueMergeFunction } from 'minimal/module/search/service/observableParams/getParamsValueMergeFunction';
import { mergeObservableParams } from 'minimal/module/search/service/observableParams/mergeObservableParams';
import { getJobDisplayStyle } from 'minimal/module/search/component/search-job-results/config/getComponentClass';

export const getCustomStyles = (params: SearchResultsParams, uniqueWrapperClass: string): string => {
    const eventDisplayStyle = getJobDisplayStyle(params.contentParams.jobDisplayStyle());
    const selectors = getSearchEventResultSelectors(`.${uniqueWrapperClass}`)[eventDisplayStyle];
    const isList = eventDisplayStyle === 'list';

    const customizationKeys = Object.keys(params) as (keyof SearchResultsParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'contentParams':
                return desktopOnly(
                    getMaxWidthCss({
                        selector: selectors.container,
                        width: params[key].width(),
                        widthUnit: params[key].widthUnit(),
                    })
                );

            case 'headerTypography':
                return getTypographyCss({
                    selector: selectors.itemTitle,
                    typography: params[key],
                });

            case 'headerTypographyOnHover':
                const headerTypographyOnHover = mergeObservableParams({
                    sourceParams: params.headerTypography,
                    targetParams: params.headerTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.headerTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemTitleOnHover,
                    typography: headerTypographyOnHover,
                });

            case 'subHeaderTypography':
                return getTypographyCss({
                    selector: selectors.itemSubHeader,
                    typography: params[key],
                });

            case 'subHeaderTypographyOnHover':
                const subHeaderTypographyOnHover = mergeObservableParams({
                    sourceParams: params.subHeaderTypography,
                    targetParams: params.subHeaderTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.subHeaderTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.itemSubHeaderOnHover,
                    typography: subHeaderTypographyOnHover,
                });

            case 'descriptionTypography':
                return getTypographyCss({
                    selector: selectors.itemDescription,
                    typography: params[key],
                });

            case 'descriptionTypographyOnHover':
                const descriptionTypographyOnHover = mergeObservableParams({
                    sourceParams: params.descriptionTypography,
                    targetParams: params.descriptionTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.descriptionTypographyOnHover,
                    }),
                });

                return isList
                    ? ''
                    : getTypographyCss({
                          selector: selectors.itemDescriptionOnHover,
                          typography: descriptionTypographyOnHover,
                      });

            case 'sortByTypography':
                return getTypographyCss({
                    selector: selectors.itemSortBy,
                    typography: params[key],
                });

            case 'sortOptionsTypography':
                return getTypographyCss({
                    selector: selectors.itemSortOptions,
                    typography: params[key],
                });

            case 'selectedSortValueTypography':
                return getTypographyCss({
                    selector: selectors.itemSelectedSortValue,
                    typography: params[key],
                });

            case 'selectedSortOptionTypography':
                return getTypographyCss({
                    selector: selectors.itemSelectedSortOption,
                    typography: params[key],
                });

            case 'container':
                return getStylingBoxCss({
                    selector: selectors.root,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'tileStyles':
                return getStylingBoxCss({
                    selector: selectors.item,
                    stylingBox: params[key],
                    lightIconSelector: selectors.itemIcon,
                    darkIconSelector: null,
                });

            case 'tileStylesOnHover':
                const tileStylesOnHover = mergeObservableParams({
                    sourceParams: params.tileStyles,
                    targetParams: params.tileStylesOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.tileStylesOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.itemOnHover,
                    stylingBox: tileStylesOnHover,
                    lightIconSelector: selectors.itemIconOnHover,
                    darkIconSelector: null,
                });

            case 'sortByStyling':
                return getStylingBoxCss({
                    selector: selectors.itemSortByDropdown,
                    stylingBox: params[key],
                    lightIconSelector: selectors.itemSortByIcon,
                    darkIconSelector: null,
                });

            case 'sortByHoverStyling':
                const sortByHoverStyling = mergeObservableParams({
                    sourceParams: params.sortByStyling,
                    targetParams: params.sortByHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.sortByHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.itemSortByIcon,
                    stylingBox: sortByHoverStyling,
                    lightIconSelector: selectors.itemSortByIconOnHover,
                    darkIconSelector: null,
                });

            case 'sortByDropdownStyling':
                return getStylingBoxCss({
                    selector: selectors.itemSortByDropdown,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'tileHeaderStyles':
                return selectors.tileHeader
                    ? getStylingBoxCss({
                          selector: selectors.tileHeader,
                          stylingBox: params[key],
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';

            case 'tileHeaderStylesOnHover':
                const tileHeaderStylesOnHover = mergeObservableParams({
                    sourceParams: params.tileHeaderStyles,
                    targetParams: params.tileHeaderStylesOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.tileHeaderStylesOnHover,
                    }),
                });

                return selectors.tileHeaderOnHover
                    ? getStylingBoxCss({
                          selector: selectors.tileHeaderOnHover,
                          stylingBox: tileHeaderStylesOnHover,
                          lightIconSelector: null,
                          darkIconSelector: null,
                      })
                    : '';
            case 'defaultToggleIconStyles':
                return getStylingBoxCss({
                    selector: selectors.defaultToggleIconStyles,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'defaultToggleIconStylesOnHover':
                return getStylingBoxCss({
                    selector: selectors.defaultToggleIconStylesOnHover,
                    stylingBox: params[key],
                    lightIconSelector: selectors.defaultToggleIconStylesOnHover,
                    darkIconSelector: null,
                });

            case 'jobIconsStyles':
                return getStylingBoxCss({
                    selector: selectors.eventTagBox,
                    stylingBox: params[key],
                    lightIconSelector: selectors.eventIconsStyles,
                    darkIconSelector: null,
                });

            case 'jobIconsStylesOnHover':
                const jobIconsStylesOnHover = mergeObservableParams({
                    sourceParams: params.jobIconsStyles,
                    targetParams: {
                        ...params.jobIconsStylesOnHover,
                        color: params.subHeaderTypographyOnHover.color,
                    },
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobIconsStylesOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.eventTagBoxOnHover,
                    stylingBox: jobIconsStylesOnHover,
                    lightIconSelector: selectors.eventIconsStylesOnHover,
                    darkIconSelector: null,
                });

            case 'alreadyAppliedTagBox':
                return getStylingBoxCss({
                    selector: selectors.alreadyAppliedTagBox,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'alreadyAppliedTagBoxOnHover':
                const alreadyAppliedTagBoxOnHover = mergeObservableParams({
                    sourceParams: params.alreadyAppliedTagBox,
                    targetParams: params.alreadyAppliedTagBoxOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.alreadyAppliedTagBoxOnHover,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.alreadyAppliedTagBoxOnHover,
                    stylingBox: alreadyAppliedTagBoxOnHover,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'selectedToggleIconStyles':
                return getStylingBoxCss({
                    selector: selectors.selectedToggleIconStyles,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'selectedToggleIconStylesOnHover':
                return getStylingBoxCss({
                    selector: selectors.selectedToggleIconStylesOnHover,
                    stylingBox: params[key],
                    lightIconSelector: selectors.selectedToggleIconStylesOnHover,
                    darkIconSelector: null,
                });
            case 'jobInfoValueTypography':
            case 'jobInfoValueTypographyOnHover':
            case 'jobInfoLabelTypography':
                return getTypographyCss({
                    selector: selectors.eventInfo,
                    typography: params[key],
                });

            case 'jobInfoLabelTypographyOnHover':
                const eventInfoTypographyOnHover = mergeObservableParams({
                    sourceParams: params.jobInfoLabelTypography,
                    targetParams: params.jobInfoLabelTypographyOnHover,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultSearchResultsParams.jobInfoLabelTypographyOnHover,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.eventInfoOnHover,
                    typography: eventInfoTypographyOnHover,
                });

            case 'sortOptionsTypographyOnHover':
            case 'selectedSortValueTypographyOnHover':
            case 'alreadyAppliedJobTagOnHover':
            case 'jobTagsTypographyOnHover':
            case 'descriptionTitleTypography':
            case 'descriptionTitleTypographyOnHover':
            case 'applyButton':
            case 'applyButtonOnHover':
            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
            // [TODO-FRCE-82361] - Need to update, when Events will have their own configuration.
            case 'jobTagsTypography':
            case 'alreadyAppliedJobTag':
            case 'jobInfoIconsStyles':
            case 'jobInfoIconsStylesOnHover':
            case 'applyButtonBox':
            case 'applyButtonBoxContainer':
            case 'applyButtonBoxOnHover':
                return '';

            default:
                return notReachable(key);
        }
    });

    return customStyles.filter((style: string) => style.trim().length).join(STYLES_SEPARATOR);
};
