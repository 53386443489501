import ko from 'knockout';
import timelineProjector from './model/timelineProjector';
import timelineEvents from '../../config/events';
import timelineTileBuilderFactory from './service/timelineTileBuilderFactory';

export default class BeautifulTimelineViewModel {

    constructor({ education, experience }) {
        this.educationSource = education;
        this.experienceSource = experience;

        this.educations = ko.observableArray([]);
        this.experiences = ko.observableArray([]);
        this.years = ko.observableArray([]);

        this._educationSub = this.educationSource.subscribe(() => this.render());
        this._experienceSub = this.experienceSource.subscribe(() => this.render());
        this._renderSignal = timelineEvents.renderTimeline.add(() => this.render());
        this._mouseoverItemSignal = timelineEvents.mouseoverItem.add(this.fadeTilesOverItem.bind(this));
        this._mouseleaveItemSignal = timelineEvents.mouseleaveItem.add(this.unFadeTiles.bind(this));

        this.render();
    }

    _calculateYears(startYear, endYear) {
        const years = [];

        for (let y = endYear; y >= startYear; y--) {
            years.push(y);
        }

        return years;
    }

    _calculateYearsRange(tiles) {
        const currentYear = new Date().getFullYear();

        let startYear = currentYear - 3;
        const endYear = currentYear + 1;

        const getYear = (date) => {
            if (!date) {
                return undefined;
            }

            return parseInt(date.split('-')[0], 10);
        };

        tiles.forEach((tile) => {
            const tileStartYear = getYear(tile.startDate) || currentYear;
            const tileEndYear = getYear(tile.endDate) || currentYear;

            startYear = Math.min(startYear, tileStartYear, tileEndYear);
        });

        return {
            startYear,
            endYear,
        };
    }

    fadeTilesOverItem(hovered, type) {
        const items = this[type];

        items().forEach((item) => {
            if (item.level > hovered.level && !(item.end < hovered.start || item.start > hovered.end)) {
                item.isFaded(true);
            }
        });
    }

    unFadeTiles(type) {
        const items = this[type];

        items().forEach((item) => {
            item.isFaded(false);
        });
    }

    render() {
        Promise.all([
            this._fetchItemsData(this.educationSource(), timelineTileBuilderFactory.createEducationTileBuilder()),
            this._fetchItemsData(this.experienceSource(), timelineTileBuilderFactory.createExperienceTileBuilder()),
        ]).then((result) => {
            const [educations, experiences] = result;
            const { startYear, endYear } = this._calculateYearsRange([].concat(educations, experiences));

            this.years(this._calculateYears(startYear, endYear));
            this.experiences(timelineProjector.createProjection('experiences', startYear, experiences));
            this.educations(timelineProjector.createProjection('educations', startYear, educations));
        });
    }

    _fetchItemsData(profileItemForms, tileBuilder) {
        const tiles = profileItemForms.map((profileItemForm) => {
            const form = profileItemForm();

            return form.validate().then(() => tileBuilder.buildTile(form));
        });

        return Promise.all(tiles);
    }

    dispose() {
        this._educationSub.dispose();
        this._experienceSub.dispose();
        this._renderSignal.detach();
        this._mouseoverItemSignal.detach();
        this._mouseleaveItemSignal.detach();
    }

}
