import mapping from 'knockout-mapping';
import candidateModel from 'apply-flow/model/candidate';

const containsElementWithName = (formElements, name) =>
    formElements.find(element => element.name.toUpperCase() === name.toUpperCase());

export async function clearUnusedAddressFields(metadataService) {
    const { address: addressModel } = candidateModel;
    const flatAddressModel = mapping.toJS(addressModel);

    const formElements = await metadataService
        .getFormElements(addressModel.country())
        .catch(console.error);

    if (!formElements) {
        return;
    }

    Object.keys(flatAddressModel).forEach((key) => {
        if (!containsElementWithName(formElements, key)) {
            addressModel[key](undefined);
        }
    });
}