import { Observable, observable, PureComputed, pureComputed, Subscription } from 'knockout';
import SelectFormElement from 'core/form/element/Select';
import GeoHierarchyQueryBuilder from 'apply-flow/module/personal-information-address/service/GeoHierarchyQueryBuilder';
import GeoHierarchyService from 'apply-flow/module/personal-information-address/service/GeoHierarchy';
import candidate from 'apply-flow/model/candidate';
import { equalsIgnoreCase } from 'core/utils/equalsIgnoreCase';
import { GeographyFieldName, GeographyOption } from '../../config/types';
import { KnockoutSubscribableOnce } from 'app/types/subscribeOnce';

const NO_PARENT_VALUE = 'no-parent-value';

export default class GeoHierarchySelect extends SelectFormElement {
    parentElement: Observable<GeoHierarchySelect | undefined>;
    childElement: Observable<GeoHierarchySelect | undefined>;
    geoHierarchyService: GeoHierarchyService | null;
    isLoading: Observable<boolean>;
    lastValue: string | undefined;
    lovColumn: Observable<GeographyFieldName | undefined>;
    lovColumnIndex: PureComputed<number>;
    countrySubscription: Subscription;
    parentValueSubscription: Subscription | undefined;

    constructor(...args: unknown[]) {
        super(...args);

        this.component('geo-hierarchy-select-form-element');

        this.parentElement = observable();
        this.childElement = observable();
        this.geoHierarchyService = null;
        this.isLoading = observable<boolean>(false);

        // Last value is needed to handle profile import with incomplete addresses. If maching parent value is chosen,
        // then imported child values will autocomplete and default to imported values. This was introduced as
        // a bugfix 30351835. With bugfix 33164274 children autocomplete behavior is extended to all last chosen values.
        // e.g. 1. select Province: Napoli, City: Napoli, 2. select Province: Milano - city will clean-up, 3. select
        // Province: Napoli - city will autocomplete to Napoli.
        this.lastValue = undefined;
        this.lovColumn = observable();

        this.lovColumnIndex = pureComputed(() => {
            const lovColumn = this.lovColumn() || '';
            const lovColumnIndex = lovColumn.replace(/[^\d]+/, '');

            return parseInt(lovColumnIndex, 10);
        });

        this.countrySubscription = candidate.address.country.subscribe(() => {
            this.lastValue = undefined;
        });
    }

    private clearFieldValue(): void {
        this.value(undefined);
        this.options([]);
    }

    private findMatchingValue(items: GeographyOption[]): GeographyOption | undefined {
        return items.find(({ lookupCode }) => equalsIgnoreCase(this.getFieldValue(), lookupCode));
    }

    private completeFieldValue(item: GeographyOption | undefined): void {
        this.value(item ? item.lookupCode : undefined);

        if (!this.lastValue) {
            this.lastValue = this.value();
        }
    }

    private getFieldValue(): string {
        return this.value() || this.lastValue;
    }

    registerParent(parentElement: GeoHierarchySelect): void {
        this.parentElement(parentElement);
        parentElement.childElement(this);

        this.parentValueSubscription = parentElement.value.subscribe((value) => {
            if (this.value()) {
                this.lastValue = this.value();
            }

            this.clearFieldValue();

            if (value) {
                this.fetchOptions();
            }
        });

        this.fetchOptions();
    }

    async findValueByTerm(): Promise<GeographyOption | undefined> {
        const fetchOptionsByTerm = await this.getOptions(this.getFieldValue());
        const matchedValue = this.findMatchingValue(fetchOptionsByTerm);

        if (matchedValue) {
            this.options(fetchOptionsByTerm);
        }

        return matchedValue;
    }

    private fetchOptions(): Promise<void> {
        this.isLoading(true);

        return Promise.resolve(this.getQueryBuilder())
            .then(this.resolveDependencies.bind(this))
            .then((queryBuilder) =>
                queryBuilder ? this.geoHierarchyService?.findByQuery(queryBuilder.getQuery()) : []
            )
            .then(
                (
                    response: GeographyOption[]
                ): GeographyOption | undefined | Promise<GeographyOption | undefined> => {
                    this.options(response);

                    if (!this.getFieldValue()) {
                        return undefined;
                    }

                    return this.findMatchingValue(response) || this.findValueByTerm();
                }
            )
            .then((item) => {
                this.completeFieldValue(item);
            })
            .catch((error) => {
                if (error === NO_PARENT_VALUE) {
                    this.clearFieldValue();
                } else {
                    console.error(error);
                }
            })
            .finally(() => this.isLoading(false));
    }

    getOptions(query: string | null): Promise<GeographyOption[]> {
        return Promise.resolve(this.getQueryBuilder())
            .then((queryBuilder) => queryBuilder.whereLike(this.lovColumn(), query))
            .then(this.resolveDependencies.bind(this))
            .then((queryBuilder) =>
                queryBuilder ? this.geoHierarchyService?.findByQuery(queryBuilder.getQuery()) : []
            )
            .then((response) => {
                this.options(response);

                return response;
            })
            .catch(this.handleDependencyError.bind(this));
    }

    async getOptionsWithValue(query: string | null, value: string): Promise<GeographyOption[]> {
        const options = await this.getOptions(query);

        const hasValueInOptions = options.findIndex((option) => option.lookupCode === value) > -1;

        if (hasValueInOptions) {
            return options;
        }

        const valueItem = await this.getOptions(value);
        const allOptions = valueItem.length ? options.concat(valueItem) : options;

        this.options(allOptions);

        return allOptions;
    }

    resolveDependencies(queryBuilder: GeoHierarchyQueryBuilder): Promise<GeoHierarchyQueryBuilder | void> {
        const parentElement = this.parentElement();

        if (!parentElement) {
            return Promise.resolve(queryBuilder);
        }

        return parentElement
            .isFulfilled()
            .then(() => parentElement.hasOptionsReady())
            .then(() => this.getOption(parentElement))
            .then(({ geographyId }) =>
                queryBuilder.where(parentElement.lovColumn(), geographyId || parentElement.value())
            )
            .then(parentElement.resolveDependencies.bind(parentElement))
            .catch(this.handleDependencyError.bind(this));
    }

    getOption(parentElement: GeoHierarchySelect): Promise<GeographyOption> {
        if (parentElement.dependencyField()) {
            return parentElement
                ._fetchOptions(parentElement.dependencyFieldValue())
                .then(() => parentElement.filterOption(parentElement.value()));
        }

        return this.filterOption(parentElement);
    }

    filterOption(parentElement: GeoHierarchySelect): Promise<GeographyOption> {
        const lookupKey = parentElement.optionsKeys().value;

        const geographyIds = parentElement
            .options()
            .filter((item) => equalsIgnoreCase(parentElement.value(), item[lookupKey]))
            .map((item) => item.geographyId)
            .join('@');

        return Promise.resolve({
            lookupCode: this.getFieldValue(),
            meaning: this.getFieldValue(),
            geographyId: geographyIds,
        });
    }

    private handleDependencyError(error: string | unknown): void {
        if (error === NO_PARENT_VALUE) {
            return;
        }

        console.error(error);
    }

    hasOptionsReady(): Promise<void> {
        const resolveOptions = (resolve: CallableFunction, reject: CallableFunction) => {
            if (this.options().length) {
                resolve();
            }

            reject(NO_PARENT_VALUE);
        };

        return new Promise((resolve, reject) => {
            if (this.isLoading()) {
                (this.isLoading as unknown as KnockoutSubscribableOnce<boolean>).subscribeOnce(() =>
                    resolveOptions(resolve, reject)
                );
            } else {
                resolveOptions(resolve, reject);
            }
        });
    }

    isFulfilled(): Promise<void> {
        if (this.value()) {
            return Promise.resolve(this.value());
        }

        this.childElement()?.isLoading(false);

        return new Promise((resolve, reject) => {
            const subscription = this.value.subscribe((value) => {
                if (value) {
                    subscription.dispose();
                    resolve(value);
                }
            });

            setTimeout(() => {
                subscription.dispose();
                reject(NO_PARENT_VALUE);
            }, 100);
        });
    }

    dispose(): void {
        if (this.parentValueSubscription) {
            this.parentValueSubscription.dispose();
        }

        if (this.countrySubscription) {
            this.countrySubscription.dispose();
        }
    }

    registerModel(newValue: string | Observable<string>): this {
        super.registerModel(newValue);

        if (this.value()) {
            this.lastValue = this.value();
        }

        return this;
    }

    protected getQueryBuilder(): GeoHierarchyQueryBuilder {
        return new GeoHierarchyQueryBuilder(this.lovColumnIndex());
    }
}
