import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class MaskedFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.component('masked-text-form-element');
    }

}