import { Observable, observable } from 'knockout';
import customContentService from 'cx/module/custom-content/service/customContent';
import { CUSTOM_SEARCH_PAGE } from 'cx/module/custom-content/enums/pageTypes';
import Page from 'app/module/cx/module/custom-content/model/Page';
import { ERROR_NO_PAGE_SET } from 'cx/module/custom-content/service/customContentHelper';

export default class SearchViewModel {
    page: Observable<Page | null>;

    constructor() {
        this.page = observable(null);

        this.loadPage();
    }

    async loadPage(): Promise<void> {
        try {
            const page = await customContentService.getPageByType(CUSTOM_SEARCH_PAGE);

            this.page(page);
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error: string | unknown): void {
        if (error !== ERROR_NO_PAGE_SET) {
            console.error(error);

            return;
        }

        this.setDefaultPage();
    }

    setDefaultPage(): void {
        const defaultPage = customContentService.getDefaultPage(CUSTOM_SEARCH_PAGE);

        this.page(defaultPage);
    }
}
