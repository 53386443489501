import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/personal-identifying-information/service/Metadata';

import ViewModel from 'apply-flow/module/personal-identifying-information/PersonalIdentifyingInformationViewModel';
import template from '../personal-identifying-information.html';

components.register('personal-identifying-information', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_SENSITIVE_PERSONAL_INFO', {
    component: 'personal-identifying-information',
    repository: metadataRepository,
});