import ko from 'knockout';
import ItemModel from '../model/PersonalIdentifyingInformation';

function mapItemToRest(item) {
    const {
        itemId: id,
        country: legislationCode,
        nationalIdtype: nationalIdentifierType,
        nationalId: nationalIdentifierNumber,
        issueDate,
        placeOfIssue,
        expirationDate,
    } = item;

    return {
        id,
        legislationCode,
        nationalIdentifierType,
        nationalIdentifierNumber,
        issueDate,
        placeOfIssue,
        expirationDate,
    };
}

function mapItemFromRest(item, readOnly) {
    return new ItemModel({
        itemId: item.id,
        country: item.legislationCode,
        nationalIdtype: item.nationalIdentifierType,
        nationalId: item.nationalIdentifierNumber,
        issueDate: item.issueDate,
        placeOfIssue: item.placeOfIssue,
        expirationDate: item.expirationDate,
        action: 'UPDATE',
        readOnly,
    });
}

export default {
    mapPersonalIdentifyingInformationItemsToRest(items, birthDate) {
        const unwrappedItems = ko.toJS(items);

        const piiData = {
            dateOfBirth: birthDate,
        };

        if (!items || items.length === 0) {
            piiData.CandidatePIIData = [];
        } else {
            piiData.CandidatePIIData = unwrappedItems.map(mapItemToRest);
        }

        return piiData;
    },

    mapPersonalIdentifyingInformationItemsFromRest(piiData) {
        return {
            candidatePIIData: piiData.candidatePIIData.map(
                item => mapItemFromRest(item, piiData.readOnly)),
        };
    },
};