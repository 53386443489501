import { SINGLE_CHOICE, MULTIPLE_CHOICE, TEXT } from '../enum/responseTypes';
import base64 from 'core/utils/base64';

const TEXT_KEY = 'responseText';
const LIST_KEY = 'responseIds';

function isQuestionAnswered({ answer, isHidden }) {
    return !isHidden && answer && (!Array.isArray(answer) || answer.length);
}

function computeAnswer({ answer, type }) {
    if (type === SINGLE_CHOICE) {
        return [answer];
    }

    if (type === MULTIPLE_CHOICE) {
        return answer;
    }

    if (type === TEXT) {
        return base64.encode(answer);
    }

    return null;
}

function computeKey(type) {
    const keysMap = {
        [SINGLE_CHOICE]: LIST_KEY,
        [MULTIPLE_CHOICE]: LIST_KEY,
        [TEXT]: TEXT_KEY,
    };

    return keysMap[type] || null;
}

function mapQuestionToRest(question) {
    const { questionId, type: responseType } = question;

    const mappedQuestion = {
        questionId,
        responseType,
    };

    const computedKey = computeKey(responseType);

    if (computedKey) {
        mappedQuestion[computedKey] = computeAnswer(question);
    }


    return mappedQuestion;
}

export default {
    mapQuestionsToRest(questions) {
        const mappedQuestions = questions
            .filter(isQuestionAnswered)
            .map(mapQuestionToRest);

        return JSON.stringify(mappedQuestions);
    },
};