import appConfig from 'app/model/config';
import jobAlertsService from './jobAlerts';

export async function findJobFamilies(candidateNumber, job) {
    const { jobFamilyId } = job;

    if (!jobFamilyId) {
        return Promise.resolve([]);
    }

    const [{ isActive }] = await jobAlertsService.getJobFamiliesByIds([jobFamilyId])
        .catch(error => console.error(error));

    if (isActive) {
        return [jobFamilyId];
    }

    const { siteTCPreferences = [] } = await jobAlertsService.getPreferences(candidateNumber)
        .catch(error => console.error(error));

    const { preferredJobFamilies = [] } = siteTCPreferences.find(({ siteNumber }) =>
        siteNumber === appConfig.siteNumber) || {};

    return preferredJobFamilies;
}