import i18n from 'core/i18n/i18n';
import { SortOption } from 'search/config/types';

export const eventsSortOptions: SortOption[] = [
    {
        code: 'RELEVANCY',
        order: 'ASC',
        name: i18n('search.sort-options.relevancy'),
    },
    {
        code: 'EVENT_DATE',
        order: 'DESC',
        name: i18n('search.sort-options.event-date'),
    },
    {
        code: 'DISTANCE',
        order: 'ASC',
        name: i18n('admin.site-editor.content-editor.additional-information-preview-distance'),
    },
];
