import router from 'app/model/router';
import jobService from 'job-details/service/job';
import tokenService from 'candidate-verification/service/token';
import submissionService from 'candidate-verification/service/submission';
import ResendTokenViewModel from 'candidate-verification/component/token-verification/ResendTokenViewModel';
import notificationsService from 'cx/service/notifications';
import cxEvents from 'cx/config/events';
import { storeShortTokenData } from 'candidate-verification/service/shortTokenStorage';

export default class ProspectVerificationViewModel extends ResendTokenViewModel {

    constructor() {
        super();

        this.action = router.routeParams().action;

        this._loadJob()
            .then(() => this._performOperation())
            .catch(err => this._handleError(err))
            .then(() => cxEvents.loaded.dispatch());
    }

    _handleError(error) {
        this.showErrorNotification(error);
    }

    _handleNotAvailableJob() {
        if (!this.action) {
            return Promise.reject('job-expired');
        }

        return Promise.resolve();
    }

    _loadJob() {
        return jobService.getJob(this.jobId)
            .then(job => this.jobTitle(job.title))
            .catch(() => this._handleNotAvailableJob());
    }


    _performOperation() {
        switch (this.action) {
            case 'not-me':
                return this._markAsNotMe();
            case 'not-interested':
                return this._markAsNotInterested();
            default:
                return this._checkSubmissions();
        }
    }

    _checkSubmissions() {
        return submissionService.hasJobSubmission(this.jobId)
            .then((hasSubmission) => {
                if (!hasSubmission) {
                    storeShortTokenData({ token: this.token, jobId: this.jobId });
                }
            });
    }

    _markAsNotMe() {
        return tokenService.verifyToken(this.token)
            .then(() => notificationsService.success(this.notMeMessage));
    }

    _markAsNotInterested() {
        return tokenService.verifyToken(this.token)
            .then(() => notificationsService.success(this.notInterestedMessage()));
    }

}