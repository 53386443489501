import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class RequiredFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.field-is-required';

        if (this._options.label) {
            this._message = 'validators.label-field-is-required';
        }

        ko.utils.extend(this._options, {
            label: this._options.label,
        });
    }

    _validate(valueObs, options) {
        let value = valueObs;

        if (options && options.property) {
            value = value[options.property];
        }

        value = ko.unwrap(value);

        if (Array.isArray(value)) {
            return this._validateArray(value);
        }

        if (typeof value === 'object' && value != null) {
            return this._validateObject(value);
        }

        if (options && options.checkbox) {
            return this._validateCheckbox(value, options.checkbox);
        }

        return Boolean(value) && /\S/.test(value);
    }

    _validateArray(value) {
        return Boolean(value.length);
    }

    _validateCheckbox(value, { checked = true }) {
        return value === checked;
    }

    _validateObject(value) {
        return Object.keys(value).reduce(
            (result, key) => result || Boolean(value[key]),
            false);
    }

}