import Signal from 'signals';

function createJobDetailsSignals() {
    const signals = {
        isClosing: new Signal(),
        renderedPreview: new Signal(),
        applyButtonVisibilityChanged: new Signal(),
    };

    signals.renderedPreview.memorize = true;

    return signals;
}

export default {
    filterPanel: {
        toggle: new Signal(),
    },

    radius: {
        enable: new Signal(),
        set: new Signal(),
    },

    clear: new Signal(),

    jobDetails: createJobDetailsSignals(),

    query: {
        params: new Signal(),
        clearFacets: new Signal(),
        requestGeolocation: new Signal(),
        basicSearch: new Signal(),
        filterSearch: new Signal(),
        offsetSearch: new Signal(),
        search: new Signal(),
    },

    results: {
        searchFired: new Signal(),
        jobsUpdated: new Signal(),
        facetsUpdated: new Signal(),
        sortByUpdated: new Signal(),
        searchFailed: new Signal(),
    },

    pageLoaded: new Signal(),
};
