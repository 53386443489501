import FormValidator from 'core/form/validator/Validator';

export default class CheckedFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.field-is-required';
    }

    _validate(value) {
        if (value) {
            return (typeof value === 'string' ? value === 'Y' : Boolean(value));
        }

        return true;
    }

}