export type SearchBarCustomizationSelectors = {
    headerTypography: string;
    searchValuePlaceholderTypography: string;
    searchValueTypography: string;
    searchButtonStyle: string;
    buttonHoverStyle: string;
    dropdownValuesTypography: string;
    commonParams: string;
    separators: string;
    fieldset: string;
    buttonBorder: string;
    buttonBackground: string;
    inputBorderMobile: string;
    inputBackgroundDesktop: string;
    dropdownMenu: string;
    lightIconSelector: string;
    lightIconSelectorHover: string;
};

export const getSearchBarSelectors = (wrapperSelector: string): SearchBarCustomizationSelectors => {
    return {
        headerTypography: `${wrapperSelector} .search-box-compact__label, ${wrapperSelector} .search-box-compact__menu-button, ${wrapperSelector} .search-box-compact-zip-code__information, ${wrapperSelector} .search-box-compact__action`,
        searchValuePlaceholderTypography: `${wrapperSelector} .search-box-compact__input::placeholder, ${wrapperSelector} .search-box-compact-recruiting-location .input-row__search-input .oj-combobox-input::placeholder`,
        searchValueTypography: `${wrapperSelector} .search-box-compact__input, ${wrapperSelector} .search-box-compact-recruiting-location .input-row__search-input .oj-combobox-input`,
        searchButtonStyle: `${wrapperSelector} .search-box-compact .search-box-compact__button`,
        buttonHoverStyle: `${wrapperSelector} .search-box-compact__button:hover`,
        dropdownValuesTypography: `${wrapperSelector} .search-box-compact__menu-item, ${wrapperSelector} .input-row__label, ${wrapperSelector} .search-box-compact__menu .input-row__label`,
        commonParams: `${wrapperSelector} .search-box-compact`,
        separators: `${wrapperSelector} .search-box-compact .search-box-compact__fieldset-spacer`,
        fieldset: `${wrapperSelector} .search-box-compact .search-box-compact__fieldset`,
        buttonBorder: `${wrapperSelector} .search-box-compact .search-box-compact__button-border`,
        buttonBackground: `${wrapperSelector} .search-box-compact .search-box-compact__button-background`,
        inputBorderMobile: `${wrapperSelector} .search-box-compact .search-box-compact__fieldset::before`,
        inputBackgroundDesktop: `${wrapperSelector} .search-box-compact .search-box-compact__form::before`,
        dropdownMenu: `${wrapperSelector} .search-box-compact .search-box-compact__menu`,
        lightIconSelector: `${wrapperSelector} .search-box-compact__button .icon-search-thin`,
        lightIconSelectorHover: `${wrapperSelector} .search-box-compact__button:hover .icon-search-thin`,
    };
};
