import { JobDetailsJobFitCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { JOB_Category_TYPES_DEFAULT } from 'site-editor/module/job-details-editor/component/cc-job-details-job-fit/config/jobCategoryTypes';

export const emptyParams: JobDetailsJobFitCustomizationParams = {
    content: {
        jobCategoryTypesOptions: JOB_Category_TYPES_DEFAULT,
    },
    jobFitTitlesTypography: { ...EMPTY_TYPOGRAPHY },
    jobFitScoreTypography: { ...EMPTY_TYPOGRAPHY },
    jobFitTitlesDescriptionTypography: { ...EMPTY_TYPOGRAPHY },
    overallDisplayStyling: { ...EMPTY_BLOCK_STYLES },
    jobFitTitlesStyling: { ...EMPTY_BLOCK_STYLES },
    jobFitPipsStyling: { ...EMPTY_BLOCK_STYLES },
    jobFitPipsSelectedStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
