import { observable } from 'knockout';
import appConfig from 'app/model/config';
import { addScriptToHead } from 'core/utils/addScriptToHead';
import { LI_CONNECTIONS_SETTING_KEY } from 'app/service/globalSettings';
import router from 'app/model/router';
import jobService from 'cx/module/job-details/service/job';
import absoluteRouter from 'app/model/absoluteRouter';

const JOB_DETAILS_ROUTES = [
    'job-details',
    'job-preview',
    'job-full-view',
    'candidate-self-service.job-preview',
];

export default class LinkedInConnectionsViewModel {

    constructor() {
        const { isEnabled, apiKey, integrationContext } = appConfig.getSettingByKey(LI_CONNECTIONS_SETTING_KEY);

        this.linkedInConnectionsEnabled = isEnabled;

        if (!isEnabled) {
            return;
        }

        this.integrationContext = integrationContext;
        this.isScriptLoaded = observable(false);
        this.isReferalMode = observable(false);
        this.jobTitle = observable();
        this.companyName = observable();
        this.jobUrl = observable();
        this.referalUrl = observable();

        const isJobDetails = JOB_DETAILS_ROUTES.includes(router.route().id);

        if (isJobDetails) {
            this.loadJob()
                .then(() => this.loadLinkedInIntegrationScript(apiKey));
        } else {
            this.loadLinkedInIntegrationScript(apiKey);
        }
    }

    loadLinkedInIntegrationScript(apiKey) {
        const content = `
         api_key: ${apiKey}
         extensions: CompanyConnections@https://www.linkedin.com/careersite/extensions/company-connections-js
        `;

        const src = 'https://platform.linkedin.com/xdoor/scripts/in.js';

        addScriptToHead(src, content, true)
            .then(() => {
                this.isScriptLoaded(true);
            })
            .catch(() => {
                console.error('Unable to load script');
            });
    }

    async loadJob() {
        const { jobId } = router.routeParams();

        const job = await jobService.getJob(jobId);

        this.isReferalMode(!job.isConfidential);

        if (this.isReferalMode()) {
            this.jobTitle(job.title);
            this.companyName(appConfig.siteName);
            this.jobUrl(this.getJobUrl(jobId));
            this.referalUrl(this.getReferalUrl(jobId));
        }
    }

    getJobUrl(jobId) {
        return absoluteRouter.interpolate({}, 'job-details', { jobId });
    }

    getReferalUrl(jobId) {
        let referalUrl = `${appConfig.uiBaseUrl}`;

        if (referalUrl.endsWith('/hcmUI')) {
            referalUrl = referalUrl.slice(0, -6);
        }

        referalUrl += '/fndSetup/faces/deeplink?objType=IRC_RECRUITING&action=ICE_JOB_DETAILS_RESP&objKey=pRequisitionNo=';
        referalUrl += jobId;
        referalUrl += ';pCalledFrom=CAND_REFERRAL_DEEPLINK';

        return referalUrl;
    }

}
