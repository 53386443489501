import storageService from 'core/storage/sessionStorage';

const REFERRER_STORAGE_KEY = 'referrer';
const NO_REFERRER_VALUE = 'no-referrer';

export default {
    get() {
        const referrer = storageService.restore(REFERRER_STORAGE_KEY);

        if (referrer === NO_REFERRER_VALUE) {
            return null;
        }

        return referrer;
    },
    store() {
        if (!this.get()) {
            storageService.store(REFERRER_STORAGE_KEY, document.referrer || NO_REFERRER_VALUE);
        }
    },
};
