import http from 'core/http/http';
import { EventCheckinData } from 'minimal/module/event-checkin/config/types';

const CHECK_IN = `CHECKIN`;

export const postCandidateEventCheckin = async (eventCheckinData: EventCheckinData): Promise<string> => {
    const response = await postCandidateEventCheckinApi(eventCheckinData).catch(
        (error: { status: number; response: string }) => {
            return Promise.reject(error.response);
        }
    );

    return response.checkInStatus;
};

const postCandidateEventCheckinApi = async ({
    emailId,
    phoneNumber,
    eventNumber,
}: EventCheckinData): Promise<{ checkInStatus: string }> => {
    const endpoint = '/recruitingCEEventRegistrationCheckIns';

    return http.post(endpoint, {
        EmailId: emailId === '' ? undefined : emailId,
        PhoneNumber: phoneNumber === '' ? undefined : phoneNumber,
        EventNumber: eventNumber,
        Action: CHECK_IN,
    });
};
