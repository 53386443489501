import { extenders } from 'knockout';
import { applicationDraft } from '../service/applicationDraftInstance';

function addRemovedItemToDraft(collectionName, removedItem) {
    if (applicationDraft && collectionName) {
        applicationDraft.addRemovedProfileItem(collectionName, removedItem);
    }
}

function clearRemovedItemsFromDraft(collectionName) {
    if (applicationDraft && collectionName) {
        applicationDraft.clearRemovedProfileItems(collectionName);
    }
}

function stashRemoved(collectionObj, { collectionName, uidPropertyName }) {
    const collection = collectionObj;
    const uidPropName = uidPropertyName;

    let removedItems = [];

    collection.subscribe((changes) => {
        changes.forEach((change) => {
            const collectionItem = change.value;
            const isAlreadyRemoved = removedItems.some(item => item[uidPropName]() === collectionItem[uidPropName]());

            if (!collectionItem[uidPropName]() || isAlreadyRemoved) {
                return;
            }

            if (change.status === 'deleted') {
                removedItems.push(collectionItem);
                addRemovedItemToDraft(collectionName, collectionItem);
            }
        });
    }, null, 'arrayChange');

    collection.markAsDeleted = (item) => {
        removedItems.push(item);
        addRemovedItemToDraft(collectionName, item);
    };

    collection.getRemovedItems = () =>
        removedItems
            .filter(item => (item.action() !== 'CREATE'))
            .filter(removedItem => collection().every(item => item[uidPropName]() !== removedItem[uidPropName]()))
            .map((item) => {
                item.action('DELETE');

                return item;
            });

    collection.clearRemovedItems = () => {
        removedItems = [];
        clearRemovedItemsFromDraft(collectionName);
    };

    collection.clearItems = () => {
        collection([]);
        removedItems = [];
        clearRemovedItemsFromDraft(collectionName);
    };

    collection.pushRemovedItems = (items) => {
        removedItems.push(...items);
    };
}

extenders.stashRemoved = stashRemoved;