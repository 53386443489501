import { Store } from 'core/store/Store';

export const START = 'start';
export const STOP = 'stop';
export const BLOCK = 'block';
export const REVEAL_CLOCK = 'reveal-clock';

const INIT = 'init';
const HIDE_CLOCK = 'hide-clock';
const BLOCK_RESEND = 'block-resend';
const TICK = 'tick';
const SET_TIMER = 'run-timer';

const COUNTDOWN_SECONDS = 60;

export default {
    createStore() {
        return new Store({
            state: {
                secondsLeft: null,
                clockRevealed: false,
                timer: null,
                blocked: false,
            },

            mutations: {
                [INIT]: (state) => {
                    state.secondsLeft(COUNTDOWN_SECONDS);
                },

                [SET_TIMER]: (state, timer) => {
                    clearInterval(state.timer());
                    state.timer(timer);
                },

                [TICK]: (state) => {
                    const next = Math.max(0, state.secondsLeft() - 1);

                    state.secondsLeft(next);
                },

                [REVEAL_CLOCK]: (state) => {
                    state.clockRevealed(true);
                },

                [HIDE_CLOCK]: (state) => {
                    state.clockRevealed(false);
                },

                [BLOCK_RESEND]: (state) => {
                    state.blocked(true);
                },
            },

            actions: {
                [START]: ({ state, commit }) => {
                    commit(INIT);

                    const timer = setInterval(() => {
                        commit(TICK);

                        if (state.secondsLeft() <= 0) {
                            commit(HIDE_CLOCK);
                            commit(SET_TIMER, null);
                        }
                    }, 1000);

                    commit(SET_TIMER, timer);
                },

                [STOP]: ({ commit }) => {
                    commit(SET_TIMER, null);
                },

                [BLOCK]: ({ commit }) => {
                    commit(HIDE_CLOCK);
                    commit(BLOCK_RESEND);
                },
            },
        });
    },
};