import InterviewAction from '../model/InterviewAction';

export default {
    mapScheduleActionToRest(requestId, interviewId, submissionId) {
        return new InterviewAction({
            actionType: 'SCHEDULE_INTERVIEW',
            requestId,
            interviewId,
            submissionId,
        });
    },
    mapRescheduleActionToRest(requestId, interviewId) {
        return new InterviewAction({
            actionType: 'RESCHEDULE_INTERVIEW',
            requestId,
            interviewId,
        });
    },
    mapCancelActionToRest(requestId) {
        return new InterviewAction({
            actionType: 'CANCEL_INTERVIEW',
            requestId,
        });
    },
    mapActionFromRest(value) {
        return new InterviewAction({
            actionType: value.actionType,
            requestId: value.interviewRequestId,
            interviewId: value.interviewId,
            submissionId: value.submissionId,
            comments: value.comments,
            message: value.message,
        });
    },
    mapAutoSlotScheduleActionToRest({ requestId, scheduleId, submissionId, startDate, endDate }) {
        return new InterviewAction({
            actionType: 'SCHEDULE_AUTO_SLOT_INTERVIEW',
            requestId,
            scheduleId,
            submissionId,
            startDate,
            endDate,
        });
    },
    mapAutoSlotRescheduleActionToRest({ requestId, submissionId, startDate, endDate }) {
        return new InterviewAction({
            actionType: 'RESCHEDULE_AUTO_SLOT_INTERVIEW',
            requestId,
            submissionId,
            startDate,
            endDate,
        });
    },
    mapRequestMoreSlotsToRest(requestId, comments) {
        return new InterviewAction({
            actionType: 'REQUEST_MORE_SLOTS',
            requestId,
            comments,
        });
    },
};