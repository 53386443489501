import mapping from 'knockout-mapping';
import FileAttachmentModel from 'apply-flow/module/file-upload/model/FileAttachment';

const ATTACHMENT_TYPE = 'document';
const DEFAULT_CATEGORY_ID = 'MISC';
const SAVE_DRAFT = 'savedraft';
const ACTION_DRAFT = 'draft';
const COPY_DRAFT = 'copyFromReferral';

function _isFileAttachment(attachment) {
    return attachment.type === ATTACHMENT_TYPE;
}

function mapDraftFileAttachmentToSubmit(fileAttachmentModel) {
    const { id } = mapping.toJS(fileAttachmentModel);

    return {
        sourceAttachmentId: id,
    };
}

export default {
    ATTACHMENT_TYPE,

    mapFileAttachmentsToRest(attachments) {
        return attachments
            .map(attachment => this.mapFileAttachmentToRest(attachment));
    },

    mapFileAttachmentToRest(fileAttachmentModel) {
        const { id, categoryId, fileName, fileContents } = mapping.toJS(fileAttachmentModel);

        return {
            id,
            type: ATTACHMENT_TYPE,
            category: categoryId || DEFAULT_CATEGORY_ID,
            attachment: fileName,
            fileContents,
        };
    },

    mapDraftFileAttachmentToRest(fileAttachmentModel) {
        const { id, categoryId, fileName, fileContents } = mapping.toJS(fileAttachmentModel);

        return {
            action: ACTION_DRAFT,
            id,
            type: ATTACHMENT_TYPE,
            category: categoryId || DEFAULT_CATEGORY_ID,
            attachment: fileName,
            fileContents,
        };
    },

    mapDraftFileAttachmentFromRest({ appdraftId, category, attachment, id, fileContents, isReferral }) {
        return new FileAttachmentModel({
            appDraftId: appdraftId,
            type: ATTACHMENT_TYPE,
            categoryId: category || DEFAULT_CATEGORY_ID,
            fileName: attachment,
            id,
            action: '',
            fileContents,
            isReferral,
        });
    },

    mapCreateDraftFileAttachmentFromRest(attachment) {
        const mappedAttachment = this.mapDraftFileAttachmentFromRest(attachment);

        mappedAttachment.fileContents(undefined);

        return mappedAttachment;
    },

    mapDraftFileAttachmentsFromRest(restCollection) {
        const restFileAttachments = restCollection.items || [];

        return restFileAttachments.filter(_isFileAttachment).map(this.mapDraftFileAttachmentFromRest);
    },

    mapFileAttachmentsFromRest(restCollection) {
        const restFileAttachments = restCollection.items || [];

        return restFileAttachments.filter(_isFileAttachment).map(this.mapFileAttachmentFromRest);
    },

    mapFileAttachmentFromRest({ id, sourceAttachmentId, attachment, fileContents, category, isReferral }) {
        return new FileAttachmentModel({
            id,
            sourceAttachmentId,
            fileName: attachment,
            fileContents,
            categoryId: category === DEFAULT_CATEGORY_ID ? '' : category,
            action: '',
            isReferral,
        });
    },

    mapDraftAttachmentsToSubmit(candidate, attachments) {
        const restAttachments = attachments.map(mapDraftFileAttachmentToSubmit);

        return {
            candidateNumber: candidate.id(),
            email: candidate.basicInformation.email(),
            lastName: candidate.basicInformation.lastName(),
            attachments: restAttachments,
            action: SAVE_DRAFT,
        };
    },

    mapCopyDraftAttachment(attachmentModel) {
        const { id, categoryId, fileName, sourceAttachmentId, isReferral } = mapping.toJS(attachmentModel);
        const attachmentId = isReferral ? sourceAttachmentId : id;

        return {
            action: COPY_DRAFT,
            sourceAttachmentId: attachmentId,
            type: ATTACHMENT_TYPE,
            category: categoryId || DEFAULT_CATEGORY_ID,
            attachment: fileName,
        };
    },

    mapMiscAttachmentsToRest(attachments) {
        return attachments.filter(({ isMiscellaneous }) => isMiscellaneous)
            .map(({ id }) => id)
            .join(',');
    },

};