import { Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';

type VerticalFilter = Filter & {
    cssClass: string;
};

const FILTER_SELECTOR = 'search-filters__set-filter';
const SELECTED_FILTER_SELECTOR = `${FILTER_SELECTOR} search-filters__filter--active`;

const isFilterSelected = (filter: VerticalFilter, selectedFilters: Filter[]): boolean => {
    return selectedFilters.some((selectedFilter) => selectedFilter.value === filter.value);
};

export const addClassToVerticalFilters = (filters: Filter[], selectedFilters: Filter[]): VerticalFilter[] => {
    return filters.map((filter) => {
        const verticalFilter = filter as VerticalFilter;

        verticalFilter.cssClass = isFilterSelected(verticalFilter, selectedFilters)
            ? SELECTED_FILTER_SELECTOR
            : FILTER_SELECTOR;

        return verticalFilter;
    });
};
