import { GeographyOption, RestGeographyResponse } from '../config/types';

export default {
    mapGeoHierarchiesFromRest(restCollection: RestGeographyResponse): GeographyOption[] {
        const geoHierarchies = restCollection.items || [];

        return geoHierarchies.map(({ geographyName, displayName, geographyId }) => ({
            lookupCode: geographyName,
            meaning: displayName,
            geographyId,
        }));
    },
};
