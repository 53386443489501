import { unwrap } from 'knockout';

function I18nViewModel(params, nodes) {
    this.key = unwrap(params.key);

    this.tokens = params.tokens || {};

    nodes.filter(node => node instanceof HTMLElement && node.getAttribute('data-i18n-token'))
        .reduce((tokensMap, node) => {
            const token = node.getAttribute('data-i18n-token');
            const htmlString = node.outerHTML;

            tokensMap[token] = htmlString;

            return tokensMap;
        }, this.tokens);
}

export default {
    createViewModel(params, componentInfo) {
        return new I18nViewModel(params, componentInfo.templateNodes);
    },
};
