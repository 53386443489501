import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';
import { URL_REGEX } from '../config/regex';

const URL_PATTERN = 'http://example.com';

export default class UrlFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        ko.utils.extend(this._options, {
            url_format: URL_PATTERN,
        });

        this._message = 'validators.url';
    }

    _validate(value) {
        if (value) {
            return URL_REGEX.test(value);
        }

        return true;
    }

}
