import httpLangCached from 'cx/service/http/httpLangCached';
import i18n from 'core/i18n/i18n';
import metadataMapper from 'apply-flow/module/personal-information-address/mapper/metadata';

export default class Metadata {

    constructor(serviceUrl) {
        this._serviceUrl = `${serviceUrl}?expand=addressFormatDetails&onlyData=true`;
    }

    getFormElements(countryCode) {
        return Promise.resolve()
            .then(() => {
                if (!countryCode) {
                    return Promise.reject('Missing country code');
                }

                return this._getAddressFormatUrl(countryCode);
            })
            .then(httpLangCached.get)
            .then((response) => {
                if (response.addressFormatDetails.length === 0) {
                    return Promise.reject('Collection of Definitions is empty');
                }

                return response;
            })
            .catch(this._getDefaultFormElements)
            .then(metadataMapper.mapFormElementsFromRest.bind(metadataMapper));
    }

    _getAddressFormatUrl(countryCode) {
        return this._serviceUrl.replace('<COUNTRY_CODE>', countryCode);
    }

    _getDefaultFormElements() {
        return {
            addressFormatDetails: [{
                prompt: i18n('apply-flow.section-personal-information.country-label'),
                attributeName: 'Country',
                requiredFlag: true,
            }],
        };
    }

}