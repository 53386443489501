import absoluteRouter from 'app/model/absoluteRouter';
import appConfig from 'app/model/config';
import { COOKIE_CONSENT_LINK } from 'app/module/minimal/component/app-menu/cookie-consent-header-identifier';

const CUSTOM_CONTENT_PAGE = 'cc-page';
const HTTP_PREFIX = 'http';

function _mapPageLink(link) {
    let linkUrl = link.url;

    if (link.url && link.url.substring(0, 4) !== HTTP_PREFIX && link.url !== COOKIE_CONSENT_LINK) {
        linkUrl = absoluteRouter.interpolate({ lang: appConfig.siteLang }, CUSTOM_CONTENT_PAGE, {
            pageCode: link.url,
            pageTitle: link.text,
        });
    }

    return {
        text: link.text,
        url: linkUrl,
        visibleOnMobile: link.visibleOnMobile,
        name: link.name,
    };
}

function _mapCustomPageLinks(links) {
    const mappedLinks = [];

    links.forEach((link) => {
        const mappedSublinks = [];

        link.sublinks.forEach((sublink) => {
            mappedSublinks.push(_mapPageLink(sublink));
        });

        const mappedLink = _mapPageLink(link);

        Object.defineProperty(mappedLink, 'sublinks', {
            value: mappedSublinks,
        });

        mappedLinks.push(mappedLink);
    });

    return mappedLinks;
}

export default {
    getHeaderPageLinks() {
        return _mapCustomPageLinks(appConfig.links.header);
    },

    getFooterPageLinks() {
        return _mapCustomPageLinks(appConfig.links.footer);
    },
};