export function convertFusionDateToBaseFormat(dateString) {
    const date = new Date(dateString);

    const day = calculateDay(date);
    const month = calculateMonth(date);
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

function calculateMonth(date) {
    const month = date.getMonth() + 1;

    return String(month).padStart(2, '0');
}

function calculateDay(date) {
    const day = date.getDate();

    return String(day).padStart(2, '0');
}