import FlowIterator from 'apply-flow/model/FlowIterator';

export default class SingleClickFlowIterator extends FlowIterator {

    isSingleClick() {
        return true;
    }

    runBeforeExit() {
        this.sections()
            .forEach(section => section.exit());
    }

    runBeforeValidation() {
        this.sections()
            .forEach(section => section.beforeValidation());
    }

}
