import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { CookiePreferenceLinkCustomizationParams } from './types';
import { EMPTY_BLOCK_STYLES, EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';
import i18n from 'core/i18n/i18n';

export const emptyParamsCookiePreferenceLink: CookiePreferenceLinkCustomizationParams = {
    cookiePreferenceLinkText: {
        text: i18n(
            'admin.theme-editor.theme-editor-panel.cookie-consent-config.cookie-preferences-button-default'
        ),
    },
    cookiePreferenceLinkTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferenceLinkHoverTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferenceLinkContainerStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferenceLinkContainerHoverStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
    content: null,
};
