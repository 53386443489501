import ko from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class RadioFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this._getUniqueId = option => `${this.element.attributes().id}-${option.lookupCode}`;

        this.element.options().forEach((elementOption) => {
            const option = elementOption;

            option.optionId = this._getUniqueId(option);
            option.attributes = ko.utils.extend({}, this.element.attributes());
            option.attributes.id = option.optionId;
            option.attributes['aria-labelledby'] = `label-${option.optionId}`;
            option.attributes.name = this.element.id;
        });
    }

}