import {
    desktopOnly,
    getColorCss,
    getHorizontalMediaMarginCss,
    getHorizontalPaddingCss,
    getMaxWidthCss,
} from 'minimal/module/search/service/customCss';
import { JobDetailsPageOptions } from 'site-editor/module/job-details-editor/component/job-details-page-options-dialog/types';

type SelectorField = 'icons' | 'container' | 'paddingBlock' | 'fullWidthBlocks';

const WRAPPER_CLASS = 'job-details-wrapper';

const SELECTORS: Record<SelectorField, string> = {
    icons: `.job-actions__section`,
    container: `.${WRAPPER_CLASS} .job-details-page-container`,
    paddingBlock: `.${WRAPPER_CLASS} .job-details-page-container, .app-dialog__wrapper .job-details-modal-container__main`,
    fullWidthBlocks: `.job-details__section.job-details__media-slider`,
};

export const computeDisplayStyles = (customPageParams: JobDetailsPageOptions | undefined): string => {
    if (!customPageParams) {
        return '';
    }

    const selectors = SELECTORS;
    const { iconsColor, pageWidth, pageWidthUnit, horizontalPaddingUnit, horizontalPadding } =
        customPageParams;

    const iconsCss = getColorCss({
        selector: selectors.icons,
        color: iconsColor,
    });

    const maxWidthCss =
        pageWidth && pageWidthUnit
            ? desktopOnly(
                  getMaxWidthCss({
                      selector: selectors.container,
                      width: pageWidth,
                      widthUnit: pageWidthUnit,
                  })
              )
            : '';

    const paddingCss =
        horizontalPaddingUnit && horizontalPadding
            ? desktopOnly(
                  getHorizontalPaddingCss({
                      selector: selectors.paddingBlock,
                      value: horizontalPadding,
                      unit: horizontalPaddingUnit,
                  })
              )
            : '';

    const fullWidthCss =
        horizontalPaddingUnit && horizontalPadding
            ? desktopOnly(
                  getHorizontalMediaMarginCss({
                      selector: selectors.fullWidthBlocks,
                      value: horizontalPadding,
                      unit: horizontalPaddingUnit,
                  })
              )
            : '';

    return [iconsCss, maxWidthCss, paddingCss, fullWidthCss].join(' ').trim();
};
