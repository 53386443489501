import storageService from 'core/storage/sessionStorage';

const TOKEN_KEY = 'shortToken';


export function storeShortTokenData(params) {
    const { token, jobId, eventId, pinCodeSent, mergedCandidate } = params;

    if (token) {
        storageService.store(TOKEN_KEY, { token, jobId, eventId, pinCodeSent, mergedCandidate });
    }
}

export function getShortToken(jobId) {
    const tokenData = storageService.restore(TOKEN_KEY) || {};

    if (jobId) {
        const decodedJobId = decodeURIComponent(tokenData.jobId);

        return compareEntityIds(jobId, decodedJobId) ? tokenData : undefined;
    }

    return tokenData;
}

export function getShortTokenForEvent(eventId) {
    const tokenData = storageService.restore(TOKEN_KEY) || {};

    if (eventId) {
        const decodedEventId = decodeURIComponent(tokenData.eventId);

        return compareEntityIds(eventId, decodedEventId) ? tokenData : undefined;
    }

    return tokenData;
}

export function clearShortTokenData() {
    storageService.remove(TOKEN_KEY);
}

export function isPinCodeSent() {
    const data = storageService.restore(TOKEN_KEY) || {};

    return data.pinCodeSent;
}

function compareEntityIds(entityId1, entityId2) {
    const encodeSpaces = value => value.split(' ').join('+');

    return encodeSpaces(entityId1) === encodeSpaces(entityId2);
}