import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class UrlFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.attributes('type', 'url');

        this.validators('url', {
            fieldname: () => this.label(),
        });

        this.validators('maxLength', {
            max: 2048,
        });
    }

}