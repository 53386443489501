import { observable, Observable, Subscription } from 'knockout';
import geolocationSuggester from 'cx/module/search/module/search-box/model/geolocationSuggester';
import AbstractSearchBoxInputViewModel from '../search-box-location/AbstractSearchBoxInputViewModel';
import { RadiusUnit } from '../search-box-location/config/types';

const STATES = {
    DEFAULT: 'location-bar.button-placeholder',
    RETRIEVING_LOCATION_IN_PROGRESS: 'location-bar.dropdown.geolocation-in-progress',
};

const DEFAULT_RADIUS = 25;

type Props = {
    action: () => void;
    beforeSearch: Observable<() => Promise<void>>;
    radiusUnit: Observable<RadiusUnit>;
    radiusValue: Observable<number>;
    value: Observable<{
        latitude: string;
        longitude: string;
        label: string;
        isGeolocation: boolean;
    }>;
};

type Geolocation = {
    latitude: number;
    longitude: number;
    name: string;
};

type Location = {
    latitude: number;
    longitude: number;
    label: string;
    isGeolocation: boolean;
};

class SearchBoxGeoLocationViewModel extends AbstractSearchBoxInputViewModel {
    location: Observable<string | undefined>;
    isWorking: Observable<boolean>;
    placeholder: Observable<string>;
    showRadiusDisplay: Observable<boolean>;
    geolocationFailed: Observable<boolean>;
    private valueSubscription: Subscription;

    constructor(params: Props) {
        super(params);

        this.location = observable<string>();
        this.isWorking = observable<boolean>(false);
        this.placeholder = observable<string>(STATES.DEFAULT);
        this.showRadiusDisplay = observable<boolean>(false);
        this.geolocationFailed = observable<boolean>(false);

        this.beforeSearch(this.showRadiusDisplayAction.bind(this));

        this.setInitialValue();

        this.valueSubscription = this.value.subscribe(this.onValueChanged, this);
    }

    onPressEnter(): void {
        if (!this.geolocationFailed() && !this.isWorking()) {
            this.action();
        }
    }

    private setInitialValue(): void {
        const { label } = this.value();

        if (label) {
            this.location(label);
            this.showRadiusDisplay(true);
        } else {
            this.fetchFromGeolocation();
        }
    }

    private fetchFromGeolocation(): void {
        this.placeholder(STATES.RETRIEVING_LOCATION_IN_PROGRESS);
        this.isWorking(true);

        geolocationSuggester
            .query()
            .catch(this.onGeoLocationFailed.bind(this))
            .then(this.onGeoLocationFetch.bind(this))
            .then(this.onLocationFetch.bind(this));
    }

    private showRadiusDisplayAction(): Promise<void> {
        if (!this.geolocationFailed()) {
            this.showRadiusDisplay(true);
        }

        return Promise.resolve();
    }

    private onLocationFetch(location: Location): void {
        this.isWorking(false);

        const { label } = location;

        this.value(location);
        this.location(label);
        this.action();
    }

    private onGeoLocationFailed(): void {
        this.isWorking(false);
        this.geolocationFailed(true);
        this.placeholder('');
    }

    private onGeoLocationFetch(geolocation: Geolocation): Location {
        const { latitude, longitude, name } = geolocation;

        this.radiusValue(DEFAULT_RADIUS);
        this.showRadiusDisplay(true);
        this.placeholder(STATES.DEFAULT);

        return { latitude, longitude, label: name, isGeolocation: true };
    }

    private onValueChanged(): void {
        const { label } = this.value();

        if (!label) {
            this.location('');
            this.radiusValue(DEFAULT_RADIUS);
            this.showRadiusDisplay(false);
        }
    }

    dispose(): void {
        this.value({});
    }
}

export default SearchBoxGeoLocationViewModel;
