export const TC_CANDIDATE = 'TC_CANDIDATE';
export const CSS_ENTRY = 'CSS_ENTRY';
export const JOB_APPLICATION = 'JOB_APPLICATION';
export const EVENT_REGISTER = 'EVENT_REGISTER';
export const UNVERIFIED_APPLICATIONS_LIMIT = 'UNVERIFIED_APPLICATIONS_LIMIT';
export const TC_CONFIRM = 'TC_CONFIRM';
export const JOB_APPLY_CONFIRM = 'JOB_APPLY_CONFIRM';
export const CLAIM_PHONE_NUMBER = 'CLAIM_PHONE_NUMBER';
export const CLAIM_EMAIL = 'CLAIM_EMAIL';
export const JOB_APPLICATION_INDEED = 'JOB_APPLY_INDEED';


export const VERIFICATION_MODES = {
    [CSS_ENTRY]: 'CSS_ACCESS',
    [JOB_APPLICATION]: 'JOB_APPLY',
    [EVENT_REGISTER]: 'EVENT_SIGNUP',
    [TC_CANDIDATE]: 'TC_SIGNUP',
    [TC_CONFIRM]: 'CONFIRM',
    [JOB_APPLY_CONFIRM]: 'JOB_APPLY_CONFIRM',
    [CLAIM_PHONE_NUMBER]: 'CLAIM_PHONE',
    [CLAIM_EMAIL]: 'CLAIM_EMAIL',
    [JOB_APPLICATION_INDEED]: 'JOB_APPLY_INDEED',
    [UNVERIFIED_APPLICATIONS_LIMIT]: 'JOB_APPLY',
};