import ko from 'knockout';

const HELPER_CONTROL_CSS = 'input-row__control--helper';
const TEXTAREA_HEIGHT_TRESHOLD = 350;
const TEXTAREA_HEIGHT_TRESHOLD_CLASS = 'input-row__control--huge-content';

function buildShadowElement(originalElement, viewModel) {
    const element = document.createElement('textarea');

    element.className = `${originalElement.className} ${HELPER_CONTROL_CSS}`;
    element.setAttribute('rows', originalElement.getAttribute('rows'));
    element.setAttribute('aria-hidden', true);
    element.setAttribute('tabIndex', '-1');

    ko.applyBindingsToNode(element, {
        value: viewModel.element.value,
    }, viewModel);

    originalElement.parentNode.insertBefore(element, originalElement.nextSibling);

    return element;
}

function parseStyle(styleValue) {
    return parseInt(styleValue.replace('px', ''), 10);
}

function resolveOriginalHeight(element) {
    const rows = parseInt(element.getAttribute('rows'), 10);
    const style = window.getComputedStyle(element);

    return parseStyle(style.paddingTop) +
           parseStyle(style.borderTopWidth) +
           (parseStyle(style.lineHeight) * rows) +
           parseStyle(style.borderBottomWidth) +
           parseStyle(style.paddingBottom) +
           (element.offsetHeight - element.clientHeight);
}

function setHeight(element, height) {
    element.style.height = `${height}px`;
}

/**
 * Scales textarea element accordingly to its content
 *
 * @example
 * <textarea rows="1" data-bind="textInput: value, textareaHeight"></textarea>
 */
ko.bindingHandlers.textareaHeight = {
    init(element, valueAccessor, allBindings, viewModel) {
        const originalHeight = resolveOriginalHeight(element);
        const refElement = buildShadowElement(element, viewModel);

        setHeight(refElement, originalHeight);

        function calculateHeight() {
            setHeight(refElement, originalHeight);

            const calcHeight = refElement.scrollHeight + (refElement.offsetHeight - refElement.clientHeight);

            return calcHeight >= originalHeight ? calcHeight : originalHeight;
        }

        function onKey() {
            const newHeight = calculateHeight();

            if (newHeight > TEXTAREA_HEIGHT_TRESHOLD) {
                element.classList.add(TEXTAREA_HEIGHT_TRESHOLD_CLASS);
            } else {
                element.classList.remove(TEXTAREA_HEIGHT_TRESHOLD_CLASS);
            }

            setHeight(element, newHeight);
        }

        element.classList.add('input-row__control--autoheight');
        element.addEventListener('keyup', onKey, false);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('keyup', onKey, false);
        });

        // init
        onKey();
    },
};
