import { RestEvent } from '../../candidate-self-service/mapper/eventRegistration';
import tokenService from 'candidate-verification/service/token';
import { EventRegistrationCreationResponse } from '../config/types';

export const mapEventRegistrationCreationResponse = (event: RestEvent): EventRegistrationCreationResponse => {
    const isDisqualifiedForAnyOfQuestionnaire = event.RecruitingEventQuestionnaireResponses?.some(
        (questionnaireItem) => questionnaireItem.disqualification === 'Y'
    );

    return {
        candidateNumber: tokenService.getCandidateNumber(),
        eventNumber: event.eventNumber,
        siteNumber: event.siteNumber,
        isCandidateDisqualified: isDisqualifiedForAnyOfQuestionnaire || false,
    };
};
