import ko from 'knockout';
import appConfig from 'app/model/config';
import themeEvents from 'cx/module/site-editor/config/events';

function CustomHeaderViewModel() {
    this.enabled = ko.observable(appConfig.headerMode === 2 || appConfig.headerMode === 4);
    this.customHtml = ko.observable(false);

    if (appConfig.theme) {
        this.customHtml(appConfig.theme.brand.headerHtml);
    }

    themeEvents.customHeaderUpdated.add((enabled, html) => {
        this.enabled(enabled);
        this.customHtml(html);
        themeEvents.customHeaderHtmlUpdated.dispatch();
    });

    this.templateExists = Boolean(document.getElementById('custom-header'));
}

export default CustomHeaderViewModel;
