import { SearchResultsContentParams, SearchResultsCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config/emptyBlockStyles';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { isMapEnabled } from 'ce-common/module/maps/service/isMapEnabled';

export const defaultContentParams: SearchResultsContentParams = {
    jobDisplayStyle: isMapEnabled() ? 'toggleListMap' : 'list',
    width: 1000,
    widthUnit: 'px',
    numberOfJobsDisplayed: 25,
    hideTalentCommunitySignup: false,
    displayAfterJobs: 5,
    enableInfiniteScroll: true,
    criteria: JSON.stringify([]),

    additionalInformationDisplayed: undefined,

    headerInformationDisplayed: ['jobTitle', 'alreadyApplied'],

    jobInfoDisplayed: ['location', 'postingDate', 'distance'],

    jobTagsInformationDisplayed: ['hotJob', 'trendingJob', 'beFirstToApply'],

    mainContentInformationDisplayed: ['description'],

    jobInfoDisplay: 'labelValue',
    jobInfoSeparator: 'dot',
    jobInfoTagsConfiguration: 'inHeader',
    enableSaveIcon: true,
    enableShareIcon: false,
    enableArrowIcon: true,
    enableApplyButton: false,
    layoutStyle: 'layout1',
    originalLayoutStyle: 'layout1',
};

export const emptySearchResultsParams: SearchResultsCustomizationParams = {
    contentParams: { ...defaultContentParams },
    headerTypography: { ...EMPTY_TYPOGRAPHY },
    headerTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypography: { ...EMPTY_TYPOGRAPHY },
    subHeaderTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    jobInfoValueTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    jobInfoValueTypography: { ...EMPTY_TYPOGRAPHY },
    jobInfoLabelTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    jobInfoLabelTypography: { ...EMPTY_TYPOGRAPHY },
    descriptionTypography: { ...EMPTY_TYPOGRAPHY },
    descriptionTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    descriptionTitleTypography: { ...EMPTY_TYPOGRAPHY },
    descriptionTitleTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    sortByTypography: { ...EMPTY_TYPOGRAPHY },
    sortOptionsTypography: { ...EMPTY_TYPOGRAPHY },
    sortOptionsTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    selectedSortValueTypography: { ...EMPTY_TYPOGRAPHY },
    selectedSortValueTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    selectedSortOptionTypography: { ...EMPTY_TYPOGRAPHY },
    jobTagsTypography: { ...EMPTY_TYPOGRAPHY },
    jobTagsTypographyOnHover: { ...EMPTY_TYPOGRAPHY },
    alreadyAppliedJobTag: { ...EMPTY_TYPOGRAPHY },
    alreadyAppliedJobTagOnHover: { ...EMPTY_TYPOGRAPHY },
    applyButton: { ...EMPTY_TYPOGRAPHY },
    applyButtonOnHover: { ...EMPTY_TYPOGRAPHY },
    container: { ...EMPTY_BLOCK_STYLES },
    tileStyles: { ...EMPTY_BLOCK_STYLES },
    tileStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    tileHeaderStyles: { ...EMPTY_BLOCK_STYLES },
    tileHeaderStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    sortByDropdownStyling: { ...EMPTY_BLOCK_STYLES },
    sortByStyling: { ...EMPTY_BLOCK_STYLES },
    sortByHoverStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
    jobIconsStyles: { ...EMPTY_BLOCK_STYLES },
    jobIconsStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    alreadyAppliedTagBoxOnHover: { ...EMPTY_BLOCK_STYLES },
    alreadyAppliedTagBox: { ...EMPTY_BLOCK_STYLES },
    defaultToggleIconStyles: { ...EMPTY_BLOCK_STYLES },
    defaultToggleIconStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    selectedToggleIconStyles: { ...EMPTY_BLOCK_STYLES },
    selectedToggleIconStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    jobInfoIconsStyles: { ...EMPTY_BLOCK_STYLES },
    jobInfoIconsStylesOnHover: { ...EMPTY_BLOCK_STYLES },
    applyButtonBox: { ...EMPTY_BLOCK_STYLES },
    applyButtonBoxContainer: { ...EMPTY_BLOCK_STYLES },
    applyButtonBoxOnHover: { ...EMPTY_BLOCK_STYLES },
};

export const defaultSearchResultsParams: SearchResultsCustomizationParams = {
    ...emptySearchResultsParams,
    headerTypographyOnHover: {
        ...emptySearchResultsParams.headerTypographyOnHover,
    },
    subHeaderTypographyOnHover: {
        ...emptySearchResultsParams.subHeaderTypographyOnHover,
    },
    jobInfoValueTypographyOnHover: {
        ...emptySearchResultsParams.jobInfoValueTypographyOnHover,
    },
    jobInfoLabelTypographyOnHover: {
        ...emptySearchResultsParams.jobInfoLabelTypographyOnHover,
    },
    descriptionTypographyOnHover: {
        ...emptySearchResultsParams.descriptionTypographyOnHover,
        color: '#fff',
    },
    descriptionTitleTypographyOnHover: {
        ...emptySearchResultsParams.descriptionTitleTypographyOnHover,
    },
    sortOptionsTypographyOnHover: {
        ...emptySearchResultsParams.sortOptionsTypographyOnHover,
    },
    tileHeaderStylesOnHover: {
        ...emptySearchResultsParams.tileHeaderStylesOnHover,
        backgroundColor: '#21699f',
    },
    tileStylesOnHover: {
        ...emptySearchResultsParams.tileStylesOnHover,
        backgroundColor: '#fff',
        borderColor: 'transparent',
        iconColorLight: 'var(--color-theme-1)',
    },
    sortByHoverStyling: {
        ...emptySearchResultsParams.sortByHoverStyling,
        iconColorLight: 'var(--color-theme-1)',
    },
};
