import { observable, pureComputed } from 'knockout';
import SearchBoxCompactViewModel from '../search-box-compact/SearchBoxCompactViewModel';
import { getSearchBarSelectors } from './config/selectors';
import { getCustomStyles } from './service/customStyles';
import { getSearchModelFromRoute } from '../../service/searchContextRouting';

export default class SearchBarViewModel extends SearchBoxCompactViewModel {

    constructor({ params, isAdminMode = false, id = 'default' }) {
        super({ params });
        this.isCustomSearch = observable(true);
        this.isAdminMode = isAdminMode;
        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.selectors = getSearchBarSelectors(`.${this.uniqueWrapperClass}`);

        this.customCss = pureComputed(() =>
            getCustomStyles(this.customizationParams, this.selectors));

        this.isStickySearchBarEnabled = this._isSearchBarSticky() && !isAdminMode;
        this.searchResultsModel = getSearchModelFromRoute();

        this.searchResultsModel.loading.subscribeOnce((value) => {
            if (!value) {
                this.isReady(true);
            }
        });
    }

    _isSearchBarSticky() {
        return this.customizationParams.searchBarContent.searchBarSticky();
    }

}