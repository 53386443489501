import ko from 'knockout';

const COMPONENT_CLASS = 'inline-spinner';

export default class InlineSpinnerViewModel {

    constructor({ isLoading, alignment, className, i18n }) {
        this.isLoading = isLoading;
        this.label = i18n;
        this.alignment = alignment;
        this.className = className;

        this.cssClass = ko.pureComputed(() => {
            const parts = [];

            parts.push(this.isLoading() ? `${COMPONENT_CLASS}--loading` : null);
            parts.push(this.alignment ? `${COMPONENT_CLASS}--${this.alignment}` : null);
            parts.push(this.className);

            return parts.filter(part => Boolean(part)).join(' ');
        });
    }

}
