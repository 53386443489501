import FormValidator from 'core/form/validator/Validator';

const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const DATE_PATTERN = /[\d]{4}-[\d]{2}-[\d]{2}/;

export default class DateFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.date';
    }

    _validate(isoDate) {
        if (!isoDate) {
            return true;
        }

        if (!isoDate.match(DATE_PATTERN)) {
            return false;
        }

        const [year, month, day] = isoDate
            .split('-')
            .map(Number);

        return (day <= this._getDaysInMonth(month, year));
    }

    _getDaysInMonth(month, year) {
        let daysInMonth = DAYS_IN_MONTH[month - 1];

        if (this._isLeapYear(year) && month === 2) {
            daysInMonth++;
        }

        return daysInMonth;
    }

    _isLeapYear(year) {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }

}