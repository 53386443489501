import SearchFacetItemVerticalViewModel from './SearchFacetItemVerticalViewModel';
import { Props } from 'minimal/module/search/component/search-facet-item/SearchFacetItemViewModel';
import { noOperation } from 'core/utils/noOperation';

export default class SearchFacetItemVerticalStaticViewModel extends SearchFacetItemVerticalViewModel {
    constructor(props: Props) {
        super(props);

        this.setOption = noOperation;
    }
}
