const middleware = [];

export default {
    add(middlewareToAdd) {
        middleware.push(middlewareToAdd);
    },

    remove(middlewareToRemove) {
        const index = middleware.indexOf(middlewareToRemove);

        middleware.splice(index, 1);
    },

    has(middlewareToCheck) {
        return middleware.includes(middlewareToCheck);
    },

    apply(response, config) {
        for (const middlewareToApply of middleware) {
            middlewareToApply(response, config);
        }
    },
};
