import { bindingHandlers, utils } from 'knockout';
import applyFlowEvents from 'apply-flow/config/events';

/**
 * Set focus to e-signature when invalid.
 *
 * @example
 *  <div data-bind="setFocusWhenInvalid"></div>
 *
 */

bindingHandlers.setFocusWhenInvalid = {
    init(element) {
        function setFocus() {
            const input = element.querySelector('[name="fullName"]');

            input.focus();
        }

        const invalidESignatureSignal = applyFlowEvents.invalidESignature.add(setFocus);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            invalidESignatureSignal.detach();
        });
    },
};