import { pureComputed, observable, PureComputed, Observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import {
    SearchResultsParams,
    AdditionInformationItem,
} from 'minimal/module/search/component/search-results/config/types';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { EventHeadingConfig, EventTagsConfig, HeaderInformationConfig } from '../../types';
import { EventShape, RegistrationInfo } from 'cx/module/search/config/types';
import { getEventUrl, openEvent } from 'minimal/module/search/service/eventRouting';
import screenInfo from 'cx/model/screenInfo';
import {
    getGridTileAdditionStyles,
    getMediaStyles,
} from 'minimal/module/search/component/search-result-item/service/additionalStyles';
import { mapTrueFalseStringToBoolean } from 'app/module/core/utils/stringToBoolean';
import appConfig from 'app/model/config';
import { search as searchService } from 'app/module/minimal/module/search/service/search';
import { EventTag, generateEventTags } from 'app/module/minimal/module/event-tags/config/eventTags';
import { LayoutStyle } from '../../../search-results/config/types';

type props = {
    event: EventShape;
    customizationParams: SearchResultsParams;
};

const RegistrationConfirmPhases = ['REGISTERED', 'ATTENDED'];

export default class SearchEventResultItemViewModel {
    event: EventShape;
    hasFocus: Observable<boolean>;
    customizationParams: SearchResultsParams;
    isDescriptionVisible: PureComputed<boolean>;
    isImageVisible: PureComputed<boolean>;
    eventHeadingConfig: PureComputed<EventHeadingConfig>;
    eventTagsConfig: PureComputed<EventTagsConfig>;
    eventTags: EventTag[];
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    layoutStyle: Observable<LayoutStyle>;
    mediaStyles: PureComputed<Record<string, string | undefined>>;
    eventUrl: string;
    isMediumScreen: PureComputed<boolean>;
    gridItemBodyAdditionalStyles: PureComputed<Record<string, string> | null>;
    gridItemHeaderAdditionalStyles: PureComputed<Record<string, string> | null>;
    registrationInfo?: RegistrationInfo | undefined;

    openEvent: (eventId: string) => void;

    constructor({ event, customizationParams }: props) {
        this.event = event;
        this.eventUrl = getEventUrl(event.id);
        this.openEvent = openEvent;
        this.hasFocus = observable<boolean>(false);
        this.isMediumScreen = pureComputed(() => screenInfo.isMediumUp());
        this.registrationInfo = this.event.registrationInfo;

        this.customizationParams =
            customizationParams || mapParamsConfigurationToObservable(emptySearchResultsParams);

        this.isDescriptionVisible = pureComputed(
            () => Boolean(event.shortDescriptionStr) && this.hasAdditionalInformation('description')
        );

        this.isImageVisible = pureComputed(() => this.hasAdditionalInformation('previewImage'));
        this.layoutStyle = this.customizationParams.contentParams.layoutStyle;

        this.mediaStyles = pureComputed<Record<string, string | undefined>>(() => {
            return getMediaStyles({
                jobDisplayStyle: this.customizationParams.contentParams.jobDisplayStyle(),
                isMediumScreen: this.isMediumScreen(),
                isImageVisible: this.isImageVisible(),
                mediaThumbUrl: this.event.bannerImageUrl,
            });
        });

        this.gridItemBodyAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius());
        });

        this.gridItemHeaderAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius(), 'header');
        });

        this.eventHeadingConfig = pureComputed(() => ({
            isLocationVisible:
                Boolean(this.event.location) &&
                this.hasAdditionalInformation('location') &&
                this.event.eventFormat === i18n('event-details.in-person'),
            isEventDateVisible: this.hasAdditionalInformation('postingDate'),
            isEventFormatVisible: Boolean(this.event.eventFormat),
            isDistanceVisible: this.shouldShowDistance(),
        }));

        this.headerInformationConfig = pureComputed(() => ({
            isAlreadyRegisteredTagVisible: Boolean(this.event.isAlreadyRegistered),
            isRegistrationPhaseConfirmed: Boolean(
                this.registrationInfo
                    ? RegistrationConfirmPhases.includes(this.registrationInfo.registrationPhase)
                    : false
            ),
        }));

        this.eventTagsConfig = pureComputed(() => ({
            isRegistrationClosedTagVisible: Boolean(this.event.registrationClosedFlag),
        }));

        this.eventTags = generateEventTags(this.event, this.eventTagsConfig);
    }

    private hasAdditionalInformation(item: AdditionInformationItem): boolean {
        const additionalInformation = this.customizationParams.contentParams.additionalInformationDisplayed();

        return additionalInformation ? additionalInformation.includes(item) : false;
    }

    shouldShowDistance(): boolean {
        const isCustomizationDistanceEnabled = this.hasAdditionalInformation('distance');

        const isDistanceCalculationEnabled = mapTrueFalseStringToBoolean(
            appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED')
        );

        return (
            isCustomizationDistanceEnabled && isDistanceCalculationEnabled && searchService.isLocationSearch()
        );
    }
}
