function _createLocationValue(id, type, name) {
    return {
        value: { locationId: id, locationType: type },
        name,
    };
}

function _getLocations(job) {
    // If workLocations and secondaryWorkLocations are available, set LocationType as 'W'

    // If workLocations and secondaryWorkLocations are not available, get primary and secondary
    // locations and set LocationType as 'P'

    const locations = [];

    const { primaryLocation, secondaryLocations, workLocation, otherWorkLocations, geographyNodeId } = job;

    const workLocationCount = workLocation.length + otherWorkLocations.length;
    const postingLocationCount = (primaryLocation !== '' ? 1 : 0) + secondaryLocations.length;

    if (workLocationCount >= 1) {
        return locations
            .concat(workLocation
                .map(({ locationId, locationName }) => _createLocationValue(locationId, 'W', locationName)))
            .concat(otherWorkLocations
                .map(({ locationId, locationName }) => _createLocationValue(locationId, 'W', locationName)));
    } else if (postingLocationCount > 1) {
        return locations
            .concat(_createLocationValue(geographyNodeId, 'P', primaryLocation))
            .concat(secondaryLocations
                .map(({ geographyNodeId: nodeId, name }) => _createLocationValue(nodeId, 'P', name)));
    } else if (postingLocationCount === 1) {
        return locations.concat(_createLocationValue(geographyNodeId, 'P', primaryLocation));
    }

    return locations;
}

export default {
    mapLocationsFromRest(job) {
        return _getLocations(job);
    },
};