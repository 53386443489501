export default {
    mapJobFamily(restCollection) {
        if (!restCollection.items || !restCollection.items.length) {
            return [];
        }

        return restCollection.items.map(this.mapSingleJobFamily);
    },

    mapSingleJobFamily(jobFamily) {
        return {
            id: jobFamily.jobFamilyId.toString(),
            jobFamilyId: jobFamily.jobFamilyId,
            name: jobFamily.jobFamilyName,
            jobFamilyName: jobFamily.jobFamilyName,
            isActive: jobFamily.activeStatus === 'A',
        };
    },

    mapActiveJobFamily(restCollection) {
        if (!restCollection.items || !restCollection.items.length) {
            return [];
        }

        return restCollection.items
            .map(item => this.mapSingleJobFamily(item))
            .filter(item => item.isActive);
    },
};
