// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapping from 'knockout-mapping';
import base64 from 'core/utils/base64';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import {
    CustomHeaderModel,
    CustomHeaderRestModel,
    Setting,
    ChildPropertiesCustomHeaders,
    CustomHeaderModelObservable,
    HeaderOption,
    CustomHeadersRequestPayload,
} from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import { ChildPropertiesCustomHeadersAdditionalSettings } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/types';
import {
    GLOBAL_HEADER_SETTINGS_KEY,
    CUSTOM_HEADERS_SETTING_PROPERTIES,
    DEFAULT_GLOBAL_HEADER_SETTINGS,
    ADDITIONAL_HEADER_SETTINGS_KEY,
} from 'site-editor/module/theme-editor/module/global-header-editor/config/constants';
import { CUSTOM_HEADERS_ADDITIONAL_SETTINGS } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/constants';
import { AdditionalHeaderCustomizationParams } from 'site-editor/module/theme-editor/module/global-header-editor/component/additional-header-settings/config/types';
import { emptyParams } from '../module/global-header-editor/component/additional-header-settings/config/emptyParams';
import { deepCopy } from 'app/module/core/utils/deepCopy';
import { FONT_WEIGHTS } from 'site-editor/module/content-editor/config/standardFonts';

const ADDITIONAL_HEADERS_HOVER_KEYS = [
    'linksHoverTypography',
    'sublinksHoverTypography',
    'submenuHoverTypography',
    'linkSelectedTypography',
];

const mapSetting = (
    childProperty: ChildPropertiesCustomHeaders & ChildPropertiesCustomHeadersAdditionalSettings,
    mappedGlobalHeaderSettings:
        | (CustomHeaderModel & AdditionalHeaderCustomizationParams)
        | typeof DEFAULT_GLOBAL_HEADER_SETTINGS,
    isAdditionalHeaderSettings: boolean
) => {
    if (isAdditionalHeaderSettings) {
        return {
            settingKey: childProperty,
            settingValue: JSON.stringify(mappedGlobalHeaderSettings[childProperty]),
        };
    }

    return {
        settingKey: childProperty,
        settingValue: String(mappedGlobalHeaderSettings[childProperty]),
    };
};

export const mapSettingsToRest = (
    mappedGlobalHeaderSettings:
        | (CustomHeaderModel & AdditionalHeaderCustomizationParams)
        | typeof DEFAULT_GLOBAL_HEADER_SETTINGS,
    isAdditionalHeaderSettings: boolean
): Setting[] => {
    return (
        isAdditionalHeaderSettings ? CUSTOM_HEADERS_ADDITIONAL_SETTINGS : CUSTOM_HEADERS_SETTING_PROPERTIES
    ).map((childProperty) => {
        return mapSetting(
            childProperty as ChildPropertiesCustomHeaders & ChildPropertiesCustomHeadersAdditionalSettings,
            mappedGlobalHeaderSettings,
            isAdditionalHeaderSettings
        );
    });
};

export const defaultCustomHeaderConfig: CustomHeaderRestModel = {
    employeeText: DEFAULT_GLOBAL_HEADER_SETTINGS.employeeText,
    profileText: DEFAULT_GLOBAL_HEADER_SETTINGS.profileText,
    type: DEFAULT_GLOBAL_HEADER_SETTINGS.type,
    logoURL: null,
    headerId: null,
    themeId: null,
    name: null,
    settings: mapSettingsToRest(DEFAULT_GLOBAL_HEADER_SETTINGS, false),
};

export const mapGlobalHeaderSettingsToRest = (
    globalHeaderSettings: CustomHeaderModelObservable
): CustomHeadersRequestPayload => {
    const mappedGlobalHeaderSettings = mapping.toJS(globalHeaderSettings);

    return {
        name: null,
        employeeText: base64.encode(mappedGlobalHeaderSettings.employeeText),
        profileText: base64.encode(mappedGlobalHeaderSettings.profileText),
        type: GLOBAL_HEADER_SETTINGS_KEY,
        settings: mapSettingsToRest(mappedGlobalHeaderSettings, false),
    };
};

export const mapAdditionalHeaderSettingsToRest = (
    additionalHeaderSettings: CustomHeaderModelObservable
): CustomHeadersRequestPayload => {
    const { name, settings } = mapping.toJS(additionalHeaderSettings);

    return {
        name,
        employeeText: null,
        profileText: null,
        type: ADDITIONAL_HEADER_SETTINGS_KEY,
        settings: mapSettingsToRest(settings, true),
    };
};

export const mapSettingFromRest = (
    settings: Setting[],
    property: ChildPropertiesCustomHeaders,
    convertStringToBoolean = true
): string | boolean => {
    const matchedValue = (settings.find(({ settingKey }: Setting) => settingKey === property) as Setting)
        ?.settingValue;

    return convertStringToBoolean ? mapTrueFalseStringToBoolean(matchedValue) : matchedValue;
};

const mapAdditionalSettingFromRest = (settings: Setting[]): AdditionalHeaderCustomizationParams => {
    const result = deepCopy(emptyParams);

    settings
        .filter(({ settingKey }) => CUSTOM_HEADERS_ADDITIONAL_SETTINGS.includes(settingKey))
        .forEach(({ settingKey, settingValue }) => {
            result[settingKey as keyof AdditionalHeaderCustomizationParams] = JSON.parse(settingValue);

            if (ADDITIONAL_HEADERS_HOVER_KEYS.includes(settingKey)) {
                const settings = result[settingKey];

                settings.fontWeight = settings.fontWeight || FONT_WEIGHTS.NORMAL;
                settings.fontStyle = settings.fontStyle || 'normal';
            }
        });

    return result;
};

export const mapCustomHeaderFromRest = ({
    employeeText,
    profileText,
    name,
    settings,
    type,
    themeId,
    headerId,
    logoURL,
}: CustomHeaderRestModel): CustomHeaderModel => {
    return {
        employeeText: base64.decode(employeeText),
        profileText: base64.decode(profileText),
        isLogoHidden: mapSettingFromRest(settings, 'isLogoHidden') as boolean,
        isEmployeeButtonHidden: mapSettingFromRest(settings, 'isEmployeeButtonHidden') as boolean,
        isProfileButtonHidden: mapSettingFromRest(settings, 'isProfileButtonHidden') as boolean,
        headerType: mapSettingFromRest(settings, 'headerType', false) as HeaderOption,
        name,
        type,
        themeId,
        headerId,
        logoURL,
        settings: mapAdditionalSettingFromRest(settings),
    };
};

export const mapCustomHeaderSettingsFromRest = (
    customHeaders: CustomHeaderRestModel[]
): { globalHeaderSettings: CustomHeaderModel; additionalHeadersSettings: CustomHeaderModel[] } => {
    const mappedHeaders = customHeaders.map(mapCustomHeaderFromRest);

    const globalHeaderSettings =
        mappedHeaders.find(({ type }) => type === GLOBAL_HEADER_SETTINGS_KEY) ??
        mapCustomHeaderFromRest(defaultCustomHeaderConfig);

    const additionalHeadersSettings = mappedHeaders.filter(
        ({ type }) => type === ADDITIONAL_HEADER_SETTINGS_KEY
    );

    return {
        globalHeaderSettings,
        additionalHeadersSettings,
    };
};

export const isPropertyFromSettings = (property: string): boolean => {
    return CUSTOM_HEADERS_SETTING_PROPERTIES.includes(property as ChildPropertiesCustomHeaders);
};
