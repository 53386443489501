import { Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import i18n from 'core/i18n/i18n';
import { Facet } from '../search-facet-dropdown-item/SearchFacetDropdownItemViewModel';
import { pureComputed, PureComputed } from 'knockout';
import signals from 'minimal/module/search/config/events';
import { searchJobResults } from '../../model/searchJobResults';

export type Props = {
    facet: Facet;
    adminMode: undefined | boolean;
    toggleFiltersDropdown: (data: Filter, event: Event) => void;
    expandedFacetTypes: string[];
};

let dropdownPreservedState: string | undefined;

export default class SearchFacetViewModel {
    facet: Facet;
    title?: string;
    adminMode: boolean | undefined;
    selectedCount: number;
    toggleFiltersDropdown: (data: Filter, event: Event) => void;
    expandedFacetTypes: string[];
    ariaLabel: string;
    hasFocus: PureComputed<boolean>;

    constructor({ facet, adminMode, toggleFiltersDropdown, expandedFacetTypes }: Props) {
        this.facet = facet;
        this.title = facet.title || i18n(`facets.${this.facet.name.toLowerCase()}-title`);
        this.adminMode = adminMode;
        this.selectedCount = this.facet.selectedItems().length;

        this.ariaLabel = `${i18n('search.filter-label-with-count', {
            filtername: this.title,
            selected_filters_count: this.selectedCount,
        })}`;

        this.toggleFiltersDropdown = toggleFiltersDropdown;
        this.expandedFacetTypes = expandedFacetTypes;

        this.hasFocus = pureComputed(() => {
            if (!searchJobResults.loading() && dropdownPreservedState == this.facet.type) {
                dropdownPreservedState = undefined;

                return true;
            }

            return false;
        });

        signals.filtersSelected.add((selectedFilter: string) => {
            dropdownPreservedState = selectedFilter;
        });
    }
}
