import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { SearchBarCustomizationParams, SearchBarCustomizationParamsKey } from '../config/types';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapContentParams } from './mapContentParams';
import { emptySearchBarParams } from '../config/defaultParams';

// TODO: reuse mapCustomComponentParamsFromRest
export const mapParamsFromRest = (params: RestParam[]): SearchBarCustomizationParams => {
    return params.reduce<SearchBarCustomizationParams>(
        (customizationParams, { paramKey, paramValue }) => {
            const typedParamKey: SearchBarCustomizationParamsKey | 'type' = paramKey as
                | SearchBarCustomizationParamsKey
                | 'type';

            switch (typedParamKey) {
                case 'searchBarContent':
                    return {
                        ...customizationParams,
                        [paramKey]: mapContentParams(paramValue),
                    };

                case 'commonParams':
                    return {
                        ...customizationParams,
                        [paramKey]: mapCommonParams(paramValue),
                    };

                case 'headerTypography':
                case 'searchValuesTypography':
                case 'searchValuesTypographySelected':
                case 'dropdownValuesTypography':
                    return {
                        ...customizationParams,
                        [paramKey]: mapTypography(paramValue),
                    };

                case 'searchButtonStyle':
                case 'buttonHoverStyle':
                case 'overallStyle':
                case 'separators':
                    return {
                        ...customizationParams,
                        [paramKey]: mapStylingBox(paramValue),
                    };

                case 'type':
                    return {
                        ...customizationParams,
                        [paramKey]: paramValue,
                    };

                default:
                    return notReachable(typedParamKey);
            }
        },
        { ...emptySearchBarParams }
    );
};
