export const isActionRequiredForApplication = application =>
    Boolean(!application.isConfirmed()
    || application.secondarySubmissions().length
    || application.scheduleInterviews().length
    || application.unscheduledInterviewRequests().length
    || application.hasAssessment()
    || application.backgroundCheckLinks().length
    || application.hasTaxCredit());


export const sortApplicationsByAction = (a, b) =>
    Number(isActionRequiredForApplication(b)) - Number(isActionRequiredForApplication(a));


export const getNextPaginationLimit = ({
    currentLimit,
    entitiesLength,
    entitiesPerPage,
    countOfEntitiesBeforeShowingEverything,
}) => {
    const nextLimit = currentLimit + entitiesPerPage;
    const isItLastIteration = currentLimit >= countOfEntitiesBeforeShowingEverything;

    return isItLastIteration
        ? entitiesLength
        : nextLimit;
};

export const focusNthEntity = ({
    parentSelector,
    itemSelector,
    index,
}) => {
    setTimeout(() => {
        const parentContainer = document.querySelector(parentSelector);
        const element = parentContainer?.querySelectorAll(itemSelector)[index];

        if (element) {
            element.focus();
        }
    }, 0);
};
