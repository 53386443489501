import router from 'app/model/router';
import cxEvents from 'cx/config/events';
import flowService from 'apply-flow/service/flow';
import tokenService from 'candidate-verification/service/token';
import CandidateChallengeAbstractViewModel from 'candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';

export default class ApplyFlowPostPinVerificationViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        cxEvents.loading.dispatch();

        if (!sessionPersistence.isActive()) {
            if (tokenService.accessCodeExists()) {
                return this._noChallengeRequired();
            }

            this._triggerChallenge()
                .then(this.onPinChallengeRequired.bind(this))
                .catch(this._handleError.bind(this));
        } else {
            this._triggerChallenge()
                .catch((error) => {
                    this._handleError(error);

                    return false;
                })
                .then((challengeRequired) => {
                    if (!challengeRequired) {
                        return this._noChallengeRequired();
                    }

                    return this.onPinChallengeRequired();
                });
        }
    }

    _noChallengeRequired() {
        return this.onPinValid()
            .then(cxEvents.loaded.dispatch);
    }

    onPinValid() {
        flowService.setSecondaryFlowData(flowService
            .createFlowData(tokenService.get().flowId, tokenService.get().stepActionUsageId));

        const routeParams = Object.assign(router.routeParams(), { sectionNumber: 1 });

        return router.go('post-apply', routeParams);
    }

    onPinChallengeRequired() {
        this.handleMergedCandiate();
        cxEvents.loaded.dispatch();
    }

    _handleError(error) {
        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            cxEvents.loaded.dispatch();

            return;
        }

        super._handleError(error);
    }

}
