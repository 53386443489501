import { CookieConsentWidgetOptions } from 'site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import {
    desktopOnly,
    getBackgroundColorCss,
    getBorderColorCss,
    getBorderRadiusCss,
    getBorderWidthCss,
    getMaxWidthCss,
    getHorizontalPaddingCss,
    getVerticalPaddingCss,
} from 'minimal/module/search/service/customCss';
import { COOKIE_CONSENT_WIDGET_TYPES } from '../types';

type SelectorField = 'container' | 'borderContainer';

const COOKIE_CONSENT_SELECTORS: Record<SelectorField, string> = {
    container: `.site-editor-cookie-consent-wrapper .app-dialog__main.cookie-consent-widget-container, .cookie-consent-site.cookie-consent-modal`,
    borderContainer: `.site-editor-cookie-consent-wrapper .app-dialog__main.cookie-consent-widget-container, .cookie-consent-site.cookie-consent-modal, .app-dialog__main.cookie-consent-widget-container .cookie-consent__pulse, .cookie-consent-modal .cookie-consent__pulse`,
};

const COOKIE_POLICY_SELECTORS: Record<SelectorField, string> = {
    container: `.site-editor-cookie-policy-wrapper .app-dialog__main.cookie-policy-page-container, .cookie-policy-modal .app-dialog__main.cookie-policy-modal__main`,
    borderContainer: `.site-editor-cookie-policy-wrapper .app-dialog__main.cookie-policy-page-container, .cookie-policy-modal .app-dialog__main.cookie-policy-modal__main`,
};

const COOKIE_PREFERENCES_SELECTORS: Record<SelectorField, string> = {
    container: `.site-editor-cookie-preferences-wrapper .app-dialog__main.cookie-preferences-page-container, .cookie-preferences-modal .app-dialog__main.cookie-preferences-modal__main`,
    borderContainer: `.site-editor-cookie-preferences-wrapper .app-dialog__main.cookie-preferences-page-container, .cookie-preferences-modal .app-dialog__main.cookie-preferences-modal__main`,
};

export const getSelectors = (widgetType: COOKIE_CONSENT_WIDGET_TYPES): Record<SelectorField, string> => {
    switch (widgetType) {
        case 'COOKIE_POLICY_WIDGET':
            return COOKIE_POLICY_SELECTORS;
        case 'COOKIE_PREFERENCES_WIDGET':
            return COOKIE_PREFERENCES_SELECTORS;
        case 'COOKIE_CONSENT_WIDGET':
        default:
            return COOKIE_CONSENT_SELECTORS;
    }
};

export const computeDisplayStyles = (
    widgetType: COOKIE_CONSENT_WIDGET_TYPES,
    customPageParams: CookieConsentWidgetOptions | undefined
): string => {
    if (!customPageParams) {
        return '';
    }

    const selectors = getSelectors(widgetType);
    const {
        borderColor,
        backgroundColor,
        borderRadius,
        borderWidth,
        verticalPadding,
        verticalPaddingUnit,
        width,
        widthUnit,
        horizontalPaddingUnit,
        horizontalPadding,
    } = customPageParams;

    const borderRadiusCss = getBorderRadiusCss({
        selector: selectors.borderContainer,
        value: borderRadius,
    });

    const borderWidthCss = getBorderWidthCss({
        selector: selectors.container,
        value: borderWidth,
    });

    const backgroundColorCss = getBackgroundColorCss({
        selector: selectors.container,
        value: backgroundColor,
    });

    const borderColorCss = getBorderColorCss({
        selector: selectors.borderContainer,
        color: borderColor,
    });

    const maxWidthCss =
        width && widthUnit
            ? desktopOnly(
                  getMaxWidthCss({
                      selector: selectors.container,
                      width: width,
                      widthUnit: widthUnit,
                  })
              )
            : '';

    const horizontalPaddingCss =
        horizontalPaddingUnit && horizontalPadding
            ? desktopOnly(
                  getHorizontalPaddingCss({
                      selector: selectors.container,
                      value: horizontalPadding,
                      unit: horizontalPaddingUnit,
                  })
              )
            : '';

    const verticalPaddingCss =
        verticalPadding && verticalPaddingUnit
            ? desktopOnly(
                  getVerticalPaddingCss({
                      selector: selectors.container,
                      value: verticalPadding,
                      unit: verticalPaddingUnit,
                  })
              )
            : '';

    const displayStylesResult = [
        borderColorCss,
        borderRadiusCss,
        borderWidthCss,
        backgroundColorCss,
        maxWidthCss,
        horizontalPaddingCss,
        verticalPaddingCss,
    ]
        .join(' ')
        .trim();

    return displayStylesResult;
};
