import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { JobDetailsInfoCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';
import {
    JOB_META_TYPES_DEFAULT,
    JobMetaTagKey,
} from 'site-editor/module/job-details-editor/component/cc-job-details-info/config/jobMetaTypes';

export const mapParamsFromRest = (params: RestParam[]): JobDetailsInfoCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    const contentParams = paramValue
                        ? JSON.parse(paramValue)
                        : { isTitleHidden: false, jobMetaTypesOptions: JOB_META_TYPES_DEFAULT };

                    return {
                        isTitleHidden: contentParams.isTitleHidden || false,
                        jobMetaTypesOptions:
                            (contentParams.jobMetaTypesOptions as unknown as JobMetaTagKey[]) ||
                            JOB_META_TYPES_DEFAULT,
                    };

                case 'titleTypography':
                case 'jobInfoTitleTypography':
                case 'jobInfoDataTypography':
                    return mapTypography(paramValue);

                case 'container':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return notReachable(paramKey);
            }
        },
    });
};
