import { notReachable } from 'app/types/utils';
import { TemplateNumber } from '../../../enum/pageTypes';

export const getSplashBrandingContent = (text: string, templateNumber: TemplateNumber): string => {
    switch (templateNumber) {
        case 'mohegan':
            return `<h1 class="welcome-text" style="text-align: center">${text}</h1>`;
        case 'minimal':
            return `<h1 class="home-page__heading font-family-secondary theme-color-1" style="text-align: center">${text}</h1>`;
        default:
            return notReachable(templateNumber);
    }
};
