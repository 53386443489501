import { JobDetailsSkillsCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';

export const emptyParams: JobDetailsSkillsCustomizationParams = {
    content: {
        isTitleHidden: false,
    },
    titleTypography: { ...EMPTY_TYPOGRAPHY },
    categoryTypography: { ...EMPTY_TYPOGRAPHY },
    skillTypography: { ...EMPTY_TYPOGRAPHY },
    container: { ...EMPTY_BLOCK_STYLES },
    skill: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
