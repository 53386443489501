import { ORA_JA_EXTRA_INFO } from '../../enum/blockTypes';

export class ExtraInformationBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        this.blocks = new Map([
            [ORA_JA_EXTRA_INFO, this.removeMultiBlockData],
        ]);

        return this.blocks;
    }

    removeMultiBlockData(candidate, contexts) {
        candidate.extraInformation = candidate.extraInformation.filter(item => contexts.includes(item?.name));
    }

    getBlockIdentifier(block) {
        const { blockProperties } = block;

        if (blockProperties && blockProperties.context) {
            return [blockProperties.context];
        }

        console.error(`missing properties for block ${block.code}`);

        return null;
    }

}