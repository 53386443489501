import ko from 'knockout';
import i18n from 'core/i18n/i18n';

export default class TextTruncateViewModel {

    constructor({ text, minTextLength, isSectionActive }) {
        this.text = text;
        this.worthTruncate = text.length > minTextLength;
        this.isTruncated = ko.observable(this.worthTruncate);
        this.isSectionActive = isSectionActive;

        this.buttonLabel = ko.pureComputed(() =>
            (this.isTruncated() ? i18n('general.show-more') : i18n('general.show-less')),
        );
    }

    toggle() {
        this.isTruncated(!this.isTruncated());
    }

}
