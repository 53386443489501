import ko from 'knockout';
import sectionValidator from 'apply-flow/model/sectionValidator';

export default function ApplyFlowSectionViewModel(params) {
    this.application = params.application;
    this.flow = ko.unwrap(params.$raw.flow);
    this.flowIterator = this.flow.iterator();
    this.section = ko.unwrap(params.section);
    this.isSingleClickFlow = this.flowIterator.isSingleClick();
    this.showHeading = (this.section.title && this.isSingleClickFlow);

    this.isValid = sectionValidator.isSectionValid(this.section.number);

    this.isActive = ko.pureComputed(() => {
        const currentSectionNumber = this.flowIterator.currentSectionNumber();

        return this.section.number === currentSectionNumber || this.isSingleClickFlow;
    });
}