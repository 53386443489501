import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { PureComputed } from 'knockout';
import {
    CookieConsentModalMessageCustomizationParams,
    CookieConsentModalMessageParams,
} from './config/types';
import { CookieConsentWidgetData } from '../../types';
import { showCookiePolicyOrPreferenceDialog } from 'cx/service/cookieAgreement';
import { getCustomStylesCookieMessage } from './service/customStyles';
import { getCookieConsentMessageSelectors } from './config/selectors';

type Props = {
    pageData: PureComputed<CookieConsentWidgetData>;
    params?: CookieConsentModalMessageParams;
    id?: string;
};

export class CookieConsentModalMessageViewModel extends CustomComponentViewModel<CookieConsentModalMessageParams> {
    pageData: PureComputed<CookieConsentWidgetData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof CookieConsentModalMessageCustomizationParams): string {
        return getCustomStylesCookieMessage(
            key,
            getCookieConsentMessageSelectors(`.${this.uniqueWrapperClass}`),
            this.customizationParams
        );
    }

    openPolicyDialog(): void {
        showCookiePolicyOrPreferenceDialog();
    }
}
