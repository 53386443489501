import { JobFit } from 'minimal/module/job-details/types';
import { JobCategoryTagKey } from 'site-editor/module/job-details-editor/component/cc-job-details-job-fit/config/jobCategoryTypes';
import i18n from 'core/i18n/i18n';

export type JobAttribute =
    | {
          name: string;
          value: number | null;
          order?: string;
      }
    | undefined;

export type AttributesList = Partial<Record<JobCategoryTagKey, JobAttribute>>;

export const generateJobAttributes = (job: JobFit, lang: string): AttributesList => {
    const attributes = {
        skillsScore: {
            value: job.skillsScore,
            name: i18n('job-details.skills', null, lang),
            order: '1',
        },
        roleSeniorityScore: {
            value: job.roleSeniorityScore,
            name: i18n('job-details.expertise', null, lang),
            order: '2',
        },
        relevantExperienceScore: {
            value: job.relevantExperienceScore,
            name: i18n('job-details.experience', null, lang),
            order: '3',
        },
        domainKnowledgeScore: {
            value: job.domainKnowledgeScore,
            name: i18n('job-details.education', null, lang),
            order: '4',
        },
    };

    return attributes;
};
