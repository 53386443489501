import { observable, Observable, PureComputed, pureComputed } from 'knockout';
import favouriteJobsService from 'minimal/service/favouriteJobs';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import './component/search-result-item-header/component';
import './component/search-result-item-apply-button';
import './component/search-result-item-job-info';
import { getJobUrl, openJob } from 'minimal/module/search/service/jobRouting';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import {
    HeaderInformationItem,
    JobInfoDisplayedItem,
    JobTagsInformationItem,
    LayoutStyle,
    MainContentInformationItem,
    SearchResultsParams,
} from 'minimal/module/search/component/search-results/config/types';
import { SearchResultsRequisitionFlexField } from '../search-results/config/types';
import { HeaderInformationConfig, JobTagsConfig, MainContentConfig } from './types';
import screenInfo from 'cx/model/screenInfo';
import router from 'app/model/router';
import deviceDetector from 'app/module/core/device-detector/deviceDetector';
import {
    getGridTileAdditionStyles,
    getMediaStyles,
} from 'minimal/module/search/component/search-result-item/service/additionalStyles';
import { Job } from 'minimal/module/job-details/types';
import {
    JobInfoDisplay,
    JobInfoSeparator,
    JobInfoTagsConfiguration,
    SEARCH_RESULTS_STRINGS,
    SearchContext,
} from 'search/config/types';
import { generateJobTags, JobTag } from 'minimal/module/job-tags/config/jobTags';
import { shouldShowDistance } from './service/shouldShowDistance';
import { getDistanceInfo } from 'minimal/module/search/component/search-result-item/service/getDistance';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';

export type SearchResultItemViewModelProps = {
    job: Job;
    customizationParams: SearchResultsParams;
};

export class SearchResultItemViewModel {
    job: Job;
    customizationParams: SearchResultsParams;
    hasFocus: Observable<boolean>;
    isFavourite: PureComputed<boolean>;
    mediaStyles: PureComputed<Record<string, string | undefined>>;
    isMediumScreen: PureComputed<boolean>;
    gridItemBodyAdditionalStyles: PureComputed<Record<string, string> | null>;
    gridItemHeaderAdditionalStyles: PureComputed<Record<string, string> | null>;
    headerInformationDisplayed: Observable<HeaderInformationItem[]>;
    headerInformationConfig: PureComputed<HeaderInformationConfig>;
    jobTagsDisplayed: Observable<JobTagsInformationItem[]>;
    jobTagsConfig: PureComputed<JobTagsConfig>;
    jobTags: JobTag[];
    mainContentInformationDisplayed: Observable<MainContentInformationItem[]>;
    mainContentConfig: PureComputed<MainContentConfig>;
    isApplyButtonVisible: boolean | undefined;
    ariaLabelledByIdJob: string;
    context: SearchContext;
    jobInfoDisplayed: PureComputed<JobInfoDisplayedItem[]>;
    jobInfoDisplay: PureComputed<JobInfoDisplay>;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
    jobInfoTagsConfiguration: PureComputed<JobInfoTagsConfiguration>;
    shouldShowDistance: boolean;
    enableSaveIcon: PureComputed<boolean>;
    enableShareIcon: PureComputed<boolean>;
    enableArrowIcon: PureComputed<boolean>;
    enableApplyButton: PureComputed<boolean>;
    distanceInfo: DistanceInfo;
    areJobTagsVisible: PureComputed<boolean>;
    isFavouriteToggled: Observable<boolean>;
    layoutStyle: Observable<LayoutStyle>;
    dffs: SearchResultsRequisitionFlexField[];

    getJobUrl: (jobId: string) => void;
    openJob: (jobId: string) => void;

    constructor({ job, customizationParams }: SearchResultItemViewModelProps) {
        this.job = job;
        this.getJobUrl = getJobUrl;
        this.openJob = openJob;
        this.isMediumScreen = pureComputed(() => screenInfo.isMediumUp());
        this.hasFocus = observable<boolean>(false);
        this.isApplyButtonVisible = !(this.job.isAlreadyApplied || this.job.isConfirmed);
        this.ariaLabelledByIdJob = this.job.id.replaceAll(' ', '');
        this.context = SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS;
        this.isFavourite = favouriteJobsService.isFavourite(job);
        this.distanceInfo = getDistanceInfo(job);

        this.areJobTagsVisible = pureComputed(() => {
            return !!this.jobTags.find((tag) => tag.isVisible());
        });

        this.isFavouriteToggled = observable<boolean>(false);

        this.customizationParams =
            customizationParams || mapParamsConfigurationToObservable(emptySearchResultsParams);

        this.headerInformationDisplayed = this.customizationParams.contentParams.headerInformationDisplayed;
        this.layoutStyle = this.customizationParams.contentParams.layoutStyle;
        this.jobTagsDisplayed = this.customizationParams.contentParams.jobTagsInformationDisplayed;

        this.mainContentInformationDisplayed =
            this.customizationParams.contentParams.mainContentInformationDisplayed;

        this.headerInformationConfig = pureComputed(() => ({
            isAlreadyAppliedVisible:
                Boolean(this.job.isAlreadyApplied) &&
                this.headerInformationDisplayed().includes('alreadyApplied'),
            isJobTitleVisible: this.headerInformationDisplayed().includes('jobTitle'),
        }));

        this.jobTagsConfig = pureComputed(() => ({
            isHotJobVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.hotJobFlag) &&
                this.jobTagsDisplayed().includes('hotJob'),
            isTrendingJobVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.trendingJob) &&
                this.jobTagsDisplayed().includes('trendingJob'),
            isBeFirstToApplyVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.beFirstToApply) &&
                this.jobTagsDisplayed().includes('beFirstToApply'),
        }));

        this.mainContentConfig = pureComputed(() => ({
            isImageVisible: this.mainContentInformationDisplayed().includes('previewImage'),
            isDescriptionVisible:
                Boolean(job.shortDescription) &&
                this.mainContentInformationDisplayed().includes('description'),
            isResponsibilitiesVisible:
                Boolean(job.responsibilities) &&
                this.mainContentInformationDisplayed().includes('responsibilities'),
            isQualificationsVisible:
                Boolean(job.qualifications) &&
                this.mainContentInformationDisplayed().includes('qualifications'),
        }));

        this.mediaStyles = pureComputed<Record<string, string | undefined>>(() =>
            getMediaStyles({
                jobDisplayStyle: this.customizationParams.contentParams.jobDisplayStyle(),
                isMediumScreen: this.isMediumScreen(),
                isImageVisible: this.mainContentConfig().isImageVisible,
                mediaThumbUrl: this.job.mediaThumbUrl,
            })
        );

        this.enableSaveIcon = pureComputed(() => this.customizationParams.contentParams.enableSaveIcon());

        this.enableShareIcon = pureComputed(() => this.customizationParams.contentParams.enableShareIcon());

        this.enableArrowIcon = pureComputed(() => this.customizationParams.contentParams.enableArrowIcon());

        this.enableApplyButton = pureComputed(() =>
            this.customizationParams.contentParams.enableApplyButton()
        );

        this.gridItemBodyAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius());
        });

        this.gridItemHeaderAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius(), 'header');
        });

        this.jobInfoDisplayed = pureComputed(() => this.customizationParams.contentParams.jobInfoDisplayed());
        this.jobInfoDisplay = pureComputed(() => this.customizationParams.contentParams.jobInfoDisplay());
        this.jobInfoSeparator = pureComputed(() => this.customizationParams.contentParams.jobInfoSeparator());

        this.shouldShowDistance = shouldShowDistance();

        this.jobInfoTagsConfiguration = pureComputed(() =>
            this.customizationParams.contentParams.jobInfoTagsConfiguration()
        );

        this.jobTags = generateJobTags(this.job, this.jobTagsConfig);

        // Workaround for iOS devices which can't maintain previous scroll position on safari and chrome.
        // See [Bug-33997367]. It will affect every mobile browser when using browser back
        // and overlay exit button, going from job details to search

        this.hasFocus.subscribe((focus) => {
            const focusedElement = document.querySelector(`[href="${getJobUrl(job.id)}"]`);

            setTimeout(() => {
                if (focus && deviceDetector.isMobile() && focusedElement && router.route().id === 'search') {
                    focusedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        });

        this.dffs = (job.requisitionFlexFields as SearchResultsRequisitionFlexField[]) || [];
    }

    toggleFavourite(): void {
        this.isFavouriteToggled(true);

        favouriteJobsService.toggleFavourite(this.job);
    }

    openJobPreview(jobId: string): void {
        if (router.route().id === 'candidate-self-service.job-preview') {
            router.go('candidate-self-service.job-preview', { jobId: jobId });
        } else {
            this.openJob(jobId);
        }

        this.hasFocus(true); // hasFocus two way binding has problems on iOS, needs to be set explicitly
    }

    protected getDffs(): SearchResultsRequisitionFlexField[] {
        return (this.job.requisitionFlexFields as SearchResultsRequisitionFlexField[]) || [];
    }
}
