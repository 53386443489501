import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { RecommendedJobsWidgetCustomizationParams, Message } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';
import { LANGUAGE_KEY_SEPARATOR } from '../config/messages';
import { Typography } from '../../../module/search/service/customCss/typography';

const mapMessage = (paramKey: string, paramValue: string | null | undefined): Message =>
    paramValue ? JSON.parse(paramValue) : emptyParams[paramKey];

export const mapParamsFromRest = (params: RestParam[]): RecommendedJobsWidgetCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    const contentParams = paramValue ? JSON.parse(paramValue) : {};

                    return {
                        width: contentParams.width || emptyParams.content.width,
                        widthUnit: contentParams.widthUnit || emptyParams.content.widthUnit,
                        buttonSize: contentParams.buttonSize || emptyParams.content.buttonSize,
                        enablePrivacyPolicy:
                            contentParams.enablePrivacyPolicy || emptyParams.content.enablePrivacyPolicy,
                    };

                case 'callToActionDesktopText':
                case 'callToActionMobileText':
                case 'callToActionButtonText':
                case 'uploadingText':
                case 'successMessageText':
                case 'privacyPolicyText':
                    return mapMessage(paramKey, paramValue);

                case 'callToActionTypography':
                case 'callToActionHoverTypography':
                case 'privacyPolicyLinkTypography':
                case 'privacyPolicyLinkHoverTypography':
                case 'privacyPolicyModalHeaderTypography':
                case 'privacyPolicyModalTextTypography':
                case 'privacyPolicyModalCloseButtonTypography':
                case 'privacyPolicyModalCloseButtonHoverTypography':
                case 'browseButtonTypography':
                case 'browseButtonHoverTypography':
                case 'uploadingMessageTypography':
                case 'errorMessageTypography':
                case 'successMessageTypography':
                case 'manageTypography':
                case 'manageDropdownTypography':
                case 'viewAllJobsLabelTypography':
                case 'manageDropdownHoverTypography':
                case 'viewAllJobsLabelHoverTypography':
                    return mapTypography(paramValue);

                case 'manageHoverTypography':
                    return getManageHoverTypography(mapTypography(paramValue));

                case 'containerStyling':
                case 'overallStyling':
                case 'overallHoverStyling':
                case 'privacyPolicyModalStyling':
                case 'privacyPolicyModalCloseButtonStyling':
                case 'privacyPolicyModalCloseButtonHoverStyling':
                case 'browseButtonStyling':
                case 'browseButtonHoverStyling':
                case 'successMessageHoverStyling':
                case 'uploadingMessageStyling':
                case 'errorMessageStyling':
                case 'successMessageStyling':
                case 'manageDropdownStyling':
                case 'viewAllJobsStyling':
                case 'viewAllJobsHoverStyling':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    if (paramKey.includes(LANGUAGE_KEY_SEPARATOR)) {
                        return mapMessage(paramKey, paramValue);
                    }

                    return null;
            }
        },
    });
};

const getManageHoverTypography = (manageHoverTypography: Typography): Typography => {
    manageHoverTypography.fontStyle = manageHoverTypography.fontStyle ?? 'normal';
    manageHoverTypography.fontWeight = manageHoverTypography.fontWeight ?? '500';

    return manageHoverTypography;
};
