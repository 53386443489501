import { components } from 'knockout';

import './component/resend-pin/component';

import PinCodeVerificationViewModel from
    'candidate-verification/component/pin-code-verification/PinCodeVerificationViewModel';
import InlinePinCodeVerificationViewModel from
    'candidate-verification/component/pin-code-verification/InlinePinCodeVerificationViewModel';

import pinCodeTemplate from './pin-code-verification.html';
import inlinePinCodeTemplate from './inline-pin-code-verification.html';

components.register('pin-code-verification', {
    viewModel: PinCodeVerificationViewModel,
    template: pinCodeTemplate,
});

components.register('inline-pin-code-verification', {
    viewModel: {
        createViewModel(params, componentInfo) {
            const viewModel = new InlinePinCodeVerificationViewModel(params);

            viewModel.nodes = {};

            componentInfo.templateNodes
                .filter(node => node.tagName)
                .forEach((node) => {
                    const nodeName = node.tagName.toLowerCase();

                    viewModel.nodes[nodeName] = node.childNodes;
                });

            return viewModel;
        },
    },
    template: inlinePinCodeTemplate,
});
