import { observable, observableArray, pureComputed } from 'knockout';

const AUTODISMISS_DELAY = 10000;
const DEFAULT_SHOW_DELAY = 1500;
const DISMISS_TRANSITION_DURATION = 400;
const DISMISS_CLASS = 'cx-notifications__item--dismiss';

class CxNotificationsViewModel {

    constructor() {
        this.notifications = observableArray([]);
        this.showNotifications = pureComputed(() => this.notifications().length > 0);
    }

    /**
     * Push new notification
     *
     * @param   config.message - notification message
     * @param   config.type - message type (currently 'success' or 'error' are possible)
     * @param   config.delay - delay of show notifications. Default 1500 ms.
     * @param   config.autodimiss - indicate if notification should be dismissed automatically
     */

    push({ type, message, delay = DEFAULT_SHOW_DELAY, autodismiss = true }) {
        setTimeout(() => {
            const [duplicatedNotification] = this.notifications()
                .filter(notification => message === notification.text && type === notification.type);

            if (duplicatedNotification) {
                return;
            }

            const newNotification = {
                className: observable(`cx-notifications__item--${type}`),
                text: message,
                type,
                autodismiss,
            };

            this.notifications.push(newNotification);

            if (autodismiss) {
                setTimeout(() => {
                    this.remove(newNotification);
                }, AUTODISMISS_DELAY);
            }
        }, delay);
    }

    remove(notification) {
        notification.className(`${notification.className()} ${DISMISS_CLASS}`);

        setTimeout(() => {
            this.notifications.remove(notification);
        }, DISMISS_TRANSITION_DURATION);
    }

    removeAll() {
        this.notifications().forEach(notification => this.remove(notification));
    }

}

const instance = new CxNotificationsViewModel();

export default instance;
