import httpMapper from 'core/http/mapper';

const APPLY_FLOW_BLOCK_DATA = {
    attachments: 'attachmentData',
    educations: 'educationData',
    languages: 'languageData',
    licensesAndCertifications: 'licenceAndCertificationData',
    skills: 'skillData',
    workExperiences: 'workExperienceData',
    workPreferences: 'workPreferenceData',
};

function mapResumeParserInputToRest(attachmentData, flowVersionId) {
    return {
        ProfileId: '0011001100',
        ProfileType: 'rp',
        FlowVersionId: flowVersionId,
        FileTitle: attachmentData.Title,
        FileContents: attachmentData.FileContents,
        languages: [{
            ProfileItemId: 1,
        }],
        licensesAndCertifications: [{
            ProfileItemId: 2,
        }],
        educations: [{
            ProfileItemId: 3,
        }],
        attachments: [{
            Id: 4,
        }],
        workExperiences: [{
            ProfileItemId: 5,
        }],
        workPreferences: [{
            ProfileItemId: 6,
        }],
        skills: [{
            ProfileItemId: 7,
        }],
    };
}

function flattenResumeParserResponseToProfile(postResponse) {
    for (const [block, blockDataKey] of Object.entries(APPLY_FLOW_BLOCK_DATA)) {
        const blockData = postResponse?.[block]?.[0]?.[blockDataKey];
        const blockJson = blockData ? JSON.parse(blockData) : [];
        const mappedJson = httpMapper.mapFromRest(blockJson);

        postResponse[block] = mappedJson;
    }

    return {
        items: [postResponse],
    };
}

export default {
    mapResumeParserInputToRest,
    flattenResumeParserResponseToProfile,
};