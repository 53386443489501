import './bindings/scrollable';
import { components } from 'knockout';

import { AppHeaderHorizontalNavbarViewModel } from './AppHeaderHorizontalNavbarViewModel';
import template from './app-header-horizontal-navbar.html';

components.register('app-header-horizontal-navbar', {
    viewModel: AppHeaderHorizontalNavbarViewModel,
    template,
});
