import { Observable } from 'knockout';
import appConfig from 'app/model/config';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { CustomHeaderModel } from 'site-editor/module/theme-editor/module/global-header-editor/config/types';
import { LogoImages } from '../../config/types';

type Props = {
    globalHeaderSettings: CustomHeaderModel;
    logoImages: LogoImages;
    headerId: Observable<number | null>;
};

export class AppHeaderHorizontalViewModel {
    isSignedIn: Observable<boolean> = signedCandidate.isSignedIn;
    showLanguageSelector: boolean;
    isEmployeeButtonHidden: boolean | undefined;
    isProfileButtonHidden: boolean | undefined;
    profileText: string | null | undefined;
    employeeText: string | null | undefined;
    isLogoHidden: boolean | undefined;
    logoImages: LogoImages;
    headerId: Observable<number | null>;

    constructor({
        globalHeaderSettings: {
            isLogoHidden,
            isProfileButtonHidden,
            profileText,
            employeeText,
            isEmployeeButtonHidden,
        },
        logoImages,
        headerId,
    }: Props) {
        this.showLanguageSelector = appConfig.languages.length > 1;
        this.isEmployeeButtonHidden = isEmployeeButtonHidden;
        this.isProfileButtonHidden = isProfileButtonHidden;
        this.profileText = profileText;
        this.employeeText = employeeText;
        this.isLogoHidden = isLogoHidden;
        this.logoImages = logoImages;
        this.headerId = headerId;
    }
}
