import LegalDisclaimer from 'apply-flow/model/LegalDisclaimer';

export default {
    mapLegalDisclaimerFromRest({ items }) {
        const [restFlow] = items;

        return new LegalDisclaimer({
            isEnabled: restFlow.legalEnabledFlag,
            versionId: restFlow.legalDescriptionVersionId,
            description: restFlow.legalDescriptionStr,
        });
    },
};