import ko, { observable, pureComputed } from 'knockout';
import EmailFormElement from 'core/form/element/Email';
import CommunicationChannelEditor from './CommunicationChannelEditor';
import ServiceCallFormValidator from 'core/form/validator/ServiceCall';
import EqualsToValidator from 'core/form/validator/EqualsTo';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import { session } from 'candidate-verification/service/user-session/session';
import { storeLoginEmail } from 'candidate-verification/service/emailStorage';

export default class EmailEditFormElement extends EmailFormElement {

    constructor(...args) {
        super(...args);

        this.origin = null;
        this.requestVerification = null;
        this.requestRemoval = null;
        this.challenge = null;
        this.noPinVerification = false;
        this.isChannelVerified = null;

        this.isVerificationInProgress = observable(false);
        this.errorCode = observable();
        this.cssMode = false;

        this.component('email-edit-form-element');

        this.setValue = this.value.bind(this);

        const editor = new CommunicationChannelEditor(this);

        this.edit = editor.edit;
        this.cancel = editor.cancel;
        this.editMode = editor.editMode;
        this.throttlingMessage = editor.throttlingMessage;
        this.saveButtonDisabled = editor.saveButtonDisabled;
        this.accessibiliyVerificationMessage = pureComputed(() => i18n('candidate-self-service.communication-channel.email-verification-message', { email_address: this.value() }));
        this.duplicatedEmailErrorOccured = observable(false);
        this.editModeErrorOccured = observable(false);
        this.initiallyRequired = observable(false);

        this.loginWithNewEmail = this.loginWithNewEmail.bind(this);
    }

    subscribeValidations() {
        this.value.subscribe(() => this.validate());
        this.isDirty.subscribe(() => this.validate());

        this.validateImmediately = ko.observable(false).extend({ notify: 'always' });
        this.validateImmediately.subscribe(() => this.validate());
    }

    async loginWithNewEmail() {
        const { jobId, eventId } = router.routeParams();
        const isInJobContext = Boolean(jobId);
        const isInEventContext = Boolean(eventId);

        await session.signOut();

        storeLoginEmail(this.value());

        if (isInJobContext) {
            router.go('job-preview.email-verification');

            return;
        }

        if (isInEventContext) {
            router.go('event-preview.email-verification');

            return;
        }

        router.go('candidate-self-service.sign-in');
    }

    isSaveButtonEnabled() {
        return this.isValid() || this.editModeErrorOccured();
    }

    isEmpty() {
        return this._isValueEmpty();
    }

    _validate(value) {
        return this._runSynchronouslyValidators(value)
            .catch(validator => ({
                isValid: false,
                validator,
            }))
            .then((result = {}) => {
                const { validator, isValid = true } = result;

                if (!isValid) {
                    this.editModeErrorOccured(false);
                    this.duplicatedEmailErrorOccured(false);
                }

                if (validator instanceof ServiceCallFormValidator) {
                    this.duplicatedEmailErrorOccured(!isValid);
                }

                if (validator instanceof EqualsToValidator) {
                    this.editModeErrorOccured(!isValid);
                }

                this.isValid(isValid);
                this.getErrorMessage(validator ? validator.getErrorMessage() : null);

                return isValid;
            })
            .catch(console.error);
    }

}