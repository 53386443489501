import ko from 'knockout';
import FormElement from 'core/form/element/Element';
import formValidatorFactory from 'core/form/validator/factory';

export default class ValidatableFormElement extends FormElement {

    constructor(...args) {
        super(...args);

        /**
         * @protected
         */
        this._validators = ko.observableArray();

        this.validators = ko.pureComputed({
            read: this._getValidators,
            write: this._setValidators,
            owner: this,
        });

        this.isRequired = ko.pureComputed({
            read: this._getIsRequired,
            write: this._setIsRequired,
            owner: this,
        });

        this.isValid = ko.observable().extend({ notify: 'always' });
        this.isValidElement = ko.pureComputed(this._isValidElement, this);
        this.validateImmediately = ko.observable(false);
        this.getErrorMessage = ko.observable();

        this.subscribeValidations();
    }

    subscribeValidations() {
        this.value.subscribe(() => this.validate());
        this.isDirty.subscribe(() => this.validate());
        this.validateImmediately.subscribe(() => this.validate());
    }

    validate(value = this.value()) {
        return this._validate(value);
    }

    getValidator(validatorClass) {
        let validator = validatorClass;

        if (typeof validator === 'string') {
            validator = formValidatorFactory.validators[validator];
        }

        const foundValidator = ko.utils.arrayFirst(this._validators(),
            existingValidator => existingValidator instanceof validator);

        return foundValidator || null;
    }

    addValidator(validatorClass, options, position = 'append') {
        let validator = validatorClass;

        if (typeof validator === 'string') {
            this._removeDuplicatedValidator(validator);
            validator = formValidatorFactory.create(validator, options);
        }

        if (position === 'prepend') {
            this._validators.unshift(validator);
        } else {
            this._validators.push(validator);
        }

        return validator;
    }

    removeValidator(validator) {
        const index = this._validators.indexOf(validator);

        this._validators.splice(index, 1);
    }

    _getValidators() {
        return this._validators();
    }

    _setValidators(validator, options) {
        if (typeof validator === 'object') {
            Object.keys(validator).forEach((name) => {
                this.addValidator(name, validator[name]);
            });
        } else {
            this.addValidator(validator, options);
        }

        return this;
    }

    _removeDuplicatedValidator(validatorName) {
        const validator = this.getValidator(validatorName);

        if (validator) {
            this.removeValidator(validator);
        }
    }

    _getIsRequired() {
        const requireValidator = this.getValidator('required');

        return requireValidator && !requireValidator.isDisabled();
    }

    _setIsRequired(isRequired) {
        let requireValidator = this.getValidator('required');

        if (!requireValidator) {
            requireValidator = this.addValidator('required');
        }

        requireValidator.isDisabled(!isRequired);
    }

    _isValidElement() {
        const hasValidators = this._validators().length;

        if (!hasValidators) {
            return true;
        }

        if (!this.validateImmediately() && !this.isDirty() && this._isValueEmpty()) {
            return true;
        }

        return this.isValid();
    }

    _isValueEmpty() {
        const value = this.value();

        return !value || (Array.isArray(value) && !value.length);
    }

    _runSynchronouslyValidators(value) {
        return this._getValidators()
            .reduce((promiseAccumulator, validator) =>
                promiseAccumulator.then(() =>
                    validator
                        .isValid(value)
                        .catch(console.error)
                        .then(isValid => (isValid ? Promise.resolve() : Promise.reject(validator))),
                ), Promise.resolve());
    }

    _validate(value) {
        return this._runSynchronouslyValidators(value)
            .catch(validator => ({
                isValid: false,
                validator,
            }))
            .then((result = {}) => {
                const { validator, isValid = true } = result;

                this.isValid(isValid);
                this.getErrorMessage(validator ? validator.getErrorMessage() : null);

                return isValid;
            })
            .catch(console.error);
    }

}
