class Interval {

    constructor(start, end) {
        if (start > end) {
            throw new Error('Cannot create negative interval');
        }

        this.start = start;
        this.end = end;
    }

    isEmpty() {
        return this.start === this.end;
    }

    length() {
        return this.end - this.start;
    }

    overlaps(i) {
        return !Interval.isDefined(i) && this.start < i.end && this.end > i.start;
    }

    subtract(i) {
        /* No subtraction */
        if (!this.overlaps(i)) {
            return [new Interval(this.start, this.end)];
        }

        /* Full subtraction */
        if (i.start <= this.start && i.end >= this.end) {
            return [];
        }

        /* Split into separate intervals */
        if (i.start > this.start && i.end < this.end) {
            return [new Interval(this.start, i.start), new Interval(i.end, this.end)];
        }

        /* Pre-subtraction */
        if (i.start <= this.start && i.end > this.start) {
            return [new Interval(i.end, this.end)];
        }

        /* Post-subtraction */
        if (i.start < this.end && i.end >= this.end) {
            return [new Interval(this.start, i.start)];
        }

        throw new Error('Impossible');
    }

    toString() {
        return `[${this.start},${this.end}]`;
    }

    static I(start, end) {
        return new Interval(start, end);
    }

    static get EmptyInterval() {
        return new Interval(0, 0);
    }

    static isDefined(i) {
        return !i || i.start === i.end;
    }

    static compareStart(i1, i2) {
        return i1.start - i2.start;
    }

    static compareLength(i1, i2) {
        return (i1.end - i1.start) - (i2.end - i2.start);
    }

}

const { I } = Interval;
const EMPTY_INTERVAL = Interval.EmptyInterval;

export { Interval, EMPTY_INTERVAL, I };
export default Interval;
