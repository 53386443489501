import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class ClobFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.validators('html');
        this.validators('xss');

        this.component('textarea-form-element');
    }

}