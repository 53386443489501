import mapping from 'knockout-mapping';
import questionsMapper from 'apply-flow/module/questionnaire/mapper/questions';
import { EVENTS } from 'apply-flow/module/questionnaire/enum/questionnaireTypes';

export default {
    mapDataToRest(questionnaire) {
        const rawQuestionnaire = mapping.toJS(questionnaire);

        return {
            questionnaireId: rawQuestionnaire.questionnaireId,
            qstnrVersionNumber: rawQuestionnaire.questionnaireVersionNumber,
            type: EVENTS,
            questionResponses: questionsMapper.mapQuestionsToRest(rawQuestionnaire.questions),
        };
    },
};
