import { CssTab } from '../../config/types';
import { LoadingStatus } from 'minimal/module/candidate-self-service/service/EventsState';
import { Observable, PureComputed } from 'knockout';

type Props = {
    activeTab: Observable<CssTab>;
    onTabChanged: (tab: CssTab) => void;
    isEventTabVisible: PureComputed<boolean>;
    loadingStatus: Observable<LoadingStatus>;
};

export default class CsNavigationBarViewModel {
    activeTab: Observable<CssTab>;
    onTabChanged: (tab: CssTab) => void;
    isEventTabVisible: PureComputed<boolean>;
    loadingStatus: Observable<LoadingStatus>;

    constructor({ activeTab, onTabChanged, isEventTabVisible, loadingStatus }: Props) {
        this.activeTab = activeTab;
        this.onTabChanged = onTabChanged;
        this.isEventTabVisible = isEventTabVisible;
        this.loadingStatus = loadingStatus;
    }
}
