import appConfig from 'app/model/config';
import copyAbilityResolver from 'job-details/component/copy-link/service/copyAbilityResolver';
import deviceDetector from 'core/device-detector/deviceDetector';

const { uiBaseUrl, version } = appConfig;
const appVersion = version ? `${version}/` : '';

export const getImageUrl = (image: string): string => `${uiBaseUrl}/CandExpStatic/${appVersion}${image}`;

export const getItemActiveSelector = (itemSelector: string, itemElementSelector: string): string =>
    [
        `${itemSelector}:hover ${itemElementSelector}`,
        `${itemSelector}:focus ${itemElementSelector}`,
        `${itemSelector}--active ${itemElementSelector}`,
    ].join(', ');

export const getItemActiveSelectorWithBefore = (itemSelector: string, itemElementSelector: string): string =>
    [
        `${itemSelector}:hover::before ${itemElementSelector}`,
        `${itemSelector}:focus::before ${itemElementSelector}`,
        `${itemSelector}--active::before ${itemElementSelector}`,
    ].join(', ');

export const getItemActiveSelectorWithoutFocus = (
    itemSelector: string,
    itemElementSelector: string
): string =>
    [`${itemSelector}:hover ${itemElementSelector}`, `${itemSelector}--active ${itemElementSelector}`].join(
        ', '
    );

export const getItemActiveSelectorWithoutFocusAndHover = (
    itemSelector: string,
    itemElementSelector: string
): string => `${itemSelector}--active ${itemElementSelector}`;

export const copyToClipboard = (nodeContent: HTMLElement): void => {
    if (!nodeContent) {
        return;
    }

    const selection = window.getSelection() as Selection;
    const range = document.createRange() as Range;

    if (copyAbilityResolver.canCopyProgramatically()) {
        range.selectNodeContents(nodeContent);

        selection.addRange(range);

        document.execCommand('copy');

        selection.removeAllRanges();
    }
};

export const getItemSelector = (itemSelector: string, itemElementSelector: string): string => {
    return deviceDetector.isMobile()
        ? getItemActiveSelectorWithoutFocusAndHover(itemSelector, itemElementSelector)
        : getItemActiveSelectorWithoutFocus(itemSelector, itemElementSelector);
};
