import { Job, JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import {
    getHeightCss,
    getMaxWidthCss,
    getStylingBoxCss,
    getTypographyCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { JobDetailsApplyButtonParams } from './config/types';
import { PureComputed, Observable, observable } from 'knockout';
import signedCandidate from 'candidate-verification/model/signedCandidate';
import { isIndeedOptiExperience, isAutoLaunchIndeed } from 'app/service/indeedExperience';
import profileImportEvents from 'cx/module/apply-flow/module/profile-import/config/events';

export type JobDetailsApplyButtonMessage = {
    type: 'onApplyButtonClicked' | 'onApplyIndeedButtonClicked' | 'onApplyIndeedAutoLaunch';
};

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsApplyButtonParams;
    id?: string;
    onMessage: (message: JobDetailsApplyButtonMessage) => void;
    mode?: string;
};

export class JobDetailsApplyButtonViewModel extends CustomComponentViewModel<JobDetailsApplyButtonParams> {
    pageData: PureComputed<JobDetailsPageData>;
    onMessage: (message: JobDetailsApplyButtonMessage) => void;
    isEnable: Observable<boolean>;
    label: Observable<string>;
    labelIndeed: string;
    buttonStatusClass: Observable<string>;
    isAdminMode?: boolean;
    isIndeedMode: boolean;

    constructor({ pageData, onMessage, mode, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
        this.onMessage = onMessage;
        this.isEnable = observable<boolean>(false);
        this.label = observable('job-details.apply-button');
        this.labelIndeed = 'apply-flow.section-personal-information.connect-with-indeed-button';
        this.buttonStatusClass = observable('apply-now-button--apply-now');
        this.isAdminMode = mode === 'admin';
        this.isIndeedMode = isIndeedOptiExperience();
        this.applyWithIndeed = this.applyWithIndeed.bind(this);

        this.setUpApplyButton(this.pageData().job);

        this.autoLaunchIndeedExperience();
    }

    getCustomStyle(key: keyof JobDetailsApplyButtonParams): string {
        switch (key) {
            case 'content':
                const widthSelector = getMaxWidthCss({
                    selector: `.${this.uniqueWrapperClass} .apply-now-button, .apply-with-indeed-button`,
                    width: this.customizationParams[key].width(),
                    widthUnit: this.customizationParams[key].widthUnit(),
                });

                const heightSelector = getHeightCss({
                    selector: `.${this.uniqueWrapperClass} .apply-now-button, .apply-with-indeed-button`,
                    height: this.customizationParams[key].height(),
                    heightUnit: this.customizationParams[key].heightUnit(),
                });

                return [widthSelector, heightSelector].join(STYLES_SEPARATOR);

            case 'titleTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .apply-now-button--apply-now .button__label, .apply-with-indeed-button`,
                    typography: this.customizationParams[key],
                });

            case 'titleAlreadyAppliedTypography':
                this.customizationParams[key].fontSize = this.customizationParams.titleTypography.fontSize;

                this.customizationParams[key].fontFamily =
                    this.customizationParams.titleTypography.fontFamily;

                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .apply-now-button--already-applied .button__label`,
                    typography: this.customizationParams[key],
                });

            case 'button':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .apply-now-button--apply-now::before, .apply-with-indeed-button::before`,
                    stylingBox: this.customizationParams[key],
                });

            case 'alreadyAppliedButton':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .apply-now-button--already-applied:disabled::before,
                    .${this.uniqueWrapperClass} .apply-now-button--already-applied::before`,
                    stylingBox: this.customizationParams[key],
                });

            case 'container':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-details__section`,
                    stylingBox: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }

    click(): void {
        const apply: JobDetailsApplyButtonMessage = {
            type: 'onApplyButtonClicked',
        };

        this.onMessage(apply);
    }

    applyWithIndeed(): void {
        const apply: JobDetailsApplyButtonMessage = {
            type: 'onApplyIndeedButtonClicked',
        };

        this.isEnable(false);

        profileImportEvents.indeedProfileImportEnded.addOnce(() => {
            this.isEnable(true);
        });

        this.onMessage(apply);
    }

    autoLaunchIndeedExperience(): void {
        if (this.showIndeedButton() && isAutoLaunchIndeed()) {
            const apply: JobDetailsApplyButtonMessage = {
                type: 'onApplyIndeedAutoLaunch',
            };

            this.isEnable(false);

            profileImportEvents.indeedProfileImportEnded.addOnce(() => {
                this.isEnable(true);
            });

            this.onMessage(apply);
        }
    }

    showIndeedButton(): boolean {
        return Boolean(
            !this.isAdminMode &&
                this.isIndeedMode &&
                !this.pageData().job.isAlreadyApplied &&
                !this.pageData().job.isConfirmed
        );
    }

    private setUpApplyButton(job: Job): void {
        const { isSignedIn } = signedCandidate;

        if (Boolean(isSignedIn()) && job.isAlreadyApplied) {
            this.isEnable(!job.isConfirmed);
            this.label(job.isConfirmed ? 'job-details.already-applied' : 'job-details.continue-apply');

            this.buttonStatusClass(
                job.isConfirmed ? 'apply-now-button--already-applied' : 'apply-now-button--apply-now'
            );
        } else {
            this.isEnable(true);
            this.buttonStatusClass('apply-now-button--apply-now');
        }
    }
}
