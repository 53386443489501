import appConfig from 'app/model/config';
import RegulatoryConstants from 'apply-flow/module/regulatory/config/RegulatoryConstants';
import AbstractRegulatoryMetadata from 'apply-flow/module/regulatory/service/AbstractRegulatoryMetadata';

export default class DisabilityMetadata extends AbstractRegulatoryMetadata {

    getBlockCode() {
        return RegulatoryConstants.ORA_DISABILITY.blockCode;
    }

    getServiceConfig() {
        return { baseUrl: `${appConfig.uiBaseUrl}/CandidateExperience/ircRestAuthApi` };
    }

}