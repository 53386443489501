import i18n from 'core/i18n/i18n';
import FormTileStrategy from 'apply-flow/module/tile-profile-items/model/FormTileStrategy';

const UNNAMED_LANGUAGE_LABEL = i18n('apply-flow.profile-item.unnamed-language');

export default class LanguageFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return {
            name: 'contentItemId',
            language: 'language',
        };
    }

    buildTitle(valueMap) {
        return valueMap.name || valueMap.language || UNNAMED_LANGUAGE_LABEL;
    }

}
