import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { JobDetailsApplyButtonCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';

export const mapParamsFromRest = (params: RestParam[]): JobDetailsApplyButtonCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    const contentParams = paramValue
                        ? JSON.parse(paramValue)
                        : {
                              width: 270,
                              height: 60,
                              widthUnit: 'px',
                              heightUnit: 'px',
                          };

                    return {
                        height: contentParams.height || 60,
                        width: contentParams.width || 270,
                        widthUnit: contentParams.widthUnit || 'px',
                        heightUnit: contentParams.heightUnit || 'px',
                    };

                case 'titleTypography':
                case 'titleAlreadyAppliedTypography':
                    return mapTypography(paramValue);

                case 'container':
                case 'button':
                case 'alreadyAppliedButton':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return notReachable(paramKey);
            }
        },
    });
};
