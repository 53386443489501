import ko from 'knockout';

export default class CandidateVerificationLayoutViewModel {

    constructor() {
        this.flow = ko.observable({
            isSingleClick: true,
            iterator: ko.observable({
                currentSection: ko.observable(),
            }),
        });
    }

}