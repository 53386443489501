import Component from './Component';

export default class RowComponent extends Component {

    constructor(data) {
        super(data);

        this.columns = data.columns;
    }

}
