import { getItemActiveSelector } from 'minimal/config/utils';

export type CookieConsentMessageCustomizationSelectorsKey =
    | 'cookieConsentMessageTypography'
    | 'cookiePreferenceLinkTypography'
    | 'cookiePreferenceLinkHoverTypography'
    | 'overAllStyling';

export const getCookieConsentMessageSelectors = (
    wrapperSelector: string
): Record<CookieConsentMessageCustomizationSelectorsKey, string> => ({
    overAllStyling: `${wrapperSelector}.cookie-consent-widgets .cookie-consent__message`,
    cookieConsentMessageTypography: `${wrapperSelector}.cookie-consent-widgets .cookie-consent__message`,
    cookiePreferenceLinkTypography: `${wrapperSelector}.cookie-consent-widgets .cookie-consent__read-more`,
    cookiePreferenceLinkHoverTypography: getItemActiveSelector(
        `${wrapperSelector}.cookie-consent-widgets .cookie-consent__read-more`,
        ''
    ),
});
