import '../components/candidate-verification';
import '../components/event-register-flow-pagination';
import '../components/event-register-flow-navigation-multipage';
import '../components/event-register-flow-summary';
import '../components/event-register-flow-header';
import '../components/event-register-flow-pin-verification';
import '../components/existing-registration';
import '../components/profile-redirection-dialog';
import './routing';
import './types';

import { components } from 'knockout';
import ApplyFlowLightViewModel from 'app/module/cx/module/apply-flow-light/ApplyFlowLightViewModel';
import templateLight from '../event-register-light.html';
import registerFlowTemplate from '../event-register.html';
import registerFlowViewModel from '../EventRegisterViewModel';

components.register('event-register-flow-light', {
    viewModel: ApplyFlowLightViewModel,
    template: templateLight,
});

components.register('event-register-flow', {
    viewModel: registerFlowViewModel,
    template: registerFlowTemplate,
});
