type Props = {
    videoUrl: string;
    videoWrapper: boolean;
    isFullscreen: boolean;
};

export default class VideoBackgroundViewModel {
    videoUrl: string;
    videoWrapper: boolean;
    isFullscreen: boolean;

    constructor({ videoUrl, videoWrapper, isFullscreen }: Props) {
        this.videoUrl = videoUrl;
        this.videoWrapper = videoWrapper;
        this.isFullscreen = isFullscreen;
    }
}
