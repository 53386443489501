import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    action: 'CREATE',
    flowVersionId: null,
    sectionId: null,
    contentTypeId: null,
    contentItemId: null,
    previousEmploymentId: null,
    employerName: null,
    jobTitle: null,
    startDate: null,
    endDate: null,
    responsibilities: null,
    employerCity: null,
    stateProvinceCode: null,
    countryCode: null,
    currentJobFlag: null,
    fullTimeCode: null,
    internalFlag: null,
    businessType: null,
    employerPhone: null,
    department: null,
    jobFamilyId: null,
    jobFunction: null,
    otherJobFunction: null,
    achievements: null,
    directReports: null,
    startingPosition: null,
    supervisorName: null,
    supervisorTitle: null,
    supervisorEmail: null,
    supervisorPhone: null,
    contactSupervisorFlag: null,
    leavingReason: null,
    additionalInformation: null,
    startCompensation: null,
    endCompensation: null,
    otherCompensation: null,
    companyUrl: null,
};

export default class CandidateExperience extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'previousEmploymentId');
    }

}
