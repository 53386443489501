import ko from 'knockout';
import router from 'app/model/router';
import offerService from '../../service/offer';
import offerEvents from '../../config/events';
import appEvents from 'app/config/events';
import tokenService from 'candidate-verification/service/token';
import OfferDeclineReason from './model/OfferDeclineReason';
import offerDeclineFormBuilder from '../../service/offerDeclineReasonsFormBuilder';

export default class OfferRefusalViewModel {

    constructor(offerModel) {
        if (offerModel && offerModel.isOfferAccepted()) {
            router.go('offer');
        }

        this.offer = ko.observable(offerModel);
        this.isOfferBeingDeclined = ko.observable(false);

        this.showOfferDeclineReasons = offerModel.declineReasonsEnabledFlag;
        this.declineReasons = offerModel.offerDeclineReasons;

        this.model = new OfferDeclineReason();
        this.declineForm = this._createForm(this.declineReasons);
    }

    _createForm(declineReasons) {
        const form = offerDeclineFormBuilder.createForm(declineReasons);

        form.registerModel(this.model);

        return ko.observable(form);
    }

    submit() {
        this.isOfferBeingDeclined(true);

        offerService.refuse(tokenService.get().accessCode, this.model.reasonId(), this.model.comment())
            .then(() => {
                this.isOfferBeingDeclined(false);
                router.go('offer-just-refused');
            })
            .catch(() => {
                this.isOfferBeingDeclined(false);
                appEvents.error.dispatch();
            });
    }

    goBack() {
        offerEvents.refuse.dispatch();
        router.go('offer');
    }

}