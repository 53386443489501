import { getItemActiveSelector, getItemActiveSelectorWithoutFocus } from 'minimal/config/utils';

type HeaderSelectorField =
    | 'wrapper'
    | 'menu'
    | 'dropdown'
    | 'logoDesktop'
    | 'logoMobile'
    | 'links'
    | 'linksHover'
    | 'linksSelected'
    | 'subLinks'
    | 'subLinksHover'
    | 'subMenu'
    | 'subMenuHover'
    | 'email'
    | 'location'
    | 'name'
    | 'buttons'
    | 'icons'
    | 'iconsHover'
    | 'iconsSelected'
    | 'menu'
    | 'linksContainer'
    | 'linksContainerHover'
    | 'linksContainerSelected'
    | 'subLinksContainer'
    | 'subLinksContainerHover'
    | 'overallIcons'
    | 'overallIconsHover'
    | 'ruleColor';

export type HeaderSelector = Record<HeaderSelectorField, string>;

export const getHeaderSelectors = (wrapperSelector: string): HeaderSelector => {
    return {
        wrapper: `${wrapperSelector}.app-header`,
        overallIcons: [
            `${wrapperSelector} .app-header-horizontal-navbar__arrow--right`,
            `${wrapperSelector} .app-header-horizontal-navbar__arrow--left`,
        ].join(','),
        overallIconsHover: [
            getItemActiveSelectorWithoutFocus(
                `${wrapperSelector} .app-header-horizontal-navbar__arrow--right`,
                ''
            ),
            getItemActiveSelectorWithoutFocus(
                `${wrapperSelector} .app-header-horizontal-navbar__arrow--left`,
                ''
            ),
        ].join(','),
        menu: '.app-menu .app-menu__wrapper',
        dropdown: [
            '.app-header-horizontal .app-header-horizontal-navbar__sublinks-block',
            '.oj-listbox-drop-layer',
            '.oj-menu-layer .language-select__menu-dropdown',
            '.header-menu .header-menu__dropdown',
        ].join(','),
        logoDesktop: '.app-header__logo--desktop img',
        logoMobile: '.app-header__logo--mobile img',
        links: [
            `${wrapperSelector} .app-header-horizontal-navbar__item`,
            `${wrapperSelector} .app-header-horizontal-navbar__item:not([aria-expanded="true"], :hover) .app-header-horizontal-navbar__text`,
            `${wrapperSelector} .internal-user-login-button__display-text`,
            `${wrapperSelector} .user-menu__text`,
            `${wrapperSelector} .user-menu__header`,
            `${wrapperSelector} .user-menu__header:not(.theme-color-1, :hover) .user-menu__header-text`,
            `${wrapperSelector} .app-menu__item`,
            `${wrapperSelector} .app-menu__item > a:not(:hover)`,
            `.language-select .language-select__menu .language-select__menu-item-selected`,
            `.app-header-horizontal .language-select .language-select__menu .language-select__menu-item-selected`,
        ].join(','),
        linksHover: [
            getItemActiveSelector(
                `${wrapperSelector} .app-header-horizontal-navbar__item`,
                '.app-header-horizontal-navbar__text'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .internal-user-login-button`,
                '.internal-user-login-button__display-text'
            ),
            getItemActiveSelector(`${wrapperSelector} .user-menu__header`, '.user-menu__text'),
            getItemActiveSelector(`${wrapperSelector} .oj-select-chosen`, ''),
            getItemActiveSelector(`${wrapperSelector} .app-menu__item > a`, ''),
            getItemActiveSelector('.user-menu__header .user-menu__text', ''),
            getItemActiveSelector(
                `.language-select .language-select__menu`,
                '.language-select__menu-item-selected'
            ),
            getItemActiveSelector(
                `.app-header-horizontal .language-select .language-select__menu`,
                '.language-select__menu-item-selected'
            ),
            getItemActiveSelectorWithoutFocus(
                `${wrapperSelector} .user-menu__header`,
                '.user-menu__header-text'
            ),
        ].join(','),
        linksSelected: [
            `.language-select .language-select__menu.oj-selected .language-select__menu-item-selected`,
            `.app-header-horizontal .language-select .language-select__menu.oj-selected .language-select__menu-item-selected`,
            `${wrapperSelector} .app-header-horizontal-navbar__item[aria-expanded="true"] .app-header-horizontal-navbar__text`,
            `${wrapperSelector} .header-menu--active .user-menu__header.theme-color-1 .user-menu__header-text`,
        ].join(','),
        subLinks: [
            `.app-header-horizontal-navbar__subitem a`,
            `.app-menu__subitem a`,
            `.app-menu__miscellaneous-item a`,
        ].join(','),
        subLinksHover: [
            getItemActiveSelector(`.app-header-horizontal-navbar__subitem a`, ''),
            getItemActiveSelector(`.app-menu__subitem a`, ''),
            getItemActiveSelector(`.app-menu__miscellaneous-item a`, ''),
            getItemActiveSelector(`.app-header-horizontal-navbar__subitem`, 'a'),
        ].join(','),
        subMenu: [
            `${wrapperSelector} .favourite-jobs__title`,
            `${wrapperSelector} .user-menu__action-item button`,
            `.language-select__menu-dropdown .language-select__menu-dropdown-item a`,
        ].join(','),
        subMenuHover: [
            getItemActiveSelector(`${wrapperSelector} .favourite-jobs__title`, ''),
            getItemActiveSelector(`${wrapperSelector} .user-menu__action-item`, 'button'),
            getItemActiveSelector(
                `.language-select__menu-dropdown .language-select__menu-dropdown-item a`,
                ''
            ),
            getItemActiveSelector(
                '.language-select__select__list .oj-listview-cell-element',
                '.oj-highlighttext.oj-complete'
            ),
        ].join(','),
        email: `${wrapperSelector} .user-menu__subtitle-item`,
        location: `${wrapperSelector} .favourite-jobs__subtitle`,
        name: `${wrapperSelector} .user-menu__title-item`,
        buttons: [
            `${wrapperSelector} .icon-employee`,
            `${wrapperSelector} .user-menu__icon`,
            `.language-select .oj-text-field-end`,
            `${wrapperSelector} .button__wrapper__arrow--down`,
            `${wrapperSelector} .button__arrow`,
            `${wrapperSelector} .language-select__menu .oj-button-menu-dropdown-icon`,
            `${wrapperSelector} .user-menu__header-arrow`,
            `.header-menu .favourite-jobs__toggle`,
            `.header-menu .favourite-jobs__item-toggle`,
            `${wrapperSelector} .app-toggle-menu__container`,
            `.app-menu__header .app-menu__close`,
            `.app-header-horizontal-navbar .app-header-horizontal-navbar__arrow--left`,
            `.app-header-horizontal-navbar .app-header-horizontal-navbar__arrow--right`,
        ].join(','),
        icons: [
            `${wrapperSelector} .icon-employee::before`,
            `${wrapperSelector} .user-menu__header:not(:hover) .user-menu__icon::before`,
            `${wrapperSelector} .language-select__menu .oj-button-menu-dropdown-icon::before`,
            `${wrapperSelector} .button__arrow::before`,
            `${wrapperSelector} .user-menu__header:not(.theme-color-1, :hover) .user-menu__header-arrow::before`,
            `${wrapperSelector} .header-menu:not(:hover) .favourite-jobs__toggle::before`,
            `.favourite-jobs__item-toggle::before`,
            `${wrapperSelector}.app-menu__wrapper .app-menu__close:not(:hover)::before`,
            `${wrapperSelector} .app-toggle-menu__container`,
        ].join(','),
        iconsHover: [
            getItemActiveSelector(`${wrapperSelector} .internal-user-login-button`, '.icon-employee::before'),
            getItemActiveSelector(`${wrapperSelector} .user-menu__header`, '.user-menu__icon::before'),
            getItemActiveSelector(
                `${wrapperSelector} .oj-text-field-container`,
                '.oj-searchselect-arrow::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .app-header-horizontal-navbar__item`,
                '.button__arrow::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .language-select .language-select__menu`,
                '.oj-button-menu-dropdown-icon::before'
            ),
            getItemActiveSelector(`${wrapperSelector} .header-menu`, '.user-menu__header-arrow::before'),
            getItemActiveSelector(
                `${wrapperSelector} .app-header-horizontal .header-menu`,
                '.favourite-jobs__toggle::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .app-header-hamburger .header-menu`,
                '.favourite-jobs__toggle::before'
            ),
            getItemActiveSelector(
                `${wrapperSelector} .app-toggle-menu__container`,
                '.app-toggle-menu__strap'
            ),
            `${wrapperSelector}.app-menu__wrapper .app-menu__close:hover::before`,
        ].join(','),
        iconsSelected: [
            `${wrapperSelector} .app-header-hamburger .header-menu--active .favourite-jobs__toggle::before`,
            `${wrapperSelector} .app-header-hamburger .header-menu--active .user-menu__header-arrow::before`,
            `${wrapperSelector} .user-menu__header.theme-color-1 .user-menu__header-arrow::before`,
            `${wrapperSelector} .app-header-horizontal .header-menu--active .favourite-jobs__toggle.theme-color-1::before`,
            `${wrapperSelector} .app-header-horizontal-navbar__item[aria-expanded="true"] .app-header-horizontal-navbar__item-link-wrapper .button__arrow::before`,
            `${wrapperSelector} .language-select__menu.oj-selected .oj-button-icon:before`,
        ].join(','),
        linksContainer: [
            `.app-menu__item > a`,
            `.language-select__menu`,
            `${wrapperSelector} .user-menu__header`,
            `.app-header-horizontal-navbar__item`,
            `${wrapperSelector} .internal-user-login-button`,
        ].join(','),
        linksContainerHover: [
            getItemActiveSelector(`.app-menu__item > a`, ''),
            getItemActiveSelector(`.language-select__menu`, ''),
            getItemActiveSelector(`.app-header .user-menu__header`, ''),
            getItemActiveSelector(`.app-header-horizontal-navbar__item`, ''),
            getItemActiveSelector(`.app-header .internal-user-login-button`, ''),
            getItemActiveSelectorWithoutFocus(`.app-header-horizontal .user-menu__header-text`, ''),
            getItemActiveSelectorWithoutFocus(
                `${wrapperSelector} .app-header-hamburger .user-menu__header`,
                ''
            ),
        ].join(','),
        linksContainerSelected: [
            '.language-select__menu.oj-selected',
            `${wrapperSelector} .app-header-horizontal-navbar__item[aria-expanded="true"]`,
            `${wrapperSelector} .header-menu--active #user-menu-header`,
        ].join(','),
        subLinksContainer: [
            `.app-menu__subitem > a`,
            `${wrapperSelector} .app-header-horizontal-navbar__subitem`,
            `.app-menu__miscellaneous-item a`,
        ].join(','),
        ruleColor: [
            '.app-menu__sublinks-line.background-color-secondary',
            '.user-menu__dropdown .user-menu__separator',
            '.app-menu__miscellaneous-line.background-color-secondary',
        ].join(','),
        subLinksContainerHover: [
            getItemActiveSelector(`.app-menu__subitem > a`, ''),
            getItemActiveSelector(`.app-header-horizontal-navbar__subitem > a`, ''),
            getItemActiveSelector(`.app-header-horizontal-navbar__subitem`, ''),
            getItemActiveSelector(`.app-menu__miscellaneous-item a`, ''),
        ].join(','),
    };
};
