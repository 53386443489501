export type HLanguage = {
    cxCode: string;
    hCaptchaCode: string;
};

export const hLanguages: HLanguage[] = [
    {
        cxCode: 'es-US',
        hCaptchaCode: 'es',
    },
    {
        cxCode: 'fr-CA',
        hCaptchaCode: 'fr',
    },
    {
        cxCode: 'pt-BR',
        hCaptchaCode: 'pt',
    },
];
