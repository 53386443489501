import { components } from 'knockout';

import { JobDetailsTitleViewModel } from './JobDetailsTitleViewModel';
import template from './job-details-title.html';

components.register('job-details-title', {
    viewModel: JobDetailsTitleViewModel,
    template,
});

components.register('cc-job-details-title', {
    viewModel: JobDetailsTitleViewModel,
    template,
});
