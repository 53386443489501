import i18n from 'core/i18n/i18n';
import { CUSTOM_CRITERIA } from '../enums/jobListTypes';
import { emptySearchBarParams } from 'minimal/module/search/component/search-bar/config/defaultParams';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import { emptyFiltersParams } from 'minimal/module/search/component/search-filters-panel/config/defaultParams';
import { emptyParams as emptyJobDetailsTitleParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-title/config/emptyParams';
import { emptyParams as emptyCookieConsentModalTitleParams } from 'cookie-consent/components/cookie-consent-modal-title/config/emptyParams';
import { emptyParams as emptyCookieConsentModalPolicyParams } from 'cookie-consent/components/cookie-consent-modal-policy/config/emptyParams';
import { emptyParams as emptyCookieConsentModalMessageParams } from 'cookie-consent/components/cookie-consent-modal-message/config/emptyParams';
import { emptyParams as emptyCookieConsentModalActionsParams } from 'cookie-consent/components/cookie-consent-modal-actions/config/emptyParams';
import { emptyParams as emptyCookieConsentModalCategoriesParams } from 'cookie-consent/components/cookie-consent-modal-categories/config/emptyParams';
import { emptyParams as emptyJobDetailsLocationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-location/config/emptyParams';
import { emptyParams as emptyJobDetailsSkillsParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-skills/config/emptyParams';
import { emptyParams as emptyJobDetailsDescriptionParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-description/config/emptyParams';
import { emptyParams as emptyJobDetailsApplyButtonParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-apply-button/config/emptyParams';
import { emptyParams as emptyJobDetailsMediaParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-media/config/emptyParams';
import { emptyParams as emptyJobDetailsTagsParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-tags/config/emptyParams';
import { emptyParams as emptyJobDetailsInfoParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-info/config/emptyParams';
import { emptyParams as emptyJobDetailsSimilarJobsParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-similar-jobs/config/emptyParams';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config';
import { CommonParams } from 'minimal/module/search/service/customCss';
import { SearchResultsCustomizationParams } from 'minimal/module/search/component/search-results/config/types';
import { FiltersCustomizationParams } from 'minimal/module/search/component/search-filters-panel/config/types';
import { SearchBarCustomizationParams } from 'minimal/module/search/component/search-bar/config/types';
import { JobDetailsLocationCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-location/config/types';
import { JobDetailsDescriptionCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-description/config/types';
import { JobDetailsApplyButtonCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-apply-button/config/types';
import { JobDetailsMediaCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-media/config/types';
import { JobDetailsSkillsCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-skills/config/types';
import { JobDetailsInfoCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-info/config/types';
import { JobDetailsTagsCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-tags/config/types';
import { FactoryComponentKey } from 'site-editor/module/content-editor/config/components';
import { JobDetailsSimilarJobsCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-similar-jobs/config/types';
import { JobDetailsTitleCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-title/config/types';
import { RecommendedJobsWidgetCustomizationParams } from 'minimal/component/recommended-jobs-widget/config/types';
import { emptyParams as emptyRecommendedJobsWidgetParams } from 'minimal/component/recommended-jobs-widget/config/emptyParams';
import { emptyParamsCookiePreferenceLink } from 'site-editor/module/content-editor/component/cc-cookie-preference-link-editor/config/emptyParams';
import { CookiePreferenceLinkCustomizationParams } from 'minimal/component/cookie-preference-link/config/types';
import { CookieConsentModalTitleCustomizationParams } from 'cookie-consent/components/cookie-consent-modal-title/config/types';
import { CookieConsentModalPolicyCustomizationParams } from 'cookie-consent/components/cookie-consent-modal-policy/config/types';
import { CookieConsentModalMessageCustomizationParams } from 'cookie-consent/components/cookie-consent-modal-message/config/types';
import { CookieConsentModalActionsCustomizationParams } from 'cookie-consent/components/cookie-consent-modal-actions/config/types';
import { CookieConsentModalCategoriesCustomizationParams } from 'cookie-consent/components/cookie-consent-modal-categories/config/types';
import { emptyParams as emptyJobDetailsJobFitParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-job-fit/config/emptyParams';
import { JobDetailsJobFitCustomizationParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-job-fit/config/types';
import { CategoryTilesCustomizationParams } from 'minimal/component/category-tiles/config/types';
import { emptyParams as emptyCategoryTilesParams } from 'minimal/component/category-tiles/config/emptyParams';

type BackgroundParams = {
    backgroundColor: string;
    backgroundImage: string;
    backgroundVideo: string;
    backgroundParallax: boolean;
};

export type SectionParams = BackgroundParams & {
    column: string;
    cssClass: string;
};

export type RowParams = {
    columnLayout: string; // TODO: add all possible layouts instead of string
};

export type ColumnParams = BackgroundParams;

export type StructureComponentParams = {
    'cc-section': SectionParams;
    'cc-row': RowParams;
    'cc-column': ColumnParams;
};

export type ContentComponentsParams = {
    'cc-rule': CommonParams & {
        width: number;
        align: 'left' | 'center' | 'right';
        backgroundColor: string | null;
        lineColor: string | null;
    };

    'cc-image': CommonParams & {
        desktopSrc: string;
        mobileSrc: string;
        desktopWidth: string;
        desktopAlt: string;
        mobileWidth: string;
        mobileAlt: string;
        align: 'left' | 'center' | 'right';
        useDesktopSrcAsMobile: boolean;
        linkUrl: string;
        openInNewTab: boolean;
    };
    'cc-space': CommonParams & {
        height: number;
    };
    'cc-button': CommonParams & {
        buttonType: string; // TODO: 'button' | 'link';
        buttonStyle: string; // TODO: 'primary' | 'secondary';
        trackClicks: boolean;
        destination: string; // TODO: specify
        externalURL: string | null;
        openInNewTab: boolean;
        backgroundColor: string | null;
        align: 'left' | 'center' | 'right';
    };
    'cc-video': CommonParams & {
        src: string;
        width: string;
        aspectRatio: string;
        autoplay: boolean;
        align: 'left' | 'center' | 'right';
    };
    'cc-job-list': CommonParams & {
        criteria: string;
        limit: number;
        jobListType: 'custom-criteria' | 'relevant-to-user';
        template: string;
        viewMoreLabel: string;
    };

    'cc-talent-community-button': CommonParams & {
        label: string;
        align: 'left' | 'center' | 'right';
    };

    'cc-minimal-search-box-compact': CommonParams & {
        backgroundColor: string | null;
        foregroundColor: string | null;
        fontColor: string | null;
    };

    'cc-headline': CommonParams;
    'cc-paragraph': CommonParams;
    'cc-linkedin-connections-widget': CommonParams;
    'cc-custom-html': CommonParams;
    'cc-minimal-scroll-indicator': CommonParams;
    'cc-minimal-search-categories': CommonParams;
    'cc-splash-mohegan-view-all-jobs': CommonParams;
    'cc-recommended-jobs-widget': RecommendedJobsWidgetCustomizationParams;
    'cc-category-tiles': CategoryTilesCustomizationParams;
    'cc-search-results': SearchResultsCustomizationParams;
    'cc-search-filters-panel': FiltersCustomizationParams;
    'cc-search-bar': SearchBarCustomizationParams;
    'cc-job-details-title': JobDetailsTitleCustomizationParams;
    'cc-job-details-location': JobDetailsLocationCustomizationParams;
    'cc-job-details-description': JobDetailsDescriptionCustomizationParams;
    'cc-job-details-organization-description': JobDetailsDescriptionCustomizationParams;
    'cc-job-details-responsibilities': JobDetailsDescriptionCustomizationParams;
    'cc-job-details-qualifications': JobDetailsDescriptionCustomizationParams;
    'cc-job-details-corporate-description': JobDetailsDescriptionCustomizationParams;
    'cc-job-details-apply-button': JobDetailsApplyButtonCustomizationParams;
    'cc-job-details-skills': JobDetailsSkillsCustomizationParams;
    'cc-job-details-media': JobDetailsMediaCustomizationParams;
    'cc-job-details-info': JobDetailsInfoCustomizationParams;
    'cc-job-details-tags': JobDetailsTagsCustomizationParams;
    'cc-job-details-similar-jobs': JobDetailsSimilarJobsCustomizationParams;
    'cc-cookie-preference-link': CookiePreferenceLinkCustomizationParams;
    'cc-cookie-consent-modal-title': CookieConsentModalTitleCustomizationParams;
    'cc-cookie-consent-modal-policy': CookieConsentModalPolicyCustomizationParams;
    'cc-cookie-consent-modal-message': CookieConsentModalMessageCustomizationParams;
    'cc-cookie-consent-modal-actions': CookieConsentModalActionsCustomizationParams;
    'cc-cookie-consent-modal-categories': CookieConsentModalCategoriesCustomizationParams;
    'cc-job-details-job-fit': JobDetailsJobFitCustomizationParams;
};

export type ComponentsParams = StructureComponentParams & ContentComponentsParams;

export const DEFAULT_PARAMS_MAP: ComponentsParams = {
    'cc-section': {
        column: '',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        backgroundImage: '',
        backgroundVideo: '',
        backgroundParallax: false,
        cssClass: '',
    },

    'cc-row': {
        columnLayout: '1',
    },

    'cc-column': {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        backgroundImage: '',
        backgroundVideo: '',
        backgroundParallax: false,
    },

    'cc-headline': {
        ...EMPTY_COMMON_PARAMS,
    },

    'cc-paragraph': {
        ...EMPTY_COMMON_PARAMS,
    },

    'cc-rule': {
        ...EMPTY_COMMON_PARAMS,
        width: 1,
        align: 'left',
        backgroundColor: null,
        lineColor: null,
    },

    'cc-image': {
        ...EMPTY_COMMON_PARAMS,
        desktopSrc: '',
        mobileSrc: '',
        desktopWidth: '',
        desktopAlt: '',
        mobileWidth: '',
        mobileAlt: '',
        align: 'left',
        useDesktopSrcAsMobile: true,
        linkUrl: '',
        openInNewTab: true,
    },

    'cc-space': {
        ...EMPTY_COMMON_PARAMS,
        height: 50,
    },

    'cc-button': {
        ...EMPTY_COMMON_PARAMS,
        buttonType: 'button',
        buttonStyle: 'primary',
        trackClicks: false,
        destination: 'external',
        externalURL: null,
        openInNewTab: true,
        backgroundColor: null,
        align: 'center',
    },

    'cc-video': {
        ...EMPTY_COMMON_PARAMS,
        src: '',
        width: '',
        aspectRatio: '16:9',
        autoplay: false,
        align: 'left',
    },

    'cc-job-list': {
        ...EMPTY_COMMON_PARAMS,
        criteria: JSON.stringify([]),
        limit: 3,
        jobListType: CUSTOM_CRITERIA,
        template: 'title-and-description',
        viewMoreLabel: i18n('custom-content.job-list-view-more'),
    },

    'cc-recommended-jobs-widget': emptyRecommendedJobsWidgetParams,
    'cc-category-tiles': emptyCategoryTilesParams,

    'cc-talent-community-button': {
        ...EMPTY_COMMON_PARAMS,
        label: i18n('custom-content.cc-talent-community-button-default-label'),
        align: 'center',
    },

    'cc-linkedin-connections-widget': {
        ...EMPTY_COMMON_PARAMS,
    },

    'cc-minimal-search-box-compact': {
        ...EMPTY_COMMON_PARAMS,
        backgroundColor: null,
        foregroundColor: null,
        fontColor: null,
    },

    'cc-custom-html': {
        ...EMPTY_COMMON_PARAMS,
    },
    'cc-minimal-scroll-indicator': {
        ...EMPTY_COMMON_PARAMS,
    },
    'cc-minimal-search-categories': {
        ...EMPTY_COMMON_PARAMS,
    },
    'cc-splash-mohegan-view-all-jobs': {
        ...EMPTY_COMMON_PARAMS,
    },

    'cc-search-results': emptySearchResultsParams,
    'cc-search-filters-panel': emptyFiltersParams,
    'cc-search-bar': emptySearchBarParams,
    'cc-job-details-title': emptyJobDetailsTitleParams,
    'cc-job-details-location': emptyJobDetailsLocationParams,
    'cc-job-details-description': emptyJobDetailsDescriptionParams,
    'cc-job-details-organization-description': emptyJobDetailsDescriptionParams,
    'cc-job-details-responsibilities': emptyJobDetailsDescriptionParams,
    'cc-job-details-qualifications': emptyJobDetailsDescriptionParams,
    'cc-job-details-corporate-description': emptyJobDetailsDescriptionParams,
    'cc-job-details-apply-button': emptyJobDetailsApplyButtonParams,
    'cc-job-details-media': emptyJobDetailsMediaParams,
    'cc-job-details-skills': emptyJobDetailsSkillsParams,
    'cc-job-details-info': emptyJobDetailsInfoParams,
    'cc-job-details-tags': emptyJobDetailsTagsParams,
    'cc-job-details-similar-jobs': emptyJobDetailsSimilarJobsParams,
    'cc-cookie-preference-link': emptyParamsCookiePreferenceLink,
    'cc-cookie-consent-modal-title': emptyCookieConsentModalTitleParams,
    'cc-cookie-consent-modal-policy': emptyCookieConsentModalPolicyParams,
    'cc-cookie-consent-modal-message': emptyCookieConsentModalMessageParams,
    'cc-cookie-consent-modal-actions': emptyCookieConsentModalActionsParams,
    'cc-cookie-consent-modal-categories': emptyCookieConsentModalCategoriesParams,
    'cc-job-details-job-fit': emptyJobDetailsJobFitParams,
} as const;

const defaultParamsFactory = {
    create(componentType: FactoryComponentKey): ComponentsParams[FactoryComponentKey] {
        return DEFAULT_PARAMS_MAP[componentType] || {};
    },
};

export default defaultParamsFactory;
