import { components } from 'knockout';

import '../binding/applyStyles';
import '../binding/sticky';
import 'ce-common/binding/clickOutside';
import 'ce-common/binding/escape';
import '../component/search-facet/component';
import '../component/search-facet-horizontal/component';
import '../component/search-facet-vertical/component';
import '../component/search-facet-item/component';
import '../component/search-sorting-item/component';
import '../component/search-sorting/component';
import '../component/search-sorting-selector/component';
import '../component/search-filters-panel/component';
import '../component/search-no-results/component';
import '../component/search-result-item/component';
import '../component/search-results';
import '../component/search-box-compact/component';
import '../component/search-bar/component';
import '../component/search-categories/component';
import '../component/search-radius';
import '../component/search-radius-zip-code/component';
import '../component/search-pagination/component';
import '../component/talent-community-tile/component';
import '../component/search-jobs-to-candidate/component';
import '../component/search-box-zipcode/component';
import '../component/search-countries/component';
import '../component/search-box-geo-location';
import '../component/search-breadcrumbs/component';
import '../component/mode-menu/component';
import '../component/custom-search/component';
import './routing';
import '../component/search-recruiting-location/component';
import '../component/search-filters-horizontal/component';
import '../component/search-filters-vertical/component';
import '../component/search-filters-mobile/component';
import '../component/search-facet-item-vertical/component';
import '../component/search-breadcrumbs-vertical/component';
import '../component/search-filters-panel-vertical/component';
import '../component/search-filters-panel-horizontal/component';
import '../component/search-facet-dropdown/component';
import '../component/search-results-toggle-button';

import ViewModel from '../SearchViewModel';
import template from '../search.html';

components.register('search', { viewModel: ViewModel, template });
