import { register } from 'ce-common/service/templateRepository';

import pinCodeTemplate from '../templates/talent-community-pin-code-required.html';
import signInTemplate from '../templates/talent-community-sign-in.html';
import userAuthorizedTemplate from '../templates/talent-community-user-authorized.html';
import userExistTemplate from '../templates/talent-community-user-exist.html';
import userSubscribedTemplate from '../templates/talent-community-user-subscribed.html';
import concurentTabIssueTemplate from '../templates/talent-community-concurent-tab-issue.html';
import dateOfBirthCheckTemplate from '../templates/talent-community-date-of-birth-check.html';
import lastNameCheckTemplate from '../templates/talent-community-last-name-check.html';

register('talent-community-pin-code-required', pinCodeTemplate);
register('talent-community-sign-in', signInTemplate);
register('talent-community-user-authorized', userAuthorizedTemplate);
register('talent-community-user-exist', userExistTemplate);
register('talent-community-user-subscribed', userSubscribedTemplate);
register('talent-community-concurent-tab-issue', concurentTabIssueTemplate);
register('talent-community-date-of-birth-check', dateOfBirthCheckTemplate);
register('talent-community-last-name-check', lastNameCheckTemplate);
