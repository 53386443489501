import AbstractProfileItem from './AbstractProfileItem';

const profileItemPrototype = {
    id: null,
    action: 'CREATE',
    flowVersionId: null,
    sectionId: null,
    contentTypeId: null,
    contentItemId: null,
    languageId: null,
    language: null,
    writingLevelId: null,
    speakingLevelId: null,
    readingLevelId: null,
    nativeSpeakerFlag: null,
    ableToTranslateFlag: null,
    ableToTeachFlag: null,
    evaluatedOn: null,
    evaluationType: null,
    evaluationLocation: null,
    comments: null,
    readingModelId: null,
    writingModelId: null,
    speakingModelId: null,
};

export default class CandidateLanguage extends AbstractProfileItem {

    constructor(data) {
        super(profileItemPrototype, data);
    }

    clear() {
        super.clear(profileItemPrototype, 'languageId');
    }

}
