import { observable } from 'knockout';
import tokenService from 'candidate-verification/service/token';
import jobAlertsService from '../../../../../job-alerts/service/jobAlerts';
import unverifiedService from 'candidate-verification/service/unverified';
import appConfig from 'app/model/config';
import candidate from 'apply-flow/model/candidate';
import agreementsService from '../../service/agreements';

export default class JobAlertsOptInViewModel {

    constructor() {
        this.isCheckboxVisible = observable(false);
        this._getCandidateJobAlertsPreferences();
        this.isChecked = candidate.subscribeToJobAlert;
    }

    _getCandidateNumber() {
        let { candidateNumber } = tokenService.get();

        if (!candidateNumber) {
            candidateNumber = unverifiedService.getUnverifiedCandidateId();
        }

        return candidateNumber;
    }

    _getCandidateJobAlertsPreferences() {
        const candidateNumber = this._getCandidateNumber();

        if (!candidateNumber) {
            this.isCheckboxVisible(true);
            candidate.hasEmptySitePreferences(true);
        } else {
            jobAlertsService.getPreferences(candidateNumber)
                .then((sitesPreferences) => {
                    this._setCheckboxVisibility(sitesPreferences);
                    this._setCandidateHasEmptySitePreferences(sitesPreferences);
                });
        }
    }

    _setCheckboxVisibility(sitePreferences) {
        if (!sitePreferences.candidateId
                || !agreementsService.isJobAlertsOptedIn(sitePreferences)) {
            this.isCheckboxVisible(true);
        }
    }

    _setCandidateHasEmptySitePreferences(sitesPreferences) {
        if (!Object.keys(sitesPreferences).length) {
            candidate.hasEmptySitePreferences(true);
        } else {
            const sitePreferences = sitesPreferences.siteTCPreferences
                .filter(p => p.siteNumber === appConfig.siteNumber).shift();

            if (!sitePreferences) {
                candidate.hasEmptySitePreferences(true);
            }
        }
    }

}