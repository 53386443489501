import mapping from 'knockout-mapping';

const rawApplication = {
    candidateNumber: null,
    jobId: null,
    eSignature: {
        fullName: '',
    },
    referralId: null,
    shareId: null,
    flowId: null,
    flowVersionId: null,
    legalDescriptionVersionId: null,
    esignDescriptionVersionId: null,
    status: null,
    submissionDate: null,
    jobTitle: null,
    jobLocation: null,
    jobSecondaryLocations: [],
    isConfirmed: false,
    isActive: false,
    siteNumber: null,
    isPipeline: false,
    isPipelineApplicationActive: false,
    isHiring: false,
    pipelineSubmissionId: null,
    submissionId: null,
    hiringJobApplications: [],
    currentPhaseId: null,
    currentStateId: null,
    hasAssessment: false,
    hasTaxCredit: false,
    isActionInProgress: false,
    secondarySubmissions: [],
    scheduleInterviews: [],
    unscheduledInterviewRequests: [],
    preferredLocations: [],
    siteName: null,
    assessmentLinks: [],
    backgroundCheckLinks: [],
    workplaceType: '',
    workplaceTypeCode: null,
};

function Application(data) {
    mapping.fromJS(rawApplication, {}, this);

    if (data) {
        mapping.fromJS(data, {}, this);
    }
}

export default Application;