import SupportingDocumentsUploadButtonViewModel from './SupportingDocumentsUploadButtonViewModel';
import { RESUME } from '../../config/attachmentCategories';

export default class ResumeUploadButtonViewModel extends SupportingDocumentsUploadButtonViewModel {

    constructor(params) {
        super(params);

        this.isResumeButton(true);
        this.categoryId = RESUME;
        this.dropdownLabel = 'apply-flow.section-more-about-you.drop-cv-here';
        this.attachmentChangedLabel = 'apply-flow.section-more-about-you.cv-changed-label';
    }

    finderFunction(attachments) {
        return attachments.find(({ isResume }) => isResume());
    }

    getButtonLabel() {
        return 'apply-flow.section-more-about-you.upload-resume';
    }

}