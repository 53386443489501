import { observable } from 'knockout';
import tokenService from 'candidate-verification/service/token';
import candidateVerificationEvents from 'candidate-verification/config/events';

class SignedCandidate {

    isSignedIn = observable(tokenService.isCandidateVerified());

    constructor() {
        candidateVerificationEvents.userSessionChanged.add(() => {
            this.isSignedIn(tokenService.isCandidateVerified());
        });

        candidateVerificationEvents.userSessionRemoved.add(() => {
            this.isSignedIn(false);
        });
    }

}

const instance = new SignedCandidate();

export default instance;