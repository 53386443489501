let instance = null;

class ColorDistributor {

    constructor(colors = 16) {
        this._MAX_COLOR_INDEX = colors - 1;
        this._currentColor = 0;
        this._colorsMap = {};
    }

    next() {
        if (this._currentColor > this._MAX_COLOR_INDEX) {
            this._currentColor = 0;
        }

        return this._currentColor++;
    }

    getColor(id) {
        if (this._colorsMap[id] === undefined) {
            this._colorsMap[id] = this.next();
        }

        return this._colorsMap[id];
    }

    static getInstance() {
        if (instance === null) {
            instance = new ColorDistributor();
        }

        return instance;
    }

}

export default ColorDistributor;