import { getItemActiveSelector } from '../../../config/utils';

type CategoryTilesField =
    | 'wrapper'
    | 'container'
    | 'tileContainer'
    | 'tileTitle'
    | 'categoryTitleOnHover'
    | 'containerOnHover'
    | 'overallTileDisplay'
    | 'overallTileDisplayOnHover'
    | 'categoryTitleHoverStyle'
    | 'categoryTitleStyle';

type CategoryTilesFieldSelector = Record<CategoryTilesField, string>;

export const getCategoryTilesSelectors = (
    wrapperSelector: string,
    tileKey = ''
): CategoryTilesFieldSelector => {
    const key = tileKey ? `.category-tile-${tileKey}` : '';

    return {
        wrapper: `${wrapperSelector}.category-tiles-wrapper`,
        container: `${wrapperSelector} .category-tiles`,
        tileContainer: `${wrapperSelector} .category-tiles .category-tile`,
        tileTitle: `${wrapperSelector} .category-tiles .category-tile${key}`,
        categoryTitleOnHover: `${wrapperSelector} .category-tiles .category-tile${key}:hover`,
        containerOnHover: `${wrapperSelector} .category-tiles:hover`,
        overallTileDisplayOnHover: [
            getItemActiveSelector(`${wrapperSelector} .category-tiles .category-tile${key}`, ''),
        ].join(','),
        overallTileDisplay: [`${wrapperSelector} .category-tiles .category-tile${key}`].join(','),
        categoryTitleStyle: `${wrapperSelector} .category-tiles .category-tile${key} .category-tile__title`,
        categoryTitleHoverStyle: `${wrapperSelector} .category-tiles .category-tile${key} .category-tile__title:hover`,
    };
};
