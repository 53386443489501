// TODO: check in project where service can be used
export function mapYesNoToBoolean(string) {
    return string === 'Y';
}

export function mapBooleanToYesNo(boolean) {
    return boolean ? 'Y' : 'N';
}

export function mapTrueFalseStringToBoolean(string) {
    return string === 'true';
}

export function mapBooleanToTrueFalseString(boolean) {
    return String(boolean);
}