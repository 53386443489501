import { observable } from 'knockout';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';
import { EMAIL, SMS } from '../../../../candidate-verification/config/verificationMethods';
import duplicateCheck from '../../../../candidate-verification/service/duplicateCheck';
import EmailEditFormElement from '../element/EmailEditFormElement';
import PhoneEditFormElement from '../element/PhoneEditFormElement';
import ValidatableElement from 'core/form/element/ValidatableElement';
import appConfig from 'app/model/config';
import PersonalInformationForm from './PersonalInformationForm';
import i18n from 'core/i18n/i18n';

const USE_FOR_COMMUNICATION_LABEL = i18n('candidate-self-service.communication-channel.use-for-communication');

const AUTOCOMPLETE = {
    title: 'honorific-prefix',
    firstName: 'given-name',
    middleNames: 'additional-name',
    lastName: 'family-name',
    suffix: 'honorific-suffix',
    phoneNumber: 'tel',
    email: 'email',
};

export default Object.create(formBuilder, {
    createForm: {
        value(params) {
            const { universalElements, emailElement, phoneElement, candidate, preferredTerritoryCode, isTCFlow } =
                params;

            const formElements = {
                elements: universalElements,
            };

            this.isTCFlow = isTCFlow;

            formElements.elements = formElements.elements.map(element => this.createFormElement(element));

            if (emailElement && phoneElement) {
                const contactFormElements = candidate.isNew()
                    ? this._composeNewContactFields(candidate, emailElement, phoneElement, preferredTerritoryCode)
                    : this._composeReturningContactFields(
                        candidate, emailElement, phoneElement, preferredTerritoryCode);

                contactFormElements.forEach(field => formElements.elements.push(field));
            }

            const {
                emailVerifiedFlag,
                emailPreferredFlag,
                phoneVerifiedFlag,
                phonePreferredFlag,
            } = candidate.basicInformation;

            const form = new PersonalInformationForm(formElements, {
                candidateId: candidate.id(),
                candidate,
                emailVerifiedFlag,
                emailPreferredFlag,
                phoneVerifiedFlag,
                phonePreferredFlag,
            });

            this._addAutoCompleteAttribute(form.elements());

            return form;
        },
    },

    _addAutoCompleteAttribute: {
        value(formElements) {
            formElements.forEach(formElement =>
                formElement.attributes('autocomplete', AUTOCOMPLETE[formElement.name()]));
        },
    },

    _composeNewContactFields: {
        value(candidate, emailElement, phoneElement, preferredTerritoryCode) {
            const { verificationMethod } = candidate.basicInformation;

            const [emailFormElement, emailCommunicationCheckbox, phoneFormElement,
                phoneCommunicationCheckbox, phoneFeesWarning] =
                this._composeContactFields({
                    candidate,
                    emailElement,
                    phoneElement,
                    preferredTerritoryCode,
                    emailVerifiedFlag: verificationMethod() === EMAIL,
                    phoneVerifiedFlag: verificationMethod() === SMS,
                });

            const emailFields = [
                emailFormElement,
                emailCommunicationCheckbox,
            ];

            const phoneFields = [
                phoneFormElement,
                phoneCommunicationCheckbox,
                phoneFeesWarning,
            ];

            return verificationMethod() === EMAIL
                ? [...emailFields, ...phoneFields]
                : [...phoneFields, ...emailFields];
        },
    },

    _composeReturningContactFields: {
        value(candidate, emailElement, phoneElement, preferredTerritoryCode) {
            const { emailVerifiedFlag, phoneVerifiedFlag } = candidate.basicInformation;

            const [emailFormElement, emailCommunicationCheckbox, phoneFormElement,
                phoneCommunicationCheckbox, phoneFeesWarning] =
            this._composeContactFields({
                candidate,
                emailElement,
                phoneElement,
                preferredTerritoryCode,
                emailVerifiedFlag: emailVerifiedFlag(),
                phoneVerifiedFlag: phoneVerifiedFlag(),
            });


            return [
                emailFormElement,
                emailCommunicationCheckbox,
                phoneFormElement,
                phoneCommunicationCheckbox,
                phoneFeesWarning,
            ];
        },
    },

    _composeContactFields: {
        value({ candidate, emailElement, phoneElement, preferredTerritoryCode, emailVerifiedFlag, phoneVerifiedFlag }) {
            const { email } = candidate.basicInformation;

            const candidateEmail = email();

            const { phone: { countryCode, areaCode, number, legislationCode } } = candidate.basicInformation;

            const candidatePhone = {
                countryCode: countryCode(),
                areaCode: areaCode(),
                number: number(),
                legislationCode: legislationCode(),
            };

            const emailFormElement = this._createEmailFormElement({
                isDisabled: emailVerifiedFlag,
                emailElement,
                candidateEmail,
            });

            const emailCommunicationCheckbox = this._createEmailCommunicationChannelCheckbox();

            const phoneFormElement = this._createPhoneFormElement({
                isDisabled: phoneVerifiedFlag,
                phoneElement,
                candidatePhone,
                preferredTerritoryCode,
            });

            const phoneCommunicationCheckbox = this._createPhoneCommunicationChannelCheckbox();
            const phoneFeesWarning = this._createPhoneCommunicationChannelWarning('phoneFeesWarning');

            return [
                emailFormElement,
                emailCommunicationCheckbox,
                phoneFormElement,
                phoneCommunicationCheckbox,
                phoneFeesWarning,
            ];
        },
    },

    _createEmailFormElement: {
        value({ isDisabled, emailElement, candidateEmail }) {
            const emailFormElement = new EmailEditFormElement('email');

            emailFormElement.origin = candidateEmail;

            emailFormElement
                .label(emailElement.label)
                .validators(emailElement.validators);

            if (emailElement.attributes.required) {
                emailFormElement.initiallyRequired(true);
            }

            this._addEmailServiceCallValidator(emailFormElement, candidateEmail);

            const errorValidatorMessage = this._isPhoneVerificationEnabled()
                ? 'validators.purge-email-error'
                : 'validators.purge-email-error-no-sms-communication';

            emailFormElement.addValidator('error', {
                message: errorValidatorMessage,
                error: 'IRC_CAND_NO_ALT_PREF_CHANNEL',
                source: emailFormElement.errorCode,
            });

            emailFormElement.addValidator('equalsTo', {
                message: 'candidate-self-service.communication-channel.edit-mode-warning',
                source: emailFormElement.editMode,
                valueToCompare: false,
            });

            if (isDisabled) {
                emailFormElement.removeValidator(emailFormElement.getValidator('required'));
                emailFormElement.isDisabled(true);
            }

            return emailFormElement;
        },
    },

    _createPhoneFormElement: {
        value({ isDisabled, phoneElement, candidatePhone, preferredTerritoryCode }) {
            let phoneFormElement;

            if (this._isPhoneVerificationEnabled()) {
                phoneFormElement = new PhoneEditFormElement('phoneNumber');
                phoneFormElement.registerModel(candidatePhone);

                phoneFormElement.addValidator('error', {
                    message: 'validators.purge-phone-error',
                    error: 'IRC_CAND_NO_ALT_PREF_CHANNEL',
                    source: phoneFormElement.errorCode,
                });

                phoneFormElement.addValidator('equalsTo', {
                    message: 'candidate-self-service.communication-channel.edit-mode-warning',
                    source: phoneFormElement.editMode,
                    valueToCompare: false,
                });

                if (phoneElement.attributes.required) {
                    phoneFormElement.initiallyRequired(true);
                }
            } else {
                phoneFormElement = formElementFactory.create(phoneElement.type, 'phoneNumber');
                phoneFormElement.isVerificationInProgress = observable(false);
                phoneFormElement.newCandidate = observable(!isDisabled);

                if (!isDisabled) {
                    phoneFormElement.attributes({
                        preferredTerritoryCode,
                    });
                }
            }

            phoneFormElement.origin = candidatePhone;

            phoneFormElement
                .label(phoneElement.label)
                .validators(phoneElement.validators)
                .attributes({ id: 'phoneNumber', autocomplete: 'none' });

            if (this._isPhoneVerificationEnabled() && isDisabled) {
                const requiredValidator = phoneFormElement.getValidator('required');

                if (requiredValidator) {
                    phoneFormElement.removeValidator(phoneFormElement.getValidator('required'));
                }

                phoneFormElement.isDisabled(true);
            }

            return phoneFormElement;
        },
    },

    _addEmailServiceCallValidator: {
        value(emailFormElement, elementValue) {
            emailFormElement.addValidator('serviceCall', {
                serviceCall: duplicateCheck.checkEmail,
                exclude: value => duplicateCheck.compareEmail(value, elementValue),
            });
        },
    },

    _isPhoneVerificationEnabled: {
        value() {
            return appConfig.getSettingByKey('SMS_ENABLED_EXTERNAL_CANDIDATES') === 'true' && !this.isTCFlow;
        },
    },

    _createEmailCommunicationChannelCheckbox: {
        value() {
            const element = formElementFactory.create('checkbox', 'emailPreferredFlag')
                .label(USE_FOR_COMMUNICATION_LABEL);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

    _createPhoneCommunicationChannelCheckbox: {
        value() {
            const element = formElementFactory.create('checkbox', 'phonePreferredFlag')
                .label(USE_FOR_COMMUNICATION_LABEL);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

    _createPhoneCommunicationChannelWarning: {
        value(name) {
            const warningMessageLabel = i18n('apply-flow.candidate-verification.phone-fees-info');

            const element = new ValidatableElement(name);

            element.component('communication-channel-warning');
            element.label(warningMessageLabel);

            if (!this._isPhoneVerificationEnabled()) {
                element.isHidden = () => true;
            }

            return element;
        },
    },

});