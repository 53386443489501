import Signal from 'signals';

export default {
    resumeParsingSuccessful: new Signal.Signal(),
    headerUpdated: new Signal.Signal(),
    resumeReuploadEvent: new Signal.Signal(),
    isReuploadProgressStatus: new Signal.Signal(),
    showAdditionalFilterMessage: new Signal.Signal(),
    showOdaPopup: new Signal.Signal(),
};
