import { notReachable } from 'app/types/utils';
import { getStyle, isStyleEmpty, STYLES_SEPARATOR } from './getStyle';
import { ObservableParams } from '../observableParams/paramsToObservable';
import { wrapBySelector } from './wrapBySelector';

export type TypographyField =
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontStyle'
    | 'fontWeight'
    | 'textAlign'
    | 'lineHeight';

export type Typography = Record<TypographyField, string | null>;
export type TypographyObservableParams = ObservableParams<Typography>;

export const getTypographyStyles = (typography: Partial<TypographyObservableParams>): string => {
    const fields = (Object.keys(typography) as TypographyField[]) || {};

    return fields
        .map((field) => {
            const value = typography[field]?.();

            if (!value || isStyleEmpty(value)) {
                return null;
            }

            switch (field) {
                case 'color':
                case 'fontFamily':
                case 'fontSize':
                case 'fontStyle':
                case 'fontWeight':
                case 'textAlign':
                case 'lineHeight':
                    return getStyle(field, value);

                default:
                    return notReachable(field);
            }
        })
        .filter((style) => !!style)
        .join(STYLES_SEPARATOR);
};

export const getTypographyCss = ({
    selector,
    typography,
}: {
    selector: string;
    typography: Partial<TypographyObservableParams>;
}): string => {
    const typographyCss = getTypographyStyles(typography);

    return wrapBySelector(selector, typographyCss);
};
