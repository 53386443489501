// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapping from 'knockout-mapping';
import { FacetsModel } from 'search/module/search-results/module/facet/config/types';

type SiteSettings = {
    LOCATIONS_FACET_ENABLED: string;
    WORK_LOCATIONS_FACET_ENABLED: string;
    WORKPLACE_TYPES_FACET_ENABLED: string;
    CATEGORIES_FACET_ENABLED: string;
    TITLES_FACET_ENABLED: string;
    ORGANIZATIONS_FACET_ENABLED: string;
    POSTING_DATES_FACET_ENABLED: string;
    FACET_ORDER: string;
};

export const DEFAULT_FACETS_SETTINGS = {
    LOCATIONS_FACET_ENABLED: 'true',
    WORK_LOCATIONS_FACET_ENABLED: 'false',
    WORKPLACE_TYPES_FACET_ENABLED: 'false',
    CATEGORIES_FACET_ENABLED: 'true',
    TITLES_FACET_ENABLED: 'true',
    ORGANIZATIONS_FACET_ENABLED: 'false',
    POSTING_DATES_FACET_ENABLED: 'true',
    FACET_ORDER:
        'locationsFacet,workLocationsFacet,workplaceTypesFacet,titlesFacet,categoriesFacet,organizationsFacet,postingDatesFacet',
};

export const addNewFacetToFacetOrderList = (
    defaultSetting: SiteSettings,
    siteSettings: SiteSettings
): string | undefined => {
    const facetOrderList = siteSettings?.FACET_ORDER?.split(',');
    const defaultfacetOrderList = defaultSetting?.FACET_ORDER?.split(',');

    if (
        !facetOrderList ||
        !defaultfacetOrderList ||
        facetOrderList?.length === defaultfacetOrderList?.length
    ) {
        return;
    }

    const missingFacetList = defaultfacetOrderList.reduce(
        (acc: Record<string, string>, facet: string, index: number) => {
            if (!facetOrderList.includes(facet)) {
                acc[index] = facet;
            }

            return acc;
        },
        {}
    );

    Object.entries(missingFacetList).forEach(([facetOrder, facetName]) =>
        facetOrderList.splice(parseInt(facetOrder), 0, facetName)
    );

    return facetOrderList.join(',');
};

export function mapFacetsFromRest(siteSettings = {}): {
    locationsEnabled: boolean;
    workLocationsEnabled: boolean;
    workplaceTypesEnabled: boolean;
    categoriesEnabled: boolean;
    organizationsEnabled: boolean;
    titlesEnabled: boolean;
    postingDatesEnabled: boolean;
    facetOrder: string;
} {
    const newfacetOrder = addNewFacetToFacetOrderList(DEFAULT_FACETS_SETTINGS, siteSettings as SiteSettings);

    const settings = Object.assign(DEFAULT_FACETS_SETTINGS, siteSettings);

    if (newfacetOrder) {
        (settings as SiteSettings).FACET_ORDER = newfacetOrder;
    }

    return {
        locationsEnabled: settings.LOCATIONS_FACET_ENABLED === 'true',
        workLocationsEnabled: settings.WORK_LOCATIONS_FACET_ENABLED === 'true',
        workplaceTypesEnabled: settings.WORKPLACE_TYPES_FACET_ENABLED === 'true',
        categoriesEnabled: settings.CATEGORIES_FACET_ENABLED === 'true',
        organizationsEnabled: settings.ORGANIZATIONS_FACET_ENABLED === 'true',
        titlesEnabled: settings.TITLES_FACET_ENABLED === 'true',
        postingDatesEnabled: settings.POSTING_DATES_FACET_ENABLED === 'true',
        facetOrder: settings.FACET_ORDER,
    };
}

export function mapFacetsToRest(facetsModel: FacetsModel): {
    LOCATIONS_FACET_ENABLED: string;
    WORK_LOCATIONS_FACET_ENABLED: string;
    WORKPLACE_TYPES_FACET_ENABLED: string;
    CATEGORIES_FACET_ENABLED: string;
    TITLES_FACET_ENABLED: string;
    ORGANIZATIONS_FACET_ENABLED: string;
    POSTING_DATES_FACET_ENABLED: string;
    FACET_ORDER: string;
} {
    const facets = mapping.toJS(facetsModel);

    return {
        LOCATIONS_FACET_ENABLED: String(facets.locationsEnabled),
        WORK_LOCATIONS_FACET_ENABLED: String(facets.workLocationsEnabled),
        WORKPLACE_TYPES_FACET_ENABLED: String(facets.workplaceTypesEnabled),
        CATEGORIES_FACET_ENABLED: String(facets.categoriesEnabled),
        TITLES_FACET_ENABLED: String(facets.titlesEnabled),
        ORGANIZATIONS_FACET_ENABLED: String(facets.organizationsEnabled),
        POSTING_DATES_FACET_ENABLED: String(facets.postingDatesEnabled),
        FACET_ORDER: facets.facetOrder,
    };
}
