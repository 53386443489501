import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';

function canEnter() {
    notificationsService.errorAfterLoaded(i18n('general.error-token-expired.header'));

    return Promise.reject({ redirectTo: 'home-page' });
}

// fallback for old token framework urls
router.configure({
    'interview-token-expired': {
        url: '/my-profile/interview/token-expired/{action}',
        canEnter,
    },

    'post-apply.resend-token': {
        parent: 'cx',
        url: '/submission/{jobId}/{submissionId}/post-apply-resend/{token}',
        canEnter,
    },

    'job-details.referral-already-applied': {
        parent: 'job-details',
        url: '/referral/{token}/already-applied',
        canEnter,
    },

    'prospect-token-verification.resend-token': {
        parent: 'job-details',
        url: '/prospect-resend/{token}',
        canEnter,
    },

    'offer-token-expired': {
        parent: 'offer',
        url: '/token-expired',
        canEnter,
    },

    'candidate-self-email-change-confirm': {
        url: '/my-profile/{token}/email-change-confirm',
        canEnter,
    },

});