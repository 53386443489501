import candidateModel from 'apply-flow/model/candidate';
import { CERTIFICATION, EDUCATION, LANGUAGE, WORK_PREFERENCE, EXPERIENCE, SKILL } from '../enum/contentTypes';
import { store as storeAttributes } from './profileItemsAttributesRepository';
import { handleAllSettled } from 'core/utils/handleAllSettled';

export default class ProfileItemCompositeService {

    constructor() {
        this.services = {};
    }

    saveAll(candidate) {
        return Promise.allSettled([
            this.save(EXPERIENCE, candidate.experience),
            this.save(EDUCATION, candidate.education),
            this.save(CERTIFICATION, candidate.licensesAndCertificates),
            this.save(LANGUAGE, candidate.languages),
            this.save(WORK_PREFERENCE, candidate.workPreferences),
            this.save(SKILL, candidate.skills),
        ])
            .then(handleAllSettled);
    }

    save(type, profileItems) {
        return this.services[type]
            ? this.services[type].saveAllByType(profileItems)
            : Promise.resolve();
    }

    storeAll(candidate) {
        this.store(EXPERIENCE, candidate.experience);
        this.store(EDUCATION, candidate.education);
        this.store(CERTIFICATION, candidate.licensesAndCertificates);
        this.store(LANGUAGE, candidate.languages);
        this.store(WORK_PREFERENCE, candidate.workPreferences);
        this.store(SKILL, candidate.skills);
    }

    getMappedModel() {
        return {
            [EXPERIENCE]: this._getMappedItems(EXPERIENCE, candidateModel.experience()),
            [EDUCATION]: this._getMappedItems(EDUCATION, candidateModel.education()),
            [CERTIFICATION]: this._getMappedItems(CERTIFICATION, candidateModel.licensesAndCertificates()),
            [LANGUAGE]: this._getMappedItems(LANGUAGE, candidateModel.languages()),
            [WORK_PREFERENCE]: this._getMappedItems(WORK_PREFERENCE, candidateModel.workPreferences()),
            [SKILL]: this._getMappedItems(SKILL, candidateModel.skills()),
        };
    }

    _getMappedItems(contentTypeId, profileItems) {
        if (!this.services[contentTypeId]) {
            return [];
        }

        const mappedItems = profileItems.reduce((allItems, profileItem) => {
            const mappedItem = this.services[contentTypeId].mapToRestWithOperationalData(profileItem);

            return [...allItems, mappedItem];
        }, []);

        return mappedItems;
    }

    store(type, profileItems) {
        if (this.services[type]) {
            this.services[type].storeAllByType(type, profileItems);
        }
    }

    registerService({ type, service, attributes, sectionId }) {
        this.services[type] = service;

        storeAttributes(sectionId, attributes);
    }

}

