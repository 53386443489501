import TimelineTileBuilder from '../model/TimelineTileBuilder';
import TimelineEducationTileStrategy from '../model/TimelineEducationTileStrategy';
import TimelineExperienceTileStrategy from '../model/TimelineExperienceTileStrategy';

export default {
    createExperienceTileBuilder() {
        const strategy = new TimelineExperienceTileStrategy();

        return new TimelineTileBuilder(strategy);
    },

    createEducationTileBuilder() {
        const strategy = new TimelineEducationTileStrategy();

        return new TimelineTileBuilder(strategy);
    },
};