import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { JobDetailsSkillsParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-skills/config/types';
import { PureComputed } from 'knockout';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsSkillsParams;
    id?: string;
};

export class JobDetailsSkillsViewModel extends CustomComponentViewModel<JobDetailsSkillsParams> {
    pageData: PureComputed<JobDetailsPageData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof JobDetailsSkillsParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'titleTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-details__description-header`,
                    typography: this.customizationParams[key],
                });

            case 'categoryTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-details__skills-category`,
                    typography: this.customizationParams[key],
                });

            case 'skillTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-details__skills-skill`,
                    typography: this.customizationParams[key],
                });

            case 'container':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-details__section`,
                    stylingBox: this.customizationParams[key],
                });

            case 'skill':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-details__skills-skill`,
                    stylingBox: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
