import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { searchJobResults } from 'minimal/module/search/model/searchJobResults';
import { DistanceUnit } from 'search/config/types';
import { isDistanceOutOfRadius } from 'search/service/distanceCalculation';
import { getDistanceText } from 'minimal/component/search-results-distance/service/distance';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import { Job } from 'minimal/module/job-details/types';

const DISTANCE_UNITS_NAMES: Record<Exclude<DistanceUnit, null>, string> = {
    MI: i18n('location-bar.dropdown.radius-unit.mi'),
    KM: i18n('location-bar.dropdown.radius-unit.km'),
};

export const getDistanceInfo = (job: Job): DistanceInfo => {
    const distanceUnit = searchJobResults.distanceUnit as DistanceUnit;
    const distanceUnitName = distanceUnit ? DISTANCE_UNITS_NAMES[distanceUnit] : '';

    const distanceStringKey = hasSecondaryLocation(job)
        ? 'search.job-item.distance-multiple-locations'
        : 'search.job-item.distance-single-location';
    const jobDistance = job.distance as string | undefined;
    const inputRadius = getInputRadius();
    const isOutOfRadius = Boolean(isDistanceOutOfRadius(inputRadius, job.distance));

    const distanceText = getDistanceText({
        isDistanceOutOfRadius: isOutOfRadius,
        inputRadius,
        jobDistance,
    });

    return {
        distanceUnit: distanceUnitName,
        distanceStringKey,
        inputRadius,
        isDistanceOutOfRadius: isOutOfRadius,
        distanceText,
    };
};

const getInputRadius = (): string | undefined => {
    const routeParams = router.routeParams() as {
        query?: {
            radius: string;
        };
    };

    return routeParams.query?.radius;
};

const hasSecondaryLocation = (job: Job): boolean => {
    return Boolean(job.secondaryLocations && job.secondaryLocations.length > 0);
};
