import mapping from 'knockout-mapping';

const rawMedia = {
    id: undefined,
    type: undefined,
    url: undefined,
    thumbUrl: undefined,
    title: undefined,
    markers: undefined,
    mediaTypeCode: undefined,
    priority: 3,
};

function Media(data) {
    const mappingOptions = {
        copy: ['markers'],
    };

    mapping.fromJS(rawMedia, mappingOptions, this);

    if (data) {
        mapping.fromJS(data, mappingOptions, this);
    }
}

export default Media;