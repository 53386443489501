import { components } from 'knockout';

import notificationsInstance from 'cx/component/cx-notifications/cxNotifications';
import template from './notifications.html';

components.register('notifications', {
    viewModel: {
        instance: notificationsInstance,
    },
    template,
});
