import FormValidator from 'core/form/validator/Validator';

const MB = 1024 * 1024;
const DEFAULT_LIMIT = 5;

export default class FileSizeFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        if (!this._options.fileSizeLimit) {
            this._options.fileSizeLimit = DEFAULT_LIMIT;
        }

        this._options.limit = this._options.fileSizeLimit * MB;
        this._message = 'validators.file-upload.exceeded-file-size-limit';
    }

    _validate(file, options) {
        if (!file) {
            return true;
        }

        return file.size <= options.limit;
    }

}