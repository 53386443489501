import converterFactory from 'core/l10n/converter/factory';


export default {
    format(time) {
        return this.formatShort(time);
    },

    formatShort(time) {
        return converterFactory.createTimeConverter('short').format(time);
    },

    formatMedium(time) {
        return converterFactory.createTimeConverter('medium').format(time);
    },

    formatLong(time) {
        return converterFactory.createTimeConverter('long').format(time);
    },

    formatFull(time) {
        return converterFactory.createTimeConverter('full').format(time);
    },

    parse(formattedTime) {
        return this.parseShort(formattedTime);
    },

    parseShort(formattedTime) {
        return converterFactory.createTimeConverter('short').parse(formattedTime);
    },

    parseMedium(formattedTime) {
        return converterFactory.createTimeConverter('medium').parse(formattedTime);
    },

    parseLong(formattedTime) {
        return converterFactory.createTimeConverter('long').parse(formattedTime);
    },

    parseFull(formattedTime) {
        return converterFactory.createTimeConverter('full').parse(formattedTime);
    },
};