import ko from 'knockout';

export default class LightCandidate {

    constructor() {
        this.email = ko.observable();
        this.phone = ko.observable();
        this.verificationMethod = ko.observable();
    }

}
