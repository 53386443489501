import { EventShape as Event } from 'cx/module/search/config/types';
import { SearchResultsModel } from './searchResults';

class SearchEventResultsModel extends SearchResultsModel<Event> {
    distanceUnit: string | null;

    constructor() {
        super();
        this.distanceUnit = null;
    }
}

export const searchEventResults = new SearchEventResultsModel();
