import { Store } from 'core/store/Store';
import { Stage } from '../model/Stage';
import { Step } from '../model/Step';
import { DONE } from '../enum/taskStatus';

export const RESET = '[Checklist] Reset';
export const SHOW_DIALOG = '[Checklist] Show dialog';
export const HIDE_DIALOG = '[Checklist] Hide dialog';
export const ADD_STAGE = '[Checklist] Add stage';
export const ADD_STEP = '[Checklist] Add step';
export const UPDATE_STEP_STATUS = '[Checklist] Update step status';

function addStage(state, { name, label }) {
    const stageAlreadyExists = state.stages()
        .some(stage => stage.name() === name);

    if (stageAlreadyExists) {
        return;
    }

    state.stages.push(new Stage({
        name,
        label,
    }));
}

function addStep(state, { stepName, stageName }) {
    const matchedStage = state.stages()
        .find(stage => stageName === stage.name());

    if (!matchedStage) {
        throw new Error(`Unable to add step (${stepName}) to non existing stage (${stageName})`);
    }

    matchedStage.addStep(new Step({
        name: stepName,
        matchedStage,
    }));
}

function updateStepStatus(state, { stageName, stepName, status }) {
    const matchedStage = state.stages()
        .find(stage => stageName === stage.name());

    const matchedStep = matchedStage.steps()
        .find(step => stepName === step.name());

    matchedStep.setStatus(status);
}

function showDialog(state) {
    state.showDialog(true);
}

function hideDialog(state) {
    state.showDialog(false);
}

function reset(state) {
    state.showDialog(false);
    state.stages([]);
}

function getProgress(state) {
    const allSteps = state.stages()
        .map(stage => stage.steps())
        .flat(2);

    const doneSteps = allSteps
        .filter(step => step.status() === DONE);

    return allSteps.length
        ? Math.ceil((doneSteps.length / allSteps.length) * 100)
        : 0;
}

export default new Store({
    state: {
        showDialog: false,
        stages: [],
    },

    mutations: {
        [ADD_STAGE]: addStage,
        [ADD_STEP]: addStep,
        [UPDATE_STEP_STATUS]: updateStepStatus,
        [SHOW_DIALOG]: showDialog,
        [HIDE_DIALOG]: hideDialog,
        [RESET]: reset,
    },

    getters: {
        progress: getProgress,
    },
});
