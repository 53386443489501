import { FunctionComponent } from 'preact';
import { SearchResultsRequisitionFlexField } from 'minimal/module/search/component/search-results/config/types';
import { Job } from 'minimal/module/job-details/types';
import { generateJobInfo } from './service/generateJobInfo';
import { JobInfoDisplayedItem } from 'minimal/module/search/component/search-results/config/types';
import { JobInfoDisplay, JobInfoSeparator } from 'search/config/types';
import { PureComputed } from 'knockout';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { SearchResultItemJobInfoLocation } from './SearchResultItemJobInfoLocation';
import { SearchResultItemJobInfoDistance } from './SearchResultItemJobInfoDistance';
import { JobAttribute } from './config/types';
import { mapDffJobInfoParamToJobAttribute } from './mapper/dffs';

type Props = {
    jobInfoDisplayed: PureComputed<JobInfoDisplayedItem[]>;
    jobInfoDisplay: PureComputed<JobInfoDisplay>;
    job: Job;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
    distanceInfo: DistanceInfo;
    shouldShowDistance: boolean;
    areJobTagsVisible: PureComputed<boolean>;
    viewType: 'listView' | 'tileView';
    dffs: SearchResultsRequisitionFlexField[];
};

export const SearchResultItemJobInfo: FunctionComponent<Props> = ({
    job,
    jobInfoDisplayed,
    jobInfoDisplay,
    jobInfoSeparator,
    distanceInfo,
    shouldShowDistance,
    areJobTagsVisible,
    viewType,
    dffs,
}) => {
    const jobInfoElements = generateJobInfo(job);

    const isAnyTagVisible = () => {
        const { beFirstToApply, hotJobFlag, trendingJob } = job;

        return beFirstToApply || hotJobFlag || trendingJob;
    };

    const filterOutEmptyOrDisabledDistanceItems = (item: JobAttribute) => {
        if (!item) {
            return false;
        }

        if (item.key && !item.value) {
            return false;
        }

        if (item?.key === 'distance' && !shouldShowDistance) {
            return false;
        }

        return true;
    };

    const jobInfoList = jobInfoDisplayed()
        .map(function (item) {
            if (item.startsWith('DFF||')) {
                const [, itemDffContextCode, itemDffSegmentCode] = item.split('||');

                const requisitionDff = dffs.find(
                    (dff) => dff.contextCode === itemDffContextCode && dff.segmentCode === itemDffSegmentCode
                )!;

                return mapDffJobInfoParamToJobAttribute(item, requisitionDff);
            }

            if (jobInfoElements[item]) {
                return jobInfoElements[item];
            }
        })
        .filter(filterOutEmptyOrDisabledDistanceItems);

    return (
        <ul
            class="job-list-item__job-info-items"
            className={`job-list-item__job-info-items job-list-item__job-info-items--${jobInfoSeparator()} 
                job-list-item__job-info-items--${jobInfoDisplay() == 'iconValue' ? 'icon' : 'label'}
                ${!areJobTagsVisible() ? 'job-list-item__job-info-items--tags-visible' : ''}`}
        >
            {jobInfoList.map((item) => (
                <>
                    <li
                        className={`job-list-item__job-info-item 
                    ${
                        areJobTagsVisible() && isAnyTagVisible()
                            ? 'job-list-item__job-info-item--tags-visible'
                            : ''
                    }`}
                    >
                        {jobInfoDisplay() == 'iconValue' && (
                            <div
                                title={item?.translationKey ?? item?.label}
                                aria-label={item?.translationKey ?? item?.label}
                                className={`job-list-item__job-info-icon 
                            job-list-item__job-info-icon--${item?.className}
                            job-list-item__job-info-icon--desktop`}
                            ></div>
                        )}

                        {jobInfoDisplay() == 'iconValue' && (
                            <div
                                title={item?.translationKey ?? item?.label}
                                aria-label={item?.translationKey ?? item?.label}
                                className={`job-list-item__job-info-icon 
                            job-list-item__job-info-icon--${item?.className}-mobile
                            job-list-item__job-info-icon--mobile`}
                            ></div>
                        )}

                        {jobInfoDisplay() == 'labelValue' && (
                            <div
                                className={`job-list-item__job-info-label  
                                ${viewType === 'tileView' ? 'job-grid-item__job-info-label' : ''}
                                job-list-item__job-info-label--${item?.className}`}
                            >
                                {item?.translationKey ?? item?.label}
                            </div>
                        )}

                        {item?.key === 'distance' && (
                            <SearchResultItemJobInfoDistance
                                distanceInfo={distanceInfo}
                                job={job}
                                jobInfoSeparator={jobInfoSeparator}
                            ></SearchResultItemJobInfoDistance>
                        )}

                        {item?.key !== 'distance' && item?.key !== 'location' && (
                            <div
                                className={`job-list-item__job-info-value-container 
                            ${viewType === 'tileView' ? 'job-grid-item__job-info-value-container' : ''}`}
                            >
                                <div class="job-list-item__job-info-value">{item?.value}</div>

                                <div className={`icon-separator icon-separator-${jobInfoSeparator()}`}></div>
                            </div>
                        )}

                        {item?.key === 'location' && (
                            <SearchResultItemJobInfoLocation
                                job={job}
                                jobInfoSeparator={jobInfoSeparator}
                            ></SearchResultItemJobInfoLocation>
                        )}
                    </li>
                </>
            ))}
        </ul>
    );
};
