import DatepickerFormElement from 'core/form/element/Datepicker';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';

export default class Monthpicker extends DatepickerFormElement {

    constructor(...args) {
        super(...args);

        this.layout = (new LocaleBasedDateTimeFormatter(appConfig.siteLang)).getMonthPickerFormat('mm-yy');
    }

}
