import currencyConverter from 'core/l10n/converter/currency';
import dateConverter from 'core/l10n/converter/date';
import dateTimeConverter from 'core/l10n/converter/dateTime';
import numberConverter from 'core/l10n/converter/number';
import percentConverter from 'core/l10n/converter/percent';
import timeConverter from 'core/l10n/converter/time';
import weekDayConverter from 'core/l10n/converter/weekDay';


export default {
    currency: currencyConverter,
    date: dateConverter,
    dateTime: dateTimeConverter,
    number: numberConverter,
    percent: percentConverter,
    time: timeConverter,
    weekDay: weekDayConverter,
};