import assessmentMapper from '../mapper/assessment';
import taxCreditMapper from '../mapper/taxCredit';

export const PARTNER_TYPE_SETTINGS = {
    ORA_ASSESSMENT: {
        getPartnerLink: 'getInlineAssessmentPartnerURL',
        partnerMapper: assessmentMapper.mapFromRest.bind(assessmentMapper),
        translationType: 'assessment',
    },
    ORA_TAXCREDITS: {
        getPartnerLink: 'getInlineTaxCreditPartnerURL',
        partnerMapper: taxCreditMapper.mapFromRest.bind(assessmentMapper),
        translationType: 'tax-credit',
    },
};