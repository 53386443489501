import ko from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class MultiSelectFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.options = ko.observableArray();
        this.component('multiselect-form-element');
    }

}