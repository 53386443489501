import { FunctionComponent } from 'preact';
import { useRef } from 'preact/hooks';
import { useKnockoutBindings } from 'core/react/hooks/useKnockoutBindings';
import { Job } from 'minimal/module/job-details/types';
import { PureComputed } from 'knockout';
import { JobInfoSeparator } from 'search/config/types';

type Props = {
    job: Job;
    jobInfoSeparator: PureComputed<JobInfoSeparator>;
};

export const SearchResultItemJobInfoLocation: FunctionComponent<Props> = ({ job, jobInfoSeparator }) => {
    const { primaryLocation, secondaryLocations, workplaceType, workplaceTypeCode } = job;

    const locationsReference = useRef<HTMLDivElement | null>(null);

    useKnockoutBindings(locationsReference, {
        primaryLocation,
        secondaryLocations,
        workplaceType,
        workplaceTypeCode,
    });

    return (
        <div className={`job-list-item__job-info-value-container`} ref={locationsReference}>
            <div class="job-list-item__job-info-value">
                <posting-locations
                    params="primaryLocation: primaryLocation,
                            secondaryLocations: secondaryLocations,
                            workplaceType: {
                                name: workplaceType,
                                code: workplaceTypeCode,
                            },
                            mode:'tooltip'"
                ></posting-locations>
            </div>
            <div className={`icon-separator-${jobInfoSeparator()}`}></div>
        </div>
    );
};
