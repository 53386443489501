import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import talentCommunityEvents from 'cx/module/talent-community/config/events';
import absoluteRouter from 'app/model/absoluteRouter';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';
import { getFusionCode } from 'custom-content/service/getFusionCode';

export default class TalentCommunityButtonViewModel extends ElementViewModel {

    constructor({ params, mode, lang }, templateButtonClass = 'cx-button') {
        super({ params });

        this.isAdminMode = mode === 'admin';
        this.isEnabled = isTCOptInEnabled() || this.isAdminMode;
        this.languageCode = lang ? lang() : getFusionCode();
        this.label = pureComputed(this._getLabelTranslation, this);
        this.url = this._getUrl();

        this.rootClass = pureComputed(this._calculateRootClass, this);
        this.buttonClass = `${templateButtonClass} ${templateButtonClass}--primary`;
    }

    _calculateRootClass() {
        return `cc-button__wrapper--align-${this.params.align()} ${this.params.cssClass()}`;
    }

    _getLabelTranslation() {
        const langLabel = this.params[`label_${this.languageCode}`];
        const { label } = this.params;

        return langLabel ? langLabel() : label();
    }

    openSignUpComponent() {
        talentCommunityEvents.show.dispatch();
    }

    _getUrl() {
        // In Site Editor button should not point anywhere
        if (this.isAdminMode) {
            return null;
        }

        return absoluteRouter.interpolate({}, 'talent-community');
    }

}
