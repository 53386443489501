import applicationDraftService, { CONTENT_NOT_CHANGED } from './applicationDraft';
import sectionState from '../model/sectionState';
import ApplicationDraft from '../model/ApplicationDraft';
import { applicationDraft, applicationDraftInstance } from './applicationDraftInstance';
import applyFlowEvents from '../config/events';
import appConfig from 'app/model/config';

const DEFAULT_TIMER_INTERVAL = 10;
const MAX_FAILS_COUNT = 3;

export function useDraftData() {
    return applicationDraft && !applicationDraft.isCandidateDataNewer();
}

export class ApplicationDraftMonitor {

    constructor() {
        this.clearDraft();

        const timerIntervalSetting = Number(appConfig.getSettingByKey('ORA_IRC_CE_SAVE_DRAFT_INTRVL'));

        this.timerInterval = (timerIntervalSetting || DEFAULT_TIMER_INTERVAL) * 1000;
        this.isActive = false;
        this.timerId = null;
        this.failsCounter = 0;
        this.saveDraft = this.saveDraft.bind(this);
        this.stop = this.stop.bind(this);
    }

    clearDraft() {
        applicationDraftInstance.clear();
    }

    async saveDraft() {
        if (!this.isActive) {
            return;
        }

        try {
            this.timerId = window.setTimeout(this.saveDraft, this.timerInterval);
            await applicationDraftService.save();
        } catch (error) {
            this._handleDraftError(error);
        }
    }

    setDraft(draft, candidateModel) {
        applicationDraftInstance.set(new ApplicationDraft(draft, candidateModel));
    }

    async start() {
        this.isActive = true;

        const isAllSectionsReady = sectionState.isAllSectionsReady();

        if (isAllSectionsReady()) {
            await this.saveDraft();

            return;
        }

        isAllSectionsReady.subscribeOnce(async () => {
            await this.saveDraft();
        });
    }

    stop() {
        this.isActive = false;
        window.clearTimeout(this.timerId);
    }

    _handleDraftError(error) {
        if (error === CONTENT_NOT_CHANGED) {
            return Promise.resolve();
        }

        this.failsCounter++;

        applyFlowEvents.afterApplicationDraftSave.dispatch();

        if (this.failsCounter >= MAX_FAILS_COUNT) {
            this.stop();

            return Promise.reject('draft aborted');
        }

        return Promise.resolve();
    }

}