import 'ojs/ojdialog';
import './binding/isDialogVisible';
import './binding/moveChatbot';

import { components } from 'knockout';

import ViewModel from './MinimalDialogViewModel';
import template from './minimal-dialog.html';

components.register('minimal-dialog', { viewModel: ViewModel, template });
