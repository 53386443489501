import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import deviceDetector from 'core/device-detector/deviceDetector';

export default class ColumnViewModel extends ElementViewModel {

    constructor({ column, pageData, onMessage }) {
        const { size, params, components } = column;

        super({ params });

        this.size = size;

        // TODO: when it's possible, separate pageData and componentData
        this.components = components.map(component => ({
            ...component,
            pageData,
            onMessage,
        }));

        this.isParallaxEnabled = pureComputed(this._shouldParalaxBeEnabled, this);

        this.backgroundSrc = this.params.backgroundImage()
            ? `url(${this.params.backgroundImage()})`
            : false;
    }

    _shouldParalaxBeEnabled() {
        return Boolean(!deviceDetector.isMobile()
            && this.params.backgroundParallax()
            && this.params.backgroundImage());
    }

}
