import { components } from 'knockout';

import { CookieConsentModalActionsViewModel } from './CookieConsentModalActionsViewModel';
import cookieConsentModalTemplate from './cookie-consent-modal-actions-template.html';
import cookiePreferencesAndPolicyTemplate from './cookie-preferences-and-policy-modal-actions-template.html';
import cookieConsentActionsParentTemplate from './cookie-consent-modal-actions.html';
import { register } from 'ce-common/service/templateRepository';

register('cookie-consent-modal-actions-template', cookieConsentModalTemplate);
register('cookie-consent-preferences-modal-actions-template', cookiePreferencesAndPolicyTemplate);

components.register('cookie-consent-modal-actions', {
    viewModel: CookieConsentModalActionsViewModel,
    template: cookieConsentActionsParentTemplate,
});

components.register('cc-cookie-consent-modal-actions', {
    viewModel: CookieConsentModalActionsViewModel,
    template: cookieConsentActionsParentTemplate,
});
