import 'ojs/ojdialog';

import { components } from 'knockout';

import ViewModel from './ProspectVerificationViewModel';

components.register('prospect-verification', {
    viewModel: ViewModel,
    template: ' ',
});
