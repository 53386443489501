import i18n from 'core/i18n/i18n';
import FormValidator from 'core/form/validator/Validator';

type FileObject = {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
};

type Options = {
    unsupported_extensions: string[];
};

export class UnsupportedFileExtensionFormValidator extends FormValidator {
    private uploadedFileFormatName: string;

    constructor(...args: unknown[]) {
        super(...args);

        this._message = 'validators.file-upload.unsupported-file-type';
        this.uploadedFileFormatName = '';
    }

    getErrorMessage(): string {
        return i18n(this._message, {
            unsupported_extension: '.' + this.uploadedFileFormatName,
        });
    }

    protected _validate(file: FileObject, options: Options): boolean {
        if (!file) {
            return true;
        }

        this.uploadedFileFormatName = (file.name.split('.').pop() as string).toLowerCase();

        const extensions = options.unsupported_extensions.join('|').replaceAll('.', '\\.');

        const expression = `(.+)(${extensions})$`;

        const disallowedFilesPattern = new RegExp(expression, 'i');

        return !disallowedFilesPattern.test(file.name);
    }
}
