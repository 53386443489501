import { PureComputed, Subscription, pureComputed } from 'knockout';
import { CookieConsentViewModel } from './CookieConsentViewModel';
import { DialogDisplaySettings } from 'minimal/component/minimal-dialog/MinimalDialogViewModel';
import {
    cookieConfig,
    hasUserAcceptedAnyCategory,
    hasUserDeclinedAllCategory,
    isCookieConsentVisible,
    setCookieConfigWithDefaultText,
    showCookiePolicyOrPreferenceDialog,
} from 'cx/service/cookieAgreement';
import { COOKIE_CONSENT_WIDGET_TYPE } from '../../types';
import { cookieConsentWidgetData } from 'cx/service/loadCookieConsentPageData';
import { COOKIE_CONSENT_NARROW_STYLE } from 'admin/module/site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import { getSections } from 'app/module/admin/module/site-editor/module/pages/service/pageTypes';

export class CookieConsentModalViewModel extends CookieConsentViewModel {
    displaySettings: PureComputed<DialogDisplaySettings>;
    classModifier: string;
    isCookieConsentVisibleSubscription: Subscription;
    isCookieConsentVisible = isCookieConsentVisible;

    constructor() {
        super({ widgetType: COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET });

        setCookieConfigWithDefaultText();
        this.classModifier = `cookie-consent-modal`;
        this.displaySettings = pureComputed(this.computeDisplaySettings, this);

        this.isCookieConsentVisibleSubscription = this.isCookieConsentVisible.subscribe((value) => {
            if (value) {
                const cookieConsentWidget = cookieConsentWidgetData ?? {
                    sections: getSections(COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET),
                    pageParams: [],
                };

                const pageMappedData = this.loadCustomPage(cookieConsentWidget);

                this.pageData().modalStyle = pureComputed(
                    () => pageMappedData?.params?.style || COOKIE_CONSENT_NARROW_STYLE
                );
            }
        });

        this.isCookieConsentVisible(this.getCookiesBarVisibility());
        this.isCookieConsentVisible.valueHasMutated();
    }

    getCookiesBarVisibility(): boolean {
        return cookieConfig.isCookieConsentEnabled()
            ? !hasUserAcceptedAnyCategory() && !hasUserDeclinedAllCategory()
            : false;
    }

    computeDisplaySettings(): DialogDisplaySettings {
        const { overlayOpacity, overlayStyle, overlayWidth, overlayWidthUnit } =
            this.viewData.customPage()?.params || {};

        return {
            opacity: overlayOpacity ? `${overlayOpacity}` : null,
            alignment: overlayStyle || null,
            width: overlayWidth && overlayWidthUnit ? `${overlayWidth}${overlayWidthUnit}` : null,
        };
    }

    openPolicyDialog(): void {
        showCookiePolicyOrPreferenceDialog();
    }

    dispose(): void {
        this.isCookieConsentVisibleSubscription.dispose();
    }
}
