import ko from 'knockout';
import http from 'core/http/http';
import postMessageService from 'core/post-message/postMessageService';
import deviceDetector from 'core/device-detector/deviceDetector';
import cxEvents from 'cx/config/events';
import StrategyBase from './DownloadStrategyBase';
import StrategyIE from './DownloadStrategyIE';

const Strategy = deviceDetector.isIE11() || deviceDetector.isEdge() ? StrategyIE : StrategyBase;

export default class DownloaderViewModel extends Strategy {

    constructor() {
        super();
        this.fileName = ko.observable();

        postMessageService.add('download', this.messageHandler.bind(this));
        postMessageService.dispatch('ready');
    }

    messageHandler(data) {
        const { fileName, mimeType } = data.fileInfo;

        this.fileName(fileName);

        if (data.fileInfo.url) {
            this.fetchAttachment(data.fileInfo.url, data.httpConfig)
                .then(this.saveAttachment.bind(this, mimeType, fileName))
                .then(cxEvents.loaded.dispatch);
        } else {
            const { fileContents } = data.fileInfo;

            this.displayAttachment(mimeType, fileName, fileContents)
                .then(cxEvents.loaded.dispatch);
        }
    }

    fetchAttachment(url, httpConfig) {
        return http.get(url, httpConfig);
    }

}