type Props = {
    jobFitTileScore: number;
};

const PIPS_BLOCK_MAX_LENGTH = 5;

export class JobFitTilePipsViewModel {
    jobFitTileScore: number;
    tilePipsBlockArray: number[];

    constructor({ jobFitTileScore }: Props) {
        this.jobFitTileScore = jobFitTileScore;

        this.tilePipsBlockArray = Array.from({ length: PIPS_BLOCK_MAX_LENGTH }, (_, index) => index + 1);
    }
}
