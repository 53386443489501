import ko from 'knockout';
import router from 'app/model/router';
import awliWidgetService from '../service/awliWidget';


function createWidgetConfigTag({ clientId, integrationContext, jobId }) {
    const widgetConfigTag = document.createElement('script');

    widgetConfigTag.setAttribute('type', 'IN/AwliWidget');
    widgetConfigTag.setAttribute('data-api-key', clientId);
    widgetConfigTag.setAttribute('data-integration-context', integrationContext);
    widgetConfigTag.setAttribute('data-company-job-code', jobId);

    return widgetConfigTag;
}

const AWLI_WIDGETS = {
    linkedin(params) {
        const widgetConfigTag = createWidgetConfigTag(params);

        widgetConfigTag.setAttribute('data-mode', 'BUTTON_DATA');
        widgetConfigTag.setAttribute('data-color', 'blue');
        widgetConfigTag.setAttribute('data-size', 'large');
        widgetConfigTag.setAttribute('data-callback-method', 'onProfileData');

        return widgetConfigTag;
    },

    awliconfirm(params) {
        const widgetConfigTag = createWidgetConfigTag(params);

        widgetConfigTag.setAttribute('data-mode', 'CONVERSION');

        return widgetConfigTag;
    },
};

ko.bindingHandlers.awliWidget = {
    init(element, valueAccessor) {
        const { profileType, clientId, integrationContext } = valueAccessor();

        const widgetParams = {
            clientId,
            integrationContext,
            jobId: router.routeParams().jobId,
        };

        const widgetConfigurationTag = AWLI_WIDGETS[profileType](widgetParams);

        awliWidgetService.load(clientId, widgetConfigurationTag.getAttribute('data-mode'));

        element.appendChild(widgetConfigurationTag);
    },
};