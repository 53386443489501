import { observable } from 'knockout';
import CustomJsValidationBlockComponentViewModel from '../custom-js-validation/CustomJsValidationBlockComponentViewModel';
import MetadataService from './service/Metadata';
import formBuilder from '../../../../../core/form/formBuilder';
import candidateModel from '../../model/candidate';
import synchronization, { COLLECTIONS } from 'apply-flow/service/synchronization';
import Context from './model/Context';
import { getExtraInformation } from './service/extraInformation';
import applyFlowEvents from '../../config/events';
import { useDraftData } from '../../service/applicationDraftMonitor';

export default class ExtraInformationViewModel extends CustomJsValidationBlockComponentViewModel {

    constructor(params) {
        super(params);

        this.section = params.section;
        this.title = params.title;
        this.model = null;

        this.isMultiRow = observable();

        this._initCustomJsValidation(params.blockProperties.context);
        this._submitFailureEventHandler = applyFlowEvents.refresh.add(this._getEffData.bind(this));

        const metadataService = new MetadataService(params.metadataServiceUrl, params.blockProperties);

        Promise.all([
            metadataService.getContext(),
            this._getCandidateExtraInformation(params.blockProperties.context),
        ])
            .then(this._createCandidateModel.bind(this))
            .then(this._createContextForm.bind(this))
            .catch(console.error);

        this._applyFlowRefreshSub = applyFlowEvents.refresh.add(this._updateExtraInformation.bind(this));
    }

    _createCandidateModel([context, candidateData]) {
        if (!context.formElements.length) {
            this._setBlockReady();

            return Promise.reject('EFF attributes list is empty!');
        }

        if (useDraftData()) {
            const [model] = candidateModel.extraInformation().filter(({ name }) => name === context.name);

            if (model) {
                this.model = model;

                return context;
            }
        }

        if (candidateData) {
            context.link = candidateData.link;
            context.rows = candidateData.rows;
        }

        this.model = new Context(context);
        candidateModel.extraInformation.push(this.model);

        return context;
    }

    _getEffData() {
        const cachedEffData = synchronization.unshiftAll(COLLECTIONS.EFF);

        if (cachedEffData.length) {
            candidateModel.extraInformation(cachedEffData);
        }
    }

    _createContextForm(context) {
        this.isMultiRow(context.isMultiRow);

        if (!context.isMultiRow) {
            this._createSingleRowForm(context.formElements);
        }
    }

    _createSingleRowForm(formElements) {
        const form = formBuilder.createForm({ elements: formElements });
        const model = this.model.isEmpty() ? this.model.addRow() : this.model.firstRow;

        form.registerModel(model.attributes);
        this.customJsValidation.addToForm(form);
        this.form(form);

        this._registerForm();
        this._setBlockReady();
    }

    _getCandidateExtraInformation(contextName) {
        if (!candidateModel.id() || useDraftData()) {
            return Promise.resolve();
        }

        return getExtraInformation(candidateModel.id(), contextName);
    }

    _updateExtraInformation() {
        this._setBlockUnready();
        this._getEffData();

        this._getCandidateExtraInformation(this.model.name)
            .then((candidateData) => {
                if (candidateData) {
                    this.model.updateIdsAndLinks(candidateData);
                }

                candidateModel.extraInformation.push(this.model);
            })
            .catch(console.error)
            .then(this._setBlockReady.bind(this));
    }

    dispose() {
        this._applyFlowRefreshSub.detach();
        this._submitFailureEventHandler.detach();
    }

}