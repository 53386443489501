import i18n from 'core/i18n/i18n';

const STATUS_UNCONFIRMED = 'candidate-self-service.tile.status-unconfirmed';

export default {
    fillEmptyStatus(applications) {
        applications.forEach((application) => {
            if (application.isConfirmed() === false && application.status() === null) {
                application.status(i18n(STATUS_UNCONFIRMED));
            }
        });

        return applications;
    },
};