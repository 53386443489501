import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';
import { CookieConsentModalActionsCustomizationParams } from '../config/types';

export const mapParamsFromRest = (params: RestParam[]): CookieConsentModalActionsCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    try {
                        const contentParams = paramValue ? JSON.parse(paramValue) : {};

                        return {
                            buttonSize: contentParams.buttonSize || emptyParams.content.buttonSize,
                        };
                    } catch (e) {
                        return {
                            buttonSize: emptyParams.content.buttonSize,
                        };
                    }

                case 'acceptButtonHoverTypography':
                    return mapTypography(paramValue);

                case 'acceptButtonTypography':
                    return mapTypography(paramValue);
                case 'cookiePreferencesButtonHoverTypography':
                    return mapTypography(paramValue);
                case 'cookiePreferencesLinkTypography':
                    return mapTypography(paramValue);
                case 'cookiePreferencesLinkHoverTypography':
                    return mapTypography(paramValue);
                case 'cookiePreferencesButtonTypography':
                    return mapTypography(paramValue);
                case 'declineButtonHoverTypography':
                    return mapTypography(paramValue);
                case 'declineButtonTypography':
                    return mapTypography(paramValue);
                case 'savePreferencesButtonHoverTypography':
                    return mapTypography(paramValue);
                case 'savePreferencesButtonTypography':
                    return mapTypography(paramValue);
                case 'acceptButtonStyling':
                    return mapStylingBox(paramValue);
                case 'acceptButtonHoverStyling':
                    return mapStylingBox(paramValue);
                case 'cookiePreferencesButtonHoverStyling':
                    return mapStylingBox(paramValue);
                case 'cookiePreferencesButtonStyling':
                    return mapStylingBox(paramValue);
                case 'cookiePreferencesLinkHoverStyling':
                    return mapStylingBox(paramValue);
                case 'cookiePreferencesLinkStyling':
                    return mapStylingBox(paramValue);
                case 'declineButtonHoverStyling':
                    return mapStylingBox(paramValue);
                case 'declineButtonStyling':
                    return mapStylingBox(paramValue);
                case 'savePreferencesButtonHoverStyling':
                    return mapStylingBox(paramValue);
                case 'savePreferencesButtonStyling':
                    return mapStylingBox(paramValue);
                case 'overallStyling':
                    return mapStylingBox(paramValue);
                default:
                    return notReachable(paramKey);
            }
        },
    });
};
