import {
    MappedQuestionnairePrefillResponse,
    QuestionnairePrefillResponse,
    QuestionPrefillResponse,
} from '../config/types';
import { MULTIPLE_CHOICE, SINGLE_CHOICE, TEXT } from '../enum/responseTypes';

export const mapDataFromRest = (
    restResponses: QuestionnairePrefillResponse
): MappedQuestionnairePrefillResponse[] => {
    const responseItems = restResponses?.items?.length > 0 ? restResponses.items : [];

    return responseItems.map(
        (prefillResponse: QuestionPrefillResponse): MappedQuestionnairePrefillResponse => {
            let answer: string | number[] | null = null;

            if (prefillResponse.responseType === TEXT && prefillResponse.responseText) {
                answer = atob(prefillResponse.responseText);
            } else if (prefillResponse.responseList) {
                const parsedResponse = JSON.parse(prefillResponse.responseList);

                if (prefillResponse.responseType === MULTIPLE_CHOICE) {
                    answer = parsedResponse;
                }

                if (prefillResponse.responseType === SINGLE_CHOICE) {
                    answer = parsedResponse[0];
                }
            } else if (prefillResponse.responseText) {
                answer = prefillResponse.responseText;
            }

            return {
                questionId: prefillResponse.questionId,
                answer: answer,
                type: prefillResponse.responseType,
            };
        }
    );
};
