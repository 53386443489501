import { pureComputed } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class SelectFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this._setAttributes();

        this.isDisabled = pureComputed(this._isDisabled, this);
        this.a11yLabel = this.element.label();
    }

    blurHandler() {
        this.hasFocus(false);

        super.blurHandler();
    }

    _isDisabled() {
        const isDisabled = this.element.isDisabled();
        const hasOptions = Array.isArray(this.element.options()) && this.element.options().length;

        return isDisabled || !hasOptions;
    }

    _setAttributes() {
        if (this.attributes.placeholder) {
            this.placeholder = this.attributes.placeholder;
        } else {
            this.placeholder = this.element.withEmptyOption() ? '' : undefined;
        }
    }

}
