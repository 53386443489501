import { EventTag } from './config/eventTags';
import { LayoutStyle } from 'minimal/module/search/component/search-results/config/types';
import { Observable } from 'knockout';

type Params = {
    eventTags: EventTag[];
    layoutStyle: Observable<LayoutStyle>;
};

export class EventTagsViewModel {
    eventTags: EventTag[];
    layoutStyle: Observable<LayoutStyle>;

    constructor({ eventTags, layoutStyle }: Params) {
        this.eventTags = eventTags;
        this.layoutStyle = layoutStyle;
    }
}
