import { isStyleEmpty } from '../customCss/getStyle';
import { notReachable } from 'app/types/utils';

type MergeProps<Param> = {
    paramKey: keyof Param;
    baseValue: string | null;
    modifierValue: string | null;
};

type Props<Param> = {
    type: 'replaceNonExistingParamsWithDefault';
    defaultParams: Param;
};

type MergeFunction<Param> = ({
    paramKey,
    baseValue,
    modifierValue,
}: MergeProps<Param>) => string | null | Param[keyof Param];

export const getParamsValueMergeFunction = <Param>(props: Props<Param>): MergeFunction<Param> => {
    switch (props.type) {
        case 'replaceNonExistingParamsWithDefault':
            return ({ paramKey, baseValue, modifierValue }: MergeProps<Param>) => {
                if (isStyleEmpty(modifierValue) && !isStyleEmpty(baseValue)) {
                    return props.defaultParams[paramKey];
                }

                return modifierValue;
            };

        default:
            return notReachable(props.type);
    }
};
