import { CommonParams, DimensionUnit } from 'minimal/module/search/service/customCss';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config';

export const mapCommonParams = (stringifiedParams: string | undefined | null): CommonParams => {
    if (!stringifiedParams) {
        return EMPTY_COMMON_PARAMS;
    }

    const parsedParams = JSON.parse(stringifiedParams) as Record<string, string | undefined | null>;

    // TODO: margin and padding properties are always null and can be removed
    return {
        cssClass: parsedParams.cssClass || null,
        cssId: parsedParams.cssId || null,
        additionalOptionsUnit: (parsedParams.additionalOptionsUnit || 'px') as DimensionUnit,
        margin: parsedParams.margin || null,
        marginTop: parsedParams.marginTop || null,
        marginBottom: parsedParams.marginBottom || null,
        marginLeft: parsedParams.marginLeft || null,
        marginRight: parsedParams.marginRight || null,
        padding: parsedParams.padding || null,
        paddingTop: parsedParams.paddingTop || null,
        paddingBottom: parsedParams.paddingBottom || null,
        paddingLeft: parsedParams.paddingLeft || null,
        paddingRight: parsedParams.paddingRight || null,
    };
};
