import { Marker } from './Marker';

export class SearchMarker extends Marker {

    constructor({ coordinates, jobId, locationId }) {
        super({ coordinates });

        this.jobs = [jobId];
        this.locationId = [locationId];
    }

    addJob(id, locationId) {
        if (this.jobs.includes(id)) {
            return;
        }

        if (!this.locationId.includes(locationId)) {
            this.locationId.push(locationId);
        }

        this.jobs.push(id);

        this._recalculate();
    }

    _recalculate() {
        this.label = this.jobs.length > 1 ? String(this.jobs.length) : '';
    }

}