import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';

export default {
    formatDateRange(startDate, endDate, options) {
        const startDateText = this.formatDate(startDate, options);
        const endDateText = this.formatDate(endDate, options);

        if (startDateText && endDateText) {
            return `${startDateText} - ${endDateText}`;
        } else if (startDateText && !endDateText) {
            return `${startDateText}`;
        } else if (!startDateText && endDateText) {
            return `${endDateText}`;
        }

        return '';
    },

    formatDate(date, options) {
        const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

        if (!date) {
            return '';
        }

        return dateFormatter.formatDate(date, options);
    },
};