import 'core/utils/subscribeOnce';
import 'cx/module/talent-community/component/tc-dialog/component';
import 'cx/module/talent-community/component/talent-community-summary/component';
import 'cx/module/talent-community/component/talent-community-flow/component';
import 'cx/module/talent-community/component/talent-community-update-flow/component';
import '../component/talent-community-expand/component';
import '../component/talent-community-subscribe/component';

import './routing';
import './templates';

import { components } from 'knockout';

import ViewModel from '../MinimalTalentCommunityViewModel';
import template from '../talent-community.html';

components.register('talent-community', { viewModel: ViewModel, template });
