import { observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import { RADIUS_UNITS, translations, RADIUS_UNITS_FULL_NAMES, MILES } from '../search-radius/config';
import SearchRadiusViewModel from '../search-radius/SearchRadiusViewModel';
import { ojTranslations } from 'cx/service/ojTranslations';

export default class SearchRadiusZipCodeViewModel extends SearchRadiusViewModel {

    constructor(params) {
        super(params);

        this.radiusUnits = RADIUS_UNITS;
        this.radiusOptionsZipCode = observable(this._getRadiusValuesWithoutUnits());
        this.ariaLabel = pureComputed(() => this._createAriaLabel());
        this.ojTranslations = ojTranslations;
    }

    _getRadiusValuesWithoutUnits() {
        return this.radiusOptions()
            .map(({ value }) => ({
                label: `+${value}`,
                value,
                ariaLabelKm: `+${value} ${i18n('location-bar.dropdown.radius-unit.kilometers')}`,
                ariaLabelMi: `+${value} ${i18n('location-bar.dropdown.radius-unit.miles')}`,
            }));
    }

    _createAriaLabel() {
        const radiusUnitLabel = this.radiusUnit() === MILES ? RADIUS_UNITS_FULL_NAMES.MI : RADIUS_UNITS_FULL_NAMES.KM;

        return `${i18n('location-bar.dropdown.location-radius-label')} +${this.radiusValue()} ${radiusUnitLabel}`;
    }

    switchUnit(key) {
        this.radiusUnit(key);
    }

    getUnitName(key) {
        return translations[key];
    }

    getUnitFullName(key) {
        return RADIUS_UNITS_FULL_NAMES[key];
    }

    onRadiusValueChanged(e) {
        const { value, previousValue } = e.detail;

        if (value === null) {
            e.currentTarget.value = previousValue;
        }
    }

}
