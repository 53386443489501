import { Observable, PureComputed, observable, pureComputed } from 'knockout';

import {
    COOKIE_CONSENT_WIDGET_TYPES,
    CookieConsentWidgetData,
    CookieConsentWidgetViewData,
} from '../../types';
import { cookieConfig } from 'cx/service/cookieAgreement';
import { computeDisplayStyles } from '../../service/displayStyles';
import { COOKIE_CONSENT_WIDE_STYLE } from 'site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import pageMapper from 'cx/module/custom-content/mapper/page';
import { PageRestData } from 'cookie-consent/types/restPageDataType';
import Page from 'cx/module/custom-content/model/Page';

type Props = {
    widgetType: COOKIE_CONSENT_WIDGET_TYPES;
};

export class CookieConsentViewModel {
    viewData: CookieConsentWidgetViewData;
    pageData: Observable<CookieConsentWidgetData>;
    displayStyles: PureComputed<string>;
    isWideModal: PureComputed<boolean>;

    constructor({ widgetType }: Props) {
        this.displayStyles = pureComputed(() =>
            computeDisplayStyles(widgetType, this.viewData.customPage()?.params)
        );

        this.viewData = {
            customPage: observable(null),
        };

        this.pageData = observable({
            widgetType: widgetType,
            modalTitle: '',
            categoriesSelectionModel: null,
            ...cookieConfig,
        });

        this.isWideModal = pureComputed(() => {
            return this.viewData.customPage()?.params?.style === COOKIE_CONSENT_WIDE_STYLE;
        });
    }

    loadCustomPage(pageRestData: PageRestData | undefined): Page | void {
        if (!pageRestData) {
            return;
        }

        const pageMappedData = pageMapper.mapPageFromRest(pageRestData);

        this.viewData.customPage(pageMappedData);

        return pageMappedData;
    }
}
