import { observable, pureComputed } from 'knockout';
import router from 'app/model/router';
import flowService from '../../service/flow';
import talentCommunityFlowService from 'cx/module/talent-community/service/talentCommunityFlow';
import sectionState from '../../model/sectionState';
import postApplyService from '../../../apply-flow-post/service/postApply';
import tokenService from '../../../candidate-verification/service/token';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import agreementsService from './service/agreements';

export default class AgreementsViewModel {

    constructor({ section, blockId, flow }) {
        this.section = section;
        this.blockId = blockId;
        this.flow = observable(flow);
        this.isOptInVisible = observable(true);
        this.isOptInJobAlertVisible = observable(true);

        this.isBlockVisible = pureComputed(() => {
            const shouldDisplayOptIn = flow.optIn.isEnabled && this.isOptInVisible();
            const shouldDisplayJobAlertOptIn = flow.jobAlertsEnabled && this.isOptInJobAlertVisible();

            return shouldDisplayOptIn || shouldDisplayJobAlertOptIn;
        });

        this._loadPreferences()
            .then(this._setBlockIsReady.bind(this))
            .catch(console.error);
    }

    _loadPreferences() {
        const token = tokenService.get();

        if (token.candidateNumber) {
            return jobAlertService.getPreferences(token.candidateNumber)
                .then(this._setOptInsVisibility.bind(this));
        }

        return Promise.resolve({
            optIn: false,
        });
    }

    _setOptInsVisibility(candidateSitePreferences) {
        this.isOptInVisible(!agreementsService.isMarketingOptedIn(candidateSitePreferences));
        this.isOptInJobAlertVisible(!agreementsService.isJobAlertsOptedIn(candidateSitePreferences));
    }

    _getFlowPromise() {
        const routeId = router.route().id;

        if (routeId.indexOf('apply-flow') !== -1) {
            return flowService.query(router.routeParams().jobId);
        }

        if (postApplyService.isPostApply()) {
            return flowService.querySecondary(router.routeParams().submissionId);
        }

        return talentCommunityFlowService.query();
    }

    _setBlockIsReady() {
        sectionState.setBlockReady(this.section.number, this.blockId);
    }

}