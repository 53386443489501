import formElementMapper from '../../../mapper/metadata';
import { createValidatorsOptions } from '../service/validatorOptions';
import { resolveElementControlType } from '../service/elementType';
import { mapYesNoToBoolean } from '../../../../../../core/utils/stringToBoolean';

function _mapContextFormElementsFromRest(restElements) {
    return restElements.sort(_sortBySequenceNumber)
        .map(_mapFormElementFromRest);
}

function _mapContextFromRest(context, formElements) {
    return {
        name: context.contextName,
        isMultiRow: mapYesNoToBoolean(context.isMultiRow),
        formElements,
    };
}

function _mapFormElementFromRest(restElement) {
    const formElement = {
        type: resolveElementControlType(restElement.controlType, restElement.type),
        name: restElement.name,
        label: restElement.prompt,
        helpText: restElement.definitionHelpText,
        value: restElement.defaultValue,
        isUniqueKey: mapYesNoToBoolean(restElement.multiRowUniqueKey),
        attributes: {
            readonly: mapYesNoToBoolean(restElement.isReadOnly),
            required: mapYesNoToBoolean(restElement.isRequired),
        },
    };

    if (restElement.controlType === 'CheckBox') {
        formElement.checked = restElement.checkedValue;
        formElement.unchecked = restElement.nonCheckedValue;
    }

    formElement.validators = createValidatorsOptions(restElement, formElement);

    return formElementMapper.mapFormElementFromRest(restElement, formElement);
}

function _sortBySequenceNumber(element1, element2) {
    return Number(element1.displaySequence) - Number(element2.displaySequence);
}

export function mapContextFromRest(restContext) {
    const [context] = restContext.items;

    return Promise.all(_mapContextFormElementsFromRest(context.attributeList))
        .then(formElements => _mapContextFromRest(context, formElements));
}