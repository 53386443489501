import { DisplayStyleWithoutToggle, DisplayStyle, ComponentClass } from 'cx/module/search/config/types';

export const getComponentClass = (displayStyle: DisplayStyle): ComponentClass => {
    switch (displayStyle) {
        case 'tile':
        case 'toggleTileMap':
            return 'jobs-grid';
        case 'list':
        case 'toggleListMap':
            return 'jobs-list';
        default:
            return 'jobs-list';
    }
};

export const getJobDisplayStyle = (displayStyle: DisplayStyle): DisplayStyleWithoutToggle => {
    switch (displayStyle) {
        case 'tile':
        case 'toggleTileMap':
            return 'tile';
        case 'list':
        case 'toggleListMap':
            return 'list';
        default:
            return 'list';
    }
};
