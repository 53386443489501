import candidateModel from 'apply-flow/model/candidate';
import mapping from 'knockout-mapping';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';

const getInitialCommunicationChannels = () => ({
    initialEmail: null,
    initialEmailVerified: false,
    initialPhone: null,
    initialPhoneVerified: false,
});

let initialCommunicationChannels = getInitialCommunicationChannels();

export const storeInitialCommunicationChannelsValue = () => {
    const { email, phone, emailVerifiedFlag, phoneVerifiedFlag, verificationMethod } =
        candidateModel.basicInformation;

    initialCommunicationChannels = getInitialCommunicationChannels();

    if (emailVerifiedFlag() || (candidateModel.isNew() && verificationMethod() === EMAIL)) {
        initialCommunicationChannels.initialEmail = email();
        initialCommunicationChannels.initialEmailVerified = true;
    }

    if (phoneVerifiedFlag() || (candidateModel.isNew() && verificationMethod() === SMS)) {
        initialCommunicationChannels.initialPhone = mapping.toJS(phone);
        initialCommunicationChannels.initialPhoneVerified = true;
    }
};

export const setInitialEmail = (email, isVerified) => {
    initialCommunicationChannels.initialEmail = email;
    initialCommunicationChannels.initialEmailVerified = isVerified;
};

export const setInitialPhone = (phone, isVerified) => {
    initialCommunicationChannels.initialPhone = phone;
    initialCommunicationChannels.initialPhoneVerified = isVerified;
};

export const getInitialEmail = () => ({
    initialEmail: initialCommunicationChannels.initialEmail,
    initialEmailVerified: initialCommunicationChannels.initialEmailVerified,
});

export const getInitialPhone = () => ({
    initialPhone: initialCommunicationChannels.initialPhone,
    initialPhoneVerified: initialCommunicationChannels.initialPhoneVerified,
});