import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import '../form/component/multi-select-dropdown-form-element/component';

import ViewModel from '../PreferredLocationsViewModel';
import template from '../preferred-locations.html';

components.register('preferred-locations', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_LOCATION_PREFERENCE', {
    component: 'preferred-locations',
});
