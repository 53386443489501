import { StylingBox } from 'minimal/module/search/service/customCss';

export const EMPTY_BLOCK_STYLES: StylingBox = {
    borderRadius: null,
    borderWidth: null,
    backgroundColor: null,
    borderColor: null,
    iconColorLight: null,
    iconColorDark: null,
    margin: null,
    padding: null,
    alignment: null,
    color: null,
    ruleColor: null,
    backgroundImage: null,
    background: null,
};
