import ko from 'knockout';
import i18n from 'core/i18n/i18n';

export default class LegalDisclaimerViewModel {

    constructor({ legalDisclaimer, legalDisclaimerForm }) {
        this.legalDisclaimer = legalDisclaimer();

        this.isDialogVisible = ko.observable(false);
        this.dialogHeaderText = i18n('apply-flow.legal-disclaimer.terms-and-conditions');

        this.form = legalDisclaimerForm;

        this.showError = ko.pureComputed(() => !this.form().isValid());
        this.isInvalid = ko.pureComputed(() => `${!this.form().isValid()}`);
    }

    toggleAccepted() {
        const { legalDisclaimer } = this;
        const isAccepted = legalDisclaimer.isAccepted();

        return isAccepted ? legalDisclaimer.decline() : legalDisclaimer.accept();
    }

    openDialog() {
        this.isDialogVisible(true);
    }

}