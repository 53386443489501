/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'preact';

const COMPONENTS: Map<string, ComponentType> = new Map();

export const register = (key: string, component: ComponentType<any>): void => {
    if (COMPONENTS.has(key)) {
        throw new Error(`Component named ${key} has been already added to repository`);
    }

    COMPONENTS.set(key, component);
};

export const get = (key: string): ComponentType => {
    const component = COMPONENTS.get(key);

    if (!component) {
        throw new Error(`Component named ${key} do not exists in repository`);
    }

    return component;
};
