import { JobDetailsLocationCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';

export const emptyParams: JobDetailsLocationCustomizationParams = {
    content: {
        displayAdditionalLocations: false,
    },
    titleTypography: { ...EMPTY_TYPOGRAPHY },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
