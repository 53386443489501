import i18n from 'core/i18n/i18n';
import { JobDetailsPageOptions } from '../types';

export const overlayStyles = {
    left: i18n('admin.site-editor.content-editor.left'),
    center: i18n('admin.site-editor.content-editor.center'),
    right: i18n('admin.site-editor.content-editor.right'),
    fullScreen: i18n('admin.site-editor.content-editor.full-screen'),
} as const;

export const pageStyles = {
    boxed: i18n('admin.site-editor.content-editor.page-options-dialog.page-display-boxed'),
    full: i18n('admin.site-editor.content-editor.page-options-dialog.page-display-full'),
} as const;

export const defaultPageOptions: JobDetailsPageOptions = {
    overlayStyle: 'center',
    overlayOpacity: 70,
    overlayWidth: 1150,
    overlayWidthUnit: 'px',
    pageWidth: 1150,
    pageWidthUnit: 'px',
    horizontalPadding: 160,
    horizontalPaddingUnit: 'px',
    iconHiddenFavorite: false,
    iconHiddenShare: false,
    iconsColor: null,
};

export const labels = {
    pageIcons: i18n('admin.site-editor.content-editor.page-options-dialog.job-details.page-icons'),
    iconHiddenFavorite: i18n(
        'admin.site-editor.content-editor.page-options-dialog.job-details.icon-hidden-favorite'
    ),
    iconHiddenShare: i18n(
        'admin.site-editor.content-editor.page-options-dialog.job-details.icon-hidden-share'
    ),
    iconsColor: 'admin.site-editor.content-editor.page-options-dialog.job-details.icons-color',
    overlayOpacity: i18n('admin.site-editor.content-editor.page-options-dialog.job-details.overlay-opacity'),
    overlayStyle: i18n('admin.site-editor.content-editor.page-options-dialog.job-details.overlay-style'),
    overlayWidth: i18n('admin.site-editor.content-editor.page-options-dialog.job-details.overlay-width'),
    pageWidth: i18n('admin.site-editor.content-editor.page-options-dialog.job-details.page-width'),
    resetToDefaults: i18n(
        'admin.site-editor.content-editor.page-options-dialog.job-details.reset-to-defaults'
    ),
    horizontalPadding: i18n(
        'admin.site-editor.content-editor.page-options-dialog.job-details.horizontal-padding'
    ),
    pageHeader: i18n('admin.site-editor.pages.page-header-options.page-header'),
} as const;
