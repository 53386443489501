import mapping from 'knockout-mapping';
import http from 'core/http/http';
import candidateMapper from 'apply-flow/mapper/candidate';
import unverifiedService from 'candidate-verification/service/unverified';
import userTracking from 'cx/service/userTracking';
import tokenService from 'candidate-verification/service/token';
import { EMAIL, SMS } from '../../candidate-verification/config/verificationMethods';
import countryCodesService from 'cx/service/phone/phoneCountryCodes';
import { extractAreaCode } from 'cx/service/phone/phoneAreaCodes';
import tokenVerification from 'candidate-verification/component/email-verification/service/tokenVerification';
import { deepCopy } from 'core/utils/deepCopy';
import { recalculateProfileItemsId } from 'apply-flow/module/profile-items/service/profileItemsIdRecalculate';

function fill(candidateModel, candidate) {
    return Promise.resolve(mapping.fromJS(candidate || {}, candidateModel));
}

function getVerificationMethodForUnverifiedCandidate(token) {
    return token.phone ? SMS : EMAIL;
}

function pushRemovedItemsFromDraft(candidateModel, removedProfileItemsMap) {
    Object.keys(removedProfileItemsMap).forEach((profileItemName) => {
        const removedItems = removedProfileItemsMap[profileItemName];

        candidateModel[profileItemName].pushRemovedItems(removedItems);
    });
}

async function createNewCandidatePromise(token, verificationMethod) {
    if (verificationMethod === EMAIL) {
        return Promise.resolve({ email: token.email });
    }

    const countryCode = await countryCodesService.extractCountryCode(token.phone);

    let phoneNumber = token.phone.replace(
        countryCodesService.LEADING_CHARS_REGEX, '');

    const areaCode = extractAreaCode(phoneNumber);

    phoneNumber = _removeCountryAndAreaCode(phoneNumber, countryCode, areaCode);

    return Promise.resolve({
        mobilePhoneCountryCode: countryCode,
        mobilePhoneAreaCode: areaCode,
        mobilePhoneLegislationCode: token.phoneLegislationCode,
        mobilePhoneNumber: phoneNumber,
        email: token.email,
    });
}

function assignClaimedLastName(candidate) {
    if (tokenVerification.claimedLastName) {
        candidate.basicInformation.lastName(tokenVerification.claimedLastName);
    }
}

function _removeCountryAndAreaCode(phoneNumber, countryCode, areaCode) {
    return phoneNumber.slice(areaCode
        ? countryCode.length + areaCode.length
        : countryCode.length);
}

export default {
    save(candidateModel) {
        const data = candidateMapper.mapCandidateToRest(candidateModel);

        return http.post('/recruitingCECandidates', data, {
            statusCodes: {
                400({ response }) {
                    let message;

                    if (response.includes('ORA_IRC_CAND_EXISTS')) {
                        message = 'candidate-exists';
                    }

                    return Promise.reject(message);
                },
            },
        })
            .then(candidateMapper.mapCandidateFromRest)
            .then((candidate) => {
                candidateModel.id(candidate.id);
                userTracking.trackCandidateNumber(candidate.id);

                if (candidate.basicInformation.isVerified()) {
                    tokenService.setCandidateNumber(candidate.id);
                }

                return candidateModel;
            });
    },

    get(candidateId) {
        if (!candidateId) {
            return Promise.reject('candidateId is required');
        }

        return http.get(`/recruitingCECandidates/${candidateId}?onlyData=true`)
            .then(candidateMapper.mapCandidateFromRest);
    },

    create(token) {
        if (!token) {
            return Promise.reject();
        }

        const verificationMethod = token.verificationMethod || getVerificationMethodForUnverifiedCandidate(token);

        return createNewCandidatePromise(token, verificationMethod)
            .then(candidateMapper.mapCandidateFromRest.bind(candidateMapper))
            .then((candidate) => {
                candidate.basicInformation.verificationMethod(verificationMethod);

                assignClaimedLastName(candidate);

                return candidate;
            });
    },

    clear(candidateModel) {
        return Promise.resolve(candidateModel.clear());
    },

    loadForToken(candidateModel, token) {
        return this.clear(candidateModel)
            .then(this.get.bind(this, token.candidateNumber))
            .catch(this.get.bind(this, unverifiedService.getUnverifiedCandidateId()))
            .catch(this.create.bind(this, token))
            .then(fill.bind(null, candidateModel));
    },

    loadFromDraft(candidateModel, { candidate, removedProfileItemsMap }) {
        if (candidateModel === candidate) {
            return Promise.reject();
        }

        recalculateProfileItemsId(candidate);

        return fill(candidateModel, candidate)
            .then(() => pushRemovedItemsFromDraft(candidateModel, removedProfileItemsMap));
    },

    getSnapshot(candidateModel) {
        const rawModel = mapping.toJS(candidateModel);

        return deepCopy(rawModel);
    },

    updateCandidateLastModificationDate(candidate) {
        const data = candidateMapper.mapCandidateLastModificationDateToRest(candidate);

        return http.post('/recruitingCECandidates', data);
    },
};
