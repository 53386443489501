type ContextKey = 'SECURED' | 'PUBLIC';

export type ContextValue = 'CandExpSecured' | 'CandidateExperience';

export const Contexts: Record<ContextKey, ContextValue> = {
    SECURED: 'CandExpSecured',
    PUBLIC: 'CandidateExperience',
} as const;

export type SitePathParams = {
    context?: ContextValue;
    lang?: string;
    siteCode?: string;
    siteUrlName?: string;
};
