import ko from 'knockout';
import keycodes from 'core/config/keycodes';

/**
 * Moves focus to an element matching given selector
 * when user press Enter key or clicks on the binding context element
 *
 * @param {string} - target DOM selector to focus on
 *
 * @example
 * <a data-bind="a11y.skipTo: '{selector}'"></a>
 */
ko.bindingHandlers['a11y.skipTo'] = {
    init(element, valueAccessor) {
        function setFocus(event) {
            const elementToFocus = document.querySelector(valueAccessor());

            if (!event.key || event.key === keycodes.ENTER) {
                elementToFocus.focus();
                event.preventDefault();
            }
        }

        element.addEventListener('click', setFocus);
        element.addEventListener('keydown', setFocus);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('click', setFocus);
            element.removeEventListener('keydown', setFocus);
        });
    },
};