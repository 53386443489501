import { components } from 'knockout';

import { JobDetailsJobFitViewModel } from '../job-details-job-fit/JobDetailsJobFitViewModel';
import template from './job-details-job-fit.html';
import './component/job-fit-tile-pips';

components.register('job-details-job-fit', {
    viewModel: JobDetailsJobFitViewModel,
    template,
});

components.register('cc-job-details-job-fit', {
    viewModel: JobDetailsJobFitViewModel,
    template,
});
