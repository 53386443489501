import { observable, Observable, pureComputed, PureComputed } from 'knockout';
import i18n from 'app/module/core/i18n/i18n';
import { KILOMETERS, MILES, RADIUS_UNITS, RADIUS_OPTIONS } from './config';
import { RadiusUnit } from '../search-box-location/config/types';

type Props = {
    radiusValue: Observable<number>;
    radiusUnit: Observable<RadiusUnit>;
    onSelect: () => void;
};

export default class SearchRadiusMenuViewModel {
    radiusValue: Observable<number>;
    radiusUnit: Observable<RadiusUnit>;
    defaultRadiusUnit: PureComputed<RadiusUnit>;
    isVisible: Observable<boolean>;
    onSelect: () => void;
    radiusOptions: PureComputed<{ label: string; value: number; ariaLabel: string }[]>;
    radiusLabel: PureComputed<string>;

    constructor({ radiusValue, radiusUnit, onSelect }: Props) {
        this.radiusValue = radiusValue;
        this.radiusUnit = radiusUnit;

        this.defaultRadiusUnit = pureComputed(() => {
            if (this.radiusUnit() === MILES) {
                return i18n('location-bar.dropdown.radius-unit.mi');
            }

            return i18n('location-bar.dropdown.radius-unit.km');
        });

        this.isVisible = observable<boolean>(false);

        this.radiusOptions = pureComputed(() =>
            RADIUS_OPTIONS[this.radiusUnit()].map((radius) => ({
                ...radius,
                ariaLabel: `+${radius.value} ${
                    this.radiusUnit() === 'KM'
                        ? i18n('location-bar.dropdown.radius-unit.kilometers')
                        : i18n('location-bar.dropdown.radius-unit.miles')
                }`,
            }))
        );

        this.radiusLabel = pureComputed(() => RADIUS_UNITS[this.radiusUnit()]);
        this.onSelect = onSelect;
    }

    toggle(): void {
        this.isVisible(!this.isVisible());
    }

    hide(): void {
        this.isVisible(false);
    }

    handleOptionSet(radiusValue: number): void {
        this.radiusValue(radiusValue);

        this.hide();
        this.onSelect();
    }

    switchUnit(): void {
        this.radiusUnit(this.radiusUnit() === KILOMETERS ? MILES : KILOMETERS);
    }
}
