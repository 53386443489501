import http from 'app/module/core/http/http';
import { mapDataFromRest } from '../mapper/prefillQuestionnaireResponses';
import { MappedQuestionnairePrefillResponse, QuestionnairePrefillResponse } from '../config/types';

export const fetchQuestionnairePrefillData = (
    questionnaireId: string,
    questionnaireVersionNumber: string
): Promise<MappedQuestionnairePrefillResponse[]> => {
    return fetchPrefillResponses(questionnaireId, questionnaireVersionNumber)
        .then((response) => {
            return mapDataFromRest(response);
        })
        .catch((error) => {
            console.error('Fetching questionnaire prefill data failed.', error);

            return Promise.resolve([]);
        });
};

const fetchPrefillResponses = async (
    questionnaireId: string,
    questionnaireVersionNumber: string
): Promise<QuestionnairePrefillResponse> => {
    return http.get(
        `/recruitingCEPrefillQuestionResponses?finder=findByQuestionnaireId;QuestionnaireId=${questionnaireId},QuestionnaireVersionNumber=${questionnaireVersionNumber}`
    );
};
