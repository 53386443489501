import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import deviceDetector from 'core/device-detector/deviceDetector';

export default class SectionViewModel extends ElementViewModel {

    constructor({ section, pageData, onMessage }) {
        const { params, components, rows } = section;

        super({ params });

        // TODO: when it's possible, separate pageData and componentData
        this.components = components.map(component => ({
            ...component,
            pageData,
        }));

        this.rows = rows;
        this.pageData = pageData;

        this.isParallaxEnabled = pureComputed(() => Boolean(!deviceDetector.isMobile()
            && this.params.backgroundParallax() && this.params.backgroundImage()));

        this.width = this.params.column();
        this.customClass = this.params.cssClass?.() ?? '';
        this.cssClass = `cc-section--width-${this.width} ${this.customClass}`;
        this.backgroundSrc = this.params.backgroundImage() ? `url(${this.params.backgroundImage()})` : false;
        this.onMessage = onMessage;
    }

}
