import { PureComputed, pureComputed } from 'knockout';
import { STYLES_SEPARATOR } from 'minimal/module/search/service/customCss';

type Props<CustomizationParams> = {
    params?: CustomizationParams;
    id?: string;
    defaultParams: CustomizationParams;
};

export abstract class CustomComponentViewModel<CustomizationParams> {
    customizationParams: CustomizationParams;
    uniqueWrapperClass: string;
    customCss: PureComputed<string>;

    protected constructor({ params, defaultParams, id = 'default' }: Props<CustomizationParams>) {
        this.customizationParams = params || defaultParams;
        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.customCss = pureComputed(() => this.getCustomStyles(this.customizationParams));
    }

    getCustomStyles(params: CustomizationParams): string {
        const customizationKeys = Object.keys(params as object) as (keyof CustomizationParams)[];
        const customStyles = customizationKeys.map((key) => this.getCustomStyle(key));

        return customStyles.filter((style) => style.trim().length).join(STYLES_SEPARATOR);
    }

    abstract getCustomStyle(key: keyof CustomizationParams): string;
}
