import { buildQueryValue } from './pipeQueryString';

function decode(value) {
    return value ? value.substring(1, value.length - 1).replace(/\\"/g, '"') : value;
}

export function encodePipeQueryString(queryValue, pipeKey, pipeValues) {
    const decodedCurrentValue = decode(queryValue);
    const builtQueryValue = buildQueryValue(decodedCurrentValue, pipeKey, pipeValues);
    const encodedQueryValue = builtQueryValue.replace(/"/g, '\\"');

    return encodedQueryValue
        ? `"${encodedQueryValue}"`
        : null;
}
