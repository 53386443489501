import { observable } from 'knockout';

/**
 * Map of observable view values (value = component name)
 */

const views = {};

export default {
    create(view) {
        if (!views[view]) {
            views[view] = observable();
        }

        return views[view];
    },

    get(view) {
        return views[view];
    },

    remove(view) {
        if (views[view]) {
            views[view](null);
        }
    },
};
