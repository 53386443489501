import i18n from 'core/i18n/i18n';
import FormTileStrategy from 'apply-flow/module/tile-profile-items/model/FormTileStrategy';
import tileDateFormatter from 'apply-flow/module/tile-profile-items/service/tileDateFormatter';
import { DATE_SHORT_FORMAT } from 'cx/service/dateTime/dateTimeFormats';

const UNNAMED_EDUCATION_LABEL = i18n('apply-flow.profile-item.unnamed-education');

export default class EducationFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return {
            degree: 'contentItemId',
            degreeName: 'degreeName',
            endDate: 'endDate',
            major: 'major',
            school: 'educationalEstablishmentId',
            schoolName: 'educationalEstablishment',
            startDate: 'startDate',
        };
    }

    buildTitle(valueMap) {
        return valueMap.major || valueMap.degree || valueMap.degreeName || UNNAMED_EDUCATION_LABEL;
    }

    buildSubtitle(valueMap) {
        const subtitle = valueMap.schoolName || valueMap.school || '';
        const dateRange = tileDateFormatter.formatDateRange(valueMap.startDate, valueMap.endDate, DATE_SHORT_FORMAT);

        if (dateRange) {
            return `${subtitle}\n${dateRange}`;
        }

        return subtitle;
    }

}