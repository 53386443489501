import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import {
    getStylingBoxCss,
    getTypographyCss,
    wrapBySelector,
    desktopOnly,
    getTypographyStyles,
} from 'minimal/module/search/service/customCss';
import { JobDetailsDescriptionParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-description/config/types';
import { PureComputed } from 'knockout';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsDescriptionParams;
    id?: string;
};

export class JobDetailsDescriptionViewModel extends CustomComponentViewModel<JobDetailsDescriptionParams> {
    pageData: PureComputed<JobDetailsPageData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof JobDetailsDescriptionParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'titleTypography': {
                const selector = `.${this.uniqueWrapperClass} .job-details__description-header`;
                const { fontSize, lineHeight, ...rest } = this.customizationParams[key];

                const typography = getTypographyCss({
                    selector: selector,
                    typography: rest,
                });

                const typographyDesktopOnly = desktopOnly(
                    wrapBySelector(selector, getTypographyStyles({ fontSize, lineHeight }))
                );

                return `${typography} ${typographyDesktopOnly}`;
            }

            case 'descriptionTypography': {
                const selector = `.${this.uniqueWrapperClass} .job-details__description-content`;
                const { fontSize, lineHeight, ...rest } = this.customizationParams[key];

                const typography = getTypographyCss({
                    selector: selector,
                    typography: rest,
                });

                const typographyDesktopOnly = desktopOnly(
                    wrapBySelector(selector, getTypographyStyles({ fontSize, lineHeight }))
                );

                return `${typography} ${typographyDesktopOnly}`;
            }

            case 'container':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-details__section`,
                    stylingBox: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
