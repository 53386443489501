import ko from 'knockout';
import timelineEvents from '../../config/events';
import fixer from 'cx/module/apply-flow/component/apply-flow-fixer/service/fixer';

const noOp = () => null;

const ADD_BUTTONS_IDS = {
    'experience-form': '#timeline-add-experience-button',
    'education-form': '#timeline-add-education-button',
};

export default class TimelineFormDialogViewModel {

    constructor(params) {
        this.form = ko.observable();
        this.formLegend = ko.observable();
        this.showRemoveButton = ko.observable(false);
        this.isVisible = ko.observable();
        this.resetFocus = '';
        this._save = noOp;
        this._remove = noOp;
        this.isValidationInProgress = ko.observable(false);
        this.onDialogClose = params.onDialogClose;

        this.hasActiveForm = ko.pureComputed({
            read: () => Boolean(this.form()),
            write: (state) => {
                if (!state) {
                    this.save();
                }
            },
        });

        this.focusCallback = fixer.focusInvalid;

        this._openDialogSignal = timelineEvents.openFormDialog.add((
            { save, remove, showRemoveButton, form, isValidationInProgress }) => {
            this.resetFocus = ADD_BUTTONS_IDS[form().componentName()];
            this._save = save;
            this._remove = remove;
            this.showRemoveButton(showRemoveButton);
            this.form(form);
            this.formLegend(form().legend());
            this.isVisible(true);
            this._setupSubscription(isValidationInProgress);
        });

        this._closeDialogSignal = timelineEvents.closeFormDialog.add(this._hide.bind(this));
    }

    save() {
        this._save(this.form());
    }

    remove() {
        this._remove(this.form());
        this._hide();
    }

    close() {
        this._hide();
    }

    _clear() {
        this.form(null);
        this.formLegend('');
        this.showRemoveButton(false);
        this.isVisible(false);
        this._save = noOp;
        this._remove = noOp;
    }

    _hide() {
        this._clear();
        timelineEvents.renderTimeline.dispatch();
    }

    dispose() {
        this._openDialogSignal.detach();
        this._closeDialogSignal.detach();
    }

    _setupSubscription(isValidationInProgress) {
        if (this.validationProgressSubscription) {
            this.validationProgressSubscription.dispose();
        }

        this.validationProgressSubscription = isValidationInProgress.subscribe(this.isValidationInProgress.bind(this));
    }

}