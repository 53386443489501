import absoluteRouter from 'app/model/absoluteRouter';
import appConfig from 'app/model/config';
import profileImportService from './profileImport';
import urlEncoder from 'core/router/urlEncoder';
import MetadataService from 'cx/module/apply-flow/module/profile-import/service/Metadata';
import { toJS } from 'knockout';

function _getProviderUrl(config) {
    let url = config.oauthService;

    const baseUrl = window.VanityUrlEnabled && window.VanityUrlUseReverseProxy
        ? appConfig.vanityBaseUrl
        : `${appConfig.uiBaseUrl}/CandidateExperience/`;

    url = url.replace('{clientId}', config.clientId)
        .replace('{redirectUri}',
            `${baseUrl || ''}profileImport/oauth?profileType=${config.code}`)
        .concat(`&state=${appConfig.siteLang},${window.VanityUrlEnabled && window.VanityUrlUseReverseProxy ? 'V' : 'F'}`);

    return url;
}

function _getOauthLoginStartUrl(config) {
    return absoluteRouter.interpolate(
        { lang: appConfig.siteLang },
        'profile-import', { redirectUrl: config.code },
        { inherit: false, encode: true });
}

export default function OAuthService() {
    let config = {};
    let closePopup = true;

    const width = 600;
    const height = 650;
    const top = (window.top.outerHeight / 2) + (window.top.screenY - (height / 2));
    const left = (window.top.outerWidth / 2) + (window.top.screenX - (width / 2));

    const popupSettings = {
        height,
        width,
        left,
        top,
        resizable: 'yes',
        location: 'no',
        scrollbars: 'yes',
    };

    window.onProfileData = function onProfileData(profileData) {
        profileImportService.getAwliProfile(profileData);
    };

    this.initialize = (pConfig) => {
        config = pConfig;
    };

    this.getOauthLoginUrl = async (code) => {
        let encodedIndeedUrl = '';
        const errorMessage = 'Unable to get OauthLoginUrl';

        try {
            config = await new MetadataService().getProviderConfig();
        } catch (error) {
            return Promise.reject(errorMessage);
        }

        const profileType = 'indeed';
        const configParams = toJS(config[profileType]);

        this.initialize(configParams);

        if (code !== configParams.code) {
            return Promise.reject(errorMessage);
        }

        const oauthUrl = absoluteRouter.interpolate(
            { lang: appConfig.siteLang },
            'profile-import', { redirectUrl: _getProviderUrl(config) },
            { inherit: false, encode: true });

        const oauthUrlString = urlEncoder.decode(oauthUrl);
        const indeedUrl = oauthUrlString.substr(oauthUrlString.indexOf('redirect=') + 9);

        encodedIndeedUrl = indeedUrl;

        const params = {};

        try {
            new URL(indeedUrl).searchParams.forEach((val, key) => {
                params[key] = urlEncoder.encode(val);
                encodedIndeedUrl = encodedIndeedUrl.replace(val, params[key]);
            });
        } catch (error) {
            return Promise.reject(errorMessage);
        }

        return Promise.resolve(encodedIndeedUrl);
    };

    this.socialLogin = (onSuccess, onCancel, closePopupWin) => {
        const popupSettingsArr = Object.keys(popupSettings).map(key => `${key}=${popupSettings[key]}`, this);

        const bc = new BroadcastChannel('indeed_channel');

        const popupWindow = window.open(_getOauthLoginStartUrl(config), 'SocialLoginWindow', popupSettingsArr.join(','));
        let isSuccess = false;

        closePopup = closePopupWin ?? true;

        function _onMessage(event) {
            const message = event.data;

            if (message && message.type === 'oauthtoken') {
                window.removeEventListener('message', _onMessage);
                isSuccess = true;
                onSuccess(message.data, popupWindow);

                if (closePopup) {
                    popupWindow.postMessage({ type: 'closewindow', data: 'closewindow' }, '*');
                    bc.postMessage({ type: 'closewindow', data: 'closewindow' }, '*');
                    popupWindow.close();
                }
            }
        }

        window.addEventListener('message', _onMessage);
        bc.onmessage = _onMessage;

        function _onCancel(event) {
            if (onCancel && !isSuccess) {
                onCancel(event, popupWindow);
            }

            if (closePopup && popupWindow) {
                popupWindow.close();
            }
        }

        const timer = setInterval(() => {
            if (popupWindow?.closed || typeof popupWindow?.closed === 'undefined') {
                clearInterval(timer);
                _onCancel('window closed');
            }
        }, 1000);

        if (window.focus) {
            if (popupWindow) {
                popupWindow.focus();
            }
        }
    };
}