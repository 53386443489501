import { components } from 'knockout';

import { JobTagsViewModel } from 'minimal/module/job-tags/JobTagsViewModel';
import template from './job-tags.html';
import './component';

components.register('job-tags', {
    viewModel: JobTagsViewModel,
    template,
});
