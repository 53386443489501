import AutosuggestFormElementViewModel from
    'core/form/component/autosuggest-form-element/AutosuggestFormElementViewModel';
import debouncePromise from 'core/utils/debouncePromise';

export const USE_THROTTLE_THRESHOLD = 5;

export default class GeoHierarchyAutosuggestFormElementViewModel extends AutosuggestFormElementViewModel {

    constructor(...args) {
        super(...args);

        this.getOptions = this.getOptions.bind(this);

        this.debouncedGetOptions = debouncePromise(this.element.getOptions.bind(this.element), 500);
    }

    async getOptions(context) {
        if ('term' in context) {
            return await this.debouncedGetOptions(context.term);
        }

        return this.element.options();
    }

}

