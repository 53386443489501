import { JobDetailsApplyButtonCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';

export const emptyParams: JobDetailsApplyButtonCustomizationParams = {
    content: {
        width: 270,
        height: 60,
        widthUnit: 'px',
        heightUnit: 'px',
    },
    titleTypography: { ...EMPTY_TYPOGRAPHY },
    titleAlreadyAppliedTypography: { ...EMPTY_TYPOGRAPHY },
    button: { ...EMPTY_BLOCK_STYLES },
    alreadyAppliedButton: { ...EMPTY_BLOCK_STYLES },
    container: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
