export const getGridTileAdditionStyles = (
    borderStyle: string | null,
    stylesFor = 'body'
): Record<string, string> | null => {
    if (!borderStyle) {
        return null;
    }

    return {
        borderRadius:
            stylesFor === 'header'
                ? `${borderStyle}px ${borderStyle}px 0 0`
                : `0 0 ${borderStyle}px ${borderStyle}px`,
    };
};

export const getMediaStyles = ({
    jobDisplayStyle,
    isMediumScreen,
    isImageVisible,
    mediaThumbUrl,
}: {
    jobDisplayStyle: 'tile' | 'list' | 'toggleListMap' | 'toggleTileMap';
    isMediumScreen: boolean;
    isImageVisible: boolean;
    mediaThumbUrl?: string | null;
}): Record<string, string> => {
    if ((jobDisplayStyle === 'tile' || isMediumScreen) && isImageVisible) {
        return mediaThumbUrl ? { backgroundImage: `url('${mediaThumbUrl}')` } : {};
    }

    return {
        backgroundImage: mediaThumbUrl ? 'none' : 'default',
    };
};
