import FormValidator from 'core/form/validator/Validator';

export default class SameYearFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.section-experience.same-year-validator-message';

        Object.assign(this._options, { withLabel: this._options.withLabel });
    }

    _validate(value, options) {
        const valueA = value;
        const valueB = options.with.value();

        if (!valueA || !valueB) {
            return true;
        }

        const [yearA] = String(valueA).split('-');
        const [yearB] = String(valueB).split('-');

        return yearA === yearB;
    }

}