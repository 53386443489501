import { DRAFTS_URL } from 'apply-flow/service/applicationDraft';

export function maskDraftContent({ url, body, status, method }) {
    if (status !== 200 || !url.includes(DRAFTS_URL) || method !== 'PATCH') {
        return;
    }

    if (body.Content) {
        body.Content = '***';
    }
}