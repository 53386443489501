import notificationsService from 'cx/service/notifications';
import { findBlockTitle } from './blockTitleFinder';

export default {
    createError(sectionId, restResponse) {
        const { statusCode, response } = restResponse;

        if (statusCode === 400 && response) {
            const blockTitle = findBlockTitle(sectionId);
            const errorMessage = blockTitle ? `${blockTitle}: ${response}` : response;

            return { validationError: errorMessage };
        }

        return restResponse;
    },

    handleValidationError({ validationError }) {
        notificationsService.error(validationError, 0, false);
    },

    isValidationError(error) {
        return Boolean(error?.validationError);
    },
};