import ko from 'knockout';
import formBuilder from 'core/form/formBuilder';
import profileItemsFormBuilder from 'apply-flow/module/profile-items/service/profileItemsFormBuilder';
import LicenseFormTileStrategy from '../model/LicenseFormTileStrategy';
import TileForm from 'apply-flow/module/tile-profile-items/form/TileForm';
import LicenseForm from 'apply-flow/module/licenses-and-certificates/service/LicenseForm';
import i18n from 'core/i18n/i18n';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            this._setSearchTypeNameElement(config);
            this._setSearchTypeEstablishmentElement(config);
            this._setUrlTypeNameElement(config);

            const licenseElement = this._getElementFromConfig(config, 'contentItemId');

            const titleElement = this._getElementFromConfig(config, 'title');

            config.elements = config.elements.map((element) => {
                element.validateImmediately = config.validateImmediately;

                return formBuilder.createFormElement(element);
            });

            let form;

            if (licenseElement && !licenseElement.isHidden && titleElement && !titleElement.isHidden) {
                form = new LicenseForm(LicenseFormTileStrategy, config);
            } else {
                form = new TileForm(LicenseFormTileStrategy, config);
            }

            form.bindComponent('license-form');

            this._issueDateCannotBeLaterThanExpiryDate(form);

            form.legend(i18n('apply-flow.profile-item.add-license-button'));

            return profileItemsFormBuilder.createForm(config, form);
        },
    },

    _setSearchTypeNameElement: {
        value(config) {
            const element = ko.utils.arrayFirst(config.elements, formElement => formElement.name === 'contentItemId');

            if (element) {
                element.type = 'search';

                element.optionsKeys = {
                    value: 'contentItemId',
                    label: 'name',
                };
            }
        },
    },

    _setSearchTypeEstablishmentElement: {
        value(config) {
            const element = ko.utils.arrayFirst(config.elements, formElement => formElement.name === 'educationalEstablishmentId');

            if (element) {
                element.type = 'search';

                element.optionsKeys = {
                    value: 'contentItemId',
                    label: 'name',
                };
            }
        },
    },

    _getElementFromConfig: {
        value(config, elementName) {
            return config.elements
                .filter(element => element.name === elementName)
                .shift();
        },
    },

    _setUrlTypeNameElement: {
        value({ elements }) {
            const certificationsUrl = ko.utils.arrayFirst(elements, element => element.name === 'certificationUrl');

            if (certificationsUrl) {
                certificationsUrl.type = 'url';
            }
        },
    },

    _issueDateCannotBeLaterThanExpiryDate: {
        value(form) {
            const expiryDate = form.getElement('expirationDate');
            const issueDate = form.getElement('issueDate');

            if (expiryDate && issueDate) {
                issueDate.validators({
                    beforeDate: {
                        maxDateFormElement: expiryDate,
                        inclusive: true,
                        pairValidationElement: expiryDate,
                        pairValidationValidator: 'afterDate',
                    },
                });

                expiryDate.validators({
                    afterDate: {
                        minDateFormElement: issueDate,
                        inclusive: true,
                        pairValidationElement: issueDate,
                        pairValidationValidator: 'beforeDate',
                    },
                });
            }
        },
    },
});