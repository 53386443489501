import Facet from './Facet';
import { encodePipeQueryString } from '../../../../../service/pipeQueryStringEncoder';

export default class FlexFieldsFacet extends Facet {

    init(facet) {
        super.init(facet);

        this.title = facet.title;
        this.paramKey = 'selectedFlexFieldsFacets';
        this.paramValueBuilder = encodePipeQueryString;
    }

}
