import RegulatoryViewModel from 'apply-flow/module/regulatory/RegulatoryViewModel';
import DiversityMetadataService from 'apply-flow/module/regulatory/module/diversity/service/DiversityMetadata';

export default class DiversityViewModel extends RegulatoryViewModel {

    constructor(params) {
        super(params, new DiversityMetadataService(params.metadataServiceUrl));
    }

}
