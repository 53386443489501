import SearchFormElement from 'core/form/element/Search';
import dictionaryService from 'apply-flow/service/dictionary';
import RequiredWorkLocationsFormValidator from '../validator/RequiredWorkLocations';

export default class WorkLocationSelectElement extends SearchFormElement {

    constructor({ label, options, optionsDisabled, fields, allLocations }) {
        super('');

        this.label(label);
        this.fields = fields;
        this.allLocations = allLocations;
        this.dictionary(dictionaryService.CODES.profileLocations);

        this.optionsKeys({
            value: 'locationId',
            label: 'locationName',
        });

        this.options(options);
        this.optionsDisabled = optionsDisabled;
        this.validators({});
    }

    _setValidators() {
        const requiredLocationsValidator = new RequiredWorkLocationsFormValidator({
            label: this.label(),
            min: this._countRequiredFields(),
            allLocations: this.allLocations,
        });

        this.addValidator(requiredLocationsValidator);

        return this;
    }

    _countRequiredFields() {
        return this.fields
            .filter(field => field.attributes.required)
            .length;
    }

    getLabel(locationCode) {
        if (!locationCode) {
            return Promise.resolve();
        }

        if (this.valueLabel()) {
            return Promise.resolve(this.valueLabel());
        }

        return this.getOption(locationCode)
            .then(option => option.meaning);
    }

    _filterOptions(query) {
        return dictionaryService.findWithFinder({
            code: this.dictionary(),
            criteria: {
                LocationName: query,
            },
            finder: 'findByLocationName',
        });
    }

    getOption(value) {
        if (!value) {
            return Promise.resolve();
        }

        return dictionaryService.findWithFinder({
            code: this.dictionary(),
            criteria: {
                LocationId: value,
            },
            finder: 'findByLocationId',
        })
            .then(([option]) => this._saveOptions(option));
    }

}