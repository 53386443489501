// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapping from 'knockout-mapping';
import i18n from 'core/i18n/i18n';

import appConfig from 'app/model/config';
import cookieStorage from 'core/cookie/storage';

import signals, {
    USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL,
    USER_ACCEPTED_COOKIE_CONSENT_CUSTOM_CATEGORY,
    USER_ACCEPTED_COOKIE_CONSENT_FUNCTIONAL,
    USER_DECLINED_COOKIE_CONSENT_ANALYTICAL,
    USER_DECLINED_COOKIE_CONSENT_CUSTOM_CATEGORY,
    USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL,
} from 'cx/config/userEvents';
import { observable } from 'knockout';
import mapper from 'cx/component/cookie-consent/mapper/cookies';

const COOKIE_CONSENT_NAME_FUNCTIONAL = `${appConfig.siteNumber}_cookieAccept_functional`;
const COOKIE_CONSENT_NAME_ANALYTICAL = `${appConfig.siteNumber}_cookieAccept_analytical`;
const COOKIE_CONSENT_NAME_CUSTOM = `${appConfig.siteNumber}_cookieAccept_custom`;
const COOKIE_CONSENT_DECLINE_ALL = `${appConfig.siteNumber}_cookieDeclineAll`;
const COOKIE_USER_TRACKING = 'ORA_CX_USERID';
const COOKIE_USER_TRACKING_FUNCTIONAL = 'ORA_CX_USERID_FUNCTIONAL';

export const isLoadingTrackingData = observable(false);
export const isLoadingTrackingDataFunctional = observable(false);

export type COOKIE_CONSENT_SIGNAL_TYPES =
    | typeof USER_ACCEPTED_COOKIE_CONSENT_FUNCTIONAL
    | typeof USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL
    | typeof USER_ACCEPTED_COOKIE_CONSENT_CUSTOM_CATEGORY
    | typeof USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL
    | typeof USER_DECLINED_COOKIE_CONSENT_ANALYTICAL
    | typeof USER_DECLINED_COOKIE_CONSENT_CUSTOM_CATEGORY;

export const isCookiePreferencesVisible = observable(false);

export const isCookiePolicyDialogVisible = observable(false);

export const cookieConfig = mapping.fromJS(mapper.mapCookiesFromConfig(appConfig.cookieConsent || {}));

export const isCookieConsentVisible = observable(false);

export const acceptCategory = (
    categoryCookieName: string,
    categorySignalToBeDispatched: COOKIE_CONSENT_SIGNAL_TYPES,
    userIdCookie?: string | null
): void => {
    cookieStorage.set(categoryCookieName, 'true', { days: 90 });
    cookieStorage.remove(COOKIE_CONSENT_DECLINE_ALL);

    signals[categorySignalToBeDispatched].dispatch(userIdCookie);
};

export const declineCategory = (
    categoryCookieName: string,
    categorySignalToBeDispatched: COOKIE_CONSENT_SIGNAL_TYPES
): void => {
    cookieStorage.remove(categoryCookieName);

    signals[categorySignalToBeDispatched].dispatch();
};

export const setDeclineAllCookie = (): void => {
    if (
        !isCookieCategoryAllowed(COOKIE_CONSENT_NAME_FUNCTIONAL) &&
        !isCookieCategoryAllowed(COOKIE_CONSENT_NAME_ANALYTICAL) &&
        !isCookieCategoryAllowed(COOKIE_CONSENT_NAME_CUSTOM)
    ) {
        cookieStorage.set(COOKIE_CONSENT_DECLINE_ALL, 'true', { days: 90 });
    }
};

export const isCookieCategoryAllowed = (category: string): boolean => {
    if (!cookieConfig?.isCookieConsentEnabled?.()) {
        return true;
    } else if (cookieConfig?.isCookieConsentEnabled()) {
        return cookieStorage.get(category) === 'true';
    } else {
        return true;
    }
};

export const hasUserAcceptedAnyCategory = (): boolean =>
    cookieStorage.get(COOKIE_CONSENT_NAME_FUNCTIONAL) === 'true' ||
    cookieStorage.get(COOKIE_CONSENT_NAME_ANALYTICAL) === 'true' ||
    cookieStorage.get(COOKIE_CONSENT_NAME_CUSTOM) === 'true';

export const hasUserDeclinedAllCategory = (): boolean =>
    cookieStorage.get(COOKIE_CONSENT_DECLINE_ALL) === 'true';

export const acceptAll = (): void => {
    acceptFunctionalCookies();
    acceptAnalyticalCookies();
    acceptCustomCategoryCookies();
};

export const closeCookieConsentDialogs = (): void => {
    isCookiePreferencesVisible(false);
    isCookiePolicyDialogVisible(false);
    isCookieConsentVisible(false);
};

export const acceptFunctionalCookies = (userIdCookie?: string | null): void => {
    acceptCategory(COOKIE_CONSENT_NAME_FUNCTIONAL, USER_ACCEPTED_COOKIE_CONSENT_FUNCTIONAL, userIdCookie);
};

export const acceptAnalyticalCookies = (userIdCookie?: string | null): void => {
    acceptCategory(COOKIE_CONSENT_NAME_ANALYTICAL, USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL, userIdCookie);
};

export const acceptCustomCategoryCookies = (): void => {
    if (cookieConfig?.isCookiePreferencesEnabled?.() && cookieConfig?.isCustomCategoryActive?.()) {
        acceptCategory(COOKIE_CONSENT_NAME_CUSTOM, USER_ACCEPTED_COOKIE_CONSENT_CUSTOM_CATEGORY);
    }
};

export const declineFunctionalCookies = (): void => {
    declineCategory(COOKIE_CONSENT_NAME_FUNCTIONAL, USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL);
    setDeclineAllCookie();
};

export const declineAnalyticalCookies = (): void => {
    declineCategory(COOKIE_CONSENT_NAME_ANALYTICAL, USER_DECLINED_COOKIE_CONSENT_ANALYTICAL);
    setDeclineAllCookie();
};

export const declineCustomCategoryCookies = (): void => {
    declineCategory(COOKIE_CONSENT_NAME_CUSTOM, USER_DECLINED_COOKIE_CONSENT_CUSTOM_CATEGORY);
    setDeclineAllCookie();
};

export const declineAll = (): void => {
    declineFunctionalCookies();
    declineAnalyticalCookies();
    declineCustomCategoryCookies();
};

export const isFunctionalCookieAllowed = (): boolean =>
    isCookieCategoryAllowed(COOKIE_CONSENT_NAME_FUNCTIONAL);

export const isAnalyticalCookieAllowed = (): boolean =>
    isCookieCategoryAllowed(COOKIE_CONSENT_NAME_ANALYTICAL);

export const isCustomCategoryCookieAllowed = (): boolean =>
    isCookieCategoryAllowed(COOKIE_CONSENT_NAME_CUSTOM);

export const showCookiePolicyOrPreferenceDialog = (): void => {
    if (cookieConfig.isCookiePreferencesEnabled()) {
        isCookiePreferencesVisible(true);
    } else {
        isCookiePolicyDialogVisible(true);
    }
};

export const savePreferences = ({
    isFunctionalCookieEnabled,
    isAnalyticalCookieEnabled,
    isCustomCookieCategoryEnabled,
}: {
    isFunctionalCookieEnabled: boolean;
    isAnalyticalCookieEnabled: boolean;
    isCustomCookieCategoryEnabled: boolean;
}): void => {
    const userIdCookie =
        cookieStorage.get(COOKIE_USER_TRACKING) || cookieStorage.get(COOKIE_USER_TRACKING_FUNCTIONAL);

    isFunctionalCookieEnabled ? acceptFunctionalCookies(userIdCookie) : declineFunctionalCookies();
    isAnalyticalCookieEnabled ? acceptAnalyticalCookies(userIdCookie) : declineAnalyticalCookies();
    isCustomCookieCategoryEnabled ? acceptCustomCategoryCookies() : declineCustomCategoryCookies();
};

export function setCookieConfigWithDefaultText(): void {
    cookieConfig.acceptButtonLabel?.() ?? cookieConfig.acceptButtonLabel?.(i18n('general.accept-button'));

    cookieConfig.declineButtonLabel?.() ?? cookieConfig.declineButtonLabel?.(i18n('general.decline-button'));

    cookieConfig.cookieLinkText?.() ?? cookieConfig.cookieLinkText?.(i18n('cookie-consent.learn-more'));

    cookieConfig.cookiePreferenceButtonText?.() ??
        cookieConfig.cookiePreferenceButtonText?.(
            i18n(
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.cookie-preferences-button-default'
            )
        );

    cookieConfig.cookiePreferenceSaveButtonText?.() ??
        cookieConfig.cookiePreferenceSaveButtonText?.(
            i18n(
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.cookie-preferences-save-button-default'
            )
        );

    cookieConfig.cookiePreferenceStrictlyNecessaryDescription?.() ??
        cookieConfig.cookiePreferenceStrictlyNecessaryDescription?.(
            i18n(
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.strictly-necessary-cookies-description-default'
            )
        );

    cookieConfig.cookiePreferenceFunctionalDescription?.() ??
        cookieConfig.cookiePreferenceFunctionalDescription?.(
            i18n(
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.functional-cookies-description-default'
            )
        );

    cookieConfig.cookiePreferenceAnalyticalDescription?.() ??
        cookieConfig.cookiePreferenceAnalyticalDescription?.(
            i18n(
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.analytical-performace-cookies-description-default'
            )
        );
}
