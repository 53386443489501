import { createValidatorsOptions } from 'apply-flow/module/extra-information/service/validatorOptions';

const TYPES_MAP = {
    TextArea: 'textarea',
    RichTextArea: 'textarea',
    Checkbox: 'checkboxStringValue',
    SingleChoiceList: 'select',
    SingleChoiceRadio: 'radio',
    MultipleChoiceCheck: 'multiselect',
    DatePicker: 'date',
    Integer: 'integer',
    Decimal: 'decimal',
};

const US_LEGILSATION_CODE = 'US';
const DISABILITY_STATUS_ATTRIBUTE_CODE = 'ORA_DISABILITY_STATUS';
const specialSupportedFormats = ['DatePicker', 'TextArea', 'Checkbox', 'Decimal', 'text', 'Integer'];

function _getFormElementType(restType) {
    return TYPES_MAP[restType] || 'text';
}

function _createFormElement(restFormElement, legislationCode) {
    const formElement = {
        type: _getFormElementType(restFormElement.displayTypeCode),
        name: _generateFormName(restFormElement, legislationCode),
        label: restFormElement.displayPrompt,
        defaultValue: restFormElement.defaultValue,
        isHidden: restFormElement.hiddenFlag,
        isLabelHidden: _isLabelHidden(restFormElement),
        value: restFormElement.defaultValue,
        attributes: {
            readonly: restFormElement.readonlyFlag,
            required: restFormElement.requiredFlag,
        },
        options: restFormElement.options,
    };

    if (restFormElement.displayTypeCode === 'Checkbox' && restFormElement.checkboxCheckedValue && restFormElement.checkboxUncheckedValue) {
        formElement.checked = restFormElement.checkboxCheckedValue;
        formElement.unchecked = restFormElement.checkboxUncheckedValue;
    }

    formElement.validators = createValidatorsOptions(restFormElement, formElement);

    return formElement;
}

function _isLabelHidden(restFormElement) {
    return restFormElement.legislationCode === US_LEGILSATION_CODE
        && restFormElement.attributeCode === DISABILITY_STATUS_ATTRIBUTE_CODE;
}

function _processAttributeOptions(options) {
    const optionsList = [];

    if (options === null) {
        return optionsList;
    }

    let optionsStr = options.substring(1, options.length - 1);

    if (optionsStr !== '') {
        optionsStr = optionsStr.split('||');

        optionsStr.forEach((option) => {
            const optionStr = option.split('=');

            optionsList.push({
                lookupCode: optionStr[0],
                meaning: optionStr[1],
            });
        });
    }

    return optionsList;
}

function _createAttribute(attribute, optionsArray) {
    return Object.assign(attribute, {
        hiddenFlag: (attribute.hiddenFlag === 'true'),
        isRequired: (attribute.requiredFlag === 'true') ? 'Y' : 'N',
        maximumLength: (attribute.attributeCode === 'ACCOMMODATION_REQUEST') ? '2000' : attribute.maximumLength,
        isFlexField: (attribute.attributeType === 'DDF' || attribute.attributeType === 'DFF'),
        flexFieldType: attribute.attributeType,
        options: optionsArray,
    });
}

function _isSpecialSupportedType(type) {
    return specialSupportedFormats.includes(type);
}

function _generateFormName(restFormElement, legislationCode) {
    return `${legislationCode}-` +
            `${restFormElement.isFlexField ? restFormElement.flexFieldType : 'STANDARD'}-` +
            `${restFormElement.isFlexField ? restFormElement.attributeName : restFormElement.attributeCode}-` +
            `${restFormElement.isFlexField ? restFormElement.segmentColumnName : 'STANDARD'}`;
}

export default {
    reformatFormElementsFromRest(restData) {
        const data = {};

        data.items = [];

        if (restData.items.length !== 0) {
            // Create block entry
            data.items.push({
                afBlockCode: restData.items[0].afblockCode,
                legislations: [],
            });
        }

        const legislationList = [];

        restData.items.forEach((attribute) => {
            const legislationIndex = legislationList.indexOf(attribute.legislationCode);
            const optionsArray = _processAttributeOptions(attribute.options);

            if (optionsArray.length || _isSpecialSupportedType(attribute.displayTypeCode)) {
                if (legislationIndex === -1) {
                    legislationList.push(attribute.legislationCode);

                    // Create legislation metadata
                    data.items[0].legislations.push({
                        legislationCode: attribute.legislationCode,
                        header: attribute.headerDescription,
                        footer: attribute.footerDescription,
                        legislationName: attribute.legislationName,
                        attributes: new Array(_createAttribute(attribute, optionsArray)),
                    });
                } else {
                    data.items[0].legislations[legislationIndex].attributes.push(
                        _createAttribute(attribute, optionsArray));
                }
            }
        });

        // 20-B: Push US form attributes to the end
        const usLegislationIndex = legislationList.indexOf(US_LEGILSATION_CODE);

        if (usLegislationIndex !== -1) {
            const [item] = data.items;
            const [usLegislation] = item.legislations.splice(usLegislationIndex, 1);

            item.legislations.push(usLegislation);
        }

        return data;
    },

    mapFormElementsFromRest(restData) {
        return restData.items.map(restFormElements => ({
            blockCode: restFormElements.afBlockCode,
            legislations: restFormElements.legislations.map(this.mapLegislationFromRest),
        }));
    },

    mapLegislationFromRest(restFormLegislationElement) {
        const legislationElement = {
            legislationCode: restFormLegislationElement.legislationCode,
            legislationName: restFormLegislationElement.legislationName,
            header: restFormLegislationElement.header,
            footer: restFormLegislationElement.footer,
            formElementsDef: restFormLegislationElement.attributes.map(
                restFormElement =>
                    _createFormElement(restFormElement, restFormLegislationElement.legislationCode),
            ),
        };

        return legislationElement;
    },
};