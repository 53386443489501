export default {
    ORA_DIVERSITY: {
        blockCode: 'ORA_DIVERSITY',
        componentName: 'diversity',
    },
    ORA_VETERAN: {
        blockCode: 'ORA_VETERAN',
        componentName: 'veteran',
    },
    ORA_DISABILITY: {
        blockCode: 'ORA_DISABILITY',
        componentName: 'disability',
    },
};