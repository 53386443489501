import i18n from 'core/i18n/i18n';
import store from '../../../store/store';
import { SEARCH_PAGE, SPLASH_PAGE, TYPE_NAMES, JOB_DETAILS } from '../../../enum/pageTypes';
import splashPageTemplates from '../config/splashPageTemplates';
import searchPageTemplates from '../config/searchPageTemplates';
import jobDetailsTemplates from '../config/jobDetailsTemplates.ts';
import pageTemplates from '../config/pageTemplates';
import sectionMapper from '../../../mapper/section';
import { cookieConsentTemplates } from '../config/cookieConsentTemplates';
import { cookiePolicyTemplates } from '../config/cookiePolicyTemplates';
import { cookiePreferenceTemplates } from '../config/cookiePreferencesTemplates';
import { COOKIE_CONSENT_WIDGET, COOKIE_POLICY_WIDGET, COOKIE_PREFERENCES_WIDGET } from '../../../enum/widgetTypes';

export function getOptions(availableTypes) {
    const template = store.site.templateNumber();

    return availableTypes[template].map(templateId => ({
        value: templateId,
        label: i18n(TYPE_NAMES[templateId]),
    }));
}

export function getSections(type, templateNumber) {
    switch (type) {
        case SPLASH_PAGE:
            return splashPageTemplates[templateNumber].sections;

        case SEARCH_PAGE:
            return searchPageTemplates.search.sections;

        case JOB_DETAILS:
            return jobDetailsTemplates.jobDetails.sections;

        case COOKIE_CONSENT_WIDGET:
            return cookieConsentTemplates.cookieConsentStyleNarrow.sections;

        case COOKIE_PREFERENCES_WIDGET:
            return cookiePreferenceTemplates.cookiePreferences.sections;

        case COOKIE_POLICY_WIDGET:
            return cookiePolicyTemplates.cookiePolicy.sections;

        default:
            return pageTemplates.empty.sections;
    }
}

export function getSectionsTemplate(type, templateNumber) {
    const sections = getSections(type, templateNumber);

    return sectionMapper.mapSectionsFromRest(sections);
}