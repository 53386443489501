import appConfig from 'app/model/config';
import recruitingLocationService from 'cx/module/recruiting-location/service/recruitingLocation';
import ApplyFlowBlockComponentViewModel from 'apply-flow/module/common/ApplyFlowBlockComponentViewModel';
import jobAlertPreferencesModel from './model/jobAlertPreferencesModel';
import jobAlertPreferencesFormBuilder from './form/jobAlertPreferencesFormBuilder';
import { get } from 'apply-flow/service/genericDictionary';
import jobAlertsService from '../../../job-alerts/service/jobAlerts';

export default class JobAlertPreferencesViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        this.siteNumber = appConfig.siteNumber;
        this.jobAlertPreferencesConfig = appConfig.getSettingByKey('ORA_IRC_TC_ACTIVE_ATTRIBUTES');
        this.jobFunctions = [];

        this._initialize();
    }

    fetchJobFamilies(term) {
        const payload = {
            siteNumber: this.siteNumber,
            term,
        };

        return jobAlertsService.getJobFamiliesByTerm(payload);
    }

    fetchJobLocations(term) {
        const payload = {
            siteNumber: this.siteNumber,
            term,
        };

        return recruitingLocationService.getLocationsByTerm(payload);
    }

    async _initialize() {
        this.jobFunctions = await this._fetchJobFunctions();
        this._createForm();
        this._registerForm();
        this._setBlockReady();
    }

    _fetchJobFunctions() {
        return get('JOB_FUNCTION_CODE', 'findByLookupTypeAndSite', appConfig.siteNumber);
    }

    _createForm() {
        const form = jobAlertPreferencesFormBuilder.createForm(
            this.jobAlertPreferencesConfig,
            jobAlertPreferencesModel,
            this.fetchJobFamilies.bind(this),
            this.fetchJobLocations.bind(this),
            this.jobFunctions,
        );

        this.form(form);
    }

}
