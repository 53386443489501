import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';

const MOVE_TRESHOLD = -0.03;
const CONVERGENCE_RATE = 0.93;

const viewportCenter = {
    x: recalculateViewportCenter().x,
    y: recalculateViewportCenter().y,
};

let pageX = 0;
let pageY = 0;

let pxRatio = window.devicePixelRatio;

function mouseMoveHandler(event) {
    pageX = MOVE_TRESHOLD * (event.clientX - viewportCenter.x);
    pageY = MOVE_TRESHOLD * (event.clientY - viewportCenter.y);
}

function resizeHandler() {
    const newPxRatio = window.devicePixelRatio;

    if (hasPixelRatioChanged(newPxRatio)) {
        pxRatio = newPxRatio;
        viewportCenter.x = recalculateViewportCenter().x;
        viewportCenter.y = recalculateViewportCenter().y;
    }
}

function recalculateViewportCenter() {
    return {
        x: parseInt(document.body.clientWidth / 2, 10),
        y: parseInt(document.body.clientHeight / 2, 10),
    };
}

function hasPixelRatioChanged(newPixelRatio) {
    return newPixelRatio !== pxRatio;
}

ko.bindingHandlers.mouseParalax = {

    init(element) {
        let currentOffsetX = pageX;
        let currentOffsetY = pageY;

        let isLoopActive = true;

        // Paralax should be disabled when site can be accessed by touch only
        if (!screenInfo.hasPointingDevice) {
            return;
        }

        function nextFrame() {
            currentOffsetX = pageX - ((pageX - currentOffsetX) * CONVERGENCE_RATE);
            currentOffsetY = pageY - ((pageY - currentOffsetY) * CONVERGENCE_RATE);

            const diffX = Math.abs(currentOffsetX - pageX);
            const diffY = Math.abs(currentOffsetY - pageY);

            // discard applying transform what will be bearly visible
            if (diffX + diffY > 0.1) {
                element.style.transform = `translate3d(${currentOffsetX}px, ${currentOffsetY}px, 0)`;
            }

            if (isLoopActive) {
                window.requestAnimationFrame(nextFrame);
            }
        }

        document.body.addEventListener('mousemove', mouseMoveHandler);
        window.addEventListener('resize', resizeHandler);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            isLoopActive = false;
            document.body.removeEventListener('mousemove', mouseMoveHandler);
            window.removeEventListener('resize', resizeHandler);
        });

        nextFrame();
    },
};

