import '../component/attachment-upload-button/component';
import '../component/attachment-preuploaded/component';
import '../component/referral-resume-block/component';
import '../module/misc-attachments/config/module';
import 'cx/module/apply-flow/module/file-upload/extender/elementsLivingTime';

import { components } from 'knockout';

import metadataConfig from 'cx/module/apply-flow/config/metadata';

import ViewModel from 'apply-flow/module/file-upload/FileUploadViewModel';
import template from '../file-upload.html';

components.register('file-upload', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_DOCUMENTS', {
    component: 'file-upload',
});
