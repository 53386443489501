import Media from 'job-details/model/Media';
import { isMapEnabled } from 'ce-common/module/maps/service/isMapEnabled';

function sortByPriority(first, second) {
    return first.priority - second.priority;
}

function generateMediaMapElement(markers) {
    return new Media({
        type: 'Map',
        priority: -1,
        markers,
    });
}

export default {
    mapFromRest(mediaArr, markers = []) {
        const mappedMedia = mediaArr
            .sort(sortByPriority)
            .map(mediaData => new Media(mediaData));

        if (isMapEnabled() && markers.length) {
            mappedMedia.unshift(generateMediaMapElement(markers));
        }

        return mappedMedia;
    },
};