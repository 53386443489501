import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { getTypographyCss } from 'minimal/module/search/service/customCss';
import { JobDetailsLocationParams } from './config/types';
import { PureComputed, pureComputed } from 'knockout';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsLocationParams;
    id?: string;
};

export class JobDetailsLocationViewModel extends CustomComponentViewModel<JobDetailsLocationParams> {
    pageData: PureComputed<JobDetailsPageData>;
    showSecondaryLocations: PureComputed<boolean>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;

        this.showSecondaryLocations = pureComputed(() => {
            const locations = pageData().job.secondaryLocations;

            return Boolean(locations && locations.length > 0);
        });
    }

    getCustomStyle(key: keyof JobDetailsLocationParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'titleTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-details__subtitle`,
                    typography: this.customizationParams[key],
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
