export const searchRequisitionsFinderParams = [
    'facetsList',
    'jobFamilyId',
    'keyword',
    'correctedKeyword',
    'useExactKeywordFlag',
    'lastSelectedFacet',
    'latitude',
    'limit',
    'location',
    'locationId',
    'longitude',
    'offset',
    'organizationId',
    'postingEndDate',
    'postingStartDate',
    'radius',
    'radiusUnit',
    'selectedCategoriesFacet',
    'selectedFlexFieldsFacets',
    'selectedLocationsFacet',
    'selectedOrganizationsFacet',
    'selectedPostingDatesFacet',
    'selectedTitlesFacet',
    'selectedWorkLocationsFacet',
    'siteNumber',
    'sortBy',
    'userTargetFacetInputTerm',
    'userTargetFacetName',
    'workLocationCountryCode',
    'workLocationZipCode',
    'hotJobFlag',
    'selectedWorkplaceTypesFacet',
    'executeSpellCheckFlag',
    'workLocationsDisplay',
];

export const similarRequisitionsFinderParams = [
    'candidateNumber',
    'limit',
    'offset',
    'requisitionId',
    'siteNumber',
];

export const searchEventsFinderParams = [
    'facetsList',
    'keyword',
    'lastSelectedFacet',
    'limit',
    'locationId',
    'offset',
    'selectedEventCategoriesFacet',
    'selectedLocationsFacet',
    'selectedStartDatesFacet',
    'selectedEventFormatsFacet',
    'siteNumber',
    'sortBy',
    'userTargetFacetInputTerm',
    'userTargetFacetName',
    'latitude',
    'longitude',
    'radius',
    'radiusUnit',
];
