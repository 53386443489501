export default class UnscheduledInterviewRequest {

    constructor(data = {}) {
        this.interviewRequestId = data.interviewRequestId;
        this.interviewId = data.interviewId;
        this.submissionId = data.submissionId;
        this.scheduleId = data.scheduleId;
        this.isSchedulable = data.isSchedulable;
    }

}