import { components } from 'knockout';

import ViewModel from './SearchFiltersPanelViewModel';
import StaticViewModel from './SearchFiltersPanelStaticViewModel';
import template from './search-filters-panel.html';

components.register('search-filters-panel', { viewModel: ViewModel, template });

components.register('cc-search-filters-panel', {
    viewModel: {
        createViewModel({ params, mode, id }) {
            if (mode === 'admin') {
                return new StaticViewModel({ params, id });
            }

            return new ViewModel({ params, id });
        },
    },
    template,
});
