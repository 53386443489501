import menuService from '../../service/menu';
import { observable } from 'knockout';

export default class AppMenuToggleViewModel {

    constructor({ uniqueWrapperClass } = {}) {
        this.isVisible = menuService.isVisible;
        this.uniqueWrapperClass = observable(uniqueWrapperClass);
    }

    open() {
        menuService.open(this.uniqueWrapperClass());
    }

}
