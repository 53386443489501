import ko from 'knockout';
import httpLangCached from 'cx/service/http/httpLangCached';
import httpUrlInterpolator from 'core/http/urlInterpolator';
import dictionaryMapper from 'apply-flow/mapper/dictionary';

export const BASE_URL = 'recruitingCEDictionaries/1/lov/';

export const MAX_DICTIONARY_ITEMS = 500;

const DEFAULT_URL_PARAMS = {
    onlyData: 'true',
    limit: MAX_DICTIONARY_ITEMS,
};

const DEFAULT_SORT_OPTION = {
    countries: 'TerritoryShortName',
    profileCountries: 'GeographyName',
    profileStateProvinces: 'GeographyDisplayName',
};

function _get(url, dictionaryParams) {
    const concatSign = (url.indexOf('?') !== -1 ? '&' : '?');
    const dictionaryParamsAsStr = httpUrlInterpolator.encode(dictionaryParams);

    return httpLangCached.get(`/${url}${concatSign}${dictionaryParamsAsStr}`, {
        headers: {
            'REST-Framework-Version': 2,
        },
    }).then(dictionaryMapper.mapDictionariesFromRest);
}

function _getDefaultSortOption(url) {
    return Object.keys(DEFAULT_SORT_OPTION)
        .filter(dictionaryCode => url.indexOf(dictionaryCode) !== -1)
        .reduce((defaultValue, dictionaryCode) => defaultValue || DEFAULT_SORT_OPTION[dictionaryCode], '');
}

export default {
    CODES: {
        nameTitles: 'nameTitles',
        educationInstitutions: 'educationInstitutions',
        educationLevels: 'educationLevels',
        attachmentCategories: 'attachmentCategories',
        countries: 'countries',
        degrees: 'profileContentItems?finder=findByContentTypeId;ContentTypeId=106',
        profileCountries: 'profileCountries',
        profileStateProvinces: 'profileStateProvinces?finder=findByCountryCode',
        profileLocations: 'profileLocations',
        phoneCountryCodes: 'phoneCountryCodes',
    },

    get(dictionaryUrl, dictionaryParams) {
        let url = dictionaryUrl;

        if (url.indexOf(BASE_URL) === -1 && url.indexOf('ExtraInformationDictionary') === -1) {
            url = BASE_URL + url;
        }

        const defaultParams = ko.utils.extend({
            orderBy: _getDefaultSortOption(url),
        }, DEFAULT_URL_PARAMS);

        return _get(url, ko.utils.extend(defaultParams, dictionaryParams));
    },

    find(code, criteriaObj, dictionaryParams) {
        const criteriaAsStr = Object.keys(criteriaObj).map((key) => {
            const value = criteriaObj[key];

            return `${key}='${typeof value === 'string' ? value?.replace(/'/g, '\'\'') : value}'`;
        }).join(';');

        const params = ko.utils.extend({
            q: criteriaAsStr,
        }, dictionaryParams);

        return this.get(code, params);
    },

    findOne(code, criteriaObj, dictionaryParams) {
        let params = ko.utils.extend({}, dictionaryParams || {});

        params = ko.utils.extend(params, {
            limit: 1,
        });

        return this.find(code, criteriaObj, params).then(results => results.shift());
    },

    findLike(code, criteriaObj, dictionaryParams) {
        const criteriaAsStr = Object.keys(criteriaObj).map(key => `UPPER(${key}) LIKE UPPER('*${criteriaObj[key].replace(/'/g, '\'\'')}*')`).join(' OR ');

        const params = ko.utils.extend({
            q: criteriaAsStr,
        }, dictionaryParams);

        return this.get(code, params);
    },

    findWithFinder({ code, criteria, dictionaryParams, finder = null }) {
        const criteriaAsStr = Object.keys(criteria).map(key => `${key}=${criteria[key]}`).join(';');

        const url = finder
            ? `${code}?finder=${finder};${criteriaAsStr}`
            : `${code};${criteriaAsStr}`;

        return this.get(url, dictionaryParams);
    },

    getByUrl(url) {
        return this.get(url);
    },
};
