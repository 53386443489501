import ko from 'knockout';
import appConfig from 'app/model/config';
import themeEvents from 'cx/module/site-editor/config/events';

ko.bindingHandlers.frontImageBackground = {
    init(element) {
        // TODO remove when backend available in CX_CONFIG
        if (appConfig.images) {
            if (appConfig.images.frontImageUrl) {
                element.style.backgroundImage = `url(${appConfig.images.frontImageUrl})`;
            }

            const frontImageUrlSub = themeEvents.frontImageUrl.add((frontImageUrl) => {
                if (frontImageUrl) {
                    element.style.backgroundImage = `url(${frontImageUrl})`;
                } else {
                    element.style.removeProperty('background-image');
                }
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                frontImageUrlSub.detach();
            });
        }
    },


};