import FormValidator from './Validator';

type CharsetValidatorOptions = {
    charset: string;
    message?: string;
};

export class CharsetValidator extends FormValidator {
    protected _validate(value: string | null, { charset }: CharsetValidatorOptions): boolean {
        if (!value || !charset) {
            return true;
        }

        const regExp = new RegExp(`^([${charset}])+$`, 'g');

        return regExp.test(value);
    }
}
