import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class IntegerFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.validators('integer');
        this.attributes('maxlength', '18');
    }

}