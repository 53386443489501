import converterFactory from 'core/l10n/converter/factory';


export default {
    format(date) {
        return this.formatShort(date);
    },

    formatShort(date) {
        return converterFactory.createDateConverter('short').format(date);
    },

    formatMedium(date) {
        return converterFactory.createDateConverter('medium').format(date);
    },

    formatLong(date) {
        return converterFactory.createDateConverter('long').format(date);
    },

    formatFull(date) {
        return converterFactory.createDateConverter('full').format(date);
    },

    formatMonthYear(date) {
        return converterFactory.createDateConverter('datetime', 'MMMM yyyy').format(date);
    },

    parse(formattedDate) {
        return this.parseShort(formattedDate);
    },

    parseShort(formattedDate) {
        return converterFactory.createDateConverter('short').parse(formattedDate);
    },

    parseMedium(formattedDate) {
        return converterFactory.createDateConverter('medium').parse(formattedDate);
    },

    parseLong(formattedDate) {
        return converterFactory.createDateConverter('long').parse(formattedDate);
    },

    parseFull(formattedDate) {
        return converterFactory.createDateConverter('full').parse(formattedDate);
    },
};