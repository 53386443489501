import { bindingHandlers } from 'knockout';
import screenInfo from '../../../model/screenInfo';

const spacingStyles = [
    'paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft',
    'marginTop', 'marginRight', 'marginBottom', 'marginLeft',
];

function applyMarginAndPadding(element, params) {
    if (!params) {
        return;
    }

    const styles = {};
    const isSmallScreen = screenInfo.isSmall();

    spacingStyles.forEach((style) => {
        styles[style] = isSmallScreen || !params[style]() ? '' : `${params[style]()}${params.additionalOptionsUnit()}`;
    });

    Object.assign(element.style, styles);
}

/**
 * Applies custom styles configured in elements content editor.
 * Supported styles: paddings, margins
 * @param {object} params - params of custom content element - contain configured styles
 *
 * @example
 * <div class="cc-element cc-element-media" data-bind="customStyles: params">
 */

bindingHandlers.customStyles = {
    update(element, valueAccessor) {
        const params = valueAccessor();

        applyMarginAndPadding(element, params);
    },
};