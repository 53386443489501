import ko from 'knockout';
import cxEvents from 'cx/config/events';

export default class ApplyFlowLightViewModel {

    constructor() {
        this.flow = ko.observable({
            isSingleClick: true,
            iterator: ko.observable({
                currentSection: ko.observable(),
            }),
        });

        cxEvents.loaded.dispatch();
    }

}