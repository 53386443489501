import { ObservableArray, unwrap } from 'knockout';
import FormValidator from 'core/form/validator/Validator';

type ArrayItem<Type> = Type | Record<string, Type>;

type Options<Type> = {
    value: Type | null;
    array: ArrayItem<Type>[] | ObservableArray<ArrayItem<Type>>;
    property?: string;
    caseInsensitive?: boolean;
};

export class NotInArrayValidator extends FormValidator {
    constructor(...args: unknown[]) {
        super(...args);

        this._message = 'validators.value-should-be-unique';
    }

    protected _validate<T>(value: T, options: Options<T>): boolean {
        if (!value) {
            return true;
        }

        const check = (item: ArrayItem<T>): boolean => {
            let itemToCheck: T | string = options.property
                ? unwrap((item as Record<string, T>)[options.property])
                : unwrap(item as T);

            if (options.caseInsensitive) {
                itemToCheck = String(itemToCheck).toLowerCase();
            }

            return itemToCheck === valueToCheck;
        };

        // Placeholder for validation message translation
        options.value = value;

        const array = unwrap(options.array);
        let valueToCheck = Array.isArray(value) ? value[0] : value;

        if (options.caseInsensitive) {
            valueToCheck = String(valueToCheck).toLowerCase();
        }

        return !array.find(check);
    }
}
