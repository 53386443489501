import profileItemsInstance from 'apply-flow/module/profile-items/service/profileItemsInstance';
import { PROFILE_ITEMS_MAP } from 'apply-flow/module/profile-items/enum/profileItems';

const PII_COLLECTION_NAME = 'personalIdentifyingInformationItems';

export function recalculateProfileItemsId(candidate) {
    Object.values(PROFILE_ITEMS_MAP)
        .forEach(profileItemsCollectionName => processProfileItemsCollection(candidate[profileItemsCollectionName]));

    processProfileItemsCollection(candidate[PII_COLLECTION_NAME]);
}

function processProfileItemsCollection(profileItems) {
    if (!profileItems) {
        return;
    }

    for (const profileItem of profileItems) {
        profileItem.id = profileItemsInstance.next();
    }
}