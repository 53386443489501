import i18n from 'app/module/core/i18n/i18n';
import { SEARCH_RESULTS_STRINGS } from 'app/module/cx/module/search/config/types';
import { observable } from 'knockout';

export default class ShareWidgetViewModel {

    constructor({
        id,
        showCopyLink,
        context,
        lang,
        hasInitialFocus,
    }) {
        this.isActive = observable(false);

        this.showCopyLink = showCopyLink;
        this.id = id;
        this.context = context;
        this.shareLinkText = i18n(context === SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS ? 'event-details.share-this-event-link' : 'job-details.share-this-job-link');
        this.lang = lang;
        this.hasInitialFocus = hasInitialFocus;
    }

    show(context, event) {
        event.stopPropagation();
        this.isActive(true);
    }

    hide(context, event) {
        event.stopPropagation();
        this.isActive(false);
    }

    toggleVisibility(context, event) {
        return this.isActive() ? this.hide(context, event) : this.show(context, event);
    }

}