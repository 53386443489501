import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class TextFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.validators('maxLength', {
            max: 2000,
        });

        this.validators('html');
        this.validators('xss');
    }

}