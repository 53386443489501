import ko from 'knockout';
import profileImportEvents from 'apply-flow/module/profile-import/config/events';
import ProfileImportConfig from 'apply-flow/module/profile-import/model/ProfileImportConfig';
import ProfileImportMetadataService from 'apply-flow/module/profile-import/service/Metadata';
import userEvents, { APPLY_FLOW_FINISHED } from 'cx/config/userEvents';

export default class AwLiConfirmViewModel {

    constructor() {
        this.isAwliClick = ko.observable(false);
        this.submitSucceed = ko.observable(false);
        this.profileImportConfig = ko.observable(new ProfileImportConfig());

        this._awliClickedSignal = profileImportEvents.awliClicked.add(() => {
            this.isAwliClick(true);
        });

        this._submitSucceedSignal = userEvents[APPLY_FLOW_FINISHED].add(() => this.submitSucceed(true));

        const metadataService = new ProfileImportMetadataService();

        metadataService.getProviderConfig()
            .then(this.profileImportConfig.bind(this));
    }

    dispose() {
        this._submitSucceedSignal.detach();
        this._awliClickedSignal.detach();
    }

}
