import { observable } from 'knockout';
import profileItemHiddenFields from './profileItemHiddenFields';

export default {
    removeHiddenFields(profileItems) {
        profileItems.forEach((profileItem) => {
            const fieldsToHide = profileItemHiddenFields.get(profileItem.sectionId());

            fieldsToHide.forEach((fieldToHide) => {
                profileItem[fieldToHide] = observable(undefined);
            });
        });

        return profileItems;
    },
};