import { bindingHandlers, utils } from 'knockout';
import candidateVerificationEvents from 'candidate-verification/config/events';

/**
 * Set focus to first number text element when resend pin action
 * is triggered and "Resend Code" button is changed to text with counter.
 *
 * @example
 * <div data-bind="setFocusWhenPinResend"></div>
 *
 */

bindingHandlers.setFocusWhenPinResend = {
    init() {
        function setFocus() {
            const isAlertAvailable = document.querySelector('.notifications__item');

            if (isAlertAvailable) {
                setTimeout(() => {
                    const resendCounter = document.querySelector('#counter-to-resend');

                    if (resendCounter) {
                        resendCounter.focus();

                        return;
                    }

                    // Where to set focus in case of try again
                    document.querySelector('#sendPinCodeTryAgain')?.focus();
                }, 3000);
            } else {
                setTimeout(() => document.querySelector('#counter-to-resend')?.focus(), 500);
            }
        }

        const pinResendSignal = candidateVerificationEvents.pinResend.add(setFocus);

        utils.domNodeDisposal.addDisposeCallback(document, () => {
            pinResendSignal.detach();
        });
    },
};