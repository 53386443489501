import router from 'app/model/router';
import { observable } from 'knockout';

export default class ScheduledInterviewActionViewModel {

    constructor(params) {
        this.interview = params.interview;
        this.isScheduled = observable(false);
        this.canReschedule = observable(false);

        if (this.interview) {
            this.isScheduled(this.interview.isScheduled());
            this.canReschedule(this.interview.isReschedulable());
        }

        this.viewDetail = this.viewDetail.bind(this);
    }

    viewDetail() {
        router.go('interview-detail', {
            id: this.interview.interviewId(),
            submissionId: this.interview.submissionId(),
        });
    }

    reScheduleInterview() {
        router.go('interview-self-re-schedule-without-token', {
            id: this.interview.scheduleId(),
            requestId: this.interview.interviewRequestId(),
            submissionId: this.interview.submissionId(),
            action: 'RESCHEDULE',
        });
    }

}