import { components } from 'knockout';

import { SearchResultItemViewModel as ViewModel } from './SearchResultItemViewModel';
import StaticViewModel from './SearchResultItemStaticViewModel';
import templateListItem from './search-result-list-item.html';
import templateGridItem from './search-result-grid-item.html';

components.register('search-result-list-item', {
    viewModel: ViewModel,
    template: templateListItem,
});

components.register('search-result-grid-item', {
    viewModel: ViewModel,
    template: templateGridItem,
});

components.register('search-result-list-item-static', {
    viewModel: StaticViewModel,
    template: templateListItem,
});

components.register('search-result-grid-item-static', {
    viewModel: StaticViewModel,
    template: templateGridItem,
});
