export default {
    encode(param) {
        return this._encode(param, this._encodeString);
    },

    encodeSlashes(param) {
        return this._encode(param, this._encodeStringSlashes);
    },

    encodeQueryParams(param) {
        return this._encode(param, this._encodeStringQueryParam);
    },

    _encode(param, stringEncoder) {
        switch (typeof param) {
            case 'string':
                return stringEncoder(param);
            case 'object':
                return this._encodeObject(param, stringEncoder);
            default:
                return param;
        }
    },

    _encodeString(str) {
        return encodeURIComponent(str)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/~/g, '%7E');
    },

    _encodeStringSlashes(str) {
        return str.replace(/\//g, '%2F');
    },

    _encodeStringQueryParam(str) {
        return encodeURIComponent(str)
            .replace(/!/g, '%21')
            .replace(/'/g, '%27')
            .replace(/\(/g, '%28')
            .replace(/\)/g, '%29')
            .replace(/~/g, '%7E')
            .replace(/%20/g, '+');
    },

    _encodeObject(obj, stringEncoder) {
        return Object.keys(obj).reduce((resultObj, key) => {
            const result = resultObj;
            const value = obj[key];

            result[key] = value ? this._encode(value, stringEncoder) : value;

            return result;
        }, {});
    },

    decode(param) {
        switch (typeof param) {
            case 'string':
                return this._decodeString(param);
            case 'object':
                return this._decodeObject(param);
            default:
                return param;
        }
    },

    _decodeString(str) {
        const decodedStr = str
            .replace(/\+/g, '%20')
            .replace(/%21/g, '!')
            .replace(/%27/g, '\'')
            .replace(/%28/g, '(')
            .replace(/%29/g, ')')
            .replace(/%7E/g, '~');

        return decodeURIComponent(decodedStr);
    },

    _decodeObject(obj) {
        return Object.keys(obj).reduce((resultObj, key) => {
            const result = resultObj;
            const value = obj[key];

            result[key] = value ? this.decode(value) : value;

            return result;
        }, {});
    },
};