import ko from 'knockout';

const { body } = document;

/**
 * Adds class to document.body when condition is truthy
 * @param {ko.observable} - observable with video url string
 * @param {string} className - name of CSS class to be applied to the element
 * @param {any} when - condition, if truthy then CSS class should be applied
 *
 * @example
 * <div data-bind="setBodyClass: {when: whenCondition, className: className}">
 */
ko.bindingHandlers.setBodyClass = {
    init(element, accessor) {
        const { when, className } = accessor();

        ko.utils.toggleDomNodeCssClass(body, className, Boolean(when));

        ko.utils.domNodeDisposal.addDisposeCallback(element, () =>
            ko.utils.toggleDomNodeCssClass(body, className, false));
    },
};
