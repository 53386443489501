export const notReachable = (state: never): never => {
    // TODO: add error logger to this function
    throw new Error(`Not reachable state appeared: ${state}`);
};

export type NullableObject<T> = { [K in keyof T]: T[K] | null };

export type Dictionary<T> = {
    [key: string]: T;
};
