import { RestParam } from 'cx/module/custom-content/mapper/mapParam';
import { JobDetailsSkillsCustomizationParams } from '../config/types';
import { emptyParams } from '../config/emptyParams';
import { notReachable } from 'app/types/utils';
import { mapCommonParams, mapStylingBox, mapTypography } from 'cx/module/custom-content/mapper';
import { mapCustomComponentParamsFromRest } from 'minimal/component/custom-component/service/mapCustomComponentParams';

export const mapParamsFromRest = (params: RestParam[]): JobDetailsSkillsCustomizationParams => {
    return mapCustomComponentParamsFromRest({
        restParams: params,
        defaultParams: emptyParams,
        mapParamValue: (paramKey, paramValue) => {
            switch (paramKey) {
                case 'content':
                    const contentParams = paramValue ? JSON.parse(paramValue) : { isTitleHidden: false };

                    return {
                        isTitleHidden: contentParams.isTitleHidden || false,
                    };

                case 'titleTypography':
                case 'categoryTypography':
                case 'skillTypography':
                    return mapTypography(paramValue);

                case 'container':
                case 'skill':
                    return mapStylingBox(paramValue);

                case 'commonParams':
                    return mapCommonParams(paramValue);

                default:
                    return notReachable(paramKey);
            }
        },
    });
};
