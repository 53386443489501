import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import { VERIFICATION_MODES, TC_CONFIRM } from 'candidate-verification/config/verificationSubmodules';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

function _confirmTalentCommunity(sitePreferences) {
    if (Object.keys(sitePreferences).length === 0) {
        return Promise.resolve();
    }

    sitePreferences.flowContext = VERIFICATION_MODES[TC_CONFIRM];

    return jobAlertService.confirmTalentCommunity(sitePreferences);
}

export default {
    confirmTalentCommunity(candidateNumber) {
        if (!isTCOptInEnabled()) {
            return Promise.resolve();
        }

        return jobAlertService.getPreferences(candidateNumber)
            .then(_confirmTalentCommunity);
    },
};