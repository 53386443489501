const AGREEMENT_BLOCK_CODE = 'ORA_AGREEMENTS';
const PERSONAL_INFORMATION_BLOCK_CODE = 'ORA_NAME_CONTACT_INFO';

function _removeAgreementBlock(block) {
    return block.code !== AGREEMENT_BLOCK_CODE;
}

function _preparePersonalInformationBlock(block) {
    if (block.code === PERSONAL_INFORMATION_BLOCK_CODE) {
        block.additionalData = {
            editMode: true,
        };
    }

    return block;
}

function _mapBlocks(blocks) {
    return blocks
        .filter(_removeAgreementBlock)
        .map(_preparePersonalInformationBlock);
}

function _mapPages(pages) {
    return pages.map((page) => {
        page.blocks = _mapBlocks(page.blocks);

        return page;
    });
}

function _mapSections(sections) {
    return sections.map((section) => {
        section.pages = _mapPages(section.pages);

        return section;
    });
}

export default {
    mapFlowFromRest(flow) {
        flow.isSingleClick = true;

        flow.sections = _mapSections(flow.sections);

        return flow;
    },
};