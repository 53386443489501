import storageService from 'core/storage/sessionStorage';
import tokenService from 'candidate-verification/service/token';

const UNVERIFIED_CANDIDATE_ID_KEY = 'unverifiedCandidateId';
const REMAINING_UNVERIFIED_APPLICATIONS_KEY = 'remainingUnverifiedApplications';
const UNVERIFIED_APPLICATIONS_EXCEEDED_KEY = 'unverifiedApplicationsExceeded';
const VERIFICATION_DATA_KEY = 'unverifiedApplicationsVerificationData';
const APPLIED_JOB_IDS_KEY = 'unverifiedAppliedJobIds';

export default {

    setUnverifiedCandidateId(candidateId) {
        storageService.store(UNVERIFIED_CANDIDATE_ID_KEY, candidateId);
    },

    getUnverifiedCandidateId() {
        return storageService.restore(UNVERIFIED_CANDIDATE_ID_KEY);
    },

    setRemainingUnverifiedApplications(remaining) {
        storageService.store(REMAINING_UNVERIFIED_APPLICATIONS_KEY, remaining);
    },

    getRemainingUnverifiedApplications() {
        return storageService.restore(REMAINING_UNVERIFIED_APPLICATIONS_KEY);
    },

    hasRemainingUnverifiedApplications() {
        return (this.getRemainingUnverifiedApplications() > 0);
    },

    isKnownUnverifiedCandidate() {
        return (this.getUnverifiedCandidateId() !== null);
    },

    setHasExceededUnverifiedApplicationsLimit(exceeded) {
        storageService.store(UNVERIFIED_APPLICATIONS_EXCEEDED_KEY, exceeded);

        if (exceeded) {
            this.setUnverifiedVerificationData();
        }
    },

    setUnverifiedVerificationData() {
        const token = tokenService.get();

        if (token) {
            const verification = {
                verificationMethod: token.verificationMethod,
                email: token.email,
                phone: token.phone,
            };

            storageService.store(VERIFICATION_DATA_KEY, verification);
        }
    },

    hasExceededUnverifiedApplicationsLimit() {
        return storageService.restore(UNVERIFIED_APPLICATIONS_EXCEEDED_KEY);
    },

    getUnverifiedVerificationData() {
        return storageService.restore(VERIFICATION_DATA_KEY);
    },

    clear() {
        storageService.remove(UNVERIFIED_CANDIDATE_ID_KEY);
        storageService.remove(REMAINING_UNVERIFIED_APPLICATIONS_KEY);

        return this;
    },

    destroy() {
        storageService.remove(UNVERIFIED_CANDIDATE_ID_KEY);
        storageService.remove(REMAINING_UNVERIFIED_APPLICATIONS_KEY);
        storageService.remove(VERIFICATION_DATA_KEY);
        storageService.remove(APPLIED_JOB_IDS_KEY);
        storageService.remove(UNVERIFIED_APPLICATIONS_EXCEEDED_KEY);

        return this;
    },

    addUnverifiedCandidateApplication(jobId) {
        const jobIds = this.getUnverifiedCandidateApplications() || [];

        jobIds.push(jobId);
        storageService.store(APPLIED_JOB_IDS_KEY, jobIds);
    },

    getUnverifiedCandidateApplications() {
        return storageService.restore(APPLIED_JOB_IDS_KEY);
    },

    hasAppliedToJob(jobId) {
        const jobIds = this.getUnverifiedCandidateApplications() || [];

        return Boolean(jobIds.filter(id => jobId === id).length);
    },
};
