import crossroads from 'crossroads';

const crossroadsPatternLexer = crossroads.patternLexer;

function _getOriginFromUrl(url) {
    const doubleSlashPos = url.indexOf('//');

    if (doubleSlashPos !== -1) {
        const pathnamePos = url.indexOf('/', doubleSlashPos + 2);

        return url.substr(0, pathnamePos);
    }

    return '';
}

function _removeOriginFromUrl(url) {
    const origin = _getOriginFromUrl(url);

    return url.replace(origin, '');
}

export default Object.create(crossroadsPatternLexer, {
    getParamIds: {
        value(pattern) {
            return crossroadsPatternLexer.getParamIds(_removeOriginFromUrl(pattern));
        },
    },

    getOptionalParamsIds: {
        value(pattern) {
            return crossroadsPatternLexer.getOptionalParamsIds(_removeOriginFromUrl(pattern));
        },
    },

    compilePattern: {
        value(pattern, ignoreCase) {
            return crossroadsPatternLexer.compilePattern(_removeOriginFromUrl(pattern), ignoreCase);
        },
    },

    interpolate: {
        value(pattern, replacements) {
            const origin = _getOriginFromUrl(pattern);

            return origin + crossroadsPatternLexer.interpolate(_removeOriginFromUrl(pattern), replacements);
        },
    },
});