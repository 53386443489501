const ua = navigator.userAgent;

function getChromeVersion() {
    return parseFloat(ua.split(/Chrome|CriOS/)[1].split('Safari')[0].replace(/[^0-9.]/g, ''));
}

function getFirefoxVersion() {
    return /FxiOS/i.test(ua) ? undefined : parseFloat(ua.split(/Firefox\//i)[1].replace(/[^0-9.]/g, ''));
}

function getOperaVersion() {
    return parseFloat(ua.split('Version/')[1].replace(/[^0-9.]/g, ''));
}

function getSafariVersion() {
    return parseFloat(ua.split('Version/')[1] ? ua.split('Version/')[1].split('Safari')[0].replace(/[^0-9.]/g, '') : 15);
}

function getInternetExplorerVersion() {
    if (/Trident/i.test(ua) && /rv:([0-9]+[.0-9]*)/.test(ua)) {
        return parseInt(ua.match(/rv:([0-9]+[.0-9]*)/)[1].replace(/[^0-9.]/g, ''), 10);
    }

    return parseInt(ua.split('MSIE')[1].replace(/[^0-9.]/g, ''), 10);
}

function getBrowser() {
    // Order is important!
    switch (true) {
        case (/Edge/.test(ua)):
            return 'Edge';
        case (/Chrome|CriOS/.test(ua)):
            return 'Chrome';
        case (/Firefox|FxiOS/.test(ua)):
            return 'Firefox';
        case (/Opera|OPR/.test(ua)):
            return 'Opera';
        case (/Safari/.test(ua)):
            return 'Safari';
        case (/MSIE|Trident/i.test(ua)):
            return 'Internet Explorer';
        default:
            return undefined;
    }
}

function getPlatform() {
    // Order is important!
    switch (true) {
        case (/Windows Phone/.test(ua)):
            return 'Windows Phone';
        case (/Windows/.test(ua)):
            return 'Windows';
        case (/Android/.test(ua)):
            return 'Android';
        case (/iPad/.test(ua)):
            return 'iPad';
        case (/iPhone/.test(ua)):
            return 'iPhone';
        case (/iPod/.test(ua)):
            return 'iPod';
        case (/Mac/.test(ua)):
            return 'OS X';
        case (/BlackBerry|BB|PlayBook/i.test(ua)):
            return 'BlackBerry';
        default:
            return undefined;
    }
}

function getVersion(browser) {
    switch (browser) {
        case 'Chrome':
            return getChromeVersion();
        case 'Firefox':
            return getFirefoxVersion();
        case 'Opera':
            return getOperaVersion();
        case 'Safari':
            return getSafariVersion();
        case 'Internet Explorer':
            return getInternetExplorerVersion();
        default:
            return undefined;
    }
}

const browser = getBrowser();
const platform = getPlatform();
const version = getVersion(browser);

export default {
    browser,
    platform,
    version,

    isIOS() {
        return (['iPhone', 'iPad', 'iPod'].indexOf(platform) > -1);
    },

    isMac() {
        return (platform === 'OS X');
    },

    isSafari9() {
        return browser === 'Safari' && version < 10;
    },

    isIE11() {
        return (browser === 'Internet Explorer' && version === 11);
    },

    isEdge() {
        return (browser === 'Edge');
    },

    isChrome() {
        return (browser === 'Chrome');
    },

    isMobile() {
        return (['iPhone', 'iPad', 'iPod', 'Windows Phone', 'Android', 'BlackBerry'].indexOf(platform) > -1);
    },
};
