import ArrayDataProvider from 'ojs/ojarraydataprovider';

export class CustomDataProvider {

    constructor(_dataProvider, _capabilityFunc) {
        let _a;

        this._dataProvider = _dataProvider;
        this._arrayDataProvider = new ArrayDataProvider([]);

        this._capabilityFunc = _capabilityFunc;
        this._DATAPROVIDER = 'dataProvider';

        this.AsyncIterable = (_a = class {

            constructor(_asyncIterator) {
                this._asyncIterator = _asyncIterator;

                this[Symbol.asyncIterator] = () => this._asyncIterator;
            }

        },
        Symbol.asyncIterator, _a);

        this.AsyncIterator = class {

            constructor(_asyncIteratorPromise) {
                this._asyncIteratorPromise = _asyncIteratorPromise;
            }

            next() {
                return this._asyncIteratorPromise.then(asyncIterator => asyncIterator.next());
            }

        };
    }

    fetchFirst(params) {
        const asyncIteratorPromise = this._dataProvider(params).then(dataProvider =>
            dataProvider.fetchFirst(params)[Symbol.asyncIterator]());

        return new this.AsyncIterable(new this.AsyncIterator(asyncIteratorPromise));
    }

    fetchByKeys(params) {
        return this._getDataProvider(params).then(dataProvider => dataProvider.fetchByKeys(params));
    }

    containsKeys(params) {
        return this._getDataProvider(params).then(dataProvider => dataProvider.containsKeys(params));
    }

    fetchByOffset(params) {
        return this._getDataProvider(params).then(dataProvider => dataProvider.fetchByOffset(params));
    }

    getTotalSize() {
        return this._getDataProvider().then(dataProvider => dataProvider.getTotalSize());
    }

    isEmpty() {
        if (!this[this._DATAPROVIDER]) {
            return 'unknown';
        }

        return this[this._DATAPROVIDER].isEmpty();
    }

    getCapability(capabilityName) {
        return this._arrayDataProvider.getCapability(capabilityName);
    }

    addEventListener(eventType, listener) {
        this._getDataProvider().then((dataProvider) => {
            dataProvider.addEventListener(eventType, listener);
        });
    }

    removeEventListener(eventType, listener) {
        this._getDataProvider().then((dataProvider) => {
            dataProvider.removeEventListener(eventType, listener);
        });
    }

    dispatchEvent(evt) {
        if (!this[this._DATAPROVIDER]) {
            return false;
        }

        return this[this._DATAPROVIDER].dispatchEvent(evt);
    }

    _getDataProvider(params) {
        return this._dataProvider(params);
    }

}