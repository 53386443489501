import ko from 'knockout';
import screenInfo from 'cx/model/screenInfo';

/**
 * Scroll body to defined value.
 *
 * @params {number} to - Number in pixels how much body should be scrolled
 * @params {string} device - Enumerable from screenInfo defining range of devices
 * on which scroll should be invoked. If not present - binding applies to all devices
 * @params {observable} when - Observable, if changed to true, induce body scroll change
 *
 * @listens click
 *
 * <div class="location-bar" data-bind="setBodyScrollTo: { to: 0, device: 'isMobile' }">
 * <div class="location-bar" data-bind="setBodyScrollTo: { to: 0, device: 'isMobile', when: observable }">
 */

const DEFAULT_OFFSET = 0;

function _scrollHandler(to, device) {
    if (!device || (device && screenInfo[device]())) {
        window.scrollTo(0, to || DEFAULT_OFFSET);
    }
}

ko.bindingHandlers.setBodyScrollTo = {
    init(element, valueAccessor) {
        const { to, device, when } = valueAccessor();

        if (when) {
            ko.when(() => when(), () => _scrollHandler(to, device));
        } else {
            _scrollHandler(to, device);
        }
    },
};

