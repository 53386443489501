import Signal from 'signals';

export default {
    dragEnter: new Signal(),
    fileDropped: new Signal(),
    submit: new Signal(),
    submitHasErrors: new Signal(),
    submitSucceed: new Signal(),
    submitFailed: new Signal(),
    sectionChanged: new Signal(),
    refresh: new Signal(),
    navigationOpen: new Signal(),
    invalidESignature: new Signal(),
    profileImportSucceed: new Signal(),
    profileImportLoading: new Signal(),
    beforeApplicationDraftSave: new Signal(),
    afterApplicationDraftSave: new Signal(),
    afterCommunicationChannelChange: new Signal(),
    restartDraftMonitor: new Signal(),
    profileItemChangeDetected: new Signal(),
};