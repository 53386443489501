import ko from 'knockout';
import $ from 'jquery';
import keyCodes from 'core/config/keycodes';

/**
 * Returns a collection of 'tabbable' child elements of passed container,
 * with the exception of unchecked radio buttons
 *
 * @private
 * @param (JQuery) $parent - container in which 'tabbable' child elements are searched
 * @returns {JQuery} collection of 'tabbable' child elements
 */
function _getTabbableElements($parent) {
    return $parent.find(':tabbable').not('input[type="radio"]:not(:checked)');
}

/**
 * Keeps the focus within container, so that the user can tab only between its 'tabbable' child elements
 * 'tabbable' - element on which user can focus via tabbing
 *
 * @example
 * <section class="favourite-panel" data-bind="a11y.trapFocus">
 *     <!-- child elements, between which user can tab -->
 * </section>
 */
ko.bindingHandlers['a11y.trapFocus'] = {
    init(element) {
        const $element = $(element);
        let $tabbables = _getTabbableElements($element);

        function focusContainer() {
            $element.focus();
        }

        function disableHandler(event, disabled) {
            if (disabled) {
                focusContainer();
            }
        }

        // Refresh listeners for every tabbable element
        function addListenersForTabbables() {
            $tabbables.each((index, tabbable) => {
                // Remove listeners from tabbable that already have this listeners attached
                $(tabbable).off('a11y-disabled', disableHandler);
                ko.utils.domNodeDisposal.removeDisposeCallback(tabbable, focusContainer);

                // Add dispose and a11y-disabled listeners
                ko.utils.domNodeDisposal.addDisposeCallback(tabbable, focusContainer);
                $(tabbable).on('a11y-disabled', disableHandler);
            });
        }

        function refreshTabbables() {
            $tabbables = _getTabbableElements($element);
        }

        function refreshAndAddListeners() {
            refreshTabbables();
            addListenersForTabbables();
        }

        function trapFocus(event) {
            if (event.which === keyCodes.TAB) {
                refreshAndAddListeners();

                const $firstTabbable = $tabbables.first();
                const $lastTabbable = $tabbables.last();

                if ($element.is(event.target) && !$tabbables.length) {
                    event.preventDefault();

                    return;
                }

                if (event.shiftKey && $firstTabbable.is(event.target)) {
                    event.preventDefault();
                    $lastTabbable.focus();
                }

                if (!event.shiftKey && $lastTabbable.is(event.target)) {
                    event.preventDefault();
                    $firstTabbable.focus();
                }
            }
        }

        $element.on('keydown', trapFocus);
        $element.on('focus', refreshAndAddListeners);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $element.off('keydown', trapFocus);
            $element.off('focus', refreshAndAddListeners);
        });
    },
};