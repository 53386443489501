import { PureComputed, Observable, pureComputed, Subscription } from 'knockout';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import {
    TOGGLE_DISPLAY_STYLE_LIST,
    NON_TOGGLE_DISPLAY_STYLE_LIST,
} from 'site-editor/module/search-editor/component/cc-search-results-editor/config/defaultParams';
import { DisplayStyle, ComponentClass, RouterQuery } from 'cx/module/search/config/types';
import router from 'app/model/router';
import storageService from 'core/storage/sessionStorage';
import { SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';
import { areRecommendedJobsEnabledWithParams } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import scrollKeeper from 'minimal/service/scrollKeeper';
import { isMapEnabled } from 'ce-common/module/maps/service/isMapEnabled';

type Props = {
    componentClass: PureComputed<ComponentClass>;
    isMapViewEnabled: Observable<boolean>;
    jobDisplayStyle: PureComputed<DisplayStyle>;
    isMapAlreadyLoaded: Observable<boolean>;
    showStaticMap: Observable<boolean>;
    isElasticSearchEnabled: boolean;
};

type ACCESSIBILITY_TYPE = 'TOGGLE_ICON_TOOLTIP' | 'TOOGLE_ICON_ARIA_LABEL';

type RouterParams = {
    query: RouterQuery;
};

type viewType = 'list' | 'map';

const DEFAULT_VIEW = 'list';
const SEARCH_RESULTS_VIEW_KEY = 'search-results-view';

export class SearchResultsToggleButtonViewModel {
    togggleListTileClass: PureComputed<string>;
    togggleMapClass: PureComputed<string>;
    isMapViewEnabled: Observable<boolean>;
    isToggleDisplayStyle: PureComputed<boolean>;
    isMapAlreadyLoaded: Observable<boolean>;
    routeParamsSubscription: Subscription;
    areRecommendedJobsEnabled: boolean;
    showStaticMap: Observable<boolean>;
    isElasticSearchEnabled: boolean;
    jobDisplayStyle: DisplayStyle;

    constructor({
        componentClass,
        isMapViewEnabled,
        jobDisplayStyle,
        isMapAlreadyLoaded,
        showStaticMap,
        isElasticSearchEnabled,
    }: Props) {
        this.jobDisplayStyle = jobDisplayStyle();
        this.isMapViewEnabled = isMapViewEnabled;
        this.togggleListTileClass = pureComputed(() => this.updatedListIconClass(componentClass));
        this.togggleMapClass = pureComputed(() => this.updatedMapIconClass());
        this.isToggleDisplayStyle = pureComputed(() => this.updateToggleDisplayStyle(jobDisplayStyle));
        this.isMapAlreadyLoaded = isMapAlreadyLoaded;
        this.showStaticMap = showStaticMap;
        this.isElasticSearchEnabled = isElasticSearchEnabled;

        this.routeParamsSubscription = router.routeParams.subscribe(
            this.onRouteParamsChanged,
            this,
            'beforeChange'
        );

        if (NON_TOGGLE_DISPLAY_STYLE_LIST.includes(this.jobDisplayStyle)) {
            storageService.remove(SEARCH_RESULTS_VIEW_KEY);
        }

        if (!isAdminRoute()) {
            const searchResultsView = storageService.restore(SEARCH_RESULTS_VIEW_KEY) ?? DEFAULT_VIEW;

            this.toggleJobMapView(isMapEnabled() ? searchResultsView : DEFAULT_VIEW);
        }

        this.areRecommendedJobsEnabled = areRecommendedJobsEnabledWithParams();

        if (this.areRecommendedJobsEnabled) {
            this.toggleJobMapView(DEFAULT_VIEW);
        }
    }

    onRouteParamsChanged(): void {
        const { query } = router.routeParams() as RouterParams;

        const searchJobsQuery = storageService.restore(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS)?.query;

        if (JSON.stringify(searchJobsQuery) !== JSON.stringify(query) && !this.isMapViewEnabled()) {
            this.isMapAlreadyLoaded(false);
        }
    }

    toggleJobMapView(viewType: viewType): void {
        scrollKeeper.store();

        if (viewType === 'list') {
            this.isMapViewEnabled(false);
        } else {
            if (isAdminRoute()) {
                this.showStaticMap(true);
            }

            this.isMapViewEnabled(true);
            this.isMapAlreadyLoaded(true);
        }

        if (!isAdminRoute() && !this.areRecommendedJobsEnabled) {
            storageService.store(SEARCH_RESULTS_VIEW_KEY, viewType);
        }

        scrollKeeper.restore();
    }

    updatedListIconClass(componentClass: PureComputed<ComponentClass>): string {
        return this.isMapViewEnabled()
            ? `search-job-results__${componentClass()}-icon`
            : `search-job-results__${componentClass()}-icon--active`;
    }

    updatedMapIconClass(): string {
        return !this.isMapViewEnabled()
            ? `search-job-results__toggle-map-icon`
            : `search-job-results__toggle-map-icon--active`;
    }

    updateToggleDisplayStyle(jobDisplayStyle: PureComputed<DisplayStyle>): boolean {
        return TOGGLE_DISPLAY_STYLE_LIST.includes(jobDisplayStyle());
    }

    getAccessibilityLabel(type: ACCESSIBILITY_TYPE): string | undefined {
        const isToggleListMapView = this.togggleListTileClass().includes(
            'search-job-results__jobs-list-icon'
        );

        switch (type) {
            case 'TOGGLE_ICON_TOOLTIP':
                return isToggleListMapView
                    ? 'admin.site-editor.content-editor.job-display-list-view'
                    : 'admin.site-editor.content-editor.job-display-tile-view';
            case 'TOOGLE_ICON_ARIA_LABEL':
                return isToggleListMapView
                    ? 'search.maps.job-display-map-view'
                    : 'search.maps.job-display-map-view-to-tile-view';
            default:
                break;
        }
    }

    getTabIndex(): number {
        return areRecommendedJobsEnabledWithParams() ? -1 : 0;
    }

    dispose(): void {
        this.routeParamsSubscription.dispose();
    }
}
