import {
    PrefillResponse,
    RegulatoryResponse,
    MappedRegulatoryResponses,
    LegislationResponse,
} from 'apply-flow/module/regulatory/config/regulatoryResponseTypes';

export const AMP = '&&';
export const PIPE = '||';
export const DASH = '-';

export const mapDataFromRest = (
    restResponses: RegulatoryResponse,
    jobId: string
): MappedRegulatoryResponses => {
    let parsedResponse;

    try {
        parsedResponse = JSON.parse(restResponses.regulatoryResponses);
    } catch (error) {
        parsedResponse = [];
        console.error('Mapper failed to parse prefill data.', error);
    }

    return {
        requisitionNumber: jobId,
        prefillResponses: mapRegulatoryResponseFromRest(parsedResponse),
    };
};

export const mapRegulatoryResponseFromRest = (restResponses: LegislationResponse[]): PrefillResponse[] => {
    return restResponses.reduce(
        (accumulator: PrefillResponse[], legislationResponse: LegislationResponse): PrefillResponse[] => {
            const legislationCode = legislationResponse.LegislationCode;
            const fieldsWithPrefillValues = legislationResponse.Response.split(AMP);

            fieldsWithPrefillValues.forEach((fieldWithPrefill) => {
                const [fieldType, attributeName, prefillResponse, columnName] = fieldWithPrefill.split(PIPE);

                if (prefillResponse) {
                    const fieldId = [
                        legislationCode,
                        fieldType,
                        attributeName,
                        ...(fieldType === 'STANDARD' ? [fieldType] : [columnName]),
                    ].join(DASH);

                    accumulator.push({
                        id: fieldId,
                        value: prefillResponse,
                    });
                }
            });

            return accumulator;
        },
        []
    );
};
