import TalentCommunityViewModel from 'cx/module/talent-community/TalentCommunityViewModel';
import router from 'app/model/router';
import historyService from 'app/module/ce-common/service/historyService';
import userTracking from 'app/module/cx/service/userTracking';

export default class MinimalTalentCommunityViewModel extends TalentCommunityViewModel {

    constructor() {
        super();

        this.cssRoute = 'candidate-self-service.info';
    }

    onAttempsLimitReached() {
        return router.go('search');
    }

    back() {
        userTracking.trackJobAppEntryCancel(router.routeParams().jobId, router.route().id);

        historyService.moveBackOrFallbackTo('home-page');
    }

}
