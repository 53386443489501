import { CustomContentParams } from 'cx/module/custom-content/mapper/params';

type PaddingParam = 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft';
type PaddingParams = Record<PaddingParam, string | null>;
type CustomContentWithPaddingParams = CustomContentParams & PaddingParams;

export const mapPaddingParamsFromRest = (padding: string): PaddingParams => {
    const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding.split(' ');

    return {
        paddingTop: paddingTop === 'null' ? null : paddingTop,
        paddingRight: paddingRight === 'null' ? null : paddingRight,
        paddingBottom: paddingBottom === 'null' ? null : paddingBottom,
        paddingLeft: paddingLeft === 'null' ? null : paddingLeft,
    };
};

export const hasPaddingParams = (params: CustomContentParams): params is CustomContentWithPaddingParams => {
    return (
        params.paddingTop !== undefined &&
        params.paddingRight !== undefined &&
        params.paddingBottom !== undefined &&
        params.paddingLeft !== undefined
    );
};

export const mapPaddingParamsToRest = (params: CustomContentWithPaddingParams): string => {
    const { paddingTop, paddingRight, paddingBottom, paddingLeft } = params;

    return `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`;
};
