import componentMapper from './component';
import rowMapper from './row';
import itemSorter from '../service/itemSorter';
import defaultComponentFactory from '../service/componentFactory';
import paramsMapper from './params';

export default {
    mapSectionsFromRest(sections, componentFactory) {
        const sortedSections = itemSorter.sort(sections);

        return sortedSections.map(section => this.mapSectionFromRest(section, componentFactory));
    },

    mapSectionFromRest(section, componentFactory = defaultComponentFactory) {
        try {
            return componentFactory.create({
                elementNumber: section.elementNumber,
                params: paramsMapper.mapParamsFromRest([
                    ...section.sectionParams,
                    { paramKey: 'type', paramValue: 'cc-section' },
                ]),
                order: section.elementOrder,
                components: componentMapper.mapComponentsFromRest(section.components || [], componentFactory),
                rows: rowMapper.mapRowsFromRest(section.rows, componentFactory),
            });
        } catch (err) {
            console.error(err);

            return componentFactory.createEmptySection();
        }
    },
};