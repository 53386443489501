import { cookieConfig, showCookiePolicyOrPreferenceDialog } from 'cx/service/cookieAgreement';
import { CookiePreferenceLinkParams } from './config/types';
import { mapParamsConfigurationToObservable } from '../../module/search/service/observableParams';
import { emptyParams } from './config/emptyParams';
import { Observable, pureComputed, PureComputed } from 'knockout';
import { getCustomStyles } from './config/customStyles';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import { getMessages } from './service/messages';
import siteLanguage from 'ce-common/service/language/siteLanguage';

export const DEFAULT_CLASS_ID = 'default';
export type Props = {
    params?: CookiePreferenceLinkParams;
    id?: string;
    lang?: Observable<string>;
};

export default class AppMenuViewModel {
    customizationParams: CookiePreferenceLinkParams;
    uniqueWrapperClass: string;
    customCss: PureComputed<string>;
    isCookieConsentEnabled = false;
    isAdminRoute = false;
    languageCode: string;
    messages: Record<string, Observable<string>>;
    cookieLinkText: PureComputed<string>;

    constructor({ id, params, lang }: Props) {
        this.uniqueWrapperClass = `component-styling-wrapper-${id ?? DEFAULT_CLASS_ID}`;
        this.customizationParams = params || mapParamsConfigurationToObservable(emptyParams);
        this.isCookieConsentEnabled = cookieConfig?.isCookieConsentEnabled();

        this.customCss = pureComputed(() =>
            getCustomStyles(this.customizationParams, this.uniqueWrapperClass)
        );

        this.languageCode = lang ? lang() : siteLanguage.getFusionCode();
        this.messages = getMessages(this.customizationParams, this.languageCode);
        this.cookieLinkText = pureComputed(() => this.messages.cookiePreferenceLinkText());
        this.isAdminRoute = isAdminRoute();
    }

    showCookieConsentPreferences(): void {
        if (this.isAdminRoute) {
            return;
        }

        showCookiePolicyOrPreferenceDialog();
    }
}
