import httpLangCached from 'cx/service/http/httpLangCached';
import formMetadataMapperResolver from 'apply-flow/module/profile-items/service/formMetadataMapperResolver';
import flowService from 'apply-flow/service/flow';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';
import profileItemHiddenFields from './profileItemHiddenFields';


const MULTI_PROFILE_ITEM_SUPPORT = {
    [contentTypes.CERTIFICATION_ID]: 'Licenses and Certifications',
    [contentTypes.EDUCATION_ID]: 'Education',
    [contentTypes.LANGUAGE_ID]: 'Languages',
    [contentTypes.EXPERIENCE_ID]: 'Work Experience',
    [contentTypes.SKILL_ID]: 'Skill',
};

export default class Metadata {

    constructor(serviceUrl, contentTypeId, sectionId, filterHiddenFields = false) {
        this._serviceUrl = serviceUrl;
        this._contentTypeId = contentTypeId;
        this._sectionId = sectionId;
        this._filterHiddenFields = filterHiddenFields;
    }

    getFormElements() {
        const metadataMapper = formMetadataMapperResolver.getMapper(this._contentTypeId);

        const { flowVersionId } = flowService.get();

        const serviceUrl = this._serviceUrl
            .replace('<SECTION_ID>', this._sectionId)
            .replace('<FLOW_VERSION_ID>', flowVersionId);

        const formElementsPromise = httpLangCached
            .get(serviceUrl, {
                retry: 2,
            })
            .then(metadataMapper.mapFormElementsFromRest.bind(metadataMapper));

        if (!this._filterHiddenFields) {
            return formElementsPromise;
        }

        return formElementsPromise
            .then(this.filterHiddenFields.bind(this));
    }

    isMultiProfileItemSupported(contentTypeId) {
        return contentTypeId in MULTI_PROFILE_ITEM_SUPPORT;
    }

    filterHiddenFields(formElements) {
        profileItemHiddenFields.set(this._sectionId, this._contentTypeId, formElements);

        return formElements;
    }

}