import ko from 'knockout';

function getAttachmentUrl(links) {
    const attachmentLink = ko.utils.arrayFirst(links, link => link.rel === 'enclosure');

    return attachmentLink ? attachmentLink.href : '';
}

class Attachment {

    constructor(attachment) {
        this.description = attachment.description;
    }

}

class FileAttachment extends Attachment {

    constructor(attachment) {
        super(attachment);

        this.type = 'file';
        this.name = attachment.fileName;
        this.url = getAttachmentUrl(attachment.links);
        this.mimeType = attachment.uploadedFileContentType;
    }

}

class LinkAttachment extends Attachment {

    constructor(attachment) {
        super(attachment);

        this.type = 'link';
        this.name = attachment.url;
        this.url = attachment.url;
    }

}

export default {
    createAttachment(attachment) {
        const constructor = attachment.datatypeCode === 'WEB_PAGE' ? LinkAttachment : FileAttachment;

        return new constructor(attachment);
    },
};
