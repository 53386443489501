import ko from 'knockout';
import a11yEvents from 'core/a11y/events';

export default class AccessibilityMessagesViewModel {

    constructor() {
        this.status = ko.observable().extend({
            notify: 'always',
        });

        this.alert = ko.observable().extend({
            notify: 'always',
        });

        a11yEvents.status.add((statusText) => {
            this.status(statusText);
        });

        a11yEvents.alert.add((alertText) => {
            this.alert(alertText);
        });
    }

}