class TcDialogViewModel {

    constructor(params) {
        this.baseClass = params.baseClass;
        this.isVisible = params.isVisible;
        this.onClose = params.onClose;

        this.nodes = {};
        this.rootClass = this.baseClass ? `tc-dialog ${this.baseClass}` : 'tc-dialog';
        this.initialDialogVisibility = this.isVisible() ? 'show' : 'hide';
    }

    close() {
        this.isVisible(false);
    }

}

export default {
    createViewModel(params, componentInfo) {
        const viewModel = new TcDialogViewModel(params);

        componentInfo.templateNodes
            .filter(node => node.tagName)
            .forEach((node) => {
                const nodeName = node.tagName.toLowerCase();

                viewModel.nodes[nodeName] = node.childNodes;
            });

        return viewModel;
    },
};

