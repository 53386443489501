import { observable, Observable, PureComputed, pureComputed } from 'knockout';
import appConfig from 'app/model/config';
import favouriteJobsService from 'minimal/service/favouriteJobs';
import { search as searchService } from 'minimal/module/search/service/search';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import './component/job-details-similar-jobs-item-header/component';
import { getJobUrl, openJob } from 'minimal/module/search/service/jobRouting';
import { emptySearchResultsParams } from 'minimal/module/search/component/search-results/config/defaultStyles';
import {
    AdditionInformationItem,
    SearchResultsParams,
} from 'minimal/module/search/component/search-results/config/types';
import { JobHeadingConfig } from './types';
import screenInfo from 'cx/model/screenInfo';
import router from 'app/model/router';
import deviceDetector from 'app/module/core/device-detector/deviceDetector';
import {
    getGridTileAdditionStyles,
    getMediaStyles,
} from 'minimal/module/search/component/search-result-item/service/additionalStyles';
import { Job } from 'minimal/module/job-details/types';

export type SearchResultItemViewModelProps = {
    job: Job;
    customizationParams: SearchResultsParams;
};

export class JobDetailsSimilarJobsItemViewModel {
    job: Job;
    customizationParams: SearchResultsParams;
    hasFocus: Observable<boolean>;
    isFavourite: PureComputed<boolean>;
    isDescriptionVisible: PureComputed<boolean>;
    isImageVisible: PureComputed<boolean>;
    jobHeadingConfig: PureComputed<JobHeadingConfig>;
    mediaStyles: PureComputed<Record<string, string | undefined>>;
    isMediumScreen: PureComputed<boolean>;
    gridItemBodyAdditionalStyles: PureComputed<Record<string, string> | null>;
    gridItemHeaderAdditionalStyles: PureComputed<Record<string, string> | null>;
    isFavouriteToggled: Observable<boolean>;

    getJobUrl: (jobId: string) => void;
    openJob: (jobId: string) => void;

    constructor({ job, customizationParams }: SearchResultItemViewModelProps) {
        this.job = job;
        this.getJobUrl = getJobUrl;
        this.openJob = openJob;
        this.isMediumScreen = pureComputed(() => screenInfo.isMediumUp());
        this.hasFocus = observable<boolean>(false);

        this.customizationParams =
            customizationParams || mapParamsConfigurationToObservable(emptySearchResultsParams);

        this.isFavourite = favouriteJobsService.isFavourite(job);
        this.isFavouriteToggled = observable<boolean>(false);

        this.isDescriptionVisible = pureComputed(
            () => Boolean(job.shortDescription) && this.hasAdditionalInformation('description')
        );

        this.isImageVisible = pureComputed(() => this.hasAdditionalInformation('previewImage'));

        this.jobHeadingConfig = pureComputed(() => ({
            isLocationVisible: this.hasAdditionalInformation('location'),
            isDistanceVisible: this.shouldShowDistance(),
            isPostingDateVisible:
                this.hasAdditionalInformation('postingDate') && Boolean(this.job.postedDate),
            isHotJobVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.hotJobFlag) &&
                this.hasAdditionalInformation('hotJob'),
            isTrendingJobVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.trendingJob) &&
                this.hasAdditionalInformation('trendingJob'),
            isBeFirstToApplyVisible:
                Boolean(!this.job.isAlreadyApplied) &&
                Boolean(this.job.beFirstToApply) &&
                this.hasAdditionalInformation('beFirstToApply'),
            isAlreadyAppliedTagVisible:
                Boolean(this.job.isAlreadyApplied) && this.hasAdditionalInformation('alreadyAppliedTag'),
        }));

        this.mediaStyles = pureComputed<Record<string, string | undefined>>(() =>
            getMediaStyles({
                jobDisplayStyle: this.customizationParams.contentParams.jobDisplayStyle(),
                isMediumScreen: this.isMediumScreen(),
                isImageVisible: this.isImageVisible(),
                mediaThumbUrl: this.job.mediaThumbUrl,
            })
        );

        this.gridItemBodyAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius());
        });

        this.gridItemHeaderAdditionalStyles = pureComputed(() => {
            return getGridTileAdditionStyles(this.customizationParams.tileStyles.borderRadius(), 'header');
        });

        // Workaround for iOS devices which can't maintain previous scroll position on safari and chrome.
        // See [Bug-33997367]. It will affect every mobile browser when using browser back
        // and overlay exit button, going from job details to search

        this.hasFocus.subscribe((focus) => {
            const focusedElement = document.querySelector(`[href="${getJobUrl(job.id)}"]`);

            setTimeout(() => {
                if (focus && deviceDetector.isMobile() && focusedElement && router.route().id === 'search') {
                    focusedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            }, 100);
        });
    }

    hasAdditionalInformation(item: AdditionInformationItem): boolean {
        const additionalInformation = this.customizationParams.contentParams.additionalInformationDisplayed();

        return additionalInformation ? additionalInformation.includes(item) : false;
    }

    shouldShowDistance(): boolean {
        const isCustomizationDistanceEnabled = this.hasAdditionalInformation('distance');

        const isDistanceCalculationEnabled = mapTrueFalseStringToBoolean(
            appConfig.getSettingByKey('DISTANCE_CALCULATION_ENABLED')
        );

        return (
            isCustomizationDistanceEnabled && isDistanceCalculationEnabled && searchService.isLocationSearch()
        );
    }

    toggleFavourite(): void {
        this.isFavouriteToggled(true);

        favouriteJobsService.toggleFavourite(this.job);
    }

    openJobPreview(jobId: string): void {
        if (router.route().id === 'candidate-self-service.job-preview') {
            router.go('candidate-self-service.job-preview', { jobId: jobId });
        } else {
            this.openJob(jobId);
        }

        this.hasFocus(true); // hasFocus two way binding has problems on iOS, needs to be set explicitly
    }
}
