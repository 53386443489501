import FormValidator from 'core/form/validator/Validator';

export default class StartDatePickerValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.later-than-current-date';
    }

    _validate(value) {
        const date = new Date(value);

        return value ? date <= new Date() : true;
    }

}