import { pureComputed, observable } from 'knockout';
import { getNextPaginationLimit } from 'cx/module/candidate-self-service/component/cs-applications/service/applicationsListHelpers';

const SHOW_ALL_BUTTON_KEY = 'candidate-self-service.show-all';
const ENTITIES_PER_PAGE = 5;
const COUNT_OF_ENTITIES_BEFORE_SHOWING_EVERYTHING = 20;

export class ApplicationsListState {

    constructor({ entities, loadingStatus, titleKey, name }) {
        this.entities = entities;
        this.loadingStatus = loadingStatus;
        this.titleKey = titleKey;
        this.name = name;

        this.limit = observable(ENTITIES_PER_PAGE);
        this.paginatedEntities = pureComputed(() => this.entities().slice(0, this.limit()));

        this.showMoreTitleKey = pureComputed(() =>
            this.limit() >= COUNT_OF_ENTITIES_BEFORE_SHOWING_EVERYTHING && SHOW_ALL_BUTTON_KEY,
        );

        this.showMore = () => {
            const nextLimit = getNextPaginationLimit({
                currentLimit: this.limit(),
                entitiesLength: this.entities().length,
                entitiesPerPage: ENTITIES_PER_PAGE,
                countOfEntitiesBeforeShowingEverything: COUNT_OF_ENTITIES_BEFORE_SHOWING_EVERYTHING,
            });

            this.limit(nextLimit);
        };
    }

}