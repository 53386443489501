import appConfig from 'app/model/config';
import store from 'admin/module/site-editor/store/store';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { Observable } from 'knockout';

type SiteShape = {
    areEventsEnabled: Observable<boolean>;
};

const GLOBAL_EVENTS_FLAG_KEY = 'ORA_IRC_GLOBAL_EVENTS_ENABLED';

export const areEventsEnabledGlobally = (): boolean =>
    mapTrueFalseStringToBoolean(appConfig.getSettingByKey(GLOBAL_EVENTS_FLAG_KEY));

export const areEventsEnabledLocally = (): boolean => {
    if (isAdminRoute()) {
        const site = store.site as SiteShape | null;

        return Boolean(site?.areEventsEnabled());
    }

    return Boolean(appConfig.siteEventsEnabledFlag);
};

export const areEventsEnabled = (): boolean => areEventsEnabledGlobally() && areEventsEnabledLocally();
