import { observable } from 'knockout';
import Component from './Component';

export default class ColumnComponent extends Component {

    constructor(data) {
        super(data);

        this.components = data.components;
        this.size = observable();
    }

}
