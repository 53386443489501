import { bindingHandlers } from 'knockout';

bindingHandlers.hasScrollbar = {
    update(element, valueAccessor) {
        const { resultTo, isActive } = valueAccessor();

        if (isActive()) {
            resultTo(element.scrollHeight > element.clientHeight);
        }
    },
};

