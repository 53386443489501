import { unwrap } from 'knockout';
import applicationsModel from 'candidate-self-service/model/applications';
import { Application } from 'apply-flow/config/types';
import { Event } from 'minimal/module/candidate-self-service/config/types';
import { eventsState } from 'minimal/module/candidate-self-service/service/EventsState';

export const getCandidateSitesList = (): string[] => {
    const applicationsSiteNames = getSitesList(applicationsModel.applications() as Application[]);
    const eventsSiteNames = getSitesList(eventsState.eventsList());

    return [...new Set([...applicationsSiteNames, ...eventsSiteNames])];
};

const getSitesList = (collection: Application[] | Event[]): string[] => {
    return collection
        .map((item) => (item.siteName !== null ? unwrap(item.siteName) : item.siteName))
        .filter((siteName): siteName is string => Boolean(siteName));
};
