import 'jqueryui-amd/widgets/draggable';
import 'jqueryui-amd/position';
import 'ojs/ojcheckboxset';
import 'ojs/ojcore';
import 'ojs/ojdialog';
import 'ojs/ojdatetimepicker';
import 'ojs/ojdvt-base';
import 'ojs/ojeditablevalue';
import 'ojs/ojinputtext';
import 'ojs/ojpopup';
import 'ojs/ojpopupcore';
import 'ojs/ojradiocheckbox';
import 'ojs/ojselectcombobox';
import 'ojs/ojaccordion';
import 'ojs/ojvalidation';
import 'ojs/ojcollapsible';
import 'ojs/ojcomponentcore';
import 'ojs/ojknockout';
import 'ojs/ojmessaging';
import 'ojs/ojknockout-validation';
import 'ojs/ojprogress-bar';
import 'cx/module/apply-flow/config/routing';
import 'core/component/inline-spinner/component';
import 'core/component/tooltip/component';
import 'cx/module/apply-flow/component/apply-flow-block/component';
import 'cx/module/apply-flow/component/apply-flow-block-title/component';
import 'minimal/module/apply-flow/component/apply-flow-navigation-multipage/component';
import 'minimal/module/apply-flow/component/apply-flow-navigation-singlepage/component';
import 'cx/module/apply-flow/component/apply-flow-navigation-singlepage/binding/scrollSpy';
import 'minimal/module/apply-flow/component/apply-flow-pagination/component';
import 'cx/module/apply-flow/component/apply-flow-section/component';
import 'minimal/module/apply-flow/component/apply-flow-header/component';
import 'cx/module/apply-flow/component/apply-flow-summary/component';
import 'minimal/module/apply-flow/component/apply-flow-legal-disclaimer/component';
import 'cx/module/apply-flow/component/existing-submission/component';
import 'minimal/module/apply-flow/component/apply-flow-submission-checklist/component';
import 'cx/module/apply-flow/component/apply-flow-accessibility-messages/component';
import 'minimal/module/apply-flow/module/agreements/config/module';
import 'minimal/module/apply-flow/module/candidate-links/config/module';
import 'cx/module/apply-flow/module/degrees/config/module';
import 'cx/module/apply-flow/module/questionnaire/config/module';
import 'cx/module/apply-flow/module/regulatory/config/module';
import 'minimal/module/apply-flow/module/e-signature/config/module';
import 'minimal/module/apply-flow/module/file-upload/config/module';
import 'cx/module/apply-flow/module/job-alert-preferences/config/module';
import 'cx/module/apply-flow/module/languages/config/module';
import 'cx/module/apply-flow/module/licenses-and-certificates/config/module';
import 'cx/module/apply-flow/module/personal-information-address/config/module';
import 'cx/module/apply-flow/module/personal-information-basic/config/module';
import 'cx/module/apply-flow/module/personal-information-basic/component/name-form/component';
import 'cx/module/apply-flow/module/personal-information-basic/component/phone-edit-form-element/component';
import 'cx/module/apply-flow/module/personal-information-basic/component/email-edit-form-element/component';
import 'cx/module/apply-flow/module/personal-information-basic/component/communication-channel-warning/component';
import 'minimal/module/apply-flow/module/skill/config/module';
import 'minimal/module/apply-flow/module/personal-identifying-information/config/module';
import 'cx/module/apply-flow/module/personal-identifying-information/component/masked-form-element/component';
import 'cx/module/apply-flow/module/previous-employment/config/module';
import 'minimal/module/apply-flow/module/profile-import/config/module';
import 'minimal/module/apply-flow/module/inline-partner/config/module';
import 'cx/module/apply-flow/module/profile-items/config/module';
import 'minimal/module/apply-flow/module/tile-profile-items/config/module';
import 'minimal/module/apply-flow/module/work-and-education-timeline/config/module';
import 'cx/module/apply-flow/module/preferred-locations/config/module';
import 'cx/module/apply-flow/module/work-preferences/config/module';
import 'cx/module/apply-flow-post/component/apply-flow-post-summary-thank-you/component';
import 'cx/module/apply-flow-post/component/apply-flow-post-pin-verification/component';
import 'cx/module/apply-flow-post/config/routing';
import '../component/apply-flow-fixer/component';
import 'minimal/module/apply-flow/module/extra-information/config/module';
import '../component/apply-flow-tile-form/component';
import 'cx/module/apply-flow/component/apply-flow-draft-saving-indicator/component';

import { components } from 'knockout';

import ApplyFlowViewModel from 'cx/module/apply-flow/ApplyFlowViewModel';
import ApplyFlowLightViewModel from 'cx/module/apply-flow-light/ApplyFlowLightViewModel';
import ApplyFlowPostViewModel from 'cx/module/apply-flow-post/ApplyFlowPostViewModel';

import applyFlowTemplate from '../apply-flow.html';
import applyFlowLightTemplate from '../apply-flow-light.html';

components.register('apply-flow', {
    viewModel: ApplyFlowViewModel,
    template: applyFlowTemplate,
});

components.register('apply-flow-light', {
    viewModel: ApplyFlowLightViewModel,
    template: applyFlowLightTemplate,
});

components.register('apply-flow-post', {
    viewModel: ApplyFlowPostViewModel,
    template: applyFlowTemplate,
});
