import router from 'app/model/router';
import urlEncoder from 'core/router/urlEncoder';
import OAuthService from 'cx/module/apply-flow/module/profile-import/service/oauth';

router.configure({
    'profile-import': {
        url: '/profile-import?redirect={redirectUrl}',
        enter(encodedRouteParams) {
            const routeParams = urlEncoder.decode(encodedRouteParams);

            const oauthService = new OAuthService();

            oauthService.getOauthLoginUrl(routeParams.redirectUrl)
                .then(indeedUrl => window.location.assign(indeedUrl))
                .catch((error) => {
                    console.error(error);
                    router.go('home-page');
                });
        },
    },
});
