import metadataConfig from 'apply-flow/config/metadata';
import flowSectionMapper from 'apply-flow/mapper/flowSection';
import flowBlockMapper from 'apply-flow/mapper/flowBlock';
import Flow from 'apply-flow/model/Flow';
import LegalDisclaimer from 'apply-flow/model/LegalDisclaimer';
import OptIn from 'apply-flow/model/OptIn';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

function _getPreSectionBlocks(sections) {
    return sections
        .reduce((pages, section) => pages.concat(section.pages), [])
        .reduce((blocks, page) => blocks.concat(page.blocks), [])
        .filter(block => metadataConfig.isPresectionBlock(block.blockCode))
        .map(flowBlockMapper.mapFlowBlockFromRest.bind(flowBlockMapper));
}

function _createPreSection(sections) {
    return {
        number: '1',
        pages: [{
            blocks: _getPreSectionBlocks(sections),
        }],
    };
}

export default {
    mapFlowFromRest({ items }) {
        const [restFlow] = items;

        const eSignature = {
            eSignatureEnabled: restFlow.esignEnabledFlag,
            eSignatureDescription: restFlow.esignDescriptionStr,
        };

        const legalDisclaimer = new LegalDisclaimer({
            isEnabled: restFlow.legalEnabledFlag,
            versionId: restFlow.legalDescriptionVersionId,
            description: restFlow.legalDescriptionStr,
        });

        const optIn = new OptIn({
            isEnabled: restFlow.optinEnabledFlag,
            versionId: restFlow.optinDescriptionVersionId,
            description: restFlow.optinDescriptionStr,
        });

        return new Flow({
            legalDisclaimer,
            optIn,
            flowTypeCode: restFlow.flowTypeCode,
            jobAlertsEnabled: isTCOptInEnabled() && restFlow.tcoptinEnabledFlag,
            name: restFlow.flowName,
            flowId: restFlow.flowId,
            legalDescriptionVersionId: restFlow.legalDescriptionVersionId,
            esignDescriptionVersionId: restFlow.esignDescriptionVersionId,
            isSingleClick: restFlow.singleClickApplyFlag,
            eSignature: restFlow.esignDescriptionStr,
            eSignatureEnabled: restFlow.esignEnabledFlag,
            preSection: _createPreSection(restFlow.sections),
            sections: flowSectionMapper.mapFlowSectionsFromRest(restFlow.sections, eSignature),
            flowVersionId: restFlow.flowVersionId,
            profileItemsVersion: restFlow.profileVersion,
            requisitionFlexFields: restFlow.requisitionFlexFields,
        });
    },
};