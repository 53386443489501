import { RestParam } from 'cx/module/custom-content/mapper/mapParam';

type Props<CustomizationParams> = {
    restParams: RestParam[];
    defaultParams: CustomizationParams;
    mapParamValue: (
        paramKey: keyof CustomizationParams,
        paramValue: string | null | undefined
    ) => CustomizationParams[keyof CustomizationParams];
};

export const mapCustomComponentParamsFromRest = <
    CustomizationParams,
    CustomizationParamsKey extends keyof CustomizationParams
>({
    restParams,
    defaultParams,
    mapParamValue,
}: Props<CustomizationParams>): CustomizationParams => {
    return restParams.reduce<CustomizationParams>(
        (params, { paramKey, paramValue }) => {
            if (paramKey === 'type') {
                return {
                    ...params,
                    type: paramValue,
                };
            }

            const customizationParamKey = paramKey as CustomizationParamsKey;

            return {
                ...params,
                [paramKey]: mapParamValue(customizationParamKey, paramValue),
            };
        },
        { ...defaultParams }
    );
};
