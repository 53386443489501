import FormValidator from 'core/form/validator/Validator';

export default class PiiExpirationDateMaxLimitValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'apply-flow.section-personal-identifying-information.validation.nino-max-issue-date-validation';
    }

    _validate(values, options) {
        const END_YEAR = 4713;
        const { issueDateElement, expiryDateElement } = options;

        const issueYear = getYear(issueDateElement.value());

        if (issueYear >= END_YEAR && expiryDateElement.value() == null) {
            return false;
        }

        return true;

        function getYear(issueDate) {
            if (!issueDate) {
                return undefined;
            }

            const [stringYear] = issueDate.split('-');

            return parseInt(stringYear, 10);
        }
    }

}