import BaseCsApplicationsViewModel from 'candidate-self-service/component/cs-applications/CsApplicationsViewModel';
import { Observable, PureComputed, observable, pureComputed } from 'knockout';
import { isNoDataLoaded } from '../../service/isNoDataLoaded';

export default class CsApplicationsViewModel extends BaseCsApplicationsViewModel {
    showNoDataComponent: PureComputed<boolean>;
    isAriaHidden: Observable<boolean>;

    constructor() {
        super();

        this.showNoDataComponent = pureComputed(() => isNoDataLoaded(this.stateArray));
        this.isAriaHidden = observable(false);
    }

    onDialogClose(): void {
        this.isAriaHidden(false);
    }
}
