import TileProfileItemsViewModel from 'apply-flow/module/tile-profile-items/TileProfileItemsViewModel';
import skillService from './service/skillRecommendation';
import { observable } from 'knockout';

export default class SkillViewModel extends TileProfileItemsViewModel {

    constructor(...args) {
        super(...args);

        const [{ isActive }] = args;

        this.isActive = isActive;
        this.sectionId = this.blockProperties.sectionId;
        this.isValidating = observable(false);

        this.isSkillRecommendationEnabled =
            skillService.isSkillRecommendationEnabled();

        this.createTile = this.createTile.bind(this);
    }

    createTile(skill) {
        if (!skill) {
            return Promise.resolve();
        }

        return this.addAndInitializeProfileItem(false, this._profileItemInitializer(skill));
    }

    _profileItemInitializer(skill) {
        return (form) => {
            const element = form.getElement('skills');

            element.value(skill);

            form.buildTile();

            this.isValidating(true);

            return this.validateProfileItemForm(form)
                .finally(() => this.isValidating(false));
        };
    }

}
