import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(linkedSites, config) {
            const formElements = linkedSites
                .map((linkedSite, index) => this._createLinkFormElement({
                    linkedSite,
                    linkNumber: (index + 1),
                    config,
                }));

            const form = formBuilder.createForm().bindComponent('candidate-links');

            form.elements(formElements);

            return form;
        },
    },

    _createLinkFormElement: {
        value({ linkedSite, linkNumber, config }) {
            const label = `${config.label} ${linkNumber}`;

            const formElement = formElementFactory.create('siteLink', `${config.name}-${linkNumber}`);

            formElement.label(label);
            formElement.attributes('id', `url-${linkNumber}`);
            formElement.isRemovable(linkNumber > 1);

            if (config.required && linkNumber === 1) {
                formElement.validators('required', { label: config.label });
            }

            formElement.registerModel(linkedSite);

            return formElement;
        },
    },
});