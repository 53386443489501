import { observable } from 'knockout';
import modeService, { MODES } from './service/mode';
import {
    isSearchByRecruitingLocationsModeEnabled,
    isZipcodeSearchEnabled,
    isGeolocationSearchEnabled,
} from 'cx/module/search/module/location/config/locationSearchConfig';

class AbstractModeMenuViewModel {

    constructor() {
        this.mode = modeService.currentMode;
        this.MODES = MODES;
        this.isModeMenuVisible = observable(false);
        this.isSearchByRecruitingLocationsModeEnabled = isSearchByRecruitingLocationsModeEnabled();
        this.isZipcodeEnabled = isZipcodeSearchEnabled();
        this.isGeolocationEnabled = isGeolocationSearchEnabled();
        this.handleModeSet = this.handleModeSet.bind(this);
    }

    handleModeSet(mode) {
        modeService.setMode(mode);
        this.hideModeMenu();
    }

    toggleModeMenu() {
        this.isModeMenuVisible(!this.isModeMenuVisible());
    }

    hideModeMenu() {
        this.isModeMenuVisible(false);
    }

}

export default AbstractModeMenuViewModel;
