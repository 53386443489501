import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import MultiSelectPillElement from './element/MultiSelectPill';
import MultiSelectListElement from 'core/form/element/MultiSelectList';
import AtLeastOneSelected from './validator/AtLeastOneSelected';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import { JOB_CATEGORY_VALUE, JOB_FUNCTION_VALUE } from 'cx/module/job-alerts/enums/additionalAttributes';

export default Object.create(formBuilder, {

    createForm: {
        value(config, model, fetchJobFamilies, fetchJobLocations, jobFunctions) {
            const form = formBuilder.createForm();

            if (config.includes(JOB_CATEGORY_VALUE)) {
                form.addElement(
                    this._createPreferredJobFamiliesFormElement(model.preferredJobFamilies, fetchJobFamilies));
            }

            if (config.includes(JOB_FUNCTION_VALUE)) {
                form.addElement(
                    this._createPreferredJobFunctionsFormElement(model.preferredJobFunctions, jobFunctions));
            }

            form.addElement(this._createPreferredLocationsFormElement(model.preferredLocations, fetchJobLocations));

            const preferenceElements = [
                form.getElement('preferredJobFamilies'),
                form.getElement('preferredJobFunctions'),
                form.getElement('preferredLocations'),
            ];

            this._addJobAlertPreferencesValidator(
                preferenceElements,
                jobAlertService.getValidationMessage(config),
            );

            return form;
        },
    },

    _createPreferredLocationsFormElement: {
        value(model, fetchJobFamilies) {
            const label = i18n('talent-community.job-alerts-self-service.location-label');

            const formElement = new MultiSelectPillElement({
                fetch: fetchJobFamilies,
            });

            formElement.label(label);

            formElement.attributes({
                name: 'preferredLocations',
                id: 'preferred-locations',
            });

            formElement.registerModel(model);

            return formElement;
        },
    },

    _createPreferredJobFamiliesFormElement: {
        value(model, fetchJobLocations) {
            const label = i18n('talent-community.job-alerts-self-service.category-label');

            const formElement = new MultiSelectPillElement({
                fetch: fetchJobLocations,
            });

            formElement.label(label);

            formElement.attributes({
                name: 'preferredJobFamilies',
                id: 'preferred-job-families',
            });

            formElement.registerModel(model);

            return formElement;
        },
    },

    _createPreferredJobFunctionsFormElement: {
        value(model, jobFunctions) {
            const label = i18n('talent-community.job-alerts-self-service.preferred-job-function-label');

            const formElement = new MultiSelectListElement();

            formElement.label(label);

            formElement.attributes({
                name: 'preferredJobFunctions',
                id: 'preferred-job-functions',
            });

            formElement.options(jobFunctions);

            formElement.registerModel(model);

            return formElement;
        },
    },

    _addJobAlertPreferencesValidator: {
        value(preferenceElements, message) {
            const activePreferenceElements = preferenceElements.filter(element => element);

            const atLeastOneSelected = new AtLeastOneSelected({
                preferenceValues: activePreferenceElements.map(element => element.value),
                message,
            });

            const firstPreferenceElement = activePreferenceElements.shift();

            firstPreferenceElement.addValidator(atLeastOneSelected);

            activePreferenceElements.forEach((element) => {
                element.value.subscribe(() => firstPreferenceElement.validate());
            });
        },
    },

});
