import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';
import appConfig from 'app/model/config';
import csEvents from '../../config/events';

export default class CsApplicationDraftTileViewModel {

    constructor({ draft }) {
        this.draft = draft;

        this.jobUrl = absoluteRouter.interpolate({}, 'job-details', { jobId: this.draft.jobId });
    }

    continueApplication() {
        // If draft siteNumber is the same as current site - params from appConfig will be used
        const routerParams = this.draft.siteNumber === appConfig.siteNumber
            ? {}
            : { siteCode: this.draft.siteNumber, siteUrlName: this.draft.siteUrlName };

        const applyFlowUrl = absoluteRouter.interpolate(routerParams, 'apply-flow', {
            jobId: this.draft.jobId,
            sectionNumber: '1',
        });

        router.redirect(applyFlowUrl);
    }

    openJobPreview() {
        router.go('job-details', { jobId: this.draft.jobId });
    }

    showRemoveDraftDialog() {
        csEvents.openDeleteDraftDialog.dispatch(this.draft);
    }

}