import FormValidator from 'core/form/validator/Validator';

export default class GreaterFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.greater-than';

        if (this._options.inclusive) {
            this._message = 'validators.min-value';
        }

        this._options.min_value = this._options.min;
    }

    _validate(value, options) {
        if (value) {
            return (options.inclusive ? value >= options.min : value > options.min);
        }

        return true;
    }

}