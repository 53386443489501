import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';

export default class RuleViewModel extends ElementViewModel {

    constructor({ params }) {
        super({ params });

        this.percentageWidth = pureComputed(this._computePercentageWidth, this);
        this.cssClass = pureComputed(this._computeCssClass, this);
    }

    _computePercentageWidth() {
        return `${this.params.width() * 100}%`;
    }

    _computeCssClass() {
        return `cc-rule--align-${this.params.align()} ${this.params.cssClass()}`;
    }

}
