import ko from 'knockout';
import router from 'app/model/router';
import sourceTraceRepository from './sourceTraceRepository';
import storageService from 'core/storage/sessionStorage';
import referrerService from './referrer';
import { isApplicationStored } from 'ce-common/service/withdrawService';

const PST_STORAGE_KEY = 'source-tracking-pst';
const UTM_STORAGE_KEY = 'source-tracking-utm';
const LAST_JOB_STORAGE_KEY = 'source-tracking-last-job';
const LAST_EVENT_STORAGE_KEY = 'source-tracking-last-event';

let sourceTraceCache = storageService.restore(UTM_STORAGE_KEY);
let parentSourceTrackingId = storageService.restore(PST_STORAGE_KEY);

function getSourceTraceDataFromUrl() {
    const { query } = router.routeParams();
    const queryParams = query || {};

    let utmParams = {
        source: queryParams.utm_source,
        sourceMedium: queryParams.utm_medium,
        campaignCode: queryParams.utm_campaign,
        campaignPostId: queryParams.utm_post_id,
        referrerHeader: referrerService.get(),
        sourceInternalInfo: queryParams.utm_internal_info,
    };

    utmParams = Object.keys(utmParams)
        .filter(key => utmParams[key] !== undefined)
        .reduce((result, key) => {
            result[key] = utmParams[key];

            return result;
        }, {});

    return utmParams;
}

function storeSourceTraceDataFromUrl() {
    const utmParams = getSourceTraceDataFromUrl();

    sourceTraceCache = utmParams;
    storageService.store(UTM_STORAGE_KEY, utmParams);
}

function getSourceTraceData() {
    if (sourceTraceCache) {
        return sourceTraceCache;
    }

    return getSourceTraceDataFromUrl();
}

function getParentSourceTrackingId() {
    const srcTrackingId = storageService.restore(PST_STORAGE_KEY);

    return srcTrackingId;
}

function storeParentSourceTrackingId(pst) {
    if (pst && !parentSourceTrackingId) {
        parentSourceTrackingId = pst;
        storageService.store(PST_STORAGE_KEY, pst);
    }
}

function createSourceTrace(sourceTraceData) {
    if (parentSourceTrackingId) {
        ko.utils.extend(sourceTraceData, { parentSourceTrackingId });
    }

    return sourceTraceRepository.create(sourceTraceData)
        .then((sourceTrace) => {
            storeParentSourceTrackingId(sourceTrace.sourceTrackingId);

            return sourceTrace;
        })
        .catch(err => console.error(err));
}

export const shouldStoreSourceTracking = () => {
    const navigationType = window.performance.getEntriesByType('navigation')[0]?.type;

    return navigationType && navigationType === 'navigate';
};

export const storeSourceTrackingInfo = () => {
    if (!shouldStoreSourceTracking()) {
        return;
    }

    referrerService.store();
    storeSourceTraceDataFromUrl();
};

export default {
    LEVEL: {
        JOB_APPLICATION: 'JOB_APPLICATION',
        EVENT_REGISTRATION: 'EVENT',
    },
    storeUtmParams: storeSourceTraceDataFromUrl,
    create(customTraceData) {
        if (customTraceData.sourceLevel === this.LEVEL.JOB_APPLICATION) {
            storageService.store(LAST_JOB_STORAGE_KEY, customTraceData.requisitionNumber);
        } else if (customTraceData.sourceLevel === this.LEVEL.EVENT_REGISTRATION) {
            storageService.store(LAST_EVENT_STORAGE_KEY, customTraceData.eventNumber);
        }

        const utmParams = getSourceTraceData();
        const sourceTraceData = ko.utils.extend(customTraceData, utmParams);

        return createSourceTrace(sourceTraceData);
    },
    createWithoutStorage(customTraceData) {
        const utmParams = getSourceTraceDataFromUrl();
        const sourceTraceData = ko.utils.extend(customTraceData, utmParams);

        return sourceTraceRepository.create(sourceTraceData)
            .catch(err => console.error(err));
    },
    shouldTraceSource(jobId) {
        const lastTrackedJob = storageService.restore(LAST_JOB_STORAGE_KEY);

        return lastTrackedJob !== jobId || isApplicationStored(jobId);
    },
    shouldTraceSourceForEvent(eventId) {
        const lastTrackedEvent = storageService.restore(LAST_EVENT_STORAGE_KEY);

        return lastTrackedEvent !== eventId;
    },
    removeParentSourceTrackingId() {
        storageService.remove(PST_STORAGE_KEY);
    },
    getSourceTraceData,
    getParentSourceTrackingId,
};