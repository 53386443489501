import ko from 'knockout';
import 'core/utils/subscribeOnce';
import formBuilderResolver from 'apply-flow/module/profile-items/service/formBuilderResolver';
import candidateModel from 'apply-flow/model/candidate';
import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import MetadataService from 'apply-flow/module/profile-items/service/Metadata';
import profileItemServiceFactory from 'apply-flow/module/profile-items/service/profileItemServiceFactory';
import synchronization from 'apply-flow/service/synchronization';
import modelResolver from 'apply-flow/module/profile-items/service/modelResolver';
import applyFlowEvents from 'apply-flow/config/events';
import applicationDraftService from 'apply-flow/service/applicationDraft';
import fixer from 'cx/module/apply-flow/component/apply-flow-fixer/service/fixer';
import { validationNotifications } from 'apply-flow/service/backend-validation/validationNotifications';
import CustomJsValidationBlockComponentViewModel from '../custom-js-validation/CustomJsValidationBlockComponentViewModel';
import { PROFILE_ITEMS_KEY_MAP, PROFILE_ITEMS_MAP } from 'apply-flow/module/profile-items/enum/profileItems';

export default class ProfileItemsViewModel extends CustomJsValidationBlockComponentViewModel {

    constructor(params) {
        super(params);

        const { section, code, title, blockId, blockProperties, metadataServiceUrl } = params;

        this.section = section;
        this.sectionNumber = section.number;
        this.code = code;
        this.title = title;
        this.blockId = blockId;
        this.blockProperties = blockProperties;
        this._getMetadata(metadataServiceUrl);
        this.readWriteService = null;
        this.profileItems = this._getCandidateProfileItems();
        this.forms = ko.observableArray();
        this._formElements = ko.observableArray();
        this.addButtonLabel = 'apply-flow.profile-item.add-item-button';
        this.isValidationInProgress = ko.observable(false);

        this.showAddButton = ko.pureComputed(() => {
            if (this._hideAddButton()) {
                return false;
            }

            const totalItems = this.forms().length;

            if (this._isMultiProfileItemSupported()) {
                return true;
            }

            return totalItems === 0;
        });

        this._refreshProfileItemsSignal = applyFlowEvents.refresh.add(this._refreshProfileItems, this);

        if (blockProperties?.sectionId) {
            this._initCustomJsValidation(blockProperties.sectionId);
        }

        this.subscribeToProfileItems();
        this._loadForms();
    }

    scrollToSelectedSubblock(blockId) {
        setTimeout(() => {
            document.getElementsByClassName(blockId)[0].scrollIntoView({
                behavior: 'smooth',
                // this option is required by: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
                passive: true,
            });
        }, 1000);
    }

    _hideAddButton() {
        return false;
    }

    _getMetadata(metadataServiceUrl) {
        const { contentTypeId, sectionId } = this.blockProperties;

        this.metadataService = new MetadataService(metadataServiceUrl, contentTypeId, sectionId, true);

        this._contentTypeId = contentTypeId;
        this._sectionId = sectionId;
    }

    _getCandidateProfileItems() {
        const profileItemsKey = PROFILE_ITEMS_MAP[this._contentTypeId];

        return candidateModel[profileItemsKey];
    }

    _loadForms() {
        return this._fetchFormElements()
            .then((formElements) => {
                this._formElements(formElements);
                this.readWriteService = this._getReadWriteService(this._contentTypeId, formElements);
            })
            .then(() => this._fetchProfileItems())
            .then(items => this._setProfileItems(items))
            .then(items => this._enableImmediateValidation(items))
            .then(() => sectionState.setBlockReady(this.sectionNumber, this.blockId));
    }

    _getReadWriteService(contentTypeId, formElements) {
        return profileItemServiceFactory.create(contentTypeId, formElements);
    }

    _fetchFormElements() {
        return this.metadataService.getFormElements();
    }

    _fetchProfileItems() {
        const cachedProfileItems = synchronization.unshiftAll(this._contentTypeId);

        if (cachedProfileItems.length) {
            applicationDraftService.updateCandidateModificationDate();

            this._setProfileItems(cachedProfileItems);
        }

        return Promise.resolve(candidateModel.id())
            .then(candidateId => candidateId || Promise.reject())
            .then(() => this.readWriteService.query(this._sectionId))
            .catch((error) => {
                console.error(error);

                return [];
            });
    }

    _setProfileItems(newProfileItems) {
        newProfileItems.forEach((profileItem) => {
            const findItem = ((item) => {
                const contentTypeId = item.contentTypeId();

                const idA = item[PROFILE_ITEMS_KEY_MAP[contentTypeId]];
                const idB = profileItem[PROFILE_ITEMS_KEY_MAP[contentTypeId]];

                return idA() && idA() === idB();
            });

            const profileItemExist = this.profileItems().find(findItem);

            if (!profileItemExist) {
                this.profileItems.push(profileItem);
            }
        });

        this.profileItems()
            .filter(profileItem => this._isNotDeleted(profileItem))
            .forEach(profileItem => this._insertSingleForm(profileItem));

        return this.profileItems();
    }

    _hasProfileItems() {
        return this.profileItems().length !== 0;
    }

    _syncForms(profileItemsChanges) {
        this._removeForms(profileItemsChanges);
        this._addForms(profileItemsChanges);
    }

    _removeForms(profileItemsChanges) {
        profileItemsChanges
            .filter(arrayChange => arrayChange.status === 'deleted')
            .map(arrayChange => arrayChange.value)
            .forEach(profileItem => this._removeSingleForm(profileItem));
    }

    _addForms(profileItemsChanges) {
        profileItemsChanges
            .filter(arrayChange => arrayChange.status === 'added')
            .map(arrayChange => arrayChange.value)
            .filter(profileItem => this._isNotDeleted(profileItem))
            .forEach(profileItem => this._insertSingleForm(profileItem));
    }

    _isMultiProfileItemSupported() {
        return this.metadataService.isMultiProfileItemSupported(this._contentTypeId);
    }

    _hasSameSectionId(profileItem) {
        return profileItem.sectionId() === this._sectionId;
    }

    _isNotDeleted(profileItem) {
        return profileItem.action() !== 'DELETE';
    }

    _refreshProfileItems() {
        sectionState.setBlockUnready(this.sectionNumber, this.blockId);

        return this._fetchProfileItems()
            .then(newProfileItems => this._setProfileItems(newProfileItems))
            .then(() => sectionState.setBlockReady(this.sectionNumber, this.blockId))
            .catch(console.error);
    }

    _insertSingleForm(profileItem) {
        if (!this._hasSameSectionId(profileItem)) {
            return false;
        }

        let form = this._getFormByItemId(profileItem.id());

        if (!form) {
            form = this._createSingleForm(profileItem);
            this.forms.push(form);

            sectionValidator.registerForm(form, this.sectionNumber);
        }

        return form;
    }

    _createSingleForm(profileItem) {
        const formBuilder = this._getFormBuilder();

        const form = formBuilder
            .createForm({
                elements: this._formElements(),
                contentTypeId: this._contentTypeId,
            })
            .registerModel(profileItem)
            .resolveElementsDependencies();

        this.customJsValidation.addToForm(form);
        this._disableContentItemId(form);

        return ko.observable(form);
    }

    _enableImmediateValidation(profileItems) {
        if (!profileItems) {
            return;
        }

        profileItems.forEach((profileItem) => {
            const form = this._getFormByItemId(profileItem.id());

            if (form) {
                form().enableImmediateValidation();
            }
        });
    }

    _disableContentItemId(form) {
        const model = form.model();

        if (!model.isNew()) {
            const formElement = form.getElement('contentItemId');

            if (formElement) {
                formElement.isDisabled(true);
            }
        }
    }

    _getFormBuilder() {
        return formBuilderResolver.getFormBuilder(this._contentTypeId);
    }

    _removeSingleForm(profileItem) {
        const form = this._getFormByItemId(profileItem.id());

        if (form) {
            form().dispose();
        }

        this.forms.remove(form);

        sectionValidator.unregisterForm(form, this.sectionNumber);
    }

    _getFormByItemId(profileItemId) {
        return ko.utils.arrayFirst(this.forms(), form => form().model().id() === profileItemId);
    }

    addProfileItem() {
        const profileItem = this._createItem();

        return this._pushProfileItem(profileItem);
    }

    _pushProfileItem(profileItem) {
        return new Promise((resolve) => {
            this.forms.subscribeOnce((changes) => {
                const form = changes
                    .filter(change => change.status === 'added')
                    .map(change => change.value())
                    .pop();

                resolve(form);
            }, this, 'arrayChange');

            this.profileItems.push(profileItem);
        });
    }

    _createItem() {
        const Model = modelResolver.getModel(this._contentTypeId);

        return new Model({
            contentTypeId: this._contentTypeId,
            sectionId: this._sectionId,
        });
    }

    removeProfileItem(profileItemForm) {
        this.scrollToSelectedSubblock(this.blockId);

        const profileItem = ko.unwrap(profileItemForm).model();

        this.profileItems.remove(profileItem);
        validationNotifications.clearNotifications();
        this.runCustomJsValidation();

        return this.blockId;
    }

    subscribeToProfileItems() {
        if (this.profileItems) {
            this._profileItemsArrayChangeSub = this.profileItems
                .subscribe(arrayChanges => this._syncForms(arrayChanges), this, 'arrayChange');
        }
    }

    validateProfileItemForm(profileItemForm) {
        profileItemForm.enableImmediateValidation();

        return profileItemForm.validate()
            .then((isValid) => {
                if (isValid) {
                    return this._runBackendValidation(profileItemForm);
                }

                this.focusInvalidElement();

                return Promise.reject();
            })
            .then(this.runCustomJsValidation);
    }

    focusInvalidElement() {
        const element = this._findContainerElement();

        fixer.focusInvalid(element);
    }

    _executeValidation(profileItem) {
        return this.readWriteService.validate(profileItem);
    }

    _runBackendValidation(profileItemForm) {
        this.isValidationInProgress(true);

        return this._executeValidation(profileItemForm.model())
            .then(() => {
                this.isValidationInProgress(false);

                return Promise.resolve();
            })
            .catch(() => {
                this.isValidationInProgress(false);

                return Promise.reject();
            });
    }

    _findContainerElement() {
        return null;
    }

    dispose() {
        this._refreshProfileItemsSignal.detach();
        this._profileItemsArrayChangeSub.dispose();
    }

}