import { ProviderConfigData } from '../../service/captchaService';

type Props = {
    captchaCallback: () => void;
    captchaData: ProviderConfigData;
};

export default class InvisibleCaptchaViewModel {
    public captchaCallback: () => void;
    public captchaData: ProviderConfigData;

    constructor({ captchaCallback, captchaData }: Props) {
        this.captchaCallback = captchaCallback;
        this.captchaData = captchaData;
    }
}
