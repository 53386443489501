import { workLocationDisplayKey } from 'cx/module/job-details/config/workLocationsSettings';

const cxConfig = window.CX_CONFIG || {};
const appConfig = cxConfig.app || {};

appConfig.languages = appConfig.languages || [];
appConfig.shortUrl = appConfig.shortUrl || {};
appConfig.ceBaseUrl = `${appConfig.uiBaseUrl}/CandidateExperience`;
appConfig.ceBaseSecuredUrl = `${appConfig.uiBaseUrl}/CandExpSecured`;
appConfig.ceSettingsUrl = `${appConfig.apiBaseUrl}/hcmRestApi/CandidateExperience`;
appConfig.templates = appConfig.templates || {};

appConfig.set = (path, value) => {
    const steps = path.split('.');
    const lastStep = steps.splice(-1, 1);

    let target = appConfig;

    steps.forEach((step) => {
        if (!target[step]) {
            target[step] = {};
        }

        target = target[step];
    });

    target[lastStep] = value;
};

appConfig.getSettingByKey = (key) => {
    const result = appConfig._findSetting(key);

    return (result && result.settingValue) ? result.settingValue : null;
};

appConfig.setSettingByKey = (key, value) => {
    if (!appConfig.settings) {
        appConfig.settings = [];
    }

    const { settings } = appConfig;
    const index = settings.indexOf(appConfig._findSetting(key));

    if (index > -1) {
        settings.splice(index, 1);
    }

    settings.push({
        settingKey: key,
        settingValue: value,
    });
};

appConfig.setSite = (config) => {
    if (!config) {
        return;
    }

    const {
        headerLinks,
        footerLinks,
        categoryTiles,
        cookies,
        chatbot,
        tcSignUp,
        facets,
        workLocationsDisplay,
        isDistanceCalculationEnabled,
    } = config;

    appConfig.set('links.header', headerLinks);
    appConfig.set('links.footer', footerLinks);
    appConfig.set('links.jobCategory', categoryTiles);
    appConfig.set('cookieConsent', cookies);
    appConfig.set('talentCommunitySignUp', tcSignUp);
    appConfig.set('isDistanceCalculationEnabled', isDistanceCalculationEnabled);

    Object.keys(chatbot).forEach((key) => {
        appConfig.setSettingByKey(key, chatbot[key]);
    });

    Object.keys(facets).forEach((key) => {
        appConfig.setSettingByKey(key, facets[key]);
    });

    appConfig.setSettingByKey(workLocationDisplayKey, workLocationsDisplay);
};

appConfig.setTheme = (config) => {
    if (!config) {
        return;
    }

    const { template: { welcomeText }, headerMode, footerMode, themeSettings, theme } = config;

    Object.keys(themeSettings).forEach((key) => {
        appConfig.setSettingByKey(key, themeSettings[key]);
    });

    appConfig.set('template.welcomeText', welcomeText);
    appConfig.set('headerMode', headerMode);
    appConfig.set('footerMode', footerMode);
    appConfig.set('theme', theme);
};

appConfig._findSetting = (key) => {
    if (!appConfig.settings) {
        return null;
    }

    return appConfig.settings
        .filter(({ settingKey }) => (settingKey === key))
        .shift();
};

appConfig.getTemplate = () => {
    if (!appConfig.template) {
        return 'mohegan';
    }

    return appConfig.template.templateNumber || 'mohegan';
};

export default appConfig;
