import applicationService from 'apply-flow/service/application';
import applicationsModel from 'candidate-self-service/model/applications';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';

function _confirmApplication(unconfirmedApplication) {
    unconfirmedApplication.isActionInProgress(true);

    return applicationService.accept(unconfirmedApplication)
        .then(confirmedApplication => applicationsModel.update(unconfirmedApplication, confirmedApplication))
        .catch((error) => {
            unconfirmedApplication.isActionInProgress(false);
            console.error(error);

            return Promise.reject(error);
        });
}

export default {

    confirmAllApplications(applications) {
        return applications.reduce((accumulator, application) => accumulator
            .then(() => _confirmApplication(application)), Promise.resolve())
            .finally(cachedCandidateApplications.clear.bind(cachedCandidateApplications));
    },
};