import Router from 'core/router/Router';
import appConfig from '../config';
import router from '../router';
import { ContextValue, Contexts, SitePathParams } from './types';

const baseRouter = new Router('base');

baseRouter.configure({
    site: {
        url: '/{context}/{lang}/sites/{siteUrlKey}',
    },
});

export default class AbsoluteRouter {
    baseURI: string;
    lang: string;
    siteCode: string;
    siteUrlName: string;
    context: ContextValue;

    constructor(baseURI: string) {
        this.baseURI = baseURI;
        this.lang = appConfig.siteLang;
        this.siteCode = appConfig.siteNumber;
        this.siteUrlName = appConfig.siteURLName;
        this.context = this.getCurrentContext();
    }

    interpolate(
        {
            // TODO: add defaultLang to CX_CONFIG fo admin app
            // so we could skip this additional check and load from appconfig
            lang = this.lang || appConfig.siteLang,
            siteCode = this.siteCode,
            siteUrlName = this.siteUrlName,
            context = this.context,
        }: SitePathParams = {} as SitePathParams,
        ...params: [urlId?: string, routeParams?: any, routeConfig?: any]
    ): string {
        const baseUrl = this.getSitePath({ context, lang, siteCode, siteUrlName });
        const [id, routeParams] = params;
        let routeConfig = params[2];

        routeConfig = routeConfig ? { action: 'utmLink', ...routeConfig } : { action: 'utmLink' };

        const pathAndQuery = router.interpolate(id, routeParams, routeConfig);

        return `${baseUrl}${pathAndQuery}`;
    }

    protected getSitePath({ context, lang, siteCode, siteUrlName }: SitePathParams): string {
        const siteUrlKey = this.getSiteUrlKey(siteUrlName, siteCode);

        return this.getUIBaseUrl() + baseRouter.interpolate('site', { context, lang, siteUrlKey });
    }

    protected getSiteUrlKey(siteUrlName = '', siteCode = ''): string {
        return siteUrlName || siteCode;
    }

    private getCurrentContext(): ContextValue {
        return this.baseURI.indexOf(Contexts.SECURED) !== -1 ? Contexts.SECURED : Contexts.PUBLIC;
    }

    private getUIBaseUrl(): string {
        const anchor = document.createElement('a');

        anchor.href = appConfig.uiBaseUrl;

        return anchor.href.replace(/\/$/, '');
    }
}
