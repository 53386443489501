import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import { setColumnSizes } from '../../service/setColumnSizes';

export default class RowViewModel extends ElementViewModel {

    constructor({ row, pageData, onMessage }) {
        const { params, columns } = row;

        super({ params });

        this.columns = columns;
        this.pageData = pageData;
        this.onMessage = onMessage;

        setColumnSizes(this.params.columnLayout(), columns);
    }

}
