import i18n from 'core/i18n/i18n';

export default class AgreementDialogViewModel {

    constructor({ isVisible, model, headerText, resetFocus }) {
        this.isVisible = isVisible;
        this.model = model;
        this.headerText = headerText;
        this.resetFocus = resetFocus;
        this.backLabel = i18n('general.previous-button');
    }

    accept() {
        this.model.accept();
        this.isVisible(false);
    }

    show() {
        this.isVisible(true);
    }

    hide() {
        this.isVisible(false);
    }

}