import AbstractStorage from './abstractStorage';

class LocalStorage extends AbstractStorage {

    _getStorageHandler() {
        return window.localStorage;
    }

}

const localStorage = new LocalStorage();

export default localStorage;