import { components } from 'knockout';

import { JobDetailsSimilarJobsViewModel } from './JobDetailsSimilarJobsViewModel';
import template from './job-details-similar-jobs.html';

components.register('job-details-similar-jobs', {
    viewModel: JobDetailsSimilarJobsViewModel,
    template,
});

components.register('cc-job-details-similar-jobs', {
    viewModel: JobDetailsSimilarJobsViewModel,
    template,
});
