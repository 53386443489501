import flowBlockMapper from 'apply-flow/mapper/flowBlock';

export default {
    mapFlowPagesFromRest(restPages) {
        return restPages
            .reduce((pages, restPage) => restPage.blocks
                .filter(this._isNotLinksBlock, this)
                .map(this._createPageFromBlock, this), [])
            .map(this.mapFlowPageFromRest)
            .filter(this._isNotEmptyPage);
    },

    _createPageFromBlock(block, index) {
        return {
            pageName: block.blockTitle,
            pageSequence: `${index + 1}`,
            blocks: [block],
        };
    },

    _isNotEmptyPage(page) {
        return page.blocks.length;
    },

    _isNotLinksBlock(block) {
        return block.blockCode !== 'ORA_LINKS';
    },

    mapFlowPageFromRest(restPage) {
        return {
            title: restPage.pageName,
            number: `${restPage.pageSequence}`,
            blocks: flowBlockMapper.mapFlowBlocksFromRest(restPage.blocks),
        };
    },
};