const listeners = [];

window.addEventListener('message', (event) => {
    const { key, value } = event.data;

    if (listeners[key]) {
        listeners[key].forEach(callback => callback(value));
    }
});

export default {
    add(key, callback) {
        if (!listeners[key]) {
            listeners[key] = [];
        }

        listeners[key].push(callback);
    },

    remove(key, callback) {
        const index = listeners[key].indexOf(callback);

        if (index < 0) {
            console.error(`No listener for key: ${key}`);

            return;
        }

        listeners[key].splice(index, 1);
    },

    dispatch(key, value, target) {
        const messageTarget = target || window.opener;

        messageTarget.postMessage({
            key,
            value,
        }, window.location.origin);
    },
};
