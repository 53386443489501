export default class AbstractSearchBoxInputViewModel {

    constructor({ value, action, radiusValue, radiusUnit, beforeSearch }) {
        this.value = value;
        this.action = action;
        this.radiusValue = radiusValue;
        this.radiusUnit = radiusUnit;
        this.beforeSearch = beforeSearch;
    }

}
