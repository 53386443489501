import { JobDetailsPageData, JobDetailsViewData } from 'minimal/module/job-details/types';
import { Observable, PureComputed } from 'knockout';
import mapping from 'knockout';

type Props = {
    viewData: JobDetailsViewData;
    pageData: PureComputed<JobDetailsPageData>;
};

export class JobDetailsHeaderViewModel {
    lang: Observable<string>;
    goBackLabel: string;
    isPreviousRouteStored: boolean;
    close: () => void;
    viewType: string;
    pageData: JobDetailsPageData | null;

    constructor({ viewData, pageData }: Props) {
        const { goBackLabel, isPreviousRouteStored, close, lang, viewType } = viewData;

        this.goBackLabel = goBackLabel;
        this.isPreviousRouteStored = isPreviousRouteStored;
        this.close = close;
        this.lang = lang;
        this.viewType = viewType;
        this.pageData = mapping.toJS(pageData);
    }
}
