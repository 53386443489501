export async function httpGetAllItems(httpMethod, url, config) {
    const { params: { limit } } = config;
    const configCopy = JSON.parse(JSON.stringify(config));
    let offset = 0;
    let hasMore = false;

    const response = await httpMethod(url, configCopy);

    hasMore = response.count === limit;

    while (hasMore) {
        offset += limit;
        configCopy.params.offset = offset;
        hasMore = false;

        try {
            const offsetResponse = await httpMethod(url, configCopy);

            response.items.push(...offsetResponse.items);
            response.count += offsetResponse.count;

            hasMore = offsetResponse.count === limit;
        } catch (error) {
            console.error(error);
        }
    }

    return response;
}