import { noOperation } from 'app/module/core/utils/noOperation';
import Page from 'cx/module/custom-content/model/Page';

type Props<PageData, PageMessage> = {
    page: Page;
    classModifier?: string;
    pageData?: PageData;
    onMessage?: (message: PageMessage) => void;
};

export default class PageViewModel<PageData, PageMessage> {
    page: Page;
    pageData: PageData | undefined;
    classWithModifier: string;
    isPageDisplayBoxed: boolean;
    cssClass: string;
    onMessage: (message: PageMessage) => void;

    constructor({ page, pageData, classModifier, onMessage }: Props<PageData, PageMessage>) {
        this.page = page;
        this.pageData = pageData;
        this.cssClass = page?.params?.cssClass ? page?.params?.cssClass : '';
        this.classWithModifier = classModifier ? `cc-page--${classModifier} ${this.cssClass}` : '';
        this.isPageDisplayBoxed = page?.params?.display === 'boxed';
        this.onMessage = onMessage || noOperation;
    }
}
