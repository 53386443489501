import $ from 'jquery';
import ko from 'knockout';


let $edge = null;
let $scrollable = null;
let isUpdating = false;

const settings = {
    offset: 300,
    onWindowScroll: true,
    loader() {},
};

/**
 * Calls handler function if when scroll position reaches certain offset from the edge of element this binding
 * is used for. This binding looks for 'setting' binding on the same element and uses it as configuration.
 * The configuration includes:
 *   - onWindowScroll {boolean} - flag to indicate whether this binding should listen to scroll event on window or
 *     parent node. Defaults to true.
 *   - offset {integer} - offset in px from the bottom edge of the container (window/parent node) when the handler
 *     function should be called. Defaults to 300.
 *   - loader {function} - the actual function to be called on scroll/resize events when scroll position is
 *     {offset}px from the bottom of the container. Defaults to no-op function.
 * @listens scroll
 * @listens resize
 * @param {boolean} - enables/disables infinite scroll functionality.
 * @example
 * <div>
 *     <!-- veeery long content here -->
 * </div>
 * <div data-bind="infiniteScroll: isInfinite,
 *                 settings: {offset: 500, onWindowScroll: false, loader: scrollHandler}">
 * </div>
 */

ko.bindingHandlers.infiniteScroll = {
    init(element, value, allBindings) {
        $edge = $(element);
        $.extend(settings, allBindings().settings);

        if (settings.onWindowScroll) {
            $scrollable = $(window);
        } else {
            $scrollable = $(element.parentNode);
        }

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            $scrollable.off('scroll.ko.infiniteScroll resize.ko.infiniteScroll');
            isUpdating = false;
        });
    },

    update(element, value) {
        const loadMore = ko.utils.unwrapObservable(value());

        if (loadMore) {
            $edge.show();

            $scrollable.on('scroll.ko.infiniteScroll resize.ko.infiniteScroll', () => {
                if ($edge.offset().top - settings.offset <= $scrollable.height() + $scrollable.scrollTop()) {
                    if (!isUpdating) {
                        isUpdating = true;
                        settings.loader();
                    }
                } else {
                    isUpdating = false;
                }
            });
        } else {
            $edge.hide();
            $scrollable.off('scroll.ko.infiniteScroll resize.ko.infiniteScroll');
            isUpdating = false;
        }
    },
};
