import { observable, observableArray, computed, utils } from 'knockout';
import router from 'app/model/router';
import mapping from 'knockout-mapping';
import formBuilder from 'core/form/formBuilder';
import formValidatorFactory from 'core/form/validator/factory';
import candidateModel from 'apply-flow/model/candidate';
import RegulatoryResponseModel from 'apply-flow/module/regulatory/model/RegulatoryResponse';
import ApplyFlowBlockComponentViewModel from 'apply-flow/module/common/ApplyFlowBlockComponentViewModel';
import sectionValidator from 'apply-flow/model/sectionValidator';
import { getCandidateProfileRegulatoryResponse } from './service/PrefillRegulatoryResponseData';
import { isPrefillLegInfoEnabled } from 'app/service/prefillResponses';
import mapper from 'apply-flow/module/regulatory/mapper/regulatoryResponses';
import { useDraftData } from 'apply-flow/service/applicationDraftMonitor';
import { AMP, PIPE, DASH } from 'apply-flow/module/regulatory/mapper/prefillRegulatoryResponses';
import applyFlowEvents from 'apply-flow/config/events';
import { ORA_DIVERSITY, ORA_VETERAN } from '../../enum/blockTypes';

const DDF = 'DDF';
const DFF = 'DFF';

export default class RegulatoryViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params, metadataService) {
        super(params);

        const requisitionNumber = router.routeParams().jobId;

        this.displayBlock = observable(false);
        this.legislations = observableArray();
        this.baseReportHeader = observable('');

        this.reportHeader = computed(() => this.baseReportHeader().replace('&amp;DISPLAY_NAME', this._getCandidateFullName()));
        this.headerText = computed(() => this.reportHeader().replace(/<style[.\w\W]+\/style>/g, '').replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ''));

        metadataService.getFormElements(requisitionNumber)
            .then(this._getCandidateProfileRegulatoryResponseInfo.bind(this))
            .then(this._createModels.bind(this))
            .then(this._setBlockReady.bind(this))
            .then(() => {
                if (this.legislations().length > 1) {
                    applyFlowEvents.submitHasErrors.add(() => this._submitFailed());
                }
            });
    }

    async _getCandidateProfileRegulatoryResponseInfo(blockDefinition) {
        if (isPrefillLegInfoEnabled() && (!candidateModel.isNew())) {
            await this._getCandidateProfileRegulatoryResponseConfig(blockDefinition.legislations);
        }

        return blockDefinition;
    }

    _createModels(blockDefinition) {
        const formData = [];

        blockDefinition.legislations.forEach((legislation) => {
            const { header, footer, legislationCode, legislationName } = legislation;

            if (legislationCode === 'US') {
                this.displayBlock(true);

                if (header && header.trim()) {
                    this.baseReportHeader(header.replace('&amp;DATE', new Date().toLocaleDateString('en-US')));
                }
            }

            const formElements = legislation
                .formElementsDef
                .map(formElementDef => formBuilder.createFormElement(formElementDef))
                .map(formElement => formElement.registerModel(this._getResponseModel(formElement.name()).value));

            formElements.forEach((formElement) => {
                // TODO: Remove below validators when REST services will accept true JSON instead of string
                // with && and || used as delimiters between properties, keys and values

                const component = formElement.component();

                if (component === 'text-form-element' || component === 'textarea-form-element') {
                    const ampValidator = formValidatorFactory.create('notIncludes', {
                        substring: AMP,
                    });

                    const pipeValidator = formValidatorFactory.create('notIncludes', {
                        substring: PIPE,
                    });

                    // addValidator does no allow to create two same type validators using
                    // api: addValidator('notIncludes', options), thus formValidatorFactory is required
                    formElement.addValidator(ampValidator);
                    formElement.addValidator(pipeValidator);
                }
            });

            const form = formBuilder
                .createForm()
                .elements(formElements);

            // Generate form based on form element definition
            const formEntity = observable(form);

            formData.push({
                code: legislationCode,
                name: legislationName,
                hasReport: (legislationCode === 'US'),
                header,
                footer,
                form: formEntity,
            });

            // Register form for the regulatory section
            sectionValidator.registerForm(formEntity, this.sectionNumber);
        });

        return this.legislations(formData);
    }

    _getCandidateFullName() {
        const { basicInformation } = candidateModel;
        const { lastName, firstName, middleNames, suffix } = mapping.toJS(basicInformation);

        return [firstName, middleNames, lastName, suffix]
            .filter(Boolean)
            .join(' ')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    _getResponseModel(id) {
        let responseModel = utils.arrayFirst(candidateModel.regulatoryResponses(),
            regulatoryResponse => regulatoryResponse
                && regulatoryResponse.id() === id);

        if (!responseModel) {
            responseModel = new RegulatoryResponseModel({
                id,
            });

            candidateModel.regulatoryResponses.push(responseModel);
        }

        return responseModel;
    }

    _getCandidateProfileRegulatoryResponseConfig(legislations) {
        const regulatoryResponseArray = [];

        legislations.forEach((legislation) => {
            const data = legislation
                .formElementsDef
                .map(formElementDef => formBuilder.createFormElement(formElementDef))
                .map(formElement => new RegulatoryResponseModel({
                    id: formElement.name(),
                }));

            regulatoryResponseArray.push(...data);
        });


        if (regulatoryResponseArray?.length > 0) {
            const { jobId } = router.routeParams();
            const payload = mapper.mapDataToRest(regulatoryResponseArray, jobId, true);

            getCandidateProfileRegulatoryResponse(payload, jobId)
                .then(mappedRestResponse => this._updateCandidateModel(mappedRestResponse.prefillResponses));
        }
    }

    _updateCandidateModel(mappedPrefillResponse) {
        mappedPrefillResponse.forEach(({ id, value }) => {
            let regRespId = id;

            let candidateResponseModel = utils.arrayFirst(candidateModel.regulatoryResponses(),
                (regulatoryResponse) => {
                    const [legislation, fieldType, attributeName, columnName] = regulatoryResponse.id().split(DASH);
                    const [mappedLegislation, mappedFieldType, mappedAttributeName, mappedColumnName] = id.split(DASH);

                    if (legislation === mappedLegislation
                    && attributeName === mappedAttributeName
                    && columnName === mappedColumnName
                    && (fieldType === DDF && mappedFieldType === DFF)) {
                        regRespId = id.replace(DFF, DDF);
                    }

                    return regulatoryResponse.id() === regRespId;
                });

            if (!candidateResponseModel) {
                candidateResponseModel = new RegulatoryResponseModel({
                    regRespId,
                    value,
                });

                candidateModel.regulatoryResponses.push(candidateResponseModel);
            } else {
                utils.arrayFirst(candidateModel.regulatoryResponses(),
                    (regulatoryResponse) => {
                        if ((regulatoryResponse
                            && regulatoryResponse.id() === regRespId)
                            && ((!useDraftData()
                                || !regulatoryResponse.value()))) {
                            if (Array.isArray(regulatoryResponse.value())) {
                                regulatoryResponse.value((value.trim().length !== 0) ? value.split(',') : []);
                            } else {
                                regulatoryResponse.value(value);
                            }
                        }
                    });
            }
        });
    }

    isAnyFieldPrefilled(index) {
        return this.legislations()[index].form().elements().some((element) => {
            const value = element.value();

            if (!value || (Array.isArray(value) && value.length === 0)) {
                return false;
            } else if (value
                && element.component() === 'checkbox-string-value-form-element'
                && value === 'N') {
                // Do not expand accordion for checkbox type field with value "N", No visual as element is unchecked
                return false;
            }

            return true;
        });
    }

    isAnyFieldRequired(index) {
        return this.legislations()[index].form().elements().some(element => element.isRequired());
    }

    _submitFailed() {
        // Do not check for veteran blocks
        if (this.legislations().length > 1 && this.code !== ORA_VETERAN) {
            this.legislations().forEach((legislation) => {
                const state = !(legislation.form().isValid());

                if (state) {
                    let code = 'Disability';

                    if (this.code === ORA_DIVERSITY) {
                        code = 'Diversity';
                    }


                    const accordionId = `accordion${code}_${legislation.code}`;
                    const collapsibleId = `collapsible${code}_${legislation.name}`;
                    const accordion = document.getElementById(accordionId);

                    // Expand/Collapse Accordion
                    accordion.set('expanded', [{ id: collapsibleId }]);
                }
            });
        }
    }

}