export function normalize(stringDate) {
    const NO_ZONE_DATE_PATTERN = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

    if (typeof stringDate !== 'string') {
        throw new Error('Improper argument type');
    }

    if (!stringDate.match(NO_ZONE_DATE_PATTERN)) {
        return stringDate;
    }

    return stringDate.replace(/-/g, '/');
}
