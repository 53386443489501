import base64 from 'core/utils/base64';

export default {
    fromRest(restResponse) {
        if (!restResponse || !restResponse[0]) {
            return {
                html: null,
                pdf: null,
            };
        }

        const [{ html, pdf }] = restResponse;

        return {
            html: base64.decode(html),
            pdf,
        };
    },
};