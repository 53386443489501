import { Typography } from 'minimal/module/search/service/customCss';

export const EMPTY_TYPOGRAPHY: Typography = {
    fontFamily: null,
    fontSize: null,
    fontWeight: null,
    fontStyle: null,
    color: null,
    textAlign: null,
    lineHeight: null,
};
