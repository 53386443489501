import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import router from 'app/model/router';
import favouriteJobsService from 'minimal/service/favouriteJobs';
import { isCopyLinkVisible } from 'cx/module/job-details/service/copyLinkVisibility';
import { Job } from 'minimal/module/job-details/types';
import { SearchContext, SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';

export type JobDetailsHeaderDisplaySettings = {
    iconHiddenFavorite: boolean;
    iconHiddenShare: boolean;
};

type Props = {
    job: Job;
    displaySettings: PureComputed<JobDetailsHeaderDisplaySettings>;
};

export class JobDetailsHeaderContentViewModel {
    job: Job;
    displaySettings: PureComputed<JobDetailsHeaderDisplaySettings>;
    isFavourite: PureComputed<boolean>;
    showCopyLink: Observable<boolean>;
    showShareWidget: PureComputed<boolean>;
    jobId: Observable<string>;
    context: SearchContext;

    constructor({ job, displaySettings }: Props) {
        this.job = job;
        this.jobId = observable(job.id);
        this.displaySettings = displaySettings;
        this.isFavourite = favouriteJobsService.isFavourite(job);
        this.showCopyLink = observable<boolean>(false);

        this.showShareWidget = pureComputed(
            () => !this.displaySettings().iconHiddenShare && this.showCopyLink()
        );

        this.setCopyLinkVisibility();

        this.context = SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS;
    }

    toggleFavourite(): void {
        favouriteJobsService.toggleFavourite(this.job);
    }

    setCopyLinkVisibility(): void {
        isCopyLinkVisible({
            routeId: router.route().id,
            jobId: this.job.id,
        }).then(this.showCopyLink);
    }
}
