import { components } from 'knockout';

import '../search-job-results';
import '../search-event-results';
import ContentComponent from 'cx/module/custom-content/model/ContentComponent';
import ViewModel from './SearchResultsViewModel';
import template from './search-results.html';

components.register('search-results', { viewModel: ViewModel, template });

components.register('cc-search-results', {
    viewModel: {
        createViewModel(params: ContentComponent) {
            return new ViewModel(params);
        },
    },
    template,
});
