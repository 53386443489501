import appConfig from 'app/model/config';
import { CAPTCHA_SETTING_KEY } from 'app/service/globalSettings';
import { apiCaptcha, ApiCaptchaConfig, Providers } from './../config/captcha';
import { getHLanguage } from './languagesService';

export type ProviderConfigData = {
    siteKey: string;
    providerName: Providers;
    apiUrl: string;
};

const getCaptchaProviderSettings = (): ProviderConfigData => {
    const { provider: providerName } = appConfig.getSettingByKey(CAPTCHA_SETTING_KEY) || {};

    const { site_key: siteKey, service_url: apiUrl } =
        appConfig.getSettingByKey(CAPTCHA_SETTING_KEY)?.settings || {};

    return {
        providerName,
        siteKey,
        apiUrl,
    };
};

export const isCaptchaEnable = (): boolean => {
    const { providerName, siteKey, apiUrl } = getCaptchaProviderSettings();

    return Boolean(providerName) && Boolean(siteKey) && Boolean(apiUrl);
};

export const getCaptchaProviderData = (): ProviderConfigData & ApiCaptchaConfig => {
    const { providerName, siteKey, apiUrl } = getCaptchaProviderSettings();

    let finalApiUrl = apiUrl;

    if (providerName == 'hCAPTCHA') {
        finalApiUrl = `${apiUrl}?hl=${getHLanguage()}&onload=onCaptchaLoad&render=explicit`;
    }

    return {
        siteKey: siteKey,
        providerName: providerName,
        apiUrl: finalApiUrl,
        componentName: apiCaptcha[providerName as Providers]?.componentName,
    };
};

export const captchaAction = (): HCaptcha => {
    const { providerName } = getCaptchaProviderSettings();

    if (providerName == 'hCAPTCHA') {
        return hcaptcha;
    }

    throw new Error('wrong provider name');
};
