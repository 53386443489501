import { Observable, observable } from 'knockout';

export type ObservableParams<T> = {
    [P in keyof T]: Observable<T[P]>;
};

export type ObservableConfiguration<T> = {
    [P in keyof T]: ObservableParams<T[P]>;
};

export const mapParamsToObservableParams = <T>(paramsObject: T): ObservableParams<T> => {
    const entries = Object.entries(paramsObject as object).map(([key, value]) => [key, observable(value)]);

    return Object.fromEntries(entries) as ObservableParams<T>;
};

export const mapParamsConfigurationToObservable = <T>(configurationObject: T): ObservableConfiguration<T> => {
    const entries = Object.entries(configurationObject as object).map(([key, value]) => [
        key,
        mapParamsToObservableParams(value),
    ]);

    return Object.fromEntries(entries) as ObservableConfiguration<T>;
};
