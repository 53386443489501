import { PureComputed, pureComputed } from 'knockout';
import router from 'app/model/router';
import searchEvents from 'search/config/events';
import { isTCOptInEnabled } from 'app/service/isTCOptInEnabled';

import appConfig from 'app/model/config';
import {
    noSearchResultsParams,
    SEARCH_RESULTS_STRINGS,
    SearchContext,
    SearchRouteNoSearchResults,
} from 'app/module/cx/module/search/config/types';

type RouterQuery = Record<string, unknown>;
type RouterParams = {
    query: RouterQuery;
};

const DEFAULT_PARAMS: noSearchResultsParams = {
    context: SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_JOBS,
    ctaRoute: SEARCH_RESULTS_STRINGS.SEARCH_ROUTE_JOBS,
    dataQaAttr: 'viewAllJobsBtn',
    beforeRedirect: () => Promise.resolve(true),
};

class NoResultsViewModel {
    talentCommunityTileEnabled: boolean;
    hasSearchCritera: PureComputed<boolean>;
    context: SearchContext;
    ctaRoute: SearchRouteNoSearchResults;
    ctaDataQa: string;
    beforeRedirect: () => Promise<boolean>;

    constructor({
        context,
        ctaRoute,
        dataQaAttr,
        beforeRedirect: beforeRedirect,
    }: noSearchResultsParams = DEFAULT_PARAMS) {
        this.talentCommunityTileEnabled = this._isTalentCommunityTileEnable();

        this.hasSearchCritera = pureComputed(() => {
            const { query } = router.routeParams() as RouterParams;

            return !!query && Object.keys(query).some((criteriaName) => criteriaName !== 'sortBy');
        });

        this.context = context;
        this.ctaRoute = ctaRoute;
        this.ctaDataQa = dataQaAttr;
        this.beforeRedirect = beforeRedirect;
    }

    runSearch(): void {
        searchEvents.query.search.dispatch({}, true);
    }

    _isTalentCommunityTileEnable(): boolean {
        return isTCOptInEnabled() && appConfig.talentCommunitySignUp.showWhenNoSearchResultsFound;
    }
}

export default NoResultsViewModel;
