import { DimensionUnit } from 'minimal/module/search/service/customCss';
import { FiltersContentParams, FiltersDisplayStyle } from '../config/types';
import { defaultContentParams } from '../config/defaultParams';

export const mapContentParams = (stringifiedParams: string | undefined | null): FiltersContentParams => {
    if (!stringifiedParams) {
        return defaultContentParams;
    }

    const parsedParams = JSON.parse(stringifiedParams) as Record<string, string | boolean | undefined | null>;

    return {
        filtersDisplayStyle:
            (parsedParams.filtersDisplayStyle as FiltersDisplayStyle) ||
            defaultContentParams.filtersDisplayStyle,

        width: parsedParams.width ? parseInt(String(parsedParams.width)) : defaultContentParams.width,
        widthUnit: (parsedParams.widthUnit as DimensionUnit) || defaultContentParams.widthUnit,
    };
};
