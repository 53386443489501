import appConfig from 'app/model/config';
import cookieStorage from 'core/cookie/storage';
import { TEMP_CANDIDATE_NUMBER } from '../userTracking';
import tokenService from '../../module/candidate-verification/service/token';

import signals, {
    APPLY_FLOW_ENTERED,
    APPLY_FLOW_DRAFT_CREATED,
    APPLY_FLOW_FINISHED,
} from '../../config/userEvents';

const APPLY_CLICK_KEY = 'PIXEL_TRACKING_URL_APPLY';
const APPLY_ENTRY_KEY = 'PIXEL_TRACKING_URL_APPLY_ENTRY';
const SUBMIT_KEY = 'PIXEL_TRACKING_URL_SUBMIT';

function createPixelImage({ candidateId, jobId }, pixelUrlSettingKey) {
    const pixelUrl = appConfig.getSettingByKey(pixelUrlSettingKey);

    if (!pixelUrl) {
        return;
    }

    const completeUrl = pixelUrl
        .replace('{reqId}', encodeURIComponent(jobId))
        .replace('{candId}', candidateId)
        .concat('&randno=')
        .concat(new Date().getTime());

    document.createElement('img').src = completeUrl;
}

function _getCandidateNumber() {
    return tokenService.getCandidateNumber()
    || cookieStorage.get(TEMP_CANDIDATE_NUMBER)
    || 'UNKNOWN';
}

export default {
    init() {
        signals[APPLY_FLOW_ENTERED].add(({ jobId }) => {
            const candidateId = _getCandidateNumber();

            createPixelImage({ candidateId, jobId }, APPLY_CLICK_KEY);
        });

        signals[APPLY_FLOW_DRAFT_CREATED].add(({ candidateId, jobId }) => {
            createPixelImage({ candidateId, jobId }, APPLY_ENTRY_KEY);
        });

        signals[APPLY_FLOW_FINISHED].add(({ candidateId, jobId }) => {
            createPixelImage({ candidateId, jobId }, SUBMIT_KEY);
        });
    },
};