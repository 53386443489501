import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { JobDetailsTitleParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-title/config/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import {
    desktopOnly,
    getTypographyCss,
    getTypographyStyles,
    wrapBySelector,
} from 'minimal/module/search/service/customCss';
import { PureComputed } from 'knockout';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsTitleParams;
    id?: string;
};

export class JobDetailsTitleViewModel extends CustomComponentViewModel<JobDetailsTitleParams> {
    pageData: PureComputed<JobDetailsPageData>;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
    }

    getCustomStyle(key: keyof JobDetailsTitleParams): string {
        switch (key) {
            case 'titleTypography':
                const selector = `.${this.uniqueWrapperClass} .job-details__title`;
                const { fontSize, lineHeight, ...rest } = this.customizationParams[key];

                const typography = getTypographyCss({
                    selector: selector,
                    typography: rest,
                });

                const typographyDesktopOnly = desktopOnly(
                    wrapBySelector(selector, getTypographyStyles({ fontSize, lineHeight }))
                );

                return `${typography} ${typographyDesktopOnly}`;

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }
}
