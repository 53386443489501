export default {
    mapPartnerDataFromRest({ requisitionNumber, assessmentUrl }) {
        return {
            requisitionNumber,
            partnerUrl: assessmentUrl,
        };
    },

    mapFromRest({ items: [{ assessmentCompletedFlag, assessmentConfiguredFlag }] }) {
        return {
            isPartnerCompleted: assessmentCompletedFlag,
            partnerConfiguredFlag: assessmentConfiguredFlag,
        };
    },

};