import sectionValidator from 'apply-flow/model/sectionValidator';
import sectionState from 'apply-flow/model/sectionState';
import talentCommunityFlowService from '../../service/talentCommunityFlow';
import talentCommunityUpdateMapper from '../../mapper/talentCommunityUpdate';
import TalentCommunityFlowViewModel from '../talent-community-flow/TalentCommunityFlowViewModel';
import { activateLogger } from 'core/api-logger/config/logger';

export default class TalentCommunityUpdateFlowViewModel extends TalentCommunityFlowViewModel {

    _loadFlow() {
        activateLogger();

        return talentCommunityFlowService.query(talentCommunityUpdateMapper);
    }

    _initializeSectionValidator(flow) {
        sectionValidator.initialize(flow.sections);

        return flow;
    }

    _initializeSectionState([flow]) {
        sectionState.initialize(flow.sections);

        this._setFlowReadySubscription();

        return flow;
    }

}
