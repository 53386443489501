import httpConfig from 'core/http/config';
import httpUrlInterpolator from 'core/http/urlInterpolator';
import httpRequest from 'core/http/request';
import httpErrorHandler from 'core/http/errorHandler';
import middleware from 'core/http/middleware';

export function makeRequest(url, data, method, requestConfig) {
    const defaults = Object.assign({}, httpConfig, httpConfig.getMethodConfig(method));
    const headers = Object.assign({}, httpConfig.headers);
    const statusCodes = Object.assign({}, httpConfig.statusCodes);

    let config = Object.assign(defaults, requestConfig);

    config.url = httpUrlInterpolator.interpolate(config.baseUrl + url, config.params, config.paramsDelimiter);
    config.body = data;
    config.method = method;
    config.headers = Object.assign(headers, config.headers);
    config.statusCodes = Object.assign(statusCodes, config.statusCodes);

    config = config.transformRequest(config);

    return makePreparedRequest(config);
}

export function makePreparedRequest(requestConfig) {
    let config = httpConfig.addAuthorizationHeader(requestConfig);

    config = httpConfig.addUserTrackingHeader(config);

    config = httpConfig.addTimestampHeader(config);

    config = httpConfig.addRestActionHeader(config);

    return httpRequest.create(config)
        .then((response) => {
            middleware.apply(response, config);

            if (response && !(config.responseType === 'arraybuffer')) {
                return config.transformResponse(response);
            }

            return response;
        })
        .catch((error) => {
            middleware.apply(error, config);

            return httpErrorHandler.manage(error, config);
        });
}
