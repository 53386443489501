import { observable, observableArray } from 'knockout';
import debouncePromise from 'core/utils/debouncePromise';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class MultiSelectListFormElement extends ValidatableFormElement {

    constructor({ fetch }) {
        super();

        this.component('multi-select-pill-form-element');
        this.rawValue = observable(null);
        this.fetch = fetch;
        this.options = observableArray();

        this.fetchDebounced = debouncePromise(this.fetch.bind(this), 500);
        this.noSuggestions = observable(false);
        this.fetchOptions = this.fetchOptions.bind(this);
    }

    fetchOptions(context) {
        this.noSuggestions(false);

        const fn = context.term ? this.fetchDebounced : this.fetch;

        return fn(context.term)
            .then(this._saveOptions.bind(this))
            .then(this._disableOptions.bind(this));
    }

    _saveOptions(options) {
        this.options(options);

        this.noSuggestions(!options.length);

        return options;
    }

    _removeOption(option) {
        const value = this.value();

        const optionToRemove = value
            .find(({ id }) => id === option.id);

        value.splice(value.indexOf(optionToRemove), 1);

        this.value(value);
    }

    _disableOptions(options) {
        const selectedIds = this.value().map(({ id }) => id);

        options
            .forEach(option => (option.disabled = (selectedIds.indexOf(option.id) !== -1)));

        return options;
    }

}