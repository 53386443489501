import appConfig from 'app/model/config';
import {
    WorkplaceTypeSiteShape,
    WorkplaceTypeKeys,
    workplaceTypeSettingsMap,
} from 'admin/module/site-editor/config/types';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import { WorkplaceType, WORKPLACE_TYPES } from 'cx/config/constants/workplaceType';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import store from 'site-editor/store/store';

const DEFAULT_VALUES = {
    WORKPLACE_TYPE_ONSITE_ENABLED: 'false',
    WORKPLACE_TYPE_REMOTE_ENABLED: 'true',
    WORKPLACE_TYPE_HYBRID_ENABLED: 'true',
};

export const getWorkplaceTypeName = (workplaceType: WorkplaceType): string | null =>
    isWorkplaceTypeVisible(workplaceType) ? `(${workplaceType.name})` : null;

export const isWorkplaceTypeVisible = (workplaceType: WorkplaceType): boolean => {
    return (
        (workplaceType?.code === WORKPLACE_TYPES.ONSITE &&
            getWorkplaceTypeDisplaySetting('WORKPLACE_TYPE_ONSITE_ENABLED')) ||
        (workplaceType?.code === WORKPLACE_TYPES.REMOTE &&
            getWorkplaceTypeDisplaySetting('WORKPLACE_TYPE_REMOTE_ENABLED')) ||
        (workplaceType?.code === WORKPLACE_TYPES.HYBRID &&
            getWorkplaceTypeDisplaySetting('WORKPLACE_TYPE_HYBRID_ENABLED'))
    );
};

export const getWorkplaceTypeDisplaySetting = (settingKey: WorkplaceTypeKeys): boolean => {
    if (isAdminRoute()) {
        const site = store.site as WorkplaceTypeSiteShape | null;

        return Boolean(site?.workplaceType[workplaceTypeSettingsMap[settingKey]]());
    }

    const settingValue = appConfig.getSettingByKey(settingKey) ?? DEFAULT_VALUES[settingKey];

    return mapTrueFalseStringToBoolean(settingValue);
};
