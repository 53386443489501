import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';

export const formatDateRange = (startDate: string, endDate: string): string => {
    const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);
    const startDateText = dateFormatter.formatDate(startDate);
    const endDateText = dateFormatter.formatDate(endDate);

    if (startDateText === endDateText) {
        return startDateText;
    }

    return [startDateText, endDateText].filter(Boolean).join(' - ');
};
