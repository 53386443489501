import storageService from 'core/storage/sessionStorage';

const WITHDRAW_APPLICATIONS = 'withdrawnApplications';

export const storeWithdrawnApplication = (jobId: string): void => {
    const previousWithdrawnApplications = storageService.exists(WITHDRAW_APPLICATIONS)
        ? storageService.restore(WITHDRAW_APPLICATIONS)
        : [];
    const withdrawnApplications = [...previousWithdrawnApplications, jobId];

    storageService.store(WITHDRAW_APPLICATIONS, withdrawnApplications);
};

export const removeWithdrawnApplication = (appliedJobId: string): void => {
    if (!storageService.exists(WITHDRAW_APPLICATIONS)) {
        return;
    }

    const previousWithdrawApplications = storageService.restore(WITHDRAW_APPLICATIONS);

    const filteredWithdrawApplications = previousWithdrawApplications.filter(
        (jobId: string) => jobId != appliedJobId
    );

    if (filteredWithdrawApplications.length) {
        storageService.store(WITHDRAW_APPLICATIONS, filteredWithdrawApplications);
    } else {
        storageService.remove(WITHDRAW_APPLICATIONS);
    }
};

export const isApplicationStored = (jobId: string): boolean => {
    const previousWithdrawApplications = storageService.restore(WITHDRAW_APPLICATIONS);

    return previousWithdrawApplications ? previousWithdrawApplications.includes(jobId) : false;
};
