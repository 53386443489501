import { components } from 'knockout';
import metadataConfig from 'cx/module/apply-flow/config/metadata';

import ViewModel from 'apply-flow/module/candidate-links/CandidateLinksViewModel';
import template from '../candidate-links.html';

components.register('candidate-links', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_LINKS', {
    component: 'candidate-links',
});
