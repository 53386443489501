import {
    WidgetType,
    AVAILABLE_WIDGET_TYPES,
    WIDGET_EDITOR_ROUTES,
    WIDGET_TYPE_NAMES,
    WIDGET_TYPE_CLASS_NAMES,
    WIDGET_CLASS_MODIFIERS,
} from './widgetTypes';

export type PageType = 'PAGE' | 'SPLASH_PAGE' | 'SEARCH_PAGE' | 'JOB_DETAILS' | WidgetType;

export enum WIDGET_PAGE_TYPE {
    WIDGET,
    PAGE,
}

export type TemplateNumber = 'mohegan' | 'minimal';

export const CUSTOM_PAGE = 'PAGE';
export const SPLASH_PAGE = 'SPLASH_PAGE';
export const SEARCH_PAGE = 'SEARCH_PAGE';
export const JOB_DETAILS = 'JOB_DETAILS';
export const ALL_TYPES = 'ALL';

export const PAGE_TYPES: PageType[] = [SPLASH_PAGE, SEARCH_PAGE, JOB_DETAILS, CUSTOM_PAGE];

export const AVAILABLE_PAGES_TYPES: Record<TemplateNumber, PageType[]> = {
    mohegan: [SPLASH_PAGE, CUSTOM_PAGE],
    minimal: [SPLASH_PAGE, SEARCH_PAGE, JOB_DETAILS, CUSTOM_PAGE],
};

export const AVAILABLE_TYPES: Record<TemplateNumber, (PageType | WidgetType)[]> = {
    mohegan: [...AVAILABLE_PAGES_TYPES.mohegan, ...AVAILABLE_WIDGET_TYPES.mohegan],
    minimal: [...AVAILABLE_PAGES_TYPES.minimal, ...AVAILABLE_WIDGET_TYPES.minimal],
};

export const EDITOR_ROUTES: Record<PageType | WidgetType, string> = {
    [CUSTOM_PAGE]: 'site-editor-content',
    [SPLASH_PAGE]: 'site-editor-splash-editor',
    [SEARCH_PAGE]: 'site-editor-search-editor',
    [JOB_DETAILS]: 'site-editor-job-details-editor',
    ...WIDGET_EDITOR_ROUTES,
};

export const TYPE_NAMES: Record<PageType | WidgetType | 'ALL', string> = {
    [CUSTOM_PAGE]: 'admin.site-editor.pages.page-types.custom',
    [SPLASH_PAGE]: 'admin.site-editor.pages.page-types.splash',
    [SEARCH_PAGE]: 'admin.site-editor.pages.page-types.search',
    [JOB_DETAILS]: 'admin.site-editor.pages.page-types.job-details',
    ...WIDGET_TYPE_NAMES,
    [ALL_TYPES]: 'admin.site-editor.pages.page-types.all-pages',
};

export const TYPE_CLASS_NAMES: Record<PageType | WidgetType | 'ALL', string> = {
    [CUSTOM_PAGE]: 'custom',
    [SPLASH_PAGE]: 'splash',
    [SEARCH_PAGE]: 'search',
    [JOB_DETAILS]: 'job-details',
    ...WIDGET_TYPE_CLASS_NAMES,
    [ALL_TYPES]: 'all-types',
};

export const CLASS_MODIFIERS: Record<PageType | WidgetType, string> = {
    [CUSTOM_PAGE]: 'custom-page',
    [SPLASH_PAGE]: 'splash-page',
    [JOB_DETAILS]: 'job-details',
    [SEARCH_PAGE]: 'search-page',
    ...WIDGET_CLASS_MODIFIERS,
};
