import httpConfig from 'core/http/config';
import tokenService from 'candidate-verification/service/token';
import { unauthorizedHandler } from 'candidate-verification/service/user-session/unauthorized';
import cookieStorage from 'core/cookie/storage';
import { mapTrueFalseStringToBoolean } from 'core/utils/stringToBoolean';
import appConfig from 'app/model/config';
import { getRestActionHeaderValue, REST_ACTION_HEADER } from 'app/service/getRestActionHeaderValue';

const COOKIE_USER_TRACKING = 'ORA_CX_USERID';
const VANITY_ADD_TIMESTAMP_HEADER = 'ORA_IRC_CE_VTURL_ADD_TIMESTAMP_HEADER';

function addAuthorizationHeader(request) {
    const accessCode = tokenService.get()?.accessCode;

    if (accessCode) {
        request.headers['Ora-Irc-Access-Code'] = accessCode;
    }

    return request;
}

function addUserTrackingHeader(request) {
    const userId = cookieStorage.get(COOKIE_USER_TRACKING);

    if (userId) {
        request.headers['Ora-Irc-Cx-UserId'] = userId;
    }

    return request;
}

function addTimestampHeader(request) {
    const injectTimestampHeader = mapTrueFalseStringToBoolean(appConfig.getSettingByKey(VANITY_ADD_TIMESTAMP_HEADER));
    const isVanityUrlUsed = window.VanityUrlEnabled && appConfig.vanityBaseUrl;

    if (!isVanityUrlUsed) {
        return request;
    }

    if (!injectTimestampHeader) {
        return request;
    }

    request.headers['Ora-Irc-Cx-Timestamp'] = Date.now();

    return request;
}

function addRestActionHeader(request) {
    const restActionValue = getRestActionHeaderValue(request);

    if (restActionValue) {
        request.headers[REST_ACTION_HEADER] = restActionValue;
    }

    return request;
}

export function extendHttpConfig() {
    httpConfig.addAuthorizationHeader = addAuthorizationHeader;

    httpConfig.addUserTrackingHeader = addUserTrackingHeader;

    httpConfig.addTimestampHeader = addTimestampHeader;

    httpConfig.setStatusCodeHandler(401, unauthorizedHandler);

    httpConfig.addRestActionHeader = addRestActionHeader;
}