import { RESUME } from '../../apply-flow/module/file-upload/config/attachmentCategories';

export default {
    mapResumeFromRest(restResumeCollection) {
        const restResume = restResumeCollection.items[0] || {};

        return {
            sourceAttachmentId: restResume.attachmentId,
            fileName: restResume.attachmentName,
            mimeType: restResume.mimeType,
            categoryId: RESUME,
            fileContents: restResume.attachmentContents,
            isReferral: true,
        };
    },

    mapUpdateHitCount(response) {
        const restResponse = response;

        if (restResponse.referralId) {
            restResponse.referralId = `${restResponse.referralId}`;
        }

        return restResponse;
    },
};