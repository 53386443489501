import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value({ pinCode, keepSignedIn }) {
            const form = formBuilder.createForm();
            const elements = [this._createPinFormElement(pinCode)];

            if (keepSignedIn) {
                elements.push(this._createKeepSignedInElement(keepSignedIn));
            }

            form.elements(elements);

            return form;
        },
    },

    _createPinFormElement: {
        value(model) {
            const label = i18n('apply-flow.candidate-verification.verification-code');

            const formElement = formElementFactory.create('pin', 'pin-code');

            formElement.addValidator('required', { label });
            formElement.label(label);
            formElement.registerModel(model);

            return formElement;
        },
    },

    _createKeepSignedInElement: {
        value(model) {
            const label = i18n('apply-flow.candidate-verification.keep-me-signed-in');
            const formElement = formElementFactory.create('checkbox', 'keep-signed-in');

            formElement.label(label);
            formElement.helpText(i18n('apply-flow.candidate-verification.keep-me-signed-in-description'));

            formElement.attributes({
                cssClass: 'pin-code-form__option',
            });

            formElement.registerModel(model);

            return formElement;
        },
    },
});

