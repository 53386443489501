import i18n from 'core/i18n/i18n';
import Component from '../model/Component';
import ContentComponent from '../model/ContentComponent';
import SectionComponent from '../model/SectionComponent';
import RowComponent from '../model/RowComponent';
import ColumnComponent from '../model/ColumnComponent';
import { DEFAULT_PARAMS_MAP } from 'cx/module/custom-content/service/defaultParamsFactory';
import { notReachable } from 'app/types/utils';
import {
    CreateComponentData,
    FactoryComponentKey,
} from 'site-editor/module/content-editor/config/components';
import { createComponent } from 'cx/module/custom-content/service/createComponent';

type ContentComponentKey = 'cc-headline' | 'cc-paragraph' | 'cc-button' | 'cc-custom-html';

const defaultContent: Record<ContentComponentKey, string> = {
    'cc-headline': '<h1></h1>',
    'cc-paragraph': '<p></p>',
    'cc-button': i18n('admin.site-editor.content-editor.sample-button'),
    'cc-custom-html': '<div>Custom HTML</div>',
};

const create = <Key extends FactoryComponentKey>(
    componentData: CreateComponentData<Key>
): SectionComponent | RowComponent | ColumnComponent | ContentComponent | Component => {
    return createComponent(componentData, (key, data) => {
        switch (key) {
            case 'cc-headline':
            case 'cc-paragraph':
            case 'cc-button':
            case 'cc-custom-html':
                return new ContentComponent({
                    ...data,
                    content: data.content || defaultContent[key as ContentComponentKey],
                });

            case 'cc-rule':
            case 'cc-image':
            case 'cc-video':
            case 'cc-space':
            case 'cc-job-list':
            case 'cc-recommended-jobs-widget':
            case 'cc-category-tiles':
            case 'cc-talent-community-button':
            case 'cc-cookie-consent-modal-title':
            case 'cc-cookie-consent-modal-policy':
            case 'cc-cookie-consent-modal-message':
            case 'cc-cookie-consent-modal-actions':
            case 'cc-cookie-consent-modal-categories':
            case 'cc-linkedin-connections-widget':
            case 'cc-cookie-preference-link':
            case 'cc-search-bar':
            case 'cc-search-filters-panel':
            case 'cc-search-results':
            case 'cc-minimal-search-box-compact':
            case 'cc-minimal-search-categories':
            case 'cc-minimal-scroll-indicator':
            case 'cc-splash-mohegan-view-all-jobs':
            case 'cc-job-details-title':
            case 'cc-job-details-location':
            case 'cc-job-details-description':
            case 'cc-job-details-organization-description':
            case 'cc-job-details-responsibilities':
            case 'cc-job-details-qualifications':
            case 'cc-job-details-corporate-description':
            case 'cc-job-details-apply-button':
            case 'cc-job-details-skills':
            case 'cc-job-details-media':
            case 'cc-job-details-info':
            case 'cc-job-details-tags':
            case 'cc-job-details-similar-jobs':
            case 'cc-job-details-job-fit':
                return new Component(data);

            case 'cc-section':
                return new SectionComponent(data);

            case 'cc-row':
                return new RowComponent(data);

            case 'cc-column':
                return new ColumnComponent(data);

            default:
                return notReachable(key);
        }
    });
};

export const createEmptySection = (): SectionComponent => {
    return new SectionComponent({
        rows: [createEmptyRow()],
        params: DEFAULT_PARAMS_MAP['cc-section'],
    });
};

export const createEmptyRow = (): RowComponent => {
    return new RowComponent({
        order: 0,
        columns: [createEmptyColumn()],
        params: DEFAULT_PARAMS_MAP['cc-row'],
    });
};

export const createEmptyColumn = (): ColumnComponent => {
    return new ColumnComponent({
        params: DEFAULT_PARAMS_MAP['cc-column'],
    });
};

export default {
    create,
    createEmptySection,
    createEmptyRow,
    createEmptyColumn,
};
