import { components } from 'knockout';

import ViewModel from './TalentCommunityTileViewModel';
import template from './talent-community-tile.html';
import TalentCommunityTileStaticViewModel from './TalentCommunityTileStaticViewModel';

components.register('talent-community-tile', {
    viewModel: ViewModel,
    template,
});

components.register('talent-community-tile-static', {
    viewModel: TalentCommunityTileStaticViewModel,
    template,
});
