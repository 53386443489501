import httpLangCached from 'cx/service/http/httpLangCached';
import router from 'app/model/router';
import metadataMapper from '../mapper/metadata';
import flowBlockMapper from '../../../mapper/flowBlock';
import { ORA_QUESTIONNAIRE } from '../enum/questionnaireBlocks';
import urlEncoder from 'core/router/urlEncoder';

export default class Metadata {

    CONTEXT_JOB_APPLY = 'JOB_APPLY_FLOW';

    CONTEXT_EVENT_REGISTER = 'EVENT_REGISTER_FLOW';

    constructor(serviceUrl) {
        this._serviceUrl = serviceUrl;
        this._context = this._serviceUrl.includes('<REQ_NUM>') ? this.CONTEXT_JOB_APPLY : this.CONTEXT_EVENT_REGISTER;
    }

    getFormElements(params) {
        const url = this._prepareServiceUrl(params);

        return httpLangCached.get(url, {
            statusCodes: {
                404() {
                    return Promise.resolve({
                        items: [],
                    });
                },
            },
        }).then(metadataMapper.mapFormElementsFromRest.bind(metadataMapper));
    }

    _prepareServiceUrl({ questionnaireId, questionnaireVersionNumber }) {
        const { jobId, eventId } = router.routeParams();
        const isJobApply = this._context === this.CONTEXT_JOB_APPLY;

        const nodeIdKey = isJobApply ? 'REQ_NUM' : 'EVENT_NUM';
        const nodeIdValue = isJobApply ? jobId : eventId;

        return this._serviceUrl
            .replace(`<${nodeIdKey}>`, `%22${urlEncoder.encodeQueryParams(nodeIdValue)}%22`)
            .replace('<QUEST_ID>', questionnaireId)
            .replace('<QUEST_VER_NUM>', questionnaireVersionNumber);
    }

    isEmpty(restBlock) {
        const { questionnaireId, questionnaireVersionNumber } = flowBlockMapper.getBlockProperties(restBlock);

        const urlParams = {
            questionnaireId,
            questionnaireVersionNumber,
        };

        const isStaticQuestionnaire = restBlock.blockCode === ORA_QUESTIONNAIRE.blockCode;

        if ((!questionnaireId || !questionnaireVersionNumber) && isStaticQuestionnaire) {
            return Promise.resolve(true);
        }

        return this.getFormElements(urlParams).then(formElements => !formElements.length);
    }

}