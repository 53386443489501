import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(declineReasons) {
            const form = formBuilder.createForm();

            const formElements = [
                this._createReasonFormElement(declineReasons),
                this._createCommentFormElement(),
            ];

            form.elements(formElements);

            return form;
        },
    },

    _createCommentFormElement: {
        value() {
            return formElementFactory.create('textarea', 'comment')
                .label(i18n('offer.offer-refusal.comment-label'))
                .attributes('autocomplete', 'off');
        },
    },

    _createReasonFormElement: {
        value(declineReasons) {
            return formElementFactory.create('select', 'reasonId')
                .label(i18n('offer.offer-refusal.reason-label'))
                .withEmptyOption(true)
                .optionsKeys({
                    value: 'reasonId',
                    label: 'reasonName',
                })
                .options(declineReasons)
                .isHidden(!(declineReasons && declineReasons.length));
        },
    },
});