export default {
    http: {
        baseUrl: 'https://london54568.lhr.fusionapps02lhr.oraclevcn.com:3001',
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        suppressErrors: true,
        transformRequest(request) {
            request.body = JSON.stringify(request.body);

            return request;
        },
    },

    allowedHosts: {
        'https://fuscdrmsmc82-fa-ext.us.oracle.com': 'DIT A',
        'https://fuscdrmsmc150-fa-ext.us.oracle.com': 'DIT B',
        'https://fuscdrmsmc237-fa-ext.us.oracle.com': 'DIT C',
        'https://fuscdrmsmc167-fa-ext.us.oracle.com': 'BRONZE',
        'https://fuscdrmsmc258-fa-ext.us.oracle.com': 'BRONZE-AUTO',
        'https://fuscdrmsmc245-fa-ext.us.oracle.com': 'SILVER',
        'https://fuscdrmsmc262-fa-ext.us.oracle.com': 'SILVER-AUTO',
    },
};
