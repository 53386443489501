import InterviewScheduleDetail from '../model/InterviewScheduleDetail';
import interviewMapper from '../mapper/interviewDetail';
import i18n from 'core/i18n/i18n';

function _isOnWeek(date, weekOffset, firstDayOfWeekIndex = 0) {
    const startDate = new Date(date);
    const currentDateTime = new Date();
    const currentDate = new Date(currentDateTime.getFullYear(), currentDateTime.getMonth(), currentDateTime.getDate());
    const currentDateWithOffset = new Date(new Date(currentDate).setDate(currentDate.getDate() + (weekOffset * 7)));
    const day = currentDateWithOffset.getDay();
    let firstDayOfWeekDelta;

    if (firstDayOfWeekIndex === 0) {
        // Sunday as first day
        firstDayOfWeekDelta = currentDateWithOffset.getDate() - day;
    } else {
        // Monday as first day
        firstDayOfWeekDelta = (currentDateWithOffset.getDate() - currentDateWithOffset.getDay()) +
            (currentDateWithOffset.getDay() === 0 ? -6 : 1);
    }

    const firstDayOfWeek = new Date(new Date(currentDateWithOffset).setDate(firstDayOfWeekDelta));
    const firstDayOfNextWeek = new Date(new Date(firstDayOfWeek).setDate(firstDayOfWeek.getDate() + 7));

    return (startDate >= firstDayOfWeek && startDate < firstDayOfNextWeek);
}

function _splitInterviewDays(interviewDays) {
    const splittedInterviewDays = interviewDays.reduce((acc, interviewDay) => {
        const dateOnly = interviewDay.startTime().substring(0, 10);

        if (!acc[dateOnly]) {
            acc[dateOnly] = { date: dateOnly, interviews: [] };
        }

        acc[dateOnly].interviews.push(interviewDay);

        return acc;
    }, {});

    return Object.keys(splittedInterviewDays)
        .map(key => splittedInterviewDays[key]);
}

function _splitInterviewsByGroup(interviews, autoCalendarSlot, scheduleParticipantsProgrammatic, action) {
    const splittedInterviews = [];

    if (interviews && interviews.length > 0) {
        const interviewForGroup1 = {
            group: 1,
            groupTitle: i18n('interview-scheduling.self-schedule.groups.group-1'),
            interviewDays: [],
        };

        const interviewForGroup2 = {
            group: 2,
            groupTitle: i18n('interview-scheduling.self-schedule.groups.group-2'),
            interviewDays: [],
        };

        const interviewForGroup3 = {
            group: 3,
            groupTitle: i18n('interview-scheduling.self-schedule.groups.group-3'),
            interviewDays: [],
        };

        interviews.forEach((interview) => {
            const interviewModel = interviewMapper.mapFromRest(interview, autoCalendarSlot,
                scheduleParticipantsProgrammatic, action);

            const startDate = interviewModel.startTime();

            if (_isOnWeek(startDate, 0)) {
                interviewForGroup1.interviewDays.push(interviewModel);
            } else if (_isOnWeek(startDate, 1)) {
                interviewForGroup2.interviewDays.push(interviewModel);
            } else {
                interviewForGroup3.interviewDays.push(interviewModel);
            }
        });

        interviewForGroup1.interviewDays = _splitInterviewDays(interviewForGroup1.interviewDays);
        interviewForGroup2.interviewDays = _splitInterviewDays(interviewForGroup2.interviewDays);
        interviewForGroup3.interviewDays = _splitInterviewDays(interviewForGroup3.interviewDays);
        splittedInterviews.push(interviewForGroup1);
        splittedInterviews.push(interviewForGroup2);
        splittedInterviews.push(interviewForGroup3);
    }

    return splittedInterviews;
}

export default {
    mapFromRest({ items }, action) {
        const interviewSchedule = items[0] || {};

        return new InterviewScheduleDetail({
            scheduleId: interviewSchedule.scheduleId,
            autoCalendarSlot: interviewSchedule.autoCalendarSlot,
            interviewGroups: _splitInterviewsByGroup(interviewSchedule.Interviews,
                interviewSchedule.autoCalendarSlot, interviewSchedule.ScheduleParticipantsProgrammatic, action),
        });
    },
};