import { Observable, observable } from 'knockout';
import { JobDetailsModalViewModel } from './JobDetailsModalViewModel';
import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { noOperation } from 'core/utils/noOperation';
import { FAKE_JOB_DETAILS_DATA } from './config/fakePageData';

export class JobDetailsFakeViewModel extends JobDetailsModalViewModel {
    pageData: Observable<JobDetailsPageData>;

    constructor() {
        super();

        this.pageData = observable(FAKE_JOB_DETAILS_DATA);

        this.viewData = {
            ...this.viewData,
            apply: noOperation,
        };
    }
}
