import { components } from 'knockout';

import '../job-details-header';
import '../job-details-header-content';
import '../job-details-content';

import { JobDetailsPageViewModel } from './JobDetailsPageViewModel';
import template from './job-details-page.html';

components.register('job-details-page', {
    viewModel: JobDetailsPageViewModel,
    template,
});
