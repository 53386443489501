import storageService from 'core/storage/sessionStorage';
import { SourceTraceDataEventContext } from 'app/module/minimal/module/event-register-flow/config/types';

const SOURCE_TRACE_STORAGE = 'source-tracking-claim-email';

export const saveSourceTrace = (sourceTrace: SourceTraceDataEventContext): void => {
    storageService.store(SOURCE_TRACE_STORAGE, sourceTrace);
};

export const removeSourceTrace = (): void => {
    storageService.remove(SOURCE_TRACE_STORAGE);
};

export const isSourceTraceExists = (): boolean => {
    return storageService.exists(SOURCE_TRACE_STORAGE);
};

export const getSourceTrace = (): SourceTraceDataEventContext => {
    return storageService.restore(SOURCE_TRACE_STORAGE);
};
