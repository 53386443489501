import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class BetweenFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.between';

        ko.utils.extend(this._options, {
            max_value: this._options.max,
            min_value: this._options.min,
        });
    }

    _validate(value, options) {
        if (value) {
            if (options.inclusive) {
                return options.min <= value && value <= options.max;
            }

            return options.min < value && value < options.max;
        }

        return true;
    }

}