import storageService from 'core/storage/sessionStorage';

const EMAIL_STORAGE_KEY = 'loginEmail';

export const storeLoginEmail = (email: string): void => {
    storageService.store(EMAIL_STORAGE_KEY, email);
};

export const getLoginEmail = (): string => {
    const loginEmail = storageService.restore(EMAIL_STORAGE_KEY);

    if (loginEmail) {
        storageService.remove(EMAIL_STORAGE_KEY);
    }

    return loginEmail;
};
