import { obfuscate as obfuscatePrimitive } from './primitivesObfuscator';

function obfuscateDateOfBirth(candidate) {
    if (!('dateOfBirth' in candidate)) {
        return;
    }

    candidate.dateOfBirth = obfuscatePrimitive(candidate.dateOfBirth);
}

function obfuscatePII(candidate) {
    if (!('personalIdentifyingInformationItems' in candidate)) {
        return;
    }

    const piiItems = candidate.personalIdentifyingInformationItems;

    piiItems.forEach((item) => {
        item.nationalId = obfuscatePrimitive(item.nationalId);
    });
}

export function obfuscate(candidate) {
    obfuscateDateOfBirth(candidate);
    obfuscatePII(candidate);

    return candidate;
}
