import {
    ORA_WORK_PREFERENCE,
    ORA_LANGUAGE,
    ORA_LICENSE_CERTIFICATES,
    ORA_EDUCATION,
    ORA_EXPERIENCE,
    ORA_SKILL,
    ORA_TIMELINE,
} from '../../enum/blockTypes';

export class MultiBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        const operation = this.removeMultiBlockData;

        this.blocks = new Map([
            [ORA_WORK_PREFERENCE, { operation, field: 'workPreferences' }],
            [ORA_LANGUAGE, { operation, field: 'languages' }],
            [ORA_LICENSE_CERTIFICATES, { operation, field: 'licensesAndCertificates' }],
            [ORA_EDUCATION, { operation: this.removeMultiBlockData, field: 'education' }],
            [ORA_EXPERIENCE, { operation, field: 'experience' }],
            [ORA_SKILL, { operation, field: 'skills' }],
        ]);

        return this.blocks;
    }

    getBlockIdentifier(block) {
        const { blockProperties } = block;

        if (blockProperties && blockProperties.sectionId) {
            return [blockProperties.sectionId];
        }

        console.error(`missing properties for block ${block.code}`);

        return null;
    }

    assignIdentifiersFromTimeline(blocks) {
        if (!blocks.has(ORA_TIMELINE)) {
            return;
        }

        this._assignIdentifier(blocks, ORA_EXPERIENCE, this._getTimelineExperience);
        this._assignIdentifier(blocks, ORA_EDUCATION, this._getTimelineEducation);

        blocks.delete(ORA_TIMELINE);
    }

    _assignIdentifier(blocks, blockCode, getFunction) {
        if (!blocks.has(blockCode)) {
            blocks.set(blockCode, [getFunction(blocks)]);
        } else {
            const blockSections = blocks.get(blockCode);

            blockSections.push(getFunction(blocks));
            blocks.set(blockCode, blockSections);
        }
    }

    _getTimelineEducation(blocks) {
        const [[, education]] = blocks.get(ORA_TIMELINE);

        return education;
    }

    _getTimelineExperience(blocks) {
        const [[experience]] = blocks.get(ORA_TIMELINE);

        return experience;
    }

    removeMultiBlockData(candidate, sections, field) {
        candidate[field] = candidate[field].filter(({ sectionId }) => sections.includes(sectionId));
    }

}