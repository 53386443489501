import appConfig from 'app/model/config';
import { combinePhone } from 'candidate-verification/service/phoneConverter';

const MAKE_EMAIL_PREFERRED_ACTION = 'MAKE_EMAIL_PREFERRED';
const MAKE_PHONE_PREFERRED_ACTION = 'MAKE_PHONE_PREFERRED';

export const REQUEST_PHONE_VERIFICATION = 'REQUEST_PHONE_VERIFICATION';
export const REQUEST_EMAIL_VERIFICATION = 'REQUEST_EMAIL_VERIFICATION';

const REQUEST_EMAIL_REMOVAL = 'REQUEST_EMAIL_REMOVAL';
const REQUEST_PHONE_REMOVAL = 'REQUEST_PHONE_REMOVAL';

const MAKE_CHANNEL_PREFERRED_ACTION_NEW_CANDIDATE = 'JOB_APPLY_CHANGE_ID';

function getFlag(value) {
    if (value) {
        return 'Y';
    }

    return 'N';
}

export default {
    mapEmailPreferredToRest(candidateNumber, emailPreferred) {
        return {
            candidateNumber,
            action: MAKE_EMAIL_PREFERRED_ACTION,
            emailPreferredFlag: getFlag(emailPreferred),
        };
    },

    mapPhonePreferredToRest(candidateNumber, phonePreferred) {
        return {
            candidateNumber,
            action: MAKE_PHONE_PREFERRED_ACTION,
            phonePreferredFlag: getFlag(phonePreferred),
        };
    },

    mapPhoneVerificationToRest(candidateNumber, phone) {
        const { mobilePhoneNumber, mobilePhoneCountryCode, mobilePhoneAreaCode, mobilePhoneLegislationCode } = phone;

        return {
            candidateNumber,
            preferredLanguage: appConfig.siteLanguage,
            action: REQUEST_PHONE_VERIFICATION,
            mobilePhoneNumber,
            mobilePhoneCountryCode,
            mobilePhoneLegislationCode,
            mobilePhoneAreaCode,
            siteNumber: appConfig.siteNumber,
        };
    },

    mapEmailVerificationToRest(candidateNumber, email) {
        return {
            candidateNumber,
            preferredLanguage: appConfig.siteLanguage,
            action: REQUEST_EMAIL_VERIFICATION,
            email,
            siteNumber: appConfig.siteNumber,
        };
    },

    mapEmailRemovalToRest(candidateNumber) {
        return {
            candidateNumber,
            action: REQUEST_EMAIL_REMOVAL,
            siteNumber: appConfig.siteNumber,
        };
    },

    mapPhoneRemovalToRest(candidateNumber) {
        return {
            candidateNumber,
            action: REQUEST_PHONE_REMOVAL,
            siteNumber: appConfig.siteNumber,
        };
    },

    mapNewCandidateEmailPreferredToRest(accessCode, email) {
        return {
            Email: email,
            Mode: MAKE_CHANNEL_PREFERRED_ACTION_NEW_CANDIDATE,
            AccessCode: accessCode,
        };
    },

    mapNewCandidatePhonePreferredToRest(accessCode, phone) {
        return {
            Phone: combinePhone(phone),
            Mode: MAKE_CHANNEL_PREFERRED_ACTION_NEW_CANDIDATE,
            AccessCode: accessCode,
        };
    },
};