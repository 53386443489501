import postMessageService from 'core/post-message/postMessageService';
import router from 'app/model/router';

const parentWindow = window.parent;

if (parentWindow) {
    postMessageService.dispatch('preview-ready', null, parentWindow);
}

postMessageService.add('change-preview-route', (routeConfig) => {
    router.go(routeConfig.name, routeConfig.params || {});
});