import l10n from 'core/l10n/l10n';
import { DATE_LONG_FORMAT, DATE_TIME_FORMAT } from './dateTimeFormats';
import { normalize as normalizeStringDate } from './stringDateNormalizer';

function _isLocaleDateStringSupported() {
    try {
        new Date().toLocaleDateString('i');
    } catch (e) {
        return e.name === 'RangeError';
    }

    return false;
}

export default class LocaleBasedDateTimeFormatter {

    constructor(lang) {
        this.language = navigator.language && navigator.language.indexOf(lang) === 0
        && navigator.language.length > lang.length
            ? navigator.language
            : lang;

        this.isLocaleDateStringSupported = _isLocaleDateStringSupported();
    }

    formatDate(rawDate, format) {
        if (!rawDate) {
            return undefined;
        }

        const date = normalizeStringDate(rawDate);
        const dateFormat = format || DATE_LONG_FORMAT;

        if (this.isLocaleDateStringSupported) {
            const localeDate = (new Date(date)).toLocaleDateString(this.language, dateFormat);

            return `${localeDate}`;
        }

        return l10n.date.format(date);
    }

    formatDateTime(date, format) {
        if (!date) {
            return undefined;
        }

        if (this.isLocaleDateStringSupported) {
            const dateTimeFormat = format || DATE_TIME_FORMAT;
            const newDate = new Date(date);

            const localeDateString = newDate.toLocaleString(this.language,
                dateTimeFormat);

            return `${localeDateString}`;
        }

        return l10n.dateTime.format(date);
    }

    getDatePickerFormat(defaultDateFormat) {
        if (this.isLocaleDateStringSupported) {
            const sample = (new Date(1970, 11, 31, 1, 0, 0)).toLocaleDateString(this.language);
            const monthIndex = sample.indexOf(12);
            const dayIndex = sample.indexOf(31);
            const yearIndex = sample.indexOf(1970);

            if (monthIndex >= 0 && dayIndex >= 0 && yearIndex >= 0) {
                const monthPosition = (monthIndex > yearIndex) + (monthIndex > dayIndex);
                const dayPosition = (dayIndex > yearIndex) + (dayIndex > monthIndex);
                const yearPosition = (yearIndex > monthIndex) + (yearIndex > dayIndex);
                const format = [];

                format[monthPosition] = 'mm';
                format[dayPosition] = 'dd';
                format[yearPosition] = 'yy';

                return format.join('-');
            }
        }

        return defaultDateFormat;
    }

    getMonthPickerFormat(defaultDateFormat) {
        if (this.isLocaleDateStringSupported) {
            const sample = (new Date(1970, 11, 31, 1, 0, 0)).toLocaleDateString(this.language);

            if (sample.indexOf('12') >= 0 && sample.indexOf('1970') >= 0) {
                return sample.indexOf('12') > sample.indexOf('1970') ? 'yy-mm' : 'mm-yy';
            }
        }

        return defaultDateFormat;
    }

    getDateMonthPickerFormat(defaultDateFormat) {
        if (this.isLocaleDateStringSupported) {
            const sample = (new Date(1970, 11, 31, 1, 0, 0)).toLocaleDateString(this.language);

            if (sample.indexOf('12') >= 0 && sample.indexOf('31') >= 0) {
                return sample.indexOf('12') > sample.indexOf('31') ? 'dd-mm' : 'mm-dd';
            }
        }

        return defaultDateFormat;
    }

}