import { components } from 'knockout';

import SearchFacetViewModel from './SearchFacetViewModel';
import SearchPostingDateFacetViewModel from './SearchPostingDateFacetViewModel';
import template from './search-facet.html';

components.register('search-categories-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-organizations-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-locations-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-postingDates-facet', {
    viewModel: SearchPostingDateFacetViewModel,
    template,
});

components.register('search-titles-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-workLocations-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-flexFields-facet', {
    viewModel: SearchFacetViewModel,
    template,
});

components.register('search-workplaceTypes-facet', {
    viewModel: SearchFacetViewModel,
    template,
});
