export const LOCATION_SEARCH_MODE_KEY = 'LOCATION_SEARCH_MODE';
export const ZIPCODE_SEARCH_ENABLED_KEY = 'ZIPCODE_SEARCH_ENABLED';
export const GEOLOCATION_SEARCH_ENABLED_KEY = 'GEOLOCATION_SEARCH_ENABLED';

export const ZIPCODE_SEARCH_ENABLED_DEFAULT_VALUE = true;
export const GEOLOCATION_SEARCH_ENABLED_DEFAULT_VALUE = true;

export const LOCATION_SEARCH_MODE_VALUES = {
    ALL: 'all',
    RECRUITING: 'recruiting',
    DEFAULT: 'all',
};