const CONTROLS_MAP = {
    TextArea: 'textarea',
    RichTextEditor: 'textarea',
    CheckBox: 'checkboxStringValue',
    DropdownList: 'select',
    ComboLOV: 'select',
    PopupLOV: 'select',
    StaticURL: 'url',
    // FIXME: change to 'search' when backend filtering will be available
    InlineSearch: 'select',
    RadioButtonGroup: 'radio',
    Date: 'date',
};

const TYPES_TO_CONTROLS_MAP = {
    Date: {
        search: 'select',
        select: 'select',
        radio: 'radio',
        default: 'date',
    },
    DateTime: {
        search: 'select',
        select: 'select',
        radio: 'radio',
        default: 'datetime',
    },
    Integer: {
        text: 'integer',
        textarea: 'integer',
    },
    BigDecimal: {
        text: 'decimal',
        textarea: 'decimal',
    },
};

export function resolveElementControlType(restControlType, restDataType) {
    const controlType = CONTROLS_MAP[restControlType] || 'text';
    const controlsMap = TYPES_TO_CONTROLS_MAP[restDataType] || {};

    return controlsMap[controlType] || controlsMap.default || controlType;
}