import candidateModel from 'apply-flow/model/candidate';
import { SMS } from 'candidate-verification/config/verificationMethods';
import { pureComputed } from 'knockout';
import tokenService from 'candidate-verification/service/token';
import candidateService from 'apply-flow/service/candidate';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';
import signedCandidate from 'candidate-verification/model/signedCandidate';

export default class UserMenuModel {

    isSignedIn = signedCandidate.isSignedIn;

    #isSignedInSubscription = this.isSignedIn.subscribe(
        this._signInStatusChanged.bind(this));

    constructor() {
        if (this.isSignedIn()) {
            this.fetchData();
        }
    }

    async fetchData() {
        if (!tokenService.isCandidateVerified()) {
            return;
        }

        if (this.header()) {
            return;
        }

        const token = tokenService.get();

        await candidateService.loadForToken(candidateModel, token)
            .catch(() => {
                console.error('Unable to create candidate model.');
            });
    }

    dispose() {
        this.#isSignedInSubscription.dispose();
    }

    get header() {
        const { firstName, lastName } = candidateModel.basicInformation;

        return pureComputed(() =>
            firstName() ?? lastName());
    }

    get title() {
        const { firstName, lastName } = candidateModel.basicInformation;

        return pureComputed(() =>
            [firstName(), lastName()]
                .join(' ')
                .trim());
    }

    get subtitle() {
        const { email, phoneNumber, verificationMethod } = candidateModel.basicInformation;

        return pureComputed(() =>
            (verificationMethod() === SMS
                ? phoneLocaleAwareFactory.createPhone(phoneNumber()).asFormattedString()
                : email()),
        );
    }

    _signInStatusChanged(isSignedIn) {
        if (isSignedIn) {
            this.fetchData();
        }
    }

}
