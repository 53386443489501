import i18n from 'core/i18n/i18n';
import { SortOption } from 'search/config/types';

export const jobsSortOptions: SortOption[] = [
    {
        code: 'RELEVANCY',
        order: 'ASC',
        name: i18n('search.sort-options.relevancy'),
    },
    {
        code: 'POSTING_DATES',
        order: 'DESC',
        name: i18n('job-details.posting-date-label'),
    },
    {
        code: 'DISTANCE',
        order: 'ASC',
        name: i18n('admin.site-editor.content-editor.additional-information-preview-distance'),
    },
];
