import i18n from 'core/i18n/i18n';
import FormTileStrategy from 'apply-flow/module/tile-profile-items/model/FormTileStrategy';

const UNNAMED_SKILL_LABEL = i18n('apply-flow.profile-item.unnamed-skill');

export default class SkillFormTileStrategy extends FormTileStrategy {

    get _sources() {
        return { name: 'skills' };
    }

    buildTitle(valueMap) {
        return valueMap.name || UNNAMED_SKILL_LABEL;
    }

}