import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';
import debouncePromise from 'core/utils/debouncePromise';

export const USE_THROTTLE_THRESHOLD = 5;

export default class DictionaryFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.getOptions = this.getOptions.bind(this);

        this.debouncedGetOptions = debouncePromise(this.element.getOptions.bind(this.element), 500);
        this.a11yLabel = this.element.label();
    }

    async getOptions(context) {
        const value = this.element.value();

        if ('term' in context) {
            return await this.debouncedGetOptions(context.term);
        } else if (value) {
            return await this.element.getOption(value);
        }

        return [];
    }

    contains(value) {
        const options = this.element.options() || [];

        return options.some(({ lookupCode }) => lookupCode === value);
    }

    blurHandler() {
        this.hasFocus(false);

        super.blurHandler();
    }

}