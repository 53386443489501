
const CITY_LEVEL = 'city';

export function hasLocation(location) {
    return Boolean(location && location.locationId && location.label);
}


export function isLocationWithCityLevel(location) {
    return Boolean(location && location.level === CITY_LEVEL);
}

