import ko from 'knockout';
import patternLexer from 'core/router/patternLexer';

export default class Route {

    constructor(id, config, parentRoute) {
        this.id = id;
        this.isAbstract = Boolean(config.abstract);
        this.title = config.title;
        this.bareTitle = config.bareTitle;
        this._view = config.view;
        this.parent = parentRoute;
        this.transition = config.transition;
        this.isDefault = config.isDefault;

        this.url = this._composeUrl(config.url, parentRoute && parentRoute.url);
        this._rules = config.rules || {};
        this._patternLexer = patternLexer;
        this._paramsIds = this._patternLexer.getParamIds(this.url);
        this._pattern = this._patternLexer.compilePattern(this.url);
        this.isNewScreen = !config.isNotNewScreen;

        this._callbacks = {
            canEnter: config.canEnter,
            canExit: config.canExit,
            enter: config.enter,
            exit: config.exit,
        };
    }

    static createEmpty() {
        return new Route('empty', {});
    }

    _composeUrl(routeUrl, parentRouteUrl) {
        let url = routeUrl || '';
        let parentUrl = parentRouteUrl || '';

        if (parentUrl) {
            const match = parentUrl.match(Route.QUERY_PARAM_REGEXP);
            const queryParam = match && match.toString();

            if (queryParam) {
                url += queryParam;
                parentUrl = parentUrl.replace(queryParam, '');
            }
        }

        return parentUrl + url;
    }

    hasParent() {
        return Boolean(this.parent);
    }

    getParent() {
        return this.parent;
    }

    match(url) {
        return this._pattern.test(url) && this._paramsMatchRules(url);
    }

    _paramsMatchRules(url) {
        const params = this.getParamsFromUrl(url);

        return Object.keys(this._rules)
            .filter(paramName => paramName in params)
            .every((paramName) => {
                const paramValue = params[paramName];
                const rule = this._rules[paramName];

                return rule.test(paramValue);
            });
    }

    is(routeId) {
        return this.id === routeId || (this.hasParent() && this.parent.is(routeId));
    }

    getParamsFromUrl(url) {
        const paramValues = this._patternLexer.getParamValues(url, this._pattern);

        return this._paramsIds.reduce((map, paramId, index) => {
            const paramsMap = map;
            const value = paramValues[index];

            if (value) {
                if (paramId.indexOf('?') === 0) {
                    const id = paramId.substr(1);

                    paramsMap[id] = this._getQueryStringMap(value);
                } else {
                    paramsMap[paramId] = value;
                }
            }

            return paramsMap;
        }, {});
    }

    _getQueryStringMap(queryString) {
        const queryChunks = queryString.replace('?', '').split('&');

        return queryChunks.reduce((queryStrMap, queryChunk) => {
            const parts = queryChunk.split('=');
            const [queryKey, queryValue] = parts;

            // patternLexer encodes query string params
            const queryStringMap = queryStrMap;

            queryStringMap[queryKey] = queryValue;

            return queryStringMap;
        }, {});
    }

    view() {
        let viewObject = ko.utils.extend({}, this._view);

        if (this.hasParent()) {
            const parentViewObject = this.getParent().view();

            viewObject = ko.utils.extend(parentViewObject, viewObject);
        }

        return viewObject;
    }

    interpolate(routeParams) {
        return this._patternLexer.interpolate(this.url, routeParams);
    }

    canEnter(...context) {
        return this._getCallback('canEnter', ...context);
    }

    canExit(...context) {
        return this._getCallback('canExit', ...context);
    }

    context() {
        return this._getCallback('context');
    }

    enter(...context) {
        return this._getCallback('enter', ...context);
    }

    exit(...context) {
        return this._getCallback('exit', ...context);
    }

    _getCallback(callbackName, ...callbackData) {
        let promise = Promise.resolve();

        if (this.hasParent()) {
            const parent = this.getParent();
            const parentCallback = parent[callbackName];

            promise = promise.then(parentCallback.bind(parent, ...callbackData));
        }

        const callback = this._callbacks[callbackName];

        if (callback) {
            promise = promise.then(callback.bind(this, ...callbackData));
        }

        return promise;
    }

}

Route.QUERY_PARAM_REGEXP = /:\?[^:]+:/;