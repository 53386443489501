export const filtersData = [
    {
        items: [
            {
                id: '1',
                name: 'United States',
                totalCount: 246,
            },
            {
                id: '999988024903684',
                name: 'PLEASANTON',
                totalCount: 70,
            },
            {
                id: '10035',
                name: 'Canada',
                totalCount: 66,
            },
            {
                id: '11583',
                name: 'REDWOOD CITY',
                totalCount: 53,
            },
            {
                id: '11521',
                name: 'NEW YORK',
                totalCount: 14,
            },
        ],
        name: 'locations',
        selectedIds: ['999988024903684'],
        type: 'LOCATIONS',
    },
    {
        items: [
            {
                id: 'MANAG',
                name: 'Managerial',
                totalCount: 8,
            },
            {
                id: 'ADMIN',
                name: 'Administrative',
                totalCount: 6,
            },
        ],
        name: 'titles',
        selectedIds: ['MANAG'],
        type: 'TITLES',
    },
    {
        items: [
            {
                id: '300100071808026',
                name: '300100071808026',
                totalCount: 4,
            },
            {
                id: '300100148485171',
                name: 'ZFRCE_Management',
                totalCount: 4,
            },
            {
                id: '300100071807999',
                name: '300100071807999',
                totalCount: 3,
            },
        ],
        name: 'categories',
        selectedIds: [],
        type: 'CATEGORIES',
    },
];
