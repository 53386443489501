import { components } from 'knockout';

import { JobDetailsSkillsViewModel } from '../job-details-skills/JobDetailsSkillsViewModel';
import template from './job-details-skills.html';

components.register('job-details-skills', {
    viewModel: JobDetailsSkillsViewModel,
    template,
});

components.register('cc-job-details-skills', {
    viewModel: JobDetailsSkillsViewModel,
    template,
});
