import ko from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';
import dictionaryService from 'apply-flow/service/dictionary';

export default class SelectFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);

        this.withEmptyOption = ko.observable(true);

        this.component('select-form-element');
        this.options = ko.observableArray();

        this.optionsKeys = ko.observable({
            label: 'meaning',
            value: 'lookupCode',
        });

        this.optionsDisabled = ko.observableArray();
        this.valueLabel = ko.pureComputed(this._getValueLabel, this);

        this.dictionary = ko.observable();
        this.dependencyField = ko.observable();
        this.dependencyFieldValue = ko.observable();

        this.ariaDescribedBy = ko.observable('');
        this.isReadonly = ko.observable(false);

        this._disabledSubscription = this.optionsDisabled.subscribe(this._disableOptions.bind(this));
    }

    resolveOptionsDependency(formElements) {
        if (!this.dependencyField()) {
            return;
        }

        const dependencyFormElement = formElements
            .filter(formElement => formElement.name() === this.dependencyField().name)
            .pop();

        if (!dependencyFormElement) {
            return;
        }

        this._dependencyValueSubscription = dependencyFormElement.value.subscribe((value) => {
            this.dependencyFieldValue(value);
            this._fetchOptions(value);
        });
    }

    _disableOptions(optionsDisabled) {
        const value = this.value();

        this.options().forEach((option) => {
            if (option.lookupCode === value) {
                option.disabled = false;
            } else {
                option.disabled = optionsDisabled.indexOf(option.lookupCode) > -1;
            }
        });
    }

    _fetchOptions(newDependencyValue) {
        const url = this.dictionary().replace(this.dependencyField().placeholder, newDependencyValue);

        return dictionaryService.getByUrl(url)
            .then(this.options.bind(this))
            .catch((err) => {
                console.error(err);
                this.options([]);
            });
    }

    filterOption(value) {
        const lookupKey = this.optionsKeys().value;

        const option = this.options()
            .filter(opt => opt[lookupKey] === value)
            .shift();

        return Promise.resolve(option);
    }

    getOption(value) {
        if (this.dependencyField()) {
            return this._fetchOptions(this.dependencyFieldValue())
                .then(() => this.filterOption(value));
        }

        return this.filterOption(value);
    }

    _getValueLabel() {
        const { value: lookupKey, label: labelKey } = this.optionsKeys();
        const [selectedOption = {}] = this.options().filter(option => option[lookupKey] === this.value());

        return selectedOption[labelKey] || '';
    }

    dispose() {
        if (super.dispose) {
            super.dispose();
        }

        if (this._dependencyValueSubscription) {
            this._dependencyValueSubscription.dispose();
        }

        this._disabledSubscription.dispose();
    }

}