import { unwrap } from 'knockout';
import http from 'core/http/http';
import httpCached from 'core/http/httpCached';
import jobAlertsMapper from '../mapper/jobAlerts';
import locationMapper from '../../recruiting-location/mapper/recruitingLocation';
import jobFamilyMapper from '../mapper/jobFamily';
import { JOB_CATEGORY_VALUE, JOB_FUNCTION_VALUE } from '../enums/additionalAttributes';

const VALIDATION_MESSAGE_JOB_CATEGORY = 'talent-community.job-alerts-self-service.validation-error';
const VALIDATION_MESSAGE_JOB_FUNCTION = 'talent-community.job-alerts-self-service.job-function-validation-error';
const VALIDATION_MESSAGE_BOTH = 'talent-community.job-alerts-self-service.both-validation-error';

export default {
    getPreferences(candidateId) {
        return http.get(`/recruitingCECandidateSitePreferences/${candidateId}?expand=:expand:&onlyData=true`, {
            params: {
                expand: [
                    'siteTCPreferences.preferredLocations',
                    'siteTCPreferences.preferredJobFamilies',
                    'siteTCPreferences.preferredJobFunctions',
                ].join(','),
            },
        }).then(jobAlertsMapper.mapPreferencesFromRest.bind(jobAlertsMapper));
    },

    save(job, candidateId, optInJobAlertFlag, marketingFlag, jobFamiliesIds) {
        const preferences = this._preferences(optInJobAlertFlag, marketingFlag);
        const data = jobAlertsMapper.mapCreateToRest(job, candidateId, preferences, jobFamiliesIds);

        return http.post('/recruitingCECandidateSitePreferences', data);
    },

    _preferences(optInJobAlertFlag, { optIn }) {
        return {
            jobAlertFlag: optInJobAlertFlag(),
            marketingEmailFlag: unwrap(optIn),
        };
    },

    saveJobAlert(candidateId, jobAlert) {
        return http.post(
            '/recruitingCECandidateSitePreferences',
            jobAlertsMapper.mapSaveJobAlertToRest(candidateId, jobAlert),
        );
    },

    saveCSSOptInEmailAlert(preferences) {
        return http.post(
            '/recruitingCECandidateSitePreferences',
            jobAlertsMapper.mapSaveCSSOptInEmailAlertToRest(preferences),
        );
    },

    saveTCJobAlert(jobAlert, candidateId) {
        return http.post(
            '/recruitingCECandidateSitePreferences',
            jobAlertsMapper.mapSaveTCJobAlertToRest(jobAlert, candidateId),
        );
    },

    confirmTalentCommunity(sitePreferences) {
        return http.post('/recruitingCECandidateSitePreferences', jobAlertsMapper.mapAcceptToRest(sitePreferences));
    },

    getLocations(site) {
        return http
            .get(`/recruitingHierarchyLocations?onlyData=true&finder=findBySiteNumber;SiteNumber=${site}&limit=1000000`)
            .then(locationMapper.mapLocations.bind(locationMapper));
    },

    getLocationsByIds(geographyIds) {
        if (!geographyIds.length) {
            return Promise.reject();
        }

        return http
            .get(`/recruitingHierarchyLocations?onlyData=true&finder=findByGeographyIds;Ids="${geographyIds.join(',')}"&limit=${geographyIds.length}`)
            .then(locationMapper.mapLocations.bind(locationMapper));
    },

    getJobFamilies(site) {
        return http
            .get(`/recruitingJobFamilies?onlyData=true&finder=findBySiteNumber;SiteNumber=${site}&limit=1000000`)
            .then(jobFamilyMapper.mapActiveJobFamily.bind(jobFamilyMapper));
    },

    getJobFamiliesByIds(jobFamiliesIds) {
        if (!jobFamiliesIds.length) {
            return Promise.reject();
        }

        return http
            .get(`/recruitingJobFamilies?onlyData=true&finder=findByIds;Ids="${jobFamiliesIds.join(',')}"&limit=${jobFamiliesIds.length}`)
            .then(jobFamilyMapper.mapJobFamily.bind(jobFamilyMapper));
    },

    getJobFamiliesByTerm(options) {
        if (options.term == null) {
            return Promise.resolve([]);
        }

        return httpCached
            .get(`/recruitingJobFamilies?onlyData=true&finder=findBySiteNumberAndWord;SiteNumber=${options.siteNumber},FilterAttributes=JobFamilyName,SearchTerms=${options.term},StartsWithFlag=true&limit=1000000`)
            .then(jobFamilyMapper.mapActiveJobFamily.bind(jobFamilyMapper));
    },

    getValidationMessage(tcActiveAttributes) {
        if (!tcActiveAttributes.includes(JOB_CATEGORY_VALUE)) {
            return VALIDATION_MESSAGE_JOB_FUNCTION;
        }

        if (!tcActiveAttributes.includes(JOB_FUNCTION_VALUE)) {
            return VALIDATION_MESSAGE_JOB_CATEGORY;
        }

        return VALIDATION_MESSAGE_BOTH;
    },
};
