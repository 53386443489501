import InterviewDetail from '../model/InterviewDetail';
import participantMapper from './interviewParticipant';
import { IntlConverterUtils } from 'ojs/ojcore';

export const AUTO_CALANDER_SLOT_FLAG_NO = 'N';
export const AUTO_CALANDER_SLOT_FLAG_YES = 'Y';

function convertParticipantsToString(participants = []) {
    return participants
        .map(participant => participantMapper.mapFromRest(participant))
        .filter(participant => Boolean(participant.name))
        .map(participant => participant.name)
        .join(', ');
}

function ensureDate(date) {
    if (!date) {
        return null;
    }

    const convertedDate = new Date(date.substring(0, 19).concat('Z'));

    return IntlConverterUtils.dateToLocalIso(convertedDate);
}

function mapFromRest(interview = {}, autoCalendarSlot, scheduleParticipantsProgrammatic, action) {
    return new InterviewDetail({
        interviewId: interview.interviewId,
        autoCalendarSlot: autoCalendarSlot === AUTO_CALANDER_SLOT_FLAG_YES,
        scheduleId: interview.scheduleId,
        requestId: interview.interviewRequestId,
        submissionId: interview.submissionId,
        isScheduled: interview.isScheduled === 'Y',
        address: interview.formattedSingleLineAddress,
        phoneFormatted: interview.formattedPhoneNumber,
        phonePasscode: interview.phoneNumberPasscode,
        webConferenceUrl: interview.webConferenceLink,
        jobTitle: interview.jobTitle,
        startTimeUTC: interview.startDate,
        startTime: ensureDate(interview.startDate),
        endTimeUTC: interview.endDate,
        endTime: ensureDate(interview.endDate),
        canCancel: interview.canCancel === 'Y',
        canSchedule: interview.canSchedule === 'Y',
        canReSchedule: interview.canReschedule === 'Y',
        reScheduleLimit: interview.remainingRescheduleCount,
        locationDetail: interview.locationDetails,
        interviewerNames: autoCalendarSlot === AUTO_CALANDER_SLOT_FLAG_YES
            ? convertParticipantsToString(scheduleParticipantsProgrammatic)
            : convertParticipantsToString(interview.ScheduleInterviewParticipants),
        action,
    });
}

export default {
    mapFromRest(value, autoCalendarSlot, scheduleParticipantsProgrammatic, action) {
        return mapFromRest(value, autoCalendarSlot, scheduleParticipantsProgrammatic, action);
    },
    mapFromRestArray(valueObj, action) {
        const [value] = valueObj.items;

        return mapFromRest(value, AUTO_CALANDER_SLOT_FLAG_NO, null, action);
    },
};