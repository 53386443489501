import ko from 'knockout';
import router from 'app/model/router';
import applyFlowEvents from 'apply-flow/config/events';
import sectionState from 'apply-flow/model/sectionState';
import sectionValidator from 'apply-flow/model/sectionValidator';
import postApplyService from '../../../apply-flow-post/service/postApply';

export default class ApplyFlowMultiPageNavigationViewModel {

    constructor({ sections, iterator }) {
        this.sections = sections;
        this.iterator = iterator;
        this.sectionValidator = sectionValidator;
        this.isVisible = ko.observable(true);

        const currentSectionNumber = this.iterator().currentSectionNumber();

        this.sectionChange(currentSectionNumber);

        this._sectionSubscription = this.iterator().currentSectionNumber.subscribe(this.sectionChange);

        this._submitSucceedSignal = applyFlowEvents.submitSucceed.add(() => this.isVisible(false));

        this.navigate = this.navigate.bind(this);

        this.route = this.getRoute();
    }

    getRoute() {
        let routeId = 'apply-flow';

        if (postApplyService.isPostApply()) {
            routeId = 'post-apply';
        }

        return routeId;
    }

    isFocusable() {
        return this.sections ? 0 : -1;
    }

    hasErrors(sectionNumber) {
        const isSectionInvalid = !sectionValidator.isSectionValid(sectionNumber)();
        const isSectionVisited = sectionState.isSectionVisited(sectionNumber);

        return isSectionInvalid && isSectionVisited;
    }

    isCurrent(sectionNumber) {
        return this.iterator().currentSectionNumber() === sectionNumber;
    }

    isActive(sectionNumber) {
        return sectionState.isSectionVisited(sectionNumber);
    }

    sectionChange(currentSectionNumber) {
        for (let i = 1; i <= currentSectionNumber; i++) {
            sectionState.setSectionVisited(i);

            if (i < currentSectionNumber) {
                sectionValidator.validateSection(i);
            }
        }
    }

    navigate(sectionNumber) {
        if (sectionState.isSectionVisited(sectionNumber)()) {
            router.go(this.route, { sectionNumber });
        }
    }

    dispose() {
        this._sectionSubscription.dispose();
        this._submitSucceedSignal.detach();
    }

}
