import { pureComputed } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';

export default class EditFormElementViewModel extends FormElementViewModel {

    constructor(params) {
        super(params);
        this.params = params;
        this.editMode = pureComputed(this._computeEditMode, this);
        this.showActionButtons = pureComputed(this._computeShowActionButtons, this);
        this.showCommunicationChannel = pureComputed(() => !this.element.isVerificationInProgress(), this);
        this.showCancelButton = pureComputed(this._computeShowCancelButton, this);
    }

    _computeEditMode() {
        return this.element.cssMode
            ? !this.element.isDisabled()
            : this.element.editMode();
    }

    _computeShowActionButtons() {
        return this.element.cssMode || this.element.isDisabled() || this.element.editMode();
    }

    _computeShowCancelButton() {
        return this.element.cssMode
            ? this.element.editMode() && this.element.isChannelVerified()
            : this.element.editMode();
    }

}
