import { Observable } from 'knockout';

export default class ProfileRedirectionDialogViewModel {
    private isVisible: Observable<boolean>;
    private message: Observable<string>;

    constructor({
        isVisible,
        dialogMessage,
    }: {
        isVisible: Observable<boolean>;
        dialogMessage: Observable<string>;
    }) {
        this.isVisible = isVisible;
        this.message = dialogMessage;
    }

    closeDialog(): void {
        this.isVisible(false);
    }

    dispose(): void {
        this.isVisible(false);
    }
}
