import router from 'app/model/router';
import storageService from 'core/storage/sessionStorage';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import { ORA_DIVERSITY, ORA_VETERAN, ORA_DISABILITY } from '../../enum/blockTypes';
import mapper from 'apply-flow/module/regulatory/mapper/metadata';
import urlEncoder from 'app/module/core/router/urlEncoder';

export class RegulatoryBlocksData {

    constructor() {
        this.blocks = this.getBlocksReduction();
    }

    getBlocksReduction() {
        if (this.blocks) {
            return this.blocks;
        }

        const operation = this.remove;

        this.blocks = new Map([
            [ORA_DIVERSITY, operation],
            [ORA_VETERAN, operation],
            [ORA_DISABILITY, operation],
        ]);

        return this.blocks;
    }

    remove(candidate, ids) {
        candidate.regulatoryResponses = candidate.regulatoryResponses.filter(({ id }) => ids.includes(id));
    }

    getData(block) {
        const encodedRequisitionId = `%22${urlEncoder.encodeQueryParams(router.routeParams().jobId)}%22`;
        const key = `${block.metadataServiceUrl.replace('<REQ_NUM>', encodedRequisitionId)}#ceCacheLang=${siteLanguage.get()}`;

        return storageService.restore(key);
    }

    prePareKey(data) {
        const reformat = mapper.reformatFormElementsFromRest(data);
        const mapped = mapper.mapFormElementsFromRest(reformat);
        const [regulatory] = mapped;
        const [...form] = regulatory.legislations;

        return form.flatMap(legislation => legislation.formElementsDef.map(element => element.name));
    }

    getBlockIdentifier(block) {
        const elements = this.getData(block);

        return this.prePareKey(elements);
    }

}