import Component from './Component';

export default class SectionComponent extends Component {

    constructor(data) {
        super(data);

        this.components = data.components;
        this.rows = data.rows;
    }

}
