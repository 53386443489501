import { components } from 'knockout';

import './component';
import './template';

import { RecommendedJobsWidgetViewModel } from './RecommendedJobsWidgetViewModel';
import recommendedJobsTemplate from './recommended-jobs-widget.html';

components.register('cc-recommended-jobs-widget', {
    viewModel: RecommendedJobsWidgetViewModel,
    template: recommendedJobsTemplate,
});
