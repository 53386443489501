import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import RegulatoryConstants from 'apply-flow/module/regulatory/config/RegulatoryConstants';
import MetadataRepository from 'apply-flow/module/regulatory/module/veteran/service/VeteranMetadata';

import ViewModel from '../VeteranViewModel';
import template from '../veteran.html';

components.register(RegulatoryConstants.ORA_VETERAN.componentName, { viewModel: ViewModel, template });

metadataConfig.registerBlock(RegulatoryConstants.ORA_VETERAN.blockCode, {
    component: RegulatoryConstants.ORA_VETERAN.componentName,
    repository: MetadataRepository,
});
