import converterFactory from 'core/l10n/converter/factory';

export default {
    format(number, currency) {
        return converterFactory.createCurrencyConverter(currency).format(number);
    },

    parse(formattedNumber, currency) {
        return converterFactory.createCurrencyConverter(currency).parse(formattedNumber);
    },
};