import { components } from 'knockout';

import { JobDetailsLocationViewModel } from './JobDetailsLocationViewModel';
import template from './job-details-location.html';

components.register('job-details-location', {
    viewModel: JobDetailsLocationViewModel,
    template,
});

components.register('cc-job-details-location', {
    viewModel: JobDetailsLocationViewModel,
    template,
});
