import FormValidator from './Validator';
import xssValidator from '../../utils/xssValidator';

export default class XssValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'admin.custom-code-editor.restricted-tags-text';
    }

    _validate(value) {
        return xssValidator.validate(value);
    }

}