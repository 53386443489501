import storageService from 'core/storage/sessionStorage';

export const LAST_ACCEPTED_LEGAL_DISCLAIMER_EVENT_TOKEN_KEY = 'lastAcceptedLegalDisclaimerForEvent';

export const storeLastLegalDisclaimerFor = (eventNumber: string): void => {
    storageService.store(LAST_ACCEPTED_LEGAL_DISCLAIMER_EVENT_TOKEN_KEY, eventNumber);
};

export const isLastLegalDisclaimerAcceptedFor = (eventNumber: string): boolean => {
    const acceptedForEvent = storageService.restore(LAST_ACCEPTED_LEGAL_DISCLAIMER_EVENT_TOKEN_KEY);

    return acceptedForEvent === eventNumber;
};

export const clearLastAcceptedLegalDisclaimer = (): void => {
    storageService.remove(LAST_ACCEPTED_LEGAL_DISCLAIMER_EVENT_TOKEN_KEY);
};
