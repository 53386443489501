import { getItemActiveSelector } from 'minimal/config/utils';

type CookiePreferenceLinkWidgetField =
    | 'wrapper'
    | 'container'
    | 'cookieConsentLink'
    | 'cookieConsentLinkOnHover'
    | 'cookieConsentLinkContainer'
    | 'cookieConsentLinkContainerOnHover';

type CookiePreferenceLinkWidgetSelector = Record<CookiePreferenceLinkWidgetField, string>;

export const getSearchResultSelectors = (wrapperSelector: string): CookiePreferenceLinkWidgetSelector => {
    return {
        wrapper: `${wrapperSelector}.cookie-preference-link-wrapper`,
        container: `${wrapperSelector} .cookie-preference-link`,
        cookieConsentLink: `${wrapperSelector} .cookie-preference-link`,
        cookieConsentLinkOnHover: getItemActiveSelector(`${wrapperSelector} .cookie-preference-link`, ''),
        cookieConsentLinkContainer: `${wrapperSelector} .cookie-preference-link .cookie-preference-link__action`,
        cookieConsentLinkContainerOnHover: getItemActiveSelector(
            `${wrapperSelector} .cookie-preference-link`,
            '.cookie-preference-link__action'
        ),
    };
};
