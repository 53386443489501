import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { AdditionalHeaderCustomizationParams } from './types';

export const emptyParams: AdditionalHeaderCustomizationParams = {
    content: {
        height: null,
        heightUnit: 'px',
        menuWidth: null,
        menuWidthUnit: 'px',
        desktopLogoUrl: null,
        desktopLogoMaxHeight: null,
        desktopLogoMaxHeightUnit: 'px',
        mobileLogoUrl: null,
        mobileLogoMaxHeight: null,
        mobileLogoMaxHeightUnit: 'px',
    },
    linksTypography: { ...EMPTY_TYPOGRAPHY },
    linksHoverTypography: { ...EMPTY_TYPOGRAPHY },
    linkSelectedTypography: { ...EMPTY_TYPOGRAPHY },
    sublinksTypography: { ...EMPTY_TYPOGRAPHY },
    sublinksHoverTypography: { ...EMPTY_TYPOGRAPHY },
    submenuTypography: { ...EMPTY_TYPOGRAPHY },
    submenuHoverTypography: { ...EMPTY_TYPOGRAPHY },
    emailTypography: { ...EMPTY_TYPOGRAPHY },
    locationTypography: { ...EMPTY_TYPOGRAPHY },
    nameTypography: { ...EMPTY_TYPOGRAPHY },
    overallStyling: { ...EMPTY_BLOCK_STYLES },
    overallStylingHover: { ...EMPTY_BLOCK_STYLES },
    linksStyling: { ...EMPTY_BLOCK_STYLES },
    linksHoverStyling: { ...EMPTY_BLOCK_STYLES },
    linksSelectedStyling: { ...EMPTY_BLOCK_STYLES },
    sublinksStyling: { ...EMPTY_BLOCK_STYLES },
    sublinksHoverStyling: { ...EMPTY_BLOCK_STYLES },
    buttonsStyling: { ...EMPTY_BLOCK_STYLES },
    buttonsHoverStyling: { ...EMPTY_BLOCK_STYLES },
    buttonsSelectedStyling: { ...EMPTY_BLOCK_STYLES },
    menuStyling: { ...EMPTY_BLOCK_STYLES },
    dropdownStyling: { ...EMPTY_BLOCK_STYLES },
};
