import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class MinSelectionFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);
        this._message = 'validators.min-selection';

        ko.utils.extend(this._options, {
            minSelection: this._options.minSelection,
        });
    }

    _validate(value, options) {
        if (value && value.length) {
            return options.minSelection <= value.length;
        }

        return true;
    }

}