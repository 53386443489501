import { observable, Observable } from 'knockout';
import { EventRegistrationPhase, Event, EventStatus } from '../../config/types';
import { convertEventDateRange, convertEventRegistrationDate, EventDateRange } from '../../service/eventDate';
import { registrationPhaseLabelMap } from '../../config/registrationPhaseLabelMap';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';

type Props = {
    event: Event;
};

export default class CsEventTileViewModel {
    event: Event;
    title: string;
    eventNumber: string;
    eventDateRange: EventDateRange;
    eventStatus: EventStatus;
    registrationPhase: EventRegistrationPhase | null;
    registrationPhaseLabel: string;
    registeredOnDate: string;
    isRegistrationActive: boolean;
    isVirtual: boolean;
    location: string | null;
    meetingURL: string | null;
    menuId: string;
    eventDetailsUrl: string;
    isActionInProgress: Observable<boolean>;
    showEventActions: boolean;
    showConfirmAction: boolean;
    isCancelled: boolean;

    constructor({ event }: Props) {
        this.event = event;
        this.eventNumber = event.eventNumber;
        this.eventStatus = event.eventStatus;
        this.title = event.title;
        this.registrationPhase = event.registrationPhase;
        this.registrationPhaseLabel = registrationPhaseLabelMap[this.registrationPhase || 'invited'];
        this.isRegistrationActive = event.registrationStatus === 'active';
        this.location = event.eventLocation;
        this.isVirtual = event.eventFormat === 'virtual';
        this.meetingURL = event.meetingURL;
        this.menuId = `event-menu-${event.eventNumber}`;
        this.isActionInProgress = observable<boolean>(false);
        this.showConfirmAction = this.isRegistrationActive && !event.confirmedFlag;
        this.eventDateRange = convertEventDateRange(event.startDate, event.endDate);
        this.registeredOnDate = convertEventRegistrationDate(event.registeredOnDate);
        this.isCancelled = this.eventStatus === 'cancelled';
        this.showEventActions = this.setShowEventActions();

        this.eventDetailsUrl = absoluteRouter.interpolate(
            {},
            'candidate-self-service.event-detail-fullscreen',
            { eventId: this.eventNumber }
        );
    }

    showEventPreview(): void {
        router.go('candidate-self-service.event-preview', { eventId: this.eventNumber });
    }

    private setShowEventActions(): boolean {
        return (
            !this.isCancelled &&
            (this.event.registrationPhase === 'registered' || this.event.registrationPhase === 'pending')
        );
    }
}
