function _removeQuotationMark(string) {
    return string.replace('"', '');
}

function _removeHTML(string) {
    return string.replace(/(<([^>]+)>)/ig, '');
}

export default {

    sanitize(string = '') {
        const sanitiziedString = _removeHTML(string);

        return _removeQuotationMark(sanitiziedString);
    },

    sanitizeHTML(string = '') {
        return _removeHTML(string);
    },
};