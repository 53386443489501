function calculateMaxAge({ days, hours }) {
    const hour = 60 * 60;

    if (days) {
        return days * 24 * hour;
    }

    return hours * hour;
}

export default {
    set(name, value, maxAge) {
        let cookieMaxAge = '';

        if (maxAge) {
            cookieMaxAge = `; max-age=${calculateMaxAge(maxAge)}`;
        }

        document.cookie = `${name}=${value}${cookieMaxAge}; path=/`;
    },

    get(name) {
        const nameEq = `${name}=`;
        const allCookies = document.cookie.split(';');

        for (let len = allCookies.length, i = 0; i < len; i++) {
            const cookie = allCookies[i].trim();

            if (cookie.indexOf(nameEq) === 0) {
                return cookie.substring(nameEq.length, cookie.length);
            }
        }

        return null;
    },

    remove(name) {
        this.set(name, '', { hours: -1 });
    },
};

