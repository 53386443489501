import { JobDetailsPageData, JobFit } from 'minimal/module/job-details/types';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { notReachable } from 'app/types/utils';
import { getStylingBoxCss, getTypographyCss } from 'minimal/module/search/service/customCss';
import { JobDetailsJobFitParams } from 'minimal/module/job-details/component/job-details-content/component/job-details-job-fit/config/types';
import {
    generateJobAttributes,
    JobAttribute,
    AttributesList,
} from 'cx/module/job-details/service/jobCategoryAttributes';
import { Observable, PureComputed, pureComputed, Subscription } from 'knockout';
import { JobCategoryTagKey } from 'site-editor/module/job-details-editor/component/cc-job-details-job-fit/config/jobCategoryTypes';
import { areJobFitEnabled } from 'app/service/areJobFitEnabled';
import i18n from 'core/i18n/i18n';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsJobFitParams;
    id?: string;
};

export class JobDetailsJobFitViewModel extends CustomComponentViewModel<JobDetailsJobFitParams> {
    pageData: PureComputed<JobDetailsPageData>;
    jobCategoryTypesOptions: Observable<JobCategoryTagKey[]>;
    jobDetailsJobFitItems: PureComputed<JobAttribute[]>;
    attributesList: PureComputed<AttributesList>;
    areJobFitEnabled: boolean;
    ariaLabel: string;
    disclaimerNote: string;
    jobDetailsJobFitItemsSub: Subscription | null;

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;
        this.jobCategoryTypesOptions = this.customizationParams.content.jobCategoryTypesOptions;

        const { jobFitState, job } = this.pageData();

        this.ariaLabel = i18n('job-details.job-fit-aria-label', null, job.lang);

        this.disclaimerNote = i18n('admin.site-editor.content-editor.job-fit-disclaimer', null, job.lang);

        this.jobDetailsJobFitItemsSub = null;

        this.attributesList = pureComputed(() => generateJobAttributes(jobFitState() as JobFit, job.lang));

        this.jobDetailsJobFitItems = pureComputed(() => {
            return this.jobCategoryTypesOptions().reduce((filteredItems, item) => {
                if (!item) {
                    return filteredItems;
                }

                if (this.isStandardFieldWithValue(item)) {
                    return filteredItems.concat(this.attributesList()[item]);
                }

                return filteredItems;
            }, [] as JobAttribute[]);
        });

        this.areJobFitEnabled = areJobFitEnabled();

        this.createJobFitAriaLabel();
    }

    private createJobFitAriaLabel(): void {
        this.jobDetailsJobFitItemsSub = this.jobDetailsJobFitItems.subscribe((jobDetailsJobFitItems) => {
            this.ariaLabel = jobDetailsJobFitItems.reduce((ariaLabel: string, item: JobAttribute) => {
                if (!item) {
                    return ariaLabel;
                }

                const { name, value } = item;

                return `${ariaLabel} ${name} ${value},`;
            }, this.ariaLabel);

            this.ariaLabel += `${(this.pageData().jobFitState() as JobFit).summary} ${this.disclaimerNote}`;
        });
    }

    isStandardFieldWithValue(item: JobCategoryTagKey): boolean {
        return Object.prototype.hasOwnProperty.call(this.attributesList(), item);
    }

    getCustomStyle(key: keyof JobDetailsJobFitParams): string {
        switch (key) {
            case 'content':
                return '';

            case 'jobFitTitlesTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-category__item-name`,
                    typography: this.customizationParams[key],
                });

            case 'jobFitScoreTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-category__item-value`,
                    typography: this.customizationParams[key],
                });

            case 'jobFitTitlesDescriptionTypography':
                return getTypographyCss({
                    selector: `.${this.uniqueWrapperClass} .job-category__description`,
                    typography: this.customizationParams[key],
                });

            case 'overallDisplayStyling':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-category`,
                    stylingBox: this.customizationParams[key],
                });

            case 'jobFitTitlesStyling':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-category__item`,
                    stylingBox: this.customizationParams[key],
                });

            case 'jobFitPipsStyling':
                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-fit-tile__pips .pip-block--default`,
                    stylingBox: this.customizationParams[key],
                });

            case 'jobFitPipsSelectedStyling':
                const defaultBorderRadius = this.customizationParams.jobFitPipsStyling.borderRadius;

                return getStylingBoxCss({
                    darkIconSelector: null,
                    lightIconSelector: null,
                    selector: `.${this.uniqueWrapperClass} .job-fit-tile__pips .pip-block--active`,
                    stylingBox: { ...this.customizationParams[key], borderRadius: defaultBorderRadius },
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return notReachable(key);
        }
    }

    dispose(): void {
        this.jobDetailsJobFitItemsSub?.dispose();
    }
}
