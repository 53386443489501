import ko from 'knockout';
import sectionState from 'apply-flow/model/sectionState';
import sectionValidator from 'apply-flow/model/sectionValidator';
import eSignatureFormBuilder from 'apply-flow/service/eSignatureFormBuilder';
import candidateModel from 'apply-flow/model/candidate';

export default class ESignatureViewModel {

    constructor({ application, section, blockId, additionalData, isActive }) {
        this.application = application;
        this.sectionNumber = section.number;
        this.isSectionActive = isActive;
        this.blockId = blockId;

        this.form = ko.observable();
        this.model = this.application().eSignature;

        this.esignDescription = additionalData.description;

        this._createForm();
    }

    _createForm() {
        const form = eSignatureFormBuilder.createForm({
            eSignature: this.model,
            candidate: candidateModel,
        });

        this.form(form);

        sectionValidator.registerForm(this.form, this.sectionNumber);
        sectionState.setBlockReady(this.sectionNumber, this.blockId);
    }

    dispose() {
        sectionValidator.unregisterForm(this.form, this.sectionNumber);
    }

}