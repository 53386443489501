import ko from 'knockout';
import i18n from 'core/i18n/i18n';
import metadataConfig from 'apply-flow/config/metadata';

const BLOCK_PROPERTIES_MAP = {
    QstnrVersionNumber: 'questionnaireVersionNumber',
    ContextCode: 'context',
    IRC_CANDIDATE_RESUME_REQUIRED: 'resumeRequired',
    IRC_CANDIDATE_COVERLETTER_REQUIRED: 'cvRequired',
    IRC_CANDIDATE_SOCIAL_REQUIRED: 'linksRequired',
    MISC_REQUIRED: 'required',
};

const BLOCK_PROPERTIES_CAST_ON_MAP = {
    ContentTypeId: _castOnIntegerOrIntegerArray,
    SectionId: _castOnIntegerOrIntegerArray,
    PreferredFlag: _castOnBooleanOrBooleanArray,
    IRC_CANDIDATE_RESUME_REQUIRED: _castOnBooleanOrBooleanArray,
    IRC_CANDIDATE_COVERLETTER_REQUIRED: _castOnBooleanOrBooleanArray,
    IRC_CANDIDATE_SOCIAL_REQUIRED: _castOnBooleanOrBooleanArray,
    MISC_REQUIRED: _castOnBooleanOrBooleanArray,
    IsRequired: _castOnBooleanOrBooleanArray,
};

let blockDisplaySequence = 1;

function _castOnBooleanOrBooleanArray(rawValue) {
    const array = rawValue.split(',').map(value => Boolean(value === 'true'));

    return array.length > 1 ? array : array.pop();
}

function _castOnIntegerOrIntegerArray(rawValue) {
    const array = rawValue.split(',').map(value => parseInt(value, 10));

    return array.length > 1 ? array : array.pop();
}

function _isComponentRegistered(restBlock) {
    return ko.components.isRegistered(restBlock.component);
}

function _hasRequiredBlockProperties({ code, blockProperties }) {
    const metaConfig = metadataConfig.getConfigurationForBlock(code);
    const hasBlockPropertiesKeys = Object.keys(blockProperties).length > 0;

    return !(metaConfig.requireBlockProperties && !hasBlockPropertiesKeys);
}

function _isNotPresectionBlock({ code }) {
    return !metadataConfig.isPresectionBlock(code);
}

function _toCamelCase(string) {
    return string[0].toLowerCase() + string.substr(1);
}

export default {
    createAgreementsBlock() {
        return {
            blockCode: 'ORA_AGREEMENTS',
            blockId: 'ORA_AGREEMENTS',
            blockName: 'agreements',
            blockHideInMobileFlag: false,
        };
    },

    createESignatureBlock(description) {
        return {
            blockCode: 'ORA_ESIGNATURE',
            blockId: 'ORA_ESIGNATURE',
            blockName: 'e-signature',
            blockTitle: i18n('apply-flow.e-signature.header'),
            blockInstructions: undefined,
            blockHideInMobileFlag: false,
            additionalData: {
                description,
            },
        };
    },

    mapFlowBlocksFromRest(restBlocks) {
        return restBlocks.map(this.mapFlowBlockFromRest.bind(this))
            .filter(_isComponentRegistered)
            .filter(_hasRequiredBlockProperties)
            .filter(_isNotPresectionBlock);
    },

    mapFlowBlockFromRest(restBlock) {
        return {
            component: metadataConfig.getComponentForBlock(restBlock.blockCode),
            code: restBlock.blockCode,
            title: restBlock.blockTitle,
            instructions: restBlock.blockInstructions,
            hideOnMobile: restBlock.blockHideInMobileFlag,
            metadataServiceUrl: this.getMetadataServiceUrl(restBlock.metadataService),
            readWriteServiceUrl: this.getMetadataServiceUrl(restBlock.readWriteService),
            displaySequence: blockDisplaySequence++,
            additionalData: restBlock.additionalData,
            blockProperties: this.getBlockProperties(restBlock),
            blockId: restBlock.pageBlockId,
        };
    },

    getMetadataServiceUrl(url) {
        if (!url) {
            return null;
        }

        const splittedUrls = url.split('||');

        if (splittedUrls.length === 1) {
            return `/${url}`;
        }

        const [firstUrl, secondUrl] = splittedUrls;

        return [`/${firstUrl}`, `/${secondUrl}`];
    },

    getBlockProperties({ blockProperties }) {
        if (!blockProperties) {
            return {};
        }

        return blockProperties.split('||')
            .reduce((properties, keyValuePair) => {
                const [key, value] = keyValuePair.split('=');

                const propertyKey = BLOCK_PROPERTIES_MAP[key] || _toCamelCase(key);
                const castOn = BLOCK_PROPERTIES_CAST_ON_MAP[key];

                if (propertyKey) {
                    properties[propertyKey] = castOn ? castOn(value) : value;
                }

                return properties;
            }, {});
    },
};
