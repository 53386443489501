import { observable, pureComputed, PureComputed, Observable } from 'knockout';
import { JobDetailsPageData } from 'minimal/module/job-details/types';
import { JobDetailsSimilarJobsParams } from './config/types';
import { emptyParams } from './config/emptyParams';
import {
    mapParamsConfigurationToObservable,
    mapParamsToObservableParams,
} from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import {
    LayoutStyle,
    SearchResultsParams,
} from 'minimal/module/search/component/search-results/config/types';
import { EMPTY_BLOCK_STYLES, EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config';
import { getCustomStyles } from 'minimal/module/search/component/search-job-results/config/customStyles';
import i18n from 'core/i18n/i18n';
import appConfig from 'app/model/config';
import { CUSTOM_JOB_DETAILS_PAGE } from 'cx/module/custom-content/enums/pageTypes';
import { AdditionInformationItem } from 'minimal/module/search/component/search-results/config/types';

type Props = {
    pageData: PureComputed<JobDetailsPageData>;
    params?: JobDetailsSimilarJobsParams;
    id?: string;
    mode?: string;
};

export class JobDetailsSimilarJobsViewModel extends CustomComponentViewModel<JobDetailsSimilarJobsParams> {
    pageData: PureComputed<JobDetailsPageData>;
    isList: PureComputed<boolean>;
    searchResultsParams: PureComputed<SearchResultsParams>;
    isAdminMode: boolean;
    resultItemComponentName: PureComputed<string>;

    constructor({ pageData, mode, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.isAdminMode = mode === 'admin';
        this.pageData = pageData;
        this.searchResultsParams = pureComputed(() => this.mapParams(this.customizationParams));
        this.isList = pureComputed(() => this.customizationParams.content.jobDisplayStyle() === 'list');

        this.resultItemComponentName = pureComputed(() =>
            this.isList()
                ? `job-details-similar-jobs-list-item${this.isAdminMode ? '-static' : ''}`
                : `job-details-similar-jobs-grid-item${this.isAdminMode ? '-static' : ''}`
        );

        this.replaceSimilarJobsLabelToContentLocaleLang();
    }

    private replaceSimilarJobsLabelToContentLocaleLang(): void {
        if (!this.isAdminMode && !appConfig.templates[CUSTOM_JOB_DETAILS_PAGE]) {
            const similarJobsHeaderElement = document.querySelector(
                '.job-details__similar-jobs-header'
            ) as HTMLElement;

            if (similarJobsHeaderElement) {
                similarJobsHeaderElement.innerText = i18n(
                    'search.search-similar.header',
                    null,
                    this.pageData().job.lang
                );
            }
        }
    }

    mapParams(params: JobDetailsSimilarJobsParams): SearchResultsParams {
        const { content, ...rest } = params;

        return {
            contentParams: {
                additionalInformationDisplayed: content.additionalInformationDisplayed as Observable<
                    AdditionInformationItem[] | undefined
                >,
                headerInformationDisplayed: content.headerInformationDisplayed,
                jobDisplayStyle: content.jobDisplayStyle,
                jobInfoDisplay: content.jobInfoDisplay,
                jobInfoDisplayed: content.jobInfoDisplayed,
                jobInfoSeparator: content.jobInfoSeparator,
                jobInfoTagsConfiguration: content.jobInfoTagsConfiguration,
                jobTagsInformationDisplayed: content.jobTagsInformationDisplayed,
                mainContentInformationDisplayed: content.mainContentInformationDisplayed,
                numberOfJobsDisplayed: content.numberOfJobsDisplayed,
                width: content.width,
                widthUnit: content.widthUnit,
                hideTalentCommunitySignup: observable<boolean>(true),
                displayAfterJobs: observable(0),
                enableInfiniteScroll: observable<boolean>(false),
                enableShareIcon: observable<boolean>(false),
                enableArrowIcon: observable<boolean>(false),
                enableApplyButton: observable<boolean>(false),
                enableSaveIcon: observable<boolean>(false),
                layoutStyle: observable<LayoutStyle>('layout1'),
                originalLayoutStyle: observable<LayoutStyle>('layout1'),
                criteria: observable(JSON.stringify([])),
            },
            jobInfoValueTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            jobInfoValueTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            jobInfoLabelTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            jobInfoLabelTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            selectedSortOptionTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            selectedSortValueTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            selectedSortValueTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            sortByTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            sortOptionsTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            sortByDropdownStyling: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            sortByHoverStyling: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            sortByStyling: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            sortOptionsTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            alreadyAppliedJobTagOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            jobTagsTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            descriptionTitleTypography: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            descriptionTitleTypographyOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            applyButton: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            applyButtonOnHover: mapParamsToObservableParams(EMPTY_TYPOGRAPHY),
            jobInfoIconsStyles: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            jobInfoIconsStylesOnHover: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            applyButtonBox: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            applyButtonBoxContainer: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            applyButtonBoxOnHover: mapParamsToObservableParams(EMPTY_BLOCK_STYLES),
            ...rest,
        };
    }

    getCustomStyles(params: JobDetailsSimilarJobsParams): string {
        const searchResultCustomizationParams = this.mapParams(params);

        return getCustomStyles(searchResultCustomizationParams, this.uniqueWrapperClass);
    }

    getCustomStyle(): string {
        return '';
    }
}
