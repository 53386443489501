import ApplyFlowBlockComponentViewModel from '../common/ApplyFlowBlockComponentViewModel';
import { CustomJsValidation } from './model/CustomJsValidation';

export default class CustomJsValidationBlockComponentViewModel extends ApplyFlowBlockComponentViewModel {

    constructor(params) {
        super(params);

        this.customJsValidation = new CustomJsValidation();
        this.runCustomJsValidation = this.customJsValidation.run.bind(this.customJsValidation);
    }

    _initCustomJsValidation(identifier) {
        this.customJsValidation.init({
            section: this.section,
            blockCode: this.code,
            identifier,
        });
    }

}