import { Observable, ObservableArray, observable, toJS } from 'knockout';
import router from 'app/model/router';
import i18n from 'app/module/core/i18n/i18n';
import SearchQueryBuilder from 'cx/module/search/model/SearchQueryBuilder';
import { Filter } from 'app/module/cx/module/search/module/search-results/module/facet/config/types';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import {
    getSearchContextParam,
    getStoredQuery,
    saveSearchContextParamInStorage,
} from '../../service/storeSearchContextDetails';
import {
    RouterParams,
    SearchContext,
    searchContextMap,
    SearchRouteOnly,
} from 'cx/module/search/config/types';
import signals from 'minimal/module/search/config/events';

export type Facet = {
    paramValueBuilder: (queryValue: string, pipeKey: string, pipeValues: string[]) => string | null;
    items: ObservableArray<Filter>;
    name: string;
    selectedIds: ObservableArray<string>;
    selectedItems: ObservableArray<Filter>;
    type: string;
    title?: string;
    paramKey?: string;
    context: SearchContext;
};

export type Props = {
    facet: Facet;
    item: Filter;
    isDropdownOpen: Observable<boolean>;
};

let recentlyTriggeredItem = '';

export default class SearchFacetDropdownItemViewModel {
    item: Filter;
    facet: Facet;
    hasFocus: Observable<boolean>;
    isDropdownOpen: Observable<boolean>;
    ariaLabel?: string;

    constructor({ facet, item, isDropdownOpen }: Props) {
        this.item = item;
        this.facet = facet;
        this.isDropdownOpen = isDropdownOpen;
        this.hasFocus = observable<boolean>(recentlyTriggeredItem === item.text);

        this.ariaLabel = `${i18n('search.filter-label', { filtername: this.item.text })} ${
            this.item.totalCount
        }`;
    }

    toggleItem(item: Filter): void {
        item.selected(!item.selected());
    }

    setOption(item: Filter): void {
        const facetFilter = this.facet.items().find((facetItem: Filter) => facetItem.value === item.value);

        facetFilter ? this.toggleItem(facetFilter) : this.facet.items.push(item);

        if (facetFilter !== item) {
            this.toggleItem(item);
        }

        this.isDropdownOpen(false);
        recentlyTriggeredItem = item.text;

        const routeId = router.route().id;

        const query = getStoredQuery(searchContextMap[routeId as SearchRouteOnly]);

        const searchQuery = new SearchQueryBuilder(query).withFacet(toJS(this.facet)).build();

        if (areEventsEnabled()) {
            saveSearchContextParamInStorage(getSearchContextParam(routeId), {
                query: searchQuery,
            } as RouterParams);
        }

        router.go(routeId, { query: searchQuery }, { inherit: false }).then(() => {
            signals.filtersSelected.dispatch(this.facet.type);
        });
    }
}
