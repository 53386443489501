import { wrapBySelector } from './wrapBySelector';

export const MOBILE_MEDIA_QUERY = '@media all and (max-width: 767px)';
export const DESKTOP_MEDIA_QUERY = '@media all and (min-width: 768px)';
export const NO_HOVER_MEDIA_QUERY = '@media (hover: none)';

export function mobileOnly(styles: string): string {
    return wrapBySelector(MOBILE_MEDIA_QUERY, styles);
}

export function desktopOnly(styles: string): string {
    return wrapBySelector(DESKTOP_MEDIA_QUERY, styles);
}

export function noHoverOnly(styles: string): string {
    return wrapBySelector(NO_HOVER_MEDIA_QUERY, styles);
}
