import ExtraInformationTileForm from './ExtraInformationTileForm';
import ExtraInformationTileStrategy from '../model/ExtraInformationTileStrategy';
import formBuilder from '../../../../../../core/form/formBuilder';
import UniqueKeyValidator from './validator/UniqueKey';

function addUniqueKeyValidator(form, existingRows, uniqueElement) {
    uniqueElement.addValidator(new UniqueKeyValidator({
        existingRows,
        uniqueKey: uniqueElement.name(),
        newRow: form.model,
        elementValueLabel: uniqueElement.valueLabel,
    }));
}

export default {
    createForm(configObj) {
        const config = Object.assign({ elements: [] }, configObj);
        let uniqueElement;

        config.elements = config.elements.map((element) => {
            element.validateImmediately = config.validateImmediately;

            const buildedElement = formBuilder.createFormElement(element);

            if (element.isUniqueKey) {
                uniqueElement = buildedElement;
            }

            return buildedElement;
        });

        const form = new ExtraInformationTileForm(uniqueElement, ExtraInformationTileStrategy, config);

        if (uniqueElement) {
            addUniqueKeyValidator(form, config.existingRows, uniqueElement);
        }

        return form;
    },
};