import searchMapper from 'search/mapper/search';
import appConfig from 'app/model/config';
import http from 'core/http/http';
import Search from './search';

const SEARCH_AI_JOBS_LIMIT = 4;
const JERSEY_BASE_URL = '/hcmRestApi';

export default class JerseySearch extends Search {

    jobsBaseUrl = '/indexSearch/recruitingCEJobRequisitions?onlyData=true';

    paramsDelimiter = '&';

    urlSuffix = '&:findParams:';

    #adfConfig = {
        jobsBaseUrl: '/recruitingCEJobRequisitions?onlyData=true',
        paramsDelimiter: ',',
        urlSuffix: ';:findParams:',
    }

    searchSimilarJobs({ params, similarJobId, limit = SEARCH_AI_JOBS_LIMIT }) {
        const { jobsBaseUrl, paramsDelimiter, urlSuffix } = this.#adfConfig;

        const url = `${jobsBaseUrl}&expand=requisitionList.secondaryLocations&finder=findSimilarRequisitions${urlSuffix}`;
        const candidateNumber = this._getCandidateNumber();

        return http.get(url, {
            params: {
                findParams: searchMapper.mapSearchAIJobsParamsToRest(params, similarJobId, candidateNumber,
                    appConfig.siteNumber, limit),
            },
            paramsDelimiter,
        })
            .then(searchMapper.mapSearchResultsFromRest);
    }

    searchJobsToCandidate(params, lastAppliedJobId) {
        const { jobsBaseUrl, paramsDelimiter, urlSuffix } = this.#adfConfig;

        const candidateNumber = this._getCandidateNumber();

        const url = `${jobsBaseUrl}&expand=requisitionList.secondaryLocations&finder=findSimilarRequisitionsByCandidate${urlSuffix}`;

        if (!params.limit) {
            params.limit = SEARCH_AI_JOBS_LIMIT;
        }

        return http.get(url, {
            params: {
                findParams: searchMapper.mapSearchAIJobsParamsToRest(
                    params,
                    lastAppliedJobId,
                    candidateNumber,
                    appConfig.siteNumber,
                    params.limit,
                ),
            },
            paramsDelimiter,
        })
            .then(searchMapper.mapSearchResultsFromRest);
    }

    _getDefaultHttpConfig() {
        return {
            paramsDelimiter: this.paramsDelimiter,
            baseUrl: `${appConfig.apiBaseUrl || ''}${JERSEY_BASE_URL}`,
        };
    }

}
