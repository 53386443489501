// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mapping from 'knockout-mapping';
import { PureComputed, pureComputed, observable, Observable } from 'knockout';
import {
    CategoryTilesParams,
    CategoryTilesDisplayContent,
    JobFamilyList,
    CategoryTileFromConfig,
    CategoryTileCustomizationParams,
    CategoryTilesParamsWithoutContent,
    TileId,
    CategoryTilesCustomizationParams,
} from './config/types';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { emptyParams } from './config/emptyParams';
import { getContentCustomStyles, getCustomStyles } from './config/customStyles';
import { PageType } from 'site-editor/enum/pageTypes';
import appConfig from 'app/model/config';
import { getCatgeoryListData, getTilesData } from './service/categoryTilesProvider';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import router from 'app/model/router';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import { DEFAULT_CATEGORY_TILE_IMAGES } from './config/defaultParams';
import { GLOBAL_TILE_ID } from 'site-editor/module/content-editor/component/cc-category-tiles-editor/component/cc-category-tiles-list/CcCategoryTilesListViewModel';
import contentEditorEvents from 'site-editor/module/content-editor/config/events';
import { SignalBinding } from 'signals';
import { deepCopy } from 'core/utils/deepCopy';
import { CategoryTileModel } from 'site-editor/config/categoryTilesTypes';
import {
    fetchCategoryTileParamsFromStorage,
    removeCategoryTileParamsFromStorage,
} from 'site-editor/module/content-editor/component/cc-category-tiles-editor/utils/categoryTileUtils';

export type Props = {
    params?: CategoryTilesParams;
    id?: string;
    mode?: string;
    pageType?: PageType;
    lang?: Observable<string>;
    content?: any;
    subComponents?: CategoryTileCustomizationParams[];
};

export class CategoryTilesViewModel {
    customCss: PureComputed<string>;
    customizationParams: CategoryTilesParams;
    uniqueWrapperClass: string;
    isAdmin: boolean;
    tileStyleClass: PureComputed<string>;
    tileVerticalAlignementClass: PureComputed<string>;
    categoryTilesStyles: PureComputed<string>;
    selectedTileElement: PureComputed<any>;
    categoryTilesList: Observable<CategoryTilesDisplayContent[]>;
    languageCode: string;
    siteNumber: string;
    jobCategoryList: CategoryTileFromConfig[];
    subComponents: Record<string, CategoryTilesParamsWithoutContent | CategoryTilesParams> | undefined;
    isCancelInProcess: Observable<boolean>;
    isCancelInProgressEventSubscription: SignalBinding<string | null>;
    categoryTilesData: Record<string, CategoryTilesCustomizationParams>;
    categoryTileStyles: { [key: string]: string };
    previousGlobalBackgroundImage: string | null;
    isEditorInitiallyLoaded: boolean;

    constructor({ id, params, subComponents, mode, lang }: Props) {
        const isThemePreview = Boolean(window.frameElement && window.frameElement.id === 'preview-frame');
        const { siteCode } = router.routeParams() as { siteCode: string };
        let styleStr = '';

        this.isEditorInitiallyLoaded = true;
        this.categoryTileStyles = {};
        this.categoryTilesData = {};

        removeCategoryTileParamsFromStorage();

        this.subComponents = this.getSubcomponents(subComponents);

        this.siteNumber = appConfig?.siteNumber;
        this.jobCategoryList = appConfig?.links?.jobCategory;

        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.customizationParams = params || mapParamsConfigurationToObservable(emptyParams);

        this.isAdmin = mode === 'admin';
        this.isCancelInProcess = observable(false);

        this.languageCode = lang ? lang() : siteLanguage.getFusionCode();

        this.isCancelInProgressEventSubscription = contentEditorEvents.isCancelInProgress.add(
            (val: boolean) => {
                this.isCancelInProcess(val);
            }
        );

        this.previousGlobalBackgroundImage = null;

        this.customCss = pureComputed(() => {
            styleStr = '';

            const selectedTileId = (
                this.customizationParams?.selectedSubComponent as unknown as Observable
            )();

            if (!isAdminRoute() || (!selectedTileId && isAdminRoute())) {
                styleStr += this.getSiteCustomStyles();
            } else {
                this.storeCategoryTilesData(selectedTileId);

                if (this.isCancelInProcess()) {
                    this.clearCategoryTileStylesAndData();
                    this.getSubcomponentsFromStorage();
                    this.resetIndividualCategoryTileStyles();
                } else if (this.isEditorInitiallyLoaded) {
                    this.resetIndividualCategoryTileStyles();
                }

                this.saveContentStylesOnGlobal(selectedTileId);
                this.getRemainingStyles(selectedTileId);

                if (!this.isCancelInProcess()) {
                    styleStr = '';
                    this.regenerateAllTilesCSS();
                }

                this.storePreviousGlobalBackgroundImage(selectedTileId);
                styleStr = this.constructCategoryTileStyleStr(styleStr);
            }

            return styleStr;
        });

        this.tileStyleClass = pureComputed(() => this.getSelectedClassName('TILE_STYLE'));
        this.tileVerticalAlignementClass = pureComputed(() => this.getSelectedClassName('TILE_ALIGNMENT'));
        this.selectedTileElement = pureComputed(() => this.getSelectedClassName('SELECTED_TILE'));
        this.categoryTilesStyles = pureComputed(() => this.getCategoryTilesStyles());
        this.categoryTilesList = observable<CategoryTilesDisplayContent[]>([]);

        if (isThemePreview) {
            this.categoryTilesList(getTilesData(this.jobCategoryList));
        } else {
            getCatgeoryListData(isAdminRoute() ? siteCode : this.siteNumber).then((data) => {
                if (data?.length) {
                    this.languageCode === 'US'
                        ? this.categoryTilesList(getTilesData(data))
                        : this.getUpdatedTilesList(data);
                }
            });
        }
    }

    private constructCategoryTileStyleStr(styleStr: string): string {
        for (const tile in this.categoryTileStyles) {
            styleStr += this.categoryTileStyles[tile];
        }

        return styleStr;
    }

    private saveContentStylesOnGlobal(selectedTileId: TileId): void {
        if (selectedTileId != GLOBAL_TILE_ID) {
            this.categoryTileStyles[GLOBAL_TILE_ID] += getContentCustomStyles(
                this.customizationParams,
                this.uniqueWrapperClass
            );
        }
    }

    private getRemainingStyles(selectedTileId: TileId): void {
        if (selectedTileId) {
            this.categoryTileStyles[selectedTileId] = getCustomStyles(
                this.customizationParams,
                this.uniqueWrapperClass,
                selectedTileId,
                this.categoryTilesData[GLOBAL_TILE_ID],
                this.previousGlobalBackgroundImage
            );
        }
    }

    private resetIndividualCategoryTileStyles(): void {
        for (const key in this.subComponents) {
            if (key !== '__ko_mapping__') {
                const subComponentInfo = this.subComponents?.[key];
                const tileId = subComponentInfo?.type?.();

                if (tileId) {
                    this.categoryTilesData[tileId] = mapping.toJS(subComponentInfo);

                    this.categoryTileStyles[tileId] = getCustomStyles(
                        subComponentInfo,
                        this.uniqueWrapperClass,
                        tileId,
                        this.categoryTilesData[GLOBAL_TILE_ID],
                        this.previousGlobalBackgroundImage
                    );
                }

                this.isEditorInitiallyLoaded = false;
            }
        }
    }

    private getSubcomponentsFromStorage(): void {
        const subComponentsFromStorage = fetchCategoryTileParamsFromStorage();

        if (subComponentsFromStorage?.length) {
            this.subComponents = mapping.fromJS({ ...subComponentsFromStorage });
        }
    }

    private storePreviousGlobalBackgroundImage(selectedTileId: TileId): void {
        if (selectedTileId === GLOBAL_TILE_ID) {
            this.previousGlobalBackgroundImage =
                this.customizationParams.categoryTileStyling.backgroundImage();
        }
    }

    private regenerateAllTilesCSS(): void {
        const tilesInfo = this.categoryTilesData;

        for (const key in tilesInfo) {
            if (key !== '__ko_mapping__') {
                const subComponentInfo = tilesInfo?.[key];

                if (key && key !== GLOBAL_TILE_ID) {
                    this.categoryTileStyles[key] = getCustomStyles(
                        mapping.fromJS(subComponentInfo),
                        this.uniqueWrapperClass,
                        key,
                        this.categoryTilesData[GLOBAL_TILE_ID],
                        this.previousGlobalBackgroundImage
                    );
                }
            }
        }
    }

    private storeCategoryTilesData(selectedTileId: TileId): void {
        if (selectedTileId != null) {
            this.categoryTilesData[selectedTileId] = mapping.toJS(this.customizationParams);
        }
    }

    private clearCategoryTileStylesAndData(): void {
        for (const key in this.categoryTileStyles) {
            if (key !== GLOBAL_TILE_ID) {
                delete this.categoryTileStyles[key];
                delete this.categoryTilesData[key];
            }
        }
    }

    private getSubcomponents(
        subComponents: CategoryTileCustomizationParams[] | undefined
    ): Record<string, CategoryTilesParamsWithoutContent | CategoryTilesParams> | undefined {
        if (subComponents?.length) {
            return mapping.fromJS({ ...subComponents });
        } else if (isAdminRoute()) {
            const finalSubComponentParams: Record<
                string,
                CategoryTilesParamsWithoutContent | CategoryTilesParams
            > = {} as Record<string, CategoryTilesParamsWithoutContent | CategoryTilesParams>;

            appConfig.links.jobCategory.forEach((eachLink: CategoryTileModel, idx: number) => {
                finalSubComponentParams[idx] = {
                    ...mapping.fromJS(deepCopy(emptyParams)),
                    type: observable(eachLink.id),
                };
            });

            return finalSubComponentParams;
        }
    }

    private getSiteCustomStyles(): string {
        let styleStr = getCustomStyles(
            this.customizationParams,
            this.uniqueWrapperClass,
            GLOBAL_TILE_ID,
            mapping.toJS(this.customizationParams)
        );

        for (const key in this.subComponents) {
            if (key !== '__ko_mapping__') {
                const subComponentInfo = this.subComponents[key];

                styleStr += getCustomStyles(
                    subComponentInfo,
                    this.uniqueWrapperClass,
                    subComponentInfo?.type?.(),
                    mapping.toJS(this.customizationParams)
                );
            }
        }

        return styleStr;
    }

    private getUpdatedTilesList(jobCategoryList: CategoryTileFromConfig[]): void {
        jobAlertService.getJobFamilies(this.siteNumber).then((jobFamilyList: JobFamilyList[]) => {
            const configData = getTilesData(jobCategoryList);

            configData.forEach((categoryTile: CategoryTilesDisplayContent) => {
                jobFamilyList.forEach((jobFamily: JobFamilyList) => {
                    if (jobFamily.id === categoryTile.jobFamilyIds) {
                        categoryTile.title = jobFamily.jobFamilyName;
                    }
                });
            });

            this.categoryTilesList(configData);
        });
    }

    getSelectedClassName(type: string): string | any {
        switch (type) {
            case 'TILE_STYLE':
                const tileStyle = this.customizationParams.content.tileStyle();

                return `category-tiles--${tileStyle}`;

            case 'TILE_ALIGNMENT':
                const tileVerticalAlignment = this.customizationParams.content.tileVerticalAlignment();

                return `category-tiles--${tileVerticalAlignment}`;

            case 'SELECTED_TILE':
                return (this.customizationParams.selectedSubComponent as unknown as Observable)() ===
                    GLOBAL_TILE_ID
                    ? `global-content-view`
                    : `individual-content-view`;

            default:
                return '';
        }
    }

    private getCategoryTilesStyles(): string {
        return [
            this.getSelectedClassName('TILE_STYLE'),
            this.getSelectedClassName('TILE_BASE_THEME'),
            this.getSelectedClassName('TILE_ALIGNMENT'),
            this.getSelectedClassName('SELECTED_TILE'),
        ].join(' ');
    }

    getTileUrl(url: string): string {
        return !url || isAdminRoute() ? 'javascript:void(0)' : url;
    }

    getClassName(tile: CategoryTilesDisplayContent): string {
        return `category-tile-${tile.jobFamilyIds || ''}`;
    }

    getTileImageCssClass(categoryTile: CategoryTilesDisplayContent): string {
        const index = categoryTile.order || 0;
        const imageColor = DEFAULT_CATEGORY_TILE_IMAGES[index % DEFAULT_CATEGORY_TILE_IMAGES.length];
        const categoryTileClass = this.getClassName(categoryTile);
        const activeTileClass =
            (this.customizationParams.selectedSubComponent as unknown as Observable)() ===
            categoryTile.jobFamilyIds
                ? 'tile-active'
                : '';

        if (categoryTile.tileImageUrl) {
            return `category-tile-image--${categoryTile.jobFamilyIds} ${categoryTileClass} ${activeTileClass}`;
        }

        return `category-tile-image--${imageColor} ${categoryTileClass} ${activeTileClass}`;
    }

    getBackgroundImageCss(): string {
        let cssString = '';

        this.categoryTilesList().forEach((categoryTile) => {
            if (categoryTile.tileImageUrl) {
                cssString += `.category-tile-image--${categoryTile.jobFamilyIds} {
                    background-image: url('${categoryTile.tileImageUrl}') ;
                }\n`;
            }
        });

        return cssString;
    }

    dispose(): void {
        this.isCancelInProgressEventSubscription.detach();
    }
}
