import oj from 'ojs/ojcore';
import cxConfig from 'app/model/config';
import translations from 'app/model/translationsConfig';

function _generateMissingTokens(tokens) {
    return Object.keys(tokens).reduce((str, key) => {
        const tokenKey = key.toUpperCase();

        return `${str} {${tokenKey}}${tokenKey}{${tokenKey}END}`;
    }, '');
}

function _getValue(tokens, tokenKey) {
    let tokenValue = tokens[tokenKey];

    if (typeof tokenValue === 'function') {
        tokenValue = tokenValue();
    }

    if (tokenValue && typeof tokenValue === 'string' && tokenValue.indexOf('data-i18n-token') < 0) {
        tokenValue = tokenValue.replace(/[\u00A0-\u9999<>&]/g, i => `&#${i.charCodeAt(0)};`);
    }

    return tokenValue;
}

function _convertTokenKeysToUpperCase(tokens) {
    return Object.keys(tokens).reduce((result, tokenKey) => {
        const tokenValue = _getValue(tokens, tokenKey);
        const newTokenKey = tokenKey.toUpperCase();

        result[newTokenKey] = tokenValue;

        return result;
    }, {});
}

function _createMissingClosingTokens(tokens, resource) {
    return Object.keys(tokens).reduce((map, tokenKey) => {
        const tokenValue = `${tokens[tokenKey]}`;
        const closingTokenKey = `${tokenKey}END`;

        if (resource.indexOf(closingTokenKey) !== -1) {
            const endTagPosition = tokenValue.lastIndexOf('</');

            map[tokenKey] = tokenValue.substr(0, endTagPosition);
            map[closingTokenKey] = tokenValue.substr(endTagPosition);
        } else {
            map[tokenKey] = tokenValue;
        }

        return map;
    }, {});
}

function _resolveLangFromPage() {
    return document.documentElement.lang;
}

export default function i18n(key, rawTokens, lang) {
    const { siteLang } = cxConfig;
    const pageLang = _resolveLangFromPage();
    const { getResource } = translations;

    let resource = getResource(key, lang || pageLang || siteLang);
    let tokens;

    // Double check to prevent displaying keys when there is no translation
    if (!resource && lang !== siteLang) {
        resource = getResource(key, siteLang || pageLang);
    }

    if (!resource) {
        console.info(`No translation for key: ${key} (${lang || siteLang || pageLang})`);

        resource = key;

        // Sometimes tokens are part of application functionality,
        // so they have to be present even if there is no translation.
        if (rawTokens) {
            resource += _generateMissingTokens(rawTokens);
        }
    }

    if (rawTokens && typeof rawTokens === 'object') {
        tokens = _convertTokenKeysToUpperCase(rawTokens);
        tokens = _createMissingClosingTokens(tokens, resource);
    }

    let translation;

    try {
        translation = oj.Translations.applyParameters(resource, tokens);
    } catch (e) {
        console.error(`Expected tokens for key "${key}" but none were given.`);

        translation = key;
    }

    return translation;
}
