import i18n from 'core/i18n/i18n';
import notificationsInstance from '../component/cx-notifications/cxNotifications';
import cxEvents from 'cx/config/events';

const defaultErrorMessage = `${i18n('general.error-message-header')} ${i18n('general.error-message-hint')}`;

export default {
    success(message, delay) {
        notificationsInstance.push({ type: 'success', message, delay });
    },

    error(message = defaultErrorMessage, delay, autodismiss) {
        notificationsInstance.push({ type: 'error', message, delay, autodismiss });
    },

    successAfterLoaded(message, delay) {
        cxEvents.loaded.addOnce(() => this.success(message, delay));
    },

    errorAfterLoaded(message, delay) {
        cxEvents.loaded.addOnce(() => this.error(message, delay));
    },

    dismissAll() {
        notificationsInstance.removeAll();
    },
};
