import {
    CategoryTileModelObservable,
    CategoryTileModel,
    SOURCE_COLLECTION_CATEGORY_LINKS,
} from 'site-editor/config/categoryTilesTypes';
import { unwrap } from 'knockout';
import { Dictionary } from 'app/types/utils';

export type CategoryTileModelRest = {
    linkNumber: string | undefined;
    linkOrder: number;
    linkText: string;
    linkUrl: string | undefined;
    parentLinkNumber: string | undefined;
    jobFamilyIds: string;
    heroImageUrl: string | undefined;
    tileImageUrl: string | undefined;
    introText: string | undefined;
};

export type IntroTextGAI = {
    jobCategoryTitle: string;
    jobFamilyIds: string;
    siteNumber: string;
    introText: string;
};

export type IntroTextGAIModelRest = {
    jobFamilyIds: string;
    siteNumber: string;
};

export const mapCategoryTileFromRest = (restCategoryTile: CategoryTileModelRest): CategoryTileModel => ({
    linkNumber: restCategoryTile.linkNumber,
    order: restCategoryTile.linkOrder,
    title: restCategoryTile.linkText,
    url: restCategoryTile.linkUrl,
    parentLinkNumber: restCategoryTile.parentLinkNumber,
    heroImageUrl: restCategoryTile.heroImageUrl,
    tileImageUrl: restCategoryTile.tileImageUrl,
    introText: restCategoryTile.introText,
    id: restCategoryTile.jobFamilyIds,
    sourceCollection: SOURCE_COLLECTION_CATEGORY_LINKS,
    pageNumber: '',
    isLoading: false,
});

export const sortCategoryTiles = (
    categoryTile1: CategoryTileModelRest,
    categoryTile2: CategoryTileModelRest
): number => categoryTile1.linkOrder - categoryTile2.linkOrder;

export const mapCategoryTilesFromRest = (restCategoryTiles: CategoryTileModelRest[]): CategoryTileModel[] =>
    restCategoryTiles.sort(sortCategoryTiles).map(mapCategoryTileFromRest);

export const mapCategoryTileToRest = (
    categoryTileModel: CategoryTileModelObservable
): CategoryTileModelRest => {
    const { id, order, title, url, heroImageUrl, tileImageUrl, introText, linkNumber, parentLinkNumber } =
        categoryTileModel;

    return {
        linkOrder: unwrap(order),
        linkText: unwrap(title),
        linkUrl: unwrap(url),
        heroImageUrl: unwrap(heroImageUrl),
        tileImageUrl: unwrap(tileImageUrl),
        introText: unwrap(introText),
        parentLinkNumber: unwrap(parentLinkNumber),
        linkNumber: unwrap(linkNumber),
        jobFamilyIds: unwrap(id),
    };
};

export const mapOrderUpdateToRest = (
    categoryTileModel: CategoryTileModelObservable
): Dictionary<string | number | undefined> => ({
    linkNumber: unwrap(categoryTileModel.linkNumber),
    linkOrder: unwrap(categoryTileModel.order),
});

export const mapCategoryTilesOrderToRest = (
    collection: string,
    categoryTiles: CategoryTileModelObservable[]
): Record<string, unknown> => ({
    [collection]: categoryTiles.map(mapOrderUpdateToRest),
});

export const mapIntroTextGAIFromRest = (introTextGAIModelRest: IntroTextGAI): IntroTextGAI => ({
    introText: introTextGAIModelRest.introText,
    jobFamilyIds: introTextGAIModelRest.jobFamilyIds,
    siteNumber: introTextGAIModelRest.siteNumber,
    jobCategoryTitle: introTextGAIModelRest.jobCategoryTitle,
});

export const mapIntroTextGAIToRest = (siteNumber: string, jobFamilyIds: string): IntroTextGAIModelRest => {
    return {
        jobFamilyIds,
        siteNumber,
    };
};
