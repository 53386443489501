export default class MultiSelectPillFormElementViewModel {

    constructor({ model, filterRepository }) {
        this.filters = model;
        this.filterRepository = filterRepository;
    }

    addFilter(filter) {
        this.filters.push(filter);
    }

    removeFilter(filter) {
        if (this.filterRepository) {
            this.filterRepository.remove(filter);
        }

        this.filters.remove(filter);
    }

    removeAll() {
        if (this.filterRepository) {
            this.filters().forEach(filter => this.filterRepository.remove(filter));
        }

        this.filters.removeAll();
    }

    _handleErrors(error) {
        console.error(error);
    }

}
