import ko from 'knockout';
import keyCodes from 'core/config/keycodes';

/**
 * Executes handler function when one of defined keys is pressed
 *
 * @param {(string|Array.string)} keys - Name of key or an array of names, in camelCase notation, e.g. 'pageDown'
 * @param {keyHandler} handler - The callback that is fired after key is pressed
 *
 * @callback keyHandler
 * @param {viewModel} - knockout viewModel
 * @param {event} - keyDown event
 *
 *
 * @example
 * <input type="text" data-bind="a11y.keysHandler: {keys: ['downArrow', 'enter'], handler: toggle}" />
 *
 * @example
 * <div data-bind="a11y.keysHandler: {keys: 'esc', handler: closeFilters}">
 */
ko.bindingHandlers['a11y.keysHandler'] = {
    init(element, accessor, ignore, viewModel) {
        const { keys } = accessor();
        const handler = accessor().handler.bind(viewModel);
        const keyCodesInt = [];

        function camelCaseToConstantNotation(camelCaseString) {
            return camelCaseString.replace(/([A-Z])/g, '_$1').toUpperCase();
        }

        function handleKeys(event) {
            if (keyCodesInt.indexOf(event.which) > -1) {
                event.stopPropagation();
                // pass model and event to handler to be consistent with knockout event bindings
                handler(viewModel, event);
            }
        }

        function findKeyCode(key) {
            return keyCodes[camelCaseToConstantNotation(key)];
        }

        function findAndPushKeyCode(key) {
            const keyCode = findKeyCode(key);

            if (keyCode) {
                keyCodesInt.push(keyCode);
            }
        }

        if (!Array.isArray(keys)) {
            findAndPushKeyCode(keys);
        } else {
            keys.forEach((key) => {
                findAndPushKeyCode(key);
            });
        }

        element.addEventListener('keydown', handleKeys);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('keydown', handleKeys);
        });
    },
};
