import { isObservable } from 'knockout';

const getSubItems = (items, property) => {
    switch (property) {
        case 'rows':
            return (isObservable(items) ? items() : items).map(item => getSubItems(item[property], 'columns')).flat();
        case 'columns':
            return (isObservable(items) ? items() : items).map(item => getSubItems(item[property], 'components')).flat();
        case 'components':
            return (isObservable(items) ? items() : items).map(item => getSubItems(item[property], '')).flat();
        default:
            return (isObservable(items) ? items() : items);
    }
};

export const getPageComponents = pageSections =>
    getSubItems(pageSections, 'rows');

// TODO: make investigation on when component type can be absent
export const getPageComponentByType = (pageSections, componentType) =>
    getPageComponents(pageSections)
        .filter(component => component?.type === componentType)
        .pop();
