import { Dictionary } from 'app/types/utils';

export type RouterQuery = {
    lastSelectedFacet?: string;
    mode?: string;
    selectedLocationsFacet?: string;
    selectedPostingDatesFacet?: string;
    selectedTitlesFacet?: string;
    selectedWorkplaceTypesFacet?: string;
    selectedOrganizationsFacet?: string;
    keyword?: string;
    location?: string;
    locationId?: string;
    latitude?: number;
    longitude?: number;
    zipcode?: string;
    locationLevel?: LocationLevel;
    radius?: string;
    radiusUnit?: DistanceUnit;
    workLocationCountryCode?: string;
    sortBy?: string;
};

export type SearchContext = 'jobs' | 'events';

export type SearchRoute =
    | 'search'
    | 'search-events'
    | 'site-editor-search-editor'
    | 'site-editor-content-translations-lang'
    | 'job-preview'
    | 'job-formatting-preview'
    | 'event-preview'
    | 'fake-job-details'
    | 'site-editor-content'
    | 'cc-page';

export type SearchRouteOnly = Exclude<
    SearchRoute,
    | 'site-editor-search-editor'
    | 'job-preview'
    | 'job-formatting-preview'
    | 'event-preview'
    | 'site-editor-content-translations-lang'
    | 'fake-job-details'
    | 'site-editor-content'
    | 'cc-page'
>;

export const searchRouteMap: Record<SearchContext, SearchRoute> = {
    jobs: 'search',
    events: 'search-events',
};

export const searchContextMap: Record<SearchRouteOnly, SearchContext> = {
    search: 'jobs',
    'search-events': 'events',
};

export const searchRouteAndPreviewMap: Dictionary<SearchRoute> = {
    jobs: 'search',
    jobPreview: 'job-preview',
    events: 'search-events',
    eventPreview: 'event-preview',
};

export type LocationLevel = 'city' | 'state' | 'country';

export type DistanceUnit = 'MI' | 'KM' | null;

export type EventFormat = 'In Person' | 'Virtual';

export type EventShape = Dictionary<unknown> & {
    id: string;
    title: string;
    startDate: string;
    endDate: string;
    eventDate: string;
    bannerImageUrl: string | null;
    shortDescriptionStr: string | null;
    location: string | null;
    eventFormat: EventFormat;
    distance?: string;
    registrationClosedFlag?: boolean;
    isAlreadyRegistered?: boolean;
    isConfirmed?: boolean;
    registrationInfo?: RegistrationInfo;
    isCandidateDisqualified?: boolean;
};

export type RegistrationInfo = {
    registrationPhase: RegistrationPhase;
};

export type RegistrationPhase = 'REGISTERED' | 'ATTENDED';

export type SearchRouteNoSearchResults = 'search' | 'search-events';

export type noSearchResultsParams = {
    context: SearchContext;
    ctaRoute: SearchRouteNoSearchResults;
    dataQaAttr: string;
    beforeRedirect: () => Promise<boolean>;
};

export type SearchResultContextComponent = 'search-job-results' | 'search-event-results' | null;

export type StorageParams = 'storage-events-params' | 'storage-jobs-params';

export const SEARCH_RESULTS_STRINGS = {
    SEARCH_ROUTE_JOBS: 'search',
    SEARCH_ROUTE_EVENTS: 'search-events',
    SEARCH_CONTEXT_JOBS: 'jobs',
    SEARCH_CONTEXT_EVENTS: 'events',
    STORAGE_EVENTS_PARAMS: 'storage-events-params',
    STORAGE_JOBS_PARAMS: 'storage-jobs-params',
} as const;

export type RouterParams = {
    query: Dictionary<unknown>;
};

export type SortOptionCode = 'POSTING_DATES' | 'RELEVANCY' | 'DISTANCE' | 'EVENT_DATE';

export type SortOptionOrder = 'ASC' | 'DESC';

export type SortOption = {
    code: SortOptionCode;
    order: SortOptionOrder;
    name: string;
};

export type DisplayStyle = 'list' | 'tile' | 'toggleListMap' | 'toggleTileMap';

export type JobInfoSeparator = 'dot' | 'line' | 'none';

export type JobInfoTagsConfiguration = 'inHeader' | 'inMainContent';

export type JobInfoDisplay = 'labelValue' | 'iconValue';

export type DisplayStyleWithoutToggle = Exclude<DisplayStyle, 'toggleListMap' | 'toggleTileMap'>;

export type ComponentClass = 'jobs-grid' | 'jobs-list';
