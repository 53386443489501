import { observable, observableArray } from 'knockout';

export default class InterviewScheduleDetail {

    constructor(data = {}) {
        this.scheduleId = data.scheduleId;
        this.autoCalendarSlot = data.autoCalendarSlot;
        this.preScheduleMessage = observable(data.preScheduleMessage);
        this.interviewGroups = observableArray(data.interviewGroups);
    }

}