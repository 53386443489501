import { observableArray, observable } from 'knockout';
import ValidatableFormElement from 'core/form/element/ValidatableElement';

export default class MultiSelectListFormElement extends ValidatableFormElement {

    constructor(...args) {
        super(...args);
        this.component('multiselectlist-form-element');
        this.rawValue = observable(null);
        this.options = observableArray();
    }

    _removeOption(option) {
        const value = this.value();

        const optionToRemove = value
            .find(lookupCode => lookupCode === option.lookupCode);

        value.splice(value.indexOf(optionToRemove), 1);

        this.value(value);
        this._disableOptions();
    }

    _disableOptions() {
        const selectedIds = this.value();
        const options = this.options();

        options
            .forEach(option => (option.disabled = selectedIds.includes(option.lookupCode)));

        this.options(options);
    }

}