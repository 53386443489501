import mapping from 'knockout-mapping';

function PreuploadFileAttachment(fileData) {
    const data = {
        fileName: fileData.name,
        fileSize: fileData.size,
        mimeType: fileData.type,
        categoryId: fileData.categoryId,
        blockId: fileData.blockId,
    };

    mapping.fromJS({
        fileName: undefined,
        fileSize: undefined,
        fileContents: undefined,
        categoryId: undefined,
    }, {}, this);

    mapping.fromJS(data, {}, this);
}

export default PreuploadFileAttachment;
