import { pureComputed } from 'knockout';
import {
    JobDetailsSimilarJobsItemViewModel,
    SearchResultItemViewModelProps,
} from './JobDetailsSimilarJobsItemViewModel';
import appConfig from 'app/model/config';
import { noOperation } from 'core/utils/noOperation';

export default class JobDetailsSimilarJobsItemStaticViewModel extends JobDetailsSimilarJobsItemViewModel {
    constructor(params: SearchResultItemViewModelProps) {
        super(params);
        this.isFavourite = pureComputed(() => false);
        this.getJobUrl = noOperation;
        this.openJob = noOperation;
    }

    toggleFavourite(): void {
        this.isFavourite(!this.isFavourite());
    }

    shouldShowDistance(): boolean {
        const isCustomizationDistanceEnabled = this.hasAdditionalInformation('distance');

        return isCustomizationDistanceEnabled && appConfig.isDistanceCalculationEnabled;
    }
}
