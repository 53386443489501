import ko from 'knockout';
import mapping from 'knockout-mapping';
import basicInformationMapper from 'apply-flow/module/personal-information-basic/mapper/basicInformation';
import addressMapper from 'apply-flow/module/personal-information-address/mapper/address';
import fileAttachmentMapper from 'apply-flow/module/file-upload/mapper/fileAttachment';
import siteLinkMapper from 'apply-flow/module/candidate-links/mapper/siteLink';
import profileItemMapper from 'apply-flow/module/profile-items/mapper/profileItem';
import unverifiedService from 'candidate-verification/service/unverified';
import { applicationDraft } from 'apply-flow/service/applicationDraftInstance';
import candidateModel from '../model/candidate';
import { SMS } from 'candidate-verification/config/verificationMethods';
import { addressBackupInstance } from './addressBackup';

function _mapFromRest(restCandidate) {
    const allProfileItems = (restCandidate.licensesAndCertifications || [])
        .concat(restCandidate.workPreferences || [])
        .concat(restCandidate.educations || [])
        .concat(restCandidate.workExperiences || [])
        .concat(restCandidate.languages || []);

    return {
        id: restCandidate.candidateNumber,
        basicInformation: basicInformationMapper.mapFromRest(restCandidate),
        address: addressMapper.mapFromRest(restCandidate),
        profileItems: allProfileItems,
        dateOfBirth: restCandidate.dateOfBirth,
        isDateOfBirthInProfile: Boolean(restCandidate.dateOfBirth),
    };
}

function _removeIdFromAttachment(restAttachment) {
    delete restAttachment.id;

    return restAttachment;
}

function _clearIdFromLink(restLink) {
    restLink.id(undefined);
}

function _restorePhoneNumber(candidate) {
    const { countryCode, areaCode, number } = mapping.toJS(candidateModel.basicInformation.phone);

    candidate.basicInformation.phone.countryCode(countryCode);
    candidate.basicInformation.phone.areaCode(areaCode);
    candidate.basicInformation.phone.number(number);
}

function _restoreCandidateData(candidate) {
    candidate.basicInformation.verificationMethod(
        candidateModel.basicInformation.verificationMethod(),
    );

    if (candidate.basicInformation.verificationMethod() === SMS) {
        _restorePhoneNumber(candidate);
    }

    candidate.basicInformation.phonePreferredFlag(
        candidateModel.basicInformation.phonePreferredFlag(),
    );

    candidate.basicInformation.emailPreferredFlag(
        candidateModel.basicInformation.emailPreferredFlag(),
    );

    candidate.basicInformation.emailVerifiedFlag(
        candidateModel.basicInformation.emailVerifiedFlag(),
    );

    candidate.basicInformation.phoneVerifiedFlag(
        candidateModel.basicInformation.phoneVerifiedFlag(),
    );

    candidate.basicInformation.isVerified(
        candidateModel.basicInformation.isVerified(),
    );
}

export default {
    mapCandidateToRest(candidateToRest) {
        const candidate = mapping.toJS(candidateToRest);


        if (unverifiedService.isKnownUnverifiedCandidate()) {
            candidate.id = unverifiedService.getUnverifiedCandidateId();
        }

        const restCandidate = {
            candidateNumber: candidate.id,
        };

        const basicInformations = basicInformationMapper.mapToRest(candidate.basicInformation, candidateToRest.isNew());
        const address = addressMapper.mapToRest(candidate.address);

        ko.utils.extend(restCandidate, basicInformations);
        ko.utils.extend(restCandidate, address);

        return restCandidate;
    },

    mapCandidateFromRest(restCandidate) {
        const candidate = _mapFromRest(restCandidate);

        candidate.profileItems = profileItemMapper.mapProfileItemsFromRest({
            items: candidate.profileItems,
        });

        if (restCandidate.cwkCandidateFlag) {
            try {
                addressBackupInstance.set(mapping.toJS(candidate.address));
            } catch (e) {
                console.error(e);
            }
        }

        return candidate;
    },

    mapCandidateFromImport(restCandidate) {
        const candidate = {
            id: restCandidate.candidateNumber,
            basicInformation: basicInformationMapper.mapFromRest(restCandidate),
            address: addressMapper.mapFromRest(restCandidate),
        };

        if (!applicationDraft) {
            restCandidate.attachments = restCandidate.attachments
                .map(attachment => _removeIdFromAttachment(attachment));
        }

        _restoreCandidateData(candidate);

        const attachmentsMapper = applicationDraft
            ? fileAttachmentMapper.mapDraftFileAttachmentsFromRest.bind(fileAttachmentMapper)
            : fileAttachmentMapper.mapFileAttachmentsFromRest.bind(fileAttachmentMapper);

        candidate.attachments = attachmentsMapper({
            items: restCandidate.attachments,
        });

        candidate.linkedSites = siteLinkMapper.mapSiteLinksFromRest({
            items: restCandidate.attachments,
        });

        candidate.linkedSites.forEach(_clearIdFromLink);

        candidate.licensesAndCertificates =
            profileItemMapper.mapProfileItemsFromImport(restCandidate.licensesAndCertifications);

        candidate.workPreferences = profileItemMapper.mapProfileItemsFromImport(restCandidate.workPreferences);
        candidate.education = profileItemMapper.mapProfileItemsFromImport(restCandidate.educations);
        candidate.experience = profileItemMapper.mapProfileItemsFromImport(restCandidate.workExperiences);
        candidate.languages = profileItemMapper.mapProfileItemsFromImport(restCandidate.languages);
        candidate.skills = profileItemMapper.mapProfileItemsFromImport(restCandidate.skills);

        return candidate;
    },

    mapCandidateLastModificationDateToRest({ id }) {
        return {
            candidateNumber: id(),
            action: 'updateclmd',
        };
    },
};