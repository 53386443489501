import { Observable, observable, ObservableArray, observableArray } from 'knockout';
import { MapLocationCoordinateDetails } from 'ce-common/module/maps/config/types';

export class MarkerSearchResultsModel {
    results: ObservableArray<MapLocationCoordinateDetails>;
    requisitionIdList: ObservableArray<string>;
    totalCount: Observable<number>;
    appending: Observable<boolean>;
    hasMore: Observable<boolean | null>;
    offset: Observable<number>;

    constructor() {
        this.results = observableArray();
        this.requisitionIdList = observableArray();
        this.totalCount = observable(0);
        this.appending = observable<boolean>(false);
        this.hasMore = observable<boolean | null>(false);
        this.offset = observable(0);
    }
}
