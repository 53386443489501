import flowService from 'apply-flow/service/flow';
import reduceBlockDataFactory from 'apply-flow/service/draft-data/ReduceBlockDataFactory';
import { ORA_MISC_DOCUMENTS } from '../../enum/blockTypes';

export default class DraftDataProcessor {

    reduceDraftDataByFlow(draftData) {
        const blocks = new Map();
        const flow = flowService.get();

        this._getAllBlocksFromFlow(flow).forEach((block) => {
            if (blocks.has(block.code)) {
                const ids = reduceBlockDataFactory.getSectionIdentifier(block);

                const blockSections = [
                    ...blocks.get(block.code),
                    ...ids,
                ];

                blocks.set(block.code, blockSections);
            } else {
                blocks.set(block.code, reduceBlockDataFactory.getSectionIdentifier(block));
            }
        });

        this._reduceDraftData(flow, blocks, draftData);

        return draftData;
    }

    _getAllBlocksFromFlow({ sections }) {
        const allBlocks = [];

        sections
            .forEach(({ pages }) => pages.forEach(({ blocks }) => blocks.forEach(block => allBlocks.push(block))));

        return allBlocks;
    }

    _reduceDraftData(flow, flowBlocks, draftData) {
        this._handleTimeline(flowBlocks);

        this._handleBasicBlocks(flowBlocks, draftData);
        this._handleMultiBlocks(flowBlocks, draftData);
        this._handleQuestionnaire(flowBlocks, draftData);
        this._handleRegulatoryResponses(flowBlocks, draftData);
        this._handleExtraInformation(flowBlocks, draftData);
        this._handleAttachments(flowBlocks, draftData);
    }

    _handleQuestionnaire(blocks, { candidate }) {
        const blockMap = reduceBlockDataFactory.getQuestionnaireBlockReduction();

        blockMap.forEach((value, key) => {
            if (blocks.has(key)) {
                value(candidate, blocks.get(key));
            } else {
                candidate.questionnaires = [];
            }
        });
    }

    _handleBasicBlocks(blocks, draftData) {
        const basicBlockMap = reduceBlockDataFactory.getBasicBlocksReduction();

        basicBlockMap.forEach((value, key) => {
            if (!blocks.has(key)) {
                value(draftData);
            }
        });
    }

    _handleMultiBlocks(blocks, { candidate }) {
        const multiBlockMap = reduceBlockDataFactory.getMultiBlocksReduction();

        multiBlockMap.forEach((value, key) => {
            if (!blocks.has(key)) {
                candidate[value.field] = [];

                return;
            }

            value.operation(candidate, blocks.get(key), value.field);
        });
    }

    _handleTimeline(blocks) {
        reduceBlockDataFactory.assignIdentifiersFromTimeline(blocks);
    }

    _handleRegulatoryResponses(blocks, { candidate }) {
        const regulatoryBlocks = reduceBlockDataFactory.getRegulatoryBlockReduction();

        const identifiers = [];

        regulatoryBlocks.forEach((value, key) => {
            if (blocks.has(key)) {
                identifiers.push(...blocks.get(key));
            }
        });

        if (identifiers.length) {
            const [[, reduce]] = regulatoryBlocks;

            reduce(candidate, identifiers);
        } else {
            candidate.regulatoryResponses = [];
        }
    }

    _handleAttachments(blocks, draftData) {
        const blockMap = reduceBlockDataFactory.getAttachmentBlockReduction();

        blockMap.forEach((value, key) => {
            if (!blocks.has(key)) {
                value(draftData, blocks.get(key) || []);
            }

            if (blocks.has(key) && key === ORA_MISC_DOCUMENTS) {
                value(draftData, blocks.get(key));
            }
        });
    }

    _handleExtraInformation(blocks, { candidate }) {
        const blockMap = reduceBlockDataFactory.getExtraInformationBlockReduction();

        blockMap.forEach((value, key) => {
            if (blocks.has(key)) {
                value(candidate, blocks.get(key));
            } else {
                candidate.extraInformation = [];
            }
        });
    }

}
