import i18n from 'core/i18n/i18n';
import formBuilder from 'apply-flow/module/tile-profile-items/form/tileFormBuilder';
import SkillFormTileStrategy from '../model/SkillFormTileStrategy';

export default Object.create(formBuilder, {
    createForm: {
        value(config) {
            const form = formBuilder.createForm(SkillFormTileStrategy, config).bindComponent('skill-form');

            this._requirePositiveIntegerFrom(form.getElement('yearsOfExperience'));

            form.legend(i18n('apply-flow.profile-item.add-skill-button'));

            return form;
        },
    },

    _requirePositiveIntegerFrom: {
        value(formElement) {
            if (!formElement) {
                return;
            }

            formElement.addValidator('positiveInteger');
        },
    },
});