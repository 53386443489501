import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';

export const getEventUrl = (eventId: string): string => {
    return absoluteRouter.interpolate({}, 'event-details', { eventId });
};

export const openEvent = (eventId: string): void => {
    router.go('event-preview', { eventId });
};
