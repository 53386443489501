import { getItemActiveSelector } from 'minimal/config/utils';

type SearchEventResultField =
    | 'root'
    | 'container'
    | 'item'
    | 'itemOnHover'
    | 'itemTitle'
    | 'itemTitleOnHover'
    | 'eventInfo'
    | 'eventInfoOnHover'
    | 'itemSubHeader'
    | 'itemSubHeaderOnHover'
    | 'itemDescription'
    | 'itemDescriptionOnHover'
    | 'itemSortBy'
    | 'itemSortOptions'
    | 'itemSelectedSortValue'
    | 'itemSelectedSortOption'
    | 'itemIcon'
    | 'itemIconOnHover'
    | 'itemSortByIcon'
    | 'itemSortByIconOnHover'
    | 'itemSortByDropdown'
    | 'tileHeader'
    | 'eventIconsStyles'
    | 'eventIconsStylesOnHover'
    | 'eventTagBox'
    | 'eventTagBoxOnHover'
    | 'alreadyAppliedTagBox'
    | 'alreadyAppliedTagBoxOnHover'
    | 'tileHeaderOnHover'
    | 'defaultToggleIconStyles'
    | 'defaultToggleIconStylesOnHover'
    | 'selectedToggleIconStyles'
    | 'selectedToggleIconStylesOnHover';

type SearchEventResultGeneralSelector = Record<SearchEventResultField, string>;

type SearchEventResultSelectors = {
    list: Omit<
        SearchEventResultGeneralSelector,
        | 'tileHeader'
        | 'tileHeaderOnHover'
        | 'defaultToggleIconStyles'
        | 'defaultToggleIconStylesOnHover'
        | 'selectedToggleIconStyles'
        | 'selectedToggleIconStylesOnHover'
    > & {
        tileHeader: null;
        tileHeaderOnHover: null;
        defaultToggleIconStyles: null;
        defaultToggleIconStylesOnHover: null;
        selectedToggleIconStyles: null;
        selectedToggleIconStylesOnHover: null;
    };
    tile: Omit<
        SearchEventResultGeneralSelector,
        | 'itemIcon'
        | 'itemIconOnHover'
        | 'defaultToggleIconStyles'
        | 'defaultToggleIconStylesOnHover'
        | 'selectedToggleIconStyles'
        | 'selectedToggleIconStylesOnHover'
    > & {
        itemIcon: null;
        itemIconOnHover: null;
        defaultToggleIconStyles: null;
        defaultToggleIconStylesOnHover: null;
        selectedToggleIconStyles: null;
        selectedToggleIconStylesOnHover: null;
    };
};

export const getSearchEventResultSelectors = (wrapperSelector: string): SearchEventResultSelectors => {
    return {
        list: {
            root: `${wrapperSelector} .events-list`,
            container: `${wrapperSelector} .events-list__list, ${wrapperSelector} .events-list__header`,
            item: `${wrapperSelector} .event-list-item`,
            itemOnHover: getItemActiveSelector(`${wrapperSelector} .event-list-item`, ``),
            itemTitle: `${wrapperSelector} .event-tile__title`,
            itemTitleOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-list-item`,
                `.event-tile__title`
            ),
            eventInfo: `${wrapperSelector} .event-info, ${wrapperSelector} .event-info::after`,
            eventInfoOnHover: [
                getItemActiveSelector(`${wrapperSelector} .event-list-item`, `.event-info`),
                getItemActiveSelector(`${wrapperSelector} .event-list-item`, `.event-info::after`),
            ].join(', '),
            itemSubHeader: `${wrapperSelector} .event-tile__subheader`,
            itemSubHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-list-item`,
                `.event-tile__subheader`
            ),
            itemDescription: `${wrapperSelector} .event-list-item__description`,
            itemDescriptionOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-list-item`,
                ` .job-list-item__description`
            ),
            itemSortBy: `${wrapperSelector} .search-results-sorting__label`,
            itemSortOptions: `${wrapperSelector} .search-results-sorting__selector-item--button`,
            itemSelectedSortValue: `${wrapperSelector} .search-results-sorting__selector`,
            itemSelectedSortOption: `${wrapperSelector} .search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
            itemIcon: `${wrapperSelector} .event-list-item__show-icon::before`,
            itemIconOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-list-item`,
                `.event-list-item__show-icon::before`
            ),
            itemSortByIcon: `${wrapperSelector} .search-results-sorting__selector-icon, ${wrapperSelector} .search-results-sorting__selector-mobile-icon`,
            itemSortByIconOnHover: [
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-icon'
                ),
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-mobile-icon'
                ),
            ].join(', '),
            itemSortByDropdown: `${wrapperSelector} .search-results-sorting__selector-menu`,
            eventIconsStyles: `${wrapperSelector} .event-tag__icon::before`,
            eventIconsStylesOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-list-item`,
                '.event-tag__icon::before'
            ),
            eventTagBox: `${wrapperSelector} .event-tag`,
            eventTagBoxOnHover: getItemActiveSelector(`${wrapperSelector} .event-list-item`, '.event-tag'),
            alreadyAppliedTagBox: `${wrapperSelector} .event-status-tag`,
            alreadyAppliedTagBoxOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-list-item`,
                '.event-status-tag'
            ),
            tileHeader: null,
            tileHeaderOnHover: null,
            defaultToggleIconStyles: null,
            defaultToggleIconStylesOnHover: null,
            selectedToggleIconStyles: null,
            selectedToggleIconStylesOnHover: null,
        },
        tile: {
            root: `${wrapperSelector} .events-grid`,
            container: `${wrapperSelector} .events-grid__list, ${wrapperSelector} .events-list__header`,
            item: `${wrapperSelector} .event-grid-item`,
            itemOnHover: getItemActiveSelector(`${wrapperSelector} .event-grid-item`, ``),
            itemTitle: `${wrapperSelector} .event-tile__title`,
            itemTitleOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-grid-item`,
                `.event-tile__title`
            ),
            eventInfo: `${wrapperSelector} .event-info, ${wrapperSelector} .event-info::after`,
            eventInfoOnHover: [
                getItemActiveSelector(`${wrapperSelector} .event-grid-item`, `.event-info`),
                getItemActiveSelector(`${wrapperSelector} .event-grid-item`, `.event-info::after`),
            ].join(', '),
            itemSubHeader: `${wrapperSelector} .event-tile__subheader`,
            itemSubHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-grid-item`,
                `.event-tile__subheader`
            ),
            itemDescription: `${wrapperSelector} .event-grid-item__description`,
            itemDescriptionOnHover: getItemActiveSelector(
                `${wrapperSelector} .job-grid-item__description`,
                `.job-list-item__content`
            ),
            itemSortBy: `${wrapperSelector} .search-results-sorting__label`,
            itemSortOptions: `${wrapperSelector} .search-results-sorting__selector-item--button`,
            itemSelectedSortValue: `${wrapperSelector} .search-results-sorting__selector`,
            itemSelectedSortOption: `${wrapperSelector} .search-results-sorting__selector-item--button.search-results-sorting__selected-sort-option`,
            itemIcon: null,
            itemIconOnHover: null,
            itemSortByIcon: `${wrapperSelector} .search-results-sorting__selector-icon, ${wrapperSelector} .search-results-sorting__selector-mobile-icon`,
            itemSortByIconOnHover: [
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-icon'
                ),
                getItemActiveSelector(
                    `${wrapperSelector} .search-results-sorting__selector`,
                    '.search-results-sorting__selector-mobile-icon'
                ),
            ].join(', '),
            itemSortByDropdown: `${wrapperSelector} .search-results-sorting__selector-menu`,
            eventIconsStyles: `${wrapperSelector} .event-tag__icon::before`,
            eventIconsStylesOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-grid-item`,
                '.event-tag__icon::before'
            ),
            eventTagBox: `${wrapperSelector} .event-tag`,
            eventTagBoxOnHover: getItemActiveSelector(`${wrapperSelector} .event-grid-item`, '.event-tag'),
            alreadyAppliedTagBox: `${wrapperSelector} .event-status-tag`,
            alreadyAppliedTagBoxOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-grid-item`,
                '.event-status-tag'
            ),
            tileHeader: `${wrapperSelector} .event-grid-item__header`,
            tileHeaderOnHover: getItemActiveSelector(
                `${wrapperSelector} .event-grid-item`,
                `.event-grid-item__header`
            ),
            defaultToggleIconStyles: null,
            defaultToggleIconStylesOnHover: null,
            selectedToggleIconStyles: null,
            selectedToggleIconStylesOnHover: null,
        },
    };
};
