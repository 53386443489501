export const PROFILE_ITEMS_MAP = {
    103: 'licensesAndCertificates',
    106: 'education',
    109: 'languages',
    117: 'workPreferences',
    129: 'experience',
    134: 'skills',
};

export const PROFILE_ITEMS_KEY_MAP = {
    103: 'certificationId',
    106: 'educationId',
    109: 'languageId',
    117: 'workPreferenceId',
    129: 'previousEmploymentId',
    134: 'skillId',
};