import ko from 'knockout';
import keycodes from 'core/config/keycodes';

const DIGITS_ONLY_REGEX = /\d/g;
const NO_DIGITS_REGEX = /\D/g;

function isValueNotNumber(value) {
    return value < '0' || value > '9';
}

function notifyChange(event) {
    event.target.dispatchEvent(new Event('change'));
}

ko.bindingHandlers.formatPhoneNumber = {
    init(element, valueAccessor) {
        const { format } = valueAccessor();

        let lastKeyCode;

        function isBackKey() {
            return lastKeyCode === keycodes.BACKSPACE || lastKeyCode === keycodes.DELETE;
        }

        function formatWithPreservedCaret() {
            const matchedDigits = element.value.substring(0, element.selectionStart).match(DIGITS_ONLY_REGEX);

            const beforeFormat = {
                caretPosition: element.selectionStart,
                leadingDigitsCount: (matchedDigits || []).length,
            };

            element.value = format();

            const adjustedCaretPosition = caretPositionAfterFormat(beforeFormat);

            element.setSelectionRange(adjustedCaretPosition, adjustedCaretPosition);
        }

        function caretPositionAfterFormat(beforeFormat) {
            let baseCaretPosition = beforeFormat.caretPosition;

            baseCaretPosition = moveCaretAfterNotNumber(baseCaretPosition);

            const matchedNonDigits = element.value.substring(0, baseCaretPosition).match(NO_DIGITS_REGEX);
            const leadingNonDigitsCount = (matchedNonDigits || []).length;

            let caretPosition = leadingNonDigitsCount + beforeFormat.leadingDigitsCount;

            caretPosition = moveCaretAfterNotNumber(caretPosition);

            return caretPosition;
        }

        function moveCaretAfterNotNumber(caretPosition) {
            let newCaretPosition = caretPosition;

            while (!isBackKey() && isValueNotNumber(element.value[newCaretPosition])) {
                newCaretPosition++;
            }

            return newCaretPosition;
        }

        element.addEventListener('keydown', (event) => {
            lastKeyCode = event.keyCode;
        });

        element.addEventListener('input', (event) => {
            formatWithPreservedCaret(event.target.value);
        });

        // Fix Edge (EdgeHTML) issue, where programatically changing value of field
        // is not firing 'change' event
        element.addEventListener('blur', notifyChange);

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeEventListener('blur', notifyChange);
        });
    },
};