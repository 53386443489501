import { observable, pureComputed } from 'knockout';
import Form from 'core/form/Form';
import CustomJsValidator from './validator/CustomJs';

export default class ValidatableForm extends Form {

    constructor(...args) {
        super(...args);

        this.isValidated = observable(false);
        this.isValidationInProgress = observable(false);
        this.isValid = pureComputed(this._isValid, this);
        this.getErrorMessages = pureComputed(this._getErrorMessages, this);
        this.getErrorCount = pureComputed(this._getErrorCount, this);
        this.isEmpty = pureComputed(this._isEmpty, this);
    }

    validate() {
        this.isValidated(true);
        this.isValidationInProgress(true);

        return Promise.all(this.elements().map(element => element.validate()))
            .then((elementsValidity) => {
                this.isValidationInProgress(false);

                return elementsValidity.reduce((isValid, elementValidity) => isValid && elementValidity, true);
            });
    }

    enableImmediateValidation() {
        this.elements().forEach((element) => {
            element.validateImmediately(true);
        });
    }

    registerCustomJsValidation(runCustomJsValidation) {
        this._elements().forEach((element) => {
            if (element.addValidator) {
                element.addValidator(new CustomJsValidator({ runCustomJsValidation }));
            }
        });
    }

    _isValid() {
        return this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .every(element => !element.isValid || (element.isValid && element.isValid()));
    }

    _isEmpty() {
        return this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .every(element => !element.value());
    }

    _getErrorMessages() {
        return this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .filter(element => !element.isValid())
            .map(element => element.getErrorMessage());
    }

    _getErrorCount() {
        let invalidElements = this.elements()
            .filter(element => !element.isHidden || (element.isHidden && !element.isHidden()))
            .filter(element => !element.isValid());

        if (!this.isValidated()) {
            invalidElements = invalidElements.filter(element => element.isDirty());
        }

        return invalidElements.length;
    }

}
