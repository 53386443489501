import { observableArray, unwrap, isObservable, pureComputed } from 'knockout';
import ArrayDataProvider from 'ojs/ojarraydataprovider';
import { CustomDataProvider } from './model/CustomDataProvider';
import i18n from 'core/i18n/i18n';

const DEFAULT_LABEL_ATTRIBUTE = 'label';
const DEFAULT_VALUE_ATTRIBUTE = 'value';

type SelectSingleValue = string | number;
type Callback = () => void;
type AttributeNames = {
    value?: string;
    label?: string;
};

type SelectSingleOption = {
    label: string;
    value: SelectSingleValue;
};

type ListAttributes = (
    labelAttribute: string,
    valueAttribute: string
) => {
    'aria-label': string;
    'data-title': string;
    lang?: string;
};

type Params = {
    ariaLabel?: string;
    ariaDescribedBy?: string;
    ariaListViewLabelledBy?: string;
    labelledBy?: string;
    labelEdge?: string;
    labelHint?: string;
    className?: string;
    name?: string;
    value: SelectSingleValue;
    data: SelectSingleOption[];
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    placeholder?: string;
    optionKeys?: AttributeNames;
    optionListClass?: string;
    itemTextAttribute?: string;
    withEmptyOption: boolean;
    optionsAriaLabel: ko.Observable<string>;
    getOptions: any;
    listLangAttribute: boolean;
    includeDisabledOptions?: boolean;

    onFocus?: Callback;
    onBlur?: Callback;
    onValueChanged?: Callback;
    onValueAction?: Callback;
};

const ID_PREFIX = 'single-select-';
let lastId = 0;

export default class ViewModel {
    id: string;
    name?: string;
    className?: string;
    classNameList?: any;
    ariaLabel?: string;
    ariaDescribedBy?: string;
    ariaListViewLabelledBy?: string;
    labelledBy?: string;
    labelEdge?: string;
    labelHint?: string;
    value: SelectSingleValue;
    dataProvider: any;
    labelAttribute: string;
    valueAttribute: string;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    placeholder?: string;
    optionListClass?: string;
    itemTextAttribute?: string;
    withEmptyOption?: boolean;
    optionsAriaLabel: ko.Observable<string>;
    listLangAttribute?: boolean;
    listAttributes: ListAttributes;
    includeDisabledOptions?: boolean;

    onBlur?: Callback;
    onFocus?: Callback;
    onValueChanged?: Callback;
    onValueAction?: Callback;

    constructor({
        name,
        className,
        ariaLabel,
        ariaDescribedBy,
        ariaListViewLabelledBy,
        labelledBy,
        labelEdge,
        labelHint,
        value,
        data,
        disabled,
        readonly,
        required,
        placeholder,
        optionKeys,
        listLangAttribute,
        optionListClass,
        itemTextAttribute,
        withEmptyOption,
        getOptions,
        optionsAriaLabel,
        includeDisabledOptions,

        onFocus,
        onBlur,
        onValueChanged,
        onValueAction,
    }: Params) {
        const attributeNames: AttributeNames = unwrap(optionKeys) || {};

        this.id = this.getNextId();
        this.name = name;
        this.ariaLabel = ariaLabel;
        this.ariaDescribedBy = ariaDescribedBy;
        this.ariaListViewLabelledBy = ariaListViewLabelledBy;
        this.labelledBy = labelledBy;
        this.labelEdge = labelEdge;
        this.labelHint = labelHint;
        this.listLangAttribute = listLangAttribute;
        this.value = value;
        this.disabled = disabled;
        this.readonly = readonly;
        this.required = required;
        this.placeholder = placeholder;
        this.optionListClass = optionListClass;
        this.labelAttribute = attributeNames.label || DEFAULT_LABEL_ATTRIBUTE;
        this.valueAttribute = attributeNames.value || DEFAULT_VALUE_ATTRIBUTE;
        this.itemTextAttribute = itemTextAttribute || this.labelAttribute;
        this.className = className || 'cx-select-single';
        this.withEmptyOption = unwrap(withEmptyOption);
        this.optionsAriaLabel = optionsAriaLabel;
        this.includeDisabledOptions = includeDisabledOptions || false;

        this.listAttributes = (labelAttribute: string, valueAttribute: string) => ({
            'data-title': labelAttribute,
            'aria-label': this.getAriaLabel(labelAttribute),
            ...(this.listLangAttribute && { lang: valueAttribute }),
        });

        let optionsData: any = unwrap(data);

        const EMPTY_OPTION = {
            [this.labelAttribute]: '',
            [this.valueAttribute]: '',
        };

        if (this.withEmptyOption && optionsData) {
            optionsData = [EMPTY_OPTION, ...optionsData];
        }

        optionsData = this.filterDisableOptions(optionsData);

        const dataObservable = observableArray(optionsData);

        if (isObservable(data)) {
            data.subscribe((newData) => {
                newData = this.filterDisableOptions(newData);
                setTimeout(() => dataObservable(newData), 0);
            });
        }

        const arrayDataProvider = new ArrayDataProvider(dataObservable, {
            keyAttributes: attributeNames.value || DEFAULT_VALUE_ATTRIBUTE,
        });

        if (getOptions) {
            const callback = (params: any) => {
                const context: any = {};

                if (params?.filterCriterion?.text) {
                    context.term = params.filterCriterion.text;
                } else {
                    context.term = '';
                }

                return getOptions(context).then((options: any) => {
                    if (this.withEmptyOption && options) {
                        options = [EMPTY_OPTION, ...options];
                    }

                    options = this.filterDisableOptions(unwrap(options));

                    return new ArrayDataProvider(observableArray(options), {
                        keyAttributes: attributeNames.value || DEFAULT_VALUE_ATTRIBUTE,
                    });
                });
            };

            this.dataProvider = new CustomDataProvider(callback);
        } else {
            this.dataProvider = arrayDataProvider;
        }

        this.classNameList = pureComputed(() =>
            this.className
                ?.split(' ')
                .map((classItem) => `${classItem}__list`)
                .join(' ')
        );

        this.onFocus = onFocus;

        // onBlur is handled by custom data binding. Native on-blur fires
        // event when modal menu is opened, not only when user leaves control.
        this.onBlur = onBlur;
        this.onValueChanged = onValueChanged;
        this.onValueAction = onValueAction;
    }

    private getNextId = () => ID_PREFIX + lastId++;
    private getAriaLabel = (label: string) => {
        const optionAriaLabel = unwrap(this.optionsAriaLabel);

        if (optionAriaLabel) {
            return label + ' ' + optionAriaLabel;
        }

        return label ? label : i18n('general.empty-select-option');
    };

    private filterDisableOptions = (options: any) => {
        if (this.includeDisabledOptions) {
            return options;
        }

        const optionsWithoutDisabledItems = options?.filter(
            (option: { disabled: boolean }) => !option.disabled
        );

        return optionsWithoutDisabledItems;
    };
}
