import ko from 'knockout';
import Base64FileReader from 'core/base64-file-reader/Base64FileReader';

export default class DownloadStrategyIE {

    constructor() {
        this.url = ko.observable();
    }

    saveAttachment(mimeType, fileName, fileContents) {
        const blob = new Blob([fileContents], { type: mimeType });

        window.navigator.msSaveBlob(blob, fileName);
    }

    displayAttachment(mimeType, fileName, fileContents) {
        const base64FileReader = new Base64FileReader();

        return base64FileReader.decode(fileContents)
            .then(this.saveAttachment.bind(this, mimeType, fileName));
    }

}