import DatepickerFormElement from 'core/form/element/Datepicker';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import appConfig from 'app/model/config';

export default class DateMonthpicker extends DatepickerFormElement {
    constructor(...args: unknown[]) {
        super(...args);

        this.layout = new LocaleBasedDateTimeFormatter(appConfig.siteLang).getDateMonthPickerFormat('dd-mm');
    }
}
