type CookieCategoryFields =
    | `categoryTitle`
    | `alwaysActiveText`
    | `categoryDescription`
    | `offButtonText`
    | 'onButtonText'
    | 'categoriesContainer'
    | `toggleOffButton`
    | `toggleOnButton`;

type CookieCategorySelectors = Record<CookieCategoryFields, string>;

export const getCookiecategorySelectors = (wrapper: string): CookieCategorySelectors => {
    const categoryContainer = `.${wrapper} .cookie-consent__categories .cookie-consent__category`;

    return {
        categoryTitle: `${categoryContainer} .cookie-consent__name`,
        alwaysActiveText: `${categoryContainer} .cookie-consent__always-active`,
        categoryDescription: `${categoryContainer} .cookie-consent__description`,
        offButtonText: `${categoryContainer} .consent-switch-button-text--inactive`,
        onButtonText: `${categoryContainer} .consent-switch-button-text--active`,
        categoriesContainer: `.${wrapper}.cookie-consent-widgets.cookie-consent-widgets__categories .cookie-consent__categories`,
        toggleOffButton: `${categoryContainer} .input-row--checkbox__switch-slider`,
        toggleOnButton: `${categoryContainer} .input-row--checkbox__switch-slider.checked`,
    };
};
