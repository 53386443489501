const BATCH_ID_STRING = 'batch-';

const OPERATION_METHOD_MAP = {
    GET: 'get',
    POST: 'create',
    PATCH: 'update',
    PUT: 'update',
    DELETE: 'delete',
    JSONP: '',
};


export default class PartFactory {

    constructor() {
        this.batchIdCounter = 1;
    }

    createPart(url, data, method) {
        return {
            id: this._getNewId(url),
            path: url,
            operation: OPERATION_METHOD_MAP[method],
            payload: data,
        };
    }

    _getNewId() {
        return BATCH_ID_STRING + this.batchIdCounter++;
    }

}