import { components } from 'knockout';

import './component';
import JobDetailsContentViewModel from './JobDetailsContentViewModel';
import jobDetailsHeaderTemplate from './job-details-content.html';

components.register('job-details-content', {
    viewModel: JobDetailsContentViewModel,
    template: jobDetailsHeaderTemplate,
});
