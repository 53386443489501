import { observable } from 'knockout';

export default class SingleRow {

    constructor(formElements, rowId, rowLink, rowData = {}) {
        this.id = rowId;
        this.link = rowLink;

        this.attributes = formElements.reduce((model, element) => {
            if (!element.attributes.readonly) {
                model[element.name] = observable(rowData[element.name]);
            }

            return model;
        }, {});
    }

    updateIdAndLink({ id, link }) {
        this.id = id;
        this.link = link;
    }

}