import { RouterQuery } from 'app/module/cx/module/search/config/types';
import { OraRegistrationPhase } from '../../candidate-self-service/mapper/eventRegistration';

export const EVENT_VISIBILITY = {
    ORA_PUBLIC: 'ORA_PUBLIC',
    ORA_PRIVATE: 'ORA_PRIVATE',
};

export type EventVisibility = typeof EVENT_VISIBILITY.ORA_PUBLIC | typeof EVENT_VISIBILITY.ORA_PRIVATE;

export type EventData = {
    eventNumber: number;
    title: string;
    location: string;
    venue: string;
    startDate: string;
    endDate: string;
    shortDescription: string;
    description: string;
    bannerImageUrl: string;
    bannerTitle: string;
    canRegisterFlag: boolean;
    eventCategory: string;
    registrationEndDate: string;
    isVirtual: boolean;
    eventFormat: string;
    lang: string;
    eventVisibilityCode: EventVisibility;
    isAlreadyRegistered?: boolean;
    isConfirmed?: boolean;
    isManualConfirmationRequired: boolean;
    contentLocale: string;
    registrationInfo?: RegistrationDetails;
    isCandidateDisqualified?: boolean;
};

export type RegistrationDetails = {
    registrationPhase: RegistrationPhase;
};

export type Settings = {
    expand: string;
};

export const EVENT_DETAILS_FETCH_CONTEXT = {
    NO_CONTEXT: '',
    CSS_CONTEXT: 'css',
    CHECK_IN_CONTEXT: 'check_in',
} as const;

export const DATE_TIME_FORMAT = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
} as const;

export type RouterParams = {
    eventId: string;
    token: string;
    query: RouterQuery;
};

export type AlreadyRegisteredEvents = {
    confirmedFlag: boolean;
    id: string | number;
    registrationPhase: RegistrationPhase;
    disqualification: string | null;
};

export type RegistrationPhase = OraRegistrationPhase;
