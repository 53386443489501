import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';
import storageService from 'core/storage/sessionStorage';
import { PREVIOUS_ROUTE } from 'ce-common/service/historyService';

export const getJobUrl = (jobId: string): string => {
    return absoluteRouter.interpolate({}, 'job-details', { jobId });
};

const FULL_JOB_VIEW_PAGE_ID = ['job-details', 'job-full-view', 'cc-page'];

export const openJob = (jobId: string): void => {
    const isFullJobView = FULL_JOB_VIEW_PAGE_ID.includes(router.route().id);

    if (!isFullJobView) {
        storageService.remove(PREVIOUS_ROUTE);
    }

    const routeId = isFullJobView ? 'job-details' : 'job-preview';

    router.go(routeId, {
        jobId,
    });
};
