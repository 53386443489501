import ko from 'knockout';
import formElementFactory from 'core/form/element/factory';

export default class Form {

    constructor(config = {}) {
        this.legend = ko.observable(config.legend);
        this.model = ko.observable();

        this._elements = ko.observableArray(config.elements);

        this.componentName = ko.observable('form-builder');

        this.elements = ko.pureComputed({
            read: this._getElements,
            write: this._setElements,
            owner: this,
        });

        this.isDirty = ko.pureComputed(this._isDirty, this);
        this.isEmpty = ko.pureComputed(this._isEmpty, this);

        this.subscriptions = [];
    }

    removeElement(element) {
        this._elements.remove(element);

        return this;
    }

    addElement(element) {
        this._elements.push(element);

        return this;
    }

    bindComponent(component) {
        this.componentName(component);

        return this;
    }

    getElement(name) {
        return this._elements().filter(element => element.name() === name).pop();
    }

    registerModel(model) {
        this._elements().forEach((element) => {
            const name = element.name();

            if (name in model) {
                element.registerModel(model[name]);
            }
        });

        this.model(model);

        return this;
    }

    resolveElementsDependencies() {
        const elements = this._getElements();
        const model = this.model();

        elements.filter(element => Boolean(element.resolveOptionsDependency)).forEach((element) => {
            element.resolveOptionsDependency(elements, model);
        });

        return this;
    }

    _getElements() {
        return this._elements();
    }

    _setElements(element, name) {
        if (Array.isArray(element)) {
            this._elements(element);
        } else if (typeof element === 'string') {
            this._elements.push(
                formElementFactory.create(element, name),
            );
        } else {
            this._elements.push(element);
        }

        return this;
    }

    _isDirty() {
        return this._elements().some(element => element.isDirty());
    }

    _isEmpty() {
        return this._elements().length === 0;
    }

    dispose() {
        this.subscriptions.forEach((subscription) => {
            subscription.dispose();
        });

        this._elements().forEach((element) => {
            element.dispose();
        });
    }

}