import { pureComputed } from 'knockout';
import ElementViewModel from '../element/ElementViewModel';
import { isYouTube } from './service/videoFormat';

export default class VideoViewModel extends ElementViewModel {

    constructor({ params }) {
        super({ params });

        this.params = params;
        this.component = this._setComponent();
        this.cssClass = pureComputed(this._computeCssClass, this);
    }

    _setComponent() {
        const src = this.params.src();

        if (isYouTube(src)) {
            return 'youtube-video';
        }

        return 'generic-video';
    }

    _computeCssClass() {
        return `cc-element-media--align-${this.params.align()} ${this.params.cssClass()}`;
    }

}
