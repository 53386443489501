import genericFormBuilder from 'core/form/formBuilder';
import i18n from 'core/i18n/i18n';
import candidateModel from 'apply-flow/model/candidate';
import appConfig from 'app/model/config';

export default Object.create(genericFormBuilder, {

    createForm: {
        value(config) {
            const dateOfBirthFormElement = genericFormBuilder.createFormElement(config.formElementDef);

            dateOfBirthFormElement.showLabelTooltip(false);
            dateOfBirthFormElement.registerModel(candidateModel.dateOfBirth);

            const dateOfBirthForm = genericFormBuilder
                .createForm()
                .elements([dateOfBirthFormElement]);

            this._dateOfBirthValidation(dateOfBirthFormElement, config);
            this._setDateOfBirthDisabled(dateOfBirthFormElement);

            return dateOfBirthForm;
        },
    },

    _dateOfBirthValidation: {
        value(dateOfBirthFormElement, config) {
            const validation = {
                maxDate: {
                    max: this._formatDateYYYYMMDD(new Date()),
                    inclusive: true,
                    lang: appConfig.siteLang,
                },
            };

            if (config.blockProperties.isDOBRequired) {
                validation.required = {
                    label: dateOfBirthFormElement.label(),
                };
            }

            dateOfBirthFormElement.validators(validation);
        },
    },

    _formatDateYYYYMMDD: {
        value(date) {
            return date.toISOString().slice(0, 10);
        },
    },

    _setDateOfBirthDisabled: {
        value(dateOfBirthFormElement) {
            if (!this._candidateIsExWorkerOrCwk()) {
                return;
            }

            if (!this._candidateHasDateOfBirthInProfile()) {
                return;
            }

            dateOfBirthFormElement.isDisabled(true);
            dateOfBirthFormElement.helpText(i18n('apply-flow.section-personal-identifying-information.date-of-birth-disabled'));
        },
    },

    _candidateIsExWorkerOrCwk: {
        value() {
            const {
                exWorker,
                cwkCandidate,
            } = candidateModel.basicInformation;

            return exWorker() || cwkCandidate();
        },
    },

    _candidateHasDateOfBirthInProfile: {
        value() {
            return candidateModel.isDateOfBirthInProfile();
        },
    },
});