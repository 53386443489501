import mapping from 'knockout-mapping';
import { pureComputed } from 'knockout';

export class Store {

    constructor({ state = {}, mutations = {}, actions = {}, getters = {} }) {
        this.state = mapping.fromJS(state);

        this._mutations = mutations;
        this._actions = actions;

        this.getters = this._handleGetters(getters);

        this.commit = this.commit.bind(this);
        this.dispatch = this.dispatch.bind(this);
    }

    commit(name, payload) {
        if (!(name in this._mutations)) {
            throw new Error(`Store: unknown mutation ${name}`);
        }

        const mutation = this._mutations[name];

        mutation.call(null, this.state, payload);
    }

    dispatch(name, payload) {
        if (!(name in this._actions)) {
            throw new Error(`Store: unknown action ${name}`);
        }

        const action = this._actions[name];

        return Promise
            .resolve()
            .then(() => action.call(
                null,
                {
                    dispatch: this.dispatch,
                    commit: this.commit,
                    state: this.state,
                },
                payload,
            ));
    }

    _handleGetters(rawGetters) {
        const getters = {};

        Object
            .keys(rawGetters)
            .forEach((key) => {
                getters[key] = pureComputed(() => rawGetters[key](this.state));
            });

        return getters;
    }

}
