export default {
    search: {
        sections: [
            {
                elementOrder: 0,
                sectionParams: [
                    {
                        paramKey: 'type',
                        paramValue: 'cc-section',
                    },
                ],
                rows: [
                    {
                        elementOrder: 0,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [{
                            elementOrder: 0,
                            columnParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-column',
                            }],
                            components: [{
                                elementOrder: 0,
                                componentParams: [{
                                    paramKey: 'type',
                                    paramValue: 'cc-search-bar',
                                }],
                            }],
                        }],
                    },
                    {
                        elementOrder: 1,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [{
                            elementOrder: 0,
                            columnParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-column',
                            }],
                            components: [{
                                elementOrder: 1,
                                componentParams: [{
                                    paramKey: 'type',
                                    paramValue: 'cc-search-filters-panel',
                                }],
                            }],
                        }],
                    },
                    {
                        elementOrder: 2,
                        rowParams: [
                            {
                                paramKey: 'columnLayout',
                                paramValue: '1',
                            },
                            {
                                paramKey: 'type',
                                paramValue: 'cc-row',
                            },
                        ],
                        columns: [{
                            elementOrder: 0,
                            columnParams: [{
                                paramKey: 'type',
                                paramValue: 'cc-column',
                            }],
                            components: [
                                {
                                    elementOrder: 0,
                                    componentParams: [{
                                        paramKey: 'type',
                                        paramValue: 'cc-recommended-jobs-widget',
                                    }],
                                },
                                {
                                    elementOrder: 1,
                                    componentParams: [{
                                        paramKey: 'type',
                                        paramValue: 'cc-search-results',
                                    }],
                                },
                            ],
                        }],
                    },
                ],
            },
        ],
    },
};
