import phoneNumberFilter from './phoneNumberFilter';

const MAX_LENGTH = 30;
const PHONE_NUMBER_PATTERN = /^(\+\d)?([\d[ ]{3,29})$/;

export default class PhoneNumberFormatter {

    constructor(value) {
        this._phoneNumber = {
            countryCode: String(value.countryCode || ''),
            number: String(value.number || ''),
            legislationCode: String(value.legislationCode || ''),
        };
    }

    get countryCode() {
        return this._phoneNumber.countryCode;
    }

    get areaCode() {
        return '';
    }

    get number() {
        return this._phoneNumber.number;
    }

    get legislationCode() {
        return this._phoneNumber.legislationCode;
    }

    get _rawPhoneNumber() {
        return `${this.countryCode}${this.number}`;
    }

    asFormattedString() {
        const countryCode = this.countryCode ? `+${this.countryCode}` : '';

        return `${countryCode} ${this.number}`.trim();
    }

    isValid() {
        if (this._rawPhoneNumber) {
            if (this.countryCode && !this.number) {
                return true;
            }

            if (!this.countryCode) {
                return false;
            }

            return this._rawPhoneNumber.length < MAX_LENGTH && PHONE_NUMBER_PATTERN.test(this._rawPhoneNumber);
        }

        return true;
    }

    asFormattedStringWithoutCountryCode() {
        return phoneNumberFilter.filter(this._phoneNumber.number);
    }

    asFormattedStringWithLegislationCode() {
        return `${this.legislationCode}${this.asFormattedString()}`;
    }

}
