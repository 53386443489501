import i18n from 'core/i18n/i18n';

export type JobCategoryTag = {
    value: JobCategoryTagKey;
    label: string;
};

export const JOB_CATEGORY_TYPES: JobCategoryTag[] = [
    {
        value: 'skillsScore',
        label: i18n('job-details.skills'),
    },
    {
        value: 'relevantExperienceScore',
        label: i18n('job-details.experience'),
    },
    {
        value: 'domainKnowledgeScore',
        label: i18n('job-details.education'),
    },
    {
        value: 'roleSeniorityScore',
        label: i18n('job-details.expertise'),
    },
];

export const JOB_Category_TYPES_DEFAULT: JobCategoryTagKey[] = [
    'skillsScore',
    'roleSeniorityScore',
    'relevantExperienceScore',
    'domainKnowledgeScore',
];

export type JobCategoryTagKey =
    | 'skillsScore'
    | 'relevantExperienceScore'
    | 'domainKnowledgeScore'
    | 'roleSeniorityScore';
