import ko from 'knockout';

function triggerDownload() {
    document.getElementById('downloader').click();
}

export default class DownloadStrategyBase {

    constructor() {
        this.url = ko.observable();
    }

    saveAttachment(mimeType, fileName, fileContents) {
        const blob = new Blob([fileContents], { type: mimeType });

        this.url(URL.createObjectURL(blob));

        triggerDownload();
    }

    displayAttachment(mimeType, fileName, fileContents) {
        this.url(`data:${mimeType};base64,${fileContents}`);

        triggerDownload();

        return Promise.resolve();
    }

}