import absoluteRouter from 'app/model/absoluteRouter';
import appConfig from 'app/model/config';
import { CategoryTileFromConfig, CategoryTilesDisplayContent } from '../config/types';
import http from 'core/http/http';
import {
    CategoryTileModelRest,
    mapCategoryTileFromRest,
} from 'app/module/admin/module/site-editor/mapper/categoryTile';

const CUSTOM_CONTENT_PAGE = 'cc-page';
const HTTP_PREFIX = 'http';

const sortByOrder = (itemA: { order: number }, itemB: { order: number }) => itemA.order - itemB.order;

export const getTilesData = (categoryTilesData: CategoryTileFromConfig[]): CategoryTilesDisplayContent[] => {
    return categoryTilesData?.length
        ? categoryTilesData.map((tile: CategoryTileFromConfig) => mapParamsFromConfig(tile)).sort(sortByOrder)
        : [];
};

const mapParamsFromConfig = (tile: CategoryTileFromConfig): CategoryTilesDisplayContent => {
    let tileUrl;
    const { linkUrl, url, linkText, tileImageUrl, jobFamilyIds, id, linkOrder, title, order } = tile;
    const pageCode = linkUrl || url;
    const pageTitle = linkText || title;
    const tileOrder = linkOrder || order;
    const tileId = jobFamilyIds || id;

    if (pageCode && pageCode.substring(0, 4) !== HTTP_PREFIX) {
        tileUrl = absoluteRouter.interpolate(
            { lang: appConfig.siteLang || appConfig.sessionLang },
            CUSTOM_CONTENT_PAGE,
            {
                pageCode: pageCode,
                pageTitle: pageTitle,
            }
        );
    } else {
        tileUrl = pageCode;
    }

    return {
        title: pageTitle,
        url: tileUrl,
        tileImageUrl: tileImageUrl,
        order: tileOrder,
        jobFamilyIds: tileId,
    };
};

export const getCatgeoryListData = (siteCode: string): Promise<any> => {
    const restUrl = `/recruitingCESites/${siteCode}/child/jobCategoryLinks`;

    return http
        .get(restUrl)
        .then((data: { items: CategoryTileModelRest[] }) => data.items.map(mapCategoryTileFromRest))
        .catch((err: string | undefined) => console.error(err));
};
