import i18n from 'core/i18n/i18n';
import mapping from 'knockout-mapping';
import { SMS, EMAIL } from 'candidate-verification/config/verificationMethods';
import {
    TC_CANDIDATE,
    CSS_ENTRY,
    JOB_APPLICATION,
    UNVERIFIED_APPLICATIONS_LIMIT,
    EVENT_REGISTER,
} from 'candidate-verification/config/verificationSubmodules';
import { combinePhone } from 'candidate-verification/service/phoneConverter';
import storageService from 'core/storage/sessionStorage';

export const WRONG_PIN_MESSAGE = i18n('apply-flow.candidate-verification.invalid-code');
export const RESEND_BLOCKED = i18n('apply-flow.candidate-verification.maximum-attemps-reached');
export const INVALID_PERSON_MESSAGE = i18n('apply-flow.candidate-verification.invalid-person');

const MESSAGES = {
    [JOB_APPLICATION]: {
        [SMS]: 'apply-flow.candidate-verification.code-sent-to-phone-job-application',
        [EMAIL]: 'apply-flow.candidate-verification.code-sent-to-email-job-application',
    },
    [EVENT_REGISTER]: {
        [SMS]: 'event-register-flow.candidate-verification.code-sent-to-phone',
        [EMAIL]: 'event-register-flow.candidate-verification.code-sent-to-email',
    },
    [CSS_ENTRY]: {
        [SMS]: 'apply-flow.candidate-verification.code-sent-to-phone',
        [EMAIL]: 'apply-flow.candidate-verification.code-sent-to-email',
    },
    [TC_CANDIDATE]: {
        [EMAIL]: {
            newCandidate: 'talent-community.candidate-verification-code.new-candidate',
            oldCandidate: 'apply-flow.candidate-verification.code-sent-if-email-exists',
        },
    },
    [UNVERIFIED_APPLICATIONS_LIMIT]: {
        [SMS]: 'unverified-candidate.confirm-email-dialog.phone-description',
        [EMAIL]: 'unverified-candidate.confirm-email-dialog.email-description',
    },
    generic: {
        [SMS]: 'apply-flow.candidate-verification.code-sent-to-phone',
        [EMAIL]: 'apply-flow.candidate-verification.code-sent-to-email',
        mergedCandidate: 'apply-flow.candidate-verification.code-sent-to-merged-candidate',
        default: 'apply-flow.candidate-verification.code-sent',
    },
};

const RESEND_MESSAGES = {
    [SMS]: 'apply-flow.candidate-verification.new-code-delivered',
    [EMAIL]: 'apply-flow.candidate-verification.new-code-delivered-email',
    default: 'apply-flow.candidate-verification.new-code-delivered-default',
};

const TC_CANDIDATE_TYPE = 'isReturningCandidate';

export function fillInMessageOptions(data) {
    const { candidate, submodule, mergedCandidate } = mapping.toJS(data);
    const { verificationMethod, email, phone } = candidate;

    const key = mergedCandidate
        ? MESSAGES.generic.mergedCandidate
        : _getMessageKey(verificationMethod, submodule);

    return {
        key,
        tokens: {
            email_address: email,
            phone_number: combinePhone(phone),
        },
    };
}


export function resendMessageKey(candidate) {
    const verificationMethod = candidate.verificationMethod();

    if (verificationMethod) {
        return RESEND_MESSAGES[verificationMethod];
    }

    return RESEND_MESSAGES.default;
}

export function pinResendMessage(counterValue) {
    return {
        key: 'apply-flow.candidate-verification.code-resend-counter',
        tokens: {
            resend_counter: counterValue,
        },
    };
}

function _getMessageKey(verificationMethod, submodule) {
    const source = MESSAGES[submodule]
        ? MESSAGES[submodule]
        : MESSAGES.generic;

    if (submodule === TC_CANDIDATE) {
        const isReturningCandidate = storageService.restore(TC_CANDIDATE_TYPE);

        storageService.remove(TC_CANDIDATE_TYPE);

        return isReturningCandidate
            ? source[verificationMethod].oldCandidate
            : source[verificationMethod].newCandidate;
    }

    return source[verificationMethod]
        ? source[verificationMethod]
        : MESSAGES.generic.default;
}