import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';

const thankYouMessage = i18n('campaign.thank-you.description');
const unsubscribeMessage = i18n('campaign.unsubscribe.description');


export function unsubscribe({ token }) {
    tokenService.verifyToken(token)
        .then(() => notificationsService.success(unsubscribeMessage))
        .catch((error) => {
            console.error(error);
            notificationsService.error();
        });
}

export function displayThankYou() {
    notificationsService.success(thankYouMessage);
}