import httpMapper from 'core/http/mapper';
import formElementMapper from 'apply-flow/mapper/metadata';
import dictionaryService from 'apply-flow/service/dictionary';


const TYPES_MAP = {
    DATE: 'date',
    TEXTAREA: 'textarea',
};

function _toCamelCase(str) {
    return httpMapper.toCamelCase(str.replace(/\s/g, ''));
}

function _sortByDisplaySequence(item1, item2) {
    return item1.displaySequence - item2.displaySequence;
}

function _createSelectCountry(formElement) {
    formElement.requiredFlag = true;
    formElement.type = 'select';

    return dictionaryService.get(dictionaryService.CODES.countries)
        .then((lookupValues) => {
            formElement.options = lookupValues;

            return formElement;
        });
}

export default {

    mapFormElementFromRest(restFormElement, restFormElements) {
        const formElement = {
            type: this.getFormElementType(restFormElement.type),
            datatype: restFormElement.type,
            name: _toCamelCase(restFormElement.attributeName),
            label: restFormElement.prompt,
            defaultValue: restFormElement.defaultValue,
            value: restFormElement.defaultValue,
            isHidden: restFormElement.uihiddenFlag,
            attributes: {
                readonly: restFormElement.readonlyFlag,
                required: restFormElement.requiredFlag,
            },
            validators: {},
            options: restFormElement.answers,
            sectionId: restFormElement.sectionId,
        };

        if (restFormElement.dictionary) {
            formElement.type = 'select';
        }

        if (restFormElement.requiredFlag) {
            formElement.validators.required = {
                label: restFormElement.prompt,
            };
        }

        if (restFormElement.maximumLength) {
            formElement.validators.maxLength = {
                max: restFormElement.maximumLength,
            };
        }

        if (formElement.name === 'country') {
            return _createSelectCountry(formElement);
        }

        return formElementMapper.mapFormElementFromRest(restFormElement, formElement, restFormElements);
    },

    mapFormElementsFromRest(restData) {
        const restFormElements = restData.items.sort(_sortByDisplaySequence);

        return Promise.all(
            restFormElements.map(restFormElement => this.mapFormElementFromRest(restFormElement, restFormElements)),
        );
    },

    getFormElementType(restType) {
        return TYPES_MAP[restType] || 'text';
    },


};