import i18n from 'core/i18n/i18n';
import formBuilder from 'core/form/formBuilder';
import formElementFactory from 'core/form/element/factory';

export default Object.create(formBuilder, {
    createForm: {
        value(model) {
            const form = formBuilder.createForm();

            form.elements([this._createLastNameFormElement(model)]);

            return form;
        },
    },

    _createLastNameFormElement: {
        value(model) {
            const label = i18n('apply-flow.section-personal-information.lastname-label');

            const formElement = formElementFactory.create('text', 'last-name');

            formElement.addValidator('required', { label }, 'prepend');
            formElement.label(label);
            formElement.registerModel(model);

            return formElement;
        },
    },
});