import { PureComputed, pureComputed } from 'knockout';

import router from 'app/model/router';
import ContentComponent from 'cx/module/custom-content/model/ContentComponent';
import { getSearchResultContextComponent } from './service/searchResultContext';
import { SearchResultContextComponent } from 'cx/module/search/config/types';

export default class SearchResultsViewModel {
    contentComponent: ContentComponent;
    uniqueWrapperClass: string;
    searchResultContextComponent: PureComputed<SearchResultContextComponent>;

    constructor(contentComponent: ContentComponent) {
        const id = contentComponent.id ?? 'default';

        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;

        this.contentComponent = contentComponent;

        this.searchResultContextComponent = pureComputed(() =>
            getSearchResultContextComponent(router.route().id)
        );
    }
}
