import ko from 'knockout';
import l10n from 'core/l10n/l10n';
import converterFactory from 'core/l10n/converter/factory';

function _getConverter(converterOptions) {
    if (typeof converterOptions === 'string') {
        return l10n[converterOptions];
    }

    return converterFactory.createConverter(converterOptions.type, converterOptions);
}

function formatter(target, options) {
    const converter = _getConverter(options);

    const formatted = ko.pureComputed({
        read() {
            const value = target();

            return value && converter.format(value);
        },

        write(value) {
            if (value && value instanceof Date === false) {
                target(converter.parse(value));
            } else {
                target(null);
            }
        },
    });

    formatted(target());

    return formatted;
}

ko.extenders.l10n = formatter;