import { components } from 'knockout';

import metadataConfig from 'apply-flow/config/metadata';
import metadataRepository from 'apply-flow/module/personal-information-address/service/Metadata';
import 'apply-flow/module/personal-information-address/component/address-form/component';
import 'apply-flow/module/personal-information-address/form/component/geo-hierarchy-autosuggest-form-element/component';
import 'apply-flow/module/personal-information-address/form/component/geo-hierarchy-search-form-element/component';
import 'apply-flow/module/personal-information-address/form/component/geo-hierarchy-select-form-element/component';

import ViewModel from '../PersonalInformationAddressViewModel';
import template from '../personal-information-address.html';

components.register('personal-information-address', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_ADDRESS', {
    component: 'personal-information-address',
    repository: metadataRepository,
});
