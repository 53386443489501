import ko from 'knockout';
import httpConfig from 'core/http/config';
import employeeInfoProvider from 'app/service/employeeInfoProvider';
import appConfig from './config';
import base64 from 'app/module/core/utils/base64';


function _addAuthHeader(request, jwtToken) {
    ko.utils.extend(request.headers, {
        Authorization: `Bearer ${jwtToken}`,
    });

    return request;
}

export default {
    init() {
        return employeeInfoProvider.getEmployeeInfo()
            .then((employeeConfig) => {
                ko.utils.extend(this, employeeConfig);

                if (this.isSet()) {
                    appConfig.setSettingByKey('employee', this.getEmployeeUser());
                    httpConfig.addAuthorizationHeader = request => _addAuthHeader(request, this.jwtToken);
                }
            })
            .catch((error) => {
                console.error(error);

                return Promise.resolve({});
            });
    },

    isSet() {
        return Boolean(this.jwtToken);
    },

    getEmployeeUser() {
        const jwtTokenInfo = this.jwtToken?.split('.');

        if (jwtTokenInfo.length < 1) {
            return '';
        }

        const [, encodedUserInfo] = jwtTokenInfo;
        const decodedUserInfo = base64.decode(encodedUserInfo) || '';

        return decodedUserInfo.split(',')[0]?.split(':')[1];
    },

};
