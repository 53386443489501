import ko from 'knockout';
import mapping from 'knockout-mapping';


function RegulatoryResponse(data) {
    this.id = ko.observable();
    this.value = ko.observable();

    mapping.fromJS(data, {
        include: 'value',
    }, this);
}

export default RegulatoryResponse;