import mapping from 'knockout-mapping';
import { RESUME, COVER_LETTER, MISCELLANEOUS } from '../config/attachmentCategories';

export default function FileAttachment(data) {
    mapping.fromJS({
        id: undefined,
        fileName: '',
        fileSize: 0,
        fileContents: undefined,
        categoryId: undefined,
        blockId: undefined,
        url: undefined,
        sourceAttachmentId: undefined,
        action: 'CREATE',
        isReferral: false,
        isResume: false,
        isCoverLetter: false,
        isMiscellaneous: false,
        isCancelled: false,
        appDraftId: undefined,
    }, {}, this);

    mapping.fromJS(data, {
        ignore: ['mimeType'],
    }, this);

    this.getMimeType = () => data.mimeType;

    this.isResume(this.categoryId() === RESUME);
    this.isCoverLetter(this.categoryId() === COVER_LETTER);
    this.isMiscellaneous(this.categoryId() === MISCELLANEOUS);
}
