import sourceTraceService from 'cx/module/source-tracking/service/sourceTrace';
import tokenService from 'cx/module/candidate-verification/service/token';
import { CHALLENGE_REQUIRED } from 'cx/module/candidate-verification/config/pinVerificationStatus';
import {
    LAST_NAME,
    DATE_OF_BIRTH,
} from 'app/module/cx/module/candidate-verification/config/claimVerificationChecks';
import {
    LAST_NAME_CHECK,
    DATE_OF_BIRTH_CHECK,
} from 'cx/module/candidate-verification/config/candidateVerificationStatus';
import { redirectToRegisterFlow } from '../../../config/eventRegisterFlowRoutes';
import { CandidateVerificationRequest, SourceTraceDataEventContext } from '../../../config/types';
import { Token } from 'cx/module/candidate-verification/config/types';
import referrerService from 'source-tracing/service/referrer';
import { setClaimedLastName } from './lastNameVerifier';
import { setClaimedDateOfBirth } from './dateOfBirthVerifier';
import { saveSourceTrace } from 'app/module/cx/module/candidate-verification/service/sourceTraceStorage';

export const createToken = async (
    candidateVerificationRequest: CandidateVerificationRequest
): Promise<void> => {
    setClaimedLastName(null as unknown as string);
    setClaimedDateOfBirth(null as unknown as Date);

    const { eventNumber, candidate, submodule, lastName, dateOfBirth, captchaToken, honeyPot } =
        candidateVerificationRequest;

    let tempSourceTrace: SourceTraceDataEventContext;

    return createSourceTrace(eventNumber)
        .then((sourceTrace: SourceTraceDataEventContext) => {
            tempSourceTrace = sourceTrace;

            return tokenService.create({
                eventNumber,
                candidate,
                submodule,
                sourceTrace,
                lastName,
                dateOfBirth,
                captchaToken,
                honeyPot,
            });
        })
        .then((verificationToken: Token) => {
            if (!verificationToken.cookieMatchedFlag) {
                if (verificationToken.nextField === DATE_OF_BIRTH) {
                    saveSourceTrace(tempSourceTrace);

                    return Promise.reject(DATE_OF_BIRTH_CHECK);
                }

                if (!verificationToken.challengeFlag || verificationToken.nextField === LAST_NAME) {
                    saveSourceTrace(tempSourceTrace);

                    return Promise.reject(LAST_NAME_CHECK);
                }
            }

            if (verificationToken.challengeFlag) {
                return Promise.reject(CHALLENGE_REQUIRED);
            }

            if (!verificationToken.accessCode) {
                return Promise.reject('token-verification');
            }

            return redirectToRegisterFlow();
        });
};

const createSourceTrace = (eventId: string): Promise<SourceTraceDataEventContext> => {
    const sourceTraceData: Record<string, string> = {
        sourceLevel: sourceTraceService.LEVEL.EVENT_REGISTRATION,
        eventNumber: eventId,
    };

    if (!referrerService.get()) {
        sourceTraceData.source = 'event';
        sourceTraceData.sourceMedium = 'career site';
    }

    return sourceTraceService.create(sourceTraceData);
};
