import mapping from 'knockout-mapping';
import SiteLink from 'apply-flow/module/candidate-links/model/SiteLink';

const ATTACHMENT_TYPE = 'URL';
const CATEGORY_ID = 'IRC_CANDIDATE_SOCIAL';

function notEmpty(link) {
    return link.attachment;
}

function _isSiteLink(attachment) {
    return attachment && attachment.type === ATTACHMENT_TYPE;
}

export default {
    ATTACHMENT_TYPE,

    mapSiteLinksToRest(siteLinks) {
        return siteLinks
            .map(this.mapSiteLinkToRest)
            .filter(notEmpty);
    },

    mapSiteLinkToRest(siteLinkModel) {
        const siteLink = mapping.toJS(siteLinkModel);

        const link = {
            type: ATTACHMENT_TYPE,
            attachment: (siteLink.url || '').trim(),
            category: CATEGORY_ID,
            description: siteLink.description,
        };

        return link;
    },

    mapSiteLinksFromRest(restCollection) {
        const restSiteLinks = restCollection.items || [];

        return restSiteLinks.filter(_isSiteLink).map(this.mapSiteLinkFromRest);
    },

    mapSiteLinkFromRest(restSiteLinkData) {
        const restSiteLink = restSiteLinkData || {};

        return new SiteLink({
            id: restSiteLink.id,
            url: restSiteLink.attachment,
            categoryId: restSiteLink.category,
            description: restSiteLink.description,
        });
    },
};