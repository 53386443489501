import i18n from 'core/i18n/i18n';
import { Marker } from './Marker';
import { getMapProvider } from '../service/providers/factory';

export class AddressMarker extends Marker {

    constructor({ coordinates, address, markerObject, lang }) {
        super({ coordinates, markerObject });

        this.address = address;
        this.contentLocaleLang = lang;
        this.popupContent = this._generatePopupContent();
    }

    _generatePopupContent() {
        const mapProvider = getMapProvider();

        if (!mapProvider) {
            return '';
        }

        const directionsLink = mapProvider.generateDirectionsDeepLink(this.coordinates);

        return `<div class="address-marker font-family-primary">
            <p class="address-marker__address text-color-primary">${this.address}</p>
            <a href="${directionsLink}" target="_blank" class="address-marker__link theme-color-1">${i18n('job-details.copy-address', null, this.contentLocaleLang)}</a>
            </div>`;
    }

}