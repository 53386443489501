import { components } from 'knockout';

import { CookieConsentModalMessageViewModel } from './CookieConsentModalMessageViewModel';
import template from './cookie-consent-modal-message.html';

components.register('cookie-consent-modal-message', {
    viewModel: CookieConsentModalMessageViewModel,
    template,
});

components.register('cc-cookie-consent-modal-message', {
    viewModel: CookieConsentModalMessageViewModel,
    template,
});
