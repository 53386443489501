const loadedScriptsMap = new Map();

function createScriptPromise(url, innerHTML) {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        script.onerror = reject;

        if (innerHTML) {
            script.innerHTML = innerHTML;
        }

        document.head.appendChild(script);
    });
}

export function addScriptToHead(url, innerHTML = null, bypassLoadedScriptsMap = false) {
    if (loadedScriptsMap.has(url) && !bypassLoadedScriptsMap) {
        return loadedScriptsMap.get(url);
    }

    const scriptPromise = createScriptPromise(url, innerHTML);

    loadedScriptsMap.set(url, scriptPromise);

    return scriptPromise;
}