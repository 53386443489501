import { observable, pureComputed } from 'knockout';

const DEFAULT_TITLE_KEY = 'general.show-more';

export default class ShowMoreApplicationsButtonViewModel {

    constructor({ titleKey, isLoading, onClicked }) {
        this.titleKey = pureComputed(() => titleKey() || DEFAULT_TITLE_KEY);
        this.isLoading = isLoading || observable(false);
        this.onClicked = onClicked;
    }

}
