import FormValidator from 'core/form/validator/Validator';

export default class ServiceCallFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._cache = {};
        this._serviceMessage = null;
    }

    getErrorMessage() {
        if (!this._serviceMessage) {
            return super.getErrorMessage();
        }

        return this._serviceMessage;
    }

    _getCacheKey(value, generator) {
        return generator ? generator(value) : JSON.stringify(value);
    }

    _validate(value, options) {
        if (!value) {
            return Promise.resolve(true);
        }

        if (typeof options.exclude === 'function' && options.exclude(value)) {
            return Promise.resolve(true);
        }

        const cacheKey = this._getCacheKey(value, options.cacheKeyGenerator);

        if (this._cache[cacheKey]) {
            return this._getCachedValue(cacheKey);
        }

        this._cache[cacheKey] = {
            promise: options.serviceCall(value)
                .catch(this._onInvalid.bind(this, cacheKey))
                .then(this._onValid.bind(this)),
            message: '',
        };

        return this._cache[cacheKey].promise;
    }

    _onInvalid(cacheKey, serviceMessage) {
        this._serviceMessage = serviceMessage;
        this._cache[cacheKey].message = serviceMessage;

        return false;
    }

    _onValid(valid = true) {
        return valid;
    }

    _getCachedValue(cacheKey) {
        const { promise } = this._cache[cacheKey];

        return promise
            .then((isValid) => {
                if (!isValid) {
                    this._serviceMessage = this._cache[cacheKey].message;
                }

                return isValid;
            });
    }

}