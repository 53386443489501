import { notReachable } from 'app/types/utils';
import { getColorCss, getBackgroundColorCss } from './generalStyles';
import { getStyle, STYLES_SEPARATOR } from './getStyle';
import { ObservableParams } from '../observableParams/paramsToObservable';
import { wrapBySelector } from './wrapBySelector';
import { observable } from 'knockout';

export type StylingBoxField =
    | 'backgroundColor'
    | 'borderColor'
    | 'iconColorDark'
    | 'iconColorLight'
    | 'borderRadius'
    | 'borderWidth'
    | 'margin'
    | 'padding'
    | 'color'
    | 'alignment'
    | 'ruleColor'
    | 'backgroundImage'
    | 'background';

export type StylingBox = Record<StylingBoxField, string | null>;
export type StylingBoxObservableParams = ObservableParams<StylingBox>;

const getFieldStyle = (field: StylingBoxField, value: string) => {
    switch (field) {
        case 'backgroundColor':
        case 'borderColor':
        case 'borderRadius':
        case 'borderWidth':
        case 'color':
        case 'alignment':
        case 'background':
            return getStyle(field, String(value));

        case 'margin':
        case 'padding':
            return getStyle(field, `${String(value)}px`);

        case 'iconColorDark':
        case 'iconColorLight':
            return null;
        case 'ruleColor':
            return getStyle('backgroundColor', `${String(value)}px`);
        case 'backgroundImage':
            return getStyle('backgroundImage', `url('${String(value)}')`);

        default:
            return notReachable(field);
    }
};

const getStylingBoxStyles = (stylingBox: StylingBoxObservableParams) => {
    const fields = Object.keys(stylingBox) as StylingBoxField[];

    const isBackgroundColorAndImageRequired =
        stylingBox.background() !== null && stylingBox.backgroundImage() !== null;

    return fields
        .map((field) => {
            let value = stylingBox[field]();

            if (value === null) {
                return null;
            }

            if (isBackgroundColorAndImageRequired && field === 'background') {
                value = `url(${stylingBox.backgroundImage()}), ${value}`;
            }

            return getFieldStyle(field, value);
        })
        .filter((style) => !!style)
        .join(STYLES_SEPARATOR);
};

const copyStylingBox = (stylingBox: StylingBoxObservableParams) => {
    const stylingBoxEntries = Object.entries(stylingBox).map(([key, value]) => {
        return [key, observable(value())];
    });

    return Object.fromEntries(stylingBoxEntries);
};

const getAllStyles = (
    stylingBox: StylingBoxObservableParams,
    selector: string,
    customSelectors: Partial<Record<StylingBoxField, string>>
): string[] => {
    const stylingBoxCopy = copyStylingBox(stylingBox);

    const customBoxStyles: string[] = [];

    Object.entries(customSelectors).forEach(([key, value]) => {
        customBoxStyles.push(
            wrapBySelector(
                value as string,
                getFieldStyle(key as StylingBoxField, stylingBoxCopy[key]()) || ''
            )
        );

        stylingBoxCopy[key as StylingBoxField](null);
    });

    const boxStyles = getStylingBoxStyles(stylingBoxCopy);

    return [wrapBySelector(selector, boxStyles), ...customBoxStyles];
};

export const getStylingBoxCss = ({
    selector,
    stylingBox,
    lightIconSelector,
    darkIconSelector,
    customSelectors,
    ruleColorSelector,
}: {
    selector: string | null;
    stylingBox: StylingBoxObservableParams;
    lightIconSelector: string | null;
    darkIconSelector: string | null;
    customSelectors?: Partial<Record<StylingBoxField, string>>;
    ruleColorSelector?: string | null;
}): string => {
    const lightIconCss = lightIconSelector
        ? getColorCss({
              selector: lightIconSelector,
              color: stylingBox.iconColorLight(),
          })
        : '';

    const darkIconCss = darkIconSelector
        ? getColorCss({
              selector: darkIconSelector,
              color: stylingBox.iconColorDark(),
          })
        : '';

    const ruleColorCss = ruleColorSelector
        ? getBackgroundColorCss({
              selector: ruleColorSelector,
              value: stylingBox.ruleColor(),
          })
        : '';

    const boxStyles = selector ? getAllStyles(stylingBox, selector, customSelectors || {}) : [];

    return [...boxStyles, lightIconCss, darkIconCss, ruleColorCss].join(STYLES_SEPARATOR);
};
