import { register } from 'ce-common/service/templateRepository';

import alreadyApplied from '../templates/candidate-verification-already-applied.html';
import confirmedSubmission from '../templates/candidate-verification-confirmed-submission.html';
import unconfirmedSubmission from '../templates/candidate-verification-unconfirmed-submission.html';
import submissionAccept from '../templates/candidate-verification-submission-accept.html';
import pinCodeRequired from '../templates/candidate-verification-pin-code-required.html';
import removedSubmission from '../templates/candidate-verification-removed-submission.html';
import tokenVerification from '../templates/candidate-verification-token-verification.html';
import lastNameVerification from '../templates/candidate-verification-last-name-check.html';
import dateOfBirthVerification from '../templates/candidate-verification-date-of-birth-check.html';

register('candidate-verification-already-applied', alreadyApplied);
register('candidate-verification-confirmed-submission', confirmedSubmission);
register('candidate-verification-unconfirmed-submission', unconfirmedSubmission);
register('candidate-verification-submission-accept', submissionAccept);
register('candidate-verification-pin-code-required', pinCodeRequired);
register('candidate-verification-removed-submission', removedSubmission);
register('candidate-verification-token-verification', tokenVerification);
register('candidate-verification-last-name-check', lastNameVerification);
register('candidate-verification-date-of-birth-check', dateOfBirthVerification);
