import FormValidator from 'core/form/validator/Validator';

export default class DecimalFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.number';
    }

    _validate(value) {
        return !value || !window.isNaN(value);
    }

}