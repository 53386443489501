import i18n from 'core/i18n/i18n';
import candidate from 'apply-flow/model/candidate';
import candidateService from 'apply-flow/service/candidate';
import applicationService from 'apply-flow/service/application';
import synchronization, { COLLECTIONS } from 'apply-flow/service/synchronization';
import profileItemServiceFactory from 'apply-flow/module/profile-items/service/profileItemServiceFactory';
import personalIdentifyingInformationService from 'apply-flow/module/personal-identifying-information/service/personalIdentifyingInformation';
import candidateAttachmentService from 'apply-flow/module/file-upload/service/candidateAttachmentService';
import draftAttachmentService from 'apply-flow/module/file-upload/service/draftAttachmentService';
import postApplyService from '../../apply-flow-post/service/postApply';
import { saveExtraInformation } from '../module/extra-information/service/extraInformation';
import applicationDraftService from '../service/applicationDraft';
import submitChecklist from 'apply-flow/service/submit-checklist/submitChecklist';
import * as candidateLogger from 'apply-flow/service/candidateLogger';
import candidatePreferences from 'cx/module/job-alerts/service/candidatePreferences';
import { handleAllSettled } from 'core/utils/handleAllSettled';

import {
    SAVING_CANDIDATE,
    SAVING_APPLICATION,
    CREATING_CANDIDATE,
    labels as stageLabels,
} from '../enum/submitStages';

function storeBeforeSave() {
    const compositeProfileItemService = profileItemServiceFactory.getCompositeService();

    compositeProfileItemService.storeAll(candidate);

    const isNotEmptyLink = link => link.url() && link.id();

    candidate.linkedSites()
        .filter(isNotEmptyLink)
        .forEach(link => synchronization.store(link, COLLECTIONS.LINKS));

    candidate.linkedSites.getRemovedItems()
        .forEach(link => synchronization.store(link, COLLECTIONS.DELETED_LIKS));

    candidate.extraInformation()
        .forEach(eff => synchronization.store(eff, COLLECTIONS.EFF));

    candidate.personalIdentifyingInformationItems()
        .forEach(piiList => synchronization.store(piiList, COLLECTIONS.PII));

    if (candidate.dateOfBirth()) {
        synchronization.store(candidate.dateOfBirth(), COLLECTIONS.DOB);
    }
}

function saveProfileItems() {
    const compositeProfileItemService = profileItemServiceFactory.getCompositeService();

    return compositeProfileItemService.saveAll(candidate);
}

function savePersonalIdentifyingInformation() {
    return personalIdentifyingInformationService.save(candidate);
}

async function saveLinks() {
    const linksToRemove = [
        ...candidate.linkedSites.getRemovedItems(),
        ...candidate.linkedSites(),
    ];

    await candidateAttachmentService
        .removeLinks(candidate.id(), linksToRemove);

    candidate.linkedSites.clearRemovedItems();

    const links = await candidateAttachmentService
        .saveLinks(candidate.id(), candidate.linkedSites());

    candidate.linkedSites(links);
}

async function saveAttachments() {
    const removedFromProfile = candidate.attachments.getRemovedItems()
        .filter(({ appDraftId }) => !appDraftId());

    await candidateAttachmentService
        .removeAll(candidate.id(), removedFromProfile);

    candidate.attachments.clearRemovedItems();

    if (applicationDraftService.draftExists()) {
        await draftAttachmentService
            .submitAllDraftAttachments(candidate, candidate.attachments());

        return;
    }

    const attachments = await candidateAttachmentService
        .saveAttachments(candidate.id(), candidate.attachments());

    candidate.attachments(attachments);
}

function saveCandidateModificationDate() {
    return applicationDraftService.updateCandidateModificationDate();
}

function updateCandidateLastModificationDate(submittedCandidate) {
    candidateService.updateCandidateLastModificationDate(submittedCandidate);
}

function createApplication(application, jobId) {
    return applicationService.create(application, jobId, candidate);
}

function saveApplication(application) {
    return postApplyService.isPostApply()
        ? applicationService.saveSecondary(application, candidate)
        : applicationService.savePrimary(application, candidate);
}

function saveCandidatePreferences(jobId) {
    return candidatePreferences.saveForJobApply(candidate, jobId);
}

function getSaveCandidateStageName() {
    return candidate.isNew()
        ? CREATING_CANDIDATE
        : SAVING_CANDIDATE;
}

function prepareSaveCandidateStage() {
    const candidateSaveStage = getSaveCandidateStageName();

    submitChecklist.addStage({
        name: candidateSaveStage,
        label: i18n(stageLabels[candidateSaveStage]),
    });
}

function getSaveCandidateSteps() {
    const candidateSaveStage = getSaveCandidateStageName();

    const handleCandidate = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle candidate',
    }, candidateService.save, candidate);

    const handleProfileItems = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle profile items',
    }, saveProfileItems);

    const handlePersonalIdentifyingInformation = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle Personal Identifying Information',
    }, savePersonalIdentifyingInformation);

    const handleExtrainformation = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle Extra Information',
    }, saveExtraInformation, candidate, candidate.extraInformation());

    const handleLinks = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle Links',
    }, saveLinks);

    const handleAttachments = submitChecklist.addStep({
        stage: candidateSaveStage,
        step: 'Handle attachments',
    }, saveAttachments);

    return {
        handleCandidate,
        handleProfileItems,
        handlePersonalIdentifyingInformation,
        handleExtrainformation,
        handleLinks,
        handleAttachments,
    };
}

function prepareSaveApplicationStage() {
    submitChecklist.addStage({
        name: SAVING_APPLICATION,
        label: i18n(stageLabels[SAVING_APPLICATION]),
    });
}

function getSaveApplicationSteps(applicationModel, jobId) {
    const handleCandidateModificationDate = submitChecklist.addStep({
        stage: SAVING_APPLICATION,
        step: 'Handle Candidate Modification Date',
    }, saveCandidateModificationDate);

    const handleApplicationCreate = submitChecklist.addStep({
        stage: SAVING_APPLICATION,
        step: 'Handle Application Create',
    }, createApplication, applicationModel, jobId);

    const handleApplicationSave = submitChecklist.addStep({
        stage: SAVING_APPLICATION,
        step: 'Handle Application Save',
    }, saveApplication, applicationModel);

    const handleCandidatePreferences = submitChecklist.addStep({
        stage: SAVING_APPLICATION,
        step: 'Handle Candidate Preferences',
    }, saveCandidatePreferences, jobId);

    const handleCandidateLastModificationDate = submitChecklist.addStep({
        stage: SAVING_APPLICATION,
        step: 'Handle Candidate Modification Date',
    }, updateCandidateLastModificationDate, candidate);

    return {
        handleCandidateModificationDate,
        handleApplicationCreate,
        handleApplicationSave,
        handleCandidatePreferences,
        handleCandidateLastModificationDate,
    };
}

async function saveCandidate() {
    storeBeforeSave();
    prepareSaveCandidateStage();

    const {
        handleCandidate,
        handleProfileItems,
        handlePersonalIdentifyingInformation,
        handleExtrainformation,
        handleLinks,
        handleAttachments,
    } = getSaveCandidateSteps();

    submitChecklist.show();

    await handleCandidate();

    saveCandidateModificationDate();

    return Promise.allSettled([
        handleProfileItems(),
        handlePersonalIdentifyingInformation(),
        handleExtrainformation(),
        handleLinks(),
        handleAttachments(),
    ])
        .then(handleAllSettled);
}

async function saveCandidateAndApplication(applicationModel, jobId) {
    candidateLogger.logCandidateSnapshot();

    prepareSaveCandidateStage();
    prepareSaveApplicationStage();

    const {
        handleCandidatePreferences,
        handleCandidateModificationDate,
        handleApplicationCreate,
        handleApplicationSave,
        handleCandidateLastModificationDate,
    } = getSaveApplicationSteps(applicationModel, jobId);

    submitChecklist.show();

    await applicationDraftService.updateCandidateDrafts();

    await saveCandidate()
        .then(handleCandidateModificationDate);

    await handleCandidatePreferences();

    handleCandidateLastModificationDate();

    const application = await handleApplicationCreate()
        .then(handleApplicationSave);

    return application;
}

export default {
    saveCandidate,
    saveCandidateAndApplication,
    updateCandidateLastModificationDate,
};
