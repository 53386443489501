import notificationsService from 'cx/service/notifications';
import http from 'core/http/http';
import { UNAUTHORIZED } from 'candidate-verification/service/user-session/unauthorized';

const CANDIDATE_IS_VALID = 'is-valid';
const NOTIFICATION_DISMISS_TIMEOUT = 400;

let lastMessage = '';

export default class ValidationNotifications {

    sendAndNotify(config) {
        const { payload, ...requestConfig } = config;

        return this._sendRequest(payload, requestConfig)
            .catch(this._notify.bind(this));
    }

    clearNotifications() {
        lastMessage = '';
        notificationsService.dismissAll();
    }

    _sendRequest(data, config) {
        const {
            restEndpoint,
            successMessage,
            ignoredErrors = [],
            isUpdate = false,
        } = config;

        const httpConfig = {
            statusCodes: {
                400: ({ response }) => {
                    if (response.includes(successMessage) || ignoredErrors.includes(response)) {
                        return CANDIDATE_IS_VALID;
                    }

                    return response;
                },
            },
            headers: {
                'Ora-Irc-Validation-Mode': 'Y',
            },
        };

        const restHandler = isUpdate ? http.patch : http.post;

        return restHandler(restEndpoint, data, httpConfig);
    }

    _isValid(message) {
        return message === CANDIDATE_IS_VALID;
    }

    _notify(error) {
        if (error.response) {
            return this._handleGeneralError(error);
        }

        if (this._isValid(error)) {
            return this._handleSuccess();
        }

        return this._handleError(error);
    }

    _handleError(message) {
        if (this._isUnauthorizedMessage(message)) {
            return Promise.reject();
        }

        let notificationTimeout = 0;
        const formattedMessage = this._formatMessage(message);

        if (lastMessage !== formattedMessage) {
            notificationTimeout = NOTIFICATION_DISMISS_TIMEOUT;
            notificationsService.dismissAll();
        }

        notificationsService.error(formattedMessage, notificationTimeout, false);
        lastMessage = formattedMessage;

        return Promise.reject();
    }

    _handleGeneralError(error) {
        return Promise.reject(error);
    }

    _isUnauthorizedMessage(message) {
        return message === UNAUTHORIZED;
    }

    _handleSuccess() {
        lastMessage = '';
        notificationsService.dismissAll();

        return Promise.resolve();
    }

    _formatMessage(inputMessage) {
        const messages = inputMessage.split(/\n/).filter(message => Boolean(message));

        return messages.map(message => `<p>${message.trim()}</p>`).join('');
    }

}

export const validationNotifications = new ValidationNotifications();
