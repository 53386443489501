import { PureComputed, pureComputed } from 'knockout';
import { JobDetailsViewModel } from './JobDetailsViewModel';
import router from 'app/model/router';
import { DialogDisplaySettings } from '../../component/minimal-dialog/MinimalDialogViewModel';
import i18n from 'core/i18n/i18n';

export class JobDetailsModalViewModel extends JobDetailsViewModel {
    displaySettings: PureComputed<DialogDisplaySettings>;
    modalContainerClass: string;
    ariaLabel: string;

    constructor() {
        super();

        this.modalContainerClass = 'job-details-modal-container';
        this.ariaLabel = i18n('job-details.page-title');
        this.afterDialogClose = this.afterDialogClose.bind(this);
        this.displaySettings = pureComputed(this.computeDisplaySettings, this);
    }

    afterDialogClose(): void {
        const routeId = router.route().id;

        if (routeId === 'job-preview') {
            this.redirectToMainPage();
        }
    }

    redirectToMainPage(): void {
        const { query } = router.routeParams() as { query?: string };

        router.go('search', { query }, { inherit: false });
    }

    computeDisplaySettings(): DialogDisplaySettings {
        const { overlayOpacity, overlayStyle, overlayWidth, overlayWidthUnit } =
            this.viewData.customPage()?.params || {};

        return {
            opacity: overlayOpacity ? `${overlayOpacity}` : null,
            alignment: overlayStyle || null,
            width: overlayWidth && overlayWidthUnit ? `${overlayWidth}${overlayWidthUnit}` : null,
        };
    }
}
