import signals, {
    JOB_VIEWS,
    APPLY_FLOW_ENTERED,
    APPLY_FLOW_SUBMITTED,
    APPLY_FLOW_FINISHED,
    APPLY_FLOW_APPLICATION_CREATED,
    PROFILE_IMPORTS_FROM_INDEED,
    PROFILE_IMPORTS_FROM_LINKEDIN,
    PROFILE_IMPORTS_FROM_RESUME,
    USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL,
    USER_DECLINED_COOKIE_CONSENT_ANALYTICAL,
} from '../../config/userEvents';
import appConfig from 'app/model/config';
import { addScriptToHead } from 'core/utils/addScriptToHead';
import { isAnalyticalCookieAllowed } from '../cookieAgreement';
import cookieStorage from 'core/cookie/storage';

import { CLICK } from './eventTypes';
import userEvents from 'cx/config/userEvents';
import { INFINITY_TAG_SETTING_KEY } from 'app/service/globalSettings';

const COOKIE_ORA_FPC = 'ORA_FPC';

const LOG_LEVEL = appConfig.getSettingByKey('IRC_CE_REST_LOG_LEVEL') || 'ERROR';

function trackEvent(eventName, eventType) {
    if (!window.ORA) {
        return;
    }

    window.ORA.click({
        data: {
            'wt.ti': eventName,
            'wt.dl': eventType,
        },
    });
}

function _loadInfinity() {
    const infinityTagValue = appConfig.getSettingByKey(INFINITY_TAG_SETTING_KEY);

    if (!infinityTagValue) {
        return;
    }

    const url = `https://c.oracleinfinity.io/acs/account/fp3kyrmvtg/js/${infinityTagValue}/odc.js`;

    addScriptToHead(url);
}

function remove() {
    cookieStorage.remove(COOKIE_ORA_FPC);
}

function init() {
    if (!isAnalyticalCookieAllowed()) {
        return;
    }

    if (!window.ORA) {
        _loadInfinity();
    }

    signals[JOB_VIEWS].add(() => {
        trackEvent(JOB_VIEWS, CLICK);
    });

    signals[APPLY_FLOW_ENTERED].add(() => {
        trackEvent(APPLY_FLOW_ENTERED, CLICK);
    });

    signals[APPLY_FLOW_SUBMITTED].add(() => {
        trackEvent(APPLY_FLOW_SUBMITTED, CLICK);
    });

    signals[APPLY_FLOW_FINISHED].add(() => {
        trackEvent(APPLY_FLOW_FINISHED, CLICK);
    });

    signals[APPLY_FLOW_APPLICATION_CREATED].add(() => {
        if (LOG_LEVEL === 'ALL') {
            trackEvent(APPLY_FLOW_APPLICATION_CREATED, CLICK);
        }
    });

    signals[PROFILE_IMPORTS_FROM_INDEED].add(() => {
        trackEvent(PROFILE_IMPORTS_FROM_INDEED, CLICK);
    });

    signals[PROFILE_IMPORTS_FROM_LINKEDIN].add(() => {
        trackEvent(PROFILE_IMPORTS_FROM_LINKEDIN, CLICK);
    });

    signals[PROFILE_IMPORTS_FROM_RESUME].add(() => {
        trackEvent(PROFILE_IMPORTS_FROM_RESUME, CLICK);
    });
}

userEvents[USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL].add(init);
userEvents[USER_DECLINED_COOKIE_CONSENT_ANALYTICAL].add(remove);

export default {
    init,
};