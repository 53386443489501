import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

const DATE_ACQUIRED_IN_PAST = 'apply-flow.section-experience.date-acquired-in-past-validator-message';
const DATE_ACQUIRED_IN_FUTURE = 'apply-flow.section-experience.date-acquired-in-future-validator-message';

/**
 * Graduate Indicator should be checked if Year acquired is in past.
 * Graduate Indicator should be unchecked if Year acquired is in future.
 */
export default class GraduatedFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        ko.utils.extend(this._options, {
            DATE_ACQUIRED: this._options.dateAcquired.label(),
        });
    }

    _validate(value, options) {
        const currentDate = new window.Date();
        const dateAcquired = options.dateAcquired.value();

        if (dateAcquired) {
            if (value !== 'Y' && dateAcquired <= currentDate) {
                this._message = DATE_ACQUIRED_IN_PAST;

                return false;
            } else if (value === 'Y' && dateAcquired > currentDate) {
                this._message = DATE_ACQUIRED_IN_FUTURE;

                return false;
            }
        }

        return true;
    }

}