
import CandidateEducation from './../model/CandidateEducation';
import CandidateExperience from './../model/CandidateExperience';
import CandidateLicensesAndCertificates from './../model/CandidateLicensesAndCertificates';
import CandidateWorkPreferences from './../model/CandidateWorkPreferences';
import CandidateLanguage from './../model/CandidateLanguage';
import CandidateSkill from './../model/CandidateSkill';
import contentTypes from 'apply-flow/module/profile-items/enum/contentTypes';

const MODELS = {
    [contentTypes.CERTIFICATION_ID]: CandidateLicensesAndCertificates,
    [contentTypes.EDUCATION_ID]: CandidateEducation,
    [contentTypes.LANGUAGE_ID]: CandidateLanguage,
    [contentTypes.WORK_PREFERENCE_ID]: CandidateWorkPreferences,
    [contentTypes.EXPERIENCE_ID]: CandidateExperience,
    [contentTypes.SKILL_ID]: CandidateSkill,
};

export default {
    getModel(contentTypeId) {
        return MODELS[contentTypeId];
    },
};