import { components } from 'knockout';

import ViewModel from './TalentCommunityButtonViewModel';
import template from 'custom-content/component/talent-community-button/talent-community-button.html';

components.register('cc-talent-community-button', {
    viewModel: {
        createViewModel(params) {
            return new ViewModel(params, 'button');
        },
    },
    template,
});