import { Observable, PureComputed, observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import ValidatableForm from 'core/form/ValidatableForm';
import formBuilder from 'core/form/formBuilder';
import ValidatableFormElement from 'core/form/element/ValidatableElement';
import formElementFactory from 'core/form/element/factory';
import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { CookieConsentModalCategoriesParams } from './config/types';
import { CookieCategories, CookieConsentWidgetData } from '../../types';
import {
    isAnalyticalCookieAllowed,
    isCustomCategoryCookieAllowed,
    isFunctionalCookieAllowed,
} from 'cx/service/cookieAgreement';
import { getCustomStyles } from './config/customStyles';

type Props = {
    pageData: PureComputed<CookieConsentWidgetData>;
    params?: CookieConsentModalCategoriesParams;
    id?: string;
};

type CookieCategory = {
    title: string;
    description: string;
    isActive: Observable<boolean>;
    type: CookieCategories;
};

export class CookieConsentModalCategoriesViewModel extends CustomComponentViewModel<CookieConsentModalCategoriesParams> {
    pageData: PureComputed<CookieConsentWidgetData>;
    cookieCategories = CookieCategories;
    form: ValidatableForm | undefined;
    model:
        | {
              isFunctionalCookieEnabled: Observable<boolean>;
              isAnalyticalCookieEnabled: Observable<boolean>;
              isCustomCookieCategoryEnabled: Observable<boolean>;
          }
        | undefined;

    categories: CookieCategory[];

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;

        this.model = pageData().categoriesSelectionModel;
        this.categories = this.getCookieCategories();

        this.form = this.createForm();

        this.setDefaultSelection();

        this.customCss = pureComputed(() => this.getCustomStyle());
    }

    getCustomStyle(): string {
        return getCustomStyles(this.customizationParams, this.uniqueWrapperClass);
    }

    setDefaultSelection(): void {
        if (!this.model) {
            return;
        }

        this.model.isFunctionalCookieEnabled(isFunctionalCookieAllowed());
        this.model.isAnalyticalCookieEnabled(isAnalyticalCookieAllowed());
        this.model.isCustomCookieCategoryEnabled(isCustomCategoryCookieAllowed());
    }

    createForm(): ValidatableForm | undefined {
        if (!this.model) {
            return;
        }

        const form = formBuilder.createForm();
        const elements = [];

        elements.push(
            this.createCategoryEnabledElement(
                `${CookieCategories.functional}_checkbox`,
                this.model.isFunctionalCookieEnabled,
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.functional-cookies-label'
            )
        );

        elements.push(
            this.createCategoryEnabledElement(
                `${CookieCategories.analyticalAndPerformance}_checkbox`,
                this.model.isAnalyticalCookieEnabled,
                'admin.theme-editor.theme-editor-panel.cookie-consent-config.analytical-performace-cookies-label'
            )
        );

        if (this.pageData?.()?.isCustomCategoryActive?.()) {
            elements.push(
                this.createCategoryEnabledElement(
                    `${CookieCategories.custom}_checkbox`,
                    this.model.isCustomCookieCategoryEnabled,
                    this.pageData().cookiePreferenceCustomCategoryLabel?.() || ''
                )
            );
        }

        form.elements(elements);

        return form;
    }

    createCategoryEnabledElement(
        name: string,
        model: Observable<boolean>,
        label: string
    ): ValidatableFormElement {
        const formElement = formElementFactory.create('checkbox', name);

        formElement.attributes({
            cssClass: 'cookie-preference_checkbox',
        });

        formElement.isSlider(true);
        formElement.label(i18n(label));

        formElement.registerModel(model);

        return formElement;
    }

    getCookieCategories(): CookieCategory[] {
        if (!this.model) {
            return [];
        }

        const categories = [
            {
                title: i18n(
                    'admin.theme-editor.theme-editor-panel.cookie-consent-config.strictly-necessary-cookies-label'
                ),
                description: this.pageData().cookiePreferenceStrictlyNecessaryDescription?.() || '',
                isActive: observable<boolean>(true),
                type: CookieCategories.scrictlyNecessary,
            },
            {
                title: i18n(
                    'admin.theme-editor.theme-editor-panel.cookie-consent-config.functional-cookies-label'
                ),
                description: this.pageData().cookiePreferenceFunctionalDescription?.() || '',
                isActive: this.model.isFunctionalCookieEnabled,
                type: CookieCategories.functional,
            },
            {
                title: i18n(
                    'admin.theme-editor.theme-editor-panel.cookie-consent-config.analytical-performace-cookies-label'
                ),
                description: this.pageData().cookiePreferenceAnalyticalDescription?.() || '',
                isActive: this.model.isAnalyticalCookieEnabled,
                type: CookieCategories.analyticalAndPerformance,
            },
        ];

        if (this.pageData?.()?.isCustomCategoryActive?.()) {
            categories.push({
                title: this.pageData().cookiePreferenceCustomCategoryLabel?.(),
                description: this.pageData().cookiePreferenceCustomCategoryDescription?.() || '',
                isActive: this.model.isCustomCookieCategoryEnabled,
                type: CookieCategories.custom,
            });
        }

        return categories;
    }
}
