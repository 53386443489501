import AbstractStorage from 'core/storage/abstractStorage';
import sessionPersistence from './sessionPersistence';
import candidateVerificationEvents from 'candidate-verification/config/events';

class UserSessionStorage extends AbstractStorage {

    store(key, data) {
        this._removeItemFromOtherStorage(key);

        const store = super.store(key, data);

        candidateVerificationEvents.userSessionChanged.dispatch();

        return store;
    }

    remove(key) {
        this._removeItemFromOtherStorage(key);

        const store = super.remove(key);

        candidateVerificationEvents.userSessionRemoved.dispatch();

        return store;
    }

    _getStorageHandler() {
        if (sessionPersistence.isActive()) {
            return window.localStorage;
        }

        return window.sessionStorage;
    }

    _removeItemFromOtherStorage(key) {
        const otherStorage = this._getStorageHandler() === window.localStorage
            ? window.sessionStorage
            : window.localStorage;

        otherStorage.removeItem(key);
    }

}

const storage = new UserSessionStorage();

export default storage;