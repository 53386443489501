import appConfig from 'app/model/config';
import i18n from 'core/i18n/i18n';
import cxEvents from 'cx/config/events';
import a11yEvents from 'core/a11y/events';

const ADMIN_PAGE_TITLE = 'Setup: Configure Career Site - Setup and Maintenance - Oracle Applications';

export default class PageTitle {

    constructor() {
        this._siteName = appConfig.siteName;
        this._routeTitle = null;
        this._pageTitleUpdateEvent = cxEvents.pageTitleUpdate.add(title => this.setTitleWithTemplate(title));
    }

    _resolvePageTitle(title) {
        if (!this._siteName) {
            return ADMIN_PAGE_TITLE;
        }

        return [this._routeTitle, title, `${this._siteName} ${i18n('general.title.ending')}`]
            .filter(titleFragment => Boolean(titleFragment))
            .join(' - ');
    }

    setTitle(title) {
        document.title = title;

        a11yEvents.status.dispatch(
            i18n('general.a11y.page-loaded', { pageTitle: title }),
        );
    }

    setTitleWithTemplate(title) {
        const pageTitle = this._resolvePageTitle(title);

        this.setTitle(pageTitle);
    }

    setRouteTitle(routeTitle) {
        this._routeTitle = routeTitle;
    }

    dispose() {
        this._pageTitleUpdateEvent.detach();
    }

}