import applicationService from './application';

export type CachedCandidateApplication = { id: string; confirmedFlag: boolean };

class CachedCandidateApplications {
    private candidateApplications: CachedCandidateApplication[] = [];
    private isDataSet = false;

    async get(): Promise<CachedCandidateApplication[]> {
        if (this.isDataSet) {
            return this.candidateApplications;
        }

        this.candidateApplications = await applicationService.queryCandidateApplications();
        this.isDataSet = true;

        return this.candidateApplications;
    }

    clear(): void {
        this.isDataSet = false;
        this.candidateApplications = [];
    }
}

export const cachedCandidateApplications = new CachedCandidateApplications();
