import ko from 'knockout';
import router from 'app/model/router';
import cxEvents from 'cx/config/events';
import applyFlowEvents from 'apply-flow/config/events';
import cachedJob from '../../service/cachedJob';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import { generateJobAttributes, generateJobFlexFields } from 'cx/module/job-details/service/jobAttributes';
import postApplyService from '../../../apply-flow-post/service/postApply';
import applicationService from '../../service/application';
import jobDetails from '../../model/jobDetails.js';
import profileImport from '../../module/profile-import/service/profileImport';

export default class ApplyFlowHeaderViewModel {

    constructor({ flow, redirectOnJobError = true, closeAction = null, summaryTemplate }) {
        this.job = ko.observable();
        this.jobAttributes = ko.observable();
        this.flow = flow();
        this.sections = this.flow.sections;
        this.iterator = this.flow.iterator.bind(this.flow);
        this.isSingleClick = flow().isSingleClick;
        this.inViewport = ko.observable();
        this.userLanguage = siteLanguage.restore();
        this.isPostApply = postApplyService.isPostApply();
        this.summaryTemplate = summaryTemplate;

        this.closeAction = closeAction || this._closeAction.bind(this);

        this._currentPageSub = this.iterator().currentSection.subscribe(() => this._updatePageTitle());

        this._updateSubmissionPageTitleSignal =
            applyFlowEvents.submitSucceed.add(() => this._updateSubmissionPageTitle());

        cachedJob.clear();
        jobDetails.clear();
        this.jobTitle = jobDetails.title;

        this._loadJob(redirectOnJobError)
            .then(() => this._updatePageTitle());

        this.isFlowDataAvailable = ko.pureComputed(() => this.job() || this.jobTitle());
    }

    _loadJob(redirectOnJobError) {
        if (this.isPostApply) {
            return this._getTitleFromSubmission(redirectOnJobError);
        }

        return cachedJob.getJob(router.routeParams().jobId)
            .then((job) => {
                this.job(job);
                this.jobTitle(job.title);
                this.jobAttributes(this._getJobAttributes());
            }).catch(() => this._handleGetJobFailure(redirectOnJobError));
    }

    _handleGetJobFailure(redirectOnJobError) {
        if (redirectOnJobError) {
            router.go('job-details');
        }
    }

    _getTitleFromSubmission(redirectOnJobError) {
        return applicationService.getSubmission(router.routeParams().submissionId)
            .then((submission) => {
                this.jobTitle(submission.jobTitle);
            }).catch(() => this._handleGetJobFailure(redirectOnJobError));
    }

    _updatePageTitle() {
        const title = this.jobTitle() || '';
        const currentSection = this.iterator().currentSection() || {};

        let pageTitle = `${title}`;

        if (!this.isSingleClick && currentSection.title) {
            pageTitle = `${currentSection.title} - ${pageTitle}`;
        }

        cxEvents.pageTitleUpdate.dispatch(pageTitle);
    }

    _updateSubmissionPageTitle() {
        const pageTitle = this.jobTitle() || '';

        cxEvents.pageTitleUpdate.dispatch(pageTitle);
    }

    _closeAction() {
        const { jobId } = router.routeParams();
        const route = router.route();

        profileImport.clearIndeedProfileFromCache();

        if (route.parent && route.parent.id === 'job-preview') {
            router.go('job-preview', {
                jobId,
            });
        } else {
            router.go('job-details', { jobId });
        }
    }

    _getJobAttributes() {
        return Object.values(generateJobAttributes(this.job()))
            .concat(...generateJobFlexFields(this.job()))
            .filter(({ id }) => id !== 'locations');
    }

    dispose() {
        this._updateSubmissionPageTitleSignal.detach();
        this._currentPageSub.dispose();
    }

}
