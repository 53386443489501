import { CustomWindow } from 'app/module/minimal/component/splash-page/config/types';

type PositionJSON = {
    bottom?: string;
    right?: string;
    left?: string;
    top?: string;
};

/*
if site language is Arabic the default chatbot position is {bottom: 20px, left: 20px}
else it is {bottom: 20px, right: 20px}
*/
export const getDefaultChatBotPosition = (): PositionJSON => {
    if (document.dir == 'rtl') {
        return {
            bottom: '20px',
            left: '20px',
        };
    }

    return {
        bottom: '20px',
        right: '20px',
    };
};

export const parseBotPositionAttribute = (position: string): PositionJSON => {
    const keyMatcher = '([^",{}\\s]+?)';
    const valMatcher = '(.,*)';
    const singleQuoteMatcher = /\s*'\s*/g;
    const matcher = new RegExp(`${keyMatcher}\\s*:\\s*${valMatcher}`, 'g');
    const parser = (match: string, key: string, value: string) => `"${key}":${value}`;

    const positionJsonString = position.replace(matcher, parser).replace(singleQuoteMatcher, '"');

    try {
        return JSON.parse(positionJsonString);
    } catch {
        return getDefaultChatBotPosition();
    }
};

/**
 * Returns true or false based on the url. We are skipping the bot initialization for these urls containing
 * 1. CandExpSecured/en/offers-preview/
 * 2. admin/events/preview/
 * 3. admin/job-template-preview/
 * 4. admin/job-preview/
 */

export const shouldInitializeBot = (url: string): boolean => {
    if (url.includes('CandExpSecured') && url.includes('offers-preview')) {
        return false;
    }

    if (url.includes('admin') && url.includes('events') && url.includes('preview')) {
        return false;
    }

    if (url.includes('admin') && (url.includes('job-template-preview') || url.includes('job-preview'))) {
        return false;
    }

    return true;
};

export const closeBot = (): void => {
    const { bots } = (window as unknown as CustomWindow) || {};

    bots && bots.isChatOpened() ? bots.closeChat() : null;
};
