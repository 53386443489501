export type ApiCaptchaConfig = {
    componentName?: string;
};

export type Providers = 'hCAPTCHA';

type ApiCaptchaTypes = {
    [key in Providers]?: ApiCaptchaConfig;
};

export const apiCaptcha: ApiCaptchaTypes = {
    hCAPTCHA: {
        componentName: 'invisible-hCaptcha',
    },
};
