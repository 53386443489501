import { CONSOLE_ERROR } from './config/entryType';
import apiLogger from './apiLogger';

function isErrorObject(data) {
    return data?.stack && data?.message;
}

export function logConsoleError(data) {
    const logData = data.map((element) => {
        if (isErrorObject(element)) {
            return {
                message: element.message,
                stack: element.stack,
            };
        }

        return element;
    });

    apiLogger.log({
        type: CONSOLE_ERROR,
        data: logData,
    });
}