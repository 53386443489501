import ko from 'knockout';
import ESignature from './model/ESignature';
import router from 'app/model/router';
import offerEvents from '../../config/events';
import offerService from '../../service/offer';
import eSignatureFormBuilder from '../../service/eSignatureFormBuilder';
import appEvents from 'app/config/events';
import tokenService from 'candidate-verification/service/token';

export default class ESignatureViewModel {

    constructor(offerModel) {
        if (offerModel && offerModel.isOfferAccepted()) {
            router.go('offer');
        }

        this.model = new ESignature();
        this.form = ko.observable({});
        this.isValid = ko.observable(true);
        this.isOfferBeingAccepted = ko.observable(false);
        this.eSignatureInstructions = null;
        this.offerModel = offerModel;

        if (offerModel) {
            this.eSignatureInstructions = offerModel.esignatureInstructions;
        }

        this._createForm();

        this.isSubmitButtonDisabled = ko.pureComputed(() => {
            const isFormValid = this.form().isValid();
            const isSignatureValid = this.isValid();

            return !isFormValid || !isSignatureValid;
        });

        this._fullNameSub = this.model.fullName.subscribe(() => {
            this.isValid(true);
        });
    }

    _createForm() {
        const form = eSignatureFormBuilder.createForm();

        form.registerModel(this.model);

        this.form(form);
    }

    submit() {
        if (this.form().isValid() && this.isValid()) {
            this.isOfferBeingAccepted(true);

            offerService.accept(tokenService.get().accessCode, this.model.fullName())
                .then(offerEvents.accept.dispatch)
                .then(() => {
                    this.isOfferBeingAccepted(false);
                    router.go('offer', { freshlyAccepted: true });
                })
                .catch((err) => {
                    this.isOfferBeingAccepted(false);

                    if (err === 'invalid-esign') {
                        this.isValid(false);
                    } else {
                        appEvents.error.dispatch();
                    }
                });
        }
    }

    dispose() {
        this._fullNameSub.dispose();
    }

}