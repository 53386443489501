import ko from 'knockout';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import siteLanguage from 'ce-common/service/language/siteLanguage';

/**
 * Adds proper attribute with value of route URL, based on passed route name.
 * The attribute name is based on element's tag name:
 *  - for 'A' the attribute is 'href'
 *  - otherwise it's 'action' (f.e. forms)
 *
 * @params {string} - name of route which URL will be used.
 *
 * @example
 * <!-- let's say for route 'vino' we have defined URL '/good/wines' -->
 * <a data-bind="route: 'vino'">Go to list of good wines</a> <!-- <a href="good/wines"></a> -->
 */
ko.bindingHandlers.route = {
    // TODO: RemoveHash: change to update and fix multiple click events
    init(element, valueAccessor, allBindings) {
        const attrName = element.tagName === 'A' ? 'href' : 'action';

        const routeName = ko.unwrap(valueAccessor());
        const routeParams = ko.unwrap(allBindings.get('routeParams'));
        const routeConfig = ko.unwrap(allBindings.get('routeConfig'));
        const routeEvents = ko.unwrap(allBindings.get('routeEvents'));
        const lang = ko.unwrap(allBindings.get('routeLang')) || siteLanguage.get();
        const isAbsoluteUrl = ko.unwrap(allBindings.get('isAbsoluteUrl')) || lang !== siteLanguage.get();

        let url = !isAbsoluteUrl
            ? router.interpolate(routeName, routeParams, { action: 'utmLink', ...routeConfig })
            : absoluteRouter.interpolate({ lang }, routeName, routeParams, routeConfig);

        if (!isAbsoluteUrl) {
            const clickHandler = (event) => {
                event.preventDefault();

                const { onBeforeRedirect } = routeEvents || {
                    onBeforeRedirect: () => Promise.resolve(true),
                };

                onBeforeRedirect().then((hasToBeRedirected) => {
                    if (hasToBeRedirected) {
                        router.go(routeName, routeParams, routeConfig);
                    }
                });
            };

            element.addEventListener('click', clickHandler);
            url = document.baseURI.replace(/\/$/, '') + url;
        }

        element.setAttribute(attrName, url);
    },
};