import {
    mapWorkLocationToString,
    mapRecruitingLocationToString,
} from 'cx/module/job-details/mapper/location';
import { SearchMarker } from 'ce-common/module/maps/model/SearchMarker';
import { Coordinates } from 'ce-common/module/maps/model/Coordinates';
import {
    SearchJobResultDetail,
    Address,
    ReqDetails,
    ReqLocationCoordinates,
    MapLocationCoordinateDetails,
} from 'ce-common/module/maps/config/types';
import { lowercaseKeys } from 'ce-common/module/maps/config/utils';

const LOCATION_TYPES = {
    WORK_LOCATION: 'WORK_LOCATION',
    RECRUITING_LOCATION: 'LOCATION',
};

function getLocationAddress(location: Address) {
    return location.locationType === LOCATION_TYPES.WORK_LOCATION
        ? mapWorkLocationToString(location)
        : mapRecruitingLocationToString(location);
}

function mapLocationCoordinatesFromRest(reqLocationCoordinates: ReqLocationCoordinates): SearchMarker[] {
    const { latitude, longitude, requisitionId, locationId } = lowercaseKeys(reqLocationCoordinates);

    return [
        {
            coordinates: new Coordinates({ latitude, longitude }),
            jobId: requisitionId,
            locationId,
        },
    ]
        .filter(({ coordinates }) => coordinates.validate())
        .map((location) => new SearchMarker(location));
}

function mapLocationCoordinateDetailsFromRest({
    locationCoordinates,
    reqBasicInfo,
}: ReqDetails): MapLocationCoordinateDetails[] {
    const [{ requisitionId, requisitionTitle, requisitionNumber }] = reqBasicInfo;

    return locationCoordinates
        .map((location) => ({
            address: getLocationAddress(location),
            id: requisitionId,
            title: requisitionTitle,
            number: requisitionNumber,
            locationId: location.locationId,
        }))
        .filter(({ address }) => address);
}

export const mapJobsFromRest = (jobs: ReqLocationCoordinates[]): SearchMarker[] => {
    return jobs.map(mapLocationCoordinatesFromRest).flat();
};

export const mapJobDetailsFromRest = ({
    reqDetails,
}: SearchJobResultDetail): MapLocationCoordinateDetails[] =>
    reqDetails.map(mapLocationCoordinateDetailsFromRest).flat();
