import { observable, Observable } from 'knockout';
import { WorkplaceType } from 'cx/config/constants/workplaceType';
import { getWorkplaceTypeName } from 'app/service/workplaceType';

const MODE_TOOLTIP = 'tooltip';
const MODE_NORMAL = 'normal';

type ModeType = typeof MODE_TOOLTIP | typeof MODE_NORMAL;

type Props = {
    primaryLocation: string;
    secondaryLocations: string[];
    workplaceType: WorkplaceType;
    mode: ModeType;
};

export class PostingLocationsViewModel {
    primaryLocation: string;
    secondaryLocations: string[];
    workplaceTypeName: string | null;
    secondaryLocationsCount: number;
    isTooltipVisible: Observable<boolean>;
    postingLocations: string[];
    mode: ModeType;
    tooltipData: string[];
    tooltipContent: string;
    postingLocationsContent: string;

    constructor({ primaryLocation, secondaryLocations, workplaceType, mode }: Props) {
        this.primaryLocation = primaryLocation || '';
        this.secondaryLocations = secondaryLocations || [];
        this.workplaceTypeName = getWorkplaceTypeName(workplaceType);
        this.mode = mode || MODE_NORMAL;
        this.secondaryLocationsCount = this.secondaryLocations?.length;
        this.postingLocations = [primaryLocation, ...this.secondaryLocations];
        this.postingLocationsContent = this.getContent(this.postingLocations);

        this.tooltipData = this.workplaceTypeName
            ? [...this.postingLocations, this.workplaceTypeName]
            : this.postingLocations;

        this.tooltipContent = this.getContent(this.tooltipData);
        this.isTooltipVisible = observable<boolean>(false);
    }

    getContent(data: string[]): string {
        if (this.secondaryLocationsCount === 0) return data?.join('<br>');

        return data?.join('<br>').concat('<br>');
    }

    showTooltip(): void {
        this.isTooltipVisible(true);
    }

    hideTooltip(): void {
        this.isTooltipVisible(false);
    }

    isTooltipMode(): boolean {
        return this.mode === MODE_TOOLTIP;
    }
}
