function getFormattedDate(date, defaultYear, defaultMonth, defaultDay) {
    const dateMonth = date.month ? `0${date.month}`.substr(-2) : defaultMonth;

    return `${defaultYear}-${dateMonth}-${defaultDay}`;
}

export default {
    isSameYear(startDate = {}, endDate = {}) {
        if (!startDate) {
            return false;
        }

        if (!startDate.year) {
            return false;
        }

        if (!endDate) {
            return false;
        }

        if (!endDate.year) {
            return false;
        }

        return startDate.year === endDate.year;
    },
    getStartDate(date) {
        return getFormattedDate(date, date.year, '01', '01');
    },
    getEndDate(date) {
        return getFormattedDate(date, date.year, '12', '01');
    },
    getSchoolYearStartDate(date) {
        return getFormattedDate(date, date.year, '09', '01');
    },
    getSchoolYearEndDate(date) {
        return getFormattedDate(date, date.year, '07', '01');
    },
};