export default class ScheduledInterview {

    constructor(data = {}) {
        this.interviewRequestId = data.interviewRequestId;
        this.interviewId = data.interviewId;
        this.submissionId = data.submissionId;
        this.scheduleId = data.scheduleId;
        this.isScheduled = data.isScheduled;
        this.isReschedulable = data.isReschedulable;
    }

}