import storageService from 'core/storage/sessionStorage';
import router from 'app/model/router';
import absoluteRouter from 'app/model/absoluteRouter';
import config from 'app/model/config';
import siteLanguageChangedService from './siteLanguageChanged';
import siteLanguageCookie from './siteLanguageCookie';

const SITE_LANGUAGE_STORAGE_KEY = 'siteLanguage';

export default {
    set(lang) {
        siteLanguageChangedService.registerChange();
        siteLanguageCookie.set(lang);

        const url = absoluteRouter.interpolate({ lang }, router.route().id, { }, { encode: false });

        router.redirect(url);
    },

    get() {
        return config.siteLang;
    },

    getFusionCode(contentLocaleLang) {
        const siteLang = contentLocaleLang ?? config.siteLang;

        return config.languages.filter(lang => lang.code === siteLang)
            .map(lang => lang.fusionCode).pop();
    },

    store() {
        storageService.store(SITE_LANGUAGE_STORAGE_KEY, this.get());
    },

    restore() {
        return storageService.restore(SITE_LANGUAGE_STORAGE_KEY);
    },
};
