import { pureComputed, observable } from 'knockout';
import router from 'app/model/router';
import offerService, { OFFER_STATUS } from './service/offer';
import offerEvents from './config/events';
import candidateVerificationEvents from 'candidate-verification/config/events';
import Offer from './model/Offer';
import CandidateChallengeAbstractViewModel from 'candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import challengeService from 'candidate-verification/service/challenge';
import tokenService from 'candidate-verification/service/token';
import notificationsService from 'cx/service/notifications';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import sessionPersistence from 'candidate-verification/service/user-session/sessionPersistence';

export default class OfferViewModel extends CandidateChallengeAbstractViewModel {

    constructor() {
        super();

        this.offer = observable();
        this.isPinRequired = observable();
        this.isHeaderAndFooterVisible = pureComputed(() => this.offer() && !this.isPinRequired());

        this._initialize = this._initialize.bind(this);
        offerEvents.accept.add(this._initialize);
        offerEvents.refuse.add(this._initialize);
        this._userSessionRemovedSignal = candidateVerificationEvents.userSessionRemoved.add(this._navigateToHome);

        this._initialize();
    }

    dispose() {
        this._userSessionRemovedSignal.detach();
    }

    onPinValid() {
        this.isPinRequired(false);
        this._initialize();
    }

    async _initialize() {
        if (this._shouldTriggerChallenge()) {
            await this._triggerChallenge()
                .then(this._onTriggeredChallenge.bind(this))
                .catch((error) => {
                    this._handleError(error);

                    this.isPinRequired(challengeService.isChallengeRequired());
                });

            if (this.isPinRequired()) {
                return;
            }
        }

        if (!this.isPreview()) {
            this._initializeOffer();
        } else {
            this._initializePreview();
        }
    }

    _shouldTriggerChallenge() {
        return (!tokenService.accessCodeExists() || sessionPersistence.isActive())
            && challengeService.isChallengeRequired();
    }

    _initializeOffer() {
        offerService.calculateOffer().then((offerData) => {
            if (offerData) {
                switch (offerData.status) {
                    case OFFER_STATUS.REJECTED:
                    case OFFER_STATUS.UNAVAILABLE:
                        router.go('offer-inactive');
                        break;
                    case OFFER_STATUS.REFUSED:
                        router.go('offer-refused');
                        break;
                    default:
                        this.offer(new Offer(offerData));
                }
            }
        }).catch(error => this._handleError(error));
    }

    _initializePreview() {
        offerService.getOfferDataContent({
            offerId: router.routeParams().query.offerId,
            format: 'html',
        })
            .then(offerData => this.offer(new Offer(offerData)))
            .catch((error) => {
                notificationsService.error();
                console.error(error);
            });
    }

    isPreview() {
        return router.routeParams().query && router.routeParams().query.preview;
    }

    _navigateToHome() {
        router.go('home-page');
    }

    _handleError(error) {
        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);

            return;
        }

        super._handleError(error);
    }

    _onTriggeredChallenge(challengeRequired) {
        this.handleMergedCandiate();
        this.isPinRequired(challengeRequired);
    }

}