
import router from 'app/model/router';
import { PROVIDER_STATUSES } from '../../config/maps';

export class AbstractMapProvider {

    constructor() {
        this.scriptUrl = '';
        this.deepLinkUrl = '';
        this.initCallbacks = [];
        this.status = PROVIDER_STATUSES.NOT_INITIALIZED;
    }

    _onScriptLoaded() {
        this.status = PROVIDER_STATUSES.READY;

        this.initCallbacks.forEach(callback => callback());

        this.initCallbacks = [];
    }

    _goToJobPreview(jobId) {
        router.go('job-preview', { jobId });
    }

    generateDirectionsDeepLink({ latitude, longitude }) {
        return this.deepLinkUrl
            .replace('{latitude}', latitude)
            .replace('{longitude}', longitude);
    }

    initialize() {
        throw new Error('Method not implemented');
    }

    createMap() {
        throw new Error('Method not implemented');
    }

    isProviderReady() {
        throw new Error('Method not implemented');
    }

    createPosition() {
        throw new Error('Method not implemented');
    }

    createMarker() {
        throw new Error('Method not implemented');
    }

    createPopup() {
        throw new Error('Method not implemented');
    }

    showPopup() {
        throw new Error('Method not implemented');
    }

    createClusterLayer() {
        throw new Error('Method not implemented');
    }

    updateClusterLayer() {
        throw new Error('Method not implemented');
    }

    setZoomLevel() {
        throw new Error('Method not implemented');
    }

    setCenter() {
        throw new Error('Method not implemented');
    }

    adjustBoundsForMultipleCoordinates() {
        throw new Error('Method not implemented');
    }

    clearMarkers() {
        throw new Error('Method not implemented');
    }

}