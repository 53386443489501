import ProfileImportConfig from '../model/ProfileImportConfig';

function _toCamelCase(str) {
    return str.replace(/_([a-z])/g, match => match[1].toUpperCase());
}

export default {
    mapFromRest(restCollection) {
        const piConfig = {};

        restCollection.items.forEach((restConfig) => {
            const outerKey = _toCamelCase(restConfig.provider.toLowerCase());

            piConfig[outerKey] = {};

            Object.keys(restConfig)
                .filter(key => key.indexOf('provider') === -1)
                .forEach((innerKey) => {
                    if (restConfig[innerKey]) {
                        piConfig[outerKey][innerKey] = restConfig[innerKey];
                    }
                });
        });

        return new ProfileImportConfig(piConfig);
    },
};