import { observable, unwrap } from 'knockout';
import addressFormBuilder from '../../form/addressFormBuilder';
import sectionValidator from '../../../../model/sectionValidator';
import candidateModel from '../../../../model/candidate';
import { checkAddressAlreadyAvailable, getCWKAddressProfileOption } from 'apply-flow/service/cwkAddressCheck';
import { addressBackupInstance } from '../../../../mapper/addressBackup';


export default class AddressFormViewModel {

    constructor({ countryFormConfig, addressFormConfig, sectionNumber, customJsValidation, sectionDisabled }) {
        this.sectionNumber = sectionNumber;
        this.addressModel = candidateModel.address;
        this.countryForm = observable();
        this.addressForm = observable();
        this.customJsValidation = customJsValidation;
        this.cwkCandidate = candidateModel.basicInformation.cwkCandidate();
        this.addressAlreadyAvailableCWK = observable();
        this.sectionDisabled = sectionDisabled;

        this._addressFormConfigSub = addressFormConfig.subscribe((config) => {
            sectionValidator.unregisterForm(this.addressForm, this.sectionNumber);
            this.addressForm(null);
            this._createAddressForm(config);
        });

        if (this.cwkCandidate && !getCWKAddressProfileOption()) {
            this._addressAlreadyAvailableCWKSub = this.addressAlreadyAvailableCWK
                .subscribe((addressAlreadyPresentCWK) => {
                    if (addressAlreadyPresentCWK) {
                        this.sectionDisabled(true);
                        this.disableAddressAndCountryForm();
                    }
                });
        }


        this._createCountryForm(unwrap(countryFormConfig));
        this._createAddressForm(unwrap(addressFormConfig));
    }

    _createAddressForm(formConfig) {
        formConfig.countryElement = this.countryForm().countryElement;

        const addressForm = addressFormBuilder.createAddressForm(formConfig);

        addressForm.registerModel(this.addressModel);
        this.customJsValidation.addToForm(addressForm);
        this.addressForm(addressForm);
        sectionValidator.registerForm(this.addressForm, this.sectionNumber);
        this._corelateCountryAndAddress();

        if (this.cwkCandidate) {
            this._checkAddressAlreadyPresentCWK();
        }
    }

    _checkAddressAlreadyPresentCWK() {
        const addressAlreadyAvailableCWK = checkAddressAlreadyAvailable(addressBackupInstance.get());

        this.addressAlreadyAvailableCWK(addressAlreadyAvailableCWK);
    }

    disableAddressAndCountryForm() {
        if (this.addressForm()?.elements()?.length > 0) {
            this.addressForm().elements().forEach((element) => {
                if (element.component() === 'text-form-element') {
                    element.attributes('readonly', 'readonly');
                    element.attributes('aria-describedby', 'block-disabled');
                } else if (element.component() === 'geo-hierarchy-search-form-element') {
                    element.isReadonly(true);
                    element.ariaDescribedBy('block-disabled');
                } else {
                    element.isDisabled(true);
                }
            });
        }

        if (this.countryForm()?.elements()?.length > 0) {
            this.countryForm().elements()[0].isReadonly(true);
            this.countryForm().elements()[0].ariaDescribedBy('block-disabled');
        }
    }

    _corelateCountryAndAddress() {
        const geoHierarchyRoot = this.addressForm().getGeoHierarchyRoot();

        if (geoHierarchyRoot) {
            geoHierarchyRoot.registerParent(this.countryForm().countryElement);
        }
    }

    _createCountryForm(formConfig) {
        const countryForm = addressFormBuilder.createCountryForm(formConfig);

        countryForm.registerModel(this.addressModel);
        this.customJsValidation.addToForm(countryForm);
        this.countryForm(countryForm);
        sectionValidator.registerForm(this.countryForm, this.sectionNumber);
    }

    dispose() {
        this._addressFormConfigSub.dispose();

        if (this._addressAlreadyAvailableCWKSub) {
            this._addressAlreadyAvailableCWKSub.dispose();
        }
    }

}