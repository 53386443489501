import 'apply-flow/module/e-signature/binding/setFocusWhenInvalid';
import '../component/text-truncate/component';

import metadataConfig from 'apply-flow/config/metadata';

import { components } from 'knockout';

import ViewModel from 'apply-flow/module/e-signature/ESignatureViewModel';
import template from 'apply-flow/module/e-signature/e-signature.html';

components.register('apply-flow-e-signature', { viewModel: ViewModel, template });

metadataConfig.registerBlock('ORA_ESIGNATURE', {
    component: 'apply-flow-e-signature',
});
