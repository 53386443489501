import { components } from 'knockout';

import { JobDetailsInfoViewModel } from '../job-details-info/JobDetailsInfoViewModel';
import template from './job-details-info.html';
import 'minimal/module/job-meta-item/config/module';

components.register('job-details-info', {
    viewModel: JobDetailsInfoViewModel,
    template,
});

components.register('cc-job-details-info', {
    viewModel: JobDetailsInfoViewModel,
    template,
});
