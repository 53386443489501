import { CookieConsentModalMessageCustomizationParams } from './types';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';

export const emptyParams: CookieConsentModalMessageCustomizationParams = {
    cookieConsentMessageTypography: { ...EMPTY_TYPOGRAPHY },
    overAllStyling: { ...EMPTY_BLOCK_STYLES },
    cookiePreferenceLinkTypography: { ...EMPTY_TYPOGRAPHY },
    cookiePreferenceLinkHoverTypography: { ...EMPTY_TYPOGRAPHY },
};
