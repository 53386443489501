import { pureComputed, observable } from 'knockout';
import { searchResultsAiJobs as searchResultsModel } from '../../model/searchJobResults';
import { getJobUrl, openJob } from 'minimal/module/search/service/jobRouting';

const RECOMMENDED_JOBS_CONTAINER_SELECTOR = '.jobs-list__list';
const RECOMMENDED_JOB_LIST_ELEMENT_SELECTOR = '.job-tile__show';

export default class AiJobsSearchAbstractViewModel {

    constructor({ requisitionId, className, isReady }) {
        this.getJobUrl = getJobUrl;
        this.openJob = openJob;
        this.initialized = searchResultsModel.initialized;
        this.requisitions = searchResultsModel.results;
        this.loadingMoreInProgress = observable(false);
        this.loading = searchResultsModel.loading;
        this.showResults = pureComputed(this._showResults, this);
        this.hasMoreResults = searchResultsModel.hasMore;
        this.requisitionId = requisitionId;
        this.className = className;
        this.header = this.getHeaderLabel();
        this.isReady = isReady ?? observable(false);

        this.performSearch();
    }

    performSearch() {
        throw new Error('Method not implemented');
    }

    getMoreJobs() {
        throw new Error('Method not implemented');
    }

    getHeaderLabel() {
        throw new Error('Method not implemented');
    }

    loadMoreJobs() {
        const currentListElementsLength = this.requisitions().length;

        this.loadingMoreInProgress(true);

        this.getMoreJobs()
            .then(() => {
                this._onMoreJobsLoaded();
                this._setFocusOnNextJobListElement(currentListElementsLength);
            })
            .catch(this._handleError);
    }

    _showResults() {
        return !this.loading() && this._hasResults();
    }

    _onInitialized(isInitialized) {
        if (isInitialized) {
            this.jobsLoaded(true);
        }
    }

    onJobsLoaded() {
        this.isReady(true);
    }

    _hasResults() {
        return this.initialized() && this.requisitions().length > 0;
    }

    _onMoreJobsLoaded() {
        this.loadingMoreInProgress(false);
    }

    _handleError(error) {
        console.error(error);
        this.loadingMoreInProgress(false);
        this.loading(false);
    }

    _setFocusOnNextJobListElement(index) {
        const parentElement = document.querySelector(RECOMMENDED_JOBS_CONTAINER_SELECTOR);
        const nextChild = parentElement.querySelectorAll(RECOMMENDED_JOB_LIST_ELEMENT_SELECTOR)[index];

        if (nextChild) {
            nextChild.focus();
        }
    }

}
