import i18n from 'core/i18n/i18n';
import candidate from 'apply-flow/model/candidate';
import submitChecklist from 'apply-flow/service/submit-checklist/submitChecklist';
import {
    SAVING_CANDIDATE,
    CREATING_CANDIDATE,
    labels as stageLabels,
    REGISTERING_EVENT,
} from 'apply-flow/enum/submitStages';
import {
    Candidate,
    EventRegisterModel,
    EventRegistrationCreationResponse,
    EventRegistrationSteps,
} from '../config/types';
import http from 'app/module/core/http/http';
import submitService from 'apply-flow/service/submit';
import candidateService from 'apply-flow/service/candidate';
import applicationDraftService from 'apply-flow/service/applicationDraft';
import tokenService from 'cx/module/candidate-verification/service/token';
import unverifiedService from 'app/module/cx/module/candidate-verification/service/unverified';
import { Event, EventActionResponse, EventActionRestParams } from '../../candidate-self-service/config/types';
import { mapEventsFromRest, RestEvent } from '../../candidate-self-service/mapper/eventRegistration';
import { mapEventRegistrationCreationResponse } from '../mapper/eventRegistration';
import { confirmEventAction } from '../../candidate-self-service/service/eventRegistration';

const EVENT_REGISTERATION_URL = '/recruitingCEEventRegistrations';
const REGISTERED_EVENT_BY_EVENT_NUMBER =
    '/recruitingCEEventRegistrations?finder=findByEventNumberFinder;EventNumber=:eventNumber:';

export const saveCandidateAndRegisterEvent = async (
    eventRegistrationModel: EventRegisterModel
): Promise<EventRegistrationCreationResponse> => {
    prepareSaveCandidateStage();
    prepareEventRegistrationStage();

    const eventRegistrationSteps = getEventRegistrationSteps(eventRegistrationModel);

    submitChecklist.show();

    await submitService.saveCandidate().then(eventRegistrationSteps.handleCandidateModificationDate);

    eventRegistrationSteps.handleCandidateLastModificationDate();

    await applicationDraftService.updateCandidateDrafts(candidate);

    return await eventRegistrationSteps.createEventRegistration();
};

const getEventRegistrationSteps = (eventRegistrationModel: EventRegisterModel): EventRegistrationSteps => {
    const createEventRegistration = submitChecklist.addStep(
        {
            stage: REGISTERING_EVENT,
            step: 'Handle Event Register Creation',
        },
        triggerEventRegistrationCreation,
        eventRegistrationModel
    );

    const handleCandidateModificationDate = submitChecklist.addStep(
        {
            stage: REGISTERING_EVENT,
            step: 'Handle Candidate Modification Date',
        },
        saveCandidateModificationDate
    );

    const handleCandidateLastModificationDate = submitChecklist.addStep(
        {
            stage: REGISTERING_EVENT,
            step: 'Handle Candidate Modification Date',
        },
        updateCandidateLastModificationDate,
        candidate
    );

    return { handleCandidateModificationDate, handleCandidateLastModificationDate, createEventRegistration };
};

const getSaveCandidateStageName = () => {
    return candidate.isNew() ? CREATING_CANDIDATE : SAVING_CANDIDATE;
};

const prepareSaveCandidateStage = () => {
    const candidateSaveStage = getSaveCandidateStageName();

    submitChecklist.addStage({
        name: candidateSaveStage,
        label: i18n(stageLabels[candidateSaveStage]),
    });
};

const prepareEventRegistrationStage = () => {
    submitChecklist.addStage({
        name: REGISTERING_EVENT,
        label: i18n(stageLabels[REGISTERING_EVENT]),
    });
};

const triggerEventRegistrationCreation = async (
    eventRegistrationModel: EventRegisterModel
): Promise<EventRegistrationCreationResponse> => {
    eventRegistrationModel.action = 'CREATE';
    eventRegistrationModel.candidateNumber = candidate.id();

    return http
        .post(EVENT_REGISTERATION_URL, eventRegistrationModel, {
            statusCodes: {
                400({ response }: { response: string }) {
                    return Promise.reject(response);
                },
            },
        })
        .then((restEvent: RestEvent) => {
            return mapEventRegistrationCreationResponse(restEvent);
        });
};

const saveCandidateModificationDate = (): void => {
    return applicationDraftService.updateCandidateModificationDate();
};

const updateCandidateLastModificationDate = (submittedCandidate: Candidate): void => {
    candidateService.updateCandidateLastModificationDate(submittedCandidate);
};

export const confirmEventRegistration = async (
    eventRegistrationModel: EventRegisterModel
): Promise<EventActionResponse> => {
    eventRegistrationModel.action = 'CONFIRM';

    const {
        RecruitingEventQuestionnaireResponses,
        flowVersionId,
        legalDescriptionVersionId,
        ...registrationConfirmActionParams
    } = eventRegistrationModel;

    return confirmEventAction(registrationConfirmActionParams as EventActionRestParams);
};

export const hasRegisteredEvent = async (eventId: string): Promise<boolean> => {
    const token = tokenService.get() || {};

    if (!token.candidateNumber && !unverifiedService.isKnownUnverifiedCandidate()) {
        return Promise.resolve(false);
    }

    const result = await http
        .get(REGISTERED_EVENT_BY_EVENT_NUMBER, {
            params: {
                eventNumber: eventId,
            },
        })
        .catch(console.error);

    return Boolean(result?.items && result.items.length);
};

export const getEventRegistrationDetails = async (eventId: string): Promise<Event> => {
    const result = await http
        .get(REGISTERED_EVENT_BY_EVENT_NUMBER, {
            params: {
                eventNumber: eventId,
            },
        })
        .catch(console.error);

    if (result?.items && result.items.length) {
        return mapEventsFromRest(result.items)[0];
    } else {
        return Promise.reject('No Event Found');
    }
};
