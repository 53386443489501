import httpLangCached from 'cx/service/http/httpLangCached';
import metadataMapper from '../mapper/metadata';

export default class Metadata {

    constructor(serviceUrl) {
        this._serviceUrl = serviceUrl;
    }

    getFormElements() {
        return httpLangCached.get(this._serviceUrl)
            .then(response => metadataMapper.mapFormElementsFromRest(response));
    }

    isMultiProfileItemSupported() {
        return true;
    }

}